import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DashboardPanelType, DateSelection } from 'types';
import {
  DateControls,
  LeftSidebar,
  ProductTour,
  useLeftSidebarState,
} from 'components';
import Datepicker from 'composite/Datepicker';
import classnames from 'classnames';
import { useRequest, useSearchFormulas, useUrlState } from 'hooks';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import useDebouncedEffect from 'use-debounced-effect';
import RumChartGrid from './RumChartGrid';
import RumEventDrawer from './RumEventDrawer';
import RumSearch from './RumSearch/RumSearch';
import RumSidebar from './RumSidebar';
import RumTable from './RumTable';
import RumTimeseries from './RumAnalytics/RumTimeseries';
import EventTypeFilter from './EventTypeFilter';
import rumLabels from './requests/rumLabels';
import {
  RumErrorTab,
  RumEventType,
  RumTab,
  RumEventWithTabToOpen,
} from './types';
import RumErrorGroupTable from './RumErrorGroupTable';
import RumApplicationFilter from './RumApplicationFilter';

type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
  rumTab: RumTab;
};

const Rum = ({ rumPageState, rumTab }: Props) => {
  const [activeRumEvent, setActiveRumEvent] =
    useUrlState<RumEventWithTabToOpen>('activeRumEvent', null);

  const {
    dependenciesForWriteStateToUrl,
    searches,
    rumState,
    writeStateToUrl,
  } = rumPageState;
  const [content, setContent] = useState('');

  const queries = searches.map((search) => search.state);
  const searchesFormulas = useSearchFormulas(queries);

  const search = searches[0];

  const leftSidebarState = useLeftSidebarState('rum');
  const rumLabelNamesRequest = useRequest(rumLabels);

  const { dateState, eventType, errorTab } = rumState;

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesForWriteStateToUrl);

  const [date, setDate] = dateState;

  useDebouncedEffect(
    () => {
      rumLabelNamesRequest.call({ eventType });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      timeout: 200,
      ignoreInitialCall: false,
    },
    [eventType],
  );

  const handleRumEventDrawerClose = () => {
    setActiveRumEvent(null);
  };

  useEffect(() => {
    if (rumTab === RumTab.geomap) {
      searches[0].setOnlyOneAdvancedGroupBy('geo.country');
    }

    if (
      (rumTab as unknown as DashboardPanelType) !== DashboardPanelType.TABLE
    ) {
      const currentAggregations = searches[0].state.multiAggregations;
      if (currentAggregations?.length > 1) {
        searches[0].setOnlyOneMultiAggregationBy(
          currentAggregations[0].measure,
          currentAggregations[0].operation,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rumTab]);

  const showErrorGroupTable = useMemo(() => {
    return errorTab === RumErrorTab.ERROR_GROUP;
  }, [errorTab]);

  return (
    <div
      className={classnames({
        rum: true,
        'relative flex w-full flex-row overflow-hidden': true,
      })}
    >
      <LeftSidebar leftSidebarState={leftSidebarState}>
        <RumApplicationFilter rumState={rumState} />
        <RumSidebar
          eventType={eventType}
          rumLabelNamesRequest={rumLabelNamesRequest}
          rumState={rumState}
        />
      </LeftSidebar>

      <div className="rum__main  relative flex flex-col overflow-auto">
        <div className="rum__header flex flex-row items-start justify-end">
          <div className="flex grow flex-col overflow-hidden">
            <RumSearch
              leftSidebarState={leftSidebarState}
              placeholder="Search"
              searches={searches}
              searchesFormulas={searchesFormulas}
              rumLabelNamesRequest={rumLabelNamesRequest}
              rumState={rumState}
              rumTab={rumTab}
            />
          </div>
          <div className="rum__header__right">
            <div className="flex flex-row items-center justify-end">
              <Datepicker onChange={setDate} value={date as DateSelection} />
              <DateControls date={date} setDate={setDate} />
            </div>
            <div className="mt-3 flex flex-row justify-end">
              <EventTypeFilter rumState={rumState} />
            </div>
          </div>
        </div>
        {rumTab === RumTab.list && eventType !== RumEventType.SESSION ? (
          <RumChartGrid eventType={eventType} rumState={rumState} />
        ) : null}
        <div
          className="rum__main relative flex flex-col overflow-auto ms-4"
          data-testid="rum_main"
        >
          {rumTab === RumTab.list && (
            <>
              {showErrorGroupTable && eventType === RumEventType.ERROR ? (
                <RumErrorGroupTable rumState={rumState} />
              ) : (
                <RumTable
                  setActiveRumEvent={setActiveRumEvent}
                  rumState={rumState}
                />
              )}
            </>
          )}
          {rumTab !== RumTab.list ? (
            <RumTimeseries
              searches={searches}
              rumState={rumState}
              rumTab={rumTab}
            />
          ) : null}
        </div>
      </div>
      {activeRumEvent ? (
        <RumEventDrawer
          activeRumEvent={activeRumEvent}
          close={handleRumEventDrawerClose}
          openReplay={activeRumEvent.openReplay}
          eventType={eventType}
        />
      ) : null}
      {<ProductTour />}
    </div>
  );
};

export default Rum;
