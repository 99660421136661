import dayjs from 'dayjs';
import { DateSelection, SelectedFacetValues, TimeSeries } from 'types';
import { onPromiseError } from 'utils';
import { buildQuery } from 'requests/utils';
import query from 'requests/query';

type Args = {
  cardinalityType?: 'All' | 'Values';
  countType?: 'Accurate' | 'Hll';
  date: DateSelection;
  filterByFacets?: string[];
  keys?: string[];
  rollUpSeconds?: number;
  selectedFacetValues: SelectedFacetValues;
};

const labelCardinality = async ({
  cardinalityType = 'Values',
  countType = 'Accurate',
  date,
  filterByFacets = [],
  keys = [],
  rollUpSeconds,
  selectedFacetValues = {},
}: Args): Promise<TimeSeries[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const logQuery = buildQuery({ filterByFacets, selectedFacetValues });

  return query<TimeSeries[], 'labelCardinality'>(`
    query LabelCardinality {
      labelCardinality(
        cardinalityType: ${cardinalityType}
        countType: ${countType}
        durationSecs: ${durationSecs}
        keys: ${JSON.stringify(keys)}
        ${logQuery !== '{}' ? `logQuery: ${logQuery},` : ''}
        rollUpSeconds: ${rollUpSeconds || durationSecs}
        timestamp: "${endTime.format()}"
      ) {
       points {
        ts
        value
       }
       tags
      }
    }
  `).then((data) => data.labelCardinality || [], onPromiseError);
};

export default labelCardinality;
