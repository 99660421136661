import { AutocompleteV2, SelectV2 } from 'components';
import React, { ReactElement } from 'react';
import { getForecastDefaultIntervalBySeasonality } from 'utils';

import {
  AlertsCreateConditionInfo,
  useAlertsCreateCondition,
} from '../AlertsCreateCondition';
import { forecastAlertPopupMessage } from '../constants';
import {
  forecastSeasonalityDurationOptions,
  forecastSeasonalityOptions,
  getForecastIntervalBySeasonality,
} from './utils';
import { RuleType } from '../types';

const AlertsCreateForecastConditionSeasonal = ({
  conditionState,
  handleForecastDurationChange,
  ruleType,
  seasonalityOptions = forecastSeasonalityOptions,
}: {
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
  handleForecastDurationChange: (val: string) => void;
  ruleType: RuleType;
  seasonalityOptions?: { label: string; value: string }[];
}): ReactElement => {
  const { forecastCondition, setForecastCondition } = conditionState;
  const { forecastDuration, seasonality, interval } = forecastCondition;

  return (
    <div>
      <div className="alerts__create__section__item">
        <div className="alerts__create__conditions__item__text">
          and Forecast Duration:
        </div>
        <div className="alert__create__anomaly__detaction_desciption">
          <AlertsCreateConditionInfo
            description={forecastAlertPopupMessage.SEASONAL_HISTORY_DURATION}
          />
        </div>
        <div className="alerts__create__conditions__item__input alerts__create__conditions__item__input--select">
          <SelectV2.Select
            onChange={(val: string) => handleForecastDurationChange(val)}
            onEnter={(val: string) => handleForecastDurationChange(val)}
            placeholder="Duration"
            isAutocompleteEnabled
            options={forecastSeasonalityDurationOptions}
            value={forecastDuration}
          />
        </div>
        <div className="alerts__create__conditions__item__text">
          Seasonality:
        </div>
        <div className="alerts__create__conditions__item__input alerts__create__conditions__item__input--select">
          <AutocompleteV2
            onChange={(val: string) =>
              setForecastCondition((prev) => ({
                ...prev,
                seasonality: val,
                interval: getForecastDefaultIntervalBySeasonality(
                  val,
                  ruleType,
                ).toString(),
              }))
            }
            placeholder="Seasonality"
            options={seasonalityOptions}
            value={seasonality}
          />
        </div>
        <div className="alerts__create__conditions__item__text">
          and Interval:
        </div>
        <div className="alerts__create__conditions__item__input alerts__create__conditions__item__input--select">
          <AutocompleteV2
            onChange={(val: string) =>
              setForecastCondition((prev) => ({ ...prev, interval: val }))
            }
            placeholder="Interval"
            options={getForecastIntervalBySeasonality(seasonality, ruleType)}
            value={interval}
          />
        </div>
      </div>
    </div>
  );
};

export default AlertsCreateForecastConditionSeasonal;
