import { DateSelection } from 'types/DateSelection';

/**
 * Fill the timestamp for a given window
 * @param executedDate DateSelection
 * @param step number
 * @param timestampBitmap { [key: string]: boolean }
 * @returns { timestamps: number[]; timestampBitmap: { [key: string]: boolean } }
 */
export const fillTimestampForWindow = ({
  executedDate,
  step,
}: {
  executedDate: DateSelection;
  step: number;
}): {
  timestamps: number[];
  timestampBitmap: { [key: string]: boolean };
} => {
  if (!executedDate || !step) {
    return { timestamps: [], timestampBitmap: {} };
  }

  const { startTimeUnix, endTimeUnix } = executedDate;
  const newTimestampBitmap: { [key: string]: boolean } = {};
  let ts = startTimeUnix;
  while (ts <= endTimeUnix) {
    newTimestampBitmap[ts] = true;
    ts += step;
  }

  const combinedTimestampBitmap = { ...newTimestampBitmap };
  const timestamps = Object.keys(combinedTimestampBitmap).map(Number).sort();
  return { timestamps, timestampBitmap: combinedTimestampBitmap };
};
