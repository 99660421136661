import classnames from 'classnames';
import React, { ReactNode } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { RiErrorWarningLine } from 'react-icons/ri';
import { ImTable } from 'react-icons/im';
import { ApmErrorsTab } from 'types';

const iconByVisualizeAs: { [key in ApmErrorsTab]: ReactNode } = {
  [ApmErrorsTab.allErrors]: <ImTable size={14} />,
  [ApmErrorsTab.errorGroups]: <RiErrorWarningLine size={14} />,
};

const labelByVisualizeAs: { [key in ApmErrorsTab]: string } = {
  [ApmErrorsTab.allErrors]: 'All Errors',
  [ApmErrorsTab.errorGroups]: 'Error Groups',
};

const ApmErrorsVisualize = () => {
  const [searchParams] = useSearchParams();
  const searchParamsString = searchParams.toString();
  return (
    <div className="search__visualize button-group mt-4">
      {[ApmErrorsTab.allErrors, ApmErrorsTab.errorGroups].map(
        (apmErrorsTab) => (
          <NavLink
            className={({ isActive }) =>
              classnames({
                'button-group__item': true,
                'button-group__item--active': isActive,
              })
            }
            key={apmErrorsTab}
            to={`/apm/errors/${apmErrorsTab}${
              searchParamsString ? `?${searchParamsString}` : ''
            }`}
          >
            <div className="button-group__item__icon">
              {iconByVisualizeAs[apmErrorsTab]}
            </div>
            {labelByVisualizeAs[apmErrorsTab]}
          </NavLink>
        ),
      )}
    </div>
  );
};

export default ApmErrorsVisualize;
