export enum TimeUnit {
  NANOSECONDS = 'ns',
  MICROSECONDS = 'μs',
  MILLISECONDS = 'ms',
  SECONDS = 's',
  MINUTES = 'm',
  HOURS = 'h',
  DAYS = 'd',
  WEEKS = 'w',
  MONTHS = 'M',
}
