import classnames from 'classnames';
import React, { useMemo } from 'react';
import tinycolor from 'tinycolor2';
import { formatDurationNs } from 'utils';
import { RumEventType } from '../types';
import { FormattedRumEventForFlameGraph } from './types';

type Props = {
  event: FormattedRumEventForFlameGraph;
  eventId: string;
  getColor: (event: FormattedRumEventForFlameGraph) => string;
  hoveredEventId?: string;
  minPresentationalDuration: number;
  minStartTimeNs: number;
  maxEndTimeNs: number;
  niceUpperBound: number;
  onClick: VoidFunction;
  scrollLeft: number;
  width: number;
};

const RumFlameGraphChartRow = ({
  event,
  eventId,
  getColor,
  hoveredEventId,
  minPresentationalDuration,
  minStartTimeNs,
  niceUpperBound,
  onClick,
  scrollLeft,
  width,
}: Props) => {
  const { startTimeNs, endTimeNs } = event;

  const duration = Math.max(endTimeNs - startTimeNs, minPresentationalDuration);
  const totalDuration = niceUpperBound;

  const chartWidth = (duration / totalDuration) * width;
  const translateX = ((startTimeNs - minStartTimeNs) / niceUpperBound) * width;

  const color = getColor(event);

  const isError = useMemo(
    () => event.eventType === RumEventType.ERROR,
    [event?.eventType],
  );

  return (
    <div
      className={classnames({
        flamegraph__span: true,
        'flamegraph__span--dark': tinycolor(color).isDark(),
        'flamegraph__span--hovered': hoveredEventId === eventId,
        'flamegraph__span--narrow': chartWidth < 50,
        'rum-flamegraph__error': isError,
      })}
      onClick={onClick}
      style={{
        backgroundColor: color,
        transform: `translate3d(${translateX}px, 0, 0) ${isError ? 'rotate(45deg)' : ''}`,
        width: `${Math.max(chartWidth, 1)}px`,
      }}
    >
      <div className="flamegraph__span__highlighter" />
      <div className="flamegraph__span__text flex">
        <div className="flex__left">
          <div
            className={classnames({
              flamegraph__span__text__name: true,
            })}
            style={{
              transform: `translate3d(${
                scrollLeft > translateX ? `${scrollLeft - translateX}px` : '0'
              }, 0, 0)`,
            }}
          ></div>
        </div>
        <div className="flamegraph__span__text__duration">
          {formatDurationNs(endTimeNs - startTimeNs, 1, 2)}
        </div>
      </div>
    </div>
  );
};

export default RumFlameGraphChartRow;
