import { MutableRefObject, useState } from 'react';
import { LogEvent, SelectedLog } from 'types';
import clamp from './clamp';

const getLogsPrevAndNextHandlers = ({
  logsRef,
  setSelectedLog,
}: {
  logsRef: MutableRefObject<LogEvent[]>;
  setSelectedLog: ReturnType<typeof useState<SelectedLog>>[1];
}) => {
  const handler = (sumToIndex: number) => () => {
    setSelectedLog((prevSelectedLog) => {
      const logs = logsRef.current;
      if (!prevSelectedLog) {
        return prevSelectedLog;
      }

      const index = clamp(
        prevSelectedLog.index + sumToIndex,
        0,
        logs.length - 1,
      );

      return {
        ...prevSelectedLog,
        index,
        log: logs[index],
      };
    });
  };
  return {
    next: handler(1),
    prev: handler(-1),
  };
};

export default getLogsPrevAndNextHandlers;
