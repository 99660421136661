import { Band } from 'uplot';
import { anomalyBandColor } from '..';

const getAnomalyBandOnSelect = ({
  anomalyBandIndexes,
  darkModeEnabled,
  mode,
  prevBands,
}: {
  anomalyBandIndexes: {
    lowerBandIndex: number;
    upperBandIndex: number;
    totalAnomalySeries: number;
  };
  darkModeEnabled: boolean;
  mode: string;
  prevBands: Band[];
}): Band[] => {
  if (!anomalyBandIndexes) return prevBands;
  if (mode === 'append') return [];

  const { lowerBandIndex, upperBandIndex, totalAnomalySeries } =
    anomalyBandIndexes;

  if (totalAnomalySeries === 0 || totalAnomalySeries === 1) {
    return prevBands;
  }

  return [
    {
      series: [upperBandIndex, lowerBandIndex],
      fill: darkModeEnabled ? anomalyBandColor.dark : anomalyBandColor.light,
      dir: -1,
    },
  ];
};

export default getAnomalyBandOnSelect;
