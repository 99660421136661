import { useSelectedFacetValuesByNameState } from 'hooks';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { extractPathNameFromURL, parseUrlParamByKey } from 'utils';

const useAlertsPageState = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const parsedParamValue = parseUrlParamByKey(
    'alertsSelectedFacetValuesByName',
  );
  const initialState = parsedParamValue || {};
  const selectedFacetValuesByNameState = useSelectedFacetValuesByNameState({
    shouldWriteToUrl: false,
    initialState,
  });

  // Get dependencies for determining when a url change should happen
  const dependenciesForWriteStateToUrl = useMemo(
    () => [selectedFacetValuesByNameState.state],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedFacetValuesByNameState.state],
  );

  const writeStateToUrl = () => {
    params.set(
      'alertsSelectedFacetValuesByName',
      JSON.stringify(selectedFacetValuesByNameState.state),
    );
    const navigateUrl = `${extractPathNameFromURL()}?${params.toString()}`;
    navigate(navigateUrl, { replace: true });
  };

  return {
    selectedFacetValuesByNameState,
    dependenciesForWriteStateToUrl,
    writeStateToUrl,
  };
};

export default useAlertsPageState;
