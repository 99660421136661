import { useRef } from 'react';

const useTextSelectionVsClick = (
  onClickHandler: (e: React.MouseEvent) => void,
) => {
  const isSelectingTextRef = useRef(false);

  const handleMouseDown = () => {
    const handleMouseUp = () => {
      isSelectingTextRef.current = window.getSelection()?.toString().length > 0;
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleClick = (e: React.MouseEvent) => {
    if (!isSelectingTextRef.current) {
      onClickHandler(e);
    }
  };

  return {
    handleMouseDown,
    handleClick,
  };
};

export default useTextSelectionVsClick;
