import { useThemeContext, useToaster } from 'components';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DateSelection } from 'types';
import {
  extractPathNameFromURL,
  extractParamsFromURL,
  getRelativeVsAbsolute,
} from 'utils';

const KEY = 'date';

const getInitialState = () => {
  const endTimeUnix = dayjs().unix();
  const startTimeUnix = dayjs()
    .subtract(60 * 5, 'seconds')
    .unix();

  return {
    startLabel: 'now-5m',
    endLabel: 'now',
    endTimeUnix,
    startTimeUnix,
  };
};

const useDateState = (initialDate?: DateSelection): [DateSelection, any] => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const themeContext = useThemeContext();
  const { utcTimeEnabled } = themeContext || {};
  const initialStateFromParams = params.get(KEY);

  const { addToast } = useToaster();

  const [state, setState] = useState<DateSelection>(
    initialStateFromParams
      ? getRelativeVsAbsolute({
          addToast,
          date: JSON.parse(initialStateFromParams),
          utcTimeEnabled,
        })
      : initialDate || getInitialState(),
  );

  const setStateAndUpdateUrl = (
    nextState: DateSelection | ((prevState: DateSelection) => DateSelection),
  ) => {
    setState((prevState) => {
      const computedNextState =
        typeof nextState === 'function' ? nextState(prevState) : nextState;
      const paramsFromURL = extractParamsFromURL();
      paramsFromURL.delete('live-tail');
      paramsFromURL.set(
        KEY,
        JSON.stringify({
          ...computedNextState,
        }),
      );

      const navigateUrl = `${extractPathNameFromURL()}?${paramsFromURL.toString()}`;

      navigate(navigateUrl, { replace: true });

      return computedNextState;
    });
  };

  return [state, setStateAndUpdateUrl];
};

export default useDateState;
