import { AnalyticsChartQueryProps } from 'components';
import { DashboardPanelProps, DashboardPanelType } from 'types';

export const getLogQLDashboardExportPanel = ({
  targets,
  type,
}: {
  targets: DashboardPanelProps['targets'];
  type: DashboardPanelType;
}): DashboardPanelProps => {
  return {
    options: {
      legend: { calcs: [], displayMode: 'list', placement: 'bottom' },
      tooltip: { mode: 'single', sort: 'none' },
    },
    targets,
    type: type,
  };
};

export const getLogqlOrKfusePanelTargets = ({
  analyticsChartQueries,
  panelType,
}: {
  analyticsChartQueries: AnalyticsChartQueryProps[];
  panelType: DashboardPanelType;
}): DashboardPanelProps['targets'] => {
  const range = panelType === DashboardPanelType.TIMESERIES;
  const targets: DashboardPanelProps['targets'] = [];
  analyticsChartQueries.forEach(({ queryKey, logql, isActive }) => {
    if (!logql?.logql) return;
    targets.push({
      datasource: { type: 'loki', uid: '' },
      editorMode: 'code',
      expr: logql.logql,
      refId: queryKey,
      hide: !isActive,
      range,
      instant: !range,
    });
  });

  return targets;
};
