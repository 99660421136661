import * as yup from 'yup';

const yupValidator = ({
  schema,
  value,
  options = {},
}: {
  schema: yup.AnyObjectSchema;
  value: any;
  options?: yup.ValidateOptions;
}): { [key: string]: string } => {
  try {
    schema.validateSync(value, options);
    return {};
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      if (err.inner.length) {
        const firstError = err.inner[0];
        return {
          [firstError.path]: firstError.errors[0],
        };
      }

      return {
        [err.path]: err.errors[0],
      };
    } else {
      return {
        error: 'An unknown error occurred',
      };
    }
  }
};

export default yupValidator;
