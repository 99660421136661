import classNames from 'classnames';
import { Button, Input, Stepper } from 'components';
import React, { ReactElement, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AlertsCreateAPMService from 'screens/NewAlerts/AlertsCreateAPM/AlertsCreateAPMService';
import { useAlertsCreateAPM } from 'screens/NewAlerts/AlertsCreateAPM/hooks';
import { AlertsCreateContacts } from 'screens/NewAlerts/components';
import { useAlertsCreate } from 'screens/NewAlerts/hooks';
import { RuleType } from 'screens/NewAlerts/types';

import { useCreateSLOState } from '../hooks';
import SLOCreateDetails from './SLOCreateDetails';
import SLODetection from './Steppers/SLODetection';
import SLOCreateNumerator from './SLOCreateNumerator';
import SLOCreateDenominator from './SLOCreateDenominator';

const SLOCreateStepper = ({
  createSLOState,
  isEditMode,
  activeDetection,
  handleActiveDetectionChange,
  alertsCreateAPMState,
  latencyThreshold,
  objective,
  setLatencyThreshold,
  setObjective,
  alertsCreateState,
  handleSubmitClick,
  setIsSloNameChanged,
  sloType,
  sloErrors,
}: {
  createSLOState: ReturnType<typeof useCreateSLOState>;
  isEditMode?: boolean;
  activeDetection: 'latency' | 'availability' | 'metrics';
  handleActiveDetectionChange: (val: 'latency' | 'availability') => void;
  alertsCreateAPMState: ReturnType<typeof useAlertsCreateAPM>;
  latencyThreshold: number;
  objective: number;
  setLatencyThreshold: (val: number) => void;
  setObjective: (val: number) => void;
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  handleSubmitClick: () => void;
  setIsSloNameChanged: (val: boolean) => void;
  sloType: RuleType;
  sloErrors: { [key: string]: boolean };
}): ReactElement => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const filteredSteps = useMemo(
    () =>
      [
        {
          title: 'Choose the detection method',
          component: (
            <SLODetection
              createSLOState={createSLOState}
              isEditMode={isEditMode}
              activeDetection={activeDetection}
              setActiveDetection={handleActiveDetectionChange}
            />
          ),
        },
        activeDetection === 'metrics'
          ? {
              title: 'Select Metrics',
              component: (
                <div className="alert__create__metrics__detaction_desciption">
                  <SLOCreateNumerator createSLOState={createSLOState} />
                  <SLOCreateDenominator createSLOState={createSLOState} />
                </div>
              ),
            }
          : {
              title: 'Pick a Service',
              component: (
                <AlertsCreateAPMService
                  alertsCreateAPMState={alertsCreateAPMState}
                  disableServiceSelection={Boolean(params.get('serviceHash'))}
                  pickLabelsText="Matchers"
                  defaultMatchers={[]}
                  hideTriggerType
                />
              ),
            },
        {
          title: 'Set Condition',
          component: (
            <div className="flex">
              {activeDetection === 'latency' && (
                <div className="flex flex-col">
                  <div className="flex items-center font-medium">
                    Latency Threshold (ms)
                    <Input
                      className={classNames({
                        'mx-2 w-24 py-0': true,
                        slo__latency: true,
                        'input--error': Boolean(sloErrors.latencyThreshold),
                      })}
                      onChange={setLatencyThreshold}
                      size="5"
                      type="number"
                      value={latencyThreshold}
                      variant="default"
                    />
                  </div>
                  {sloErrors.latencyThreshold && (
                    <span className="text--red">
                      Latency Threshold should be Greater than or equal to 0
                    </span>
                  )}
                </div>
              )}

              <div className="ml-2 flex flex-col">
                <div className=" flex items-center font-medium">
                  Objective %
                  <Input
                    className={classNames({
                      'mx-2 w-24 py-0': true,
                      slo__objective: true,
                      'input--error': Boolean(sloErrors.objective),
                    })}
                    onChange={setObjective}
                    size="5"
                    type="number"
                    value={objective}
                    variant="default"
                  />
                </div>
                {sloErrors.objective && (
                  <span className="text--red">
                    Objective should be between 0 and 100
                  </span>
                )}
              </div>
            </div>
          ),
        },
        {
          title: 'Add Details',
          component: (
            <SLOCreateDetails
              isEditMode={isEditMode}
              createSLOState={createSLOState}
              activeDetection={activeDetection}
              setIsSloNameChanged={setIsSloNameChanged}
            />
          ),
        },
        {
          title: 'Add Contacts',
          component: (
            <AlertsCreateContacts alertsCreateState={alertsCreateState} />
          ),
        },
      ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeDetection,
      alertsCreateAPMState,
      alertsCreateState,
      objective,
      params,
    ],
  );

  const isCreateButtonDisabled = useMemo(() => {
    return Object.values(sloErrors).some((error) => Boolean(error));
  }, [sloErrors]);

  return (
    <div className="slo__create__stepper">
      <Stepper steps={filteredSteps} />
      <div className="slo__create__stepper__actions">
        <Button
          variant="outline"
          className="button"
          onClick={() => navigate('/slo')}
          type="button"
        >
          Cancel
        </Button>
        <Button
          variant="default"
          disabled={isCreateButtonDisabled}
          className="button button--blue"
          onClick={handleSubmitClick}
        >
          {isEditMode ? 'Save SLO' : 'Create SLO'}{' '}
        </Button>
      </div>
    </div>
  );
};

export default SLOCreateStepper;
