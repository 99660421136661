import { useRequest, useToggle } from 'hooks';
import {
  MutableRefObject,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SelectedFacetValues, ValueCount } from 'types';
import { SidebarData } from '../types';

const searchFacetValues = (
  facetValues: ValueCount[],
  facetValueSearch: string,
  getSearchedValue?: (value: string) => void,
) => {
  const searchLowered = facetValueSearch.trim().toLowerCase();
  if (!searchLowered) {
    return facetValues;
  }

  return facetValues.filter((facetValue) => {
    const valueToSearch =
      typeof getSearchedValue === 'function'
        ? getSearchedValue(facetValue.value)
        : facetValue.value;

    return (valueToSearch || '').toLowerCase().indexOf(searchLowered) > -1;
  });
};

type Props = {
  disableSearch?: boolean;
  excludeFacetValue: (value: string) => void;
  getSearchedValue?: (value: string) => string;
  isRadio?: boolean;
  lastRefreshedAt: number;
  name: string;
  ref?: MutableRefObject<HTMLDivElement>;
  renderedName: ReactNode;
  renderPlaceholderText: (name: string) => string;
  renderValue?: (label: string) => ReactNode;
  requestAndStoreByFacetName?: () => void;
  selectedFacetValues: SelectedFacetValues;
  selectOnlyFacetValue: (value: string) => void;
  shouldDisableLoader?: boolean;
  sidebarDataState: SidebarData;
  toggleFacetValue: (value: string, values: ValueCount[]) => void;
  isRangeFacet?: boolean;
  expanded: boolean;
};

//TODO: Move away from V1 same as FacetPicker V2 Incrementally
// as we migrate the Sidebar State for other Pages
// InFacetPickerV2, we fetch the Data at the Parent Level and pass it down
//No change in User experience
const useFacetPickerValuesStateV2 = ({
  disableSearch,
  excludeFacetValue,
  getSearchedValue,
  isRadio,
  lastRefreshedAt,
  name,
  renderedName,
  renderPlaceholderText,
  renderValue,
  sidebarDataState,
  selectedFacetValues,
  selectOnlyFacetValue,
  shouldDisableLoader,
  toggleFacetValue,
  isRangeFacet,
  expanded,
  requestAndStoreByFacetName,
}: Props) => {
  const [initialFacetValues, setInitialFacetValues] = useState<ValueCount[]>(
    [],
  );
  const [facetValueSearch, setFacetValueSearch] = useState<string>('');
  const showAllToggle = useToggle();

  const isLoading = Boolean(sidebarDataState.sidebarDataLoading[name]);
  const facetValues = sidebarDataState.sidebarData[name] || [];

  useEffect(() => {
    if (initialFacetValues.length < facetValues.length) {
      setInitialFacetValues(facetValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facetValues]);

  const allValues = initialFacetValues.length
    ? initialFacetValues
    : facetValues;

  const searchedFacetValues = useMemo(() => {
    if (isRangeFacet) {
      return [];
    }
    const result = searchFacetValues(
      allValues.filter((facetValue) => facetValue.value !== 'UNKNOWN'),
      facetValueSearch,
      getSearchedValue,
    );

    return result.sort((a, b) => b.count - a.count);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFacetValues, facetValues, facetValueSearch, getSearchedValue]);

  useEffect(() => {
    if (!expanded) {
      return;
    }

    requestAndStoreByFacetName && requestAndStoreByFacetName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, lastRefreshedAt]);

  const countsByFacetValue: { [value: string]: number } = useMemo(
    () =>
      isRangeFacet
        ? {}
        : facetValues.reduce(
            (obj, facetValue: ValueCount) => ({
              ...obj,
              [facetValue.value]: facetValue.count,
            }),
            {},
          ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [facetValues],
  );

  const handlersByValue = (value: string) => ({
    excludeFacetValue: () => {
      excludeFacetValue(value);
    },
    selectOnlyFacetValue: () => {
      selectOnlyFacetValue(value);
    },
    toggleFacetValue: () => {
      toggleFacetValue(value, allValues);
    },
  });

  const searchedFacetValuesCount = searchedFacetValues.length;

  return {
    disableSearch,
    facetValues,
    setFacetValueSearch,
    name,
    renderedName,
    facetValueSearch,
    isLoading,
    searchedFacetValuesCount,
    renderPlaceholderText,
    searchedFacetValues,
    showAllToggle,
    countsByFacetValue,
    isRadio,
    renderValue,
    selectedFacetValues,
    handlersByValue,
    allValues,
  };
};

export default useFacetPickerValuesStateV2;
