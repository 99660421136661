import {
  SizeObserver,
  TooltipTrigger,
  TimeseriesRenderer,
  TimeseriesExplorer,
  ChartToolbar,
  useModalsContext,
  useThemeContext,
  useCursorContextState,
} from 'components';
import React, { ReactElement, useMemo } from 'react';
import {
  DateSelection,
  LegendTypes,
  QueryDataProps,
  UplotExtended,
} from 'types';
import {
  combineRangeQueryData,
  decodePromqlToReadable,
  getDefaultAnalyticsChartTypes,
  getPromqlQueryByIndex,
  setDateRangeOnChartZoom,
} from 'utils';

import MetricsChartsRightToolbar from './MetricsChartsRightToolbar';
import { MetricsChartsQueryItemProps } from './types';
import { hasMetricsChartAdvanceVisualization, onCreateAlert } from './utils';

const MetricsCharts = ({
  date,
  setDate,
  isLoading,
  queryData,
  queryItem,
}: {
  date: DateSelection;
  setDate: (date: DateSelection) => void;
  isLoading: boolean;
  queryData: QueryDataProps;
  queryItem: MetricsChartsQueryItemProps;
}): ReactElement => {
  const { cursorState, setCursorState } = useCursorContextState();
  const { darkModeEnabled } = useThemeContext();
  const chartModal = useModalsContext();

  const combinedData = useMemo(() => {
    const { formulas, queries } = queryItem;

    return combineRangeQueryData({
      formulas,
      queries,
      queryData,
      darkModeEnabled,
      combineTimestamp: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData, darkModeEnabled]);

  const defaultChartType = useMemo(() => {
    const dataPointLength = combinedData?.data[1]?.filter(Boolean)?.length;
    const hasAdvanceVisualization = hasMetricsChartAdvanceVisualization({
      queries: [queryItem.queries[queryItem.queryIndex]],
      queryData,
    });

    return getDefaultAnalyticsChartTypes({
      hasAdvanceVisualization,
      dataPointLength,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinedData?.data, queryData, queryItem.queries]);

  const onViewFullscreen = (activeChart: string, unit: string) => {
    const promql = getPromqlQueryByIndex(
      { ...queryItem, returnType: 'array' },
      date,
    ) as string;
    const selectedQuery = queryItem.queries[queryItem.queryIndex];

    chartModal.push(
      <TimeseriesExplorer
        activeQueries={[
          {
            promql,
            metricName: selectedQuery.metric,
            steps: selectedQuery.steps,
          },
        ]}
        activeChartType={activeChart}
        chartData={combinedData}
        date={date}
        onClose={() => chartModal.pop()}
        unit={unit}
      />,
    );
  };

  const promql = decodePromqlToReadable(
    getPromqlQueryByIndex(queryItem, date) as string,
  );

  const hooks = useMemo(() => {
    return [
      ...(combinedData.hooks || []),
      {
        hook: (u: UplotExtended) => setDateRangeOnChartZoom(u, setDate),
        type: 'setSelect',
      },
    ];
  }, [combinedData.hooks, setDate]);

  return (
    <SizeObserver>
      {({ width: chartWidth }) => (
        <TimeseriesRenderer
          bands={combinedData.bands || []}
          chartData={combinedData}
          chartTypes={defaultChartType}
          chartKey={queryItem.queryKey}
          cursorState={cursorState}
          date={date}
          hooks={hooks}
          isLoading={isLoading}
          legend={{
            legendType: LegendTypes.COMPACT_ONE_LINE,
            legendHeight: 150,
          }}
          renderToolbar={({
            activeChart,
            setActiveChart,
            unit,
            activeStroke,
            setActiveStroke,
          }) => (
            <ChartToolbar
              chartTypes={defaultChartType}
              activeChart={activeChart}
              activeStroke={activeStroke}
              setActiveChart={setActiveChart}
              setActiveStroke={setActiveStroke}
              toolbar={{
                leftToolbar: (
                  <TooltipTrigger
                    tooltip={<div className="flex break-all">{promql}</div>}
                  >
                    <div className="new-metrics__chart__left-toolbar__title">
                      {promql.length > 50
                        ? `${promql.slice(
                            0,
                            Math.floor((chartWidth * 0.7) / 8),
                          )}...`
                        : promql}
                    </div>
                  </TooltipTrigger>
                ),
                rightToolbar: (
                  <MetricsChartsRightToolbar
                    onCreateAlert={() => onCreateAlert(date, queryItem)}
                    onViewFullscreen={() => onViewFullscreen(activeChart, unit)}
                  />
                ),
                toolbarMenuType: 'dropdown',
              }}
            />
          )}
          size={{ width: chartWidth, height: 260 }}
          onCursorStateChange={setCursorState}
          tooltipType="compact"
          unit="number"
        />
      )}
    </SizeObserver>
  );
};

export default MetricsCharts;
