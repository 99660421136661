import { SelectedFacetValuesByName } from 'types';

type Args = {
  selectedFacetValuesByName: SelectedFacetValuesByName;
  serviceDistinctLabels: Record<string, string>;
};

const removeLabelsFromSelectedFacetValuesByName = ({
  selectedFacetValuesByName,
  serviceDistinctLabels,
}: Args) => {
  const result = { ...selectedFacetValuesByName };

  Object.keys(serviceDistinctLabels).forEach((label) => {
    const selectedFacetValues = selectedFacetValuesByName[label];
    if (selectedFacetValues) {
      const values = Object.keys(selectedFacetValues);
      if (values.length === 1 && values[0] === serviceDistinctLabels[label]) {
        delete result[label];
      } else {
        delete result[label][serviceDistinctLabels[label]];
      }
    }
  });

  return result;
};

export default removeLabelsFromSelectedFacetValuesByName;
