import { AutocompleteOption, AutocompleteListV2 } from 'components';
import React, { useEffect } from 'react';

type Props = {
  facetValueOptions: AutocompleteOption[];
  fetchFacetValuesByFacetName: (facetName: string) => Promise<any>;
  onAutocompleteOptionClick: ({
    option,
  }: {
    option: AutocompleteOption;
  }) => void;
  parsedFacetName: string;
  parsedValue: string;
};

const LogsSearchBarV2PanelValues = ({
  fetchFacetValuesByFacetName,
  facetValueOptions,
  onAutocompleteOptionClick,
  parsedFacetName,
  parsedValue,
}: Props) => {
  useEffect(() => {
    // don't fetch facet values for facet names and facet name starts with @
    if (parsedFacetName.startsWith('@')) return;
    fetchFacetValuesByFacetName(parsedFacetName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AutocompleteListV2
      close={() => {}}
      disableKeyExist
      onClickHandler={onAutocompleteOptionClick}
      onClickKeyExistHandler={() => {}}
      options={facetValueOptions}
      typed={parsedValue}
    />
  );
};

export default LogsSearchBarV2PanelValues;
