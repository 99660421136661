import React, { ReactElement } from 'react';

import { useAlertsCreateCondition } from '../AlertsCreateCondition';
import AlertsCreateAnomalyCondition from '../AlertsCreateMetrics/AlertsCreateAnomalyCondition';
import { AlertsCreateCondition } from '../AlertsCreateCondition';
import { AlertsConfigureNoData } from '../components';
import { useAlertsCreate } from '../hooks';

const AlertsCreateAPMCondition = ({
  alertsCreateState,
  conditionState,
  unit,
}: {
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
  unit: string;
}): ReactElement => {
  const { alertType } = alertsCreateState;
  return (
    <>
      {alertType === 'threshold' && (
        <>
          <AlertsCreateCondition
            alertsCreateState={alertsCreateState}
            conditionState={conditionState}
            queryAndFormulaKeysLabel={[]}
            unit={unit}
          />
          <AlertsConfigureNoData conditionState={conditionState} />
        </>
      )}
      {alertType === 'anomaly' && (
        <>
          <AlertsCreateAnomalyCondition
            alertsCreateState={alertsCreateState}
            conditionState={conditionState}
            queryAndFormulaKeysLabel={[]}
          />
          <AlertsConfigureNoData conditionState={conditionState} />
        </>
      )}
    </>
  );
};

export default AlertsCreateAPMCondition;
