import { fetchGrafanaApi } from 'requests';

import { FolderPermissionsProps } from '../types';

const getFolderPermissionsByUid = async ({
  uid,
}: {
  uid: string;
}): Promise<FolderPermissionsProps[]> => {
  const result = await fetchGrafanaApi(
    `grafana/api/access-control/folders/${uid}`,
  );

  return result;
};

export default getFolderPermissionsByUid;
