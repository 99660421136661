import React from 'react';
import classnames from 'classnames';

const Header = ({
  children,
  isBorderedLeft,
  isRoundedLeft,
}: {
  children: React.ReactNode;
  isBorderedLeft?: boolean;
  isRoundedLeft?: boolean;
}): React.ReactElement => {
  return (
    <div
      className={classnames(
        'flex h-[36px] items-center justify-center whitespace-nowrap',
        'border-y border-form-border',
        'bg-interaction-nested px-2.5 text-[15px] text-text-secondary',
        { 'border-l': isBorderedLeft },
        { 'rounded-l': isRoundedLeft },
      )}
    >
      {children}
    </div>
  );
};

export default Header;
