import { Portal } from '@radix-ui/react-portal';
import { useRefContext } from 'hooks';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

type Props = {
  children: ReactNode;
};

const getStyle = (state: {
  clientX: number;
  clientY: number;
  height: number;
  width: number;
  windowHeight: number;
  windowWidth: number;
}) => {
  if (!state) return { opacity: 0 };
  let x = state.clientX + 18;
  let y = state.clientY - 4;

  if (x + state.width > state.windowWidth) {
    x = state.clientX - state.width - 8;
    if (x < 0) x = 8;
  } else if (x < 0) {
    x = 8;
  }

  if (y + state.height > state.windowHeight) {
    y = state.clientY - state.height - 8;
    if (y < 0) y = 8;
  }

  return {
    transform: `translate3d(${x}px, ${y}px, 0)`,
  };
};

const CursorTooltip = ({ children }: Props) => {
  const { appRef } = useRefContext();
  const elementRef = useRef(null);
  const [state, setState] = useState(null);

  useEffect(() => {
    const onMouseMove = (e) => {
      const element = elementRef.current;
      if (element) {
        const { clientX, clientY } = e;

        setState({
          clientX,
          clientY,
          width: element.offsetWidth,
          windowHeight: window.innerHeight,
          windowWidth: window.innerWidth,
        });
      }
    };

    document.addEventListener('mousemove', onMouseMove);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  const style = state ? getStyle(state) : { opacity: 0 };

  return (
    <Portal container={appRef?.current}>
      <div className="cursor-tooltip" ref={elementRef} style={style}>
        {children}
      </div>
    </Portal>
  );
};

export default CursorTooltip;
