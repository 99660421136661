import React, { ReactElement, useMemo } from 'react';

import { getStatFontSize } from '../utils';
import { DashboardPanelComponentProps } from '../types';

const DashboardPanelNoData = ({
  message,
  panelSize,
}: {
  message?: string;
  panelSize: DashboardPanelComponentProps['panelSize'];
}): ReactElement => {
  const fontSize = useMemo(() => getStatFontSize(8, panelSize), [message]);

  return (
    <div
      className="dashboard__panel__no-data"
      style={{ height: panelSize.heightContainer }}
    >
      <p className="dashboard__panel__no-data__text" style={{ fontSize }}>
        {message || 'No data'}
      </p>
    </div>
  );
};

export default DashboardPanelNoData;
