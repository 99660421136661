import {
  Button,
  PopoverPosition,
  PopoverTriggerV2,
  TooltipTrigger,
} from 'components';
import React, { Dispatch, ReactElement, SetStateAction, useMemo } from 'react';
import { FiTarget } from 'react-icons/fi';
import { HiDotsVertical } from 'react-icons/hi';
import { RuleProps, RuleType } from 'screens/NewAlerts/types';
import { RequestResult } from 'types/Request';

import ServiceAlertsStatusList from './ServiceAlertsStatusList';
import { filterAPMAlertsByDistinctLabels } from './utils';

const ServiceAlertsStatusAPM = ({
  serviceName,
  serviceDistinctLabels,
  serviceHash,
  serviceLabels,
  setActiveSmartAlert,
  requestGrafanaAlertsRules,
  dataTestId,
}: {
  serviceName: string;
  serviceDistinctLabels: Record<string, string>;
  serviceHash: string;
  serviceLabels: Record<string, string>;
  setActiveSmartAlert: Dispatch<SetStateAction<RuleProps>>;
  requestGrafanaAlertsRules: RequestResult<RuleProps[], any>;
  dataTestId: string;
}): ReactElement => {
  const createNewAPMAlert = () => {
    const encodeRuleTypeURI = encodeURIComponent(
      JSON.stringify({ value: RuleType.APM }),
    );

    const apmAlertsURI = `#/alerts/create?ruleType=${encodeRuleTypeURI}&serviceHash=${serviceHash}`;
    window.open(apmAlertsURI, '_blank');
  };

  const apmServiceAlertsRules = useMemo(() => {
    if (!requestGrafanaAlertsRules.result) return { ok: [], alerting: [] };
    const filteredRules = filterAPMAlertsByDistinctLabels({
      rules: requestGrafanaAlertsRules.result,
      serviceLabels: serviceLabels,
      serviceName,
    });

    if (filteredRules.length === 0) {
      return { ok: [], alerting: [] };
    }

    const ok = filteredRules.filter((rule) => rule.status === 'ok');
    const alerting = filteredRules.filter((rule) => rule.status === 'alerting');

    return { ok, alerting };
  }, [requestGrafanaAlertsRules.result, serviceLabels, serviceName]);

  const apmStatusColor = useMemo(() => {
    const { alerting, ok } = apmServiceAlertsRules;
    if (alerting.length === 0 && ok.length === 0) return '';
    if (apmServiceAlertsRules.alerting.length) return `var(--ui-status-danger)`;
    return `var(--ui-status-success-contrast)`;
  }, [apmServiceAlertsRules]);

  return (
    <div
      className="service__header__left__advance-functions"
      data-testid={dataTestId}
    >
      <TooltipTrigger
        className="mr-1"
        tooltip={apmServiceAlertsRules.alerting.length > 0 ? 'Alerting' : 'OK'}
      >
        <FiTarget
          color={apmStatusColor}
          size={14}
          title="Enable Advance Functions"
        />
      </TooltipTrigger>
      <div className="service__header__left__slo-info__title">
        <div>APM Alerts</div>
      </div>
      {requestGrafanaAlertsRules.isLoading ? (
        <div className="spinner" />
      ) : (
        <>
          <PopoverTriggerV2
            popover={({ close }) => (
              <ServiceAlertsStatusList
                isASM={false}
                onRuleClick={(rule) => {
                  setActiveSmartAlert(rule);
                  close();
                }}
                rules={[
                  ...apmServiceAlertsRules.alerting,
                  ...apmServiceAlertsRules.ok,
                ]}
              />
            )}
            offsetY={2}
            offsetX={-70}
            position={PopoverPosition.BOTTOM_LEFT}
          >
            <div className="flex items-center">
              {apmServiceAlertsRules && apmServiceAlertsRules.ok.length > 0 && (
                <div
                  className="mr-2 cursor-pointer"
                  style={{ color: `var(--ui-status-success-contrast)` }}
                >
                  {apmServiceAlertsRules.ok.length} OK
                </div>
              )}
              {apmServiceAlertsRules &&
                apmServiceAlertsRules.alerting.length > 0 && (
                  <div
                    className="flex items-center"
                    style={{ color: `var(--ui-status-danger)` }}
                  >
                    {`${apmServiceAlertsRules.alerting.length} alerting`}
                  </div>
                )}
            </div>
          </PopoverTriggerV2>
          <PopoverTriggerV2
            popover={({ close }) => {
              return (
                <Button
                  className="w-full"
                  onClick={() => {
                    createNewAPMAlert();
                    close();
                  }}
                  variant={'default'}
                  size="sm"
                >
                  Create new alert
                </Button>
              );
            }}
            position={PopoverPosition.BOTTOM_LEFT}
            offsetX={20}
          >
            <TooltipTrigger
              className="service__header__left__advance-functions__menu"
              tooltip={'APM Alert Menu'}
            >
              <HiDotsVertical />
            </TooltipTrigger>
          </PopoverTriggerV2>
        </>
      )}
    </div>
  );
};

export default ServiceAlertsStatusAPM;
