import { DashboardPanelType, QueryDataProps } from 'types';
import { themeHexagonColorDark, themeHexagonColorLight } from '../colors';
import { combineInstantQueriesData } from '../MetricsQueryBuilder';
import { combineLogsChartDataInstant } from '../MetricsLogsQueryBuilder';

type InstantQueryDataProps = {
  data: Array<any>;
  isLoading: boolean;
  labels?: string[];
};

export const queryDataFormattingByGraphType = ({
  darkModeEnabled,
  formulas,
  graphType,
  queries,
  queryData,
}: {
  darkModeEnabled?: boolean;
  formulas: { queryKey: string; isActive: boolean }[];
  graphType: DashboardPanelType;
  queries: { queryKey: string; isActive: boolean }[];
  queryData: { [key: string]: InstantQueryDataProps };
}): InstantQueryDataProps => {
  Object.keys(queryData).forEach((key) => {
    if (
      queryData[key] &&
      queryData[key].data &&
      queryData[key].data.length > 0
    ) {
      const [_, id] = key.split('_');
      queryData[key].data = queryData[key].data.map((d) => {
        if (d.hasOwnProperty('getPromIndex')) return d;
        return {
          ...d,
          getPromIndex: () => Number(id),
        };
      });
    }
  });

  const combinedInstantData = combineInstantQueriesData({
    formulas,
    queries,
    queryData,
  });
  const { data, isLoading, labels } = combinedInstantData;
  if (graphType === DashboardPanelType.GRAFANA_POLYSTAT_PANEL) {
    if (!data || !Array.isArray(data)) {
      return { data: null, isLoading, labels: [] };
    }
    return hostmapDataFormatting({
      darkModeEnabled,
      queryData: combinedInstantData,
    });
  }

  if (graphType === DashboardPanelType.PIECHART) {
    return {
      data: { name: '', children: data, color: 'transparent' },
      isLoading,
      labels,
    };
  }

  return combinedInstantData;
};

export const queryDataFormattingByGraphForLogs = ({
  darkModeEnabled,
  formulas,
  graphType,
  queries,
  queryData,
}: {
  darkModeEnabled?: boolean;
  formulas: { queryKey: string; isActive: boolean }[];
  graphType: DashboardPanelType;
  queries: { queryKey: string; isActive: boolean }[];
  queryData: QueryDataProps;
}): InstantQueryDataProps => {
  const combinedLogsInstantData = combineLogsChartDataInstant(
    queryData,
    queries,
    formulas,
  );

  const { data, isLoading, labels } = combinedLogsInstantData;
  if (graphType === DashboardPanelType.GRAFANA_POLYSTAT_PANEL) {
    if (!data || !Array.isArray(data)) {
      return { data: null, isLoading, labels: [] };
    }
    return hostmapDataFormatting({
      darkModeEnabled,
      queryData: combinedLogsInstantData,
    });
  }

  if (graphType === DashboardPanelType.PIECHART) {
    return {
      data: { name: '', children: data, color: 'transparent' },
      isLoading,
      labels,
    };
  }

  return combinedLogsInstantData;
};

const hostmapDataFormatting = ({
  darkModeEnabled,
  queryData,
}: {
  darkModeEnabled?: boolean;
  queryData: InstantQueryDataProps;
}) => {
  const { data } = queryData;
  const hostmapColor = darkModeEnabled
    ? themeHexagonColorDark.fill
    : themeHexagonColorLight.fill;
  const borderColor = darkModeEnabled
    ? themeHexagonColorDark.border
    : themeHexagonColorLight.border;

  const outerBorderColor = darkModeEnabled
    ? themeHexagonColorDark.outline
    : themeHexagonColorLight.outline;

  const newData = data.map((d) => ({
    ...d,
    color: hostmapColor,
    borderColor,
    outerBorderColor,
  }));

  return { ...queryData, data: newData };
};
