import { usePortalContext } from 'components';
import React from 'react';

type Props = {
  header?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
  handleModalClose: () => void;
};

import { Dialog, DialogContent, DialogFooter, DialogHeader } from '../shadcn';

const Modal = ({ header, children, footer, handleModalClose }: Props) => {
  const portalContext = usePortalContext();
  return (
    <Dialog open onOpenChange={handleModalClose}>
      <DialogContent portalContainer={portalContext.portalRef.current}>
        {Boolean(header) && <DialogHeader>{header}</DialogHeader>}
        {Boolean(children) && children}

        {Boolean(footer) && <DialogFooter>{footer}</DialogFooter>}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
