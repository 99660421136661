import { useAuth, useRequest } from 'hooks';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import GroupManagementMembers from './GroupManagementMembers';
import GroupManagementEditSettings from './GroupManagementEditSettings';
import { getGroupById, getGroupMembersById } from './requests';

const GroupManagementEdit = ({
  auth,
}: {
  auth: ReturnType<typeof useAuth>;
}) => {
  const { groupId } = useParams();
  const groupRequest = useRequest(getGroupById);
  const groupMembersRequest = useRequest(getGroupMembersById);

  useEffect(() => {
    if (groupId === 'new') return;
    groupRequest.call({ groupId });
    groupMembersRequest.call({ groupId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  return (
    <div className="mx-auto max-w-[1200px] py-4">
      <div className="flex items-center justify-between pb-2">
        <h2 className="text-lg font-semibold">
          {groupId === 'new' ? 'Create' : 'Edit'} Group
        </h2>
      </div>
      <div className="rounded-sm border px-4 pb-4 pt-3">
        <GroupManagementEditSettings groupRequest={groupRequest} />
        {groupId !== 'new' && (
          <GroupManagementMembers
            groupMembersRequest={groupMembersRequest}
            user={auth.user}
          />
        )}
      </div>
    </div>
  );
};

export default GroupManagementEdit;
