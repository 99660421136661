import { fetchGrafanaApi } from 'requests';

import { GroupProps } from '../types';

const NUMBER_OF_USER_LIMIT = 3000;

const getGrafanaGroupList = async (): Promise<GroupProps[]> => {
  const result = await fetchGrafanaApi(
    `grafana/api/teams/search?perpage=${NUMBER_OF_USER_LIMIT}&page=0&accesscontrol=true&query=`,
  );

  return result?.teams || [];
};

export default getGrafanaGroupList;
