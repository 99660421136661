import { Icon, PopoverTriggerV2 } from 'components';
import React from 'react';
import { useRumReplayStateContext } from './RumReplayStateContext';
import RumReplayEventsItem from './RumReplayEventsItem';
import { RumEvent, Session } from './types';
import RumReplayEventsFilterPanel from './RumReplayEventsFilterPanel';

type Props = {
  events: RumEvent[];
  session: Session;
};

const RumReplayEvents = ({ session }: Props) => {
  const { actions, currentTimeState, isPlayingToggle, shownEvents } =
    useRumReplayStateContext();
  const [currentTime] = currentTimeState;

  const { startTimeUnixMs } = session;

  const onClickHandler = (event: RumEvent) => () => {
    const { seekTo } = actions;
    seekTo({
      ms: event.time - startTimeUnixMs,
      shouldPlay: isPlayingToggle.value,
    });
  };

  return (
    <div className="rum-replay__events">
      <div className="rum-replay__events__toolbar">
        <div className="rum-replay__events__toolbar__left">
          <PopoverTriggerV2 popover={() => <RumReplayEventsFilterPanel />}>
            <div className="button button--short">
              <Icon className="button__icon" icon="filter" />
              <span>Filter Events</span>
            </div>
          </PopoverTriggerV2>
        </div>
      </div>
      <div className="rum-replay__events__list">
        {shownEvents.map((event, i) => {
          const isLast = i === shownEvents.length - 1;
          const nextEvent =
            i + 1 < shownEvents.length ? shownEvents[i + 1] : null;
          const eventMs = event.time - startTimeUnixMs;
          const nextEventMs = nextEvent ? nextEvent.time - startTimeUnixMs : 0;

          const isHighlighted =
            currentTime >= eventMs &&
            (isLast || (nextEvent && currentTime < nextEventMs));

          const onClick = onClickHandler(event);

          return (
            <RumReplayEventsItem
              event={event}
              eventMs={eventMs}
              key={i}
              isHighlighted={isHighlighted}
              onClick={onClick}
            />
          );
        })}
      </div>
    </div>
  );
};

export default RumReplayEvents;
