import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { dateTimeFormat, delimiter } from 'kfuse-constants';
import { transformInstantQueryToTable } from 'requests';
import { findUnitCategoryFormatById } from 'utils';
import {
  PrometheusDataset,
  DashboardPanelTableTransformProps,
  DashboardPanelOverrideProps,
} from 'types';

import { DashboardTableInitProps } from '../types';

const SUPPORTED_TRANSFORMS = [
  'formatTime',
  'organize',
  'seriesToColumns',
  'seriesToRows',
  'joinByField',
  'sortBy',
  'extractFields',
  'groupingToMatrix',
  'merge',
];

const getColumnsFromObject = (data: { [key: string]: any }[]): string[] => {
  // get all columns from data
  const cols = data.reduce((acc, curr) => {
    Object.keys(curr).forEach((key) => {
      if (!acc.includes(key)) {
        acc.push(key);
      }
    });
    return acc;
  }, [] as string[]);

  // rename time and value column to Time and Value
  return cols.map((col) => {
    if (col === 'time') {
      return 'Time';
    }

    if (col === 'value') {
      return 'Value';
    }

    return col;
  });
};

const getAllUniqueKeysFromArrays = (arr: Array<{ [key: string]: any }>) => {
  const keysBitmap: { [key: string]: boolean } = {};
  arr.forEach((item) => {
    if (!item) return;
    Object.keys(item).forEach((key) => {
      const type = typeof item[key];
      if (type === 'object' || type === 'function') return;
      keysBitmap[key] = true;
    });
  });

  return Object.keys(keysBitmap);
};

const getDefaultColumns = (arr: { [key: string]: any }[]) => {
  const colsKeys = getAllUniqueKeysFromArrays(arr);
  const cols = colsKeys.map((key) => {
    return { key: key, label: key };
  });

  // keep key time as first column in table
  // key value as last column in table
  const timeCol = cols.find((col) => col.key === 'time');
  if (timeCol) {
    timeCol.label = 'Time';
  }
  const valueCol = cols.find((col) => col.key === 'value');
  if (valueCol) {
    valueCol.label = 'Value';
  }

  const otherCols = cols.filter(
    (col) => col.key !== 'time' && col.key !== 'value',
  );
  if (timeCol && valueCol) {
    return [timeCol, ...otherCols, valueCol];
  }

  return otherCols;
};

export const hideTableColumn = (
  excludeByName: { [key: string]: boolean },
  columns: string[],
): string[] => {
  if (excludeByName) {
    columns = columns.filter((col) => {
      if (!excludeByName[col]) {
        return true;
      }

      if (excludeByName[col] === false) {
        return true;
      }

      return false;
    });

    Object.keys(excludeByName).forEach((item) => {
      const isExisted = columns.includes(item);
      if (!isExisted && excludeByName[item] === false) {
        columns.push(item);
      }
    });
  }

  return columns;
};

export const sortTableColumn = (
  indexByName: { [key: string]: number },
  columns: string[],
): string[] => {
  if (indexByName) {
    columns.sort((a, b) => {
      const indexA = isNil(indexByName[a]) ? columns.length : indexByName[a];
      const indexB = isNil(indexByName[b]) ? columns.length : indexByName[b];

      return indexA - indexB;
    });
  }

  return columns;
};

export const renameTableColumn = (
  renameByName: { [key: string]: string },
  columns: string[],
): Array<{ key: string; label: string }> => {
  if (renameByName) {
    const renameCols: Array<{ key: string; label: string }> = [];
    columns.map((col) => {
      if (!col || !renameByName[col]) return;
      renameCols.push({ key: col, label: renameByName[col] });
    });

    Object.keys(renameByName).forEach((item) => {
      if (!item) return;
      if (!renameByName[item]) return;

      const isExisted = renameCols.find((col) => col.key === item);
      const [key, num] = item.split(' ');

      if (!isExisted && key && !isNaN(Number(num))) {
        renameCols.push({ key: item, label: renameByName[item] });
      }
    });

    return renameCols;
  }

  return columns.map((col) => ({ key: col, label: col }));
};

const renameTableColumnByRegex = (
  columns: Array<{ key: string; label: string }>,
  options: { regex: string; renamePattern: string },
) => {
  const { regex, renamePattern } = options;
  const regexPattern = new RegExp(regex);
  const colIndex = columns.findIndex((col) => col.key === regex);
  if (colIndex === -1) return columns;

  const newColumns = [...columns];
  newColumns[colIndex].label = newColumns[colIndex].label.replace(
    regexPattern,
    renamePattern,
  );
  return newColumns;
};

const filterFieldsByName = (
  columns: string[],
  options: {
    include: { names: string[] };
    exclude: { names: string[] };
  },
): Array<{ key: string; label: string }> => {
  const { include, exclude } = options;

  const newColumns = [];
  columns.map((col) => {
    if (include && include.names.includes(col)) {
      newColumns.push({ key: col, label: col });
    }
  });

  return newColumns;
};

const getTableColumnsByOrganize = (options: {
  excludeByName: { [key: string]: boolean };
  includeByName: { names: string[] };
  indexByName: { [key: string]: number };
  renameByName: { [key: string]: string };
}): Array<{ key: string; label: string }> => {
  if (!options || typeof options !== 'object') {
    return [];
  }
  const columns: Array<{ key: string; label: string }> = [];
  const excludeByName = options.excludeByName || {};
  const renameByName = options.renameByName || {};
  const renameByNameKeys = Object.keys(options.renameByName || {});

  const unionExcludeAndRename: string[] = [];
  renameByNameKeys.forEach((key) => {
    if (!renameByName[key]) return;
    if (excludeByName[key] === true) return;
    if (excludeByName[key] === false) {
      unionExcludeAndRename.push(key);
      return;
    }

    unionExcludeAndRename.push(key);
  });
  const newIndexByName = { ...options.indexByName };
  const isValueExist = unionExcludeAndRename.find((s) => s === 'Value');
  if (isValueExist) {
    newIndexByName.Value = null;
  }

  const sortedColumns = sortTableColumn(newIndexByName, unionExcludeAndRename);
  sortedColumns.forEach((key) => {
    columns.push({ key, label: options.renameByName[key] });
  });

  return columns;
};

const includeUnitToColumn = (
  columns: { key: string; label: string }[],
  columnUnits: { unit: string; decimals: number }[],
) => {
  if (!columnUnits || columnUnits.length === 0) return columns;
  return columns.map((col, colIndex) => {
    return {
      ...col,
      renderCell: ({ row }) => {
        const value = row[col.key];
        const { unit, decimals } = columnUnits[colIndex];
        if (!value || !unit) return value;
        if (isNaN(Number(value))) return value;
        if (Number(value) === 0) return value;

        const unitFormat = findUnitCategoryFormatById(unit);

        if (unitFormat) {
          const formatted = unitFormat.fn(Number(value), decimals);

          if (!formatted) return value;
          return `${formatted.prefix || ''}${formatted.text}${
            formatted.suffix || ''
          }`;
        }
        return value;
      },
    };
  });
};

export const getTableColumns = ({
  data,
  overrides,
  transformations,
  unit,
}: {
  data: Array<{ [key: string]: any }>;
  overrides?: DashboardPanelOverrideProps[];
  transformations: DashboardPanelTableTransformProps[];
  unit?: string;
}): Array<{ key: string; label: string }> => {
  if (!data || !data[0]) return getDefaultColumns([{}]);

  const defaultColumns = getDefaultColumns(data);
  const columnUnits = Array.from({ length: defaultColumns.length }, () => ({
    unit: unit,
    decimals: undefined,
  }));

  overrides?.forEach(({ matcher, properties }) => {
    if (!matcher || !properties) return;
    if (matcher.id === 'byName') {
      const colIndex = defaultColumns.findIndex(
        (col) => col.label === matcher.options,
      );
      if (colIndex === -1) return;
      const overideUnit = properties.find((p) => p.id === 'unit');

      if (overideUnit) {
        columnUnits[colIndex] = {
          unit: overideUnit.value,
          decimals: overideUnit.decimals,
        };
      }
    }
  });
  return includeUnitToColumn(defaultColumns, columnUnits);
};

export const organizeTableData = (
  activePromqlQueryRefId: string[] = [],
  data: PrometheusDataset[][],
  overrides: DashboardPanelOverrideProps[],
  transformations: DashboardPanelTableTransformProps[],
): Array<{ [key: string]: any }> => {
  if (!Array.isArray(data)) return [];

  const isSomeTransformSupported = transformations?.some((t) =>
    SUPPORTED_TRANSFORMS.some((id) => t.id === id),
  );
  // if no transformations or no supported transformations, return data as is
  if (!transformations || !isSomeTransformSupported) {
    const flattenedDatasets = data.reduce(
      (arr: any, dataum: any) => [...arr, ...dataum],
      [],
    );
    return transformInstantQueryToTable(flattenedDatasets);
  }

  let initTableData = initTableTransformations(data, activePromqlQueryRefId);
  let initColumns: Array<{ key: string; label: string }> = [];
  transformations.forEach(({ id, options, disabled }) => {
    if (disabled) return;
    if (id === 'formatTime') {
      initTableData = formatTableTimestamp(initTableData, options);
    }

    if (id === 'joinByField') {
      initTableData = joinByField(initTableData, options);
    }

    if (id === 'merge') {
      initTableData = mergeSeriesData(activePromqlQueryRefId, data);
    }

    if (id === 'organize') {
      const byFieldColumn = transformations.find(
        (d) => d.id === 'seriesToColumns',
      );
      const organizedData = organizeTableDataWithColumn(
        initTableData,
        options,
        byFieldColumn?.options?.byField,
      );
      initTableData = organizedData.data;
      initColumns = organizedData.columns;
    }

    if (id === 'seriesToColumns') {
      initTableData = convertSeriesToColumnsRaw(
        activePromqlQueryRefId,
        data,
        options,
      );
    }

    if (id === 'seriesToRows') {
      initTableData = convertSeriesToRows(initTableData);
    }

    if (id === 'extractFields') {
      initTableData = extractFields(initTableData, options);
    }

    if (id === 'groupingToMatrix') {
      initTableData = groupingToMatrix(initTableData, options);
    }

    if (id === 'reduce') {
      if (options?.mode === 'seriesToRows') {
        initTableData = convertSeriesToRows(initTableData);
      }
    }
    if (id === 'sortBy') {
      initTableData = sortByTableColumn(initTableData, options);
    }

    if (id === 'calculateField') {
      initTableData = calculateField(initTableData, options);
    }
  });

  if (overrides && Array.isArray(overrides)) {
    initTableData = overrideColumnsData(initTableData, initColumns, overrides);
  }

  const isFormatTime = transformations.some((t) => t.id === 'formatTime');
  if (!isFormatTime) {
    initTableData = formatTableTimestamp(initTableData, {
      outputFormat: dateTimeFormat,
      timeField: 'Time',
      useTimezone: false,
    });
  }

  return initTableData;
};

const initTableTransformations = (
  data: PrometheusDataset[][],
  activePromqlQueryRefId: string[] = [],
) => {
  if (!Array.isArray(data) || !Array.isArray(activePromqlQueryRefId)) {
    return [];
  }
  const initData: DashboardTableInitProps[] = [];
  data.forEach((datum, promIndex) => {
    if (!datum) return;
    const refIdAndValue = `Value #${activePromqlQueryRefId[promIndex]}`;
    if (Array.isArray(datum)) {
      datum.forEach((promData) => {
        initData.push({
          [refIdAndValue]: promData?.value?.[1],
          Value: promData?.value?.[1],
          Time: promData?.value?.[0],
          ...(promData?.metric || {}),
        });
      });
      return;
    }

    if (typeof datum === 'object') {
      const { metric, values } = datum as PrometheusDataset;
      if (Array.isArray(values)) {
        values.forEach((promData) => {
          initData.push({
            [refIdAndValue]: promData?.[1],
            Value: promData?.[1],
            Time: promData?.[0],
            ...(metric || {}),
          });
        });
      }
    }
  });
  return initData;
};

const mergeSeriesData = (
  activePromqlQueryRefId: string[] = [],
  data: PrometheusDataset[][],
): DashboardTableInitProps[] => {
  if (!Array.isArray(data)) return [];
  if (!Array.isArray(activePromqlQueryRefId)) return [];

  const allLabelBitmap: { [key: string]: Record<string, boolean> } = {};
  data.map((dataset, idx) => {
    const refId = activePromqlQueryRefId[idx];
    allLabelBitmap[refId] = {};
    dataset.map(({ metric }) => {
      const metricKeys = Object.keys(metric).sort();
      metricKeys.map((k) => {
        allLabelBitmap[refId][k] = true;
      });
    });
  });

  const commonLabelBitmap: Record<string, number> = {};
  Object.keys(allLabelBitmap).map((refId) => {
    Object.keys(allLabelBitmap[refId]).map((key) => {
      commonLabelBitmap[key] = commonLabelBitmap[key] || 0;
      commonLabelBitmap[key] += 1;
    });
  });

  const commonLabelKeys = Object.keys(commonLabelBitmap).filter((key) => {
    return commonLabelBitmap[key] === activePromqlQueryRefId.length;
  });

  const labelKeysBitmap: { [key: string]: Record<string, unknown> } = {};
  data.map((dataset, idx) => {
    const refId = activePromqlQueryRefId[idx];
    const refIdAndValue = `Value #${refId}`;

    if (dataset.length === 0) {
      // placeholder for empty dataset
      Object.keys(labelKeysBitmap).map((key) => {
        labelKeysBitmap[key][refIdAndValue] = undefined;
      });
      return;
    }

    dataset.map(({ metric, value }) => {
      const uniqueLabelKeys = commonLabelKeys
        .map((k) => `${k}:${metric[k]}`)
        .join(',');
      const uniqueLabelKeysWithTime = `${uniqueLabelKeys}${delimiter}${value[0]}`;

      if (!labelKeysBitmap[uniqueLabelKeysWithTime]) {
        labelKeysBitmap[uniqueLabelKeysWithTime] = {
          ...metric,
          [refIdAndValue]: value[1],
          Time: value[0],
        };
      } else {
        labelKeysBitmap[uniqueLabelKeysWithTime] = {
          ...labelKeysBitmap[uniqueLabelKeysWithTime],
          [refIdAndValue]: value[1],
          Time: value[0],
        };
      }
    });
  });

  return Object.values(labelKeysBitmap);
};

const convertSeriesToColumnsRaw = (
  activePromqlQueryRefId: string[] = [],
  data: PrometheusDataset[][],
  options: { byField: string },
): DashboardTableInitProps[] => {
  if (!Array.isArray(data)) return [];
  if (!Array.isArray(activePromqlQueryRefId)) return [];

  const labelsBitmap: { [key: string]: { [key: string]: string } } = {};
  const uniqueLabels: { [key: string]: boolean } = {};

  activePromqlQueryRefId.map((refId, idx) => {
    const refData = data[idx];
    if (!refData) return;
    refData.map((rData) => {
      const labelKeys = Object.keys(rData.metric);
      labelKeys.map((labelKey) => {
        if (!labelsBitmap[refId]) {
          labelsBitmap[refId] = {};
        }
        labelsBitmap[refId][labelKey] = labelKey;
        uniqueLabels[labelKey] = true;
      });
    });
  });

  Object.keys(uniqueLabels).map((l) => {
    const filteredExistedLabel = activePromqlQueryRefId.filter((refId) => {
      return Boolean(labelsBitmap[refId][l]);
    });
    if (l === options.byField) return;
    if (filteredExistedLabel.length === 1) {
      activePromqlQueryRefId.map((refId) => {
        labelsBitmap[refId][l] = l;
      });
      return;
    }

    activePromqlQueryRefId.map((refId, idx) => {
      if (!labelsBitmap[refId][l]) return;
      const usedLabel = activePromqlQueryRefId
        .slice(0, idx + 1)
        .filter((refId) => {
          return labelsBitmap[refId][l];
        }).length;

      labelsBitmap[refId][l] = `${l} ${usedLabel}`;
    });
  });
  const seriesIntialData: DashboardTableInitProps[] = [];

  activePromqlQueryRefId.map((refId, idx) => {
    const refData = data[idx];
    if (!refData) return;
    refData.map((rData) => {
      const labelKeys = labelsBitmap[refId];
      const labels: { [key: string]: any } = {};
      Object.keys(labelKeys).map((l) => {
        if (!rData.metric[l]) return;
        labels[labelKeys[l]] = rData.metric[l];
      });

      const refIdAndValue = `Value #${refId}`;
      seriesIntialData.push({
        [refIdAndValue]: rData.value[1],
        Time: rData.value[0],
        ...labels,
      });
    });
  });

  const convertedSeriesToCols = convertSeriesToColumns(
    seriesIntialData,
    options,
  );

  return convertedSeriesToCols;
};

const convertSeriesToColumns = (
  data: DashboardTableInitProps[],
  options: { byField: string },
): DashboardTableInitProps[] => {
  if (!Array.isArray(data)) {
    return [];
  }

  if (!options || !options.byField) {
    return data;
  }

  const { byField } = options;
  const dataBitmaps: { [key: string]: { [key: string]: any } } = {};

  data.forEach((datum) => {
    if (!datum || !datum.hasOwnProperty(byField)) return;
    const key = datum[byField];
    if (!dataBitmaps[key]) {
      dataBitmaps[key] = {};
    }
    // merge all data with same key
    Object.assign(dataBitmaps[key], datum);
  });

  const dataKeys = Object.keys(dataBitmaps);
  const transformedData: DashboardTableInitProps[] = dataKeys.map((key) => {
    const transformedDatum = dataBitmaps[key];
    transformedDatum[byField] = key;
    return transformedDatum;
  });

  return transformedData;
};

const convertSeriesToRows = (
  data: DashboardTableInitProps[],
): DashboardTableInitProps[] => {
  if (!Array.isArray(data)) {
    return [];
  }

  const rowsBitmap: { [key: string]: boolean } = {};
  data.forEach((datum) => {
    if (!datum) return;
    Object.keys(datum).forEach((key) => {
      rowsBitmap[key] = true;
    });
  });
  if (rowsBitmap['Time']) delete rowsBitmap['Time'];
  if (rowsBitmap['Value']) delete rowsBitmap['Value'];
  const rows = Object.keys(rowsBitmap);
  const transformedData: DashboardTableInitProps[] = [];
  data.forEach((datum) => {
    if (!datum) return;
    const newRow: DashboardTableInitProps = {};
    rows.map((row) => {
      if (!datum.hasOwnProperty(row)) return;
      newRow['Metric'] = row;
      newRow['Value'] = datum[row];
      newRow['Time'] = datum['Time'];
    });
    transformedData.push(newRow);
  });

  return transformedData;
};

const overrideColumnsData = (
  data: DashboardTableInitProps[],
  columns: Array<{ key: string; label: string }>,
  overrides: DashboardPanelOverrideProps[],
) => {
  if (!Array.isArray(data)) {
    return [];
  }

  if (!Array.isArray(columns) || !Array.isArray(overrides)) {
    return data;
  }

  const newData = [...data];
  newData.forEach((datum) => {
    overrides.forEach(({ matcher, properties }) => {
      if (
        typeof matcher !== 'object' ||
        !Array.isArray(properties) ||
        typeof properties[0] !== 'object'
      ) {
        return;
      }
      const { id, options } = matcher;
      if (id === 'byName') {
        const col = columns.find((col) => col.label === options);

        if (col) {
          const value = datum[col.key];
          const { id, value: overrideValue } = properties[0];
          if (id === 'unit' && overrideValue && value) {
            const unitFormat = findUnitCategoryFormatById(overrideValue);

            if (unitFormat) {
              const { prefix, text, suffix } = unitFormat.fn(value || 0);
              datum[col.key] = `${prefix || ''}${text}${suffix || ''}`;
            }
          }
        }
      }
    });
  });

  return newData;
};

const formatTableTimestamp = (
  data: DashboardTableInitProps[],
  options: { outputFormat: string; timeField: string; useTimezone: boolean },
) => {
  if (!Array.isArray(data)) {
    return [];
  }

  const { outputFormat, timeField, useTimezone } = options;
  if (
    typeof outputFormat !== 'string' ||
    typeof timeField !== 'string' ||
    typeof useTimezone !== 'boolean'
  ) {
    return data;
  }

  const newTimestampData = data.map((datum) => {
    if (!datum || !datum.hasOwnProperty(timeField)) return datum;
    const timestamp = datum[timeField as keyof DashboardTableInitProps];
    const newTimestamp = dayjs.unix(Number(timestamp));
    if (!newTimestamp.isValid()) return datum;
    return {
      ...datum,
      [timeField]: useTimezone
        ? newTimestamp.format(outputFormat)
        : newTimestamp.utc().format(outputFormat),
    };
  });

  return newTimestampData;
};

const joinByField = (
  data: DashboardTableInitProps[],
  options: { byField: string; mode: 'outer' | 'inner' },
): DashboardTableInitProps[] => {
  if (!Array.isArray(data)) return [];
  if (!options) return data;

  const { byField, mode } = options;
  if (typeof byField !== 'string' || (mode !== 'outer' && mode !== 'inner')) {
    return data;
  }

  const dataBitmaps: { [key: string]: any } = {};
  data.forEach((row) => {
    if (!row[byField]) return;
    const key = row[byField];
    if (!dataBitmaps[key]) {
      dataBitmaps[key] = [];
    }
    dataBitmaps[key].push(row);
  });

  // Get all keys for 'outer' join, or keys from first table for 'inner' join
  const keys =
    mode === 'outer'
      ? Array.from(new Set(Object.keys(dataBitmaps)))
      : Object.keys(dataBitmaps);

  // Join tables
  const joinedData: DashboardTableInitProps[] = keys.map((key) => {
    const joinedRow: { [key: string]: any } = {};
    dataBitmaps[key].forEach((row) => {
      Object.assign(joinedRow, row);
    });
    return joinedRow;
  });

  return joinedData;
};

const sortByTableColumn = (
  data: DashboardTableInitProps[],
  options: { sort: Array<{ field: string; desc: boolean }> },
): DashboardTableInitProps[] => {
  if (!Array.isArray(data)) {
    return [];
  }

  if (!options || !Array.isArray(options.sort)) {
    return data;
  }

  const { sort } = options;
  if (!sort || sort.length == 0) return data;
  const sortedData = data.sort((a, b) => {
    for (let i = 0; i < sort.length; i++) {
      const { field, desc } = sort[i];
      if (!field) return 0;
      if (!a[field] || !b[field]) return 0;

      const aVal = a[field];
      const bVal = b[field];
      if (aVal === bVal) continue;
      if (aVal < bVal) return desc ? 1 : -1;
      return desc ? -1 : 1;
    }
    return 0;
  });

  return sortedData;
};

const organizeTableDataWithColumn = (
  data: DashboardTableInitProps[],
  options: {
    excludeByName: any;
    includeByName: any;
    indexByName: any;
    renameByName: any;
  },
  byField: string,
): {
  data: DashboardTableInitProps[];
  columns: Array<{ key: string; label: string }>;
} => {
  if (!Array.isArray(data)) {
    return { data: [], columns: [] };
  }
  const columns = getTableColumnsByOrganize(options);
  if (!columns) return { data, columns: [] };

  const newColumns = organizeTableAddLabelColumn(data, columns, options);
  if (!newColumns) return { data, columns };

  if (byField) {
    const byFieldIndex = newColumns.findIndex((col) => col.key === byField);
    if (byFieldIndex > -1) {
      const byFieldColumn = newColumns.splice(byFieldIndex, 1)[0];
      newColumns.unshift(byFieldColumn);
    }
  }

  const newData = data.map((datum) => {
    const newDatum: { [key: string]: string } = {};
    newColumns.forEach((col) => {
      if (!datum || !datum.hasOwnProperty(col.key)) return;
      newDatum[col.label] = datum[col.key];
    });

    return newDatum;
  });
  return { data: newData, columns: newColumns };
};

const organizeTableAddLabelColumn = (
  data: DashboardTableInitProps[] = [],
  columns: Array<{ key: string; label: string }> = [],
  options?: { excludeByName: any; renameByName: any },
) => {
  if (!Array.isArray(data) || !Array.isArray(columns)) {
    return [];
  }

  const columnsFromData = getColumnsFromObject(data);
  if (!Array.isArray(columnsFromData)) {
    return columns;
  }

  const colWithData = columns.filter((col) =>
    columnsFromData.includes(col.key),
  );

  if (!options?.excludeByName && !options?.renameByName) {
    columnsFromData.forEach((col) => {
      // Discard columns with Value # prefix
      const isValueWithHash = col.includes('Value #');
      if (isValueWithHash) return;
      colWithData.push({ key: col, label: col });
    });

    // Move Time column to first
    const timeIndex = colWithData.findIndex((col) => col.key === 'Time');
    if (timeIndex !== -1) {
      const timeCol = colWithData.splice(timeIndex, 1);
      colWithData.unshift(timeCol[0]);
    }
    // Move Value column to last
    const valueIndex = colWithData.findIndex((col) => col.key === 'Value');
    if (valueIndex !== -1) {
      const valueCol = colWithData.splice(valueIndex, 1);
      colWithData.push(valueCol[0]);
    }
    return colWithData;
  }

  columnsFromData.forEach((col) => {
    const isAdded = colWithData.find((c) => c.key === col);
    if (isAdded) return;

    // Exclude columns by name
    if (options?.excludeByName && options.excludeByName[col] === true) return;

    // Exclude columns by Value
    if (col === 'Value') {
      return;
    }

    // Rename columns by name
    if (options?.renameByName && options.renameByName[col]) {
      colWithData.push({ key: col, label: options.renameByName[col] });
      return;
    }

    colWithData.push({ key: col, label: col });
  });

  return colWithData;
};

const extractFields = (
  data: DashboardTableInitProps[],
  options: { source: string },
): DashboardTableInitProps[] => {
  if (!Array.isArray(data)) {
    return [];
  }

  if (!options || !options.source) {
    return data;
  }

  const { source } = options;
  const extractedData = data.map((datum) => {
    if (!datum || !datum.hasOwnProperty(source)) return datum;
    const extractedValue = extractValueFromString(datum[source]);
    const newDatum = { ...datum };
    delete newDatum[source];
    Object.keys(extractedValue).forEach((key) => {
      if (!newDatum[key]) {
        newDatum[key] = extractedValue[key];
        return;
      }

      if (newDatum[key]) {
        const oldKeyValue = newDatum[key];
        delete newDatum[key];
        newDatum[`${key} 1`] = oldKeyValue;
        newDatum[`${key} 2`] = extractedValue[key];
      }
    });
    return newDatum;
  });

  return extractedData;
};

/**
 * Extract value from string
 * @param str
 * 1. find JSON in the string
 */
const extractValueFromString = (str: string) => {
  if (typeof str !== 'string') return str;
  const json = str.match(/\{.*\}/);
  const keyPairMap: { [key: string]: string } = {};
  if (json) {
    const parsed = convertStringToJSON(json[0]);
    if (parsed) {
      Object.keys(parsed).forEach((key) => {
        keyPairMap[key] = parsed[key];
      });
    }
    // remove json from string
    str = str.replace(json[0], '');
  }

  const split = str.split(' ');
  split.forEach((s) => {
    keyPairMap[s] = '';
  });
  return keyPairMap;
};

const convertStringToJSON = (
  str: string,
): {
  [key: string]: string;
} => {
  // Replace curly braces with spaces, if present at the start and end of the string
  const trimmedInput = str.replace(/^\{|\}$/g, '');

  // Surround keys and values with double quotes
  const quotedString = trimmedInput.replace(/(\w+):([^,}]+)/g, '"$1":"$2"');

  // Re-add the curly braces to form a valid JSON string
  const jsonString = `{${quotedString}}`;

  try {
    // Parse the string into a JSON object
    const jsonObject = JSON.parse(jsonString);
    return jsonObject;
  } catch (error) {
    return null;
  }
};

const groupingToMatrix = (
  data: DashboardTableInitProps[],
  options: { columnField: string; rowField: string; valueField: string },
) => {
  if (!Array.isArray(data)) {
    return [];
  }

  if (
    !options ||
    !options.columnField ||
    !options.rowField ||
    !options.valueField
  ) {
    return data;
  }

  const { columnField, rowField, valueField } = options;
  const matrixData: { [key: string]: { [key: string]: any } } = {};

  data.forEach((datum) => {
    if (!datum) return;
    const row = datum[rowField];
    if (!row) return;
    const col = datum[columnField];
    const value = datum[valueField];
    if (!matrixData[row]) {
      matrixData[row] = {};
    }
    matrixData[row][col] = value;
  });

  const keyColumnName = `${rowField}\\${columnField}`;
  const matrixDataArray = Object.keys(matrixData).map((row) => {
    const rowData = matrixData[row];
    const newRow: { [key: string]: any } = {};
    Object.keys(rowData).forEach((col) => {
      newRow[keyColumnName] = row;
      newRow[col] = rowData[col];
    });
    return newRow;
  });
  return matrixDataArray;
};

const calculateField = (
  data: DashboardTableInitProps[],
  options: {
    alias: string;
    mode: string;
    reduce: { include: string[]; reducer: string };
  },
) => {
  if (!Array.isArray(data)) {
    return [];
  }

  if (!options || !options.alias || !options.reduce) {
    return data;
  }
  const { alias, reduce } = options;
  const { include } = reduce;

  const calculateFieldData = data.map((datum) => {
    if (!datum) return;
    let aliasValue = undefined;
    include.forEach((key) => {
      if (!datum[key]) return;
      aliasValue = datum[key];
    });

    return { [alias]: aliasValue, ...datum };
  });

  return calculateFieldData;
};
