import dayjs from 'dayjs';
import { silenceMatcherOperator, Operator } from 'utils/query-operator';
import { SilencedInstanceProps, SilencesProps } from './types';

const DEFAULT_SCHEDULE_DURATION = 3600;

export const getSilenceOperator = (
  isEqual: boolean,
  isRegex: boolean,
): string => {
  if (isEqual === false && isRegex === false) {
    return Operator.NotEqual;
  }
  if (isEqual === true && isRegex === false) {
    return Operator.Equal;
  }
  if (isEqual === false && isRegex === true) {
    return Operator.NotRegex;
  }
  return Operator.Regex;
};

export const getIsEqualAndIsRegex = (
  operator: string,
): { isEqual: boolean; isRegex: boolean } => {
  if (operator === Operator.Equal) {
    return { isEqual: true, isRegex: false };
  }

  if (operator === Operator.NotRegex) {
    return { isEqual: false, isRegex: true };
  }

  if (operator === Operator.Regex) {
    return { isEqual: true, isRegex: true };
  }

  return { isEqual: false, isRegex: false }; // !=
};

export const calcScheduleDuration = (
  startsAt: string,
  endsAt: string,
): number => {
  const startAtDate = dayjs(startsAt);
  const endAtDate = dayjs(endsAt);
  return endAtDate.diff(startAtDate, 'seconds');
};

export const getSilenceMatcherOperatorOptions = () => {
  return Object.keys(silenceMatcherOperator).map((key) => ({
    label: key,
    value: key,
  }));
};

export const filterSilencedInstances = ({
  silencedInstances,
  silences,
}: {
  silencedInstances: SilencedInstanceProps[];
  silences: SilencesProps[];
}): SilencesProps[] => {
  if (!silencedInstances && !silences) return silences;
  if (!silencedInstances) return silences;

  const instanceMap = new Map<string, SilencedInstanceProps[]>();
  silencedInstances?.forEach((instance) => {
    const silenceIds = instance.status.silencedBy;
    silenceIds.forEach((id) => {
      if (!instanceMap.has(id)) {
        instanceMap.set(id, []);
      }
      instanceMap.get(id)?.push(instance);
    });
  });

  return silences?.map((silence) => ({
    ...silence,
    silencedInstance: instanceMap.get(silence.id) || [],
  }));
};

export const getSilenceMatcherString = (silence: SilencesProps): string[] => {
  const matcherString: string[] = [];
  silence.matchers.forEach((matcher) => {
    const operator = getSilenceOperator(matcher.isEqual, matcher.isRegex);
    matcherString.push(`${matcher.name}${operator}${matcher.value}`);
  });
  return matcherString;
};

export const getDefaultSilences = (): SilencesProps => {
  const startsAt = dayjs();
  const endsAt = dayjs().add(DEFAULT_SCHEDULE_DURATION, 'seconds');
  return {
    comment: '',
    createdBy: 'admin',
    duration: DEFAULT_SCHEDULE_DURATION,
    endsAt: endsAt.toISOString(),
    id: '',
    matchers: [{ isEqual: true, isRegex: false, name: '', value: '' }],
    startsAt: startsAt.toISOString(),
    status: { state: 'active' },
    updatedAt: startsAt.toISOString(),
  };
};
