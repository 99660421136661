import { Button, InputWithValidatorV3, useToaster } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RequestResult } from 'types/Request';

import { createNewGroup, updateGroupById } from './requests';
import { GroupProps } from './types';

const GroupManagementEditSettings = ({
  groupRequest,
}: {
  groupRequest: RequestResult<GroupProps, any>;
}): ReactElement => {
  const navigate = useNavigate();
  const { addToast } = useToaster();
  const { groupId } = useParams();
  const updateGroupRequest = useRequest(updateGroupById);
  const createGroupRequest = useRequest(createNewGroup);
  const group = groupRequest.result;

  const [groupDetails, setGroupDetails] = useState<{
    name: string;
    email: string;
    policy?: string;
  }>({ name: '', email: '', policy: '' });

  const onUpdateGroup = () => {
    if (!groupDetails.name) {
      addToast({ text: 'Name is required', status: 'error' });
      return;
    }
    updateGroupRequest
      .call({ groupId, name: groupDetails.name, email: groupDetails.email })
      .then((res) => {
        if (res) {
          addToast({ text: 'Group updated successfully', status: 'success' });
          groupRequest.call({ groupId });
        }
      });
  };

  const onCreateGroup = () => {
    if (!groupDetails.name) {
      addToast({ text: 'Name is required', status: 'error' });
      return;
    }
    createGroupRequest
      .call({ name: groupDetails.name, email: groupDetails.email })
      .then((res) => {
        if (res) {
          addToast({ text: 'Group created successfully', status: 'success' });
          navigate(`/admin/groups/${res.groupId}`);
        }
      });
  };

  useEffect(() => {
    if (group) {
      setGroupDetails({ name: group.name, email: group.email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  return (
    <div className="mb-6">
      <h1 className="text-lg font-semibold">Settings</h1>
      <div className="admin__row-muted flex items-center gap-2 rounded-sm p-2">
        {updateGroupRequest.isLoading || createGroupRequest.isLoading ? (
          <div className="flex h-7 w-full items-center justify-center">
            <div className="spinner"></div>
          </div>
        ) : (
          <>
            <label className="flex flex-col gap-1">
              <span>
                Name (<span className="text-red-500">*</span>)
              </span>
              <InputWithValidatorV3
                disabled={groupId !== 'new'}
                name="name"
                onChange={(val) =>
                  setGroupDetails((prev) => ({ ...prev, name: val }))
                }
                placeholder="Please enter group name"
                size="5"
                type="text"
                value={groupDetails.name}
                variant="default"
              />
            </label>
            <label className="flex flex-col gap-1">
              <span>Email</span>
              <InputWithValidatorV3
                name="email"
                onChange={(val) =>
                  setGroupDetails((prev) => ({ ...prev, email: val }))
                }
                placeholder="Please enter group email"
                size="5"
                type="text"
                value={groupDetails.email}
                variant="default"
              />
            </label>
            <div className="flex self-end pb-0.5">
              <Button
                className="ml-auto"
                size="sm"
                variant="default"
                onClick={groupId === 'new' ? onCreateGroup : onUpdateGroup}
              >
                {groupId === 'new' ? 'Create' : 'Update'}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GroupManagementEditSettings;
