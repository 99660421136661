type Point = { x: number; y: number };

const calculateIntersectionOfTwoLine = ({
  p1,
  p2,
}: {
  p1: { start: Point; end: Point };
  p2: { start: Point; end: Point };
}): Point | null => {
  // Calculate the slope (m) of the first line
  const m1 = (p1.start.y - p1.end.y) / (p1.start.x - p1.end.x);
  // Calculate the slope (m) of the second line
  const m2 = (p2.start.y - p2.end.y) / (p2.start.x - p2.end.x);

  // Calculate the y-intercept (b) of the first line
  const b1 = p1.start.y - m1 * p1.start.x;
  // Calculate the y-intercept (b) of the second line
  const b2 = p2.start.y - m2 * p2.start.x;

  // Check if the slopes are equal
  if (m1 === m2) {
    // If the y-intercepts are also equal, the lines are coincident (overlap completely)
    if (b1 === b2) {
      console.warn('Lines are coincident');
    } else {
      // If only the slopes are equal, the lines are parallel and do not intersect
      console.warn('Lines are parallel');
    }
    return null; // No intersection point
  }

  // Calculate the x-coordinate of the intersection point
  const x = (b2 - b1) / (m1 - m2);
  // Calculate the y-coordinate of the intersection point using the equation of the first line
  const y = m1 * x + b1;
  // Return the intersection point
  return { x, y };
};

export default calculateIntersectionOfTwoLine;
