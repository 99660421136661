import classNames from 'classnames';
import { useToggle, useTracesState } from 'hooks';
import React, { useMemo } from 'react';
import ServiceSummaryGrid from 'screens/Service/ServiceSummaryGrid';
import {
  ChartGridKeysState,
  SelectedFacetValuesByName,
  Span,
  TraceMetrics,
} from 'types';
import { CursorStateProvider, TooltipTrigger } from '..';

type Props = {
  colorsByServiceName: { [key: string]: string };
  chartGridKeysState: ChartGridKeysState;
  span: Span;
  spans: Span[];
  traceMetrics: TraceMetrics;
  tracesState: ReturnType<typeof useTracesState>;
};

const TraceSidebarActiveSpanMetrics = ({
  chartGridKeysState,
  colorsByServiceName,
  span,
  spans,
  tracesState,
}: Props) => {
  const serviceOrEndpointToggle = useToggle(true);

  const isInternalSpan =
    span.attributes['span_service_entry'] === 'false' &&
    span.attributes['span_kind'] !== 'SPAN_KIND_CLIENT';

  const childSpan = useMemo(
    () => spans.find((s) => s.parentSpanId === span.spanId),
    [span, spans],
  );

  const isExternalSpan = span.attributes['span_kind'] === 'SPAN_KIND_CLIENT';

  const serviceHashToUse =
    span?.attributes?.kf_database_service_hash || span.service.hash;

  const serviceSummaryGridFilter = useMemo(() => {
    const filter: SelectedFacetValuesByName = {};

    filter['kf_source'] = { apm: 1 };
    if (!serviceOrEndpointToggle.value) {
      if (isExternalSpan) {
        if (childSpan) {
          filter['service_name'] = { [childSpan.serviceName]: 1 };
          filter['span_name'] = { [childSpan.name]: 1 };
        } else {
          filter['span_name'] = { [span.name]: 1 };
        }
      } else {
        filter['span_name'] = { [span.name]: 1 };
      }
    }

    return filter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childSpan, span.spanId, serviceOrEndpointToggle.value]);

  return (
    <div className="trace-sidebar__active-span__body__metrics  pb-4 pt-3">
      <div className="button-group button-group--short mb-3">
        <button
          className={classNames({
            'button-group__item': true,
            'button-group__item--active': serviceOrEndpointToggle.value,
          })}
          onClick={() => !isInternalSpan && serviceOrEndpointToggle.toggle()}
        >
          Service
        </button>
        <TooltipTrigger
          className={classNames({
            'button-group__item': true,
            'button-group__item--active': !serviceOrEndpointToggle.value,
          })}
          tooltip={
            isInternalSpan ? 'Endpoint is disabled for internal spans' : ''
          }
        >
          <button
            onClick={serviceOrEndpointToggle.toggle}
            disabled={isInternalSpan}
          >
            Endpoint
          </button>
        </TooltipTrigger>
      </div>
      <CursorStateProvider>
        <ServiceSummaryGrid
          chartGridKeysState={chartGridKeysState}
          colorsByServiceName={colorsByServiceName}
          date={tracesState.dateState[0]}
          hideExecutionBreakDown={!serviceOrEndpointToggle.value}
          key={span.spanId}
          labelsToSumBy={['service_name', ...Object.keys(span.service.labels)]}
          selectedFacetValuesByName={serviceSummaryGridFilter}
          setDate={tracesState.dateState[1]}
          service={span.service.name}
          serviceDistinctLabels={span.service.distinctLabels || {}}
          serviceHash={serviceHashToUse}
          shouldUseClientServiceName={isExternalSpan}
        />
      </CursorStateProvider>
    </div>
  );
};

export default TraceSidebarActiveSpanMetrics;
