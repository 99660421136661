const extractParamsFromURL = (): URLSearchParams => {
  const url = new URL(window.location.href);
  const firstQuestionMark = url.hash.indexOf('?');
  const urlSearchParams = new URLSearchParams(
    firstQuestionMark > -1
      ? url.hash.substring(firstQuestionMark + 1, url.hash.length)
      : '',
  );
  return urlSearchParams;
};

export default extractParamsFromURL;
