import {
  Tabs,
  Tab,
  useTabs,
  ChartGridV2,
  CursorStateProvider,
  TraceSidebar,
  ErrorMessage,
} from 'components';
import {
  useColorsByServiceName,
  useSelectedFacetValuesByNameState,
  useTracesState,
} from 'hooks';
import React, { useState, useMemo } from 'react';
import { DateSelection, FilterMethodByType, Trace } from 'types';
import ServerlessRightSidebarConfiguration from './ServerlessRightSidebarConfiguration';
import ServerlessRightSidebarFilters from './ServerlessRightSidebarFilters';
import ServerlessRightSidebarLogs from './ServerlessRightSidebarLogs';
import ServerlessRightSidebarMetrics from './ServerlessRightSidebarMetrics';
import { queryRangeStep } from '../utils';
import TracesTable from 'screens/Traces/TracesTable';
import { useEventsState } from 'screens/Events/hooks';
import EventsExplorerTable from 'screens/Events/EventsExplorer/EventsExplorerTable';
import { getQueryForServerlessMetric } from '../hooks/utils';
import { multipleQueryRangeWithLabels } from 'utils/chartGrid';
import { useTracesPageStateContext } from 'context/PageStateProvider';

type Args = {
  colorsByFunctionName: { [key: string]: string };
  configurationByFunctionName: { [key: string]: any };
  date: DateSelection;
  functionArn: string;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
  serverlessMetrics: string[];
};

const getRows = ({
  colorsByFunctionName,
  date,
  functionArn,
  serverlessMetrics,
}: Args) => {
  const options = serverlessMetrics.map((metric) => ({
    label: metric.replace('aws_lambda_', '').replace(/_/g, ' '),
    value: metric,
  }));

  const colorMap = colorsByFunctionName;
  const step = `${queryRangeStep(date)}s`;

  return [
    [
      {
        initialKey: 'aws_lambda_invocations',
        charts: options.map((option) => ({
          key: option.value,
          chartType: 'line',
          colorMap,
          label: option.label,
          legendTableColumns: ['key', 'min', 'max', 'avg'],
          libraryType: 'uplot',
          onSelection: () => {},
          query: multipleQueryRangeWithLabels(
            [
              () =>
                getQueryForServerlessMetric({
                  functionArn,
                  param: option.value,
                  step,
                }),
            ],
            [[functionArn]],
          ),
          tooltipProps: { layoutType: 'drawer' },
        })),
      },
      {
        initialKey: 'aws_lambda_errors',
        charts: options.map((option) => ({
          key: option.value,
          chartType: 'line',
          colorMap,
          label: option.label,
          legendTableColumns: ['key', 'min', 'max', 'avg'],
          libraryType: 'uplot',
          onSelection: () => {},
          query: multipleQueryRangeWithLabels(
            [
              () =>
                getQueryForServerlessMetric({
                  functionArn,
                  param: option.value,
                  step,
                }),
            ],
            [[functionArn]],
          ),
          tooltipProps: { layoutType: 'drawer' },
        })),
      },
      {
        initialKey: 'aws_lambda_duration',
        charts: options.map((option) => ({
          key: option.value,
          chartType: 'line',
          colorMap,
          label: option.label,
          legendTableColumns: ['key', 'min', 'max', 'avg'],
          libraryType: 'uplot',
          onSelection: () => {},
          query: multipleQueryRangeWithLabels(
            [
              () =>
                getQueryForServerlessMetric({
                  functionArn,
                  param: option.value,
                  step,
                }),
            ],
            [[functionArn]],
          ),
          tooltipProps: { layoutType: 'drawer' },
        })),
      },
    ],
  ];
};

type Props = {
  colorsByFunctionName: { [key: string]: string };
  configurationByFunctionName: { [key: string]: any };
  date: DateSelection;
  activeServerlessFunction: Record<string, string>;
  sidebarFilterState: FilterMethodByType<'map'>;
  serverlessMetrics: string[];
  error: {
    getServerlessMetrics: { message: string };
  };
};

const ServerlessRightSidebar = ({
  colorsByFunctionName,
  configurationByFunctionName,
  date,
  activeServerlessFunction,
  sidebarFilterState,
  serverlessMetrics,
  error,
}: Props) => {
  const tracesPageState = useTracesPageStateContext();
  const { chartGridKeysState } = tracesPageState;
  const functionName = activeServerlessFunction?.name;
  const functionArn = activeServerlessFunction?.functionArn;
  const colorsByServiceName = useColorsByServiceName(date);

  const rows = useMemo(() => {
    return getRows({
      colorsByFunctionName,
      date,
      functionArn,
      serverlessMetrics,
    });
  }, [colorsByFunctionName, date, serverlessMetrics, functionArn]);

  const tabs = useTabs();

  const eventsState = useEventsState({ urlKeysToSync: [] });

  const [activeTrace, setActiveTrace] = useState<Trace>(null);
  const tracesState = useTracesState({
    shouldWriteToUrl: false,
  });

  const deploymentTabFilterByFacets = useMemo(() => {
    return {
      source_type_name: {
        aws: 1,
      },
      event_type: {
        'aws.lambda': 1,
      },
      resources_0_ARN: {
        [activeServerlessFunction.functionArn]: 1,
      },
      eventName: {
        Invoke: 1,
      },
    };
  }, [activeServerlessFunction]);

  const eventTabFilterByFacets = useMemo(() => {
    return {
      source_type_name: {
        aws: 1,
      },
      event_type: {
        'aws.lambda': 1,
      },
      resources_0_ARN: {
        [activeServerlessFunction.functionArn]: 1,
      },
    };
  }, [activeServerlessFunction]);

  const invocationFilterByFacets = useMemo(() => {
    return {
      'service.name': {
        'aws.lambda': 1,
      },
      function_arn: {
        [activeServerlessFunction.functionArn]: 1,
      },
    };
  }, [activeServerlessFunction]);

  return (
    <div>
      <ErrorMessage
        message={error?.getServerlessMetrics?.message}
        className="justify-start pl-[24px]"
      />
      <ServerlessRightSidebarFilters
        date={date}
        functionName={functionName}
        sidebarFilterState={sidebarFilterState}
      />
      <div className="serverless__right-sidebar__section">
        <ChartGridV2.ChartGrid date={date} rows={rows} />
      </div>
      <div className="serverless__right-sidebar__section">
        <Tabs
          className="serverless__right-sidebar__tabs tabs--underline"
          tabs={tabs}
        >
          <Tab label="Invocations">
            <TracesTable
              colorsByServiceName={colorsByServiceName}
              liveTail={{
                isEnabled: false,
                isPlaying: false,
                items: [],
              }}
              setActiveTrace={setActiveTrace}
              tracesState={tracesState}
              additionalFilterByFacets={invocationFilterByFacets}
            />
          </Tab>
          <Tab label="Logs">
            <ServerlessRightSidebarLogs
              date={date}
              functionArn={activeServerlessFunction?.functionArn}
            />
          </Tab>
          <Tab label="Metrics">
            <ServerlessRightSidebarMetrics
              colorsByFunctionName={colorsByFunctionName}
              date={date}
              functionArn={activeServerlessFunction?.functionArn}
              serverlessMetrics={serverlessMetrics}
            />
          </Tab>
          <Tab label="Deployments">
            <EventsExplorerTable
              eventsState={eventsState}
              additionalFilterByFacets={deploymentTabFilterByFacets}
            />
          </Tab>
          <Tab label="Configuration">
            <ServerlessRightSidebarConfiguration
              configurationByFunctionName={configurationByFunctionName}
              functionName={functionName}
            />
          </Tab>
        </Tabs>
      </div>
      {activeTrace ? (
        <TraceSidebar
          // This page will have it's own page state on day, but grabbing this from traces for now
          chartGridKeysState={chartGridKeysState}
          close={() => setActiveTrace(null)}
          colorsByServiceName={{}}
          key={activeTrace.span.spanId}
          trace={activeTrace}
          tracesState={tracesState}
        />
      ) : null}
    </div>
  );
};

export default ServerlessRightSidebar;
