import React, { ReactElement } from 'react';
import serverlessLabelValues from 'screens/Serverless/requests/serverlesslabelValues';
import { ServerlessPageProps } from 'screens/Serverless/types';
import { SelectedFacetValuesByName } from 'types';
import ServerlessSidebarFacetGroup from './ServerlessSidebarFacetGroup';

const ServerlessSidebar = ({
  serverlessState,
}: ServerlessPageProps): ReactElement => {
  const { date, filterState, getFacetValueCountsRequest } = serverlessState;

  const sidebarFilterState = filterState.getMethodAndStateByFilterKey(
    'sidebarFilters',
    'map',
  );

  const requestByLabelName = (name: string) => {
    return () =>
      serverlessLabelValues({
        name,
        date,
        selectedFacetValuesByName:
          (filterState?.filter?.sidebarFilters as SelectedFacetValuesByName) ||
          {},
      }).then((result) =>
        result.map(({ aggrVal, groupVal }) => {
          const value = groupVal[name];
          const returnVal = {
            count: aggrVal.function_arn,
            value: value || '',
          };
          return returnVal;
        }),
      );
  };

  const facetNames = getFacetValueCountsRequest?.result || [];
  return (
    <div className="serverless__sidebar">
      <ServerlessSidebarFacetGroup
        component="Core"
        date={date}
        forceExpanded
        facetNames={facetNames}
        filterState={filterState}
        sidebarFilterState={sidebarFilterState}
        request={requestByLabelName}
      />
    </div>
  );
};

export default ServerlessSidebar;
