import {
  Button,
  LeftSidebar,
  ShowSidebarTooltipButton,
  useLeftSidebarState,
} from 'components';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSLOState } from './hooks';
import { SLOSidebar } from './SLOSidebar';
import { SLOList } from './SLOList';
import { getColorsByServiceHash } from 'utils';
import useSLOQueryTemplates from './hooks/useSLOQueryTemplates';
import { Service } from 'types/generated';
import useRequest from 'hooks/useRequest';
import { SelectedFacetValuesByName } from 'types/selectedFacets';
import getServices from 'requests/getServices';
import dayjs from 'dayjs';

const SLOs = (): ReactElement => {
  const navigate = useNavigate();
  const sloState = useSLOState();
  const leftSidebarState = useLeftSidebarState('services');
  const { selectedFacetValuesByNameState, sloList, loadSLOList } = sloState;
  const { queryTemplates, error } = useSLOQueryTemplates();
  const getServicesRequest = useRequest(getServices, true, true);

  useEffect(() => {
    if (queryTemplates.queryTemplatesFetched) {
      loadSLOList(queryTemplates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryTemplates.queryTemplatesFetched]);

  const navigateToCreateSLO = () => {
    const selectedTelemetryObj =
      selectedFacetValuesByNameState.state['telemetrytype'] || {};
    const selectedTelemetryList = Object.keys(selectedTelemetryObj);
    const selectedTelemetry = selectedTelemetryList.map((key) =>
      selectedTelemetryObj[key] ? key : false,
    );
    const selectedTelemetryStr =
      selectedTelemetry.filter(Boolean).join('|') || 'apm';
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('sloType', 'availability');
    urlSearchParams.set('kf_source', selectedTelemetryStr);
    navigate(`/slo/create?${urlSearchParams.toString()}`);
  };

  const serviceByHash = useMemo(
    () =>
      (getServicesRequest.result || []).reduce(
        (obj, service) => ({ ...obj, [service.hash]: service }),
        {},
      ),
    [getServicesRequest.result],
  );

  const colorsByServiceHash = useMemo(
    () =>
      getColorsByServiceHash((getServicesRequest.result || []) as Service[]),
    [getServicesRequest.result],
  );

  useEffect(() => {
    const selectedFacetValuesByName = {
      span_type: { db: 0 },
    };

    getServicesRequest.call({
      date: {
        startTimeUnix: dayjs().subtract(24, 'hours').unix(),
        endTimeUnix: dayjs().unix(),
      },
      selectedFacetValuesByName:
        selectedFacetValuesByName as SelectedFacetValuesByName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="slos">
      <LeftSidebar
        className="slos__left-sidebar"
        leftSidebarState={leftSidebarState}
      >
        <SLOSidebar
          colorsByServiceHash={colorsByServiceHash}
          serviceByHash={serviceByHash}
          sloState={sloState}
        />
      </LeftSidebar>
      <div className="slos__main">
        <div className="slos__header">
          <div className="slos__header__left">
            {leftSidebarState.width === 0 ? (
              <ShowSidebarTooltipButton onClick={leftSidebarState.show} />
            ) : null}
            <div className="dashboard__list__title">SLOs</div>
          </div>
          <div className="slos__header__right">
            <Button variant="default" size="sm" onClick={navigateToCreateSLO}>
              Create New Slo
            </Button>
          </div>
        </div>
        <SLOList
          colorsByServiceHash={colorsByServiceHash}
          serviceByHash={serviceByHash}
          sloState={sloState}
          queryTemplates={queryTemplates}
          sloError={error}
        />
      </div>
    </div>
  );
};

export default SLOs;
