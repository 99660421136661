import React, { ReactElement } from 'react';
import * as Shadcn from '../shadcn';

type Props = {
  checked?: boolean;
  className?: string;
  onChange?: (checked: boolean) => void;
  onClick?: VoidFunction;
  value: string;
  variant?: 'default' | 'compact';
};

const Radio = ({
  checked,
  onChange,
  onClick,
  value,
  variant = 'default',
}: Props): ReactElement => {
  const onChangeHandler = (e: React.SyntheticEvent) => {
    if (onChange) {
      onChange(e.currentTarget.checked);
    }
  };

  const onClickHandler = (e) => {
    if (onClick) {
      e.stopPropagation();
      onClick();
    }
  };

  return (
    <Shadcn.RadioGroup defaultValue={checked ? value : null} variant={variant}>
      <Shadcn.RadioGroupItem
        checked={checked}
        onChange={onChangeHandler}
        onClick={onClickHandler}
        value={value}
        variant={variant}
      />
    </Shadcn.RadioGroup>
  );
};

export default Radio;
