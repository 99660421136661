import { FunctionParamsProps } from 'types';

const SEASONALITY_OPTIONS = [
  { label: 'hourly', value: '1' },
  { label: 'daily', value: '2' },
];

export const agileRobustSeasonalParams: FunctionParamsProps[] = [
  {
    name: 'seasonality',
    default: '1',
    options: SEASONALITY_OPTIONS,
    value: '1',
    type: 'select',
  },
];

export const getForecastSeasonalParamsForKfuseQl =
  (): FunctionParamsProps[] => {
    return [
      {
        name: 'seasonality',
        default: '1',
        value: '1',
        options: SEASONALITY_OPTIONS,
        type: 'select',
      },
    ];
  };
