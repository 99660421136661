import { GroupProps } from '../GroupManagement/types';
import { UserProps } from '../UserManagement/types';
import { FolderPermissionCategory } from '../FolderManagement/types';

export const PermissionCategoryOptions = [
  { value: FolderPermissionCategory.USER, label: 'User' },
  { value: FolderPermissionCategory.TEAM, label: 'Group' },
  { value: FolderPermissionCategory.ROLE, label: 'Role' },
];

export const getPermissionCategoryOptions = (type: 'folder' | 'group') => {
  if (type === 'folder') {
    return PermissionCategoryOptions;
  }
  return [{ value: FolderPermissionCategory.USER, label: 'User' }];
};

export const PermissionRolesOptions = [
  { value: 'Viewer', label: 'Viewer' },
  { value: 'Editor', label: 'Editor' },
  { value: 'Admin', label: 'Admin' },
];

export const getFilteredUserListOptions = (
  userList: UserProps[],
  type: 'folder' | 'group',
) => {
  return userList.map((user) => ({
    value: type === 'folder' ? user.gid : user.id,
    label: user.email,
    avatarUrl: user.avatarUrl,
    id: type === 'folder' ? user.gid : user.id,
  }));
};

export const getFilteredGroupListOptions = (groupList: GroupProps[]) => {
  return groupList.map((group) => ({
    value: group.id,
    label: group.name,
    avatarUrl: group.avatarUrl,
    id: group.id,
  }));
};
