import { tooltipFormatter } from 'components/Timeseries/utils';
import React, { ReactElement, useEffect, useRef } from 'react';
import Sunburst from 'sunburst-chart';
import { SunburstDataProps } from 'types';

import { getParentData } from './utils';

const SunburstGraph = ({
  data,
  height,
  width,
  unit = 'number',
}: {
  data: SunburstDataProps;
  height: number;
  width: number;
  unit?: string;
}): ReactElement => {
  const sunburstRef = useRef<HTMLDivElement>(null);
  const sunburstInstanceRef = useRef(null);

  function create() {
    if (!sunburstInstanceRef.current) {
      const chart = Sunburst()
        .data(data)
        .size('size')
        .height(height)
        .width(width)
        .color('color')
        .strokeColor('none')
        .radiusScaleExponent(1.5)
        .transitionDuration(0)
        .tooltipTitle(() => '')
        .tooltipContent((node) => {
          const tooltipData = getParentData(node);
          const lastData = tooltipData[tooltipData.length - 1];
          if (!lastData) return '';

          const renderTooltipItem = () => {
            let tooltipHtml = '';
            tooltipData.map((d, i) => {
              tooltipHtml += `
              <div class="sunburst__tooltip__item" >
                <div class="sunburst__tooltip__item__name">${d.name}</div>
              </div>
            `;
            });

            return tooltipHtml;
          };

          return `
          <div class="sunburst__tooltip">
            ${renderTooltipItem()}
            <div class="sunburst__tooltip__value">
              <div class="sunburst__tooltip__row__size">${tooltipFormatter(
                lastData.value,
                unit,
              )}</div>
            </div>
          </div>
        `;
        })(sunburstRef.current);

      sunburstInstanceRef.current = chart;
    } else {
      sunburstInstanceRef.current.data(data).height(height).width(width);
    }
  }

  useEffect(() => {
    create();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, height, width]);

  useEffect(() => {
    const currentRef = sunburstRef?.current;
    return () => {
      if (currentRef) {
        currentRef.innerHTML = '';
        if (
          sunburstInstanceRef?.current &&
          typeof sunburstInstanceRef?.current?.destroy === 'function'
        ) {
          sunburstInstanceRef.current.destroy();
        }
      }
    };
  }, []);

  return <div className="sunburst" ref={sunburstRef}></div>;
};

export default SunburstGraph;
