import classNames from 'classnames';
import { Geomap, TreemapGraph } from 'components';
import React, { ReactElement, useMemo } from 'react';
import { DashboardPanelComponentProps, DashboardPanelType } from 'types';

import { useDashboardDataLoader } from '../hooks';

const DashboardPanelChartInstant = ({
  baseWidth,
  dashboardState,
  dashboardTemplateState,
  isInView,
  nestedIndex,
  panel,
  panelSize,
  panelIndex,
}: DashboardPanelComponentProps): ReactElement => {
  const { templateValues } = dashboardTemplateState;

  const dashboardDataLoader = useDashboardDataLoader({
    baseWidth,
    dashboardState,
    dashboardTemplateState,
    isInView,
    nestedIndex,
    panelIndex,
    templateValues,
    type: panel.type,
  });

  const treemapData = useMemo(() => {
    return {
      name: '',
      children: dashboardDataLoader.result?.data || [],
      color: 'transparent',
    };
  }, [dashboardDataLoader.result?.data]);

  return (
    <div
      className={classNames({
        'dashboard-panel__body__treemap':
          panel.type === DashboardPanelType.TREEMAP ||
          panel.type === DashboardPanelType.GEOMAP,
      })}
    >
      {panel.type === DashboardPanelType.TREEMAP && (
        <TreemapGraph
          data={treemapData}
          height={panelSize.heightContainer}
          width={panelSize.width}
        />
      )}
      {panel.type === DashboardPanelType.GEOMAP && (
        <Geomap
          data={dashboardDataLoader.result?.data || []}
          height={panelSize.heightContainer}
          width={panelSize.width}
        />
      )}
    </div>
  );
};

export default DashboardPanelChartInstant;
