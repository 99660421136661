import { QueryCombinedStatusProps, QueryDataProps } from 'types/QueryData';

const getCombinedQueryDataTimestamps = ({
  combineTimestamp = false,
  queryData,
  queries,
  formulas,
}: {
  combineTimestamp?: boolean;
  formulas: QueryCombinedStatusProps[];
  queryData: QueryDataProps;
  queries: QueryCombinedStatusProps[];
}): {
  timestamps: number[];
  timestampsWithIndex: { [key: string]: number };
} => {
  const chartKeys = Object.keys(queryData);
  const timestampsBitmask: { [key: string]: boolean } = {};

  chartKeys.forEach((key, chartIdx) => {
    const [type, queryKey] = key.split('_');
    let query = null;
    if (type === 'query') {
      query = queries.find((q) => q.queryKey === queryKey);
    }
    if (type === 'formula') {
      query = formulas.find((f) => f.queryKey === queryKey);
    }
    if (!query || !query.isActive || !queryData[key]?.range?.data) return;

    const { data } = queryData[key].range;
    if (!data || !data[0]) return;

    if (
      !combineTimestamp &&
      chartIdx > 0 &&
      Object.keys(timestampsBitmask).length !== 0
    ) {
      return;
    }

    const firstData = data[0];
    firstData.forEach((t) => {
      timestampsBitmask[t] = true;
    });
  });

  const timestamps = Object.keys(timestampsBitmask).sort();
  const timestampsWithIndex: { [key: string]: number } = {};
  timestamps.forEach((timestamp, index) => {
    timestampsWithIndex[timestamp] = index;
  });

  return { timestamps: timestamps.map(Number), timestampsWithIndex };
};

export default getCombinedQueryDataTimestamps;
