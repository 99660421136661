import React from 'react';

import { Table, TableRow } from 'components';
import KubernetesRightSideBar from '../KubernetesRightSideBar';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellTimestamp,
  KubeTableCell,
  KubeTableEntityTags,
} from './Components';
import { generatePath, useParams } from 'react-router-dom';
import KubernetesTableGroup from './KubernetesTableGroup';
import {
  useEntitiesData,
  useEntitiesGroupData,
  useMergedGroupByFilter,
} from './useEntitiesData';

const renderEmptyCell = () => '';

function KubernetesTableForServiceRow({ row }) {
  const { date, entitiesType, navigate } = useKubernetesController();

  const facets = useMergedGroupByFilter(row.groupBys);
  const [rows] = useEntitiesData({
    entitiesType,
    kubesSort: null,
    facets,
    page: 0,
    limit: 50,
    date,
  });

  const setActiveKube = (row) => {
    navigate(({ location, params, pathTemplate }) => {
      return {
        ...location,

        pathname: generatePath(pathTemplate, {
          ...params,

          activeKube: row.service.metadata.uid,
          tab: 'yaml',
        }),
      };
    });
  };

  const activeKubeParam = useParams()['activeKube'];
  const activeKube = (
    typeof activeKubeParam === 'string' && rows ? rows : []
  ).find((row) => {
    return row.service.metadata.uid === activeKubeParam;
  });

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {activeKube && <KubernetesRightSideBar activeKube={activeKube} />}
      <Table
        className="-my-px"
        renderHeader={false}
        rows={rows ?? []}
        renderRow={(props) => (
          <TableRow
            {...props}
            className="border-y"
            onRowClickHandler={(row) => () => setActiveKube(row)}
          />
        )}
        columns={[
          { key: 'header', label: '' },
          {
            key: 'kube_service',
            label: 'Service',
            renderCell: (prop: any) => {
              const serviceName = prop?.row.service?.metadata?.name;
              return (
                <KubeTableCell {...prop} tooltipText={serviceName}>
                  {serviceName}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'metadata.creationTimestamp',
            label: 'Age',
            renderCell: (prop: any) => {
              const timestamp =
                prop?.row?.service?.metadata?.creationTimestamp || '';
              return <KubeCellTimestamp {...prop} timestamp={timestamp} />;
            },
          },
          {
            key: 'spec.type',
            label: 'Type',
            renderCell: (prop: any) => {
              return (
                <KubeTableCell {...prop} align="left">
                  {prop?.row?.service?.spec?.type}
                </KubeTableCell>
              );
            },
          },

          {
            key: 'spec.clusterIp',
            label: 'Cluster IP',
            renderCell: (prop: any) => {
              return (
                <KubeTableCell {...prop} align="left">
                  {prop?.row?.service?.spec?.clusterIp}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'spec.externalIPs',
            label: 'External IPS',
            renderCell: (prop: any) => {
              return (
                <KubeTableCell {...prop} align="left">
                  {prop?.row?.service?.spec.externalIPs
                    ? prop?.row?.service?.spec.externalIPs
                    : '-'}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'headerPorts',
            label: 'Ports',
            renderCell: (prop: any) => {
              const ports = prop?.row?.service?.spec?.ports;

              if (!ports) return <>-</>;
              return (
                <KubeTableCell {...prop} align="left">
                  {ports[0]?.name || ports[0]?.port + '/' + ports[0]?.protocol}
                </KubeTableCell>
              );
            },
          },
        ]}
      />
    </div>
  );
}

export default function KubernetesTableForServiceGroup() {
  const { date, entitiesType, facets, groupBySearchTerms } =
    useKubernetesController();

  const [rows] = useEntitiesGroupData({
    entitiesType,
    date,
    facets,
    groupBySearchTerms,
  });

  return (
    <KubernetesTableGroup
      rows={rows ?? []}
      columns={[
        {
          key: 'headerkube_service',
          label: 'Service Group',
          renderCell: (prop: any) => (
            <KubeTableEntityTags
              {...prop}
              selectedFacetValuesByNameState={facets}
            />
          ),
        },
        {
          key: 'headerage',
          label: 'Age',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerkube_service_type',
          label: 'Type',
          renderCell: renderEmptyCell,
        },

        {
          key: 'headerclusterIp',
          label: 'Cluster IP',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerexternalIPS',
          label: 'External IPS',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerports',
          label: 'Ports',
          renderCell: renderEmptyCell,
        },
      ]}
      renderExpandedRow={(props) => <KubernetesTableForServiceRow {...props} />}
    />
  );
}
