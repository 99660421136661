import { TableColumnType } from 'components/Table';
import { DashboardPanelOverrideProps } from 'types/Dashboard';
import { findUnitCategoryFormatById } from 'utils/valueFormats';
import { TableTransformerData } from '../types';

const overrideColumnsData = ({
  data,
  overrides,
}: {
  data: TableTransformerData;
  overrides: DashboardPanelOverrideProps[];
}): TableTransformerData => {
  const { data: tableData, columns } = data;
  if (!Array.isArray(tableData)) {
    return data;
  }

  if (!Array.isArray(columns) || !Array.isArray(overrides)) {
    return data;
  }

  overrides.forEach(({ matcher, properties }) => {
    if (
      typeof matcher !== 'object' ||
      !Array.isArray(properties) ||
      typeof properties[0] !== 'object'
    ) {
      return;
    }
    const { id, options } = matcher;
    if (id === 'byName') {
      const colIndex = columns.findIndex((col) => col.key === options);
      if (colIndex < 0) return;
      const col = columns[colIndex];
      const { id, value: overrideValue, decimals } = properties[0];
      if (id === 'unit' && overrideValue) {
        col.renderCell = ({ row }) =>
          getRenderCellWithUnit({
            colKey: col.key,
            row,
            unit: overrideValue,
            decimals,
          });
        col.type = TableColumnType.NUMBER;
      }
    }
  });

  return { data: tableData, columns };
};

export const getRenderCellWithUnit = ({
  colKey,
  decimals,
  row,
  unit,
}: {
  colKey: string;
  decimals?: number;
  row: TableTransformerData['data'][number];
  unit: string;
}): string | number => {
  const value = row[colKey];
  if (!value || !unit) return value;
  if (isNaN(Number(value))) return value;
  if (Number(value) === 0) return value;

  const unitFormat = findUnitCategoryFormatById(unit);

  if (unitFormat) {
    const formatted = unitFormat.fn(Number(value), decimals);

    if (!formatted) return value;
    return `${formatted.prefix || ''}${formatted.text}${
      formatted.suffix || ''
    }`;
  }
  return value;
};

export default overrideColumnsData;
