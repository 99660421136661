import { useCallback } from 'react';
import { ChipProps } from './types';
import { onBlurHandler, onChangeHandler, onKeydownHandler } from './utils';

type ChipHandlers = {
  handlePropertyChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOperatorChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    operatorRef: React.MutableRefObject<HTMLInputElement>,
  ) => void;
  handleValueChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    valueIndex: number,
  ) => void;
  handleOperatorBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleValueBlur: (
    event: React.FocusEvent<HTMLInputElement>,
    valueIndex: number,
  ) => void;
  handleValueKeydown: (
    event: React.KeyboardEvent<HTMLInputElement>,
    valueIndex: number,
  ) => void;
};

const useChipHandlers = (props: ChipProps): ChipHandlers => {
  const handlePropertyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeHandler.property(props)(event);
    },
    [props],
  );

  const handleOperatorChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      operatorRef: React.MutableRefObject<HTMLInputElement>,
    ) => {
      onChangeHandler.operator({
        ...props,
        operatorRef,
      })(event);
    },
    [props],
  );

  const handleValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, valueIndex: number) => {
      onChangeHandler.value({
        ...props,
        valueIndex,
      })(event);
    },
    [props],
  );

  const handleOperatorBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      onBlurHandler.operator(props)(event);
    },
    [props],
  );

  const handleValueBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>, valueIndex: number) => {
      onBlurHandler.value({ ...props, valueIndex })(event);
    },
    [props],
  );

  const handleValueKeydown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>, valueIndex: number) => {
      onKeydownHandler.value({ ...props, valueIndex })(event);
    },
    [props],
  );

  return {
    handlePropertyChange,
    handleOperatorChange,
    handleValueChange,
    handleOperatorBlur,
    handleValueBlur,
    handleValueKeydown,
  };
};

export default useChipHandlers;
