import { RuleProps, RuleType } from 'screens/NewAlerts/types';

export const filterAPMAlertsByDistinctLabels = ({
  rules,
  serviceLabels,
  serviceName,
}: {
  rules: RuleProps[];
  serviceLabels: Record<string, string>;
  serviceName: string;
}): RuleProps[] => {
  const filteredAPMRules: RuleProps[] = [];

  rules.forEach((rule) => {
    if (!rule.annotations && rule.annotations?.ruleType !== RuleType.APM)
      return;

    const parsedExtraData = JSON.parse(rule.annotations?.extraData || '{}');
    if (parsedExtraData.serviceName !== serviceName) return;

    const ruleUniqueLabels = parsedExtraData.uniqueLabels || {};
    // compare two objects and check if they are equal or not
    const isMatch = Object.keys(serviceLabels).every(
      (key) => serviceLabels[key] === ruleUniqueLabels[key],
    );
    if (!isMatch) return;

    filteredAPMRules.push(rule);
  });

  return filteredAPMRules;
};
