import React from 'react';

import { Box } from 'components/layouts/Box';
import { Table, TableRow } from 'components';
import KubernetesRightSideBar from '../KubernetesRightSideBar';
import { useDateState } from 'hooks';
import { EntityTypes } from '../types';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellStatus,
  KubeCellTimestamp,
  KubeTableCell,
  KubeTableEntityTags,
} from './Components';
import { generatePath, useParams } from 'react-router-dom';
import KubernetesTableGroup from './KubernetesTableGroup';
import {
  useEntitiesData,
  useEntitiesGroupData,
  useMergedGroupByFilter,
} from './useEntitiesData';

const renderEmptyCell = () => '';

function KubernetesTableForCronJobRow({ row }) {
  const { date, entitiesType, navigate } = useKubernetesController();

  const facets = useMergedGroupByFilter(row.groupBys);
  const [rows] = useEntitiesData({
    entitiesType,
    kubesSort: null,
    facets,
    page: 0,
    limit: 50,
    date,
  });

  const setActiveKube = (row) => {
    navigate(({ location, params, pathTemplate }) => {
      return {
        ...location,

        pathname: generatePath(pathTemplate, {
          ...params,

          activeKube: row.cronJob.metadata.uid,
          tab: 'yaml',
        }),
      };
    });
  };

  const activeKubeParam = useParams()['activeKube'];
  const activeKube = (
    typeof activeKubeParam === 'string' && rows ? rows : []
  ).find((row) => {
    return row.cronJob.metadata.uid === activeKubeParam;
  });

  return (
    <Box width="full" height="full">
      {activeKube && <KubernetesRightSideBar activeKube={activeKube} />}
      <Table
        className="-my-px"
        renderHeader={false}
        rows={rows ?? []}
        renderRow={(props) => (
          <TableRow
            {...props}
            className="border-y"
            onRowClickHandler={(row) => () => setActiveKube(row)}
          />
        )}
        columns={[
          { key: 'header', label: '' },
          {
            key: 'kube_cron_job',
            label: 'Cron Job',
            renderCell(prop) {
              const cronJobName = prop?.row?.cronJob?.metadata?.name;
              return (
                <KubeTableCell
                  {...prop}
                  className="kubernetes__table__cell__entity-name"
                  tooltipText={cronJobName}
                >
                  {cronJobName}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'kube_cluster_name',
            label: 'Cluster',
            renderCell(prop) {
              return <KubeCellCluster {...prop} />;
            },
          },
          {
            key: 'metadata.namespace',
            label: 'Namespace',
            renderCell(prop) {
              return <KubeCellNamespace {...prop} />;
            },
          },
          {
            key: 'metadata.creationTimestamp',
            label: 'Age',
            renderCell(prop) {
              const timestamp =
                prop.row.cronJob.metadata?.creationTimestamp || '';
              return <KubeCellTimestamp {...prop} timestamp={timestamp} />;
            },
          },
          {
            key: 'spec.schedule',
            label: 'Schedule',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop}>
                  {prop?.row?.cronJob?.spec.schedule}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'spec.suspend',
            label: 'Suspend',
            renderCell(prop) {
              const status =
                prop?.row?.cronJob?.spec.suspend === true
                  ? 'Suspended'
                  : 'Active';
              return <KubeCellStatus {...prop} status={status} />;
            },
          },
          {
            key: 'headerActiveJobs',
            label: 'Active Jobs',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="right">
                  {prop?.row.cronJob?.status?.active?.name || 0}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'headerKubeLabels',
            label: 'Kubernetes Labels',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop}>
                  {prop?.row?.cronJob?.metadata?.labels?.map(
                    (l: string) => l,
                  ) || ''}
                </KubeTableCell>
              );
            },
          },
        ]}
      />
    </Box>
  );
}

export default function KubernetesTableForCronJobGroup() {
  const [date] = useDateState();
  const { entitiesType, facets, groupBySearchTerms } =
    useKubernetesController();

  const [rows] = useEntitiesGroupData({
    entitiesType,
    date,
    facets,
    groupBySearchTerms,
  });

  return (
    <KubernetesTableGroup
      rows={rows ?? []}
      columns={[
        {
          key: 'headerkube_cron_job',
          label: 'Cron Job',
          renderCell: (prop) => <KubeTableEntityTags {...prop} />,
        },
        {
          key: 'headerkube_cron_job',
          label: 'Cron Job',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerkube_cluster_name',
          label: 'Cluster',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerkube_namespace',
          label: 'Namespace',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerage',
          label: 'Age',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerschedule',
          label: 'Schedule',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headersuspend',
          label: 'Suspend',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headeractivejob',
          label: 'Active Jobs',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerkuberneteslabel',
          label: 'Kubernetes Labels',
          renderCell: renderEmptyCell,
        },
      ]}
      renderExpandedRow={(props) => <KubernetesTableForCronJobRow {...props} />}
    />
  );
}
