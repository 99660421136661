import { QueryDataPropsRange } from 'types';

const shiftForecastBandTimeseriesTimestamps = ({
  upper,
  lower,
  shiftByTimestamps,
}: {
  lower: QueryDataPropsRange;
  upper: QueryDataPropsRange;
  shiftByTimestamps: number[];
}): {
  lowerShifted: QueryDataPropsRange;
  upperShifted: QueryDataPropsRange;
} => {
  const { data: lowerData } = lower;
  const { data: upperData } = upper;

  // remove the last element from shiftByTimestamps which is overlapped with the first element.
  shiftByTimestamps.pop();
  const shiftArray = new Array(shiftByTimestamps.length).fill(undefined);

  const lowerDataShifted: number[][] = lowerData.map((d, idx) => {
    if (idx === 0) {
      return [...shiftByTimestamps, ...d];
    }
    return [...shiftArray, ...d];
  });

  const upperDataShifted: number[][] = upperData.map((d, idx) => {
    if (idx === 0) {
      return [...shiftByTimestamps, ...d];
    }
    return [...shiftArray, ...d];
  });

  return {
    lowerShifted: { ...lower, data: lowerDataShifted },
    upperShifted: { ...upper, data: upperDataShifted },
  };
};

export default shiftForecastBandTimeseriesTimestamps;
