import React from 'react';

import { Box } from 'components/layouts/Box';
import { calculateCapacity } from 'utils/timeNs';
import { Table, TableRow } from 'components';
import KubernetesRightSideBar from '../KubernetesRightSideBar';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellStatus,
  KubeCellTimestamp,
  KubeTableCell,
  KubeTableEntityTags,
  KubeTableFilterCell,
} from './Components';
import { generatePath, useParams } from 'react-router-dom';
import KubernetesTableGroup from './KubernetesTableGroup';
import {
  useEntitiesData,
  useEntitiesGroupData,
  useMergedGroupByFilter,
} from './useEntitiesData';

const renderEmptyCell = () => '';

function KubernetesTableForPersistentVolumeClaimRow({ row }) {
  const { date, entitiesType, navigate } = useKubernetesController();

  const facets = useMergedGroupByFilter(row.groupBys);
  const [rows] = useEntitiesData({
    entitiesType,
    kubesSort: null,
    facets,
    page: 0,
    limit: 50,
    date,
  });

  const setActiveKube = (row) => {
    navigate(({ location, params, pathTemplate }) => {
      return {
        ...location,

        pathname: generatePath(pathTemplate, {
          ...params,

          activeKube: row.persistentVolumeClaim.metadata.uid,
          tab: 'yaml',
        }),
      };
    });
  };

  const activeKubeParam = useParams()['activeKube'];
  const activeKube = (
    typeof activeKubeParam === 'string' && rows ? rows : []
  ).find((row) => {
    return row.persistentVolumeClaim.metadata.uid === activeKubeParam;
  });

  return (
    <Box width="full" height="full">
      {activeKube && <KubernetesRightSideBar activeKube={activeKube} />}
      <Table
        className="-my-px"
        renderHeader={false}
        rows={rows ?? []}
        renderRow={(props) => (
          <TableRow
            {...props}
            className="border-y"
            onRowClickHandler={(row) => () => setActiveKube(row)}
          />
        )}
        columns={[
          { key: 'header', label: '' },
          {
            key: 'persistentvolumeclaim',
            label: 'Persistent Volume Claim',
            renderCell(prop) {
              const persistentVolumeClaimName =
                prop?.row.persistentVolumeClaim?.metadata?.name;
              return (
                <KubeTableCell
                  {...prop}
                  className="kubernetes__table__cell__entity-name"
                  tooltipText={persistentVolumeClaimName}
                >
                  {persistentVolumeClaimName}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'kube_cluster_name',
            label: 'Cluster',
            renderCell(prop) {
              return <KubeCellCluster {...prop} />;
            },
          },
          {
            key: 'metadata.namespace',
            label: 'Namespace',
            renderCell(prop) {
              return <KubeCellNamespace {...prop} />;
            },
          },
          {
            key: 'spec.storageClassName',
            label: 'Class',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="left">
                  {prop?.row?.persistentVolumeClaim?.spec?.storageClassName}
                </KubeTableCell>
              );
            },
          },

          {
            key: 'status.phase',
            label: 'Phase',
            renderCell(prop) {
              const status = prop?.row?.persistentVolumeClaim?.status.phase;
              return <KubeCellStatus {...prop} status={status} />;
            },
          },
          {
            key: 'spec.volumeName',
            label: 'Persistent Volume',
            renderCell({ row, ...prop }) {
              return (
                <KubeTableFilterCell
                  facetKey="kube_persistent_volume"
                  facetValue={row.persistentVolumeClaim.spec.volumeName}
                  className="kubernetes__table__cell__cluster-name truncate w-full"
                  row={row}
                  {...prop}
                />
              );
            },
          },
          {
            key: 'headerAccessModes',
            label: 'Desired Access Modes',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="left">
                  {prop?.row?.persistentVolumeClaim?.spec?.accessModes.map(
                    (s: string) => s,
                  )}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'metadata.creationTimestamp',
            label: 'Age',
            renderCell(prop) {
              const timestamp =
                prop?.row?.persistentVolumeClaim?.metadata?.creationTimestamp ||
                '';
              return <KubeCellTimestamp {...prop} timestamp={timestamp} />;
            },
          },
          {
            key: 'status.capacity',
            label: 'Capacity Requests',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="right">
                  {calculateCapacity(
                    prop?.row?.persistentVolumeClaim?.status?.capacity?.storage,
                  )}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'capacity',
            label: 'Capacity',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="right">
                  {calculateCapacity(
                    prop?.row?.persistentVolumeClaim?.status?.capacity?.storage,
                  )}
                </KubeTableCell>
              );
            },
          },
        ]}
      />
    </Box>
  );
}

export default function KubernetesTableForPersistentVolumeClaimGroup() {
  const { date, entitiesType, facets, groupBySearchTerms } =
    useKubernetesController();

  const [rows] = useEntitiesGroupData({
    entitiesType,
    date,
    facets,
    groupBySearchTerms,
  });

  return (
    <KubernetesTableGroup
      rows={rows ?? []}
      columns={[
        {
          key: 'headerpersistentvolumeclaim',
          label: 'Persistent Volume Claim Group',
          renderCell: (prop) => <KubeTableEntityTags {...prop} />,
        },
        {
          key: 'headerkube_cluster_name',
          label: 'Cluster',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerkube_namespace',
          label: 'Namespace',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerpvc_phase',
          label: 'Phase',
          renderCell: renderEmptyCell,
        },

        {
          key: 'headerkube_storage_class_name',
          label: 'Class',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerkube_persistent_volume',
          label: 'Persistent Volume',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerdesiredAccessModes',
          label: 'Desired Access Modes',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerage',
          label: 'Age',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headercapacityRequests',
          label: 'Capacity Requests',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headercapacity',
          label: 'Capacity',
          renderCell: renderEmptyCell,
        },
      ]}
      renderExpandedRow={(props) => (
        <KubernetesTableForPersistentVolumeClaimRow {...props} />
      )}
    />
  );
}
