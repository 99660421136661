import { useCallback } from 'react';
import {
  Expression,
  Operator,
  FocusedElementInfo,
  ElementType,
  Suggestion,
  ChipProps,
} from 'components/ExpressionBuilder/types';

import { assert, exhaustiveSwitchError } from 'utils';

const useOnSuggestionSelect = ({
  suggestions,
  expressions,
  createExpression,
  setProperty,
  setOperator,
  setValue,
  focusedElementInfo,
  setElementToFocusInfo,
  setShouldUpdateUrlIfNecessary,
}: {
  suggestions: Array<Suggestion>;
  expressions: Array<Expression>;
  focusedElementInfo: FocusedElementInfo;
  createExpression: (args: {
    index: number;
    property: string;
    operator: Operator;
  }) => void;
  setShouldUpdateUrlIfNecessary: (value: React.SetStateAction<boolean>) => void;
} & Pick<
  ChipProps,
  'setElementToFocusInfo' | 'setProperty' | 'setOperator' | 'setValue'
>): ((index: number) => void) => {
  return useCallback(
    (index: number) => {
      const suggestion = suggestions[index];
      const type = focusedElementInfo.type;
      switch (type) {
        case ElementType.INITIALIZER: {
          assert(focusedElementInfo.type === ElementType.INITIALIZER);
          createExpression({
            index: expressions.length,
            property: suggestion.label,
            operator: '=' as Operator,
          });
          setElementToFocusInfo({
            type: ElementType.VALUE,
            index: expressions.length,
            valueIndex: 0,
          });
          break;
        }
        case ElementType.PROPERTY: {
          assert(focusedElementInfo.type === ElementType.PROPERTY);
          setProperty({
            index: focusedElementInfo.index,
            property: suggestion.label,
          });
          setOperator({
            index: focusedElementInfo.index,
            operator: '=',
          });
          setElementToFocusInfo({
            type: ElementType.VALUE,
            index: focusedElementInfo.index,
            valueIndex: 0,
          });
          break;
        }
        case ElementType.OPERATOR: {
          assert(focusedElementInfo.type === ElementType.OPERATOR);
          setValue({
            index: focusedElementInfo.index,
            valueIndex: 0,
            value: suggestion.label,
          });
          setElementToFocusInfo({
            type: ElementType.VALUE,
            index: focusedElementInfo.index,
            valueIndex: 0,
          });
          break;
        }
        case ElementType.VALUE:
          assert(focusedElementInfo.type === ElementType.VALUE);
          setValue({
            index: focusedElementInfo.index,
            valueIndex: focusedElementInfo.valueIndex,
            value: suggestion.label,
          });
          setShouldUpdateUrlIfNecessary(true);
          break;
        default:
          throw exhaustiveSwitchError(type);
      }
    },
    [
      createExpression,
      expressions.length,
      focusedElementInfo,
      setElementToFocusInfo,
      setOperator,
      setProperty,
      setShouldUpdateUrlIfNecessary,
      setValue,
      suggestions,
    ],
  );
};

export default useOnSuggestionSelect;
