import React, { useMemo } from 'react';
import { colorsByAlertState } from 'kfuse-constants';
import { ChipWithLabel } from 'components';
import { getSelector } from '../utils/selectorsfunction';

import { DaemonSet, EntityTypes } from '../types';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellTimestamp,
  KubeTableCell,
} from './Components';
import KubernetesTable from './KubernetesTable';
import { useEntitiesCount, useEntitiesData } from './useEntitiesData';

export default function KubernetesTableForDaemonSet() {
  const { entitiesType, facets, page, limit } = useKubernetesController();
  const filters = useMemo(() => [facets], [facets]);
  const [rows] = useEntitiesData<{ daemonSet: DaemonSet }>({
    filters,
    page,
    limit,
  });
  const [count] = useEntitiesCount({
    entitiesType,
    facets,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'kube_daemon_set',
          label: 'Daemon Set',
          renderCell(prop) {
            const demonSetName = prop.row.daemonSet.metadata.name;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name pb-1.5 pt-1.5"
                tooltipText={demonSetName}
              >
                {demonSetName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell(prop) {
            return <KubeCellCluster {...prop} />;
          },
        },
        {
          key: 'metadata.namespace',
          label: 'Namespace',
          renderCell(prop) {
            return <KubeCellNamespace {...prop} />;
          },
        },
        {
          key: 'status.currentNumberScheduled',
          label: 'Status',
          renderCell(prop) {
            const row = prop.row;
            return (
              <KubeTableCell {...prop}>
                <ChipWithLabel
                  className="kubernetes__table__cell__daemonset__status"
                  color={colorsByAlertState['ok']}
                  label={row.daemonSet.status.currentNumberScheduled ?? ''}
                />
                <ChipWithLabel
                  className="kubernetes__table__cell__daemonset__status"
                  color={colorsByAlertState['pending']}
                  label={row.daemonSet.status.desiredNumberScheduled ?? ''}
                />
                <ChipWithLabel
                  className="kubernetes__table__cell__daemonset__status"
                  color={colorsByAlertState['alerting']}
                  label={row.daemonSet.status.updatedNumberScheduled ?? ''}
                />
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerSelectors',
          label: 'Selectors',
          renderCell(prop) {
            const selector = getSelector(prop?.row.daemonSet);
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name"
                tooltipText={selector}
              >
                {selector}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'spec?.deploymentStrategy',
          label: 'Strategy',
          renderCell(prop) {
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name"
              >
                {prop.row.daemonSet.spec.deploymentStrategy}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'metadata.creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            return (
              <KubeCellTimestamp
                {...prop}
                timestamp={prop.row.daemonSet.metadata.creationTimestamp}
              />
            );
          },
        },
      ]}
    />
  );
}
