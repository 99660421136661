import { themeColorLight, themeColorDark } from 'utils/colors/theme-color';
import { canvasPadding, drawLineOnTimeseries } from 'utils';
import Uplot from 'uplot';

const drawXaxisLine = ({
  u,
  darkModeEnabled,
}: {
  u: Uplot;
  darkModeEnabled: boolean;
}): void => {
  const { ctx, height, width } = u;
  ctx.save();
  const devicePixelRatio = window.devicePixelRatio || 1;

  const padding = canvasPadding(u);
  const TOTAL_WIDTH = width * devicePixelRatio;
  const TOTAL_HEIGHT = height * devicePixelRatio;
  // x offset for the vertical line
  const X_OFFSET = 16;
  const LINE_OFFSET = 2;

  // draw bottom horizontal line
  const strokeColor = darkModeEnabled
    ? themeColorLight.light06
    : themeColorDark.dark01;
  drawLineOnTimeseries({
    ctx,
    start: {
      x: padding.left - X_OFFSET,
      y: TOTAL_HEIGHT - padding.bottom + LINE_OFFSET,
    },
    end: {
      x: TOTAL_WIDTH - padding.right,
      y: TOTAL_HEIGHT - padding.bottom + LINE_OFFSET,
    },
    options: { color: strokeColor, lineWidth: LINE_OFFSET },
  });

  ctx.restore();
};

export default drawXaxisLine;
