import dayjs from 'dayjs';
import React from 'react';
import { DateSelection } from 'types';
import Alert from './Alert';

type Props = {
  className?: string;
  date: DateSelection;
};

const DateWarningAlert = ({ className, date }: Props) => {
  const { startTimeUnix, endTimeUnix } = date;
  const startTimeDayJs = dayjs.unix(startTimeUnix);
  const endTimeDayJs = dayjs.unix(endTimeUnix);
  const diff = endTimeDayJs.diff(startTimeDayJs, 'hour', true);

  if (diff > 3) {
    return (
      <div className={className}>
        <Alert
          description="Higher time window selections can cause performance issues, try reducing it"
          icon="alert-circle"
        />
      </div>
    );
  }

  return null;
};

export default DateWarningAlert;
