import classnames from 'classnames';
import { useToggle } from 'hooks';
import React from 'react';
import { Frame } from 'types';
import Chevron from '../Chevron';

type Props = {
  frame: Frame;
  isExpandable: boolean;
  isOpenToggle: ReturnType<typeof useToggle>;
};

const StackTraceFrameTitle = ({ frame, isExpandable, isOpenToggle }: Props) => {
  const { filename, lineno } = frame;

  return (
    <div
      className={classnames({
        'stack-trace__frame__title': true,
        'stack-trace__frame__title--expandable': isExpandable,
      })}
      onClick={isExpandable ? isOpenToggle.toggle : () => {}}
    >
      {isExpandable ? <Chevron isOpen={isOpenToggle.value} /> : null}
      <div className="stack-trace__frame__description">
        <span className="stack-trace__frame__description__value">
          {filename}
        </span>
        {' in '}
        <span className="stack-trace__frame__description__value">
          {frame.function}
        </span>
        {' at '}
        <span className="stack-trace__frame__description__value">
          {`line ${lineno}`}
        </span>
      </div>
    </div>
  );
};

export default StackTraceFrameTitle;
