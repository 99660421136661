import Uplot from 'uplot';
import { canvasPadding, drawLineOnTimeseries } from 'utils/Timeseries';
import { ctaColorRed } from 'utils/colors';

const drawForecastVerticalLine = ({
  u,
  darkModeEnabled,
  forecastDuration,
  breakPoint,
}: {
  u: Uplot;
  darkModeEnabled: boolean;
  forecastDuration: string;
  breakPoint: number;
}): void => {
  const { ctx, height, width } = u;
  const padding = canvasPadding(u);

  const devicePixelRatio = window.devicePixelRatio || 1;
  const TOTAL_HEIGHT = height * devicePixelRatio;
  const TOTAL_WIDTH = width * devicePixelRatio;
  const totalDataPoints = u.data[0].length;
  const oneXGridSize =
    (width * devicePixelRatio - padding.left - padding.right) / totalDataPoints;
  const verticalLineOffet = breakPoint
    ? oneXGridSize * breakPoint + padding.left
    : width;

  const colorRed = darkModeEnabled ? ctaColorRed.dark : ctaColorRed.light;
  // draw dotted line

  drawLineOnTimeseries({
    ctx,
    start: { x: verticalLineOffet, y: padding.top },
    end: { x: verticalLineOffet, y: TOTAL_HEIGHT - padding.bottom },
    options: { color: colorRed, lineWidth: 4, dash: [10, 10] },
  });

  ctx.save();
  ctx.font = `bold ${12 * devicePixelRatio}px sans-serif`;
  ctx.fillStyle = colorRed;
  const textWidthNow = ctx.measureText('Now').width;
  const textNowYOffset = 10 * devicePixelRatio;
  ctx.fillText(
    'Now',
    verticalLineOffet + textWidthNow / 2,
    TOTAL_HEIGHT - padding.bottom + textNowYOffset,
  );
  ctx.restore();

  // write text on top right corner
  ctx.save();
  const text = 'Forecast' + (forecastDuration ? ` (${forecastDuration})` : '');
  const fontSize = 13 * devicePixelRatio;
  ctx.font = `600 ${fontSize}px sans-serif`;
  ctx.fillStyle = colorRed;
  const textWidth = ctx.measureText(text).width;
  const textXOffset = 16;
  ctx.fillText(
    text,
    verticalLineOffet + textWidth + textXOffset,
    padding.top + textXOffset,
  );
  ctx.restore();

  // draw thick line
  const BOTTOM_LINE_WIDTH = 8;
  drawLineOnTimeseries({
    ctx,
    start: {
      x: verticalLineOffet,
      y: TOTAL_HEIGHT - padding.bottom + BOTTOM_LINE_WIDTH - 2,
    },
    end: {
      x: TOTAL_WIDTH - padding.right,
      y: TOTAL_HEIGHT - padding.bottom + BOTTOM_LINE_WIDTH - 2,
    },
    options: { color: colorRed, lineWidth: BOTTOM_LINE_WIDTH },
  });
};

export default drawForecastVerticalLine;
