import {
  Button,
  Loader,
  PopoverPosition,
  PopoverTriggerV2,
  Stepper,
  TooltipTrigger,
  useThemeContext,
} from 'components';
import { Separator } from 'components/shadcn';
import { CalendarPickerModal } from 'composite';
import dayjs from 'dayjs';
import { useLocalStorageState, useRequest } from 'hooks';
import { dateTimeFormat, MILLISECONDS } from 'kfuse-constants';
import React, { ReactElement, useMemo, useState } from 'react';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { ChevronUp, ChevronDown } from 'react-feather';
import { DateSelection } from 'types';
import { getDateFromRange } from 'screens/Dashboard/utils';
import { getRollupToSecond } from 'utils';

import LogsHydrationArchivesHydrateFilter from './LogsHydrationArchivesHydrateFilter';
import LogsHydrationArchivesHydrateRetention from './LogsHydrationArchivesHydrateRetention';
import LogsHydrationArchivesHydrateTags from './LogsHydrationArchivesHydrateTags';
import { hydrateArchive } from './requests';
import { ArchiveInfoProps, HydrateFormProps } from './types';
import {
  getDefaultHydrateForm,
  FILTER_HELP_MESSAGE,
  TAG_HELP_MESSAGE,
  hydrateTimeQuickRangeOptions,
} from './utils';

const LogsHydrationArchivesHydrate = ({
  archive,
  close,
  onSuccess,
}: {
  archive: ArchiveInfoProps;
  close: () => void;
  onSuccess: (jobId: string) => void;
}): ReactElement => {
  const [absoluteTimeRangeStorage, setAbsoluteTimeRangeStorage] =
    useLocalStorageState('AbsoluteTimeRange', []);
  const { utcTimeEnabled } = useThemeContext();
  const hydrateArchiveRequest = useRequest(hydrateArchive);
  const defaultDate = getDateFromRange('now-1M', 'now');
  const [hydrateForm, setHydrateForm] = useState<HydrateFormProps>(
    getDefaultHydrateForm(defaultDate, archive),
  );

  const onDateChange = (date: DateSelection) => {
    const { startTimeUnix, endTimeUnix, startLabel, endLabel } = date;

    if (!startLabel && !endLabel) {
      setAbsoluteTimeRangeStorage((preHistory: void[]) => {
        if (preHistory.length > 3) {
          preHistory.pop();
        }
        return [...[{ startTimeUnix, endTimeUnix }], ...preHistory];
      });
    }

    if (!startLabel || !endLabel) {
      setHydrateForm((prev) => ({
        ...prev,
        startTsMs: startTimeUnix * MILLISECONDS,
        endTsMs: endTimeUnix * MILLISECONDS,
      }));
      return;
    }

    const nextStartTimeUnix = archive.FirstArchivedRecordUTCTsMs;
    const startLabelSplit = startLabel.split('+');
    const nextTimeInSeconds = getRollupToSecond(startLabelSplit[1]);
    const nextEndTimeUnix =
      nextStartTimeUnix + nextTimeInSeconds * MILLISECONDS;
    setHydrateForm((prev) => ({
      ...prev,
      startTsMs: nextStartTimeUnix,
      endTsMs: nextEndTimeUnix,
    }));
  };

  const onHydrate = () => {
    hydrateArchiveRequest.call(hydrateForm).then((res) => {
      close();
      onSuccess(res.jobId);
    });
  };

  const { date, schedule } = useMemo(() => {
    const { startTsMs, endTsMs } = hydrateForm;
    const startTsSecs = startTsMs / 1000;
    const endTsSecs = endTsMs / 1000;

    const startAtDate = utcTimeEnabled
      ? dayjs.unix(startTsSecs).utc()
      : dayjs.unix(startTsSecs);
    const endAtDate = utcTimeEnabled
      ? dayjs.unix(endTsSecs).utc()
      : dayjs.unix(endTsSecs);

    const schedule = `${startAtDate.format(dateTimeFormat)} to ${endAtDate.format(dateTimeFormat)}`;
    const date = { startTimeUnix: startTsSecs, endTimeUnix: endTsSecs };
    return { date, schedule };
  }, [hydrateForm, utcTimeEnabled]);

  return (
    <div className="flex max-h-[95vh] w-[800px] flex-col px-4">
      <div className="p-4">
        <div className="text-xl font-bold">
          Hydrating from <span className="text-primary">{archive.name}</span>
        </div>
      </div>
      <Loader
        className="min-h-[200px]"
        isLoading={hydrateArchiveRequest.isLoading}
      >
        <div className="max-h-[75vh] flex-1 overflow-y-auto">
          <Stepper
            steps={[
              {
                title: (
                  <span>
                    Set Time Range (<span className="text-red-500">*</span>)
                  </span>
                ),
                component: (
                  <div>
                    <label>
                      Start Time and End Time (
                      {utcTimeEnabled ? 'UTC' : 'Browser Time'})
                    </label>
                    <div
                      className="mt-1 max-w-[310px] rounded-sm border"
                      data-testid="hydrate-schedule-picker"
                    >
                      <PopoverTriggerV2
                        className="calendar-picker__trigger"
                        popoverPanelClassName="popover__panel--overflow"
                        popover={({ close }) => (
                          <CalendarPickerModal
                            absoluteTimeRangeStorage={absoluteTimeRangeStorage}
                            close={close}
                            onChange={onDateChange}
                            startLiveTail={null}
                            value={date}
                            quickRangeOptions={hydrateTimeQuickRangeOptions()}
                            periodType="Next"
                            renderInfo={
                              <div className="p-4">
                                <div className="text-xs italic">
                                  The start of next time from quick range will
                                  be the first archived record timestamp
                                </div>
                              </div>
                            }
                          />
                        )}
                        position={PopoverPosition.BOTTOM_LEFT}
                        right
                        width={480}
                      >
                        <div className="calendar-picker__trigger__value">
                          {schedule}
                        </div>
                        <ChevronUp
                          className="calendar-picker__trigger__chevron calendar-picker__trigger__chevron--up"
                          size={16}
                        />
                        <ChevronDown
                          className="calendar-picker__trigger__chevron calendar-picker__trigger__chevron--down"
                          size={16}
                        />
                      </PopoverTriggerV2>
                    </div>
                  </div>
                ),
              },
              {
                title: (
                  <div className="flex items-center gap-1">
                    <div>Set Filter</div>
                    <TooltipTrigger tooltip={FILTER_HELP_MESSAGE}>
                      <BsQuestionCircleFill className="blue-question-circle" />
                    </TooltipTrigger>
                  </div>
                ),
                component: (
                  <LogsHydrationArchivesHydrateFilter
                    hydrateForm={hydrateForm}
                    setHydrateForm={setHydrateForm}
                  />
                ),
              },
              {
                title: (
                  <div className="flex items-center gap-1">
                    <div>Add Tags</div>
                    <TooltipTrigger tooltip={TAG_HELP_MESSAGE}>
                      <BsQuestionCircleFill className="blue-question-circle" />
                    </TooltipTrigger>
                  </div>
                ),
                component: (
                  <LogsHydrationArchivesHydrateTags
                    hydrateForm={hydrateForm}
                    setHydrateForm={setHydrateForm}
                  />
                ),
              },
              {
                title: (
                  <span>
                    Set Retention (<span className="text-red-500">*</span>)
                  </span>
                ),
                component: (
                  <LogsHydrationArchivesHydrateRetention
                    hydrateForm={hydrateForm}
                    setHydrateForm={setHydrateForm}
                  />
                ),
              },
            ]}
          />
        </div>
      </Loader>
      <Separator />
      <div className="flex justify-end gap-2 pb-4 pt-2">
        <Button variant="outline" onClick={close}>
          Cancel
        </Button>
        <Button variant="default" onClick={onHydrate}>
          Hydrate
        </Button>
      </div>
    </div>
  );
};

export default LogsHydrationArchivesHydrate;
