import { PopoverTriggerV2 } from 'components';
import React, { ReactElement } from 'react';

import { useLogsState } from './hooks';
import LogsFingerprintsListItemPanel from './LogsFingerprintsListItemPanel';

const LogsSelectedLogFingerprint = ({
  fpHash,
  fpPattern,
  logsState,
  shouldDisableFilterActions,
}: {
  fpHash: string;
  fpPattern: string;
  logsState: ReturnType<typeof useLogsState>;
  shouldDisableFilterActions: boolean;
}): ReactElement => {
  return (
    <div className="logs__selected-log__fingerprint">
      <PopoverTriggerV2
        className="logs__selected-log__fingerprint__trigger"
        popover={({ close }) => (
          <LogsFingerprintsListItemPanel
            close={close}
            hash={fpHash}
            fpPattern={fpPattern}
            logsState={logsState}
            shouldDisableFilterActions={shouldDisableFilterActions}
          />
        )}
      >
        {fpPattern}
      </PopoverTriggerV2>
    </div>
  );
};

export default LogsSelectedLogFingerprint;
