import {
  Button,
  ConfirmationModal,
  Loader,
  OverlayMessage,
  OverlayMessageProps,
  Table,
  TableSearch,
  TooltipTrigger,
  useModalsContext,
  usePaginator,
  useTableSearch,
  useTableSort,
} from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdDelete as DeleteIcon, MdModeEdit as EditIcon } from 'react-icons/md';

import {
  getGrafanaAlertManagerMuteTiming,
  mutateGrafanaAlertManagerMuteTiming,
} from './requests';
import { AlertMuteTimingProps } from './types';
import { AlertsMuteTimingTimeRangeColumn } from './AlertsMuteTimingTimeRangeColumn';

const muteTimingColumns = ({
  onDeleteMuteTiming,
}: {
  onDeleteMuteTiming: (row: AlertMuteTimingProps) => void;
}) => [
  { key: 'name', label: 'Name' },
  {
    key: 'timeRange',
    label: 'Time Range',
    renderCell: ({ row }: { row: AlertMuteTimingProps }) => {
      return <AlertsMuteTimingTimeRangeColumn row={row} />;
    },
  },
  {
    label: '',
    key: 'actions',
    renderCell: ({ row }: { row: AlertMuteTimingProps }) => {
      return (
        <div
          className="table__row__actions"
          style={{ '--table-actions-width': '74px' }}
        >
          <div className="table__row__actions--hidden">
            <div className="table__row__actions__slider">
              <div className="alerts__contacts__table__actions">
                <div className="table__cell__actions__item--blue">
                  <Link className="link" to={`/alerts/mute-timing/${row.name}`}>
                    <TooltipTrigger tooltip="Edit">
                      <EditIcon
                        className="alerts__contacts__table__actions__icon--edit"
                        size={18}
                      />
                    </TooltipTrigger>
                  </Link>
                </div>
                <div
                  className="table__cell__actions__item--red"
                  onClick={() => onDeleteMuteTiming(row)}
                >
                  <TooltipTrigger tooltip="Delete">
                    <DeleteIcon
                      className="alerts__contacts__table__actions__icon--delete"
                      size={18}
                    />
                  </TooltipTrigger>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
];

const emptyArr = [];
const AlertsMuteTiming = (): ReactElement => {
  const modal = useModalsContext();
  const muteTimingListRequest = useRequest(
    getGrafanaAlertManagerMuteTiming,
    true,
    true,
  );
  const mutateMuteTimingRequest = useRequest(
    mutateGrafanaAlertManagerMuteTiming,
  );

  useEffect(() => {
    muteTimingListRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteMuteTiming = (row: AlertMuteTimingProps) => {
    modal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description={
          <span>
            Are you sure you want to delete{' '}
            <span className="font-bold">{row.name}</span> mute timing? This
            action cannot be undone.
          </span>
        }
        dataTestId="delete-group-confirmation-modal"
        onCancel={() => modal.pop()}
        onConfirm={() => {
          modal.pop();
          getGrafanaAlertManagerMuteTiming(false).then((res: any) => {
            const payload = { ...res };
            payload.alertmanager_config.mute_time_intervals =
              payload.alertmanager_config.mute_time_intervals.filter(
                (timing: AlertMuteTimingProps) => timing.name !== row.name,
              );
            mutateMuteTimingRequest.call(payload).then(() => {
              muteTimingListRequest.call();
            });
          });
        }}
        title="Delete Mute Timing"
      />,
    );
  };

  const columns = muteTimingColumns({ onDeleteMuteTiming });

  const muteTimingList = muteTimingListRequest.result || emptyArr;
  const tableSearch = useTableSearch({ rows: muteTimingList });
  const tableSort = useTableSort({ columns, rows: tableSearch.searchedRows });
  const paginator = usePaginator({
    rows: tableSort.sortedRows,
    initialNumberOfRowsPerPage: 25,
  });

  const overlayMessageProps: OverlayMessageProps = muteTimingListRequest.error
    ? {
        isActive: true,
        iconName: 'warning',
        message: <>Failed to load mute timing list</>,
      }
    : { isActive: false };

  return (
    <div className="mx-auto max-w-[1200px] py-4">
      <div className="flex items-center justify-between pb-2">
        <h2 className="whitespace-nowrap text-lg font-semibold">Mute Timing</h2>

        <Link to="/alerts/mute-timing/new?new=true">
          <Button variant="default" size="sm">
            Add New Mute Timing
          </Button>
        </Link>
      </div>
      <div>
        <TableSearch
          className="dashboard__list__search"
          placeholder="Search mute timing..."
          tableSearch={tableSearch}
          dataTestId="dashboard-list-search"
        />
        <OverlayMessage {...overlayMessageProps}>
          <Loader
            isLoading={
              muteTimingListRequest.isLoading ||
              mutateMuteTimingRequest.isLoading
            }
          >
            <Table
              className="table--bordered table--bordered-cells table__actions--hidden alerts__list__table"
              columns={columns}
              dataTestId="alerts-list-table"
              externalTableSort={tableSort}
              isSortingEnabled
              rows={paginator.paginatedRows}
            />
          </Loader>
        </OverlayMessage>
      </div>
    </div>
  );
};

export default AlertsMuteTiming;
