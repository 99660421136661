import { AutocompleteOption, Loader } from 'components';
import { debounce } from 'lodash';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import MetricsQueryBuilderActiveMetricSummary from './MetricsQueryBuilderActiveMetricSummary';
import { IoIosWarning } from 'react-icons/io';

type Props = {
  isLoading: boolean;
  labelsList: {
    [key: string]: { options: AutocompleteOption[]; isLoading: boolean };
  };
  metricsList: AutocompleteOption[];
  onClick: (metricName: string) => void;
  onHover: (metricName: string) => void;
  onTagClick: (metricName: string, tagName: string) => void;
  error: {
    getLabelVlaues: { message: string };
    getLabels: { message: string };
  };
};

const MetricsQueryBuilderMetricPanel = ({
  isLoading,
  labelsList,
  metricsList,
  onClick,
  onHover,
  onTagClick,
  error,
}: Props): ReactElement => {
  const [activeMetric, setActiveMetric] = useState('');
  const isMounted = useRef(false);

  const onHoverMetric = useCallback(
    (metricName: string) => {
      if (!isMounted.current) return;
      setActiveMetric(metricName);
      onHover(metricName);
    },
    [onHover],
  );

  const debouncedHoverMetric = useMemo(
    () => debounce(onHoverMetric, 300),
    [onHoverMetric],
  );

  const metricSummary = useMemo(() => {
    const matcher = `${activeMetric}{}`;
    if (activeMetric && labelsList[matcher]) {
      return labelsList[matcher];
    }
    return { options: [], isLoading: false };
  }, [activeMetric, labelsList]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Loader isLoading={isLoading}>
      <div className="metrics__query-builder__metric__panel">
        <div className="metrics__query-builder__metric__panel__list overflow-y-scroll">
          {metricsList.map((metric) => {
            return (
              <div
                className="metrics__query-builder__metric__panel__list__item py-2"
                key={metric.value}
                onMouseDown={() => onClick(metric.value)}
                onMouseEnter={() => debouncedHoverMetric(metric.value)}
              >
                {metric.label}
              </div>
            );
          })}
        </div>

        <div className="metrics__query-builder__metric__panel__tags overflow-y-scroll">
          {activeMetric && (
            <>
              <MetricsQueryBuilderActiveMetricSummary
                activeMetric={activeMetric}
              />
              <Loader isLoading={metricSummary.isLoading}>
                {error.getLabels ? (
                  // error.getLabelVlaues is never set
                  <div className="flex gap-[4px] w-full justify-start m-[2px] pr-[14px]">
                    <IoIosWarning
                      className="overlay-message__icon-and-message__icon"
                      size={16}
                    />
                    <div className="text-red-500">
                      {error?.getLabels?.message}
                    </div>
                  </div>
                ) : (
                  <>
                    {metricSummary.options.map(
                      ({ label }: { label: string }) => {
                        return (
                          <div
                            className="chip metrics__query-builder__metric__panel__tags__item"
                            key={label}
                            onMouseDown={() => onTagClick(activeMetric, label)}
                          >
                            {label}
                          </div>
                        );
                      },
                    )}
                  </>
                )}
              </Loader>
            </>
          )}
        </div>
      </div>
    </Loader>
  );
};

export default MetricsQueryBuilderMetricPanel;
