import { AreaSelectionProps, UplotExtended } from 'types';
import {
  canvasPadding,
  ctaColor,
  drawRectangeOnTimeseries,
  heatmapAreaSelectionColor,
} from 'utils';

export const defaultAreaSelection = () => {
  return {
    isDrawing: false,
    isResizing: false,
    isMoving: false,
    resizeDirection: '',
    startX: 0,
    startY: 0,
    moveStartX: 0,
    moveStartY: 0,
    rect: { x: 0, y: 0, w: 0, h: 0 },
    resizeRect: {
      left: { x: 0, y: 0, w: 0, h: 0 },
      right: { x: 0, y: 0, w: 0, h: 0 },
      top: { x: 0, y: 0, w: 0, h: 0 },
      bottom: { x: 0, y: 0, w: 0, h: 0 },
      topLeft: { x: 0, y: 0, w: 0, h: 0 },
      topRight: { x: 0, y: 0, w: 0, h: 0 },
      bottomLeft: { x: 0, y: 0, w: 0, h: 0 },
      bottomRight: { x: 0, y: 0, w: 0, h: 0 },
    },
  };
};

export const onMouseDownForAreaSelection = ({
  e,
  u,
}: {
  e: MouseEvent;
  u: UplotExtended;
}) => {
  const { rect } = u.areaSelection;
  const devicePixelRatio = window.devicePixelRatio;
  const x = e.offsetX * devicePixelRatio;
  const y = e.offsetY * devicePixelRatio;

  const padding = canvasPadding(u);

  const adjustedX = x + padding.left;
  const adjustedY = y + padding.top;
  const direction = getResizeDirection(adjustedX, adjustedY, u);
  if (direction && direction !== 'move') {
    u.areaSelection.isResizing = true;
    u.areaSelection.resizeDirection = direction;
  } else if (direction === 'move') {
    u.areaSelection.isMoving = true;
    u.areaSelection.moveStartX = adjustedX - rect.x;
    u.areaSelection.moveStartY = adjustedY - rect.y;
    u.areaSelection.isResizing = false;
    u.areaSelection.isDrawing = false;
  } else {
    u.areaSelection.isDrawing = true;
    u.areaSelection.isResizing = false;
    u.areaSelection.isMoving = false;
    u.areaSelection.startX = adjustedX;
    u.areaSelection.startY = adjustedY;
    u.areaSelection.rect = {
      x: u.areaSelection.startX,
      y: u.areaSelection.startY,
      w: 0,
      h: 0,
    };
  }
};

export const onMouseMoveForAreaSelection = ({
  e,
  u,
}: {
  e: MouseEvent;
  u: UplotExtended;
}) => {
  const { rect, isDrawing, isMoving, resizeDirection, moveStartX, moveStartY } =
    u.areaSelection;

  const devicePixelRatio = window.devicePixelRatio;
  const graphRect = u.root.getBoundingClientRect();
  const x = e.clientX - graphRect.left;
  const y = e.clientY - graphRect.top;
  const scaledX = x * devicePixelRatio;
  const scaledY = y * devicePixelRatio;

  // constants that account for graph paddings/margins
  const tickmarkOffsetX = 50;
  const tickmarkOffsetY = 72;
  const innerLeft = 180;
  const innerTop = 32;
  const innerRight = graphRect.width * devicePixelRatio - tickmarkOffsetX;
  const innerBottom = graphRect.height * devicePixelRatio - tickmarkOffsetY;

  const startX = u.areaSelection.startX;
  const startY = u.areaSelection.startY;

  if (isDrawing) {
    if (scaledX >= startX) {
      rect.x = startX;
      rect.w = Math.min(innerRight - startX, scaledX - startX);
    } else {
      rect.x = Math.max(scaledX, innerLeft);
      rect.w = Math.min(startX - innerLeft, startX - scaledX);
    }

    if (scaledY >= startY) {
      rect.y = startY;
      rect.h = Math.min(innerBottom - startY, scaledY - startY);
    } else {
      rect.y = Math.max(scaledY, innerTop);
      rect.h = Math.min(startY - innerTop, startY - scaledY);
    }

    drawSelectionRectangle({ u });
    u.ignoreTooltip = true;
  } else if (resizeDirection) {
    if (resizeDirection.includes('right')) {
      rect.w = Math.max(0, Math.min(scaledX, innerRight) - rect.x);
    }
    if (resizeDirection.includes('left')) {
      const newWidth = Math.max(0, rect.x - Math.max(scaledX, innerLeft));
      rect.x = Math.max(scaledX, innerLeft);
      rect.w = newWidth;
    }
    if (resizeDirection.includes('bottom')) {
      rect.h = Math.max(0, Math.min(scaledY, innerBottom) - rect.y);
    }
    if (resizeDirection.includes('top')) {
      const newHeight = Math.max(0, rect.y - Math.max(scaledY, innerTop));
      rect.y = Math.max(scaledY, innerTop);
      rect.h = newHeight;
    }

    drawSelectionRectangle({ u });
  } else if (isMoving) {
    u.root.style.cursor = 'move';
    u.ignoreTooltip = true;

    const newX = scaledX - moveStartX;
    const newY = scaledY - moveStartY;

    rect.x = Math.max(innerLeft, Math.min(newX, innerRight - rect.w));
    rect.y = Math.max(innerTop, Math.min(newY, innerBottom - rect.h));

    drawSelectionRectangle({ u });
  } else {
    const direction = getResizeDirection(scaledX, scaledY, u);
    if (direction) {
      switch (direction) {
        case 'left':
        case 'right':
          u.root.style.cursor = 'ew-resize';
          break;
        case 'top':
        case 'bottom':
          u.root.style.cursor = 'ns-resize';
          break;
        case 'top-left':
        case 'bottom-right':
          u.root.style.cursor = 'nwse-resize';
          break;
        case 'top-right':
        case 'bottom-left':
          u.root.style.cursor = 'nesw-resize';
          break;
        case 'move':
          u.root.style.cursor = 'move';
          break;
        default:
          u.root.style.cursor = 'crosshair';
      }
      u.ignoreTooltip = true;
    } else {
      u.root.style.cursor = 'crosshair';
      u.ignoreTooltip = false;
    }
  }
};

export const onMouseUpForAreaSelection = ({
  e,
  u,
  onAreaSelectionChange,
}: {
  e: MouseEvent;
  u: UplotExtended;
  onAreaSelectionChange: (values: AreaSelectionProps) => void;
}): void => {
  const { rect } = u.areaSelection;
  u.areaSelection.isDrawing = false;
  u.areaSelection.isResizing = false;
  u.areaSelection.isMoving = false;
  u.areaSelection.resizeDirection = '';
  u.ignoreTooltip = false;

  const startTime = u.posToVal(rect.x, 'x', true);
  const endTime = u.posToVal(rect.x + rect.w, 'x', true);

  const startValue = u.posToVal(rect.y, 'y', true);
  const endValue = u.posToVal(rect.y + rect.h, 'y', true);

  if (onAreaSelectionChange) {
    onAreaSelectionChange({
      startTime,
      endTime,
      startValue,
      endValue,
      rect,
    });
  }
};

const setResizeRect = ({
  u,
  x,
  y,
}: {
  u: UplotExtended;
  x: number;
  y: number;
}) => {
  const { rect, resizeDirection } = u.areaSelection;
  switch (resizeDirection) {
    case 'left':
      const prevWidth = rect.w + rect.x;
      rect.x = x;
      rect.w = prevWidth - rect.x;
      break;
    case 'right':
      rect.w = x - rect.x;
      break;
    case 'top':
      const prevHeight = rect.h + rect.y;
      rect.y = y;
      rect.h = prevHeight - rect.y;
      break;
    case 'bottom':
      rect.h = y - rect.y;
      break;
    case 'top-left':
      const prevWidthTL = rect.w + rect.x;
      const prevHeightTL = rect.h + rect.y;
      rect.x = x;
      rect.y = y;
      rect.w = prevWidthTL - rect.x;
      rect.h = prevHeightTL - rect.y;
      break;
    case 'top-right':
      const prevHeightTR = rect.h + rect.y;
      rect.y = y;
      rect.w = x - rect.x;
      rect.h = prevHeightTR - rect.y;
      break;
    case 'bottom-left':
      const prevWidthBL = rect.w + rect.x;
      rect.x = x;
      rect.w = prevWidthBL - rect.x;
      rect.h = y - rect.y;
      break;
    case 'bottom-right':
      rect.w = x - rect.x;
      rect.h = y - rect.y;
      break;
  }
};

export const drawSelectionRectangle = ({ u }: { u: UplotExtended }) => {
  const devicePixelRatio = window.devicePixelRatio;
  const { rect } = u.areaSelection;
  const { clearCanvasByContext, darkModeEnabled } = u;
  const ctx = u.eventOverlayCtx;
  clearCanvasByContext(ctx);

  ctx.strokeStyle = ctaColor.orange;
  ctx.lineWidth = 1.5 * devicePixelRatio;
  ctx.strokeRect(rect.x, rect.y, rect.w, rect.h);
  ctx.fillStyle = darkModeEnabled
    ? heatmapAreaSelectionColor.dark
    : heatmapAreaSelectionColor.light;
  ctx.fillRect(rect.x, rect.y, rect.w, rect.h);
  drawResizeHandle({ ctx, u });
};

function isPointInRect(
  x: number,
  y: number,
  rect: { x: number; y: number; w: number; h: number },
): boolean {
  return (
    x >= rect.x && x <= rect.x + rect.w && y >= rect.y && y <= rect.y + rect.h
  );
}

// Helper function to determine if the cursor is over a resize handle
function getResizeDirection(x: number, y: number, u: UplotExtended): string {
  const { areaSelection } = u;
  const { rect, resizeRect } = areaSelection;
  if (isPointInRect(x, y, resizeRect.bottomRight)) return 'bottom-right';
  if (isPointInRect(x, y, resizeRect.topLeft)) return 'top-left';
  if (isPointInRect(x, y, resizeRect.topRight)) return 'top-right';
  if (isPointInRect(x, y, resizeRect.bottomLeft)) return 'bottom-left';

  if (isPointInRect(x, y, resizeRect.bottom)) return 'bottom';
  if (isPointInRect(x, y, resizeRect.top)) return 'top';
  if (isPointInRect(x, y, resizeRect.left)) return 'left';
  if (isPointInRect(x, y, resizeRect.right)) return 'right';

  if (isPointInRect(x, y, rect)) return 'move';

  return '';
}

const drawResizeHandle = ({
  ctx,
  u,
}: {
  ctx: CanvasRenderingContext2D;
  u: UplotExtended;
}) => {
  const { areaSelection } = u;
  const { rect, resizeRect } = areaSelection;
  const devicePixelRatio = window.devicePixelRatio;
  const size = 8 * devicePixelRatio;
  const halfSize = size / 2;
  const color = ctaColor.white;
  const resizeHeight = 6 * devicePixelRatio;
  // bottom right
  drawRectangeOnTimeseries({
    ctx,
    x: rect.x + rect.w - halfSize,
    y: rect.y + rect.h - halfSize,
    width: size,
    height: size,
    options: { color, lineWidth: 2, lineColor: ctaColor.yellow },
  });
  resizeRect.bottomRight = {
    x: rect.x + rect.w - halfSize,
    y: rect.y + rect.h - halfSize,
    w: size,
    h: size,
  };

  resizeRect.bottom = {
    x: rect.x,
    y: rect.y + rect.h - resizeHeight,
    w: rect.w,
    h: resizeHeight,
  };

  // top left
  drawRectangeOnTimeseries({
    ctx,
    x: rect.x - halfSize,
    y: rect.y - halfSize,
    width: size,
    height: size,
    options: { color, lineWidth: 2, lineColor: ctaColor.yellow },
  });
  resizeRect.topLeft = {
    x: rect.x - halfSize,
    y: rect.y - halfSize,
    w: size,
    h: size,
  };

  resizeRect.top = {
    x: rect.x,
    y: rect.y - halfSize,
    w: rect.w,
    h: resizeHeight,
  };
  // top right
  drawRectangeOnTimeseries({
    ctx,
    x: rect.x + rect.w - halfSize,
    y: rect.y - halfSize,
    width: size,
    height: size,
    options: { color, lineWidth: 2, lineColor: ctaColor.yellow },
  });
  resizeRect.topRight = {
    x: rect.x + rect.w - halfSize,
    y: rect.y - halfSize,
    w: size,
    h: size,
  };

  resizeRect.right = {
    x: rect.x + rect.w - halfSize,
    y: rect.y,
    w: resizeHeight,
    h: rect.h,
  };

  // bottom left
  drawRectangeOnTimeseries({
    ctx,
    x: rect.x - halfSize,
    y: rect.y + rect.h - halfSize,
    width: size,
    height: size,
    options: { color, lineWidth: 2, lineColor: ctaColor.yellow },
  });
  resizeRect.bottomLeft = {
    x: rect.x - halfSize,
    y: rect.y + rect.h - halfSize,
    w: size,
    h: size,
  };

  resizeRect.left = {
    x: rect.x - halfSize,
    y: rect.y,
    w: resizeHeight,
    h: rect.h,
  };
};
