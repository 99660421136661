import classnames from 'classnames';
import React from 'react';
import { spanListColumns } from './constants';

type Item = { [key: string]: any };

type Props = {
  item: Item;
};

const TraceSidebarMainSpanListRow = ({ item }: Props) => {
  const additionalLabel = item?.isCalculatedAvg ? ' (avg)' : '';
  return (
    <div className="trace-sidebar__main__span-list__row">
      {spanListColumns.map((column) => {
        const value = item[column.key as keyof Item];
        return (
          <div
            className={classnames({
              'trace-sidebar__main__span-list__cell': true,
              [`trace-sidebar__main__span-list__cell--${column.key}`]: true,
            })}
            key={column.key}
          >
            {column.render ? column.render(value, additionalLabel) : value}
          </div>
        );
      })}
    </div>
  );
};

export default TraceSidebarMainSpanListRow;
