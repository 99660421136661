import { debounce, keys, pickBy } from 'lodash';
import { AutocompleteOption } from 'components';
import { useFilterState, useKeyboardShortcut, useToggle } from 'hooks';
import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';
import { FilterPanelProps, FilterRequestMap, SearchItemProps } from 'types';
import {
  getEndDoubleQuote,
  parseFacetKey,
  parseSearchTerm,
  parseValueFromQuery,
} from 'utils';

const defaultPanelData: FilterPanelProps = {
  activeOperator: '=', // default operator
  optionData: { options: [], isLoading: false },
  typed: '',
};

type AutocompleteApplyMethodsProps = {
  initialOptions?: AutocompleteOption[];
  focusToggle: ReturnType<typeof useToggle>;
  search: string;
  setSearchItems: Dispatch<SetStateAction<SearchItemProps>>;
  setEditSearch: Dispatch<SetStateAction<any>>;
  setSearch: Dispatch<SetStateAction<string>>;
  setActivePanel: Dispatch<SetStateAction<FilterPanelProps>>;
};

const useAutocompleteState = ({
  filterState,
  initialOptions,
  operatorMap,
  requestMap,
  parseSearchQuery,
  onApplyFilterMap,
  setTyped,
  typed,
  onChangeInput = () => null,
  onFoucsInput,
  onScrollToBottom,
}: {
  filterState: ReturnType<typeof useFilterState>;
  initialOptions?: AutocompleteOption[];
  operatorMap?: { [key: string]: string };
  requestMap: FilterRequestMap;
  parseSearchQuery?: (
    searchQuery: string,
    type: 'full' | 'partial',
  ) => SearchItemProps;
  onApplyFilterMap?: {
    filterByFacets: (
      searchItem: SearchItemProps,
      editSearch?: any,
      methods?: AutocompleteApplyMethodsProps,
    ) => void;
    sidebarFilters: (
      searchItem: SearchItemProps,
      editSearch?: any,
      methods?: AutocompleteApplyMethodsProps,
    ) => void;
    searchTerms?: (
      text: string,
      editSearch?: any,
      methods?: AutocompleteApplyMethodsProps,
    ) => void;
    keyExists?: (
      option: AutocompleteOption,
      methods?: AutocompleteApplyMethodsProps,
    ) => void;
  };
  setTyped?: (s: string) => void;
  typed?: string;
  onChangeInput?: (s: string, methods: AutocompleteApplyMethodsProps) => void;
  onFoucsInput?: (methods: AutocompleteApplyMethodsProps) => void;
  onScrollToBottom?: (methods: AutocompleteApplyMethodsProps) => void;
}) => {
  const { addFilter, updateFilterByIndex } = filterState;
  const markForDeletionToggle = useToggle();
  const focusToggle = useToggle();

  const inputRef = useRef<HTMLInputElement>(null);
  const searchBarRef = useRef<HTMLDivElement>(null);

  const [activePanel, setActivePanel] =
    useState<FilterPanelProps>(defaultPanelData);

  const [autocompleteOptions, setAutocompleteOptions] = useState<{
    [key: string]: FilterPanelProps['optionData'];
  }>({});
  const [editSearch, setEditSearch] = useState(null);
  const [defaultSearch, defaultSetSearch] = useState('');
  const search = typeof typed === 'string' ? typed : defaultSearch;
  const setSearch = setTyped || defaultSetSearch;

  const [searchItems, setSearchItems] = useState<SearchItemProps>({
    facetName: '',
    operator: '=',
    optionType: '',
    value: '',
  });

  const applyFilterMethods: AutocompleteApplyMethodsProps = {
    initialOptions,
    focusToggle,
    search,
    setSearchItems,
    setEditSearch,
    setSearch,
    setActivePanel,
  };

  const onFoucs = () => {
    focusToggle.on();
    const { optionData } = activePanel;
    if (optionData.options.length === 0 && !optionData.isLoading) {
      onChange(search);
    }

    if (editSearch && editSearch.filterKey === 'sidebarFilters') {
      setEditSearch(null);
    }
    onFoucsInput?.(applyFilterMethods);
  };

  const isOptionAlreadyApplied = (option: AutocompleteOption) => {
    const activeKeyExistsFacets = keys(
      pickBy(filterState.keyExists, (val) => val === 1),
    ).map(parseFacetKey);

    const optionValue = option.value.startsWith('@')
      ? option.value.slice(1)
      : option.value;

    return activeKeyExistsFacets.some(
      ({ name, type }) => name === optionValue && type === option.dataType,
    );
  };

  const onAutocompleteOptionClick = ({
    option,
  }: {
    close: () => void;
    option: AutocompleteOption;
    type: 'mouse' | 'key';
  }) => {
    const { value: optionValue, optionType } = option;
    setSearchItems((prevSearchItem) => {
      const newSearchItem = { ...prevSearchItem };
      if (prevSearchItem.facetName) {
        newSearchItem.value = optionValue;
      } else {
        loadInitialOptionValues(option);
        newSearchItem.facetName = optionValue;
        setTimeout(() => inputRef.current?.focus(), 100);
      }
      newSearchItem.optionType = optionType;
      newSearchItem.dataType = option.dataType;

      const { facetName, operator, value } = newSearchItem;
      const searchTerm = `${facetName}${operator}${value ? `"${value}"` : '"'}`;
      setSearch(searchTerm);

      if (facetName && operator && value) {
        onEnter();
      }
      return newSearchItem;
    });
  };

  const loadInitialOptionValues = async (option: AutocompleteOption) => {
    if (autocompleteOptions[option.value]) {
      reRenderPanel({
        nextTyped: '',
        optionData: autocompleteOptions[option.value],
        activeOperator: searchItems.operator,
      });
      return;
    }

    let newOptionType = option.optionType;
    if (!newOptionType) {
      newOptionType = initialOptions.find(
        (opt) => opt.value === option.value,
      )?.optionType;
    }

    if (requestMap[newOptionType]) {
      setAutocompleteOptions((prev) => {
        reRenderPanel({
          nextTyped: '',
          optionData: { options: [], isLoading: true },
          activeOperator: searchItems.operator,
        });
        return {
          ...prev,
          [option.value]: { options: [], isLoading: true },
        };
      });

      const valueOptions = await requestMap[newOptionType](option);
      setAutocompleteOptions((prev) => {
        reRenderPanel({
          nextTyped: '',
          optionData: { options: valueOptions, isLoading: false },
          activeOperator: searchItems.operator,
        });
        return {
          ...prev,
          [option.value]: { options: valueOptions, isLoading: false },
        };
      });
    } else {
      reRenderPanel({
        nextTyped: '',
        optionData: { options: [], isLoading: false },
        activeOperator: searchItems.operator,
      });
    }
  };

  const checkSearchOrder = (
    nextSearch: string,
  ): {
    options: AutocompleteOption[];
    isLoading: boolean;
  } => {
    const {
      facetName: parsedFacetName,
      value: parsedValue,
      operator: parsedOperator,
    } = parseSearchQuery(nextSearch, 'partial');

    setSearchItems((prev) => ({
      ...prev,
      facetName: parsedFacetName,
      value: parsedValue,
      operator: parsedOperator || prev.operator,
    }));

    if (!parsedFacetName) {
      return { options: initialOptions || [], isLoading: false };
    }

    if (autocompleteOptions[parsedFacetName]) {
      return autocompleteOptions[parsedFacetName];
    } else {
      loadInitialOptionValues({
        label: parsedFacetName,
        value: parsedFacetName,
        optionType: '',
      });
    }

    return { options: [], isLoading: false };
  };

  const debouncedOnChangeInput = useCallback(debounce(onChangeInput, 300), [
    onChangeInput,
  ]);

  const onChange = (nextSearch: string) => {
    setSearch(nextSearch);
    const checkSearch = checkSearchOrder(nextSearch);
    if (checkSearch) {
      let typed = '';
      if (searchItems.facetName && searchItems.operator) {
        const splitSearchTerm = nextSearch.split(searchItems.operator);
        typed = splitSearchTerm[splitSearchTerm.length - 1];
        typed = typed.slice(1, typed.length - 1);
      } else {
        typed = nextSearch;
      }

      reRenderPanel({
        nextTyped: typed,
        optionData: checkSearch,
        activeOperator: searchItems.operator,
      });
    } else if (checkSearch === null) {
      closePanel();
    }

    if (nextSearch && markForDeletionToggle.value) {
      markForDeletionToggle.off();
    }

    debouncedOnChangeInput(nextSearch, applyFilterMethods);
  };

  const onEnter = () => {
    setSearchItems((prevState) => {
      const { facetName, operator, value } = prevState;
      if (facetName && operator && value) {
        applyFilterByFacets(prevState, 'filterByFacets');
        return prevState;
      }

      // if it is a valid query then filter
      const parsedQuery = parseSearchQuery(search, 'full');
      if (parsedQuery) {
        applyFilterByFacets(parsedQuery, 'filterByFacets');
        return prevState;
      }

      // if facetName exist and value is null and its label
      if (facetName && !value) {
        loadInitialOptionValues({
          label: facetName,
          value: facetName,
          optionType: '',
        });
        return prevState;
      }

      // if none of above then do grep search
      if (search) {
        applyFilterSearchTerms(search, 'searchTerms');
        return prevState;
      }

      return prevState;
    });
  };

  const applyFilterByFacets = (
    facetObj: SearchItemProps,
    filterKey: string,
  ) => {
    const { operator } = facetObj;
    if (operator === '!=' || operator === '=') {
      onApplyFilterMap.sidebarFilters(facetObj, editSearch, applyFilterMethods);
      closePanel();
      setSearch('');
      clearSearchItem();
      return;
    }

    onApplyFilterMap.filterByFacets(facetObj, editSearch, applyFilterMethods);
    closePanel();
    setSearch('');
    clearSearchItem();
  };

  const applyFilterSearchTerms = (nextSearch: string, filterKey: string) => {
    const trimmedSearch = nextSearch.trim();

    if (trimmedSearch) {
      const { operator, search } = parseSearchTerm(
        trimmedSearch,
        searchItems.operator,
        operatorMap,
      );

      if (
        search?.startsWith('"') &&
        search?.endsWith('"') &&
        search?.startsWith(`"key exists`)
      ) {
        const key = search.replace(/"/g, '').replace('key exists', '').trim();
        const option = initialOptions.find((opt) => opt.value === key);
        applyFilterKeyExists({
          label: key,
          value: key,
          dataType: option?.dataType || 'STRING',
        });
        return;
      }

      const newSearch = `${operator}:${search}`;
      if (onApplyFilterMap.searchTerms) {
        onApplyFilterMap.searchTerms(newSearch, editSearch, applyFilterMethods);
        return;
      }

      if (editSearch) {
        const { index, filterKey: prevFilterKey } = editSearch;
        if (prevFilterKey) {
          updateFilterByIndex(index, prevFilterKey, newSearch);
        }
      } else {
        addFilter(filterKey, newSearch);
      }
    }

    closePanel();
    clearSearchItem();
    setSearch('');
  };

  const applyFilterKeyExists = (option: AutocompleteOption) => {
    if (onApplyFilterMap.keyExists && option) {
      onApplyFilterMap.keyExists(option, applyFilterMethods);
    }
    closePanel();
    clearSearchItem();
    setSearch('');
  };

  const closePanel = () => {
    setActivePanel(defaultPanelData);
    focusToggle.off();
    if (inputRef.current) inputRef.current.blur();
  };

  const onClick = () => {
    if (!focusToggle.value) {
      const input = inputRef.current;
      if (input) {
        input.focus();
        focusToggle.on();
        onChange(search);
      }
    }
  };

  const clearSearchItem = () => {
    setSearchItems({ facetName: '', operator: '=', optionType: '', value: '' });
    setEditSearch(null);
  };

  const reRenderPanel = (renderData: {
    nextTyped: string;
    optionData: FilterPanelProps['optionData'];
    activeOperator: string;
  }) => {
    const { activeOperator, optionData, nextTyped } = renderData;

    setActivePanel((prev) => {
      return { activeOperator, optionData, typed: nextTyped };
    });
  };

  const updateActiveOperator = (operator: string) => {
    setSearchItems((prevState) => {
      const newState = { ...prevState };
      newState.operator = operator;
      const parsedValue = parseValueFromQuery(search);
      const closingQuote = getEndDoubleQuote(search, parsedValue);
      if (newState.facetName) {
        setSearch(
          `${newState.facetName}${operator}"${parsedValue}${closingQuote}`,
        );
      }

      return newState;
    });
    setActivePanel((prev) => ({ ...prev, activeOperator: operator }));
    focusToggle.on();
  };

  useKeyboardShortcut({
    Escape: {
      callback: () => {
        setActivePanel(defaultPanelData);
        focusToggle.off();
        markForDeletionToggle.off();
      },
      requiresModifier: false,
    },
    '/': {
      callback: (event) => {
        if (event.target === inputRef.current) return;

        if (!focusToggle.value) {
          focusToggle.on();
          setTimeout(() => inputRef.current?.focus(), 10);
        }
      },
      requiresModifier: true,
    },
  });

  return {
    activePanel,
    applyFilterKeyExists,
    autocompleteOptions,
    clearSearchItem,
    closePanel,
    editSearch,
    focusToggle,
    initialOptions,
    inputRef,
    isOptionAlreadyApplied,
    markForDeletionToggle,
    onAutocompleteOptionClick,
    onChange,
    onClick,
    onEnter,
    onFoucs,
    onScrollToBottom: () => onScrollToBottom(applyFilterMethods),
    operatorMap,
    reRenderPanel,
    requestMap,
    search,
    searchBarRef,
    setActivePanel,
    setAutocompleteOptions,
    setEditSearch,
    setSearch,
    setSearchItems,
    updateActiveOperator,
  };
};

export default useAutocompleteState;
