import classname from 'classnames';
import { AutocompleteOption, Input } from 'components';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { X, Plus } from 'react-feather';
import { convertNumberToReadableUnit } from 'utils';

const MultiselectWithoutDropdown = ({
  className,
  maxLength = 20,
  onChange,
  options,
  placeholder,
  value,
}: {
  className?: string;
  maxLength?: number;
  onChange: (value: string[]) => void;
  options: AutocompleteOption[];
  placeholder?: string;
  value: string[];
}): ReactElement => {
  const [inputValue, setInputValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const labelsByValue: AutocompleteOption[] = useMemo(() => {
    return value.map((valueItem: string) => ({
      value: valueItem,
      label:
        options?.find((option) => option.value === valueItem)?.label ||
        valueItem,
    }));
  }, [value]);

  const handleRemove = (value: string) => {
    const newSelectedOptions = selectedOptions.filter(
      (option) => option !== value,
    );
    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  const handleAdd = (option: AutocompleteOption) => {
    const newSelectedOptions = [...selectedOptions, option.value];
    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  const unselectedOptions = useMemo(() => {
    if (selectedOptions.length === 0) return options;
    return options.filter((option) => !selectedOptions.includes(option.value));
  }, [options, selectedOptions, value]);

  const filteredOptions = useMemo(() => {
    let newOptions = [];
    if (inputValue === '' || selectedOptions.length === 0) {
      newOptions = unselectedOptions;
    } else {
      newOptions = unselectedOptions.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()),
      );
    }

    return newOptions.slice(0, maxLength);
  }, [inputValue, unselectedOptions]);

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  return (
    <div
      className={classname({
        className,
        'multiselect-without-dropdown': true,
      })}
    >
      <div className="multiselect-without-dropdown__container">
        {labelsByValue.map(({ label, value }, idx) => (
          <div className="chip" key={idx}>
            <span>{label}</span>
            <button
              className="multiselect-without-dropdown__container__selected"
              onClick={() => handleRemove(value)}
            >
              <X />
            </button>
          </div>
        ))}
        <div className="multiselect-without-dropdown__container__input">
          <Input
            className="input--no-border"
            onChange={(val) => setInputValue(val)}
            placeholder={placeholder}
            value={inputValue}
            type="text"
          />
        </div>
        <div className="multiselect-without-dropdown__container__close">
          <X
            onClick={() => {
              setInputValue('');
              onChange([]);
            }}
          />
        </div>
      </div>
      <div className="multiselect-without-dropdown__options">
        {filteredOptions.map((option, idx) => {
          return (
            <div className="chip" key={idx}>
              <span>
                {option.label}
                {option.count &&
                  ` (${convertNumberToReadableUnit(option.count)})`}
              </span>
              <button
                className="multiselect-without-dropdown__options__add"
                onClick={() => handleAdd(option)}
              >
                <Plus />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MultiselectWithoutDropdown;
