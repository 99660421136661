import classnames from 'classnames';
import { useAutocompleteState } from 'hooks';
import React, { ReactElement } from 'react';
import { X } from 'react-feather';
import { FilterTagsProps } from 'types/Filter';

const SearchBarTags = ({
  tags,
  autocompleteState,
}: {
  autocompleteState: ReturnType<typeof useAutocompleteState>;
  tags: FilterTagsProps[];
}): ReactElement => {
  const { markForDeletionToggle } = autocompleteState;
  return (
    <div
      className="search-bar__container__tags"
      style={{ marginRight: tags.length > 0 ? 16 : 6 }}
    >
      {tags.map((tag, i) => {
        const onRemove = (e) => {
          e.stopPropagation();
          tag.onClick();
        };

        const onEdit = (e) => {
          e.stopPropagation();
          tag.onEdit();
        };
        return (
          <div
            key={i}
            className={classnames({
              chip: true,
              'chip--marked-for-deletion':
                markForDeletionToggle.value && i === tags.length - 1,
            })}
            title={typeof tag.label === 'string' ? tag.label : undefined}
          >
            {tag.onEdit ? (
              <button
                className="chip__label chip__label--clickable"
                onClick={onEdit}
              >
                {tag.label}
              </button>
            ) : (
              <span className="chip__label">{tag.label}</span>
            )}
            <button className="chip__button" onClick={onRemove} type="button">
              <X size={12} />
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default SearchBarTags;
