export enum EntityTypes {
  Pod = 'Pod',
  ReplicaSet = 'ReplicaSet',
  Deployment = 'Deployment',
  Service = 'Service',
  Node = 'Node',
  Cluster = 'Cluster',
  Job = 'Job',
  CronJob = 'CronJob',
  DaemonSet = 'DaemonSet',
  StatefulSet = 'StatefulSet',
  PersistentVolume = 'PersistentVolume',
  PersistentVolumeClaim = 'PersistentVolumeClaim',
  Role = 'Role',
  RoleBinding = 'RoleBinding',
  ClusterRole = 'ClusterRole',
  ClusterRoleBinding = 'ClusterRoleBinding',
  ServiceAccount = 'ServiceAccount',
  Ingress = 'Ingress',
  Namespace = 'Namespace',
}
