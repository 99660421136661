import { DataTransformerConfig } from 'utils/DataTransformer/types';

import promqlQueryV2 from './promqlQueryV2';

import promqlQueryRangeV3, {
  PromqlQueryRangeV3Props,
} from './promqlQueryRangeV3';

export type PromqlQueryRangeV3MultipleProps = {
  instant: boolean;
  promqlWithMeta: PromqlQueryRangeV3Props[];
  mainTransformer: DataTransformerConfig[];
};

const promqlQueryRangeV3Multiple = async <T>({
  instant,
  promqlWithMeta,
  mainTransformer,
}: PromqlQueryRangeV3MultipleProps): Promise<T> => {
  let data: any;
  if (instant) {
    data = await Promise.all(
      promqlWithMeta.map(({ addEncoding, meta, promqlQuery, transformer }) =>
        promqlQueryV2({
          meta,
          promqlQuery,
          transformer,
          addEncoding,
        }),
      ),
    );
  } else {
    data = await Promise.all(
      promqlWithMeta.map((p) => promqlQueryRangeV3({ ...p })),
    );
  }

  if (mainTransformer) {
    const transformed = mainTransformer.reduce(
      (prevData, item) => item.func(prevData),
      data,
    );
    return transformed as unknown as T;
  }
  return data as unknown as T;
};

export default promqlQueryRangeV3Multiple;
