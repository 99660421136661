import { ChipWithLabel } from 'components';
import React, { useMemo } from 'react';
import { formatNumber, formatPercentage } from 'utils';
import { colors } from './constants';
import { CompareAttributeCount } from './types';

type Props = {
  compareAttributeCount: CompareAttributeCount;
};

const getTotalByAttribute = (
  compareAttributeCount: CompareAttributeCount,
  property: 'baselineCount' | 'selectionCount',
) =>
  compareAttributeCount.counts.reduce((sum, count) => sum + count[property], 0);

const sections: {
  label: string;
  color: string;
  property: 'baseline' | 'selection';
}[] = [
  { label: 'Baseline', color: colors.baseline, property: 'baseline' },
  { label: 'Selection', color: colors.selection, property: 'selection' },
];

const TracesHeatmapChartsGridItemTooltip = ({
  compareAttributeCount,
}: Props) => {
  const { name } = compareAttributeCount;

  return (
    <div>
      {sections.map((section) => {
        const total = getTotalByAttribute(
          compareAttributeCount,
          `${section.property}Count`,
        );

        const totalCount =
          compareAttributeCount[`${section.property}TotalCount`];

        return (
          <div className="mt-3" key={section.property}>
            <div>
              <ChipWithLabel
                color={section.color}
                label={<span className="font-semibold">{section.label}</span>}
              />
            </div>
            <div>
              {`Bars represent `}
              <span className="font-semibold">
                {formatPercentage(total / totalCount)}
              </span>
              {' of total ('}
              <span className="font-semibold">{formatNumber(totalCount)}</span>
              {`) spans in ${section.property}`}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TracesHeatmapChartsGridItemTooltip;
