import classnames from 'classnames';
import { Portal } from '@radix-ui/react-portal';
import { FacetAccordion } from 'components';

import { useOnClickOutside } from 'hooks';
import React, { useEffect, useState, useRef } from 'react';

const HELP_TEXT_WIDTH = 300;

const HorizontallyExpandOnHoverAccordion = ({
  className,
  renderTrigger,
  renderContent,
  triggerClassName,
  expanded,
  onExpansionChange,
  isFocusCaptured,
  hovered,
  setHovered,
  appRef,
  onWheel,
  portalClassName,
  containerRef,
  fixedContainerRef,
  leftSidebarRef,
  info,
  renderedName,
}: {
  className?: string;
  renderTrigger: (
    // Only passed-in for "unhovered" accordion to detect if name is truncated
    facetNameRef?: React.MutableRefObject<HTMLElement>,
  ) => React.ReactNode;
  renderContent: (opts: {
    hoveredAccordionRef?: React.MutableRefObject<HTMLDivElement>;
    scrollDivRef?: React.MutableRefObject<HTMLDivElement>;
  }) => React.ReactNode;
  triggerClassName?: string;
  expanded: boolean;
  onExpansionChange: (updatedExpanded: boolean) => void;
  portalClassName?: string;
  isFocusCaptured?: () => boolean;
  hovered: boolean;
  setHovered: (isHovered: boolean) => void;
  appRef?: React.MutableRefObject<HTMLDivElement>;
  onWheel: (event: React.WheelEvent<HTMLDivElement>) => void;
  containerRef: React.MutableRefObject<HTMLDivElement>;
  fixedContainerRef: React.MutableRefObject<HTMLDivElement>;
  leftSidebarRef: React.MutableRefObject<HTMLDivElement>;
  info?: string;
  renderedName: React.ReactNode;
}) => {
  const notHoveredScrollDivRef = useRef<HTMLDivElement | null>(null);
  const facetNameRef = useRef<HTMLElement | null>(null);
  const hoveredAccordionRef = useRef<HTMLDivElement | null>(null);

  const [isFacetNameTruncated, setIsFacetNameTruncated] = useState(false);

  useOnClickOutside({
    onClick: () => {
      setHovered(false);
    },
    ref: hoveredAccordionRef,
    shouldUseClick: true,
  });

  useEffect(() => {
    const checkTruncation = () => {
      const element = facetNameRef.current;
      if (element) {
        setIsFacetNameTruncated(element.scrollWidth > element.clientWidth);
      }
    };
    checkTruncation();
    window.addEventListener('resize', checkTruncation);
    return () => window.removeEventListener('resize', checkTruncation);
  }, []);

  const handleExpansionChange = (updatedExpanded: boolean) => {
    // Exit hovered mode upon collapse
    if (updatedExpanded === false && !isFocusCaptured()) {
      setHovered(false);
    }
    onExpansionChange(updatedExpanded);
  };

  const containerRect = containerRef.current?.getBoundingClientRect();
  const leftSidebarRect = leftSidebarRef.current?.getBoundingClientRect();
  const leftSidebarWidth = leftSidebarRect?.width || 1;

  return (
    <div
      className={classnames({
        relative: true,
        'my-1': true,
        [className]: className,
      })}
      ref={containerRef}
      onMouseLeave={() => {
        /**
         * This block is triggered when the mouse leaves the accordion component.
         * It scrolls the 'not hovered values' div back to the top.
         * This behavior is intended to prevent any jumpiness or abrupt changes in the
         * scroll position when the accordion is re-hovered.
         */
        if (notHoveredScrollDivRef.current) {
          notHoveredScrollDivRef.current.scrollTo({
            top: 0,
          });
        }
        if (!isFocusCaptured()) {
          setHovered(false);
        }
      }}
      onWheel={onWheel}
    >
      <FacetAccordion
        renderTrigger={() => (
          <div
            onMouseEnter={() => {
              if (isFacetNameTruncated) {
                setHovered(true);
              }
            }}
            className="w-full min-w-0"
          >
            {renderTrigger(facetNameRef)}
          </div>
        )}
        renderContent={() => (
          <div
            onMouseEnter={() => {
              setHovered(true);
            }}
          >
            {renderContent({
              scrollDivRef: notHoveredScrollDivRef,
            })}
          </div>
        )}
        expanded={expanded}
        setExpanded={handleExpansionChange}
        isVisuallyHidden={hovered}
        triggerClassName={triggerClassName}
      />
      {hovered && (
        <Portal container={appRef?.current}>
          <div
            ref={fixedContainerRef}
            className={classnames({
              'fixed z-[2] bg-background shadow-4': true,
              [portalClassName]: portalClassName,
            })}
            style={{
              top: `${containerRect.top + window.scrollY}px`,
              left: `${containerRect.left}px`,
              // allocate 300px for info(help text)
              width:
                isFacetNameTruncated && hovered
                  ? 'max-content'
                  : `${
                      leftSidebarWidth * 1.5 +
                      (info?.length > 0 ? HELP_TEXT_WIDTH : 0)
                    }px`,
              minWidth: info?.length > 0 ? '740px' : '440px',
            }}
          >
            {(() => {
              const renderedAccordion = (
                <FacetAccordion
                  renderTrigger={renderTrigger}
                  renderContent={() =>
                    renderContent({
                      hoveredAccordionRef,
                    })
                  }
                  expanded={expanded}
                  setExpanded={handleExpansionChange}
                  isVisuallyHidden={false}
                  triggerClassName={triggerClassName}
                  accordionRef={hoveredAccordionRef}
                />
              );
              const shouldShowHelpText = info?.length > 0;
              if (!shouldShowHelpText) {
                return renderedAccordion;
              }
              return (
                <div className="flex">
                  <div className="grow overflow-hidden">
                    {renderedAccordion}
                  </div>
                  <div className="w-[300px] shrink-0 border-l border-l-border p-4">
                    <div className="mb-1 font-noto text-xs font-semibold leading-[19.5px]">
                      {renderedName}
                    </div>
                    <div className="text-xxs font-xxs font-noto">{info}</div>
                  </div>
                </div>
              );
            })()}
          </div>
        </Portal>
      )}
    </div>
  );
};
export default HorizontallyExpandOnHoverAccordion;
