import classNames from 'classnames';
import { DelayedHoverComponent, ScrollView } from 'components';
import React, {
  MouseEvent,
  ReactElement,
  useMemo,
  useRef,
  useState,
} from 'react';
import { checkAnomalySeriesBandByLabel } from 'utils';

import { SeriesIcon } from '../components';
import { UPlotConfig } from '../types';
import { calculateTextWidth } from '../utils';
import LegendKeyValueFormatter from './LegendKeyValueFormatter';

const MIN_LEGEND_HEIGHT = 24;

const LegendsCompactOneLine = ({
  config,
  onItemClick,
  onFocusSeries,
  updateChartSize,
  legendHeight,
}: {
  config: UPlotConfig;
  onItemClick: (e: MouseEvent<HTMLLIElement>, idx: number) => void;
  onFocusSeries: (idx: number) => void;
  updateChartSize: (height: number) => void;
  legendHeight?: number;
}): ReactElement => {
  const [legendHeightState, setLegendHeightState] = useState(legendHeight);
  const lastHoveredSeriesRef = useRef<number | null>(null);

  const shownLabelsIndex = useMemo(() => {
    const { series } = config;
    let labelWidths = 60;

    for (let i = 0; i < series.length; i++) {
      const label = series[i].label;
      if (!label) {
        continue;
      }
      labelWidths = labelWidths += calculateTextWidth(label, 24);
      if (labelWidths > config.width) {
        // label shows up only if there are more than 1 series, 0th index is a placeholder series for timestamps.
        if (i === 1) {
          continue;
        }
        return i - 1;
      }
    }
  }, [config]);

  const onLegendItemHover = (idx: number, isHovered: boolean) => {
    if (isHovered) {
      onFocusSeries(idx);
    } else {
      lastHoveredSeriesRef.current = idx;
    }
  };

  return (
    <ScrollView
      height={'auto'}
      maxHeight={
        legendHeightState > MIN_LEGEND_HEIGHT ? legendHeightState : undefined
      }
      width={config.width}
      scrollIndicator={false}
    >
      <DelayedHoverComponent
        className="min-h-[30px]"
        onMouseLeft={() => {
          lastHoveredSeriesRef.current = null;
          onFocusSeries(null);
        }}
        onDelayComplete={() => {
          if (lastHoveredSeriesRef.current !== null) {
            onFocusSeries(lastHoveredSeriesRef.current);
          }
        }}
      >
        {({ isHovered }) => (
          <div
            className={classNames({
              'uplot__legends__compact__one-line': true,
              'uplot__legends__compact__one-line--hovered': isHovered,
              'scrollbar-thin scrollbar-thumb-border scrollbar-track-background':
                isHovered,
            })}
            style={
              {
                '--timeseries-legend-height': `${legendHeightState || 150}px`,
              } as React.CSSProperties
            }
          >
            {config?.series.map((s, idx) => {
              if (!s.label) return null;
              if (checkAnomalySeriesBandByLabel(s.label)) {
                return null;
              }

              return (
                <div
                  style={{ opacity: s.show ? 1 : 0.5 }}
                  className="uplot__legends__compact__listitem"
                  key={idx}
                  onClick={(e) => onItemClick(e, idx)}
                  onMouseEnter={() => onLegendItemHover(idx, isHovered)}
                >
                  <SeriesIcon backgroundColor={s.stroke || s._stroke} />
                  <LegendKeyValueFormatter text={s.label} />
                </div>
              );
            })}
            {Boolean(shownLabelsIndex) && (
              <div className="uplot__legends__compact__one-line__more">{`+${
                config.series.length - shownLabelsIndex - 1
              }`}</div>
            )}
          </div>
        )}
      </DelayedHoverComponent>
    </ScrollView>
  );
};

export default LegendsCompactOneLine;
