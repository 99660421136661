import React, { useMemo } from 'react';
import { Dashboard } from 'screens/Dashboard';
import pod from '../metricsJsonData/pod.json';
import cluster from '../metricsJsonData/cluster.json';
import namespace from '../metricsJsonData/namespace.json';
import node from '../metricsJsonData/node.json';
import deployment from '../metricsJsonData/deployment.json';
import job from '../metricsJsonData/job.json';
import replicaset from '../metricsJsonData/replicaset.json';
import daemonset from '../metricsJsonData/daemonset.json';
import statefulset from '../metricsJsonData/statefulset.json';
import service from '../metricsJsonData/service.json';

type Props = {
  entityType: string;
  entity: any;
};

const KubernetesMetrics = ({ entityType, entity }: Props) => {
  const updateModelTemplateValues = (
    templateValues: { [key: string]: any },
    updateKeys: string[],
    model: any,
  ) => {
    if (!model) return;
    const newModel = structuredClone(model);
    updateKeys.forEach((key) => {
      const templateIdx = newModel.templating.list.findIndex(
        (item: any) => item.label === key,
      );
      const template = newModel.templating.list[templateIdx];
      if (!template) return;

      newModel.templating.list[templateIdx] = {
        ...template,
        current: {
          text: templateValues[key],
          value: templateValues[key],
          selected: true,
        },
      };
    });
    return newModel;
  };

  const fetchJsonBasedOnEntity = () => {
    const tags = entity.tags;
    const clusterTag = tags.find((tag) => tag.includes('kube_cluster_name'));
    const namespaceTag = tags.find((tag) => tag.includes('kube_namespace'));
    const clusterName = clusterTag ? clusterTag.split(':')[1] : '';
    const namespaceValue = namespaceTag ? namespaceTag.split(':')[1] : '';

    const templateValuesBitmap: { [key: string]: any } = {
      kube_cluster_name: clusterName,
      kube_namespace: namespaceValue,
      pod_name: entity.metadata?.name,
      kube_node: entity.metadata?.name,
      kube_deployment: entity.metadata?.name,
      kube_replica_set: entity.metadata?.name,
      kube_job: entity.metadata?.name,
      kube_daemon_set: entity.metadata?.name,
      kube_stateful_set: entity.metadata?.name,
      kube_service: entity.metadata?.name,
    };

    switch (entityType) {
      case 'Pod':
        const podModel = updateModelTemplateValues(
          templateValuesBitmap,
          ['kube_namespace', 'pod_name', 'kube_cluster_name'],
          pod,
        );
        return podModel;
      case 'Cluster':
        return updateModelTemplateValues(
          templateValuesBitmap,
          ['kube_cluster_name'],
          cluster,
        );
      case 'Namespace':
        return updateModelTemplateValues(
          templateValuesBitmap,
          ['kube_namespace', 'kube_cluster_name'],
          namespace,
        );
      case 'Node':
        return updateModelTemplateValues(
          templateValuesBitmap,
          ['kube_node'],
          node,
        );
      case 'Deployment':
        return updateModelTemplateValues(
          templateValuesBitmap,
          ['kube_namespace', 'kube_deployment', 'kube_cluster_name'],
          deployment,
        );
      case 'ReplicaSet':
        return updateModelTemplateValues(
          templateValuesBitmap,
          ['kube_namespace', 'kube_replica_set', 'kube_cluster_name'],
          replicaset,
        );
      case 'Job':
        return updateModelTemplateValues(
          templateValuesBitmap,
          ['kube_namespace', 'kube_job', 'kube_cluster_name'],
          job,
        );
      case 'DaemonSet':
        return updateModelTemplateValues(
          templateValuesBitmap,
          ['kube_namespace', 'kube_daemon_set', 'kube_cluster_name'],
          daemonset,
        );
      case 'StatefulSet':
        return updateModelTemplateValues(
          templateValuesBitmap,
          ['kube_namespace', 'kube_stateful_set', 'kube_cluster_name'],
          statefulset,
        );
      case 'Service':
        return updateModelTemplateValues(
          templateValuesBitmap,
          ['kube_namespace', 'kube_service', 'kube_cluster_name'],
          service,
        );
      default:
        return;
    }
  };

  const jsonModel = useMemo(() => {
    return fetchJsonBasedOnEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (jsonModel) {
    return (
      <Dashboard
        disableEditPanel={true}
        disableDeletePanel={true}
        disableFilter={true}
        jsonModal={jsonModel}
        hideSidebar={true}
        hideHeader={true}
        showFilterTimepicker={true}
      />
    );
  }

  return null;
};

export default KubernetesMetrics;
