import { AutocompleteV2, Button, InputWithValidatorV3 } from 'components';
import React, { ReactElement, Dispatch, SetStateAction } from 'react';
import { MdDelete as DeleteIcon } from 'react-icons/md';

import { PolicyScopeFilterProps } from './types';
import { getPolicyScopeFilterOperatorOptions } from './utils';

const PolicyManagementEditFilter = ({
  scopeFilters,
  setScopeFilters,
}: {
  scopeFilters: PolicyScopeFilterProps[];
  setScopeFilters: Dispatch<SetStateAction<PolicyScopeFilterProps[]>>;
}): ReactElement => {
  const handleOperatorChange = (idx: number, key: string, value: string) => {
    setScopeFilters((prev) => {
      const newFilters = [...prev];
      newFilters[idx][key] = value;
      return newFilters;
    });
  };

  const handleAddMatcher = () => {
    setScopeFilters((prev) => [...prev, { key: '', op: '=', value: '' }]);
  };

  const handleDeleteMatcher = (idx: number) => {
    setScopeFilters((prev) => {
      const newFilters = [...prev];
      newFilters.splice(idx, 1);
      return newFilters;
    });
  };

  return (
    <div className="admin__row-muted rounded-sm pb-2 pl-2">
      {scopeFilters.map((filter, idx) => (
        <div key={idx} className="flex items-center gap-1 pt-2">
          <div>
            <label>Key</label>
            <div>
              <InputWithValidatorV3
                size="6"
                type="text"
                variant="default"
                onChange={(e) => handleOperatorChange(idx, 'key', e)}
                placeholder="Key"
                value={filter.key}
              />
            </div>
          </div>
          <div>
            <label>Operator</label>
            <div>
              <AutocompleteV2
                className="w-[80px]"
                options={getPolicyScopeFilterOperatorOptions()}
                onChange={(e: string) => handleOperatorChange(idx, 'op', e)}
                value={filter.op}
              />
            </div>
          </div>
          <div>
            <label>Value</label>
            <div>
              <InputWithValidatorV3
                size="6"
                type="text"
                variant="default"
                onChange={(e) => handleOperatorChange(idx, 'value', e)}
                placeholder="Value"
                value={filter.value}
              />
            </div>
          </div>
          <div className="pt-2.5">
            <Button
              variant="ghost-destructive"
              size="sm"
              className="mt-2 px-2"
              onClick={() => handleDeleteMatcher(idx)}
            >
              <DeleteIcon size={16} />
            </Button>
          </div>
        </div>
      ))}
      <Button
        variant="outline"
        size="sm"
        className="mt-2"
        onClick={handleAddMatcher}
      >
        Add filter
      </Button>
    </div>
  );
};

export default PolicyManagementEditFilter;
