import React from 'react';
import classnames from 'classnames';
import { Icon } from 'components';

const FooterChip = ({ children }: { children: React.ReactNode }) => {
  return (
    <span
      className={classnames(
        'rounded border border-border bg-background',
        'm-0.5 px-0.5 font-[11px] text-text',
        'inline-flex h-[20px] min-w-[18px] items-center justify-center',
      )}
    >
      {children}
    </span>
  );
};

const PadX = ({ children }: { children: React.ReactNode }) => (
  <span className="mx-1 inline-flex">{children}</span>
);

const FooterInfoItem = ({
  chips,
  postfix,
}: {
  chips: React.ReactNode;
  postfix: string;
}) => {
  return (
    <div className="inline-flex items-center whitespace-nowrap">
      {chips}
      <span className="mb-0.5  ml-0.5 text-xs text-text">{postfix}</span>
    </div>
  );
};

const EnterToUpdateQuery = () => {
  return (
    <FooterInfoItem
      chips={
        <FooterChip>
          <PadX>Enter</PadX>
        </FooterChip>
      }
      postfix="to update query"
    />
  );
};

const EscToClose = () => {
  return (
    <FooterInfoItem
      chips={
        <FooterChip>
          <PadX>Esc</PadX>
        </FooterChip>
      }
      postfix="to close"
    />
  );
};

const ArrowsToNavigate = () => {
  return (
    <FooterInfoItem
      chips={
        <>
          <FooterChip>
            <Icon icon="arrow-up" size="xs" />
          </FooterChip>
          <FooterChip>
            <Icon icon="arrow-down" size="xs" />
          </FooterChip>
        </>
      }
      postfix="to navigate"
    />
  );
};

const OperatorsToUpdateOperator = () => {
  return (
    <FooterInfoItem
      chips={
        <>
          <FooterChip>
            <PadX>=</PadX>
          </FooterChip>
          <FooterChip>
            <PadX>!</PadX>
          </FooterChip>
        </>
      }
      postfix="to update operator"
    />
  );
};

const SpaceToAddOrClause = () => {
  return (
    <FooterInfoItem
      chips={
        <FooterChip>
          <PadX>Space</PadX>
        </FooterChip>
      }
      postfix="to add OR clause"
    />
  );
};

const SuggestionsFooter = ({
  shouldShowAddOrClauseInfo,
  shouldShowOperatorsInfo,
}: {
  shouldShowAddOrClauseInfo: boolean;
  shouldShowOperatorsInfo: boolean;
}): React.ReactElement => {
  return (
    <footer
      className={classnames(
        'flex w-full gap-2',
        'bg-background-secondary p-2',
        'border-t border-border',
        'text-[12px] text-text-secondary',
      )}
    >
      <div className="flex gap-8">
        <ArrowsToNavigate />
        <EnterToUpdateQuery />
        <EscToClose />
        {shouldShowOperatorsInfo && <OperatorsToUpdateOperator />}
        {shouldShowAddOrClauseInfo && <SpaceToAddOrClause />}
      </div>
    </footer>
  );
};

export default SuggestionsFooter;
