import { Button, LogsMetricsQueryBuilder } from 'components';
import { useLogsMetricsQueryState } from 'hooks';
import React, { ReactElement, useCallback } from 'react';
import { Plus } from 'react-feather';
import { ALLOW_ONLY_ONE_ACTIVE_SEARCH } from 'screens/Traces/utils';
import { DashboardPanelType, LogsState } from 'types';

const LogsAnalyticsQueryBuilder = ({
  activeVisualization,
  logsMetricsQueryState,
  logsState,
}: {
  activeVisualization?: string;
  logsMetricsQueryState: ReturnType<typeof useLogsMetricsQueryState>;
  logsState: LogsState;
}): ReactElement => {
  const addQueryHandler = useCallback(() => {
    const deActivateOtherQueries = ALLOW_ONLY_ONE_ACTIVE_SEARCH.includes(
      activeVisualization as unknown as DashboardPanelType,
    );
    if (deActivateOtherQueries) {
      logsMetricsQueryState.deactivateAllFormulas();
    }
    logsMetricsQueryState.addQuery(deActivateOtherQueries);
  }, [activeVisualization, logsMetricsQueryState]);

  const addFormulaHandler = useCallback(() => {
    const deActivateOtherQueries = ALLOW_ONLY_ONE_ACTIVE_SEARCH.includes(
      activeVisualization as unknown as DashboardPanelType,
    );
    if (deActivateOtherQueries) {
      logsMetricsQueryState.deactivateAllQueries();
    }
    logsMetricsQueryState.addFormula(deActivateOtherQueries);
  }, [activeVisualization, logsMetricsQueryState]);

  return (
    <div className="min-w-0 flex-1 pb-2">
      <LogsMetricsQueryBuilder
        activeVisualization={activeVisualization}
        allowMultipleQueries={true}
        blockedFunctionsCategories={
          activeVisualization === DashboardPanelType.TABLE ||
          activeVisualization === DashboardPanelType.TOP_LIST ||
          activeVisualization === DashboardPanelType.PIECHART
            ? ['Algorithms']
            : []
        }
        logsMetricsQueryState={logsMetricsQueryState}
        logsState={logsState}
      />
      <Button variant="default" size="sm" onClick={addQueryHandler}>
        <Plus size={16} /> Add Query
      </Button>
      <Button
        variant="default"
        size="sm"
        className="ml-2"
        onClick={addFormulaHandler}
      >
        <Plus size={16} /> Add Formula
      </Button>
    </div>
  );
};

export default LogsAnalyticsQueryBuilder;
