import { useToggle } from 'hooks';
import React, { ReactNode, createContext, useContext } from 'react';

const AccordianContext = createContext(null);

export const AccordianContextProvider = ({
  children,
  defaultOpen = false,
}: {
  children: ReactNode;
  defaultOpen?: boolean;
}) => {
  const isAccordianOpen = useToggle(defaultOpen);

  return (
    <AccordianContext.Provider value={{ isAccordianOpen }}>
      {children}
    </AccordianContext.Provider>
  );
};

export const useAccordianContext = () => useContext(AccordianContext);
