import { delimiter } from 'kfuse-constants';
import {
  DashboardPanelType,
  DateSelection,
  LogsMetricForumlaProps,
  LogsMetricQueryProps,
  LogQLWithMetaProps,
  QueryLangType,
} from 'types';
import {
  buildLogql,
  buildKfuseQl,
  buildLogqlFormula,
  getRollupToSecond,
  getStepFromDate,
  getVectorAggrLegend,
  getRollupByVisualization,
  getForecastLookbackAndPredictDate,
  kfuseqlBuilderFormula,
  calcAgileRobustAnomalyTimeRange,
} from 'utils';
import { getUsedQueryKeysInFormulas } from 'utils/FormulaValidator/validateLogqlFormulaExpression';
import { KfuseQlFunction } from 'utils/KfuseqlBuilder/types';

import { hasQueryAdvanceFunction } from './logsQueryBuilder';

const getLogQLWithMetaToLoad = ({
  customerFilter,
  dataFormat,
  date,
  formulas,
  formulaOnly,
  instant,
  queries,
  queryLangType,
}: {
  customerFilter?: { key: string; value: string };
  dataFormat: DashboardPanelType;
  date: DateSelection;
  formulas: LogsMetricForumlaProps[];
  formulaOnly: boolean;
  instant: boolean;
  queries: LogsMetricQueryProps[];
  queryLangType: string;
}): LogQLWithMetaProps[] => {
  const logQLWithMeta: LogQLWithMetaProps[] = [];
  const { startTimeUnix, endTimeUnix } = date;

  const defaultStep = getRollupByVisualization(date, 'bar');
  const getStep = (query: LogsMetricQueryProps) => {
    if (instant) {
      return endTimeUnix - startTimeUnix;
    }
    if (query.step) return getRollupToSecond(query.step);
    return defaultStep;
  };

  const formulaExpressions = formulas.map((formula) => formula.expression);
  const usedKeysInFormulas = getUsedQueryKeysInFormulas(formulaExpressions);

  queries
    .filter((q) => q.isActive || usedKeysInFormulas.includes(q.queryKey))
    .forEach((query: LogsMetricQueryProps) => {
      if (!formulaOnly || usedKeysInFormulas.includes(query.queryKey)) {
        let logql = [];
        let kfuseQlOperation = '';
        let step = getStep(query);
        const { rangeAggregateGrouping, normalizeFunction } = query;
        const advanceFunc = hasQueryAdvanceFunction(query, queryLangType);
        let anomalySeasonality = undefined;
        let forecastSeasonality = undefined;

        if (query.showInput) {
          logql = [query.logql];
        } else {
          if (queryLangType === QueryLangType.LOGQL) {
            logql = buildLogql({
              customerFilter,
              queries: [query],
              step: `${step}s`,
            });
          } else {
            const seconds = endTimeUnix - startTimeUnix;
            const dateForForecast = getForecastLookbackAndPredictDate({
              forecastDuration: `${seconds}s`,
              lookbackDuration: `${seconds}s`,
              date,
              type: 'logs',
            });

            if (advanceFunc.isAnomaly) {
              const anomalyFuncParams = query.functions.find(
                (func) => func.name === KfuseQlFunction.ANOMALIES,
              )?.params;
              if (
                anomalyFuncParams &&
                anomalyFuncParams[0].value === 'agile-robust'
              ) {
                anomalySeasonality = anomalyFuncParams[1].value;
                const { anomalyStep } = calcAgileRobustAnomalyTimeRange(
                  anomalySeasonality,
                  date,
                );
                step = anomalyStep;
              }
            }

            if (advanceFunc.isForecast) {
              const forecastFuncParams = query.functions.find(
                (func) => func.name === KfuseQlFunction.FORECAST,
              )?.params;
              if (
                forecastFuncParams &&
                forecastFuncParams[0].value === 'seasonal'
              ) {
                forecastSeasonality = forecastFuncParams[1].value;
              }
              step = dateForForecast.step;
            }

            const kfuseQl = buildKfuseQl({
              customerFilter,
              date,
              queries: [query],
              step: `${step}s`,
              instant,
            });
            logql = kfuseQl.map((k) => k.kfuseQl);
            kfuseQlOperation = kfuseQl[0]?.operation;
          }
        }

        if (logql && logql[0]) {
          const labels =
            rangeAggregateGrouping?.map((label) => label.split(delimiter)[1]) ||
            [];
          const aggregateLegend = getVectorAggrLegend({
            query,
            selectedVal: query.vectorAggregate,
          });
          const legend = kfuseQlOperation
            ? kfuseQlOperation.slice(1)
            : aggregateLegend;

          logQLWithMeta.push({
            logql: logql[0],
            meta: {
              aggregate: legend,
              refId: query.queryKey,
              type: dataFormat,
              step: step,
              metricName: legend,
              labels,
              unit: normalizeFunction === 'duration' ? 's' : normalizeFunction,
              queryType: 'query',
              kfuseQlOperation,
              forecastSeasonality,
              anomalySeasonality,
              isActive: query.isActive,
              ...advanceFunc,
            },
            limit: query.limit,
            queryType: 'query',
          });
        }
      }
    });

  formulas.forEach((formula: LogsMetricForumlaProps) => {
    let logql: string[] | { kfuseQl: string; operation: string }[] = [];

    const usedQuery = queries.find((q) =>
      usedKeysInFormulas.includes(q.queryKey),
    );

    if (queryLangType === QueryLangType.LOGQL) {
      logql = buildLogqlFormula({
        customerFilter,
        formulas: [formula],
        queries,
        step: instant ? getStepFromDate({ date }) : `${usedQuery?.step}`,
      });
    }

    if (queryLangType === QueryLangType.KFUSEQL) {
      logql = kfuseqlBuilderFormula({
        customerFilter,
        date,
        formulas: [formula],
        queries,
        step: instant ? getStepFromDate({ date }) : `${usedQuery?.step}`,
        instant,
      });
    }

    if (logql && logql[0]) {
      logQLWithMeta.push({
        logql:
          queryLangType === QueryLangType.LOGQL ? logql[0] : logql[0].kfuseQl,
        meta: {
          aggregate: `Value_of_${formula.queryKey}`,
          refId: formula.queryKey,
          type: dataFormat,
          step: defaultStep,
          metricName: `formula_of_${formula.queryKey}`,
          unit: 'number',
          formulaExpression: formula.expression,
          queryType: 'formula',
          isActive: formula.isActive,
          kfuseQlOperation:
            queryLangType === QueryLangType.LOGQL ? '' : logql[0].operation,
        },
        queryType: 'formula',
      });
    }
  });

  return logQLWithMeta;
};

export default getLogQLWithMetaToLoad;
