import parseUnit from 'parse-unit';
import timestring from 'timestring';

const parseDurationToMs = (s: string) => {
  const [number, unit] = parseUnit(s);
  if (unit === 'ns') {
    return number / 1000000;
  }

  if (unit === 'μs') {
    return number / 1000;
  }

  try {
    return timestring(s, 'ms');
  } catch (e) {
    return parseFloat(s);
  }
};

export default parseDurationToMs;
