import fetchAdvanceFunctions from './fetchAdvanceFunctions';

const mutateAsmAutoAlert = ({
  kfSource,
  matcher,
  method,
}: {
  kfSource: string;
  matcher: string;
  method: 'POST' | 'DELETE';
}): Promise<any> => {
  return fetchAdvanceFunctions('api/v1/asm_auto_alerts', {
    headers: { 'Content-Type': 'application/json' },
    method: method,
    body: JSON.stringify({ matcher, kf_source: kfSource }),
  });
};

export default mutateAsmAutoAlert;
