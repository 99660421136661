import { Datepicker } from 'composite';
import {
  CursorStateProvider,
  Button,
  CheckboxWithLabel,
  MetricsQueryBuilder,
  ProductTour,
} from 'components';
import { useMetricsExplorerPageStateContext } from 'context/PageStateProvider';
import { useMetricsQueryStateV2 } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { IoIosWarning } from 'react-icons/io';
import { RefreshCw, X } from 'react-feather';
import {
  DashboardPanelType,
  DateSelection,
  ExplorerQueryProps,
  FormulaProps,
  QueryDataProps,
} from 'types';
import { refreshDate } from 'utils';

import MetricsCharts from './MetricsCharts';
import MetricsChartsCombined from './MetricsChartsCombined';

const emptyQueryData = {};

const getQueryDataByQueryId = (
  queryData: QueryDataProps,
  queryId: string,
): QueryDataProps => {
  const queryIdWithAnomaly = queryId + '_anomaly';
  if (queryData[queryIdWithAnomaly]) {
    const queryIdWithAnomalyKeys = ['_upper', '_lower', ''];
    const queryDataWithAnomaly: QueryDataProps = {};
    queryIdWithAnomalyKeys.map((key) => {
      queryDataWithAnomaly[`${queryIdWithAnomaly}${key}`] =
        queryData[`${queryIdWithAnomaly}${key}`];
    });
    return queryDataWithAnomaly;
  }

  const queryIdWithForecast = queryId + '_forecast';
  if (queryData[queryIdWithForecast]) {
    const queryDataWithForecast = {
      [queryIdWithForecast]: queryData[queryIdWithForecast],
    };
    return queryDataWithForecast;
  }

  if (queryData[queryId]) {
    const queryDataWithQuery = { [queryId]: queryData[queryId] };
    return queryDataWithQuery;
  }

  return emptyQueryData;
};

const Metrics = ({
  close = undefined,
  defaultDate,
  defaultFormulas,
  defaultQueries,
  title = 'Metrics Explorer',
}: {
  close?: () => void;
  defaultDate?: DateSelection;
  defaultFormulas?: FormulaProps[];
  defaultQueries?: ExplorerQueryProps[];
  title?: string;
}): ReactElement => {
  const {
    dateState,
    dependenciesForWriteStateToUrl,
    isMultiChartState,
    formulasState,
    queriesState,
    writeStateToUrl,
  } = useMetricsExplorerPageStateContext();
  const [isMultiChart, setIsMultiChart] = isMultiChartState;
  const [date, setDate] = dateState;

  const queryBuilderState = useMetricsQueryStateV2({
    date,
    dataFormat: DashboardPanelType.TIMESERIES,
    formulasState,
    isRange: true,
    queriesState,
  });
  const { formulas, queries, queryData } = queryBuilderState;

  const [error, setError] = useState({
    getMetrics: null,
  });

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesForWriteStateToUrl);

  useEffect(() => {
    const queryBuilderHasErrors =
      !!queryBuilderState.error?.getLabelVlaues ||
      !!queryBuilderState.error?.getLabels ||
      !!queryBuilderState.error?.getMetricsList;

    setError((prevError) => ({
      ...prevError,
      getMetrics: queryBuilderHasErrors
        ? { message: 'Failed to fetch metrics' }
        : null,
    }));
  }, [queryBuilderState.error]);

  useEffect(() => {
    const {
      callMultiplePromqlQueries,
      loadInitialLabelsAndValues,
      loadLabelList,
      setFormulas,
      setQueries,
      setQueryData,
    } = queryBuilderState;

    if (defaultDate) {
      setDate(defaultDate);
    }

    if (defaultQueries) {
      setQueries(defaultQueries);
      defaultQueries.forEach((query, index) => {
        loadLabelList([], query.metric, -1);
        loadInitialLabelsAndValues(query.metric, query.series);
      });
    }

    if (defaultFormulas) {
      setFormulas(defaultFormulas);
    }

    if (defaultFormulas || defaultQueries) {
      callMultiplePromqlQueries(defaultQueries, defaultFormulas, date);
      setIsMultiChart(false);
    }

    return () => {
      setQueryData({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="new-metrics">
      <div className="new-metrics__header">
        <div className="new-metrics__header__title">{title}</div>
        <div className="new-metrics__header__date-picker">
          <Datepicker
            className="logs__search__datepicker"
            hasStartedLiveTail={false}
            onChange={setDate}
            startLiveTail={null}
            value={date}
          />
          <Button
            className="ml-2"
            onClick={() => refreshDate(date, setDate)}
            variant="icon-outline"
            size="default"
          >
            <RefreshCw size={14} />
          </Button>
          {close && (
            <Button className="ml-2" onClick={close} variant="icon-outline">
              <X size={16} />
            </Button>
          )}
        </div>
      </div>
      <MetricsQueryBuilder metricsQueryState={queryBuilderState} />

      <div
        className={`new-metrics__combine-query-checkbox ${error.getMetrics ? 'items-center' : 'items-end'}`}
      >
        {error.getMetrics && (
          <div className="flex gap-[4px] justify-end pr-[14px]">
            <IoIosWarning
              className="overlay-message__icon-and-message__icon"
              size={16}
            />
            <div className="text-red-500">{error.getMetrics.message}</div>
          </div>
        )}

        <CheckboxWithLabel
          label="Combine all queries into one chart"
          onChange={(checked) => setIsMultiChart(!checked)}
          value={!isMultiChart}
        />
      </div>
      {!isMultiChart && (
        <div className="new-metrics__combined-chart">
          <MetricsChartsCombined
            date={date}
            setDate={setDate}
            formulas={formulas}
            queries={queries}
            queryData={queryData}
          />
        </div>
      )}
      {isMultiChart && (
        <CursorStateProvider>
          <div className="new-metrics__charts">
            {queries.map((query, index: number) => {
              if (!query.isActive) return null;
              const queryId = `query_${query.queryKey}`;
              const chartData = getQueryDataByQueryId(queryData, queryId);
              const isLoading = Object.values(chartData).some(
                (data) => data?.isLoading,
              );

              return (
                <div className="new-metrics__charts__item" key={queryId}>
                  <MetricsCharts
                    date={date}
                    setDate={setDate}
                    isLoading={isLoading || false}
                    queryData={chartData}
                    queryItem={{
                      queries,
                      queryIndex: index,
                      type: 'query',
                      queryKey: query.queryKey,
                    }}
                  />
                </div>
              );
            })}
            {formulas.map((formula, index: number) => {
              if (!formula.isActive) return null;
              const formulaId = `formula_${formula.queryKey}`;
              const chartData =
                queryData[formulaId] || ({} as QueryDataProps['key']);
              return (
                <div className="new-metrics__charts__item" key={formulaId}>
                  <MetricsCharts
                    date={date}
                    setDate={setDate}
                    isLoading={chartData.isLoading || false}
                    queryData={{ [formulaId]: chartData }}
                    queryItem={{
                      formulas,
                      queries,
                      queryIndex: index,
                      type: 'formula',
                      queryKey: formula.queryKey,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </CursorStateProvider>
      )}
      {<ProductTour />}
    </div>
  );
};

export default Metrics;
