import { useSelectedFacetValuesByNameState } from 'hooks';
import React, { ReactElement } from 'react';
import { ValueCount } from 'types';

import LogsHydrationSidebarGroup from './LogsHydrationSidebarGroup';
import { LogsHydrationFacet } from '../utils';

const LogsHydrationSidebar = ({
  facetNames,
  getPredefinedFacetValues,
  hydrationProperties,
  selectedFacetValuesByNameState,
}: {
  facetNames: typeof LogsHydrationFacet;
  getPredefinedFacetValues: (facetName: string) => () => Promise<any>;
  hydrationProperties: Record<string, ValueCount[]>;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
}): ReactElement => {
  return (
    <div className="events__sidebar">
      <LogsHydrationSidebarGroup
        hydrationProperties={hydrationProperties}
        forceExpanded
        facetNames={facetNames}
        selectedFacetValuesByNameState={selectedFacetValuesByNameState}
        request={getPredefinedFacetValues}
      />
    </div>
  );
};

export default LogsHydrationSidebar;
