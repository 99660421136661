import classnames from 'classnames';
import { ExpressionBuilder, Suggestions } from 'components';
import React, { useMemo, useCallback, useRef, useState } from 'react';
import type { FocusedElementInfo } from 'components/ExpressionBuilder';
import { onKeydownHandler } from 'components/ExpressionBuilder/utils';
import { useKubernetesController } from './KubernetesController';

import { Row as FlexRow } from 'components/layouts/Flex';
import useAsync from './hooks/useAsync';
import { kubeLabelNames } from 'requests';

const KubernetesGroupBy = (): React.ReactElement => {
  const { groupBySearchTerms, setGroupBySearchTerms, entitiesType, facets } =
    useKubernetesController();
  const [facetKeys] = useAsync(async () => {
    const { kubeFacetCounts } = await kubeLabelNames({
      entityType: entitiesType,
      fields: ['tags.facetKey'],
      selectedFacetValuesByName: facets,
    });
    const consolidated = new Set(
      kubeFacetCounts.tags.map(({ facetKey }) => {
        return facetKey;
      }),
    );

    return Array.from(consolidated, (key) => {
      return {
        key,
        type: 'TAG',
      };
    });
  }, [entitiesType, facets]);

  const initializerRef = useRef<HTMLInputElement>(null);
  const suggestionsRef = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState('');

  // focusedElementInfo determines if Suggestions popover is open
  // This handler adds a 200ms delay when nullifying focusedElementInfo
  // to allow 'click' event on the suggestions pane.
  const timerId = useRef<NodeJS.Timeout>(null);
  const [focusedElementInfo, _setFocusedElementInfo] =
    useState<FocusedElementInfo | null>(null);

  const setFocusedElementInfo = useCallback(
    (info: FocusedElementInfo | null): void => {
      if (info === null) {
        timerId.current = setTimeout(() => {
          _setFocusedElementInfo(null);
        }, 200);
      } else {
        clearTimeout(timerId.current);
        _setFocusedElementInfo(info);
      }
    },
    [],
  );

  const suggestions = useMemo(() => {
    return Array.isArray(facetKeys)
      ? facetKeys?.filter(({ key }) => {
          return key?.includes(searchTerm);
        })
      : [];
  }, [facetKeys, searchTerm]);

  const onSuggestionSelect = (suggestionIdx: number) => {
    if (!suggestions[suggestionIdx]) {
      return;
    }
    setGroupBySearchTerms([...groupBySearchTerms, suggestions[suggestionIdx]]);
    setSearchTerm('');
    // take focus
    initializerRef.current?.focus();
    // blur it to close suggestions
    initializerRef.current?.blur();
  };

  const onChipRemove = (chipIdx: number) => {
    if (!groupBySearchTerms[chipIdx]) {
      return;
    }
    return setGroupBySearchTerms(
      groupBySearchTerms.filter((_, idx) => idx !== chipIdx),
    );
  };

  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<
    number | null
  >(null);

  return (
    <FlexRow gap="0" align="center" dataTestId="kubernetes-groupby-bldr">
      <ExpressionBuilder.Header>Group by</ExpressionBuilder.Header>
      <ExpressionBuilder.RelativeContainer
        focusedElementInfo={focusedElementInfo}
        initializerRef={initializerRef}
        className={classnames('rounded-tr', {
          'rounded-br': focusedElementInfo === null,
        })}
      >
        <ExpressionBuilder.Chips
          focusedElementInfo={focusedElementInfo}
          setFocusedElementInfo={setFocusedElementInfo}
          initializerRef={initializerRef}
          onKeyDown={onKeydownHandler.container({
            chipRefs: { current: [] },
            initializerRef,
            updateUrlIfNecessary: () => null,
            activeSuggestionIndex,
            setActiveSuggestionIndex,
            onSuggestionSelect,
            suggestions: suggestions?.map(({ key }) => ({
              id: key,
              label: key,
            })),
          })}
          searchTerm={searchTerm}
          onSearchTermChange={(e) => setSearchTerm(e.target.value)}
          className={classnames({ 'rounded-br': focusedElementInfo === null })}
        >
          {groupBySearchTerms.map((groupBySearchTerm, idx) => (
            <ExpressionBuilder.GroupByChip
              key={groupBySearchTerm.key}
              onRemove={() => {
                onChipRemove(idx);
              }}
            >
              {groupBySearchTerm.key}
            </ExpressionBuilder.GroupByChip>
          ))}
        </ExpressionBuilder.Chips>
        <Suggestions.Container
          isOpen={focusedElementInfo !== null}
          suggestionsRef={suggestionsRef}
        >
          {suggestions?.map((suggestion, suggestionIdx) => (
            <Suggestions.Suggestion
              key={suggestion.key}
              isActive={suggestionIdx === activeSuggestionIndex}
              activate={() => setActiveSuggestionIndex(suggestionIdx)}
              deactivate={() => setActiveSuggestionIndex(null)}
              onClick={() => onSuggestionSelect(suggestionIdx)}
            >
              {suggestion.key}
            </Suggestions.Suggestion>
          ))}
        </Suggestions.Container>
      </ExpressionBuilder.RelativeContainer>
    </FlexRow>
  );
};

export default KubernetesGroupBy;
