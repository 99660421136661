import { AnalyticsChart } from 'components';
import { useSearches } from 'hooks';
import React, { useEffect, useMemo } from 'react';
import { formatDurationNs, parseErrorFromQueryData } from 'utils';
import useRumState from '../hooks/useRumState';
import useRumTimeseries from './hooks/useRumTimeseries';
import { RumTab } from '../types';
import { isDurationFacet } from '../utils';

type Props = {
  searches: ReturnType<typeof useSearches>['searches'];
  rumState: ReturnType<typeof useRumState>;
  rumTab: RumTab;
};

const RumTimeseries = ({ searches, rumState, rumTab }: Props) => {
  const rumTimeseries = useRumTimeseries({
    searches,
    rumState,
    rumTab,
  });
  const {
    activeVisualization,
    analyticsChart,
    analyticsData,
    groupBysForTable,
    onViewFullscreen,
    openExportToDashboardModal,
  } = rumTimeseries;

  const { dateState } = rumState;
  const [date, setDate] = dateState;

  const rumAnalyticsOverlayMessage = parseErrorFromQueryData({
    queryData: analyticsData.chartData,
    queries: analyticsData.chartQueries,
    formulas: analyticsData.formulaQueries,
    dataType: activeVisualization === 'timeseries' ? 'range' : 'instant',
  });

  const renderValue = useMemo(() => {
    const isMeasureDuration = isDurationFacet(
      searches[0].multiAggregations?.[0]?.measure,
    );
    return isMeasureDuration ? (n: number) => formatDurationNs(n, 1, 2) : null;
  }, [searches]);

  return (
    <div className="rum__timeseries">
      <AnalyticsChart
        analyticsChart={analyticsChart}
        chartData={analyticsData.chartData}
        chartFormulas={analyticsData.formulaQueries}
        chartQueries={analyticsData.chartQueries}
        defaultActiveChart="Stacked Bar"
        doNotSplitKeysOfAnalyticTable={true}
        groupBy={groupBysForTable}
        hideCombinedChart
        overlayMessage={rumAnalyticsOverlayMessage}
        renderValue={renderValue}
        rightToolbar={{
          enableExportForInstantQueries: true,
          onViewFullscreen,
          onExportClick: openExportToDashboardModal,
        }}
        setDate={setDate}
        settingOptions={{
          legendType: 'values',
          toolbarChartShowType: 'dropdown',
        }}
      />
    </div>
  );
};

export default RumTimeseries;
