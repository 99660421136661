import dayjs from 'dayjs';
import { useMap, useToggle } from 'hooks';
import { useRef, useState } from 'react';

import { LabelsProps } from '../types';
import useDateState from './useDateState';

const endTimeUnix = dayjs().unix();
const startTimeUnix = dayjs()
  .subtract(60 * 5, 'seconds')
  .unix();

const useLogsState = () => {
  const loadingByComponentMap = useMap();
  const loadingByFacetKeyMap = useMap();

  const [date, setDate] = useDateState('date', {
    startLabel: 'now-5m',
    endLabel: 'now',
    endTimeUnix,
    startTimeUnix,
  });
  const [labels, setLabels] = useState<LabelsProps>();

  const hasStartedLiveTailToggle = useToggle();
  const liveTailToggle = useToggle();

  const getSearchFilterState = () => {
    return {
      date,
    };
  };

  const getFetchMethod = (fetchType: string): any => {
    switch (fetchType) {
      case 'fetchLogs':
        return { cursorRef, setLogs };
      default:
        return {};
    }
  };

  const cursorRef = useRef(null);
  const [logs, setLogs] = useState([]);
  const [search, setSearch] = useState('');
  const [width, setWidth] = useState(0);

  return {
    cursor: cursorRef.current,
    date,
    getFetchMethod,
    getSearchFilterState,
    hasStartedLiveTailToggle,
    labels,
    liveTailToggle,
    loadingByComponent: loadingByComponentMap.state,
    loadingByFacetKey: loadingByFacetKeyMap.state,
    logs,
    search,
    setDate: (nextDate) => {
      setDate(nextDate);
    },
    setLabels,
    setSearch,
    setWidth: (nextWidth) => {
      setWidth(nextWidth);
    },
    width,
  };
};

export default useLogsState;
