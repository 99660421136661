import { first, last } from 'lodash';

export const getNextElement = <T>(
  currentElement: T,
  allElements: Array<T>,
): T => {
  if (currentElement === last(allElements)) return first(allElements);
  const currentIndex = allElements.indexOf(currentElement);
  return allElements[currentIndex + 1];
};

export const getPreviousElement = <T>(
  currentElement: T,
  allElements: Array<T>,
): T => {
  if (currentElement === first(allElements)) return last(allElements);
  const currentIndex = allElements.indexOf(currentElement);
  return allElements[currentIndex - 1];
};

export const computeDOMVerticalDistance = (
  elementA: HTMLElement,
  elementB: HTMLElement,
): number =>
  Math.abs(
    elementA.getBoundingClientRect().y - elementB.getBoundingClientRect().y,
  );
