import { useRequest } from 'hooks';
import React, { ReactElement, useEffect } from 'react';
import { getLogMetricsResultWithKfuseQl } from 'requests';
import { DateSelection, LayoutType } from 'types';
import { kfuseqlDataTransformer, logsDataTransformer } from 'utils';

import { AlertsChart } from '../AlertsChart';
import { OutlierConditionProps } from '../AlertsCreateCondition';
import { getOutlierKfuseqlForLoad } from '../AlertsCreateLogs/utils';
import { AlertAnomalyQueryItem } from '../types';

const AlertsCreateLogsChartOutlier = ({
  outlierCondition,
  chartLayoutType,
  date,
  isChartCompact,
  forWindow,
  queryItem,
  unit,
}: {
  outlierCondition: OutlierConditionProps;
  chartLayoutType?: LayoutType;
  date: DateSelection;
  isChartCompact: boolean;
  forWindow?: string;
  queryItem: AlertAnomalyQueryItem;
  unit?: string;
}): ReactElement => {
  const kfuseQlOutlierRequest = useRequest(getLogMetricsResultWithKfuseQl);

  const loadOutlierData = () => {
    const kfuseql = getOutlierKfuseqlForLoad({ queryItem, outlierCondition });
    const baseTransformer = logsDataTransformer();
    baseTransformer[0] = {
      id: 'kfuseqlDataTransformer',
      func: kfuseqlDataTransformer,
    };
    kfuseQlOutlierRequest.call({
      date,
      kfuseQl: kfuseql,
      meta: { ...queryItem, isOutlier: true },
      transformer: baseTransformer,
    });
  };

  useEffect(() => {
    loadOutlierData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, queryItem, outlierCondition]);

  return (
    <AlertsChart
      conditionOperator={null}
      conditionValue={null}
      date={date}
      isChartCompact={isChartCompact}
      enableEvaluationLine={false}
      isLoading={kfuseQlOutlierRequest.isLoading}
      queryData={kfuseQlOutlierRequest.result}
      unit={unit}
    />
  );
};

export default AlertsCreateLogsChartOutlier;
