import React, {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { TooltipCoordsProps } from 'types';

const CompactTooltipContainerV2 = ({
  children,
  classname,
  coords,
  onOutsideClick,
}: {
  children: ReactElement;
  classname?: string;
  coords: TooltipCoordsProps;
  onOutsideClick?: () => void;
}): ReactElement => {
  const { x, y, positionX, positionY } = coords;
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [tooltipMeasurement, setTooltipMeasurement] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const [yOffset, setYOffset] = useState<number>(0);

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        for (const entry of entries) {
          const tW = Math.floor(entry.contentRect.width);
          const tH = Math.floor(entry.contentRect.height);

          if (
            tooltipMeasurement.width !== tW ||
            tooltipMeasurement.height !== tH
          ) {
            setTooltipMeasurement({ width: tW, height: tH });
          }
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useLayoutEffect(() => {
    if (tooltipRef.current) {
      resizeObserver.observe(tooltipRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [resizeObserver]);

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const { intersectionRatio, boundingClientRect } = entry;
        if (intersectionRatio === 1) {
          return;
        }

        const visibleHeight = intersectionRatio * boundingClientRect.height;
        const inVisibleHeight = boundingClientRect.height - visibleHeight;
        if (inVisibleHeight !== yOffset) {
          setYOffset(inVisibleHeight + 32);
        }
      },
      { root: null, rootMargin: '0px', threshold: 0.5 },
    );

    if (tooltipRef.current) {
      observer.observe(tooltipRef.current);
    }

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!onOutsideClick) return;
    const handleClickOutside = (event: MouseEvent) => {
      if (!tooltipRef.current) return;
      if (event.target?.className?.includes('u-over')) return;
      if (event.target?.innerText?.includes('View span with')) return;

      onOutsideClick();
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick]);

  if (!children) {
    return null;
  }

  const offsetLeft = positionX === 'left' ? tooltipMeasurement.width : 0;
  const yCalc = positionY === 'top' ? `calc(${y}px - 100%)` : `${y}px`;

  return (
    <div
      className={classname}
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        transform: `translate(${x - offsetLeft}px, ${yCalc})`,
        zIndex: 9999,
      }}
      ref={tooltipRef}
    >
      {children}
    </div>
  );
};

export default CompactTooltipContainerV2;
