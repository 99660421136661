import { LeftSidebarSectionHeader } from 'components';
import React, { ReactElement } from 'react';
import EventsSidebarFacetGroup from './EventsSidebarFacetGroup';
import { EventPageProps } from '../../types';
import { eventsCoreLabels, eventsLabels } from '../../utils';

const EventsSidebar = ({ eventsState }: EventPageProps): ReactElement => {
  const {
    date,
    additionalLabels,
    cloudLabels,
    kubernetesLabels,
    requestByFacetName,
    requestByLabelName,
    requestAndStoreByFacetName,
    requestAndStoreByLabelName,
    sidebarDataState,
    filterState,
  } = eventsState;

  const sidebarFilterState = filterState.getMethodAndStateByFilterKey(
    'sidebarFilters',
    'map',
  );

  return (
    <div className="events__sidebar">
      <EventsSidebarFacetGroup
        component="Core"
        date={date}
        forceExpanded
        facetNames={eventsCoreLabels}
        filterState={filterState}
        sidebarFilterState={sidebarFilterState}
        request={requestByFacetName}
        requestAndStoreByFacetName={requestAndStoreByFacetName}
        sidebarDataState={sidebarDataState}
      />
      <div className="events__sidebar__labels">
        <LeftSidebarSectionHeader iconName="labels">
          Labels
        </LeftSidebarSectionHeader>
        <EventsSidebarFacetGroup
          component="Cloud"
          date={date}
          facetNames={cloudLabels}
          filterState={filterState}
          sidebarFilterState={sidebarFilterState}
          request={requestByLabelName}
          requestAndStoreByFacetName={requestAndStoreByLabelName}
          sidebarDataState={sidebarDataState}
        />
        <EventsSidebarFacetGroup
          component="Kubernetes"
          date={date}
          facetNames={kubernetesLabels}
          filterState={filterState}
          sidebarFilterState={sidebarFilterState}
          request={requestByLabelName}
          requestAndStoreByFacetName={requestAndStoreByLabelName}
          sidebarDataState={sidebarDataState}
        />
        <EventsSidebarFacetGroup
          component="Additional"
          date={date}
          facetNames={additionalLabels}
          filterState={filterState}
          sidebarFilterState={sidebarFilterState}
          request={requestByLabelName}
          requestAndStoreByFacetName={requestAndStoreByLabelName}
          sidebarDataState={sidebarDataState}
          isLastListItem={true}
        />
      </div>
      <div className="left-sidebar__divider" />
      <EventsSidebarFacetGroup
        component="Event"
        date={date}
        facetNames={eventsLabels}
        filterState={filterState}
        sidebarFilterState={sidebarFilterState}
        request={requestByFacetName}
        requestAndStoreByFacetName={requestAndStoreByFacetName}
        sidebarDataState={sidebarDataState}
        isLastListItem={true}
      />
    </div>
  );
};

export default EventsSidebar;
