import dayjs from 'dayjs';
import { SpanMetrics } from 'types';
import { escapeDoubleQuote, onPromiseError } from 'utils';

import queryTraceService from './queryTraceService';

type Args = {
  endTimeNs: number;
  latencyNs: number;
  serviceHash: string;
  spanName: string;
};

const getSpanMetrics = async ({
  endTimeNs,
  latencyNs,
  serviceHash,
  spanName,
}: Args): Promise<SpanMetrics> => {
  const endTime = dayjs(Math.round(endTimeNs / 1000000));
  return queryTraceService<SpanMetrics, 'spanMetrics'>(`
    {
      spanMetrics(
        latencyNs: ${latencyNs}
        service: {
          hash: "${serviceHash}"
        }
        spanName: "${escapeDoubleQuote(spanName)}"
        timestamp: "${endTime.format('YYYY-MM-DDTHH:mm:ss.SSSZ')}",
      ) {
        spanDurationPercentiles {
          max
          p99
          p95
          p90
          p75
          p50
        }
        spanDurationRank
      }
    }
  `).then((data) => data.spanMetrics || null, onPromiseError);
};

export default getSpanMetrics;
