import { PrometheusDataset } from 'types';
import { isNumber } from 'utils';

import { fillTimestampForWindow } from '../fillTimestampForWindow';
import { DataFrame } from '../types';

export const prometheusDataTransformer = ({
  datasets,
  meta,
}: {
  datasets: PrometheusDataset[];
  meta: DataFrame['meta'];
}): DataFrame => {
  const { executedDate } = meta;
  const data: DataFrame['data'] = [];
  let maxValue = -Infinity;
  let minValue = Infinity;

  const { timestamps } = fillTimestampForWindow({
    executedDate,
    step: meta.step,
  });

  if (!datasets)
    return { data: [], meta, maxValue: 0, minValue: 0, timestamps };
  datasets.forEach((dataset) => {
    const { values } = dataset;
    const valueByTimestamp: { [key: string]: string } = {};

    values.forEach((value) => {
      const [ts, val] = value;
      const numVal = Number(val);
      maxValue = Math.max(maxValue, numVal || 0);
      minValue = Math.min(minValue, numVal || Infinity);
      valueByTimestamp[ts] = val;
    });

    const timeseriesData = timestamps.map((ts) =>
      isNumber(valueByTimestamp[ts]) ? valueByTimestamp[ts] : undefined,
    );

    data.push({ label: dataset.metric, values: timeseriesData });
  });

  return {
    data,
    meta,
    maxValue: maxValue === -Infinity ? 0 : maxValue,
    minValue: minValue === Infinity ? 0 : minValue,
    timestamps,
  };
};

export const prometheusDataTransformerInstant = ({
  datasets,
  meta,
}: {
  datasets: PrometheusDataset[];
  meta: DataFrame['meta'];
}): DataFrame => {
  const data: DataFrame['data'] = [];
  let maxValue = -Infinity;
  let minValue = Infinity;
  const timestampsByMetric: { [key: string]: boolean } = {};

  if (!datasets)
    return { data: [], meta, maxValue: 0, minValue: 0, timestamps: [] };

  datasets.forEach((dataset) => {
    const { value } = dataset;
    const [ts, val] = value;
    timestampsByMetric[ts] = true;
    maxValue = Math.max(maxValue, Number(val));
    minValue = Math.min(minValue, Number(val));
    data.push({ label: dataset.metric, values: [val], timestamp: Number(ts) });
  });

  return {
    data,
    meta,
    maxValue: maxValue === -Infinity ? 0 : maxValue,
    minValue: minValue === Infinity ? 0 : minValue,
    timestamps: Object.keys(timestampsByMetric).map(Number),
  };
};
