import uPlot from 'uplot';

import get2DPathBySeriesIndex from './get2DPathBySeriesIndex';
import { canvasPadding } from './tooltipUtils';

const drawBand = ({
  bandColor,
  ctx,
  lowerBandIndex,
  series,
  upperBandIndex,
  u,
}: {
  bandColor: string;
  ctx: CanvasRenderingContext2D;
  lowerBandIndex: number;
  series: uPlot.Series[];
  upperBandIndex: number;
  u: uPlot;
}): void => {
  const upperBandPath = series[upperBandIndex]._paths;
  const lowerBandPath = series[lowerBandIndex]._paths;

  let upperBandPath2D = undefined;
  let lowerBandPath2D = undefined;

  if (!upperBandPath) {
    upperBandPath2D = get2DPathBySeriesIndex(u, upperBandIndex);
  } else {
    upperBandPath2D = new Path2D(upperBandPath.fill);
  }

  if (!lowerBandPath) {
    lowerBandPath2D = get2DPathBySeriesIndex(u, lowerBandIndex);
  } else {
    lowerBandPath2D = new Path2D(lowerBandPath.fill);
  }

  const bandPath2D = new Path2D();
  bandPath2D.addPath(upperBandPath2D);
  bandPath2D.addPath(lowerBandPath2D);

  ctx.fillStyle = bandColor;
  ctx.fill(bandPath2D, 'evenodd');

  // clear extended area
  const padding = canvasPadding(u);
  const pixelRatio = window.devicePixelRatio;
  ctx.clearRect(
    padding.left,
    u.height * pixelRatio - padding.bottom,
    u.width * pixelRatio,
    padding.bottom,
  );
};

export default drawBand;
