import React, { ReactNode } from 'react';
import { FavoriteFacet } from 'types';
import FacetWithIconIcon from './FacetWithIconIcon';

type Props = {
  facet: FavoriteFacet;
  renderedName?: ReactNode;
  nameRef?: React.MutableRefObject<HTMLElement>;
};

const FacetWithIcon = ({ facet, renderedName, nameRef }: Props) => {
  const { name, type } = facet;
  return (
    <span className="facet-with-icon flex max-w-full">
      <div className="flex shrink-0">
        {type ? <FacetWithIconIcon type={type} /> : null}
      </div>
      <span
        className="facet-with-icon__name max-w-full overflow-hidden truncate"
        ref={nameRef}
      >
        {renderedName ? renderedName : name}
      </span>
    </span>
  );
};

export default FacetWithIcon;
