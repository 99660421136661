import { AutocompleteOption } from 'components';
import delimiter from 'kfuse-constants/delimiter';
import React, { FC, useMemo } from 'react';
import { components, OptionProps } from 'react-select';
import { FINGERPRINT_KEY } from 'utils/LogqlBuilder';
import { parseFacetKey } from 'utils/facets';

const LogsMetricsQueryBuilderQueryOptions: FC<
  OptionProps<AutocompleteOption, false>
> = (props: OptionProps<AutocompleteOption, false>) => {
  const { dataType, optionType } = useMemo(() => {
    const val = props?.value as string;
    if (val === '*' || val === FINGERPRINT_KEY) {
      return { dataType: '', optionType: '' };
    }

    const type = val.startsWith('@')
      ? val.split(delimiter)[1]
      : parseFacetKey(val).type;

    const optionType = val.startsWith('@') ? 'facet' : 'label';
    return { dataType: type, optionType };
  }, [props?.value]);

  return (
    <components.Option {...props}>
      <div className="flex flex--justify-content-between">
        <div className="flex">
          <div>{props?.label}</div>
          <div className="query-builder__logs__option-type ml-4">
            {dataType?.toUpperCase() || ''}
          </div>
        </div>
        <div className="autocomplete__panel__option__right__option-type">
          {optionType}
        </div>
      </div>
    </components.Option>
  );
};

export default LogsMetricsQueryBuilderQueryOptions;
