import dayjs from 'dayjs';
import { useTracesState } from 'hooks';
import { TraceError } from 'types';
import { onPromiseError } from 'utils';
import queryTraceService from './queryTraceService';
import { buildTracesFilter } from './utils';

const parseError = (error: string) => {
  const parsedError = JSON.parse(error);
  return parsedError;
};

type Args = {
  customerFilter?: { key: string; value: string };
  errorGroupingKey?: string;
  isListView?: boolean;
  isServiceFromDatabasesList?: boolean;
  pageNum?: number;
  serviceHash?: string;
  traceErrorId?: string;
  tracesState: ReturnType<typeof useTracesState>;
};

const traceErrors = async ({
  customerFilter,
  errorGroupingKey,
  isListView,
  isServiceFromDatabasesList,
  pageNum = 1,
  serviceHash,
  tracesState,
  traceErrorId,
}: Args): Promise<TraceError[]> => {
  const {
    dateState,
    facetRegexState,
    keyExistsState,
    selectedFacetValuesByNameState,
    selectedFacetRangeByNameState,
  } = tracesState;

  const [date] = dateState;
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  const facetRegex = facetRegexState.state;
  const keyExists = keyExistsState.state;
  const selectedFacetValuesByName = { ...selectedFacetValuesByNameState.state };
  const selectedFacetRangeByName = selectedFacetRangeByNameState.state;

  if (errorGroupingKey) {
    selectedFacetValuesByName['error_grouping_key'] = {
      [errorGroupingKey]: 1,
    };
  }

  if (serviceHash) {
    if (isServiceFromDatabasesList) {
      selectedFacetValuesByName['kf_database_service_hash'] = {
        [serviceHash]: 1,
      };
    } else {
      selectedFacetValuesByName['service_hash'] = {
        [serviceHash]: 1,
      };
    }
  }

  if (traceErrorId) {
    selectedFacetValuesByName['errorId'] = {
      [traceErrorId]: 1,
    };
  }

  return queryTraceService<TraceError[], 'traceErrors'>(`
    {
      traceErrors (
        durationSecs: ${durationSecs},
        filter: ${buildTracesFilter({
          customerFilter,
          facetRegex,
          keyExists,
          selectedFacetRangeByName,
          selectedFacetValuesByName,
        })}
        limit: 100
        pageNum: ${pageNum}
        timestamp: "${endTime.format()}",
      ) {
        id
        attributes
        error
        errorGroupingKey
        errorType
        method
        parentId
        service {
          name
          labels
          hash
          distinctLabels
        }
        ${isListView ? '' : 'statusCode'}
        timestampNs
        traceId
        ${isListView ? '' : 'url'}
      }
    }
  `).then(
    (data) =>
      (data.traceErrors || []).map((traceError) => ({
        ...traceError,
        parsedError: parseError(traceError.error),
      })),
    onPromiseError,
  );
};

export default traceErrors;
