import { TimeSeries } from 'types/generated';

import { fillTimestampForWindow } from '../fillTimestampForWindow';
import { DataFrame } from '../types';

const mergeFilledTimestampAndDatasetTimestamps = (
  datasets: TimeSeries[],
  timestampBitmap: { [key: number]: boolean },
) => {
  datasets?.forEach((dataset) => {
    dataset.points.forEach((point) => {
      timestampBitmap[point.ts / 1000] = true;
    });
  });
  return Object.keys(timestampBitmap)
    .map(Number)
    .sort((a, b) => a - b);
};

export const logqlDataTransformer = ({
  datasets,
  meta,
}: {
  datasets: TimeSeries[];
  meta: DataFrame['meta'];
}): DataFrame => {
  const data: DataFrame['data'] = [];
  let maxValue = -Infinity;
  let minValue = Infinity;

  const { executedDate } = meta;
  const { timestampBitmap } = fillTimestampForWindow({
    executedDate,
    step: meta.step,
  });
  const timestamps = mergeFilledTimestampAndDatasetTimestamps(
    datasets,
    timestampBitmap,
  );
  if (!datasets || datasets.length === 0) {
    return { data, meta, minValue, maxValue, timestamps };
  }
  datasets.forEach((dataset) => {
    const { points, tags } = dataset;
    const valueByTimestamp: { [key: string]: number } = {};
    points.forEach((point) => {
      const timestampInSeconds = point.ts / 1000;
      valueByTimestamp[timestampInSeconds] = point.value;
      minValue = Math.min(minValue, point.value);
      maxValue = Math.max(maxValue, point.value);
    });

    const timeseriesData: (number | undefined)[] = timestamps.map(
      (ts) => valueByTimestamp[ts],
    );
    data.push({ label: tags, values: timeseriesData });
  });

  return { data, meta, minValue, maxValue, timestamps };
};

export const logqlDataTransformerInstant = ({
  datasets,
  meta,
}: {
  datasets: TimeSeries[];
  meta: DataFrame['meta'];
}): DataFrame => {
  const data: DataFrame['data'] = [];
  let maxValue = -Infinity;
  let minValue = Infinity;
  const timestampBitmap: { [key: number]: boolean } = {};

  if (!datasets || datasets.length === 0) {
    return { data, meta, minValue, maxValue, timestamps: [] };
  }

  datasets.forEach((dataset) => {
    const { points, tags } = dataset;
    const valueByTimestamp: { [key: string]: number } = {};
    points.forEach((point) => {
      const timestampInSeconds = point.ts / 1000;
      valueByTimestamp[timestampInSeconds] = point.value;
      minValue = Math.min(minValue, point.value);
      maxValue = Math.max(maxValue, point.value);

      if (Object.keys(tags).length === 0) {
        tags.label = meta.metricName;
      }

      timestampBitmap[timestampInSeconds] = true;
      data.push({
        label: tags,
        values: [point.value],
        timestamp: timestampInSeconds,
      });
    });
  });

  return {
    data,
    meta,
    minValue,
    maxValue,
    timestamps: Object.keys(timestampBitmap).map(Number),
  };
};
