import { useRequest } from 'hooks';
import { useEffect } from 'react';
import { promqlMetadata } from 'requests';
import { getDateFromRange } from 'screens/Dashboard/utils';
import { ExplorerQueryProps, FormulaProps } from 'types';
import { convertFromAndToDate } from 'utils';

import {
  AnomalyConditionProps,
  ConditionProps,
  ForecastConditionProps,
  MetricChangeConditionProps,
  OutlierConditionProps,
  useAlertsCreateCondition,
} from '../../AlertsCreateCondition';
import {
  forecastAlertValidatorForCreate,
  thresholdAlertValidatorForCreate,
} from '../../AlertsCreateValidators';
import { useAlertsCreate } from '../../hooks';
import { AlertType, RuleType } from '../../types';
import { getPromQlQuery } from '../../utils';
import {
  buildChangeAlertPromql,
  buildForecastLinearPromql,
  buildForecastSeasonalPromql,
  buildOutlierAlertPromql,
} from '../utils';

const useAlertsCreateMetrics = (
  alertsCreateState: ReturnType<typeof useAlertsCreate>,
  conditionState: ReturnType<typeof useAlertsCreateCondition>,
) => {
  const {
    addToast,
    alertsDetails,
    alertType,
    date,
    mutateAlertsRule,
    setIsSaving,
    setErrorHandling,
  } = alertsCreateState;
  const {
    anomalyCondition,
    forecastCondition,
    metricsChangeCondition: changeCondition,
    outlierCondition,
  } = conditionState;

  const metricMetadataRequest = useRequest(promqlMetadata);

  const createThresoldAlertsRule = ({
    condition,
    formulas,
    queries,
  }: {
    condition: ConditionProps;
    formulas: FormulaProps[];
    queries: ExplorerQueryProps[];
  }) => {
    const validation = thresholdAlertValidatorForCreate({
      alertsDetails,
      condition,
      formulas,
      queries,
    });
    if (typeof validation === 'string') {
      addToast({ text: validation, status: 'error' });
      return;
    }

    const validationKeys = Object.keys(validation);
    if (validationKeys.length) {
      setErrorHandling((prev) => ({ ...prev, ...validation }));
      validationKeys.forEach((key) => {
        addToast({ text: validation[key], status: 'error' });
      });
      return;
    }

    const { queryKey } = condition;
    const { promql } = getPromQlQuery({ formulas, queryKey, queries, date });
    if (!promql) {
      addToast({ text: 'Not a valid query', status: 'error' });
      return;
    }

    setIsSaving(true);
    mutateAlertsRule({
      condition,
      datasourceType: 'prometheus',
      promqlQuery: promql,
      ruleAnnotations: {
        alertType: AlertType.THRESHOLD,
        ruleType: RuleType.METRICS,
      },
    });
  };

  const createChangeAlertsRule = ({
    condition,
    formulas,
    changeCondition,
    queries,
  }: {
    condition: ConditionProps;
    formulas: FormulaProps[];
    changeCondition: MetricChangeConditionProps;
    queries: ExplorerQueryProps[];
  }) => {
    const validation = thresholdAlertValidatorForCreate({
      alertsDetails,
      condition,
      formulas,
      queries,
    });
    if (typeof validation === 'string') {
      addToast({ text: validation, status: 'error' });
      return;
    }

    const validationKeys = Object.keys(validation);
    if (validationKeys.length) {
      setErrorHandling((prev) => ({ ...prev, ...validation }));
      validationKeys.forEach((key) => {
        addToast({ text: validation[key], status: 'error' });
      });
      return;
    }

    const { queryKey } = condition;
    const { promql } = getPromQlQuery({ formulas, queryKey, queries, date });
    if (!promql) {
      addToast({ text: 'Not a valid query', status: 'error' });
      return;
    }

    const changePromql = buildChangeAlertPromql(changeCondition, promql);
    setIsSaving(true);
    mutateAlertsRule({
      condition,
      datasourceType: 'prometheus',
      date: getDateFromRange('now-' + changeCondition.comparedTime, 'now'),
      promqlQuery: changePromql,
      ruleAnnotations: {
        alertType: AlertType.CHANGE,
        ruleType: RuleType.METRICS,
      },
    });
  };

  const createOutlierAlertsRule = ({
    condition,
    formulas,
    outlierCondition,
    queries,
  }: {
    condition: ConditionProps;
    formulas: FormulaProps[];
    outlierCondition: OutlierConditionProps;
    queries: ExplorerQueryProps[];
  }) => {
    condition.value = '0'; // Outlier condition value is not required from the user.
    const validation = thresholdAlertValidatorForCreate({
      alertsDetails,
      condition,
      formulas,
      queries,
    });
    if (typeof validation === 'string') {
      addToast({ text: validation, status: 'error' });
      return;
    }

    const validationKeys = Object.keys(validation);
    if (validationKeys.length) {
      setErrorHandling((prev) => ({ ...prev, ...validation }));
      validationKeys.forEach((key) => {
        addToast({ text: validation[key], status: 'error' });
      });
      return;
    }

    const { queryKey } = condition;
    const { promql } = getPromQlQuery({ formulas, queryKey, queries, date });
    if (!promql) {
      addToast({ text: 'Not a valid query', status: 'error' });
      return;
    }

    const outlierPromql = buildOutlierAlertPromql(promql, outlierCondition);
    setIsSaving(true);
    mutateAlertsRule({
      condition,
      datasourceType: 'prometheus',
      date,
      promqlQuery: outlierPromql,
      ruleAnnotations: {
        alertType: AlertType.OUTLIERS,
        ruleType: RuleType.METRICS,
      },
    });
  };

  const createForecastDetectionRule = ({
    condition,
    formulas,
    queries,
    conditionState,
  }: {
    condition: ConditionProps;
    formulas: FormulaProps[];
    queries: ExplorerQueryProps[];
    conditionState: ReturnType<typeof useAlertsCreateCondition>;
  }) => {
    const { forecastCondition } = conditionState;

    const validation = forecastAlertValidatorForCreate({
      alertsDetails,
      condition,
      forecastCondition,
      formulas,
      queries,
    });

    if (typeof validation === 'string') {
      addToast({ text: validation, status: 'error' });
      return;
    }

    const validationKeys = Object.keys(validation);
    if (validationKeys.length) {
      setErrorHandling((prev) => ({ ...prev, ...validation }));
      validationKeys.forEach((key) => {
        addToast({ text: validation[key], status: 'error' });
      });
      return;
    }

    const { queryKey } = condition;
    const { promql } = getPromQlQuery({ formulas, queryKey, queries, date });
    if (!promql) {
      addToast({ text: 'Not a valid query', status: 'error' });
      return;
    }

    const { forecastAlgorithm, interval } = forecastCondition;
    let forecastPromql = '';
    if (forecastAlgorithm === 'linear') {
      forecastPromql = buildForecastLinearPromql(promql, forecastCondition);
    } else if (forecastAlgorithm === 'seasonal') {
      forecastPromql = buildForecastSeasonalPromql({
        condition,
        forecastCondition,
        promql,
        type: 'create',
      });
    }

    if (!forecastPromql) {
      addToast({ text: 'Not a valid query', status: 'error' });
      return;
    }

    setIsSaving(true);
    mutateAlertsRule({
      condition,
      datasourceType: 'prometheus',
      promqlQuery: forecastPromql,
      interval: `${interval}s`,
      ruleAnnotations: {
        alertType: AlertType.FORECAST,
        ruleType: RuleType.METRICS,
      },
    });
  };

  const setChangeAndOutlierConditions = ({
    newAlertType,
    newAnomalyCondition,
    newForecastCondition,
    newChangeCondition,
    newOutlierCondition,
    relativeTimeRange,
  }: {
    newAlertType: AlertType;
    newChangeCondition: MetricChangeConditionProps;
    newForecastCondition: ForecastConditionProps;
    newOutlierCondition: OutlierConditionProps;
    newAnomalyCondition: AnomalyConditionProps;
    relativeTimeRange: { from: number; to: number };
  }) => {
    if (newAlertType === AlertType.CHANGE) {
      const { from, to } = relativeTimeRange;
      const { startLabel } = convertFromAndToDate(from, to);
      conditionState.setMetricsChangeCondition({
        ...changeCondition,
        ...newChangeCondition,
        ...{ comparedTime: startLabel },
      });
    }

    if (newAlertType === AlertType.OUTLIERS) {
      conditionState.setOutlierCondition({
        ...outlierCondition,
        ...newOutlierCondition,
      });
    }

    if (newAlertType === AlertType.ANOMALY) {
      conditionState.setAnomalyCondition({
        ...anomalyCondition,
        ...newAnomalyCondition,
      });
    }

    if (newAlertType === AlertType.FORECAST) {
      conditionState.setForecastCondition({
        ...forecastCondition,
        ...newForecastCondition,
      });
    }
  };

  const appendPromqlForChart = (promql: string, metricName: string): string => {
    if (alertType === AlertType.CHANGE) {
      return buildChangeAlertPromql(changeCondition, promql);
    }

    if (alertType === AlertType.OUTLIERS && outlierCondition.algorithm === '') {
      return undefined;
    }

    if (alertType === AlertType.OUTLIERS) {
      return buildOutlierAlertPromql(promql, outlierCondition, 'load');
    }

    if (alertType === AlertType.ANOMALY) {
      return undefined;
    }

    if (alertType === AlertType.FORECAST) {
      return undefined;
    }

    return promql;
  };

  useEffect(() => {
    if (alertType === AlertType.CHANGE && !metricMetadataRequest.result) {
      metricMetadataRequest.call();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertType]);

  return {
    appendPromqlForChart,
    createForecastDetectionRule,
    createThresoldAlertsRule,
    createChangeAlertsRule,
    createOutlierAlertsRule,
    setChangeAndOutlierConditions,
  };
};

export default useAlertsCreateMetrics;
