import { DashboardExport, useModalsContext } from 'components';
import { useTracesState } from 'hooks';
import React from 'react';
import { DashboardPanelType } from 'types';

type Props = {
  tracesState: ReturnType<typeof useTracesState>;
};

const TracesTableDashboardExport = ({ tracesState }: Props) => {
  const modals = useModalsContext();

  const onClick = () => {
    modals.push(
      <DashboardExport
        closeModal={modals.pop}
        date={tracesState.dateState[0]}
        panel={{
          targets: [
            {
              editorMode: 'code',
              expr: JSON.stringify(tracesState.state),
              key: 'Q-09b106c9-2f31-4591-b382-480e5236903f-0',
              queryType: 'range',
              refId: 'A',
            },
          ],
          type: DashboardPanelType.TRACES,
        }}
      />,
    );
  };

  return (
    <button className="button button--short" onClick={onClick} type="button">
      Add to Dashboard
    </button>
  );
};

export default TracesTableDashboardExport;
