import { SearchTag } from 'components';
import { FacetRegexFilter } from 'hooks';
import React from 'react';

const getFacetRegexTag = (
  facetRegexFilter: FacetRegexFilter,
): Pick<SearchTag, 'label' | 'text'> => {
  const { isEqual, name, value } = facetRegexFilter.value;
  const operator = isEqual ? '=~' : '!~';

  const label = (
    <>
      <span className="traces-search__input__trigger__tag__name">{name}</span>
      <span>{operator}</span>
      <span className="traces-search__input__trigger__tag__value">
        {`"${value}"`}
      </span>
    </>
  );

  return {
    label,
    text: `${name}${operator}"${value}"`,
  };
};

export default getFacetRegexTag;
