import { Span } from 'types';
import { tryUnmarshallSpan } from 'utils';
import { Attribute } from './types';

export const getAttribute = (attribute: Attribute, span: Span): string => {
  return span.attributes[attribute];
};

const getEventsAttributesErrors = (span: Span) => {
  const spanUnmarshalled = tryUnmarshallSpan(span);
  if (spanUnmarshalled?.events) {
    return spanUnmarshalled.events
      .filter(
        (event) => event.attributes && event.attributes['exception_stacktrace'],
      )
      .map((event) => event.attributes['exception_stacktrace']);
  }

  return [];
};

const parseStackTrace = (stackTrace: unknown) => {
  if (Array.isArray(stackTrace)) {
    if (stackTrace.length) {
      return stackTrace;
    }

    return null;
  }

  if (typeof stackTrace === 'string') {
    try {
      const doubleParsedStackTrace = JSON.parse(stackTrace);
      if (Array.isArray(doubleParsedStackTrace)) {
        if (doubleParsedStackTrace.length) {
          return doubleParsedStackTrace;
        }
        return null;
      }

      return stackTrace;
    } catch (e) {
      return stackTrace;
    }
  }

  return null;
};

const getHcAttributesError = (span: Span) => {
  const spanUnmarshalled = tryUnmarshallSpan(span);
  if (!spanUnmarshalled) {
    return null;
  }

  const stackTrace = spanUnmarshalled.hc_attributes
    ? spanUnmarshalled.hc_attributes['span.stacktrace']
    : null;

  if (stackTrace) {
    const parsedStackTrace = parseStackTrace(stackTrace);
    if (parsedStackTrace) {
      return parsedStackTrace;
    }
  }

  return null;
};

export const getSpanErrors = (span: Span) => {
  const result = getEventsAttributesErrors(span);

  const hcAttributesError = getHcAttributesError(span);
  if (hcAttributesError) {
    result.push(hcAttributesError);
  }

  return result;
};
