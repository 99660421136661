import uPlot from 'uplot';
import { OutlierSpanProps, UplotExtended } from 'types/Timeseries';

import { canvasPadding } from './tooltipUtils';
import { drawRectangeOnTimeseries, drawLineOnTimeseries } from './drawUtils';
import { ctaColor } from '../colors';

export const drawOutlierSpan = ({
  outlierSpanData,
  u,
}: {
  outlierSpanData: OutlierSpanProps[];
  u: UplotExtended;
}): void => {
  if (!outlierSpanData) return;
  const padding = canvasPadding(u);
  const devicePixelRatio = window.devicePixelRatio || 1;
  if (outlierSpanData.length === 0) {
    u.clearCanvasByContext(u.eventOverlayCtx);
    return;
  }
  // always draw outlier span
  if (u.eventsData?.outlierSpan?.data) return;
  const hashMap = new Map<
    number,
    { span: OutlierSpanProps; isFocused: boolean }
  >();
  outlierSpanData.forEach((span) => {
    const timestamp = span.timestamp;
    const leftX = u.valToPos(timestamp, 'x', true);
    const bottomY = u.height * devicePixelRatio - padding.bottom;
    const topY = padding.top;
    hashMap.set(leftX, { span, isFocused: false });
    drawOutlierSpanPoint({
      bottomY,
      leftX,
      pointColor: ctaColor.lightPurple,
      topY,
      u,
    });
  });
  u.eventsData = {
    ...u.eventsData,
    outlierSpan: { data: hashMap, axis: 'x' },
  };
};

export const syncDrawnOutlierSpan = ({
  outlierSpanMap,
  u,
}: {
  outlierSpanMap: UplotExtended['eventsData']['outlierSpan']['data'];
  u: UplotExtended;
}): void => {
  const padding = canvasPadding(u);
  const devicePixelRatio = window.devicePixelRatio || 1;
  const hashMapList = Array.from(outlierSpanMap.keys());
  u.clearCanvasByContext(u.eventOverlayCtx);
  hashMapList.forEach((leftX) => {
    const spanData = outlierSpanMap.get(leftX);
    const bottomY = u.height * devicePixelRatio - padding.bottom;
    const topY = padding.top;
    if (spanData.isFocused) {
      drawOutlierSpanPoint({
        bottomY,
        leftX,
        isFocused: true,
        pointColor: ctaColor.deepPurple,
        topY,
        u,
      });
      return;
    }

    drawOutlierSpanPoint({
      bottomY,
      leftX,
      isFocused: false,
      pointColor: ctaColor.lightPurple,
      topY,
      u,
    });
  });
};

export const drawOutlierSpanPoint = ({
  bottomY,
  leftX,
  isFocused,
  pointColor,
  topY,
  u,
}: {
  bottomY: number;
  leftX: number;
  isFocused?: boolean;
  pointColor: string;
  topY: number;
  u: UplotExtended;
}): void => {
  const LINE_WIDTH = isFocused ? 4 : 2;
  const lineWidth = LINE_WIDTH * uPlot.pxRatio;
  drawLineOnTimeseries({
    ctx: u.eventOverlayCtx,
    start: { x: leftX, y: topY },
    end: { x: leftX, y: bottomY },
    options: { color: pointColor, lineWidth },
  });
  drawRectangeOnTimeseries({
    ctx: u.eventOverlayCtx,
    x: leftX - 5,
    y: topY,
    width: 10,
    height: 10,
    options: { color: pointColor, lineWidth: 2 * uPlot.pxRatio },
  });
};
