import { TableTransformerData } from '../types';
import getEvaluatedValue from './utils/getEvaluatedValue';

const geomapTransformer = ({
  data: acc,
  options,
}: {
  data: {
    data: TableTransformerData['data'];
  };
  options?: { unit: string; decimals: number };
}): { data: Record<string, string | number> } => {
  const { data } = acc;

  const countriesMap =
    data && data.length > 0
      ? data.reduce((acc, item) => {
          const country = item['geo.country'] as string;
          if (country) {
            const value = item['Value #a'] || 0;
            const evaluatedValue = options?.unit
              ? getEvaluatedValue(
                  value as number,
                  options.unit,
                  options.decimals,
                )
              : value;

            acc[country] = evaluatedValue;
          }
          return acc;
        }, {})
      : {};
  return { data: countriesMap };
};

export default geomapTransformer;
