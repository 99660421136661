import {
  LuAlertCircle as AlertCircle,
  LuFilter as Filter,
  LuAlertTriangle as AlertTriangle,
  LuArrowUp as ArrowUp,
  LuArrowDown as ArrowDown,
  LuArrowBigRight as ArrowBigRight,
  LuChevronRight as ChevronRight,
  LuChevronDown as ChevronDown,
  LuChevronUp as ChevronUp,
  LuInfo as Info,
  LuKeySquare as KeySquare,
  LuLock as Lock,
  LuPause as Pause,
  LuPieChart as PieChart,
  LuSearch as Search,
  LuSettings as Settings,
  LuLineChart as LineChart,
  LuX as X,
} from 'react-icons/lu';
import React from 'react';
import { cn } from 'utils/tailwind';
import exhaustiveSwitchError from 'utils/exhaustiveSwitchError';
import { cva } from 'class-variance-authority';
import { FiEdit3 } from 'react-icons/fi';
import { FaPlayCircle } from 'react-icons/fa';

type IconType =
  | 'alert-circle'
  | 'alert-triangle'
  | 'arrow-big-right'
  | 'arrow-up'
  | 'arrow-down'
  | 'chevron-down'
  | 'chevron-up'
  | 'chevron-right'
  | 'filter'
  | 'info'
  | 'key'
  | 'lock'
  | 'line-chart'
  | 'pie-chart'
  | 'play'
  | 'pause'
  | 'search'
  | 'settings'
  | 'x'
  | 'edit';
type Size = 'xs' | 'sm' | 'md' | 'lg';

type Props = {
  icon: IconType;
  size?: Size;
  className?: string;
  style?: React.CSSProperties;
  children?: string;
};

const iconVariants = cva('', {
  variants: {
    size: {
      xs: 'size-3',
      sm: 'size-3.5',
      md: 'size-4',
      lg: '',
    },
  },
});

const Icon = ({
  icon,
  size = 'sm',
  className,
  style,
}: Props): React.ReactElement => {
  const lucideClassName = cn(iconVariants({ size }), className);
  switch (icon) {
    case 'alert-circle':
      return <AlertCircle className={lucideClassName} style={style} />;
    case 'alert-triangle':
      return <AlertTriangle className={lucideClassName} style={style} />;
    case 'arrow-big-right':
      return <ArrowBigRight className={lucideClassName} style={style} />;
    case 'arrow-up':
      return <ArrowUp className={lucideClassName} style={style} />;
    case 'arrow-down':
      return <ArrowDown className={lucideClassName} style={style} />;
    case 'chevron-down':
      return <ChevronDown className={lucideClassName} style={style} />;
    case 'chevron-up':
      return <ChevronUp className={lucideClassName} style={style} />;
    case 'chevron-right':
      return <ChevronRight className={lucideClassName} style={style} />;
    case 'filter':
      return <Filter className={lucideClassName} style={style} />;
    case 'info':
      return <Info className={lucideClassName} style={style} />;
    case 'key':
      return <KeySquare className={lucideClassName} style={style} />;
    case 'lock':
      return <Lock className={lucideClassName} style={style} />;
    case 'line-chart':
      return <LineChart className={lucideClassName} style={style} />;
    case 'pause':
      return <Pause className={lucideClassName} style={style} />;
    case 'pie-chart':
      return <PieChart className={lucideClassName} style={style} />;
    case 'play':
      return <FaPlayCircle className={lucideClassName} style={style} />;
    case 'search':
      return <Search className={lucideClassName} style={style} />;
    case 'settings':
      return <Settings className={lucideClassName} style={style} />;
    case 'x':
      return <X className={lucideClassName} style={style} />;
    case 'edit':
      return <FiEdit3 className={lucideClassName} style={style} />;
    default:
      throw exhaustiveSwitchError(icon);
  }
};

export default Icon;
