import { ErrorBoundary } from 'components';
import React, { ReactElement, useMemo, useState } from 'react';
import { DateSelection } from 'types/DateSelection';

import { DashboardByName } from '../../Kfuse';
import { Property } from '../types';

const serviceReportDashboardNames: { [key: string]: string } = {
  [Property.reportPerformance]: 'APM Performance Report',
  [Property.reportSLA]: 'APM SLA Report',
};

const ServiceReportTabs = ({
  asmMatcher,
  date,
  kfSource,
  property,
}: {
  asmMatcher: string;
  date: DateSelection;
  kfSource?: string;
  property: Property;
}): ReactElement => {
  const activeDashboard = serviceReportDashboardNames[property];
  const [refreshedDate] = useState(date);

  const kubeFilters = useMemo(() => {
    const filter = {
      kf_source: kfSource,
      matchers: asmMatcher,
    };

    return filter;
  }, [asmMatcher, kfSource]);

  return (
    <div className="min-h-[50vh]">
      {kubeFilters && (
        <ErrorBoundary errorMessage={<h1>Failed to load outliers charts</h1>}>
          <DashboardByName
            date={refreshedDate}
            disableFilter={false}
            disableDeletePanel={true}
            fetchLimitedTemplates={
              property === Property.reportPerformance
                ? [{ name: 'span_name', joinValues: false }]
                : [{ name: 'period', joinValues: false }]
            }
            folderName="kfuse-apm"
            hideHeader={true}
            name={activeDashboard}
            shouldWriteToUrl={false}
            showReloadButton={true}
            templateValues={kubeFilters}
          />
        </ErrorBoundary>
      )}
    </div>
  );
};

export default ServiceReportTabs;
