import {
  LeftSidebar,
  OverlayMessage,
  OverlayMessageProps,
  ShowSidebarTooltipButton,
  useLeftSidebarState,
} from 'components';
import { useAlertsPageStateContext } from 'context/PageStateProvider';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import AlertsList from './AlertsList';
import AlertsSidebar from './AlertsSidebar';
import { useAlertsState } from './hooks';
import { IoIosWarning } from 'react-icons/io';

const Alerts = () => {
  const {
    dependenciesForWriteStateToUrl,
    selectedFacetValuesByNameState,
    writeStateToUrl,
  } = useAlertsPageStateContext();
  const alertsState = useAlertsState();
  const leftSidebarState = useLeftSidebarState('alerts');
  const navigate = useNavigate();
  const newAlertButtonRef = useRef(null);

  const { reloadAlerts, error } = alertsState;

  const onNewAlertClick = () => {
    navigate('/alerts/list');
  };

  useEffect(() => {
    reloadAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesForWriteStateToUrl);

  const overlayMessageProps: OverlayMessageProps = error.getAlerts
    ? {
        isActive: true,
        iconName: 'warning',
        message: <>{error.getAlerts.message || 'Error'}</>,
      }
    : { isActive: false };

  return (
    <div className="alerts">
      <LeftSidebar
        className="alerts__left-sidebar"
        leftSidebarState={leftSidebarState}
      >
        <AlertsSidebar
          alertsState={alertsState}
          selectedFacetValuesByNameState={selectedFacetValuesByNameState}
        />
      </LeftSidebar>
      <div className="alerts__main">
        <div className="alerts__header">
          <div className="alerts__header__left">
            {leftSidebarState.width === 0 ? (
              <ShowSidebarTooltipButton onClick={leftSidebarState.show} />
            ) : null}
            <div className="alerts__header__title">Alerts</div>
          </div>

          <button
            className="button button--blue whitespace-nowrap"
            onClick={onNewAlertClick}
            ref={newAlertButtonRef}
          >
            Create New Alert
          </button>
        </div>
        <OverlayMessage {...overlayMessageProps}>
          <AlertsList
            alertsState={alertsState}
            selectedFacetValuesByNameState={selectedFacetValuesByNameState}
          />
        </OverlayMessage>
      </div>
    </div>
  );
};

export default Alerts;
