import dayjs from 'dayjs';
import {
  DateSelection,
  ValueCount,
  SelectedFacetValuesByName,
  SelectedFacetRangeByName,
  FacetRegexTerm,
} from 'types';
import { onPromiseError } from 'utils';
import queryRumService from './queryRumService';
import { buildRumFilter } from './utils';
import { RumEventType } from '../types';

type Args = {
  applicationFilter: string;
  actionId?: string;
  date: DateSelection;
  errorId?: string;
  eventType: RumEventType;
  idSearch?: string;
  labelName: string;
  longTaskId?: string;
  resourceId?: string;
  facetRegex?: FacetRegexTerm[];
  selectedFacetRangeByName?: SelectedFacetRangeByName;
  selectedFacetValuesByName?: SelectedFacetValuesByName;
  viewId?: string;
};

const rumLabelValues = async ({
  applicationFilter,
  actionId = '',
  date,
  errorId = '',
  eventType,
  idSearch,
  labelName,
  longTaskId = '',
  resourceId = '',
  facetRegex,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  viewId = '',
}: Args): Promise<ValueCount[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  return queryRumService<ValueCount[], 'attributeValues'>(`
  query {
    attributeValues(
      eventType: ${eventType},
      timestamp: "${endTime.format()}",
      durationSecs: ${durationSecs},
      filter: ${buildRumFilter({
        applicationFilter,
        actionId,
        errorId,
        facetRegex,
        idSearch,
        longTaskId,
        resourceId,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
        viewId,
      })},
      field: "${labelName}"
    ) {
      value,
      count,
    }
  }
  `).then((data) => data.attributeValues || [], onPromiseError);
};

export default rumLabelValues;
