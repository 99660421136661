import dayjs, { OpUnitType } from 'dayjs';
import { MILLISECONDS, MINUTES } from 'kfuse-constants';
import { DateSelection } from 'types/DateSelection';

/**
 * Validated codified string like now-1h
 * @param value string
 * Valid codified string: now, now-1s, now-100m, now-9h, now-17d, now-1w, now-1M, now-1y
 * Invalid codified string: now-1, now-1s1, now-1m1, now-1h1, now-1d1, now-1w1, now-1M1, now-1y1
 */
export const validateCodifiedDate = (value: string): boolean => {
  if (value === 'now') {
    return true;
  }
  const codifiedDateRegex = /^now-(\d+)([smhdwMy])$/;
  return codifiedDateRegex.test(value);
};

/**
 * Validate codified string like now+1h
 * Valid - now+1s, now+100m, now+9h, now+17d, now+1w, now+1M, now+1y
 * Invalid - now+1, now+1h+1d, now+1w+1m, now+1y+1m
 */
export const validateCodifiedDatePlus = (value: string): boolean => {
  const codifiedDateRegex = /^now\+(\d+)([smhdwMy])$/;
  return codifiedDateRegex.test(value);
};

/**
 * Codified string to time converter
 * Unit - seconds, minutes, hours, days, weeks, months, years
 * now - current time
 * Valid - now-1s, now-100m, now-9h, now-17d, now-1w, now-1M, now-1y
 * Invalid - now-1, now-1h-1d, now-1w-1m, now-1y-1m
 * @param value string
 * @returns number unix timestamp
 */
export const convertTimeStringToUnix = (
  value: string,
  utcTimeEnabled?: boolean,
): number => {
  if (value === 'now') {
    return dayjs().unix();
  }
  if (validateCodifiedDate(value)) {
    const [, time, unit] = value.match(/^now-([0-9]+)([smhdwMy])$/);
    if (utcTimeEnabled) {
      return dayjs
        .utc()
        .subtract(parseInt(time, 10), unit as OpUnitType)
        .unix();
    }

    const localDate = dayjs()
      .subtract(parseInt(time, 10), unit as OpUnitType)
      .unix();

    if (
      parseInt(time) > 1 &&
      ['d', 'w', 'M', 'y'].includes(unit) &&
      isDayLightSavingTime(localDate)
    ) {
      return dayjs.unix(localDate).subtract(1, 'h').unix();
    }

    return localDate;
  }
  return null;
};

/**
 * Check if the time is day light saving time
 * @param time unix timestamp
 * @returns boolean
 */
export const isDayLightSavingTime = (time: number): boolean => {
  const date = new Date(time * 1000);
  return date.getTimezoneOffset() > new Date().getTimezoneOffset();
};

/**
 * Codified string to time converter for upcomming time
 * Unit - seconds, minutes, hours, days, weeks, months, years
 * now - current time
 * Valid - now+1s, now+100m, now+9h, now+17d, now+1w, now+1M, now+1y
 * Invalid - now+1, now+1h+1d, now+1w+1m, now+1y+1m
 * @param value string
 * @returns number unix timestamp
 */
export const convertTimeStringToUnixUpcoming = (
  value: string,
  utcTimeEnabled?: boolean,
): number => {
  if (value === 'now') {
    return dayjs().unix();
  }
  if (validateCodifiedDatePlus(value)) {
    const [, time, unit] = value.match(/^now\+([0-9]+)([smhdwMy])$/);

    if (utcTimeEnabled) {
      return dayjs
        .utc()
        .add(parseInt(time, 10), unit as OpUnitType)
        .unix();
    }

    return dayjs()
      .add(parseInt(time, 10), unit as OpUnitType)
      .unix();
  }
  return null;
};

export const convertTimestampToCode = (
  date: DateSelection,
): { from: string; to: string } => {
  const { startLabel, endLabel, startTimeUnix, endTimeUnix } = date;

  if (startLabel && endLabel) {
    return { from: startLabel, to: endLabel };
  }

  // diff in start and end time in minutes
  const diff = Math.round(endTimeUnix - startTimeUnix);
  // diff in end time and now in minutes
  const diffNow = Math.round(dayjs().unix() - endTimeUnix);

  return {
    from: convertSecondToCode(diff + diffNow),
    to: convertSecondToCode(diffNow),
  };
};

/**
 * Convert codified string to unix timestamp
 * @param diff number
 * @returns string
 * 1 - 59 seconds - now-1s
 * 1 - 59 minutes - now-1m
 * 60 - 1439 minutes - now-1h
 * 1440 - 10079 minutes - now-1d
 * 10080 - 40319 minutes - now-1w
 * 40320 - 525599 minutes - now-1M
 */
const convertSecondToCode = (diff: number): string => {
  if (diff === 0) {
    return 'now';
  }
  if (diff < 60) {
    return `now-${diff}s`;
  }
  if (diff < 3600) {
    // 60 minutes
    return `now-${Math.round(diff / 60)}m`;
  }
  if (diff < 86400) {
    // 24 hours
    return `now-${Math.round(diff / 3600)}h`;
  }
  if (diff < 604800) {
    // 7 days
    return `now-${Math.round(diff / 86400)}d`;
  }
  if (diff < 2592000) {
    // 30 days
    return `now-${Math.round(diff / 604800)}w`;
  }
  if (diff < 31536000) {
    // 365 days
    return `now-${Math.round(diff / 2592000)}M`;
  }
  return `now-${Math.round(diff / 31536000)}y`;
};

export const convertSecondToTimeString = (diff: number): string => {
  return convertSecondToCode(diff).replace('now-', '');
};

export const convertFromAndToDate = (
  from: number,
  to: number,
): DateSelection => {
  const endTimeUnix = dayjs().subtract(to, 'seconds').unix();
  const startTimeUnix = dayjs().subtract(from, 'seconds').unix();

  const { from: startLabel, to: endLabel } = convertTimestampToCode({
    startTimeUnix,
    endTimeUnix,
  });

  return { startTimeUnix, endTimeUnix, startLabel, endLabel };
};

export const getDateFromCodexRange = (
  from: string,
  to: string,
): DateSelection => {
  if (validateCodifiedDate(from) && validateCodifiedDate(to)) {
    const startTimeUnix = convertTimeStringToUnix(from);
    const endTimeUnix = convertTimeStringToUnix(to);

    return { startTimeUnix, endTimeUnix, startLabel: from, endLabel: to };
  }

  if (
    new Date(from).toString() !== 'Invalid Date' &&
    new Date(to).toString() !== 'Invalid Date'
  ) {
    return {
      startTimeUnix: dayjs(from).unix(),
      endTimeUnix: dayjs(to).unix(),
      startLabel: from,
      endLabel: to,
    };
  }

  return {
    startTimeUnix: convertTimeStringToUnix('now-5m'),
    endTimeUnix: convertTimeStringToUnix('now'),
    startLabel: 'now-5m',
    endLabel: 'now',
  };
};

/**
 * Convert seconds to date
 * @param from
 * @param to
 * @returns
 * @example { from: 300, to: 0 } -> { startTimeUnix: 1625580000, endTimeUnix: 1625580300, startLabel: 'now-5m', endLabel: 'now' }
 */
export const getDateFromSecondRange = (
  from: number,
  to: number,
): DateSelection => {
  const endTimeUnix = dayjs().subtract(to, 'seconds').unix();
  const startTimeUnix = dayjs().subtract(from, 'seconds').unix();

  const { from: startLabel, to: endLabel } = convertTimestampToCode({
    startTimeUnix,
    endTimeUnix,
  });

  return { startTimeUnix, endTimeUnix, startLabel, endLabel };
};

const getReadableTimeSuffix = (time: number, unit: string): string => {
  const timeShort = ['s', 'm', 'h', 'd', 'w', 'M', 'y'];
  const timeLong = [
    'seconds',
    'minutes',
    'hours',
    'days',
    'weeks',
    'months',
    'years',
  ];
  const timeLogsSingle = ['second', 'minute', 'hour', 'day', 'week', 'month'];

  const index = timeShort.indexOf(unit);
  if (time === 1) {
    return timeLogsSingle[index];
  }

  return timeLong[index];
};

/**
 * Convert seconds to readable string
 * @param second
 * @returns
 * @example 1 -> 1s
 * @example 60 -> 1m
 * @example 300 -> 5m
 * @example 3600 -> 1h
 * @example 86400 -> 1d
 * @example 604800 -> 1w
 * @example 2592000 -> 1M
 * @example 31536000 -> 1y
 */
export const convertSecondToReadable = (
  second: number,
  full?: boolean,
): string => {
  if (second < 60) {
    return `${second}${full ? ` ${getReadableTimeSuffix(second, 's')}` : 's'}`;
  }
  if (second < 3600) {
    const minute = Math.round(second / 60);
    return `${minute}${full ? ` ${getReadableTimeSuffix(minute, 'm')}` : 'm'}`;
  }
  if (second < 86400) {
    const hour = Math.round(second / 3600);
    return `${hour}${full ? ` ${getReadableTimeSuffix(hour, 'h')}` : 'h'}`;
  }
  if (second < 604800) {
    const day = Math.round(second / 86400);
    return `${day}${full ? ` ${getReadableTimeSuffix(day, 'd')}` : 'd'}`;
  }
  if (second < 2592000) {
    const week = Math.round(second / 604800);
    return `${week}${full ? ` ${getReadableTimeSuffix(week, 'w')}` : 'w'}`;
  }
  if (second < 31536000) {
    const month = Math.round(second / 2592000);
    return `${month}${full ? ` ${getReadableTimeSuffix(month, 'M')}` : 'M'}`;
  }
  const year = Math.round(second / 31536000);
  return `${year}${full ? ` ${getReadableTimeSuffix(year, 'y')}` : 'y'}`;
};

/**
 * Convert seconds to readable string with precision
 * @param second
 * @param full
 * @returns
 * example 1 -> 1s
 * example 80 -> 1m 20s
 * example 3200 -> 53m 20s
 * example 3600 -> 1h
 * example 4220 -> 1h 10m 20s
 * example 86400 -> 1d
 * example 100000 -> 1d 3h 46m 40s
 * example 1000000 -> 11d 13h 46m 40s
 */
export const convertSecondToReadablePrecision = (
  second: number,
  full?: boolean,
): string => {
  const units = [
    { value: 31536000, short: 'y', long: 'year' },
    { value: 2592000, short: 'M', long: 'month' },
    { value: 604800, short: 'w', long: 'week' },
    { value: 86400, short: 'd', long: 'day' },
    { value: 3600, short: 'h', long: 'hour' },
    { value: 60, short: 'm', long: 'minute' },
    { value: 1, short: 's', long: 'second' },
  ];

  let remainingSeconds = second;
  const parts = [];

  for (const unit of units) {
    if (remainingSeconds >= unit.value) {
      const count = Math.floor(remainingSeconds / unit.value);
      remainingSeconds %= unit.value;

      const suffix = full
        ? ` ${unit.long}${count !== 1 ? 's' : ''}`
        : unit.short;
      parts.push(`${count}${suffix}`);
    }
  }

  return parts.join(' ') || '0s';
};

/**
 * Convert a precise duration string to seconds
 * @param duration string in format like "1d 3h 46m 40s"
 * @returns number of seconds
 * @example
 * convertPreciseDurationToSeconds("1d 3h 46m 40s") -> 100000
 * convertPreciseDurationToSeconds("11d 13h 46m 40s") -> 1000000
 * convertPreciseDurationToSeconds("1h 10m 20s") -> 4220
 * convertPreciseDurationToSeconds("5m") -> 300
 */
export const convertPreciseDurationToSeconds = (
  duration: string,
): number | string => {
  const unitToSeconds = {
    y: 31536000,
    d: 86400,
    h: 3600,
    m: 60,
    s: 1,
  };

  const parts = duration.split(' ');
  let totalSeconds = 0;

  for (const part of parts) {
    const unit = part.slice(-1);
    const value = parseInt(part.slice(0, -1), 10);

    if (isNaN(value) || !(unit in unitToSeconds)) {
      return `Invalid duration format: ${part}`;
    }

    totalSeconds += value * unitToSeconds[unit as keyof typeof unitToSeconds];
  }

  return totalSeconds;
};

export const convertTimeStringToDate = (value: string): DateSelection => {
  const startTimeUnix = convertTimeStringToUnix(value);

  if (!startTimeUnix) {
    return null;
  }

  return {
    startTimeUnix,
    endTimeUnix: dayjs().unix(),
    startLabel: value,
    endLabel: 'now',
  };
};

/**
 * Validate duration string
 * @param value string
 * Valid duration string: 1ns, 1us, 1ms, 1s, 1m, 1h, 1d, 1w, 1M, 1y
 * Invalid duration string: 1, 1n, 1u, 1m1, 1h1, 1d1, 1w1, 1M1, 1y1
 */
export const validateDurationString = (value: string): boolean => {
  const durationRegex = /^([0-9]+)([nusmhdwMy])$/;
  return durationRegex.test(value);
};

export const getTimeDiffInSeconds = (code: string) => {
  switch (code) {
    case '1h':
      return 3600;
    case '1d':
      return 86400;
    case '1w':
      return 604800;
    case '1M':
      return 2592000;
    case '3M':
      return 7776000;
    default:
      return 0;
  }
};

/**
 * Converts a local timestamp to UTC
 * @param timestamp - The local timestamp in milliseconds
 * @returns The UTC timestamp in milliseconds
 */
export function convertToUTC(timestamp: number): number {
  const date = new Date(timestamp);
  return date.getTime() + date.getTimezoneOffset() * MILLISECONDS * MINUTES;
}
