import dayjs from 'dayjs';
import { DateSelection } from 'types';

const isDigit = (char: string): boolean => {
  return /^\d$/.test(char);
};

const parseStepIntAndUnit = (
  step: string,
): { stepInt: number; unit: string } => {
  const chars = step.split('');
  let numberString = '';
  for (let i = 0; i < chars.length; i += 1) {
    const char = chars[i];
    if (isDigit(char)) {
      numberString += char;
    } else {
      return {
        stepInt: parseInt(numberString),
        unit: chars.slice(i).join(''),
      };
    }
  }
};

// https://github.com/grafana/grafana/pull/10434
export const getAdjustedStartAndEndTimeUnix = ({
  allowFutureTime = false,
  date,
  step,
  useCeilInAdjustingTime = false,
}: {
  allowFutureTime?: boolean;
  date: DateSelection;
  step: string;
  useCeilInAdjustingTime?: boolean;
}): DateSelection => {
  const { stepInt, unit } = parseStepIntAndUnit(step);
  const stepInSeconds = unit === 'ms' ? stepInt / 1000 : stepInt;
  const { startTimeUnix, endTimeUnix } = date;

  // https://github.com/grafana/grafana/pull/10434/files#diff-a4c7450a7fbfcf23283bf451fef14397c9b007c8fdba59ffe497fb55b2813e6bR111
  //use ceil for now only for SLO, verify if this is correct behaviour for other cases
  const clampedEndFloor =
    Math.floor(endTimeUnix / stepInSeconds) * stepInSeconds;
  const clampedCiel = Math.ceil(endTimeUnix / stepInSeconds) * stepInSeconds;
  const clampedEndToUse = useCeilInAdjustingTime
    ? clampedCiel
    : clampedEndFloor;

  const clampedRange =
    Math.floor((endTimeUnix - startTimeUnix) / stepInSeconds) * stepInSeconds;
  const currentTime = dayjs().unix();

  if (!allowFutureTime && clampedEndToUse > currentTime) {
    return {
      endTimeUnix: currentTime,
      startTimeUnix: currentTime - clampedRange,
    };
  }

  return {
    endTimeUnix: clampedEndToUse,
    startTimeUnix: clampedEndToUse - clampedRange,
  };
};
