import React, { useEffect, useState } from 'react';
import { RangeValue } from './types';
import { Input } from '../../Input';
import * as Shadcn from '../../shadcn';

type Prop = 'lower' | 'upper';

type Props = {
  min: number;
  max: number;
  onChange: (nextValue: RangeValue) => void;
  onEnteredChange?: (nextEntered: RangeValue) => void;
  value: RangeValue;
};

const RangeSlider = ({ min, max, onChange, onEnteredChange, value }: Props) => {
  const [entered, setEntered] = useState({
    lower: value.lower,
    upper: value.upper,
  });

  const onBlurHandler = (prop: Prop) => (
    e: React.FocusEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation();
    e.preventDefault();
    const nextValue = Number(e.currentTarget.value);
    if (value[prop] !== nextValue) {
      onChange({
        ...value,
        [prop]: nextValue,
      });
    }
  };

  const onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onChangeHandler = (nextValue: number[]) => {
    const [lower, upper] = nextValue;
    setEntered({
      lower,
      upper,
    });
  };

  const onChangeEnd = (nextValue: number[]) => {
    const [lower, upper] = nextValue;
    onChange({ lower, upper });
  };

  const onEnterHandler = (prop: Prop) => () => {
    onChange({
      ...value,
      [prop]: entered[prop],
    });
  };

  const onEnteredChangeHandler = (prop: Prop) => (value: RangeValue) => {
    setEntered((prevEntered) => {
      const nextEntered = {
        ...prevEntered,
        [prop]: value,
      };

      if (onEnteredChange) {
        onEnteredChange(nextEntered);
      }

      return nextEntered;
    });
  };

  useEffect(() => {
    if (value) {
      setEntered(value);
    }
  }, [value]);

  return (
    <div className="range-slider" onClick={onClick}>
      <div className="range-slider__inputs">
        <div className="range-slider__inputs__value">
          <Input
            className="input--small"
            onBlur={onBlurHandler('lower')}
            onChange={onEnteredChangeHandler('lower')}
            onEnter={onEnterHandler('lower')}
            type="number"
            value={entered.lower}
          />
        </div>
        <div className="range-slider__inputs__label">To</div>
        <div className="range-slider__inputs__value">
          <Input
            className="input--small"
            onBlur={onBlurHandler('upper')}
            onChange={onEnteredChangeHandler('upper')}
            onEnter={onEnterHandler('upper')}
            type="number"
            value={entered.upper}
          />
        </div>
      </div>
      <div className="range-slider__shadcn-slider">
        <Shadcn.Slider
          min={min}
          max={max}
          onValueChange={onChangeHandler}
          onValueCommit={onChangeEnd}
          value={[entered.lower, entered.upper]}
        />
      </div>
    </div>
  );
};

export default RangeSlider;
