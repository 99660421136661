import React from 'react';
import classnames from 'classnames';

type ClickableDivProps = React.HTMLAttributes<HTMLDivElement> & {
  onClick: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

const ClickableDiv = React.forwardRef<HTMLDivElement, ClickableDivProps>(
  ({ onClick, children, ...props }, ref) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onClick(event);
      }
      if (props.onKeyDown) {
        props.onKeyDown(event);
      }
    };

    return (
      <div
        {...props}
        ref={ref}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        className={classnames('cursor-pointer', props.className)}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
    );
  },
);

ClickableDiv.displayName = 'ClickableDiv';

export default ClickableDiv;
