import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  extractParamsFromURL,
  extractPathNameFromURL,
  tryJsonParse,
} from 'utils';

const useUrlState = <T>(
  key: string,
  initialState: T,
  cleanUp = false,
): [T, (nextState: T | ((prevState: T) => T)) => void] => {
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const initialStateFromParams = params.get(key);
  const parsedInitialStateFromParams = tryJsonParse(initialStateFromParams);

  const [state, setState] = useState<T>(
    parsedInitialStateFromParams ? parsedInitialStateFromParams : initialState,
  );

  const setStateAndUpdateUrl = (nextState: T | ((prevState: T) => T)) => {
    setState((prevState) => {
      const paramsFromURL = extractParamsFromURL();
      const computedNextState =
        typeof nextState === 'function' ? nextState(prevState) : nextState;
      paramsFromURL.set(key, JSON.stringify(computedNextState));
      const navigateUrl = `${extractPathNameFromURL()}?${paramsFromURL.toString()}`;
      navigate(navigateUrl, { replace: true, state: location.state });

      return computedNextState;
    });
  };

  useEffect(() => {
    return () => {
      if (cleanUp) {
        params.delete(key);
        const extractPathFromURL = key === 'searches';
        const navigateUrl = extractPathFromURL
          ? `${extractPathNameFromURL()}?${params.toString()}`
          : `?${params.toString()}`;
        navigate(navigateUrl, { replace: true, state: location.state });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, setStateAndUpdateUrl];
};

export default useUrlState;
