import dayjs from 'dayjs';

/**
 * Function to get the value of a variable {0M/M}
 * 0M/M - current month
 * 1M/M - previous month
 * 2M/M - 2 months ago
 * 0w/w - current week
 * 1w/w - previous week
 * 2w/w - 2 weeks ago
 * 0d/d - current day
 * 1d/d - previous day
 * 2d/d - 2 days ago
 * 0h/h - current hour
 * 1h/h - previous hour
 * 2h/h - 2 hours ago
 */
const getTimeUnixByUnit = (
  unit: string,
  shift: number,
  dateObj: dayjs.Dayjs,
) => {
  if (unit === 'M') {
    const startTimeUnix = dateObj
      .startOf('month')
      .subtract(shift, 'month')
      .unix();
    const endTimeUnix = dateObj.endOf('month').subtract(shift, 'month').unix();

    return { startTimeUnix, endTimeUnix };
  }

  if (unit === 'w') {
    const startTimeUnix = dateObj
      .startOf('week')
      .subtract(shift, 'week')
      .unix();
    const endTimeUnix = dateObj.endOf('week').subtract(shift, 'week').unix();
    return { startTimeUnix, endTimeUnix };
  }

  if (unit === 'd') {
    const startTimeUnix = dateObj.startOf('day').subtract(shift, 'day').unix();
    const endTimeUnix = dateObj.endOf('day').subtract(shift, 'day').unix();
    return { startTimeUnix, endTimeUnix };
  }

  if (unit === 'h') {
    const startTimeUnix = dateObj
      .startOf('hour')
      .subtract(shift, 'hour')
      .unix();
    const endTimeUnix = dateObj.endOf('hour').subtract(shift, 'hour').unix();
    return { startTimeUnix, endTimeUnix };
  }

  return undefined;
};

const calculateTimeShift = (time: string) => {
  const parts = time.split('/');
  const unit = parts[1];
  const dateObj = dayjs();

  if (parts[0].includes('now')) {
    const currentShift = getTimeUnixByUnit(unit, 0, dateObj);
    if (!currentShift) return undefined;

    return {
      startTimeUnix: currentShift.startTimeUnix,
      endTimeUnix: dateObj.unix(),
    };
  }

  const shift = parseInt(parts[0]);
  return getTimeUnixByUnit(unit, shift, dateObj);
};

export default calculateTimeShift;
