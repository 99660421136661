import { Loader, TimeseriesRenderer, SizeObserver } from 'components';
import dayjs from 'dayjs';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { promqlQueryRange } from 'requests';
import { formatChartLegend } from 'utils';
import { LayoutType, MetricsTransformSeriesProps } from 'types';

const defaultChartTypes = ['Stacked Bar'];

const SLOChart = ({
  promQl,
  legendFormat,
  layoutType,
  reloadChartId,
  stroke,
}: {
  promQl: string;
  legendFormat: string;
  layoutType?: LayoutType;
  reloadChartId?: string;
  stroke: string;
}): ReactElement => {
  const promqlQueryRangeRequest = useRequest(promqlQueryRange, false);

  const changedPromQL = useMemo(() => {
    const replace1DWith6H = promQl.replace(/\[1d\]/g, '[6h]');
    return replace1DWith6H;
  }, [promQl]);

  const loadSLOHistory = async () => {
    const oneDay = 24 * 60 * 60;
    const sixHoursInSeconds = 6 * 60 * 60;
    const dayUnix = dayjs().unix();
    const last30Days = oneDay * 30;
    promqlQueryRangeRequest.call({
      useCeilInAdjustingTime: true,
      date: { startTimeUnix: dayUnix - last30Days, endTimeUnix: dayUnix },
      metricNames: [],
      promqlQueries: [changedPromQL],
      seriesFormatter: ({
        idx,
        metric,
        promIndex,
      }: MetricsTransformSeriesProps) => {
        const series = formatChartLegend(
          idx,
          metric,
          legendFormat || JSON.stringify(metric),
        );
        return { ...series, ...(legendFormat ? { stroke: stroke } : {}) };
      },
      steps: [sixHoursInSeconds, sixHoursInSeconds],
      type: 'timeseries',
    });
  };

  useEffect(() => {
    loadSLOHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedPromQL]);

  useEffect(() => {
    if (reloadChartId) {
      loadSLOHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadChartId]);

  return (
    <div className="slo__details__history-chart">
      <Loader isLoading={promqlQueryRangeRequest.isLoading}>
        {!promqlQueryRangeRequest.isLoading &&
          promqlQueryRangeRequest.result &&
          promqlQueryRangeRequest.result.series.length === 0 && (
            <div className="slo__details__history-chart__no-data">
              Newly required SLOs will take some time to generate data. Please
              check back later, or flip to the historical view to see historical
              data.
            </div>
          )}
        <SizeObserver>
          {({ width }) => (
            <TimeseriesRenderer
              date={null}
              chartData={
                promqlQueryRangeRequest.result || { data: [], series: [] }
              }
              chartTypes={defaultChartTypes}
              layoutType={layoutType || undefined}
              legend={{ legendType: 'compact' }}
              tooltipType="compact"
              size={{ width: width - 16, height: 160 }}
              styles={{ boxShadow: false }}
              unit="number"
            />
          )}
        </SizeObserver>
      </Loader>
    </div>
  );
};

export default SLOChart;
