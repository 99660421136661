import { Zoom } from 'components/Flamegraph/types';
import { clampScale } from 'components/Flamegraph/utils';
import FlamegraphInner from 'components/Flamegraph/FlamegraphInner';
import ShiftToZoomHelperText from 'components/ShiftToZoomHelperText';
import { Loader } from 'components';
import { useRequest, useToggle } from 'hooks';
import React, { useMemo, useRef, useState } from 'react';
import useDebouncedEffect from 'use-debounced-effect';
import formatAndGroupEvents from './formatAndGroupEvents';
import getFlameGraphConfig from './getFlameGraphConfig';
import RumFlameGraphRows from './RumFlameGraphRows';
import { RumEvent, RumEventData, RumEventType } from '../types';
import getRumEvents from '../requests/getRumEvents';
import RumFlameGraphTooltip from './RumFlameGraphTooltip';
import RumFlameGraphTicks from './RumFlameGraphTicks';
import RumFlameGraphOverviewRows from './RumFlameGraphOverviewRows';
import { FLAME_GRAPH_TABLE_WIDTH } from './RumFlameGraph';
import { FormattedRumEventForFlameGraph } from './types';
import RumFlamegraphFilter from './RumFlamegraphFilter';
import { getIdFilter } from '../utils';
import {
  ONE_SEC_IN_NS,
  convertMsToSec,
  getDurationField,
  parseDurationField,
} from './utils';

type Props = {
  activeRumEvent: RumEvent;
  applicationFilter?: string;
  flameGraphChartContainerWidth: number;
  eventType: RumEventType;
  getColor: (event: FormattedRumEventForFlameGraph) => string;
  startTimeMs: number;
  setNestedDrawerEvent: (event: RumEvent | null) => void;
};

const getFacetValuesForType = (
  eventType: RumEventType,
  flameGraphFacetFilters: {
    actionName: string;
    actionType: string;
    errorSource: string;
    resourceType: string;
    resourceStatus: string;
    resourceUrl: string;
  },
) => {
  const selectedFacetValuesByNameForAction = {
    ...(flameGraphFacetFilters.actionName
      ? { 'action.name': { [flameGraphFacetFilters.actionName]: 1 } }
      : null),
    ...(flameGraphFacetFilters.actionType
      ? { 'action.type': { [flameGraphFacetFilters.actionType]: 1 } }
      : null),
  };
  const selectedFacetValuesByNameForError = {
    ...(flameGraphFacetFilters.errorSource
      ? { 'error.source': { [flameGraphFacetFilters.errorSource]: 1 } }
      : null),
  };
  const selectedFacetValuesByNameForResource = {
    ...(flameGraphFacetFilters.resourceType
      ? { 'resource.type': { [flameGraphFacetFilters.resourceType]: 1 } }
      : null),
    ...(flameGraphFacetFilters.resourceStatus
      ? {
          'resource.status_code': {
            [flameGraphFacetFilters.resourceStatus]: 1,
          },
        }
      : null),
    ...(flameGraphFacetFilters.resourceUrl
      ? { 'resource.url': { [flameGraphFacetFilters.resourceUrl]: 1 } }
      : null),
  };
  switch (eventType) {
    case RumEventType.ACTION:
      return selectedFacetValuesByNameForAction;
    case RumEventType.RESOURCE:
      return selectedFacetValuesByNameForResource;
    case RumEventType.ERROR:
      return selectedFacetValuesByNameForError;
    default:
      return {};
  }
};

const RumFlameGraphMain = ({
  activeRumEvent,
  applicationFilter,
  flameGraphChartContainerWidth,
  eventType,
  getColor,
  startTimeMs,
  setNestedDrawerEvent,
}: Props) => {
  const eventsRequest = useRequest(
    async ({
      eventTypes,
      startTimeUnix,
      endTimeUnix,
    }: {
      eventTypes: RumEventType[];
      startTimeUnix: number;
      endTimeUnix: number;
    }) => {
      const typesToFetch =
        eventTypes.length > 0
          ? eventTypes
          : [
              RumEventType.RESOURCE,
              RumEventType.ACTION,
              RumEventType.LONGTASK,
              RumEventType.ERROR,
            ];

      const eventRequests = typesToFetch.map((eventType) => {
        return getRumEvents({
          applicationFilter,
          startTimeUnix,
          endTimeUnix,
          eventType,
          idSearch: '',
          selectedFacetRangeByName: {},
          selectedFacetValuesByName: getFacetValuesForType(
            eventType,
            flameGraphFacetFilters,
          ),
          sortBy: 'time',
          sortOrder: 'asc',
          ...getIdFilter({
            activeRumEvent,
            shoulduseActionIds: eventType !== RumEventType.ACTION,
          }),
        });
      });

      const results = await Promise.all(eventRequests);
      return results.flat();
    },
    true,
    true,
  );
  const [tooltipEventId, setTooltipEventId] = useState<string | null>(null);
  const [hoveredEventId, setHoveredEventId] = useState<string | null>(null);

  const flameGraphContainerRef = useRef<HTMLDivElement>(null);
  const flameGraphOverviewContainerRef = useRef<HTMLDivElement>(null);

  const [eventTypes, setEventTypes] = useState<RumEventType[]>([]);
  const [flameGraphFacetFilters, setFrameGraphFacetFilters] = useState<{
    actionName: string;
    actionType: string;
    errorSource: string;
    resourceType: string;
    resourceStatus: string;
    resourceUrl: string;
  }>({
    actionName: '',
    actionType: '',
    errorSource: '',
    resourceType: '',
    resourceStatus: '',
    resourceUrl: '',
  });

  useDebouncedEffect(
    () => {
      const eventData = activeRumEvent.data as RumEventData;
      const durationField = getDurationField(eventType);
      const durationSecs = Math.ceil(
        parseDurationField(
          durationField ? eventData[durationField as keyof RumEventData] : '0',
        ) / ONE_SEC_IN_NS,
      );

      const startTimeUnix = Math.floor(convertMsToSec(startTimeMs));
      const endTimeUnix = Math.ceil(convertMsToSec(startTimeMs)) + durationSecs;

      eventsRequest.call({
        eventTypes,
        startTimeUnix,
        endTimeUnix,
      });
    },
    { ignoreInitialCall: false, timeout: 200 },
    [eventType, flameGraphFacetFilters, startTimeMs],
  );

  const dateFilter = useMemo(() => {
    const eventData = activeRumEvent.data as RumEventData;
    const durationField = getDurationField(eventType);
    const durationSecs = Math.ceil(
      parseDurationField(
        durationField ? eventData[durationField as keyof RumEventData] : '0',
      ) / ONE_SEC_IN_NS,
    );

    const startTimeUnix = Math.floor(convertMsToSec(startTimeMs));
    const endTimeUnix = Math.ceil(convertMsToSec(startTimeMs)) + durationSecs;

    return { startTimeUnix, endTimeUnix };
  }, [activeRumEvent, eventType, startTimeMs]);

  const elementRef = useRef<HTMLDivElement>(null);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [zoom, setZoom] = useState<Zoom>({
    scale: 1,
  });
  const showMapToggle = useToggle();
  const widthOfChartWithZoom = Math.max(
    flameGraphChartContainerWidth * zoom.scale,
    flameGraphChartContainerWidth,
  );

  const combinedRequestResult = useMemo(() => {
    if (!eventsRequest.result) {
      return null;
    }

    return eventsRequest.result
      .map((event) => event.data)
      .flat()
      .filter((event) => {
        if (!eventTypes?.length) {
          return true;
        }
        const isEventShown = eventTypes.includes(
          event.eventType as RumEventType,
        );
        return isEventShown;
      });
  }, [eventTypes, eventsRequest.result]);

  const flameGraphEventData = useMemo(() => {
    if (!combinedRequestResult) {
      return null;
    }

    return formatAndGroupEvents(combinedRequestResult);
  }, [combinedRequestResult]);

  const flameGraphConfig = useMemo(() => {
    if (!flameGraphEventData) {
      return null;
    }

    const { formattedEvents } = flameGraphEventData;

    return getFlameGraphConfig({
      activeRumEvent,
      formattedEvents,
      flameGraphChartContainerWidth,
      widthOfChartWithZoom,
      zoom,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flameGraphEventData, widthOfChartWithZoom, zoom]);

  const onScroll = (e: any) => {
    setScrollLeft(e.currentTarget.scrollLeft);
  };

  const onWheel = (e: any) => {
    if (e.shiftKey) {
      e.preventDefault();
      if (!showMapToggle.value) {
        showMapToggle.on();
      }

      const delta = -e.deltaY;
      if (Math.abs(delta) > 5) {
        setZoom((prevZoom) => {
          const { scale } = prevZoom;

          const nextScale = delta > 0 ? scale * 1.025 : scale / 1.025;

          return {
            scale: clampScale(nextScale),
          };
        });
      }
    }
  };

  const hoveredEvent = useMemo(() => {
    const eventById = flameGraphEventData?.eventById;
    if (
      !hoveredEventId ||
      !eventById ||
      !eventById[hoveredEventId as keyof typeof eventById]
    ) {
      return null;
    }

    const event = eventById[
      hoveredEventId as keyof typeof eventById
    ] as FormattedRumEventForFlameGraph;

    return event;
  }, [flameGraphEventData?.eventById, hoveredEventId]);

  const tooltipEvent = useMemo(() => {
    const eventById = flameGraphEventData?.eventById;
    if (
      !tooltipEventId ||
      !eventById ||
      !eventById[tooltipEventId as keyof typeof eventById]
    ) {
      return null;
    }
    const event = eventById[
      tooltipEventId as keyof typeof eventById
    ] as FormattedRumEventForFlameGraph;
    return event;
  }, [flameGraphEventData?.eventById, tooltipEventId]);

  const handleSetTooltipAndHoveredEvent = (eventId: string | null) => {
    if (eventId) {
      setHoveredEventId(eventId);
      setTooltipEventId(eventId);
    } else {
      setHoveredEventId(null);
      setTooltipEventId(null);
    }
  };

  const handleClickedEvent = (eventId: string) => {
    if (!eventId) {
      setNestedDrawerEvent(null);
      return;
    }
    const event = eventById[
      eventId as keyof typeof eventById
    ] as FormattedRumEventForFlameGraph;
    const originalEvent = event.originalEvent;

    if (originalEvent) {
      setNestedDrawerEvent(originalEvent);
    }
  };

  if (eventsRequest.isLoading || !eventsRequest.calledAtLeastOnce) {
    return <Loader className="h-full" isLoading />;
  }

  if (!flameGraphConfig || !flameGraphEventData) {
    return (
      <>
        <RumFlamegraphFilter
          applicationFilter={applicationFilter}
          activeRumEvent={activeRumEvent}
          dateFilter={dateFilter}
          eventType={eventType}
          eventTypes={eventTypes}
          flameGraphFacetFilters={flameGraphFacetFilters}
          setEventTypes={setEventTypes}
          setFrameGraphFacetFilters={setFrameGraphFacetFilters}
        />
        <div className="placeholder h-full">No Events Found</div>
      </>
    );
  }

  const {
    minPresentationalDuration,
    minStartTimeNs,
    maxEndTimeNs,
    niceUpperBound,
    niceUpperBoundForOverview,
    tickSpacing,
    tickSpacingForOverview,
  } = flameGraphConfig;

  const { eventById, eventIdsByEventType, flameGraphRows } =
    flameGraphEventData;

  return (
    <>
      <RumFlamegraphFilter
        applicationFilter={applicationFilter}
        activeRumEvent={activeRumEvent}
        dateFilter={dateFilter}
        eventType={eventType}
        eventTypes={eventTypes}
        flameGraphFacetFilters={flameGraphFacetFilters}
        setEventTypes={setEventTypes}
        setFrameGraphFacetFilters={setFrameGraphFacetFilters}
      />
      <div className="rum-flamegraph__overview-main-container">
        <div className="rum-flamegraph__overview-table">
          <div className="rum-flamegraph__overview-table-item font-bold">
            <div className="rum-flamegraph__overview-table-item__label">
              Actions
            </div>
            <div className="rum-flamegraph__overview-table-item__label">
              Errors
            </div>
            <div className="rum-flamegraph__overview-table-item__label">
              Resources
            </div>
            <div className="rum-flamegraph__overview-table-item__label">
              Long Tasks
            </div>
          </div>
        </div>
        <div
          className="rum-flamegraph__content"
          ref={flameGraphOverviewContainerRef}
        >
          <RumFlameGraphTicks
            className="flamegraph__ticks--sticky"
            containerRef={flameGraphOverviewContainerRef}
            hoveredEvent={hoveredEvent}
            minStartTimeNs={minStartTimeNs}
            maxEndTimeNs={maxEndTimeNs}
            niceUpperBound={niceUpperBoundForOverview}
            tickSpacing={tickSpacingForOverview}
            width={
              flameGraphChartContainerWidth + FLAME_GRAPH_TABLE_WIDTH - 100
            }
          />
          <div className="mt-1">
            {[
              RumEventType.ACTION,
              RumEventType.ERROR,
              RumEventType.RESOURCE,
              RumEventType.LONGTASK,
            ].map((eventType) => (
              <div className="rum-flamegraph__overview-row" key={eventType}>
                <div className="rum-flamegraph__overview-row__line"></div>
                {(eventIdsByEventType[eventType] || []).map((eventId) => (
                  <RumFlameGraphOverviewRows
                    key={eventId}
                    flameGraphConfig={flameGraphConfig}
                    getColor={getColor}
                    hoveredEventId={hoveredEventId}
                    formattedEvent={
                      eventById[eventId as keyof typeof eventById]
                    }
                    eventId={eventId}
                    setHoveredEventId={setHoveredEventId}
                    width={
                      flameGraphChartContainerWidth +
                      FLAME_GRAPH_TABLE_WIDTH -
                      100
                    }
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <FlamegraphInner
        baseWidth={flameGraphChartContainerWidth}
        elementRef={elementRef}
        onScroll={onScroll}
        zoom={zoom}
      >
        <div
          className="flamegraph__visualization rum-flamegraph__header-container"
          onWheel={onWheel}
          ref={flameGraphContainerRef}
          style={{
            width: `${widthOfChartWithZoom + FLAME_GRAPH_TABLE_WIDTH}px`,
          }}
        >
          <div className="rum-flamegraph__header flex">
            <div className="rum-flamegraph__table__item font-bold">
              <div className="rum-flamegraph__table__item__label">Name</div>
              <div className="rum-flamegraph__table__item__label rum-flamegraph__table__item__label--fixed">
                Size
              </div>
              <div className="rum-flamegraph__table__item__label rum-flamegraph__table__item__label--fixed">
                Duration
              </div>
            </div>
            <div className="rum-flamegraph__content">
              <RumFlameGraphTicks
                className="flamegraph__ticks"
                containerRef={flameGraphContainerRef}
                hoveredEvent={hoveredEvent}
                minStartTimeNs={minStartTimeNs}
                maxEndTimeNs={maxEndTimeNs}
                niceUpperBound={niceUpperBound}
                tickSpacing={tickSpacing}
                width={widthOfChartWithZoom}
              />
            </div>
          </div>
          <RumFlameGraphRows
            elementRef={elementRef}
            getColor={getColor}
            hoveredEventId={hoveredEventId}
            minPresentationalDuration={minPresentationalDuration}
            minStartTimeNs={minStartTimeNs}
            maxEndTimeNs={maxEndTimeNs}
            setClickedEventId={handleClickedEvent}
            setHoveredEventId={handleSetTooltipAndHoveredEvent}
            scrollLeft={scrollLeft}
            eventById={eventById}
            flameGraphRows={flameGraphRows}
            width={widthOfChartWithZoom}
            niceUpperBound={niceUpperBound}
          />
        </div>
      </FlamegraphInner>
      <div className="flamegraph__helper-text">
        <ShiftToZoomHelperText />
      </div>
      {tooltipEvent ? (
        <RumFlameGraphTooltip
          tooltipEvent={tooltipEvent}
          minStartTimeNs={minStartTimeNs}
        />
      ) : null}
    </>
  );
};

export default RumFlameGraphMain;
