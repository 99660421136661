import { FacetAccordion } from 'components';
import React, { ReactNode, useState } from 'react';

type Props = {
  children: ReactNode;
  forceExpanded?: boolean;
  group: string;
  isLastListItem?: boolean;
};

const FacetGroup = ({
  children,
  forceExpanded,
  group,
  isLastListItem,
}: Props): React.ReactElement => {
  const [expanded, setExpanded] = useState(forceExpanded);
  return (
    <>
      <FacetAccordion
        renderTrigger={() => group}
        renderContent={() => (expanded ? children : null)}
        isVisuallyHidden={false}
        expanded={expanded}
        setExpanded={setExpanded}
        triggerClassName="leading-[28px] pl-2 mb-1 w-full active:bg-interaction-nested-contrast hover:bg-interaction-nested data-[state=open]:bg-interaction-nested border-t-0 border-transparent cursor-pointer group"
      />
      {expanded && !isLastListItem && <div className="left-sidebar__divider" />}
    </>
  );
};

export default FacetGroup;
