import { Button, InputWithValidatorV3, useToaster } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useState } from 'react';
import { MdModeEdit as EditIcon, MdClose as CloseIcon } from 'react-icons/md';

import useUserManagementState from './useUserManagementState';
import { updateRoleById } from './requests';

const UserManagementEditInfo = ({
  userManagementState,
}: {
  userManagementState: ReturnType<typeof useUserManagementState>;
}): ReactElement => {
  const { addToast } = useToaster();
  const { userByIdRequest } = userManagementState;
  const user = userByIdRequest.result;

  const updateUserByIdRequest = useRequest(updateRoleById);

  const [isNameEditActive, setIsNameEditActive] = useState(false);
  const [name, setName] = useState(user?.name);

  const onSaveName = () => {
    if (!name || !user.id) {
      addToast({ text: 'Name is required', status: 'error' });
      return;
    }
    updateUserByIdRequest
      .call({
        userId: user.id,
        user: { ...user, name },
      })
      .then((res) => {
        if (res) {
          addToast({ text: 'Name updated successfully', status: 'success' });
          userByIdRequest.call({ userId: user.id });
        }
        setIsNameEditActive(false);
      });
  };

  return (
    <div className="pb-4">
      <div className="alerts__contacts__list__header">
        <h2 className="text-lg font-semibold">User Management</h2>
      </div>
      <div>
        <table className="w-full table-auto">
          <tbody>
            <tr className="admin__row-muted">
              <td className="w-[200px] py-2 pl-2 font-medium">Name</td>
              <td className="min-w-[400px] text-left font-medium">
                {isNameEditActive ? (
                  <div className="flex items-center gap-2">
                    {updateUserByIdRequest.isLoading ? (
                      <div className="admin__row-muted flex min-h-[32px] items-center justify-center">
                        <div className="spinner"></div>
                      </div>
                    ) : (
                      <>
                        <InputWithValidatorV3
                          className="max-w-[200px]"
                          size="4"
                          type="text"
                          variant="default"
                          value={name}
                          placeholder="Enter name"
                          onChange={(e) => setName(e)}
                        />
                        <Button
                          variant="default"
                          size="sm"
                          onClick={onSaveName}
                        >
                          Save
                        </Button>
                      </>
                    )}
                  </div>
                ) : (
                  user?.name
                )}
              </td>
              <td className="pr-2 text-right font-medium">
                {isNameEditActive ? (
                  <Button
                    variant="ghost-destructive"
                    size="sm"
                    onClick={() => setIsNameEditActive(false)}
                  >
                    <CloseIcon />
                  </Button>
                ) : (
                  <Button
                    variant="link"
                    size="sm"
                    onClick={() => setIsNameEditActive(true)}
                  >
                    <EditIcon />
                  </Button>
                )}
              </td>
            </tr>
            <tr>
              <td className="w-[200px] py-2 pl-2 font-medium">Email</td>
              <td className="min-w-[400px] text-left font-medium">
                {user?.email}
              </td>
              <td></td>
            </tr>
            <tr className="admin__row-muted">
              <td className="w-[200px] py-2 pl-2 font-medium">Username</td>
              <td className="min-w-[400px] text-left font-medium">
                {user?.login}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserManagementEditInfo;
