import { ReactNode } from 'react';

type Props = {
  children: JSX.Element;
  disabled?: boolean;
  label?: ReactNode;
};

const Tab = ({ children }: Props): JSX.Element => {
  return children;
};

export default Tab;
