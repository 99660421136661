import { startCase } from 'lodash';
import dayjs from 'dayjs';
import { DateSelection, SelectedFacetValuesByName } from 'types';
import { onPromiseError } from 'utils';
import fetchJson from '../../../requests/fetchJson';

type Props = {
  date: DateSelection;
  selectedFacetValuesByName?: SelectedFacetValuesByName;
};

const OMITTED_LABELS = ['__name__'];

const formatServerLessLabelsResponse = (result) => {
  const response = result?.data || [];

  const filteredResponse = response.filter(
    (item: string) => !OMITTED_LABELS.includes(item),
  );

  const formattedResponse = filteredResponse.map((item: string) => {
    return {
      facetName: item,
      name: item,
      renderName: () => startCase(item),
    };
  });

  return formattedResponse;
};

const serverlessLabels = ({ date }: Props): Promise<string[]> => {
  const { endTimeUnix, startTimeUnix } = date;
  const start = dayjs.unix(startTimeUnix).toISOString();
  const end = dayjs.unix(endTimeUnix).toISOString();
  return fetchJson(
    `/api/v1/labels?match[]=aws_lambda_invocations&start=${start}&end=${end}`,
  ).then(formatServerLessLabelsResponse, onPromiseError);
};

export default serverlessLabels;
