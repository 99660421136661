import fetchGrafanaApi from './fetchGrafanaApi';

const createGrafanaFolder = ({
  folderName,
  uid,
  version,
}: {
  folderName: string;
  uid?: string;
  version?: number;
}): Promise<Record<string, unknown>> => {
  const url = uid ? `grafana/api/folders/${uid}` : `grafana/api/folders`;
  const body = { title: folderName };
  if (version) {
    body.version = version;
  }
  return fetchGrafanaApi(url, {
    headers: { 'Content-Type': 'application/json' },
    method: uid ? 'PUT' : 'POST',
    body: JSON.stringify(body),
  });
};

export default createGrafanaFolder;
