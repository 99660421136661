import { useThemeContext } from 'components';
import { dateTimeFormat } from 'kfuse-constants/dateTimeFormat';
import dayjs from 'dayjs';
import React, { ReactElement } from 'react';

const CompactTooltipText = ({
  color,
  label,
  position,
  value,
  timestamp,
}: {
  color: string;
  label: string;
  position: string;
  value?: string;
  timestamp?: number;
}): ReactElement => {
  const { utcTimeEnabled } = useThemeContext();

  return (
    <div
      className="uplot__compact-tooltip"
      style={{
        alignItems: position === 'left' ? 'flex-end' : 'flex-start',
      }}
    >
      {timestamp && (
        <div className="uplot__compact-tooltip__value">
          {utcTimeEnabled
            ? dayjs.unix(timestamp).utc().format(dateTimeFormat)
            : dayjs.unix(timestamp).format(dateTimeFormat)}
        </div>
      )}
      <div
        className="uplot__compact-tooltip__item"
        style={{ backgroundColor: color }}
      >
        <div>{label}</div>
      </div>
      {Boolean(value) && (
        <div className="uplot__compact-tooltip__value">{value}</div>
      )}
    </div>
  );
};

export default CompactTooltipText;
