import { LogsMetricQueryProps, RangeAggregate } from 'types';
import { validateDurationString } from 'utils';

export const validateLogqlQuery = (
  query: LogsMetricQueryProps,
): string | boolean => {
  const { limit, logql, rangeAggregate, rangeAggregateParam, showInput, step } =
    query;
  if (showInput && !logql) return 'LogQL query is required';

  if (step && !validateDurationString(step)) return 'Invalid step';

  if (limit) {
    const { count } = limit;
    if (!count || count < 1) return 'Invalid limit count';
  }

  if (
    rangeAggregate === RangeAggregate.quantile_over_time &&
    !rangeAggregateParam
  ) {
    return 'Range aggregate param is required';
  }

  return true;
};
