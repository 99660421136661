import classNames from 'classnames';
import { InputWithValidatorV2, Textarea, TooltipTrigger } from 'components';
import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form';

import { useCreateSLOState } from '../hooks';
const SLOCreateDetails = ({
  createSLOState,
  isEditMode,
  setIsSloNameChanged,
}: {
  activeDetection: string;
  createSLOState: ReturnType<typeof useCreateSLOState>;
  isEditMode: boolean;
  setIsSloNameChanged: (val: boolean) => void;
}): ReactElement => {
  const { control, sloFormError, registerSLOInput, setFormValue } =
    createSLOState;

  return (
    <div className="slo__create__details">
      <TooltipTrigger
        tooltip={isEditMode ? 'Name cannot be edited' : ''}
        className={classNames({
          'slo__type--disabled': isEditMode,
        })}
      >
        <div>
          <p>Name:</p>
          <InputWithValidatorV2
            {...registerSLOInput('sloName')}
            errorText={sloFormError.sloName?.message as string}
            placeholder="Enter the name"
            type="text"
            disabled={isEditMode}
            onChange={(e) => {
              setFormValue('sloName', e.target.value);
              setIsSloNameChanged(true);
            }}
          />
        </div>
      </TooltipTrigger>
      <div>
        <p>Description:</p>
        <Controller
          control={control}
          name="sloDescription"
          render={({ field }) => (
            <Textarea
              {...field}
              errorText={sloFormError.sloDescription?.message as string}
              placeholder="Enter the description"
              type="text"
            />
          )}
        />
      </div>
    </div>
  );
};

export default SLOCreateDetails;
