import { useMemo, useState } from 'react';

type Args = {
  getItemString: (item: unknown) => string;
  items: unknown[];
};

const useSimpleSearch = ({ getItemString, items }: Args) => {
  const [search, setSearch] = useState<string>('');

  const searchedItems = useMemo(() => {
    if (search) {
      const searchLowered = search.toLowerCase();
      return items.filter((item) => {
        const itemString = getItemString(item);
        return typeof itemString === 'string'
          ? itemString.toLowerCase().indexOf(searchLowered) > -1
          : true;
      });
    }
    return items;
  }, [items, search]);

  return {
    search,
    searchedItems,
    setSearch,
  };
};

export default useSimpleSearch;
