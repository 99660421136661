import { convertNumberToReadableUnit } from 'utils';

const MAX_TOP_LIST_ITEM_HEIGHT = 50;
const MIN_TOP_LIST_ITEM_HEIGHT = 24;

const calculateToplistSize = (
  height: number,
  numberOfItem: number,
  width: number,
) => {
  const scalingFactor = width / 1000; // This divisor to change the impact of width on the scaling
  const itemHeight = Math.min(
    MAX_TOP_LIST_ITEM_HEIGHT,
    Math.max(
      MIN_TOP_LIST_ITEM_HEIGHT,
      Math.floor((height / (numberOfItem + 1)) * scalingFactor),
    ),
  );
  const fontSize = Math.floor(itemHeight * 0.6);
  return { itemHeight, fontSize };
};

export const computeToplist = (
  data: Array<{ label: string; count: number; evaluatedValue?: string }>,
  height: number,
  width: number,
): {
  topList: { label: string; readableText: string; percentage: number }[];
  itemHeight: number;
  fontSize: number;
  maxTextWidth: number;
  maxFillWidth: number;
} => {
  if (!data || !Array.isArray(data)) return null;
  if (data.length === 0) return null;
  if (!data[0].label || !data[0].count) return null;

  const { itemHeight, fontSize } = calculateToplistSize(
    height,
    data.length,
    width,
  );
  const topList = [];
  let maxTextWidth = 10;
  const maxValue = data.reduce((max, item) => Math.max(max, item.count), 0);
  for (const item of data) {
    const { label, count, evaluatedValue } = item;
    const readableText = evaluatedValue
      ? evaluatedValue
      : convertNumberToReadableUnit(count, 2);

    const textWidth = readableText.length * fontSize * 0.7;
    maxTextWidth = Math.max(maxTextWidth, textWidth);
    topList.push({
      label,
      readableText,
      percentage: (count / maxValue) * 100,
    });
  }

  maxTextWidth = maxTextWidth + maxTextWidth * 0.1; // add 10% padding
  return {
    topList,
    itemHeight,
    fontSize,
    maxTextWidth,
    maxFillWidth: width - maxTextWidth, // 4 is the margin between text and fill
  };
};
