import { has, size, pickBy, fromPairs, first } from 'lodash';
import exhaustiveSwitchError from './exhaustiveSwitchError';
import { delimiter } from 'kfuse-constants';

export enum FilterType {
  EMPTY = 'empty',
  EQ = 'eq',
  NEQ = 'neq',
}

export enum FacetValueActionType {
  SELECT = 'Select',
  ALL = 'All',
  ONLY = 'Only',
  TOGGLE = 'Toggle',
}

const getFilterType = (
  selectedFacetValues: Record<string, 0 | 1>,
): FilterType => {
  if (size(selectedFacetValues) === 0) return FilterType.EMPTY;
  return first(Object.values(selectedFacetValues)) === 1
    ? FilterType.EQ
    : FilterType.NEQ;
};

const createCompositeKey = (facetName: string, value: string) =>
  `${facetName}${delimiter}${value}`;

const getNextSelectedFacetValues = ({
  facetName,
  facetValueToToggle,
  allFacetValues,
  selectedFacetValues,
  shouldUseCompositeKey,
}: {
  facetName: string;
  facetValueToToggle: string;
  allFacetValues: Array<string>;
  selectedFacetValues: Record<string, 0 | 1>;
  shouldUseCompositeKey?: boolean;
}): Record<string, 0 | 1> => {
  const filterType = getFilterType(selectedFacetValues);
  const facetValueKey = shouldUseCompositeKey
    ? createCompositeKey(facetName, facetValueToToggle)
    : facetValueToToggle;

  const isFacetValueAlreadyIncludedInFilters = has(
    selectedFacetValues,
    facetValueKey,
  );

  const isListWithSingleItem = size(allFacetValues) === 1;

  if (isListWithSingleItem) {
    // Clears the filter if there's exactly one item in the list and the current filter type is NEQ.
    if (filterType === FilterType.NEQ) {
      return {};
    }
    // Toggles EQ to NEQ (for a list with single item)
    if (filterType === FilterType.EQ) {
      return {
        [facetValueKey]: isFacetValueAlreadyIncludedInFilters ? 0 : 1,
      };
    }
  }

  switch (filterType) {
    case FilterType.EMPTY:
      return { [facetValueKey]: 0 };
    case FilterType.EQ:
      if (
        size(selectedFacetValues) === 1 &&
        isFacetValueAlreadyIncludedInFilters
      ) {
        return {};
      }
      // Clear filters when there are N-1 EQ Filters
      if (size(selectedFacetValues) === allFacetValues.length - 1) {
        return {};
      }
      // Toggle current value
      return isFacetValueAlreadyIncludedInFilters
        ? pickBy(selectedFacetValues, (_, key) => key !== facetValueKey)
        : { ...selectedFacetValues, [facetValueKey]: 1 };
    case FilterType.NEQ:
      // Reset N NEQ filters to 1 EQ filter
      if (size(selectedFacetValues) === allFacetValues.length) {
        return { [facetValueKey]: 1 };
      }
      // Toggle current value
      return isFacetValueAlreadyIncludedInFilters
        ? pickBy(selectedFacetValues, (_, key) => key !== facetValueKey)
        : { ...selectedFacetValues, [facetValueKey]: 0 };
    default:
      throw exhaustiveSwitchError(filterType);
  }
};

const facetValueToggleUtil = {
  getFilterType,
  getNextSelectedFacetValues,
};

export default facetValueToggleUtil;
