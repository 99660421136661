import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useKubernetesPageState = () => {
  const { pathname, search } = useLocation();

  const [snapshot, setSnapshot] = useState(() => {
    if (pathname === '/kubernetes' || !pathname.startsWith('/kubernetes')) {
      return null;
    }

    return { pathname, search };
  });

  useEffect(() => {
    if (pathname !== '/kubernetes' && pathname.startsWith('/kubernetes')) {
      setSnapshot({ pathname, search });
    }
  }, [pathname, search]);

  return {
    snapshot,
  };
};

export default useKubernetesPageState;
