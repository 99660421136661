import { SizeObserver, CursorStateProvider } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  getDatasources,
  getLogMetricsResultWithKfuseQl,
  getLogMetricsTimeSeriesLogQL,
  promqlQueryRange,
} from 'requests';
import {
  DashboardPanelType,
  DateSelection,
  LayoutType,
  QueryLangType,
} from 'types';
import {
  calculateForecastLookbackDuration,
  escapeLinesTabsAndQuotes,
  getForecastLookbackAndPredictDate,
  getRollupByVisualization,
  getRollupToSecond,
  kfuseqlDataTransformer,
  logsDataTransformer,
} from 'utils';

import {
  AlertsChart,
  AlertsChartAnomalyPromql,
  AlertsChartPromql,
  AlertsChartForecast,
  AlertsChartAnomalyKfuseql,
  AlertsCreateLogsChartOutlier,
  AlertsCreateLogsChartForecastSplit,
} from '../AlertsChart';
import { apmChartUnit } from '../AlertsCreateAPM/utils';
import {
  AlertType,
  AlertsMetricsParsedProps,
  RuleProps,
  RuleType,
} from '../types';
import { AlertsCreateTracesChart } from '../AlertsCreateTraces';

const AlertsDetailsChart = ({
  alertParsedMetadata,
  chartLayoutType,
  date,
  isChartCompact,
  rule,
}: {
  alertParsedMetadata: AlertsMetricsParsedProps;
  chartLayoutType?: LayoutType;
  date: DateSelection;
  isChartCompact: boolean;
  rule: RuleProps;
}): ReactElement => {
  const { condition, formulas, promqls, queries } = alertParsedMetadata;
  const [datasource, setDatasource] = useState(null);
  const getDatasourcesRequest = useRequest(getDatasources);
  const promqlQueryRangeRequest = useRequest(promqlQueryRange);
  const logMetricsTimeSeriesLogQLRequest = useRequest(
    getLogMetricsTimeSeriesLogQL,
  );
  const kfuseqlQueryRequest = useRequest(getLogMetricsResultWithKfuseQl);
  const extraData = JSON.parse(rule?.annotations?.extraData || '{}');
  const ruleType = rule.annotations?.ruleType;
  const alertType = rule.annotations?.alertType;

  const callLoadEvaluationGraph = async (newDatasource: string) => {
    if (newDatasource === 'prometheus') {
      const encodedPromqls = promqls.map((promql) =>
        encodeURIComponent(promql),
      );
      promqlQueryRangeRequest.call({
        date,
        metricNames: [''],
        promqlQueries: encodedPromqls,
        type: 'timeseries',
      });
    } else if (newDatasource === 'loki') {
      logMetricsTimeSeriesLogQLRequest.call({
        date,
        logQlQuery: { logQL: promqls[0] },
        width: document.body.clientWidth - 140,
      });
    } else if (newDatasource === QueryLangType.KFUSEQL) {
      const transformer = logsDataTransformer(false);
      transformer[0] = {
        id: 'kfuseqlDataTransformer',
        func: kfuseqlDataTransformer,
      };
      const escapedKfuseQl = escapeLinesTabsAndQuotes(promqls[0]);
      kfuseqlQueryRequest.call({
        date,
        meta: {
          refId: 'a',
          step: getRollupByVisualization(date),
          type: DashboardPanelType.TIMESERIES,
        },
        kfuseQl: escapedKfuseQl,
        transformer,
      });
    }
  };

  const loadEvaluationGraph = async () => {
    if (getDatasourcesRequest.isLoading) return;

    if (
      datasource &&
      datasource.type === 'loki' &&
      extraData.queryLangType === QueryLangType.KFUSEQL
    ) {
      callLoadEvaluationGraph(QueryLangType.KFUSEQL);
      return;
    }

    if (datasource && datasource.type) {
      callLoadEvaluationGraph(datasource.type);
      return;
    }

    const result = await getDatasourcesRequest.call();
    const datasourceReq = result.find(
      (data) =>
        data.uid === rule.datasourceUid || data.name === rule.datasourceUid,
    );
    setDatasource(datasourceReq);

    if (datasourceReq) {
      if (extraData.queryLangType === QueryLangType.KFUSEQL) {
        callLoadEvaluationGraph(QueryLangType.KFUSEQL);
        return;
      }
      callLoadEvaluationGraph(datasourceReq.type);
    }
  };

  useEffect(() => {
    if (
      rule.annotations.alertType === AlertType.FORECAST ||
      rule.annotations.alertType === AlertType.ANOMALY ||
      rule.annotations.alertType === AlertType.CHANGE ||
      rule.annotations.alertType === AlertType.OUTLIERS ||
      rule.annotations.ruleType === RuleType.TRACES
    ) {
      return;
    }
    loadEvaluationGraph();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promqls, condition, date]);

  const dataRequest =
    datasource?.type === 'loki'
      ? extraData.queryLangType === QueryLangType.KFUSEQL
        ? kfuseqlQueryRequest
        : logMetricsTimeSeriesLogQLRequest
      : promqlQueryRangeRequest;

  if (rule.annotations.ruleType === RuleType.TRACES) {
    return (
      <AlertsCreateTracesChart
        queries={alertParsedMetadata.queries}
        formulas={alertParsedMetadata.formulas}
        condition={alertParsedMetadata.condition}
        date={date}
      />
    );
  }

  if (
    ruleType === RuleType.LOGS &&
    (alertType === AlertType.FORECAST ||
      alertType === AlertType.OUTLIERS ||
      alertType === AlertType.ANOMALY)
  ) {
    const escapedKfuseQl = escapeLinesTabsAndQuotes(promqls[0]);
    const queryItem = {
      kfuseql: escapedKfuseQl,
      refId: 'a',
      type: DashboardPanelType.TIMESERIES,
      queryType: 'query',
      step: alertParsedMetadata.step
        ? getRollupToSecond(alertParsedMetadata.step)
        : getRollupByVisualization(date),
      metricName: alertParsedMetadata.operation,
      kfuseQlOperation: alertParsedMetadata.operation,
    };

    if (alertType === AlertType.FORECAST) {
      const { forecastCondition } = alertParsedMetadata;
      const { forecastDuration, forecastAlgorithm, interval, seasonality } =
        forecastCondition;
      const lookbackDuration =
        calculateForecastLookbackDuration(forecastDuration);
      const dateForChart = getForecastLookbackAndPredictDate({
        forecastDuration,
        lookbackDuration,
        forecastInterval:
          forecastAlgorithm === 'seasonal' ? Number(interval) : undefined,
        date,
        seasonality: forecastAlgorithm === 'seasonal' ? seasonality : undefined,
        type: 'metrics',
      });
      dateForChart.step = queryItem.step;

      return (
        <SizeObserver>
          {({ width: baseWidth }) => (
            <CursorStateProvider>
              <AlertsCreateLogsChartForecastSplit
                baseWidth={baseWidth}
                forecastCondition={alertParsedMetadata.forecastCondition}
                date={date}
                dateForChart={dateForChart}
                isChartCompact={isChartCompact}
                queryItem={queryItem}
                getQueryMappings={{
                  formulas: [],
                  queries: [{ queryKey: 'a', isActive: true }],
                }}
                unit="number"
              />
            </CursorStateProvider>
          )}
        </SizeObserver>
      );
    }

    return (
      <SizeObserver>
        {({ width: baseWidth }) => (
          <>
            {alertType === AlertType.ANOMALY && (
              <AlertsChartAnomalyKfuseql
                anomalyCondition={alertParsedMetadata.anomalyCondition}
                baseWidth={baseWidth}
                date={date}
                isChartCompact={isChartCompact}
                chartLayoutType={chartLayoutType}
                forWindow={rule.evaluate?.for}
                queryItem={queryItem}
                unit="number"
              />
            )}
            {alertType === AlertType.OUTLIERS && (
              <AlertsCreateLogsChartOutlier
                outlierCondition={alertParsedMetadata.outlierCondition}
                chartLayoutType={chartLayoutType}
                date={date}
                isChartCompact={isChartCompact}
                forWindow={rule.evaluate?.for}
                queryItem={queryItem}
                unit="number"
              />
            )}
          </>
        )}
      </SizeObserver>
    );
  }

  if (rule.annotations?.alertType === AlertType.ANOMALY) {
    return (
      <SizeObserver>
        {({ width: baseWidth }) => (
          <AlertsChartAnomalyPromql
            anomalyCondition={alertParsedMetadata.anomalyCondition}
            baseWidth={baseWidth}
            date={date}
            isChartCompact={isChartCompact}
            chartLayoutType={chartLayoutType}
            forWindow={rule.evaluate?.for}
            queryItem={{
              promql: alertParsedMetadata.promqls[0],
              refId: 'a',
              type: DashboardPanelType.TIMESERIES,
              queryType: 'query',
              step: getRollupByVisualization(date),
              metricName: '{*}',
            }}
            unit={
              rule.annotations.ruleType === RuleType.APM
                ? apmChartUnit[extraData?.apmTriggerType]
                : 'number'
            }
          />
        )}
      </SizeObserver>
    );
  }

  if (rule.annotations.alertType === AlertType.FORECAST) {
    return (
      <SizeObserver>
        {({ width: baseWidth }) => (
          <CursorStateProvider>
            <AlertsChartForecast
              baseWidth={baseWidth}
              isChartCompact={isChartCompact}
              chartLayoutType={chartLayoutType}
              condition={alertParsedMetadata.condition}
              date={date}
              forecastCondition={alertParsedMetadata.forecastCondition}
              formulas={formulas}
              queries={queries}
              queryKey={condition.queryKey}
            />
          </CursorStateProvider>
        )}
      </SizeObserver>
    );
  }

  if (
    rule.annotations.alertType === AlertType.CHANGE ||
    rule.annotations.alertType === AlertType.OUTLIERS
  ) {
    return (
      <AlertsChartPromql
        changeCondition={alertParsedMetadata.changeCondition}
        condition={condition}
        chartLayoutType={chartLayoutType}
        date={date}
        isChartCompact={isChartCompact}
        outlierCondition={alertParsedMetadata.outlierCondition}
        promql={alertParsedMetadata.promqls[0]}
        unit="number"
      />
    );
  }

  return (
    <AlertsChart
      conditionOperator={condition.of}
      conditionValue={Number(condition.value)}
      chartLayoutType={chartLayoutType}
      date={date}
      isChartCompact={isChartCompact}
      isLoading={dataRequest.isLoading || getDatasourcesRequest.isLoading}
      queryData={dataRequest.result}
      unit={
        rule.annotations.ruleType === RuleType.APM
          ? apmChartUnit[extraData?.apmTriggerType]
          : 'number'
      }
    />
  );
};

export default AlertsDetailsChart;
