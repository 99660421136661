import { CheckboxWithLabel } from 'components';
import React, { ReactElement } from 'react';
import { useViewport } from 'reactflow';
import TracesServiceMap from 'screens/Traces/TracesServiceMap';
import { DateSelection, Service as ServiceType } from 'types';

import useAlertsServiceCorrelationState from './useAlertsServiceCorrelationState';
import { ActiveCorrelationProps } from './types';

const Y_OFFSET = -40;
const X_OFFSET = 80;

const AlertsServiceCorrelationMapToolbar = ({
  activeCorrelation,
  attributes,
  onCorrelationKeyChange,
  position,
}: {
  activeCorrelation: ActiveCorrelationProps[string];
  attributes: Record<string, string>;
  onCorrelationKeyChange: (key: string, value: boolean) => void;
  position: { x: number; y: number };
}) => {
  const { x, y, zoom } = useViewport();

  const positionX = position.x * zoom + x + X_OFFSET * zoom;
  const positionY = position.y * zoom + y + Y_OFFSET * zoom;
  const isDatabase = attributes.service_name?.includes('://');
  return (
    <div
      className="absolute rounded-sm bg-secondary px-3 py-2"
      style={{
        transform: `translate3d(${positionX}px, ${positionY}px, 0)`,
        top: 0,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="pb-1 text-xs">Enable Correlation</div>
      <div>
        <CheckboxWithLabel
          value={activeCorrelation.redMetric}
          label="RED Metrics"
          onChange={(value) => onCorrelationKeyChange('redMetric', value)}
          dataTestId="alerts-service-correlation-map-tooltip-show-on-map"
          className="mb-1 text-xs"
        />
      </div>
      <div>
        <CheckboxWithLabel
          value={activeCorrelation.useMetric}
          label="USE Metrics"
          onChange={(value) => onCorrelationKeyChange('useMetric', value)}
          dataTestId="alerts-service-correlation-map-tooltip-show-on-map"
          className="mb-1 text-xs"
        />
      </div>
      {isDatabase && (
        <div>
          <CheckboxWithLabel
            value={activeCorrelation.integration}
            label="Integration"
            onChange={(value) => onCorrelationKeyChange('integration', value)}
            dataTestId="alerts-service-correlation-map-tooltip-show-on-map"
            className="text-xs"
          />
        </div>
      )}
    </div>
  );
};

const emptyObject = {};

const AlertsServiceCorrelationsMap = ({
  date,
  serviceHash,
  serviceByHash,
  alertsServiceCorrelationState,
}: {
  date: DateSelection;
  serviceHash: string;
  serviceByHash: Record<string, ServiceType>;
  alertsServiceCorrelationState: ReturnType<
    typeof useAlertsServiceCorrelationState
  >;
}): ReactElement => {
  const { activeCorrelation, colorsByServiceName, onCorrelationKeyChange } =
    alertsServiceCorrelationState;

  return (
    <div className="alerts__correlation__service-map">
      <TracesServiceMap
        colorsByServiceName={colorsByServiceName}
        date={date}
        selectedFacetValuesByName={emptyObject}
        serviceByHash={serviceByHash}
        searchedNode={serviceHash}
        hideSearchNode
        renderNodeTooltip={(id, node, isSelected) => {
          if (isSelected) return null;
          return <div>Click to see correlation options</div>;
        }}
        renderEdgeTooltip={() => <></>}
        renderSelectedNodeToolbar={(node) => (
          <AlertsServiceCorrelationMapToolbar
            position={node.position}
            activeCorrelation={
              activeCorrelation[node.id] || {
                redMetric: false,
                useMetric: false,
                integration: false,
              }
            }
            onCorrelationKeyChange={(propertyKey, value) =>
              onCorrelationKeyChange({
                propertyKey,
                value,
                serviceHash: node.id,
              })
            }
            attributes={node.data?.attributes || {}}
          />
        )}
      />
    </div>
  );
};

export default AlertsServiceCorrelationsMap;
