import { policyScopeFilterOperator } from 'utils/query-operator';

export const getPolicyScopeFilterOperatorOptions = () => {
  return Object.keys(policyScopeFilterOperator).map((key) => ({
    label: key,
    value: key,
  }));
};

export const policyTypeOptions = [
  { label: 'Custom', value: 'custom' },
  { label: 'All', value: 'all' },
  { label: 'None', value: 'none' },
];
