import {
  FlyoutCaret,
  TooltipTrigger,
  FacetAccordion,
  useModalsContext,
  useToaster,
  ConfirmationModal,
} from 'components';
import { useRequest, useToggle } from 'hooks';
import React, { ReactElement, ReactNode, useState } from 'react';
import { removeLogsFavoriteFacetGroup } from 'requests';
import {
  FacetBase,
  FacetName,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import { Trash2 } from 'react-feather';

import { useLogsState, useQueryScheduler } from './hooks';
import LogsFacetGroupExpanded from './LogsFacetGroupExpanded';
import classNames from 'classnames';

const LogsFacetGroupTrigger = ({
  component,
  isDeleteable,
  onDeleteGroup,
}: {
  component: string;
  isDeleteable?: boolean;
  onDeleteGroup?: () => void;
}) => {
  return (
    <div className="mr-1 flex w-full justify-between">
      <div>{component}</div>
      {isDeleteable ? (
        <div className="hidden group-hover:flex">
          <div
            className={classNames({
              'h-[24px] w-[24px] flex items-center justify-center rounded-sm text-icon hover:bg-interaction-primary hover:text-white':
                true,
            })}
          >
            <TooltipTrigger
              asChild={true}
              variant="compact"
              tooltip={'Delete Group'}
              onClick={(e) => {
                e.stopPropagation();
                onDeleteGroup?.();
              }}
              className="flex size-full items-center justify-center"
            >
              <Trash2 size={14} />
            </TooltipTrigger>
          </div>
        </div>
      ) : null}
    </div>
  );
};

type Props = {
  component: string;
  handlersByName: (facet: FacetBase) => {
    clearFacet: VoidFunction;
    changeFacetRange: VoidFunction;
    excludeFacetValue: (value: string) => void;
    selectOnlyFacetValue: (value: string) => void;
    toggleFacetValue: (value: string, values: any[]) => void;
    toggleKeyExists?: () => void;
  };
  getActions: (facet: FacetBase) => FacetPickerAction[];
  isDeleteable?: boolean;
  hardcodedFacets?: FacetName[];
  keepOpen?: boolean;
  logsState: ReturnType<typeof useLogsState>;
  queryScheduler: ReturnType<typeof useQueryScheduler>;
  renderNameHandler: (facet: FacetBase) => () => ReactNode;
  renderPlaceholderText: (facetKey: string) => string;
  requestByFacet: (
    facet: FacetBase,
  ) => Promise<{ count: number; value: string }[]>;
  selectedFacetRanges: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  refreshFacetGroups: string;
  logsFavouriteFacetGroupsRequest: ReturnType<typeof useRequest>;
  setRefreshFacetGroups: (component: string) => void;
};

const LogsFacetGroup = ({
  component,
  handlersByName,
  hardcodedFacets,
  isDeleteable,
  getActions,
  keepOpen,
  logsState,
  queryScheduler,
  renderNameHandler,
  renderPlaceholderText,
  requestByFacet,
  refreshFacetGroups,
  setRefreshFacetGroups,
  selectedFacetRanges,
  selectedFacetValuesByName,
  logsFavouriteFacetGroupsRequest,
}: Props): ReactElement => {
  const modal = useModalsContext();
  const { addToast } = useToaster();
  const expandedToggle = useToggle();
  const toogleHideAutogeneratedFacets = useToggle(true);
  // const expanded = expandedToggle.value;
  const hideAutogeneratedFacets = toogleHideAutogeneratedFacets.value;
  const [expanded, setExpanded] = useState(false);
  const removeLogsFavoriteFacetGroupRequest = useRequest(
    removeLogsFavoriteFacetGroup,
  );

  const onDeleteGroup = () => {
    modal.push(
      <ConfirmationModal
        className="logs__analytics__saved-metrics__delete-modal"
        description={`Are you sure you want to delete group ${component}?`}
        onCancel={() => modal.pop()}
        onConfirm={() => {
          removeLogsFavoriteFacetGroupRequest
            .call({ facetGroup: component })
            .then(() => {
              logsFavouriteFacetGroupsRequest.call({});
              addToast({
                text: `Group ${component} deleted`,
                status: 'success',
              });
              modal.pop();
            });
        }}
        title="Delete Group"
      />,
    );
  };

  return (
    <FacetAccordion
      renderContent={() => {
        return expanded || keepOpen ? (
          <LogsFacetGroupExpanded
            component={component}
            getActions={getActions}
            handlersByName={handlersByName}
            hardcodedFacets={hardcodedFacets}
            hideAutogeneratedFacets={hideAutogeneratedFacets}
            logsState={logsState}
            refreshFacetGroups={refreshFacetGroups}
            queryScheduler={queryScheduler}
            renderNameHandler={renderNameHandler}
            renderPlaceholderText={renderPlaceholderText}
            requestByFacet={requestByFacet}
            setRefreshFacetGroups={setRefreshFacetGroups}
            selectedFacetRanges={selectedFacetRanges}
            selectedFacetValuesByName={selectedFacetValuesByName}
          />
        ) : null;
      }}
      renderTrigger={() => (
        <LogsFacetGroupTrigger
          component={component}
          isDeleteable={isDeleteable}
          onDeleteGroup={onDeleteGroup}
        />
      )}
      expanded={expanded}
      setExpanded={(updatedExpanded) => {
        if (updatedExpanded) {
          expandedToggle.toggle(); // no need?
        }
        setExpanded(updatedExpanded);
      }}
      isVisuallyHidden={false}
      triggerClassName="leading-[28px] pl-2 mb-1 w-full active:bg-interaction-nested-contrast hover:bg-interaction-nested data-[state=open]:bg-interaction-nested border-t-0 border-transparent cursor-pointer group"
    />
  );

  return (
    <>
      <div className="facet-group">
        {!keepOpen ? (
          <button
            className="facet-group__button"
            onClick={expandedToggle.toggle}
          >
            <FlyoutCaret
              className="facet-group__button__flyout-caret"
              isOpen={expanded}
            />
            <div className="facet-group__button__text">{component}</div>
            {!hardcodedFacets && (
              <span
                className="logs__facet-group__button__hide-autogenerated"
                onClick={(e) => {
                  e.stopPropagation();
                  toogleHideAutogeneratedFacets.toggle();
                }}
              >
                <TooltipTrigger
                  tooltip={
                    hideAutogeneratedFacets
                      ? 'Show autogenerated'
                      : 'Hide autogenerated'
                  }
                >
                  {hideAutogeneratedFacets ? (
                    <BsFillEyeFill />
                  ) : (
                    <BsFillEyeSlashFill />
                  )}
                </TooltipTrigger>
              </span>
            )}
          </button>
        ) : null}
      </div>
      {expanded || keepOpen ? (
        <LogsFacetGroupExpanded
          component={component}
          getActions={getActions}
          handlersByName={handlersByName}
          hardcodedFacets={hardcodedFacets}
          hideAutogeneratedFacets={hideAutogeneratedFacets}
          logsState={logsState}
          queryScheduler={queryScheduler}
          renderNameHandler={renderNameHandler}
          renderPlaceholderText={renderPlaceholderText}
          requestByFacet={requestByFacet}
          selectedFacetRanges={selectedFacetRanges}
          selectedFacetValuesByName={selectedFacetValuesByName}
        />
      ) : null}
    </>
  );
};

export default LogsFacetGroup;
