import { useRequest } from 'hooks';
import React, { useRef } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { ChartGridItem, TimeSeries } from 'types';
import { RumErrorGroup, RumEventType } from './types';
import useRumState from './hooks/useRumState';
import RumErrorsGroupTableChart from './RumErrorsGroupTableChart';
import aggregateRumTimeSeries from './requests/aggregateRumTimeSeries';
import { formatAggregateTimeSeriesData, getRollupByVisualization } from 'utils';

const RumErrorGroupChartSensor = ({
  row,
  rumState,
}: {
  row: RumErrorGroup;
  rumState: ReturnType<typeof useRumState>;
}) => {
  const aggregateRumErrorsRequest = useRequest(
    (args) =>
      aggregateRumTimeSeries(args).then((data: TimeSeries[]) => {
        const points = data?.[0]?.points || [];
        const chartGridItem = formatAggregateTimeSeriesData({
          date: args.date,
          points: points.map((point) => ({
            Value: point.value,
            BucketStart: Math.floor(point.ts / 1000),
            GroupVal: {},
          })),
          step: args.rollUpSeconds,
          fillMissingTimestamps: true,
        });

        return {
          keys: [args.errorGroupingKey],
          data: chartGridItem.data,
        };
      }),
    true,
    true,
  );

  const fetchedRef = useRef(false);
  const { fingerprint } = row;

  const onChange = (isVisible: boolean) => {
    if (isVisible && !fetchedRef.current) {
      fetchedRef.current = true;
      const {
        dateState,
        idSearch,
        selectedFacetRangeByNameState,
        selectedFacetValuesByNameState,
      } = rumState;
      aggregateRumErrorsRequest.call({
        aggregation: 'count',
        aggregationField: '*',
        date: dateState[0],
        eventType: RumEventType.ERROR,
        errorGroupingKey: fingerprint,
        groupBys: ['error.fingerprint'],
        idSearch,
        rollUpSeconds: getRollupByVisualization(dateState[0], 'bar'),
        selectedFacetRangeByName: selectedFacetRangeByNameState.state,
        selectedFacetValuesByName: selectedFacetValuesByNameState.state,
      });
    }
  };

  return (
    <VisibilitySensor onChange={onChange}>
      <RumErrorsGroupTableChart
        chartGridItem={
          (aggregateRumErrorsRequest.result || {}) as ChartGridItem
        }
        isLoading={aggregateRumErrorsRequest.isLoading}
      />
    </VisibilitySensor>
  );
};

export default RumErrorGroupChartSensor;
