import { DateSelection } from 'types/DateSelection';

export const kubeOpenLogsInNewTab = (
  date: DateSelection,
  selectedFacetValues: { [key: string]: any },
  logEventIndex: number,
) => {
  const { startTimeUnix, endTimeUnix } = date;
  const dateURI = encodeURIComponent(
    JSON.stringify({ startTimeUnix, endTimeUnix }),
  );
  const facetURI = encodeURIComponent(JSON.stringify(selectedFacetValues));
  const url = `#/logs?date=${dateURI}&selectedFacetValues=${facetURI}&logEventIndex=${logEventIndex}`;
  window.open(url, '_blank');
};

export const kubeOpenAPMInNewTab = (
  date: DateSelection,
  selectedFacetValues: { [key: string]: any },
  traceId: string,
) => {
  const { startTimeUnix, endTimeUnix } = date;
  const dateURI = encodeURIComponent(
    JSON.stringify({ startTimeUnix, endTimeUnix }),
  );
  const facetURI = encodeURIComponent(JSON.stringify(selectedFacetValues));
  const url = `#/apm/traces/list?date=${dateURI}&selectedFacetValuesByName=${facetURI}&traceId=${traceId}`;
  window.open(url, '_blank');
};

export const kubeOpenEventInNewTab = (
  date: DateSelection,
  filter: { [key: string]: any },
  eventId: string,
) => {
  const { startTimeUnix, endTimeUnix } = date;
  const dateURI = encodeURIComponent(
    JSON.stringify({ startTimeUnix, endTimeUnix }),
  );
  const filterURI = encodeURIComponent(JSON.stringify(filter));
  const activeEvent = encodeURIComponent(JSON.stringify({ id: eventId }));
  const url = `#/events/list?date=${dateURI}&filter=${filterURI}&activeEvent=${activeEvent}`;
  window.open(url, '_blank');
};
