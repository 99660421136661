import {
  Loader,
  PopoverPosition,
  PopoverTriggerV2,
  TooltipTrigger,
} from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Plus } from 'react-feather';
import { FiTarget } from 'react-icons/fi';
import { getSloStatusByService } from 'requests';
import useSLOQueryTemplates from 'screens/SLO/hooks/useSLOQueryTemplates';
import { SLOProps } from 'types/SLO';
import { ctaColor } from 'utils';

import { SLODetails } from '../SLO/SLODetails';

const CreateNewSLOPopover = ({
  createAvailabilitySLO,
  createLatencySLO,
  close,
}: {
  createAvailabilitySLO: () => void;
  createLatencySLO: () => void;
  close: () => void;
}) => {
  return (
    <div>
      <div
        className="service__slo-info__create__button"
        onClick={() => {
          createAvailabilitySLO();
          close();
        }}
      >
        Add New Availability SLO
      </div>
      <div
        className="service__slo-info__create__button"
        onClick={() => {
          createLatencySLO();
          close();
        }}
      >
        Add New Latency SLO
      </div>
    </div>
  );
};

const ServiceSLOList = ({
  onClick,
  serviceHash,
  sloStatus,
}: {
  onClick: (val: SLOProps) => void;
  serviceHash: string;
  sloStatus: { ok: SLOProps[]; breached: SLOProps[] };
}) => {
  const { ok, breached } = sloStatus || { ok: [], breached: [] };
  if (ok.length === 0 && breached.length === 0) {
    return null;
  }

  const sloList = [...ok, ...breached];

  return (
    <div className="service__header__left__slo-list">
      {sloList.map((slo) => {
        return (
          <div
            className="service__header__left__slo-list__item"
            key={slo.name}
            onClick={() => onClick(slo)}
          >
            <div
              className="service__header__left__slo-list__item__status-sign"
              style={{
                backgroundColor: slo.statusErrorBudget.statusColor,
              }}
            ></div>
            <div>
              <div className="service__header__left__slo-list__item__name">
                {slo.name}
              </div>
              <div className="service__header__left__slo-list__item__status">
                Status:&nbsp;
                <span style={{ color: slo.statusErrorBudget.statusColor }}>
                  <b>{slo.statusErrorBudget.status} </b>
                </span>
              </div>
              <div className="service__header__left__slo-list__item__status__error-budget">
                Error Budget Remaining:{' '}
                <span style={{ color: slo.statusErrorBudget.errorBudgetColor }}>
                  <b>{slo.statusErrorBudget.errorBudget}</b>
                </span>
              </div>
            </div>
          </div>
        );
      })}
      <div
        className="service__header__left__slo-list__button"
        onClick={() => {
          const filterURI = encodeURI(
            `selectedFacetValuesByName={"servicehash":{"${serviceHash}":1}}`,
          );
          window.open(`#/slo?${filterURI}`, '_blank');
        }}
      >
        View all SLOs&nbsp;
      </div>
    </div>
  );
};

const ServiceSLOInfo = ({
  kfSource,
  serviceName,
  serviceHash,
}: {
  isServiceUnique: boolean;
  kfSource: string;
  serviceName: string;
  serviceHash: string;
}): ReactElement => {
  const [activeSLO, setActiveSLO] = useState<SLOProps>(null);
  const sloStatusRequest = useRequest(getSloStatusByService);
  const { queryTemplates } = useSLOQueryTemplates();

  const createAvailabilitySLO = () => {
    const sloTypeURI = 'availability';
    window.open(
      `#/slo/create?sloType=${sloTypeURI}&kf_source=${kfSource}&serviceHash=${serviceHash}&serviceName=${serviceName}`,
      '_blank',
    );
  };

  const createLatencySLO = () => {
    const sloTypeURI = 'latency';
    window.open(
      `#/slo/create?sloType=${sloTypeURI}&kf_source=${kfSource}&serviceHash=${serviceHash}&serviceName=${serviceName}`,
      '_blank',
    );
  };

  useEffect(() => {
    if (queryTemplates.queryTemplatesFetched) {
      sloStatusRequest.call({ serviceHash, queryTemplates });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceHash, queryTemplates]);

  const sloStatusColor = useMemo(() => {
    if (!sloStatusRequest.result) {
      return '';
    }
    if (sloStatusRequest.result.breached.length > 0) {
      return ctaColor.red;
    }

    if (
      sloStatusRequest.result.ok.length === 0 &&
      sloStatusRequest.result.breached.length === 0
    ) {
      return '';
    }

    return ctaColor.green;
  }, [sloStatusRequest.result]);

  return (
    <Loader
      className="service__header__left__advance-functions--loader"
      isLoading={sloStatusRequest.isLoading}
    >
      <div className="service__header__left__slo-info" data-testid="slo">
        <div
          className="service__header__left__slo-info__title-icon"
          style={{ color: sloStatusColor }}
        >
          <FiTarget />
        </div>
        <PopoverTriggerV2
          className="service__slo-info__create__popover"
          disable={
            sloStatusRequest.result &&
            sloStatusRequest.result.ok.length === 0 &&
            sloStatusRequest.result.breached.length === 0
          }
          popover={({ close }) => (
            <ServiceSLOList
              onClick={(val) => {
                setActiveSLO(val);
                close();
              }}
              serviceHash={serviceHash}
              sloStatus={sloStatusRequest.result}
            />
          )}
          offsetX={-26}
          position={PopoverPosition.BOTTOM_LEFT}
        >
          <div className="service__header__left__slo-info__title">
            <div>
              {sloStatusRequest.result &&
              sloStatusRequest.result.ok.length === 0 &&
              sloStatusRequest.result.breached.length === 0
                ? 'No SLO'
                : 'SLO'}
            </div>
            <div className="service__header__left__slo-info__main">
              {sloStatusRequest.result &&
                sloStatusRequest.result.ok.length !== 0 && (
                  <div className="service__header__left__slo-info__title__ok">
                    {sloStatusRequest.result.ok.length} OK
                  </div>
                )}
              {sloStatusRequest.result &&
                sloStatusRequest.result.breached.length !== 0 && (
                  <div className="service__header__left__slo-info__title__breached">
                    {sloStatusRequest.result.breached.length} BREACHED
                  </div>
                )}
            </div>
          </div>
        </PopoverTriggerV2>
        <div className="service__header__left__slo-info__create">
          <PopoverTriggerV2
            className="service__slo-info__create__popover"
            popover={({ close }) => {
              return (
                <CreateNewSLOPopover
                  createAvailabilitySLO={createAvailabilitySLO}
                  createLatencySLO={createLatencySLO}
                  close={close}
                />
              );
            }}
            position={PopoverPosition.BOTTOM_LEFT}
            offsetX={28}
          >
            <TooltipTrigger tooltip={'Create new SLO'}>
              <Plus className="service__header__left__slo-info__create__icon" />
            </TooltipTrigger>
          </PopoverTriggerV2>
        </div>
        {activeSLO && (
          <SLODetails
            close={() => setActiveSLO(null)}
            sloData={activeSLO}
            title={activeSLO.name}
            queryTemplates={queryTemplates}
          />
        )}
      </div>
    </Loader>
  );
};

export default ServiceSLOInfo;
