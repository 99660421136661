import { AutocompleteOption } from 'components';
import { useGlobalFilters } from 'context';
import dayjs from 'dayjs';

import { useRequest, useUrlState } from 'hooks';
import { useMemo } from 'react';
import { eventFacetValues, eventLabelNames, eventLabelValues } from 'requests';
import { convertTimeStringToUnix, groupLabels } from 'utils';

import useDebouncedEffect from 'use-debounced-effect';
import useEventsLocalStorageStore from './useEventsLocalStorageStore';
import useEventsAnalytics from './useEventsAnalytics';
import useEventsSidebarData from './useEventsSidebarData';

const getLast12HoursDate = () => {
  const endTimeUnix = dayjs().unix();
  const startTimeUnix = dayjs().subtract(12, 'hour').unix();

  return {
    startLabel: 'now-12h',
    endLabel: 'now',
    endTimeUnix,
    startTimeUnix,
  };
};

const useEventsState = ({
  urlKeysToSync = [],
}: {
  urlKeysToSync?: string[];
}) => {
  const getLabelNamesRequest = useRequest(eventLabelNames, true, true);

  const {
    globalFilters: {
      events: { date, setDate, filterState },
    },
  } = useGlobalFilters({ urlKeysToSync });
  const eventsLocalStorageStore = useEventsLocalStorageStore();
  const eventsSidebarData = useEventsSidebarData();

  const { analyticsQuery, setAnalyticsQuery } = useEventsAnalytics();

  const [activeEventInfo, setActiveEventInfo] = useUrlState('activeEvent', {
    id: '',
    timeStamp: '',
  });

  const [combineAllQueries, setCombineAllQueries] = useUrlState(
    'combineAllQueries',
    false,
  );

  const { filter } = filterState;

  const { additionalLabels, cloudLabels, kubernetesLabels } = useMemo(
    () => groupLabels(getLabelNamesRequest.result || []),
    [getLabelNamesRequest.result],
  );

  const newCloudLabels = useMemo(
    () => [
      ...cloudLabels,
      { component: 'Cloud', name: 'kube_cluster_name', type: 'string' },
    ],
    [cloudLabels],
  );

  const getEventLabelValuesOptions = (
    option: AutocompleteOption,
  ): Promise<AutocompleteOption[]> => {
    return new Promise((resolve) => {
      eventLabelValues({
        date,
        labelName: option.value,
        selectedFacetValuesByName: {},
      })
        .then((labelValues) => {
          const options: AutocompleteOption[] = labelValues.map(
            ({ count, value }) => ({
              label: value,
              value: value,
              count,
              optionType: 'value',
            }),
          );
          resolve(options);
        })
        .catch(() => {
          resolve([]);
        });
    });
  };

  const getEventFacetValuesOptions = (
    option: AutocompleteOption,
  ): Promise<AutocompleteOption[]> => {
    return new Promise((resolve) => {
      eventFacetValues({
        date,
        facetName: option.value,
        selectedFacetValuesByName: {},
      })
        .then((facetValues) => {
          const options: AutocompleteOption[] = facetValues.map(
            ({ count, value }) => ({
              label: value,
              value: value,
              count,
              optionType: 'value',
            }),
          );
          resolve(options);
        })
        .catch(() => {
          resolve([]);
        });
    });
  };

  const refreshDate = (): void => {
    if (date.startLabel && date.endLabel) {
      const endTimeUnix = convertTimeStringToUnix(date.endLabel);
      const startTimeUnix = convertTimeStringToUnix(date.startLabel);
      if (endTimeUnix && startTimeUnix) {
        setDate({ ...date, endTimeUnix, startTimeUnix });
      }
    } else {
      setDate({ ...date, endTimeUnix: dayjs().unix() });
    }
  };

  const requestByLabelName = (labelName: string) => () =>
    eventLabelValues({
      date,
      labelName,
      filterByFacets: filter['filterByFacets'],
      searchTerms: filter['searchTerms'],
      selectedFacetValuesByName: filter['sidebarFilters'],
    });
  const requestByFacetName = (facetName: string) => () =>
    eventFacetValues({
      date,
      facetName,
      filterByFacets: filter['filterByFacets'],
      searchTerms: filter['searchTerms'],
      selectedFacetValuesByName: filter['sidebarFilters'],
    });

  const requestAndStoreByFacetName = (facetName: string) => () => {
    const request = requestByFacetName(facetName);
    eventsSidebarData.fetchSidebarValues({
      request,
      name: facetName,
    });
  };

  const requestAndStoreByLabelName = (labelName: string) => () => {
    const request = requestByLabelName(labelName);
    eventsSidebarData.fetchSidebarValues({
      request,
      name: labelName,
    });
  };

  useDebouncedEffect(
    () => {
      getLabelNamesRequest.call({ date });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      timeout: 100,
      ignoreInitialCall: false,
    },
    [date],
  );

  return {
    activeEventInfo,
    additionalLabels,
    analyticsQuery,
    combineAllQueries,
    cloudLabels: newCloudLabels,
    date,
    getEventLabelValuesOptions,
    getEventFacetValuesOptions,
    filterByFacets: filter.filterByFacets,
    filterState,
    kubernetesLabels,
    refreshDate,
    requestByFacetName,
    requestAndStoreByFacetName,
    requestAndStoreByLabelName,
    requestByLabelName,
    sidebarDataState: eventsSidebarData,
    setActiveEventInfo,
    setCombineAllQueries,
    setDate,
    setAnalyticsQuery,
    error: getLabelNamesRequest?.error,
    ...eventsLocalStorageStore,
  };
};

export default useEventsState;
