import { isNil } from 'lodash';
import { TableTransformerData } from '../types';
import { findUnitCategoryFormatById } from 'utils/valueFormats';

const getEvaluatedValue = (
  value: number,
  unit: string,
  decimals: number,
): string => {
  const unitFormat = findUnitCategoryFormatById(unit);

  if (unitFormat) {
    const formatted = unitFormat.fn(Number(value), decimals);

    if (!formatted) return value.toString();
    return `${formatted.prefix || ''}${formatted.text}${
      formatted.suffix || ''
    }`;
  }
};

const topListTransformer = ({
  data,
  options,
}: {
  data: TableTransformerData;
  options: { unit: string; decimals: number; direction: 'top' | 'bottom' };
}): {
  data: { label: string; count: number; evaluatedValue: string }[];
} => {
  const topListData: {
    label: string;
    count: number;
    evaluatedValue: string;
  }[] = [];

  data.data.map((item) => {
    const itemKeys = Object.keys(item);
    const labelWithoutValue = itemKeys.filter(
      (key) => !key.startsWith('Value #'),
    );
    const valueKey = itemKeys.find((key) => key.startsWith('Value #'));
    const label = labelWithoutValue.map((key) => item[key]).join(', ');
    const count = item[valueKey];
    if (isNil(count)) return;

    const countNumber = Number(count);
    topListData.push({
      label,
      count: countNumber,
      evaluatedValue: options?.unit
        ? getEvaluatedValue(countNumber, options.unit, options.decimals)
        : undefined,
    });
  });

  const sortedData = topListData.sort((a, b) => {
    if (options?.direction === 'bottom') {
      return a.count - b.count;
    }
    return b.count - a.count;
  });

  return { data: sortedData };
};

export default topListTransformer;
