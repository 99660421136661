import { RangeFacetsMap } from './isRangeFacet';

const isDurationFacet = (facet: string) => {
  if (!facet) {
    return false;
  }
  return (
    facet in RangeFacetsMap &&
    RangeFacetsMap[facet as keyof typeof RangeFacetsMap] === 'DURATION'
  );
};

export default isDurationFacet;
