import { useToggle } from 'hooks';
import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { SessionMetadata } from './types';
import useSegmentFetcher from './useSegmentFetcher';

const RumReplaySegmentFetcherContext =
  createContext<ReturnType<typeof useSegmentFetcher>>(null);

type Props = {
  children: ReactNode;
  isInitedToggle: ReturnType<typeof useToggle>;
  session: SessionMetadata;
  startReplayAtUnixMs: number;
};

export const RumReplaySegmentFetcherContextProvider = ({
  children,
  isInitedToggle,
  session,
  startReplayAtUnixMs,
}: Props) => {
  const segmentFetcher = useSegmentFetcher({ session });
  const { init } = segmentFetcher;

  useEffect(() => {
    init(startReplayAtUnixMs).then(() => {
      setTimeout(isInitedToggle.on, 1000);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RumReplaySegmentFetcherContext.Provider value={segmentFetcher}>
      {children}
    </RumReplaySegmentFetcherContext.Provider>
  );
};

export const useRumReplaySegmentFetchercontext = () =>
  useContext(RumReplaySegmentFetcherContext);
