import { useDateState } from 'hooks';
import { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { extractPathNameFromURL, parseUrlParamByKey } from 'utils';

const useMetricsSummaryPageState = ({
  metricDateState,
}: {
  metricDateState: ReturnType<typeof useDateState>;
}) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const selectedMetricState = useState<string>(
    parseUrlParamByKey('metric') || '',
  );
  const selectedMetricLabelState = useState<string>(
    parseUrlParamByKey('metricLabel') || '',
  );

  // Get dependencies for determining when a url change should happen
  const dependenciesForWriteStateToUrl = useMemo(
    () => [metricDateState[0]],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [metricDateState[0]],
  );

  const writeStateToUrl = () => {
    params.set('metricDate', JSON.stringify(metricDateState[0]));
    params.set('metric', JSON.stringify(selectedMetricState[0]));
    params.set('metricLabel', JSON.stringify(selectedMetricLabelState[0]));
    const navigateUrl = `${extractPathNameFromURL()}?${params.toString()}`;
    navigate(navigateUrl, { replace: true });
  };

  return {
    dateState: metricDateState,
    dependenciesForWriteStateToUrl,
    selectedMetricState,
    selectedMetricLabelState,
    writeStateToUrl,
  };
};

export default useMetricsSummaryPageState;
