import { Flamegraph, Resizer, ResizerOrientation } from 'components';
import { useForm, useLocalStorageState } from 'hooks';
import { flamegraphPalette } from 'kfuse-constants';
import React, { useMemo, useState } from 'react';
import { Span, TraceMetrics } from 'types';
import { Attribute } from './types';
import TraceSidebarExecTime from './TraceSidebarExecTime';
import TraceSidebarMainWaterfall from './TraceSidebarMainWaterfall';
import { getAttribute } from './utils';
import { resolveColorFromMap } from 'utils';

type GetColorMapByAttribute = {
  attribute: Attribute;
  colorsByServiceName: { [key: string]: string };
  spans: Span[];
};

const getColorMapByAttribute = ({
  attribute,
  colorsByServiceName,
  spans,
}: GetColorMapByAttribute): { [key: string]: string } => {
  if (attribute === Attribute.serviceName) {
    return colorsByServiceName;
  }

  const attributeBitMap = spans.reduce((obj, span) => ({
    ...obj,
    [getAttribute(attribute, span)]: 1,
  }));

  return Object.keys(attributeBitMap)
    .sort()
    .reduce(
      (obj, hostname, i) => ({
        ...obj,
        [hostname]: flamegraphPalette[i % flamegraphPalette.length].string(),
      }),
      {},
    );
};

type Props = {
  clickedSpanId?: string;
  colorsByServiceName: { [key: string]: string };
  hoveredSpanId?: string;
  setClickedSpanId: (spanId: string) => void;
  setHoveredSpanId: (spanId: string) => void;
  shouldShowWaterfall: boolean;
  spans: Span[];
  traceMetrics: TraceMetrics;
};

const TraceSidebarMainContent = ({
  clickedSpanId,
  colorsByServiceName,
  hoveredSpanId,
  setClickedSpanId,
  setHoveredSpanId,
  shouldShowWaterfall,
  spans,
  traceMetrics,
}: Props) => {
  const [width, setWidth] = useLocalStorageState(
    'traceSidebarMainContentWidth',
    360,
  );
  const onResize = ({ deltaX }) => {
    setWidth((prevWidth) => Math.max(prevWidth - deltaX, 240));
  };

  const form = useForm({
    attribute: Attribute.serviceName,
    type: 'execTime',
  });

  const colorMap = useMemo(
    () =>
      getColorMapByAttribute({
        attribute: form.values.attribute as Attribute,
        colorsByServiceName,
        spans,
      }),
    [colorsByServiceName, form.values, spans],
  );
  return (
    <div className="trace-sidebar__main">
      <div className="trace-sidebar__main__left">
        {shouldShowWaterfall ? (
          <TraceSidebarMainWaterfall
            clickedSpanId={clickedSpanId}
            getColor={(span: Span) => {
              if (form.values.attribute === Attribute.serviceName) {
                return resolveColorFromMap(
                  colorsByServiceName,
                  span.service?.name,
                );
              }

              return resolveColorFromMap(
                colorMap,
                getAttribute(form.values.attribute as Attribute, span),
              );
            }}
            setClickedSpanId={setClickedSpanId}
            spans={spans}
          />
        ) : (
          <Flamegraph
            clickedSpanId={clickedSpanId}
            getColor={(span: Span) =>
              resolveColorFromMap(
                colorMap,
                getAttribute(form.values.attribute as Attribute, span),
              )
            }
            hoveredSpanId={hoveredSpanId}
            setClickedSpanId={setClickedSpanId}
            setHoveredSpanId={setHoveredSpanId}
            spans={spans}
          />
        )}
        <Resizer
          onMouseMove={onResize}
          orientation={ResizerOrientation.vertical}
        />
      </div>
      <div
        className="trace-sidebar__main__right"
        style={{ width: `${width}px` }}
      >
        <TraceSidebarExecTime
          colorMap={colorMap}
          form={form}
          spans={spans}
          traceMetrics={traceMetrics}
        />
      </div>
    </div>
  );
};

export default TraceSidebarMainContent;
