import { LabelValueChip } from 'components';
import React, { ReactElement, useMemo } from 'react';
import { parseMetricLabelQuery } from 'utils';

import { useAlertsCreateAPM } from './hooks';

const AlertsCreateAPMServiceLabel = ({
  labelValue,
  labelIndex,
  alertsCreateAPMState,
}: {
  alertsCreateAPMState: ReturnType<typeof useAlertsCreateAPM>;
  labelIndex: number;
  labelValue: string;
}): ReactElement => {
  const {
    apmAlertDetails,
    date,
    getApmMetricMatcher,
    labelValuePicker,
    setApmAlertDetails,
  } = alertsCreateAPMState;
  const {
    labelListMap,
    labelValueListMap,
    loadLabelList,
    loadLabelValueList,
    setLabelListMap,
    setLabelValueListMap,
  } = labelValuePicker;
  const parsed = parseMetricLabelQuery(labelValue);
  const { additionalLabels, serviceName } = apmAlertDetails;

  const transformedMatcher = useMemo(
    () =>
      getApmMetricMatcher({
        labels: additionalLabels,
        labelIndex,
        newUniqueLabels: apmAlertDetails.uniqueLabels,
        newService: serviceName,
      }),
    [
      additionalLabels,
      apmAlertDetails.uniqueLabels,
      getApmMetricMatcher,
      labelIndex,
      serviceName,
    ],
  );

  const updateApmDetailsLabels = (
    propertyKey: string,
    val: string | string[],
  ) => {
    setApmAlertDetails((prev) => {
      const newApmAlertDetails = { ...prev };
      newApmAlertDetails[propertyKey] = val;
      return newApmAlertDetails;
    });
  };

  return (
    <>
      <div className="button-group">
        <div className="button-group__item button-group__item--unpadded">
          <LabelValueChip
            isLabelLoading={labelListMap[transformedMatcher]?.isLoading}
            isValueLoading={labelValueListMap[parsed.label]?.isLoading}
            label={parsed?.label}
            labelOptions={labelListMap[transformedMatcher]?.data || []}
            operator={parsed?.operator}
            onLabelClick={() => {
              loadLabelList({ date, matcher: transformedMatcher });
            }}
            onLabelChange={(val: string) => {
              const newMatchers = [...additionalLabels];
              newMatchers[labelIndex] = `${val}${parsed.operator}"${
                parsed?.value || ''
              }"`;
              updateApmDetailsLabels('additionalLabels', newMatchers);
              if (!val) return;
              loadLabelValueList({
                date,
                matcher: transformedMatcher,
                label: val,
              });
            }}
            onLabelValueChipDelete={() => {
              const newMatchers = [...additionalLabels];
              newMatchers.splice(labelIndex, 1);
              setLabelListMap((prev) => {
                const newLabelListMap = { ...prev };
                delete newLabelListMap[transformedMatcher];
                return newLabelListMap;
              });
              setLabelValueListMap((prev) => {
                const newLabelValueListMap = { ...prev };
                delete newLabelValueListMap[parsed?.label];
                return newLabelValueListMap;
              });
              updateApmDetailsLabels('additionalLabels', newMatchers);
            }}
            onOperatorChange={(val: string) => {
              const newMatchers = [...additionalLabels];
              newMatchers[labelIndex] =
                `${parsed?.label}${val}"${parsed?.value}"`;
              updateApmDetailsLabels('additionalLabels', newMatchers);
            }}
            onValueChange={(val: string) => {
              const newMatchers = [...additionalLabels];
              newMatchers[labelIndex] =
                `${parsed.label}${parsed.operator}"${val}"`;
              updateApmDetailsLabels('additionalLabels', newMatchers);
            }}
            value={parsed?.value}
            valueOptions={labelValueListMap[parsed?.label]?.data || []}
          />
        </div>
      </div>
      <div className="search__button-group__divider">
        <div />
      </div>
    </>
  );
};

export default AlertsCreateAPMServiceLabel;
