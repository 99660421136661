import {
  ErrorBoundary,
  Loader,
  RightSidebar,
  Tab,
  Tabs,
  Table,
  TableRow,
  useTabs,
} from 'components';
import { colorByKubeState } from 'kfuse-constants';
import { useRequest, useSelectedFacetValuesByNameState } from 'hooks';
import React, { useState, useEffect } from 'react';
import { kubeViewRelatedEntities } from 'requests';
import { DateSelection } from 'types';

import {
  KubernetesEvents,
  KubernetesLogs,
  KubernetesMetrics,
  KubernetesSideBarYaml,
} from './KubernetesDetailsTabs';
import KubernetesRightSideBarDetails from './KubernetesRightSideBarDetails';
import KubernetesTags from './KubernetesTags';
import { EntityTypes } from './types';
import {
  entityKeyMapping,
  entityMappedToGraphQL,
  entityMultipleKeyMapping,
  KubesDetailsTabsEntityTypes,
} from './utils';
import {
  KubernetesRulesTable,
  KubernetesSubjectsTable,
} from './ViewRelatedTables';
import { useKubernetesController } from './KubernetesController';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

const KubernetesRightSideBar = ({ activeKube }) => {
  const { flux, date, entitiesType, navigate, ...kubesState } =
    useKubernetesController();

  const location = useLocation();

  const kubeViewRelatedEntitiesRequest = useRequest(kubeViewRelatedEntities);
  const selectedFacetValuesByNameState = useSelectedFacetValuesByNameState();
  const tabs = useTabs();

  const entity = activeKube[entityMappedToGraphQL(entitiesType)];
  const [kubeViewFilterParams, setKubeViewFilterParams] = useState(null);
  const name = entity
    ? entitiesType == 'Cluster'
      ? entity.clusterName
      : entity.metadata.name
    : '';
  const viewRelatedInitialValue = entitiesType === 'Pod' ? 'Cluster' : 'Pod';
  const [viewRelatedEntity, setViewRelatedEntity] = useState(
    viewRelatedInitialValue,
  );

  useEffect(
    () => {
      if (!entity || !entity?.tags) {
        return;
      }
      const podNameTag = entity.tags.find((tag) =>
        tag.startsWith(entityKeyMapping[viewRelatedEntity]),
      );
      let keyData = '';
      let valueData = '';
      if (podNameTag) {
        keyData =
          entitiesType === 'Cluster'
            ? entityKeyMapping['Cluster']
            : entityKeyMapping[viewRelatedEntity];
        valueData = entity.tags.find((tag) => tag.startsWith(keyData));
      }

      let keyDataArray = [];
      let valueDataArray: string[] = [];

      let value = valueData ? valueData.split(':')[1] : '';

      if (entitiesType == 'Pod' && viewRelatedEntity == 'Service') {
        keyDataArray = entityMultipleKeyMapping['Pod_Service'];
        valueDataArray = keyDataArray.map((key) => {
          const tag = entity?.tags?.find((tag) => tag.startsWith(`${key}:`));
          return tag ? tag.split(':')[1] : undefined;
        });
        value = '';
      }

      if (keyData == '') {
        keyDataArray = entityMultipleKeyMapping[entitiesType];
        valueDataArray = keyDataArray.map((key) => {
          const tag = entity?.tags?.find((tag) => tag.startsWith(`${key}:`));
          return tag ? tag.split(':')[1] : undefined;
        });
      }

      const filterParams = {
        selectedFacetValuesByName: {
          ...(value
            ? {
                [keyData]: {
                  [value]: 1,
                },
              }
            : {
                ...keyDataArray.reduce(
                  (
                    acc: { [x: string]: { [x: number]: number } },
                    key: string | number,
                    index: string | number,
                  ) => {
                    if (valueDataArray[index as number]) {
                      acc[key] ||= {};
                      acc[key][
                        valueDataArray[index as number] as unknown as number
                      ] = 1;
                    }
                    return acc;
                  },
                  {},
                ),
              }),
          ...selectedFacetValuesByNameState.state,
        },
      };
      // TODO UNCOMMENT whenever needed
      // kubeViewRelatedEntitiesRequest.call({
      //   entityType: viewRelatedEntity,
      //   ...filterParams,
      // });
      setKubeViewFilterParams(filterParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewRelatedEntity, selectedFacetValuesByNameState.state],
  );

  const getTableBasedOnEnitityType = () => {
    switch (entitiesType) {
      case EntityTypes.Role:
      case EntityTypes.ClusterRole:
        return KubernetesRulesTable({ onClickCell: null });
      case EntityTypes.RoleBinding:
      case EntityTypes.ClusterRoleBinding:
        return KubernetesSubjectsTable({ entity, onClickCell: null });
    }
  };

  let jobArray: any = [];
  if (viewRelatedEntity === EntityTypes.Namespace) {
    if (entitiesType === EntityTypes.CronJob) {
      jobArray = [activeKube.cronJob];
    } else if (entitiesType === EntityTypes.Job) {
      jobArray = [activeKube.job];
    }
  }

  const isMELATabs = KubesDetailsTabsEntityTypes.includes(entitiesType);

  const close = () => {
    navigate(({ location, params, pathTemplate }) => {
      return {
        ...location,

        pathname: generatePath(pathTemplate, {
          ...params,

          activeKube: null,
          tab: null,
        }),
      };
    });
  };

  return (
    <RightSidebar
      className="kubernetes__right-sidebar"
      close={close}
      title={entitiesType + ' ' + name}
      dataTestId="kubernetes-right-sidebar"
      shouldCancelOutsideClick={(e) =>
        e.target?.className?.includes('select__panel__option')
      }
    >
      <div className="flex--direction-col flex">
        {entitiesType == EntityTypes.Pod && (
          <>
            <div
              className="kubernetes__table__cell__status__bar"
              style={{
                backgroundColor: colorByKubeState[entity?.status.toLowerCase()],
              }}
            ></div>
            <div className="mx-4 mt-2 flex">
              <div
                className="kubernetes__table__cell__status"
                data-status={entity?.status.toLowerCase()}
                style={{
                  color: colorByKubeState[entity?.status.toLowerCase()],
                }}
              >
                {entity?.status.toUpperCase()}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="px-4 pt-2">
        <KubernetesRightSideBarDetails entity={entity} close={close} />
      </div>
      {entitiesType !== EntityTypes.Cluster ? (
        <div className="px-4 pt-4">
          <p className="kubernetes__tags__subheader">Tags</p>
          <KubernetesTags close={close} tags={entity?.tags || []} />
        </div>
      ) : null}
      {entitiesType !== EntityTypes.Cluster &&
      entitiesType !== EntityTypes.CronJob &&
      entitiesType !== EntityTypes.RoleBinding ? (
        <div className="px-4 pt-3">
          <p className="kubernetes__tags__subheader">Kubernetes Labels</p>
          <KubernetesTags close={close} tags={entity?.metadata?.labels || []} />
        </div>
      ) : null}
      {/* TODO UNCOMMENT whenever needed */}
      {/* {!KubesRelatedTableWithoutEntityTypes.includes(entitiesType) && (
        <div className="px-4 pt-2">
          <KubernetesViewRelatedDropdown
            entityType={entitiesType}
            setViewRelatedEntity={setViewRelatedEntity}
            activeKube={activeKube}
          />
        </div>
      )} */}
      {/* {KubesRelatedTableBindingsEntity.includes(entitiesType) && (
        <div className="px-4 pt-2">
          <p className="kubernetes__tags__subheader mb-1 mt-2">
            {entitiesType.endsWith('Binding') ? 'Subjects' : 'Rules'}
          </p>
        </div>
      )}
      <div className="mb-2">
        {!KubesRelatedTableWithoutEntityTypes.includes(entitiesType) && (
          <Loader
            className="kubernetes__details__body__table mx-4"
            isLoading={kubeViewRelatedEntitiesRequest.isLoading}
          >
            <ViewRelatedTable
              viewRelatedEntity={viewRelatedEntity}
              kpisByFunctionName={
                jobArray.length > 0
                  ? jobArray
                  : kubeViewRelatedEntitiesRequest.result || {}
              }
              kubesState={kubesState}
            />
          </Loader>
        )}
        {entitiesType === EntityTypes.Role ||
        entitiesType === EntityTypes.ClusterRole ? (
          <Loader
            className="kubernetes__details__body__table mx-4"
            isLoading={false}
          >
            <Table
              className="kubernetes__table__table"
              columns={getTableBasedOnEnitityType()}
              isSortingEnabled
              rows={entity.rules ? entity.rules : []}
              renderRow={(props) => (
                <TableRow
                  {...props}
                  className="kubernetes__table__table__row"
                />
              )}
            />
          </Loader>
        ) : null}
        {entitiesType === EntityTypes.RoleBinding ||
        entitiesType === EntityTypes.ClusterRoleBinding ? (
          <Loader
            className="kubernetes__details__body__table mx-4"
            isLoading={false}
          >
            <Table
              className="kubernetes__table__table"
              columns={getTableBasedOnEnitityType()}
              isSortingEnabled
              rows={entity.subjects ? entity.subjects : []}
              renderRow={(props) => (
                <TableRow
                  {...props}
                  className="kubernetes__table__table__row"
                />
              )}
            />
          </Loader>
        ) : null}
      </div> */}
      <Tabs
        className="tabs--underline kubernetes__details__tabs flex flex-1 flex-col"
        tabs={tabs}
        tabsContentClassName="flex-1"
      >
        {[
          entitiesType !== EntityTypes.Cluster && (
            <Tab label="YAML">
              <ErrorBoundary errorMessage={<h1>Failed to load yaml</h1>}>
                <KubernetesSideBarYaml
                  entity={entity}
                  entityType={entitiesType}
                  kubeViewFilterParams={kubeViewFilterParams}
                />
              </ErrorBoundary>
            </Tab>
          ),
          /* TODO UNCOMMENT whenever needed */
          /* {entitiesType !== EntityTypes.Cluster &&
          entitiesType !== EntityTypes.Node && (
            <Tab label="Related Resources">
              <ErrorBoundary errorMessage={<h1>Failed to load logs</h1>}>
                <KubernetesRelatedResources
                  date={date}
                  entityType={entitiesType}
                  row={activeKube}
                  entity={entity}
                />
              </ErrorBoundary>
            </Tab>
          )} */
          isMELATabs && (
            <Tab label="Logs">
              <ErrorBoundary errorMessage={<h1>Failed to load logs</h1>}>
                <KubernetesLogs
                  date={date}
                  entityType={entitiesType}
                  row={activeKube}
                  entity={entity}
                />
              </ErrorBoundary>
            </Tab>
          ),
          /* TODO UNCOMMENT whenever needed */
          /* {isMELATabs && (
          <Tab label="APM">
            <ErrorBoundary errorMessage={<h1>Failed to load APM</h1>}>
              <KubernetesAPM
                date={date}
                entityType={entitiesType}
                row={activeKube}
                entity={entity}
              />
            </ErrorBoundary>
          </Tab>
        )} */
          isMELATabs && (
            <Tab label="Metrics">
              <ErrorBoundary errorMessage={<h1>Failed to load metrics</h1>}>
                <KubernetesMetrics entityType={entitiesType} entity={entity} />
              </ErrorBoundary>
            </Tab>
          ),
          isMELATabs && (
            <Tab label="Events">
              <ErrorBoundary errorMessage={<h1>Failed to load Events</h1>}>
                <KubernetesEvents entityType={entitiesType} entity={entity} />
              </ErrorBoundary>
            </Tab>
          ),
        ].filter(Boolean)}
      </Tabs>
    </RightSidebar>
  );
};

export default KubernetesRightSideBar;
