import { Picker } from 'components';
import React, { ReactElement } from 'react';

const DENSITY_OPTIONS_MAP: { [key: string]: string } = {
  'Single-line': '1',
  Compact: '3',
  Expanded: '5+',
};

const EventsExplorerTableOptions = ({
  close,
  setTableEventsSettings,
  tableEventsSettings,
}: {
  close: () => void;
  setTableEventsSettings: any;
  tableEventsSettings: any;
}): ReactElement => {
  return (
    <div className="flex flex-row items-center p-1">
      <div className="ml-1.5 flex-1">Lines to show</div>
      <Picker
        onChange={(value) => {
          setTableEventsSettings({
            ...tableEventsSettings,
            listDensity: value,
          });
          close();
        }}
        options={Object.keys(DENSITY_OPTIONS_MAP).map((value) => ({
          label: DENSITY_OPTIONS_MAP[value],
          value,
        }))}
        value={tableEventsSettings.listDensity}
      />
    </div>
  );
};

export default EventsExplorerTableOptions;
