import { getSelectedFacetValuesByFacetKey, parseFacetKey } from 'utils';

import { mapDataTypeToGraphQLType } from './buildFacetQuery';
import formatFacetName from './formatFacetName';

const buildFacetExpression = ({
  component,
  name,
  type,
  facetValue,
  operator,
}: {
  component: string;
  name: string;
  type: string;
  facetValue: string;
  operator: string;
}) => {
  const sanitizedName = name.startsWith('@') ? name.slice(1) : name;
  const formattedFacetName = formatFacetName(component, sanitizedName, type);
  return `{ ${operator}: { facetName: "${formattedFacetName}", value: "${facetValue.replace(/"/g, '\\"')}" } }`;
};

const getSelectedFacetQuery = (facetKey, selectedFacetValuesByFacetKey) => {
  const facet = parseFacetKey(facetKey);
  const { component, name, type } = facet;
  const facetValues = Object.keys(selectedFacetValuesByFacetKey);
  const operator =
    selectedFacetValuesByFacetKey[facetValues[0]] === 1 ? 'eq' : 'neq';

  if (facetValues.length === 1) {
    return buildFacetExpression({
      component,
      name,
      type: mapDataTypeToGraphQLType(type, true),
      facetValue: facetValues[0],
      operator,
    });
  }

  let result = `{ ${operator === 'eq' ? 'or' : 'and'}: [`;
  facetValues.forEach((facetValue) => {
    result += buildFacetExpression({
      component,
      name,
      type: mapDataTypeToGraphQLType(type, true),
      facetValue,
      operator,
    });
  });
  result += ']}';

  return result;
};

const buildSelectedFacetsQuery = (selectedFacetValues) => {
  const facetValueCompositeKeys = Object.keys(selectedFacetValues);
  if (!facetValueCompositeKeys.length) {
    return '';
  }

  const selectedFacetValuesByFacetKey =
    getSelectedFacetValuesByFacetKey(selectedFacetValues);
  const facetKeys = Object.keys(selectedFacetValuesByFacetKey);

  if (facetKeys.length === 1) {
    return getSelectedFacetQuery(
      facetKeys[0],
      selectedFacetValuesByFacetKey[facetKeys[0]],
    );
  }

  let result = '{ and: [';
  facetKeys.forEach((facetKey) => {
    result += getSelectedFacetQuery(
      facetKey,
      selectedFacetValuesByFacetKey[facetKey],
    );
  });
  result += '] }';

  return result;
};

//TODO Pavan - this is a temporary solution to fix the issue with filtering logs for service
//if this is still needed, refactor buildSelectedFacetsQuery to use Operator as Parameter
export const buildFacetsQueryWithOr = (selectedFacetValues) => {
  const facetValueCompositeKeys = Object.keys(selectedFacetValues);
  if (!facetValueCompositeKeys.length) {
    return '';
  }

  const selectedFacetValuesByFacetKey =
    getSelectedFacetValuesByFacetKey(selectedFacetValues);
  const facetKeys = Object.keys(selectedFacetValuesByFacetKey);

  if (facetKeys.length === 1) {
    return getSelectedFacetQuery(
      facetKeys[0],
      selectedFacetValuesByFacetKey[facetKeys[0]],
    );
  }

  let result = '{ or: [';
  facetKeys.forEach((facetKey) => {
    result += getSelectedFacetQuery(
      facetKey,
      selectedFacetValuesByFacetKey[facetKey],
    );
  });
  result += '] }';

  return result;
};

export default buildSelectedFacetsQuery;
