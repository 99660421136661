import { useMemo } from 'react';

type Props = {
  data: {
    errors: number;
    latency: number;
    requests: number;
  };
};

const usePopupOverlayMessage = ({ data }: Props) => {
  const overlayMessage = useMemo(() => {
    const dataIsEmpty = !data;
    if (dataIsEmpty) {
      const errorMessage = 'There was an error with fetching data';
      return errorMessage;
    }
    return 'Data is not found';
  }, [data]);

  return overlayMessage;
};

export default usePopupOverlayMessage;
