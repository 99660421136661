import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

import { compact } from 'lodash';
import classnames from 'classnames';
import useChipHandlers from './useChipHandlers';

import { Chip as ChipBase } from 'components';

import { ChipRef, ChipProps, ElementType } from './types';

const Chip = forwardRef<ChipRef, ChipProps>(
  (props, ref): React.ReactElement => {
    const { expression, focusedElementInfo, setFocusedElementInfo } = props;
    const propertyRef = useRef<HTMLInputElement>(null);
    const operatorRef = useRef<HTMLInputElement>(null);
    const valuesRefs = useRef<Array<HTMLInputElement | null>>([]);

    useEffect(() => {
      valuesRefs.current = valuesRefs.current.slice(
        0,
        expression.values.length || 0,
      );
    }, [expression]);

    useImperativeHandle(ref, () => ({
      focusProperty: () => {
        propertyRef.current?.focus();
      },
      focusOperator: () => {
        operatorRef.current?.focus();
      },
      focusValue: (valueIndex: number, selectionStart = 0) => {
        valuesRefs.current[valueIndex]?.focus();
        if (selectionStart) {
          valuesRefs.current[valueIndex]?.setSelectionRange(
            selectionStart,
            selectionStart,
          );
        }
      },
      getFlatRefList: () =>
        compact([
          propertyRef.current,
          operatorRef.current,
          ...valuesRefs.current.map((ref) => ref),
        ]),
    }));

    const {
      handlePropertyChange,
      handleOperatorChange,
      handleValueChange,
      handleOperatorBlur,
      handleValueBlur,
      handleValueKeydown,
    } = useChipHandlers(props);

    const styl = [
      {
        'bg-chip-bg': focusedElementInfo === null,
      },
      {
        'bg-chip-active-bg': focusedElementInfo !== null,
      },
    ];

    return (
      <ChipBase
        data-testid={props['data-testid']}
        onRemove={(e) => {
          e.stopPropagation();
          props.removeExpression({ index: props.index });
        }}
      >
        <input
          ref={propertyRef}
          type="text"
          value={expression.property}
          onChange={handlePropertyChange}
          style={{
            width: `${expression.property.length || 1}ch`,
          }}
          className={classnames('text-text outline-none', ...styl)}
          onFocus={() => {
            setFocusedElementInfo({
              type: ElementType.PROPERTY,
              index: props.index,
            });
          }}
          onBlur={() => {
            setFocusedElementInfo(null);
          }}
        />
        {expression.operator !== null && (
          <input
            ref={operatorRef}
            type="text"
            value={expression.operator}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleOperatorChange(e, operatorRef);
            }}
            style={{ width: `${expression.operator.length || 1}ch` }}
            className={classnames('mx-1 text-operator outline-none', ...styl)}
            onFocus={() => {
              setFocusedElementInfo({
                type: ElementType.OPERATOR,
                index: props.index,
              });
            }}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              handleOperatorBlur(event);
              setFocusedElementInfo(null);
            }}
          />
        )}
        {expression.values?.map((value, valueIndex) => (
          <>
            {valueIndex > 0 && (
              <input
                type="text"
                className={classnames(
                  'mx-1 text-operator outline-none',
                  ...styl,
                )}
                value={'OR'}
                style={{ width: '2ch' }}
                disabled={true}
              />
            )}
            <input
              key={valueIndex}
              ref={(el) => (valuesRefs.current[valueIndex] = el)}
              type="text"
              value={value}
              onKeyDown={(e) => {
                handleValueKeydown(e, valueIndex);
              }}
              onChange={(e) => {
                handleValueChange(e, valueIndex);
              }}
              onFocus={() => {
                setFocusedElementInfo({
                  type: ElementType.VALUE,
                  index: props.index,
                  valueIndex,
                });
              }}
              onBlur={(e) => {
                handleValueBlur(e, valueIndex);
                setFocusedElementInfo(null);
              }}
              style={{
                width: `${expression.values[valueIndex]?.length || 1}ch`,
              }}
              className={classnames('text-value outline-none', ...styl)}
            />
          </>
        ))}
      </ChipBase>
    );
  },
);

export default Chip;
