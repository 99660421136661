import { tooltipFormatter } from 'components/Timeseries/utils';
import { NANOSECONDS } from 'kfuse-constants/timeInNumber';
import Uplot from 'uplot';
import {
  canvasPadding,
  getOperatorSign,
  drawLineOnTimeseries,
  drawRectangeOnTimeseries,
  ctaColor,
  chartThresholdBandColor,
  chartThresholdBandColorYellow,
  chartThresholdBandColorGreen,
} from 'utils';

const drawChartAreaRUMLCP = ({
  u,
  conditionValue,
  conditionOperator,
  darkModeEnabled,
  unit,
}: {
  u: Uplot;
  conditionValue: {
    greenThreshold: number;
    redThreshold: number;
  };
  conditionOperator: {
    greenOperator: string;
    redOperator: string;
  };
  darkModeEnabled?: boolean;
  unit: string;
}): void => {
  const { greenThreshold, redThreshold } = conditionValue;
  const { greenOperator: opGreen, redOperator: opRed } = conditionOperator;
  const { ctx, height, width } = u;
  const padding = canvasPadding(u);
  const devicePixelRatio = window.devicePixelRatio || 1;
  const TOTAL_HEIGHT = height * devicePixelRatio;
  const TOTAL_WIDTH = width * devicePixelRatio;
  const CANVAS_WIDTH = TOTAL_WIDTH - padding.left - padding.right;

  const drawLineBitmap: { [key: string]: number } = {};

  const adjustYPosition = (yPos: number) => {
    const yExceedsTop = yPos < 0;
    const yExceedsBottom = yPos > TOTAL_HEIGHT - padding.bottom;
    let y = yPos;

    if (yExceedsTop) {
      y = padding.top;
    }
    if (yExceedsBottom) {
      y = TOTAL_HEIGHT - padding.bottom;
    }
    return y;
  };

  drawLineBitmap['green'] = adjustYPosition(
    u.valToPos(greenThreshold * NANOSECONDS, 'y', true),
  );
  drawLineBitmap['red'] = adjustYPosition(
    u.valToPos(redThreshold * NANOSECONDS, 'y', true),
  );

  drawLineOnTimeseries({
    ctx,
    start: { x: padding.left, y: drawLineBitmap['green'] },
    end: { x: TOTAL_WIDTH - padding.right, y: drawLineBitmap['green'] },
    options: { color: ctaColor.green, dash: [], lineWidth: 1 },
  });

  drawLineOnTimeseries({
    ctx,
    start: { x: padding.left, y: drawLineBitmap['red'] },
    end: { x: TOTAL_WIDTH - padding.right, y: drawLineBitmap['red'] },
    options: { color: ctaColor.red, dash: [], lineWidth: 1 },
  });

  // Draw the rectangle for the red area
  drawRectangeOnTimeseries({
    ctx,
    x: padding.left,
    y: padding.top,
    width: CANVAS_WIDTH,
    height: drawLineBitmap['red'] - padding.top,
    options: {
      color: darkModeEnabled
        ? chartThresholdBandColor.dark
        : chartThresholdBandColor.light,
    },
  });

  // Draw the rectangle for the yellow area
  drawRectangeOnTimeseries({
    ctx,
    x: padding.left,
    y: drawLineBitmap['green'],
    width: CANVAS_WIDTH,
    height: drawLineBitmap['red'] - drawLineBitmap['green'],
    options: {
      color: darkModeEnabled
        ? chartThresholdBandColorYellow.dark
        : chartThresholdBandColorYellow.light,
    },
  });

  // Draw the rectangle for the green area
  drawRectangeOnTimeseries({
    ctx,
    x: padding.left,
    y: drawLineBitmap['green'],
    width: CANVAS_WIDTH,
    height: TOTAL_HEIGHT - drawLineBitmap['green'] - padding.bottom,
    options: {
      color: darkModeEnabled
        ? chartThresholdBandColorGreen.dark
        : chartThresholdBandColorGreen.light,
    },
  });

  // Draw text for the red threshold
  const value = redThreshold;
  const operator = opRed;
  const color = ctaColor.red;
  let valuePosition = u.valToPos(value * NANOSECONDS, 'y', true);
  valuePosition = adjustYPosition(valuePosition);
  const isAbove = operator === 'gt' || operator === 'gte';

  const text = `${getOperatorSign(operator)} ${tooltipFormatter(
    value || 0,
    unit,
  )}`;
  ctx.save();
  const fontSize = 10 * devicePixelRatio;
  ctx.font = `400 ${fontSize}pt sans-serif`;
  ctx.fillStyle = color;
  const textWidth = ctx.measureText(text).width;
  const yOffset = isAbove ? -8 : 10;
  const xOffset = 6;
  ctx.fillText(
    text,
    padding.left + textWidth + xOffset,
    valuePosition + yOffset,
  );
  ctx.restore();
};

export default drawChartAreaRUMLCP;
