export const resultsPerPageLimits = [10, 25, 50, 75];

export const getOptions = (numberOfRowsPerPage: number, rowsCount: number) => [
  ...(rowsCount < 150 ? [{ label: 'All', value: null }] : []),
  ...resultsPerPageLimits
    .filter((limit) => rowsCount > limit || limit === numberOfRowsPerPage)
    .map((limit) => ({ label: limit, value: limit })),
];

export const getSlicePageIndexes = ({
  activePageIndex,
  maxNumberOfPages,
}: {
  activePageIndex: number;
  maxNumberOfPages: number;
}) => {
  const result = [];
  const sliceStart = Math.max(activePageIndex - 2, 0);
  const sliceEnd = Math.min(sliceStart + 4, maxNumberOfPages - 1);

  for (let i = sliceStart; i <= sliceEnd; i += 1) {
    result.push(i);
  }

  const length = result.length;
  for (let i = 0; i < 5 - length; i += 1) {
    const index = result[0] - 1;
    if (index > -1) {
      result.unshift(index);
    }
  }

  return result;
};
