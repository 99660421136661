import { Button, useToaster } from 'components';
import { Separator } from 'components/shadcn';
import React, { ReactElement } from 'react';
import { X } from 'react-feather';

import { useDashboardState } from '../hooks';
import { copyDashboardJson, exportDashboardJson } from './utils';

const DashboardConfigurePopover = ({
  close,
  dashboardState,
  onDashboardDelete,
}: {
  close: () => void;
  dashboardState: ReturnType<typeof useDashboardState>;
  onDashboardDelete?: () => void;
}): ReactElement => {
  const { dashboardDetails, panels } = dashboardState;
  const { addToast } = useToaster();

  return (
    <div className="dashboard__configure__popover px-2 py-1">
      <div className="flex justify-between items-center">
        <div className="text-sm font-medium">Actions</div>
        <div className="dashboard__configure__popover__description">
          <Button variant="ghost" size="xs" onClick={close}>
            <X size={16} />
          </Button>
        </div>
      </div>
      <Separator className="mt-1" />
      <Button
        className="mt-2 w-full justify-start"
        onClick={() => {
          exportDashboardJson(panels, dashboardDetails)
            .then(() => {
              addToast({
                text: 'Dashboard JSON Exported',
                status: 'success',
                position: 'bottom-right',
              });
            })
            .catch(() => {
              addToast({
                text: 'Dashboard JSON Export Failed',
                status: 'error',
                position: 'bottom-right',
              });
            });
        }}
        variant="ghost"
        size="sm"
      >
        Export Dashboard JSON
      </Button>
      <Button
        className="mt-2 w-full justify-start"
        onClick={() => {
          copyDashboardJson(panels, dashboardDetails);
          addToast({
            text: 'Dashboard JSON Copied to Clipboard',
            status: 'success',
            position: 'bottom-right',
          });
        }}
        variant="ghost"
        size="sm"
      >
        Copy Dashboard JSON
      </Button>
      <Separator className="mt-2" />
      <Button
        className="mt-2 mb-2 w-full"
        onClick={onDashboardDelete}
        variant="destructive"
        size="sm"
      >
        Delete Dashboard
      </Button>
    </div>
  );
};

export default DashboardConfigurePopover;
