import React from 'react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';

const NoAccessBanner = () => {
  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="rounded-lg border p-16 text-center">
        <HiOutlineExclamationCircle className="mx-auto mb-4 size-16 text-yellow-400" />
        <h2 className="mb-2 text-2xl font-bold">Access Restricted</h2>
        <p className="mb-4 text-sm">
          You do not have permission to access this page. If you believe this is
          an error, please contact your system administrator for assistance.
        </p>
      </div>
    </div>
  );
};

export default NoAccessBanner;
