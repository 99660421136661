import {
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  SpanFilter,
} from 'types';
import {
  escapeLinesTabsAndQuotes,
  getIsDeselecting,
  getIsHcFacetName,
} from 'utils';

type Args = {
  additionalFilterByFacets?: SelectedFacetValuesByName;
  customerFilter?: { key: string; value: string };
  facetRegex?: FacetRegexTerm[];
  keyExists?: { [key: string]: number };
  parentSpanIdFilter?: string;
  selectedFacetRangeByName?: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  spanFilter?: SpanFilter;
  traceIdSearch?: string;
};

const getFilterKey = (isHcFacetValue: boolean) => {
  if (isHcFacetValue) {
    return 'jsonFilter';
  }

  return 'attributeFilter';
};

const getNameString = (name: string) => {
  if (name === 'trace_id') {
    return 'traceId';
  }

  return name;
};

const getFilter = (isHcFacetValue: boolean, name: string, value: string) => {
  const nameKey = isHcFacetValue ? 'keyPath' : 'key';
  const nameString = getNameString(name);

  return `{ ${nameKey}: "${nameString}", value:"${escapeLinesTabsAndQuotes(
    value,
  )}"}`;
};

const getRootSpanInclude = (isDeselecting: boolean, value: string) => {
  if (value === 'true') {
    return !isDeselecting;
  }

  return isDeselecting;
};

const buildSelectedFacetValuesByNameFilter = (
  selectedFacetValuesByName: SelectedFacetValuesByName,
) => {
  let result = '';

  const names = Object.keys(selectedFacetValuesByName).filter(
    (name) => name !== 'kf_source',
  );

  names.forEach((name) => {
    const isHcFacetValue = getIsHcFacetName(name);
    const selectedFacetValues = selectedFacetValuesByName[name];
    const isDeselecting = getIsDeselecting(selectedFacetValues);
    const keys = Object.keys(selectedFacetValues);

    if (keys.length > 1) {
      result += `{ ${isDeselecting ? 'and' : 'or'}: [`;
    }

    const filterKey = getFilterKey(isHcFacetValue);

    keys.forEach((value) => {
      if (name === 'rootSpan') {
        const rootSpanInclude = getRootSpanInclude(isDeselecting, value);
        result += `{ attributeFilter: { ${
          rootSpanInclude ? 'eq' : 'neq'
        }: { key: "parentSpanId", value: ""  } } }`;
      } else {
        result += '{';
        result += filterKey;
        result += ': {';
        result += isDeselecting ? 'neq' : 'eq';
        result += ': ';

        result += getFilter(isHcFacetValue, name, value);
        result += '}';
        result += '}';
      }
    });

    if (keys.length > 1) {
      result += `]}`;
    }
  });

  return result;
};

export const buildTracesFilter = ({
  additionalFilterByFacets,
  customerFilter,
  facetRegex = [],
  keyExists = {},
  parentSpanIdFilter,
  selectedFacetRangeByName = {},
  selectedFacetValuesByName = {},
  spanFilter,
  traceIdSearch,
}: Args): string => {
  let result = '{ and: [';

  result += buildSelectedFacetValuesByNameFilter(selectedFacetValuesByName);
  if (additionalFilterByFacets) {
    result += buildSelectedFacetValuesByNameFilter(
      additionalFilterByFacets,
      false,
    );
  }

  if (selectedFacetRangeByName) {
    Object.keys(selectedFacetRangeByName).forEach((name) => {
      const { lower, upper } = selectedFacetRangeByName[name];
      result += `{ durationFilter: { lowerBound: ${
        lower * 1000000
      }, upperBound: ${upper * 1000000} }}`;
    });
  }

  if (parentSpanIdFilter) {
    result += `{attributeFilter: {eq: {key: "parentSpanId", value: "${parentSpanIdFilter}"}}}`;
  }

  if (spanFilter === SpanFilter.serviceEntrySpans) {
    result += `{attributeFilter: {eq : {key: "span_service_entry", value: "true"}}}`;
  }

  if (spanFilter === SpanFilter.traceRootSpans) {
    result += `{attributeFilter: {eq : {key: "parentSpanId", value: ""}}}`;
  }

  if (traceIdSearch) {
    result += `{attributeFilter: { eq: {key: "traceId", value: "${traceIdSearch}"}}}`;
  }

  if (
    customerFilter &&
    customerFilter.key &&
    customerFilter.value &&
    customerFilter.value !== 'All'
  ) {
    result += `{attributeFilter: { eq: {key: "${customerFilter.key}", value: "${customerFilter.value}"}}}`;
  }

  Object.keys(keyExists)
    .filter((key) => keyExists[key])
    .forEach((key) => {
      const isHcAttribute = key.indexOf('$.') === 0;
      const filterKey = isHcAttribute ? 'jsonFilter' : 'attributeFilter';
      const keyString = isHcAttribute ? 'keyPath' : 'key';
      result += `{${filterKey}: {keyExists : {${keyString}: "${key}", value: ""}}}`;
    });

  facetRegex.forEach((facetRegexTerm: FacetRegexTerm) => {
    const isHcFacetName = getIsHcFacetName(facetRegexTerm.name);
    const filterKey = getFilterKey(isHcFacetName);
    const keyString = isHcFacetName ? 'keyPath' : 'key';

    const values = facetRegexTerm.value.split(' OR ');

    if (values.length > 1) {
      result += '{ or: [';
    }
    values.forEach((value) => {
      result += `{${filterKey}: {${
        facetRegexTerm.isEqual ? 'regex' : 'nregex'
      } : {${keyString}: "${facetRegexTerm.name}", value: "${value}"}}}`;
    });
    if (values.length > 1) {
      result += ']}';
    }
  });

  // deprioritizing ci_test for now
  // result += `{attributeFilter: {eq: {key: "ci_test", value: "false"}}}`;

  result += '] }';

  return result;
};

export default buildTracesFilter;
