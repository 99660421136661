import { Badge, PopoverTriggerV2 } from 'components';
import React from 'react';
import MetricSummaryTagsValuePopoverPanel from './MetricSummaryTagsValuePopoverPanel';

type Props = {
  metricName: string;
  tag: string;
  tagValue: string;
};

const MetricsTagText = ({ metricName, tag, tagValue }: Props) => {
  const tagAndValue = `${tag}:${tagValue}`;

  return (
    <PopoverTriggerV2
      popover={({ close }) => (
        <MetricSummaryTagsValuePopoverPanel
          metricName={metricName}
          tagValue={tagAndValue}
          close={close}
        />
      )}
    >
      <Badge
        className="mb-1 mr-1 rounded-sm px-2 text-xs font-medium text-text-secondary"
        variant="outline"
      >
        {tagValue.length > 45 ? `${tagValue.slice(0, 45)}...` : `${tagValue}`}
      </Badge>
    </PopoverTriggerV2>
  );
};

export default MetricsTagText;
