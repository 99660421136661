import {
  RumEvent,
  RumActionEventData,
  RumErrorEventData,
  RumEventType,
  RumLongTaskEventData,
  RumResourceEventData,
  RumViewEventData,
  RumSessionEventData,
} from '../types';

type IdFilterResult =
  | { viewId: string }
  | { longTaskId: string }
  | { resourceId: string }
  | { sessionId: string }
  | { errorId: string }
  | { actionId: string }
  | { actionIds: string }
  | null;

const getIdFilter = ({
  activeRumEvent,
  shoulduseActionIds,
}: {
  activeRumEvent: RumEvent;
  shoulduseActionIds?: boolean;
}): IdFilterResult => {
  if (!activeRumEvent) {
    return null;
  }
  const eventData = activeRumEvent.data;

  if (activeRumEvent.eventType === RumEventType.VIEW) {
    const viewData = eventData as RumViewEventData;
    return { viewId: viewData['view.id'] };
  }

  if (activeRumEvent.eventType === RumEventType.LONGTASK) {
    const longTaskData = eventData as RumLongTaskEventData;
    return { longTaskId: longTaskData['long_task.id'] };
  }

  if (activeRumEvent.eventType === RumEventType.RESOURCE) {
    const resourceData = eventData as RumResourceEventData;
    return { resourceId: resourceData['resource.id'] };
  }

  if (activeRumEvent.eventType === RumEventType.ERROR) {
    const errorData = eventData as RumErrorEventData;
    return { errorId: errorData['error.id'] };
  }

  if (activeRumEvent.eventType === RumEventType.SESSION) {
    const sessionData = eventData as RumSessionEventData;
    return { sessionId: sessionData['session.id'] };
  }

  if (activeRumEvent.eventType === RumEventType.ACTION) {
    if (shoulduseActionIds) {
      const actionData = eventData as RumActionEventData;
      return { actionIds: actionData['action.id'] };
    }
    const actionData = eventData as RumActionEventData;
    return { actionId: actionData['action.id'] };
  }

  return null;
};

export default getIdFilter;
