import { AutocompleteV2, AutocompleteOption } from 'components';
import React, { ReactElement } from 'react';

import {
  AlertsCreateConditionFor,
  AlertsCreateConditionOf,
  AlertsCreateConditionQueryKey,
  AlertsCreateConditionThreshold,
  AlertsCreateConditionWhen,
  useAlertsCreateCondition,
} from '../AlertsCreateCondition';
import { useAlertsCreate } from '../hooks';
import { changeType, changeConditionByLabel, timeType } from './utils';

function AlertsCreateMetricsChangeCondition({
  queryAndFormulaKeysLabel,
  conditionState,
  alertsCreateState,
}: {
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
  queryAndFormulaKeysLabel: AutocompleteOption[];
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
}): ReactElement {
  const { metricsChangeCondition, setMetricsChangeCondition } = conditionState;
  const { alertType, evaluate, setEvaluate } = alertsCreateState;

  const changeCondition = metricsChangeCondition;

  return (
    <div>
      <div className="alerts__create__section__item">
        <div className="alerts__create__conditions__item__text">
          Trigger when the
        </div>
        <AlertsCreateConditionWhen conditionState={conditionState} />
        <div className="alerts__create__conditions__item__text">of the</div>
        <div className="alerts__create__change__item__input">
          <AutocompleteV2
            onChange={(val: string) =>
              setMetricsChangeCondition({ ...changeCondition, change: val })
            }
            options={changeType}
            value={changeCondition.change}
          />
        </div>
        <div className="alerts__create__conditions__item__text">of</div>
        <AlertsCreateConditionQueryKey
          conditionState={conditionState}
          queryAndFormulaKeysLabel={queryAndFormulaKeysLabel}
        />
        <div className="alerts__create__conditions__item__text">from</div>
        <div className="alerts__create__change__item__input">
          <AutocompleteV2
            onChange={(val: string) =>
              setMetricsChangeCondition({ ...changeCondition, time: val })
            }
            options={timeType}
            value={changeCondition.time}
          />
        </div>
        <div className="alerts__create__conditions__item__text">
          ago to over
        </div>
        <AlertsCreateConditionFor
          evaluate={evaluate}
          setEvaluate={setEvaluate}
          ruleType={alertsCreateState.ruleType}
        />
        <div className="alerts__create__change__item__text">is</div>
        <AlertsCreateConditionOf
          conditionState={conditionState}
          options={changeConditionByLabel}
        />
        <AlertsCreateConditionThreshold
          alertType={alertType}
          conditionState={conditionState}
        />
      </div>
    </div>
  );
}

export default AlertsCreateMetricsChangeCondition;
