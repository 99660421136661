import { useToaster } from 'components';
import { useRequest } from 'hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getGrafanaMutedAlerts } from 'requests';

import {
  getSilencedInstanceByFilter,
  createOrUpdateSilence,
} from '../requests';
import { SilencesProps } from '../types';
import {
  calcScheduleDuration,
  getDefaultSilences,
  getSilenceMatcherString,
} from '../utils';

const useAlertsSilenceEditState = () => {
  const { addToast } = useToaster();
  const navigate = useNavigate();
  const [silenceDetails, setSilenceDetails] = useState<SilencesProps | null>(
    null,
  );
  const { uid } = useParams();
  const createOrUpdateSilenceRequest = useRequest(createOrUpdateSilence);
  const getFilteredSilencedInstancesRequest = useRequest(
    getSilencedInstanceByFilter,
  );
  const silenceByUidRequest = useRequest<SilencesProps, any>(() =>
    getGrafanaMutedAlerts().then((res: SilencesProps[]) =>
      res.find((silence) => silence.id === uid),
    ),
  );

  const loadSilencedInstances = (silence: SilencesProps) => {
    const matcherString = getSilenceMatcherString(silence);
    getFilteredSilencedInstancesRequest.call(matcherString);
  };

  const onSaveSilence = () => {
    const silence = { ...silenceDetails };
    if (silence.scheduleValidation) {
      addToast({ text: silence.scheduleValidation, status: 'error' });
      return;
    }

    delete silence.duration;
    delete silence.status;
    delete silence.updatedAt;
    delete silence.scheduleValidation;
    createOrUpdateSilenceRequest.call({ silence }).then(() => {
      navigate('/alerts/silences');
    });
  };

  useEffect(() => {
    if (!uid) return;
    if (uid === 'new') {
      setSilenceDetails(getDefaultSilences());
      return;
    }
    silenceByUidRequest.call().then((res) => {
      const duration = calcScheduleDuration(res.startsAt, res.endsAt);
      setSilenceDetails({ ...res, duration });
      loadSilencedInstances(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  return {
    createOrUpdateSilenceRequest,
    getFilteredSilencedInstancesRequest,
    loadSilencedInstances,
    onSaveSilence,
    setSilenceDetails,
    silenceByUidRequest,
    silenceDetails,
    uid,
  };
};

export default useAlertsSilenceEditState;
