import dayjs from 'dayjs';
import { Span } from 'types/generated';
import { onPromiseError } from 'utils';
import queryTraceService from './queryTraceService';

type Args = {
  spanId: string;
  endTimeNs: number;
  traceId: string;
};

const describeSpan = async ({
  spanId,
  endTimeNs,
  traceId,
}: Args): Promise<Span> => {
  const timestamp = dayjs(Math.round(endTimeNs / 1000000));
  return queryTraceService<Span, 'describeSpan'>(`
    {
        describeSpan(
        spanId: "${spanId}"
        traceId: "${traceId}"
        timestamp: "${timestamp.format()}"
      ) {
        attributes
        endpoint
        endTimeNs
        method
        name
        durationNs
        parentSpanId
        rootSpan
        service {
          name
          distinctLabels
          hash
          labels
        }
        span
        spanId
        startTimeNs
        statusCode
        traceId
      }
    }
  `).then((data) => data.describeSpan || null, onPromiseError);
};

export default describeSpan;
