import React from 'react';
import { DateSelection } from 'types';
import TracesServiceMapLinkTooltipEdge from './TracesServiceMapLinkTooltipEdge';

type Props = {
  date: DateSelection;
  edge: {
    source: string;
    target: string;
    data: {
      hasInverse: boolean;
      serviceDistinctLabels: Record<string, string>;
      serviceHash: string;
      serviceName: string;
      clientServiceName: string;
      clientServiceHash: string;
      clientServiceDistinctLabels: Record<string, string>;
    };
  };
};

const parseServiceName = (serviceName: string) =>
  serviceName.startsWith('UNKNOWN') ? 'UNKNOWN' : serviceName;

const TracesServiceMapLinkTooltip = ({ date, edge }: Props) => {
  const { data } = edge;
  const {
    clientServiceHash,
    clientServiceName,
    clientServiceDistinctLabels,
    serviceDistinctLabels,
    hasInverse,
    serviceName,
    serviceHash,
  } = data;

  return (
    <div>
      <TracesServiceMapLinkTooltipEdge
        clientServiceDistinctLabels={clientServiceDistinctLabels}
        clientServiceHash={clientServiceHash}
        clientServiceName={clientServiceName}
        date={date}
        label={`${data.clientServiceName}-${data.serviceName}`}
        serviceDistinctLabels={serviceDistinctLabels}
        serviceName={serviceName}
        serviceHash={serviceHash}
      />
      {hasInverse ? (
        <TracesServiceMapLinkTooltipEdge
          clientServiceDistinctLabels={serviceDistinctLabels}
          clientServiceHash={clientServiceHash}
          clientServiceName={serviceName}
          date={date}
          label={`${serviceName}-${clientServiceName}`}
          serviceDistinctLabels={clientServiceDistinctLabels}
          serviceName={clientServiceName}
          serviceHash={serviceHash}
          style={{ transform: 'translateY(120px)' }}
        />
      ) : null}
    </div>
  );
};

export default TracesServiceMapLinkTooltip;
