import React, { ReactElement } from 'react';
import { Loader, TopList } from 'components';
import useRumTopViewWithHighestError from './hooks/useRumTopViewWithHighestError';
import { RumEventType } from './types';
import { useSearch } from 'hooks';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { TooltipTrigger, useModalsContext } from 'components';
import { TimeseriesExplorer } from 'components';

const RumErrorsTopView = ({ rumPageState }: Props): ReactElement => {
  const { rumState } = rumPageState;
  const {
    applicationFilter,
    dateState,
    facetRegexState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    filtersState,
  } = rumState;

  const [date] = dateState;
  const filters = filtersState.state;
  const search = useSearch();

  const rumTopListQueries = (
    aggregateField: string,
    aggregateFunction: string,
  ) => ({
    isActive: true,
    type: 'query',
    queryKey: 'query_a',
    query: {
      ...search.state,
      searchBarState: {
        applicationFilter,
        filters,
      },
      aggregateField,
      aggregateFunction,
      groupBys: ['view.name'],
    },
  });

  const rumEventsRequest = useRumTopViewWithHighestError({
    applicationFilter,
    date,
    eventType: RumEventType.VIEW,
    facetRegexState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    chart: {
      rumTopListQueries: [rumTopListQueries('view.name', 'count')],
      eventType: RumEventType.VIEW,
    },
  });

  const errorSeries = rumEventsRequest.result?.errorSeries || [];
  const transformErrorPercent = (errorSeries) => {
    return errorSeries.map((series) => {
      const errorPercent = parseFloat(series?.errorPercent.toFixed(2)) || 0;
      const viewName = series?.dimensions['view.name'] || '/';
      return {
        label: viewName,
        count: errorPercent,
        evaluatedValue: errorPercent.toString(),
      };
    });
  };

  const transformedErrorPercent = transformErrorPercent(errorSeries).sort(
    (a, b) => b.count - a.count,
  );

  const modal = useModalsContext();
  const parentWidth = window.innerWidth * 0.8;
  const onExploreClick = async () => {
    modal.push(
      <TimeseriesExplorer
        activeQueries={[rumTopListQueries('view.name', 'count')]}
        chartData={null}
        date={date}
        queryType="rum-toplist"
        onClose={modal.pop}
        unit=""
        eventType={RumEventType.VIEW}
      />,
    );
  };

  return (
    <div className="rum__main overflow-auto">
      <div className="mb-4 ml-2 flex items-center justify-between p-1">
        <div className="text-[11px] font-medium">
          Top Views with Highest Error Rate
        </div>
        <TooltipTrigger tooltip="Explore Metric">
          <div
            className="chart-grid__item__header__right__explorer cursor-pointer"
            onClick={onExploreClick}
          >
            <BsArrowsFullscreen size={12} />
          </div>
        </TooltipTrigger>
      </div>

      <Loader isLoading={rumEventsRequest.isLoading}>
        <div className="overflow-hidden p-2">
          {errorSeries.length > 0 ? (
            <div>
              <TopList
                data={transformedErrorPercent}
                height={250}
                width={parentWidth * 0.8}
              />
            </div>
          ) : (
            <div className="text-center text-gray-500">No data available</div>
          )}
        </div>
      </Loader>
    </div>
  );
};

export default RumErrorsTopView;
