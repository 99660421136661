import classNames from 'classnames';

import React, { ReactElement, useMemo } from 'react';
import { convertNumberToReadableUnit } from 'utils/formatNumber';
import { Skeleton } from '../base';
import { FlatList } from '../FlatList';
import { AutocompleteOption, AutocompleteListPropsV2 } from './types';

const AutocompleteOptionButton = ({
  disableKeyExist,
  isFocused,
  isOptionAlreadyApplied,
  option,
  onClickHandler,
  onClickKeyExistHandler,
}: {
  disableKeyExist?: boolean;
  isFocused: boolean;
  isOptionAlreadyApplied?: AutocompleteListPropsV2['isOptionAlreadyApplied'];
  option: AutocompleteOption;
  onClickHandler: AutocompleteListPropsV2['onClickHandler'];
  onClickKeyExistHandler: AutocompleteListPropsV2['onClickKeyExistHandler'];
}) => {
  const shouldDisableKeyExist =
    disableKeyExist || isOptionAlreadyApplied?.(option);
  return (
    <button
      type="button"
      className={classNames({
        autocomplete__list__option: true,
        'autocomplete__list__option--active': isFocused,
      })}
      onMouseDown={() => onClickHandler({ close, option, type: 'mouse' })}
    >
      <div className=" autocomplete__panel__option__left">
        <div className="autocomplete__panel__option__left__label">
          {option.label}
        </div>
        {option.dataType && (
          <span className="autocomplete__panel__option__left__data-type">
            {option.dataType?.toUpperCase()}
          </span>
        )}
      </div>
      <div className="autocomplete__panel__option__right">
        {option.optionType === 'facet' && !shouldDisableKeyExist && (
          <div
            className="autocomplete__panel__option__right__only-button"
            onMouseDown={(e) => {
              e.stopPropagation();
              onClickKeyExistHandler({ close, option });
            }}
          >
            Key Exist
          </div>
        )}
        {!option.count && (
          <div className="autocomplete__panel__option__right__option-type">
            {option.optionType}
          </div>
        )}
        {option.count && (
          <div className="autocomplete__panel__option__right__count">
            {convertNumberToReadableUnit(option.count, 2)}
          </div>
        )}
      </div>
    </button>
  );
};

const AutocompleteListV2 = ({
  close,
  disableKeyExist,
  emptyPlaceholder,
  isLoading,
  isOptionAlreadyApplied,
  onClickHandler,
  onClickKeyExistHandler,
  onScrollToBottom,
  options,
  typed,
}: AutocompleteListPropsV2): ReactElement => {
  const filteredOptions = useMemo(() => {
    const typedLowered = typed.toLowerCase().trim();
    const typedTerms = [typedLowered];
    if (typedLowered) {
      return options.filter((option) =>
        typedTerms.some(
          (typedTerm) => option.value.toLowerCase().indexOf(typedTerm) > -1,
        ),
      );
    }
    return options;
  }, [options, typed]);

  if (!filteredOptions || filteredOptions.length === 0) {
    if (isLoading) {
      return <Skeleton count={3} />;
    }

    if (emptyPlaceholder) {
      return <>{emptyPlaceholder}</>;
    }

    return null;
  }

  return (
    <>
      <FlatList
        data={filteredOptions}
        onEnterKey={(option) => onClickHandler({ close, option, type: 'key' })}
        onScrollToBottom={onScrollToBottom}
        renderItem={({ item, index, isFocused }) => (
          <AutocompleteOptionButton
            disableKeyExist={disableKeyExist}
            isFocused={isFocused}
            isOptionAlreadyApplied={isOptionAlreadyApplied}
            key={`${index}`}
            option={item}
            onClickHandler={onClickHandler}
            onClickKeyExistHandler={onClickKeyExistHandler}
          />
        )}
      />
      {isLoading ? <Skeleton count={3} /> : null}
    </>
  );
};

export default AutocompleteListV2;
