import { calculateCapacity } from 'utils/timeNs';

import { KubeEntityGroupAggregationProps } from '../types';

export const getKubeGroupCellValue = (
  row: { fieldAggregations: KubeEntityGroupAggregationProps[] },
  aggrID: string,
): string => {
  if (!row?.fieldAggregations) return null;
  if (!aggrID) return null;
  return row?.fieldAggregations?.find((fieldData) => fieldData.id === aggrID)
    ?.result;
};

export const podStatusSuccessGroup = (row: any) => {
  if (
    row.groupBys &&
    (row.groupBys[0]?.value === 'completed' ||
      row.groupBys[0]?.value === 'running' ||
      row.groupBys[0]?.value === 'succeeded')
  ) {
    return row.count;
  } else {
    return (
      (parseInt(getKubeGroupCellValue(row, 'RUNNING_COUNT')) || 0) +
      (parseInt(getKubeGroupCellValue(row, 'COMPLETED')) || 0)
    );
  }
};

export const podStatusPendingGroup = (row: any) => {
  if (row.groupBys && row.groupBys[0]?.value === 'pending') {
    return row.count;
  } else {
    return parseInt(getKubeGroupCellValue(row, 'PENDING')) || 0;
  }
};

export const podStatusCrashGroup = (row: any) => {
  if (
    row.groupBys &&
    (row.groupBys[0]?.value === 'crashloopbackoff' ||
      row.groupBys[0]?.value === 'error' ||
      row.groupBys[0]?.value === 'terminated')
  ) {
    return row.count;
  } else {
    return parseInt(getKubeGroupCellValue(row, 'CRASHLOOPBACK')) || 0;
  }
};

export const namespaceClusterGroup = (row: any) => {
  return row.namespace
    ? row?.namespace?.tags
        .find((element: string | string[]) => {
          return element.includes('kube_cluster_name');
        })
        .split(':')[1]
    : row?.tags
    ? row?.tags
        .find((element: string | string[]) => {
          return element.includes('kube_cluster_name');
        })
        .split(':')[1]
    : '';
};

export const cpuUsageNamespaceGroup = (row: any) => {
  const value = row?.cpuPercentage;
  const splitValue = value.split('.')[0];

  return splitValue.length > 6
    ? (value / 1000000).toFixed(2) + ' µCPUs'
    : (value / 1000).toFixed(2) + ' mCPUs';
};

export const namespaceCPUUtilizedCalculation = (row: any) => {
  const value = row?.cpuPercentage;
  if (value) {
    const splitValue = value.split('.')[0];

    return splitValue.length > 6
      ? (value / 1000000).toFixed(2) + ' µCPUs'
      : (value / 1000).toFixed(2) + ' mCPUs';
  } else {
    return '';
  }
};

export const namespaceMemUtilizedCalculation = (row: any) => {
  if (row?.memoryPercentage) {
    return calculateCapacity(row?.memoryPercentage);
  } else {
    return '';
  }
};
