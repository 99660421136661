import { AutocompleteV2 } from 'components';
import React, { ReactElement } from 'react';
import { ChartType } from 'types';

import ChartToolbarType from './ChartToolbarType';
import { ChartToolbarPropsComponent } from '../types';
import {
  areaAndBarTypesPredefined,
  chartTypesPredefined,
  lineStrokeTypesPredefined,
} from '../utils';

const ChartToolbar = ({
  activeChart,
  activeStroke,
  chartTypes,
  setActiveChart,
  setActiveStroke,
  toolbar: { leftToolbar, rightToolbar, toolbarMenuType = 'button' },
}: ChartToolbarPropsComponent): ReactElement => {
  const chartTypesNew = chartTypes ? chartTypes : chartTypesPredefined;

  return (
    <div className="uplot__chart-renderer__toolbar">
      <div className="uplot__chart-renderer__toolbar__left">
        {leftToolbar && leftToolbar}
      </div>
      <div className="uplot__chart-renderer__toolbar__right">
        {activeStroke && activeChart === 'Line' && (
          <AutocompleteV2
            className="uplot__chart-renderer__toolbar__select autocomplete__fixed-height-28"
            isSearchable={false}
            options={lineStrokeTypesPredefined.map((type: string) => ({
              label: type,
              value: type,
            }))}
            onChange={(type) => setActiveStroke(type)}
            value={activeStroke}
          />
        )}
        {(activeChart === 'Area' || activeChart == 'Stacked Bar') && (
          <AutocompleteV2
            className="uplot__chart-renderer__toolbar__select autocomplete__fixed-height-28"
            isSearchable={false}
            options={areaAndBarTypesPredefined.map((type: string) => ({
              label: type,
              value: type,
            }))}
            //only one version so far
            onChange={() => {}}
            value={'stacked'}
          />
        )}
        {toolbarMenuType === 'button' && (
          <ChartToolbarType
            chartType={activeChart}
            chartTypes={chartTypes}
            onChartTypeChange={(type) => setActiveChart(type)}
          />
        )}
        {toolbarMenuType === 'dropdown' && (
          <AutocompleteV2
            className="uplot__chart-renderer__toolbar__select autocomplete__fixed-height-28"
            isSearchable={false}
            options={chartTypesNew.map((type: any) => ({
              label: type === 'Stacked Bar' ? 'Bar' : type,
              value: type,
            }))}
            onChange={(type: ChartType) => setActiveChart(type)}
            value={activeChart}
          />
        )}
        {rightToolbar && rightToolbar}
      </div>
    </div>
  );
};

export default ChartToolbar;
