import React, { ReactElement, useState } from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';

import {
  AlertsCreateConditionInfo,
  executionErrorType,
  noDataType,
  useAlertsCreateCondition,
} from '../AlertsCreateCondition';
import { AutocompleteV2 } from 'components/Autocomplete';
import {
  EXECUTION_ERROR_DESCRIPTION,
  NO_DATA_DESCRIPTION,
} from '../constants/alertsConstants';

const AlertsConfigureNoData = ({
  conditionState,
}: {
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
}): ReactElement => {
  const { updateCondition, condition } = conditionState;
  const [selected, setSelected] = useState(false);

  return (
    <div>
      <div
        className="alerts__create__nodata__container flex items-center"
        onClick={() => setSelected(!selected)}
      >
        {selected ? (
          <ChevronDown
            className="select__trigger__icon select__trigger__icon--open"
            size={16}
            style={{ verticalAlign: 'middle' }}
          />
        ) : (
          <ChevronRight
            className="select__trigger__icon select__trigger__icon--closed"
            size={16}
            style={{ verticalAlign: 'middle' }}
          />
        )}
        <div className="alerts__create__nodata__section__header">
          Configure no data and error handling
        </div>
      </div>
      {selected && (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
          <div className="alerts__create__conditions__item__input">
            <AutocompleteV2
              onChange={(val: string) => updateCondition('noData', val)}
              options={noDataType}
              placeholder=""
              value={condition.noData}
            />
          </div>
          <div className="alerts__create__conditions__item__text">
            if data is missing
          </div>
          <div className="alert__create__nodata__description__container">
            <AlertsCreateConditionInfo description={NO_DATA_DESCRIPTION} />
          </div>
          <div className="alerts__create__conditions__item__input">
            <AutocompleteV2
              onChange={(val: string) => updateCondition('executionError', val)}
              options={executionErrorType}
              placeholder=""
              value={condition.executionError}
            />
          </div>
          <div className="alerts__create__conditions__item__text">
            if execution error or timeout
          </div>
          <div className="alert__create__nodata__description__container">
            <AlertsCreateConditionInfo
              description={EXECUTION_ERROR_DESCRIPTION}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AlertsConfigureNoData;
