import React, { useMemo } from 'react';

import { ClusterRoleBinding, EntityTypes } from '../types';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellTimestamp,
  KubeTableCell,
} from './Components';
import KubernetesTable from './KubernetesTable';
import { useEntitiesCount, useEntitiesData } from './useEntitiesData';

export default function KubernetesTableForClusterRoleBinding() {
  const { entitiesType, facets, page, limit } = useKubernetesController();

  const filters = useMemo(() => [facets], [facets]);
  const [rows] = useEntitiesData<{ clusterRoleBinding: ClusterRoleBinding }>({
    filters,
    page,
    limit,
  });
  const [count] = useEntitiesCount({
    entitiesType,
    facets,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'kube_cluster_role_binding',
          label: 'Cluster Role Binding',
          renderCell(prop) {
            const clusterRoleBindingName =
              prop?.row.clusterRoleBinding?.metadata?.name;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name pt-1.5 pb-1.5"
                style={{ maxWidth: 220 }}
                tooltipText={clusterRoleBindingName}
              >
                {clusterRoleBindingName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell(prop) {
            return <KubeCellCluster {...prop} />;
          },
        },
        {
          key: 'roleRef.name',
          label: 'Role',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="left">
                {prop.row.clusterRoleBinding.roleRef.name}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerSub',
          label: 'Subjects',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="left">
                {prop.row.clusterRoleBinding.subjects
                  ? prop.row.clusterRoleBinding.subjects[0]?.kind
                  : '0'}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'metadata.creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            return (
              <KubeCellTimestamp
                {...prop}
                timestamp={
                  prop.row.clusterRoleBinding.metadata.creationTimestamp
                }
              />
            );
          },
        },
      ]}
    />
  );
}
