import { SearchTag } from 'components';
import { Operator, SelectedFacetValueFilter } from 'hooks';
import React from 'react';
import { Service } from 'types';

type Args = {
  colorsByServiceName: Record<string, string>;
  renderFacet?: (facet: string) => string;
  selectedFacetValueFilter: SelectedFacetValueFilter;
  serviceByHash: Record<string, Service>;
};

const defaultGetFacetValueTag = ({
  colorsByServiceName,
  renderFacet,
  selectedFacetValueFilter,
  serviceByHash,
}: Args): SearchTag[] => {
  // const keys = Object.keys(state).filter((key) => key !== 'kf_source');
  const { facet, operator } = selectedFacetValueFilter.value;
  const renderedFacet = renderFacet ? renderFacet(facet) : facet;
  const facetValuesBitmap = selectedFacetValueFilter.value.values;

  const isFacetServiceOrDatabaseHash = [
    'service_hash',
    'kf_database_service_hash',
  ].includes(facet);

  const labelName = facet === 'service_hash' ? 'service' : 'database';
  const label = isFacetServiceOrDatabaseHash ? (
    <>
      <span className="traces-search__input__trigger__tag__name">
        {labelName}
      </span>
      <span>{operator}</span>
      <span className="traces-search__input__trigger__tag__value">
        {Object.keys(facetValuesBitmap)
          .map((serviceHash) => {
            const service = serviceByHash[serviceHash];
            if (service) {
              const distinctLabelValues = Object.values(
                service.distinctLabels || {},
              );
              return `${service.name}${
                distinctLabelValues.length
                  ? ` (${distinctLabelValues.join(', ')})`
                  : ''
              }`;
            }

            return serviceHash;
          })
          .join(' OR ')}
      </span>
    </>
  ) : (
    <>
      <span className="traces-search__input__trigger__tag__name">
        {renderedFacet}
      </span>
      <span>{operator}</span>
      <span className="traces-search__input__trigger__tag__value">
        {`"${Object.keys(facetValuesBitmap).join(' OR ')}"`}
      </span>
    </>
  );

  return {
    label,
    name: renderedFacet,
    text: `${
      facet === 'service_hash' ? 'service' : renderedFacet
    }${operator}"${Object.keys(facetValuesBitmap).join(' OR ')}"`,
  };
};

export default defaultGetFacetValueTag;
