import fetchAdvanceFunctions from './fetchAdvanceFunctions';

const analysisServiceCorrelation = ({
  anomalyStartTime,
  anomalyEndTime,
  labelsScope,
  metricsScope,
  kfSource,
  serviceHash,
  sourceExpr,
  step,
}: {
  anomalyStartTime: number;
  anomalyEndTime: number;
  labelsScope: string[];
  metricsScope: { asm_services: string[][]; integrations: string[][] };
  kfSource: string;
  serviceHash: string;
  sourceExpr: string;
  step?: string;
}): Promise<any> => {
  return fetchAdvanceFunctions('api/v1/correlations', {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({
      anomalyStartTime,
      anomalyEndTime,
      correlationStepSize: step,
      labelsScope,
      kf_source: kfSource,
      metricsScope,
      sourceExpr,
      service_hash: serviceHash,
      startTime: anomalyStartTime,
      endTime: anomalyEndTime,
    }),
  }).then((result: { correlations: any }) => result.correlations);
};

export default analysisServiceCorrelation;
