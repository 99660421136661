import { EntityTypes } from '../types';

export const KubesRelatedTableWithoutEntityTypes = [
  EntityTypes.Ingress,
  EntityTypes.Role,
  EntityTypes.RoleBinding,
  EntityTypes.ClusterRole,
  EntityTypes.ClusterRoleBinding,
  EntityTypes.ServiceAccount,
];

export const KubesDetailsTabsEntityTypes = [
  EntityTypes.Pod,
  EntityTypes.Cluster,
  EntityTypes.Namespace,
  EntityTypes.Node,
  EntityTypes.Deployment,
  EntityTypes.DaemonSet,
  EntityTypes.ReplicaSet,
  EntityTypes.StatefulSet,
  EntityTypes.Service,
];

export const KubesRelatedTableBindingsEntity = [
  EntityTypes.Role,
  EntityTypes.RoleBinding,
  EntityTypes.ClusterRole,
  EntityTypes.ClusterRoleBinding,
];
