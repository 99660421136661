import { Service } from 'types/generated';
import { DataFrame } from '../types';

const SERVICE_HASH_KEY = 'service_hash';

const replaceServiceHashWithLabel = (
  dataFrame: DataFrame,
  serviceByHash: Record<string, Service>,
): DataFrame => {
  const newDataFrameData = [...dataFrame.data];
  Object.keys(serviceByHash).forEach((hash) => {
    const service = serviceByHash[hash];
    const distinctLabelValues = Object.values(service.distinctLabels || {});
    const serviceName = `${service.name}${
      distinctLabelValues.length ? ` (${distinctLabelValues.join(', ')})` : ''
    }`;

    for (let i = 0; i < newDataFrameData.length; i++) {
      if (newDataFrameData[i].label[SERVICE_HASH_KEY] === hash) {
        newDataFrameData[i] = {
          ...newDataFrameData[i],
          label: {
            ...newDataFrameData[i].label,
            [SERVICE_HASH_KEY]: serviceName,
          },
        };
      }
    }
  });

  return { ...dataFrame, data: newDataFrameData };
};

export default replaceServiceHashWithLabel;
