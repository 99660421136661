import { useRequest } from 'hooks';
import { getOrgByUserId, getUserById } from './requests';

const useUserManagementState = () => {
  const userByIdRequest = useRequest(getUserById);
  const orgByIdRequest = useRequest(getOrgByUserId);

  return { userByIdRequest, orgByIdRequest };
};

export default useUserManagementState;
