import {
  DownloadModal,
  TableColumn,
  useColumnsState,
  useModalsContext,
} from 'components';
import React, { useEffect } from 'react';
import useRumDownloader from './hooks/useRumDownloader';
import { DateSelection } from 'types';
import {
  useSortState,
  useSelectedFacetRangeByNameState,
  useSelectedFacetValuesByNameState,
  useFacetRegexState,
} from 'hooks';

const RumDownloadModal = ({
  applicationFilter,
  actionId,
  columnsState,
  date,
  downloadType,
  eventType,
  facetRegexState,
  idSearch,
  rumTableSortState,
  selectedFacetRangeByNameState,
  selectedFacetValuesByNameState,
  viewId,
}: {
  applicationFilter: string;
  actionId?: string;
  columnsState: ReturnType<typeof useColumnsState>;
  date: DateSelection;
  downloadType: string;
  eventType: string;
  facetRegexState?: ReturnType<typeof useFacetRegexState>;
  idSearch: string;
  rumTableSortState: ReturnType<typeof useSortState>;
  selectedFacetRangeByNameState: ReturnType<
    typeof useSelectedFacetRangeByNameState
  >;
  selectedFacetValuesByNameState?: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
  viewId?: string;
}) => {
  const modals = useModalsContext();

  const onDone = () => {
    setTimeout(() => {
      modals.pop();
    }, 2000);
  };

  const rumDownloader = useRumDownloader({
    columnsState,
    downloadType,
    onDone,
  });

  useEffect(() => {
    rumDownloader.fetch({
      actionId,
      applicationFilter,
      columnsState,
      date,
      eventType,
      facetRegexState,
      idSearch,
      rumTableSortState,
      selectedFacetRangeByNameState,
      selectedFacetValuesByNameState,
      viewId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DownloadModal
      downloader={rumDownloader}
      modals={modals}
      title="rumEvents"
    />
  );
};

export default RumDownloadModal;
