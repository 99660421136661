import { Dispatch, SetStateAction } from 'react';
import {
  DateSelection,
  FunctionParamsProps,
  FunctionProps,
  LogsMetricQueryProps,
  QueryLangType,
} from 'types';
import {
  getFunctionParams,
  getForecastSeasonalParamsForKfuseQl,
  getLogsFunctionNameParams,
  calcAgileRobustAnomalyTimeRange,
  agileRobustSeasonalParams,
  hasQueryAdvanceFunctionMultiple,
} from 'utils';
import { KfuseQlFunction } from 'utils/KfuseqlBuilder/types';

import useLogsMetricsQueryData from './useLogsMetricsQueryData';

const ANOMALIES_WITH_MIN_TIME_REQUIRED = ['robust', 'agile-robust'];
const checkMinTimeRequiredForAnomalies = (
  func: FunctionProps,
): { isMinTimeRequired: boolean; seasonality: string } => {
  if (!func || func.name !== KfuseQlFunction.ANOMALIES)
    return { isMinTimeRequired: false, seasonality: '' };

  let isMinTimeRequired = false;
  let seasonality = '';
  func.params.map((p) => {
    if (
      p.name === 'anomaly' &&
      ANOMALIES_WITH_MIN_TIME_REQUIRED.includes(p.value)
    ) {
      isMinTimeRequired = true;
    }
    if (isMinTimeRequired && p.name === 'seasonality') {
      seasonality = p.value;
    }
    if (isMinTimeRequired && p.name === 'anomaly' && p.value === 'robust') {
      seasonality = '2'; // default to daily seasonality
    }
  });
  return { isMinTimeRequired, seasonality };
};

export const useLogsMetricsQueryFunction = ({
  date,
  logsMetricsQueryData,
  setQueries,
  setDate,
  userActionRef,
}: {
  date: DateSelection;
  logsMetricsQueryData: ReturnType<typeof useLogsMetricsQueryData>;
  setQueries: Dispatch<SetStateAction<LogsMetricQueryProps[]>>;
  setDate: (date: DateSelection) => void;
  userActionRef: React.MutableRefObject<{
    ignoreDateChangeReload: boolean;
  }>;
}) => {
  const { loadLogqlQuery, handleLoadingLogsChartOnDataChange } =
    logsMetricsQueryData;

  const addFunction = (queryIndex: number, functionName: string) => {
    setQueries((prevQueries) => {
      const newQueries = [...prevQueries];
      const { functions } = newQueries[queryIndex];
      const step = newQueries[queryIndex].step;
      const newFunctions = [...functions];
      const functionParams = getLogsFunctionNameParams(functionName, step);
      const newFunction = { name: functionName, params: functionParams };
      const hasAdvanceFunc = hasQueryAdvanceFunctionMultiple(
        newQueries,
        QueryLangType.KFUSEQL,
      );
      if (hasAdvanceFunc) {
        newFunctions.splice(newFunctions.length - 1, 0, newFunction);
      } else {
        newFunctions.push(newFunction);
      }
      newQueries[queryIndex].functions = newFunctions;
      loadLogqlQuery(newQueries[queryIndex]);
      return newQueries;
    });
  };

  const enforceMinTimeRangeForAnomalies = (func: FunctionProps) => {
    const { isMinTimeRequired, seasonality } =
      checkMinTimeRequiredForAnomalies(func);
    const { agileRobustAnomalyDate } = calcAgileRobustAnomalyTimeRange(
      seasonality,
      date,
    );

    if (!isMinTimeRequired || !setDate) return false;
    userActionRef.current.ignoreDateChangeReload = true;
    setDate(agileRobustAnomalyDate);
    return true;
  };

  const updateFunction = (
    queryIndex: number,
    fnIndex: number,
    paramIndex: number,
    value: string,
  ) => {
    setQueries((prevQueries) => {
      const newQueries = [...prevQueries];
      const queryStep = newQueries[queryIndex].step;
      const { functions } = newQueries[queryIndex];
      const newFunctions = [...functions];
      const newParams = [...newFunctions[fnIndex].params];
      newFunctions[fnIndex].params[paramIndex].value = value;

      let reloadedByDate = false;
      if (newFunctions[fnIndex].name === 'forecast') {
        const forecastParams = updatedParamsOnForecastChange(newParams, value);
        newFunctions[fnIndex].params = forecastParams;
      }

      if (newFunctions[fnIndex].name === 'anomalies' && paramIndex === 0) {
        const anomalyParams = updatedParamsOnAnomalyChange(
          newParams,
          queryStep,
          value,
        );
        newFunctions[fnIndex].params = anomalyParams;
      }

      const paramName = newParams[paramIndex].name;
      if (
        newFunctions[fnIndex].name === 'anomalies' &&
        (paramName === 'anomaly' || paramName === 'seasonality')
      ) {
        reloadedByDate = enforceMinTimeRangeForAnomalies(newFunctions[fnIndex]);
      }

      if (!reloadedByDate) {
        loadLogqlQuery(newQueries[queryIndex]);
      } else {
        handleLoadingLogsChartOnDataChange({
          date,
          formulas: [],
          queries: newQueries,
        });
      }
      return newQueries;
    });
  };

  const updatedParamsOnAnomalyChange = (
    newParams: FunctionParamsProps[],
    queryStep: string,
    value: any,
  ): FunctionParamsProps[] => {
    if (value === 'agile') {
      newParams = newParams.filter(
        (item) => item.name !== 'window' && item.name !== 'seasonality',
      );
    }

    if (value === 'agile-robust') {
      newParams = newParams.filter((item) => item.name !== 'window');
      newParams.splice(1, 0, ...agileRobustSeasonalParams);
    }

    if (value === 'basic' || value === 'robust') {
      newParams = newParams.filter((item) => item.name !== 'seasonality');
    }

    const isWindowParamExist = newParams.find(
      (param) => param.name === 'window',
    );

    if (!isWindowParamExist && value !== 'agile' && value !== 'agile-robust') {
      const anomalyParam = getFunctionParams('anomalies');
      const window = anomalyParam.find((param) => param.name === 'window');
      window.value = queryStep;
      // insert window param at index 1
      newParams.splice(1, 0, window);
    }

    if (value === 'robust') {
      return newParams;
    }

    return newParams.filter((param) => param.name !== 'model');
  };

  const updatedParamsOnForecastChange = (
    newParams: FunctionParamsProps[],
    value: string,
  ) => {
    if (value === 'seasonal') {
      newParams.push(...getForecastSeasonalParamsForKfuseQl());
    }

    if (value === 'linear') {
      newParams = newParams.filter((param) => param.name !== 'seasonality');
    }
    return newParams;
  };

  const removeFunction = (queryIndex: number, fnIndex: number) => {
    setQueries((prevQueries) => {
      const newQueries = [...prevQueries];
      newQueries[queryIndex].functions.splice(fnIndex, 1);
      loadLogqlQuery(newQueries[queryIndex]);
      return newQueries;
    });
  };

  return { addFunction, updateFunction, removeFunction };
};

export default useLogsMetricsQueryFunction;
