import React from 'react';
import { PaginatorServerSide, Table, TableColumn, TableRow } from 'components';
import { Column as FlexColumn } from 'components/layouts/Flex';
import { Box } from 'components/layouts/Box';

import { entityMappedToGraphQL } from '../utils';
import { useKubernetesController } from '../KubernetesController';
import { generatePath, useParams } from 'react-router-dom';
import KubernetesRightSideBar from '../KubernetesRightSideBar';
import { EntityTypes } from '../types';
import { Icon } from 'components';

type KubernetesTableProps<T> = {
  count: number;
  columns: Array<TableColumn<T>>;
  rows: Array<T>;
};

export default function KubernetesTable<T>({
  count,
  columns,
  rows,
}: KubernetesTableProps<T>) {
  const {
    limit,
    setLimit,
    page,
    setPage,
    kubesSort,
    setKubesSort,
    navigate,
    entitiesType,
  } = useKubernetesController();
  const mappedEntity = entityMappedToGraphQL(entitiesType);

  const idFor = (row) =>
    entitiesType === EntityTypes.Cluster
      ? row[mappedEntity].id
      : row[mappedEntity].metadata.uid;
  const activeKubeParam = useParams()['activeKube'];
  const activeKube = (typeof activeKubeParam === 'string' ? rows : []).find(
    (row) => {
      return idFor(row) === activeKubeParam;
    },
  );

  function onRowClick({ row }) {
    navigate(({ location, params, pathTemplate }) => {
      return {
        ...location,

        pathname: generatePath(pathTemplate, {
          ...params,

          activeKube: idFor(row),
        }),
      };
    });
  }

  return (
    <FlexColumn align="end">
      {activeKube && <KubernetesRightSideBar activeKube={activeKube} />}

      <Box className="overflow-scroll" maxWidth="full">
        <Table
          dataTestId="kubernetes-table"
          className="border-x border-t"
          columns={columns}
          isSortingEnabled
          rows={rows ?? []}
          renderHeader={(col) => {
            return (
              <Box px="2" py="1" asChild>
                <div className="table--bordered table--bordered-cells flex">
                  {col.column.label}
                </div>
              </Box>
            );
          }}
          renderRow={(props: any, index: number) => {
            if (!props.row[mappedEntity]) return null;
            const key =
              props.row[mappedEntity]?.id ??
              props.row[mappedEntity]?.metadata.uid ??
              index;

            return (
              <TableRow
                {...props}
                key={key}
                className="border"
                onRowClickHandler={(row) => () => onRowClick({ row })}
              />
            );
          }}
        />
      </Box>
      <PaginatorServerSide
        paginator={{
          totalNumberOfRows: count,
          numberOfRowsPerPage: limit,
          get maxNumberOfPages(): number {
            return Math.ceil(this.totalNumberOfRows / this.numberOfRowsPerPage);
          },
          pageNumber: page,
          setNumberOfRowsPerPage: setLimit,
          setActivePageIndex: setPage,
          showNextPage() {
            setPage(page + 1);
          },
          showPrevPage() {
            setPage(page - 1);
          },
        }}
      />
    </FlexColumn>
  );
}
