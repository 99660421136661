import { Icon } from 'components';
import React, { useMemo } from 'react';
import { useRumReplayStateContext } from './RumReplayStateContext';
import RumReplayBarHashes from './RumReplayBarHashes';
import { RumEvent, Session, SessionMetadata } from './types';
import { getBarLeftAndWidth, getInactivePeriods } from './utils';

type Args = {
  events: RumEvent[];
  session: SessionMetadata;
};

const getTabEventsByIndex = ({ events, session }: Args) => {
  const result: Record<number, RumEvent[]> = {};
  const { tabs } = session;

  events.forEach((event) => {
    const { time } = event;
    tabs.forEach((tab, i) => {
      if (time >= tab.startTimeUnixMs && time < tab.endTimeUnixMs) {
        if (!result[i]) {
          result[i] = [];
        }

        result[i].push(event);
      }
    });
  });

  return result;
};

type Props = {
  session: SessionMetadata;
};

const RumReplaySessionController = ({ session }: Props) => {
  const {
    actions,
    activeTabIndex,
    currentTimeState,
    hoverPercentState,
    segmentFetcher,
    shownEvents,
    shownRumEventTypesState,
  } = useRumReplayStateContext();
  const [currentTime] = currentTimeState;
  const [hoverPercent] = hoverPercentState;
  const [shownRumEventTypes] = shownRumEventTypesState;
  const { onBarClick, onBarHover } = actions;

  const { startTimeUnixMs, endTimeUnixMs, tabs } = session;
  const totalMs = endTimeUnixMs - startTimeUnixMs;

  const tabEventsByIndex = useMemo(
    () => getTabEventsByIndex({ events: shownEvents, session }),
    [shownEvents, session],
  );

  const { eventsBySegmentKeyByTabId } = segmentFetcher;

  const tabInactivePeriods = useMemo(
    () =>
      tabs.map((tab) => {
        const eventsBySegmentKey = eventsBySegmentKeyByTabId[tab.tabId] || {};
        const rrwebEvents = tab.segments.reduce(
          (arr, segment) => [
            ...arr,
            ...(eventsBySegmentKey[segment.segmentKey] || []),
          ],
          [],
        );
        return getInactivePeriods({ rrwebEvents });
      }),
    [eventsBySegmentKeyByTabId, tabs],
  );

  return (
    <div
      className="rum-replay__session-controller"
      onClick={onBarClick}
      onMouseEnter={onBarHover}
      onMouseMove={onBarHover}
    >
      {tabs.map((tab, i) => {
        const relativeStartMs = tab.startTimeUnixMs - startTimeUnixMs;
        const left = `${(relativeStartMs / totalMs) * 100}%`;
        const width = `${((tab.endTimeUnixMs - tab.startTimeUnixMs) / totalMs) * 100}%`;

        const inactivePeriods = tabInactivePeriods[i];
        const tabEvents = tabEventsByIndex[i] || [];

        return (
          <div className="rum-replay__session-controller__lane" key={i}>
            <div className="rum-replay__session-controller__lane__bar">
              <div
                className="rum-replay__session-controller__lane__bar__fill"
                style={{ left, width }}
              >
                {activeTabIndex === i ? (
                  <div className="rum-replay__session-controller__lane__bar__big-arrow">
                    <Icon icon="arrow-big-right" />
                  </div>
                ) : null}
              </div>
              <div className="rum-replay__bar__inactivity-periods">
                {inactivePeriods.map((inactivePeriod, i) => (
                  <div
                    className="rum-replay__bar__inactivity-periods__item"
                    key={i}
                    style={getBarLeftAndWidth({
                      ...inactivePeriod,
                      session,
                    })}
                  />
                ))}
              </div>
              <RumReplayBarHashes
                events={tabEvents}
                shownRumEventTypes={shownRumEventTypes}
                session={session}
              />
            </div>
          </div>
        );
      })}
      <div
        className="rum-replay__session-controller__elapsed"
        style={{ width: `${(currentTime / totalMs) * 100}%` }}
      >
        <div className="rum-replay__session-controller__elapsed__bg" />
      </div>
      {hoverPercent ? (
        <div
          className="rum-replay__session-controller__hover"
          style={{ width: `${hoverPercent * 100}%` }}
        />
      ) : null}
    </div>
  );
};

export default RumReplaySessionController;
