import { AutocompleteOption } from 'components';
import { parseMetricLabelQuery } from 'utils';

import { CardinalityQueryProps, CardinalityRowProps } from './types';

export const builderCardinalityMatcher = (
  labels: string[],
  labelIndex: number,
): string => {
  const matcherStr: string[] = [];

  labels.map((lb, index) => {
    if (index > labelIndex) {
      return;
    }
    const { label, value, operator } = parseMetricLabelQuery(lb);

    if (label && value && operator) {
      return matcherStr.push(`${label}${operator}"${value}"`);
    }
  });

  const joined = matcherStr.join(',');
  return `{${joined}}`;
};

export const sortCardinalityRows = ({
  rows,
  cardinalityQuery,
}: {
  rows: {
    overallCardinality: number;
    labelNames?: { [key: string]: number };
    metricNames?: { [key: string]: number };
  };
  cardinalityQuery: CardinalityQueryProps;
}): {
  cardinalityRows: CardinalityRowProps[];
  sortedKeys: string[];
} => {
  const { limit, type } = cardinalityQuery;
  const objName = type === 'label' ? 'labelNames' : 'metricNames';
  const labelNamesKeys = rows?.[objName] ? Object.keys(rows[objName]) : [];
  const sortedData = labelNamesKeys.sort((a, b) => {
    return limit.direction === 'topk'
      ? rows[objName][b] - rows[objName][a]
      : rows[objName][a] - rows[objName][b];
  });

  const sortedKeys = sortedData.slice(0, Number(limit.count));
  const cardinalityRows = sortedKeys.map((label) => {
    return {
      label,
      [limit.byKey]: rows[objName][label],
    };
  });

  return { cardinalityRows, sortedKeys };
};

export const convertSeriesLabelsMapToOptions = (data: {
  overallCardinality: number;
  labelNames?: { [key: string]: number };
}): AutocompleteOption[] => {
  const seriesCardinalityKeys = Object.keys(data.labelNames || {});
  const options = seriesCardinalityKeys.map((metric) => ({
    label: metric,
    value: metric,
  }));
  return options;
};

export const cardinalityMatcher = (
  cardinalityQuery: CardinalityQueryProps,
  labelIndex?: number,
): {
  labelMatcher: string;
  metricMatcher: string;
} => {
  const { labels, metric, type } = cardinalityQuery;
  const index = typeof labelIndex === 'number' ? labelIndex : labels.length - 1;

  const labelMatcher = builderCardinalityMatcher(labels, index);
  const metricMatcher = `${metric || ''}${labelMatcher}`;

  return { labelMatcher, metricMatcher };
};
