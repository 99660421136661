import { AutocompleteOption } from 'components';
import { Band, Series } from 'uplot';
import { DataFrameMeta, DataFrame } from 'utils/DataTransformer';

import { DateSelection } from './DateSelection';
import { PrometheusDataset, PrometheusMetric } from './prometheus';

export type VectorTypes = 'instant' | 'range';

export type ExplorerQueryProps = {
  isValid?: boolean;
  isActive?: boolean;
  functions?: FunctionProps[];
  labels: string[];
  legendFormat?: string;
  metric: string;
  promql?: string;
  queryKey: string;
  series: string[];
  showInput?: boolean;
  steps?: number;
};

export type FunctionProps = {
  name: string;
  params?: FunctionParamsProps[];
  vectorType?: VectorTypes;
};

export type FunctionParamsProps = {
  default: any;
  options?: AutocompleteOption[];
  min?: number;
  max?: number;
  name: string;
  regex?: RegExp;
  required?: boolean;
  step?: number;
  type: 'text' | 'select' | 'multi-select' | 'number';
  value: any;
  validator?: (value: string) => string;
};

export type FormulaProps = {
  expression: string;
  isValid?: boolean;
  isActive?: boolean;
  queryKey: string;
};

export type FunctionNamesProps = {
  category: string;
  description: string;
  expression: string;
  name: string;
  shortName: string;
  vectorType: VectorTypes;
  imageUrl: string;
  algorithm: string;
};

export type ChartProps = {
  chartId: string;
  formulas: FormulaProps[];
  isLoading: boolean;
  isTitleEditing: boolean;
  queries: ExplorerQueryProps[];
  title: string;
};

export type QueryDimensionProps = {
  label: string;
  operator: string;
  value: string;
};

export type MetricsQueryItemProps = {
  formulas?: FormulaProps[];
  queries: ExplorerQueryProps[];
  queryIndex: number;
  steps?: number[];
  type: 'query' | 'formula';
  returnType?: 'string' | 'array';
};

export type CombinedQueryProps = {
  queryKey: string;
  isActive: boolean;
};

export type MetricsQueriesDataTimeseriesProps = {
  data: number[][];
  maxValue: number;
  minValue?: number;
  series: Series[];
};

export type MetricsQueriesDataTimeseriesCombinedProps =
  MetricsQueriesDataTimeseriesProps & {
    bands?: Band[];
    isLoading?: boolean;
  };

export type MetricsQueriesDataProps<T> = {
  [key: string]: {
    isLoading: boolean;
    data: T;
    labels?: string[];
    type?: 'instant' | 'range';
    meta: DataFrameMeta;
  };
};

export type TimeseriesDataProps = MetricsQueriesDataProps['query']['data'] & {
  isLoading?: boolean;
  bands?: Band[];
};

export enum AnomalyAlgorithmType {
  BASIC = 'basic',
  AGILE = 'agile',
  ROBUST = 'robust',
  AGILE_ROBUST = 'agile-robust',
}

export type MetricsTransformSeriesProps = {
  idx: number;
  promIndex: number;
  metric: { [key: string]: string };
  maxUniqueLabels: number;
};

export type MetricsTransformSeriesFuncProps = (
  val: MetricsTransformSeriesProps,
) => Series;

export type MetricsDatasetTransformProps = {
  datasets: PrometheusDataset[];
  date: DateSelection;
  defaultStep: number;
  steps: number[];
  seriesFormatter?: MetricsTransformSeriesFuncProps;
};

export type FunctionCategory = string;
export type FunctionName = string;
export type FunctionInfo = FunctionNamesProps & {
  algorithm?: string;
  tolerance?: string;
};

export type FunctionInfoByFunctionNameThenFunctionCategory = Record<
  FunctionCategory,
  Record<FunctionName, FunctionInfo>
>;

export type MetricsDatasetInstantProps = {
  data: {
    color?: string;
    count: number;
    getMetric?: () => PrometheusMetric;
    getPromIndex?: () => number;
    getValues?: () => PrometheusDataset['values'] | PrometheusDataset['value'];
    getValue?: () => number;
    label?: string;
    name?: string;
    size?: number;
    value?: number;
  }[];
  labels?: string[];
  maxValue?: number;
  minValue?: number;
};

export type PromqlWithMetaProps = {
  meta: DataFrame['meta'];
  promql: string | string[];
  isAnomaly?: boolean;
  isForecast?: boolean;
  forecastSeasonality?: string;
  queryType: 'query' | 'formula';
};
