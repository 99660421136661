import React from 'react';
import { IoIosWarning } from 'react-icons/io';
import classnames from 'classnames';

type Props = {
  message: string;
  className?: string;
  fitContent?: boolean;
};

const ErrorMessage = ({ message, className, fitContent }: Props) => {
  if (!message) return null;

  return (
    <div
      className={classnames({
        'w-full': !fitContent,
        'w-fit': fitContent,
        'flex gap-[4px]': true,
        [className]: className,
      })}
    >
      <IoIosWarning
        className="overlay-message__icon-and-message__icon"
        size={16}
      />
      <div className="text-red-500">{message}</div>
    </div>
  );
};

export default ErrorMessage;
