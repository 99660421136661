import classNames from 'classnames';
import { AccordionV2 } from 'components';
import React, { Children, cloneElement, ReactElement, ReactNode } from 'react';
import { SiKubernetes as KubernetesIcon } from 'react-icons/si';
import { FiChevronDown as ChevronDownIcon } from 'react-icons/fi';

import { NavLink, useLocation } from 'react-router-dom';
import {
  Row as FlexRow,
  Column as FlexColumn,
  Item as FlexItem,
} from 'components/layouts/Flex';

import * as styles from './KubernetesLeftSidebar.module.scss';

function KubernetesAccordion({
  children,
}: {
  children: ReactElement | ReactElement[];
}) {
  return (
    <div className={classNames(styles.root, 'ml-4 mr-2')}>
      <AccordionV2.Root className={styles.accordionOne}>
        <AccordionV2.Item defaultOpen={true}>
          <AccordionV2.Header className={styles.accordionOneHeader}>
            <AccordionV2.Trigger className={styles.accordionOneHeaderTrigger}>
              <div className="flex flex--align-items-center gap-2">
                <KubernetesIcon />
                Kubernetes
              </div>
              <ChevronDownIcon
                className={styles.headerTriggerChevron}
                aria-hidden
              />
            </AccordionV2.Trigger>
          </AccordionV2.Header>
          <AccordionV2.Content
            className={classNames(
              styles.accordionOneContent,
              'rounded flex flex-col items-stretch',
            )}
          >
            {Children.map(children, (child) => {
              if (child.type === KubernetesNavLink) {
                return cloneElement(child, {
                  className({ isActive }: { isActive: boolean }) {
                    return classNames(
                      'flex flex--align-items-center gap-2 ',
                      styles.accordionOneItem,
                      {
                        [styles.activeEntity]: isActive,
                      },
                    );
                  },
                  children: [
                    <KubernetesIcon key="icon" />,
                    ...Children.toArray(child.props.children),
                  ],
                });
              }

              return child;
            })}
          </AccordionV2.Content>
        </AccordionV2.Item>
      </AccordionV2.Root>
    </div>
  );
}

function KubernetesAccordionSection({
  heading,
  children,
}: {
  heading: string;
  children: ReactNode;
}) {
  return (
    <AccordionV2.Root>
      <AccordionV2.Item defaultOpen={false}>
        <AccordionV2.Header className={styles['accordionTwoHeader']}>
          <AccordionV2.Trigger className={styles['accordionTwoHeaderTrigger']}>
            <div className="flex flex--align-items-center gap-2">
              <KubernetesIcon />
              {heading}
            </div>
            <ChevronDownIcon
              className={styles.headerTriggerChevron}
              aria-hidden
            />
          </AccordionV2.Trigger>
        </AccordionV2.Header>
        <AccordionV2.Content className={classNames(styles.accordionTwoContent)}>
          <FlexRow align="stretch">
            <div className={styles.accordionTwoStroke} />
            <FlexItem grow asChild>
              <FlexColumn gap="0">
                {Children.map(children, (child: JSX.Element) => {
                  return cloneElement(child, {
                    className({ isActive }: { isActive: boolean }) {
                      return classNames(styles.accordionTwoItem, {
                        [styles.activeEntity]: isActive,
                      });
                    },
                  });
                })}
              </FlexColumn>
            </FlexItem>
          </FlexRow>
        </AccordionV2.Content>
      </AccordionV2.Item>
    </AccordionV2.Root>
  );
}

function KubernetesNavLink({
  className,
  to,
  children,
}: {
  className?: string;
  to: string;
  children: ReactNode;
}) {
  const location = useLocation();

  const search = new URLSearchParams(location.search);

  search.delete('page');
  search.delete('limit');
  search.delete('kubesSort');

  return (
    <NavLink
      to={{
        ...location,

        pathname: to,
        search: String(search),
      }}
      className={className}
    >
      {children}
    </NavLink>
  );
}

const KubernetesLeftSidebarResources = (): ReactElement => {
  const location = useLocation();

  const search = new URLSearchParams(location.search);

  search.delete('page');
  search.delete('limit');
  search.delete('kubesSort');

  return (
    <KubernetesAccordion>
      <KubernetesNavLink to="/kubernetes/pod">Pods</KubernetesNavLink>
      <KubernetesNavLink to="/kubernetes/cluster">Clusters</KubernetesNavLink>
      <KubernetesNavLink to="/kubernetes/namespace">
        Namespaces
      </KubernetesNavLink>
      <KubernetesNavLink to="/kubernetes/node">Nodes</KubernetesNavLink>
      <KubernetesAccordionSection heading="Workloads">
        <KubernetesNavLink to="/kubernetes/deployment">
          Deployment
        </KubernetesNavLink>
        <KubernetesNavLink to="/kubernetes/replica-set">
          Replica Sets
        </KubernetesNavLink>
        <KubernetesNavLink to="/kubernetes/job">Jobs</KubernetesNavLink>
        <KubernetesNavLink to="/kubernetes/cron-job">
          Cron Jobs
        </KubernetesNavLink>
        <KubernetesNavLink to="/kubernetes/daemon-set">
          Daemon Sets
        </KubernetesNavLink>
        <KubernetesNavLink to="/kubernetes/stateful-set">
          Stateful Sets
        </KubernetesNavLink>
      </KubernetesAccordionSection>
      <KubernetesAccordionSection heading="Network">
        <KubernetesNavLink to="/kubernetes/service">Services</KubernetesNavLink>
        <KubernetesNavLink to="/kubernetes/ingress">
          Ingresses
        </KubernetesNavLink>
      </KubernetesAccordionSection>
      <KubernetesAccordionSection heading="Storage">
        <KubernetesNavLink to="/kubernetes/persistent-volume-claim">
          Persistent Volume Claims
        </KubernetesNavLink>
        <KubernetesNavLink to="/kubernetes/persistent-volume">
          Persistent Volumes
        </KubernetesNavLink>
      </KubernetesAccordionSection>
      <KubernetesAccordionSection heading="Access Control">
        <KubernetesNavLink to="/kubernetes/role">Roles</KubernetesNavLink>
        <KubernetesNavLink to="/kubernetes/role-binding">
          Role Bindings
        </KubernetesNavLink>
        <KubernetesNavLink to="/kubernetes/cluster-role">
          Cluster Roles
        </KubernetesNavLink>
        <KubernetesNavLink to="/kubernetes/cluster-role-binding">
          Cluster Role Bindings
        </KubernetesNavLink>
        <KubernetesNavLink to="/kubernetes/service-account">
          Service Accounts
        </KubernetesNavLink>
      </KubernetesAccordionSection>
    </KubernetesAccordion>
  );
};

export default KubernetesLeftSidebarResources;
