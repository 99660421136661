import { fetchGrafanaApi } from 'requests';

import { SilencedInstanceProps } from '../types';

const getSilencedInstanceByFilter = async (
  filters: string[],
): Promise<SilencedInstanceProps[]> => {
  const filterString = filters.map((filter) => `&filter=${filter}`).join('');
  const result = await fetchGrafanaApi(
    `grafana/api/alertmanager/grafana/api/v2/alerts?${filterString}`,
  );

  return result;
};

export default getSilencedInstanceByFilter;
