import { FormulaState, SearchState } from 'hooks';
import {
  DashboardPanelProps,
  DashboardPanelTargetsProps,
  DashboardPanelType,
} from 'types/Dashboard';
import { SpanFilter } from 'types/SpanFilter';
import { getPanelFieldConfig } from './panelManipulation';

export const ALLOW_ONLY_ONE_ACTIVE_SEARCH = [
  DashboardPanelType.TOP_LIST,
  DashboardPanelType.TABLE,
  DashboardPanelType.PIECHART,
  DashboardPanelType.TIMESERIES,
];

const getPanelQueryForTrace = ({
  formulas,
  queries,
  prevExpr,
}: {
  formulas: FormulaState[];
  queries: SearchState[];
  prevExpr: string;
}): { expr: string; refId: string } => {
  const activeQueries = queries.filter((query) => query.isActive);
  const activeFormulas = formulas.filter((formula) => formula.isActive);

  const parsedExpr = JSON.parse(prevExpr || '{}');

  const outputQuery: {
    customerFilter?: { key: string; value: string };
    query?: SearchState;
    formula?: FormulaState;
    queries?: SearchState[];
    spanFilter?: SpanFilter;
  } = { ...parsedExpr };

  let refId = 'a';
  if (activeFormulas.length > 0) {
    outputQuery.formula = activeFormulas[0];
    outputQuery.queries = queries;
    refId = activeFormulas[0].queryKey;
  }

  if (activeQueries.length > 0) {
    outputQuery.query = activeQueries[0];
    refId = activeQueries[0].queryKey;
  }

  return { expr: JSON.stringify(outputQuery), refId };
};

export const getEditedPanelForTrace = ({
  editedPanel,
  formulas,
  queries,
}: {
  editedPanel: DashboardPanelProps;
  formulas: FormulaState[];
  queries: SearchState[];
}): DashboardPanelProps => {
  const panelType = editedPanel.type;
  const prevExpr = editedPanel.targets[0].expr;
  const { expr, refId } = getPanelQueryForTrace({
    formulas,
    queries,
    prevExpr,
  });

  const targets: DashboardPanelTargetsProps[] = [];
  const range = panelType === DashboardPanelType.TIMESERIES ? true : false;
  const fieldConfig = getPanelFieldConfig({
    prevFieldConfig: editedPanel.fieldConfig,
  });
  const datasource = { type: 'tempo', uid: '' };

  const commonTargetProps = {
    datasource,
    editorMode: 'code',
    range,
    instant: range ? false : true,
    refId,
    expr,
  };
  targets.push(commonTargetProps);

  const options: DashboardPanelProps['options'] = {
    legend: { calcs: [], displayMode: 'list', placement: 'bottom' },
  };

  const panel = {
    ...editedPanel,
    targets,
    fieldConfig,
    options,
    datasource,
    isEdited: true,
  };

  return panel;
};

export const getTracesPanelExprToQueryAndFormula = (
  panel: DashboardPanelProps,
): {
  queries: SearchState[];
  formulas: FormulaState[];
} => {
  const queries: SearchState[] = [];
  const formulas: FormulaState[] = [];
  if (!panel?.targets[0]?.expr) {
    return { queries: undefined, formulas: undefined };
  }
  const expr = panel.targets[0].expr;
  const parsedExpr = JSON.parse(expr);

  if (parsedExpr.query) {
    queries.push(parsedExpr.query);
  }

  if (parsedExpr.formula) {
    formulas.push(parsedExpr.formula);
    queries.push(...(parsedExpr.queries || []));
  }

  if (parsedExpr.queries && !parsedExpr.query) {
    queries.push(...parsedExpr.queries);
  }

  if (parsedExpr.formulas && !parsedExpr.formula) {
    formulas.push(...(parsedExpr.formulas || []));
  }

  return { queries, formulas };
};
