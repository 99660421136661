import React from 'react';
import { CursorTooltip } from 'components';
import { formatDurationNs } from 'utils';
import {
  RumActionEventData,
  RumErrorEventData,
  RumEventType,
  RumLongTaskEventData,
  RumResourceEventData,
} from '../types';
import { convertMsToNs, formatDurationFromNsString } from './utils';
import { FormattedRumEventForFlameGraph } from './types';

const getTooltipLines = (
  event: FormattedRumEventForFlameGraph,
  minStartTimeNs = 0,
) => {
  const { duration, eventType, size } = event;
  const formattedDuration = formatDurationNs(duration, 1, 2);

  const { data, time: eventTime } = event.originalEvent;

  switch (eventType) {
    case RumEventType.RESOURCE: {
      const resourceData = data as RumResourceEventData;
      const firstByte = formatDurationFromNsString(
        resourceData['resource.first_byte.duration'],
      );
      const download = formatDurationFromNsString(
        resourceData['resource.download.duration'],
      );
      const at = convertMsToNs(eventTime) - minStartTimeNs;
      const formattedAtTime = formatDurationNs(at, 1, 2);

      return [
        <div key="type">Type: {resourceData['resource.type']}</div>,
        <div key="duration">Duration: {formattedDuration}</div>,
        <div key="size">Size: {size}</div>,
        <div key="at">At: {formattedAtTime}</div>,
        <div key="firstByte">First Byte: {firstByte}</div>,
        <div key="download">Download: {download}</div>,
        <div key="url" className="rum-flamegraph__cursor-tooltip__url">
          URL: {resourceData['resource.url']}
        </div>,
      ];
    }

    case RumEventType.ERROR: {
      const errorData = data as RumErrorEventData;
      const at = convertMsToNs(eventTime) - minStartTimeNs;
      const formattedAtTime = formatDurationNs(at, 1, 2);

      return [
        <div key="message">Error: {errorData['error.message']}</div>,
        <div key="source">Source: {errorData['error.source']}</div>,
        <div key="at">At: {formattedAtTime}</div>,
      ];
    }

    case RumEventType.LONGTASK: {
      const longTaskData = data as RumLongTaskEventData;
      const at = convertMsToNs(eventTime) - minStartTimeNs;
      const formattedAtTime = formatDurationNs(at, 1, 2);
      return [
        <div key="message">
          Tasks Blocking the main tread result in Delayed User Interaction
        </div>,
        <div key="time">At: {formattedAtTime}</div>,
        <div key="duration">Duration: {formattedDuration}</div>,
        <div key="id">Id: {longTaskData['long_task.id']}</div>,
      ];
    }

    case RumEventType.ACTION: {
      const actionData = data as RumActionEventData;
      const at = convertMsToNs(eventTime) - minStartTimeNs;
      const formattedAtTime = formatDurationNs(at, 1, 2);
      const duration = formatDurationFromNsString(
        actionData['action.loading_time'],
      );

      return [
        <div key="action">
          Action:{' '}
          {`${actionData['action.type']} on
          ${actionData['action.target.name']}`}
        </div>,
        <div key="time">At: {formattedAtTime}</div>,
        <div key="duration">Duration: {formattedDuration}</div>,
      ];
    }

    default:
      return [];
  }
};

const TooltipContent = ({ lines }: { lines: (string | JSX.Element)[] }) => (
  <div className="flex flex-wrap">
    {lines.map((line, index) => (
      <div className="flex items-center justify-center m-2" key={index}>
        {line}
      </div>
    ))}
  </div>
);

type Props = {
  tooltipEvent: FormattedRumEventForFlameGraph;
  minStartTimeNs: number;
};

const RumFlameGraphTooltip = ({ tooltipEvent, minStartTimeNs }: Props) => {
  if (!tooltipEvent || !tooltipEvent.originalEvent) return null;

  const tooltipLines = getTooltipLines(tooltipEvent, minStartTimeNs);

  return (
    <CursorTooltip>
      <div className="rum-flamegraph__cursor-tooltip">
        <TooltipContent lines={tooltipLines} />
      </div>
    </CursorTooltip>
  );
};

export default RumFlameGraphTooltip;
