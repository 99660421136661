import { promqlQueryRangeV3 } from 'requests';
import { ChartQueryArgs, DashboardPanelType, QueryDataProps } from 'types';
import {
  anomalyDataTransformer,
  calculateRateIntervalVariableValue,
  combineRangeQueryData,
  DataFrame,
  formatLegendMultiple,
  legendCustomColorTimeseries,
  legendFormatCustomTimeseries,
  metricsDataTransformer,
  sortAndLimitTimeseries,
} from 'utils';

const getAnomalyPromqlSarima = (promql: string) => {
  return `sarima(${promql}, 2, 1, 2, 0, 0, 0, 0, 1, 3)`;
};

const getNextCharByIndex = (index: number) => {
  return String.fromCharCode(97 + index);
};

const handleAnomalyData = (
  data: [QueryDataProps['query']['range'], QueryDataProps['query']['range'][]],
  stepInMs: number,
) => {
  const [normalData, anomalyData] = data;
  const meta = {
    refId: getNextCharByIndex(0),
    step: stepInMs / 1000,
    type: DashboardPanelType.TIMESERIES,
  };

  const queryRangeData: QueryDataProps = {
    query_a_anomaly: { range: normalData, meta, isLoading: false },
    query_a_anomaly_upper: {
      range: anomalyData[0],
      meta,
      isLoading: false,
    },
    query_a_anomaly_lower: {
      range: anomalyData[1],
      meta,
      isLoading: false,
    },
  };

  const combinedAnomalyData = combineRangeQueryData({
    queries: [{ queryKey: 'a', isActive: true }],
    formulas: [],
    queryData: queryRangeData,
    combineTimestamp: false,
  });
  return combinedAnomalyData;
};

type QueryArgs = {
  rateIntervalMs: string;
  rateIntervalSeconds?: string;
  stepInMs: number;
};

const multipleQueryRangeWithLabels =
  (
    queries: (({
      rateIntervalMs,
      rateIntervalSeconds,
      stepInMs,
    }: QueryArgs) => string)[],
    legendFormats: (string | ((metric: Record<string, any>) => string))[][],
    addEncoding?: boolean,
  ) =>
  ({
    chart,
    date,
    instant,
    isAnomalyEnabled,
    width,
    parsedPromql,
  }: ChartQueryArgs) => {
    const { colorMap, enableAnomaly, chartType, useCeilInAdjustingTime } =
      chart;
    const { rateIntervalMs, rateIntervalSeconds, stepInMs } =
      calculateRateIntervalVariableValue(date, chartType);
    if (parsedPromql) {
      const promqls = queries.map((query) =>
        query({ rateIntervalMs, rateIntervalSeconds, stepInMs }),
      );
      return Promise.resolve({ promqls, stepInMs, legendFormats });
    }

    const queriesWithPromql = queries.map((query) =>
      query({ rateIntervalMs, rateIntervalSeconds, stepInMs }),
    );

    if (enableAnomaly && isAnomalyEnabled) {
      queriesWithPromql.forEach((promql) => {
        queriesWithPromql.push(getAnomalyPromqlSarima(promql));
      });
    }

    return Promise.all(
      queriesWithPromql.map((query, idx) => {
        const transformer = metricsDataTransformer();
        transformer.splice(1, 0, {
          id: 'sortAndLimitTimeseries',
          func: (dataFrame: DataFrame) =>
            sortAndLimitTimeseries(dataFrame, {
              direction: 'topk',
              count: undefined,
            }),
        });
        if (idx !== 0 && isAnomalyEnabled) {
          transformer.pop();
          transformer.push({
            id: 'anomalyBandTransformer',
            func: anomalyDataTransformer,
          });
        }
        transformer.splice(3, 0, {
          id: 'legendFormatCustomTimeseries',
          func: (dataFrame: DataFrame) =>
            legendFormatCustomTimeseries(dataFrame, (label) =>
              formatLegendMultiple(
                label,
                legendFormats[isAnomalyEnabled ? 0 : idx],
              ),
            ),
        });
        if (!isAnomalyEnabled) {
          transformer.push({
            id: 'legendCustomColorTimeseries',
            func: (rangeData: QueryDataProps['query']['range']) =>
              legendCustomColorTimeseries(rangeData, colorMap),
          });
        }
        return promqlQueryRangeV3({
          addEncoding,
          date,
          promqlQuery: query,
          useCeilInAdjustingTime,
          meta: {
            refId: getNextCharByIndex(idx),
            step: stepInMs / 1000,
            type: DashboardPanelType.TIMESERIES,
            legendFormat: legendFormats[isAnomalyEnabled ? 0 : idx]?.[0],
          },
          transformer,
        });
      }),
    ).then((data) => {
      const meta = {
        refId: getNextCharByIndex(0),
        step: stepInMs / 1000,
        type: DashboardPanelType.TIMESERIES,
      };

      if (isAnomalyEnabled && enableAnomaly) {
        return handleAnomalyData(data, stepInMs);
      }

      const queryData: QueryDataProps = {};
      const queriesBasic: { queryKey: string; isActive: boolean }[] = [];
      data.forEach((item, index) => {
        const refId = getNextCharByIndex(index);
        queryData[`query_${refId}`] = {
          range: item,
          meta: meta,
          isLoading: false,
        };
        queriesBasic.push({ queryKey: refId, isActive: true });
      });

      const combinedData = combineRangeQueryData({
        queries: queriesBasic,
        formulas: [],
        queryData: queryData,
        combineTimestamp: false,
      });

      return combinedData;
    });
  };

export default multipleQueryRangeWithLabels;
