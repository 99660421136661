import query from 'requests/query';

type Args = {
  displayName: string;
  facetGroup: string;
  newFacetGroup: string;
  newDisplayName: string;
};

const editLogsFavoriteFacet = async ({
  displayName,
  facetGroup,
  newFacetGroup,
  newDisplayName,
}: Args): Promise<Record<'editFavoriteFacet', void>> => {
  return query<void, 'editFavoriteFacet'>(`
    mutation {
      editFavoriteFacet (
        display_name: "${displayName}"
        facetGroup: "${facetGroup}"
        editInfo: {
            newGroup: "${newFacetGroup}",
            newDisplayName: "${newDisplayName}"
        }
      )
    }
  `);
};

export default editLogsFavoriteFacet;
