import { useSearchFormulas, useSearches } from 'hooks/useSearch';
import { useRequest, useTracesState } from 'hooks';
import React, { ReactElement, useMemo } from 'react';
import { DashboardPanelType, DateSelection, Service } from 'types';
import { getRange } from 'utils';

import TracesQuerySearch from './TracesQuerySearch';
import TracesSearchGrouperFormula from './TracesSearchGrouperFormula';
import TracesSearchQueryButtons from './TracesSearchQueryButtons';

const getLabelOptionLabel = (label: string) => {
  if (label === 'duration_ns') {
    return 'duration (ns)';
  }

  if (label === 'service_hash') {
    return 'service';
  }

  return label;
};

const getOptions = (traceLabelNamesRequest: ReturnType<typeof useRequest>) => {
  if (!traceLabelNamesRequest.result) {
    return { groupByOptions: [], measureOptions: [] };
  }

  const labelOptions = ['duration_ns', ...(traceLabelNamesRequest.result || [])]
    .sort()
    .map((label) => ({
      label: getLabelOptionLabel(label),
      value: label,
    }));

  const groupByOptions = [
    { label: 'Everything', value: '*' },
    ...labelOptions.filter((item) => item.value !== 'duration_ns'),
  ];
  const measureOptions = [{ label: 'All spans', value: null }, ...labelOptions];

  return {
    groupByOptions,
    measureOptions,
  };
};

const TracesQuerySearchAnalytics = ({
  allowOnlyOneActiveSearchTypes,
  colorsByServiceName,
  date,
  hideQueryButtons = false,
  placeholder,
  searches,
  searchesFormulas,
  serviceByHash,
  traceLabelNamesRequest,
  tracesState,
  tracesTab,
  runQueries,
  shouldSyncWithPageState = true,
}: {
  allowOnlyOneActiveSearchTypes?: DashboardPanelType[];
  colorsByServiceName: Record<string, string>;
  date: DateSelection;
  hideQueryButtons?: boolean;
  placeholder?: string;
  searches: ReturnType<typeof useSearches>;
  searchesFormulas: ReturnType<typeof useSearchFormulas>;
  serviceByHash: Record<string, Service>;
  traceLabelNamesRequest: ReturnType<typeof useRequest>;
  tracesState: ReturnType<typeof useTracesState>;
  tracesTab: DashboardPanelType;
  runQueries?: () => void;
  shouldSyncWithPageState?: boolean;
}): ReactElement => {
  const { groupByOptions, measureOptions } = useMemo(
    () => getOptions(traceLabelNamesRequest),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [traceLabelNamesRequest.result],
  );

  const labelNames: string[] = useMemo(
    () => (traceLabelNamesRequest.result || []).sort(),
    [traceLabelNamesRequest.result],
  );
  return (
    <>
      {searches.map((search, i) => (
        <TracesQuerySearch
          allowOnlyOneActiveSearchTypes={allowOnlyOneActiveSearchTypes}
          colorsByServiceName={colorsByServiceName}
          getRange={getRange}
          groupByOptions={groupByOptions}
          index={i}
          key={i}
          labelNames={labelNames}
          measureOptions={measureOptions}
          placeholder={placeholder}
          search={search}
          searches={searches}
          serviceByHash={serviceByHash}
          tracesTab={tracesTab}
          tracesState={tracesState}
          searchesFormulas={searchesFormulas}
          shouldSyncWithPageState={shouldSyncWithPageState}
        />
      ))}
      {searchesFormulas &&
        searchesFormulas.formulas.map((formula, i) => (
          <TracesSearchGrouperFormula
            allowOnlyOneActiveSearchTypes={allowOnlyOneActiveSearchTypes}
            key={i}
            date={date}
            formulaState={formula}
            searches={searches}
            tracesTab={tracesTab}
          />
        ))}
      {!hideQueryButtons && (
        <TracesSearchQueryButtons
          allowOnlyOneActiveSearchTypes={allowOnlyOneActiveSearchTypes}
          searches={searches}
          searchesFormulas={searchesFormulas}
          tracesTab={tracesTab}
          runQueries={runQueries}
        />
      )}
    </>
  );
};

export default TracesQuerySearchAnalytics;
