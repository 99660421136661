import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  InputWithValidatorV2,
  Loader,
  useModalsContext,
  useToaster,
} from 'components';
import { useRequest, useSearch } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { saveTraceMetric } from 'requests';
import {
  KeyExists,
  RequestResult,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  SpanFilter,
} from 'types';
import { saveTraceAnalyticsValidator } from './utils';
import { IoIosWarning } from 'react-icons/io';

type Props = {
  customerFilter?: { key: string; value: string };
  keyExists: KeyExists;
  getSavedTraceMetricsRequest: ReturnType<typeof useRequest>;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  selectedHcFacetValuesByName: SelectedFacetValuesByName;
  spanFilter: SpanFilter;
  search: ReturnType<typeof useSearch>;
  traceIdSearch: string;
};

type SaveMetricFormProps = {
  name: string;
};

const TracesSaveMetricsModal = ({
  customerFilter,
  keyExists,
  getSavedTraceMetricsRequest,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  selectedHcFacetValuesByName,
  spanFilter,
  search,
  traceIdSearch,
}: Props): ReactElement => {
  const modals = useModalsContext();
  const [error, setError] = useState({
    saveTraces: null,
  });

  const saveTraceMetricRequest = useRequest(saveTraceMetric, true, true);
  const { addToast } = useToaster();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SaveMetricFormProps>({
    resolver: yupResolver(saveTraceAnalyticsValidator()),
    defaultValues: { name: '' },
  });
  const closeModal = () => {
    modals.pop();
  };

  const saveMetrics = (payload: any, request: RequestResult<any, any>) => {
    request.call(payload).then((saveMetricResponse: any) => {
      if (saveMetricResponse) {
        getSavedTraceMetricsRequest.call();
        addToast({ status: 'success', text: 'Metric saved successfully.' });
        closeModal();
      }
    });
  };

  const onSaveTraceAnalyticsMetrics = (data: SaveMetricFormProps) => {
    const payload = {
      customerFilter,
      keyExists,
      name: data.name,
      search,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
      selectedHcFacetValuesByName,
      spanFilter,
      traceIdSearch,
    };

    saveMetrics(payload, saveTraceMetricRequest);
  };

  useEffect(() => {
    if (saveTraceMetricRequest.error) {
      setError((prevError) => ({
        ...prevError,
        saveTraces: { message: 'Failed to save metric', status_code: 401 },
      }));
    }
  }, [saveTraceMetricRequest.error]);

  return (
    <div className="trace__analytics__save-metrics">
      <div className="trace__analytics__save-metrics__header">
        <div className="trace__analytics__save-metrics__header__title">
          Save metric
        </div>
        <div
          className="trace__analytics__save-metrics__header__close"
          onClick={closeModal}
        >
          <X />
        </div>
      </div>
      <Loader isLoading={getSavedTraceMetricsRequest.isLoading}>
        <form onSubmit={handleSubmit(onSaveTraceAnalyticsMetrics)}>
          <div className="trace__analytics__save-metrics__name">
            <label>Give metric a name</label>
            <InputWithValidatorV2
              {...register('name')}
              name="name"
              placeholder="Give metric a name"
              title="Give metric a name"
              type="text"
              errorText={errors.name?.message}
            />
          </div>

          {error.saveTraces && (
            <div className="flex gap-[4px] w-full justify-start pr-[14px]">
              <IoIosWarning
                className="overlay-message__icon-and-message__icon"
                size={16}
              />
              <div className="text-red-500">{error.saveTraces.message}</div>
            </div>
          )}

          <div className="trace__analytics__save-metrics--save-button">
            <Button variant="default" size="sm" type="submit">
              Save Metric
            </Button>
          </div>
        </form>
      </Loader>
    </div>
  );
};

export default TracesSaveMetricsModal;
