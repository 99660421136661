import classnames from 'classnames';
import React, {
  MutableRefObject,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import RrwebPlayer from 'rrweb-player';
import useDebouncedEffect from 'use-debounced-effect';
import 'rrweb-player/dist/style.css';
import { useRumReplayStateContext } from './RumReplayStateContext';
import { TabMetadata } from './types';
import { eventWithTime } from '@rrweb/types';

type CreateNewPlayerArgs = {
  autoPlay?: boolean;
  elementRef: MutableRefObject<HTMLDivElement>;
  onCurrentTimeUpdate: (arg0: number) => void;
  onFinish: (arg0: { index: number }) => void;
  index: number;
  offset: number;
  rrwebEvents: eventWithTime[];
  width: number;
};

const createNewPlayer = ({
  elementRef,
  onCurrentTimeUpdate,
  onFinish,
  index,
  offset,
  rrwebEvents,
  width,
}: CreateNewPlayerArgs) => {
  const player = new RrwebPlayer({
    target: elementRef.current, // customizable root element
    props: {
      autoPlay: false,
      events: rrwebEvents,
      height: width / 2,
      UNSAFE_replayCanvas: true,
      showController: false,
      skipInactive: true,
      width,
    },
  });

  const createHandler =
    (setState: React.Dispatch<React.SetStateAction<unknown>>) =>
    ({ payload }: { payload: unknown }) => {
      setState(payload);
    };

  player.addEventListener(
    'ui-update-current-time',
    createHandler((payload: number) => {
      onCurrentTimeUpdate(offset + payload);
    }),
  );

  player.addEventListener('finish', () => {
    onFinish({ index });
  });

  return player;
};

type Props = {
  height: number;
  index: number;
  offset: number;
  playersByIndexRef: MutableRefObject<Record<number, RrwebPlayer>>;
  tab: TabMetadata;
  width: number;
};

const RumReplayPlayer = ({
  height,
  index,
  offset,
  playersByIndexRef,
  tab,
  width,
}: Props) => {
  const elementRef = useRef(null);
  const {
    actions,
    activeTabIndex,
    currentTimeState,
    isPlayingToggle,
    segmentFetcher,
  } = useRumReplayStateContext();

  const { onCurrentTimeUpdate, onFinish, registerRrwebPlayer } = actions;

  const [currentTime] = currentTimeState;

  const eventsBySegmentKey =
    segmentFetcher.eventsBySegmentKeyByTabId[tab.tabId];

  const rrwebEvents = useMemo(() => {
    const effectiveEventsBySegmentKey = eventsBySegmentKey || {};
    return tab.segments.reduce(
      (arr, segment) => [
        ...arr,
        ...(effectiveEventsBySegmentKey[segment.segmentKey] || []),
      ],
      [],
    );
  }, [eventsBySegmentKey, tab]);

  useDebouncedEffect(
    () => {
      const existingPlayer = playersByIndexRef.current[index];
      if (existingPlayer) {
        existingPlayer.$destroy();
      }

      const player = createNewPlayer({
        elementRef,
        onCurrentTimeUpdate,
        onFinish,
        index,
        offset,
        rrwebEvents,
        width,
      });

      const isPlaying = activeTabIndex === index && isPlayingToggle.value;
      if (isPlaying) {
        player.goto(currentTime - offset, true);
      }

      playersByIndexRef.current[index] = player;
    },
    { timeout: 0, ignoreInitialCall: true },
    [rrwebEvents],
  );

  useLayoutEffect(() => {
    if (rrwebEvents.length > 2) {
      const player = createNewPlayer({
        elementRef,
        onCurrentTimeUpdate,
        onFinish,
        index,
        offset,
        rrwebEvents,
        width,
      });
      registerRrwebPlayer({ index, player });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDebouncedEffect(
    () => {
      const player = playersByIndexRef.current[index];
      if (player) {
        player.$set({ width, height });
        player.triggerResize();
      }
    },
    { timeout: 200, ignoreInitialCall: true },
    [height, width],
  );

  return (
    <div
      className={classnames({
        'rum-replay__player': true,
        'rum-replay__player--active': index === activeTabIndex,
      })}
      ref={elementRef}
    />
  );
};

export default RumReplayPlayer;
