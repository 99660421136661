import {
  AlertQueryItem,
  AlertsMetricsParsedProps,
  AlertType,
} from 'screens/NewAlerts/types';
import { AnomalyAlgorithmType } from 'types';
import { getRollupToSecond } from 'utils';

import {
  alertParseConditionValue,
  AnomalyConditionProps,
  ForecastConditionProps,
  OutlierConditionProps,
} from '../../AlertsCreateCondition';
import { anomalyBandMapReverse } from '../../AlertsCreateMetrics/utils';
import { removeFieldsFromKfuseQl } from './alertsCreateLogs';
import { logsForecastSeasonalityMapping } from './alertsCreateLogsForecast';

export const parseLogsAlertsQuery = ({
  data,
  annotations,
}: {
  data: AlertQueryItem[];
  annotations: any;
}): AlertsMetricsParsedProps => {
  const parsed: AlertsMetricsParsedProps = {};
  const alertType = annotations.alertType;

  if (alertType === AlertType.ANOMALY) {
    const anomalyCondition = parseLogsAlertAnomalyCondition({ data });
    Object.assign(parsed, anomalyCondition);
  }

  if (!alertType || alertType === AlertType.THRESHOLD) {
    const thresholdCondition = parseLogsAlertThresholdCondition({ data });
    Object.assign(parsed, thresholdCondition);
  }

  if (alertType === AlertType.OUTLIERS) {
    const outlierCondition = parseLogsAlertOutlierCondition({ data });
    Object.assign(parsed, outlierCondition);
  }

  if (alertType === AlertType.FORECAST) {
    const forecastCondition = parseLogsAlertForecastCondition({ data });
    Object.assign(parsed, forecastCondition);
  }

  const parsedExtraData = JSON.parse(annotations.extraData || '{}');
  if (parsedExtraData?.queryType === 'formula') {
    parsed.condition.queryKey = 'Formula (1)';
  } else {
    parsed.condition.queryKey = 'Query (a)';
  }

  return parsed;
};

const parseLogsAlertThresholdCondition = ({
  data,
}: {
  data: AlertQueryItem[];
}) => {
  const [queryModel] = data;
  const { model, relativeTimeRange } = queryModel;
  const { expr } = model;

  const condition = alertParseConditionValue(data);
  const innerKfuseQl = removeFieldsFromKfuseQl(expr);

  return { condition, promqls: [innerKfuseQl], relativeTimeRange };
};

const parseLogsAlertOutlierCondition = ({
  data,
}: {
  data: AlertQueryItem[];
}) => {
  const [queryModel] = data;
  const { model, relativeTimeRange } = queryModel;
  const { expr } = model;
  const condition = alertParseConditionValue(data);
  const outlierCondition: OutlierConditionProps = {};
  const { parsedAnomaly, operation, step } = parseLogsAlertAdvancedExpr({
    expr,
    startWith: 'outlier',
  });
  outlierCondition.algorithm = parsedAnomaly.anomalyAlgorithm.toUpperCase();
  outlierCondition.tolerance = parsedAnomaly.eps;

  Object.assign(outlierCondition, parsedAnomaly);
  const innerKfuseQl = expr.split('|').slice(0, -1).join('|');
  return {
    condition,
    promqls: [innerKfuseQl],
    relativeTimeRange,
    outlierCondition,
    operation,
    step,
  };
};

export const parseLogsAlertAnomalyCondition = ({
  data,
}: {
  data: AlertQueryItem[];
}) => {
  const condition = alertParseConditionValue(data);
  const anomalyCondition: AnomalyConditionProps = {};
  const [queryModel] = data;
  const { model, relativeTimeRange } = queryModel;
  const { expr } = model;
  const parsedExpr = parseLogsAlertAdvancedExpr({ expr, startWith: 'anomaly' });

  Object.assign(anomalyCondition, parsedExpr.parsedAnomaly);
  const innerKfuseQl = expr.split('|').slice(0, -1).join('|');

  return {
    anomalyCondition,
    operation: parsedExpr.operation,
    step: parsedExpr.step,
    promqls: [innerKfuseQl],
    condition,
    relativeTimeRange,
  };
};

export const parseLogsAlertForecastCondition = ({
  data,
}: {
  data: AlertQueryItem[];
}) => {
  const condition = alertParseConditionValue(data);
  const [queryModel] = data;
  const { model, relativeTimeRange } = queryModel;
  const { expr } = model;
  const forecastCondition: ForecastConditionProps = {};
  const parsedExpr = parseLogsAlertAdvancedExpr({
    expr,
    startWith: 'predict',
  });
  const { parsedAnomaly } = parsedExpr;
  forecastCondition.forecastAlgorithm = parsedAnomaly.anomalyAlgorithm;
  forecastCondition.forecastDuration = parsedAnomaly.forecast;

  if (parsedAnomaly.seasonality) {
    forecastCondition.seasonality =
      logsForecastSeasonalityMapping[
        parsedAnomaly.seasonality as keyof typeof logsForecastSeasonalityMapping
      ];
    forecastCondition.interval = getRollupToSecond(parsedExpr.step).toString();
  }

  const innerKfuseQl = expr.split('|').slice(0, -1).join('|');
  return {
    condition,
    promqls: [innerKfuseQl],
    relativeTimeRange,
    forecastCondition,
    step: parsedExpr.step,
    operation: parsedExpr.operation,
  };
};

const parseLogsAlertAdvancedExpr = ({
  expr,
  startWith,
}: {
  expr: string;
  startWith: string;
}) => {
  const splitExpr = expr.split('|').map((item) => item.trim());
  const lastItem = splitExpr[splitExpr.length - 1];
  if (!lastItem) return {};
  if (!lastItem.startsWith(startWith)) return {};

  const splitAnomaly = lastItem.split(',').map((item) => item.trim());

  const parsedAnomaly: AnomalyConditionProps = {};
  const anomalyAlgorithm = splitAnomaly[0];
  const operation = anomalyAlgorithm.match(/\((.*?)\)/)?.[1];
  const step = anomalyAlgorithm.split('by')[1];

  splitAnomaly.slice(1).forEach((item) => {
    const [key, value] = item.split('=').map((item) => item.trim());
    if (key === 'bounds') {
      parsedAnomaly.bound = value;
      return;
    }
    if (key === 'model') {
      parsedAnomaly.anomalyAlgorithm = value as AnomalyAlgorithmType;
      return;
    }
    if (key === 'band') {
      parsedAnomaly.band = anomalyBandMapReverse[value];
      return;
    }
    parsedAnomaly[key] = value;
  });

  return { parsedAnomaly, operation, step };
};
