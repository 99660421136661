import classNames from 'classnames';
import React, { ReactElement, useState } from 'react';
import { MdOutlineCloudUpload } from 'react-icons/md';

const UploadBox = ({
  onFileUpload,
}: {
  onFileUpload: (file: File) => void;
}): ReactElement => {
  const [dragging, setDragging] = useState(false);
  const [fileName, setFileName] = useState('');

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragOut = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setFileName(e.dataTransfer.files[0].name);
      onFileUpload(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFileName(e.target.files[0].name);
      onFileUpload(e.target.files[0]);
    }
  };

  return (
    <label htmlFor="fileInput" className="w-full p-6 cursor-pointer">
      <div
        className={classNames({
          'upload-box': true,
          'upload-box--dragging': dragging,
        })}
        onDragOver={handleDrag}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDrop={handleDrop}
      >
        <div className="text-center">
          <div className="flex justify-center">
            <MdOutlineCloudUpload size={60} />
          </div>
          <div className="text-lg font-semibold">Upload JSON</div>
          {!fileName && (
            <div className="mt-4">
              Drag and drop or <a>browse</a>
            </div>
          )}
          <input
            id="fileInput"
            type="file"
            accept=".json"
            onChange={handleFileChange}
            className="hidden"
          />
          {fileName && <div className="mt-4">Selected file: {fileName}</div>}
        </div>
      </div>
    </label>
  );
};

export default UploadBox;
