import {
  Hexagon,
  KfuseHostmapProps,
  KfuseHostmapHook,
  KfuseHostmapOptions,
  HostmapDataProps,
} from './types';
import { calculateHexagonPositions, drawBorder, drawHexagon } from './utils';

const KfuseHostmap = ({
  options,
  data,
  target,
}: {
  options: KfuseHostmapOptions;
  data: HostmapDataProps[];
  target?: HTMLDivElement;
}): KfuseHostmapProps => {
  const self = {} as KfuseHostmapProps;

  const root = document.createElement('div');
  root.className = 'khostmap';
  self.root = root;

  const wrap = document.createElement('div');
  wrap.className = 'khostmap__wrap';

  // canvas for drawing
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  self.ctx = ctx;
  wrap.appendChild(canvas);

  // canvas for tooltip
  const tooltip = document.createElement('canvas');
  const tooltipCtx = tooltip.getContext('2d');
  self.tooltipCtx = tooltipCtx;
  const tooltipWrap = document.createElement('div');
  tooltipWrap.className = 'khostmap__tooltip';
  tooltipWrap.appendChild(tooltip);

  wrap.appendChild(tooltipWrap);
  root.appendChild(wrap);

  self.cursor = {} as KfuseHostmapProps['cursor'];

  self.hoveredHexagonIndex = undefined;
  const mouseMove = (e: MouseEvent) => {
    const { hexagons } = self;
    const rect = self.ctx.canvas.getBoundingClientRect();
    const { devicePixelRatio = 1 } = window;
    const mouseX = (e.clientX - rect.left) * devicePixelRatio;
    const mouseY = (e.clientY - rect.top) * devicePixelRatio;

    let isHoveredOnHexagon = false;
    hexagons.forEach((hexagon, idx: number) => {
      self.ctx.beginPath();

      // Define the hexagon path
      for (let i = 0; i < 6; i++) {
        self.ctx.lineTo(
          hexagon.x + hexagon.radius * Math.cos((i * 2 * Math.PI) / 6),
          hexagon.y + hexagon.radius * Math.sin((i * 2 * Math.PI) / 6),
        );
      }

      self.ctx.closePath();
      const isHovered = self.ctx.isPointInPath(mouseX, mouseY);

      if (isHovered && self.hoveredHexagonIndex !== idx) {
        fire('setCursor', hexagon, idx);
        self.hoveredHexagonIndex = idx;
      }
      isHoveredOnHexagon = isHoveredOnHexagon || isHovered;
    });

    if (!isHoveredOnHexagon) {
      fire('setCursor', null);
      self.hoveredHexagonIndex = undefined;
    }
  };

  const mouseEnter = (e: MouseEvent) => {
    self.cursor = { x: e.clientX, y: e.clientY };
    self.ctx.canvas.addEventListener('mousemove', mouseMove);
  };

  const mouseLeave = (e: MouseEvent) => {
    self.cursor = { x: -10, y: -10 };
    self.ctx.canvas.removeEventListener('mousemove', mouseMove);
  };

  const draw = (_data: HostmapDataProps[]) => {
    const hexagons = calculateHexagonPositions(self.width, self.height, _data);
    clearCanavas();
    drawHexagons(hexagons);
    drawHexagonBorder(hexagons);
    self.hexagons = hexagons;
  };

  const drawHexagons = (hexagons: Hexagon[]) => {
    hexagons.forEach((hexagon) => {
      drawHexagon(self.ctx, hexagon);
    });

    fire('drawHexagons');
  };

  const drawHexagonBorder = (hexagons: Hexagon[]) => {
    hexagons.forEach((hexagon) => {
      drawBorder(self.ctx, hexagon);
    });

    fire('drawBorder');
  };

  const clearTooltipCanavas = () => {
    self.tooltipCtx.clearRect(0, 0, self.width, self.height);
  };

  const clearCanavas = () => {
    self.ctx.clearRect(0, 0, self.width, self.height);
  };

  const fire = (hookName: string, ...args: any) => {
    const hooks = (self.hooks || {}) as KfuseHostmapHook;
    if (hookName in hooks) {
      hooks[hookName].forEach((fn: any) => {
        fn.call(null, self, ...args);
      });
    }
  };

  const setSize = (width: number, height: number) => {
    calcSize(width, height);
    self.ctx.canvas.width = self.width;
    self.ctx.canvas.height = self.height;
    self.tooltipCtx.canvas.width = self.width;
    self.tooltipCtx.canvas.height = self.height;

    wrap.style.width = `${width}px`;
    wrap.style.height = `${height}px`;
  };

  const calcSize = (width: number, height: number) => {
    const { devicePixelRatio = 1 } = window;
    self.width = width * devicePixelRatio;
    self.height = height * devicePixelRatio;
  };

  const setHooks = (hooks: KfuseHostmapHook) => {
    self.hooks = hooks;
  };

  const destroy = () => {
    if (self.ctx && self.ctx.canvas) {
      self.ctx.canvas.removeEventListener('mouseenter', mouseEnter);
      self.ctx.canvas.removeEventListener('mouseleave', mouseLeave);
      self.ctx.canvas.removeEventListener('mousemove', mouseMove);
    }

    if (root.parentNode) {
      root.parentNode.removeChild(root);
    }

    self.root = null;
    self.ctx = null;
    self.tooltipCtx = null;
    fire('destroy');
  };

  const init = () => {
    fire('init');
    setSize(options.width, options.height);
    self.hooks = options.hooks || {};
    draw(data);

    self.ctx.canvas.addEventListener('mouseenter', mouseEnter);
    self.ctx.canvas.addEventListener('mouseleave', mouseLeave);
  };

  if (target && target instanceof HTMLDivElement) {
    target.appendChild(root);
    init();
  } else {
    init();
  }

  self.setSize = setSize;
  self.draw = draw;
  self.drawHexagons = drawHexagons;
  self.drawHexagonBorder = drawHexagonBorder;
  self.clearTooltipCanavas = clearTooltipCanavas;
  self.destroy = destroy;
  self.setHooks = setHooks;

  return self;
};

export default KfuseHostmap;
