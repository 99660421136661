import { pick } from 'lodash';

const intersectLabels = (...labels: { [x: string]: boolean }[]) => {
  if (labels.length === 0) {
    return {};
  }
  let intersected = labels[0];

  for (let i = 1; i < labels.length; i++) {
    intersected = pick(intersected, Object.keys(labels[i]));
  }

  return intersected;
};

export default intersectLabels;
