import { sortLexicographically, sortNumerically } from 'utils';
import getValue from './getValue';
import { TableColumn } from './types';

interface Args<T> {
  asc: boolean;
  column: TableColumn<T>;
}

export const sortTableRowsLexicographically =
  <T>({ asc, column }: Args<T>) =>
  (a: T, b: T) =>
    sortLexicographically({
      a: getValue({ column, row: a }),
      b: getValue({ column, row: b }),
      asc,
    });

export const sortTableRowsNumerically =
  <T>({ asc, column }: Args<T>) =>
  (a: T, b: T) =>
    sortNumerically({
      a: getValue({ column, row: a }),
      b: getValue({ column, row: b }),
      asc,
    });
