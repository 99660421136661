import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import useTextSelectionVsClick from 'hooks/useTextSelectionVsClick';

const defaultProps = {
  className: '',
  onClick: () => {},
};

const propTypes = {
  cellWidth: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  left: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

const SheetCell = ({
  baseOffsetTop,
  cellWidth,
  children,
  className,
  column,
  left,
  offsetTop,
  offsetX,
  offsetY,
  row,
  scrollX,
  scrollY,
  sheetDimensions,
  tooltip,
  x,
  y,
  onClick,
}) => {
  const { handleMouseDown, handleClick } = useTextSelectionVsClick(onClick);

  return (
    <div
      className={classNames({
        sheet__cell: true,
        [className]: className,
      })}
      style={{ left: `${left}px`, width: `${cellWidth}px`, userSelect: 'text' }}
      onMouseDown={handleMouseDown}
      onClick={handleClick}
    >
      {children}
      {tooltip ? (
        <div className="sheet__cell__actions">
          {tooltip({ column, index: y, offsetX, offsetY, offsetTop, row })}
        </div>
      ) : null}
    </div>
  );
};

SheetCell.defaultProps = defaultProps;
SheetCell.propTypes = propTypes;

export default SheetCell;
