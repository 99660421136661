export const OUTLIERS_TITLE =
  'Outlier monitors detect when a member of a group (e.g., hosts, availability zones, partitions) is behaving unusually compared to the rest.';
export const OUTLIERS_MESSAGE =
  'On each alert evaluation, It will check whether or not all groups are clustered together, exhibiting the same behavior. An alert is triggered whenever at least one group diverges from the rest of the groups.';
export const OUTLIERS_DESCRIPTION =
  'An alert is triggered whenever one member in a group behaves differently from its peers.';
export const DBSCAN_ALGORITHM_DESCRIPTION =
  'DBSCAN is a density based clustering algorithm which can help group together similar points. ';
export const OUTLIERS_TOLERANCE_DESCRIPTION =
  '"outlierness" tolerance to use. This value determines how different a group has to be (in terms of %) from the majority (median) group. A 0.15 value in tolerance would mean that any points which are 15% higher or lower than the majority will be considered outliers.';
