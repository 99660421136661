import { DateSelection } from 'types/DateSelection';
import { getRollupByVisualization } from './rollup';
import { getUrlParamByKey } from './url';
/**
 * Scrape interval is setting in Prometheus, which can checked from
 * https://prometheus.io/docs/prometheus/latest/configuration/configuration/#scrape_interval
 * Default is set 15s by grafana.
 */
export const SCREEN_INTERVAL = 15;
export const DEFAULT_SCRAPE_INTERVAL_COUNT = 8;

/**
 * Returns the value of the $__rate_interval variable in seconds and milliseconds
 * @param date
 * @returns
 * @link https://grafana.com/docs/grafana/latest/dashboards/variables/add-template-variables/#__rate_interval
 */
export const calculateRateIntervalVariableValue = (
  date: DateSelection,
  chartType: string,
): {
  rateIntervalMs: string;
  rateIntervalSeconds: string;
  stepInMs: number;
} => {
  const scrapeIntervalCount = getUrlParamByKey('scrapeIntervalCount');
  const numberOfScrapeIntervals =
    scrapeIntervalCount && Number(scrapeIntervalCount) > 0
      ? parseInt(scrapeIntervalCount)
      : DEFAULT_SCRAPE_INTERVAL_COUNT;

  const scrapeIntervalMs = SCREEN_INTERVAL * 1000;
  const step = getRollupByVisualization(date, chartType);
  const stepInMs = step * 1000;

  // max($__interval + Scrape interval, 4 * Scrape interval)
  const rateInterval = Math.max(
    stepInMs + scrapeIntervalMs,
    numberOfScrapeIntervals * scrapeIntervalMs,
  );

  return {
    rateIntervalMs: `${rateInterval}ms`,
    rateIntervalSeconds: `${rateInterval / 1000}s`,
    stepInMs: step * 1000,
  };
};

/**
 * Returns the value of the $__interval variable in seconds
 * @param date
 * @returns
 * @link https://grafana.com/docs/grafana/latest/dashboards/variables/add-template-variables/#__interval
 */
export const getIntervalVariableValue = (date: DateSelection): string => {
  const step = getRollupByVisualization(date);
  return `${step}s`;
};

/**
 * Returns the value of the $__range variable in seconds
 * @param date
 * @returns
 * @link https://grafana.com/docs/grafana/latest/dashboards/variables/add-template-variables/#__range
 */
export const getRangeVariableValue = (date: DateSelection): string => {
  const { startTimeUnix, endTimeUnix } = date;
  const range = endTimeUnix - startTimeUnix;
  return `${Math.floor(range)}s`;
};
