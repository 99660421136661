import { useThemeContext } from 'components';
import React, { ReactElement, useMemo } from 'react';
import { DashboardPanelType, DateSelection, QueryDataProps } from 'types';
import { combineRangeQueryData, getRollupByVisualization } from 'utils';

import { useAlertsCreateCondition } from '../AlertsCreateCondition';
import { AlertsChart, AlertsChartAnomalyPromql } from '../AlertsChart';
import { useAlertsCreateAPM } from './hooks';
import { apmChartUnit, getAPMAlertInnerPromql } from './utils';
import { AlertType } from '../types';

const AlertsCreateAPMChart = ({
  alertsCreateAPMState,
  alertType,
  baseWidth,
  conditionState,
  forWindow,
  isEditing = false,
  date,
}: {
  alertsCreateAPMState: ReturnType<typeof useAlertsCreateAPM>;
  alertType: AlertType;
  baseWidth: number;
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
  forWindow?: string;
  isEditing?: boolean;
  date: DateSelection;
}): ReactElement => {
  const { darkModeEnabled } = useThemeContext();
  const { anomalyCondition, condition } = conditionState;
  const { apmAlertDetails, promqlQueryRangeRequest } = alertsCreateAPMState;
  const { serviceName, triggerType } = apmAlertDetails;

  if (alertType === 'anomaly') {
    if (!serviceName) {
      return null;
    }
    const promql = getAPMAlertInnerPromql(apmAlertDetails, date);
    return (
      <AlertsChartAnomalyPromql
        anomalyCondition={anomalyCondition}
        baseWidth={baseWidth}
        date={date}
        forWindow={forWindow}
        isEditing={isEditing}
        queryItem={{
          promql,
          refId: 'a',
          type: DashboardPanelType.TIMESERIES,
          queryType: 'query',
          step: getRollupByVisualization(date),
          metricName: '{*}',
        }}
        unit={apmChartUnit[triggerType] || 'number'}
      />
    );
  }

  if (alertType === 'threshold') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const combinedData = useMemo(() => {
      const queryData: QueryDataProps = {
        query_a: {
          range: promqlQueryRangeRequest.result || {},
          isLoading: promqlQueryRangeRequest.isLoading,
          meta: null,
        },
      };
      return combineRangeQueryData({
        formulas: [],
        queries: [{ isActive: true, queryKey: 'a' }],
        queryData,
        darkModeEnabled,
        combineTimestamp: false,
      });
    }, [
      darkModeEnabled,
      promqlQueryRangeRequest.isLoading,
      promqlQueryRangeRequest.result,
    ]);

    return (
      <AlertsChart
        conditionOperator={condition.of}
        conditionValue={condition.value ? Number(condition.value) : undefined}
        date={date}
        enableEvaluationLine={alertType === 'threshold'}
        isLoading={promqlQueryRangeRequest.isLoading}
        queryData={combinedData}
        unit={apmChartUnit[triggerType] || 'number'}
      />
    );
  }
  return null;
};

export default AlertsCreateAPMChart;
