import { ChipWithLabel, FacetPicker } from 'components';
import { colorsByAlertState } from 'kfuse-constants';
import { useSelectedFacetValuesByNameState, useToggle } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { ValueCount } from 'types/generated';

import { DashboardListSidebarFacet } from '../utils';

const DashboardListSidebarFacetGroup = ({
  dashboardFilterProperties,
  facetNames,
  forceExpanded,
  request,
  selectedFacetValuesByNameState,
}: {
  dashboardFilterProperties: { [key: string]: ValueCount[] };
  facetNames: { name: string; forceExpanded?: boolean }[];
  forceExpanded?: boolean;
  request: any;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
}): ReactElement => {
  const [lastRefreshedAt, setLastRefreshedAt] = useState(null);
  const expandedToggle = useToggle();
  const expanded = forceExpanded || expandedToggle.value;

  useEffect(() => {
    setLastRefreshedAt(new Date());
  }, [selectedFacetValuesByNameState.state, dashboardFilterProperties]);

  const handlersByName = (name: string) => ({
    excludeFacetValue: (value: string) => {
      selectedFacetValuesByNameState.excludeFacetValue({ name, value });
    },
    selectOnlyFacetValue: (value: string) => {
      selectedFacetValuesByNameState.selectOnlyFacetValue({ name, value });
    },
    toggleFacetValue: (value: string, allValues: Array<string>) => {
      selectedFacetValuesByNameState.toggleFacetPickerValueCheckbox({
        facetName: name,
        facetValueToToggle: value,
        allFacetValues: allValues,
      });
    },
  });

  const clearFacetHandler = (name: string) => () => {
    selectedFacetValuesByNameState.clearFacet(name);
  };

  return (
    <div>
      {expanded && (
        <div className="events__sidebar__facet-group__facet-names">
          {facetNames.map(
            (facet: { name: string; forceExpanded?: boolean }) => {
              const name = facet.name;
              return (
                <FacetPicker
                  forceExpanded={facet.forceExpanded || false}
                  clearFacet={clearFacetHandler(name)}
                  key={name}
                  lastRefreshedAt={lastRefreshedAt}
                  name={name}
                  renderName={(s) => {
                    const facet = DashboardListSidebarFacet.find(
                      (f) => f.name === s,
                    );
                    return facet.label.replace(/_+/g, ' ');
                  }}
                  request={request(name)}
                  selectedFacetValues={
                    selectedFacetValuesByNameState.state[name] || {}
                  }
                  renderValue={(label) => {
                    if (name === 'Status') {
                      return (
                        <ChipWithLabel
                          color={colorsByAlertState[label]}
                          label={label}
                        />
                      );
                    }
                    return label;
                  }}
                  {...handlersByName(name)}
                />
              );
            },
          )}
        </div>
      )}
    </div>
  );
};

export default DashboardListSidebarFacetGroup;
