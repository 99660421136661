import { useIsHoveredToggleContext, useToaster } from 'components';
import React, { ReactNode, ReactElement, useEffect } from 'react';
import { Search, XCircle, Eye, Clipboard } from 'react-feather';

type Props = {
  children?: ReactNode;
  close: () => void;
  excludeFacetValue: (value: string) => void;
  formattedValue?: string;
  isExcludeFacetValueShown?: boolean;
  label?: string;
  selectOnlyFacetValue: (value: string) => void;
  showKeyExists?: () => void;
  showKeyExistsLabel?: string;
  value: string;
};

const FacetPickerValuesItemPopoverPanel = ({
  children,
  close,
  excludeFacetValue,
  formattedValue,
  isExcludeFacetValueShown = true,
  label,
  selectOnlyFacetValue,
  showKeyExists,
  showKeyExistsLabel,
  value,
}: Props): ReactElement => {
  const excludeFacetValueHandler = () => {
    excludeFacetValue(value);
    close();
  };

  const selectOnlyFacetValueHandler = () => {
    selectOnlyFacetValue(value);
    close();
  };

  const showKeyExistsHandler = () => {
    showKeyExists();
    close();
  };

  const { addToast } = useToaster();
  const isHoveredToggleContext = useIsHoveredToggleContext();

  useEffect(() => {
    if (isHoveredToggleContext) {
      isHoveredToggleContext.onPopoverMount();
    }
    return () => {
      if (isHoveredToggleContext) {
        isHoveredToggleContext.onPopoverUnmout();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="logs__selected-log__attribute__panel">
      <button
        className="logs__selected-log__attribute__panel__item"
        onClick={selectOnlyFacetValueHandler}
      >
        <Search
          className="logs__selected-log__attribute__panel__item__icon"
          size={14}
        />
        <span className="logs__selected-log__attribute__panel__item__label">
          {'Filter By '}
        </span>
        <span className="logs__selected-log__attribute__panel__item__value">
          {label || value}
        </span>
      </button>
      {isExcludeFacetValueShown ? (
        <button
          className="logs__selected-log__attribute__panel__item"
          onClick={excludeFacetValueHandler}
        >
          <XCircle
            className="logs__selected-log__attribute__panel__item__icon"
            size={14}
          />
          <span className="logs__selected-log__attribute__panel__item__label">
            {'Exclude '}
          </span>
          <span className="logs__selected-log__attribute__panel__item__value">
            {label || value}
          </span>
        </button>
      ) : null}
      {showKeyExists ? (
        <button
          className="logs__selected-log__attribute__panel__item"
          onClick={showKeyExistsHandler}
        >
          <Eye
            className="logs__selected-log__attribute__panel__item__icon"
            size={14}
          />
          <span className="logs__selected-log__attribute__panel__item__label">
            {'Show where '}
            <span className="logs__selected-log__attribute__panel__item__value">
              {showKeyExistsLabel}
            </span>
            {' exists'}
          </span>
        </button>
      ) : null}
      <button
        className="logs__selected-log__attribute__panel__item"
        onClick={() => {
          navigator.clipboard.writeText(
            formattedValue ? formattedValue : value || label,
          );
          addToast({ status: 'success', text: 'Copied to clipboard' });
          close();
        }}
      >
        <Clipboard
          className="logs__selected-log__attribute__panel__item__icon"
          size={14}
        />
        <span className="logs__selected-log__attribute__panel__item__label">
          {'Copy '}
        </span>
        <span className="logs__selected-log__attribute__panel__item__value">
          {formattedValue ? formattedValue : value || label}
        </span>
      </button>
      {children ? children : null}
    </div>
  );
};

export default FacetPickerValuesItemPopoverPanel;
