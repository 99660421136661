import { QueryCombinedStatusProps, QueryDataProps } from 'types/QueryData';

const parseErrorFromQueryData = ({
  queryData,
  queries,
  formulas,
  dataType = 'range',
  customErrorMessage,
}: {
  queryData: QueryDataProps;
  queries: QueryCombinedStatusProps[];
  formulas: QueryCombinedStatusProps[];
  dataType: 'range' | 'instant';
  customErrorMessage?: (error: string) => string | null;
}): string | null => {
  const activeQueries = queries.filter((query) => query.isActive);
  const activeFormulas = formulas.filter((formula) => formula.isActive);
  const numOfActiveQueries = activeQueries.length + activeFormulas.length;
  const isOnlyOneActive = numOfActiveQueries === 1;

  const errors: string[] = [];
  let dataCount = -1;
  const keys = Object.keys(queryData);
  keys.forEach((key) => {
    const [type, queryKey] = key.split('_');
    let query = null;
    if (type === 'query') {
      query = queries.find((q) => q.queryKey === queryKey);
    }
    if (type === 'formula') {
      query = formulas.find((f) => f.queryKey === queryKey);
    }
    if (!query) return;

    if (query.isActive) {
      const error = queryData[key].error;
      if (error) {
        const errorStr = customErrorMessage ? customErrorMessage(error) : error;
        errors.push(errorStr);
        return;
      }

      const data = queryData[key];
      if (data[dataType] && data[dataType].data) {
        if (dataCount === -1) {
          dataCount = 0;
        }
        dataCount += data[dataType].data.length;
      }
    }
  });

  // if there is only one active query/formula, return the error
  if (errors.length > 0 && isOnlyOneActive) {
    return errors.join(', ');
  }

  // if all queries/formulas have error, return the first error
  if (errors.length === numOfActiveQueries) {
    return errors[0];
  }

  if (dataCount === 0) {
    return 'Your query did not return any data. Please double check your query and try again.';
  }

  return null;
};

export default parseErrorFromQueryData;
