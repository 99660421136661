import React, { ReactElement } from 'react';

import { AlertsCreateDetection } from '../components';
import {
  changeAlertPopupMessage,
  thresholdAlertPopupMessage,
  outliersAlertPopupMessage,
  anomalyAlertPopupMessage,
  forecastAlertPopupMessage,
} from '../constants';
import { AlertType } from '../types';

const metricsAlertDetectionList = [
  { label: 'Threshold Alert', value: AlertType.THRESHOLD },
  { label: 'Change Alert', value: AlertType.CHANGE },
  { label: 'Outliers Alert', value: AlertType.OUTLIERS },
  { label: 'Anomaly Detection', value: AlertType.ANOMALY },
  { label: 'Forecast Alert', value: AlertType.FORECAST },
];

const detectionDescriptions = {
  threshold: {
    message: thresholdAlertPopupMessage.THRESHOLD_MESSAGE,
    title: thresholdAlertPopupMessage.THRESHOLD_TITLE,
    description: thresholdAlertPopupMessage.THRESHOLD_DESCRIPTION,
  },
  change: {
    message: changeAlertPopupMessage.CHANGE_MESSAGE,
    title: changeAlertPopupMessage.CHANGE_TITLE,
    description: changeAlertPopupMessage.CHANGE_DESCRIPTION,
  },
  outliers: {
    message: outliersAlertPopupMessage.OUTLIERS_MESSAGE,
    title: outliersAlertPopupMessage.OUTLIERS_TITLE,
    description: outliersAlertPopupMessage.OUTLIERS_DESCRIPTION,
  },
  anomaly: {
    message: anomalyAlertPopupMessage.ANOMALY_MESSAGE,
    title: anomalyAlertPopupMessage.ANOMALY_TITLE,
    description: anomalyAlertPopupMessage.ANOMALY_DESCRIPTION,
  },
  forecast: {
    message: forecastAlertPopupMessage.FORECAST_MESSAGE,
    title: forecastAlertPopupMessage.FORECAST_TITLE,
    description: forecastAlertPopupMessage.FORECAST_DESCRIPTION,
  },
};

type Props = {
  selectedAlertType: AlertType;
  handleTabClick: (tab: string) => void;
};

const AlertsCreateMetricsDetection = ({
  selectedAlertType,
  handleTabClick,
}: Props): ReactElement => {
  return (
    <AlertsCreateDetection
      activeDetection={selectedAlertType}
      detectionList={metricsAlertDetectionList}
      detectionDescriptionMap={detectionDescriptions}
      setActiveDetection={handleTabClick}
    />
  );
};

export default AlertsCreateMetricsDetection;
