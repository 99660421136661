import classnames from 'classnames';
import { TableSearch, useTableSearch } from 'components';
import { useMap, useToggle } from 'hooks';
import React, { useLayoutEffect, useRef } from 'react';
import { ChartJsData, ChartLegendTableColumn } from 'types';
import ChartGridItemLegendCompact from './ChartGridItemLegendCompact';
import ChartGridItemLegendTable from './ChartGridItemLegendTable';

type Props = {
  colorMap: { [key: string]: string };
  data: ChartJsData[];
  deselectedKeysMap: ReturnType<typeof useMap>;
  keys: string[];
  legendRows: any[];
  legendTableColumns: ChartLegendTableColumn[];
  showCompactLegend?: boolean;
};

const ChartGridItemLegend = ({
  colorMap,
  data,
  deselectedKeysMap,
  keys,
  legendRows,
  legendTableColumns,
  showCompactLegend,
}: Props) => {
  const isOverflowingToggle = useToggle();
  const ref = useRef();
  const showingMoreToggle = useToggle();

  const toggleKey = (key: string) => {
    const isDeselected = Boolean(key in deselectedKeysMap.state);
    const deselectedKeysLen = Object.keys(deselectedKeysMap.state).length;

    // when clicked on already deselected key
    if (deselectedKeysLen === legendRows.length - 1 && !isDeselected) {
      deselectedKeysMap.reset();
      return;
    }

    if (isDeselected) {
      deselectedKeysMap.remove(key);
    } else {
      const legendRowsKeys: { [key: string]: boolean } = {};
      legendRows.forEach((row) => {
        legendRowsKeys[row.key] = true;
      });
      delete legendRowsKeys[key];
      deselectedKeysMap.bulkUpdate(legendRowsKeys);
    }
  };

  useLayoutEffect(() => {
    const element = ref.current;
    if (element) {
      const { offsetHeight, offsetWidth, scrollHeight, scrollWidth } = element;
      const isOverflowing =
        offsetHeight < scrollHeight || offsetWidth < scrollWidth;
      if (isOverflowing) {
        isOverflowingToggle.on();
      } else {
        isOverflowingToggle.off();
      }
    }
  }, []);

  const tableSearch = useTableSearch({
    rows: legendRows,
    shouldWriteToUrl: false,
  });

  return (
    <div className="chart-grid__item__legend">
      {legendRows.length > 8 && !showCompactLegend && (
        <TableSearch
          className="uplot__value-legends__search-bar"
          placeholder="Search timeseries..."
          tableSearch={tableSearch}
        />
      )}
      <div
        className={classnames({
          'chart-grid__item__legend__items': true,
          'chart-grid__item__legend__items--show-more': showingMoreToggle.value,
        })}
        ref={ref}
      >
        {showCompactLegend ? (
          <ChartGridItemLegendCompact
            colorMap={colorMap}
            data={data}
            deselectedKeysMap={deselectedKeysMap}
            keys={keys}
            toggleKey={toggleKey}
          />
        ) : (
          <ChartGridItemLegendTable
            colorMap={colorMap}
            data={data}
            deselectedKeysMap={deselectedKeysMap}
            keys={keys}
            legendRows={tableSearch.searchedRows}
            legendTableColumns={legendTableColumns}
            toggleKey={toggleKey}
          />
        )}
      </div>
      {isOverflowingToggle.value ? (
        <div className="chart-grid__item__legend__show-more">
          <button className="link" onClick={showingMoreToggle.toggle}>
            {showingMoreToggle.value ? 'Show less' : 'Show more'}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default ChartGridItemLegend;
