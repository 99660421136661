import fetchGrafanaApi from './fetchGrafanaApi';
import { GrafanaFolderProps } from 'types/Grafana';

const getGrafanaAlertsFolders = (): Promise<GrafanaFolderProps[]> => {
  return fetchGrafanaApi(
    `grafana/api/search?query=&starred=false&skipRecent=true&skipStarred=true&folderIds=0&layout=folders&prevSort=null`,
    {
      headers: { 'Content-Type': 'application/json' },
      method: 'GET',
    },
  ).then((result: Array<{ title: string; url: string }>) => {
    if (result && result.length > 0) {
      const folders: GrafanaFolderProps[] = [];
      result.forEach((folder) => {
        if (folder.url.startsWith('/grafana/dashboards/')) {
          folders.push(folder as GrafanaFolderProps);
        }
      });
      return folders;
    }
    return [];
  });
};

export default getGrafanaAlertsFolders;
