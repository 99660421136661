export const ANOMALY_TITLE =
  'An anomaly alert uses past behavior to detect when a metric is behaving abnormally.';
export const ANOMALY_DESCRIPTION =
  'An alert is triggered whenever a metric deviates from an expected pattern.';
export const ANOMALY_MESSAGE =
  'Please see the details of each algorithm when selecting the algo name below "Set Conditions"';
export const RRCF_ALGORITHM_DESCRIPTION =
  'RRCF (Robust Random Cut Forest) is a machine learning algorithm used for detecting anomalies in large datasets. It uses a tree-based ensemble method to identify outliers based on their relative isolation within the dataset. The algorithm constructs a set of binary trees from random subsamples of the data, and determines the level of isolation of each point in the dataset by counting the number of trees that must be traversed before reaching that point. Anomalies are identified as points that require fewer traversals than the majority of points in the dataset, indicating that they are more isolated and potentially more unusual. The algorithm is robust to high-dimensional data, skewed distributions, and the presence of noisy or irrelevant features, making it well-suited for a wide range of applications in anomaly detection and outlier analysis.';
export const GLOBAL_HISTORY_DESCRIPTION =
  'Time window to use for the rolling dataset (from the metric query done over this time window). At any point in time, RRCF algo captures the signal behavior seen over this time window.';
export const LOCAL_HISTORY_DESCRIPTION =
  'Time window to use for capturing the signal behavior in recent past.';
export const ANOMALY_INTERVAL_DESCRIPTION =
  'The interval at which this alert expression will be evaluated. This interval is automatically derived from the chosen global history time window';

export const BASIC_ALGORITHM_DESCRIPTION =
  'Use when metrics have no repeating seasonal pattern. This algorithm is based on a simple lagging rolling quantile computation to determine the range of expected values. It uses data based on the "window" parameter and adjusts quickly to changing conditions but has no knowledge of seasonal behavior or longer trends.';
export const AGILE_ALGORITHM_DESCRIPTION =
  'Use when metrics are seasonal and expected to shift. The algorithm quickly adjusts to shifts in metric values. It incorporates the immediate past into its predictions, allowing quick updates for level shifts at the expense of being less robust to recent, long-lasting anomalies.';
export const ROBUST_ALGORITHM_DESCRIPTION =
  'Use when seasonal metrics expected to be stable, and slow, level shifts are considered anomalies. It is stable and predictions remain constant even through long-lasting anomalies at the expense of taking longer to respond to intended level shifts.';

export const BASIC_BOUNDS_DESCRIPTION =
  'The size of the expected range of values. This corresponds to the number of standard deviation from the expected value.';
export const BASIC_BAND_DESCRIPTION =
  'The threshold condition for triggering an alert. Upper considers points above the expected range of values as anomaly. Lower considers points below the expected range of values as anomaly. Both considers above and below the expected range of values as anomaly.';
export const BASIC_WINDOW_DESCRIPTION =
  'The time window to use for the rolling quantile computation.';

export const ANOMALY_ROBUST_MODEL_DESCRIPTION =
  'The type of the seasonal component. Additive model is used when the seasonal component does not vary with the level of the time  series. Multiplicative model is used if the seasonal component is proportional to the level of the time series.';
export const ANOMALY_ROBUST_PERIOD_DESCRIPTION =
  'The periodicity of the time series (in terms of points).';
export const ANOMALY_ROBUST_SEASONALITY =
  'The seasonality for the cycle of the time series';
export const ANOMALY_AGILE_P_DESCRIPTION =
  'The order of the autoregressive model';
export const ANOMALY_AGILE_D_DESCRIPTION =
  'The degree of differencing (the number of times the data have had past values subtracted)';
export const ANOMALY_AGILE_Q_DESCRIPTION =
  'The order of the moving-average model';
export const ANOMALY_AGILE_SP_DESCRIPTION =
  'The seasonal order of the autoregressive model';
export const ANOMALY_AGILE_SD_DESCRIPTION =
  'The seasonal degree of differencing (the number of times the data have had past values subtracted)';
export const ANOMALY_AGILE_SQ_DESCRIPTION =
  'The seasonal order of the moving-average model';
export const ANOMALY_AGILE_M_DESCRIPTION =
  'The number of periods in each season';
