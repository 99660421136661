import { useLeftSidebarState } from 'components';
import { useFeatureFlag } from 'configcat-react';
import {
  useDateState,
  useRequest,
  useSelectedFacetValuesByNameState,
} from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { getServices } from 'requests';
import { DateSelection } from 'types';
import useDebouncedEffect from 'use-debounced-effect';
import { getColorsByServiceName } from 'utils';
import ServicesMainServices from './ServicesMainServices';
import { ServicesTab } from './utils';

type Props = {
  customerFilter: { key: string; value: string };
  dateState: ReturnType<typeof useDateState>;
  isAsmChecked: boolean;
  leftSidebarState: ReturnType<typeof useLeftSidebarState>;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
  serviceTab: string;
  showInactiveServices: boolean;
  setShowInactiveServices: (showInactiveServices: boolean) => void;
};

const SECONDS_IN_5_MINUTES = 60 * 5;

const subtract5MinutesFromStartTimeUnix = (
  date: DateSelection,
): DateSelection => {
  return {
    ...date,
    startTimeUnix: date.startTimeUnix - SECONDS_IN_5_MINUTES,
  };
};

const ServicesMainServicesContainer = ({
  customerFilter,
  dateState,
  isAsmChecked,
  leftSidebarState,
  selectedFacetValuesByNameState,
  serviceTab,
  showInactiveServices,
  setShowInactiveServices,
}: Props) => {
  const [error, setError] = useState({
    getServices: null,
  });

  const getServicesRequest = useRequest(getServices, true, true);

  const serviceByHash = useMemo(
    () =>
      (getServicesRequest.result || []).reduce(
        (obj, service) => ({ ...obj, [service.hash]: service }),
        {},
      ),
    [getServicesRequest.result],
  );

  const colorsByServiceName = useMemo(
    () => getColorsByServiceName(getServicesRequest.result || []),
    [getServicesRequest.result],
  );

  const [date] = dateState;
  const selectedFacetValuesByName = useMemo(
    () => ({
      ...selectedFacetValuesByNameState.state,
    }),
    [selectedFacetValuesByNameState.state],
  );

  useDebouncedEffect(
    () => {
      let selectedFacetValuesByName = selectedFacetValuesByNameState.state;

      if (serviceTab === ServicesTab.db) {
        selectedFacetValuesByName = {
          ...selectedFacetValuesByName,
          span_type: { db: 1 },
        };
      } else if (serviceTab === ServicesTab.table) {
        selectedFacetValuesByName = {
          ...selectedFacetValuesByName,
          span_type: { db: 0 },
        };
      }
      getServicesRequest
        .call({
          customerFilter: customerFilter,
          date: subtract5MinutesFromStartTimeUnix(date),
          selectedFacetValuesByName: selectedFacetValuesByName,
        })
        .then((nextResult) => {
          if (nextResult) {
            setError((prevError) => ({ ...prevError, getServices: null }));
          }
        })
        .catch(() => {
          setError((prevError) => ({
            ...prevError,
            getServices: { message: 'Failed to fetch services' },
          }));
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    { timeout: 400, ignoreInitialCall: false },
    [customerFilter, date, selectedFacetValuesByNameState.state, serviceTab],
  );

  const { value: isLatencyBoundedToMinMax, loading: latencyConfigLoading } =
    useFeatureFlag('isLatencyBoundedToMinMax', false);

  return !latencyConfigLoading ? (
    <ServicesMainServices
      customerFilter={customerFilter}
      colorsByServiceName={colorsByServiceName}
      dateState={dateState}
      isAsmChecked={isAsmChecked}
      isLatencyBoundedToMinMax={isLatencyBoundedToMinMax}
      leftSidebarState={leftSidebarState}
      selectedFacetValuesByName={selectedFacetValuesByName}
      selectedFacetValuesByNameState={selectedFacetValuesByNameState}
      serviceByHash={serviceByHash}
      showInactiveServices={showInactiveServices}
      setShowInactiveServices={setShowInactiveServices}
      servicesError={error}
    />
  ) : null;
};

export default ServicesMainServicesContainer;
