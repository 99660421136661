import {
  CursorStateProvider,
  DateControls,
  Loader,
  SizeObserver,
} from 'components';
import { useRequest } from 'hooks';
import Datepicker from 'composite/Datepicker';
import React, { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getGrafanaDashboardByUid } from 'requests';

import DashboardFilter from './DashboardFilter';
import {
  DashboardGridline,
  DashboardGridlineProvider,
} from './DashboardGridline';
import DashboardHeader from './DashboardHeader';
import DashboardPanelRender from './DashboardPanelRender';
import DashboardSidebar from './DashboardSidebar';
import { useDashboardState, useDashboardTemplateState } from './hooks';
import { getDateFromRange } from './utils';

const Dashboard = ({
  disableEditPanel = false,
  disableDeletePanel = false,
  disableFilter = false,
  jsonModal,
  hideHeader = false,
  hideSidebar = true,
  showFilterTimepicker = false,
  fetchLimitedTemplates = [],
  shouldWriteToUrl = true,
  uid,
  showReloadButton = false,
}: {
  disableEditPanel?: boolean;
  disableDeletePanel?: boolean;
  disableFilter?: boolean;
  jsonModal?: any;
  hideHeader?: boolean;
  showFilterTimepicker?: boolean;
  hideSidebar?: boolean;
  fetchLimitedTemplates?: { name: string; joinValues?: boolean }[];
  shouldWriteToUrl?: boolean;
  uid?: string;
  showReloadButton?: boolean;
}): ReactElement => {
  const dashboardByUidRequest = useRequest(getGrafanaDashboardByUid);
  const { dashboardId } = useParams();
  const dashboardState = useDashboardState();
  const {
    date,
    dashboardDeleteByUidRequest,
    initialDashboardSetup,
    grafanaDashboardMutateRequest,
    onDateChange,
    panels,
    setPanels,
    setDashboardDetails,
    setDashboardMeta,
  } = dashboardState;

  const dashboardTemplateState = useDashboardTemplateState({
    date,
    disableFilter,
    fetchLimitedTemplates,
    shouldWriteToUrl,
  });
  const { initialTemplateSetup } = dashboardTemplateState;

  const init = (json: any, meta: Record<string, any>) => {
    const { time } = json;
    const isDefaultDate = date.startLabel === 'now-1h';
    if (isDefaultDate) {
      onDateChange(getDateFromRange(time.from, time.to), true);
    } else {
      onDateChange(getDateFromRange(time.from, time.to), true);
    }
    initialTemplateSetup(json?.templating?.list).then(() => {
      initialDashboardSetup(json);
      setDashboardMeta(meta);
    });
  };

  useEffect(() => {
    setPanels([]);
    if (dashboardId === 'new' || dashboardId === 'import') {
      return;
    }
    if (jsonModal) {
      init(jsonModal, {});
      setDashboardDetails({ title: jsonModal.title, description: '' });
    } else if (uid) {
      dashboardByUidRequest
        .call(uid)
        .then((res) => init(res.dashboard, res.meta));
    } else if (dashboardId) {
      dashboardByUidRequest.call(dashboardId).then((result) => {
        init(result.dashboard, result.meta);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonModal]);

  return (
    <Loader
      className="dashboard"
      isLoading={
        grafanaDashboardMutateRequest.isLoading ||
        dashboardDeleteByUidRequest.isLoading
      }
    >
      <CursorStateProvider>
        <SizeObserver>
          {({ height: baseHeight, width: baseWidth }) => (
            <>
              <div className="dashboard__header--sticky">
                {!hideHeader && (
                  <DashboardHeader
                    dashboardState={dashboardState}
                    disableEditPanel={disableEditPanel}
                    dashboardTemplateState={dashboardTemplateState}
                    hideSidebar={hideSidebar}
                  />
                )}
                <div className="flex items-center justify-between">
                  {!disableFilter ? (
                    <DashboardFilter
                      dashboardState={dashboardState}
                      dashboardTemplateState={dashboardTemplateState}
                      showReloadButton={showReloadButton}
                    />
                  ) : (
                    <div></div>
                  )}
                  {showFilterTimepicker ? (
                    <Datepicker
                      className="dashboard__header__right__datepicker"
                      hasStartedLiveTail={false}
                      onChange={onDateChange}
                      startLiveTail={null}
                      value={date}
                    />
                  ) : (
                    <div></div>
                  )}
                  {showReloadButton && (
                    <div className="dashboard__header__right">
                      <DateControls date={date} setDate={onDateChange} />
                    </div>
                  )}
                </div>
              </div>
              <DashboardGridlineProvider>
                {hideSidebar && (
                  <DashboardSidebar
                    dashboardState={dashboardState}
                    dashboardTemplateState={dashboardTemplateState}
                  />
                )}
                <Loader
                  className="dashboard__body"
                  isLoading={dashboardByUidRequest.isLoading}
                  dataTestId="dashboard-body"
                >
                  <DashboardPanelRender
                    baseHeight={baseHeight}
                    baseWidth={baseWidth}
                    dashboardState={dashboardState}
                    dashboardTemplateState={dashboardTemplateState}
                    disableEditPanel={disableEditPanel}
                    disableDeletePanel={disableDeletePanel}
                    nestedIndex={null}
                    panels={panels}
                  />
                  <DashboardGridline />
                </Loader>
              </DashboardGridlineProvider>
            </>
          )}
        </SizeObserver>
      </CursorStateProvider>
    </Loader>
  );
};

export default Dashboard;
