import React from 'react';
import useRumState from './hooks/useRumState';
import RumErrorGroupTable from './RumErrorGroupTable';
import { RumEventType } from './types';
import { DateSelection } from 'types/DateSelection';
import useDateState from 'hooks/useDateState';
import { getIdFilter } from './utils';

type Props = {
  applicationFilter: string;
  className?: string;
  dateFilter: DateSelection;
  idFilter: ReturnType<typeof getIdFilter>;
};

const RumEventDrawerErrorGroup = ({
  applicationFilter,
  dateFilter,
  idFilter,
}: Props) => {
  const dateState = useDateState(dateFilter);
  const errorRumState = useRumState({
    initialApplicationFilter: applicationFilter,
    dateState: dateState,
    initialEventIdFilter: idFilter,
    initialEventType: RumEventType.ERROR,
    shouldWriteToUrl: false,
  });
  return <RumErrorGroupTable rumState={errorRumState} />;
};

export default RumEventDrawerErrorGroup;
