import classnames from 'classnames';
import React, {
  forwardRef,
  HTMLProps,
  MutableRefObject,
  ReactElement,
} from 'react';

const defaultProps = {
  children: null,
} as Partial<Props>;

type Props = {
  children?: ReactElement | ReactElement[];
  className?: string;
  dataTestId?: string;
  isLoading?: boolean;
  size?: 'small' | 'medium' | 'large';
} & HTMLProps<HTMLDivElement>;

const Loader = forwardRef(
  (
    {
      children,
      className,
      dataTestId,
      isLoading,
      size = 'medium',
      ...rest
    }: Props,
    ref: MutableRefObject<HTMLDivElement>,
  ): ReactElement => {
    return (
      <div
        className={classnames({ 'kf-loader': true, [className]: className })}
        ref={ref}
        {...rest}
        data-testid={dataTestId}
      >
        {children ? children : null}
        {isLoading && (
          <div className="kf-loader__bg">
            <div
              className={classnames({
                'kf-loader__spinner': true,
                [`kf-loader__spinner--${size}`]: true,
              })}
            >
              <div className="kf-loader__spinner__rect1" />
              <div className="kf-loader__spinner__rect2" />
              <div className="kf-loader__spinner__rect3" />
              <div className="kf-loader__spinner__rect4" />
              <div className="kf-loader__spinner__rect5" />
            </div>
          </div>
        )}
      </div>
    );
  },
);

Loader.defaultProps = defaultProps;

export default Loader;
