import classNames from 'classnames';
import {
  AutocompleteV2,
  Button,
  InputWithValidatorV3,
  Loader,
  useToaster,
} from 'components';
import { useRequest } from 'hooks';
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Plus } from 'react-feather';

import AlertsMuteTimingEditTimeRange from './AlertsMuteTimingEditTimeRange';
import AlertsMuteTimingEditDaysOfWeek from './AlertsMuteTimingEditDaysOfWeek';
import AlertsMuteTimingEditDaysOfMonth from './AlertsMuteTimingEditDaysOfMonth';
import {
  getGrafanaAlertManagerMuteTiming,
  mutateGrafanaAlertManagerMuteTiming,
} from './requests';
import { AlertMuteTimingProps } from './types';
import {
  defaultMuteTiming,
  defaultTimeInterval,
  getTimeZonesOption,
  validateMuteTimingInterval,
} from './utils';
import { parseUrlParamByKey } from 'utils/url';

const allTimeZones = getTimeZonesOption();

const LocationSection = ({
  muteInterval,
  setMuteTiming,
  intervalIdx,
}: {
  muteInterval: AlertMuteTimingProps['time_intervals'][0];
  setMuteTiming: Dispatch<SetStateAction<AlertMuteTimingProps>>;
  intervalIdx: number;
}) => {
  return (
    <div className="flex flex-col pt-2">
      <div className="text-sm font-semibold">Location</div>
      <div className="pb-1 text-xs text-text-secondary">
        Select the location to mute the alert
      </div>
      <AutocompleteV2
        className="max-w-[380px] rounded-sm"
        options={allTimeZones}
        value={muteInterval.location}
        onChange={(val) => {
          setMuteTiming((prev) => {
            const newState = { ...prev };
            const newIntervals = { ...newState.time_intervals[intervalIdx] };
            newIntervals.location = val as string;
            newState.time_intervals[intervalIdx] = newIntervals;
            return newState;
          });
        }}
      />
    </div>
  );
};

const MonthsSection = ({
  clearErrorByIndex,
  errorText,
  intervalIdx,
  muteInterval,
  setMuteInterval,
}: {
  clearErrorByIndex: (idx: number, key: string) => void;
  errorText: string;
  intervalIdx: number;
  muteInterval: AlertMuteTimingProps['time_intervals'][0];
  setMuteInterval: Dispatch<SetStateAction<AlertMuteTimingProps>>;
}) => {
  const { months } = muteInterval;
  const [monthsOfYear, setMonthsOfYear] = useState<string>(months.join(', '));

  const handleMonthOfYearChange = (val: string) => {
    setMuteInterval((prev) => {
      const newState = { ...prev };
      const newIntervals = { ...newState.time_intervals[intervalIdx] };
      newIntervals.months = val
        .split(',')
        .map((d) => d.trim())
        .filter(Boolean);
      newState.time_intervals[intervalIdx] = newIntervals;
      setMonthsOfYear(newIntervals.months.join(', '));
      return newState;
    });
  };

  return (
    <div className="flex flex-col pt-2">
      <div className="text-sm font-semibold">Months</div>
      <div className="pb-1 text-xs text-text-secondary">
        The months of the year in either numerical or the full calendar month
      </div>
      <div className="max-w-[380px]">
        <InputWithValidatorV3
          className=" rounded-sm"
          placeholder="Example: 1:3, may:august, december"
          size="4"
          type="text"
          variant="default"
          onChange={(val) => {
            setMonthsOfYear(val);
            clearErrorByIndex(intervalIdx, 'months');
          }}
          value={monthsOfYear}
          onBlur={() => handleMonthOfYearChange(monthsOfYear)}
          error={errorText}
          errorMessageShownType="inline"
        />
      </div>
    </div>
  );
};

const YearsSection = ({
  errorText,
  clearErrorByIndex,
  muteInterval,
  setMuteInterval,
  intervalIdx,
}: {
  errorText: string;
  clearErrorByIndex: (idx: number, key: string) => void;
  muteInterval: AlertMuteTimingProps['time_intervals'][0];
  setMuteInterval: Dispatch<SetStateAction<AlertMuteTimingProps>>;
  intervalIdx: number;
}) => {
  const { years } = muteInterval;
  const [yearsOfCentury, setYearsOfCentury] = useState<string>(
    years.join(', '),
  );

  const handleYearOfCenturyChange = (val: string) => {
    setMuteInterval((prev) => {
      const newState = { ...prev };
      const newIntervals = { ...newState.time_intervals[intervalIdx] };
      newIntervals.years = val
        .split(',')
        .map((d) => d.trim())
        .filter(Boolean);
      newState.time_intervals[intervalIdx] = newIntervals;
      setYearsOfCentury(newIntervals.years.join(', '));
      return newState;
    });
  };

  return (
    <div className="flex flex-col pt-2">
      <div className="text-sm font-semibold">Years of Century</div>
      <div className="pb-1 text-xs text-text-secondary">
        Select the years of the century to mute the alert
      </div>
      <div className="max-w-[380px]">
        <InputWithValidatorV3
          className="rounded-sm"
          placeholder="Example: 2023, 2024:2025, 2050"
          size="4"
          type="text"
          variant="default"
          onChange={(val) => {
            setYearsOfCentury(val);
            clearErrorByIndex(intervalIdx, 'years');
          }}
          value={yearsOfCentury}
          onBlur={() => handleYearOfCenturyChange(yearsOfCentury)}
          error={errorText}
          errorMessageShownType="inline"
        />
      </div>
    </div>
  );
};

const AlertsMuteTimingEdit = (): ReactElement => {
  const { name } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToaster();
  const newParam = parseUrlParamByKey('new');
  const isNewMuteTiming = name === 'new' && newParam === true;
  const muteTimingRequest = useRequest(
    getGrafanaAlertManagerMuteTiming,
    true,
    true,
  );
  const mutateMuteTimingRequest = useRequest(
    mutateGrafanaAlertManagerMuteTiming,
    true,
    true,
  );
  const [muteTiming, setMuteTiming] = useState<AlertMuteTimingProps | null>(
    defaultMuteTiming,
  );
  const [error, setError] = useState<{
    [key: number]: { [key: string]: string };
  } | null>(null);
  const [nameError, setNameError] = useState<string | null>(null);

  const onSaveMuteTiming = async () => {
    if (muteTiming.name === '') {
      setNameError('Mute timing name is required');
      return;
    }
    const error = validateMuteTimingInterval(muteTiming.time_intervals);
    if (error) {
      setError(error);
      return;
    }

    const filteredMuteTiming: AlertMuteTimingProps = { ...muteTiming };
    filteredMuteTiming.time_intervals = muteTiming.time_intervals.map(
      (interval) => ({
        ...interval,
        times: interval.times.filter(
          (time) => time.start_time && time.end_time,
        ),
      }),
    );

    try {
      const res = await muteTimingRequest.call(false);
      const payload = {
        ...res,
        alertmanager_config: {
          ...res.alertmanager_config,
          mute_time_intervals: [...res.alertmanager_config.mute_time_intervals],
        },
      };

      if (isNewMuteTiming) {
        const isNameExists =
          payload.alertmanager_config.mute_time_intervals.some(
            (timing: AlertMuteTimingProps) => timing.name === muteTiming.name,
          );
        if (isNameExists) {
          setNameError('Mute timing name already exists');
          return;
        }
        payload.alertmanager_config.mute_time_intervals.push(
          filteredMuteTiming,
        );
      } else {
        const existingMuteIndex =
          payload.alertmanager_config.mute_time_intervals.findIndex(
            (timing: AlertMuteTimingProps) => timing.name === name,
          );
        payload.alertmanager_config.mute_time_intervals[existingMuteIndex] =
          filteredMuteTiming;
      }

      const result = await mutateMuteTimingRequest.call(payload);
      if (result) {
        addToast({
          text: `Mute timing ${isNewMuteTiming ? 'created' : 'updated'} successfully`,
          status: 'success',
        });
        navigate('/alerts/mute-timing');
      }
    } catch (error) {
      addToast({
        text: `Failed to ${isNewMuteTiming ? 'create' : 'update'} mute timing`,
        status: 'error',
      });
    }
  };

  const addNewTimeInterval = () => {
    setMuteTiming((prev) => ({
      ...prev,
      time_intervals: [...prev.time_intervals, defaultTimeInterval],
    }));
  };

  const deleteTimeInterval = (idx: number) => {
    setMuteTiming((prev) => ({
      ...prev,
      time_intervals: prev.time_intervals.filter((_, i) => i !== idx),
    }));
  };

  const clearErrorByIndex = (idx: number, key: string) => {
    setError((prev) => {
      const newState = { ...prev };
      if (!newState) return newState;
      if (!newState[idx]) return newState;
      if (!newState[idx][key]) return newState;
      delete newState[idx][key];
      return newState;
    });
  };

  useEffect(() => {
    muteTimingRequest.call().then((res) => {
      if (!res) return;
      const muteTiming = res.find(
        (timing: AlertMuteTimingProps) => timing.name === name,
      );
      if (!muteTiming) return;
      const muteTimingSanitized = {
        ...muteTiming,
        time_intervals: muteTiming.time_intervals.map((interval) => ({
          ...interval,
          times: interval.times ? interval.times : defaultTimeInterval.times,
          weekdays: interval.weekdays ? interval.weekdays : [],
          months: interval.months ? interval.months : [],
          years: interval.years ? interval.years : [],
          days_of_month: interval.days_of_month ? interval.days_of_month : [],
        })),
      };
      setMuteTiming(muteTimingSanitized);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mx-auto max-w-[1200px] py-4">
      <div className="flex items-center justify-between pb-2">
        <h2 className="whitespace-nowrap text-lg font-semibold">
          {isNewMuteTiming ? 'Create' : 'Edit'} Mute Timing
        </h2>
      </div>
      <div>
        <Loader
          isLoading={
            muteTimingRequest.isLoading || mutateMuteTimingRequest.isLoading
          }
        >
          {muteTiming ? (
            <div>
              <div className="mb-2 flex items-center justify-between">
                <div>
                  <div className="mb-1 text-xs font-medium">
                    Mute Timing Name
                  </div>
                  <InputWithValidatorV3
                    className="min-w-[396px] rounded-sm"
                    placeholder="Mute Timing Name"
                    size="5"
                    type="text"
                    variant="default"
                    value={muteTiming.name}
                    disabled={!isNewMuteTiming}
                    onChange={(val) => {
                      setMuteTiming((prev) => ({ ...prev, name: val }));
                      setNameError(null);
                    }}
                    error={nameError}
                    errorMessageShownType="inline"
                  />
                </div>
                <Button onClick={onSaveMuteTiming} variant="default" size="sm">
                  Save Mute Timing
                </Button>
              </div>
              {muteTiming.time_intervals.map((interval, idx) => {
                return (
                  <div
                    key={idx}
                    className={classNames({
                      'admin__row-muted--lighter p-4 shadow-sm': true,
                      'mb-6': idx !== muteTiming.time_intervals.length - 1,
                      'mb-4': idx === muteTiming.time_intervals.length - 1,
                    })}
                  >
                    <AlertsMuteTimingEditTimeRange
                      deleteTimeInterval={deleteTimeInterval}
                      muteInterval={interval}
                      setMuteTiming={setMuteTiming}
                      intervalIdx={idx}
                      clearErrorByIndex={clearErrorByIndex}
                      errorByIndex={error?.[idx]}
                    />
                    <LocationSection
                      muteInterval={interval}
                      setMuteTiming={setMuteTiming}
                      intervalIdx={idx}
                    />
                    <AlertsMuteTimingEditDaysOfWeek
                      muteInterval={interval}
                      setMuteInterval={setMuteTiming}
                      intervalIdx={idx}
                    />
                    <AlertsMuteTimingEditDaysOfMonth
                      clearErrorByIndex={clearErrorByIndex}
                      errorText={error?.[idx]?.days_of_month}
                      intervalIdx={idx}
                      muteInterval={interval}
                      setMuteInterval={setMuteTiming}
                    />
                    <MonthsSection
                      clearErrorByIndex={clearErrorByIndex}
                      errorText={error?.[idx]?.months}
                      intervalIdx={idx}
                      muteInterval={interval}
                      setMuteInterval={setMuteTiming}
                    />
                    <YearsSection
                      clearErrorByIndex={clearErrorByIndex}
                      errorText={error?.[idx]?.years}
                      intervalIdx={idx}
                      muteInterval={interval}
                      setMuteInterval={setMuteTiming}
                    />
                  </div>
                );
              })}
            </div>
          ) : null}
        </Loader>
      </div>
      <Button onClick={addNewTimeInterval} variant="default" size="sm">
        <Plus size={16} className="mr-1" /> Add another time interval
      </Button>
    </div>
  );
};

export default AlertsMuteTimingEdit;
