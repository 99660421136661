import { FacetRegexTerm } from 'types';
import { FacetRegexFilter, Filter, FilterType } from './types';
import useFiltersState from './useFiltersState';
import useFacetRegexState from '../useFacetRegexState';

const getFacetRegexFilter = (facetRegex: FacetRegexTerm): FacetRegexFilter => ({
  disabled: false,
  type: FilterType.facetRegex,
  value: facetRegex,
});

export const getLegacyFacetRegexState = (
  state: Filter[],
): ReturnType<typeof useFacetRegexState>['state'] => {
  return state
    .filter((filter) => filter.type === FilterType.facetRegex)
    .map((filter: FacetRegexFilter) => filter.value);
};

const getFacetRegexState = (
  filtersState: ReturnType<typeof useFiltersState>,
): ReturnType<typeof useFacetRegexState> => {
  const { state, setState } = filtersState;
  const add = (nextTerm: FacetRegexTerm) => {
    setState((prevState) => [...prevState, getFacetRegexFilter(nextTerm)]);
  };

  const removeByIndex = (index: number) => {
    setState((prevState) => {
      const nextState = [...prevState];
      const facetRegexFilters = prevState.filter(
        (filter) => filter.type === FilterType.facetRegex,
      );

      if (index < facetRegexFilters.length) {
        const filter = facetRegexFilters[index];
        const filterIndex = prevState.findIndex(
          (prevFilter) => prevFilter === filter,
        );

        if (filterIndex > -1) {
          nextState.splice(filterIndex, 1);
        }
      }
      return nextState;
    });
  };

  return {
    add,
    removeByIndex,
    state: getLegacyFacetRegexState(state),
  };
};

export default getFacetRegexState;
