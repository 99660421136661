import classnames from 'classnames';
import { useToggle } from 'hooks';
import React from 'react';
import { VALUE } from './constants';
import * as Shadcn from '../shadcn';

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
  forceOpen?: boolean;
  isOpenToggle: ReturnType<typeof useToggle>;
};

const AccordionRoot = ({
  children,
  className,
  forceOpen,
  isOpenToggle,
}: Props) => {
  return (
    <Shadcn.Accordion
      className={classnames({ accordion: true, [className]: className })}
      collapsible
      defaultValue={forceOpen ? VALUE : null}
      onValueChange={(nextValue) => {
        isOpenToggle.set(nextValue === VALUE);
      }}
      type="single"
    >
      <Shadcn.AccordionItem className="accordion__item" value={VALUE}>
        {children}
      </Shadcn.AccordionItem>
    </Shadcn.Accordion>
  );
};

export default AccordionRoot;
