import { AutocompleteOption } from 'components/Autocomplete';
import { DateSelection } from 'types/DateSelection';
import { getRollupToSecond, getRollupByVisualization } from 'utils';

export const logsTopBottomCountOptions: AutocompleteOption[] = [
  { value: '2', label: 2 },
  { value: '5', label: 5 },
  { value: '10', label: 10 },
  { value: '15', label: 15 },
  { value: '25', label: 25 },
  { value: '30', label: 30 },
  { value: '50', label: 50 },
  { value: '100', label: 100 },
];

const logsRollupOptions = [
  { value: '1s', label: '1s' },
  { value: '2s', label: '2s' },
  { value: '5s', label: '5s' },
  { value: '10s', label: '10s' },
  { value: '15s', label: '15s' },
  { value: '20s', label: '20s' },
  { value: '30s', label: '30s' },
  { value: '1m', label: '1m' },
  { value: '2m', label: '2m' },
  { value: '5m', label: '5m' },
  { value: '10m', label: '10m' },
  { value: '15m', label: '15m' },
  { value: '20m', label: '20m' },
  { value: '25m', label: '25m' },
  { value: '30m', label: '30m' },
  { value: '45m', label: '45m' },
  { value: '1h', label: '1h' },
  { value: '2h', label: '2h' },
  { value: '4h', label: '4h' },
  { value: '6h', label: '6h' },
  { value: '8h', label: '8h' },
  { value: '12h', label: '12h' },
  { value: '1d', label: '1d' },
  { value: '2d', label: '2d' },
  { value: '4d', label: '4d' },
  { value: '7d', label: '7d' },
];

export const logsRollupOptionsByRange = (
  date: DateSelection,
  minStep: string,
): AutocompleteOption[] => {
  const { startTimeUnix, endTimeUnix } = date;
  const timeDiff = endTimeUnix - startTimeUnix;
  const step = getRollupByVisualization(date, 'bar');
  const minStepSecond = getRollupToSecond(minStep);

  const dataPoints = Math.floor(timeDiff / step);
  // min roll up time
  const minTimeSecond = Math.floor(timeDiff / Math.min(dataPoints, 1440));
  // max roll up time
  const maxTimeSecond = Math.floor(timeDiff / 2);

  const options: AutocompleteOption[] = [];
  logsRollupOptions.forEach((option) => {
    const rollup = getRollupToSecond(option.value);
    if (rollup < minStepSecond) return;
    if (rollup >= minTimeSecond && rollup <= maxTimeSecond) {
      options.push(option);
    }
  });
  return options;
};
