import { JsonType } from 'components';
import dayjs from 'dayjs';
import React from 'react';

type Props = {
  label: string;
  type: JsonType;
  value: any;
};

const TraceSidebarActiveSpanJsonValue = ({ label, type, value }: Props) => {
  if (label.indexOf('Ns') > -1 && label !== 'durationNs') {
    return (
      <>
        <span className={`text--${type}`}>{String(value)}</span>
        <span className="trace-sidebar__active-span__json__time">
          {` (${dayjs(value / 1000000).format('MMMM DD HH:mm a')})`}
        </span>
      </>
    );
  }

  return <div className={`text--${type}`}>{String(value)}</div>;
};

export default TraceSidebarActiveSpanJsonValue;
