import classnames from 'classnames';
import { LeftSidebar, ShowSidebarTooltipButton } from 'components';
import { Datepicker } from 'composite';
import { CalendarPickerTimezone } from 'composite/CalendarPicker';
import React from 'react';
import { GrList } from 'react-icons/gr';
import { BiArchive } from 'react-icons/bi';
import { RefreshCw } from 'react-feather';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { refreshDate } from 'utils';

import LogsHydrationArchives from './LogsHydrationArchives';
import LogsHydrationJobsList from './LogsHydrationJobsList';
import { LogsHydrationSidebar } from './LogsHydrationSidebar';
import useLogsHydrationState from './useLogsHydrationState';
import {
  getHydrationJobQuickRangeOptions,
  LogsHydrationArchivesFacet,
  LogsHydrationFacet,
} from './utils';

const getTabs = ({
  tab,
  navigate,
}: {
  tab: string;
  navigate: NavigateFunction;
}) => {
  return [
    {
      key: 'archives',
      label: 'Archives',
      isActive: tab === 'archives',
      icon: <BiArchive size={16} />,
      onClick: () => navigate(`/logs/hydration/archives`),
    },
    {
      key: 'jobs',
      icon: <GrList size={14} />,
      isActive: tab === 'jobs',
      label: 'Jobs',
      onClick: () => navigate(`/logs/hydration/jobs`),
    },
  ];
};

const LogsHydration = () => {
  const logsHydrationState = useLogsHydrationState();
  const {
    cloudType,
    date,
    getPredefinedFacetValuesArchives,
    getPredefinedFacetValuesJobs,
    hydrationProperties,
    hydrationPropertiesArchives,
    leftSidebarState,
    selectedFacetValuesByNameStateArchives,
    selectedFacetValuesByNameStateJobs,
    setDate,
  } = logsHydrationState;
  const navigate = useNavigate();
  const { tab } = useParams();

  const tabs = getTabs({ tab, navigate });
  return (
    <div className="alerts">
      <LeftSidebar
        className="alerts__left-sidebar"
        leftSidebarState={leftSidebarState}
      >
        {tab === 'jobs' && (
          <LogsHydrationSidebar
            facetNames={LogsHydrationFacet}
            getPredefinedFacetValues={getPredefinedFacetValuesJobs}
            hydrationProperties={hydrationProperties}
            selectedFacetValuesByNameState={selectedFacetValuesByNameStateJobs}
          />
        )}
        {tab === 'archives' && (
          <LogsHydrationSidebar
            facetNames={LogsHydrationArchivesFacet(cloudType)}
            getPredefinedFacetValues={getPredefinedFacetValuesArchives}
            hydrationProperties={hydrationPropertiesArchives}
            selectedFacetValuesByNameState={
              selectedFacetValuesByNameStateArchives
            }
          />
        )}
      </LeftSidebar>
      <div className="alerts__main px-4">
        <div className="flex items-center justify-between py-2">
          <div className="flex items-center">
            {leftSidebarState.width === 0 ? (
              <ShowSidebarTooltipButton onClick={leftSidebarState.show} />
            ) : null}
            <h2 className="text-lg font-semibold">Logs Hydration</h2>
          </div>
          {tab === 'jobs' ? (
            <div className="flex items-center">
              <Datepicker
                className="logs__search__datepicker"
                hasStartedLiveTail={false}
                onChange={setDate}
                quickRangeOptions={getHydrationJobQuickRangeOptions()}
                value={date}
              />
              <button
                className="new-metrics__header__refresh-button"
                onClick={() => refreshDate(date, setDate)}
              >
                <RefreshCw size={14} />
              </button>
            </div>
          ) : (
            <div className="flex rounded-sm border py-1 pl-2">
              <CalendarPickerTimezone keepTimezoneText={false} />
            </div>
          )}
        </div>
        <div className="mt-2 flex h-8 items-center">
          {tabs.map((item) => (
            <button
              className={classnames({
                logs__tabs__item: true,
                'logs__tabs__item--active': item.key === tab,
              })}
              key={item.key}
              onClick={() => {
                if (item.key === 'jobs') {
                  refreshDate(date, setDate);
                }
                item.onClick();
              }}
            >
              <div className="logs__tabs__item__icon">{item.icon}</div>
              {item.label}
            </button>
          ))}
        </div>
        {tab === 'archives' && (
          <LogsHydrationArchives logsHydrationState={logsHydrationState} />
        )}
        {tab === 'jobs' && (
          <LogsHydrationJobsList logsHydrationState={logsHydrationState} />
        )}
      </div>
    </div>
  );
};

export default LogsHydration;
