import query from 'requests/query';

type Args = {
  datatype: string;
  displayName: string;
  facet: string;
  facetGroup: string;
  source?: string;
};

const addLogsFavoriteFacet = async ({
  datatype,
  displayName,
  facet,
  facetGroup,
  source = '',
}: Args): Promise<Record<'addFavoriteFacet', void>> => {
  return query<void, 'addFavoriteFacet'>(`
    mutation {
      addFavoriteFacet (
        source: "${source}"
        facet: "${facet}"
        facetGroup: "${facetGroup}"
        displayName: "${displayName}"
        datatype: "${datatype}"
      )
    }
  `);
};

export default addLogsFavoriteFacet;
