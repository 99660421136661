import classNames from 'classnames';
import { Button, InputWithValidatorV3, TooltipTrigger } from 'components';
import { useLogsMetricsQueryState } from 'hooks';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { XCircle } from 'react-feather';
import { ALLOW_ONLY_ONE_ACTIVE_SEARCH } from 'screens/Traces/utils';
import { DashboardPanelType } from 'types/Dashboard';
import { LogsMetricForumlaProps } from 'types/LogsMetricsQueryBuilder';
import { validateLogqlFormulaExpression } from 'utils';

const LogsMetricsQueryBuilderFormula = ({
  activeVisualization,
  formula,
  index,
  logsMetricsQueryState,
  queryKeys,
}: {
  activeVisualization?: string;
  formula: LogsMetricForumlaProps;
  index: number;
  logsMetricsQueryState: ReturnType<typeof useLogsMetricsQueryState>;
  queryKeys: string[];
}): ReactElement => {
  const {
    queries,
    removeFormula,
    updateFormula,
    activateOnlySingleFormula,
    deactivateAllQueries,
  } = logsMetricsQueryState;

  const isFormulaValid = useMemo(() => {
    if (!formula.expression) {
      return '';
    }
    const isValid = validateLogqlFormulaExpression(
      formula.expression,
      queryKeys,
      queries,
    );
    if (typeof isValid === 'string') {
      return isValid;
    }
    if (isValid === false) {
      return 'Invalid expression';
    }
    return '';
  }, [formula.expression, queryKeys, queries]);

  const formulaKeyClickHandler = useCallback(() => {
    const activateOnlySingleQuery = ALLOW_ONLY_ONE_ACTIVE_SEARCH.includes(
      activeVisualization as DashboardPanelType,
    );
    if (activateOnlySingleQuery) {
      if (formula.isActive) {
        updateFormula(index, 'isActive', !formula.isActive);
      } else {
        activateOnlySingleFormula(index);
        const countOfSearches = queries.filter(
          (query) => query.isActive,
        ).length;
        if (countOfSearches > 0) {
          deactivateAllQueries();
        }
      }
    } else {
      updateFormula(index, 'isActive', !formula.isActive);
    }
  }, [
    activateOnlySingleFormula,
    activeVisualization,
    deactivateAllQueries,
    formula.isActive,
    index,
    queries,
    updateFormula,
  ]);

  return (
    <div key={index} className="metrics__query-builder__formula">
      <div>
        <div className="metrics__query-builder__formula__item">
          <div
            className={classNames({
              'metrics__query-builder__query-item__query-key': true,
              'metrics__query-builder__query-item__query-key--inactive':
                !formula.isActive,
            })}
            onClick={formulaKeyClickHandler}
          >
            {formula.queryKey}
          </div>
          <InputWithValidatorV3
            className="metrics__query-builder__formula__item__input"
            error={isFormulaValid}
            placeholder="Add formula example - 2*a"
            onChange={(val) => updateFormula(index, 'expression', val)}
            value={formula.expression}
            type="text"
            size="5"
            variant="default"
          />
        </div>
      </div>
      <div className="metrics__query-builder__query-action">
        <TooltipTrigger tooltip="Remove formula">
          <Button
            className="metrics__query-builder__query-action__icon--delete h-full px-1.5"
            variant="icon"
            size="sm"
          >
            <XCircle
              onClick={() => removeFormula(formula.queryKey)}
              size={16}
            />
          </Button>
        </TooltipTrigger>
      </div>
    </div>
  );
};

export default LogsMetricsQueryBuilderFormula;
