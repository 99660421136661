import { MutableRefObject, useEffect } from 'react';

type Args = {
  onClick: (e: Event) => void;
  ref: MutableRefObject<HTMLElement>;
  shouldCancel?: (e: Event) => boolean;
  shouldUseClick?: boolean;
};

const useOnOutsideClick = ({
  ref,
  onClick,
  shouldCancel = () => false,
  shouldUseClick,
}: Args) => {
  useEffect(() => {
    const listener = (event: Event) => {
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        shouldCancel(event)
      ) {
        return;
      }
      onClick(event);
    };

    const event = shouldUseClick ? 'click' : 'mousedown';

    document.addEventListener(event, listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener(event, listener);
      document.removeEventListener('touchstart', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, onClick]);
};

export default useOnOutsideClick;
