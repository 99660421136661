import {
  LegendsCompactOneLine,
  Timeseries,
  TooltipCompact,
  useCursorContextState,
  useTimeseries,
} from 'components';
import { TimeseriesAnnotation } from 'components/Timeseries';
import { useForm, useRequest } from 'hooks';
import React, { ReactElement, useEffect, useMemo } from 'react';
import {
  Chart,
  DateSelection,
  KfuseHooksAnnotationProps,
  KfuseHooksProps,
  LayoutType,
  TimeseriesRenderProps,
  UplotExtended,
} from 'types';
import {
  addEventOverlayDom,
  drawOutlierSpan,
  getEventPopoverPosition,
  getDrawnEventTooltip,
  setDateRangeOnChartZoom,
} from 'utils';

import ChartGridOutlierSpanActions from './ChartGridOutlierSpanActions';
import ChartGridOutlierSpanTooltip from './ChartGridOutlierSpanTooltip';
import useChartGridItemData from './useChartGridItemData';
import { getOulierSpanFromTraces } from './utils';
import { drawChartAreaRUMLCP } from 'utils/Timeseries';
import { drawChartAreaRUMCLS } from 'utils/Timeseries';
import { drawChartAreaRUMINP } from 'utils/Timeseries';

type Props = {
  chart: Chart;
  chartGridItemData: ReturnType<typeof useChartGridItemData>;
  date: DateSelection;
  form: ReturnType<typeof useForm>;
  width: number;
  tooltipProps: { layoutType: LayoutType };
};

const emptyData = { data: [], series: [] };

const ChartGridItemTimeseries = ({
  chart,
  chartGridItemData,
  date,
  form,
  width,
  tooltipProps = null,
}: Props): ReactElement => {
  const { cursorState, setCursorState } = useCursorContextState();
  const {
    chartType,
    renderComponent,
    limitTo,
    queryEventOverlay,
    onSetSidebar,
    onSelection,
    unit,
  } = chart;
  const queryEventOverlayRequest = useRequest(queryEventOverlay, true, true);
  const { queryRangeRequest } = chartGridItemData;
  const {
    isAnomalyEnabled,
    isLogScaleEnabled,
    isScaledToPercentage,
    shouldShowTop5,
  } = form.values;

  const timeseriesResult = useMemo(() => {
    if (!queryRangeRequest.result) return emptyData;
    if (!queryRangeRequest.result.data || !queryRangeRequest.result.series)
      return emptyData;

    if (shouldShowTop5) {
      const { data, series, ...rest } = queryRangeRequest.result;
      const top5Data = data.slice(0, limitTo + 1 || 6);
      const top5Series = series.slice(0, limitTo || 5);
      return { data: top5Data, series: top5Series, ...rest };
    }

    return queryRangeRequest.result;
  }, [limitTo, queryRangeRequest.result, shouldShowTop5]);

  const chartTypes = useMemo(() => {
    if (isAnomalyEnabled) return ['Line'];
    if (chartType === 'bar') return ['Stacked Bar'];
    if (chartType === 'area') return ['Area'];
    return ['Line'];
  }, [chartType, isAnomalyEnabled]);

  const chartScale = useMemo(() => {
    if (isLogScaleEnabled) return 'log';
    if (isScaledToPercentage) return 'percent';
    return 'linear';
  }, [isLogScaleEnabled, isScaledToPercentage]);

  const LCP = chart.key.slice(3).trim() === 'LargestContentfulPaint';
  const CLS = chart.key.slice(3).trim() === 'CumulativeLayoutShift';
  const INP = chart.key.slice(3).trim() === 'InteractionToNextPaint';
  const uplotHooks = useMemo(() => {
    const hooks = [
      ...(timeseriesResult.hooks || []),
      {
        hook: (u: UplotExtended) => setDateRangeOnChartZoom(u, onSelection),
        type: 'setSelect',
      },
      {
        hook: (u: UplotExtended) => {
          const { eventOverlayCtx, eventOverlayDiv } = addEventOverlayDom(
            width,
            200,
          );
          u.root.childNodes[0].appendChild(eventOverlayDiv);
          u.eventOverlayCtx = eventOverlayCtx;
        },
        type: 'init',
      },
    ];

    if (form.values.isSpanOverlayEnabled && queryEventOverlayRequest.result) {
      const outlierSpanData = getOulierSpanFromTraces(
        queryEventOverlayRequest.result || [],
      );
      hooks.push({
        hook: (u: UplotExtended) => drawOutlierSpan({ outlierSpanData, u }),
        type: 'draw',
      });
    }

    // Draw chart area color for LCP
    if (LCP) {
      hooks.push({
        hook: (u: UplotExtended) => {
          const conditionOperator = {
            greenOperator: 'lt',
            redOperator: 'gt',
          };
          drawChartAreaRUMLCP({
            u,
            conditionValue: {
              greenThreshold: 2.5,
              redThreshold: 4,
            },
            conditionOperator,
            unit: 's',
          });
        },
        type: 'draw',
      });
    }

    // Draw chart area color for CLS
    if (CLS) {
      hooks.push({
        hook: (u: UplotExtended) => {
          const conditionOperator = {
            greenOperator: 'lt',
            redOperator: 'gt',
          };
          drawChartAreaRUMCLS({
            u,
            conditionValue: {
              greenThreshold: 0.1,
              redThreshold: 0.25,
            },
            conditionOperator,
            unit: '',
          });
        },
        type: 'draw',
      });
    }
    // Draw chart area color for INP
    if (INP) {
      hooks.push({
        hook: (u: UplotExtended) => {
          const conditionOperator = {
            greenOperator: 'lt',
            redOperator: 'gt',
          };
          drawChartAreaRUMINP({
            u,
            conditionValue: {
              greenThreshold: 200,
              redThreshold: 500,
            },
            conditionOperator,
            unit: 'ms',
          });
        },
        type: 'draw',
      });
    }

    return hooks;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form.values.isSpanOverlayEnabled,
    onSelection,
    queryEventOverlayRequest.result,
    timeseriesResult?.hooks,
    width,
  ]);

  const kfuseHooks = useMemo(() => {
    const hooks = {
      kfuseTooltip: [
        ({ u }: KfuseHooksProps) => {
          const tooltip = getDrawnEventTooltip(u);
          if (!tooltip) return;

          return {
            renderTooltip: () => (
              <ChartGridOutlierSpanTooltip tooltip={tooltip} />
            ),
          };
        },
      ],
      kfuseAnnotation: [],
    };

    if (form.values.isSpanOverlayEnabled && queryEventOverlayRequest.result) {
      hooks.kfuseAnnotation = [
        ({ u, e, close }: KfuseHooksAnnotationProps) => {
          const coords = getEventPopoverPosition(e, u);
          if (!coords) return;

          return (
            <ChartGridOutlierSpanActions
              coords={coords}
              close={close}
              onSetSidebar={onSetSidebar}
              queryEventOverlayList={queryEventOverlayRequest.result}
            />
          );
        },
      ];
    }

    return hooks;
  }, [
    form.values.isSpanOverlayEnabled,
    onSetSidebar,
    queryEventOverlayRequest.result,
  ]);

  const timeseriesProp: TimeseriesRenderProps = useMemo(() => {
    const prop = {
      date,
      bands: timeseriesResult.bands || [],
      chartData: timeseriesResult,
      chartTypes,
      cursorState,
      hooks: uplotHooks,
      layoutType: tooltipProps?.layoutType,
      onCursorStateChange: setCursorState,
      size: { width: width, height: 200 },
      styles: {
        scaleDistribution: { type: chartScale },
      },
      unit: isScaledToPercentage ? 'percent' : unit,
      kfuseHook: kfuseHooks,
    };

    return prop;
  }, [
    chartScale,
    chartTypes,
    cursorState,
    date,
    isScaledToPercentage,
    kfuseHooks,
    setCursorState,
    timeseriesResult,
    tooltipProps?.layoutType,
    unit,
    uplotHooks,
    width,
  ]);
  const timeseries = useTimeseries(timeseriesProp);

  useEffect(() => {
    if (!queryEventOverlay || !form.values.isSpanOverlayEnabled) return;
    queryEventOverlayRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryRangeRequest.result, form.values.isSpanOverlayEnabled]);

  return (
    <>
      <Timeseries
        {...timeseriesProp}
        isLoading={
          queryRangeRequest.isLoading || queryEventOverlayRequest.isLoading
        }
        timeseries={timeseries}
        renderAnnotation={(prop) => (
          <TimeseriesAnnotation config={prop.config} />
        )}
        renderLegend={({
          config,
          onLegendItemClick,
          onFocusSeries,
          updateChartSize,
        }) => (
          <LegendsCompactOneLine
            config={config}
            onFocusSeries={onFocusSeries}
            onItemClick={onLegendItemClick}
            updateChartSize={updateChartSize}
            legendHeight={120}
          />
        )}
        renderComponent={(prop) => (
          <>{renderComponent && renderComponent(prop)}</>
        )}
        renderTooltip={({
          activeChart,
          config,
          cursorState,
          onCursorStateChange,
          unit,
          ...rest
        }) => (
          <TooltipCompact
            chartType={activeChart}
            config={config}
            cursorState={cursorState}
            onCursorStateChange={onCursorStateChange}
            unit={unit}
            {...tooltipProps}
            {...rest}
          />
        )}
      />
    </>
  );
};

export default ChartGridItemTimeseries;
