import { SearchBar } from 'components';
import { useAutocompleteState } from 'hooks';
import React, { ReactElement, useMemo } from 'react';
import {
  buildSearchFilterTags,
  eventQueryOperator,
  parsePartialSearchQuery,
  parseValidSearchQuery,
} from 'utils';
import {
  FilterTagsProps,
  FilterTagsStateAndMethods,
  SearchItemProps,
} from 'types';

import useServerlessState from 'screens/Serverless/hooks/useServerlessState';

const ServerlessSearchBar = ({
  serverlessState,
}: {
  serverlessState: ReturnType<typeof useServerlessState>;
}): ReactElement => {
  const { filterState, getFacetValueCountsRequest } = serverlessState;

  const { getServerlessLabelValuesOptions, getServerlessFacetValuesOptions } =
    serverlessState;
  const {
    addFilter,
    filter,
    filterStructure,
    getMethodAndStateByFilterKey,
    updateFilterByIndex,
    updateMappedFilterByKey,
  } = filterState;

  const initialOptions = useMemo(
    () =>
      (getFacetValueCountsRequest.result || []).map((item) => ({
        label: item.facetName,
        value: item.facetName,
        optionType: 'facet',
      })),
    [getFacetValueCountsRequest.result],
  );

  const onApplyFilterByFacets = (
    facetObj: SearchItemProps,
    editSearch: any,
  ) => {
    const { facetName, operator, value } = facetObj;
    const query = `${facetName}${operator}${value}`;
    if (editSearch) {
      const { index, filterKey: prevFilterKey } = editSearch;
      if (prevFilterKey) {
        updateFilterByIndex(index, prevFilterKey, query);
      }
    } else {
      addFilter('filterByFacets', query);
    }
  };

  const onApplySidebarFilters = (facetObj: SearchItemProps) => {
    const { facetName, operator, value } = facetObj;
    const sidebarFilters = filter['sidebarFilters'];
    const facetNameSideBarFilter = sidebarFilters[facetName] || {};
    const payload = {
      ...facetNameSideBarFilter,
      [value]: operator === '=' ? 1 : 0,
    };
    updateMappedFilterByKey('sidebarFilters', facetName, payload);
  };

  const autocompleteState = useAutocompleteState({
    filterState,
    initialOptions: initialOptions,
    operatorMap: eventQueryOperator,
    requestMap: {
      label: getServerlessLabelValuesOptions,
      facet: getServerlessFacetValuesOptions,
    },
    parseSearchQuery: (searchQuery: string, type: 'full' | 'partial') => {
      if (type === 'full') {
        return parseValidSearchQuery(searchQuery, false);
      }
      return parsePartialSearchQuery(searchQuery, false);
    },
    onApplyFilterMap: {
      filterByFacets: onApplyFilterByFacets,
      sidebarFilters: onApplySidebarFilters,
    },
  });

  const { setSearch, setEditSearch, updateActiveOperator } = autocompleteState;

  const tags = useMemo(() => {
    const newTags: FilterTagsProps[] = [];
    const filterKeys = Object.keys(filter);
    filterKeys.map((filterKey) => {
      if (!filterStructure[filterKey]) return;

      const stateAndMethods = getMethodAndStateByFilterKey(
        filterKey,
        filterStructure[filterKey].dataType,
      );
      const stateAndMethodsForTags: FilterTagsStateAndMethods = {
        ...stateAndMethods,
        setSearch,
        setEditSearch,
        updateActiveOperator,
      };
      const newTag = buildSearchFilterTags({
        filterKey,
        filterStructure,
        stateAndMethods: stateAndMethodsForTags,
      });
      newTags.push(...newTag);
    });
    return newTags;
  }, [filter]);

  return (
    <div className="serverless__header__search-bar">
      <SearchBar
        autocompleteState={autocompleteState}
        filterState={filterState}
        placeholder="Search Functions"
        tags={tags}
      />
    </div>
  );
};

export default ServerlessSearchBar;
