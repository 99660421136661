import query from 'requests/query';
import { FacetName } from 'types/generated';
import { onPromiseError } from 'utils';

const getLogsFacetNames = async ({
  source = '',
  limit = 20,
}: {
  source: string;
  limit?: number;
}): Promise<FacetName[]> => {
  return query<FacetName[], 'getFacetNames'>(`
        query GetFacetNames {
          getFacetNames(
            ${source ? `source: "${source}",` : ''}
            limit: ${limit}
          ) {
            facetNames {
              name
              type
              source
            }
          }
        }
      `).then((data) => data.getFacetNames?.facetNames || [], onPromiseError);
};

export default getLogsFacetNames;
