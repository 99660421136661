import { AutocompleteOption } from 'components';
import { useRequest } from 'hooks';
import { useEffect, useState } from 'react';
import { getMetricsList, promqlLabels, promqlLabelValues } from 'requests';
import { DateSelection } from 'types/DateSelection';
import { buildPromqlLabelMatcher, parseMetricLabelQuery } from 'utils';

const useMetricLabelDataState = ({
  date,
  preDefinedValues,
  preReloadMatcher,
  preLoadMetricLabels,
}: {
  date: DateSelection;
  preDefinedValues?: AutocompleteOption[];
  preReloadMatcher?: (matcher: string) => string;
  preLoadMetricLabels?: (matcher: string) => Promise<AutocompleteOption[]>;
}) => {
  const [metricsList, setMetricsList] = useState([]);
  const [labelValueList, setLabelValueList] = useState<{
    [key: string]: {
      [key: string]: { options: AutocompleteOption[]; isLoading: boolean };
    };
  }>({});
  const [labelsList, setLabelsList] = useState<{
    [key: string]: { options: AutocompleteOption[]; isLoading: boolean };
  }>({});
  const promqlLabelsRequest = useRequest(promqlLabels, false, true);
  const promqlLabelValuesRequest = useRequest(promqlLabelValues, false, true);
  const getMetricsListRequest = useRequest(getMetricsList, true, true);
  const [error, setError] = useState({
    getLabels: null,
    getLabelVlaues: null,
    getMetricsList: null,
  });

  // TODO: rewrite this useEffect to set the error in then/catch on the request
  useEffect(() => {
    if (getMetricsListRequest?.error) {
      setError((prevError) => ({
        ...prevError,
        getMetricsList: { message: 'Failed to fetch metrics list' },
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        getMetricsList: null,
      }));
    }
  }, [getMetricsListRequest?.error]);

  const loadAffectedLabelValues = async (
    metricName: string,
    seriesIndex: number,
    newSeries: string[],
  ) => {
    const affectedSeries = newSeries.filter(
      (series, idx) => idx > seriesIndex && series !== '=""',
    );
    if (affectedSeries.length === 0) return;
    await Promise.all(
      affectedSeries.map((series, idx) =>
        loadLabelValues(metricName, idx, newSeries),
      ),
    );
  };

  const loadInitialLabelsAndValues = async (
    metricName: string,
    series: string[],
  ) => {
    if (!metricName || series.length === 0) return;
    loadAffectedLabelValues(metricName, -1, series);
  };

  const loadLabelList = (
    newSeries: string[],
    metricName: string,
    seriesIndex: number,
  ) => {
    if (!metricName) return;
    const promqlLabel = buildPromqlLabelMatcher({
      series: newSeries,
      seriesIndex,
    });
    const matcher = `${metricName}{${promqlLabel}}`;
    const fetchMatcher = preReloadMatcher ? preReloadMatcher(matcher) : matcher;

    setLabelsList((prev) => ({
      ...prev,
      [matcher]: { options: [], isLoading: true },
    }));

    if (preLoadMetricLabels) {
      preLoadMetricLabels(fetchMatcher).then((labelsListOptions) => {
        setLabelsList((prev) => ({
          ...prev,
          [matcher]: { options: labelsListOptions, isLoading: false },
        }));
      });
      return;
    }

    promqlLabelsRequest
      .call({ date, matcher: fetchMatcher })
      .then((labelsResponse: string[]) => {
        if (labelsResponse) {
          setError((prevError) => ({
            ...prevError,
            getLabels: null,
          }));

          const labelsListOptions = labelsResponse.map((label: string) => ({
            label,
            value: `${label}=""`,
          }));

          setLabelsList((prev) => ({
            ...prev,
            [matcher]: { options: labelsListOptions, isLoading: false },
          }));
        }
      })
      .catch(() => {
        setError((prevError) => ({
          ...prevError,
          getLabels: { message: 'Failed to fetch labels' },
        }));

        setLabelsList((prev) => ({
          ...prev,
          [matcher]: { options: [], isLoading: false },
        }));
      });
  };

  const loadLabelValues = async (
    metricName: string,
    seriesIndex: number,
    newSeries?: string[],
  ) => {
    const labelKey = newSeries[seriesIndex];
    const { label } = parseMetricLabelQuery(labelKey);
    const promqlLabel = buildPromqlLabelMatcher({
      series: newSeries,
      seriesIndex,
    });
    const matcher = `${metricName}{${promqlLabel}}`;
    const fetchMatcher = preReloadMatcher ? preReloadMatcher(matcher) : matcher;
    setLabelValueList((prev) => ({
      ...prev,
      [metricName]: {
        ...prev[metricName],
        [label]: { options: [], isLoading: true },
      },
    }));
    return new Promise((resolve) => {
      promqlLabelValuesRequest
        .call({ date, matcher: fetchMatcher, label: label })
        .then((labelValuesResponse: string[]) => {
          if (labelValuesResponse) {
            setError((prevError) => ({
              ...prevError,
              getLabelVlaues: null,
            }));
          }

          const labelValuesOptions = labelValuesResponse.map((value) => ({
            label: value,
            value: value,
          }));
          const newLabelValueList = [
            ...preDefinedValues,
            ...labelValuesOptions,
          ];
          setLabelValueList((prev) => ({
            ...prev,
            [metricName]: {
              ...prev[metricName],
              [label]: { options: newLabelValueList, isLoading: false },
            },
          }));
          resolve(labelValuesResponse);
        })
        .catch(() => {
          setError((prevError) => ({
            ...prevError,
            getLabelVlaues: { message: 'Failed to fetch label values' },
          }));

          setLabelValueList((prev) => ({
            ...prev,
            [metricName]: {
              ...prev[metricName],
              [label]: { options: preDefinedValues, isLoading: false },
            },
          }));
        });
    });
  };

  return {
    getMetricsListRequest,
    error,
    labelsList,
    labelValueList,
    metricsList,
    loadAffectedLabelValues,
    loadInitialLabelsAndValues,
    loadLabelList,
    loadLabelValues,
    setMetricsList,
  };
};

export default useMetricLabelDataState;
