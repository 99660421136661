import { Accordion, Badge, Button } from 'components';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { DateSelection } from 'types/DateSelection';

import AlertDetailsServiceLabel from './AlertDetailsServiceLabel';
import AlertsDetailsPropertiesTraces from './AlertsDetailsPropertiesTraces';
import { SLO_ALERT_EXPRESSION_DESCRIPTION } from '../constants/alertsConstants';
import { AlertsMetricsParsedProps, RuleProps, RuleType } from '../types';
import {
  extractServiceIdFromSLOAlert,
  extractServiceIdFromAPMAlert,
  getUserCreatedAnnotations,
} from '../utils';

const AlertsDetailsPropertiesExpression = ({
  date,
  parsedMetadata,
  rule,
  hideExpression = false,
}: {
  date: DateSelection;
  parsedMetadata: AlertsMetricsParsedProps;
  rule: RuleProps;
  hideExpression?: boolean;
}): ReactElement => {
  const annotations = rule.annotations || {};
  const extraData = JSON.parse(annotations.extraData || '{}');
  const additionalLabels = extraData.additionalLabels || [];
  const tags = rule.tags || [];

  return (
    <div className="w-full pl-4">
      {rule &&
        annotations.ruleType !== RuleType.APM &&
        annotations.ruleType !== RuleType.SLO &&
        annotations.ruleType !== RuleType.TRACES &&
        !hideExpression && (
          <div className="alerts__details__properties__summary__item">
            <span className="text-sm font-bold text-text-secondary">
              Expression
            </span>{' '}
            <div className="code-container">
              <pre
                data-testid="alert-detail-expression"
                className="code-preview"
              >
                {parsedMetadata?.promqls[0]}
              </pre>
            </div>
          </div>
        )}
      {rule && annotations.ruleType === RuleType.TRACES && (
        <AlertsDetailsPropertiesTraces
          date={date}
          rule={rule}
          parsedMetadata={parsedMetadata}
        />
      )}
      {rule && annotations.ruleType === RuleType.SLO && (
        <>
          <Accordion
            className="slo__alert__query__accordion"
            renderContent={() => (
              <div className="alerts__details__properties__summary__item">
                <b>Expression</b>{' '}
                <div className="code-container">
                  <pre
                    data-testid="alert-detail-expression"
                    className="code-preview"
                  >
                    {parsedMetadata?.promqls[0]}
                  </pre>
                </div>
              </div>
            )}
            renderTrigger={() => <>{SLO_ALERT_EXPRESSION_DESCRIPTION}</>}
          />
        </>
      )}
      {rule && annotations.ruleType === RuleType.SLO && (
        <AlertDetailsServiceLabel
          serviceHash={extractServiceIdFromSLOAlert(rule)}
        />
      )}
      {rule && annotations.ruleType === RuleType.APM && (
        <AlertDetailsServiceLabel
          serviceHash={extractServiceIdFromAPMAlert(rule)}
        />
      )}
      {rule && annotations.ruleType === RuleType.APM && (
        <>
          {additionalLabels.length > 0 && (
            <div className="">
              <span className="text-sm font-bold text-text-secondary">
                Additional Labels:{' '}
              </span>
              {additionalLabels.map((label: string) => (
                <Badge
                  className="mb-1 mr-1 rounded-sm px-2 text-xs font-medium text-text-secondary"
                  key={label}
                  variant="outline"
                >
                  {label}
                </Badge>
              ))}
            </div>
          )}
        </>
      )}
      {getUserCreatedAnnotations(rule.annotations).length > 0 && (
        <div className="">
          <span className="text-sm font-bold text-text-secondary">
            Annotations:{' '}
          </span>
          {getUserCreatedAnnotations(rule.annotations).map((item) => (
            <Badge
              className="mb-1 mr-1 rounded-sm px-2 text-xs font-medium text-text-secondary"
              key={`${item.label}:${item.value}`}
              variant="outline"
            >
              {`${item.label}: ${item.value}`}
            </Badge>
          ))}
        </div>
      )}
      <div className="alerts__details__properties__summary__item">
        <span className="text-sm font-bold text-text-secondary">Title: </span>
        <span data-testid="alert-detail-title">{annotations.summary}</span>
      </div>
      <div className="alerts__details__properties__summary__item">
        <span className="text-sm font-bold text-text-secondary">
          Description:
        </span>{' '}
        <span data-testid="alert-detail-description">
          {annotations.description}
        </span>
      </div>
      {annotations.runbook_url && (
        <div className="alerts__details__properties__summary__item">
          <span className="text-sm font-bold text-text-secondary">
            Runbook:{' '}
          </span>
          <Link to={annotations.runbook_url} target="_blank" rel="noreferrer">
            {annotations.runbook_url}
          </Link>
        </div>
      )}
      {tags.length > 0 && (
        <div className="alerts__details__properties__summary__item">
          <span className="text-sm font-bold text-text-secondary">Tags: </span>
          {tags.map((label) => (
            <Badge
              className="mb-1 mr-1 rounded-sm px-2 text-xs font-medium text-text-secondary"
              key={label}
              variant="outline"
            >
              {label}
            </Badge>
          ))}
        </div>
      )}
    </div>
  );
};

export default AlertsDetailsPropertiesExpression;
