import { fetchJson } from 'requests';

import { PolicyProps } from '../types';

const updatePolicyByName = async (
  policy: PolicyProps,
): Promise<Record<string, string>> => {
  const result = await fetchJson(`rbac/policies/${policy.policy_id}`, {
    method: 'PUT',
    body: JSON.stringify(policy),
  });

  return result as Record<string, string>;
};

export default updatePolicyByName;
