import { ChipWithLabel, LanguageIconWithLabel } from 'components';
import dayjs from 'dayjs';
import { colorsByLogLevel } from 'kfuse-constants';
import React, { useMemo } from 'react';
import { Service, TraceError } from 'types';
import ServiceWithLabels from './ServiceWithLabels';
import { resolveColorFromMap } from 'utils';

type Props = {
  colorsByServiceName: Record<string, string>;
  serviceByHash: Record<string, Service>;
  traceError: TraceError;
};

const TraceErrorIssue = ({
  colorsByServiceName,
  serviceByHash,
  traceError,
}: Props) => {
  const { attributes, errorType, parsedError, service, timestampNs } =
    traceError;
  const language = attributes['telemetry_sdk_language'];

  const { exception } = parsedError;

  const serviceToUse = useMemo(() => {
    return serviceByHash?.[service?.hash];
  }, [service, serviceByHash]);

  return (
    <div className="apm-errors__table__issue">
      <ChipWithLabel
        color={colorsByLogLevel['error']}
        label={
          <div
            className="apm-errors__table__issue__right"
            data-testid="apm-errors-table"
          >
            <div className="apm-errors__table__issue__title">
              <LanguageIconWithLabel language={language} />
              <span className="apm-errors__table__issue__title__type">
                {`${exception.type || errorType} `}
              </span>
              <span>in</span>
              <span className="apm-errors__table__issue__title__module">
                {` ${exception.module}`}
              </span>
            </div>
            <div className="apm-errors__table__issue__message">
              {exception.message}
            </div>
            <div className="apm-errors__table__issue__meta">
              <div className="apm-errors__table__issue__meta__item apm-errors__table__issue__time">
                {dayjs(timestampNs / 1000000).fromNow()}
              </div>
              {Boolean(serviceToUse) && (
                <div className="apm-errors__table__issue__meta__item apm-errors__table__issue__service">
                  <ServiceWithLabels
                    color={resolveColorFromMap(
                      colorsByServiceName,
                      serviceByHash?.[serviceToUse.hash]?.name,
                    )}
                    name={serviceByHash?.[serviceToUse.hash]?.name}
                    distinctLabels={serviceToUse.distinctLabels}
                    labels={serviceToUse.labels}
                  />
                </div>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default TraceErrorIssue;
