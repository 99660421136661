import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateSelection, LogsMetricQueryProps, QueryLangType } from 'types';
import {
  defaultLogsQuery,
  extractPathNameFromURL,
  extractParamsFromURL,
  parseUrlParamByKey,
} from 'utils';
import { getDateFromRange } from 'screens/Dashboard/utils';
import { useToggle } from '..';

const useLogsPageState = () => {
  const navigate = useNavigate();
  const parsedQueries = parseUrlParamByKey('LogsMetricsQueries');
  const parsedLangType = parseUrlParamByKey('queryLangType');

  const dateState = useState<DateSelection>(
    parseUrlParamByKey('logsDate') || getDateFromRange('now-5m', 'now'),
  );
  const parsedCustomerFilter = parseUrlParamByKey('customerFilter');

  const [customerFilter, setCustomerFilter] = useState<{
    key: string;
    value: string;
  }>(parsedCustomerFilter);

  const prettifyJsonToggle = useToggle();

  const queriesState = useState<LogsMetricQueryProps[]>(
    parsedQueries || [defaultLogsQuery],
  );

  const queryLangTypeState = useState<QueryLangType>(
    parsedLangType || QueryLangType.KFUSEQL,
  );

  const dependenciesForWriteStateToUrl = useMemo(
    () => [
      customerFilter,
      dateState[0],
      queriesState[0],
      queryLangTypeState[0],
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerFilter, dateState[0], queriesState[0], queryLangTypeState[0]],
  );

  // Should be called on state change when on the Traces page
  const writeStateToUrl = () => {
    const params = extractParamsFromURL();
    params.set('logsDate', JSON.stringify(dateState[0]));
    if (customerFilter) {
      params.set('customerFilter', JSON.stringify(customerFilter));
    }
    params.set('LogsMetricsQueries', JSON.stringify(queriesState[0]));
    params.set('queryLangType', JSON.stringify(queryLangTypeState[0]));
    const navigateUrl = `${extractPathNameFromURL()}?${params.toString()}`;
    navigate(navigateUrl, { replace: true });
  };

  return {
    customerFilter,
    dependenciesForWriteStateToUrl,
    writeStateToUrl,
    dateState,
    setCustomerFilter,
    prettifyJsonToggle,
    queriesState,
    queryLangTypeState,
  };
};

export default useLogsPageState;
