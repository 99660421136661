import { chartTypesPredefined } from 'components/Timeseries/utils';

const MAX_DATA_POINT_LENGTH = 5;

const getDefaultAnalyticsChartTypes = ({
  hasAdvanceVisualization,
  dataPointLength,
}: {
  hasAdvanceVisualization: boolean;
  dataPointLength: number;
}): string[] => {
  const chartTypes = [];
  if (hasAdvanceVisualization) {
    chartTypes.push('Line');
    return chartTypes;
  }

  if (dataPointLength < MAX_DATA_POINT_LENGTH) {
    const predefinedChartTypes = chartTypesPredefined.filter(
      (type) => type !== 'Points',
    );
    return ['Points', ...predefinedChartTypes];
  }

  return undefined;
};

export default getDefaultAnalyticsChartTypes;
