import classnames from 'classnames';
import { Chevron, IconWithLabel } from 'components';
import { useToggle } from 'hooks';
import {
  iconsBySpanType,
  valueBySpanKindType,
  iconsBySpanKindType,
} from 'kfuse-constants';
import React from 'react';
import { SlArrowRight } from 'react-icons/sl';
import { Span } from 'types';
import TraceSidebarMainWaterfallItemLabel from './TraceSidebarMainWaterfallItemLabel';
import { WaterfallNode } from './types';

const PARENT_SPAN_ID = '0';

type Props = {
  clickedSpanId?: string;
  getColor: (span: Span) => string;
  minStartTimeNs: number;
  niceUpperBound: number;
  node: WaterfallNode;
  parentLeft?: number;
  setClickedSpanId: (spanId: string) => void;
  spanById: { [key: string]: Span };
  width: number;
};

const TraceSidebarMainWaterfallItem = ({
  clickedSpanId,
  getColor,
  minStartTimeNs,
  niceUpperBound,
  node,
  parentLeft,
  setClickedSpanId,
  spanById,
  width,
}: Props) => {
  const isExpandedToggle = useToggle(true);
  const { children, spanId } = node;
  const span = spanById[spanId];
  const isGhostSpan = span?.attributes?.isGhostSpan;
  const startTimeNs = span?.startTimeNs || minStartTimeNs;
  const diffInNs = span ? span.endTimeNs - startTimeNs : 0;
  const leftPercent = (startTimeNs - minStartTimeNs) / niceUpperBound;
  const left = leftPercent * width;

  const barWidth = (diffInNs / niceUpperBound) * width;
  const barColor = getColor(span);

  const icon = iconsBySpanType[span.attributes['span_type']];
  const spanKindIcon = iconsBySpanKindType[span.attributes['span_kind']];
  const spanKindValue = valueBySpanKindType[span.attributes['span_kind']];

  const onChevronClick = (e: any) => {
    e.stopPropagation();
    isExpandedToggle.toggle();
  };

  const onClick = () => {
    setClickedSpanId(spanId);
  };

  return (
    <>
      <div
        className={classnames({
          'trace-sidebar__main__waterfall__item': true,
          'trace-sidebar__main__waterfall__item--active':
            spanId === clickedSpanId,
        })}
        onClick={onClick}
      >
        <div
          className="trace-sidebar__main__waterfall__item__padder"
          style={{ width: `${left}px` }}
        >
          {typeof parentLeft === 'number' ? (
            <>
              <div
                className="trace-sidebar__main__waterfall__item__parent-left"
                style={{ width: `${parentLeft}px` }}
              />
              <div className="trace-sidebar__main__waterfall__item__padder__line">
                <div className="trace-sidebar__main__waterfall__item__padder__line__edge" />
              </div>
            </>
          ) : null}

          {children.length ? (
            <>
              {isExpandedToggle.value ? (
                <div className="trace-sidebar__main__waterfall__item__padder__line__edge-bottom" />
              ) : null}

              {spanKindValue === valueBySpanKindType.SPAN_KIND_SERVER &&
                span.parentSpanId !== PARENT_SPAN_ID && (
                  <SlArrowRight
                    className="absolute right-[23px] top-[2px]"
                    size={7}
                    color="var(--text01)"
                  />
                )}

              <button
                className="trace-sidebar__main__waterfall__item__button"
                onClick={onChevronClick}
              >
                <Chevron isOpen={isExpandedToggle.value} size={8} />
              </button>
            </>
          ) : null}
        </div>
        <div className="trace-sidebar__main__waterfall__item__inner">
          <div
            className={classnames({
              'trace-sidebar__main__waterfall__item__bar': true,
              'trace-sidebar__main__waterfall__item__bar--ghost': isGhostSpan,
            })}
            style={{
              backgroundColor: barColor,
              width: `${Math.max(barWidth, 10)}px`,
            }}
          />
          <div className="trace-sidebar__main__waterfall__item__info">
            {icon ? (
              <div className="flex gap-[4px]">
                <IconWithLabel
                  icon={spanKindIcon}
                  label={''}
                  tooltip={`Span kind: ${spanKindValue}`}
                />

                <IconWithLabel
                  icon={icon}
                  tooltip={`Span type: ${span.attributes['span_type']}`}
                  label={
                    <TraceSidebarMainWaterfallItemLabel
                      diffInNs={diffInNs}
                      isGhostSpan={isGhostSpan}
                      span={span}
                    />
                  }
                />
              </div>
            ) : (
              <TraceSidebarMainWaterfallItemLabel
                diffInNs={diffInNs}
                isGhostSpan={isGhostSpan}
                span={span}
              />
            )}
          </div>
        </div>
      </div>
      {isExpandedToggle.value
        ? children
            .sort(
              (a, b) =>
                spanById[a.spanId].startTimeNs - spanById[b.spanId].startTimeNs,
            )
            .map((childNode) => (
              <TraceSidebarMainWaterfallItem
                clickedSpanId={clickedSpanId}
                getColor={getColor}
                key={childNode.spanId}
                minStartTimeNs={minStartTimeNs}
                niceUpperBound={niceUpperBound}
                node={childNode}
                parentLeft={left}
                setClickedSpanId={setClickedSpanId}
                spanById={spanById}
                width={width}
              />
            ))
        : null}
    </>
  );
};

export default TraceSidebarMainWaterfallItem;
