import { SelectedFacetValuesByName, ValueCount } from 'types';
import {
  filterListWithSelectedSidebar,
  mergeTwoSidebarCountsValues,
} from 'utils';

import { HydrationJobProps } from '../types';

export const LogsHydrationFacet = [
  { name: 'status', label: 'Job Status', forceExpanded: false },
  { name: 'name', label: 'Archive Name', forceExpanded: false },
  { name: 'tags', label: 'Tags', forceExpanded: false },
];

const getCountForHydrationJobListSidebar = (
  jobs: HydrationJobProps[],
): {
  tags: { [key: string]: number };
  status: { [key: string]: number };
  name: { [key: string]: number };
} => {
  const tags: { [key: string]: number } = {};
  const status: { [key: string]: number } = {};
  const name: { [key: string]: number } = {};

  jobs.forEach((job, idx) => {
    job.tags.forEach((tag) => {
      if (tags[tag] === undefined) {
        tags[tag] = 1;
      } else {
        tags[tag] += 1;
      }
    });

    if (status[job.JobStatus] === undefined) {
      status[job.JobStatus] = 1;
    } else {
      status[job.JobStatus] += 1;
    }

    if (name[job.ArchiveName] === undefined) {
      name[job.ArchiveName] = 1;
    } else {
      name[job.ArchiveName] += 1;
    }
  });

  return { tags, status, name };
};

export const filterHydrationJobs = (
  jobs: HydrationJobProps[],
  selectedFacetValuesByName: SelectedFacetValuesByName,
): {
  formattedJobs: HydrationJobProps[];
  status: ValueCount[];
  tags: ValueCount[];
  name: ValueCount[];
} => {
  const filtered = filterListWithSelectedSidebar(
    jobs,
    selectedFacetValuesByName,
  );

  const countFromRules = getCountForHydrationJobListSidebar(jobs);
  const countFromFiltered = getCountForHydrationJobListSidebar(filtered);
  const mergedCount = mergeTwoSidebarCountsValues(
    countFromRules,
    countFromFiltered,
  );

  const { tags, status, name } = mergedCount;

  return {
    formattedJobs: filtered,
    status: Object.keys(status).map((key) => ({
      value: key,
      count: status[key],
    })),
    tags: Object.keys(tags).map((key) => ({
      value: key,
      count: tags[key],
    })),
    name: Object.keys(name).map((key) => ({
      value: key,
      count: name[key],
    })),
  };
};
