import classNames from 'classnames';
import React, { Dispatch, SetStateAction, ReactElement } from 'react';

import { AlertMuteTimingProps } from './types';
import { getDaysOfWeek } from './utils';

const AlertsMuteTimingEditDaysOfWeek = ({
  muteInterval,
  setMuteInterval,
  intervalIdx,
}: {
  muteInterval: AlertMuteTimingProps['time_intervals'][0];
  setMuteInterval: Dispatch<SetStateAction<AlertMuteTimingProps>>;
  intervalIdx: number;
}): ReactElement => {
  const daysOfWeek = getDaysOfWeek();
  const { weekdays } = muteInterval;

  const handleDayOfWeekClick = (day: string) => {
    setMuteInterval((prev) => {
      const newState = { ...prev };
      const newIntervals = { ...newState.time_intervals[intervalIdx] };
      const isSelected = weekdays.includes(day);
      if (isSelected) {
        newIntervals.weekdays = newIntervals.weekdays.filter((d) => d !== day);
      } else {
        newIntervals.weekdays.push(day);
      }
      newState.time_intervals[intervalIdx] = newIntervals;
      return newState;
    });
  };

  return (
    <div className="flex flex-col pt-2">
      <div className="text-sm font-semibold">Days of Week</div>
      <div className="pb-1 text-xs text-text-secondary">
        Select the days of the week to mute the alert
      </div>
      <div className="flex flex-row items-center gap-x-2 pt-1">
        {daysOfWeek.map((day) => {
          return (
            <div
              key={day.value}
              className={classNames({
                'border px-3 py-1.5 cursor-pointer hover:bg-indigo-200 hover:border-primary':
                  true,
                'border-primary text-primary bg-indigo-100 ': weekdays.includes(
                  day.value,
                ),
              })}
              onClick={() => handleDayOfWeekClick(day.value)}
            >
              {day.label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AlertsMuteTimingEditDaysOfWeek;
