import { CursorStateProvider, useThemeContext } from 'components';
import { useLogsMetricsQueryState } from 'hooks';
import React, { ReactElement, useMemo } from 'react';
import { DateSelection } from 'types';
import { combineRangeQueryData } from 'utils';

import {
  AlertsChart,
  AlertsChartAnomalyKfuseql,
  AlertsCreateLogsChartForecast,
  AlertsCreateLogsChartOutlier,
} from '../AlertsChart';
import { useAlertsCreateCondition } from '../AlertsCreateCondition';
import { AlertType } from '../types';
import { getQueryItemForKfuseql } from './utils';

const AlertsCreateLogsChart = ({
  alertType,
  baseWidth,
  conditionState,
  date,
  forWindow,
  isEditing = false,
  logsMetricsQueryState,
  unit,
}: {
  alertType: AlertType;
  baseWidth: number;
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
  date: DateSelection;
  forWindow?: string;
  isEditing?: boolean;
  logsMetricsQueryState: ReturnType<typeof useLogsMetricsQueryState>;
  unit: string;
}): ReactElement => {
  const { darkModeEnabled } = useThemeContext();
  const { formulas, queries, logsChartData: queryData } = logsMetricsQueryState;
  const { condition, anomalyCondition, forecastCondition, outlierCondition } =
    conditionState;

  const queryItem = useMemo(() => {
    const item = getQueryItemForKfuseql({
      formulas,
      queries,
      selectedQuery: condition.queryKey,
      queryLangType: logsMetricsQueryState.queryLangType,
    });
    if (!item || typeof item === 'string') return;

    return item;
  }, [
    formulas,
    queries,
    logsMetricsQueryState.queryLangType,
    condition.queryKey,
  ]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const combinedData = useMemo(() => {
    return combineRangeQueryData({
      formulas,
      queries,
      queryData,
      darkModeEnabled,
      combineTimestamp: true,
      combineLabelWithQueryKey: true,
    });
  }, [darkModeEnabled, formulas, queries, queryData]);

  if (alertType === AlertType.ANOMALY) {
    return (
      <AlertsChartAnomalyKfuseql
        anomalyCondition={anomalyCondition}
        baseWidth={baseWidth}
        chartLayoutType={'explore'}
        date={date}
        forWindow={forWindow}
        isChartCompact={false}
        queryItem={queryItem}
        unit={unit}
      />
    );
  }

  if (alertType === AlertType.OUTLIERS) {
    return (
      <AlertsCreateLogsChartOutlier
        outlierCondition={outlierCondition}
        chartLayoutType={'explore'}
        date={date}
        isChartCompact={false}
        forWindow={forWindow}
        queryItem={queryItem}
        unit={unit}
      />
    );
  }

  if (alertType === AlertType.FORECAST) {
    return (
      <CursorStateProvider>
        <AlertsCreateLogsChartForecast
          condition={condition}
          forecastCondition={forecastCondition}
          baseWidth={baseWidth}
          chartLayoutType={'explore'}
          date={date}
          isChartCompact={false}
          queryItem={queryItem}
          queries={queries}
          formulas={formulas}
          queryKey={condition.queryKey}
          unit={unit}
        />
      </CursorStateProvider>
    );
  }

  return (
    <AlertsChart
      conditionOperator={condition.of}
      conditionValue={condition.value ? Number(condition.value) : undefined}
      date={date}
      isChartCompact={false}
      enableEvaluationLine={
        alertType === AlertType.CHANGE || alertType === AlertType.THRESHOLD
      }
      isLoading={combinedData.isLoading}
      queryData={combinedData}
      unit={unit}
    />
  );
};

export default AlertsCreateLogsChart;
