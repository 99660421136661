import { Badge, Button, ServiceWithLabels, TooltipTrigger } from 'components';
import React, { ReactElement, useMemo } from 'react';
import { MdClose as CloseIcon } from 'react-icons/md';
import { resolveColorFromMap } from 'utils';

import useAlertsServiceCorrelationState from './useAlertsServiceCorrelationState';
import classNames from 'classnames';

const AlertsServiceCorrelationsFiltersBadge = ({
  alertsServiceCorrelationState,
  selectedCorrelationService,
  isFilterFlat,
}: {
  alertsServiceCorrelationState: ReturnType<
    typeof useAlertsServiceCorrelationState
  >;
  selectedCorrelationService: Record<string, any>[];
  isFilterFlat?: boolean;
}): ReactElement => {
  const { colorsByServiceName, onCorrelationKeyChange } =
    alertsServiceCorrelationState;

  return (
    <>
      {selectedCorrelationService.map((service) => (
        <div
          key={service.serviceHash}
          className={classNames({
            'mt-2 flex': true,
            'flex-row rounded-sm border h-8 px-2 items-center': isFilterFlat,
            'flex-col': !isFilterFlat,
          })}
        >
          <ServiceWithLabels
            color={resolveColorFromMap(
              colorsByServiceName,
              service.serviceName,
            )}
            name={service.serviceName}
            distinctLabels={{}}
            labels={service.labels}
          />
          <div
            className={classNames({
              'flex flex-wrap gap-2 pl-2': true,
              'pt-2': !isFilterFlat,
            })}
          >
            {service.activeCorrelation.redMetric && (
              <Badge
                variant="outline"
                className="h-6 border-none bg-red-100 font-normal text-red-800"
              >
                Red
                {!isFilterFlat && (
                  <TooltipTrigger tooltip="Remove Red Metric">
                    <CloseIcon
                      className="ml-1 cursor-pointer hover:text-red-500"
                      onClick={() =>
                        onCorrelationKeyChange({
                          propertyKey: 'redMetric',
                          value: false,
                          serviceHash: service.serviceHash,
                        })
                      }
                    />
                  </TooltipTrigger>
                )}
              </Badge>
            )}
            {service.activeCorrelation.useMetric && (
              <Badge
                variant="outline"
                className="h-6 border-none bg-green-100 font-normal text-green-800"
              >
                Use
                {!isFilterFlat && (
                  <TooltipTrigger tooltip="Remove Use Metric">
                    <CloseIcon
                      className="ml-1 cursor-pointer hover:text-red-500"
                      onClick={() =>
                        onCorrelationKeyChange({
                          propertyKey: 'useMetric',
                          value: false,
                          serviceHash: service.serviceHash,
                        })
                      }
                    />
                  </TooltipTrigger>
                )}
              </Badge>
            )}
            {service.activeCorrelation.integration && (
              <Badge
                variant="outline"
                className="h-6 border-none bg-blue-100 font-normal text-blue-800"
              >
                Integration{' '}
                {!isFilterFlat && (
                  <TooltipTrigger tooltip="Remove Integration">
                    <CloseIcon
                      className="ml-1 cursor-pointer hover:text-red-500"
                      onClick={() =>
                        onCorrelationKeyChange({
                          propertyKey: 'integration',
                          value: false,
                          serviceHash: service.serviceHash,
                        })
                      }
                    />
                  </TooltipTrigger>
                )}
              </Badge>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

const AlertsServiceCorrelationsFilters = ({
  alertsServiceCorrelationState,
}: {
  alertsServiceCorrelationState: ReturnType<
    typeof useAlertsServiceCorrelationState
  >;
}): ReactElement => {
  const {
    hideServiceMap,
    activeCorrelation,
    serviceByHash,
    onRunCorrelation,
    setHideServiceMap,
  } = alertsServiceCorrelationState;

  const selectedCorrelationService = useMemo(() => {
    const serviceKeys = Object.keys(activeCorrelation);
    return serviceKeys.map((key) => ({
      serviceName: serviceByHash[key].name,
      serviceHash: key,
      activeCorrelation: activeCorrelation[key],
      labels: serviceByHash[key].labels,
    }));
  }, [activeCorrelation, serviceByHash]);

  if (hideServiceMap) {
    return (
      <div className="w-full pl-2">
        <div className="text-sm font-bold">Selected Correlation Service</div>
        <div className="flex flex-row items-center justify-between">
          {selectedCorrelationService.length > 0 && (
            <div className="flex w-full flex-wrap gap-x-4">
              <AlertsServiceCorrelationsFiltersBadge
                alertsServiceCorrelationState={alertsServiceCorrelationState}
                selectedCorrelationService={selectedCorrelationService}
                isFilterFlat={true}
              />
            </div>
          )}
          {selectedCorrelationService.length === 0 && (
            <div className="py-2 text-sm font-normal text-gray-500">
              No filter selected
            </div>
          )}
          <Button
            variant="default"
            size="sm"
            onClick={() => setHideServiceMap(false)}
          >
            Show services map
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-w-[300px] pl-2 max-w-[300px]">
      <div className="text-sm font-bold">Selected Correlation Service</div>
      <div className="text-sm font-normal">
        {selectedCorrelationService.length === 0 && (
          <div className="flex min-h-[160px] items-center justify-center text-center text-xs font-normal">
            No services selected. You can select specific services or run
            correlation with all services.
          </div>
        )}
        <AlertsServiceCorrelationsFiltersBadge
          alertsServiceCorrelationState={alertsServiceCorrelationState}
          selectedCorrelationService={selectedCorrelationService}
          isFilterFlat={false}
        />
      </div>
      <div className="flex justify-end pt-2">
        <Button
          className="mt-4"
          variant="default"
          size="sm"
          onClick={onRunCorrelation}
        >
          Run Correlation
        </Button>
      </div>
    </div>
  );
};

export default AlertsServiceCorrelationsFilters;
