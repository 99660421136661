import React from 'react';

import { Box } from 'components/layouts/Box';
import { Table, TableRow } from 'components';
import { calculateCapacity } from 'utils/timeNs';
import { kubeNamespaceCount } from 'requests';
import KubernetesRightSideBar from '../KubernetesRightSideBar';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellPercentageBar,
  KubeCellStatus,
  KubeCellTimestamp,
  KubeTableCell,
  KubeTableEntityTags,
} from './Components';
import useAsync from '../hooks/useAsync';
import { getAggregationsByEntities } from '../utils/selectorsfunction';
import { generatePath, useParams, useSearchParams } from 'react-router-dom';
import KubernetesTableGroup from './KubernetesTableGroup';

import { useKubernetesTableDataForNode } from './KubernetesTableForNode';
import { useMergedGroupByFilter } from './useEntitiesData';

const renderEmptyCell = () => '';

function KubernetesTableForNodeRow({ row }) {
  const { date, entitiesType, navigate } = useKubernetesController();

  const filters = useMergedGroupByFilter(row.groupBys);
  const [rows] = useKubernetesTableDataForNode({
    entitiesType,
    kubesSort: null,
    page: 0,
    limit: 50,
    filters,
    date,
  });

  const setActiveKube = (row) => {
    navigate(({ location, params, pathTemplate }) => {
      return {
        ...location,

        pathname: generatePath(pathTemplate, {
          ...params,

          activeKube: row.node.metadata.uid,
          tab: 'yaml',
        }),
      };
    });
  };

  const activeKubeParam = useParams()['activeKube'];
  const activeKube = (
    typeof activeKubeParam === 'string' && rows ? rows : []
  ).find((row) => {
    return row.node.metadata.uid === activeKubeParam;
  });

  return (
    <Box width="full" height="full">
      {activeKube && <KubernetesRightSideBar activeKube={activeKube} />}
      <Table
        className="-my-px"
        renderHeader={false}
        rows={rows ?? []}
        renderRow={(props) => (
          <TableRow
            {...props}
            className="border-y"
            onRowClickHandler={(row) => () => setActiveKube(row)}
          />
        )}
        columns={[
          { key: 'header', label: '' },
          {
            key: 'kube_node',
            label: 'Node',
            renderCell(prop) {
              const nodeName = prop.row.node.metadata.name;
              return (
                <KubeTableCell
                  {...prop}
                  className="kubernetes__table__cell__entity-name"
                  tooltipText={nodeName}
                >
                  {nodeName}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'metadata.creationTimestamp',
            label: 'Age',
            renderCell(prop) {
              const age = prop.row.node.metadata;
              const timestamp = age.creationTimestamp;
              return <KubeCellTimestamp {...prop} timestamp={timestamp} />;
            },
          },
          {
            key: 'status.status',
            label: 'Status',
            renderCell(prop) {
              const status = prop.row.node.status.status;
              return <KubeCellStatus {...prop} status={status} />;
            },
          },
          {
            key: 'status.capacity.cpu',
            label: 'Cpu Capacity',
            renderCell(prop) {
              return (
                <KubeTableCell
                  {...prop}
                  className="kubernetes__table__cell__entity-name"
                >
                  {(prop.row.node.status.allocatable.cpu / 1000).toFixed(2)}/
                  {(prop.row.node.status.capacity.cpu / 1000).toFixed(2)} CPUs
                </KubeTableCell>
              );
            },
          },
          {
            key: 'headerCpuUsage',
            label: '% CPU Usage',
            renderCell(prop) {
              const percent = prop.row.cpuPercentage * 100;
              return (
                <KubeTableCell {...prop}>
                  <KubeCellPercentageBar percent={percent} />
                </KubeTableCell>
              );
            },
          },
          {
            key: 'status.capacity.memory',
            label: 'Mem Capacity',
            renderCell(prop) {
              return (
                <KubeTableCell
                  {...prop}
                  className="kubernetes__table__cell__entity-name"
                >
                  {calculateCapacity(prop.row.node.status.allocatable.memory)} /
                  {calculateCapacity(prop.row.node.status.capacity.memory)}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'headerMemUsage',
            label: '% Mem Usage',
            renderCell(prop) {
              const percent = prop.row.memoryPercentage * 100;
              return (
                <KubeTableCell {...prop}>
                  <KubeCellPercentageBar percent={percent} />
                </KubeTableCell>
              );
            },
          },
          {
            key: 'unschedulable',
            label: 'Schedulability',
            renderCell(prop) {
              const status =
                prop.row.node.unschedulable === 'true' ? 'inactive' : 'active';
              return <KubeCellStatus {...prop} status={status} />;
            },
          },
          {
            key: 'headerRoles',
            label: 'Roles',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop}>{prop.row.node.roles}</KubeTableCell>
              );
            },
          },
          {
            key: 'status.kubeletVersions',
            label: 'Kubelet Version',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop}>
                  {prop.row.node.status.kubeletVersions}
                </KubeTableCell>
              );
            },
          },
        ]}
      />
    </Box>
  );
}

export default function KubernetesTableForNodeGroup() {
  const { date, entitiesType, facets, groupBySearchTerms } =
    useKubernetesController();

  const [rows] = useAsync(
    async (signal: AbortSignal) => {
      const [cpu, memory, entities] = await Promise.all([
        null, // nodesCPUPercentage(groupBySearchTerms, date)({ signal }),
        null, // nodesCPUPercentage(groupBySearchTerms, date)({ signal }),
        kubeNamespaceCount(
          {
            entityType: entitiesType,
            selectedFacetValuesByName: facets,
            groupBy: groupBySearchTerms,
            aggregations: getAggregationsByEntities(
              entitiesType,
              groupBySearchTerms,
            ),
          },
          { signal },
        ),
      ]);

      return entities.map((podData) => {
        // const key = getGroupKey(podData.groupBys);
        // const cpuPercentage = cpu[key] || 0;
        // const memoryPercentage = memory[key] || 0;

        return {
          ...podData,
          // cpuPercentage,
          // memoryPercentage,
        };
      });
    },
    [date, facets, groupBySearchTerms],
  );

  return (
    <KubernetesTableGroup
      rows={rows ?? []}
      columns={[
        {
          key: 'headerkube_node',
          label: 'Node Group',
          renderCell(prop) {
            return <KubeTableEntityTags {...prop} />;
          },
        },
        {
          key: 'headerage',
          label: 'Age',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headernode_status',
          label: 'Status',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headercpuCapacity',
          label: 'Cpu Capacity',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headercpu',
          label: '% CPU Usage',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headermemoryCap',
          label: 'Mem Capacity',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headermem',
          label: '% Mem Usage',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headernode_schedulable',
          label: 'Schedulability',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerroles',
          label: 'Roles',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerkubelet',
          label: 'Kubelet Version',
          renderCell: renderEmptyCell,
        },
      ]}
      renderExpandedRow={(props) => <KubernetesTableForNodeRow {...props} />}
    />
  );
}
