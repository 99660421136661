import classNames from 'classnames';
import { Button, InputWithValidatorV3, TooltipTrigger } from 'components';
import { useMetricsQueryStateV2 } from 'hooks';
import React, { ReactElement } from 'react';
import { XCircle } from 'react-feather';
import { ExplorerQueryProps, FormulaProps } from 'types/MetricsQueryBuilder';

const MetricsQueryFormula = ({
  formulas,
  queries,
  metricsQueryState,
}: {
  formulas: FormulaProps[];
  queries: ExplorerQueryProps[];
  metricsQueryState: ReturnType<typeof useMetricsQueryStateV2>;
}): ReactElement => {
  const { toggleQueryAndCallOnePromqlQuery, removeFormula, updateFormula } =
    metricsQueryState;

  if (!formulas.length) {
    return null;
  }

  return (
    <div>
      {formulas.map(
        ({ isActive, isValid, expression }: FormulaProps, index: number) => {
          return (
            <div
              key={index}
              className="flex flex-row justify-between pt-2"
              data-testid="metric-formula-builder"
            >
              <div className="metrics__query-builder__formula__item">
                <div
                  className={classNames({
                    'metrics__query-builder__query-item__query-key': true,
                    'metrics__query-builder__query-item__query-key--inactive':
                      !isActive,
                  })}
                  onClick={() =>
                    toggleQueryAndCallOnePromqlQuery({ index, type: 'formula' })
                  }
                >
                  {index + 1}
                </div>
                <InputWithValidatorV3
                  className="metrics__query-builder__formula__item__input"
                  error={expression && !isValid ? 'Invalid expression' : ''}
                  placeholder="Add formula example - 2*a"
                  onChange={(val) => updateFormula(index, 'expression', val)}
                  value={expression}
                  type="text"
                  size="5"
                  variant="default"
                />
              </div>
              <div className="metrics__query-builder__query-action">
                <TooltipTrigger tooltip="Delete">
                  <Button
                    className="h-full px-1.5 metrics__query-builder__query-action__icon--delete"
                    variant="icon"
                    size="sm"
                  >
                    <XCircle onClick={() => removeFormula(index)} size={16} />
                  </Button>
                </TooltipTrigger>
              </div>
            </div>
          );
        },
      )}
    </div>
  );
};

export default MetricsQueryFormula;
