import {
  generatePath,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import merge from 'lodash.merge';

export default function useMergedLocation(
  pathTemplate: string,
  {
    params,
    searchParams,
  }: {
    params?: Record<string, string>;
    searchParams?: Record<string, unknown>;
  } = {},
) {
  const location = useLocation();
  const currentParams = useParams();
  const [currentSearchParams] = useSearchParams();

  const search = new URLSearchParams(currentSearchParams.toString());

  Object.entries(searchParams).forEach(([key, value]) => {
    if (value === null) {
      search.delete(key);
    } else if (typeof value === 'object') {
      const json = JSON.parse(search.get(key));

      search.set(key, JSON.stringify(merge({}, json, value)));
    } else {
      search.set(key, value);
    }
  });

  return {
    ...location,

    pathname: generatePath(pathTemplate, {
      ...currentParams,
      ...params,
    }),
    search: search.toString(),
  };
}
