import { AutocompleteOption, useToaster } from 'components';
import { useUrlState } from 'hooks';
import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import {
  DateSelection,
  FormulaProps,
  ExplorerQueryProps,
  FunctionProps,
  DashboardPanelType,
} from 'types';
import {
  AGGREGATE_FUNCTIONS,
  getFunctionParams,
  getMetricsExplorerDefaultQuery,
} from 'utils';

import useMetricsDataState from './useMetricsDataState';
import useMetricsFunctionState from './useMetricsFunctionState';
import useMetricsFormulaState from './useMetricsFormulaState';
import useMetricsQueryOps from './useMetricsQueryOps';
import useMetricLabelDataState from './useMetricLabelDataState';

const NON_CALLABLE_PROPS = ['showInput', 'promql', 'isActive', 'steps'];
const DEBOUNCE_CALLABLE_PROPS = ['steps'];

const useMetricsQuery = ({
  activeQueryType = 'multi',
  defaultQueries = [getMetricsExplorerDefaultQuery('')],
  date,
  dataFormat = DashboardPanelType.TIMESERIES,
  isRange = true,
  onAPICall,
  preAddQuery,
  preDefinedValues = [],
  preLoadMetricList,
  preLoadMetricLabels,
  preLoadMetricSeries,
  preReloadQuery,
  preReloadMatcher,
  urlCleanUp = false,
  queriesState,
  formulasState,
}: {
  activeQueryType?: 'multi' | 'single';
  defaultQueries?: ExplorerQueryProps[];
  date: DateSelection;
  dataFormat?: DashboardPanelType;
  isRange: boolean;
  onAPICall?: (val: {
    formulas: FormulaProps[];
    queryIndex: number;
    queries: ExplorerQueryProps[];
    type: 'query' | 'formula';
  }) => void;
  preAddQuery?: (query: ExplorerQueryProps) => ExplorerQueryProps;
  preDefinedValues?: AutocompleteOption[];
  preLoadMetricList?: (setMetricsList: any) => void;
  preLoadMetricLabels?: (matcher: string) => Promise<AutocompleteOption[]>;
  preLoadMetricSeries?: (metricName: string) => Promise<{
    labelsListOptions: AutocompleteOption[];
    seriesListOptions: AutocompleteOption[];
    seriesValuesOptions: { [key: string]: AutocompleteOption[] };
  }>;
  preReloadQuery?: (promql: string, metricName: string) => string;
  preReloadMatcher?: (matcher: string) => string;
  urlCleanUp?: boolean;
  queriesState?: ReturnType<typeof useUrlState<ExplorerQueryProps[]>>;
  formulasState?: ReturnType<typeof useUrlState<FormulaProps[]>>;
}) => {
  const [queriesUrl, setQueriesUrl] = useUrlState<ExplorerQueryProps[]>(
    'metricsQueries',
    defaultQueries,
    urlCleanUp,
  );

  const queries = queriesState ? queriesState[0] : queriesUrl;
  const setQueries = queriesState ? queriesState[1] : setQueriesUrl;

  const { addToast } = useToaster();
  const metricsDataState = useMetricsDataState({
    date,
    dataFormat,
    isRange,
    preReloadQuery,
  });
  const metricsFormulaState = useMetricsFormulaState({
    activeQueryType,
    date,
    formulasState,
    metricsDataState,
    queries,
    setQueries,
    urlCleanUp,
  });
  const {
    checkAndLoadedAffectedFormulas,
    formulas,
    setFormulas,
    validateAffectedFormulas,
  } = metricsFormulaState;

  const metricsFunctionState = useMetricsFunctionState({
    checkAndLoadedAffectedFormulas,
    formulas,
    queries,
    metricsDataState,
    setQueries,
    date,
  });
  const metricLabelDataState = useMetricLabelDataState({
    date,
    preDefinedValues,
    preLoadMetricLabels,
    preReloadMatcher,
  });
  const {
    getMetricsListRequest,
    loadInitialLabelsAndValues,
    loadLabelValues,
    setMetricsList,
  } = metricLabelDataState;

  const {
    callOnePromqlQuery,
    queryData,
    callMultiplePromqlQueries,
    removeQueryData,
    setQueryData,
  } = metricsDataState;

  const queryOperation = useMetricsQueryOps({
    activeQueryType,
    setFormulas,
    setQueries,
    setQueryData,
  });

  const addQuery = () => {
    setQueries((prevQueries: ExplorerQueryProps[]) => {
      const newQueries = [...prevQueries];
      const lastQueryKey = newQueries[newQueries.length - 1]?.queryKey || 'a';

      const newQuery = {
        ...(newQueries[newQueries.length - 1]
          ? cloneDeep(newQueries[newQueries.length - 1])
          : getMetricsExplorerDefaultQuery('')),
        queryKey: String.fromCharCode(lastQueryKey.charCodeAt(0) + 1),
      };

      if (preAddQuery) {
        newQueries.push(preAddQuery(newQuery));
        return newQueries;
      }
      newQueries.push(newQuery);
      if (newQuery.isActive) {
        callOnePromqlQuery({
          formulas,
          queries: newQueries,
          queryIndex: newQueries.length - 1,
          type: 'query',
          callType: null,
          date,
        });
      }

      if (activeQueryType === 'single') {
        newQueries.forEach((query, index) => {
          if (index !== newQueries.length - 1) {
            query.isActive = false;
          }
        });
      }
      validateAffectedFormulas({
        queryIndex: newQueries.length - 1,
        newQueries,
        validateType: 'add',
      });
      return newQueries;
    });
  };

  const toggleQueryAndCallOnePromqlQuery = ({
    index,
    type,
  }: {
    index: number;
    type: 'query' | 'formula';
  }) => {
    const isTypeQuery = type === 'query';
    const item = isTypeQuery ? queries[index] : formulas[index];

    if (!item.isActive) {
      callOnePromqlQuery({
        formulas,
        queries,
        queryIndex: index,
        type,
        callType: null,
        date,
      });
    }
    queryOperation.handleEnableDisableQuery(index, type);
  };

  const updateQuery = (queryIndex: number, propertyKey: string, value: any) => {
    setQueries((prevQueries: ExplorerQueryProps[]) => {
      const newQueries = [...prevQueries];
      const newQuery = { ...newQueries[queryIndex] };
      newQuery[propertyKey] = value;
      newQueries[queryIndex] = newQuery;

      if (DEBOUNCE_CALLABLE_PROPS.includes(propertyKey)) {
        if (newQuery.isActive) {
          callOnePromqlQuery({
            formulas,
            queries: newQueries,
            queryIndex,
            type: 'query',
            callType: 'debounce',
            date,
          });
        }
        checkAndLoadedAffectedFormulas(queryIndex, newQueries, 'debounce');
        return newQueries;
      }

      if (propertyKey === 'isActive') {
        setQueryData({ ...queryData });
        return newQueries;
      }

      if (propertyKey === 'series') {
        const lastSeries = value[value.length - 1];
        if (lastSeries === '=""') {
          return newQueries;
        }
      }

      if (NON_CALLABLE_PROPS.includes(propertyKey)) {
        return newQueries;
      }

      if (newQuery.isActive) {
        callOnePromqlQuery({
          formulas,
          queries: newQueries,
          queryIndex,
          type: 'query',
          callType: null,
          date,
        });
      }
      checkAndLoadedAffectedFormulas(queryIndex, newQueries, 'normal');
      return newQueries;
    });
  };

  const updateParsedQuery = (
    queryIndex: number,
    metric: string,
    functions: FunctionProps[],
    series: string[],
  ) => {
    setQueries((prevQueries: ExplorerQueryProps[]) => {
      const newQueries = [...prevQueries];
      const newQuery = { ...newQueries[queryIndex] };

      newQuery.metric = metric;
      newQuery.functions = functions;
      newQuery.series = series;
      newQueries[queryIndex] = newQuery;

      if (newQuery.isActive) {
        callOnePromqlQuery({
          formulas,
          queries: newQueries,
          queryIndex,
          type: 'query',
          callType: null,
          date,
        });
      }
      checkAndLoadedAffectedFormulas(queryIndex, newQueries, 'normal');
      return newQueries;
    });
  };

  const removeQuery = (queryIndex: number) => {
    setQueries((prevQueries: ExplorerQueryProps[]) => {
      const newQueries = [...prevQueries];
      if (queries.length === 1) {
        addToast({ status: 'error', text: 'Cannot remove query last query' });
        return prevQueries;
      }
      const query = newQueries[queryIndex];
      removeQueryData(`query_${query.queryKey}`);
      validateAffectedFormulas({
        queryIndex,
        newQueries,
        validateType: 'remove',
      });
      newQueries.splice(queryIndex, 1);
      return newQueries;
    });
  };

  const openMetricUsingTags = (
    queryIndex: number,
    metricName: string,
    tag: string,
  ) => {
    setQueries((prevQueries: ExplorerQueryProps[]) => {
      const newQueries = [...prevQueries];
      const newQuery = { ...newQueries[queryIndex] };
      const { metric, functions } = newQuery;

      if (metricName !== metric) {
        const newDefaultQuery = getMetricsExplorerDefaultQuery(metricName);
        newDefaultQuery.functions[0].params[1].value.push(tag);
        newQueries[queryIndex] = newDefaultQuery;
      }

      if (metricName === metric) {
        const newFunctions = [...functions];
        const aggrIndex = newFunctions.findIndex((func) =>
          AGGREGATE_FUNCTIONS.includes(func.name),
        );
        if (aggrIndex !== -1) {
          const existingTags = newFunctions[aggrIndex].params[1].value;
          if (!existingTags.includes(tag)) {
            newFunctions[aggrIndex].params[1].value =
              newFunctions[aggrIndex].params[1].value.concat(tag);
          }
        } else {
          const functionParams = getFunctionParams('avg_by');
          functionParams[1].value.push(tag);
          newFunctions.push({
            name: 'avg_by',
            params: functionParams,
            vectorType: 'instant',
          });
        }
        newQuery.functions = newFunctions;
      }

      callOnePromqlQuery({
        formulas,
        queries: newQueries,
        queryIndex,
        type: 'query',
        callType: null,
        date,
      });
      return newQueries;
    });
  };

  const updateSeries = (
    queryIndex: number,
    seriesIndex: number,
    type: 'label' | 'value',
    value: string,
  ) => {
    setQueries((prevQueries: ExplorerQueryProps[]) => {
      const newQueries = [...prevQueries];
      const newQuery = { ...newQueries[queryIndex] };
      const newSeries = [...newQuery.series];

      newSeries[seriesIndex] = value;
      newQuery.series = newSeries;
      newQueries[queryIndex] = newQuery;
      if (type === 'label') {
        loadLabelValues(newQuery.metric, seriesIndex, newSeries);
      }

      if (type === 'value') {
      }

      if (value !== '=""') {
        if (newQuery.isActive) {
          callOnePromqlQuery({
            formulas,
            queries: newQueries,
            queryIndex,
            type: 'query',
            callType: 'debounce',
            date,
          });
        }
        checkAndLoadedAffectedFormulas(queryIndex, newQueries, 'debounce');
      }
      return newQueries;
    });
  };

  const removeSeries = (queryIndex: number, seriesIndex: number) => {
    setQueries((prevQueries: ExplorerQueryProps[]) => {
      const newQueries = [...prevQueries];
      const newQuery = { ...newQueries[queryIndex] };
      const newSeries = [...newQuery.series];

      newSeries.splice(seriesIndex, 1);
      newQuery.series = newSeries;
      newQueries[queryIndex] = newQuery;

      if (newQuery.isActive) {
        callOnePromqlQuery({
          formulas,
          queries: newQueries,
          queryIndex,
          type: 'query',
          callType: null,
          date,
        });
      }
      checkAndLoadedAffectedFormulas(queryIndex, newQueries, 'normal');
      return newQueries;
    });
  };

  useEffect(() => {
    callMultiplePromqlQueries(queries, formulas, date);

    if (preLoadMetricList) {
      preLoadMetricList(setMetricsList);
    } else {
      getMetricsListRequest.call(date).then((metricsListResponse: string[]) => {
        if (metricsListResponse) {
          const metricsListOptions = metricsListResponse.map((metric) => {
            return { value: metric, label: metric };
          });
          setMetricsList(metricsListOptions);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const metricsQueries = new URLSearchParams(
      url.hash.substring(10, url.hash.length),
    ).get('metricsQueries');
    const metricsFormulas = new URLSearchParams(
      url.hash.substring(10, url.hash.length),
    ).get('metricsFormulas');

    if (metricsQueries) {
      try {
        const metricsQueriesJSON = JSON.parse(metricsQueries || '[]');
        const metricsFormulasJSON = JSON.parse(metricsFormulas || '[]');

        metricsQueriesJSON.forEach(
          (query: ExplorerQueryProps, index: number) => {
            loadInitialLabelsAndValues(query.metric, query.series);
          },
        );
      } catch {
        //
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    activeQueryType,
    addQuery,
    date,
    formulas,
    openMetricUsingTags,
    removeQuery,
    removeSeries,
    queries,
    updateQuery,
    updateParsedQuery,
    updateSeries,
    setQueries,
    toggleQueryAndCallOnePromqlQuery,
    ...queryOperation,
    ...metricsDataState,
    ...metricsFormulaState,
    ...metricsFunctionState,
    ...metricLabelDataState,
  };
};

export default useMetricsQuery;
