import { Chip } from 'components';
import React, { MutableRefObject, useRef } from 'react';

type Props = {
  onTagClick: (
    tag: string,
    tagRef: MutableRefObject<HTMLButtonElement>,
  ) => void;
  tag: string;
};

const KubernetesTagText = ({ onTagClick, tag }: Props) => {
  const tagRef = useRef<HTMLButtonElement>(null);

  return (
    <Chip asChild>
      <button
        data-testid={tag}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          onTagClick(tag, tagRef);
        }}
        ref={tagRef}
      >
        {tag}
      </button>
    </Chip>
  );
};

export default KubernetesTagText;
