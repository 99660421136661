const isScrolledToBottom = (element: HTMLElement): boolean => {
  return element.scrollTop + element.clientHeight === element.scrollHeight;
};

const isScrolledToTop = (element: HTMLElement): boolean => {
  return element.scrollTop === 0;
};

const isTryingToScrollUp = (wheelEvent: React.WheelEvent): boolean => {
  return wheelEvent.deltaY < 0;
};

const isTryingToScrollDown = (wheelEvent: React.WheelEvent): boolean => {
  return wheelEvent.deltaY > 0;
};

const isVerticallyScrollable = (element: HTMLElement): boolean => {
  return element.scrollHeight > element.clientHeight;
};

const getTop = (element: HTMLElement): number => {
  if (!element) {
    return 0;
  }
  const top = element.getBoundingClientRect().top;
  return top;
};

const updateTop = (element: HTMLElement, newTopInPx: number): string => {
  return (element.style.top = `${newTopInPx}px`);
};

// input
const isCursorAtLeftmost = (element: HTMLInputElement): boolean =>
  element.selectionStart === 0 && element.selectionEnd === 0;

const isCursorAtRightmost = (element: HTMLInputElement): boolean =>
  element.selectionStart === element.value.length &&
  element.selectionEnd === element.value.length;

const setCursorAtLeftmost = (element: HTMLInputElement): void => {
  element?.focus();
  element.setSelectionRange(0, 0);
};

const setCursorAtRightmost = (element: HTMLInputElement): void => {
  element?.focus();
  const length = element.value.length + 1;
  element.setSelectionRange(length, length);
};

const domUtil = {
  isScrolledToBottom,
  isScrolledToTop,
  isTryingToScrollUp,
  isTryingToScrollDown,
  isVerticallyScrollable,
  getTop,
  updateTop,
  isCursorAtLeftmost,
  isCursorAtRightmost,
  setCursorAtLeftmost,
  setCursorAtRightmost,
};

export default domUtil;
