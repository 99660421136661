import { cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from 'utils/tailwind';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  type?: string;
  size?: '1' | '2' | '3' | '4' | '5' | '6';
  variant?: 'default' | 'search';
};

const inputVariants = cva('', {
  variants: {
    variant: {
      default:
        'flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-placeholder disabled:cursor-not-allowed disabled:opacity-50 focus:bg-input-focus focus:outline-primary-shade',
      error: 'border-destructive',
      search:
        'size-full rounded border border-form-border pl-8 pr-4 text-xs  hover:border-form-border-hover focus:border-interaction-primary focus:bg-interaction-secondary bg-background focus:outline-none placeholder:text-tertiary',
    },
    size: {
      default: 'h-7',
      1: 'h-6 text-xs',
      2: 'h-[1.625rem] text-xs',
      3: 'h-7 text-xs',
      4: 'h-[1.875rem] text-xs',
      5: 'h-8 text-xs',
      6: 'h-[2.25rem] text-sm',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
});

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  // eslint-disable-next-line react/prop-types
  ({ className, variant, size, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = 'Input';

export { Input };
