import classNames from 'classnames';
import { Loader, TimeseriesRenderer } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect } from 'react';
import { promqlQueryRange } from 'requests';
import { DateSelection } from 'types';

const defaultChartTypes = ['Line'];

const AlertsServiceCorrelationsMetricsChartMini = ({
  promql,
  date,
  title,
  onRowClick,
}: {
  promql: string;
  date: DateSelection;
  title: string;
  onRowClick: (chartData: { data: any; series: any }) => void;
}): ReactElement => {
  const promqlQueryRangeRequest = useRequest(promqlQueryRange);

  const loadChartData = () => {
    promqlQueryRangeRequest.call({
      date: date,
      promqlQueries: [promql],
      metricNames: [''],
      type: 'timeseries',
    });
  };

  useEffect(() => {
    loadChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promql, date]);

  return (
    <div
      className="mt-2 flex cursor-pointer flex-row items-center justify-around border px-4 hover:bg-interaction-secondary"
      onClick={() => onRowClick(promqlQueryRangeRequest.result)}
    >
      <div className="text-sm font-semibold">{title}</div>
      <Loader
        className={classNames({
          alerts__chart__container__mini: true,
          'alerts__chart__container--placeholder':
            promqlQueryRangeRequest.isLoading,
        })}
        isLoading={promqlQueryRangeRequest.isLoading}
      >
        <TimeseriesRenderer
          chartTypes={defaultChartTypes}
          date={date || null}
          chartData={promqlQueryRangeRequest.result || { data: [], series: [] }}
          isLoading={false}
          unit="number"
          size={{ height: 140, width: 400 }}
        />
      </Loader>
    </div>
  );
};

export default AlertsServiceCorrelationsMetricsChartMini;
