import classNames from 'classnames';
import React, { ReactElement } from 'react';
import ChipWithLabel from './ChipWithLabel';
import { TooltipTrigger } from './TooltipTrigger';

type Props = {
  className?: string;
  color?: string;
  name: string;
  distinctLabels: Record<string, string>;
  labels: Record<string, string>;
  selected?: boolean;
};

const ServiceWithLabels = ({
  className,
  color,
  name,
  distinctLabels,
  labels,
  selected = false,
}: Props): ReactElement => {
  const distinctLabelKeys = Object.keys(distinctLabels || {}).filter(
    (label) => distinctLabels[label],
  );
  return (
    <ChipWithLabel
      color={color}
      label={
        <TooltipTrigger
          className={classNames({
            'service-with-labels flex w-full flex-row items-center': true,
            [className]: !!className,
          })}
          tooltip={() => {
            if (!labels || !Object.keys(labels).length) return null;
            return (
              <div data-testid="tooltip">
                {Object.keys(labels || {})
                  .filter((label) => labels[label])
                  .map((label) => (
                    <div className="flex" key={`${label}-${labels[label]}`}>
                      <div className="flex-1 pr-3 font-medium">{label}</div>
                      <div>{labels[label]}</div>
                    </div>
                  ))}
              </div>
            );
          }}
        >
          <div
            className="service-with-labels__name break-all"
            data-testid="service-name"
          >
            {name}
          </div>
          {distinctLabelKeys.length ? (
            <div
              className={classNames({
                'service-with-labels__distinct-labels': true,
                'ml-2 flex-1 truncate whitespace-nowrap text-2xs font-normal overflow-hidden':
                  true,
                'text-foreground': !selected,
                'text-white': selected,
              })}
            >
              {distinctLabelKeys.map((label) => (
                <div
                  className="service-with-labels__distinct-labels__item"
                  key={label}
                >
                  {distinctLabels[label]}
                </div>
              ))}
            </div>
          ) : null}
        </TooltipTrigger>
      }
    />
  );
};

export default ServiceWithLabels;
