import React, { useMemo } from 'react';

import { ServiceAccount } from '../types';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellTimestamp,
  KubeTableCell,
} from './Components';
import KubernetesTable from './KubernetesTable';
import { useEntitiesCount, useEntitiesData } from './useEntitiesData';

export default function KubernetesTableForServiceAccount() {
  const { entitiesType, facets, page, limit } = useKubernetesController();

  const filters = useMemo(() => [facets], [facets]);
  const [rows] = useEntitiesData<{ serviceAccount: ServiceAccount }>({
    filters,
    page,
    limit,
  });
  const [count] = useEntitiesCount({
    entitiesType,
    facets,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'kube_service_account',
          label: 'Service Account',
          renderCell(prop) {
            const serviceAccountName = prop.row.serviceAccount.metadata.name;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name pb-1.5 pt-1.5"
                tooltipText={serviceAccountName}
              >
                {serviceAccountName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell(prop) {
            return <KubeCellCluster {...prop} />;
          },
        },
        {
          key: 'metadata.namespace',
          label: 'Namespace',
          renderCell(prop) {
            return <KubeCellNamespace {...prop} />;
          },
        },
        {
          key: 'automountServiceAccountToken',
          label: 'Automount Token',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="left">
                {prop.row.serviceAccount.automountServiceAccountToken === true
                  ? 'true'
                  : 'false'}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerSecretsNames',
          label: 'Secret Names',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="left">
                {prop.row.serviceAccount.secrets
                  ? prop.row.serviceAccount.secrets[0]?.name
                  : ''}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'metadata.creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            return (
              <KubeCellTimestamp
                {...prop}
                timestamp={prop.row.serviceAccount.metadata.creationTimestamp}
              />
            );
          },
        },
        {
          key: 'headerSecrets',
          label: 'Secrets',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                1
              </KubeTableCell>
            );
          },
        },
      ]}
    />
  );
}
