import { isNumber, getRollupToSecond } from 'utils';

/**
 * Forecast history duration must be calculated by useing forecast duration
 * Logic to calculate forecast history duration
 * 1. Forecast history must be capped at 24 hours min and 7 days max
 */
export const calculateForecastLookbackDuration = (
  forecastDuration: string | number,
): string => {
  let forecastDurationIntoSeconds = forecastDuration as number;
  if (typeof forecastDuration === 'string') {
    forecastDurationIntoSeconds = getRollupToSecond(forecastDuration);
  }

  if (!isNumber(forecastDurationIntoSeconds)) return '';

  // 24 hour = 86400 seconds and 7 days = 604800 seconds
  const oneDay = 86400;
  if (forecastDurationIntoSeconds <= oneDay) return '1d';

  // if forecast duration is less than or equal to 3 days then history will be 2 days
  if (forecastDurationIntoSeconds <= oneDay * 3) return '1d';

  // if forecast duration is less than or equal to 7 days then history will be 3 days
  if (forecastDurationIntoSeconds <= oneDay * 7) return '3d';

  return '7d';
};

export const customStepForForecast = (duration: string): string => {
  const durationIntoSeconds = getRollupToSecond(duration);

  if (!isNumber(durationIntoSeconds)) return '';

  /**
   * If history is less than 24 hour then step will be empty
   * If history is greater than 24 hour and less than 7d then step will be 1 hour
   * If history is greater than 7d then step will be 1 day
   */
  if (durationIntoSeconds < 86400) return '';
  if (durationIntoSeconds >= 86400 && durationIntoSeconds < 604800) return '1h';
  return '1d';
};

export const getForecastLookbackWindow = (duration: string): string => {
  const forecastLookbackDuration = calculateForecastLookbackDuration(duration);
  const forecastLookbackStep = customStepForForecast(forecastLookbackDuration);

  return `${forecastLookbackDuration}:${forecastLookbackStep}`;
};
