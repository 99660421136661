import { TableColumn } from 'components/Table';
import { formatDurationNs } from 'utils/timeNs';

const formatTableDuration = ({
  data,
  options,
}: {
  data: {
    data: Record<string, unknown>[];
    columns: TableColumn<{ key: string; label: string }>[];
  };
  options: {
    isDurationField: (field: string) => boolean;
    fieldParser: (field: string) => string;
  };
}): {
  data: Record<string, unknown>[];
  columns: TableColumn<{ key: string; label: string }>[];
} => {
  if (!Array.isArray(data.data)) {
    return { data: [], columns: [] };
  }

  const { isDurationField, fieldParser } = options;
  if (
    typeof isDurationField !== 'function' ||
    typeof fieldParser !== 'function'
  ) {
    return data;
  }

  const newDurationData = data.data.map((datum) => {
    const labels: { [key: string]: any } = datum.label;
    if (!labels) return datum;
    Object.keys(labels).forEach((key) => {
      const field = fieldParser(key);
      if (isDurationField(field)) {
        try {
          labels[key] = formatDurationNs(Number(labels[key]), 1, 2);
        } catch (e) {
          console.error(e);
          labels[key] = datum[key];
        }
      }
    });
    return { ...datum, label: labels };
  });

  return { ...data, data: newDurationData };
};

export default formatTableDuration;
