import React, { ReactElement } from 'react';

type PopupProps = {
  title: string;
  message: string;
  algorithm?: string;
};

const AlertsDetectionPopup = ({
  title,
  message,
  algorithm,
}: PopupProps): ReactElement => {
  if (algorithm === 'dbscan') {
    return (
      <>
        {message}
        <a
          href="https://en.wikipedia.org/wiki/DBSCAN"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          Learn more
        </a>
      </>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="text-md font-semibold">{title}</div>
      <div>{message}</div>
    </div>
  );
};

export default AlertsDetectionPopup;
