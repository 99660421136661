import tokenizer from './tokenizer';

type Expr =
  | {
      type: 'BinaryExpr' | 'AddSubExpr';
      op: 'Addition' | 'Subtraction' | 'Multiplication' | 'Division';
      left: Expr;
      right: Expr;
    }
  | { type: 'NumberLiteral'; value: number }
  | { type: 'Variable'; name: string };

class ArithmeticParser {
  private tokens: string[];
  private lookAhead: string | null;

  constructor() {
    this.tokens = [];
    this.lookAhead = null;
  }

  parse(input: string): Expr {
    this.tokens = tokenizer(input);
    this.lookAhead = this.tokens.length > 0 ? this.tokens[0] : null;
    const expr = this.expression();
    if (this.lookAhead !== null) {
      throw new Error('Unexpected tokens at the end of expression');
    }
    return expr;
  }

  private expression(): Expr {
    let expr = this.term();

    while (this.lookAhead === '+' || this.lookAhead === '-') {
      const op = this.lookAhead === '+' ? 'Addition' : 'Subtraction';
      this.match(this.lookAhead);
      expr = {
        type: 'AddSubExpr',
        op,
        left: expr,
        right: this.term(),
      };
    }

    return expr;
  }

  private term(): Expr {
    let expr = this.factor();

    while (this.lookAhead === '*' || this.lookAhead === '/') {
      const op = this.lookAhead === '*' ? 'Multiplication' : 'Division';
      this.match(this.lookAhead);
      expr = {
        type: 'BinaryExpr',
        op,
        left: expr,
        right: this.factor(),
      };
    }

    return expr;
  }

  private factor(): Expr {
    if (this.lookAhead === '(') {
      this.match('(');
      const expr = this.expression();
      this.match(')');
      return expr;
    } else if (this.isNumber(this.lookAhead)) {
      const number = Number(this.lookAhead);
      this.match(this.lookAhead);
      return { type: 'NumberLiteral', value: number };
    } else if (this.isVariable(this.lookAhead)) {
      const name = this.lookAhead;
      this.match(this.lookAhead);
      return { type: 'Variable', name };
    } else {
      throw new Error(`Unexpected token: ${this.lookAhead}`);
    }
  }

  private match(token: string): void {
    if (this.lookAhead === token) {
      this.nextToken();
    } else {
      throw new Error(
        `Unexpected token: Expected ${token}, found ${this.lookAhead}`,
      );
    }
  }

  private nextToken(): void {
    this.tokens.shift();
    this.lookAhead = this.tokens.length > 0 ? this.tokens[0] : null;
  }

  private isNumber(token: string | null): boolean {
    return token !== null && !isNaN(Number(token));
  }

  private isVariable(token: string | null): boolean {
    return token !== null && /^[a-zA-Z]+$/.test(token);
  }
}

export default ArithmeticParser;
