import { FormulaProps, ExplorerQueryProps } from 'types';

import {
  ConditionProps,
  ForecastConditionProps,
} from '../AlertsCreateCondition';
import { validateAlertDetails } from './alertsDetailsValidator';
import { validateAlertThresholdValue } from './conditionValidator';
import customAnnotationsValidator from './customAnnotationsValidator';
import {
  validateForecastConditionLinear,
  validateForecastConditionSeasonal,
} from './forecastValidator';
import { AlertType, AlertsCreateDetailsProps } from '../types';
import { validateAlertMetricQueryKey } from './validateAlertMetricQueryKey';
/**
 * Order of threshold alert to be validated.
 * 1. Selected query exist
 * 2. Promql is valid and has metric selected
 * 3. Threshold value are correct
 * 4. Validate forecast condition
 * 5. Validate details of alert like name, folder
 * 6. Validate runbook must be a URL
 * 7. Custom annotations are not used from reserve
 */
const forecastAlertValidatorForCreate = ({
  alertsDetails,
  condition,
  forecastCondition,
  formulas,
  queries,
}: {
  alertsDetails: AlertsCreateDetailsProps;
  condition: ConditionProps;
  forecastCondition: ForecastConditionProps;
  formulas: FormulaProps[];
  queries: ExplorerQueryProps[];
}): string | { [key: string]: string } => {
  const { queryKey, value } = condition;

  // This will validate 1 and 2
  const validateQuery = validateAlertMetricQueryKey({
    formulas,
    queryKey,
    queries,
  });
  if (validateQuery) return validateQuery;

  // 3. Threshold value are correct
  const validateThreshold = validateAlertThresholdValue({
    value,
    alertType: AlertType.FORECAST,
  });
  if (Object.keys(validateThreshold).length) return validateThreshold;

  const { forecastAlgorithm } = forecastCondition;

  if (!forecastAlgorithm) {
    return 'Please select forecast algorithm';
  }

  if (forecastAlgorithm === 'linear') {
    const validateLinear = validateForecastConditionLinear(forecastCondition);
    if (validateLinear) return validateLinear;
  }

  if (forecastAlgorithm === 'seasonal') {
    const validateSeasonal =
      validateForecastConditionSeasonal(forecastCondition);

    if (typeof validateSeasonal === 'string') return validateSeasonal;
    if (Object.keys(validateSeasonal).length) return validateSeasonal;
  }

  // This will validate 5 and 6
  const validateDetails = validateAlertDetails(alertsDetails);
  if (Object.keys(validateDetails).length) return validateDetails;

  // This will validate 7
  const validateCustomAnnotations = customAnnotationsValidator(
    alertsDetails.customAnnotations,
  );
  if (validateCustomAnnotations) return validateCustomAnnotations;

  return {};
};

export default forecastAlertValidatorForCreate;
