import delimiter from 'kfuse-constants/delimiter';
import { FilterProps } from 'types';
import { escapePeriod } from 'utils/escapePeriod';

import { buildFacetQuery } from './buildFacetQuery';
import buildFingerprintsLogQuery from './buildFingerprintsLogQuery';
import buildSelectedFacetRange from './buildSelectedFacetRange';
import buildSelectedFacetsQuery, {
  buildFacetsQueryWithOr,
} from './buildSelectedFacetsQuery';
import buildSearchTermsFilter from './buildSearchTermsFilter';
import formatFacetName from './formatFacetName';

type Args = FilterProps & {
  source?: string;
};

const buildQuery = ({
  customerFilter,
  facetName,
  filterByFacets = [],
  filterOrExcludeByFingerprint = {},
  keyExists = {},
  logLevel,
  searchTerms = [],
  selectedFacetRanges = {},
  selectedFacetValues = {},
  selectedFacetValuesWithOr = {},
  searchTermsWithOr = [],
  source = null,
}: Args): string => {
  let result = '{';
  result += 'and: [';
  result += buildFingerprintsLogQuery(filterOrExcludeByFingerprint);

  result += buildSearchTermsFilter(searchTerms);

  if (logLevel) {
    result += `{ eq: { facetName: "level", value: "${logLevel}" } }`;
  }

  if (facetName) {
    result += `{ startsWith: { facetName: "${facetName}", value: "" } }`;
  }

  if (source) {
    result += `{ eq: { facetName: "source", value: "${source}" } }`;
  }

  Object.keys(keyExists).forEach((facetKeyWithType) => {
    const [component, name, type] = facetKeyWithType.split(delimiter);

    const nameParts = name.split(' OR ');

    const clauses = nameParts.map((name) =>
      component
        ? `{ keyExists: "${formatFacetName(component, name, type)}" }`
        : `{ keyExists: "@:${type}.${escapePeriod(name)}" }`,
    );

    const clause =
      clauses.length > 1 ? `{ or: [${clauses.join('')}]}` : clauses[0];

    const neq = !keyExists[facetKeyWithType];
    if (neq) {
      result += `{ not: ${clause} }`;
    } else {
      result += clause;
    }
  });

  result += buildSelectedFacetRange(selectedFacetRanges);

  result += buildSelectedFacetsQuery(selectedFacetValues);

  if (
    customerFilter &&
    customerFilter.key &&
    customerFilter.value &&
    customerFilter.value !== 'All'
  ) {
    result += '{ and: [';
    result += `{ eq: { facetName: "${customerFilter.key}", value: "${customerFilter.value}" } }`;
    result += '] }';
  }
  if (
    (selectedFacetValuesWithOr &&
      Object.keys(selectedFacetValuesWithOr).length > 0) ||
    (searchTermsWithOr && searchTermsWithOr.length > 0)
  ) {
    let orResult = '{ or: [';
    if (searchTermsWithOr && searchTermsWithOr.length > 0) {
      orResult += buildSearchTermsFilter(searchTermsWithOr);
    }
    if (
      selectedFacetValuesWithOr &&
      Object.keys(selectedFacetValuesWithOr).length > 0
    ) {
      orResult += buildFacetsQueryWithOr(selectedFacetValuesWithOr);
    }
    orResult += '] }';
    result += orResult;
  }

  result += buildFacetQuery(filterByFacets);
  result += ']';

  result += '}';
  return result;
};

export default buildQuery;
