import classNames from 'classnames';
import { Input } from 'components/shadcn';
import React, { ReactElement } from 'react';
import { FaExclamation } from 'react-icons/fa';

import { TooltipTrigger } from '../TooltipTrigger';
import { InputWithValidatorV3Props } from './types';

const InputWithValidatorV3 = ({
  className,
  error,
  errorTextClassName,
  errorMessageShownType = 'tooltip',
  exclamationMarkPosition = 'right',
  hideErrorMessage = false,
  onChange,
  ...rest
}: InputWithValidatorV3Props): ReactElement => {
  return (
    <div className="relative">
      {errorMessageShownType === 'tooltip' && (
        <TooltipTrigger
          tooltip={<div className={errorTextClassName}>{error}</div>}
          delayDuration={100}
          contentClassName={!error ? 'opacity-0' : ''}
          className="w-full"
        >
          <Input
            className={classNames({
              'input__background--error': error,
              [className]: className,
            })}
            onChange={(e) => onChange(e.target.value)}
            {...rest}
          />
        </TooltipTrigger>
      )}
      {errorMessageShownType === 'inline' && (
        <div>
          <Input
            className={classNames({
              'input__background--error': error,
              [className]: className,
            })}
            onChange={(e) => onChange(e.target.value)}
            {...rest}
          />
          {error && !hideErrorMessage && (
            <p className={classNames('text--red text-xs', errorTextClassName)}>
              {error}
            </p>
          )}
        </div>
      )}
      {error && exclamationMarkPosition !== 'none' && (
        <span
          className={classNames(
            'absolute -right-[7px] -top-[7px] z-[1000]',
            exclamationMarkPosition === 'right'
              ? '-right-[7px]'
              : '-left-[7px]',
          )}
        >
          <span className="flex size-[16px] items-center justify-center rounded-full bg-destructive">
            <FaExclamation size={9} fill="var(--ui-background)" />
          </span>
        </span>
      )}
    </div>
  );
};

export default InputWithValidatorV3;
