import React from 'react';

import { GRID_COLUMN_COUNT } from '../constants';
import { useDashboardGridlineContext } from './DashboardGridlineContext';

const DashboardGridline = () => {
  const { isDragging } = useDashboardGridlineContext();

  if (!isDragging) return null;

  const innerWidth = window.innerWidth - 56;
  const oneGridWidth = innerWidth / GRID_COLUMN_COUNT;
  return (
    <svg className="dashboard__body__gridline">
      {Array.from({ length: oneGridWidth }, (_, i) => (
        <line
          key={i}
          className="dashboard__body__gridline__line"
          x1="0"
          y1={i * oneGridWidth}
          x2="100%"
          y2={i * oneGridWidth}
          strokeWidth="1"
        />
      ))}
      {Array.from({ length: GRID_COLUMN_COUNT + 1 }, (_, i) => (
        <line
          key={i}
          className="dashboard__body__gridline__line"
          x1={i * oneGridWidth}
          y1="0"
          x2={i * oneGridWidth}
          y2="100%"
          strokeWidth="1"
        />
      ))}
    </svg>
  );
};

export default DashboardGridline;
