import React from 'react';
import { Icon } from 'components';
import classnames from 'classnames';

const GroupByChip = ({
  children,
  onRemove,
}: {
  children: string;
  onRemove: () => void;
}): React.ReactElement => {
  return (
    <div
      className={classnames(
        'bg-chip-groupby-bg text-text-secondary',
        'group flex items-center border-border',
        'rounded pl-1.5 font-robotoMono text-[15px]',
        'relative whitespace-nowrap',
        'min-w-0 max-w-[92%]',
      )}
    >
      <div className="min-w-0 grow truncate" title={children}>
        {children}
      </div>
      <button
        className={classnames(
          'h-full items-center',
          'ml-0.5 px-1',
          'flex justify-center rounded-r',
          'hover:bg-chip-groupby-active-bg',
          'hover:text-primary-foreground',
        )}
        onClick={(e) => {
          e.stopPropagation();
          onRemove();
        }}
      >
        <Icon icon="x" />
      </button>
    </div>
  );
};

export default GroupByChip;
