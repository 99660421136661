import {
  Loader,
  Table,
  TooltipTrigger,
  TableSearch,
  useTableSearch,
} from 'components';
import React, { ReactElement, useMemo } from 'react';
import { convertNumberToReadableUnit } from 'utils/formatNumber';

import useMetricsCardinality from './useMetricsCardinality';
import MetricsCardinalityBarChart from './MetricsCardinalityBarChart';

const columns = ({
  isChartLoading,
  isValueLoading,
  isValueChartLoading,
  isValueHrLoading,
  overallCardinality,
}: {
  isChartLoading: boolean;
  isValueLoading: boolean;
  isValueChartLoading: boolean;
  isValueHrLoading: boolean;
  overallCardinality: number;
}) => [
  {
    key: 'label',
    label: 'Label',
  },
  {
    key: 'seriesCount',
    label: 'Series Count',
    renderCell: ({ row }: { row: any }) => {
      if (!row.seriesCount) return '';
      return (
        <TooltipTrigger tooltip={row.seriesCount > 1000 ? row.seriesCount : ''}>
          {convertNumberToReadableUnit(
            Math.min(row.seriesCount, overallCardinality),
          )}
        </TooltipTrigger>
      );
    },
  },
  {
    key: 'seriesPercentage',
    label: 'Series Percentage',
    renderCell: ({ row }: { row: any }) => {
      if (!row.seriesCount) return '';
      const percent = (row.seriesCount / overallCardinality) * 100;
      return Math.min(percent, 100).toFixed(2) + '%';
    },
  },
  {
    key: 'valueCountHour',
    label: 'Value Count (1h)',
    renderCell: ({ row }: { row: any }) => {
      if (isValueHrLoading) return <div className="spinner" />;
      return (
        <TooltipTrigger tooltip={row.valueCount > 1000 ? row.valueCount : ''}>
          {convertNumberToReadableUnit(row.valueCountHr)}
        </TooltipTrigger>
      );
    },
  },
  {
    key: 'valueCount',
    label: 'Value Count',
    renderCell: ({ row }: { row: any }) => {
      if (isValueLoading) return <div className="spinner" />;
      return (
        <TooltipTrigger tooltip={row.valueCount > 1000 ? row.valueCount : ''}>
          {convertNumberToReadableUnit(row.valueCount)}
        </TooltipTrigger>
      );
    },
  },
  {
    key: 'valueChart',
    label: 'Value Chart',
    renderCell: ({ row }: { row: any }) => {
      if (isValueChartLoading) return <div className="spinner" />;
      return <MetricsCardinalityBarChart data={row.valueSeries || []} />;
    },
  },
  {
    key: 'seriesChart',
    label: 'Series Chart',
    renderCell: ({ row }: { row: any }) => {
      if (isChartLoading) return <div className="spinner" />;
      return <MetricsCardinalityBarChart data={row.timeSeries || []} />;
    },
  },
];

const MetricsCardinalityTable = ({
  metricsCardinality,
}: {
  metricsCardinality: ReturnType<typeof useMetricsCardinality>;
}): ReactElement => {
  const {
    cardinalityQuery,
    cardinalityRows,
    labelCardinalityRequest,
    metricsCardinalityRequest,
    labelSeriesTimeseriesRequest,
    labelValueCountHourRequest,
    labelValueCountRequest,
    labelValueTimeseriesRequest,
    metricSeriesTimeseriesRequest,
  } = metricsCardinality;
  const { type } = cardinalityQuery;

  const tableSearch = useTableSearch({
    rows: cardinalityRows,
    shouldWriteToUrl: false,
    searchSpecifcRows: ['label'],
  });

  const dataSourceRequest =
    cardinalityQuery.type === 'label'
      ? labelCardinalityRequest
      : metricsCardinalityRequest;

  const timeseriesRequest =
    type === 'label'
      ? labelSeriesTimeseriesRequest
      : metricSeriesTimeseriesRequest;

  const columnsMemo = useMemo(
    () => {
      const obj = {
        isChartLoading: timeseriesRequest.isLoading,
        isValueLoading: labelValueCountRequest.isLoading,
        isValueChartLoading: labelValueTimeseriesRequest.isLoading,
        isValueHrLoading: labelValueCountHourRequest.isLoading,
        overallCardinality: dataSourceRequest.result?.overallCardinality || 0,
      };
      if (cardinalityQuery.type === 'metric') {
        const metricColumns = [...columns(obj)];
        metricColumns[0].label = 'Metric';
        return metricColumns.filter(
          (col) =>
            col.key !== 'valueCount' &&
            col.key !== 'valueChart' &&
            col.key !== 'valueCountHour',
        );
      }

      return columns(obj);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cardinalityRows,
      timeseriesRequest.isLoading,
      labelValueCountRequest.isLoading,
      labelValueTimeseriesRequest.isLoading,
      labelValueCountHourRequest.isLoading,
      dataSourceRequest.isLoading,
    ],
  );

  return (
    <>
      <div className=" text--h3 pt-4">
        All cardinality: {dataSourceRequest.result?.overallCardinality || 0}
      </div>
      <Loader className="mb-8 mt-2" isLoading={dataSourceRequest.isLoading}>
        <TableSearch
          className="uplot__value-legends__search-bar"
          placeholder={`Search ${cardinalityQuery.type || ''}...`}
          tableSearch={tableSearch}
          dataTestId="label-search-bar"
        />
        <Table
          className="table--bordered table--bordered-cells metrics-cardinality-table"
          columns={columnsMemo}
          rows={tableSearch.searchedRows}
          dataTestId="metrics-cardinality-table"
        />
      </Loader>
    </>
  );
};

export default MetricsCardinalityTable;
