import { AutocompleteOption } from 'components';
import { RuleType } from '../../types';
import {
  AnomalyConditionProps,
  ConditionProps,
  ForecastConditionProps,
  MetricChangeConditionProps,
  OutlierConditionProps,
} from '../types';

export const defaultCondition = (ruleType: RuleType): ConditionProps => ({
  of: 'gt',
  queryKey: 'Query (a)',
  value: '',
  when: ruleType === RuleType.APM ? 'max' : 'last',
  noData: ruleType === RuleType.LOGS || RuleType.APM ? 'OK' : 'NoData',
  executionError: 'Error',
});

export const conditionForReducerLabel: AutocompleteOption[] = [
  { label: 'Mean', value: 'mean' },
  { label: 'Min', value: 'min' },
  { label: 'Max', value: 'max' },
  { label: 'Sum', value: 'sum' },
  { label: 'Count', value: 'count' },
  { label: 'Last', value: 'last' },
];

export const conditionByLabel: AutocompleteOption[] = [
  { label: 'above', value: 'gt' },
  { label: 'below', value: 'lt' },
  { label: 'equal to', value: 'eq' },
  { label: 'not equal to', value: 'neq' },
];

export const noDataType: AutocompleteOption[] = [
  { label: 'Alerting', value: 'Alerting' },
  { label: 'No Data', value: 'NoData' },
  { label: 'OK', value: 'OK' },
];

export const executionErrorType: AutocompleteOption[] = [
  { label: 'Alerting', value: 'Alerting' },
  { label: 'OK', value: 'OK' },
  { label: 'Error', value: 'Error' },
];

export const defaultMetrics: MetricChangeConditionProps = {
  change: 'change',
  time: 'now-1h',
  comparedTime: 'now-5m',
};

export const defaultOutliersCondition: OutlierConditionProps = {
  algorithm: 'DBSCAN',
  tolerance: '0.6',
};

export const defaultAnomalyCondition: AnomalyConditionProps = {
  anomalyAlgorithm: '',
  window: '5m',
  bound: '1',
  band: 'both',
  model: '0',
  period: '2',
  seasonality: '3600',
};

export const defaultForecastCondition: ForecastConditionProps = {
  forecastAlgorithm: '',
  forecastDuration: '',
  seasonality: '1',
  interval: '600',
};
