import React, {
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
} from 'react';
import uPlot, { AlignedData } from 'uplot';
import { UPlotConfig } from './types';
import { dataMatch, optionsUpdateState } from './utils';
import { ChartType } from 'types/Timeseries';

type Props = {
  activeChart: ChartType;
  data: AlignedData;
  options: UPlotConfig;
  chartRef?: MutableRefObject<uPlot | null>;
};

const UplotChart = ({
  activeChart,
  data,
  options,
  chartRef,
}: Props): ReactElement => {
  const targetRef = useRef<HTMLDivElement>(null);

  function destroy(chart: uPlot | null) {
    if (chart) {
      chart.destroy();
      chartRef.current = null;
    }
  }
  function create() {
    const newChart = new uPlot(
      options,
      data,
      targetRef.current as HTMLDivElement,
    );
    chartRef.current = newChart;
  }

  useEffect(() => {
    create();
    return () => {
      destroy(chartRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prevProps = useRef({ options, data }).current;
  useEffect(() => {
    const chart = chartRef.current;
    if (prevProps.options !== options) {
      const optionsState = optionsUpdateState(prevProps.options, options);
      if (!chart || optionsState === 'create') {
        destroy(chart);
        create();
      } else if (optionsState === 'update') {
        chart.setSize({ width: options.width, height: options.height });
      } else {
        destroy(chart);
        create();
      }
    }
    if (prevProps.data !== data) {
      if (!chart) {
        create();
      } else if (
        activeChart !== 'Heatmap' &&
        !dataMatch(prevProps.data, data)
      ) {
        chart.setData(data);
      } else {
        chart.setData(data);
      }
    }

    return () => {
      prevProps.options = options;
      prevProps.data = data;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, data]);

  return <div ref={targetRef} style={{ cursor: 'crosshair' }}></div>;
};

export default UplotChart;
