import stringToColor from './stringToColor';

const resolveColorFromMap = (
  colorMap: { [key: string]: string },
  key: string,
) => {
  if (!key) {
    return '';
  }
  try {
    return colorMap?.[key] || stringToColor(key);
  } catch (e) {
    console.log(e);
    return '';
  }
};

export default resolveColorFromMap;
