import classnames from 'classnames';
import { Json, JsonType } from 'components';
import { useKeyExistsState, useToggle } from 'hooks';
import React from 'react';
import { useOverflowDetector } from 'react-detectable-overflow';
import { LogEvent } from 'types';
import LogsLogLineParts from './LogsLogLineParts';
import { useLogsState } from './hooks';

type Props = {
  customColumnsState?: ReturnType<typeof useKeyExistsState>;
  isJSONPrettified: boolean;
  logEvent: LogEvent;
  logsState: ReturnType<typeof useLogsState>;
  renderedMessage: string | Record<string, any>;
  shouldDisableFilterActions: boolean;
};

const LogsSelectedLogEvent = ({
  customColumnsState,
  isJSONPrettified,
  logEvent,
  logsState,
  renderedMessage,
  shouldDisableFilterActions,
}: Props) => {
  const { logLineParts } = logEvent;
  const { ref, overflow } = useOverflowDetector({});
  const isExpandedToggle = useToggle();

  return (
    <>
      <div
        className={classnames({
          'logs__selected-log__message__text': true,
          'logs__selected-log__message__text--expanded': isExpandedToggle.value,
          'text--prewrap': true,
        })}
        ref={ref}
      >
        {logLineParts ? (
          <LogsLogLineParts
            customColumnsState={customColumnsState}
            fpHash={logEvent.fpHash}
            logLineParts={logLineParts}
            logsState={logsState}
            source={logEvent.labels.source}
            shouldDisableFilterActions={shouldDisableFilterActions}
          />
        ) : isJSONPrettified ? (
          <Json data={renderedMessage} />
        ) : typeof renderedMessage === 'string' ? (
          renderedMessage
        ) : (
          JSON.stringify(renderedMessage, null, 2)
        )}
      </div>
      <div className="text-right">
        {overflow || isExpandedToggle.value ? (
          <button className="link" onClick={isExpandedToggle.toggle}>
            {isExpandedToggle.value ? 'Less' : '...More'}
          </button>
        ) : null}
      </div>
    </>
  );
};

export default LogsSelectedLogEvent;
