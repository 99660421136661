import {
  AutocompleteOption,
  evaluationWindowTimeMappingOption,
} from 'components';
import {
  buildFormulaQuery,
  buildPromqlWithFunctions,
  decodePromqlToReadable,
  isDevEnv,
  parsePromqlAndBuildQuery,
} from 'utils';

import { MetricChangeConditionProps } from '../../AlertsCreateCondition';

export const changeType: AutocompleteOption[] = [
  { label: 'Change', value: 'change' },
  { label: 'Change %', value: 'change_percent' },
];

export const timeType: AutocompleteOption[] = [
  { label: '5 minutes', value: 'now-5m' },
  { label: '10 minutes', value: 'now-10m' },
  { label: '15 minutes', value: 'now-15m' },
  { label: '30 minutes', value: 'now-30m' },
  { label: '1 hour', value: 'now-1h' },
  { label: '2 hours', value: 'now-2h' },
  { label: '4 hours', value: 'now-4h' },
  { label: '1 day', value: 'now-1d' },
  { label: '2 days', value: 'now-2d' },
  { label: '1 week', value: 'now-7d' },
  { label: '1 month', value: 'now-30d' },
];

const evaluateForTypeActual: AutocompleteOption[] = [
  { label: '1 minute', value: '1m' },
  { label: '5 minutes', value: '5m' },
  { label: '10 minutes', value: '10m' },
  { label: '15 minutes', value: '15m' },
  { label: '30 minutes', value: '30m' },
  { label: '1 hour', value: '1h' },
  { label: '2 hours', value: '2h' },
  { label: '4 hours', value: '4h' },
  { label: '1 day', value: '1d' },
  { label: '2 days', value: '2d' },
  { label: '1 week', value: '1w' },
  { label: '1 month', value: '1M' },
];

export const evaluateForType: AutocompleteOption[] =
  evaluateForTypeActual.filter((item) => {
    if (isDevEnv) return true;
    return item.value !== '1m';
  });

export const evaluationWindowTimeMapping: evaluationWindowTimeMappingOption = {
  'now-1d': '10m',
  'now-7d': '30m',
};

export const changeConditionByLabel: AutocompleteOption[] = [
  { label: 'above', value: 'gt' },
  { label: 'below', value: 'lt' },
  { label: 'equal to', value: 'eq' },
  { label: 'not equal to', value: 'neq' },
  { label: 'above or equal to', value: 'gte' },
  { label: 'below or equal to', value: 'lte' },
];

export const parseChangePromql = (
  promql: string,
): { change: string; promql: string; time: string } => {
  let time = 'now-1h';
  let change = 'change';

  // find offset and value next to it
  const offsetRegex = /offset\s(\d+[smhdwMy])/g;
  const offsetMatch = offsetRegex.exec(promql);
  if (offsetMatch) {
    time = `now-${offsetMatch[1]}`;
  }

  if (promql.includes(') / ') && promql.startsWith('(')) {
    change = 'change_percent';
  }

  const innerPromql = getChangeAlertInnerPromql(promql, change);
  if (!innerPromql) return { change, promql: '', time };

  return { change, promql: decodePromqlToReadable(innerPromql), time };
};

export const getChangeAlertInnerPromql = (
  promql: string,
  change: string,
): string => {
  let fullPromql = promql;
  if (change === 'change_percent') {
    fullPromql = promql.split(') / ')[0];
  }
  const splitPromql = fullPromql.split(' - ');

  let firstHalf = splitPromql[0];
  let secondHalf = splitPromql[1];
  if (splitPromql.length > 2) {
    const halfIndex = Math.floor(splitPromql.length / 2);
    firstHalf = splitPromql.slice(0, halfIndex).join(' - ');
    secondHalf = splitPromql.slice(halfIndex).join(' - ');
  }

  if (change === 'change_percent' && firstHalf.charAt(0) === '(') {
    return firstHalf.slice(1);
  }
  return firstHalf;
};

export const buildChangeAlertPromql = (
  changeCondition: MetricChangeConditionProps,
  promql: string,
): string => {
  if (!promql) return '';

  const { change, time } = changeCondition;
  const timeDuration = time.split('-')[1];

  const { queries, formulas } = parsePromqlAndBuildQuery([promql]);
  queries[0].functions.unshift({
    name: 'offset',
    params: [
      { default: '', name: 'duration', type: 'select', value: timeDuration },
    ],
    vectorType: 'instant',
  });

  let offsetQuery = '';
  if (formulas.length > 0) {
    const promqlQueries = queries.map((query) =>
      buildPromqlWithFunctions(query, 'string'),
    );
    const queryKeys = queries.map((query) => query.queryKey);
    const formulaQuery = buildFormulaQuery(promqlQueries, queryKeys, formulas);
    offsetQuery = formulaQuery ? formulaQuery[0] : '';
  } else {
    offsetQuery = buildPromqlWithFunctions(queries[0], 'string');
  }

  if (change === 'change_percent') {
    return `(${promql} - ${offsetQuery} ) / ${offsetQuery}`;
  }

  return `${promql} - ${offsetQuery}`;
};
