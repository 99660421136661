import { useForm } from 'hooks';
import React, { ReactElement } from 'react';
import { Chart } from 'types';

import CheckboxWithLabel from '../CheckboxWithLabel';
import useChartGridItemData from './useChartGridItemData';

type Props = {
  chart: Chart;
  chartGridItemData: ReturnType<typeof useChartGridItemData>;
  form: ReturnType<typeof useForm>;
};

const ChartGridItemHeaderTopK = ({
  chart,
  chartGridItemData,
  form,
}: Props): ReactElement => {
  const { libraryType, limitTo, limitToValue } = chart;
  const { keys, queryRangeRequest } = chartGridItemData;
  const { propsByKey } = form;

  const uplotDatalength = queryRangeRequest.result?.data.length;
  const reChartsDataLength = keys.length;
  const dataLength =
    libraryType === 'uplot' ? uplotDatalength : reChartsDataLength;

  if (dataLength > 5 && !(limitTo && limitToValue)) {
    return (
      <CheckboxWithLabel
        label="Show Top 5"
        {...(propsByKey('shouldShowTop5') as {
          onChange: (value: any) => void;
          value: boolean;
        })}
        dataTestId="show-top-5"
      />
    );
  }

  return null;
};

export default ChartGridItemHeaderTopK;
