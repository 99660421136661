import { SearchTag } from 'components';
import { BitmapFilter } from 'hooks';
import React from 'react';

type Args = {
  filter: BitmapFilter;
  renderFacet: (facet: string) => string;
};

const getKeyExistsTag = ({ filter, renderFacet }: Args): SearchTag[] => {
  const { facet } = filter.value;
  const renderedFacet = renderFacet ? renderFacet(facet) : facet;

  const label = (
    <>
      <span className="traces-search__input__trigger__tag__name">
        key exists
      </span>
      <span>=</span>
      <span className="traces-search__input__trigger__tag__value">
        {`"${renderedFacet}"`}
      </span>
    </>
  );

  return {
    label,
    text: `key exists="${renderedFacet}"`,
  };
};

export default getKeyExistsTag;
