import {
  AutocompleteV2,
  Button,
  CheckboxWithLabel,
  Input,
  Textarea,
} from 'components';
import React, { ReactElement } from 'react';

import { ContactNotifierTypeOptions } from './types';

const AlertsContactsCreateElementField = ({
  disabled,
  field,
  onChange,
  onClear,
  showClear,
  value,
}: {
  disabled?: boolean;
  field: ContactNotifierTypeOptions;
  onChange: (val: string | boolean) => void;
  onClear?: () => void;
  showClear?: boolean;
  value?: string | boolean;
}): ReactElement => {
  const { description, element, label, placeholder } = field;

  if (element === 'input' && disabled && showClear) {
    return (
      <div className="relative flex items-center">
        <Input
          disabled={disabled}
          onChange={onChange}
          placeholder="Configured"
          type="text"
          value={value as string}
        />
        <Button
          className="absolute right-0 m-1 border-0"
          size="xs"
          variant="outline-secondary"
          onClick={onClear}
        >
          Clear
        </Button>
      </div>
    );
  }

  if (element === 'input') {
    return (
      <Input
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        type="text"
        value={value as string}
      />
    );
  }

  if (element === 'select') {
    return (
      <AutocompleteV2
        onChange={onChange}
        options={field.selectOptions}
        placeholder={'Choose'}
        value={value as string}
      />
    );
  }

  if (element === 'textarea') {
    return (
      <Textarea
        className="alerts__create__details__textarea"
        onChange={onChange}
        placeholder={placeholder}
        type="text"
        value={value as string}
      />
    );
  }

  if (element === 'checkbox') {
    return (
      <div className="pt-4">
        <CheckboxWithLabel
          label={label}
          onChange={onChange}
          value={value as boolean}
        />
        {Boolean(description) && (
          <div className="alerts__contacts__create__element__description">
            {description}
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default AlertsContactsCreateElementField;
