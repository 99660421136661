import { useColumnsState } from 'components';
import {
  useFacetRegexState,
  useRequest,
  useSelectedFacetRangeByNameState,
  useSelectedFacetValuesByNameState,
  useSortState,
} from 'hooks';
import { useMemo, useRef, useState } from 'react';
import { DateSelection } from 'types';
import { RumEventType } from './types';
import useDebouncedEffect from 'use-debounced-effect';
import getRumEvents from './requests/getRumEvents';
import { isEventTableUIPaginated } from './requests/utils';
import { RumEventData } from './types';

type Args = {
  applicationFilter: string;
  columnsState: ReturnType<typeof useColumnsState>;
  date: DateSelection;
  eventType: RumEventType;
  facetRegexState?: ReturnType<typeof useFacetRegexState>;
  idSearch: string;
  rumTableSortState?: ReturnType<typeof useSortState>;
  selectedFacetRangeByNameState: ReturnType<
    typeof useSelectedFacetRangeByNameState
  >;
  selectedFacetValuesByNameState?: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
  viewId?: string;
  actionId?: string;
  actionIds?: string;
};

type RumEvent = {
  time: number;
  data: RumEventData;
  eventType: string;
};

type RumTableRequestResult = {
  data: RumEvent[];
  error: string | null;
};

const useRumTableRequest = ({
  applicationFilter,
  columnsState,
  date,
  eventType,
  facetRegexState,
  idSearch,
  rumTableSortState,
  selectedFacetRangeByNameState,
  selectedFacetValuesByNameState,
  ...idFilter
}: Args): {
  call: () => void;
  isLoading: boolean;
  onScrollEnd: () => void;
  result: RumTableRequestResult;
} => {
  const pageCursorRef = useRef<string>(null);

  const request = useRequest(getRumEvents, true, true);
  const [result, setResult] = useState({ data: [], error: null });

  const isUIPaginated = useMemo(() => {
    return isEventTableUIPaginated(eventType);
  }, [eventType]);

  const handleError = (error: string) => {
    setResult((prevResult) => ({ ...prevResult, error }));
  };

  const call = () => {
    const cursor = pageCursorRef.current || '';
    const { startTimeUnix, endTimeUnix } = date;
    request
      .call({
        applicationFilter,
        columnsState,
        cursor,
        eventType,
        facetRegex: facetRegexState?.state,
        startTimeUnix,
        idSearch,
        endTimeUnix,
        sortBy: rumTableSortState.state.key,
        sortOrder: rumTableSortState.state.isAsc ? 'asc' : 'desc',
        selectedFacetRangeByName: selectedFacetRangeByNameState.state,
        selectedFacetValuesByName: selectedFacetValuesByNameState.state,
        ...idFilter,
      })
      .then((nextResult) => {
        if (nextResult && nextResult.data && nextResult.data.length) {
          pageCursorRef.current = nextResult.cursor;
          if (isUIPaginated) {
            setResult({
              data: nextResult.data.slice(0, 200),
              error: null,
            });
          } else {
            setResult((prevResult) => ({
              data: [...prevResult.data, ...nextResult.data],
              error: null,
            }));
          }
        }
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const onScrollEnd = () => {
    if (isUIPaginated) {
      if (
        request.result.data &&
        result.data.length < request.result.data.length
      ) {
        setResult((prevResult) => ({
          ...prevResult,
          data: [
            ...prevResult.data,
            ...request.result.data.slice(
              prevResult.data.length,
              prevResult.data.length + 200,
            ),
          ],
        }));
      }
    } else if (pageCursorRef.current) {
      call();
    }
  };

  useDebouncedEffect(
    () => {
      pageCursorRef.current = null;
      setResult({ data: [], error: null });
      call();
    },
    {
      ignoreInitialCall: false,
      timeout: 200,
    },
    [
      applicationFilter,
      date,
      eventType,
      facetRegexState?.state,
      idSearch,
      columnsState?.state?.selectedColumns,
      selectedFacetRangeByNameState.state,
      selectedFacetValuesByNameState.state,
      rumTableSortState?.state,
    ],
  );

  return {
    call,
    isLoading: request.isLoading,
    onScrollEnd,
    result,
  };
};

export default useRumTableRequest;
