import { TopList, Loader, useThemeContext } from 'components';
import React, { ReactElement, useMemo } from 'react';
import { DashboardPanelComponentProps, DashboardPanelType } from 'types';
import { queryDataFormattingByGraphType } from 'utils';

import { useDashboardDataLoader } from '../hooks';
import { getPanelWidthHeight } from '../utils';

const DashboardPanelTopList = ({
  baseWidth,
  dashboardState,
  dashboardTemplateState,
  isInView,
  nestedIndex,
  panel,
  panelIndex,
}: DashboardPanelComponentProps): ReactElement => {
  const { templateValues } = dashboardTemplateState;
  const { darkModeEnabled } = useThemeContext();

  const dashboardDataLoader = useDashboardDataLoader({
    baseWidth,
    dashboardState,
    dashboardTemplateState,
    isInView,
    nestedIndex,
    panelIndex,
    templateValues,
    type: DashboardPanelType.TOP_LIST,
  });

  const topListQueryData = useMemo(
    () =>
      queryDataFormattingByGraphType({
        darkModeEnabled,
        graphType: DashboardPanelType.TOP_LIST,
        queries: [{ queryKey: 'a', isActive: true }],
        queryData: { query_a: dashboardDataLoader.result },
        formulas: [],
      }),
    [darkModeEnabled, dashboardDataLoader.result],
  );
  const panelSize = getPanelWidthHeight(panel.gridPos, baseWidth, panel.title);

  return (
    <Loader isLoading={dashboardDataLoader.isLoading}>
      <div
        className="overflow-auto p-2"
        style={{ maxHeight: panelSize.heightContainer - 8 }}
      >
        {topListQueryData?.data ? (
          <TopList
            data={topListQueryData.data}
            height={panelSize.heightContainer}
            width={panelSize.width - 24}
          />
        ) : null}
      </div>
    </Loader>
  );
};

export default DashboardPanelTopList;
