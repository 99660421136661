import {
  SunburstGraph,
  Table,
  TableColumn,
  TreemapGraph,
  useTableSort,
} from 'components';
import React, { ReactElement, useState } from 'react';
import ResizeObserver from 'rc-resize-observer';
import { SunburstDataProps } from 'types/Sunburst';

const AnalyticsChartTreeMap = ({
  data,
  columns,
  treeChartData,
}: {
  data: Record<string, string>[];
  columns: TableColumn<{ key: string; label: string }>[];
  treeChartData: SunburstDataProps[];
}): ReactElement => {
  const [width, setWidth] = useState(document.body.clientWidth - 300);

  if (!data || !Array.isArray(data)) return null;
  if (data.length === 0) return null;

  return (
    <ResizeObserver onResize={(size) => setWidth(size.width)}>
      <div className="dashboard__widget__piechart">
        <div className="">
          <TreemapGraph
            data={{ name: '', children: treeChartData, color: 'transparent' }}
            height={400}
            width={width < 300 ? 300 : width}
          />
        </div>
      </div>
    </ResizeObserver>
  );
};

export default AnalyticsChartTreeMap;
