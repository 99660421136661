import { HeatmapDataProps } from 'types/heatmap';
import { isDevEnv, findUnitCategoryFormatById, DataFrameMeta } from 'utils';

const fillEmptyTimestamps = (timestamps: number[], meta: DataFrameMeta) => {
  const { executedDate, step } = meta;
  const filledTimestamps = [];
  const startTime = executedDate.startTimeUnix;
  const endTime = executedDate.endTimeUnix;

  for (let i = startTime + step; i <= endTime; i += step) {
    filledTimestamps.push(i);
  }

  return filledTimestamps;
};

export const heatmapDataTransformer = ({
  datasets,
  meta,
}: {
  datasets: HeatmapDataProps;
  meta: DataFrameMeta;
}): {
  data: [number[], number[], number[]];
  minValue: number;
  maxValue: number;
  unit: string;
  uniqueTimestamps: number[];
} => {
  if (!datasets) {
    return {
      data: [[], [], []],
      minValue: 0,
      maxValue: 0,
      unit: 'ns',
      uniqueTimestamps: [],
    };
  }

  const timestamps: number[] = [];
  const counts: number[] = [];
  const xAxis: number[] = [];
  const timestampBitmap: Record<number, { [key: number]: number }> = {};
  const attrTimestampBitmap: Record<string, number> = {};

  datasets.buckets.forEach(
    ({ timeBucketStartSecs, attrBucketStart, count }) => {
      timestampBitmap[timeBucketStartSecs] = {
        ...(timestampBitmap[timeBucketStartSecs] || {}),
        [attrBucketStart]: count,
      };
      const uniqueAttrTimestamp = `${timeBucketStartSecs}-${attrBucketStart}`;
      attrTimestampBitmap[uniqueAttrTimestamp] = count;
    },
  );

  const filledTimestamps = fillEmptyTimestamps(timestamps, meta);

  filledTimestamps.forEach((timestamp) => {
    const attrCounts = timestampBitmap[timestamp];
    if (!attrCounts) {
      timestamps.push(timestamp);
      counts.push(0);
      xAxis.push(0);
      return;
    }
    Object.keys(attrCounts).forEach((attrBucketStart) => {
      const count = attrCounts[Number(attrBucketStart)];
      timestamps.push(timestamp);
      counts.push(count);
      xAxis.push(Number(attrBucketStart));
    });
  });

  const minValue = Math.min(...xAxis);
  const maxValue = Math.max(...xAxis);
  if (isDevEnv) {
    categoryWiseHeatmapData({
      xAxis,
      buckets: datasets.buckets,
      counts,
      minValue,
      maxValue,
      unit: datasets.unit || 'ns',
    });
  }

  return {
    data: [timestamps, xAxis, counts],
    minValue,
    maxValue,
    unit: datasets.unit || 'ns',
    uniqueTimestamps: filledTimestamps,
  };
};

/**
 * Build for debugging only
 */
const categoryWiseHeatmapData = ({
  xAxis,
  buckets,
  counts,
  minValue,
  maxValue,
  unit,
}: {
  xAxis: number[];
  buckets: HeatmapDataProps['buckets'];
  counts: number[];
  minValue: number;
  maxValue: number;
  unit: string;
}) => {
  const categoryCount = 20;

  const categorySize = (maxValue - minValue) / categoryCount;
  const categories = Array.from({ length: categoryCount }, (_, i) => {
    const start = i === 0 ? minValue : minValue + i * categorySize + 1;
    const end = minValue + (i + 1) * categorySize;
    return [start, end];
  });

  const categoryBitmap: { [key: number]: number } = {};
  buckets.forEach(({ attrBucketStart, count }) => {
    categoryBitmap[attrBucketStart] = count;
  });
  const xAxisCategoryBitmap: {
    [key: number]: { x: number[]; count: number[] };
  } = {};

  xAxis.forEach((x, xIdx) => {
    // find the category
    const category = categories.find((c) => x >= c[0] && x <= c[1]);
    if (category !== undefined) {
      const xCategory = xAxisCategoryBitmap[category[0]]?.x || [];
      const countCategory = xAxisCategoryBitmap[category[0]]?.count || [];
      xAxisCategoryBitmap[category[0]] = {
        x: [...xCategory, x],
        count: [...countCategory, counts[xIdx]],
      };
    }
  });

  const categoryData = categories.map((category) => {
    const unitFn = findUnitCategoryFormatById(unit);
    const formattedStartCategory = unitFn.fn(category[0]);
    const rangeStart =
      formattedStartCategory.text + formattedStartCategory.suffix;
    const formattedEndCategory = unitFn.fn(category[1]);
    const rangeEnd = formattedEndCategory.text + formattedEndCategory.suffix;
    const values = xAxisCategoryBitmap[category[0]]?.x || [];
    const valuesFormatted = values.map((v) => {
      const unitFn = findUnitCategoryFormatById(unit);
      const formattedValue = unitFn.fn(v);
      return formattedValue.text + formattedValue.suffix;
    });
    return {
      range: `${rangeStart} - ${rangeEnd}`,
      count: xAxisCategoryBitmap[category[0]]?.count.length || 0,
      values: JSON.stringify(valuesFormatted),
      countValues: JSON.stringify(
        xAxisCategoryBitmap[category[0]]?.count || [],
      ),
    };
  });

  // console.table(categoryData, ['range', 'count', 'values', 'countValues']);
};
