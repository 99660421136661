export enum KfPlatform {
  Docker = 'docker',
  Kubernetes = 'kubernetes',
  HOST = 'host',
}

export enum KfSource {
  APM = 'apm',
  Knight = 'knight',
}
