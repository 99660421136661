import { SelectedFacetValuesByName, ValueCount } from 'types';
import {
  filterListWithSelectedSidebar,
  mergeTwoSidebarCountsValues,
} from 'utils';

export const DashboardListSidebarFacet = [
  { name: 'foldertitle', label: 'Folder', forceExpanded: false },
  { name: 'tags', label: 'Tags', forceExpanded: false },
];

const getDashboardCountForSidebar = (
  dashboardList: Array<any>,
): { folders: { [key: string]: number }; tags: { [key: string]: number } } => {
  const folders: { [key: string]: number } = {};
  const tags: { [key: string]: number } = {};

  dashboardList.forEach((dash: any) => {
    if (folders[dash.foldertitle] === undefined) {
      folders[dash.foldertitle] = 1;
    } else {
      folders[dash.foldertitle] += 1;
    }

    dash.tags.forEach((tag) => {
      if (tags[tag] === undefined) {
        tags[tag] = 1;
      } else {
        tags[tag] += 1;
      }
    });
  });

  return { folders, tags };
};

export const filterDashboardList = (
  dashboardList: Array<any>,
  selectedFacetValuesByName: SelectedFacetValuesByName,
): {
  dashboardList: Array<any>;
  folders: ValueCount[];
  tags: ValueCount[];
} => {
  const newDashboardList = dashboardList.map((dash: any) => {
    return { ...dash, foldertitle: dash.folderTitle || 'General' };
  });
  const filteredDashboardList = filterListWithSelectedSidebar(
    newDashboardList,
    selectedFacetValuesByName,
  );

  const countFromDashboardList = getDashboardCountForSidebar(newDashboardList);
  const countFromFilteredDashboardList = getDashboardCountForSidebar(
    filteredDashboardList,
  );

  const mergedCount = mergeTwoSidebarCountsValues(
    countFromDashboardList,
    countFromFilteredDashboardList,
  );
  const { folders, tags } = mergedCount;

  const serviceFacetValues: ValueCount[] = [];
  Object.keys(folders).forEach((key) => {
    serviceFacetValues.push({ value: key, count: folders[key] });
  });

  const tagsFacetValues: ValueCount[] = [];
  Object.keys(tags).forEach((key) => {
    tagsFacetValues.push({ value: key, count: tags[key] });
  });

  return {
    dashboardList: filteredDashboardList,
    folders: serviceFacetValues,
    tags: tagsFacetValues,
  };
};
