import dayjs from 'dayjs';
import { FacetBase, FacetValue, LogsState } from 'types';
import { onPromiseError } from 'utils';

import query from './query';
import { buildQuery, formatFacetName, formatValueCounts } from './utils';

type Args = {
  facet: FacetBase;
  logsState: LogsState;
  limit?: number;
};

const getLogFacetValuesCounts = async ({
  facet,
  logsState,
  limit = 500,
}: Args): Promise<FacetValue> => {
  const { date } = logsState;
  const startTimeUnix = date.zoomedStartTimeUnix || date.startTimeUnix;
  const endTimeUnix = date.zoomedEndTimeUnix || date.endTimeUnix;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const logQuery = buildQuery(logsState);

  return query<FacetValue[], 'getFacetValueCounts'>(`
    {
      getFacetValueCounts(
        durationSecs: ${durationSecs}
        facetName: "${formatFacetName(facet.component, facet.name, facet.type)}"
        ${logQuery !== '{}' ? `logQuery: ${logQuery},` : ''}
        limit: ${limit},
        timestamp: "${endTime.format()}",
      ) {
        count
        floatValue
        value
      }
    }
  `)
    .then((data) => data.getFacetValueCounts || [], onPromiseError)
    .then(formatValueCounts(facet.type));
};

export default getLogFacetValuesCounts;
