import classnames from 'classnames';
import React from 'react';
import { FacetPickerAction, SubscriptionArgs, ValueCount } from 'types';

const FacetActions = ({
  renderAction,
  name,
  actions,
  request,
  requestSubscription,
}: {
  renderAction?: (name: string) => React.ReactNode;
  name: string;
  actions: Array<FacetPickerAction>;
  request?: (args: any) => Promise<ValueCount[]>;
  requestSubscription: SubscriptionArgs;
}) => {
  return (
    <div className="facet-picker__title__actions">
      {renderAction && typeof renderAction === 'function'
        ? renderAction(name)
        : null}

      {(actions || []).map((action, i) => (
        <div
          className={classnames({
            'h-[24px] w-[24px] flex items-center justify-center rounded-sm text-icon hover:bg-interaction-primary hover:text-white':
              true,
            'bg-primary text-white': action.show,
          })}
          key={i}
          onClick={(e) => {
            action.onClick({
              request,
              requestSubscription,
            });
            e.stopPropagation();
          }}
        >
          {action.label}
        </div>
      ))}
    </div>
  );
};

export default FacetActions;
