import { replaceLegendVariables } from 'utils';
import { DataFrame } from '../types';

/**
 * If there is no label
 * Metrics for prometheus
 * Count of {query_key} for trace analytics
 * {Count/Aggregation} of {query_key} for logs analytics
 * Include {formula} for formula and use {Value} of instead of count
 * For promql formula will include whole promql.
 * If there is only one label
 * Label Value only
 * If there is more than one label
 * {key:value} for more than one label
 */
export const labelTransformerLogic = ({
  label,
  meta,
}: {
  label: DataFrame['data'][0]['label'];
  meta: DataFrame['meta'];
}): string => {
  const { metricName } = meta;
  const labelKeys = Object.keys(label);
  if (labelKeys.length === 0) {
    return metricName || '{}';
  }
  if (labelKeys.length === 1) {
    return label[labelKeys[0]];
  }

  return `${labelKeys.map((key) => `${key}:${label[key]}`).join(', ')}`;
};

const labelTransformer = (dataFrame: DataFrame): DataFrame => {
  const { data, meta } = dataFrame;
  const transformedData = data.map((data) => ({
    ...data,
    displayLabel: meta.legendFormat
      ? replaceLegendVariables(meta.legendFormat, data.label)
      : labelTransformerLogic({ label: data.label, meta }),
  }));

  return {
    ...dataFrame,
    data: transformedData,
  };
};

export default labelTransformer;
