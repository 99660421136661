import {
  DashboardAdvanceFunctionOptionProps,
  DashboardPanelProps,
  DashboardPanelTargetsProps,
  DashboardPanelType,
  DashboardPromqlWithMetaProps,
  DashboardTemplateValueProps,
  DateSelection,
} from 'types';

import { MutableRefObject } from 'react';
import {
  DataFrameMeta,
  promqlDashboardDataTransformer,
  metricsDataTransformer,
  DataTransformerConfig,
  DataFrame,
  adjustForecastTimeseriesTimestamp,
} from 'utils/DataTransformer';
import { getRollupByVisualization } from 'utils/rollup';

import {
  getReplacedInterval,
  getReplacedTimeFromWithDate,
  transformFormulaExpression,
  transformPromql,
} from './common-utils';
import { getPanelStyles } from './panel-utils';
import {
  mapTimeseriesDrawStyle,
  mapTimeseriesLegendMode,
} from './widget-utils';
import { getForecastPromQLForDashboard } from 'utils';

export const getPanelDisplayOptions = (panel: DashboardPanelProps) => {
  const { fieldConfig, options } = panel;
  const panelStyles = getPanelStyles(fieldConfig?.defaults);
  let drawStyles = undefined;
  if (panel.type !== DashboardPanelType.TIMESERIES) {
    drawStyles = mapTimeseriesDrawStyle(fieldConfig?.defaults?.custom);
  }
  const legendType = mapTimeseriesLegendMode(options.legend.displayMode);
  return { panelStyles, drawStyles, drawStyle: drawStyles?.[0], legendType };
};

const hashPanelAdvanceFunction = (panel: DashboardPanelProps) => {
  const { fieldConfig } = panel;
  const { mappings } = fieldConfig?.defaults;
  const kfuse_function = mappings?.find(
    (m) => m.type === 'value' && m.options['kfuse_function'],
  );

  if (!kfuse_function) {
    return null;
  }

  const { options } = kfuse_function;
  const advanceFunctionOption: DashboardAdvanceFunctionOptionProps = {
    function: '',
  };
  const advanceFunction = options['kfuse_function'];
  if (advanceFunction.text === 'forecast') {
    advanceFunctionOption.function = 'forecast';
    advanceFunctionOption['kfuse_forecast_algo'] =
      options['kfuse_forecast_algo']?.text || 'linear';
    advanceFunctionOption['kfuse_forecast_seasonality'] =
      options['kfuse_forecast_seasonality']?.text || 'hourly';
  }

  return advanceFunctionOption;
};

export const getPromqlsWithMetaForDashboard = ({
  date,
  panel,
  templateValues,
  userActionRef,
  width,
}: {
  date: DateSelection;
  panel: DashboardPanelProps;
  templateValues: DashboardTemplateValueProps;
  userActionRef: MutableRefObject<{ defaultTimeChanged: boolean }>;
  width: number;
}): {
  promqlWithMeta: DashboardPromqlWithMetaProps[];
  mainTransformer: DataTransformerConfig[];
  isRange: boolean;
} => {
  const promqlWithMeta: DashboardPromqlWithMetaProps[] = [];
  const { targets } = panel;
  const panelDate = getReplacedTimeFromWithDate({
    date,
    panel,
    templateValues,
  });
  const { drawStyle: chartType } = getPanelDisplayOptions(panel);
  // this ensures that all the must be range queries or it will instant

  const activeTargets = targets.filter(
    (t) => !t.hide || (!t.expr && !t.expression),
  );
  const isRange = activeTargets.every(({ range }) => {
    if (range === undefined) {
      return true;
    }
    return range;
  });

  activeTargets.forEach((target) => {
    const { expr, expression } = target;
    let promql = expr;
    if (expression) {
      // update formula expression with promql
      promql = transformFormulaExpression({
        date,
        width,
        expression: target.expression,
        targets,
        templateValues,
        chartType,
      });
    }

    // update the promql with the template values
    const promqlTransformed = transformPromql({
      date,
      width,
      templateValues,
      promql,
      chartType,
    });
    const meta = getMetaFromDashboardPanelTarget({
      chartType,
      panelDate,
      target,
      templateValues,
      panelType: panel.type,
      userActionRef,
    });
    // get the transformer for the target
    const targetTransformer = metricsDataTransformer(!isRange);
    targetTransformer.pop();
    promqlWithMeta.push({
      allowFutureTime: panelDate.allowFutureTime,
      meta: { ...meta, instant: !isRange },
      promqlQuery: promqlTransformed,
      transformer: targetTransformer,
      date: panelDate,
    });
  });

  const advanceFunctionOption = hashPanelAdvanceFunction(panel);
  if (advanceFunctionOption && advanceFunctionOption.function === 'forecast') {
    const { promqlQuery, transformer, ...rest } = promqlWithMeta[0];
    const { kfuse_forecast_seasonality, kfuse_forecast_algo } =
      advanceFunctionOption;
    const [forecastQuery, query] = getForecastPromQLForDashboard({
      query: promqlQuery,
      date: panelDate,
      algorithm: kfuse_forecast_algo,
      seasonality: kfuse_forecast_seasonality,
    });

    // to avoid getting squashed data, we need to double the time duration to get the forecast step
    const { endTimeUnix, startTimeUnix } = panelDate;
    const timeDuration = endTimeUnix - startTimeUnix;
    const dateForForecastStep = {
      startTimeUnix: 0,
      endTimeUnix: timeDuration * 2,
    };
    const forecastStep = getRollupByVisualization(
      dateForForecastStep,
      chartType,
    );
    const newMeta = {
      ...rest.meta,
      isForecast: true,
      step: forecastStep,
    };

    const forecastTransformer = [...transformer];
    forecastTransformer.splice(2, 0, {
      id: 'adjustForecastTimeseriesTimestamp',
      func: (dataFrame: DataFrame) =>
        adjustForecastTimeseriesTimestamp({
          dataFrame,
          startTimeUnix: panelDate.endTimeUnix,
          step: newMeta.step,
        }),
    });

    const forecastSeasonality =
      kfuse_forecast_algo === 'seasonal'
        ? kfuse_forecast_seasonality
        : undefined;

    promqlWithMeta[0].meta = newMeta;
    // remove the every other target except the first one
    promqlWithMeta.splice(1);
    promqlWithMeta.unshift({
      ...rest,
      promqlQuery: forecastQuery,
      meta: { ...newMeta, forecastSeasonality },
      transformer: forecastTransformer,
    });
  }

  const mainTransformer: DataTransformerConfig[] = [
    {
      id: 'promqlDashboardDataTransformer',
      func: promqlDashboardDataTransformer,
    },
  ];

  return { promqlWithMeta, isRange, mainTransformer };
};

export const getMetaFromDashboardPanelTarget = ({
  chartType,
  panelDate,
  target,
  templateValues,
  panelType,
  userActionRef,
}: {
  chartType: string;
  panelDate: DateSelection;
  target: DashboardPanelTargetsProps;
  templateValues: DashboardTemplateValueProps;
  panelType: DashboardPanelType;
  userActionRef: MutableRefObject<{ defaultTimeChanged: boolean }>;
}): DataFrameMeta => {
  let step = null;
  if (userActionRef.current.defaultTimeChanged === false) {
    step = getReplacedInterval(target, templateValues);
  }

  if (step === null) {
    step = getRollupByVisualization(panelDate, chartType);
  }

  return {
    refId: target.refId,
    step,
    type: panelType,
    legendFormat: target.legendFormat === '__auto' ? '' : target.legendFormat,
  };
};
