import { useRumPageStateContext } from 'context';
import React from 'react';
import { useParams } from 'react-router-dom';
import Rum from './Rum';
import { RumTab } from './types';

const RumContainer = () => {
  const { tab } = useParams();
  const rumPageState = useRumPageStateContext();

  return (
    <Rum rumPageState={rumPageState} rumTab={(tab as RumTab) || RumTab.list} />
  );
};

export default RumContainer;
