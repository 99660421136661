import classnames from 'classnames';
import { SearchInput } from 'components';
import React from 'react';
import { InputProps } from '../Input';

import useTableSearch from './useTableSearch';

type Props = {
  className?: string;
  dataTestId?: string;
  placeholder?: string;
  size?: InputProps['size'];
  tableSearch: ReturnType<typeof useTableSearch>;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const TableSearch = ({
  className,
  dataTestId,
  placeholder,
  size = '1',
  tableSearch,
}: Props) => {
  return (
    <div
      className={classnames({ 'table-search': true, [className]: className })}
      data-testid={dataTestId}
    >
      <SearchInput
        onChange={tableSearch.setSearch}
        placeholder={placeholder || 'Search'}
        value={tableSearch.search}
        size={size}
        type="text"
      />
    </div>
  );
};

export default TableSearch;
