import React, { useState, useEffect, useRef, ReactElement } from 'react';

import HostmapTooltip from './HostmapTooltip';
import KfuseHostmap from './KfuseHostmap';
import { HostmapProps, KfuseHostmapOptions, KfuseHostmapProps } from './types';

const getHostmapOptions = (options: any) => {
  const config = { ...options, hooks: {} };
  config.addHook = (type: string, hook: any) => {
    if (!config.hooks[type]) {
      config.hooks[type] = [];
    }

    config.hooks[type]?.push(hook as any);
  };

  return config;
};

const Hostmap = ({
  data,
  height,
  layoutType = 'explore',
  width,
}: HostmapProps): ReactElement => {
  const [hexagons, setHexagons] = useState([]);
  const [config, setConfig] = useState<KfuseHostmapOptions>(null);
  const hostmapRef = useRef<KfuseHostmapProps>(null);

  const create = (newConfig: KfuseHostmapOptions, data: any) => {
    const newChart = KfuseHostmap({
      options: newConfig,
      data,
      target: hostmapRef.current,
    });
    hostmapRef.current = newChart;
  };

  const destroy = () => {
    if (hostmapRef.current) {
      hostmapRef.current.destroy();
    }
  };

  useEffect(() => {
    const newConfig = getHostmapOptions({ width, height });
    setConfig(newConfig);

    create(newConfig, hexagons);
    return () => {
      destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newConfig = getHostmapOptions({ width, height });
    setConfig(newConfig);
    const hostmap = hostmapRef.current;

    if (hostmap) {
      hostmap.setSize(width, height);
      hostmap.setHooks(newConfig.hooks);
      hostmap.draw(data);
    }

    setHexagons(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, height, width]);

  return (
    <>
      <div ref={hostmapRef}></div>
      {config ? (
        <HostmapTooltip config={config} layoutType={layoutType} />
      ) : null}
    </>
  );
};

export default Hostmap;
