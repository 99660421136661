import dayjs from 'dayjs';
import { fetchJson } from 'requests';

import { UserProps } from '../types';

const getUserList = async (): Promise<UserProps[]> => {
  const result = await fetchJson(`rbac/users/`);

  const users = (result as UserProps[]) || [];
  const updatedUsers: UserProps[] = users.map((user: UserProps) => ({
    ...user,
    lastSeenAtUnix: dayjs(user.lastSeenAt).unix(),
  }));

  return updatedUsers;
};

export default getUserList;
