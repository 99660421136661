import {
  CloudLabels,
  CoreLabels,
  KubernetesLabels,
  delimiter,
} from 'kfuse-constants';
import {
  LogsMetricForumlaProps,
  LogsMetricQueryProps,
} from 'types/LogsMetricsQueryBuilder';
import {
  DataFrameMeta,
  getLogQLWithMetaToLoad,
  getRollupToSecond,
} from 'utils';

import { alertParseConditionValue } from '../../AlertsCreateCondition';
import { DashboardPanelType } from 'types/Dashboard';

export const parseAlertsLogsQueryAndConditions = (
  data: any,
  annotations: any,
) => {
  const promqls: string[] = [];
  const condition = alertParseConditionValue(data);
  const [queryModel] = data;
  const { model, relativeTimeRange } = queryModel;
  promqls.push(model.expr);

  if (annotations) {
    const parsedExtraData = JSON.parse(annotations.extraData || '{}');
    if (parsedExtraData?.queryType === 'formula') {
      condition.queryKey = 'Formula (1)';
    } else {
      condition.queryKey = 'Query (a)';
    }
  }

  return { condition, promqls, relativeTimeRange };
};

export const getLabelWithDelimiter = (): string[] => {
  const labels = [...CoreLabels, ...KubernetesLabels, ...CloudLabels];
  const newlabels: string[] = [];
  labels.map(({ component, name, type }) => {
    newlabels.push(`${component}${delimiter}${name}${delimiter}${type}`);
  });
  return newlabels;
};

const parseGroupByFromKfuseQl = (kfuseQl: string) => {
  const splitKfuseQl = kfuseQl.split('|').map((item) => item.trim());
  const groupItem = splitKfuseQl.find((item) => item.includes('(_timeslice'));
  if (!groupItem) return '';

  const match = groupItem.match(/\((.*?)\)/);
  if (!match) return '';
  return match[1];
};

export const removeFieldsFromKfuseQl = (kfuseQl: string) => {
  if (!kfuseQl || typeof kfuseQl !== 'string') return '';

  const parts = kfuseQl.split('|').map((part) => part?.trim() || '');
  if (parts.length <= 1) return kfuseQl;

  const lastPart = parts[parts.length - 1] || '';
  return lastPart.startsWith('fields')
    ? parts.slice(0, -1).join(' | ').trim()
    : kfuseQl;
};

export const getSelectedLogQueryLogql = ({
  formulas,
  queries,
  selectedQuery,
  queryLangType,
}: {
  formulas: LogsMetricForumlaProps[];
  queries: LogsMetricQueryProps[];
  selectedQuery: string;
  queryLangType: string;
}):
  | { logql: string; query: LogsMetricQueryProps; meta: DataFrameMeta }
  | string => {
  if (selectedQuery.includes('Query')) {
    const queryKeyParsed = selectedQuery.split('(')[1].split(')')[0];
    const query = queries.find((q) => q.queryKey === queryKeyParsed);
    if (!query) {
      return 'Selected query does not exist';
    }

    if (query) {
      let step = '1m';
      if (query.step) {
        const stepSecond = getRollupToSecond(query.step);
        if (stepSecond < 15) {
          step = '15s';
        } else {
          step = query.step;
        }
      }

      query.step = step;
      const logqlWithMetaToLoad = getLogQLWithMetaToLoad({
        queries: [query],
        dataFormat: DashboardPanelType.TIMESERIES,
        queryLangType,
        date: { startTimeUnix: 0, endTimeUnix: 0 },
        formulas: [],
        formulaOnly: false,
        instant: false,
      });
      return {
        logql: logqlWithMetaToLoad[0].logql,
        query,
        meta: logqlWithMetaToLoad[0].meta,
      };
    }
  }

  if (selectedQuery.includes('Formula')) {
    const formulaKeyParsed = selectedQuery.split('(')[1].split(')')[0];
    const formula = formulas.find((f) => f.queryKey === formulaKeyParsed);
    if (!formula) {
      return 'Selected formula does not exist';
    }

    const newQueries = queries.map((q) => {
      let step = '1m';
      if (q.step) {
        const stepSecond = getRollupToSecond(q.step);
        if (stepSecond < 15) {
          step = '15s';
        } else {
          step = q.step;
        }
      }
      q.step = step;
      return q;
    });

    if (formula) {
      const logqlWithMetaToLoad = getLogQLWithMetaToLoad({
        queries: newQueries,
        dataFormat: DashboardPanelType.TIMESERIES,
        queryLangType,
        date: { startTimeUnix: 0, endTimeUnix: 0 },
        formulas: [formula],
        formulaOnly: true,
        instant: false,
      });
      const formulaLogql = logqlWithMetaToLoad.find(
        (l) => l.queryType === 'formula',
      );
      return {
        logql: formulaLogql.logql,
        query: queries[0],
        meta: formulaLogql.meta,
      };
    }
  }

  return null;
};

export const getLogqlWithFields = (
  kfuseQl: string,
  operation: string,
): string => {
  const groupBy = parseGroupByFromKfuseQl(kfuseQl);
  return `${kfuseQl} | fields ${operation}, ${groupBy}`;
};

export const confirmSelectedLogsAlertQueryKey = (
  formulaCount: number,
  queryCount: number,
  selectedQuery: string,
): string => {
  const isSelectingFormula = selectedQuery.includes('Formula');

  if (formulaCount > 0 && !isSelectingFormula) {
    return `You have explored formulas, are you sure you want to create an alert with a ${selectedQuery}?`;
  }

  if (queryCount > 1 && !isSelectingFormula) {
    return `You have multiple explored queries, are you sure you want to create an alert with a ${selectedQuery}?`;
  }

  return '';
};
