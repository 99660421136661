import { useTimeseries, useThemeContext, TooltipTrigger } from 'components';
import { Datepicker } from 'composite';
import React, { ReactElement, useMemo } from 'react';
import { X } from 'react-feather';
import ResizeObserver from 'rc-resize-observer';
import { Series } from 'uplot';
import { combineQueriesData, decodePromqlToReadable } from 'utils';

import TimeseriesExplorerChart from './TimeseriesExplorerChart';
import TimeseriesExplorerToolbar from './TimeseriesExplorerToolbar';
import { TimeseriesExplorerProps } from './types';
import useTimeseriesExplorer from './useTimeseriesExplorer';
import { getSeriesShownState } from './utils';

const activeChartTypeArray = ['Line'];
const TimeseriesExplorer = ({
  activeChartType,
  unit,
  renderComponent,
  ...rest
}: TimeseriesExplorerProps): ReactElement => {
  const { darkModeEnabled } = useThemeContext();
  const { activeQueries, onClose } = rest;
  const timeseriesExplorer = useTimeseriesExplorer({
    ...rest,
    activeChartType,
  });
  const {
    chartData,
    chartSize,
    compareToPrev,
    date,
    setChartSize,
    setSeriesBitmap,
    setDate,
  } = timeseriesExplorer;

  const combinedData = useMemo(() => {
    if (!chartData) return null;
    if (chartData.data && chartData.series) {
      return chartData;
    }

    const activeQueriesLength = activeQueries.length;
    const activeCompareToPrev = compareToPrev.filter(
      (item) => item.isActive,
    ).length;
    const totalActiveQueries = activeQueriesLength * (activeCompareToPrev + 1);
    const queries = Array.from({ length: totalActiveQueries }, (_, index) => ({
      queryKey: index.toString(),
      isActive: true,
    }));

    return combineQueriesData({
      formulas: [],
      queries,
      queryData: chartData,
      darkModeEnabled,
      combineTimestamp: false,
    });
  }, [activeQueries.length, chartData, compareToPrev, darkModeEnabled]);

  const headerText = decodePromqlToReadable(
    activeQueries[0]?.promql || activeQueries[0]?.logql,
  );

  const timeseriesProps = useMemo(() => {
    if (activeChartType) {
      activeChartTypeArray[0] = activeChartType;
    }
    return {
      bands: combinedData?.bands || [],
      date,
      chartData: combinedData,
      unit,
      chartTypes: activeChartTypeArray,
      layoutType: 'modal',
      size: {
        width: chartSize.width,
        height: (window.innerHeight - 200) / 2,
      },
      hooks: combinedData?.hooks,
      onSeriesShowHide: (idx, series: Series[]) =>
        setSeriesBitmap(getSeriesShownState(series)),
    };
  }, [
    activeChartType,
    chartSize.width,
    combinedData,
    date,
    setSeriesBitmap,
    unit,
  ]);
  const timeseries = useTimeseries(timeseriesProps);

  return (
    <div className="new-metrics__chart__modal">
      <div className="new-metrics__chart__modal__header">
        <div className="new-metrics__chart__modal__header__title">
          {headerText && (
            <TooltipTrigger
              tooltip={<div className="flex break-all">{headerText}</div>}
            >
              {headerText?.length > 90
                ? `${headerText.slice(0, 90)}...`
                : headerText}
            </TooltipTrigger>
          )}
        </div>
        <div className="new-metrics__chart__modal__header__right">
          {date && (
            <div>
              <Datepicker
                className="logs__search__datepicker"
                hasStartedLiveTail={false}
                onChange={setDate}
                startLiveTail={null}
                value={date}
              />
            </div>
          )}
          <div
            className="new-metrics__chart__modal__header__right__close"
            onClick={onClose}
          >
            <X />
          </div>
        </div>
      </div>

      <div className="new-metrics__chart__modal__content">
        <ResizeObserver
          onResize={(size) =>
            setChartSize({ width: size.offsetWidth, height: size.height })
          }
        >
          <div className="new-metrics__chart__modal__content__left">
            <TimeseriesExplorerChart
              combinedData={combinedData}
              renderComponent={renderComponent}
              timeseriesExplorer={timeseriesExplorer}
              timeseries={timeseries}
              timeseriesProps={timeseriesProps}
            />
          </div>
        </ResizeObserver>
        <TimeseriesExplorerToolbar
          timeseriesExplorer={timeseriesExplorer}
          timeseries={timeseries}
        />
      </div>
    </div>
  );
};

export default TimeseriesExplorer;
