import { isNil } from 'lodash';
import uPlot, { Series } from 'uplot';
import { TooltipCoordsProps, UplotExtended } from 'types';
import {
  checkAnomalySeriesBandByLabel,
  getActivePointPosition,
} from 'utils/Timeseries';

export const isCursorOutsideCanvas = (
  { left, top }: uPlot.Cursor,
  canvas: DOMRect,
): boolean => {
  if (left === undefined || top === undefined) {
    return false;
  }
  return left < 0 || left > canvas.width || top < 0 || top > canvas.height;
};

export const getLabelFromSeries = (
  series: Series[],
  seriesIndex: number,
): string => {
  if (!series || !series[seriesIndex] || seriesIndex === 0) return '';
  return series[seriesIndex].label;
};

export const getLabelColor = (
  series: Series[],
  seriesIndex: number,
): Series.Stroke => {
  if (!series || !series[seriesIndex]) return null;

  const stroke = series[seriesIndex]._stroke;
  if (stroke) {
    return stroke;
  }

  return series[seriesIndex].fill;
};

export const getCursorValue = (
  data: Array<any>,
  seriesIndex: number,
  idx: number,
): string => {
  return data[seriesIndex][idx];
};

export const getCursorTimestamp = (data: Array<any>, idx: number): number => {
  return data[0][idx];
};

export const getBarSeriesIdx = (u: uPlot): number => {
  const pointIdx = u.posToIdx(u.cursor.left);
  const height = u.bbox.height / window.devicePixelRatio;
  const cursorTop = height - u.cursor.top;

  const posRange: { min: number; max: number; seriesIdx: number }[] = [];
  for (let i = 0; i < u.series.length; i++) {
    const series = u.series[i];

    if (series.show && series.paths) {
      const point = u.data[i][pointIdx];
      const y = height - u.valToPos(point, 'y');
      if (posRange.length === 0) {
        posRange.push({ min: 0, max: y, seriesIdx: i });
      } else {
        posRange.push({
          min: posRange[posRange.length - 1].max,
          max: y,
          seriesIdx: i,
        });
      }
    }

    if (i === u.series.length - 1) {
      posRange.push({
        min: posRange[posRange.length - 1].max,
        max: height,
        seriesIdx: null,
      });
    }
  }

  if (posRange.length === 0) {
    return null;
  }

  for (let i = 0; i < posRange.length; i++) {
    const range = posRange[i];
    if (cursorTop > range.min && cursorTop < range.max) {
      return range.seriesIdx;
    }
  }

  return null;
};

export const getIndexOfSeriesNearestToCursorValue = (u: uPlot): number => {
  const pointIdx = u.posToIdx(u.cursor.left);
  const posValue = u.posToVal(u.cursor.top, 'y');
  const { data, series } = u;
  let nearestIdx = 0;
  let smallestDiff = Infinity;

  for (let i = 1; i < data.length; i++) {
    const seriesData = data[i];
    const value = seriesData[pointIdx];
    if (value === undefined) continue;
    if (!series[i].show) continue;
    if (checkAnomalySeriesBandByLabel(series[i].label)) continue;
    const diff = Math.abs(posValue - Number(value));

    if (diff < smallestDiff) {
      smallestDiff = diff;
      nearestIdx = i;
    }
  }

  return nearestIdx;
};

export const tooltipCursorData = ({
  bbox,
  pointIdx,
  seriesIdx,
  u,
}: {
  bbox: DOMRect;
  pointIdx: number;
  seriesIdx?: number;
  u: UplotExtended;
}): {
  cursor: { pointX: number; pointY: number };
  tooltip: TooltipCoordsProps;
} => {
  if (isNil(pointIdx)) {
    return null;
  }

  const { chartType, getRawDataBySeriesIdx } = u;
  const seriesNearFn =
    chartType === 'Line'
      ? getIndexOfSeriesNearestToCursorValue
      : getBarSeriesIdx;
  const seriesIdxNear = seriesIdx || seriesNearFn(u);

  if (isNil(seriesIdxNear) && chartType !== 'Line') {
    return tooltipCursorCumulativeData({ bbox, pointIdx, u });
  }

  const dataPointCoords = getActivePointPosition(u, seriesIdxNear, pointIdx);
  const cursorLeft: number = dataPointCoords.x;
  const cursorTop: number = dataPointCoords.y;
  // if cursor is outside of the canvas, return null
  if (cursorLeft < 0 || cursorTop < 0) {
    return null;
  }

  const nextPos = calculateNextTooltipPosition({
    cursorLeft,
    cursorTop,
    bbox,
  });

  const label = getLabelFromSeries(u.series, seriesIdxNear);
  if (!label) return null;

  if (checkAnomalySeriesBandByLabel(label)) {
    return null;
  }
  const labelColor = getLabelColor(u.series, seriesIdxNear);
  let value = '';
  if (chartType === 'Line') {
    value = getCursorValue(u.data, seriesIdxNear, pointIdx);
  } else {
    const rawValue = getRawDataBySeriesIdx(seriesIdxNear, pointIdx);
    value = rawValue ? rawValue : '';
  }
  return {
    cursor: { pointX: cursorLeft, pointY: cursorTop },
    tooltip: {
      ...nextPos,
      data: { label, color: labelColor as string, value },
    },
  };
};

const tooltipCursorCumulativeData = ({
  bbox,
  pointIdx,
  u,
}: {
  bbox: DOMRect;
  pointIdx: number;
  u: UplotExtended;
}): {
  cursor: { pointX: number; pointY: number };
  tooltip: TooltipCoordsProps;
} => {
  let topMostSeriesIdx = u.series.length - 1;
  if (isNil(topMostSeriesIdx)) return null;

  const { getRawDataBySeriesIdx } = u;
  let cumulativeValue = 0;
  for (let i = 1; i < u.data.length; i++) {
    if (!u.series[i].show) continue;

    const rawValue = getRawDataBySeriesIdx(i, pointIdx);
    if (isNil(rawValue)) continue;
    const value = Number(rawValue);
    cumulativeValue += value;
    topMostSeriesIdx = i;
  }

  const dataPointCoords = getActivePointPosition(u, topMostSeriesIdx, pointIdx);
  const cursorLeft: number = dataPointCoords.x;
  const cursorTop: number = dataPointCoords.y;

  // if cursor is outside of the canvas, return null
  if (cursorLeft < 0 || cursorTop < 0) {
    return null;
  }

  const nextPos = calculateNextTooltipPosition({
    cursorLeft,
    cursorTop,
    bbox,
  });

  return {
    cursor: { pointX: cursorLeft, pointY: cursorTop },
    tooltip: {
      ...nextPos,
      data: {
        label: 'Total',
        color: '',
        value: cumulativeValue.toString(),
      },
    },
  };
};

export const calculateNextTooltipPosition = ({
  cursorLeft,
  cursorTop,
  bbox,
}: {
  cursorLeft: number;
  cursorTop: number;
  bbox: DOMRect;
}): {
  x: number;
  y: number;
  positionX: 'left' | 'right';
  positionY: 'top' | 'bottom';
} => {
  const { width } = bbox;
  const devicePixelRatio = window.devicePixelRatio || 1;
  const positionX =
    bbox.width * 0.5 > cursorLeft / devicePixelRatio ? 'right' : 'left';
  // Determine the vertical position of the tooltip.
  // If the cursor's top position is less than 35% of the bounding box's height,
  // position the tooltip at the bottom. Otherwise, position it at the top.
  const positionY =
    bbox.height * 0.35 > cursorTop / devicePixelRatio ? 'bottom' : 'top';

  const offset = Math.max(width * 0.1, 48);

  let nextX, nextY;

  if (positionX === 'right') {
    nextX = cursorLeft + offset;
  } else {
    // positionX === 'left'
    nextX = cursorLeft - offset;
  }

  if (positionY === 'bottom') {
    nextY = cursorTop + offset;
  } else {
    // positionY === 'top'
    nextY = cursorTop - offset;
  }

  // if nextY is out of bounds, snap it to the top
  if (nextY < 0) {
    nextY = 0;
  }

  return { x: nextX, y: nextY, positionX, positionY };
};
