import React from 'react';

import { Box } from 'components/layouts/Box';
import { Table, TableRow } from 'components';
import KubernetesRightSideBar from '../KubernetesRightSideBar';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellTimestamp,
  KubeTableCell,
  KubeTableEntityTags,
  KubeTableFilterCell,
} from './Components';
import { generatePath, useParams } from 'react-router-dom';
import KubernetesTableGroup from './KubernetesTableGroup';
import {
  useEntitiesData,
  useEntitiesGroupData,
  useMergedGroupByFilter,
} from './useEntitiesData';

const renderEmptyCell = () => '';

function KubernetesTableForReplicaSetRow({ row }) {
  const { date, entitiesType, navigate } = useKubernetesController();

  const facets = useMergedGroupByFilter(row.groupBys);
  const [rows] = useEntitiesData({
    entitiesType,
    kubesSort: null,
    facets,
    page: 0,
    limit: 50,
    date,
  });

  const setActiveKube = (row) => {
    navigate(({ location, params, pathTemplate }) => {
      return {
        ...location,

        pathname: generatePath(pathTemplate, {
          ...params,

          activeKube: row.replicaSet.metadata.uid,
          tab: 'yaml',
        }),
      };
    });
  };

  const activeKubeParam = useParams()['activeKube'];
  const activeKube = (
    typeof activeKubeParam === 'string' && rows ? rows : []
  ).find((row) => {
    return row.replicaSet.metadata.uid === activeKubeParam;
  });

  return (
    <Box width="full" height="full">
      {activeKube && <KubernetesRightSideBar activeKube={activeKube} />}
      <Table
        className="-my-px"
        renderHeader={false}
        rows={rows ?? []}
        renderRow={(props) => (
          <TableRow
            {...props}
            className="border-y"
            onRowClickHandler={(row) => () => setActiveKube(row)}
          />
        )}
        columns={[
          { key: 'header', label: '' },
          {
            key: 'kube_replica_set',
            label: 'Replica Set',
            renderCell: (prop) => {
              const replicaSetName = prop?.row.replicaSet?.metadata?.name;
              return (
                <KubeTableCell {...prop} tooltipText={replicaSetName}>
                  {replicaSetName}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'kube_cluster_name',
            label: 'Cluster',
            renderCell(prop) {
              return <KubeCellCluster {...prop} />;
            },
          },
          {
            key: 'metadata.namespace',
            label: 'Namespace',
            renderCell(prop) {
              return <KubeCellNamespace {...prop} />;
            },
          },
          {
            key: 'kube_deployment',
            label: 'Deployment',
            renderCell({ row, ...prop }) {
              const deploymentNameKey = 'kube_deployment';
              const deploymentNameTag: string = row.replicaSet.tags.find(
                (tag: string) => tag.startsWith(deploymentNameKey),
              );
              const deploymentName = deploymentNameTag.substring(
                deploymentNameKey.length + 1,
              );

              return (
                <KubeTableFilterCell
                  facetKey="kube_namespace"
                  facetValue={deploymentName}
                  className="kubernetes__table__cell__cluster-name truncate w-full"
                  row={row}
                  {...prop}
                />
              );
            },
          },
          {
            key: 'metadata.creationTimestamp',
            label: 'Age',
            renderCell(prop) {
              const timestamp =
                prop?.row?.replicaSet?.metadata?.creationTimestamp || '';
              return <KubeCellTimestamp {...prop} timestamp={timestamp} />;
            },
          },
          {
            key: 'replicas',
            label: 'Current',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="right">
                  {prop?.row?.replicaSet?.replicas}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'replicasDesired',
            label: 'Desired',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="right">
                  {prop?.row?.replicaSet?.replicasDesired}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'readyReplicas',
            label: 'Ready',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="right">
                  {prop?.row?.replicaSet?.readyReplicas}
                </KubeTableCell>
              );
            },
          },
        ]}
      />
    </Box>
  );
}

export default function KubernetesTableForReplicaSetGroup() {
  const { date, entitiesType, facets, groupBySearchTerms } =
    useKubernetesController();

  const [rows] = useEntitiesGroupData({
    entitiesType,
    date,
    facets,
    groupBySearchTerms,
  });

  return (
    <KubernetesTableGroup
      rows={rows ?? []}
      columns={[
        {
          key: 'kube_replica_set',
          label: 'Replica Set Group',
          renderCell: (prop: any) => (
            <KubeTableEntityTags
              {...prop}
              selectedFacetValuesByNameState={facets}
            />
          ),
        },
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell: renderEmptyCell,
        },
        {
          key: 'metadata.namespace',
          label: 'Namespace',
          renderCell: renderEmptyCell,
        },
        {
          key: 'kube_deployment',
          label: 'Deployment',
          renderCell: renderEmptyCell,
        },
        {
          key: 'age',
          label: 'Age',
          renderCell: renderEmptyCell,
        },
        {
          key: 'current',
          label: 'Current',
          renderCell: renderEmptyCell,
        },
        {
          key: 'desired',
          label: 'Desired',
          renderCell: renderEmptyCell,
        },
        {
          key: 'ready',
          label: 'Ready',
          renderCell: renderEmptyCell,
        },
      ]}
      renderExpandedRow={(props) => (
        <KubernetesTableForReplicaSetRow {...props} />
      )}
    />
  );
}
