import { PromqlWithMetaProps } from 'types/MetricsQueryBuilder';

import {
  QueryDataProps,
  QueryDataPropsInstant,
  QueryDataPropsRange,
} from 'types/QueryData';

export const buildPromqlQueryData = (
  promqlWithMeta: PromqlWithMetaProps[],
  datasets: QueryDataPropsRange[],
): QueryDataProps => {
  const queryData: QueryDataProps = {};

  const queryKeys = ['_upper', '_lower', ''];
  datasets.forEach((dataset, index) => {
    const promqlMeta = promqlWithMeta[index];
    const { meta, queryType, isAnomaly, isForecast } = promqlMeta;
    let queryId = `${queryType}_${meta.refId}`;
    if (isAnomaly && Array.isArray(dataset)) {
      queryId = `${queryId}_anomaly`;
      dataset.forEach((item: QueryDataPropsRange, idx) => {
        queryData[`${queryId}${queryKeys[idx]}`] = {
          isLoading: false,
          range: item,
          meta,
        };
      });
    } else if (isForecast) {
      queryId = `${queryId}_forecast`;
      if (Array.isArray(dataset)) {
        dataset.forEach((item: QueryDataPropsRange, idx) => {
          queryData[`${queryId}${queryKeys[idx]}`] = {
            isLoading: false,
            range: item,
            meta,
          };
        });
      } else {
        queryData[queryId] = { isLoading: false, range: dataset, meta };
      }
    } else {
      queryData[queryId] = { isLoading: false, range: dataset, meta };
    }
  });
  return queryData;
};

export const buildPromqlInstantQueryData = (
  promqlWithMeta: PromqlWithMetaProps[],
  datasets: QueryDataPropsInstant[],
): QueryDataProps => {
  const queryData: QueryDataProps = {};
  datasets.forEach((dataset, index) => {
    const promqlMeta = promqlWithMeta[index];
    const { meta, queryType } = promqlMeta;
    const queryId = `${queryType}_${meta.refId}`;

    queryData[queryId] = { isLoading: false, instant: dataset, meta };
  });
  return queryData;
};
