import classnames from 'classnames';
import {
  Loader,
  OverlayMessage,
  OverlayMessageProps,
  Table,
  TableColumnType,
  TooltipTrigger,
} from 'components';
import dayjs from 'dayjs';
import { FilterType, Operator, useRequest, useUrlState } from 'hooks';
import { timeFormats } from 'kfuse-constants';
import VisibilitySensor from 'react-visibility-sensor';
import React, { useEffect, useMemo, useState } from 'react';
import { ExternalLink } from 'react-feather';
import { formatNumber } from 'utils';
import RumErrorGroupTableRow from './RumErrorGroupTableRow';
import rumErrorGroups from './requests/rumErrorGroups';
import useRumState from './hooks/useRumState';
import { RumErrorGroup, RumEventType } from './types';
import RumErrorGroupChartSensor from './RumErrorGroupChartSensor';
import RumErrorsGroupTableSidebar from './RumErrorsGroupTableSidebar';

const columns = ({
  rumState,
  onOpenInErrorPage,
}: {
  rumState: ReturnType<typeof useRumState>;
  onOpenInErrorPage: (row: RumErrorGroup) => void;
}) => [
  {
    key: 'fingerprint',
    label: 'Group ID',
    renderCell: ({ row }: { row: RumErrorGroup }) => (
      <VisibilitySensor
        onChange={(isVisible) => {
          if (isVisible) {
            row?.onFetchErrorGroupingKey(row.fingerprint);
          }
        }}
      >
        <div>{row.fingerprint}</div>
      </VisibilitySensor>
    ),
  },
  {
    key: 'errorType',
    label: 'Type',
    renderCell: ({ row }: { row: RumErrorGroup }) =>
      row?.optimizedRowData?.errorType,
  },
  {
    key: 'message',
    label: 'Error Message',
    renderCell: ({ row }: { row: RumErrorGroup }) => (
      <div>
        <div>{row?.optimizedRowData?.message}</div>
      </div>
    ),
  },
  {
    key: 'numOccurrences',
    label: 'Occurrences',
    renderCell: ({ row }: { row: RumErrorGroup }) =>
      formatNumber(row?.optimizedRowData?.numOccurrences || 0),
    type: TableColumnType.NUMBER,
  },
  {
    key: 'firstOccurrenceMs',
    label: 'First Occurrence',
    renderCell: ({ row }: { row: RumErrorGroup }) =>
      row?.optimizedRowData?.firstOccurrenceMs
        ? dayjs(row.optimizedRowData.firstOccurrenceMs).format(
            timeFormats.dateAtTime,
          )
        : '',
  },
  {
    key: 'lastOccurrenceMs',
    label: 'Last Occurrence',
    renderCell: ({ row }: { row: RumErrorGroup }) =>
      row?.optimizedRowData?.lastOccurrenceMs
        ? dayjs(row.optimizedRowData.lastOccurrenceMs).format(
            timeFormats.dateAtTime,
          )
        : '',
  },
  {
    key: 'chart',
    label: 'Chart',
    renderCell: ({ row }: { row: RumErrorGroup }) => (
      <RumErrorGroupChartSensor rumState={rumState} row={row} />
    ),
  },
  {
    key: 'Actions',
    label: 'Actions',
    renderCell: ({ row }: { row: RumErrorGroup }) => {
      return (
        <div className="flex--justify-content-center flex">
          <button
            className="link"
            onClick={(e) => {
              onOpenInErrorPage(row);
              e.stopPropagation();
            }}
          >
            <TooltipTrigger tooltip="Open in error page">
              <ExternalLink size={16} />
            </TooltipTrigger>
          </button>
        </div>
      );
    },
  },
];

type Props = {
  className?: string;
  onlyTop10?: boolean;
  rumState: ReturnType<typeof useRumState>;
};

const RumErrorGroupTable = ({ className, onlyTop10, rumState }: Props) => {
  const [rumErrorGroup, setRumErrorGroup] = useUrlState('rumErrorGroup', {
    groupId: null,
    errorId: null,
    errorTimeMs: null,
  });

  const rumErrorGroupsRequest = useRequest(rumErrorGroups, true, true);

  const [visibleRows, setVisibleRows] = useState([]);

  useEffect(() => {
    if (rumErrorGroupsRequest.result && rumErrorGroupsRequest.result.length) {
      setVisibleRows(rumErrorGroupsRequest.result.slice(0, 100));
    } else {
      setVisibleRows([]);
    }
  }, [rumErrorGroupsRequest.result]);

  const onScrollEnd = () => {
    if (
      rumErrorGroupsRequest.result &&
      visibleRows.length < rumErrorGroupsRequest.result.length
    ) {
      if (visibleRows.length < rumErrorGroupsRequest.result.length) {
        setVisibleRows((prevRows) => [
          ...prevRows,
          ...rumErrorGroupsRequest.result.slice(
            visibleRows.length,
            visibleRows.length + 100,
          ),
        ]);
      }
    }
  };

  useEffect(() => {
    setVisibleRows([]);
    rumErrorGroupsRequest.call({
      isListView: true,
      onlyTop10,
      rumState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rumState.state]);

  const close = () => {
    setRumErrorGroup({
      groupId: null,
      errorId: null,
      errorTimeMs: null,
    });
  };

  const onRowClick = ({ row }: { row: RumErrorGroup }) => {
    setRumErrorGroup({
      groupId: row.fingerprint,
      errorId: row.lastOccurrenceError,
      errorTimeMs: row.lastOccurrenceMs,
    });
  };

  const onOpenInErrorPage = (row: RumErrorGroup) => {
    const filters = [
      {
        type: FilterType.selectedFacetValue,
        value: {
          facet: 'error.fingerprint',
          operator: Operator.equal,
          values: {
            [row.fingerprint]: 1,
          },
        },
      },
    ];

    window.open(
      `#/rum/list?rumFilters=${JSON.stringify(
        filters,
      )}&rumEventType=${JSON.stringify(RumEventType.ERROR)}&rumDate=${JSON.stringify(rumState.dateState[0])}`,
    );
  };

  const overlayMessageProps: OverlayMessageProps = useMemo(() => {
    if (!rumErrorGroupsRequest.error) return null;
    if (rumErrorGroupsRequest.error) {
      return {
        isActive: true,
        iconName: 'warning',
        message: (
          <>
            Failed to fetch Error. Please double check your query and try again.
          </>
        ),
      };
    } else {
      return {
        isActive: false,
      };
    }
  }, [rumErrorGroupsRequest.error]);

  return (
    <>
      <div className={classnames({ [className]: className })}>
        <Loader isLoading={rumErrorGroupsRequest.isLoading}>
          {!rumErrorGroupsRequest.isLoading &&
          rumErrorGroupsRequest.result &&
          !rumErrorGroupsRequest.result.length ? (
            <div className="placeholder">{`No error groups`}</div>
          ) : (
            <OverlayMessage {...overlayMessageProps}>
              <Table
                className="table--padded table--bordered table--bordered-cells"
                columns={columns({
                  rumState,
                  onOpenInErrorPage,
                })}
                isStickyHeaderEnabled
                onRowClick={onRowClick}
                rows={visibleRows}
                renderRow={(tableRowProps) => (
                  <RumErrorGroupTableRow
                    rumState={rumState}
                    tableRowProps={tableRowProps}
                  />
                )}
                onScrollEnd={onScrollEnd}
              />
            </OverlayMessage>
          )}
        </Loader>
      </div>
      {rumErrorGroup.groupId ? (
        <RumErrorsGroupTableSidebar
          close={close}
          errorGroupingKey={rumErrorGroup.groupId}
          errorId={rumErrorGroup.errorId}
          errorTimeMs={rumErrorGroup.errorTimeMs}
          rumState={rumState}
        />
      ) : null}
    </>
  );
};

export default RumErrorGroupTable;
