import { SunburstGraph, Table, TableColumn, useTableSort } from 'components';
import React, { ReactElement, useState } from 'react';
import ResizeObserver from 'rc-resize-observer';
import { SunburstDataProps } from 'types/Sunburst';

const AnalyticsChartPieChart = ({
  data,
  columns,
  pieChartData,
}: {
  data: Record<string, string>[];
  columns: TableColumn<{ key: string; label: string }>[];
  pieChartData: SunburstDataProps[];
}): ReactElement => {
  const [width, setWidth] = useState(document.body.clientWidth - 300);
  const tableSort = useTableSort({ columns: columns || [], rows: data || [] });

  if (!data || !Array.isArray(data)) return null;
  if (data.length === 0) return null;

  return (
    <ResizeObserver onResize={(size) => setWidth(size.width)}>
      <div className="dashboard__widget__piechart">
        <div className="">
          <SunburstGraph
            data={{ name: '', children: pieChartData, color: 'transparent' }}
            height={340}
            width={width / 2 < 300 ? 300 : width / 2}
          />
        </div>
        <div
          style={{ maxWidth: width / 2, minWidth: (width - 60) / 2 }}
          className="dashboard__widget__piechart__table"
        >
          <div
            className="table__analytics__container"
            data-testid="analytics_piechart"
          >
            <Table
              className="table__analytics"
              columns={columns}
              rows={data}
              externalTableSort={tableSort}
              isSortingEnabled
            />
          </div>
        </div>
      </div>
    </ResizeObserver>
  );
};

export default AnalyticsChartPieChart;
