import { ApmServiceMapTooltip } from 'components';
import { useRequest } from 'hooks';
import React, { useEffect } from 'react';
import { queryRange } from 'requests';
import { DateSelection } from 'types';
import { getTimeParameter } from '../utils';

type FiltersArgs = {
  clientServiceHash: string;
  clientServiceName: string;
  serviceHash: string;
  serviceName: string;
};

type QueryArgs = {
  date: DateSelection;
} & FiltersArgs;

const getFilterString = ({
  clientServiceHash,
  clientServiceName,
  serviceHash,
  serviceName,
}: FiltersArgs) => {
  const clientValue =
    clientServiceHash.indexOf('UNKNOWN') > -1 ? 'UNKNOWN' : clientServiceHash;
  const clientServiceNameString =
    clientValue === 'UNKNOWN'
      ? `,client_service_name="${clientServiceName}"`
      : '';

  const serverValue =
    serviceHash.indexOf('UNKNOWN') > -1 ? 'UNKNOWN' : serviceHash;
  const serverServiceNameString =
    serverValue === 'UNKNOWN' ? `,service_name="${serviceName}"` : '';

  return `client_service_hash="${clientValue}"${clientServiceNameString},service_hash="${serverValue}"${serverServiceNameString}`;
};

const errorsQuery = ({
  date,
  clientServiceHash,
  clientServiceName,
  serviceHash,
  serviceName,
}: QueryArgs) => {
  const filtersString = getFilterString({
    clientServiceHash,
    clientServiceName,
    serviceHash,
    serviceName,
  });
  const timeParameter = getTimeParameter(date);
  return `sum by (service_hash, client_service_hash) (rate(edge_latency_count{error="true",${filtersString}}[${timeParameter}]))`;
};

const latencyQuery = ({
  date,
  clientServiceHash,
  clientServiceName,
  serviceHash,
  serviceName,
}: QueryArgs) => {
  const filtersString = getFilterString({
    clientServiceHash,
    clientServiceName,
    serviceHash,
    serviceName,
  });
  const timeParameter = getTimeParameter(date);
  return `max (max_over_time(edge_latency_max{${filtersString}}[${timeParameter}])) by (service_hash,client_service_hash)`;
};

const requestsQuery = ({
  date,
  clientServiceHash,
  clientServiceName,
  serviceHash,
  serviceName,
}: QueryArgs) => {
  const filtersString = getFilterString({
    clientServiceHash,
    clientServiceName,
    serviceHash,
    serviceName,
  });
  const timeParameter = getTimeParameter(date);
  return `sum by (service_hash, client_service_hash) (rate(edge_latency_count{${filtersString}}[${timeParameter}]))`;
};

const queryValue = (date: DateSelection, query: string) =>
  queryRange({ date, instant: true, query }).then((result) =>
    result.length && result[0].value.length > 1
      ? Number(result[0].value[1])
      : null,
  );

const queryValues = async (args: QueryArgs) => {
  const values = await Promise.all([
    queryValue(args.date, errorsQuery(args)),
    queryValue(args.date, latencyQuery(args)),
    queryValue(args.date, requestsQuery(args)),
  ]);

  return {
    errors: values[0],
    latency: values[1],
    requests: values[2],
  };
};

type Props = {
  date: DateSelection;
  clientServiceHash: string;
  clientServiceName: string;
  name: string;
  serviceHash: string;
  serviceName: string;
};

const ServiceMapLinkTooltip = ({
  date,
  clientServiceHash,
  clientServiceName,
  name,
  serviceHash,
  serviceName,
}: Props) => {
  const valuesRequest = useRequest(queryValues);

  useEffect(() => {
    valuesRequest.call({
      date,
      clientServiceName,
      clientServiceHash,
      serviceHash,
      serviceName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = valuesRequest.isLoading;
  const values = valuesRequest.result || {};

  return (
    <ApmServiceMapTooltip isLoading={isLoading} title={name} values={values} />
  );
};

export default ServiceMapLinkTooltip;
