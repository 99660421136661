import { format, formatDistanceToNow } from 'date-fns';
import * as dateTimeFormat from 'kfuse-constants';

const dateAtTime = (timestamp: string) =>
  format(new Date(timestamp), dateTimeFormat.dateAtTime);

const timeAgo = (timestamp: string) =>
  formatDistanceToNow(new Date(timestamp)) + ' ago';

const formatDateTime = {
  dateAtTime,
  timeAgo,
};

export default formatDateTime;
