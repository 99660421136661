import {
  Button,
  DownloadPopover,
  PopoverTriggerV2,
  PopoverPosition,
  TableOptionsPopover,
  useColumnsState,
  useModalsContext,
  useTableOptions,
} from 'components';
import { useRequest } from 'hooks';
import React, { ReactNode } from 'react';
import { FaRegSave } from 'react-icons/fa';
import { DateSelection, DownloadType, LogEvent } from 'types';
import LogsSheetToolbarSaveView from './LogsSheetToolbarSaveView';
import useSheetState from './useSheetState';
import LogsAddToDashboardPopover from '../LogsAddToDashboardPopover';
import LogsDownloadModal from '../LogsDownloadModal';
import { useLogsState, useLogsWorkbooksState } from '../hooks';

type Props = {
  columnsState: ReturnType<typeof useColumnsState>;
  customColumnsState: ReturnType<typeof useColumnsState>;
  date: DateSelection;
  getWorkbooksRequest: ReturnType<typeof useRequest>;
  logsState: ReturnType<typeof useLogsState>;
  renderToolbarLeft?: () => ReactNode;
  tableOptions: ReturnType<typeof useTableOptions>;
  disableOnlySaveQueryInToolBar?: boolean;
  disableOnlyAddToDashboardInToolBar?: boolean;
};

const LogsSheetToolbar = ({
  columnsState,
  customColumnsState,
  date,
  getWorkbooksRequest,
  logsState,
  renderToolbarLeft,
  tableOptions,
  disableOnlySaveQueryInToolBar,
  disableOnlyAddToDashboardInToolBar,
}: Props) => {
  const modals = useModalsContext();

  const openLogsDownloadModal = (downloadType) => {
    modals.push(
      <LogsDownloadModal
        columns={columnsState.columns}
        date={date}
        downloadType={downloadType}
        logsState={logsState}
      />,
      true,
    );
  };
  return (
    <>
      <div className="logs__table__toolbar">
        <div className="logs__table__toolbar__left">
          {renderToolbarLeft ? renderToolbarLeft() : null}
          <TableOptionsPopover
            className="logs__table__toolbar__item"
            columnsState={columnsState}
            tableOptions={tableOptions}
          />
        </div>
        <div className="logs__table__toolbar__middle" />
        <div className="logs__table__toolbar__right">
          {disableOnlyAddToDashboardInToolBar ? null : (
            <div className="logs__table__toolbar__item logs__table__toolbar__item--add-to-dashboard">
              <LogsAddToDashboardPopover
                logsState={logsState}
                customColumnsState={customColumnsState}
                columnsState={columnsState}
              />
            </div>
          )}

          {disableOnlySaveQueryInToolBar ? null : (
            <PopoverTriggerV2
              className="logs__table__toolbar__item logs__table__toolbar__item--save-query"
              popover={({ close }) => {
                return (
                  <LogsSheetToolbarSaveView
                    close={close}
                    getWorkbooksRequest={getWorkbooksRequest}
                    logsState={logsState}
                    customColumnsState={customColumnsState}
                  />
                );
              }}
              position={PopoverPosition.BOTTOM_RIGHT}
            >
              <Button variant="outline-secondary" size="xs">
                <FaRegSave className="button__icon" size={12} />
                <span>Save Query</span>
              </Button>
            </PopoverTriggerV2>
          )}
          <DownloadPopover
            downloadTypes={[
              DownloadType.csv,
              DownloadType.json,
              DownloadType.txt,
            ]}
            openModal={openLogsDownloadModal}
          />
        </div>
      </div>
    </>
  );
};

export default LogsSheetToolbar;
