import { Loader, TableWithState, useColumnsState } from 'components';
import React, { useMemo } from 'react';
import { Service } from 'types';

import useKpisBySpanNameRequest from './useKpisBySpanNameRequest';

type Props = {
  columnsState: ReturnType<typeof useColumnsState>;
  kpisBySpanNameRequest: ReturnType<typeof useKpisBySpanNameRequest>;
  serviceByHash: Record<string, Service>;
};

const ServiceTabTable = ({
  columnsState,
  kpisBySpanNameRequest,
  serviceByHash,
}: Props) => {
  const { isLoading, kpisBySpanName } = kpisBySpanNameRequest;

  const rows = useMemo(() => {
    return Object.keys(kpisBySpanName).map((name) => ({
      name,
      service: serviceByHash[name] || null,
      serviceName: kpisBySpanName[name].service_name,
    }));
  }, [kpisBySpanName, serviceByHash]);

  return (
    <Loader
      className="service__table"
      isLoading={isLoading}
      dataTestId="service_tab_table"
    >
      <TableWithState columnsState={columnsState} rows={rows} />
    </Loader>
  );
};

export default ServiceTabTable;
