import { ComponentPropsWithoutRef } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { createPrimitiveComponent, createArrayVariants } from './shared';

export const variants = {
  width: {
    full: 'w-full',
    screen: 'w-screen',
  },
  minWidth: {
    full: 'w-full',
    screen: 'w-screen',
  },
  maxWidth: {
    full: 'w-full',
    screen: 'w-screen',
  },
  height: {
    full: 'h-full',
    screen: 'w-screen',
  },
  minHeight: {
    full: 'h-full',
    screen: 'w-screen',
  },
  maxHeight: {
    full: 'h-full',
    screen: 'w-screen',
  },
  p: createArrayVariants(['p-0', 'p-1', 'p-2', 'p-3', 'p-4']),
  px: createArrayVariants(['px-0', 'px-1', 'px-2', 'px-3', 'px-4']),
  py: createArrayVariants(['py-0', 'py-1', 'py-2', 'py-3', 'py-4']),

  pl: createArrayVariants(['pl-0', 'pl-1', 'pl-2', 'pl-3', 'pl-4']),
  pr: createArrayVariants(['pr-0', 'pr-1', 'pr-2', 'pr-3', 'pr-4']),
  pt: createArrayVariants(['pt-0', 'pt-1', 'pt-2', 'pt-3', 'pt-4']),
  pb: createArrayVariants(['pb-0', 'pb-1', 'pb-2', 'pb-3', 'pb-4']),

  m: createArrayVariants(['m-0', 'm-1', 'm-2', 'm-3', 'm-4']),
  mx: createArrayVariants(['mx-0', 'mx-1', 'mx-2', 'mx-3', 'mx-4']),
  my: createArrayVariants(['my-0', 'my-1', 'my-2', 'my-3', 'my-4']),

  ml: createArrayVariants(['ml-0', 'ml-1', 'ml-2', 'ml-3', 'ml-4']),
  mr: createArrayVariants(['mr-0', 'mr-1', 'mr-2', 'mr-3', 'mr-4']),
  mt: createArrayVariants(['mt-0', 'mt-1', 'mt-2', 'mt-3', 'mt-4']),
  mb: createArrayVariants(['mb-0', 'mb-1', 'mb-2', 'mb-3', 'mb-4']),

  elevation: {
    true: 'shadow',
    sm: 'shadow-sm',
    md: 'shadow-md',
    lg: 'shadow-lg',
    xl: 'shadow-xl',
    '2xl': 'shadow-2xl',
    inner: 'shadow-inner',
  },
};

export const Box = createPrimitiveComponent(
  'layout::Box',
  'div',
  cva('', {
    variants,
  }),
);

export type BoxProps = ComponentPropsWithoutRef<typeof Box>;
