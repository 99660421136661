import { DateSelection } from 'types';
import { onPromiseError } from 'utils';

import fetchJson from './fetchJson';
import { formatCardinalityTimeseries } from './utils';

const metricsLabelValueCardinality = ({
  date,
  format = 'none',
  labels,
  matcher,
  rollUpSeconds,
  instant,
  noAggregation,
}: {
  date?: DateSelection;
  format?: 'timeseries' | 'none';
  labels: string[];
  matcher?: string;
  rollUpSeconds?: number;
  instant?: boolean;
  noAggregation?: boolean;
}): Promise<any> => {
  const newFormData = new FormData();

  if (date) {
    const { endTimeUnix, startTimeUnix } = date;
    const duration = endTimeUnix - startTimeUnix;
    newFormData.append('duration', instant ? '0' : duration.toString());
    newFormData.append(
      'rollUpSeconds',
      rollUpSeconds ? rollUpSeconds.toString() : duration.toString(),
    );
    newFormData.append('timestamp', endTimeUnix.toString());
  }
  if (!noAggregation) {
    newFormData.append('aggregation', 'distinctcount');
  }

  if (labels && labels.length > 0) {
    labels.forEach((label) => newFormData.append('label', label));
  }

  let params = new URLSearchParams(newFormData).toString();
  if (matcher) {
    params = `${params}&match[]=${matcher}`;
  }

  return fetchJson(
    `/api/v1/cardinality/label_values?${params.toString()}`,
  ).then((result: any) => {
    if (!result) return;
    if (format === 'none') return result[0] ? result[0] : undefined;
    if (format === 'timeseries')
      return formatCardinalityTimeseries(result, 'label');
  }, onPromiseError);
};

export default metricsLabelValueCardinality;
