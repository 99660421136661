import classnames from 'classnames';
import { keyCodes } from 'kfuse-constants';
import { useOnClickOutside } from 'hooks';
import React, { ReactElement, ReactNode, useEffect } from 'react';
import { X } from 'react-feather';
import { Button } from '../base';
import { Drawer, DrawerContent } from '../shadcn';
import { usePortalContext } from 'components';

type Props = {
  className?: string;
  close: VoidFunction;
  closeOnOutsideClick?: VoidFunction;
  children: ReactNode;
  dataTestId?: string;
  title: ReactNode;
  prev?: () => void;
  next?: () => void;
  shouldCancelOutsideClick?: (e: Event) => boolean;
};

const RightSidebar = ({
  className,
  children,
  close,
  closeOnOutsideClick,
  dataTestId,
  title,
  prev,
  next,
  shouldCancelOutsideClick,
}: Props): ReactElement => {
  const portalContext = usePortalContext();
  useOnClickOutside({
    onClick: closeOnOutsideClick ? closeOnOutsideClick : close,
    ref: portalContext.portalRef,
    shouldCancel: shouldCancelOutsideClick,
  });

  useEffect(() => {
    const listener = (e) => {
      switch (e.keyCode) {
        case keyCodes.ESC:
          close();
          return;
      }
      switch (e.key) {
        case 'ArrowLeft':
          prev?.();
          return;
        case 'ArrowRight':
          next?.();
          return;
        default:
          return;
      }
    };

    document.addEventListener('keydown', listener, { capture: false });

    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer
      direction="right"
      open
      onClose={close}
      modal={false}
      dismissible={false}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          closeOnOutsideClick ? closeOnOutsideClick() : null;
        }
      }}
    >
      <DrawerContent
        portalContainer={portalContext.portalRef.current}
        className={className}
      >
        <div
          className={classnames({
            'right-sidebar': true,
            [className]: className,
          })}
          data-testid={dataTestId}
        >
          <div className="right-sidebar__header">
            <div
              className="right-sidebar__header__title text--h2"
              data-testid="right-sidebar-title"
            >
              {title}
            </div>
            <Button
              variant="icon"
              className="px-0 hover:bg-transparent hover:text-inherit"
              size="md"
              onClick={close}
            >
              <X size={24} />
            </Button>
          </div>
          <div className="right-sidebar__body">{children}</div>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default RightSidebar;
