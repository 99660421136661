import React, { useMemo, useCallback } from 'react';
import { AutocompleteV2 } from 'components';
import { useRequest } from 'hooks';
import { DateSelection } from 'types';
import { RumEvent, RumEventType } from '../types';
import rumLabelValues from '../requests/rumLabelValues';
import { getFilterOptions } from './utils';
import { getIdFilter } from '../utils';

const getFilterParams = (filterKey: string) => {
  switch (filterKey) {
    case 'actionName':
      return {
        labelName: 'action.name',
        eventType: RumEventType.ACTION,
      };
    case 'actionType':
      return {
        labelName: 'action.type',
        eventType: RumEventType.ACTION,
      };
    case 'errorSource':
      return {
        labelName: 'error.source',
        eventType: RumEventType.ERROR,
      };
    case 'resourceType':
      return {
        labelName: 'resource.type',
        eventType: RumEventType.RESOURCE,
      };
    case 'resourceStatus':
      return {
        labelName: 'resource.status_code',
        eventType: RumEventType.RESOURCE,
      };
    case 'resourceUrl':
      return {
        labelName: 'resource.url',
        eventType: RumEventType.RESOURCE,
      };
    default:
      return { labelName: '', eventType: null };
  }
};

const RumFlamegraphFilterAutocomplete = ({
  applicationFilter,
  activeRumEvent,
  dateFilter,
  filterKey,
  label,
  placeholder,
  onChange,
  value,
}: {
  applicationFilter: string;
  activeRumEvent: RumEvent;
  dateFilter: DateSelection;
  filterKey: string;
  label: string;
  placeholder: string;
  onChange: (value: string) => void;
  value: string;
}) => {
  const request = useRequest(rumLabelValues, true, true);

  const handleMenuOpen = useCallback(() => {
    request.call({
      applicationFilter,
      ...getIdFilter({ activeRumEvent }),
      date: dateFilter,
      ...getFilterParams(filterKey),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRumEvent, dateFilter, filterKey]);

  const options = useMemo(
    () => getFilterOptions(request.result || [], value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [request.result],
  );

  return (
    <div>
      <label>{label}</label>
      <AutocompleteV2
        className="autocomplete__fixed-height-28"
        components={{ ClearIndicator: null }}
        isLoading={request.isLoading}
        onChange={onChange}
        onMenuOpen={handleMenuOpen}
        options={options}
        placeholder={placeholder}
        value={value}
      />
    </div>
  );
};

export default RumFlamegraphFilterAutocomplete;
