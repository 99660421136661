import { Checkbox } from 'components/shadcn';
import React, { ReactElement, useMemo } from 'react';
import useTableBulkActions from './useTableBulkActions';

const TableBulkActionsCheckbox = ({
  isSelectAll,
  tableBulkActions,
  uniqueId,
}: {
  isSelectAll?: boolean;
  tableBulkActions: ReturnType<typeof useTableBulkActions>;
  uniqueId?: string;
}): ReactElement => {
  const { isAllSelected, selectedRows, onCheckedChange, onSelectDeselectAll } =
    tableBulkActions;

  const checked = useMemo(() => {
    if (!isSelectAll) return Boolean(selectedRows[uniqueId]);

    return isAllSelected;
  }, [isAllSelected, selectedRows, isSelectAll, uniqueId]);

  return (
    <div
      className="flex items-center py-2 pl-3"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Checkbox
        variant="default"
        checked={checked}
        onCheckedChange={(value) => {
          if (isSelectAll) {
            onSelectDeselectAll(value as boolean);
          } else {
            onCheckedChange(uniqueId, value as boolean);
          }
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </div>
  );
};

export default TableBulkActionsCheckbox;
