import React from 'react';
import { Button, TooltipTrigger } from '..';
import { BiExport } from 'react-icons/bi';

const InstantQueriesDashboardExport = ({
  onExport,
}: {
  onExport: () => void;
}) => {
  return (
    <div className="-mt-5 flex justify-end">
      <Button
        className="m-1"
        variant="icon-outline"
        size="sm"
        onClick={onExport}
      >
        <TooltipTrigger tooltip="Export">
          <BiExport />
        </TooltipTrigger>
      </Button>
    </div>
  );
};

export default InstantQueriesDashboardExport;
