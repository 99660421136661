import classNames from 'classnames';
import { PercentageBar, TooltipTrigger } from 'components';
import dayjs from 'dayjs';
import { colors, colorByKubeState, dateTimeFormatChart } from 'kfuse-constants';
import React, { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { KUBERNETES_PATH_STRING } from '../../hooks/useKubesFluxState';
import { useKubernetesController } from '../../KubernetesController';

import { KubeTableColumnMethods, RenderCellProps } from '../../types';
import { entityMappedToGraphQL } from '../../utils';
import useMergedLocation from '../../hooks/useMergedLocation';

export const KubeTableCell = ({
  children,
  tooltipText,
  className,
}: {
  children: ReactElement | ReactNode | string;
  tooltipText?: string;
} & RenderCellProps<'div'>): ReactElement => {
  return (
    <TooltipTrigger asChild tooltip={tooltipText}>
      <span className={className}>{children}</span>
    </TooltipTrigger>
  );
};

type Props = RenderCellProps<'div'> & KubeTableColumnMethods;

export const KubeTableFilterCell = ({
  facetKey,
  facetValue,
}: Props & { facetKey: string; facetValue: string }): ReactElement => {
  const location = useMergedLocation(KUBERNETES_PATH_STRING, {
    searchParams: {
      selectedFacetValuesByName: {
        [facetKey]: {
          [facetValue]: 1,
        },
      },
      page: null,
      expandedGroup: null,
    },
  });

  return (
    <Link
      className="link"
      to={location}
      onClick={(evt) => evt.stopPropagation()}
    >
      {facetValue}
    </Link>
  );
};

export const KubeCellCluster = ({ row, ...props }: Props): ReactElement => {
  const { entitiesType } = useKubernetesController();
  const entityMapped = entityMappedToGraphQL(entitiesType);
  const facetKey = 'kube_cluster_name';
  const tag: string = row[entityMapped].tags.find((tag: string) =>
    tag.startsWith(facetKey),
  );
  const facetValue = tag.substring(facetKey.length + 1);

  return (
    <KubeTableFilterCell
      facetKey="kube_cluster_name"
      facetValue={facetValue}
      className="kubernetes__table__cell__cluster-name truncate w-full"
      row={row}
      {...props}
    />
  );
};

export const KubeCellNamespace = ({ row, ...props }: Props): ReactElement => {
  const { entitiesType } = useKubernetesController();
  const entityMapped = entityMappedToGraphQL(entitiesType);

  return (
    <KubeTableFilterCell
      facetKey="kube_namespace"
      facetValue={row[entityMapped].metadata.namespace}
      className="kubernetes__table__cell__cluster-name truncate w-full"
      row={row}
      {...props}
    />
  );
};

const KubePercentageBarCondition = [
  { color: colors.greenCyan, min: 0, max: 70 },
  { color: colors.yellow, min: 70, max: 90 },
  { color: colors.red, min: 90, max: 100 },
  { color: colors.darkRed, min: 100, max: Infinity },
];
export const KubeCellPercentageBar = ({
  percent,
  max = 100,
}: {
  percent: number;
  max?: number;
}): ReactElement => {
  if (typeof percent !== 'number' || isNaN(percent)) return <>-</>;

  const color = KubePercentageBarCondition.find(
    (condition) => percent >= condition.min && percent < condition.max,
  )?.color;

  const value = (percent / max) * 100;

  return (
    <div className="flex flex--direction-col flex--align-items-end">
      <div className="flex flex--justify-content-end">
        {percent.toFixed(2)}%
      </div>
      <PercentageBar
        className="kubernetes__table__cell__percentage-bar"
        color={color}
        percent={value}
      />
    </div>
  );
};

export const KubeCellTimestamp = ({
  timestamp,
  ...prop
}: {
  timestamp: number;
} & Props): ReactElement => {
  if (!timestamp) return <KubeTableCell {...prop}>-</KubeTableCell>;

  const timestampAge = dayjs.unix(timestamp);
  return (
    <KubeTableCell
      {...prop}
      align="right"
      tooltipText={timestampAge.format(dateTimeFormatChart)}
    >
      {timestampAge.fromNow(true)}
    </KubeTableCell>
  );
};

export const KubeCellStatus = ({
  status,
  ...prop
}: {
  status: string;
} & Props): ReactElement => {
  if (!status) return <KubeTableCell {...prop}>-</KubeTableCell>;

  return (
    <KubeTableCell {...prop}>
      <button
        className="kubernetes__table__cell__status w-full font-semibold uppercase px-2 py-1 text-center"
        data-status={status.toLowerCase()}
        style={{ color: colorByKubeState[status.toLowerCase()] }}
      >
        {status}
      </button>
    </KubeTableCell>
  );
};
