import { DateFormatter } from 'components';
import { startCase } from 'lodash';
import React from 'react';
import { dateTimeFormatWithMilliseconds } from 'kfuse-constants';
import { formatDurationNs, formatFileSize, isNullOrUndefined } from 'utils';
import { RumLongTaskEvent } from '../types';
import isSizeFacet from '../utils/isSizeFacet';
import isDurationFacet from '../utils/isDurationFacet';
import sidebarFacets from '../requests/sidebarFacets';

const longTaskColumns = sidebarFacets
  .filter(
    (facet) => facet.long_task_facet && facet.field !== 'session.has_replay',
  )
  .map((facet) => {
    return {
      key: facet.field,
      label: facet.name,
    };
  })
  .sort((a, b) => a.label.localeCompare(b.label));

const RumLongTaskStringColumnKey = longTaskColumns.reduce(
  (acc, column) => {
    acc[column.key] = column.label as string;
    return acc;
  },
  {} as Record<string, string>,
);

export const RumLongTaskColumnKey = {
  date: 'time',
  'application.id': 'Application ID',
  ...RumLongTaskStringColumnKey,
};

type RenderCellPropsOfVLongTask = {
  row: RumLongTaskEvent;
  value: any;
};

export const rumDynamicLongTaskTableColumns = () => [
  {
    key: RumLongTaskColumnKey.date,
    label: 'Date',
    renderCell: ({ value }: RenderCellPropsOfVLongTask) => (
      <DateFormatter
        formatString={dateTimeFormatWithMilliseconds}
        unixTimestamp={value}
      />
    ),
  },
  ...Object.keys(RumLongTaskStringColumnKey).map((key) => {
    return {
      key,
      label: startCase(
        RumLongTaskStringColumnKey[
          key as keyof typeof RumLongTaskStringColumnKey
        ],
      ),
      value: ({ row }: RenderCellPropsOfVLongTask) => {
        const isDurationColumn = isDurationFacet(key);
        const value = row.data[key as keyof typeof row.data];
        if (isDurationColumn) {
          const convertedValue = Number(value);
          return isNaN(convertedValue) || isNullOrUndefined(convertedValue)
            ? '-'
            : formatDurationNs(convertedValue, 1, 2);
        }
        const isSizeColumn = isSizeFacet(key);
        if (isSizeColumn) {
          return formatFileSize(value as string);
        }
        return value;
      },
    };
  }),
];
