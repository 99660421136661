import { useKeyExistsState } from 'hooks';
import React, { MutableRefObject } from 'react';
import { FacetPart } from 'types';
import { useLogsWorkbooksState } from './hooks';
import LogsSelectedLogAttribute from './LogsSelectedLogAttribute';

type Props = {
  containerRef: MutableRefObject<HTMLDivElement>;
  customColumnsState?: ReturnType<typeof useKeyExistsState>;
  disableAddCustomColumn?: boolean;
  facetPart: FacetPart;
  fpHash: string;
  index: number;
  logFacet: any;
  logsState: ReturnType<typeof useLogsWorkbooksState>['currentLogsState'];
  offsetX?: number;
  offsetY?: number;
  shouldDisableFilterActions?: boolean;
  source: string;
};

const LogsLogLinePartsItemFacet = ({
  containerRef,
  customColumnsState,
  disableAddCustomColumn,
  facetPart,
  fpHash,
  index,
  logFacet,
  logsState,
  offsetX,
  offsetY,
  shouldDisableFilterActions,
  source,
}: Props) => {
  const { content, dataType } = facetPart;
  return (
    <LogsSelectedLogAttribute
      containerRef={containerRef}
      customColumnsState={customColumnsState}
      label={
        <span
          className={`logs__log-line-parts__item__facet logs__log-line-parts__item__facet--${dataType}`}
        >
          {content}
        </span>
      }
      disableAddCustomColumn={disableAddCustomColumn}
      enableKeyExistsFilter
      fpHash={fpHash}
      index={index}
      logFacet={{ ...logFacet, value: content }}
      logsState={logsState}
      offsetX={offsetX}
      offsetY={offsetY}
      shouldDisableFilterActions={shouldDisableFilterActions}
      source={source}
    />
  );
};

export default LogsLogLinePartsItemFacet;
