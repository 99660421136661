const chartingPalette = [
  '#7eb0d5',
  '#b2e061',
  '#bd7ebe',
  '#ffb55a',
  '#ffee65',
  '#beb9db',
  '#0d88e6',
  '#00b7c7',
  '#5ad45a',
  '#8be04e',
  '#ebdc78',
  '#8bd3c7',
  '#115f9a',
  '#1984c5',
  '#22a7f0',
  '#48b5c4',
  '#76c68f',
  '#a6d75b',
  '#c9e52f',
  '#d0ee11',
  '#f4f100',
];
export default chartingPalette;
