import { DashboardPanelProps, DashboardPanelType } from 'types/Dashboard';

export const ALLOW_ONLY_ONE_ACTIVE_SEARCH = [
  DashboardPanelType.TOP_LIST,
  DashboardPanelType.TABLE,
  DashboardPanelType.PIECHART,
];

export const getRumDashboardExportPanel = ({
  expr,
  type,
  refId,
}: {
  expr: string;
  type: DashboardPanelType;
  refId: string;
}): DashboardPanelProps => {
  return {
    options: {
      legend: { calcs: [], displayMode: 'list', placement: 'bottom' },
      tooltip: { mode: 'single', sort: 'none' },
    },
    targets: [
      {
        datasource: { type: 'rum', uid: '' },
        editorMode: 'code',
        expr,
        queryType: type === DashboardPanelType.TIMESERIES ? 'range' : 'instant',
        refId,
      },
    ],
    type,
  };
};
