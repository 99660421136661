import dayjs from 'dayjs';
import { onPromiseError } from 'utils';
import useRumState from '../hooks/useRumState';
import queryRumService from './queryRumService';
import { buildRumFilter } from './utils';
import { RumErrorGroup } from '../types';

type Args = {
  errorGroupingKey?: string;
  onlyTop10?: boolean;
  isListView?: boolean;
  rumState: ReturnType<typeof useRumState>;
};

const getLimit = (onlyTop10: boolean, isListView: boolean) => {
  if (onlyTop10) {
    return 10;
  }
  if (isListView) {
    return 10000;
  }
  return 1;
};

const rumErrorGroups = async ({
  errorGroupingKey,
  onlyTop10,
  isListView,
  rumState,
}: Args): Promise<RumErrorGroup[]> => {
  const {
    applicationFilter,
    dateState,
    facetRegexState,
    selectedFacetValuesByNameState,
    selectedFacetRangeByNameState,
  } = rumState;

  const [date] = dateState;
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  const facetRegex = facetRegexState?.state;
  const selectedFacetValuesByName = { ...selectedFacetValuesByNameState.state };
  const selectedFacetRangeByName = selectedFacetRangeByNameState.state;

  if (errorGroupingKey) {
    selectedFacetValuesByName['error.fingerprint'] = {
      [errorGroupingKey]: 1,
    };
  }

  return queryRumService<RumErrorGroup[], 'errorGroups'>(`
  {
    errorGroups(
      limit: ${getLimit(onlyTop10, isListView)},
      durationSecs: ${durationSecs},
      filter: ${buildRumFilter({
        applicationFilter,
        facetRegex,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
        ...rumState?.eventIdFilter,
      })}
      timestamp: "${endTime.format()}",
    ) {
        fingerprint
        ${isListView ? '' : 'service'}
        ${isListView ? '' : 'errorType'}
        ${isListView ? '' : 'message'}
        ${isListView ? '' : 'numOccurrences'}
        ${isListView ? '' : 'firstOccurrenceError'}
        lastOccurrenceError
        ${isListView ? '' : 'firstOccurrenceMs'}
        lastOccurrenceMs
    }
  }
  `).then((data) => data.errorGroups || [], onPromiseError);
};

export default rumErrorGroups;
