export const getSelector = (row: any) => {
  const allSelectors = row?.spec?.selectors?.map((item) => {
    return `${item?.key}:${item?.Values?.map((element) => {
      return `${element} `;
    })}`;
  });

  return allSelectors;

  // if (allSelectors && allSelectors.length > 1) {
  //   if (Object.keys(allSelectors[0]).length > 25) {
  //     const obj = allSelectors[0];
  //     return obj.toString().substring(0, 25) + '...';
  //   } else {
  //     return allSelectors[0] + '...';
  //   }
  // } else {
  //   return allSelectors;
  // }
};

export const getRules = (row: any) => {
  const allRules = row?.rules?.map((element) => {
    return `${element?.verbs?.map((item) => {
      return `${item}`;
    })} ${element?.apiGroups?.map((item) => {
      return ` ${item}`;
    })} ${
      element?.resource
        ? element?.resource?.map((item) => {
            return `${item ? item : ''}`;
          })
        : ''
    } ${
      element?.resourceNames
        ? element?.resourceNames?.map((item) => {
            return `${item ? item : ''}`;
          })
        : ''
    }`;
  });

  return allRules;
  // if (allRules && allRules.length > 1) {
  //   if (Object.keys(allRules[0]).length > 25) {
  //     const obj = allRules[0];
  //     return obj.toString().substring(0, 25) + '...';
  //   } else {
  //     return allRules[0] + '...';
  //   }
  // } else {
  //   return allRules;
  // }
};

export const ingressRules = (row: any) => {
  const ingressRulesData = row?.spec?.rules?.map((rule) => {
    const host = rule?.host ? rule.host : '';
    const httpPaths = rule?.httpPaths?.map((path) => {
      const pathType = path?.pathType;
      const serviceName = path?.backend?.service?.serviceName;
      return ` ${pathType} ${serviceName}`;
    });
    const httpPathsString = httpPaths.join(', ');
    return `${host} ${httpPathsString}, `;
  });

  return ingressRulesData;

  // if (ingressRulesData && Object.keys(ingressRulesData[0]).length > 25) {
  //   const obj = ingressRulesData[0];
  //   return obj.toString().substring(0, 25) + '...';
  // } else {
  //   return ingressRulesData ? ingressRulesData[0] + '...' : ingressRulesData;
  // }
};

export const getAggregationsByEntities = (
  entityType: string,
  groupBySearchTerms: any,
) => {
  if (groupBySearchTerms) {
    switch (entityType) {
      case 'Pod':
        return `{id: "RUNNING_COUNT", aggregationType: FILTERED_COUNT, path: ["status"], arg: "Running"}, 
        {id: "PENDING_COUNT", aggregationType: FILTERED_COUNT, path: ["status"], arg: "Pending"},
        {id: "CRASHLOOPBACK", aggregationType: FILTERED_COUNT, path: ["status"], arg: "CrashLoopBackOff"},
        {id: "COMPLETED", aggregationType: FILTERED_COUNT, path: ["status"], arg: "Completed"},
      {id: "CLUSTER", aggregationType: STR_JOIN, path: ["metadata", "cluster"]},   
      {id: "NAMESPACE", aggregationType: STR_JOIN, path: ["metadata", "namespace"]}, 
      {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["metadata", "creationTimestamp"]}, 
      {id: "TOTAL_RESTART_COUNT", aggregationType: SUM, jsonPath: "$.containerStatuses[*].restartCount"}, 
      {id: "COUNT_CONTAINER_READY", aggregationType: FILTERED_COUNT, jsonPath: "$.containerStatuses[*].ready", arg: "true"}`;
      case 'Cluster':
        return `
        {id: "node_count", aggregationType: SUM, path: ["nodeCount"]},
        {id: "cpu_capacity", aggregationType: SUM, path: ["cpuCapacity"]},
        {id: "cpu_allocatable", aggregationType: SUM, path: ["cpuAllocatable"]},
        {id: "memory_capacity", aggregationType: SUM, path: ["memoryCapacity"]},
        {id: "memory_allocatable", aggregationType: SUM, path: ["memoryAllocatable"]},
        {id: "podcapacity", aggregationType: SUM, path: ["podCapacity"]},
        {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["creationTimestamp"]}, 
      {id: "VERSIONS", aggregationType: STR_JOIN, path: ["kubeletVersions"]}`;
      case 'Node':
        return `{id: "RUNNING_COUNT", aggregationType: FILTERED_COUNT, path: ["status"], arg: "Running"}, 
        {id: "PENDING_COUNT", aggregationType: FILTERED_COUNT, path: ["status"], arg: "Pending"},
        {id: "CRASHLOOPBACK", aggregationType: FILTERED_COUNT, path: ["status"], arg: "CrashLoopBackOff"},
        {id: "COMPLETED", aggregationType: FILTERED_COUNT, path: ["status"], arg: "Completed"},
        {id: "CPUALLOCATABLE", aggregationType: SUM, path: ["status","allocatable", "cpu"]}
        {id: "CPUCAPACITY", aggregationType: SUM, path: ["status","capacity","cpu"]}
        {id: "MEMALLOCATABLE", aggregationType: SUM, path: ["status","allocatable","memory"]}
        {id: "ANNOTATIONS", aggregationType: STR_JOIN, path: ["metadata","annotations"]}
        {id: "MEMCAPACITY", aggregationType: SUM, path: ["status","capacity","memory"]}
      {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["metadata", "creationTimestamp"]}, 
      {id: "ROLES", aggregationType: SUM, jsonPath: "$.roles[*]"}, 
      {id: "KUBELET_VERSION", aggregationType: FILTERED_COUNT, path: ["status"], arg: "kubeletVersions"}`;
      case 'Namespace':
        return `{id: "RUNNING_COUNT", aggregationType: FILTERED_COUNT, path: ["status"], arg: "Running"}, 
        {id: "PENDING_COUNT", aggregationType: FILTERED_COUNT, path: ["status"], arg: "Pending"},
        {id: "CRASHLOOPBACK", aggregationType: FILTERED_COUNT, path: ["status"], arg: "CrashLoopBackOff"},
        {id: "COMPLETED", aggregationType: FILTERED_COUNT, path: ["status"], arg: "Completed"},
      {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["metadata", "creationTimestamp"]} `;
      case 'Deployment':
        return `{id: "RUNNING_COUNT", aggregationType: FILTERED_COUNT, path: ["status"], arg: "Running"}, 
        {id: "PENDING_COUNT", aggregationType: FILTERED_COUNT, path: ["status"], arg: "Pending"},
        {id: "CRASHLOOPBACK", aggregationType: FILTERED_COUNT, path: ["status"], arg: "CrashLoopBackOff"},
        {id: "COMPLETED", aggregationType: FILTERED_COUNT, path: ["status"], arg: "Completed"},
      {id: "NAMESPACE", aggregationType: STR_JOIN, path: ["metadata", "namespace"]}, 
      {id: "STRATEGY", aggregationType: FILTERED_COUNT, path: ["deploymentStrategy"], arg: "deploymentStrategy"},
      {id: "CURRENT",  aggregationType: SUM, path: ["replicas"]},
      {id: "AVAILABLE",  aggregationType: SUM, path: ["availableReplicas"]},
      {id: "DESIRED",  aggregationType: SUM, path: ["replicasDesired"]},
      {id: "READY",  aggregationType: SUM, path: ["readyReplicas"]},
      {id: "UPDATED",  aggregationType: SUM, path:["updatedReplicas"]},
      {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["metadata", "creationTimestamp"]}`;
      case 'ReplicaSet':
        return `{id: "CURRENT",  aggregationType: SUM, path: ["replicas"]},
        {id: "AVAILABLE",  aggregationType: SUM, path: ["availableReplicas"]},
        {id: "DESIRED",  aggregationType: SUM, path: ["replicasDesired"]},
        {id: "READY",  aggregationType: SUM, path: ["readyReplicas"]},
        {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["metadata", "creationTimestamp"]}`;
      case 'Job':
        return ` {id: "DURATION", aggregationType: MIN, path: ["status","completionTime"]},
        {id: "COMPLETIONS", aggregationType: SUM, path: ["spec","completions"]},
        {id: "PARALLELISM", aggregationType: SUM, path: ["spec","parallelism"]},
        {id: "BACKOFFLIMIT", aggregationType: SUM, path: ["spec","backoffLimit"],},
        {id: "LABELS", aggregationType: STR_JOIN, jsonPath: "$.selectors[*].Values"}, 
        {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["metadata", "creationTimestamp"]}`;
      case 'CronJob':
        return `{id: "ACTIVE_JOB", aggregationType: STR_JOIN, path: ["status", "active"]},
        {id: "SCHEDULE_CRON_JOB", aggregationType: STR_JOIN, path: ["spec", "schedule"]}
        {id: "SUSPEND_CRON_JOB", aggregationType: STR_JOIN, path: ["spec", "suspend"]},
        {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["metadata", "creationTimestamp"]}`;
      case 'DaemonSet':
        return `{id: "RUNNING_COUNT", aggregationType: SUM, path: ["status", "numberAvailable"]}, 
        {id: "PENDING_COUNT", aggregationType: SUM, path: ["status", "numberUnavailable"]},
        {id: "CRASHLOOPBACK", aggregationType: SUM, path: ["status", "numberMisscheduled"]},
        {id: "COMPLETED", aggregationType: SUM, path: ["status""numberReady"]},
      {id: "CLUSTER", aggregationType: STR_JOIN, path: ["metadata", "cluster"]},   
      {id: "SELECTORS", aggregationType: STR_JOIN, jsonPath: "$.spec.selectors[*]"}, 
      {id: "NAMESPACE", aggregationType: STR_JOIN, path: ["metadata", "namespace"]}, 
      {id: "STRATEGY", aggregationType: STR_JOIN, path: ["spec","deploymentStrategy"]},
      {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["metadata", "creationTimestamp"]}`;
      case 'StatefulSet':
        return `{id: "CURRENT",  aggregationType: SUM, path: ["status","currentReplicas"]},
        {id: "AVAILABLE",  aggregationType: SUM, path: ["status","availableReplicas:"]},
        {id: "DESIRED",  aggregationType: SUM, path: ["spec","desiredReplicas"]},
        {id: "SERVICE",  aggregationType: STR_JOIN, path: ["spec", "serviceName"]},
        {id: "POLICY",  aggregationType: STR_JOIN, path: ["spec", "podManagementPolicy"]},
        {id: "UPDATEPOLICY",  aggregationType: STR_JOIN, path: ["spec", "updateStrategy"]},
        {id: "SELECTORS", aggregationType: STR_JOIN, jsonPath: "$.spec.selectors[*]"}, 
        {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["metadata", "creationTimestamp"]}`;
      case 'Ingress':
        return ` 
        {id: "NAMESPACE", aggregationType: STR_JOIN, path: ["metadata", "namespace"]}, 
        {id: "INGRESS_CLASSNAME", aggregationType: STR_JOIN, path: ["spec", "ingressClassName"], arg:"ingressClassName"}, 
        {id: "RULES", aggregationType: STR_JOIN, jsonPath: "$.spec.rule[*]"}, 
        {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["metadata", "creationTimestamp"]}`;
      case 'Service':
        return `
        {id: "TYPE", aggregationType: STR_JOIN, path: ["spec", "type"]}, 
        {id: "CLUSTERIP", aggregationType: STR_JOIN, path: ["spec", "clusterIp"]}, 
        {id: "EXTERNALIPS", aggregationType: STR_JOIN, jsonPath: "$.spec.externalIPs[*]"},
        {id: "PORTS", aggregationType: STR_JOIN, jsonPath: "$.spec.ports[*]"}, 
        {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["metadata", "creationTimestamp"]}`;
      case 'PersistentVolume':
        return `{id: "NAMESPACE", aggregationType: STR_JOIN, path: ["metadata", "namespace"]}, 
        {id: "PHASE", aggregationType: STR_JOIN, path: ["status", "phase"]},
        {id: "VOLUME_MODE", aggregationType: STR_JOIN, path: ["status", "volumeMode"]},
        {id: "ACCESS_MODES", aggregationType: STR_JOIN, jsonPath: "$.spec.accessModes[*]"},
        {id: "POLICY", aggregationType: STR_JOIN, path: ["spec", "persistentVolumeReclaimPolicy"]}, 
        {id: "STORAGE_CLASSNAME", aggregationType: STR_JOIN, path: ["spec", "storageClassName"]}, 
        {id: "CAPACITY", aggregationType: SUM, path: ["spec", "capacity","storage"]}, 
        {id: "STORAGE_TYPE", aggregationType: STR_JOIN, path: ["spec", "persistentVolumeType"]}, 
        {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["metadata", "creationTimestamp"]}`;
      case 'PersistentVolumeClaim':
        return `{id: "NAMESPACE", aggregationType: STR_JOIN, path: ["metadata", "namespace"]}, 
        {id: "PHASE", aggregationType: STR_JOIN, path: ["status", "phase"], arg:"phase"},
        {id: "VOLUME_MODE", aggregationType: STR_JOIN, path: ["status", "volumeMode"]},
        {id: "CAPACITY", aggregationType: SUM, path: ["status", "capacity","storage"]},
        {id: "ACCESS_MODES", aggregationType: STR_JOIN, jsonPath: "$.spec.accessModes[*]"},
        {id: "STORAGE_CLASSNAME", aggregationType: STR_JOIN, path: ["spec", "storageClassName"]}, 
        {id: "STORAGE_TYPE", aggregationType: STR_JOIN, path: ["spec", "persistentVolumeType"]}, 
        {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["metadata", "creationTimestamp"]}`;
      case 'Role':
        return `{id: "NAMESPACE", aggregationType: STR_JOIN, path: ["metadata", "namespace"]},
        {id: "RULES", aggregationType: STR_JOIN, jsonPath: "$.rules[*]"},
        {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["metadata", "creationTimestamp"]}
        `;
      case 'RoleBinding':
        return `{id: "NAMESPACE", aggregationType: STR_JOIN, path: ["metadata", "namespace"]},
        {id: "ROLES", aggregationType: STR_JOIN, path: ["roleRef", "name"]},
        {id: "SUBJECTS", aggregationType: STR_JOIN, jsonPath: "$.subjects[*]"},
        {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["metadata", "creationTimestamp"]}`;
      case 'ClusterRole':
        return `{id: "RULES", aggregationType: STR_JOIN, jsonPath: "$.rules[*]"},
        {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["metadata", "creationTimestamp"]}`;
      case 'ClusterRoleBinding':
        return `{id: "ROLES", aggregationType: STR_JOIN, path: ["roleRef", "name"]},
        {id: "SUBJECTS", aggregationType: STR_JOIN, jsonPath: "$.subjects[*]"},
        {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["metadata", "creationTimestamp"]}`;
      case 'ServiceAccount':
        return `{id: "NAMESPACE", aggregationType: STR_JOIN, path: ["metadata", "namespace"]}, 
        {id: "SECRET_NAMES", aggregationType: STR_JOIN, path: ["secrets", "name"], arg: "name"},
        {id: "AUTO_MOUNT_TOKEN", aggregationType: STR_JOIN, path: ["automountServiceAccountToken"]},
        {id: "MIN_CREATE_TIME", aggregationType: MIN, path: ["metadata", "creationTimestamp"]}`;
      default:
        return '';
    }
  } else {
    return;
  }
};
