import { getRollupByVisualizationByMinLimit, getRollupToSecond } from 'utils';

import {
  AlertQueryExpressionType,
  AlertQueryItem,
  AlertsEvaluateProps,
  AlertQueryReducerType,
} from '../types';
import { ConditionGrafana } from '../AlertsCreateCondition';
/**
 * Get alert promql query expression for alert
 * @returns
 */
export const getAlertPromqlExprQuery = ({
  promqlQuery,
  datasourceUid,
  datasourceType,
  evaluate,
  interval,
}: {
  promqlQuery: string;
  datasourceUid: string;
  datasourceType: string;
  evaluate: AlertsEvaluateProps;
  interval?: string;
}): AlertQueryItem => {
  const { interval: intervalFor, intervalMs } = getAlertInterval(evaluate.for);
  const query = {
    refId: 'A',
    queryType: '',
    datasourceUid: datasourceUid,
    relativeTimeRange: getAlertRelativedTimeRange(evaluate.for),
    model: {
      datasource: { type: datasourceType },
      refId: 'A',
      hide: false,
      expr: promqlQuery,
      interval: intervalFor,
      intervalMs,
      range: true,
    },
  };

  if (interval) {
    query.model.interval = interval;
    query.model.intervalMs = getRollupToSecond(interval) * 1000;
  }

  return query;
};

/**
 * Get alert reducer
 */
export const getAlertReducerForQuery = ({
  conditions,
  expression,
  reducer,
  refId,
}: {
  conditions: ConditionGrafana[];
  expression: string;
  reducer: AlertQueryReducerType;
  refId: string;
}): AlertQueryItem => {
  return {
    datasourceUid: '-100',
    model: {
      conditions,
      datasource: { type: '__expr__', uid: '__expr__', name: 'Expression' },
      refId,
      type: AlertQueryExpressionType.reduce,
      hide: false,
      reducer: reducer,
      expression,
      settings: { mode: 'dropNN' },
    },
    refId,
    queryType: '',
  };
};

/**
 * Get alert math
 */
export const getAlertMathForQuery = ({
  conditions,
  expression,
  refId,
}: {
  conditions: ConditionGrafana[];
  expression: string;
  refId: string;
}): AlertQueryItem => {
  return {
    datasourceUid: '-100',
    model: {
      conditions,
      datasource: { type: '__expr__', uid: '__expr__', name: 'Expression' },
      refId,
      type: AlertQueryExpressionType.math,
      hide: false,
      expression,
    },
    refId,
    queryType: '',
  };
};

export const getAlertInterval = (
  forWindow: string,
): {
  interval: string;
  intervalMs: number;
} => {
  const endTime = getRollupToSecond(forWindow);
  const dateRange = { startTimeUnix: 0, endTimeUnix: endTime };
  const step = getRollupByVisualizationByMinLimit(dateRange, 'line', '15s');
  return { interval: `${step}s`, intervalMs: step * 1000 };
};

/**
 * Get relative time range for alert
 * From will over picked by user convert to second
 * To will be 0
 * The data picked up for alert will be from now - from to now - to and evaluate by the condition
 * @param forWindow
 * @returns {from: number; to: number}
 */
const getAlertRelativedTimeRange = (
  forWindow: string,
): { from: number; to: number } => {
  const from = getRollupToSecond(forWindow);

  return { from, to: 0 };
};
