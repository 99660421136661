import React from 'react';
import Icon from './base/Icon';

type Props = {
  isOpen: boolean;
  size?: number;
};

const Chevron = ({ isOpen }: Props) => {
  return isOpen ? (
    <Icon icon="chevron-down" size="sm" />
  ) : (
    <Icon icon="chevron-right" size="sm" />
  );
};

export default Chevron;
