import React, { ReactElement } from 'react';
import { AlertMuteTimingProps } from './types';

export const AlertsMuteTimingTimeRangeColumn = ({
  row,
}: {
  row: AlertMuteTimingProps;
}): ReactElement => {
  return (
    <div>
      {row.time_intervals.map((interval, idx) => {
        const { days_of_month, times, weekdays, location, months, years } =
          interval;

        const timezone = location ? location : 'UTC';
        const timesString =
          times &&
          times
            .map((time) => {
              return ` ${time.start_time} - ${time.end_time} [${timezone}]`;
            })
            .join(' and ');

        const weekdaysString = weekdays
          ? weekdays
              .map((day) => {
                const dayName = day.charAt(0).toUpperCase() + day.slice(1);
                return dayName.slice(0, 3);
              })
              .join(', ')
          : 'All';

        return (
          <div className="pb-2 font-medium text-text-secondary" key={idx}>
            <div>
              <span className="font-semibold">Times:</span>
              {timesString ? timesString : 'All'}
            </div>
            {weekdays && (
              <div>
                <span className="font-semibold">Weekdays: </span>
                {weekdaysString}
              </div>
            )}
            <div className="flex flex-row">
              <div>
                <span className="font-semibold">Days of month: </span>
                {days_of_month ? days_of_month?.join(', ') : 'All'} {' | '}
              </div>
              <div>
                <span className="font-semibold">Months: </span>
                {months ? months.join(', ') : 'All'} {' | '}
              </div>
              <div>
                <span className="font-semibold">Years: </span>
                {years ? years.join(', ') : 'All'}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AlertsMuteTimingTimeRangeColumn;
