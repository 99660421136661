import {
  delimiter,
  IGNORE_ANOMALY_LOWER_BAND_LABEL,
  IGNORE_ANOMALY_UPPER_BAND_LABEL,
} from 'kfuse-constants';
import { isNil } from 'lodash';
import {
  QueryCombinedStatusProps,
  QueryDataProps,
  QueryDataPropsRangeCombined,
} from 'types/QueryData';
import { Band, Series } from 'uplot';
import {
  drawAnomalyOutofBoundPoints,
  drawForecastSeriesByIndex,
  drawForecastVerticalLine,
  getAnomalyBandIndexes,
  getAnomalyOutofBoundPoints,
} from 'utils/Timeseries';
import { anomalyBandColor } from 'utils/colors';
import { convertSecondToReadable } from 'utils/timeCodeToUnix';

const combineForecastQueryData = ({
  darkModeEnabled,
  formulas,
  queries,
  queryData,
  timestamps,
  timestampsWithIndex,
}: {
  darkModeEnabled: boolean;
  formulas: QueryCombinedStatusProps[];
  queries: QueryCombinedStatusProps[];
  queryData: QueryDataProps;
  timestamps: number[];
  timestampsWithIndex: { [key: string]: number };
}) => {
  const forecastChartKeys = Object.keys(queryData);
  const isForecast = forecastChartKeys.some((key) => key.includes('forecast'));
  if (!isForecast) return;

  const newSeries: Series[] = [];
  let newMaxValue = -Infinity;
  let newMinValue = Infinity;
  const newData: Array<number[]> = [];
  const bands: Band[] = [];
  let isLoading = false;

  const bandsBitmapByLabel: {
    [key: string]: { inner?: number; upper?: number; lower?: number };
  } = {};

  forecastChartKeys.forEach((key, queryIndex) => {
    if (!key.includes('forecast')) return;
    if (key.includes('upper') || key.includes('lower')) return;
    const [type, queryKey] = key.split('_');
    if (queryData[key].isLoading) {
      isLoading = true;
    }
    let query = null;
    if (type === 'query') {
      query = queries.find((q) => q.queryKey === queryKey);
    }
    if (type === 'formula') {
      query = formulas.find((f) => f.queryKey === queryKey);
    }
    if (!query || !query.isActive || !queryData[key].range?.data) return;

    const upperKey = `${type}_${queryKey}_forecast_upper`;
    const lowerKey = `${type}_${queryKey}_forecast_lower`;
    const batchedData = [
      queryData[key],
      queryData[upperKey],
      queryData[lowerKey],
    ];

    batchedData.forEach((bdata, batchIndex) => {
      if (!bdata) return;
      if (bdata.isLoading) {
        isLoading = true;
      }

      if (!bdata.range?.data) return;

      const { data, series, minValue, maxValue } = bdata.range;
      const currSeriesTimestamps = data[0];
      data.forEach((d: number[], index: number) => {
        if (index === 0) return; // skip first index as it is timestamp
        const preFillData = Array(timestamps.length).fill(undefined);
        d.forEach((value, valueIndex) => {
          const timestamp = currSeriesTimestamps[valueIndex];
          const timestampIndex = timestampsWithIndex[timestamp];
          preFillData[timestampIndex] = value;
        });
        newData.push(preFillData);
      });

      series.forEach((s: Series, seriesIdx: number) => {
        const forecastSeries = { ...s };
        const prevLabel = forecastSeries.label;
        let bandType = 'inner';
        if (batchIndex === 1) {
          bandType = 'upper';
          forecastSeries.label = `${IGNORE_ANOMALY_UPPER_BAND_LABEL}${delimiter}${prevLabel}`;
          forecastSeries.band = true;
          forecastSeries.stroke = 'transparent'; // hide the upper band
        }
        if (batchIndex === 2) {
          bandType = 'lower';
          forecastSeries.label = `${IGNORE_ANOMALY_LOWER_BAND_LABEL}${delimiter}${prevLabel}`;
          forecastSeries.band = true;
          forecastSeries.stroke = 'transparent'; // hide the lower band
        }

        bandsBitmapByLabel[prevLabel] = {
          ...(bandsBitmapByLabel[prevLabel] || {}),
          [bandType]: newSeries.length + 1,
        };

        newSeries.push(forecastSeries);
      });

      newMaxValue = maxValue > newMaxValue ? maxValue : newMaxValue;
      newMinValue = minValue < newMinValue ? minValue : newMinValue;
    });

    const bandsBitmapByLabelKeys = Object.entries(bandsBitmapByLabel);
    if (bandsBitmapByLabelKeys.length === 1) {
      bandsBitmapByLabelKeys.forEach(([_, { inner, upper, lower }]) => {
        if (!isNil(upper) && !isNil(lower) && !isNil(inner)) {
          bands.push({
            series: [upper, lower], // [upper, lower]
            fill: darkModeEnabled
              ? anomalyBandColor.dark
              : anomalyBandColor.light,
            dir: -1,
          });
        }
      });
    }
  });

  const totalDataPoints = timestamps.length;
  const newHooks: QueryDataPropsRangeCombined['hooks'] = [];

  if (totalDataPoints) {
    const breakPoint = Math.floor(totalDataPoints / 2); // half of the data
    // draw the forecast series
    newHooks.push({
      type: 'drawSeries',
      hook: (u, seriesIndex) =>
        drawForecastSeriesByIndex({ u, seriesIndex, breakPoint }),
    });

    const firstTimestamp = timestamps[0];
    const midTimestamp = timestamps[breakPoint];
    const seconds = midTimestamp - firstTimestamp;
    const readableDuration = convertSecondToReadable(seconds);

    // draw the forecast vertical line
    newHooks.push({
      type: 'draw',
      hook: (u) => {
        drawForecastVerticalLine({
          u,
          darkModeEnabled,
          forecastDuration: readableDuration,
          breakPoint,
        });
      },
    });
  }

  newHooks.push({
    type: 'drawSeries',
    hook: (u: uPlot, seriesIdx: number) => {
      if (!seriesIdx || !u.series[seriesIdx]) return;

      const series = u.series[seriesIdx];
      const anomalyBandIndexes = getAnomalyBandIndexes(series.label, u.series);
      if (!anomalyBandIndexes) return;
      const outOfBoundPoints = getAnomalyOutofBoundPoints({
        anomalyBandIndexes,
        data: u.data,
        seriesIndex: seriesIdx,
        u,
      });
      drawAnomalyOutofBoundPoints({ outOfBoundPoints, u });
    },
  });

  return {
    data: newData,
    bands,
    hooks: newHooks,
    maxValue: newMaxValue,
    minValue: newMinValue,
    series: newSeries,
    isLoading,
  };
};

export default combineForecastQueryData;
