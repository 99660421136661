import { RightSidebar } from 'components';
import { useRequest } from 'hooks';
import React, { useMemo } from 'react';
import useRumState from './hooks/useRumState';
import { RumEventType } from './types';
import RumChartGrid from './RumChartGrid';
import rawEvent from './requests/rawEvent';
import useDebouncedEffect from 'use-debounced-effect';
import RumEventJson from './RumEventJson';

type Props = {
  close: VoidFunction;
  errorGroupingKey: string;
  errorId: string;
  errorTimeMs: number;
  rumState: ReturnType<typeof useRumState>;
};

const RumErrorsGroupTableSidebar = ({
  close,
  errorGroupingKey,
  errorId,
  errorTimeMs,
  rumState,
}: Props) => {
  const rumRawEventRequest = useRequest(rawEvent);

  useDebouncedEffect(
    () => {
      if (!errorId) {
        return;
      }
      rumRawEventRequest.call({
        eventTimeUnix: errorTimeMs,
        eventId: errorId,
        eventType: RumEventType.ERROR,
      });
    },
    {
      timeout: 200,
      ignoreInitialCall: false,
    },
    [errorId],
  );

  const eventDrawerData = useMemo(() => {
    if (!rumRawEventRequest.result) {
      return null;
    }

    const ddAttributesRemoved = { ...rumRawEventRequest.result };
    delete ddAttributesRemoved._dd;

    return ddAttributesRemoved;
  }, [rumRawEventRequest.result]);

  return (
    <RightSidebar
      className="apm-errors__group-sidebar"
      close={close}
      title={`Error group: ${errorGroupingKey}`}
      dataTestId="apm-errors-group-sidebar"
    >
      <div>
        <RumChartGrid
          eventType={RumEventType.ERROR}
          errorGroupingKey={errorGroupingKey}
          rumState={rumState}
        />
        <div className="text--h3 ms-3 mt-2 bold">Latest Error</div>
        {eventDrawerData && (
          <RumEventJson
            json={eventDrawerData}
            rumState={rumState}
            eventType={RumEventType.ERROR}
          />
        )}
      </div>
    </RightSidebar>
  );
};

export default RumErrorsGroupTableSidebar;
