import {
  Button,
  ConfirmationModal,
  Loader,
  Paginator,
  ProductTour,
  Table,
  TableSearch,
  TooltipTrigger,
  useColumnsState,
  useModalsContext,
  usePaginator,
  useTableSearch,
  useTableSort,
  useToaster,
} from 'components';
import { useLogsPageStateContext } from 'context';
import dayjs from 'dayjs';
import { useRequest } from 'hooks';
import React from 'react';
import { GrEdit, GrTrash } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import { deleteWorkbook } from 'requests';
import LogsSavedViewsRenameModal from './LogsSavedViewsRenameModal';
import { convertSavedQueryToUrlSearchParams } from './utils';

type CellType = {
  row: {
    logsState: string;
    name: string;
  };
};

const columns = ({
  openDeleteModal,
  openRenameModal,
  openLogsSavedView,
}: {
  openDeleteModal: (workbook: any) => () => void;
  openRenameModal: (workbook: any) => () => void;
  openLogsSavedView: (workbook: any) => () => void;
}) => [
  {
    key: 'name',
    label: 'Name',
    renderCell: ({ row }: CellType) => {
      return (
        <Button onClick={openLogsSavedView(row)} variant="link" size="sm">
          {row.name}
        </Button>
      );
    },
  },
  {
    key: 'createdAt',
    label: 'Created',
    renderCell: ({ value }) => dayjs(value).fromNow(),
  },
  {
    key: 'actions',
    label: 'Actions',
    renderCell: ({ row }: any) => (
      <div className="logs-saved-views__actions">
        <TooltipTrigger tooltip="Rename">
          <button onClick={openRenameModal(row)}>
            <GrEdit />
          </button>
        </TooltipTrigger>
        <TooltipTrigger tooltip="Delete">
          <button onClick={openDeleteModal(row)}>
            <GrTrash />
          </button>
        </TooltipTrigger>
      </div>
    ),
  },
];

type Props = {
  getWorkbooksRequest: ReturnType<typeof useRequest>;
};

const emptyArray: any[] = [];

const LogsSavedViews = ({ getWorkbooksRequest }: Props) => {
  const modals = useModalsContext();
  const toastmaster = useToaster();
  const logsPageState = useLogsPageStateContext();
  const [queriesState, setQueriesState] = logsPageState.queriesState;
  const navigate = useNavigate();

  const openDeleteModal = (workbook) => () => {
    const onConfirm = () => {
      const onSuccess = () => {
        getWorkbooksRequest.call();
        modals.pop();
        toastmaster.addToast({
          status: 'success',
          text: `Successfully deleted '${workbook.name}'`,
        });
      };

      deleteWorkbook(workbook.id).then(onSuccess, () => {});
    };

    modals.push(
      <ConfirmationModal
        onCancel={() => modals.pop()}
        onConfirm={onConfirm}
        description={`Are you sure you want to delete '${workbook.name}'`}
        title={`Delete ${workbook.name}`}
      />,
    );
  };

  const openRenameModal = (workbook) => () => {
    modals.push(
      <LogsSavedViewsRenameModal
        getWorkbooksRequest={getWorkbooksRequest}
        workbook={workbook}
      />,
    );
  };

  const openLogsSavedView = (workbook) => () => {
    const { customerFilter, queries } = convertSavedQueryToUrlSearchParams(
      workbook.logsState,
    );
    if (customerFilter) {
      logsPageState.setCustomerFilter(customerFilter);
    }
    setQueriesState(queries);
    setTimeout(() => {
      navigate(`/logs`);
    }, 100);
  };

  const columnsState = useColumnsState({
    columns: columns({
      openDeleteModal,
      openRenameModal,
      openLogsSavedView,
    }),
    key: 'logs-saved-views',
    initialState: {
      selectedColumns: {
        name: 1,
        createdAt: 1,
        updatedAt: 1,
        actions: 1,
      },
    },
  });

  const tableSearch = useTableSearch({
    rows: getWorkbooksRequest.result || emptyArray,
  });

  const tableSort = useTableSort({
    columns: columns({
      openDeleteModal,
      openRenameModal,
      openLogsSavedView,
    }),
    rows: tableSearch.searchedRows,
  });

  const paginator = usePaginator({ rows: tableSort.sortedRows });
  return (
    <div className="logs-saved-views">
      <div className="logs-saved-views-header">Logs Saved Queries</div>
      <TableSearch
        className="dashboard__list__search mb-2"
        placeholder="Search saved queries"
        tableSearch={tableSearch}
        dataTestId="logs-saved-views-search"
      />
      <Loader isLoading={getWorkbooksRequest.isLoading}>
        <Table
          className="table--bordered table--bordered-cells table--padded"
          columns={columnsState.columns}
          rows={paginator.paginatedRows}
          externalTableSort={tableSort}
          isSortingEnabled
          dataTestId="logs-saved-views"
        />
        {<ProductTour />}
        <div className="table-footer">
          <Paginator paginator={paginator} />
        </div>
      </Loader>
    </div>
  );
};

export default LogsSavedViews;
