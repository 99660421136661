import { useMemo } from 'react';
import { useSearches } from '../useSearch';

const useEventsPageState = () => {
  const { searches } = useSearches({});

  // Get dependencies for determining when a url change should happen
  const dependenciesForWriteStateToUrl = useMemo(() => [], []);

  const writeStateToUrl = () => {};

  return {
    dependenciesForWriteStateToUrl,
    searches,
    writeStateToUrl,
  };
};

export default useEventsPageState;
