import { FormulaProps, ExplorerQueryProps } from 'types';

export const validateAlertMetricQueryKey = ({
  formulas,
  queryKey,
  queries,
}: {
  formulas: FormulaProps[];
  queryKey: string;
  queries: ExplorerQueryProps[];
}): string => {
  if (queryKey.includes('Query')) {
    const queryKeyParsed = queryKey.split('(')[1].split(')')[0];
    const query = queries.find((q) => q.queryKey === queryKeyParsed);
    if (!query) {
      return 'Selected query does not exist';
    }
    if (!query.metric && !query.showInput) {
      return 'Selected query does not have a metric';
    }
  }
  if (queryKey.includes('Formula')) {
    const formulaIndex = Number(queryKey.split('(')[1].split(')')[0]) - 1;
    const formula = formulas[formulaIndex];
    if (!formula) {
      return 'Selected formula does not exist';
    }
  }
  return '';
};
