import classNames from 'classnames';
import { Button, TooltipTrigger } from 'components';
import { Datepicker } from 'composite';
import React from 'react';
import { Maximize2 } from 'react-feather';
import { FaAws } from 'react-icons/fa';
import { ServerlessPageProps } from 'screens/Serverless/types';
import { DateSelection } from 'types/DateSelection';
import ServerlessSearchBar from './ServerlessSearchBar';

const ServerlessHeader = ({
  serverlessState,
  leftSidebarState,
}: ServerlessPageProps) => {
  const serverlessTabs = [{ icon: <FaAws />, serverlessTab: 'AWS' }];

  const { date, setDate } = serverlessState;

  const activeTab = 'AWS';

  return (
    <>
      <div className="serverless__header">
        {leftSidebarState.width === 0 ? (
          <TooltipTrigger
            className="serverless__search__show-filters-button"
            tooltip="Show Filters"
          >
            <Button
              variant="icon-outline"
              size="xs"
              onClick={leftSidebarState.show}
            >
              <Maximize2 size={12} />
            </Button>
          </TooltipTrigger>
        ) : null}
        <div className="serverless__header__left">
          <div className="serverless__header__title text--h1">Serverless</div>
          <div className="serverless__header__tabs button-group">
            {serverlessTabs.map((tab) => (
              <button
                className={classNames({
                  'button-group__item': true,
                  'button-group__item--active': tab.serverlessTab === activeTab,
                })}
                key={tab.serverlessTab}
                onClick={() => {}}
              >
                <div className="button-group__item__icon">{tab.icon}</div>
                {tab.serverlessTab}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="serverless__header serverless__datepicker">
        <div className="serverless__header__left">
          <div className="mb-2 mt-2 width-100-per">
            <ServerlessSearchBar serverlessState={serverlessState} />
          </div>
        </div>
        <div className="serverless__header__right">
          <Datepicker
            onChange={setDate as (dateSelection: DateSelection) => void}
            value={date as DateSelection}
          />
        </div>
      </div>
    </>
  );
};

export default ServerlessHeader;
