import { useToaster } from 'components';
import React from 'react';
import { Clipboard, Search, XCircle } from 'react-feather';

type Props = {
  onExcludeByTag: (tag: string) => void;
  onFilterByTag: (tag: string) => void;
  setPopOverOpen?: (open: boolean) => void;
  isInAlerts?: boolean;
  close: () => void;
  tag: string;
};

const EventsExplorerTableTagsPanel = ({
  onExcludeByTag,
  onFilterByTag,
  tag,
  isInAlerts,
  close,
}: Props) => {
  const { addToast } = useToaster();

  const handleCopyToClipboard = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    navigator.clipboard.writeText(tag);
    addToast({ status: 'success', text: 'Copied to clipboard' });
    close();
  };

  return (
    <div className="events__attribute__panel">
      {isInAlerts ? (
        <button
          className="logs__selected-log__attribute__panel__item"
          onClick={handleCopyToClipboard}
        >
          <Clipboard
            className="logs__selected-log__attribute__panel__item__icon"
            size={14}
          />
          <span className="logs__selected-log__attribute__panel__item__label">
            {'Copy '}
          </span>
          <span className="logs__selected-log__attribute__panel__item__value">
            {tag}
          </span>
        </button>
      ) : (
        <>
          <div
            className="events__attribute__panel__item"
            onClick={(e) => {
              e.stopPropagation();
              onFilterByTag(tag);
              close();
            }}
          >
            <Search
              className="events__attribute__panel__item--icon"
              size={14}
            />
            <span className="events__attribute__panel__item--key">
              {' '}
              Filter By
            </span>
            <span className="events__attribute__panel__item--value">{tag}</span>
          </div>
          <div
            className="events__attribute__panel__item"
            onClick={(e) => {
              e.stopPropagation();
              onExcludeByTag(tag);
              close();
            }}
          >
            <XCircle
              className="events__attribute__panel__item--icon"
              size={14}
            />

            <span className="events__attribute__panel__item--key">
              {' '}
              Exclude
            </span>
            <span className="events__attribute__panel__item--value">{tag}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default EventsExplorerTableTagsPanel;
