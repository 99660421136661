import { AutocompleteListV2 } from 'components';
import React, { useEffect } from 'react';

const TracesSearchInputPanelValues = ({
  fetchLabelValuesByName,
  isLoadingByNameMap,
  labelValuesByNameMap,
  lastParsedValue,
  onClickLabelValueHandler,
  parsedFacetName,
  searchedLabelValueOptions,
}) => {
  useEffect(() => {
    fetchLabelValuesByName(parsedFacetName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedFacetName]);

  return (
    <AutocompleteListV2
      close={() => {}}
      disableKeyExist
      emptyPlaceholder="No suggestions"
      isLoading={parsedFacetName && isLoadingByNameMap.state[parsedFacetName]}
      onClickHandler={onClickLabelValueHandler}
      onClickKeyExistHandler={() => {}}
      options={searchedLabelValueOptions}
      typed={lastParsedValue}
    />
  );
};

export default TracesSearchInputPanelValues;
