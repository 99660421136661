import classNames from 'classnames';
import {
  CheckboxWithLabel,
  TooltipTrigger,
  toggleLogScale,
  useTimeseries,
} from 'components';
import React, { ReactElement } from 'react';

import useTimeseriesExplorer from './useTimeseriesExplorer';

const DISABLE_FUNCTION_LIST = true;

const TimeseriesExplorerToolbar = ({
  timeseries,
  timeseriesExplorer,
}: {
  timeseries: ReturnType<typeof useTimeseries>;
  timeseriesExplorer: ReturnType<typeof useTimeseriesExplorer>;
}): ReactElement => {
  const {
    callPromqlQueryRange,
    compareToPrev,
    functionList,
    loadQueryCall,
    setCompareToPrev,
    setFunctionList,
    setYScale,
    yScale,
  } = timeseriesExplorer;
  const { activeChart, config } = timeseries;

  const onCompareChange = (idx: number) => {
    const newCompareToPrev = [...compareToPrev];
    newCompareToPrev[idx].isActive = !newCompareToPrev[idx].isActive;
    setCompareToPrev(newCompareToPrev);
    loadQueryCall();
  };

  const onFunctionChange = (idx: number, isActive: boolean) => {
    const newFunctionList = [...functionList];
    newFunctionList.forEach((item) => {
      item.isActive = false;
    });
    newFunctionList[idx].isActive = !isActive;
    setFunctionList(newFunctionList);
    callPromqlQueryRange();
  };

  const applyYScale = (scale: 'linear' | 'log') => {
    const updatedConfig = toggleLogScale(config, { type: scale });
    timeseries.setConfig(updatedConfig);
    setYScale(scale);
  };

  return (
    <div className="new-metrics__chart__modal__toolbar">
      {!DISABLE_FUNCTION_LIST && (
        <>
          <div className="new-metrics__chart__modal__toolbar__title">
            Functions
          </div>
          <div className="new-metrics__chart__modal__toolbar__function">
            {functionList.map((item, idx) => {
              return (
                <div
                  className="new-metrics__chart__modal__toolbar__function__item"
                  key={item.label}
                >
                  <button
                    className={classNames({
                      button: true,
                      'button--blue': item.isActive,
                    })}
                    onClick={() => onFunctionChange(idx, item.isActive)}
                  >
                    {item.label}
                  </button>
                </div>
              );
            })}
          </div>
        </>
      )}
      <div className="new-metrics__chart__modal__toolbar__title pt-1">
        Compare to Previous
      </div>
      <div
        className={classNames({
          'new-metrics__chart__modal__toolbar__compare': true,
          'opacity-50': activeChart !== 'Line',
          'opacity-100': activeChart === 'Line',
        })}
      >
        <TooltipTrigger tooltip={<div>Only available for Line chart</div>}>
          {compareToPrev.map((item, idx) => {
            return (
              <div
                className={classNames({
                  'new-metrics__chart__modal__toolbar__compare__item': true,
                  'pointer-events-none': activeChart !== 'Line',
                })}
                key={item.label}
              >
                <CheckboxWithLabel
                  label={item.label}
                  onChange={() => onCompareChange(idx)}
                  value={item.isActive}
                />
              </div>
            );
          })}
          {activeChart !== 'Line' && (
            <p className="text--yellow pt-1">Only available for Line chart </p>
          )}
        </TooltipTrigger>
      </div>
      <div className="new-metrics__chart__modal__toolbar__title pt-4">
        Y Scale
      </div>
      <div className="button-group mt-2">
        <div
          className={classNames({
            'button-group__item': true,
            'button-group__item--active': yScale === 'linear',
          })}
        >
          <button onClick={() => applyYScale('linear')}>Linear</button>
        </div>
        <div
          className={classNames({
            'button-group__item': true,
            'button-group__item--active': yScale === 'log',
          })}
        >
          <button onClick={() => applyYScale('log')}>Log</button>
        </div>
      </div>
    </div>
  );
};

export default TimeseriesExplorerToolbar;
