export type User = {
  id: string;
  email: string;
  role: UserRole;
  login: string;
  gid: string;
  orgId: number;
  scope: string;
};

export enum UserRole {
  ADMIN = 'Admin',
  VIEWER = 'Viewer',
  EDITOR = 'Editor',
}
