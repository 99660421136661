import { Dispatch, ReactNode, SetStateAction } from 'react';
import { SearchState } from 'hooks/useSearch';
import { TimeseriesRenderProps } from 'types';

import { DateSelection } from './DateSelection';
import { PrometheusMetric, PrometheusDatasetWithLabel } from './prometheus';
import { RumEventType } from 'screens/Rum';

export type ChartGridKeysState = [
  Record<string, string>,
  Dispatch<SetStateAction<Record<string, string>>>,
];

export type ChartJsData = { [key: string]: number };

export type ChartGridReferenceLine = {
  label: string;
  x: number;
  angle?: number;
};

type RenderTooltipTimestampArgs = {
  index: number;
  stepInMs: number;
  timestamp: number;
};

export type ChartGridItem = {
  data: ChartJsData[];
  keys: string[];
  renderTooltipTimestamp?: ({
    index,
    stepInMs,
    timestamp,
  }: RenderTooltipTimestampArgs) => ReactNode;
  referenceLines?: ChartGridReferenceLine[];
  timestamps: number[];
};

export type ChartGridItemRender = {
  data: ChartJsData[];
  keys: string[];
  timestamps: number[];
};

export type ChartOption = {
  label: string;
  value: string;
};

export type ChartGridItemType = {
  initialKey?: string;
  charts: Chart[];
  onKeyChange?: (string) => void;
  shouldShowTop5?: boolean;
};

export type ChartQueryArgs = {
  chart: Chart;
  date: DateSelection;
  instant?: boolean;
  isAnomalyEnabled?: boolean;
  parsedPromql?: boolean;
  step?: string;
  width: number;
};

export type ChartQuery = ({
  date,
  instant,
  parsedPromql,
  step,
}: ChartQueryArgs) => Promise<ChartGridItem>;

type InstantQueryResult = Promise<number>;
type InstantQueryFunction = (params: {
  date: DateSelection;
}) => InstantQueryResult;

export type Chart = {
  key: string;
  additionalButtons: {
    key: string;
    icon: ReactNode;
    onClick: VoidFunction;
    tooltip: string;
  }[];
  allowYAxisDecimals?: boolean;
  chartType?: string;
  colorMap?: { [key: string]: string };
  datasetsFormatter?: (datasets: PrometheusDatasetWithLabel[]) => ChartGridItem;
  disabledCompare?: boolean;
  disableExplore?: boolean;
  disableLogScale?: boolean;
  enableAnomaly?: boolean;
  enablePercentageChart?: boolean;
  enableLogScaleByDefault?: boolean;
  enableSpanOverlay?: boolean;
  hooks?: TimeseriesRenderProps['hooks'];
  instant?: boolean;
  initialParam?: string;
  label: string;
  labels?: ((metric: PrometheusMetric) => string)[];
  libraryType?: 'uplot' | 'rechart';
  legendTableColumns?: ChartLegendTableColumn[];
  limitTo?: 'top' | 'bottom';
  limitToValue?: number;
  marginLeft?: number;
  onSelection?: (arg0: number, arg1: number) => void;
  onSetSidebar?: (val: any) => void;
  options?: ChartOption[];
  render?: () => ReactNode;
  renderComponent?: TimeseriesRenderProps['renderComponent'];
  renderTotalCount?: (number) => string;
  scaleYRange?: { min: number; max: number };
  showCompactLegend?: boolean;
  totalKey?: string;
  traceQueries?: SearchState[];
  rumQueries?: SearchState[];
  query: ChartQuery;
  instantQuery?: InstantQueryFunction;
  queryEventOverlay?: () => Promise<any>;
  step?: string;
  unit: string;
  useCeilInAdjustingTime?: boolean;
  xAxisTickFormatter?: (labels: number[]) => (s: number) => string;
  yAxisTickFormatter?: (s: number) => string;
  eventType?: RumEventType;
};

export enum ChartLegendTableColumn {
  key = 'key',
  avg = 'avg',
  min = 'min',
  max = 'max',
  sum = 'sum',
}
