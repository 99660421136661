import { ChartType } from 'types/Timeseries';
import uPlot, { AlignedData, Series } from 'uplot';

export const readableJsonFromUplotChart = ({
  activeChart,
  data,
  series,
}: {
  activeChart: ChartType;
  data: AlignedData;
  series: Series[];
}): string => {
  try {
    const timeStamps = data?.[0] || [];
    const dataValues = data?.slice(1) || [];

    const valuesLabels = (series?.map((series) => series.label) || []).slice(1);

    const returnFormat = dataValues.reduce(
      (acc, dataValue, index) => {
        acc[valuesLabels[index]] = Array.from(dataValue);
        return acc;
      },
      {
        timeStamps,
      } as Record<string, number[]>,
    );
    return JSON.stringify({ ...returnFormat, activeChart }, null, 2);
  } catch (e) {
    return JSON.stringify({ error: e.message }, null, 2);
  }
};

export const jsonInDomForTest = (u: uPlot, activeChart: ChartType) => {
  const tooltipDiv = document.createElement('div');
  tooltipDiv.classList.add('uplot__test__json');
  tooltipDiv.classList.add('hidden');

  const json = readableJsonFromUplotChart({
    activeChart,
    data: u.data,
    series: u.series,
  });
  tooltipDiv.textContent = json;

  // if already exists, replace it
  const existingTooltipDiv = u.root.querySelector('.uplot__test__json');
  if (existingTooltipDiv) {
    existingTooltipDiv.textContent = json;
  } else {
    u.root.appendChild(tooltipDiv);
  }
};
