import { getLegacyLogsStateFromFiltersState } from 'hooks/useLogsState';
import { DateSelection, LogsMetricQueryProps, QueryLangType } from 'types';
import { validateLogqlQuery } from 'utils/LogqlBuilder';
import {
  buildLogqlFilter,
  getSelectedFacetParsed,
} from 'utils/LogqlBuilder/LogqlBuilder';
import {
  escapeLinesTabsAndQuotes,
  getIsComponentLabelStrict,
  getIsLogRangeFacet,
} from '..';
import { buildKfuseQlForFunction } from './kfuseBuilderFunction';

const getDataTypesForKfuseQl = (type: string) => {
  if (type.toLowerCase() === 'duration') {
    return 'duration_seconds';
  }
  return type.toLowerCase();
};

export const buildKfuseQl = ({
  customerFilter,
  date,
  queries,
  step = '1m',
  instant = false,
}: {
  customerFilter?: { key: string; value: string };
  date: DateSelection;
  queries: LogsMetricQueryProps[];
  step?: string;
  instant?: boolean;
}): { kfuseQl: string; operation: string }[] => {
  const kfuseQl: { kfuseQl: string; operation: string }[] = [];
  queries.forEach((query: LogsMetricQueryProps) => {
    let innerKfuseQl = '';
    const {
      filtersState,
      metric,
      rangeAggregate,
      rangeAggregateGrouping,
      rangeAggregateParam,
      limit,
    } = query;
    if (!validateLogqlQuery(query)) {
      kfuseQl.push({ kfuseQl: '', operation: '' });
      return;
    }

    const filters = filtersState
      ? getLegacyLogsStateFromFiltersState({ filtersState })
      : {
          filterOrExcludeByFingerprint: {},
          keyExists: query.filters.keyExists || {},
          filterByFacets: query.filters.filterByFacets || [],
          searchTerms: query.filters.searchTerms || [],
          selectedFacetRangeByName: {},
          selectedFacetValuesByName: query.filters.sidebarFilters || {},
        };

    const { labelFilter, pipelineFilter } = buildLogqlFilter({
      customerFilter,
      filters,
      metric,
      queryLangType: 'kfuseql',
    });

    const grepFilter = buildKfuseQlForGrep(filters.searchTerms);
    if (grepFilter) {
      innerKfuseQl = `${innerKfuseQl} ${innerKfuseQl ? '' : ''} ${grepFilter}`;
    }

    if (pipelineFilter) {
      innerKfuseQl = `${innerKfuseQl} ${innerKfuseQl ? 'and' : ''} ${pipelineFilter}`;
    }

    if (labelFilter && labelFilter !== '*') {
      innerKfuseQl = `${innerKfuseQl} ${innerKfuseQl ? 'and' : ''} ${labelFilter}`;
    }

    if (innerKfuseQl) {
      innerKfuseQl = escapeLinesTabsAndQuotes(innerKfuseQl);
    }

    if (!innerKfuseQl) {
      innerKfuseQl = '*';
    }

    if (!instant) {
      innerKfuseQl = `${innerKfuseQl} | timeslice ${step}`;
    }

    const groupBy = getGroupByForKfuseQl({
      rangeAggregateGrouping,
      rangeAggregate,
      rangeAggregateParam: Number(rangeAggregateParam),
      metric,
      instant,
    });
    if (groupBy.groupBy) {
      innerKfuseQl = `${innerKfuseQl} | ${groupBy.groupBy}`;
    }

    const functionsKfuseQl = buildKfuseQlForFunction({
      date,
      functions: query.functions,
      step,
      groupOperation: groupBy.groupOperation,
    });
    if (functionsKfuseQl.functionsKfuseQl) {
      innerKfuseQl = `${innerKfuseQl} | ${functionsKfuseQl.functionsKfuseQl}`;
    }

    if (functionsKfuseQl.operation) {
      groupBy.groupOperation = functionsKfuseQl.operation;
    }

    kfuseQl.push({ kfuseQl: innerKfuseQl, operation: groupBy.groupOperation });
  });

  return kfuseQl;
};

const getGroupByForKfuseQl = ({
  rangeAggregateGrouping,
  rangeAggregate,
  rangeAggregateParam,
  metric,
  instant = false,
}: {
  rangeAggregateGrouping: string[];
  rangeAggregate: string;
  rangeAggregateParam: number;
  metric: string;
  instant?: boolean;
}): { groupBy: string; groupOperation: string } => {
  let groupByOperation = '';
  let groupOperation = '';
  const groupBy: string[] = [];
  const { name, type, parsedMetric } = getSelectedFacetParsed(metric);

  if (!instant) {
    groupBy.push(`_timeslice`);
  }

  if (parsedMetric === 'logs') {
    groupByOperation = 'count';
    groupOperation = '_count';
  } else if (parsedMetric === 'fp') {
    groupByOperation = `count_unique(${name})`;
    groupOperation = '_count_unique';
  } else if (parsedMetric === 'label') {
    groupByOperation = `count_unique(${name})`;
    groupOperation = '_count_unique';
  } else if (name.startsWith('@')) {
    const isNumeric = getIsLogRangeFacet(type || '');
    if (isNumeric) {
      const mappedRangeAggregate = mapRangeAggregateForKfuseQl(
        rangeAggregate,
        rangeAggregateParam,
      );
      groupByOperation = `${mappedRangeAggregate}(${name}:${getDataTypesForKfuseQl(type)})`;
      groupOperation = `_${mappedRangeAggregate}`;
    } else {
      groupByOperation = `count_unique(${name})`;
      groupOperation = '_count_unique';
    }
  }

  if (rangeAggregateGrouping.length > 0) {
    rangeAggregateGrouping.forEach((facet) => {
      const { name, parsedMetric } = getSelectedFacetParsed(facet);
      let groupByLabel = name;
      if (
        parsedMetric === 'fp' ||
        parsedMetric === 'label' ||
        parsedMetric === 'logs'
      ) {
        groupByLabel = name;
      }

      if (parsedMetric === 'facet') {
        groupByLabel = name;
      }
      const isLabelAlreadyInGroupBy = groupBy.includes(groupByLabel);
      if (!isLabelAlreadyInGroupBy) {
        groupBy.push(groupByLabel);
      }
    });
  }

  if (instant && groupBy.length === 0) {
    groupBy.push(`__everything__`);
  }

  return {
    groupBy: `${groupByOperation} by (${groupBy.join(', ')})`,
    groupOperation,
  };
};

const mapRangeAggregateForKfuseQl = (
  rangeAggregate: string,
  rangeAggregateParam: number,
) => {
  switch (rangeAggregate) {
    case 'avg_over_time':
      return 'avg';
    case 'first_over_time':
      return 'first';
    case 'max_over_time':
      return 'max';
    case 'min_over_time':
      return 'min';
    case 'last_over_time':
      return 'last';
    case 'stddev_over_time':
      return 'stddev';
    case 'stdvar_over_time':
      return 'stdvar';
    case 'sum_over_time':
      return 'sum';
    case 'quantile_over_time':
      if (rangeAggregateParam === 0.5) {
        return 'p50';
      }
      if (rangeAggregateParam === 0.75) {
        return 'p75';
      }
      if (rangeAggregateParam === 0.9) {
        return 'p90';
      }
      if (rangeAggregateParam === 0.95) {
        return 'p95';
      }
      if (rangeAggregateParam === 0.99) {
        return 'p99';
      }
  }
};

const buildKfuseQlForGrep = (searchTerms: string[]) => {
  if (!searchTerms || searchTerms.length === 0) {
    return '';
  }

  return searchTerms.join(' and ');
};

export const getSanitizedFacetNameBasedOnQueryType = ({
  queryLangType,
  facetName,
  source,
}: {
  queryLangType: string;
  facetName: string;
  source: string;
}): string => {
  if (queryLangType === QueryLangType.LOGQL && facetName.startsWith('@')) {
    return facetName.slice(1);
  }

  // All component labels must be Core, Kubernetes, Cloud, Additional
  if (
    queryLangType === QueryLangType.KFUSEQL &&
    getIsComponentLabelStrict(source)
  ) {
    return facetName;
  }

  // Not every facet will have a @ prefix in kfuseql
  if (queryLangType === QueryLangType.KFUSEQL && !facetName.startsWith('@')) {
    return `@${facetName}`;
  }
  return facetName;
};
