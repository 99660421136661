import { TooltipTrigger } from 'components';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { MdClose as CloseIcon } from 'react-icons/md';
import { FaLock as LockIcon } from 'react-icons/fa6';

import { FolderPermissionCategory, FolderPermissionsProps } from './types';

const FolderManagementSettingsTableActions = ({
  permissionCategory,
  row,
  onRemoveMember,
}: {
  permissionCategory: FolderPermissionCategory;
  row: FolderPermissionsProps;
  onRemoveMember: (
    permission: FolderPermissionsProps,
    type: FolderPermissionCategory,
  ) => void;
}): ReactElement => {
  const isAdmin =
    permissionCategory === FolderPermissionCategory.ROLE &&
    row.builtInRole === 'Admin';
  return (
    <div
      className="table__row__actions"
      style={{ '--table-actions-width': '41px' }}
    >
      <div className="table__row__actions--hidden">
        <div className="table__row__actions__slider">
          <div className="alerts__contacts__table__actions">
            <div
              className={classNames({
                'table__cell__actions__item--red': !isAdmin,
                'table__cell__actions__item--blue': isAdmin,
              })}
              onClick={() =>
                !isAdmin && onRemoveMember(row, permissionCategory)
              }
            >
              <TooltipTrigger
                tooltip={isAdmin ? 'Admin role cannot be removed' : 'Remove '}
              >
                {isAdmin ? (
                  <LockIcon
                    className="alerts__contacts__table__actions__icon--blue"
                    size={17}
                  />
                ) : (
                  <CloseIcon
                    className="alerts__contacts__table__actions__icon--delete"
                    size={18}
                  />
                )}
              </TooltipTrigger>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FolderManagementSettingsTableActions;
