import { ServiceTableKpiKeys } from 'kfuse-constants';
import {
  DateSelection,
  EventListProps,
  PrometheusDataset,
  SelectedFacetValuesByName,
  SpanFilter,
} from 'types';
import { getServiceNamePropertyToSumBy } from 'utils';
import { Dispatch, SetStateAction } from 'react';

const getPropertyValue = ({ property, serviceName, value }) => {
  if (property === 'service_hash' || property === 'client_service_hash') {
    if (value === 'UNKNOWN' || value === 'EXTERNAL') {
      return `${value}:${serviceName}`;
    }
  }

  return value;
};

export const formatDatasets =
  ({
    eventList,
    key,
    isDb,
    property,
    setState,
  }: {
    eventList?: EventListProps[];
    key: string;
    isDb?: boolean;
    property: string;
    setState: Dispatch<SetStateAction<{ [key: string]: any }>>;
  }) =>
  (result: PrometheusDataset[]) => {
    setState((prevState) => {
      const nextState = { ...prevState };

      result.forEach((dataset) => {
        const { metric, value } = dataset;
        const serviceNamePropertyToSumBy =
          getServiceNamePropertyToSumBy(property);

        const serviceName = serviceNamePropertyToSumBy
          ? metric[serviceNamePropertyToSumBy]
          : '';

        const propertyValue = getPropertyValue({
          property,
          value: metric[property],
          serviceName,
        });

        if (!nextState[propertyValue]) {
          nextState[propertyValue] = {
            isDb,
          };
        }

        if (serviceNamePropertyToSumBy) {
          nextState[propertyValue]['service_name'] = serviceName;
        }

        if (value.length > 1) {
          nextState[propertyValue][key] =
            key === ServiceTableKpiKeys.apdex && isNaN(value[1] as number)
              ? 1
              : Number(value[1]);
        }

        const event = eventList?.find((event) => event.text === propertyValue);
        if (event) {
          nextState[propertyValue]['eventTime'] = event.timestamp;
        }
      });

      return nextState;
    });
  };

export const buildFilterFromFormValues = (
  formValues: {
    [key: string]: any;
  },
  spanFilter?: SpanFilter,
): string => {
  const result = Object.keys(formValues)
    .filter((key) => formValues[key])
    .map((key) => `${key}="${formValues[key]}"`);

  if (spanFilter && spanFilter !== SpanFilter.allSpans) {
    result.push(
      `${
        spanFilter === SpanFilter.serviceEntrySpans
          ? 'span_service_entry'
          : spanFilter
      }="true"`,
    );
  }

  return result.join(',');
};

export const buildSelectedFacetValuesByName = (formValues: {
  [key: string]: any;
}): SelectedFacetValuesByName =>
  Object.keys(formValues)
    .filter((key) => formValues[key])
    .reduce((obj, key) => ({ ...obj, [key]: { [formValues[key]]: 1 } }), {});

export const serviceKubeKeys = [
  'availability_zone',
  'region',
  'kube_cluster_name',
  'kube_namespace',
];

export const getTimeParameter = (date: DateSelection) => {
  const { endTimeUnix, startTimeUnix } = date;
  const diffInSeconds = endTimeUnix - startTimeUnix;
  return `${diffInSeconds}s`;
};

/*
 * Confirm with Ashish if anyone Changing the below function, This is a workaround for the issue in the backend
 */
export const transFormFacetNames = ({
  selectedFacetValuesByName,
  ignorePodNameAndSpanType = false,
}: {
  selectedFacetValuesByName: SelectedFacetValuesByName;
  ignorePodNameAndSpanType?: boolean;
}) => {
  const transformedFacetNames: SelectedFacetValuesByName =
    selectedFacetValuesByName;

  if (ignorePodNameAndSpanType) {
    delete transformedFacetNames['pod_name'];
    delete transformedFacetNames['span_type'];
  }

  return transformedFacetNames;
};

const ALLOWED_FACETS_FOR_EVENTS_API = [
  'kube_cluster_name',
  'env',
  'kube_namespace',
  'region',
  'service_name',
  'service_version',
  'environment',
];

export const getAllowedFacetsByName = (
  selectedFacetValuesByName: SelectedFacetValuesByName,
) => {
  if (!selectedFacetValuesByName) return {};
  const facetNames = Object.keys(selectedFacetValuesByName);
  const allowedFacetsByName: SelectedFacetValuesByName = {};
  for (const facetName of facetNames) {
    if (ALLOWED_FACETS_FOR_EVENTS_API.includes(facetName)) {
      if (facetName === 'service_version') {
        allowedFacetsByName['service_version'] =
          selectedFacetValuesByName[facetName];
      } else {
        allowedFacetsByName[facetName] = selectedFacetValuesByName[facetName];
      }
    }
  }
  return allowedFacetsByName;
};

export const addAsmMatcherClausesIfNotPresent = (clauses: string[] = []) => {
  const newClauses = [...clauses];
  const clausesTobeAdded: { [key: string]: number } = {
    availability_zone: 1,
    cloud_account_id: 1,
    env: 1,
    environment: 1,
    kube_cluster_name: 1,
    kube_namespace: 1,
    project: 1,
    region: 1,
    service_name: 1,
  };

  newClauses.forEach((clause) => {
    const [key] = clause.split('=');
    if (clausesTobeAdded[key]) {
      delete clausesTobeAdded[key];
    }

    const [key2] = clause.split('!');
    if (clausesTobeAdded[key2]) {
      delete clausesTobeAdded[key2];
    }
  });

  for (const key in clausesTobeAdded) {
    newClauses.push(`${key}=""`);
  }

  return newClauses;
};
