import * as React from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { LuCircle as Circle } from 'react-icons/lu';

import { cn } from 'utils/tailwind';
import exhaustiveSwitchError from 'utils/exhaustiveSwitchError';

const CompactRadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return <RadioGroupPrimitive.Root {...props} ref={ref} />;
});

const CompactRadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => (
  <RadioGroupPrimitive.Item
    ref={ref}
    className={cn(
      'w-4 h-4 rounded-full border border-interaction-primary hover:border-form-border-hover focus:border-interaction-primary focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-0',
      'data-state=checked:bg-primary data-state=checked:text-primary-foreground',
      className,
    )}
    {...props}
  >
    <RadioGroupPrimitive.Indicator
      className={cn('flex items-center justify-center')}
    >
      <div className="size-2 rounded-full bg-current" />
    </RadioGroupPrimitive.Indicator>
  </RadioGroupPrimitive.Item>
));

const DefaultRadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn('grid gap-2', className)}
      {...props}
      ref={ref}
    />
  );
});

const DefaultRadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        'aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
        <Circle className="size-2.5 fill-current text-current" />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  );
});

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> & {
    variant: 'default' | 'compact';
  }
>(({ className, ...props }, ref) => {
  const { variant } = props;

  switch (variant) {
    case 'default':
      return <DefaultRadioGroup ref={ref} {...props} />;
    case 'compact':
      return <CompactRadioGroup ref={ref} {...props} />;
    default:
      throw exhaustiveSwitchError(variant);
  }
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & {
    variant: 'default' | 'compact';
  }
>(({ className, ...props }, ref) => {
  const { variant } = props;
  switch (variant) {
    case 'default':
      return <DefaultRadioGroupItem ref={ref} {...props} />;
    case 'compact':
      return <CompactRadioGroupItem ref={ref} {...props} />;
    default:
      throw exhaustiveSwitchError(variant);
  }
});

RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem };
