import { MultiselectV2 } from 'components';
import React from 'react';
import { DateSelection } from 'types';
import { RumEvent, RumEventType } from '../types';
import RumFlamegraphFilterAutocomplete from './RumFlamegraphFilterAutocomplete';

const createAutocompleteConfig = () => [
  {
    label: 'Action Name',
    key: 'actionName',
    placeholder: 'All Action Names',
    isShown: (eventTypes: string[]) =>
      !eventTypes.length || eventTypes.includes(RumEventType.ACTION),
    options: (options: {
      actionNameOptions: { label: string; value: string }[];
    }) => [{ label: 'All', value: null }, ...options.actionNameOptions],
  },
  {
    label: 'Action Type',
    key: 'actionType',
    placeholder: 'All Action Types',
    isShown: (eventTypes: string[]) =>
      !eventTypes.length || eventTypes.includes(RumEventType.ACTION),
    options: (options: {
      actionTypeOptions: { label: string; value: string }[];
    }) => [{ label: 'All', value: null }, ...options.actionTypeOptions],
  },
  {
    label: 'Error Source',
    key: 'errorSource',
    placeholder: 'All Error Sources',
    isShown: (eventTypes: string[]) =>
      !eventTypes.length || eventTypes.includes(RumEventType.ERROR),
    options: (options: {
      errorSourceOptions: { label: string; value: string }[];
    }) => [{ label: 'All', value: null }, ...options.errorSourceOptions],
  },
  {
    label: 'Resource Type',
    key: 'resourceType',
    placeholder: 'All Resource Types',
    isShown: (eventTypes: string[]) =>
      !eventTypes.length || eventTypes.includes(RumEventType.RESOURCE),
    options: (options: {
      resourceTypeOptions: { label: string; value: string }[];
    }) => [{ label: 'All', value: null }, ...options.resourceTypeOptions],
  },
  {
    label: 'Resource Status',
    key: 'resourceStatus',
    placeholder: 'All Resource Status',
    isShown: (eventTypes: string[]) =>
      !eventTypes.length || eventTypes.includes(RumEventType.RESOURCE),
    options: (options: {
      resourceStatusOptions: { label: string; value: string }[];
    }) => [{ label: 'All', value: null }, ...options.resourceStatusOptions],
  },
  {
    label: 'Resource URL',
    key: 'resourceUrl',
    placeholder: 'All Resource URLs',
    isShown: (eventTypes: string[]) =>
      !eventTypes.length || eventTypes.includes(RumEventType.RESOURCE),
    options: (options: {
      resourceUrlOptions: { label: string; value: string }[];
    }) => [{ label: 'All', value: null }, ...options.resourceUrlOptions],
  },
];

const RumFlamegraphFilter = ({
  applicationFilter,
  activeRumEvent,
  dateFilter,
  eventType,
  eventTypes,
  flameGraphFacetFilters,
  setEventTypes,
  setFrameGraphFacetFilters,
}: {
  applicationFilter: string;
  activeRumEvent: RumEvent;
  dateFilter: DateSelection;
  eventType: RumEventType;
  eventTypes: RumEventType[];
  flameGraphFacetFilters: {
    actionName: string;
    actionType: string;
    errorSource: string;
    resourceType: string;
    resourceStatus: string;
    resourceUrl: string;
  };
  setEventTypes: (eventTypes: RumEventType[]) => void;
  setFrameGraphFacetFilters: (facetFilters: {
    actionName: string;
    actionType: string;
    errorSource: string;
    resourceType: string;
    resourceStatus: string;
    resourceUrl: string;
  }) => void;
}) => {
  const eventTypesOptions = [
    { label: 'Actions', value: RumEventType.ACTION },
    { label: 'Errors', value: RumEventType.ERROR },
    { label: 'Resources', value: RumEventType.RESOURCE },
    { label: 'Long Tasks', value: RumEventType.LONGTASK },
  ];

  const handleFilterChange = (key: string) => (value: string) => {
    setFrameGraphFacetFilters({
      ...flameGraphFacetFilters,
      [key]: value,
    });
  };

  const autocompleteConfig = createAutocompleteConfig();

  return (
    <div className="mr-2 flex flex-row justify-between">
      <div className="rum-flamegraph__filter">
        <div>
          <label>Event Types</label>
          <MultiselectV2
            className="autocomplete__fixed-height-28"
            components={{ ClearIndicator: null }}
            onChange={(values: RumEventType[]) => setEventTypes(values)}
            options={eventTypesOptions}
            placeholder={'All Event Types'}
            value={eventTypes}
          />
        </div>
        {autocompleteConfig
          .filter(({ isShown }) => isShown(eventTypes))
          .map(({ label, key, placeholder, options }) => (
            <RumFlamegraphFilterAutocomplete
              applicationFilter={applicationFilter}
              key={key}
              label={label}
              placeholder={placeholder}
              filterKey={key}
              activeRumEvent={activeRumEvent}
              dateFilter={dateFilter}
              value={
                flameGraphFacetFilters[
                  key as keyof typeof flameGraphFacetFilters
                ]
              }
              onChange={handleFilterChange(key)}
            />
          ))}
      </div>
    </div>
  );
};

export default RumFlamegraphFilter;
