import { useLiveTail } from 'hooks';
import React from 'react';
import { Play, Pause, RefreshCw } from 'react-feather';
import { DateSelection } from 'types';
import { Button } from './base';

type Props = {
  date: DateSelection;
  liveTail?: ReturnType<typeof useLiveTail>;
  setDate: (date: DateSelection) => void;
};

const DateControls = ({ date, liveTail, setDate }: Props) => {
  const isLiveTailEnabled = liveTail?.isEnabled;
  const isPlaying = liveTail?.isPlaying;
  const toggleLiveTail = liveTail?.toggleLiveTail || (() => {});

  const { endLabel } = date;
  const isRelativeTime = endLabel && endLabel.indexOf('now') === 0;
  const showRefreshButton = !isLiveTailEnabled;

  if (!isLiveTailEnabled && !showRefreshButton) {
    return null;
  }

  const onRefresh = () => {
    const { endTimeUnix, startTimeUnix } = date;
    const diffInSeconds = endTimeUnix - startTimeUnix;
    const nextEndTimeUnix = Math.floor(new Date().getTime() / 1000);
    const nextStartTimeUnix = nextEndTimeUnix - diffInSeconds;
    if (isRelativeTime) {
      setDate({
        endLabel: 'now',
        endTimeUnix: nextEndTimeUnix,
        startLabel: `now-${Math.floor(diffInSeconds / 60)}m`,
        startTimeUnix: nextStartTimeUnix,
      });
    } else {
      setDate({
        ...date,
        endTimeUnix,
        startTimeUnix,
      });
    }
  };

  return (
    <div className="date-controls" data-testid="refresh">
      {isLiveTailEnabled ? (
        <Button
          variant="icon-outline"
          size="sm"
          className="h-8"
          onClick={toggleLiveTail}
        >
          {isPlaying ? (
            <Pause className="date-controls__button__icon" size={16} />
          ) : (
            <Play className="date-controls__button__icon" size={16} />
          )}
        </Button>
      ) : null}
      {showRefreshButton ? (
        <Button
          variant="icon-outline"
          size="sm"
          className="h-8"
          onClick={onRefresh}
        >
          <RefreshCw size={16} />
        </Button>
      ) : null}
    </div>
  );
};

export default DateControls;
