/**
 * Replace dashboard template variables in promql
 * @param promql string
 * @param templating DashboardTemplateProps[]
 */
export const promqlVariableReplacer = (
  promql: string,
  variable: string,
  value: string,
): string => {
  promql = promql.replaceAll(`$${variable}`, value);
  promql = promql.replaceAll(`$\{${variable}}`, value);
  return promql;
};
