import React, { useMemo } from 'react';
import { getRules } from '../utils/selectorsfunction';

import { EntityTypes, RoleBinding } from '../types';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellTimestamp,
  KubeTableCell,
} from './Components';
import KubernetesTable from './KubernetesTable';
import { useEntitiesCount, useEntitiesData } from './useEntitiesData';

export default function KubernetesTableForRoleBinding() {
  const { entitiesType, facets, page, limit } = useKubernetesController();

  const filters = useMemo(() => [facets], [facets]);
  const [rows] = useEntitiesData<{ roleBinding: RoleBinding }>({
    filters,
    page,
    limit,
  });
  const [count] = useEntitiesCount({
    entitiesType,
    facets,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'kube_role_binding',
          label: 'Role Binding',
          renderCell(prop) {
            const roleBindingName = prop.row.roleBinding.metadata.name;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name pt-1.5 pb-1.5"
                style={{ maxWidth: 180 }}
                tooltipText={roleBindingName}
              >
                {roleBindingName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell(prop) {
            return <KubeCellCluster {...prop} />;
          },
        },
        {
          key: 'metadata.namespace',
          label: 'Namespace',
          renderCell(prop) {
            return <KubeCellNamespace {...prop} />;
          },
        },
        {
          key: 'kube_role',
          label: 'Role',
          renderCell(prop) {
            const bindingRole = prop.row.roleBinding.tags
              .find((t: string) => t.includes('kube_role'))
              .split(':')[1];
            return (
              <KubeTableCell
                {...prop}
                align="left"
                className="kubernetes__table__cell__entity-name"
                style={{ maxWidth: 240 }}
                tooltipText={bindingRole}
              >
                {bindingRole}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerSubjects',
          label: 'Subjects',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="left">
                {prop.row.roleBinding.subjects[0].kind}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'metadata.creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            return (
              <KubeCellTimestamp
                {...prop}
                timestamp={prop.row.roleBinding.metadata.creationTimestamp}
              />
            );
          },
        },
      ]}
    />
  );
}
