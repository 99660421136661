import { SelectedFacetRange } from 'types';
import { capitalize, escapePeriod, parseFacetKey } from 'utils';
import formatFacetName from './formatFacetName';

const getSelectedFacetRangeQuery = (
  facetKey: string,
  selectedFacetRange: SelectedFacetRange,
) => {
  const { component, name, type } = parseFacetKey(facetKey);
  const { isLowerInclusive, isUpperInclusive, lower, upper } =
    selectedFacetRange;
  const dataType = capitalize(type);

  const facetName = component
    ? formatFacetName(component, name, type)
    : `@:${dataType}.${escapePeriod(name)}`;

  let result = `{and: [`;

  if (typeof lower === 'number') {
    const operator = isLowerInclusive ? 'gte' : 'gt';
    result += `{${operator}: { facetName: "${facetName}", dataType: ${dataType}, value: "${lower}" }}`;
  }

  if (typeof upper === 'number') {
    const operator = isUpperInclusive ? 'lte' : 'lt';
    result += `{${operator}: { facetName: "${facetName}", dataType: ${dataType}, value: "${upper}" }}`;
  }

  result += `]}`;

  return result;
};

const buildSelectedFacetRange = (selectedFacetRanges: {
  [key: string]: SelectedFacetRange;
}): string => {
  const facetKeys = Object.keys(selectedFacetRanges);
  if (!facetKeys.length) {
    return '';
  }

  let result = '{ and: [';
  facetKeys.forEach((facetKey) => {
    result += getSelectedFacetRangeQuery(
      facetKey,
      selectedFacetRanges[facetKey],
    );
  });
  result += '] }';

  return result;
};

export default buildSelectedFacetRange;
