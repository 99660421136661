import classnames from 'classnames';
import React from 'react';
import * as Shadcn from '../shadcn';

type Props = {
  className?: string;
  count?: number;
};

const Skeleton = ({ className, count = 1 }: Props) => {
  return (
    <div
      className={classnames({
        skeleton: true,
        [className]: className,
      })}
    >
      {new Array(count).fill(null).map((_, i) => (
        <Shadcn.Skeleton key={i} className="skeleton__row" />
      ))}
    </div>
  );
};

export default Skeleton;
