import { Span } from 'types';

const tryUnmarshallSpan = (span: Span) => {
  try {
    const spanUnmarshalled = JSON.parse(span.span);
    return spanUnmarshalled;
  } catch (e) {
    return null;
  }
};

export default tryUnmarshallSpan;
