import { useSelectedFacetValuesByNameState } from 'hooks';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDateState } from 'screens/Logs/hooks';
import { ChartGridKeysState } from 'types';
import {
  extractParamsFromURL,
  extractPathNameFromURL,
  parseUrlParamByKey,
} from 'utils';

type Props = {
  apmDateState: ReturnType<typeof useDateState>;
  chartGridKeysState: ChartGridKeysState;
};

const getInitialSelectedFacetValues = (parsedParamValue) => {
  if (parsedParamValue && typeof parsedParamValue === 'object') {
    return parsedParamValue;
  }
  return { kf_source: { apm: 1 } };
};

const useServicesPageState = ({ apmDateState, chartGridKeysState }: Props) => {
  const navigate = useNavigate();

  const parsedCustomerFilter = parseUrlParamByKey('customerFilter');
  const parsedShowInactiveServices = parseUrlParamByKey('showInactiveServices');
  const [showInactiveServices, setShowInactiveServices] = useState(
    parsedShowInactiveServices,
  );
  const [customerFilter, setCustomerFilter] = useState<{
    key: string;
    value: string;
  }>(parsedCustomerFilter);

  const parsedParamValue = parseUrlParamByKey('servicesSelectedFacetValues');
  const initialSelectedFacetValues =
    getInitialSelectedFacetValues(parsedParamValue);

  const selectedFacetValuesByNameState = useSelectedFacetValuesByNameState({
    initialState: initialSelectedFacetValues,
    shouldWriteToUrl: false,
  });

  const date = apmDateState[0];

  // Get dependencies for determining when a url change should happen
  const dependenciesForWriteStateToUrl = useMemo(
    () => [
      customerFilter,
      date,
      selectedFacetValuesByNameState.state,
      showInactiveServices,
    ],
    [
      customerFilter,
      date,
      selectedFacetValuesByNameState.state,
      showInactiveServices,
    ],
  );

  // Should be called on state change when on the Traces page
  const writeStateToUrl = () => {
    const params = extractParamsFromURL();
    params.set('apmDate', JSON.stringify(apmDateState[0]));
    if (customerFilter) {
      params.set('customerFilter', JSON.stringify(customerFilter));
    }
    params.set('showInactiveServices', JSON.stringify(showInactiveServices));
    params.set(
      'servicesSelectedFacetValues',
      JSON.stringify(selectedFacetValuesByNameState.state),
    );
    const navigateUrl = `${extractPathNameFromURL()}?${params.toString()}`;
    navigate(navigateUrl, { replace: true });
  };

  return {
    apmDateState,
    chartGridKeysState,
    customerFilter,
    dependenciesForWriteStateToUrl,
    showInactiveServices,
    setShowInactiveServices,
    selectedFacetValuesByNameState,
    setCustomerFilter,
    writeStateToUrl,
  };
};

export default useServicesPageState;
