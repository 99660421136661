import classnames from 'classnames';
import { ProgressBar, useModalsContext } from 'components';
import React from 'react';
import { IoIosWarning } from 'react-icons/io';
import { Downloader } from 'types';
import { formatNumber } from 'utils';
import { Button } from './base';

const maxSizeInBytes = 1024 * 1024 * 10;

const formatBytes = (n: number) => {
  if (n > 1024 * 1024) {
    return `${(n / 1024 / 1024).toFixed(1)} MB`;
  }

  if (n > 1024) {
    return `${(n / 1024).toFixed(1)} KB`;
  }

  return `${n.toFixed(1)} Bytes`;
};

type Props = {
  downloader: Downloader;
  modals: ReturnType<typeof useModalsContext>;
  title: string;
};

const DownloadModal = ({ downloader, modals, title }: Props) => {
  const { state, stopAndDownload } = downloader;
  const { isDone, result, size } = state;

  const cancel = () => {
    modals.pop();
  };

  const isDownloadFailed = downloader.error;
  return (
    <div className="modal modal--small">
      <div className="modal__header">
        <div className="modal__header__text">{`Downloading ${title}`}</div>
      </div>
      <div className="modal__body logs-download-modal__body">
        {isDownloadFailed ? (
          <div className="w-full flex flex-col justify-center items-center py-3">
            <IoIosWarning
              className="overlay-message__icon-and-message__icon"
              size={60}
            />
            <div className="text-red-500">Error fetching data</div>
          </div>
        ) : (
          <>
            <div className="flex py-3">
              <div className="flex__left">{formatBytes(size)}</div>
              <div className="flex__right">
                {`${formatNumber(result.length)} ${title} downloaded`}
              </div>
            </div>
            <ProgressBar
              className={classnames({
                'logs-download-modal__progress-bar': true,
                'progress-bar--complete': isDone,
              })}
              percent={isDone ? 1 : size / maxSizeInBytes}
            />
          </>
        )}
      </div>
      <div className="modal__footer">
        <Button onClick={cancel} variant="outline" size="sm">
          {isDownloadFailed ? 'Close' : 'Cancel'}
        </Button>
        <Button
          className="ml-2"
          onClick={stopAndDownload}
          variant="default"
          size="sm"
          disabled={isDownloadFailed}
        >
          Stop and download immediately
        </Button>
      </div>
    </div>
  );
};

export default DownloadModal;
