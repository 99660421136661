export enum Compare {
  hour = 'hour',
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
}

export type LegendRow = {
  key: string;
  label: string;
  avg: number;
  min: number;
  max: number;
  sum: number;
};
