import { Separator } from 'components/shadcn';
import React, { ReactElement } from 'react';

import { alertTypeAlgorithm } from '../AlertsCreateMetrics/utils';
import { AlertsMetricsParsedProps } from '../types';

const AlertsDetailsOutliersParameters = ({
  parsed,
}: {
  parsed: AlertsMetricsParsedProps;
}): ReactElement => {
  const { outlierCondition } = parsed;
  return (
    <>
      <div className="alerts__details__properties__summary__item">
        <span className="text-sm font-bold text-text-secondary">
          Algorithm:{' '}
        </span>{' '}
        {alertTypeAlgorithm[outlierCondition.algorithm]}
      </div>
      <Separator orientation="horizontal" />
      <div className="alerts__details__properties__summary__item">
        <span className="text-sm font-bold text-text-secondary">
          Tolerance:{' '}
        </span>{' '}
        {outlierCondition.tolerance}
      </div>
    </>
  );
};

export default AlertsDetailsOutliersParameters;
