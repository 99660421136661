import {
  QueryCombinedStatusProps,
  QueryDataProps,
  QueryDataPropsRange,
} from 'types/QueryData';

import { DataFrame } from '../types';
import { combineRangeQueryData } from '../combineQueryData';
import {
  mergeTwoTimeSeries,
  timeseriesDataTransformer,
} from '../visualTransformer';
import forecastDataTransformer from './forecastDataTransformer';
import shiftForecastBandTimeseriesTimestamps from './shiftForecastBandTimeseriesTimestamps';

const hasMetaAdvanceFunction = (dataFrames: DataFrame[]) => {
  return dataFrames.some((dataFrame) => {
    const { meta } = dataFrame;
    return meta.isForecast || meta.isAnomaly || meta.isOutlier;
  });
};

const isForecast = (dataFrames: DataFrame[]) => {
  return dataFrames.some((dataFrame) => {
    const { meta } = dataFrame;
    return meta.isForecast;
  });
};

const promqlDashboardDataTransformer = (
  dataFrames: DataFrame[],
): QueryDataPropsRange => {
  const queryData: QueryDataProps = {};
  const queries: QueryCombinedStatusProps[] = [];

  const hasAdvanceFunction = hasMetaAdvanceFunction(dataFrames);
  if (hasAdvanceFunction) {
    if (isForecast(dataFrames)) {
      const [forecastFrame, lookbackFrame] = dataFrames;
      const { meta: forecastMeta } = forecastFrame;
      const lookbackData = timeseriesDataTransformer(lookbackFrame);

      const queryId = `query_${forecastMeta.refId}_forecast`;
      if (forecastMeta.forecastSeasonality) {
        const [upper, lower, mean] = forecastDataTransformer(forecastFrame);
        const { lowerShifted, upperShifted } =
          shiftForecastBandTimeseriesTimestamps({
            lower,
            upper,
            shiftByTimestamps: lookbackData.data[0],
          });
        const mergedData = mergeTwoTimeSeries({
          timeSeries1: lookbackData,
          timeSeries2: mean,
        });

        const dataArray = [upperShifted, lowerShifted, mergedData];
        const queryKeys = ['_upper', '_lower', ''];
        queryKeys.forEach((key, index) => {
          queryData[`${queryId}${key}`] = {
            isLoading: false,
            meta: forecastMeta,
            range: dataArray[index],
          };
        });
      } else {
        const forecastData = timeseriesDataTransformer(forecastFrame);
        const mergedData = mergeTwoTimeSeries({
          timeSeries1: lookbackData,
          timeSeries2: forecastData,
        });
        queryData[queryId] = {
          isLoading: false,
          meta: forecastMeta,
          range: mergedData,
        };
      }

      queries.push({ queryKey: forecastMeta.refId, isActive: true });
    }
  }

  if (!hasAdvanceFunction) {
    dataFrames.forEach((dataFrame) => {
      const data = timeseriesDataTransformer(dataFrame);
      const { meta } = dataFrame;

      const queryId = `query_${meta.refId}`;
      const type = meta.instant ? 'instant' : 'range';
      queryData[queryId] = { isLoading: false, meta, [type]: data };
      queries.push({ queryKey: meta.refId, isActive: true });
    });
  }

  const combinedDataRange = combineRangeQueryData({
    queryData,
    queries,
    formulas: [],
    combineTimestamp: true,
  });

  return combinedDataRange;
};

export default promqlDashboardDataTransformer;
