import { round } from 'lodash';
import dayjs from 'dayjs';
import { TimeUnit } from 'types';

export const formatNsAsDate = (ns: number) => {
  const unixTimestamp = formatNs(ns, TimeUnit.SECONDS);
  return dayjs.unix(unixTimestamp).format('MMM D HH:mm:ss');
};

export const formatNsWithRound = ({
  ns,
  unit,
  roundTo = 0,
}: {
  ns: number;
  unit: TimeUnit;
  roundTo: number;
}) => {
  const value = ns / valueOfUnit(unit);
  if (value % 1 === 0) {
    return Math.floor(value);
  }

  if (roundTo) {
    return round(value, roundTo);
  }

  return Math.round(value);
};

export const formatNs = (ns: number, unit: TimeUnit, toFixed = 0) => {
  const value = ns / valueOfUnit(unit);
  if (value % 1 === 0) {
    return Math.floor(value);
  }

  if (toFixed) {
    return value.toFixed(toFixed);
  }

  return Math.round(value);
};

export const valueOfUnit = (unit: TimeUnit): number => {
  switch (unit) {
    case TimeUnit.NANOSECONDS:
      return 1;
    case TimeUnit.MICROSECONDS:
      return 1000;
    case TimeUnit.MILLISECONDS:
      return 1000000;
    case TimeUnit.SECONDS:
      return 1000000000;
    case TimeUnit.MINUTES:
      return 60000000000;
    case TimeUnit.HOURS:
      return 3600000000000;
    default:
      return 0;
  }
};

export const getUnit = (
  diffInNs: number,
  thresholdMultiplier = 1,
): TimeUnit => {
  const units = [
    TimeUnit.HOURS,
    TimeUnit.MINUTES,
    TimeUnit.SECONDS,
    TimeUnit.MILLISECONDS,
    TimeUnit.MICROSECONDS,
  ];

  for (let i = 0; i < units.length; i += 1) {
    const unit = units[i];
    if (diffInNs >= thresholdMultiplier * valueOfUnit(unit)) {
      return unit;
    }
  }

  return TimeUnit.NANOSECONDS;
};

export const formatDurationNs = (
  duration: number,
  thresholdMultiplier = 3,
  toFixed = 0,
): string => {
  const unit = getUnit(duration, thresholdMultiplier);
  return `${formatNs(duration, unit, toFixed)}${unit}`;
};

export const formatDiffNs = (
  startTimeNs: number,
  endTimeNs: number,
): string => {
  const diffInNs = endTimeNs - startTimeNs;
  const unit = getUnit(diffInNs);
  return `${formatNs(diffInNs, unit)}${unit}`;
};

export const duration = (startTimeNs: number, endTimeNs: number): string => {
  const diffInNs = endTimeNs - startTimeNs;
  return `${formatNs(diffInNs, TimeUnit.MINUTES)}${TimeUnit.MINUTES}`;
};

export const lastRunDuration = (
  startTimeNs: number,
  endTimeNs: number,
): string => {
  const diffInNs = endTimeNs - startTimeNs;
  const unit = getUnit(diffInNs);
  if (unit == TimeUnit.MINUTES && formatNs(diffInNs, unit) > 60) {
    const hours = Math.floor(formatNs(diffInNs, unit) / 60);
    const minutes = formatNs(diffInNs, unit) % 60;
    return `${hours}h ${minutes}m`;
  }
  return `${formatNs(diffInNs, unit)}${unit}`;
};

export const ageDuration = (startTimeNs: number, endTimeNs: number): string => {
  const diffInNs = endTimeNs - startTimeNs;
  const unit = getUnit(diffInNs);
  if (unit == TimeUnit.MINUTES && formatNs(diffInNs, unit) > 60) {
    const hours = Math.floor(formatNs(diffInNs, unit) / 60);
    const minutes = formatNs(diffInNs, unit) % 60;
    return `${hours}h ${minutes}m`;
  }
  return `${Math.round(formatNs(diffInNs, unit) / 24)}${TimeUnit.HOURS}`;
};

export const calculateCapacity = (bytes: number): string => {
  if (bytes >= 0) {
    if (bytes >= 1000000000) {
      return (bytes / 1000000000).toFixed(2) + ' GB';
    } else {
      return (bytes / 1000000).toFixed(2) + ' MB';
    }
  }
};

export const timeToReadableText = (time: string): string => {
  const units = time.match(/[a-zA-Zμ]+/);
  if (!units) return '';

  const num = parseInt(time.slice(0, time.length - units[0].length), 10);

  if (isNaN(num)) {
    return '';
  }

  let unit;

  switch (units[0]) {
    case TimeUnit.SECONDS:
      unit = num === 1 ? 'second' : 'seconds';
      break;
    case TimeUnit.MINUTES:
      unit = num === 1 ? 'minute' : 'minutes';
      break;
    case TimeUnit.HOURS:
      unit = num === 1 ? 'hour' : 'hours';
      break;
    case TimeUnit.DAYS:
      unit = num === 1 ? 'day' : 'days';
      break;
    case TimeUnit.WEEKS:
      unit = num === 1 ? 'week' : 'weeks';
      break;
    case TimeUnit.MONTHS:
      unit = num === 1 ? 'month' : 'months';
      break;
    case TimeUnit.MILLISECONDS:
      unit = num === 1 ? 'millisecond' : 'milliseconds';
      break;
    case TimeUnit.MICROSECONDS:
      unit = num === 1 ? 'microsecond' : 'microseconds';
      break;
    case TimeUnit.NANOSECONDS:
      unit = num === 1 ? 'nanosecond' : 'nanoseconds';
      break;
    default:
      return '';
  }

  return `${num} ${unit}`;
};
