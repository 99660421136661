import { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getDateFromRange } from 'screens/Dashboard/utils';
import { FavoriteFacetExtend } from 'screens/LogsFacetExplorer/types';
import { extractPathNameFromURL, parseUrlParamByKey } from 'utils';

const useLogsFacetExplorerPageState = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dateState = useState(
    parseUrlParamByKey('logsFacetExplorerDate') ||
      getDateFromRange('now-1d', 'now'),
  );
  const initialSource = parseUrlParamByKey('logsFacetExplorerSource');
  const selectedSourceState = useState<string>(initialSource || '');

  const initialFacet = parseUrlParamByKey('logsFacetExplorerFacet');
  const selectedFacetState = useState<FavoriteFacetExtend>(
    initialFacet || undefined,
  );

  // Get dependencies for determining when a url change should happen
  const dependenciesForWriteStateToUrl = useMemo(
    () => [dateState[0], selectedSourceState[0], selectedFacetState[0]],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateState[0], selectedSourceState[0], selectedFacetState[0]],
  );

  // Should be called on state change when on the LogsCardinality page
  const writeStateToUrl = () => {
    params.set('logsFacetExplorerDate', JSON.stringify(dateState[0]));
    params.set(
      'logsFacetExplorerSource',
      JSON.stringify(selectedSourceState[0]),
    );
    params.set('logsFacetExplorerFacet', JSON.stringify(selectedFacetState[0]));
    const navigateUrl = `${extractPathNameFromURL()}?${params.toString()}`;
    navigate(navigateUrl, { replace: true });
  };

  return {
    dateState,
    selectedSourceState,
    selectedFacetState,
    dependenciesForWriteStateToUrl,
    writeStateToUrl,
  };
};

export default useLogsFacetExplorerPageState;
