import { TableColumn } from './types';

interface Args<T> {
  column: TableColumn<T>;
  row: T;
  doNotSplitKeysOfTable?: boolean;
}

const findValue = (
  key: string,
  obj: { [key: string]: any },
  doNotSplitKeysOfTable: boolean,
): any => {
  const keys = doNotSplitKeysOfTable ? [key] : key.split('.');
  if (keys.length === 1) {
    return obj[keys[0]];
  }

  if (keys[0] in obj) {
    return findValue(
      keys.slice(1).join('.'),
      obj[keys[0]],
      doNotSplitKeysOfTable,
    );
  }

  return undefined;
};

const getValue = <T>({ column, row, doNotSplitKeysOfTable }: Args<T>) => {
  const { key, value } = column;
  const v =
    typeof value === 'function'
      ? value({ row })
      : findValue(key, row, doNotSplitKeysOfTable);
  return v;
};

export default getValue;
