import { Zoom } from 'components/Flamegraph/types';
import { getNiceScale } from 'utils';
import { FLAME_GRAPH_TABLE_WIDTH } from './RumFlameGraph';
import { FormattedRumEventForFlameGraph } from './types';
import { RumEvent } from '../types';
import { convertMsToNs } from './utils';

const getFlameGraphConfig = ({
  activeRumEvent,
  formattedEvents,
  flameGraphChartContainerWidth,
  widthOfChartWithZoom,
  zoom,
}: {
  activeRumEvent: RumEvent;
  formattedEvents: FormattedRumEventForFlameGraph[];
  flameGraphChartContainerWidth: number;
  widthOfChartWithZoom: number;
  zoom: Zoom;
}) => {
  const maxEndTime = Math.max(
    ...formattedEvents.map((event) => event.endTimeNs),
  );
  const minStartTime = convertMsToNs(activeRumEvent.time);
  const diffInNs = maxEndTime - minStartTime;
  const min = 0;
  const max = zoom.scale < 1 ? Math.round(diffInNs / zoom.scale) : diffInNs;
  const maxForOverviewGraph = diffInNs;

  const minPresentationalDuration = Math.round(
    (10 * max) / widthOfChartWithZoom,
  );

  const minPresentationalDurationForOverview = Math.round(
    (10 * maxForOverviewGraph) /
      (flameGraphChartContainerWidth + FLAME_GRAPH_TABLE_WIDTH - 100),
  );

  const maxTicks = Math.floor(widthOfChartWithZoom / 100);
  const maxTicksForOverviewGraph = Math.floor(
    (flameGraphChartContainerWidth + FLAME_GRAPH_TABLE_WIDTH - 100) / 100,
  );
  const { niceUpperBound, tickSpacing } = getNiceScale(min, max, maxTicks);
  const {
    niceUpperBound: niceUpperBoundForOverview,
    tickSpacing: tickSpacingForOverview,
  } = getNiceScale(min, maxForOverviewGraph, maxTicksForOverviewGraph);

  return {
    minPresentationalDuration,
    minPresentationalDurationForOverview,
    minStartTimeNs: minStartTime,
    maxEndTimeNs: maxEndTime,
    niceUpperBound,
    niceUpperBoundForOverview,
    tickSpacing,
    tickSpacingForOverview,
  };
};

export default getFlameGraphConfig;
