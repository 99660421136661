import { fetchJson } from 'requests';
import { JobProgressResponseProps } from '../types';

const jobProgress = async (
  jobId: string,
): Promise<JobProgressResponseProps> => {
  const result = await fetchJson('/hydration/query/job-progress', {
    method: 'POST',
    body: JSON.stringify({ jobId }),
  });

  return result as unknown as JobProgressResponseProps;
};

export default jobProgress;
