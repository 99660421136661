import { FilterType, Operator } from 'hooks/useFiltersState';
import {
  DateSelection,
  LogsMetricForumlaProps,
  LogsMetricQueryProps,
  QueryLangType,
} from 'types';
import { getFacetKey, hasQueryAdvanceFunction } from 'utils';
import { AlertType } from 'screens/NewAlerts/types';

const getCustomerFilterForQuery = ({
  customerFilter,
}: {
  customerFilter: { key: string; value: string };
}) => {
  return customerFilter &&
    customerFilter.key &&
    customerFilter.value &&
    customerFilter.value !== 'All'
    ? [
        {
          type: FilterType.selectedFacetValue,
          value: {
            facet: getFacetKey({
              component: '',
              name: customerFilter.key,
              type: 'string',
            }),
            operator: Operator.equal,
            values: {
              [customerFilter.value]: 1,
            },
          },
        },
      ]
    : [];
};

const getMetricAlertTypeFromQuery = (query: LogsMetricQueryProps): string => {
  const { functions } = query;
  if (!functions || functions.length === 0) return AlertType.THRESHOLD;
  const lastFunction = functions[functions.length - 1];
  if (!lastFunction) return AlertType.THRESHOLD;

  const { isAnomaly, isForecast, isOutlier } = hasQueryAdvanceFunction(
    query,
    QueryLangType.KFUSEQL,
  );

  if (isAnomaly) return AlertType.ANOMALY;
  if (isOutlier) return AlertType.OUTLIERS;
  if (isForecast) return AlertType.FORECAST;

  return AlertType.THRESHOLD;
};

export const onCreateAlertLogs = ({
  customerFilter,
  date,
  queryItem,
  queryLangType,
}: {
  customerFilter?: { key: string; value: string };
  date: DateSelection;
  queryItem: {
    formulas?: LogsMetricForumlaProps[];
    queries: LogsMetricQueryProps[];
    queryIndex?: number;
  };
  queryLangType: QueryLangType;
}) => {
  const { formulas, queries } = queryItem;
  const queryIndex = 0;
  let newQueries = queries.map((query) => ({
    ...query,
    filters: [
      ...(query.filters || []),
      ...getCustomerFilterForQuery({ customerFilter }),
    ],
  }));
  newQueries = [newQueries[queryIndex]];
  if (newQueries.length === 1 && formulas.length === 0) {
    newQueries[0] = { ...newQueries[0], queryKey: 'a' };
  }
  if (formulas && formulas.length > 0) {
    newQueries = newQueries.map((query) => ({ ...query, isActive: false }));
  }

  const alertType = getMetricAlertTypeFromQuery(newQueries[queryIndex]);
  if (
    alertType === AlertType.ANOMALY ||
    alertType === AlertType.OUTLIERS ||
    alertType === AlertType.FORECAST
  ) {
    queries[queryIndex].functions.pop();
  }

  const ruleTypeEncoded = encodeURIComponent(JSON.stringify({ value: 'logs' }));
  const alertTypeEncoded = encodeURIComponent(
    JSON.stringify({ value: alertType }),
  );
  const encodeQueryURI = encodeURIComponent(JSON.stringify(newQueries));
  window.open(
    `/#/alerts/create?ruleType=${ruleTypeEncoded}&alertType=${alertTypeEncoded}&LogsMetricsQueries=${encodeQueryURI}&date=${encodeURIComponent(
      JSON.stringify(date),
    )}&LogsMetricsFormulas=${encodeURIComponent(JSON.stringify(formulas))}&queryLangType=${queryLangType}`,
    '_blank',
  );
  return;
};
