import classnames from 'classnames';
import { PopoverTriggerV2 } from 'components/PopoverTriggerV2';
import { useToggle } from 'hooks';
import React, { ReactElement, useRef, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import MultiselectPanel from './MultiselectPanel';
import MultiselectValue from './MultiselectValue';
import { MultiselectOption } from './types';
import { Input } from '../Input';
import { PanelPosition } from 'types/panel';

type Props = {
  allowCustomEntry?: boolean;
  className?: string;
  fullwidthPanel?: boolean;
  hideScrollbars?: boolean;
  hideValue?: boolean;
  onChange: (value: string[]) => void;
  options: MultiselectOption[];
  right?: boolean;
  top?: boolean;
  placeholder?: string;
  value: string[];
};

const getPosition = ({ right, top }) => {
  if (right && top) {
    return PanelPosition.TOP_RIGHT;
  }

  if (right) {
    return PanelPosition.BOTTOM_RIGHT;
  }

  if (top) {
    return PanelPosition.TOP_LEFT;
  }

  return PanelPosition.BOTTOM_LEFT;
};

const Multiselect = ({
  allowCustomEntry,
  className,
  fullwidthPanel = false,
  hideScrollbars = false,
  hideValue = false,
  onChange,
  options,
  right,
  top,
  placeholder,
  value,
}: Props): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const ref = useRef();
  const [typed, setTyped] = useState('');
  const openToggle = useToggle();

  const onClickHandler = (close: () => void, nextValue: string) => () => {
    onChange([...value, nextValue]);
    setTyped('');
    close();
    openToggle.off();
  };

  const onClick = () => {
    openToggle.on();
    inputRef.current.focus();
  };

  const onEnter = () => {
    if (allowCustomEntry && typed.trim()) {
      onChange([...value, typed]);
      setTyped('');
      close();
      openToggle.off();
    }
  };

  const onInputChange = (nextTyped: string) => {
    setTyped(nextTyped);
  };

  const removeHandler = (index: number) => (e: Event) => {
    e.stopPropagation();

    const nextValue = [...value];
    nextValue.splice(index, 1);
    onChange(nextValue);
  };

  return (
    <PopoverTriggerV2
      className={classnames({ select: true, [className]: className })}
      position={getPosition({ right, top })}
      popover={({ close }) => (
        <MultiselectPanel
          close={close}
          onClickHandler={onClickHandler}
          options={options}
          typed={typed}
          value={value}
        />
      )}
      onOpen={() => {
        setTimeout(() => {
          inputRef.current.focus();
        }, 50);
      }}
    >
      <div
        className={classnames({ multiselect: true, [className]: className })}
        ref={ref}
      >
        <div className="multiselect__trigger" onClick={onClick}>
          <div className="multiselect__trigger__inner flex flex-wrap">
            {hideValue ? null : (
              <MultiselectValue
                options={options}
                removeHandler={removeHandler}
                value={value}
              />
            )}
            <Input
              className={classnames({
                'm-auto h-full': true,
                multiselect__input: true,
                'multiselect__input--placeholder':
                  !openToggle.value && !value && placeholder,
                'multiselect__input--min-height': hideValue,
              })}
              onEnter={onEnter}
              onChange={onInputChange}
              placeholder={placeholder}
              ref={inputRef}
              type="text"
              value={openToggle.value || allowCustomEntry ? typed : ''}
            />
          </div>
          {openToggle.value ? (
            <ChevronUp className="multiselect__icon" size={16} />
          ) : (
            <ChevronDown className="multiselect__icon" size={16} />
          )}
        </div>
      </div>
    </PopoverTriggerV2>
  );
};

export default Multiselect;
