import fetchGrafanaApi from './fetchGrafanaApi';

const getGrafanaFolders = (uid?: string): Promise<any[] | any> => {
  const url = uid ? `grafana/api/folders/${uid}` : `grafana/api/folders`;
  const data = fetchGrafanaApi(url, {
    headers: { 'Content-Type': 'application/json' },
    method: 'GET',
  });

  return data;
};

export default getGrafanaFolders;
