import { uniq } from 'lodash';
import { SearchState } from 'hooks/useSearch/types';

import validateArithmeticFormulas from './validateArithmeticFormulas';

export const getUsedQueryKeysFromFormula = (formula: string): string[] => {
  const findUsedQuery = new RegExp('[a-z]', 'gi');
  const usedQueryKeys = formula.match(findUsedQuery);
  const uniqueUsedQueryKeys = uniq(usedQueryKeys);
  return uniqueUsedQueryKeys || [];
};

const validateTraceFormulaExpression = (
  formula: string,
  queries: SearchState[],
): boolean | string => {
  const queryVar = queries.map((query) => query.queryKey);
  const supportedOperators = ['+', '-', '*', '/'];

  if (
    !validateArithmeticFormulas({
      formula,
      queryVar,
      operators: supportedOperators,
      operatorRegex: '[+\\-*\\/]',
    })
  ) {
    return 'Invalid formula';
  }

  const findUsedQuery = new RegExp('[a-z]', 'gi');
  const usedQueryKeys = formula.match(findUsedQuery);
  if (!usedQueryKeys) return true;

  const groupBys = usedQueryKeys?.map((key) => {
    const query = queries.find((q) => q.queryKey === key);
    if (!query) return [];
    return query.groupBys;
  });

  if (!groupBys) return true;

  // check if all queries have same groupBy with each other
  const groupByZero = groupBys[0];
  const checkSameGroupBy = groupBys?.every(
    (groupBy) => groupBy.join(',') === groupByZero.join(','),
  );

  if (!checkSameGroupBy) {
    return 'Group by must be the same for all queries';
  }

  return true;
};

export default validateTraceFormulaExpression;
