import { findUnitCategoryFormatById } from 'utils/valueFormats';

const getEvaluatedValue = (
  value: number,
  unit: string,
  decimals: number,
): string => {
  const unitFormat = findUnitCategoryFormatById(unit);

  if (unitFormat) {
    const formatted = unitFormat.fn(Number(value), decimals);

    if (!formatted) return value.toString();
    return `${formatted.prefix || ''}${formatted.text}${
      formatted.suffix || ''
    }`;
  }
};

export default getEvaluatedValue;
