import { useHydrationPageStateContext } from 'context';
import { useLeftSidebarState } from 'components';
import { useRequest } from 'hooks';
import { useMemo, useState } from 'react';

import { ArchiveInfoProps, HydrationJobProps } from './types';
import { getArchivesList, getJobsList } from './requests';
import { filterHydrationJobs, filterHydrationArchives } from './utils';

export const useLogsHydrationState = () => {
  const {
    dateState,
    selectedFacetValuesByNameStateJobs,
    selectedFacetValuesByNameStateArchives,
  } = useHydrationPageStateContext();
  const [date, setDate] = dateState;
  const [jobList, setJobList] = useState<HydrationJobProps[]>([]);
  const [archivesList, setArchivesList] = useState<ArchiveInfoProps[]>([]);
  const getJobsListRequest = useRequest(getJobsList);
  const getArchivesListRequest = useRequest(getArchivesList, true, true);
  const leftSidebarState = useLeftSidebarState('logsHydration');
  const { hydrationProperties, filteredHydrationJobsList } = useMemo(() => {
    if (!jobList) {
      return {
        hydrationProperties: { name: [], status: [], tags: [] },
        filteredHydrationJobsList: [],
      };
    }

    const { formattedJobs, status, tags, name } = filterHydrationJobs(
      jobList,
      selectedFacetValuesByNameStateJobs.state,
    );

    return {
      hydrationProperties: { name, status, tags },
      filteredHydrationJobsList: formattedJobs,
    };
  }, [jobList, selectedFacetValuesByNameStateJobs.state]);

  const {
    hydrationPropertiesArchives,
    cloudType,
    filteredHydrationArchivesList,
  } = useMemo(() => {
    if (!archivesList) {
      return {
        hydrationPropertiesArchives: { name: [], status: [], tags: [] },
        filteredHydrationArchivesList: [],
      };
    }

    const { formattedArchives, cloudType, inconfig, incloud } =
      filterHydrationArchives(
        archivesList,
        selectedFacetValuesByNameStateArchives.state,
      );

    return {
      hydrationPropertiesArchives: { inconfig, incloud },
      filteredHydrationArchivesList: formattedArchives,
      cloudType,
    };
  }, [archivesList, selectedFacetValuesByNameStateArchives.state]);

  const getPredefinedFacetValuesJobs = (facetName: string) => () => {
    return new Promise((resolve) => {
      resolve(
        hydrationProperties[facetName as keyof typeof hydrationProperties] ||
          [],
      );
    });
  };

  const getPredefinedFacetValuesArchives = (facetName: string) => () => {
    return new Promise((resolve) => {
      resolve(
        hydrationPropertiesArchives[
          facetName as keyof typeof hydrationPropertiesArchives
        ] || [],
      );
    });
  };

  return {
    archivesList,
    cloudType,
    date,
    filteredHydrationJobsList,
    filteredHydrationArchivesList,
    getArchivesListRequest,
    getJobsListRequest,
    getPredefinedFacetValuesJobs,
    getPredefinedFacetValuesArchives,
    hydrationProperties,
    hydrationPropertiesArchives,
    leftSidebarState,
    selectedFacetValuesByNameStateJobs,
    selectedFacetValuesByNameStateArchives,
    setArchivesList,
    setDate,
    setJobList,
  };
};

export default useLogsHydrationState;
