import dayjs from 'dayjs';
import { EventListProps } from 'types/Event';
import uPlot from 'uplot';
import binarySearch from 'utils/binarySearch';

import { canvasPadding, getTooltipCanvasX } from './tooltipUtils';
import { drawCircleOnTimeseries, drawLineOnTimeseries } from './drawUtils';
import { themeColorDark, themeColorLight } from '../colors';

/**
 * `drawVersionPoints` is a function that draws version points on a uPlot chart.
 * It takes an object with three properties: `darkModeEnabled`, `eventList`, and `u`.
 * @param {boolean} darkModeEnabled - A boolean indicating whether dark mode is enabled.
 * @param {EventListProps[]} eventList - An array of events to be plotted on the chart.
 * @param {uPlot} u - The uPlot instance on which to draw the version points.
 */
export const drawVersionPoints = ({
  darkModeEnabled,
  eventList,
  u,
}: {
  darkModeEnabled: boolean;
  eventList: EventListProps[];
  u: uPlot;
}): void => {
  if (!eventList) return;
  const pointColor = darkModeEnabled
    ? themeColorDark.dark12
    : themeColorLight.light11;

  const padding = canvasPadding(u);
  const devicePixelRatio = window.devicePixelRatio || 1;
  const hashMap = new Map();
  eventList.forEach((event) => {
    const timestamps = u.data[0];
    const timestamp = dayjs(event.timestamp).unix();
    const pointIdx = binarySearch(timestamps, timestamp, true) as number | null;

    if (pointIdx === null) return;
    const leftX = getTooltipCanvasX(u, pointIdx);
    if (leftX === null) return;
    const bottomY = u.height * devicePixelRatio - padding.bottom;
    const topY = padding.top;

    drawVersionPointSingle({ bottomY, leftX, pointColor, topY, u });
    hashMap.set(pointIdx, { event, clientX: leftX, clientY: topY });
  });
  u.versionPoints = hashMap;
};

/**
 * `drawVersionPointSingle` is a function that draws a single version point on a uPlot chart.
 * It takes an object with five properties: `bottomY`, `leftX`, `pointColor`, `topY`, and `u`.
 * @param {number} bottomY - The y-coordinate of the bottom of the chart.
 * @param {number} leftX - The x-coordinate of the point to draw.
 * @param {string} pointColor - The color to use for the point.
 * @param {number} topY - The y-coordinate of the top of the chart.
 * @param {uPlot} u - The uPlot instance on which to draw the point.
 */
export const drawVersionPointSingle = ({
  bottomY,
  leftX,
  pointColor,
  topY,
  u,
}: {
  bottomY: number;
  leftX: number;
  pointColor: string;
  topY: number;
  u: uPlot;
}): void => {
  drawLineOnTimeseries({
    ctx: u.ctx,
    start: { x: leftX, y: topY },
    end: { x: leftX, y: bottomY },
    options: {
      color: pointColor,
      lineWidth: 2,
      lineStyle: 'dashed',
      dash: [5, 10],
    },
  });
  drawCircleOnTimeseries({
    ctx: u.ctx,
    x: leftX,
    y: topY,
    options: { color: pointColor, radius: 6 },
  });
};
