import React, { Fragment } from 'react';
import { Table, TableColumn, TableRow } from 'components';
import { KubeTableGroupExpanded } from './Components';
import { useSearchParams } from 'react-router-dom';
import { tryJsonParse } from 'utils';
import isEqual from 'lodash.isequal';
import { Presence } from '@radix-ui/react-presence';

type KubernetesTableGroupProps<TableRowProps> = {
  columns: Array<TableColumn<TableRowProps>>;
  rows: Array<TableRowProps>;
  renderExpandedRow: (prop: TableRowProps) => JSX.Element;
};

export default function KubernetesTableGroup<TableRowProps>({
  columns,
  rows,
  renderExpandedRow,
}: KubernetesTableGroupProps<TableRowProps>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const expandedRowSearchParam = tryJsonParse(
    searchParams.get('expandedGroup'),
  );

  const expandedRow = (
    searchParams.has('expandedGroup') && rows ? rows : []
  ).find((row) => {
    return isEqual(row.groupBys, expandedRowSearchParam);
  });

  const setExpandedRow = (row) => {
    setSearchParams((params) => {
      if (expandedRow === row) {
        params.delete('expandedGroup');
      } else {
        params.set('expandedGroup', JSON.stringify(row.groupBys));
      }

      return params;
    });
  };
  return (
    <Table
      className="border-x border-t"
      renderRow={(props, index: number) => {
        const isGroupRow =
          JSON.stringify(props.row) === JSON.stringify(expandedRow);

        return (
          <Fragment key={index}>
            <TableRow
              {...props}
              className="border"
              onRowClickHandler={(row) => {
                return (evt: React.MouseEventHandler<HTMLElement>) => {
                  // We only want to expand the row when not clicking on a filter chip
                  if (
                    evt.target === evt.currentTarget ||
                    !evt.target.textContent.trim()
                  ) {
                    setExpandedRow(row);
                  }
                };
              }}
            />
            <Presence present={isGroupRow}>
              <tr data-testid="table-expanded-row">
                <td colSpan={100}>{renderExpandedRow(props)}</td>
              </tr>
            </Presence>
          </Fragment>
        );
      }}
      rows={rows ?? []}
      columns={[
        {
          key: 'header',
          label: '',
          renderCell(prop: any) {
            return (
              <KubeTableGroupExpanded
                {...prop}
                isExpanded={isEqual(prop.row, expandedRow)}
              />
            );
          },
        },
        ...columns,
      ]}
    />
  );
}
