import React, { ReactElement, ReactNode } from 'react';
import { useModalsContext } from './context';
import Modal from './Modal';

const Modals = (): ReactElement => {
  const { pop, stack } = useModalsContext();

  if (stack.length) {
    return (
      <>
        {stack.map((component: ReactNode, key: React.Key) => (
          <Modal key={key} handleModalClose={pop}>
            {component}
          </Modal>
        ))}
      </>
    );
  }

  return null;
};

export default Modals;
