import { Button } from 'components';
import classnames from 'classnames';
import React from 'react';
import {
  BsArrowLeftShort as ArrowLeftShortIcon,
  BsArrowRightShort as ArrowRightShortIcon,
} from 'react-icons/bs';

import usePaginatorServerSide from './usePaginatorServerSide';
import { Select } from '../SelectV2';
import { getOptions, getSlicePageIndexes, resultsPerPageLimits } from './utils';

type Props = {
  paginator: ReturnType<typeof usePaginatorServerSide>;
};

const PaginatorServerSide = ({ paginator }: Props) => {
  const {
    pageNumber,
    showNextPage,
    showPrevPage,
    maxNumberOfPages,
    numberOfRowsPerPage,
    totalNumberOfRows,
    setActivePageIndex,
    setNumberOfRowsPerPage,
  } = paginator;
  const slicePageIndexes = getSlicePageIndexes({
    activePageIndex: pageNumber,
    maxNumberOfPages,
  });

  const onClickHandler = (index: number) => () => {
    setActivePageIndex(index);
  };

  if (totalNumberOfRows <= resultsPerPageLimits[0]) {
    return null;
  }

  return (
    <div className="paginator" data-testid={`paginator-current-${pageNumber}`}>
      <div className="paginator__label">Results per page:</div>
      <Select
        className="paginator__select"
        onChange={setNumberOfRowsPerPage}
        options={getOptions(numberOfRowsPerPage, totalNumberOfRows)}
        top
        value={numberOfRowsPerPage}
      />
      {numberOfRowsPerPage !== null && numberOfRowsPerPage !== 'all' ? (
        <div className="paginator__buttons">
          <Button
            data-testid="paginator-prev"
            variant="icon"
            size="xs"
            disabled={pageNumber === 0}
            className={classnames({
              'paginator__buttons__arrow--disabled': pageNumber === 0,
            })}
            onClick={showPrevPage}
          >
            <ArrowLeftShortIcon />
          </Button>
          {slicePageIndexes.map((index) => (
            <Button
              data-testid={`paginator-${index}`}
              variant="icon"
              size="xs"
              className={classnames({
                'paginator__buttons__number--active': index === pageNumber,
              })}
              key={index}
              onClick={onClickHandler(index)}
            >
              {index + 1}
            </Button>
          ))}
          <Button
            data-testid="paginator-next"
            variant="icon"
            size="xs"
            disabled={pageNumber === maxNumberOfPages - 1}
            className={classnames({
              'paginator__buttons__arrow--disabled':
                pageNumber === maxNumberOfPages - 1,
            })}
            onClick={showNextPage}
          >
            <ArrowRightShortIcon />
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default PaginatorServerSide;
