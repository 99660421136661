import React, { useMemo } from 'react';
import { getRules } from '../utils/selectorsfunction';

import { ClusterRole, EntityTypes } from '../types';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellTimestamp,
  KubeTableCell,
} from './Components';
import KubernetesTable from './KubernetesTable';
import { useEntitiesCount, useEntitiesData } from './useEntitiesData';

export default function KubernetesTableForClusterRole() {
  const { entitiesType, facets, page, limit } = useKubernetesController();

  const filters = useMemo(() => [facets], [facets]);
  const [rows] = useEntitiesData<{ clusterRole: ClusterRole }>({
    filters,
    page,
    limit,
  });
  const [count] = useEntitiesCount({
    entitiesType,
    facets,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'kube_cluster_role',
          label: 'Cluster Role',
          renderCell(prop) {
            const clusterRoleName = prop?.row.clusterRole?.metadata?.name;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name pt-1.5 pb-1.5"
                style={{ maxWidth: 220 }}
                tooltipText={clusterRoleName}
              >
                {clusterRoleName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell(prop) {
            return <KubeCellCluster {...prop} />;
          },
        },
        {
          key: 'headerRules',
          label: 'Rules',
          renderCell(prop) {
            const clusterRoleRules = getRules(prop?.row.clusterRole);
            return (
              <KubeTableCell
                {...prop}
                align="left"
                className="kubernetes__table__cell__entity-name"
                style={{ maxWidth: 360 }}
                tooltipText={clusterRoleRules}
              >
                {clusterRoleRules}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'metadata.creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            return (
              <KubeCellTimestamp
                {...prop}
                timestamp={prop.row.clusterRole.metadata.creationTimestamp}
              />
            );
          },
        },
      ]}
    />
  );
}
