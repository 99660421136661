import { LeftSidebar, useLeftSidebarState } from 'components/LeftSidebar';
import React from 'react';
import useServerlessState from 'screens/Serverless/hooks/useServerlessState';
import ServerlessSidebar from './ServerlessSidebar';

type Props = {
  serverlessState: ReturnType<typeof useServerlessState>;
  leftSidebarState: ReturnType<typeof useLeftSidebarState>;
};

const ServerlessSidebarWrapper = ({
  serverlessState,
  leftSidebarState,
}: Props) => {
  return (
    <LeftSidebar
      className="serverless__left-sidebar"
      leftSidebarState={leftSidebarState}
    >
      <ServerlessSidebar serverlessState={serverlessState} />
    </LeftSidebar>
  );
};

export default ServerlessSidebarWrapper;
