import { DateSelection } from 'types';
import { onPromiseError } from 'utils';

import fetchJson from './fetchJson';
import { formatCardinalityTimeseries } from './utils';

const metricsSeriesCardinality = ({
  date,
  format = 'none',
  instant = false,
  labels,
  matcher,
  rollUpSeconds,
}: {
  date?: DateSelection;
  format: 'timeseries' | 'none';
  instant: boolean;
  labels: string[];
  matcher?: string;
  rollUpSeconds?: number;
}): Promise<any> => {
  const newFormData = new FormData();

  if (date) {
    const { endTimeUnix, startTimeUnix } = date;
    const duration = endTimeUnix - startTimeUnix;
    newFormData.append('duration', instant ? '0' : duration.toString());
    newFormData.append(
      'rollUpSeconds',
      rollUpSeconds ? rollUpSeconds.toString() : duration.toString(),
    );
    newFormData.append('timestamp', endTimeUnix.toString());
  }
  newFormData.append('aggregation', 'distinctcount');

  if (labels && labels.length > 0) {
    labels.forEach((label) => newFormData.append('label', label));
  }

  let params = new URLSearchParams(newFormData).toString();
  if (matcher) {
    params = `${params}&match[]=${matcher}`;
  }

  return fetchJson(`/api/v1/cardinality/series?${params}`).then(
    (result: any) => {
      if (!result) return;
      if (format === 'none') return result[0] ? result[0] : [];
      if (format === 'timeseries')
        return formatCardinalityTimeseries(result, 'label');
    },
    onPromiseError,
  );
};

export default metricsSeriesCardinality;
