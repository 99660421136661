import React from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { DownloadType } from 'types';
import { Button } from './base';
import { PopoverPosition, PopoverTriggerV2 } from './PopoverTriggerV2';

type Props = {
  downloadTypes: DownloadType[];
  openModal: (downloadType: DownloadType) => void;
};

const DownloadPopover = ({ downloadTypes, openModal }: Props) => {
  const onClickHandler = (downloadType: DownloadType) => () => {
    openModal(downloadType);
  };
  return (
    <PopoverTriggerV2
      className="logs__table__toolbar__item"
      popover={() => (
        <div>
          {downloadTypes.map((downloadType) => (
            <button
              className="popover__panel__item"
              key={downloadType}
              onClick={onClickHandler(downloadType)}
            >{`Download as ${downloadType}`}</button>
          ))}
        </div>
      )}
      position={PopoverPosition.BOTTOM_RIGHT}
    >
      <Button variant="outline-secondary" size="xs" data-testid="download">
        <AiOutlineDownload className="button__icon" size={12} />
        <span>Download</span>
      </Button>
    </PopoverTriggerV2>
  );
};

export default DownloadPopover;
