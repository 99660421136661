import { delimiter } from 'kfuse-constants';
import { FilterType, Operator, SelectedFacetValueFilter } from './types';
import useFiltersState from './useFiltersState';

type Args = {
  filtersState: ReturnType<typeof useFiltersState>;
};

const getFiltersByFacets = ({ filtersState }: Args) => {
  const filters = filtersState.state.filter(
    (filter) =>
      filter.type === FilterType.selectedFacetValue &&
      filter.value.operator !== Operator.equal &&
      filter.value.operator !== Operator.notEqual,
  ) as SelectedFacetValueFilter[];

  return filters.map((filter) => {
    const [component, facetName, type] = filter.value.facet.split(delimiter);
    const facetString = `${type}${delimiter}${component}:${facetName}`;
    return `${facetString}${filter.value.operator}"${Object.keys(filter.value.values).join(' OR ')}"`;
  });
};

export default getFiltersByFacets;
