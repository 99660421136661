export type HydrationJobProps = {
  ArchiveAddTags: string;
  ArchiveDurationMs: number;
  ArchiveEndTsMs: number;
  ArchiveFilters: string;
  ArchiveName: string;
  ArchiveStartTsMs: number;
  CreatedAtMs: number;
  ErrorMsg: string | null;
  JobId: string;
  JobStatus: string;
  LastUpdatedAtMs: number;
  ShouldCancel: boolean;
  status: string; // Not part of the API response, but used for filtering
  name: string; // Not part of the API response, but used for filtering
  tags: string[]; // Not part of the API response, but used for filtering
};

export type HydrateFormProps = {
  archiveName: string;
  startTsMs: number;
  endTsMs: number;
  retentionDurationValue: number;
  retentionDurationUnit: string;
  filter: { key: string; value: string }[];
  addTags: { key: string; value: string }[];
};

export enum JobStatus {
  INIT = 'INIT',
  INDEXING = 'INDEXING',
  HYDRATING = 'HYDRATING',
  DONE = 'DONE',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export enum HydrationCloudType {
  S3 = 's3',
  GCS = 'gcs',
  AZURE = 'azure',
}

export type ArchiveInfoProps = {
  approxSizeBytes: number;
  ExistsInArchiveConfig: boolean;
  ExistsInBucket: boolean;
  filters: { Matcher: string; Operator: string; Value: string }[];
  FirstArchivedRecordUTCTsMs: number;
  FirstObjectCreatedUTCTsMs: number;
  index: boolean;
  location: string;
  name: string;
  Region: string;
  CloudType: HydrationCloudType;
  ins3: string; // Not part of the API response, but used for display
  inconfig: string; // Not part of the API response, but used for display
  incloud: string; // Not part of the API response, but used for display
};

export type JobProgressResponseProps = {
  TotalObjectsProcessed: number;
  TotalRecordsFiltered: number;
  TotalRecordsIndexed: number;
  TotalRecordsProcessed: number;
  jobId: string;
  jobStatus?: JobStatus;
  totalObjects: number;
};
