import { ReactNode, useState } from 'react';

const useModals = () => {
  const [state, setState] = useState({
    stack: [],
    shouldPreventClose: false,
  });

  const pop = () => {
    setState((prevState) => ({
      stack: prevState.stack.slice(0, prevState.stack.length - 1),
      shouldPreventClose: false,
    }));
  };

  const push = (component: ReactNode, shouldPreventClose = false) => {
    setState((prevState) => ({
      stack: [...prevState.stack, component],
      shouldPreventClose,
    }));
  };

  return {
    pop,
    push,
    shouldPreventClose: state.shouldPreventClose,
    stack: state.stack,
  };
};

export default useModals;
