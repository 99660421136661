import classnames from 'classnames';
import React from 'react';
import { clamp } from 'utils';

type Props = {
  className?: string;
  percent: number;
};

const ProgressBar = ({ className, percent }: Props) => {
  const clampedPercent = clamp(percent, 0, 1);
  return (
    <div
      className={classnames({ 'progress-bar': true, [className]: className })}
    >
      <div
        className="progress-bar__fill"
        style={{ transform: `scaleX(${clampedPercent})` }}
      />
    </div>
  );
};

export default ProgressBar;
