import { DateSelection } from 'types';
import { onPromiseError } from 'utils';

import fetchJson from './fetchJson';
import { formatCardinalityTimeseries } from './utils';

const metricsCardinality = ({
  date,
  instant,
  matchers,
  rollUpSeconds,
  format = 'none',
}: {
  date?: DateSelection;
  instant?: boolean;
  matchers?: string[];
  rollUpSeconds?: number;
  format?: 'timeseries' | 'none';
}): Promise<any> => {
  const newFormData = new FormData();

  if (date) {
    const { endTimeUnix, startTimeUnix } = date;
    const duration = endTimeUnix - startTimeUnix;
    newFormData.append('duration', instant ? '0' : duration.toString());
    newFormData.append(
      'rollUpSeconds',
      rollUpSeconds ? rollUpSeconds.toString() : duration.toString(),
    );
    newFormData.append('timestamp', endTimeUnix.toString());
  }

  let params = new URLSearchParams(newFormData).toString();
  if (matchers && matchers.length > 0) {
    const matchersParam = matchers.map((m) => `match[]=${m}`).join('&');
    params = `${params}&${matchersParam}`;
  }

  return fetchJson(`/api/v1/cardinality/metrics_series?${params}`).then(
    (result: any) => {
      if (!result) return;
      if (format === 'none') return result[0] ? result[0] : [];
      if (format === 'timeseries')
        return formatCardinalityTimeseries(result, 'metric');
    },
    onPromiseError,
  );
};

export default metricsCardinality;
