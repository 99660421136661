import dayjs from 'dayjs';
import { DateSelection } from 'types';
import { onPromiseError } from 'utils';

import fetchJson from './fetchJson';

const promqlLabelValues = ({
  date,
  label,
  matcher,
}: {
  date?: DateSelection;
  label: string;
  matcher?: string;
}): Promise<string[]> => {
  let dateParam = '';
  let matcherParam = '';
  if (date) {
    const { endTimeUnix, startTimeUnix } = date;
    const start = dayjs.unix(startTimeUnix).toISOString();
    const end = dayjs.unix(endTimeUnix).toISOString();
    dateParam = `&start=${start}&end=${end}`;
  }
  if (matcher) {
    matcherParam = `match[]=${matcher}`;
  }
  return fetchJson(
    `/api/v1/label/${label}/values?${matcherParam}${dateParam}`,
  ).then((result: { data: string[] }) => {
    if (!result?.data || !Array.isArray(result.data)) return [];
    return result.data.filter((label) => label !== '');
  }, onPromiseError);
};

export default promqlLabelValues;
