import dayjs from 'dayjs';
import {
  DateSelection,
  LogsMetricsAPIRequestProps,
  QueryDataPropsInstant,
  QueryDataPropsRange,
  TimeSeries,
} from 'types';
import {
  getAdjustedStartAndEndTimeUnix,
  getRollupByVisualization,
  onPromiseError,
  transformLogQL,
} from 'utils';

import query from './query';
import { formatLogsInstantQuery, formatLogsTimeseriesDataset } from './utils';

type Args = {
  date: DateSelection;
  format?: string;
  instant?: boolean;
  logQlQuery: LogsMetricsAPIRequestProps;
  width?: number;
};

const getLogMetricsTimeSeriesLogQL = async ({
  date,
  format,
  instant,
  logQlQuery,
  width,
}: Args): Promise<QueryDataPropsInstant | QueryDataPropsRange> => {
  const { limit, logQL, queryKey, step } = logQlQuery;
  const { startTimeUnix, endTimeUnix } = date;
  const stepMs = step || getRollupByVisualization(date, 'bar') * 1000;
  const adjusted = getAdjustedStartAndEndTimeUnix({
    date,
    step: `${stepMs}ms`,
  });
  const endTime = dayjs.unix(instant ? endTimeUnix : adjusted.endTimeUnix);

  const durationSecs = endTimeUnix - startTimeUnix;
  const durationMs = durationSecs * 1000;

  const logQLSanitized = transformLogQL(logQL, date, width);
  return query<TimeSeries[], 'getLogMetricsTimeSeries'>(`
    {
      getLogMetricsTimeSeries(
        durationMs: ${durationMs},
        ${logQL ? `logQL: "${logQLSanitized}",` : ''}
        stepMs: ${instant ? durationMs : stepMs}
        timestamp: "${endTime.format()}"
      ) {
        points {
          ts
          value
        }
        tags
      }
    }
  `).then((data) => {
    if (instant && format) {
      return formatLogsInstantQuery(
        { dataset: data.getLogMetricsTimeSeries, limit },
        queryKey,
        format,
      );
    }

    return formatLogsTimeseriesDataset({
      datasets: [{ dataset: data.getLogMetricsTimeSeries, limit }],
      date: instant ? date : adjusted,
      step: stepMs,
      queryKey,
    });
  }, onPromiseError);
};

export default getLogMetricsTimeSeriesLogQL;
