import { ApmServiceMapTooltip } from 'components';
import { useRequest } from 'hooks';
import React, { useEffect } from 'react';
import { queryRange } from 'requests';
import { DateSelection, Span } from 'types';
import { getTimeParameter } from '../utils';

type QueryArgs = {
  date: DateSelection;
  isUpstream: boolean;
  serviceHash: string;
  serviceName: string;
};

const getQueryValue = (serviceHash: string) => {
  if (serviceHash.indexOf('UNKNOWN') > -1) {
    return 'UNKNOWN';
  }

  if (serviceHash.indexOf('EXTERNAL') > -1) {
    return 'EXTERNAL';
  }

  return serviceHash;
};

const errorsQuery = ({
  date,
  isUpstream,
  serviceHash,
  serviceName,
}: QueryArgs) => {
  const key = isUpstream ? 'client_service_hash' : 'service_hash';
  const queryValue = getQueryValue(serviceHash);

  const serviceNameKey = isUpstream ? 'client_service_name' : 'service_name';

  const serviceNameFilterString =
    queryValue === 'EXTERNAL' || queryValue === 'UNKNOWN'
      ? `,${serviceNameKey}="${serviceName}"`
      : '';

  const timeParameter = getTimeParameter(date);
  return `sum by (${key}) (rate(edge_latency_count{error="true",${key}="${queryValue}"${serviceNameFilterString}}[${timeParameter}]))`;
};

const latencyQuery = ({
  date,
  isUpstream,
  serviceHash,
  serviceName,
}: QueryArgs) => {
  const key = isUpstream ? 'client_service_hash' : 'service_hash';
  const queryValue = getQueryValue(serviceHash);

  const serviceNameKey = isUpstream ? 'client_service_name' : 'service_name';

  const serviceNameFilterString =
    queryValue === 'EXTERNAL' || queryValue === 'UNKNOWN'
      ? `,${serviceNameKey}="${serviceName}"`
      : '';

  const timeParameter = getTimeParameter(date);
  return `max by (${key}) (max_over_time(edge_latency_max{${key}="${queryValue}"${serviceNameFilterString}}[${timeParameter}]))`;
};

const requestsQuery = ({
  date,
  isUpstream,
  serviceHash,
  serviceName,
}: QueryArgs) => {
  const key = isUpstream ? 'client_service_hash' : 'service_hash';
  const queryValue = getQueryValue(serviceHash);

  const serviceNameKey = isUpstream ? 'client_service_name' : 'service_name';

  const serviceNameFilterString =
    queryValue === 'EXTERNAL' || queryValue === 'UNKNOWN'
      ? `,${serviceNameKey}="${serviceName}"`
      : '';
  const timeParameter = getTimeParameter(date);
  return `sum by (${key}) (rate(edge_latency_count{${key}="${queryValue}"${serviceNameFilterString}}[${timeParameter}]))`;
};

const queryValue = (date: DateSelection, query: string) =>
  queryRange({ date, instant: true, query }).then((result) =>
    result.length && result[0].value.length > 1
      ? Number(result[0].value[1])
      : null,
  );

const queryValues = async ({ date, isUpstream, serviceHash, serviceName }) => {
  const values = await Promise.all([
    queryValue(
      date,
      errorsQuery({ date, isUpstream, serviceHash, serviceName }),
    ),
    queryValue(
      date,
      latencyQuery({ date, isUpstream, serviceHash, serviceName }),
    ),
    queryValue(
      date,
      requestsQuery({ date, isUpstream, serviceHash, serviceName }),
    ),
  ]);

  return {
    errors: values[0],
    latency: values[1],
    requests: values[2],
  };
};

type Props = {
  date: DateSelection;
  isUpstream?: boolean;
  name: string;
  serviceHash: string;
  serviceName: string;
};

const ServiceMapNodeTooltip = ({
  date,
  isUpstream,
  name,
  serviceHash,
  serviceName,
}: Props) => {
  const valuesRequest = useRequest(queryValues);

  const isLoading = valuesRequest.isLoading;
  const values = valuesRequest.result || {};

  useEffect(() => {
    valuesRequest.call({ date, isUpstream, serviceHash, serviceName });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ApmServiceMapTooltip isLoading={isLoading} title={name} values={values} />
  );
};

export default ServiceMapNodeTooltip;
