import React from 'react';
import { formatDurationNs } from 'utils';
import { RumEvent, RumEventType } from './types';

type Args = {
  event: RumEvent;
};

const getActionDescription = ({ event }: Args) => {
  const url = event.data['view.url'] ? new URL(event.data['view.url']) : null;
  return (
    <>
      <span className="font-bold">{event.data['action.type']}</span>
      <span>{' on '}</span>
      <span className="font-bold">{event.data['action.target.name']}</span>
      {url ? (
        <>
          <span>{' on '}</span>
          <span className="font-bold">
            {url.hash.split('?')[0].replace('#', '')}
          </span>
        </>
      ) : null}
    </>
  );
};

const getErrorDescription = ({ event }: Args) => {
  const errorMessage = event.data['error.message'];
  return (
    <>
      <span className="font-bold text-red-400">{errorMessage}</span>
    </>
  );
};

const getResourceDescription = ({ event }: Args) => {
  const type = event.data['resource.type'];
  const duration = event.data['resource.duration'];
  const url = event.data['resource.url'];

  return (
    <>
      <span>{`${type} `}</span>
      <span>{url}</span>
      <span>{` ${formatDurationNs(Number(duration) * 1000000)}`}</span>
    </>
  );
};

const labelByLoadingType = {
  initial_load: 'Load',
  route_change: 'SPA Route Change',
};

const getViewDescription = ({ event }: Args) => {
  const loadingType = event.data['view.loading_type'];
  const urlPath = event.data['view.url_path'];
  return (
    <>
      {`${labelByLoadingType[loadingType]} `}
      <span className="font-bold">{urlPath}</span>
    </>
  );
};

type Props = {
  event: RumEvent;
};

const RumReplayEventsItemDescription = ({ event }: Props) => {
  switch (event.eventType) {
    case RumEventType.ACTION:
      return getActionDescription({ event });
    case RumEventType.ERROR:
      return getErrorDescription({ event });
    case RumEventType.RESOURCE:
      return getResourceDescription({ event });
    case RumEventType.VIEW:
      return getViewDescription({ event });
    default:
      return null;
  }
};

export default RumReplayEventsItemDescription;
