import { fetchJson } from 'requests';
import { eventWithTime } from '@rrweb/types';

type Args = {
  segmentKey: string;
};

const getRrwebSegment = ({
  segmentKey,
}: Args): Promise<{ rrwebEvents: eventWithTime[] }> =>
  fetchJson(`/rum/rrwebsegments/fetch?segmentKey=${segmentKey}`);

export default getRrwebSegment;
