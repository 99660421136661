import { uniq } from 'lodash';
import { eventWithTime } from '@rrweb/types';
import { useRef, useState } from 'react';
import { getRrwebSegment } from './requests';
import { SessionMetadata, SnapshotMetadata } from './types';

type FetchEventsArgs = {
  segmentKey: string;
  tabId: string;
};

type Args = {
  session: SessionMetadata;
};

const useSegmentFetcher = ({ session }: Args) => {
  const [eventsBySegmentKeyByTabId, setEventsBySegmentKeyByTabId] = useState<
    Record<string, Record<string, eventWithTime[]>>
  >({});

  const fetchedSegmentBitmapRef = useRef<Record<string, 1>>({});

  const setBulkFetchedEvents = (
    bulkFetchedEvents: {
      tabId: string;
      segmentKey: string;
      rrwebEvents: eventWithTime[];
    }[],
  ) => {
    const filteredBulkFetchedEvents = bulkFetchedEvents.filter(
      (bulkFetchedEvent) => bulkFetchedEvent,
    );
    if (filteredBulkFetchedEvents.length) {
      setEventsBySegmentKeyByTabId((prevState) => ({
        ...filteredBulkFetchedEvents.reduce(
          (obj, { tabId, segmentKey, rrwebEvents }) => ({
            ...obj,
            [tabId]: {
              ...(obj[tabId] || {}),
              [segmentKey]: rrwebEvents,
            },
          }),
          { ...(prevState || {}) },
        ),
      }));
    }
  };

  const fetchEvents = ({ segmentKey, tabId }: FetchEventsArgs) => {
    return getRrwebSegment({ segmentKey }).then((result) => ({
      tabId,
      segmentKey,
      rrwebEvents: result.rrwebEvents,
    }));
  };

  const fetchEventsIfNeeded = ({ segmentKey, tabId }: FetchEventsArgs) =>
    new Promise((resolve) => {
      if (!fetchedSegmentBitmapRef.current[segmentKey]) {
        fetchedSegmentBitmapRef.current[segmentKey] = 1;
        return resolve(fetchEvents({ segmentKey, tabId }));
      }

      resolve(null);
    });

  const fetchEventsByTimeIfNeeded = (time: number) => {
    const fetchEventArgs = getFetchEventArgsByStartAndEnd({
      startTimeMs: time,
      endTimeMs: time + 30000,
    });
    return Promise.all(
      fetchEventArgs.map((fetchEventArg) => fetchEventsIfNeeded(fetchEventArg)),
    );
  };

  const fetchNextSnapshotByTimeIfNeeded = (timeMs: number) => {
    const fetchEventsBySnapshotArgs = getFetchSnapshotEventsArgsByTime(
      session.startTimeUnixMs + timeMs,
    );

    const fetchEventsBySnapshotArgsByTabId: Record<
      string,
      { tabId: string; snapshot: SnapshotMetadata; snapshotIndex: number }
    > = fetchEventsBySnapshotArgs.reduce(
      (obj, fetchEventsBySnapshotArg) => ({
        ...obj,
        [fetchEventsBySnapshotArg.tabId]: fetchEventsBySnapshotArg,
      }),
      {},
    );

    const { tabs } = session;
    const snapshotFetchEventArgs = tabs
      .map((tab) => {
        const { tabId } = tab;
        const snapshotIndex =
          fetchEventsBySnapshotArgsByTabId[tabId]?.snapshotIndex;
        const nextSnapshotIndex =
          typeof snapshotIndex === 'number' ? snapshotIndex + 1 : -1;
        const snapshot =
          nextSnapshotIndex > -1 ? tab.snapshots[nextSnapshotIndex] : null;

        return {
          tabId,
          snapshot: snapshot || null,
          snapshotIndex: nextSnapshotIndex,
        };
      })
      .filter((snapshotFetchEventArg) => snapshotFetchEventArg.snapshot);

    const fetchEventArgs = getSnapshotFetchEventsArgs(snapshotFetchEventArgs);

    return Promise.all(fetchEventArgs.map(fetchEventsIfNeeded));
  };

  const getFetchSnapshotEventsArgsByTime = (timeMs: number) => {
    const { tabs } = session;
    return tabs
      .map((tab) => {
        const { snapshots, tabId } = tab;
        const foundIndex = snapshots.findIndex((snapshot) => {
          return (
            timeMs >= snapshot.startTimeUnixMs &&
            timeMs < snapshot.endTimeUnixMs
          );
        });

        return {
          tabId,
          snapshot: foundIndex > -1 ? snapshots[foundIndex] : null,
          snapshotIndex: foundIndex,
        };
      })
      .filter((fetchEventsBySnapshotArg) => fetchEventsBySnapshotArg.snapshot);
  };

  const getSnapshotFetchEventsArgs = (
    fetchEventsBySnapshotArgs: { snapshot: SnapshotMetadata; tabId: string }[],
  ) => {
    return fetchEventsBySnapshotArgs.reduce(
      (arr, { snapshot, tabId }) => [
        ...arr,
        ...snapshot.segments.map((segment) => ({
          segmentKey: segment.segmentKey,
          tabId,
        })),
      ],
      [],
    );
  };

  const getSnapshotFetchEventsArgsByTime = (timeMs: number) => {
    const fetchEventsBySnapshotArgs = getFetchSnapshotEventsArgsByTime(timeMs);
    return getSnapshotFetchEventsArgs(fetchEventsBySnapshotArgs);
  };

  const getFetchEventArgsByStartAndEnd = ({ startTimeMs, endTimeMs }) => {
    const result: FetchEventsArgs[] = [];
    const startTimeUnixMs = session.startTimeUnixMs + startTimeMs;
    const endTimeUnixMs = session.startTimeUnixMs + endTimeMs;
    session.tabs.forEach((tab) => {
      const { snapshots } = tab;
      snapshots.forEach((snapshot) => {
        if (
          (snapshot.startTimeUnixMs >= startTimeUnixMs &&
            snapshot.startTimeUnixMs < endTimeUnixMs) ||
          (snapshot.endTimeUnixMs >= startTimeUnixMs &&
            snapshot.startTimeUnixMs < startTimeUnixMs)
        ) {
          snapshot.segments.forEach((segment) => {
            result.push({
              segmentKey: segment.segmentKey,
              tabId: tab.tabId,
            });
          });
        }
      });
    });

    return result;
  };

  const init = (startReplayAtUnixMs = 0) => {
    // fetch first and last snapshot of each tab
    const firstAndLastSnapshotFetchEventArgs = session.tabs.reduce(
      (arr, tab) => {
        const { snapshots, tabId } = tab;
        const firstSnapshot = snapshots.length ? snapshots[0] : null;
        const lastSnapshot =
          snapshots.length > 1 ? snapshots[snapshots.length - 1] : null;

        const fetchEventArgs = getSnapshotFetchEventsArgs([
          ...(firstSnapshot ? [{ snapshot: firstSnapshot, tabId: tabId }] : []),
          ...(lastSnapshot ? [{ snapshot: lastSnapshot, tabId: tabId }] : []),
        ]);
        return [...arr, ...fetchEventArgs];
      },
      [],
    );

    const snapshotFetchEventArgs =
      getSnapshotFetchEventsArgsByTime(startReplayAtUnixMs);

    const uniqueFetchEventArgs: FetchEventsArgs[] = uniq([
      ...snapshotFetchEventArgs,
      ...firstAndLastSnapshotFetchEventArgs,
    ]);

    return Promise.all(
      uniqueFetchEventArgs.map((fetchEventArg) =>
        fetchEventsIfNeeded(fetchEventArg),
      ),
    ).then(setBulkFetchedEvents);
  };

  const fetchAllSegments = () => {
    const fetchEventArgs = session.tabs.reduce((arr, tab) => {
      const { snapshots, tabId } = tab;
      return [
        ...arr,
        ...getSnapshotFetchEventsArgs(
          snapshots.map((snapshot) => ({ snapshot, tabId })),
        ),
      ];
    }, []);

    return Promise.all(
      fetchEventArgs.map((fetchEventArg) => fetchEventsIfNeeded(fetchEventArg)),
    ).then(setBulkFetchedEvents);
  };

  return {
    eventsBySegmentKeyByTabId,
    fetchAllSegments,
    fetchEventsByTimeIfNeeded,
    fetchNextSnapshotByTimeIfNeeded,
    init,
  };
};

export default useSegmentFetcher;
