import { canvasPadding } from './tooltipUtils';
import { syncDrawnOutlierSpan } from './drawOutlierSpan';
import {
  OutlierSpanProps,
  TooltipCoordsProps,
  UplotExtended,
} from 'types/Timeseries';

const APPROX_RADIUS = 5 * window.devicePixelRatio;

export const getNearestSpanPoint = (
  x: number,
  hashMapList: number[],
): number => {
  let nearestSpanPoint: number | undefined = undefined;
  hashMapList.forEach((left, index) => {
    const cursorAndSpanDiff = Math.abs(x - left);
    if (cursorAndSpanDiff < APPROX_RADIUS) {
      nearestSpanPoint = index;
    }
  });
  return nearestSpanPoint;
};

const getDrawnEventTooltip = (
  u: UplotExtended,
): TooltipCoordsProps & { spanData: OutlierSpanProps } => {
  const padding = canvasPadding(u);
  const devicePixelRatio = window.devicePixelRatio || 1;
  const outlierSpanData = u.eventsData?.outlierSpan?.data;
  if (!outlierSpanData) return;
  const hashMapList = Array.from(outlierSpanData.keys());
  let nearestSpanPoint:
    | { spanData: OutlierSpanProps; left: number }
    | undefined = undefined;
  const cursorLeft = u.cursor.left * devicePixelRatio + padding.left;
  // reset all spans to not focused
  hashMapList.forEach((left) => {
    const spanData = outlierSpanData.get(left);
    outlierSpanData.set(left, { ...spanData, isFocused: false });
  });
  // get the nearest span point
  const nearestSpanPointIndex = getNearestSpanPoint(cursorLeft, hashMapList);
  if (!nearestSpanPointIndex) {
    syncDrawnOutlierSpan({ outlierSpanMap: outlierSpanData, u });
    return;
  }

  // get the nearest span point data
  const nearestSpanPoinData = outlierSpanData.get(
    hashMapList[nearestSpanPointIndex],
  );
  nearestSpanPoint = {
    spanData: nearestSpanPoinData.span,
    left: hashMapList[nearestSpanPointIndex],
  };
  // set the nearest span point to focused
  outlierSpanData.set(nearestSpanPoint.left, {
    span: nearestSpanPoint.spanData,
    isFocused: true,
  });

  syncDrawnOutlierSpan({ outlierSpanMap: outlierSpanData, u });
  const bbox = u.root.getBoundingClientRect();
  const xAdjusted = nearestSpanPoint.left / devicePixelRatio;
  const yAdjusted = padding.top;
  const x = xAdjusted + bbox.left;
  const positionX = x < window.innerWidth / 2 ? 'right' : 'left';
  const xOffSet = positionX === 'right' ? 10 : -10;

  return {
    data: undefined,
    spanData: nearestSpanPoint?.spanData,
    positionX,
    positionY: 'bottom',
    x: x + xOffSet,
    y: yAdjusted + bbox.top,
  };
};

export default getDrawnEventTooltip;
