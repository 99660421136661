import React from 'react';
import { Span } from 'types';
import { formatDurationNs } from 'utils';

type Props = {
  diffInNs: number;
  isGhostSpan: boolean;
  span: Span;
};

const TraceSidebarMainWaterfallItemLabel = ({
  diffInNs,
  isGhostSpan,
  span,
}: Props) => {
  const formattedDuration = formatDurationNs(diffInNs, 1, 2);

  if (isGhostSpan) {
    return (
      <div className="trace-sidebar__main__waterfall__item__label">
        <div className="trace-sidebar__main__waterfall__item__time">
          {formattedDuration}
        </div>
        <div className="trace-sidebar__main__waterfall__item__name">
          Missing Span
        </div>
      </div>
    );
  }

  return (
    <div className="trace-sidebar__main__waterfall__item__label">
      <div className="trace-sidebar__main__waterfall__item__time">
        {formattedDuration}
      </div>
      <div className="trace-sidebar__main__waterfall__item__name">
        {span.name}
      </div>
      <div className="trace-sidebar__main__waterfall__item__method">
        {span.method}
      </div>
      <div className="trace-sidebar__main__waterfall__item__endpoint">
        {span.endpoint}
      </div>
    </div>
  );
};

export default TraceSidebarMainWaterfallItemLabel;
