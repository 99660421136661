import {
  inverseByOperator,
  isInclusiveByOperator,
  limitTypeByOperator,
} from 'kfuse-constants';
import { getWithoutQuotes } from './escapePeriod';
import { isNumber, isNumberWithUnit } from './isNumber';
import parseDurationToMs from './parseDurationToMs';
import { findFirstOperator } from './SearchBar';

export const getLastParsedValue = (s: string): string => {
  const parts = s.split(' OR ');
  return parts[parts.length - 1];
};

const getRangeClauses = ({
  firstOperator,
  firstOperatorIndex,
  typed,
  useNs,
}) => {
  const parseDuration = (s: string) =>
    parseDurationToMs(s) * (useNs ? 1000000 : 1);
  const firstPart = typed.substring(0, firstOperatorIndex);

  const secondPart = typed.substring(
    firstOperatorIndex + firstOperator.length,
    typed.length,
  );

  const isFirstPartNumber = isNumber(
    parseDuration(getWithoutQuotes(firstPart)),
  );

  const foundSecondOperator = isFirstPartNumber
    ? findFirstOperator(secondPart)
    : null;

  if (foundSecondOperator?.operator) {
    const { operator: secondOperator, operatorIndex: secondOperatorIndex } =
      foundSecondOperator;
    const firstValue = getWithoutQuotes(firstPart);
    const secondValue = getWithoutQuotes(
      secondPart.substring(secondOperatorIndex + secondOperator.length),
    );

    const isDuration =
      isNumberWithUnit(firstValue) || isNumberWithUnit(secondValue);

    return {
      facetName: secondPart.substring(0, secondOperatorIndex),
      isDuration,
      clauses: [
        {
          operator: inverseByOperator[firstOperator],
          original: firstValue,
          value: isDuration ? parseDuration(firstValue) : Number(firstValue),
        },
        {
          operator: secondOperator,
          original: secondValue,
          value: isDuration ? parseDuration(secondValue) : Number(secondValue),
        },
      ],
    };
  }

  const value = getWithoutQuotes(isFirstPartNumber ? firstPart : secondPart);
  const isDuration = isNumberWithUnit(value);
  return {
    facetName: isFirstPartNumber ? secondPart : firstPart,
    isDuration,
    clauses: [
      {
        operator: isFirstPartNumber
          ? inverseByOperator[firstOperator]
          : firstOperator,
        original: value,
        value: isDuration ? parseDuration(value) : Number(value),
      },
    ],
  };
};

export const getRange = ({
  firstOperator,
  firstOperatorIndex,
  useNs,
  typed,
}) => {
  const { isDuration, facetName, clauses } = getRangeClauses({
    firstOperator,
    firstOperatorIndex,
    typed,
    useNs,
  });

  const valueByLimitType: Record<'lower' | 'upper', any> = {
    lower: {
      isInclusive: false,
      value: null,
    },
    upper: {
      isInclusive: false,
      value: null,
    },
  };

  clauses.forEach((clause) => {
    const limitType = limitTypeByOperator[clause.operator];
    const value = valueByLimitType[limitType].value;
    if (limitType === 'lower') {
      if (value === null || clause.value < value) {
        valueByLimitType.lower.original = clause.original;
        valueByLimitType.lower.value = clause.value;
        valueByLimitType.lower.isInclusive =
          isInclusiveByOperator[clause.operator];
      }
    } else {
      if (value === null || clause.value > value) {
        valueByLimitType.upper.original = clause.original;
        valueByLimitType.upper.value = clause.value;
        valueByLimitType.upper.isInclusive =
          isInclusiveByOperator[clause.operator];
      }
    }
  });

  return {
    facetName,
    lower: valueByLimitType.lower.value,
    lowerOriginal: valueByLimitType.lower.original,
    isLowerInclusive: valueByLimitType.lower.isInclusive,
    upper: valueByLimitType.upper.value,
    upperOriginal: valueByLimitType.upper.original,
    isUpperInclusive: valueByLimitType.upper.isInclusive,
    isDuration,
  };
};

export const getRangeText = ({
  lower,
  upper,
  isLowerInclusive,
  isUpperInclusive,
  name,
}) => {
  if (lower !== null && upper !== null) {
    const lowerOperator = isLowerInclusive ? '<=' : '<';
    const upperOperator = isUpperInclusive ? '<=' : '<';
    return `${lower}${lowerOperator}${name}${upperOperator}${upper}`;
  }

  if (lower !== null) {
    const operator = isLowerInclusive ? '>=' : '>';
    return `${name}${operator}${lower}`;
  }

  if (upper !== null) {
    const operator = isUpperInclusive ? '<=' : '<';
    return `${name}${operator}${upper}`;
  }

  return '';
};
