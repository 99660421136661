export const themeColorDark = {
  dark01: '#18191c',
  dark02: '#202225',
  dark03: '#262729',
  dark04: '#292b2f',
  dark05: '#2f3136',
  dark06: '#36393f',
  dark07: '#393c43',
  dark08: '#3f4147',
  dark09: '#40444b',
  dark10: '#4f545c',
  dark11: '#72767d',
  dark12: '#8e9297',
};

export const themeColorLight = {
  light00: '#eeeef4',
  light01: '#f7f7f9',
  light02: '#efeff3',
  light03: '#e7e7ed',
  light04: '#dfdfe7',
  light05: '#d7d7e1',
  light06: '#cfcfd9',
  light07: '#c7c7d3',
  light08: '#bfbfcb',
  light09: '#b7b7c5',
  light10: '#afafbf',
  light11: '#9797a9',
};

export const ctaColor = {
  red: '#da545b',
  blue: '#3d8bc9',
  black: '#000000',
  white: '#ffffff',
  green: '#c3e29c',
  greenCyan: '#0e9f6e',
  yellow: '#ffd800',
  deepPurple: '#632ba6',
  lightPurple: '#916ac0',
  orange: '#d9914e',
};

export const themeBorderColorLight = {
  border01: '#c4c9cd',
  border02: '#dfe1e8',
  border03: '#ecedf1',
};

export const themeBorderColorDark = {
  border01: '#3b393c',
  border02: '#353935',
  border03: '#58638b',
};

export const themeHexagonColorDark = {
  fill: '#434343',
  border: '#aeaeae',
  outline: '#808080',
  hovered: '#434343',
};

export const themeHexagonColorLight = {
  fill: '#e9e9e9',
  border: '#8c8c8c',
  outline: '#000000',
  hovered: '#efefef',
};

export const anomalyBandColor = {
  dark: 'rgba(250, 255, 255, 0.1)',
  light: 'rgba(125, 125, 125, 0.2)',
};

export const chartThresholdBandColor = {
  dark: 'rgba(77, 0, 7, 0.3)',
  light: 'rgba(253, 134, 148, 0.3)',
};

export const chartThresholdBandColorGreen = {
  dark: 'rgba(0, 77, 7, 0.3)',
  light: 'rgba(34, 177, 76, 0.2)',
};

export const chartThresholdBandColorYellow = {
  dark: 'rgba(153, 153, 0, 0.3)',
  light: 'rgba(255, 204, 0, 0.2)',
};

export const heatmapAreaSelectionColor = {
  dark: 'rgba(185, 110, 40, 0.7)',
  light: 'rgba(185, 110, 40, 0.4)',
};

export const ctaColorRed = {
  dark: ctaColor.red,
  light: '#fa6c7c',
};

export const geoMapColor = {
  fill: '#D6D6DA',
  highlightedFill: '#579fdd',
  hover: '#4b86ee',
};

export const INACTIVE_COLOR_LIGHT = themeColorDark.dark05;
export const INACTIVE_COLOR_DARK = themeColorLight.light08;
