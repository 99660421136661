import { SelectedFacetValuesByName } from 'types';
import { buildPromQLFilterFromSelectedFacetValuesByName } from 'utils';

type Args = {
  rateInterval: string;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  stepInMs: number;
};

export enum ServerlessTableKpiKeys {
  invocations = 'invocations',
  duration = 'duration',
  errors = 'errors',
}

const serverlessTableKpis = [
  {
    key: ServerlessTableKpiKeys.invocations,
    label: 'Invocations',
    renderCell: ({ value }) => (value ? value.toLocaleString() : '0'),
    functionsQuery: ({ selectedFacetValuesByName, stepInMs }: Args): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName,
      });
      return `ceil(sum by (functionname, function_arn) (sum_over_time(aws_lambda_invocations${filter}[${stepInMs}ms])))`;
    },
  },
  {
    key: ServerlessTableKpiKeys.duration,
    label: 'Duration',
    renderCell: ({ value }) => (value ? value.toLocaleString() : '0'),
    functionsQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName,
      });
      return `ceil(max by (functionname, function_arn) (max_over_time(aws_lambda_duration_maximum${filter}[${stepInMs}ms])))`;
    },
  },
  {
    key: ServerlessTableKpiKeys.errors,
    label: 'Errors',
    renderCell: ({ value }) => (value ? value.toLocaleString() : '0'),
    functionsQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName,
      });
      return `ceil(max by (functionname, function_arn) (max_over_time(aws_lambda_errors${filter}[${stepInMs}ms])))`;
    },
  },
];

export default serverlessTableKpis;
