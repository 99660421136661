import { getLegacyFacetRegexState } from './getFacetRegexState';
import { getLegacyKeyExistsState } from './getKeyExistsState';
import { getLegacySelectedFacetRangeByNameState } from './getSelectedFacetRangeByNameState';
import { getLegacySelectedFacetValuesByNameState } from './getSelectedFacetValuesByNameState';
import { Filter } from './types';

const getLegacyFiltersFromFiltersState = (filters: Filter[]) => {
  return {
    facetRegex: getLegacyFacetRegexState(filters),
    keyExists: getLegacyKeyExistsState(filters),
    selectedFacetRangeByName: getLegacySelectedFacetRangeByNameState(filters),
    selectedFacetValuesByName: getLegacySelectedFacetValuesByNameState(filters),
  };
};

export default getLegacyFiltersFromFiltersState;
