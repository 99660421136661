import React, { ReactElement } from 'react';
import { Column as FlexColumn } from 'components/layouts/Flex';
import { Box } from 'components/layouts/Box';

const KubernetesTagsPopover = ({
  onExclude,
  onFilter,
  onCopyToClipboard,
  tag,
}: {
  onExclude: (tag: string) => void;
  onFilter: (tag: string) => void;
  onCopyToClipboard: (tag: string) => void;
  tag: string;
}): ReactElement => {
  return (
    <Box p="2" asChild>
      <FlexColumn>
        {onFilter && (
          <Box p="2" asChild>
            <button
              type="button"
              data-testid={`filter ${tag}`}
              className="font-medium text-2xs hover:bg-sky-700 hover:text-white"
              onClick={() => onFilter(tag)}
            >
              Filter by {tag}
            </button>
          </Box>
        )}
        {onExclude && (
          <Box p="2" asChild>
            <button
              type="button"
              data-testid={`exclude ${tag}`}
              className="font-medium text-2xs hover:bg-sky-700 hover:text-white"
              onClick={() => onExclude(tag)}
            >
              Exclude {tag}
            </button>
          </Box>
        )}
        {onCopyToClipboard && (
          <Box p="2" asChild>
            <button
              type="button"
              data-testid={`copy ${tag}`}
              className="font-medium text-2xs hover:bg-sky-700 hover:text-white"
              onClick={() => onCopyToClipboard(tag)}
            >
              Copy to Clipboard
            </button>
          </Box>
        )}
      </FlexColumn>
    </Box>
  );
};

export default KubernetesTagsPopover;
