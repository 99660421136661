import { TableColumn } from 'components';
import { saveAs } from 'file-saver';
import { useRequest } from 'hooks';
import { MAX_LOG_OR_TRACE_DOWNLOAD_SIZE } from 'kfuse-constants';
import { useState } from 'react';
import { getLogsV2 } from 'requests';
import { DownloaderState, Downloader } from 'types';
import { getFileExtension, getFormatter } from 'utils';

type Args = {
  columns: TableColumn<any>;
  onDone?: VoidFunction;
  downloadType: string;
};

const useLogsDownloader = (args: Args): Downloader => {
  const { columns, downloadType, onDone } = args;
  const [state, setState] = useState<DownloaderState>({
    isDone: false,
    result: [],
    size: 0,
  });

  const getLogsRequest = useRequest(getLogsV2);
  const fileExtension = getFileExtension(downloadType);
  const format = getFormatter(downloadType);

  const download = (s: string) => {
    const blob = new Blob([s], {
      type: 'text/plain;charset=utf-8',
    });

    if (onDone) {
      onDone();
    }

    saveAs(blob, `logs.${fileExtension}`);
  };

  const fetchLogs = ({ cursor, date, logsState }) => {
    getLogsRequest
      .call({
        cursor,
        date,
        limit: 2000,
        sort: {
          sortBy: 'timestamp',
          sortOrder: 'Asc',
        },
        logsState,
      })
      .then((result) => {
        const resultCursor = result.cursor || null;
        const resultEvents = result.events || [];

        setState((prevState) => {
          const resultEventsMapped = resultEvents
            .filter((event) => event)
            .map((item) => {
              return {
                ...item,
                message: item.logLine,
                extraFacets: item.facets,
                fpPattern: item.fpString,
              };
            });
          const nextResult = [...prevState.result, ...resultEventsMapped];
          const nextResultJsonString = format({ columns, rows: nextResult });
          const size = new TextEncoder().encode(nextResultJsonString).length;
          const sizeInKiloBytes = size / 1024;
          const sizeInMegaBytes = sizeInKiloBytes / 1024;

          if (
            sizeInMegaBytes > MAX_LOG_OR_TRACE_DOWNLOAD_SIZE ||
            resultCursor === null
          ) {
            download(nextResultJsonString);

            return {
              isDone: true,
              size,
              result: nextResult,
            };
          }

          if (resultCursor) {
            fetchLogs({ cursor: resultCursor, date, logsState });
          }

          return {
            isDone: false,
            size,
            result: nextResult,
          };
        });
      });
  };

  const stopAndDownload = () => {
    const string = format({ columns, rows: state.result });
    download(string);
  };

  return {
    fetch: fetchLogs,
    isLoading: getLogsRequest.isLoading,
    state,
    stopAndDownload,
  };
};

export default useLogsDownloader;
