import { Checkbox, useThemeContext } from 'components';
import { useAuth } from 'hooks';
import React, { FC, ReactElement } from 'react';
import { User } from 'types/user';

type Props = {
  auth: ReturnType<typeof useAuth>;
};

const HeaderUserPanel: FC<Props> = ({ auth }: Props): ReactElement => {
  const { darkModeEnabled, toggleDarkMode } = useThemeContext();
  const user = auth.user as User;
  const email = user?.email;
  const login = user?.login;
  const role = user?.role;

  const onClickLogout = async () => {
    auth.logout();
  };

  return (
    <div className="header__user__panel">
      <div className="header__user__panel__item">
        <div className="header__user__panel__item__label">Dark Mode</div>
        <div className="header__user__panel__item__value">
          <Checkbox onChange={toggleDarkMode} value={darkModeEnabled} />
        </div>
      </div>
      {auth.authorityType === 'none' ? null : (
        <>
          <div className="header__user__panel__item">
            <div className="header__user__panel__item__label">
              <span>Role: </span>
              <span>{role}</span>
            </div>
          </div>
          <div className="header__user__panel__item">
            <div className="header__user__panel__item__label">
              <button className="link" onClick={onClickLogout}>
                <span>Logout</span>
                <span className="header__user__panel__item__label__username">{` (${email || login})`}</span>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HeaderUserPanel;
