import {
  AutocompleteV2,
  Button,
  InputWithValidatorV3,
  useToaster,
} from 'components';
import { Separator } from 'components/shadcn';
import { useRequest } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { parseUrlParamByKey } from 'utils/url';

import PolicyManagementEditFilter from './PolicyManagementEditFilter';
import { createPolicy, getPolicyByName, updatePolicyByName } from './requests';
import { PolicyScopeFilterProps } from './types';
import { policyTypeOptions } from './utils';

const PolicyManagementEdit = () => {
  const { addToast } = useToaster();
  const { policyId } = useParams();
  const newParam = parseUrlParamByKey('new');
  const isNewPolicy = policyId === 'new' && newParam === true;
  const navigate = useNavigate();

  const [policy, setPolicy] = useState<{
    name: string;
    nameErr: string;
    type: string;
  }>({ name: '', nameErr: '', type: 'custom' });
  const [scopeFilters, setScopeFilters] = useState<PolicyScopeFilterProps[]>([
    { key: '', op: '=', value: '' },
  ]);
  const policyRequestByName = useRequest(getPolicyByName);
  const createPolicyRequest = useRequest(createPolicy);
  const updatePolicyRequest = useRequest(updatePolicyByName);

  const onSavePolicy = () => {
    if (!policy.name) {
      addToast({ text: 'Policy name is required', status: 'error' });
      setPolicy({ ...policy, nameErr: 'Policy name is required' });
      return;
    }
    if (isNewPolicy) {
      createPolicyRequest
        .call({
          name: policy.name,
          scope: {
            filters: policy.type === 'custom' ? scopeFilters : undefined,
            type: policy.type,
          },
        })
        .then(() => {
          addToast({ text: 'Policy created successfully', status: 'success' });
          navigate('/admin/policies');
        });
    } else {
      updatePolicyRequest
        .call({
          name: policy.name,
          policy_id: policyId,
          scope: {
            filters: policy.type === 'custom' ? scopeFilters : undefined,
            type: policy.type,
          },
        })
        .then(() => {
          addToast({ text: 'Policy updated successfully', status: 'success' });
          navigate('/admin/policies');
        });
    }
  };

  useEffect(() => {
    if (isNewPolicy) return;
    policyRequestByName.call(policyId).then((res) => {
      if (res) {
        setScopeFilters(res.scope?.filters || []);
        setPolicy({ ...policy, name: res.name, type: res.scope?.type });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewPolicy]);

  const isLoading =
    updatePolicyRequest.isLoading ||
    createPolicyRequest.isLoading ||
    policyRequestByName.isLoading;
  return (
    <div className="mx-auto max-w-[1200px] py-4">
      <div className="flex items-center justify-between pb-2">
        <h2 className="text-lg font-semibold">
          {isNewPolicy ? 'Create' : 'Edit'} Policy
        </h2>
      </div>
      {isLoading ? (
        <div className="admin__row-muted flex min-h-[200px] items-center justify-center">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <div className="flex w-1/2 flex-col pt-2">
            <label className="flex flex-col gap-1">
              <span>
                Name (<span className="text-red-500">*</span>)
              </span>
              <InputWithValidatorV3
                size="4"
                type="text"
                variant="default"
                placeholder="Please enter policy name"
                value={policy.name}
                onChange={(val) => setPolicy({ ...policy, name: val })}
                error={policy.nameErr}
                errorMessageShownType="inline"
              />
            </label>
          </div>
          <div className="flex w-1/2 flex-col pb-4 pt-2">
            <label className="flex flex-col gap-1">
              <span>
                Type (<span className="text-red-500">*</span>)
              </span>
              <AutocompleteV2
                options={policyTypeOptions}
                value={policy.type}
                onChange={(val: string) => setPolicy({ ...policy, type: val })}
              />
            </label>
          </div>
          {policy.type === 'custom' && (
            <>
              <div className="pb-1 font-semibold">Scope</div>
              <PolicyManagementEditFilter
                scopeFilters={scopeFilters}
                setScopeFilters={setScopeFilters}
              />
            </>
          )}
          <Separator />
          <div className="flex items-center justify-end pt-4">
            <Button variant="default" size="sm" onClick={onSavePolicy}>
              Save Policy
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default PolicyManagementEdit;
