import { SelectedFacetValuesByName } from 'types';
import { first } from 'lodash';

enum KubeFacetType {
  TAG = 'TAG',
  LABEL = 'LABEL',
  ANNOTATION = 'ANNOTATION',
}

type KubeFacetSelector = {
  type: KubeFacetType;
  key: string;
  value: string;
};

type KubeSelector = {
  id?: string;
  name?: string;
  facet?: KubeFacetSelector;
};

type KubeFilter = {
  eq?: KubeSelector;
  neq?: KubeSelector;
  and?: KubeFilter[];
  or?: KubeFilter[];
};

const equalExpression = (selector: KubeSelector): KubeFilter => {
  return { eq: selector };
};

const facetValueExpression = (facet: KubeFacetSelector): KubeSelector => {
  return { facet };
};

const andExpressions = (expressions: KubeFilter[]): KubeFilter => {
  return { and: expressions };
};

const orExpressions = (expressions: KubeFilter[]): KubeFilter => {
  return { or: expressions };
};

const notEqualExpression = (selector: KubeSelector): KubeFilter => {
  return { neq: selector };
};

const toGQLFilterString = (filter: KubeFilter): string => {
  const processValue = (value: any): string => {
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        return `[${value.map((item) => processValue(item)).join(', ')}]`;
      } else {
        const fields = Object.entries(value).map(([key, val]) => {
          // Assume this is enum
          const isEnum =
            key === 'type' &&
            typeof val === 'string' &&
            val === val.toUpperCase();
          // For enums, don't double quote
          if (isEnum) {
            return `${key}: ${val}`;
          }
          return `${key}: ${processValue(val)}`;
        });
        return `{${fields.join(', ')}}`;
      }
    } else if (typeof value === 'string') {
      return `"${value}"`;
    }
    return value;
  };

  return processValue(filter);
};

export function buildKubeFilterVariable(
  filters: Array<SelectedFacetValuesByName>,
) {
  return andExpressions(
    filters.flatMap((filter) => {
      return Object.entries(filter).map(([facetName, selectedFacetValues]) => {
        const isPositiveFilter =
          first(Object.values(selectedFacetValues)) === 1;
        const facetExpression = isPositiveFilter
          ? equalExpression
          : notEqualExpression;
        const expressions = isPositiveFilter ? orExpressions : andExpressions;

        return expressions(
          Object.keys(selectedFacetValues).map((facetValue) =>
            facetExpression(
              facetValueExpression({
                type: KubeFacetType.TAG,
                key: facetName,
                value: facetValue,
              }),
            ),
          ),
        );
      });
    }),
  );
}

export const buildKubeFilter = (
  selectedFacetValuesByName: SelectedFacetValuesByName,
): string => {
  return toGQLFilterString(
    buildKubeFilterVariable([selectedFacetValuesByName]),
  );
};

export default buildKubeFilter;
