import { AddToastProps } from 'components/Toasts/context';
import dayjs from 'dayjs';
import { delimiter } from 'kfuse-constants';
import { DateSelection } from 'types';
import { getRelativeVsAbsolute } from 'utils';

const isTimestampInMs = (startTimeUnix: number): boolean => {
  const timestamp = Number(startTimeUnix);

  if (
    Math.abs(Date.now() - timestamp) < Math.abs(Date.now() - timestamp * 1000)
  ) {
    return true;
  }

  return false;
};

const convertKeyIfNeeded = (key: string) => {
  const parts = key.split(delimiter);

  // Support for keys when we didn't support types'
  if (parts.length === 3) {
    return [parts[0], parts[1], 'string', parts[2]].join(delimiter);
  }

  return key;
};

const processDate = (date: DateSelection): DateSelection => {
  if (isTimestampInMs(date.startTimeUnix)) {
    date.startTimeUnix = Math.floor(date.startTimeUnix / 1000);
    date.endTimeUnix = Math.floor(date.endTimeUnix / 1000);
  }

  return date;
};

const logsStateParams = [
  {
    key: 'date',
    getInitialState: () => {
      const endTimeUnix = dayjs().unix();
      const startTimeUnix = dayjs()
        .subtract(60 * 5, 'seconds')
        .unix();

      return {
        startLabel: 'now-5m',
        endLabel: 'now',
        endTimeUnix,
        startTimeUnix,
      };
    },

    process: processDate,
  },
  {
    key: 'keyExists',
    getInitialState: () => ({}),
  },
  {
    key: 'filterByFacets',
    getInitialState: () => [],
  },
  {
    key: 'filterOrExcludeByFingerprint',
    getInitialState: () => ({}),
  },
  { key: 'searchTerms', getInitialState: () => [] },
  {
    key: 'selectedFacetRanges',
    getInitialState: () => ({}),
  },
  {
    key: 'customColumns',
    getInitialState: () => [],
  },
  {
    key: 'selectedFacetValues',
    getInitialState: () => ({}),
    process: (initialSelectedFacetValues = {}) => {
      return Object.keys(initialSelectedFacetValues).reduce(
        (obj, key) => ({
          ...obj,
          [convertKeyIfNeeded(key)]: initialSelectedFacetValues[key],
        }),
        {},
      );
    },
  },
  {
    key: 'fingerprintQuery',
    getInitialState: () => ({
      countOf: '*',
      groupBy: [`Core${delimiter}source${delimiter}string`],
      limit: 100,
      sortBy: '',
      sortOrder: 'Desc',
    }),
  },
];

const readLogsStateFromUrlParams = ({
  addToast,
  utcTimeEnabled,
}: {
  addToast: (props: AddToastProps) => void;
  utcTimeEnabled: boolean;
}) => {
  const search = window.location.href.split('?')[1] || '';
  const urlSearchParams = new URLSearchParams(`?${search}`);
  const logsState = {};

  logsStateParams.forEach(({ key, getInitialState, process }) => {
    const value = urlSearchParams.get(key);
    if (value) {
      try {
        const parsedValue = JSON.parse(value);
        logsState[key] = process ? process(parsedValue) : parsedValue;
        if (key === 'date') {
          logsState[key] = {
            ...logsState[key],
            ...getRelativeVsAbsolute({
              addToast,
              date: logsState[key],
              utcTimeEnabled,
            }),
          };
        }
      } catch (e) {
        logsState[key] = getInitialState();
      }
    } else {
      logsState[key] = getInitialState();
    }
  });

  return logsState;
};

export default readLogsStateFromUrlParams;
