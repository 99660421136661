import { fetchGrafanaApi } from 'requests';

const updateFolderPermissionRole = async ({
  folderUid,
  role,
  permission,
}: {
  folderUid: string;
  role: 'Viewer' | 'Editor' | 'Admin';
  permission: string;
}): Promise<Record<string, unknown>> => {
  const options = { method: 'POST', body: JSON.stringify({ permission }) };
  const result = await fetchGrafanaApi(
    `grafana/api/access-control/folders/${folderUid}/builtInRoles/${role}`,
    options,
  );

  return result;
};

export default updateFolderPermissionRole;
