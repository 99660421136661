import { fetchJson } from 'requests';

const addUserToGroup = async ({
  groupId,
  userId,
  permission,
  method,
}: {
  groupId: string;
  userId: string;
  permission: string;
  method: 'POST' | 'PUT';
}): Promise<Record<string, unknown>> => {
  const options = {
    method,
    body: JSON.stringify({ userId, permission }),
  };
  const result = await fetchJson(`rbac/groups/${groupId}/users`, options);

  return result as Record<string, unknown>;
};

export default addUserToGroup;
