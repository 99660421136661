import { isNil } from 'lodash';
import {
  delimiter,
  IGNORE_ANOMALY_LOWER_BAND_LABEL,
  IGNORE_ANOMALY_UPPER_BAND_LABEL,
} from 'kfuse-constants';
import {
  QueryCombinedStatusProps,
  QueryDataProps,
  QueryDataPropsRangeCombined,
} from 'types/QueryData';
import uPlot, { Band, Series } from 'uplot';
import {
  anomalyBandColor,
  getNonRedColorForOneSeries,
  getAnomalyBandIndexes,
  getAnomalyOutofBoundPoints,
  drawAnomalyOutofBoundPoints,
} from 'utils';

const getAnomalySeriesColor = (
  index: number,
  queryIndex: number,
  seriesIdx: number,
) => {
  if (index === 0) {
    return getNonRedColorForOneSeries({}, queryIndex, seriesIdx);
  }
  return '';
};

const combineAnomalyQueryData = ({
  darkModeEnabled,
  formulas,
  queries,
  queryData,
  timestamps,
  timestampsWithIndex,
}: {
  darkModeEnabled: boolean;
  formulas: QueryCombinedStatusProps[];
  queries: QueryCombinedStatusProps[];
  queryData: QueryDataProps;
  timestamps: number[];
  timestampsWithIndex: { [key: string]: number };
}): QueryDataPropsRangeCombined => {
  const anomalyChartKeys = Object.keys(queryData);
  const isAnomaly = anomalyChartKeys.some((key) => key.includes('anomaly'));
  if (!isAnomaly) return;

  const newSeries: Series[] = [];
  let newMaxValue = -Infinity;
  let newMinValue = Infinity;
  const newData: Array<number[]> = [];
  const bands: Band[] = [];
  let isLoading = false;

  const bandsBitmapByLabel: {
    [key: string]: { inner?: number; upper?: number; lower?: number };
  } = {};

  anomalyChartKeys.forEach((key, queryIndex) => {
    if (!key.includes('anomaly')) return;
    if (key.includes('upper') || key.includes('lower')) return;
    const [type, queryKey] = key.split('_');
    let query = null;
    if (type === 'query') {
      query = queries.find((q) => q.queryKey === queryKey);
    }
    if (type === 'formula') {
      query = formulas.find((f) => f.queryKey === queryKey);
    }
    if (!query || !query.isActive) return;

    const upperKey = `${type}_${queryKey}_anomaly_upper`;
    const lowerKey = `${type}_${queryKey}_anomaly_lower`;
    const batchedData = [
      queryData[key],
      queryData[upperKey],
      queryData[lowerKey],
    ];

    batchedData.forEach((bdata, batchIndex) => {
      if (!bdata) return;
      if (bdata.isLoading) {
        isLoading = true;
      }

      if (!bdata.range?.data) return;

      const { data, series, minValue, maxValue } = bdata.range;
      const currSeriesTimestamps = data[0];
      data.forEach((d: number[], index: number) => {
        if (index === 0) return; // skip first index as it is timestamp
        const preFillData = Array(timestamps.length).fill(undefined);
        d.forEach((value, valueIndex) => {
          const timestamp = currSeriesTimestamps[valueIndex];
          const timestampIndex = timestampsWithIndex[timestamp];
          preFillData[timestampIndex] = value;
        });
        newData.push(preFillData);
      });

      series.forEach((s: Series, seriesIdx: number) => {
        const anomalySeries = {
          ...s,
          stroke: getAnomalySeriesColor(batchIndex, queryIndex, seriesIdx),
        };
        const prevLabel = anomalySeries.label;
        let bandType = 'inner';
        if (batchIndex === 1) {
          bandType = 'upper';
          anomalySeries.label = `${IGNORE_ANOMALY_UPPER_BAND_LABEL}${delimiter}${prevLabel}`;
          anomalySeries.band = true;
        }
        if (batchIndex === 2) {
          bandType = 'lower';
          anomalySeries.label = `${IGNORE_ANOMALY_LOWER_BAND_LABEL}${delimiter}${prevLabel}`;
          anomalySeries.band = true;
        }

        bandsBitmapByLabel[prevLabel] = {
          ...(bandsBitmapByLabel[prevLabel] || {}),
          [bandType]: newSeries.length + 1,
        };

        newSeries.push(anomalySeries);
      });

      newMaxValue = maxValue > newMaxValue ? maxValue : newMaxValue;
      newMinValue = minValue < newMinValue ? minValue : newMinValue;
    });

    const bandsBitmapByLabelKeys = Object.entries(bandsBitmapByLabel);
    if (bandsBitmapByLabelKeys.length === 1) {
      bandsBitmapByLabelKeys.forEach(([_, { inner, upper, lower }]) => {
        if (!isNil(upper) && !isNil(lower) && !isNil(inner)) {
          bands.push({
            series: [upper, lower], // [upper, lower]
            fill: darkModeEnabled
              ? anomalyBandColor.dark
              : anomalyBandColor.light,
            dir: -1,
          });
        }
      });
    }
  });

  const newHooks: QueryDataPropsRangeCombined['hooks'] = [
    {
      type: 'drawSeries',
      hook: (u: uPlot, seriesIdx: number) => {
        if (!seriesIdx || !u.series[seriesIdx]) return;

        const series = u.series[seriesIdx];
        const anomalyBandIndexes = getAnomalyBandIndexes(
          series.label,
          u.series,
        );
        if (!anomalyBandIndexes) return;
        const outOfBoundPoints = getAnomalyOutofBoundPoints({
          anomalyBandIndexes,
          data: u.data,
          seriesIndex: seriesIdx,
          u,
        });
        drawAnomalyOutofBoundPoints({ outOfBoundPoints, u });
      },
    },
  ];

  return {
    bands,
    data: newData,
    hooks: newHooks,
    isLoading,
    maxValue: newMaxValue,
    minValue: newMinValue,
    series: newSeries,
  };
};

export default combineAnomalyQueryData;
