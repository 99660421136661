import { AutocompleteOption } from 'components';

import { OutlierConditionProps } from '../../AlertsCreateCondition';
import { alertQueryAndVariableParser } from '../../utils';

export const toleranceTypeOptions: AutocompleteOption[] = [
  { label: '0.33', value: '0.33' },
  { label: '0.5', value: '0.5' },
  { label: '0.6', value: '0.6' },
  { label: '1.0', value: '1.0' },
  { label: '1.5', value: '1.5' },
  { label: '2.0', value: '2.0' },
  { label: '2.5', value: '2.5' },
  { label: '3.0', value: '3.0' },
  { label: '3.5', value: '3.5' },
  { label: '4.0', value: '4.0' },
  { label: '4.5', value: '4.5' },
  { label: '5.0', value: '5.0' },
];

export const parseOutlierPromql = (
  promql: string,
): {
  promql: string;
  algorithm: string;
  tolerance: string;
} => {
  if (!promql) return { promql: '', algorithm: 'DBSCAN', tolerance: '0.5' };
  let algoKey = 'dbscan';
  if (promql.startsWith('outliers')) {
    algoKey = 'outliers';
  }
  const { query, variables } = alertQueryAndVariableParser({
    algoKey,
    promql,
    numberOfVariable: algoKey === 'dbscan' ? 3 : 4,
    hasWindow: false,
  });

  const algorithmD = variables[0].replace(/"/g, '');
  const tolerance = algoKey === 'dbscan' ? variables[0] : variables[1];

  return {
    promql: query,
    algorithm: algoKey === 'dbscan' ? 'DBSCAN' : algorithmD,
    tolerance: tolerance.trim(),
  };
};

export const buildOutlierAlertPromql = (
  promql: string,
  outliersCondition: OutlierConditionProps,
  type: 'load' | 'create' = 'create',
): string => {
  if (!promql) return '';

  const { tolerance } = outliersCondition;

  if (type === 'load') {
    return `dbscan(${promql}, ${tolerance}, 1)`;
  }
  return `dbscan(${promql}, ${tolerance}, 1, 1)`;
};
