import dayjs from 'dayjs';
import {
  DateSelection,
  FacetRegexTerm,
  LatencyDistribution,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types';
import { onPromiseError } from 'utils';
import { buildRumFilter } from './utils';
import queryRumService from './queryRumService';

type Args = {
  applicationFilter: string;
  date: DateSelection;
  facetRegex: FacetRegexTerm[];
  idSearch: string;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  width: number;
};

const latencyDistribution = async ({
  applicationFilter,
  date,
  facetRegex,
  idSearch,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  width,
}: Args): Promise<LatencyDistribution> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  const numBuckets = Math.floor(width / 10);

  const queryFilterString = buildRumFilter({
    applicationFilter,
    facetRegex,
    idSearch,
    selectedFacetValuesByName,
    selectedFacetRangeByName,
  });

  return queryRumService<LatencyDistribution, 'latencyDistribution'>(`
    {
        latencyDistribution(
            timestamp: "${endTime.format()}"
            durationSecs: ${durationSecs}
            filter: ${queryFilterString}
            field: "view.loading_time"
            numBuckets: ${numBuckets}
            maxType: "p95"
          ) {
            buckets {
              count
              bucketStart
              bucketEndRank
            }
            percentiles {
              p95
              p90
              p75
              p50
        }
    }
  }
  `).then(
    (data) => ({
      ...(data.latencyDistribution || {
        buckets: [],
        percentiles: { max: 0, p99: 0, p95: 0, p90: 0, p75: 0, p50: 0 },
      }),
      durationSecs,
      numBuckets,
    }),
    onPromiseError,
  );
};

export default latencyDistribution;
