import { Separator } from 'components/shadcn';
import React, { ReactElement } from 'react';

import { getAPMReducerLabel } from '../AlertsCreateAPM/utils';

const AlertsDetailsAPMParameters = ({
  annotations,
}: {
  annotations: { [key: string]: any };
}): ReactElement => {
  const extraData = JSON.parse(annotations.extraData || '{}');
  return (
    <>
      <div className="alerts__details__properties__summary__item">
        <span className="text-sm font-bold text-text-secondary">
          Trigger Type:{' '}
        </span>{' '}
        {getAPMReducerLabel(extraData.apmTriggerType)}
      </div>
      {!extraData.serviceHash && (
        <>
          <Separator orientation="horizontal" />
          <div className="alerts__details__properties__summary__item">
            <span className="text-sm font-bold text-text-secondary">
              Service Name:{' '}
            </span>{' '}
            {extraData.serviceName || extraData.serviceNamePattern}
          </div>
        </>
      )}
      <Separator orientation="horizontal" />
    </>
  );
};

export default AlertsDetailsAPMParameters;
