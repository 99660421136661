import { Loader } from 'components';
import kfBrowserSdk from 'kf-browser-sdk';
import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';
import {
  FolderManagement,
  GroupManagement,
  GroupManagementEdit,
  UserManagement,
  UserManagementEdit,
  PolicyManagement,
  PolicyManagementEdit,
  PolicyConfigManagement,
} from './Admin';
import ApmErrors from './ApmErrors';
import { AlertsMuteTiming, AlertsMuteTimingEdit } from './AlertsMuteTiming';
import { Alerts, AlertsCreate, AlertsCreateList } from './NewAlerts';
import AlertsDetailsSLOWrapper from './NewAlerts/AlertsDetails/AlertsDetailsSLOWrapper';
import AlertsDetailsWrapper from './NewAlerts/AlertsDetails/AlertsDetailsWrapper';
import { AlertsContacts, AlertsContactsCreate } from './AlertsContacts';
import { AlertsSilences, AlertsSilencesEdit } from './AlertsSilences';
import { ControlPlane, DasboardByFolder } from './Kfuse';
import { Dashboard, DashboardList } from './Dashboard';
import { EventsContainer } from './Events';
import {
  GrafanaMetrics,
  GrafanaLogs,
  GrafanaTrace,
  GrafanaProfiler,
} from './Grafana';
import Logs from './Logs';
import { LogsCardinality } from './LogsCardinality';
import { LogsDerivedMetrics } from './LogsDerivedMetrics';
import { LogsFacetExplorer } from './LogsFacetExplorer';
import { LogsHydration } from './LogsHydration';
import LogsSavedViews from './LogsSavedViews';
import NewMetrics from './NewMetrics';
import { MetricsCardinality } from './MetricsCardinality';
import MetricsSummary from './MetricsSummary';
import Serverless from './Serverless';
import Services from './Services';
import { Traces } from './Traces';
import { TracesCardinality } from './TracesCardinality';
import Transactions from './Transactions';
import Cicd from './Cicd/Cicd';
import CicdPipeline from './CicdPipeline';
import { Kubernetes, KUBERNETES_PATH_STRING } from './Kubernetes';
import SavedTraceMetrics from './SavedTraceMetrics';
import { SLOCreate, SLOs } from './SLO';
import { ServicesTab } from './Services/utils';
import { useFeatureFlag } from 'configcat-react';
import Rum from './Rum';
import RumPerformanceContainer from './Rum/RumPerformanceContainer';
import RumPerformanceGridContainer from './Rum/RumPerformanceGridContainer';
import RumErrorsGridContainer from './Rum/RumErrorsGridContainer';

const AppRouter = ({ auth, getWorkbooksRequest }) => {
  const { service } = useParams();
  const { value: landingPage, loading: landingPageConfigLoading } =
    useFeatureFlag('landingPage', '/logs');

  const location = useLocation();

  useEffect(() => {
    kfBrowserSdk.startView({ name: location.pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (landingPageConfigLoading) {
    return <Loader className="h-full" isLoading />;
  }
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to={landingPage} />} />
      <Route path="/admin/users" element={<UserManagement />} />
      <Route
        path="/admin/users/:userId"
        element={<UserManagementEdit auth={auth} />}
      />
      <Route path="/admin/folders" element={<FolderManagement auth={auth} />} />
      <Route path="/admin/groups" element={<GroupManagement />} />
      <Route
        path="/admin/groups/:groupId"
        element={<GroupManagementEdit auth={auth} />}
      />
      <Route path="/admin/policy-config" element={<PolicyConfigManagement />} />
      <Route path="/admin/policies" element={<PolicyManagement />} />
      <Route
        path="/admin/policies/:policyId"
        element={<PolicyManagementEdit />}
      />
      <Route path="/apm">
        <Route path="/apm/services">
          <Route
            element={
              <Services
                isInServiceDetails={false}
                key={service}
                tab={ServicesTab.table}
              />
            }
            path=""
          />
          <Route element={<Services key={service} />} path="service-map" />
          <Route
            element={<Services key={service} tab={ServicesTab.db} />}
            path={ServicesTab.db}
          />
          <Route path="/apm/services/:serviceNameAndHash">
            <Route
              element={
                <Services
                  isInServiceDetails={true}
                  key={service}
                  tab={ServicesTab.table}
                />
              }
              path=""
            />
            <Route element={<Services key={service} />} path="service-map" />
          </Route>
        </Route>
        <Route path="/apm/errors">
          <Route element={<ApmErrors />} path=":tab" />
        </Route>
        <Route path="/apm/traces">
          <Route element={<Traces />} path=":tab" />
          <Route element={<Traces />} path="" />
        </Route>
        <Route
          path="/apm/traces/cardinality-analytics"
          element={<TracesCardinality />}
        />
        <Route
          element={<SavedTraceMetrics />}
          path="/apm/saved-trace-metrics"
        />
      </Route>
      <Route path="/events">
        <Route element={<EventsContainer />} path=":tab" />
        <Route element={<EventsContainer />} path="" />
      </Route>
      <Route path="/grafana-metrics" element={<GrafanaMetrics />} />
      <Route path="/grafana-logs" element={<GrafanaLogs />} />
      <Route path="/grafana-trace" element={<GrafanaTrace />} />
      <Route path="/grafana-profiler" element={<GrafanaProfiler />} />
      <Route path="/kfuse/:dashboardName" element={<ControlPlane />} />
      <Route
        path="/kfuse/:folderName/:dashboardName"
        element={
          <DasboardByFolder
            disableFilter={false}
            showFilterTimepicker={true}
            showReloadButton={true}
            disableDeletePanel={true}
          />
        }
      />
      <Route element={<NewMetrics />} path="/metrics" />
      <Route element={<MetricsSummary />} path="/metrics/summary" />
      <Route
        element={<MetricsCardinality />}
        path="/metrics/cardinality-analytics"
      />
      <Route path="/dashboards/lists" element={<DashboardList />} />
      <Route path="/dashboards/:dashboardId" element={<Dashboard />} />
      <Route path="/logs">
        <Route
          element={<LogsSavedViews getWorkbooksRequest={getWorkbooksRequest} />}
          path="saved-queries"
        />
        <Route element={<LogsHydration />} path="hydration/:tab" />
        <Route
          element={
            <Logs getWorkbooksRequest={getWorkbooksRequest} user={auth.user} />
          }
          path=":tab"
        />
        <Route
          element={
            <Logs getWorkbooksRequest={getWorkbooksRequest} user={auth.user} />
          }
          path=""
        />
        <Route element={<LogsDerivedMetrics />} path="derived-metrics" />
        <Route element={<LogsCardinality />} path="cardinality-analytics" />
        <Route element={<LogsFacetExplorer />} path="facet-explorer" />
      </Route>
      <Route element={<Alerts />} path="/alerts" />
      <Route element={<AlertsContacts />} path="/alerts/contacts" />
      <Route
        element={<AlertsContactsCreate />}
        path="/alerts/contacts/create"
      />
      <Route element={<AlertsMuteTiming />} path="/alerts/mute-timing" />
      <Route
        element={<AlertsMuteTimingEdit />}
        path="/alerts/mute-timing/:name"
      />
      <Route element={<AlertsSilences />} path="/alerts/silences" />
      <Route element={<AlertsSilencesEdit />} path="/alerts/silences/:uid" />
      <Route element={<AlertsCreate />} path="/alerts/create" />
      <Route
        element={<AlertsDetailsWrapper />}
        path="/alerts/details/:alertId"
      />
      <Route element={<AlertsCreateList />} path="/alerts/list" />
      <Route
        element={<AlertsDetailsSLOWrapper />}
        path="/alerts/details/slo/:alertId"
      />
      <Route element={<Transactions />} path="/transactions" />
      <Route element={<Cicd />} path="/cicd" />
      <Route element={<CicdPipeline key={service} />} path="/cicd/" />
      <Route path="/serverless" element={<Serverless />} />

      <Route path="/rum">
        <Route element={<Rum />} path=":tab" />
        <Route element={<Rum />} path="" />
      </Route>

      <Route path="/rum/performance-monitoring">
        <Route
          path="/rum/performance-monitoring"
          element={
            <Navigate to="/rum/performance-monitoring/overview" replace />
          }
        />
        <Route element={<RumPerformanceContainer />} path="overview" />
        <Route element={<RumPerformanceGridContainer />} path="performance" />
        <Route element={<RumErrorsGridContainer />} path="errors" />
        <Route element={<RumPerformanceContainer />} path="" />
        <Route element={<RumPerformanceContainer />} path=":tab" />
      </Route>

      <Route
        path={KUBERNETES_PATH_STRING}
        element={<Kubernetes auth={auth} />}
      />
      <Route path="/advanced-service-monitoring" element={<Services isAsm />} />
      <Route element={<SLOs />} path="/slo" />
      <Route path="/slo/create" element={<SLOCreate />} />
      <Route path="/slo/edit" element={<SLOCreate isEditMode />} />
    </Routes>
  );
};

export default AppRouter;
