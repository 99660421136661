import { useMergeState } from 'hooks';
import { Operation } from 'types';
import { SearchState } from './types';
import { getInitialState } from './utils';

const useSearch = () => {
  const [state, setState] = useMergeState<SearchState>(getInitialState());

  const addGroupBy = (group: string) => {
    setState((prevState) => {
      const nextGroupBys = [...prevState.groupBys, group];
      return {
        groupBys: nextGroupBys,
      };
    });
  };

  const addMultiAggregationBy = () => {
    setState((prevState) => {
      const nextMultiAggregations = [...prevState.multiAggregations];
      nextMultiAggregations.push({
        measure: null,
        operation: Operation.distinctcount,
      });
      return {
        multiAggregations: nextMultiAggregations,
      };
    });
  };

  const removeAggregationByIndex = (i: number) => {
    setState((prevState) => {
      const nextMultiAggregations = [...prevState.multiAggregations];
      nextMultiAggregations.splice(i, 1);
      return {
        multiAggregations: nextMultiAggregations,
      };
    });
  };

  const addAdvancedGroupBy = (group: string) => {
    setState((prevState) => {
      const nextGroupBys = [...prevState.advancedGroupBys];
      nextGroupBys.push({
        by: group,
        limitTo: prevState.advancedGroupBys[0].limitTo,
        limitToValue: prevState.advancedGroupBys[0].limitToValue,
      });
      return {
        advancedGroupBys: nextGroupBys,
      };
    });
  };

  const removeGroupByByIndexHandler = (i: number) => () => {
    setState((prevState) => {
      const nextGroupBys = [...prevState.groupBys];
      nextGroupBys.slice(i, 1);
      return {
        groupBys: nextGroupBys,
      };
    });
  };

  const changeHandler =
    <T extends keyof SearchState>(key: T) =>
    (value: SearchState[T]) => {
      setState({ [key]: value });
    };

  const changeMeasure = (nextMeasure: string) => {
    setState({
      measure: nextMeasure,
      operation:
        nextMeasure === 'duration' ? Operation.avg : Operation.distinctcount,
    });
  };

  return {
    ...state,
    addAdvancedGroupBy,
    addMultiAggregationBy,
    addGroupBy,
    changeActive: changeHandler('isActive'),
    changeAdvancedGroupBys: changeHandler('advancedGroupBys'),
    changeGroupBys: changeHandler('groupBys'),
    changeLimitTo: changeHandler('limitTo'),
    changeLimitToValue: changeHandler('limitToValue'),
    changeMeasure: changeMeasure,
    changeOperation: changeHandler('operation'),
    changeRollUpInSeconds: changeHandler('rollUpInSeconds'),
    changeVisualizeAs: changeHandler('visualizeAs'),
    removeGroupByByIndexHandler,
    removeAggregationByIndex,
    state,
  };
};

export default useSearch;
