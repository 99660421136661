import { combineRangeQueryData, getUsedQueriesLabelForFormula } from 'utils';
import { DashboardPanelType } from 'types/Dashboard';
import { QueryCombinedStatusProps, QueryDataProps } from 'types/QueryData';

import filterLogqlFormulaMatchingLabels from './filterLogqlFormulaMatchingLabels';
import { DataFrame } from '../types';
import { timeseriesDataTransformer } from '../visualTransformer';

const getFilteredFormulaDataFrames = ({
  dataFrames,
  tempLogsChartData,
  tempLabelKeys,
}: {
  dataFrames: DataFrame[];
  tempLogsChartData: QueryDataProps;
  tempLabelKeys: { [key: string]: string[] };
}) => {
  const formulasStatus: QueryCombinedStatusProps[] = [];
  const filteredDataFrames: DataFrame[] = [];

  dataFrames.forEach((dataFrame) => {
    const { queryType, refId, isActive } = dataFrame.meta;
    if (queryType !== 'formula') return;

    const labelBitmap = getUsedQueriesLabelForFormula({
      formulaExpression: dataFrame?.meta.formulaExpression,
      tempLogsChartData,
      tempLabelKeys,
    });
    let filteredDataFrame = dataFrame;
    if (labelBitmap) {
      filteredDataFrame = filterLogqlFormulaMatchingLabels({
        dataFrame: dataFrame,
        labelBitmap,
      });
    }
    filteredDataFrames.push(filteredDataFrame);
    formulasStatus.push({ isActive, queryKey: refId });
  });

  return { formulasStatus, filteredDataFrames };
};

const getTempNonFormulaDataFrames = (dataFrames: DataFrame[]) => {
  const queriesStatus: QueryCombinedStatusProps[] = [];
  const tempLogsChartData: QueryDataProps = {};
  const tempLabelKeys: { [key: string]: string[] } = {};

  dataFrames.forEach((dataFrame) => {
    const { queryType, refId, isActive } = dataFrame.meta;
    if (queryType === 'formula') return;
    const tempData = timeseriesDataTransformer(dataFrame);
    const queryId = `${queryType}_${refId}`;
    tempLogsChartData[queryId] = {
      range: tempData,
      meta: dataFrame.meta,
      isLoading: false,
    };
    tempLabelKeys[queryId] = dataFrame.meta.labels;
    queriesStatus.push({ isActive, queryKey: refId });
  });

  return { queriesStatus, tempLogsChartData, tempLabelKeys };
};

const logqlDashboardDataTransformer = (dataFrames: DataFrame[]) => {
  if (dataFrames.length === 0) return {};

  const { queriesStatus, tempLogsChartData, tempLabelKeys } =
    getTempNonFormulaDataFrames(dataFrames);

  const { formulasStatus, filteredDataFrames } = getFilteredFormulaDataFrames({
    dataFrames,
    tempLogsChartData,
    tempLabelKeys,
  });

  const panelType = dataFrames[0].meta.type;
  // if panel type is not timeseries, return the active formula data frame or active query data frame
  if (panelType !== DashboardPanelType.TIMESERIES) {
    const activeFormulaDataFrame = filteredDataFrames.find(
      (dataFrame) => dataFrame.meta.isActive,
    );
    const activeQueriesDataFrame = dataFrames.find(
      (dataFrame) => dataFrame.meta.isActive,
    );
    return activeFormulaDataFrame || activeQueriesDataFrame || dataFrames[0];
  }

  filteredDataFrames.forEach((dataFrame) => {
    const { queryType, refId } = dataFrame.meta;
    const tempData = timeseriesDataTransformer(dataFrame);
    const queryId = `${queryType}_${refId}`;
    tempLogsChartData[queryId] = {
      range: tempData,
      meta: dataFrame.meta,
      isLoading: false,
    };
  });

  const combinedData = combineRangeQueryData({
    formulas: formulasStatus,
    queries: queriesStatus,
    queryData: tempLogsChartData,
  });

  return combinedData;
};

export default logqlDashboardDataTransformer;
