import React, {
  useEffect,
  useRef,
  ComponentPropsWithoutRef,
  Children,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Slot } from '@radix-ui/react-slot';

import { root as rootStyle } from './ContentEditable.module.css';
import { cn } from '../../utils/tailwind';
import useBeforeInput from './useBeforeInput';
import { getSelectionRange } from './dom';

function setCaretPosition(ref: Node, offset: number): void {
  if (ref.contains(document.activeElement)) {
    const range = document.createRange();
    const selection = document.getSelection();
    const it = document.createNodeIterator(ref, NodeFilter.SHOW_TEXT);

    let consumed = offset;
    let node = null;

    do {
      node = it.nextNode();

      if (node) {
        consumed -= node.textContent.length;
      }

      if (consumed <= 0) {
        break;
      }
    } while (node);

    if (node) {
      range.setStart(node, consumed + node.textContent.length);
      // if (node.parentElement.offsetWidth) {
      //   range.setStart(node, consumed + node.textContent.length);
      // } else {
      //   // we're in a hidden element, find the next visible node
      //   while (!node.parentElement.offsetWidth) {
      //     node = it.nextNode();
      //   }
      //
      //   range.setStart(node, 0);
      // }
    } else {
      range.setStart(ref, 0);
    }

    range.collapse(true);

    selection.removeAllRanges();
    selection.addRange(range);
  }
}

type ContentEditableProps = ComponentPropsWithoutRef<'div'> & {
  onBeforeContentEdit?: (next: string, current: string) => void;
  onSelectionChange?: (
    offset: number,
    selection: Selection,
    target: HTMLElement,
  ) => void;
};

/**
 * `ContentEditable` is a controlled component that utilizes HTML `contenteditable` attribute
 * to allow rich text editing. Any attempted text change by the user would fire `onBeforeContentEdit`
 * action with the next and current text string. A parent component is expected to make any
 * modification and render it as the children of `ContentEditable`. Please note, the collective
 * rendered text strings should match passed in text string exactly or undefined behavior be happen.
 *
 * `ContentEditable` works exclusively with the rendered `textContent`. Any Expression level
 * construct should be handled by parent component.
 */
const ContentEditable = forwardRef(
  (
    {
      children,
      onBeforeContentEdit,
      onSelectionChange,
      className,
      ...props
    }: ContentEditableProps,
    componentRef,
  ) => {
    const ref = useRef<null | HTMLElement>(null);
    // const history = useEditHistory();

    useBeforeInput(ref, onBeforeContentEdit);

    useImperativeHandle(componentRef, () => {
      return {
        setCaretPosition(position: number) {
          setCaretPosition(ref.current, position);
        },
      };
    });

    useEffect(() => {
      function onMaybeFocusChange() {
        if (
          onSelectionChange &&
          ref.current?.contains(document.activeElement)
        ) {
          const selection = document.getSelection();
          const offset = getSelectionRange(
            ref.current,
            selection.getRangeAt(0),
          );

          onSelectionChange(offset, selection, ref.current);
        }
      }

      document.addEventListener('selectionchange', onMaybeFocusChange);

      return () => {
        document.removeEventListener('selectionchange', onMaybeFocusChange);
      };
    }, [onSelectionChange]);

    return (
      <Slot
        contentEditable
        suppressContentEditableWarning
        ref={ref}
        className={cn(rootStyle, className)}
        {...props}
      >
        {Children.only(children)}
      </Slot>
    );
  },
);

export default ContentEditable;
