import React, { useMemo } from 'react';
import { duration } from 'utils/timeNs';
import { useKubernetesController } from '../KubernetesController';

import { Job } from '../types';
import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellStatus,
  KubeCellTimestamp,
  KubeTableCell,
} from './Components';
import KubernetesTable from './KubernetesTable';
import { useEntitiesCount, useEntitiesData } from './useEntitiesData';

export default function KubernetesTableForJob() {
  const { entitiesType, facets, page, limit } = useKubernetesController();
  const filters = useMemo(() => [facets], [facets]);
  const [rows] = useEntitiesData<{ job: Job }>({
    filters,
    page,
    limit,
  });
  const [count] = useEntitiesCount({
    entitiesType,
    facets,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'kube_job',
          label: 'Job',
          renderCell(prop) {
            const jobName = prop.row.job.metadata.name;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name"
                tooltipText={jobName}
              >
                {jobName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell(prop) {
            return <KubeCellCluster {...prop} />;
          },
        },
        {
          key: 'metadata.namespace',
          label: 'Namespace',
          renderCell(prop) {
            return <KubeCellNamespace {...prop} />;
          },
        },
        {
          key: 'status.active',
          label: 'Status',
          renderCell(prop) {
            const statusBit =
              prop.row.job.status?.active == 1 ||
              prop.row.job.status.succeeded == 1;
            const status = statusBit ? 'Success' : 'Failed';
            return <KubeCellStatus {...prop} status={status} />;
          },
        },
        {
          key: 'spec.completions',
          label: 'Completions',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.job.spec.completions}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'spec.parallelism',
          label: 'Parallelism',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.job.spec.parallelism}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'spec.backofflimit',
          label: 'Backoff Limit',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.job.spec.backoffLimit}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'metadata.creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            return (
              <KubeCellTimestamp
                {...prop}
                timestamp={prop.row.job.metadata.creationTimestamp}
              />
            );
          },
        },
        {
          key: 'status.completionTime',
          label: 'Duration',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {duration(
                  prop.row.job.status.startTime,
                  prop.row.job.status.completionTime,
                )}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'status.conditionMessage',
          label: 'Kubernetes Labels',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.job.status.conditionMessage}
              </KubeTableCell>
            );
          },
        },
      ]}
    />
  );
}
