import classNames from 'classnames';
import React from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';

const SearchBarPanelOperator = ({
  activeOperator,
  queryOperators,
  onOperatorClick,
}: {
  activeOperator: string;
  queryOperators: { [key: string]: string };
  onOperatorClick: (key: string) => void;
}): ReactElement => {
  return (
    <>
      <div className="search-bar__panel__toolbar">
        <div>
          Operators:
          {Object.keys(queryOperators).map((key) => {
            return (
              <div
                onMouseDown={() => onOperatorClick(key)}
                className={classNames({
                  'search-bar__panel__toolbar__operator__option': true,
                  'search-bar__panel__toolbar__operator__option--selected':
                    key === activeOperator,
                })}
                key={key}
              >{`${key} ${queryOperators[key]}`}</div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SearchBarPanelOperator;
