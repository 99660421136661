export type CustomBEFilterType = {
  key: React.Key;
  label: React.ReactNode;
  value: boolean;
  toggleFilter: (isChecked: boolean) => void;
};

export enum EdgeType {
  bezier = 'bezier',
  simplebezier = 'simplebezier',
  smoothstep = 'smoothstep',
  step = 'step',
  straight = 'straight',
}

export enum Orientation {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

export enum ServiceMapType {
  cluster = 'Cluster',
  flow = 'Flow',
}

export type ServiceMapState = {
  customFilterState: Record<string, unknown>;
  edgeType: EdgeType;
  focusedNodeId: string;
  hideDanglingNodes: boolean;
  hideExternalAndUnknown: boolean;
  hoveredEdgeId: string;
  hoveredNodeId: string;
  orientation: Orientation;
  outerRingKey: string;
  search: string;
  selectedNodeId: string;
  showMiniMap: boolean;
  showOnlyPathsWithErrors: boolean;
};

export type NodeSizeBasedOn = 'requests' | 'errors' | 'maxlatency' | null;

export type LegendProps = {
  maxNodeSize: number;
  minNodeSize: number;
  nodeSizeBasedOn: NodeSizeBasedOn;
  setNodeSizeBasedOn: (type: NodeSizeBasedOn) => void;
};
