import { Datepicker } from 'composite';
import React, { ReactElement, useEffect } from 'react';
import EventsExplorerTable from 'screens/Events/EventsExplorer/EventsExplorerTable';
import { useEventsState } from 'screens/Events/hooks';
import { DateSelection } from 'types';

import { EntityTypes } from '../types';
import { kubeOpenEventInNewTab } from './utils';

type Props = {
  entityType: EntityTypes;
  entity: any;
};

const KubernetesEvents = ({ entityType, entity }: Props): ReactElement => {
  const eventsState = useEventsState({ urlKeysToSync: [] });
  const { date, filterState, setDate } = eventsState;
  const { filter, clearAllFilters, replaceFilterByKey } = filterState;

  useEffect(() => {
    clearAllFilters();
    const newFilterByKeys = fetchJsonBasedOnEntity();
    if (!newFilterByKeys) return;
    replaceFilterByKey('sidebarFilters', newFilterByKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEventFilterByKeys = (
    templateValues: { [key: string]: any },
    filterItemKeys: string[],
  ) => {
    const newFilterByKeys: {
      [key: string]: { [key: string]: number };
    } = {};
    filterItemKeys.forEach((key) => {
      newFilterByKeys[key] = { [templateValues[key]]: 1 };
    });
    return newFilterByKeys;
  };

  const fetchJsonBasedOnEntity = () => {
    const tags = entity.tags;
    const clusterTag = tags.find((tag) => tag.includes('kube_cluster_name'));
    const namespaceTag = tags.find((tag) => tag.includes('kube_namespace'));
    const clusterName = clusterTag ? clusterTag.split(':')[1] : '';
    const namespaceValue = namespaceTag ? namespaceTag.split(':')[1] : '';

    const filterValuesBitmap: { [key: string]: any } = {
      kube_cluster_name: clusterName,
      kube_namespace: namespaceValue,
      pod_name: entity.metadata?.name,
      kube_node: entity.metadata?.name,
      kube_deployment: entity.metadata?.name,
      kube_replica_set: entity.metadata?.name,
      kube_job: entity.metadata?.name,
      kube_daemon_set: entity.metadata?.name,
      kube_stateful_set: entity.metadata?.name,
      kube_service: entity.metadata?.name,
    };

    switch (entityType) {
      case 'Pod':
        return getEventFilterByKeys(filterValuesBitmap, [
          'kube_cluster_name',
          'kube_namespace',
          'pod_name',
        ]);
      case 'Cluster':
        return getEventFilterByKeys(filterValuesBitmap, ['kube_cluster_name']);
      case 'Namespace':
      case 'Job':
      case 'CronJob':
      case 'Service':
        return getEventFilterByKeys(filterValuesBitmap, [
          'kube_cluster_name',
          'kube_namespace',
        ]);
      case 'Node':
        return getEventFilterByKeys(filterValuesBitmap, ['kube_node']);
      case 'Deployment':
        return getEventFilterByKeys(filterValuesBitmap, [
          'kube_cluster_name',
          'kube_namespace',
          'kube_deployment',
        ]);
      case 'ReplicaSet':
        return getEventFilterByKeys(filterValuesBitmap, [
          'kube_cluster_name',
          'kube_namespace',
          'kube_replica_set',
        ]);
      case 'DaemonSet':
        return getEventFilterByKeys(filterValuesBitmap, [
          'kube_cluster_name',
          'kube_namespace',
          'kube_daemon_set',
        ]);
      case 'StatefulSet':
        return getEventFilterByKeys(filterValuesBitmap, [
          'kube_cluster_name',
          'kube_namespace',
          'kube_stateful_set',
        ]);
      default:
        return;
    }
  };

  return (
    <div className="events__main">
      <div className="flex flex--justify-content-end mt-2 mr-2">
        <Datepicker
          onChange={setDate as (dateSelection: DateSelection) => void}
          value={date as DateSelection}
        />
      </div>
      <div className="kubernetes__events__body">
        {Object.keys(filter.sidebarFilters).length > 0 ? (
          <EventsExplorerTable
            eventsState={eventsState}
            onRowClick={({ row }: any) =>
              kubeOpenEventInNewTab(date, filter, row.id)
            }
          />
        ) : null}
      </div>
    </div>
  );
};
export default KubernetesEvents;
