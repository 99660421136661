import dayjs from 'dayjs';
import { useFilterState } from 'hooks';
import React, { createContext, useState } from 'react';
import { DateSelection } from 'types';

export interface FilterContextState {
  globalFilters: {
    events: {
      date: DateSelection;
      setDate: React.Dispatch<React.SetStateAction<DateSelection>>;
      filterState: ReturnType<typeof useFilterState>;
    };
  };
}

export const FilterContext = createContext<FilterContextState | undefined>(
  undefined,
);

const getLast12Hours = () => {
  const endTimeUnix = dayjs().unix();
  const startTimeUnix = dayjs().subtract(12, 'hour').unix();

  return {
    startLabel: 'now-12h',
    endLabel: 'now',
    endTimeUnix,
    startTimeUnix,
  };
};

export const GlobalFilterProvider: React.FC = ({ children }) => {
  //Events filters
  const [eventsDate, setEventsDate] = useState<DateSelection>(getLast12Hours());
  const eventsFilterState = useFilterState({ shouldWriteToUrl: false });

  return (
    <FilterContext.Provider
      value={{
        globalFilters: {
          events: {
            date: eventsDate,
            filterState: eventsFilterState,
            setDate: setEventsDate,
          },
        },
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
