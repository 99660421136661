import React from 'react';
import { IoIosWarning } from 'react-icons/io';

const FailedTimeseries = () => {
  return (
    <div className="uplot__na-timeseries h-[140px] w-full flex gap-[4px] w-full justify-center items-center pr-[14px]">
      <IoIosWarning
        className="overlay-message__icon-and-message__icon"
        size={16}
      />
      <div className="text-red-500">Failed to load data for timeseries</div>
    </div>
  );
};

export default FailedTimeseries;
