import classnames from 'classnames';
import React, { ReactElement, ReactNode, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { PanelPosition } from 'types';
import SelectPanel from './SelectPanel';
import SelectTriggerValue from './SelectTriggerValue';
import { SelectOption } from './types';
import Icon from '../base/Icon';
import { PopoverTriggerV2 } from '../PopoverTriggerV2';

const getPosition = ({ right, top }) => {
  if (right && top) {
    return PanelPosition.TOP_RIGHT;
  }

  if (right) {
    return PanelPosition.BOTTOM_RIGHT;
  }

  if (top) {
    return PanelPosition.TOP_LEFT;
  }

  return PanelPosition.BOTTOM_LEFT;
};

type Props = {
  className?: string;
  clear?: VoidFunction;
  isAutocompleteEnabled?: boolean;
  getSearchedValue?: (value: any) => string;
  onChange: (value: any) => void;
  onEnter?: (search: string) => void;
  onSearchChange?: (search: string) => void;
  options: SelectOption[];
  placeholder?: string;
  renderValue?: (value: any) => ReactNode;
  right?: boolean;
  top?: boolean;
  value: any;
  dataTestId?: string;
};

const Select = ({
  className,
  clear,
  getSearchedValue,
  isAutocompleteEnabled,
  onChange,
  onEnter,
  onSearchChange,
  options,
  placeholder,
  renderValue,
  right,
  top,
  value,
  dataTestId,
}: Props): ReactElement => {
  const [search, setSearch] = useState('');
  const onChangeHandler = (nextValue) => {
    onChange(nextValue);
    setSearch('');
  };

  return (
    <PopoverTriggerV2
      className={classnames({ select: true, [className]: className })}
      position={getPosition({ right, top })}
      popover={({ close }) => (
        <div className="selectv2__panel" data-testid={`${dataTestId}-options`}>
          <SelectPanel
            close={close}
            getSearchedValue={getSearchedValue}
            isAutocompleteEnabled={isAutocompleteEnabled}
            onChange={onChangeHandler}
            options={options}
            search={search}
            setSearch={setSearch}
          />
        </div>
      )}
    >
      {({ isOpen }) => (
        <div className="select__trigger" data-testid={`${dataTestId}-dropdown`}>
          <SelectTriggerValue
            close={close}
            isAutocompleteEnabled={isAutocompleteEnabled}
            isOpen={isOpen}
            onEnter={onEnter}
            options={options}
            onSearchChange={onSearchChange}
            placeholder={placeholder}
            renderValue={renderValue}
            search={search}
            setSearch={setSearch}
            value={value}
          />
          {isOpen ? (
            <ChevronUp
              className="select__trigger__icon select__trigger__icon--open"
              size={16}
            />
          ) : (
            <ChevronDown
              className="select__trigger__icon select__trigger__icon--closed"
              size={16}
            />
          )}
          {value && clear ? (
            <div className="select__trigger__clear" onClick={clear}>
              <Icon icon="x" />
            </div>
          ) : null}
        </div>
      )}
    </PopoverTriggerV2>
  );
};

export default Select;
