import { useToaster } from 'components';
import dayjs from 'dayjs';
import { useRequest } from 'hooks';
import { useEffect, useState } from 'react';
import {
  getGrafanaAlertManager,
  getGrafanaAlertsStatus,
  getGrafanaMutedAlerts,
  muteGrafanaAlert,
  unmuteGrafanaAlert,
} from 'requests';
import { DateSelection, ValueCount } from 'types';
import { convertTimeStringToUnixUpcoming } from 'utils';

import { RuleProps } from '../types';
import { formatGrafanaAlertsRules, mapMutedAlertsWithRules } from '../utils';

const useAlertsState = () => {
  const { addToast } = useToaster();

  const [error, setError] = useState({
    getAlerts: null,
    getGrafanaAlertsRules: null,
    getGrafanaMutedAlerts: null,
    getGrafanaAlertManager: null,
  });

  const requestGrafanaAlertsRules = useRequest(
    getGrafanaAlertsStatus,
    true,
    true,
  );
  const requestGrafanaMutedAlerts = useRequest(
    getGrafanaMutedAlerts,
    true,
    true,
  );
  const requestsGrafanaAlertManager = useRequest(
    getGrafanaAlertManager,
    true,
    true,
  );

  const unmuteGrafanaAlertRequest = useRequest(unmuteGrafanaAlert, true, true);
  const muteGrafanaAlertRequest = useRequest(muteGrafanaAlert, true, true);

  const [isLoading, setIsLoading] = useState(false);
  const [rules, setRules] = useState<RuleProps[]>([]);
  const [alertsProperties, setAlertsProperties] = useState<{
    [key: string]: ValueCount[];
  }>({
    contactpoints: [],
    mute: [],
    tags: [],
    type: [],
    folder: [],
  });

  const getPredefinedFacetValues = (facetName: string) => () => {
    return new Promise((resolve) => {
      resolve(alertsProperties[facetName]);
    });
  };

  const reloadAlerts = async () => {
    setIsLoading(true);
    const datasets = await Promise.all([
      requestGrafanaAlertsRules.call('rules'),
      requestGrafanaMutedAlerts.call(),
      requestsGrafanaAlertManager.call('contact-list'),
    ]).catch(() => {
      setIsLoading(false);
      setError((prevError) => ({
        ...prevError,
        getAlerts: { message: 'Failed to load alerts' },
      }));
    });

    if (!datasets) {
      return;
    }

    setError((prevError) => ({ ...prevError, getAlerts: null }));

    const [rulesRes, mutedRes, contactPoints] = datasets;

    const contactPointsList: string[] = contactPoints.map(
      (contactPoint) => contactPoint.name,
    );
    const formattedRules = formatGrafanaAlertsRules(
      rulesRes,
      contactPointsList,
    );

    if (mutedRes && mutedRes.length > 0) {
      const rulesWithMutedStatus = mapMutedAlertsWithRules(
        formattedRules,
        mutedRes,
      );
      setRules(rulesWithMutedStatus);
    } else {
      setRules(formattedRules);
    }

    setIsLoading(false);
  };

  const unMuteAlert = (id: string) => {
    setIsLoading(true);
    return new Promise((resolve, reject) => {
      unmuteGrafanaAlertRequest
        .call(id)
        .then(() => {
          addToast({ text: 'Alert unmuted successfully', status: 'success' });
          resolve(true);
        })
        .catch(() => {
          setIsLoading(false);
          reject();
        });
    });
  };

  const muteAlert = (rule: RuleProps, date: DateSelection) => {
    const matchers = [
      { name: 'alertname', value: rule.name, isEqual: true, isRegex: false },
    ];

    Object.keys(rule.labels || {}).forEach((key) => {
      matchers.push({
        name: key,
        value: 'true',
        isEqual: true,
        isRegex: false,
      });
    });

    const positiveLabel = date.startLabel.replace('-', '+');
    const endsAtUnix = convertTimeStringToUnixUpcoming(positiveLabel);
    const payload = {
      matchers,
      startsAt: dayjs().toISOString(),
      endsAt: dayjs.unix(endsAtUnix).toISOString(),
      createdBy: 'admin',
      comment: 'Muted by admin',
    };

    setIsLoading(true);

    return new Promise((resolve, reject) => {
      muteGrafanaAlertRequest
        .call(payload)
        .then((res) => {
          addToast({ text: 'Alert muted successfully', status: 'success' });
          resolve(res);
        })
        .catch(() => {
          setIsLoading(false);
          reject();
        });
    });
  };

  useEffect(() => {
    if (unmuteGrafanaAlertRequest.error) {
      addToast({
        status: 'error',
        text: 'Failed to unmute alert',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unmuteGrafanaAlertRequest.error]);

  useEffect(() => {
    if (muteGrafanaAlertRequest.error) {
      addToast({
        status: 'error',
        text: 'Failed to mute alert',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [muteGrafanaAlertRequest.error]);

  return {
    alertsProperties,
    isLoading,
    getPredefinedFacetValues,
    muteAlert,
    unMuteAlert,
    reloadAlerts,
    rules,
    requestGrafanaAlertsRules,
    setAlertsProperties,
    setIsLoading,
    error,
  };
};

export default useAlertsState;
