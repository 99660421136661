import classnames from 'classnames';
import React from 'react';

type Props = {
  contextLine: string;
  lineno: number;
  postContext: string[];
  preContext: string[];
};

const StackTraceFrameCode = ({
  contextLine,
  lineno,
  postContext,
  preContext,
}: Props) => {
  const lines = [...preContext, contextLine, ...postContext];
  const startLineNumber = lineno - preContext.length;

  return (
    <div className="stack-trace__frame__code">
      {lines.map((line, i) => {
        const lineNumber = startLineNumber + i;
        return (
          <div
            className={classnames({
              'stack-trace__frame__code__line': true,
              'stack-trace__frame__code__line--active': lineno === lineNumber,
            })}
            key={i}
          >
            <div className="stack-trace__frame__code__line__number">
              {lineNumber}
            </div>
            <div className="stack-trace__frame__code__line__text">{line}</div>
          </div>
        );
      })}
    </div>
  );
};

export default StackTraceFrameCode;
