import { Separator } from 'components/shadcn';
import { Datepicker } from 'composite';
import { generateLogPeriodOptions } from 'composite/utils';
import React, { useEffect, useState } from 'react';
import { IoWarningOutline } from 'react-icons/io5';
import LogsFacetExplorerSourceFacets from './LogsFacetExplorerSourceFacets';
import LogsFacetExplorerSources from './LogsFacetExplorerSources';
import useLogsFacetExplorerState from './useLogsFacetExplorerState';
import LogsFacetExplorerMain from './LogsFacetExplorerMain';
import { IoIosWarning } from 'react-icons/io';
import { logsErrors } from 'utils/error/logsErrors';
import ErrorMessage from 'components/ErrorMessage';

const LogsFacetExplorer = () => {
  const [error, setError] = useState({
    getLogsSources: null,
  });
  const logsFacetExplorerState = useLogsFacetExplorerState();
  const {
    date,
    getLogsSourcesRequest,
    selectedSource,
    selectedFacet,
    onDateChange,
    logsFacetError,
  } = logsFacetExplorerState;

  useEffect(() => {
    getLogsSourcesRequest
      .call()
      .then((nextResult) => {
        if (nextResult) {
          setError((prevError) => ({ ...prevError, getLogsSources: null }));
        }
      })
      .catch(() => {
        setError((prevError) => ({
          ...prevError,
          getLogsSources: { message: logsErrors.getLogsSources },
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mx-auto max-w-[1480px] px-4 py-2">
      <div className="flex items-center justify-between pb-2">
        <h2 className="text-lg font-semibold whitespace-nowrap">
          Facet Explorer
        </h2>

        <div className="flex gap-[4px] items-center">
          {(error?.getLogsSources ||
            logsFacetError?.getLogsFacetNames ||
            logsFacetError?.getLogsFavoriteFacets) && (
            <div className="flex justify-end gap-[4px]">
              <ErrorMessage
                message={error?.getLogsSources?.message}
                className="justify-end pr-[14px]"
              />
              <ErrorMessage
                message={logsFacetError?.getLogsFacetNames?.message}
                className="justify-end pr-[14px]"
              />
              <ErrorMessage
                message={logsFacetError?.getLogsFavoriteFacets?.message}
                className="justify-end pr-[14px]"
              />
            </div>
          )}

          {selectedSource && (
            <Datepicker
              value={date}
              onChange={onDateChange}
              quickRangeOptions={generateLogPeriodOptions('now-1d')}
            />
          )}
        </div>
      </div>
      <LogsFacetExplorerSources
        logsFacetExplorerState={logsFacetExplorerState}
      />
      <Separator orientation="horizontal" className="mt-2" />
      {!selectedSource && (
        <div className="flex h-96 min-h-96 items-center justify-center ">
          <div className="flex flex-col items-center gap-2">
            <IoWarningOutline size={60} className="text-text-secondary" />
            <div className="text-lg font-bold text-text-secondary">
              No source selected, please select a source to view the facets
            </div>
          </div>
        </div>
      )}
      {selectedSource && (
        <div className="metrics-summary__body">
          <LogsFacetExplorerSourceFacets
            logsFacetExplorerState={logsFacetExplorerState}
          />
          {selectedFacet && (
            <LogsFacetExplorerMain
              logsFacetExplorerState={logsFacetExplorerState}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default LogsFacetExplorer;
