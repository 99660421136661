import { useState } from 'react';

const getShowDatabasesChecked = (dbSpanFilter: {
  filter: string;
  operator: string;
}): boolean => dbSpanFilter === null;

type DBSpanFilter = {
  filter: string;
  operator: string;
};
const initialState: DBSpanFilter = null;

const useSpanTypeFilters = () => {
  const [dbSpanFilter, setDBSpanFilter] = useState(initialState);
  const toggleShowAll = (checked: boolean) => {
    if (checked) {
      setDBSpanFilter(null);
    } else {
      setDBSpanFilter({
        filter: 'db',
        operator: '!=',
      });
    }
  };

  const clear = () => {
    setDBSpanFilter(initialState);
  };

  return {
    state: dbSpanFilter,
    isShowDatabasesChecked: getShowDatabasesChecked(dbSpanFilter),
    toggleShowAll,
    clear,
  };
};

export default useSpanTypeFilters;
