import { yupResolver } from '@hookform/resolvers/yup';
import { AutocompleteOption, useToaster } from 'components';
import { startCase } from 'lodash';
import {
  useDateState,
  useMetricsQueryStateV2,
  useRequest,
  useUrlState,
} from 'hooks';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  createSlo,
  editSlo,
  getGrafanaAlertManager,
  promqlLabels,
  promqlQuery,
} from 'requests';
import { getDateFromRange } from 'screens/Dashboard/utils';
import { ExplorerQueryProps, SLOFormProps } from 'types';
import { sloMetricsNames } from '../constants';
import {
  apmMetricSeries,
  getSLOPromql,
  getServiceFromMetricSeries,
  parseSLOPromql,
} from '../utils';
import { getFunctionParams, getMetricsExplorerDefaultQuery } from 'utils';

import { SLOCountValueThreshold, SLOQueryTemplates, SLOType } from '../types';
import {
  buildSLOPromql,
  getDefaultNameForSLOType,
  sloCreateInputSchema,
} from '../utils';
import { buildPromqlLabelMatcher } from 'utils';

const { EDGE_LATENCY_BUCKET, EDGE_LATENCY_COUNT } = sloMetricsNames;

const setPredefinedMetrics = (setMetrics: any) => {
  const sloMetricList = [
    { label: EDGE_LATENCY_COUNT, value: EDGE_LATENCY_COUNT },
    { label: EDGE_LATENCY_BUCKET, value: EDGE_LATENCY_BUCKET },
  ];

  setMetrics(sloMetricList);
};

const getDefaultSLOQueries = () => {
  const newQuery = getMetricsExplorerDefaultQuery('');
  const defaultFunction = getFunctionParams('sum_by');
  newQuery.functions = [
    {
      name: 'sum_by',
      params: defaultFunction,
      vectorType: 'instant',
    },
  ];
  return [newQuery];
};

const checkIfKfSourceExist = (series: string[]) => {
  let isKfSourceExist = false;
  series.forEach((s) => {
    if (s.startsWith('kf_source')) {
      isKfSourceExist = true;
    }
  });
  return isKfSourceExist;
};

const removeByDefaultAggregation = (query: ExplorerQueryProps) => {
  const newQuery = { ...query };
  newQuery.functions = [];
  return newQuery;
};

const useCreateSLOState = (
  initialSloEditState?: any,
  activeDetection?: string,
) => {
  const navigate = useNavigate();
  const { addToast } = useToaster();
  const [date, setDate] = useDateState(getDateFromRange('now-1h', 'now'));
  const [isEditing, setIsEditing] = useState(false);

  const [dryRunPromQls, setDryRunPromQls] = useState<{ [key: string]: string }>(
    {},
  );

  const [sloCountThresold, setSloCountThresold] = useState<{
    denominator: SLOCountValueThreshold;
    numerator: SLOCountValueThreshold;
  }>({
    denominator: { count: 'count', operator: '<', threshold: '' },
    numerator: { count: 'count', operator: '<', threshold: '' },
  });

  const [sloResult, setSloResult] = useState<[]>(null);
  const [sloType, setSloType] = useUrlState<{
    value: SLOType;
  }>('sloType', { value: SLOType.AVAILABILITY });

  const [serviceName] = useUrlState('serviceName', '');

  const defaultSLOName = getDefaultNameForSLOType({
    type: activeDetection || '',
    serviceName,
  });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<SLOFormProps>({
    resolver: yupResolver(sloCreateInputSchema()),
    defaultValues: {
      sloName: initialSloEditState?.name || defaultSLOName,
      sloDescription: initialSloEditState?.description || '',
      sloLabels: [{ key: '', value: '' }],
      objective: undefined,
    },
  });

  const createSloRequest = useRequest(createSlo);
  const editSLORequest = useRequest(editSlo);
  const requestsGrafanaAlertManager = useRequest(getGrafanaAlertManager);
  const promqlQueryRequest = useRequest(promqlQuery);

  const syncUpSloServiceName = (
    queries: ExplorerQueryProps[],
    type: 'numerator' | 'denominator',
  ) => {
    if (!queries || !queries[0]?.series) return;
    const serviceName = getServiceFromMetricSeries(queries[0].series);
    if (!serviceName) return;

    const isKubeService = queries[0].series.some((s: string) =>
      s.startsWith('kube_service='),
    );
    const serviceLabel = isKubeService ? 'kube_service' : 'service_name';

    const serviceLabelValue = `${serviceLabel}="${serviceName}"`;
    const checkQueries =
      type === 'numerator'
        ? [...denominatorQueryState.queries]
        : [...numeratorQueryState.queries];

    const newSeries = [...checkQueries[0].series];
    const isServiceNameExist = newSeries.some((s: string) =>
      s.startsWith(`${serviceLabel}=`),
    );

    if (!isServiceNameExist) {
      const lastSeries = newSeries[newSeries.length - 1];
      if (lastSeries === '=""') {
        newSeries[newSeries.length - 1] = serviceLabelValue;
      } else {
        newSeries.push(serviceLabelValue);
      }
    } else {
      const index = newSeries.findIndex((s: string) =>
        s.startsWith(`${serviceLabel}=`),
      );
      newSeries[index] = serviceLabelValue;
    }

    checkQueries[0].series = newSeries;
    if (type === 'numerator') {
      denominatorQueryState.setQueries(checkQueries);
    } else {
      numeratorQueryState.setQueries(checkQueries);
    }
  };

  const checkDuplicateServiceAndRemove = (
    queries: ExplorerQueryProps[],
    type: 'numerator' | 'denominator',
  ) => {
    let isDuplicateService = false;
    const newQueries = queries.map((query: ExplorerQueryProps) => {
      const newQuery = { ...query };
      const duplicateIndexs: number[] = [];
      newQuery.series.forEach((series: string, idx) => {
        if (series.startsWith('service_name=')) {
          duplicateIndexs.push(idx);
        }
      });

      if (duplicateIndexs.length > 1) {
        isDuplicateService = true;
        duplicateIndexs.map((idx: number, index) => {
          if (index > 0) {
            newQuery.series.splice(idx, 1);
          }
        });
      }
      return newQuery;
    });

    if (isDuplicateService) {
      addToast({ status: 'error', text: `Duplicate service cannot be added.` });

      if (type === 'numerator') {
        numeratorQueryState.setQueries(newQueries);
      }
      if (type === 'denominator') {
        denominatorQueryState.setQueries(newQueries);
      }
    }
    return isDuplicateService;
  };

  const loadSLOResult = async (
    queryItem: any,
    type: 'numerator' | 'denominator',
  ) => {
    syncUpSloServiceName(queryItem?.queries, type);

    if (
      checkDuplicateServiceAndRemove(numeratorQueryState.queries, 'numerator')
    ) {
      return;
    }
    if (
      checkDuplicateServiceAndRemove(
        denominatorQueryState.queries,
        'denominator',
      )
    ) {
      return;
    }

    const { badEventsPromql, goodEventsPromql, service } = buildSLOPromql({
      denoQueryState: {
        formulas: denominatorQueryState.formulas,
        queries: denominatorQueryState.queries,
      },
      numeQueryState: {
        formulas: numeratorQueryState.formulas,
        queries: numeratorQueryState.queries,
      },
      options: {
        nume: sloCountThresold.numerator,
        deno: sloCountThresold.denominator,
      },
      useType: 'load',
    });

    if (!service || !badEventsPromql || !goodEventsPromql) {
      return;
    }

    const dataset = await Promise.all([
      promqlQueryRequest.call({
        date,
        promqlQueries: [badEventsPromql, goodEventsPromql],
      }),
    ]);

    setSloResult(dataset[0]);
  };

  const numeratorQueryState = useMetricsQueryStateV2({
    activeQueryType: 'single',
    date,
    defaultQueries: getDefaultSLOQueries(),
    isRange: true,
  });

  const denominatorQueryState = useMetricsQueryStateV2({
    activeQueryType: 'single',
    date,
    defaultQueries: getDefaultSLOQueries(),
    isRange: true,
  });

  useEffect(() => {
    if (
      initialSloEditState &&
      initialSloEditState?.type?.toLowerCase() === 'metrics'
    ) {
      const { goodEventsSLIQuery, totalEventsSLIQuery } = initialSloEditState;
      const goodQueries = parseSLOPromql(goodEventsSLIQuery);
      const totalQueries = parseSLOPromql(totalEventsSLIQuery);

      denominatorQueryState.setQueries(totalQueries.queries);
      denominatorQueryState.setFormulas(totalQueries.formulas);

      numeratorQueryState.setQueries(goodQueries.queries);
      numeratorQueryState.setFormulas(goodQueries.formulas);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dryRunSLO = ({
    activeDetection,
    alertsCreateState,
    alertsCreateAPMState,
    denominatorQueryState,
    numeratorQueryState,
    latencyThreshold,
    objective,
    queryTemplates,
  }: {
    activeDetection: string;
    alertsCreateState: SLOFormProps;
    alertsCreateAPMState: any;
    denominatorQueryState: ReturnType<typeof useMetricsQueryStateV2>;
    numeratorQueryState: ReturnType<typeof useMetricsQueryStateV2>;
    latencyThreshold: number;
    isEditMode: boolean;
    objective: number;
    queryTemplates: { [key: string]: SLOQueryTemplates };
  }) => {
    const numeratorPromql = getSLOPromql({
      queryState: numeratorQueryState,
      isNumerator: true,
    });

    const denominatorPromql = getSLOPromql({
      queryState: denominatorQueryState,
    });

    const sliBadEventsPromQuery =
      activeDetection === 'metrics'
        ? `(${denominatorPromql} - ${numeratorPromql}) `
        : queryTemplates[activeDetection].sliBadEventsPromQuery;
    const sliTotalEventsPromQuery =
      activeDetection === 'metrics'
        ? denominatorPromql
        : queryTemplates[activeDetection].sliTotalEventsPromQuery;

    const queryTemplate = queryTemplates[activeDetection];

    const matcher = buildPromqlLabelMatcher({
      series: alertsCreateAPMState.apmAlertDetails.additionalLabels,
      seriesIndex: -1,
      includesAll: true,
    });
    const payload = {
      name: '',
      queryTemplate,
      type: startCase(activeDetection),
      latencyThreshold: latencyThreshold,
      service: {
        name: alertsCreateAPMState.apmAlertDetails.serviceName,
        hash: alertsCreateAPMState.apmAlertDetails.serviceHash,
      },
      objective,
      contactPoints: alertsCreateState.contactPoints,
      matchers: matcher,
      sliBadEventsPromQuery: sliBadEventsPromQuery,
      sliTotalEventsPromQuery: sliTotalEventsPromQuery,
    };
    setDryRunPromQls(payload);
  };

  const createSLO = ({
    activeDetection,
    alertsCreateState,
    alertsCreateAPMState,
    latencyThreshold,
    isEditMode,
    objective,
    createSLOState,
    initialSloEditState,
  }: {
    activeDetection: string;
    alertsCreateState: SLOFormProps;
    alertsCreateAPMState: any;
    latencyThreshold: number;
    isEditMode: boolean;
    objective: number;
    createSLOState: any;
    initialSloEditState: any;
  }) => {
    const numeratorPromql = getSLOPromql({
      queryState: numeratorQueryState,
      isNumerator: true,
    });

    const denominatorPromql = getSLOPromql({
      queryState: denominatorQueryState,
    });

    const sloName =
      createSLOState?.getFormValue('sloName') ||
      `${alertsCreateAPMState.apmAlertDetails.serviceName} SLO`;

    const description = createSLOState?.getFormValue('sloDescription') || '';

    const matcher = buildPromqlLabelMatcher({
      series: alertsCreateAPMState.apmAlertDetails.additionalLabels,
      seriesIndex: -1,
      includesAll: true,
    });

    const payload = {
      contactPoints: alertsCreateState.contactPoints,
      description,
      denominatorPromql,
      latencyThreshold: latencyThreshold,
      matcher,
      name: sloName,
      numeratorPromql,
      objective,
      service: {
        name: alertsCreateAPMState.apmAlertDetails.serviceName,
        hash: alertsCreateAPMState.apmAlertDetails.serviceHash,
      },
      type: startCase(activeDetection),
    };

    if (isEditMode) {
      editSLORequest
        .call({
          ...payload,
          sloId: initialSloEditState.id,
        })
        .then((response: any) => {
          if (response) {
            addToast({
              text: `SLO updated successfully`,
              status: 'success',
            });
            navigate('/slo');
          } else {
            addToast({ text: 'Failed to update SLO', status: 'error' });
          }
        });
    } else {
      createSloRequest.call(payload).then((response: any) => {
        if (response) {
          addToast({
            text: `SLO ${isEditMode ? 'updated' : 'created'} successfully`,
            status: 'success',
          });
          navigate('/slo');
        } else {
          addToast({ text: 'Failed to create SLO', status: 'error' });
        }
      });
    }
  };

  return {
    control,
    createSLO,
    createSloRequest,
    dryRunSLO,
    dryRunPromQls,
    date,
    handleSubmit,
    isEditing,
    loadSLOResult,
    numeratorQueryState,
    denominatorQueryState,
    requestsGrafanaAlertManager,
    registerSLOInput: register,
    setDate,
    sloCountThresold,
    sloFormError: errors,
    sloResult,
    sloType,
    setIsEditing,
    setFormValue: setValue,
    getFormValue: getValues,
    setSloCountThresold,
    setSloType,
  };
};

export default useCreateSLOState;
