import React from 'react';

import { Box } from 'components/layouts/Box';
import { Table, TableRow } from 'components';
import { getRules } from '../utils/selectorsfunction';
import KubernetesRightSideBar from '../KubernetesRightSideBar';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellTimestamp,
  KubeTableCell,
  KubeTableEntityTags,
} from './Components';
import { generatePath, useParams } from 'react-router-dom';
import KubernetesTableGroup from './KubernetesTableGroup';
import {
  useEntitiesData,
  useEntitiesGroupData,
  useMergedGroupByFilter,
} from './useEntitiesData';

const renderEmptyCell = () => '';

function KubernetesTableForRoleRow({ row }) {
  const { date, entitiesType, navigate } = useKubernetesController();

  const facets = useMergedGroupByFilter(row.groupBys);
  const [rows] = useEntitiesData({
    entitiesType,
    kubesSort: null,
    facets,
    page: 0,
    limit: 50,
    date,
  });

  const setActiveKube = (row) => {
    navigate(({ location, params, pathTemplate }) => {
      return {
        ...location,

        pathname: generatePath(pathTemplate, {
          ...params,

          activeKube: row.role.metadata.uid,
          tab: 'yaml',
        }),
      };
    });
  };

  const activeKubeParam = useParams()['activeKube'];
  const activeKube = (
    typeof activeKubeParam === 'string' && rows ? rows : []
  ).find((row) => {
    return row.role.metadata.uid === activeKubeParam;
  });

  return (
    <Box width="full" height="full">
      {activeKube && <KubernetesRightSideBar activeKube={activeKube} />}
      <Table
        className="-my-px"
        renderHeader={false}
        rows={rows ?? []}
        renderRow={(props) => (
          <TableRow
            {...props}
            className="border-y"
            onRowClickHandler={(row) => () => setActiveKube(row)}
          />
        )}
        columns={[
          { key: 'header', label: '' },
          {
            key: 'kube_role',
            label: 'Role',
            renderCell(prop) {
              const roleName = prop.row.role.metadata?.name;
              return (
                <KubeTableCell
                  {...prop}
                  className="kubernetes__table__cell__entity-name pt-1.5 pb-1.5"
                  tooltipText={roleName}
                >
                  {roleName}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'kube_cluster_name',
            label: 'Cluster',
            renderCell(prop) {
              return <KubeCellCluster {...prop} />;
            },
          },
          {
            key: 'metadata.namespace',
            label: 'Namespace',
            renderCell(prop) {
              return <KubeCellNamespace {...prop} />;
            },
          },
          {
            key: 'headerRules',
            label: 'Rules',
            renderCell(prop) {
              const roleRules = getRules(prop?.row.role);
              return (
                <KubeTableCell
                  {...prop}
                  align="left"
                  className="kubernetes__table__cell__entity-name"
                  tooltipText={roleRules}
                >
                  {roleRules}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'metadata.creationTimestamp',
            label: 'Age',
            renderCell(prop) {
              const timestamp = prop.row.role.metadata?.creationTimestamp || '';
              return <KubeCellTimestamp {...prop} timestamp={timestamp} />;
            },
          },
        ]}
      />
    </Box>
  );
}

export default function KubernetesTableForRoleGroup() {
  const { date, entitiesType, facets, groupBySearchTerms } =
    useKubernetesController();

  const [rows] = useEntitiesGroupData({
    entitiesType,
    date,
    facets,
    groupBySearchTerms,
  });

  return (
    <KubernetesTableGroup
      rows={rows ?? []}
      columns={[
        {
          key: 'headerkube_role',
          label: 'Role Group',
          renderCell: (prop) => <KubeTableEntityTags {...prop} />,
        },
        {
          key: 'headerkube_cluster_name',
          label: 'Cluster',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerkube_namespace',
          label: 'Namespace',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerrules',
          label: 'Rules',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerage',
          label: 'Age',
          renderCell: renderEmptyCell,
        },
      ]}
      renderExpandedRow={(props) => <KubernetesTableForRoleRow {...props} />}
    />
  );
}
