import { Button, SelectV2, TooltipTrigger } from 'components';
import React from 'react';
import { Plus } from 'react-feather';
import isRangeFacet from '../utils/isRangeFacet';
import { useSearch } from 'hooks/index';
import { SelectOption } from 'components/SelectV2';
import { RumTab } from '../types';
import { IoMdClose } from 'react-icons/io';
import { Operation } from 'types/useSearch';
import { DashboardPanelType } from 'types';

const getOperationForMeasure = (name: string) => {
  return isRangeFacet(name) ? Operation.avg : Operation.distinctcount;
};

const RumMultiAggregate = ({
  measureOptions,
  operationOptions,
  rumTab,
  search,
}: {
  measureOptions: SelectOption[];
  operationOptions: SelectOption[];
  rumTab: RumTab;
  search: ReturnType<typeof useSearch>;
}) => {
  const { multiAggregations } = search;

  const hanldeAddAggregate = () => {
    search.addMultiAggregationBy();
  };

  const handleDeleteAggregate = (index: number) => {
    if (multiAggregations.length <= 1) {
      return;
    }
    search.removeAggregationByIndex(index);
  };

  const handleChangeOperation = (index: number, value: string) => {
    const newAggregations = [...multiAggregations];
    if (newAggregations[index]) {
      newAggregations[index].operation = value as Operation;
      search.changeMultiAggregations(newAggregations);
    }
  };

  const handleChangeMeasure = (index: number, value: string) => {
    const newAggregations = [...multiAggregations];
    if (newAggregations[index]) {
      newAggregations[index].measure = value as Operation;
      newAggregations[index].operation = getOperationForMeasure(value);
      search.changeMultiAggregations(newAggregations);
    }
  };

  const isMultiAggregationEnabled =
    (rumTab as unknown as DashboardPanelType) === DashboardPanelType.TABLE;

  return (
    <>
      {multiAggregations.map((aggregation, index) => {
        const operationPlaceholder =
          aggregation.measure === null ? 'Count of' : 'Count unique of';
        return (
          <div className="button-group mr-1" key={index}>
            <div className="button-group__item  button-group__item--label">
              {index === 0 ? 'Show' : 'and'}
            </div>
            <div className="button-group__item button-group__item--value">
              {isRangeFacet(aggregation.measure) ? (
                <SelectV2.Select
                  isAutocompleteEnabled
                  onChange={(value) => handleChangeOperation(index, value)}
                  options={operationOptions}
                  value={aggregation.operation}
                />
              ) : (
                operationPlaceholder
              )}
            </div>
            <div className="button-group__item">
              <SelectV2.Select
                isAutocompleteEnabled
                onChange={(value) => handleChangeMeasure(index, value)}
                options={measureOptions}
                value={aggregation.measure}
              />
            </div>
            <div className="button-group__item">
              {multiAggregations.length > 1 && (
                <Button
                  className="h-full px-1"
                  onClick={() => handleDeleteAggregate(index)}
                  variant="icon"
                  size="sm"
                >
                  <IoMdClose />
                </Button>
              )}
            </div>
          </div>
        );
      })}
      <div className="button-group">
        <div className="button-group__item">
          <TooltipTrigger
            className="h-full"
            tooltip={
              isMultiAggregationEnabled
                ? ''
                : 'Multi Aggregations are only available in Table View'
            }
            delayDuration={0}
          >
            <Button
              className="h-full px-1"
              onClick={hanldeAddAggregate}
              variant="icon"
              size="sm"
              disabled={!isMultiAggregationEnabled}
            >
              <Plus size={20} />
            </Button>
          </TooltipTrigger>
        </div>
      </div>
    </>
  );
};

export default RumMultiAggregate;
