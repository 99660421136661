import React, { ReactElement } from 'react';
import { DashboardPanelComponentProps } from 'types';
import DashboardPanelLogsTable from './DashboardPanelLogsTable';

const DashboardPanelLogs = (
  props: DashboardPanelComponentProps,
): ReactElement => {
  const { panel } = props;
  if (panel.targets.length === 0) return null;

  const { expr } = panel.targets[0];
  try {
    JSON.parse(expr);
  } catch (e) {
    return null;
  }

  return <DashboardPanelLogsTable key={expr} {...props} />;
};

export default DashboardPanelLogs;
