import { AutocompleteV2, TooltipTrigger, useToaster } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement } from 'react';
import { FaInfo as InfoIcon } from 'react-icons/fa';

import {
  updateFolderPermissionById,
  updateFolderPermissionRole,
} from './requests';
import {
  FolderPermissionCategory,
  FolderPermissionsProps,
  FolderProps,
} from './types';
import {
  filterLibraryTextFromPermissionActions,
  FolderPermissionOptions,
} from './utils';

const FolderManagementSettingsPermission = ({
  folder,
  permission,
  permissionCategory,
  onPermissionChange,
}: {
  folder: FolderProps;
  onPermissionChange?: () => void;
  permission?: FolderPermissionsProps;
  permissionCategory: FolderPermissionCategory;
}): ReactElement => {
  const { addToast } = useToaster();
  const updateFolderPermissionRequest = useRequest(updateFolderPermissionById);
  const updateFolderPermissionRoleRequest = useRequest(
    updateFolderPermissionRole,
  );

  const onPermission = (value: string) => {
    if (!folder.id) return;

    const isRole = permissionCategory === FolderPermissionCategory.ROLE;
    if (isRole) {
      updateFolderPermissionRoleRequest
        .call({
          folderUid: folder.uid,
          role: permission.builtInRole,
          permission: value,
        })
        .then((res) => {
          if (res?.message) {
            addToast({ text: res.message as string, status: 'success' });
          }
          onPermissionChange && onPermissionChange();
        });
      return;
    }

    const isUser = permissionCategory === FolderPermissionCategory.USER;
    updateFolderPermissionRequest
      .call({
        folderUid: folder.uid,
        id: isUser ? permission?.userId : permission?.teamId,
        permission: value,
        type: isUser ? 'users' : 'teams',
      })
      .then((res) => {
        if (res?.message) {
          addToast({ text: res.message as string, status: 'success' });
        }
        onPermissionChange && onPermissionChange();
      });
  };

  const isAdmin =
    permissionCategory === FolderPermissionCategory.ROLE &&
    permission.permission === 'Admin';

  if (
    updateFolderPermissionRoleRequest.isLoading ||
    updateFolderPermissionRequest.isLoading
  ) {
    return (
      <div className="admin__row-muted flex h-10 w-full items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <TooltipTrigger
      tooltip={isAdmin ? 'Admin role cannot be changed' : ''}
      className="w-full"
    >
      <div className="flex items-center">
        <AutocompleteV2
          className="max-h-[32x] w-full max-w-[82%]"
          options={FolderPermissionOptions}
          value={permission?.permission}
          isDisabled={isAdmin}
          onChange={onPermission}
        />
        <TooltipTrigger
          tooltip={filterLibraryTextFromPermissionActions(
            permission?.actions,
          ).join(', ')}
        >
          <div className="ml-2 rounded-sm border px-0.5 py-1 text-gray-500">
            <InfoIcon size={11} />
          </div>
        </TooltipTrigger>
      </div>
    </TooltipTrigger>
  );
};

export default FolderManagementSettingsPermission;
