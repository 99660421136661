import { useRequest } from 'hooks';
import React, { useEffect } from 'react';
import { DateSelection, FilterMethodByType } from 'types';
import { facetNames } from '../constants';
import ServerlessRightSidebarFiltersItem from './ServerlessRightSidebarFiltersItem';
import ServerlessRightSidebarChipItem from './ServerlessRightSidebarChipItem';
import promqlSeries from 'requests/promqlSeries';
import { buildPromQLFilterFromSelectedFacetValuesByName } from 'utils/buildPromQLFilterFromSelectedFacetValuesByName';

type Props = {
  date: DateSelection;
  functionName: string;
  sidebarFilterState: FilterMethodByType<'map'>;
};

const EXCLUDE_FROM_CHIPS = ['__name__'];

const ServerlessRightSidebarFilters = ({
  date,
  functionName,
  sidebarFilterState,
}: Props) => {
  const labelsRequest = useRequest(promqlSeries);

  useEffect(() => {
    const filters = buildPromQLFilterFromSelectedFacetValuesByName({
      selectedFacetValuesByName: {
        ...(functionName ? { functionname: { [functionName]: 1 } } : {}),
      },
    });

    labelsRequest.call({
      date,
      match: `aws_lambda_invocations${filters}`,
    });
  }, [functionName]);

  const values = labelsRequest?.result?.data?.[0] || {};

  const filteredValues = Object.keys(values).filter(
    (item) => !EXCLUDE_FROM_CHIPS.includes(item),
  );

  return (
    <>
      <div className="serverless__right-sidebar__filter-chips">
        {filteredValues.map((item) => (
          <ServerlessRightSidebarChipItem
            date={date}
            facetName={item}
            key={item}
            name={item}
            sidebarFilterState={sidebarFilterState}
            value={values[item]}
          />
        ))}
      </div>
      <div className="serverless__right-sidebar__filters">
        {facetNames.map((facetName) => (
          <ServerlessRightSidebarFiltersItem
            date={date}
            facetName={facetName.facetName}
            key={facetName.name}
            name={facetName.name}
            sidebarFilterState={sidebarFilterState}
            value={values[facetName.name]}
          />
        ))}
      </div>
    </>
  );
};

export default ServerlessRightSidebarFilters;
