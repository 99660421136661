import React, { ReactElement, useState } from 'react';
import { DateSelection } from 'types/DateSelection';

import { CorrelationMetricsQueryProps } from './types';

import AlertsServiceCorrelationsMetricsChartMini from './AlertsServiceCorrelationsMetricsChartMini';
import AlertsServiceCorrelationsMetricsChartLarge from './AlertsServiceCorrelationsMetricsChartLarge';
const AlertsServiceCorrelationsMetrics = ({
  correlationMetrics,
  date,
  error,
}: {
  correlationMetrics: CorrelationMetricsQueryProps[];
  date: DateSelection;
  error: Error;
}): ReactElement => {
  const [activeChart, setActiveChart] = useState<{
    correlation: CorrelationMetricsQueryProps;
    chartData: { data: any; series: any };
  } | null>(null);

  if (error) {
    return (
      <div className="flex min-h-[260px] items-center justify-center text-center text-sm font-normal">
        Run in some issue to load correlations metrics, please try again.
      </div>
    );
  }

  if (!correlationMetrics)
    return (
      <div className="flex min-h-[260px] items-center justify-center text-center text-sm font-normal">
        Run correlation to see metrics
      </div>
    );

  if (correlationMetrics.length === 0) {
    return (
      <div className="flex min-h-[260px] items-center justify-center text-center text-sm font-normal">
        No correlations found
      </div>
    );
  }

  return (
    <div>
      {activeChart ? (
        <AlertsServiceCorrelationsMetricsChartLarge
          chartData={activeChart.chartData}
          date={date}
          title={activeChart.correlation.name}
          onBackToSummary={() => setActiveChart(null)}
          promql={activeChart.correlation.correlatedSeries}
        />
      ) : (
        <>
          {correlationMetrics.map((correlation, idx) => {
            return (
              <div key={idx}>
                <AlertsServiceCorrelationsMetricsChartMini
                  promql={correlation.correlatedSeries}
                  date={date}
                  title={correlation.name}
                  onRowClick={(chartData) => {
                    setActiveChart({ correlation, chartData });
                  }}
                />
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default AlertsServiceCorrelationsMetrics;
