import { ApmServiceMapTooltip } from 'components';
import { useRequest } from 'hooks';
import React, { useEffect } from 'react';
import { queryRange } from 'requests';
import { DateSelection, SelectedFacetValuesByName } from 'types';
import {
  buildPromQLClausesFromSelectedFacetValuesByName,
  getSelectedFacetValueByNameWithCustomerFilters,
} from 'utils';
import { getTimeParameter } from './utils';

type QueryArgs = {
  date: DateSelection;
  isUpstream: boolean;
  serviceHash: string;
  serviceName: string;
  selectedFacetValuesByName: SelectedFacetValuesByName;
};

const errorsQuery = ({
  date,
  isUpstream,
  serviceHash,
  serviceName,
  selectedFacetValuesByName,
}: QueryArgs) => {
  const key = isUpstream ? 'client_service_hash' : 'service_hash';
  const value = serviceHash.indexOf('UNKNOWN') > -1 ? 'UNKNOWN' : serviceHash;
  const timeParameter = getTimeParameter(date);
  const filters = buildPromQLClausesFromSelectedFacetValuesByName({
    selectedFacetValuesByName: {
      ...selectedFacetValuesByName,
      kf_source: {
        ...(selectedFacetValuesByName.kf_source || { apm: 1 }),
      },
      ...(value === 'UNKNOWN' ? { service_name: { [serviceName]: 1 } } : {}),
    },
  }).join(',');
  const filtersString = filters ? `,${filters}` : '';
  return `sum by (service_hash) (rate(edge_latency_count{error="true",${key}="${value}"${filtersString}}[${timeParameter}]))`;
};

const latencyQuery = ({
  date,
  isUpstream,
  serviceHash,
  serviceName,
  selectedFacetValuesByName,
}: QueryArgs) => {
  const key = isUpstream ? 'client_service_hash' : 'service_hash';
  const value = serviceHash.indexOf('UNKNOWN') > -1 ? 'UNKNOWN' : serviceHash;
  const timeParameter = getTimeParameter(date);
  const filters = buildPromQLClausesFromSelectedFacetValuesByName({
    selectedFacetValuesByName: {
      ...selectedFacetValuesByName,
      kf_source: {
        ...(selectedFacetValuesByName.kf_source || { apm: 1 }),
      },
      ...(value === 'UNKNOWN' ? { service_name: { [serviceName]: 1 } } : {}),
    },
  }).join(',');
  const filtersString = filters ? `,${filters}` : '';
  return `max by (service_hash) (max_over_time(edge_latency_max{${key}="${value}"${filtersString}}[${timeParameter}]))`;
};

const requestsQuery = ({
  date,
  isUpstream,
  serviceHash,
  serviceName,
  selectedFacetValuesByName,
}: QueryArgs) => {
  const key = isUpstream ? 'client_service_hash' : 'service_hash';
  const value = serviceHash.indexOf('UNKNOWN') > -1 ? 'UNKNOWN' : serviceHash;
  const timeParameter = getTimeParameter(date);
  const filters = buildPromQLClausesFromSelectedFacetValuesByName({
    selectedFacetValuesByName: {
      ...selectedFacetValuesByName,
      ...(value === 'UNKNOWN' ? { service_name: { [serviceName]: 1 } } : {}),
    },
  }).join(',');
  const filtersString = filters ? `,${filters}` : '';
  return `sum by (service_hash) (rate(edge_latency_count{${key}="${value}"${filtersString}}[${timeParameter}]))`;
};

const queryValue = (date: DateSelection, query: string) =>
  queryRange({ date, instant: true, query }).then((result) =>
    result.length && result[0].value.length > 1
      ? Number(result[0].value[1])
      : null,
  );

const queryValues = async ({
  date,
  isUpstream,
  serviceHash,
  serviceName,
  selectedFacetValuesByName,
}: QueryArgs) => {
  const values = await Promise.all([
    queryValue(
      date,
      errorsQuery({
        date,
        isUpstream,
        selectedFacetValuesByName,
        serviceHash,
        serviceName,
      }),
    ),
    queryValue(
      date,
      latencyQuery({
        date,
        isUpstream,
        selectedFacetValuesByName,
        serviceHash,
        serviceName,
      }),
    ),
    queryValue(
      date,
      requestsQuery({
        date,
        isUpstream,
        selectedFacetValuesByName,
        serviceHash,
        serviceName,
      }),
    ),
  ]);

  return {
    errors: values[0],
    latency: values[1],
    requests: values[2],
  };
};

type Props = {
  customerFilter: { key: string; value: string };
  date: DateSelection;
  isUpstream?: boolean;
  name: string;
  serviceHash: string;
  serviceName: string;
  selectedFacetValuesByName: SelectedFacetValuesByName;
};

const TracesServiceMapNodeTooltip = ({
  customerFilter,
  date,
  isUpstream,
  name,
  serviceHash,
  serviceName,
  selectedFacetValuesByName,
}: Props) => {
  const valuesRequest = useRequest(queryValues, true, true);
  const values =
    valuesRequest.result ||
    ({} as {
      errors: number;
      latency: number;
      requests: number;
    });

  useEffect(() => {
    valuesRequest.call({
      date,
      isUpstream,
      serviceHash,
      serviceName,
      selectedFacetValuesByName: getSelectedFacetValueByNameWithCustomerFilters(
        {
          customerFilter,
          selectedFacetValuesByName,
        },
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ApmServiceMapTooltip
      isLoading={valuesRequest.isLoading}
      title={name}
      values={values}
    />
  );
};

export default TracesServiceMapNodeTooltip;
