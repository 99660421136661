import { TooltipCoordsProps, UplotExtended } from 'types/Timeseries';

import { canvasPadding } from './index';

const getTooltipPositionByLayoutType = ({
  bbox,
  nextPos,
  u,
}: {
  bbox: DOMRect;
  nextPos: TooltipCoordsProps;
  u: UplotExtended;
}): { x: number; y: number } => {
  if (!nextPos) return;
  const { layoutType } = u;
  const padding = canvasPadding(u);
  const devicePixelRatio = window.devicePixelRatio || 1;

  const nextPosX = nextPos.x / devicePixelRatio;
  const nextPosY = nextPos.y / devicePixelRatio;
  if (layoutType === 'dashboard' && nextPos) {
    const TOP_OFFSET = 34;
    const LEFT_PADDING_OFFSET = 6;
    return {
      x: nextPosX + LEFT_PADDING_OFFSET,
      y: nextPosY + TOP_OFFSET,
    };
  }

  if (nextPos && layoutType === 'modal') {
    // All modal that has a chart must maintain a margin offset of 24px
    const MODEL_MARGIN_OFFSET = 24;
    return {
      x:
        nextPosX +
        bbox.left -
        MODEL_MARGIN_OFFSET -
        padding.left / devicePixelRatio,
      y:
        nextPosY +
        bbox.top -
        MODEL_MARGIN_OFFSET -
        padding.top / devicePixelRatio,
    };
  }

  if (nextPos && layoutType === 'drawer') {
    const DRAWER_LEFT_OFFSET = 360;
    return {
      x:
        nextPosX +
        bbox.left -
        DRAWER_LEFT_OFFSET -
        padding.left / devicePixelRatio,
      y: nextPosY + bbox.top - padding.top / devicePixelRatio,
    };
  }

  if (nextPos && layoutType === 'drawer-large') {
    const DRAWER_LEFT_OFFSET = 300;
    return {
      x:
        nextPosX +
        bbox.left -
        DRAWER_LEFT_OFFSET -
        padding.left / devicePixelRatio,
      y: nextPosY + bbox.top - padding.top / devicePixelRatio,
    };
  }

  return {
    x: nextPosX + bbox.left - padding.left / devicePixelRatio,
    y: nextPosY + bbox.top - padding.top / devicePixelRatio,
  };
};

export default getTooltipPositionByLayoutType;
