import { round } from 'lodash';
import React, { useState } from 'react';
import { formatDurationNs } from 'utils/timeNs';
import { Select } from '..';
import { LegendProps, NodeSizeBasedOn } from './types';

const SELECT_OPTIONS = [
  { label: 'Requests/sec', value: 'requests' },
  { label: 'Max Latency', value: 'maxlatency' },
  { label: 'Errors/sec', value: 'errors' },
];

const MinNodeText = ({ formattedMin }: { formattedMin: number }) => {
  return <span>{`Min ${formattedMin === 0 ? '<0.01' : formattedMin}/s`}</span>;
};

const MaxNodeText = ({ formattedMax }: { formattedMax: number }) => {
  return <span>{`Max ${formattedMax === 0 ? '<0.01' : formattedMax}/s `}</span>;
};

const ServiceMapNodeSizeLegend = ({
  legendProps,
}: {
  legendProps: LegendProps;
}) => {
  const { minNodeSize, maxNodeSize, nodeSizeBasedOn, setNodeSizeBasedOn } =
    legendProps;

  const [activeNodeType, setActiveNodeType] = useState(nodeSizeBasedOn);
  const formattedMin = minNodeSize ? round(minNodeSize, 2) : 0;
  const formattedMax = maxNodeSize ? round(maxNodeSize, 2) : 0;

  const nodeSizeChangeHandler = (type: NodeSizeBasedOn) => {
    setActiveNodeType(type);
    setNodeSizeBasedOn(type);
  };

  return (
    <div className="service-map-legend absolute right-0 top-0 m-4 w-64 rounded-lg bg-background p-2 shadow-lg">
      <div className="mb-2 flex items-center justify-around">
        <span className="font-medium">Node Size</span>
        <Select
          className="service-map-legend__select"
          options={SELECT_OPTIONS}
          onChange={(type) => nodeSizeChangeHandler(type)}
          value={activeNodeType}
        />
      </div>
      <div className="flex items-center justify-around">
        <div className="size-4 rounded-full bg-gray-300"></div>
        <div className="size-10 rounded-full bg-gray-300"></div>
      </div>
      <div className="mt-1 flex justify-around">
        {activeNodeType === 'maxlatency' ? (
          <>
            <span>{`Min ${
              minNodeSize === 0
                ? '<0.01'
                : formatDurationNs(minNodeSize * 1000000, 1, 1)
            }`}</span>
            <span>{`Max ${
              maxNodeSize === 0
                ? '<0.01'
                : formatDurationNs(maxNodeSize * 1000000, 1, 1)
            } `}</span>
          </>
        ) : (
          <>
            <MinNodeText formattedMin={formattedMin} />
            <MaxNodeText formattedMax={formattedMax} />
          </>
        )}
      </div>
    </div>
  );
};

export default ServiceMapNodeSizeLegend;
