import { RightSidebar } from 'components';
import useRequest from 'hooks/useRequest';
import React, { useEffect, useMemo, useState } from 'react';
import getServerlessMetrics from 'requests/getServerlessMetrics';
import { DateSelection } from 'types/DateSelection';
import { FilterMethodByType } from 'types/Filter';
import ServerlessRightSidebar from './ServerlessRightSidebar';
import { infrastructureErrors } from 'utils/error/infrastructureErrors';

type ServerlessRightSidebarWrapperProps = {
  activeServerlessFunction: Record<string, string>;
  colorsByFunctionName: Record<string, string>;
  date: DateSelection;
  sidebarFilterState: FilterMethodByType<'map'>;
  setActiveServerlessFunction: (row: any) => void;
  getServerlessFunctionsRequest: any;
};

const ServerlessRightSidebarWrapper = ({
  activeServerlessFunction,
  colorsByFunctionName,
  date,
  sidebarFilterState,
  setActiveServerlessFunction,
  getServerlessFunctionsRequest,
}: ServerlessRightSidebarWrapperProps) => {
  const [error, setError] = useState({
    getServerlessMetrics: null,
  });
  const getServerlessMetricsRequest = useRequest(
    getServerlessMetrics,
    true,
    true,
  );

  useEffect(() => {
    getServerlessMetricsRequest
      .call(date)
      .then((nextResult) => {
        if (nextResult) {
          setError((prevError) => ({ ...prevError, getTraces: null }));
        }
      })
      .catch(() => {
        setError((prevError) => ({
          ...prevError,
          getServerlessMetrics: {
            message: infrastructureErrors.getServerlessMetrics,
          },
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const serverlessMetrics = useMemo(
    () => getServerlessMetricsRequest.result || [],
    [getServerlessMetricsRequest.result],
  );

  if (activeServerlessFunction)
    return (
      <RightSidebar
        className="serverless__right-sidebar"
        close={() => setActiveServerlessFunction(null)}
        title={activeServerlessFunction?.functionArn}
      >
        <ServerlessRightSidebar
          colorsByFunctionName={colorsByFunctionName}
          configurationByFunctionName={
            getServerlessFunctionsRequest.result || {}
          }
          date={date}
          activeServerlessFunction={activeServerlessFunction}
          sidebarFilterState={sidebarFilterState}
          serverlessMetrics={serverlessMetrics}
          error={error}
        />
      </RightSidebar>
    );

  return null;
};

export default ServerlessRightSidebarWrapper;
