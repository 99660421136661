const evaluateMathExpression = (str: string): number => {
  let total = 0;
  let currentNumber = 0;
  let lastOperation = '+';

  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (char >= '0' && char <= '9') {
      currentNumber = currentNumber * 10 + Number(char);
    }

    if (['+', '-', '*', '/'].includes(char) || i === str.length - 1) {
      switch (lastOperation) {
        case '+':
          total += currentNumber;
          break;
        case '-':
          total -= currentNumber;
          break;
        case '*':
          total *= currentNumber;
          break;
        case '/':
          total /= currentNumber;
          break;
      }

      currentNumber = 0;
      lastOperation = char;
    }
  }

  return total;
};

export default evaluateMathExpression;
