import { AutocompleteV2, Button, Input, TooltipTrigger } from 'components';
import React, { ReactElement } from 'react';
import { parseMetricLabelQuery } from 'utils';
import { IoMdClose } from 'react-icons/io';

import useTracesCardinality from './useTracesCardinality';
import { traceCardinalityMatcher } from './utils';

const TracesCardinalityLabelValue = ({
  labelIndex,
  labels,
  labelType,
  onLabelChange,
  series,
  tracesCardinality,
}: {
  labelIndex: number;
  labels: string[];
  labelType: 'hcLabels' | 'lcLabels';
  onLabelChange?: (label: string, operator: string) => void;
  series: string;
  tracesCardinality: ReturnType<typeof useTracesCardinality>;
}): ReactElement => {
  const { labelValuePicker, updateTracesCardinalityQuery } = tracesCardinality;
  const { labelListMap, labelValueListMap } = labelValuePicker;

  const { label, value, operator } = parseMetricLabelQuery(series);
  const matcherType = labelType === 'hcLabels' ? 'Hc' : 'Lc';
  const labelMatcher = traceCardinalityMatcher(
    labels,
    labelIndex - 1,
    matcherType,
  );

  return (
    <div className="button-group" data-testid="cardinality-query">
      {labelIndex === 0 && (
        <div className="button-group__item button-group__item--label">
          {labelType === 'hcLabels' ? 'High Cardinality' : 'Low Cardinality'}
        </div>
      )}
      <div className="button-group__item button-group__item--value">
        <AutocompleteV2
          className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value"
          isLoading={labelListMap[labelMatcher]?.isLoading}
          onChange={(val: string) => onLabelChange(val, operator)}
          options={labelListMap[labelMatcher]?.data || []}
          placeholder={'Select a label'}
          value={label}
        />
      </div>
      <div className="button-group__item button-group__item--value">
        <AutocompleteV2
          className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value"
          isSearchable={false}
          onChange={(op) => {
            const newLabel = `${label}${op}"${value}"`;
            const newLabels = [...labels];
            newLabels[labelIndex] = newLabel;
            updateTracesCardinalityQuery(labelType, newLabels);
          }}
          options={[
            { label: '=', value: '=' },
            { label: '!=', value: '!=' },
          ]}
          value={operator}
          placeholder={'Select a operator'}
        />
      </div>
      <div className="button-group__item button-group__item--value button-group__item__input--value">
        {labelType === 'lcLabels' && (
          <AutocompleteV2
            className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value"
            isLoading={labelValueListMap[label]?.isLoading}
            onChange={(val: string) => {
              const newLabel = `${label}${operator}"${val}"`;
              const newLabels = [...labels];
              newLabels[labelIndex] = newLabel;
              updateTracesCardinalityQuery(labelType, newLabels);
            }}
            options={labelValueListMap[label]?.data || []}
            placeholder={'Select a value'}
            value={value as string}
          />
        )}
        {labelType === 'hcLabels' && (
          <Input
            className="input--no-border"
            onChange={(val) => {
              const newLabel = `${label}${operator}"${val}"`;
              const newLabels = [...labels];
              newLabels[labelIndex] = newLabel;
              updateTracesCardinalityQuery(labelType, newLabels);
            }}
            type="text"
            value={value as string}
            placeholder="Enter a value"
          />
        )}
      </div>

      <TooltipTrigger
        className="button-group__item button-group__item__no-padding flex"
        tooltip="Delete"
      >
        <Button
          className="h-full metrics__function-builder__item__close"
          variant="ghost"
          size="sm"
          onClick={() => {
            if (labels.length === 1) {
              updateTracesCardinalityQuery(labelType, ['=""']);
              return;
            }
            const newLabels = [...labels];
            newLabels.splice(labelIndex, 1);
            updateTracesCardinalityQuery(labelType, newLabels);
          }}
          data-testid="clear-query"
        >
          <IoMdClose size={16} />
        </Button>
      </TooltipTrigger>
    </div>
  );
};

export default TracesCardinalityLabelValue;
