import { fetchJson } from 'requests';

export type InactivePeriod = {
  startTimeUnixMs: number;
  endTimeUnixMs: number;
};

export type RawSegmentMetadata = {
  index: number;
  startTs: number;
  endTs: number;
  firstFullSnapshotTs: number;
  segmentKey: string;
};

export type RawTabMetadata = {
  tabId: string;
  startTs: number;
  endTs: number;
  segments: RawSegmentMetadata[];
};

export type RawSessionMetadata = {
  tabs: RawTabMetadata[];
};

type Args = {
  applicationId: string;
  sessionId: string;
};

const getRrwebSegmentsMetadata = ({
  applicationId,
  sessionId,
}: Args): Promise<RawSessionMetadata> =>
  fetchJson<RawSessionMetadata>(
    `/rum/rrwebsegments/metadata?application_id=${applicationId}&session_id=${sessionId}`,
  ).then((result) => (result.tabs ? result : { tabs: [] }));

export default getRrwebSegmentsMetadata;
