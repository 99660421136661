import {
  ChipWithLabel,
  DateFormatter,
  FacetPickerValuesItemPopoverPanel,
  PopoverPosition,
  PopoverTriggerV2,
  useColumnsState,
} from 'components';
import { useKeyExistsState } from 'hooks';
import {
  colorsByLogLevel,
  CoreSourceLabel,
  dateTimeFormatWithMilliseconds,
} from 'kfuse-constants';
import React, { MutableRefObject, ReactElement } from 'react';
import { formatLogLineParts, formatLogMessage, parseFacetKey } from 'utils';
import LogsLogLineParts from './LogsLogLineParts';
import LogsMessage from './LogsMessage';
import { useLogsWorkbooksState } from './hooks';
import { findFacetValueFromLogEvent } from './utils';

type MessageColumnArgs = {
  containerRef?: MutableRefObject<HTMLDivElement>;
  customColumnsState?: ReturnType<typeof useKeyExistsState>;
  disableAddCustomColumn?: boolean;
  logsState: ReturnType<typeof useLogsWorkbooksState>['currentLogsState'];
  shouldDisableFilterActions?: boolean;
};

type Row = {
  labels: { [key: string]: string };
};

export const HIDE_AUTOGENERATED_PREFIXS = [
  '_duration',
  '_number',
  '_ipv4_address',
  '_uuid',
];

const overrideFirstColumnWithLogLevelChip = (columns) => {
  if (columns.length) {
    const firstColumn = { ...columns[0] };
    const { key, renderCell } = firstColumn;
    const nextRenderCell = (args) => {
      const { row } = args;
      const label = renderCell ? renderCell(args) : row[key];
      return (
        <ChipWithLabel color={colorsByLogLevel[row.level]} label={label} />
      );
    };

    firstColumn.renderCell = nextRenderCell;

    return [firstColumn, ...columns.slice(1)];
  }

  return columns;
};

const labels = {
  CONTAINER_NAME: 'labels.kube_container_name',
  HOST: 'labels.host',
  IMAGE: 'image',
  KUBE_NAMESPACE: 'labels.kube_namespace',
  KUBE_SERVICE: 'labels.kube_service',
  KUBE_CLUSTER_NAME: 'labels.kube_cluster_name',
  LOG_LEVEL: 'logLevel',
  MESSAGE: 'message',
  POD_NAME: 'labels.pod_name',
  SOURCE: 'labels.source',
  TIMESTAMP: 'timestamp',
};

export const defaultLogsTableColumnsBitmap = Object.fromEntries(
  Object.values(labels).map((label) => [label, 1]),
);

export const {
  CONTAINER_NAME,
  HOST,
  IMAGE,
  KUBE_NAMESPACE,
  KUBE_SERVICE,
  KUBE_CLUSTER_NAME,
  LOG_LEVEL,
  MESSAGE,
  POD_NAME,
  SOURCE,
  TIMESTAMP,
} = labels;

const CONTAINER_NAME_COLUMN = {
  key: CONTAINER_NAME,
  label: 'Container Name',
  renderCell: ({ row }) => row?.labels?.kube_container_name,
  width: 100,
};
const HOST_COLUMN = {
  key: HOST,
  label: 'Host',
  renderCell: ({ row }) => row?.labels?.host,
  width: 100,
};
const KUBE_NAMESPACE_COLUMN = {
  key: KUBE_NAMESPACE,
  label: 'Kube Namespace',
  renderCell: ({ row }) => row?.labels?.kube_namespace,
  width: 100,
};
const KUBE_SERVICE_COLUMN = {
  key: KUBE_SERVICE,
  label: 'Kube Service',
  renderCell: ({ row }) => row?.labels?.kube_service,
  width: 100,
};
const KUBE_CLUSTER_NAME_COLUMN = {
  key: KUBE_CLUSTER_NAME,
  label: 'Kube Cluster Name',
  renderCell: ({ row }) => row?.labels?.kube_cluster_name,
  width: 150,
};
const MESSAGE_COLUMN = ({
  containerRef,
  customColumnsState,
  disableAddCustomColumn,
  logsState,
  shouldDisableFilterActions,
}: MessageColumnArgs) => ({
  key: MESSAGE,
  label: 'Message',
  renderCell: ({
    offsetX,
    offsetY,
    row,
  }: {
    offsetX: number;
    offsetY: number;
    row: any;
    tableRef: MutableRefObject<HTMLTableElement>;
    wrapText: boolean;
    offsetX: number;
    offsetY: number;
  }): ReactElement => {
    if (row.message) {
      return <LogsMessage logEvent={row} />;
    }

    if (row.logLineParts) {
      return (
        <LogsLogLineParts
          containerRef={containerRef}
          customColumnsState={customColumnsState}
          disableAddCustomColumn={disableAddCustomColumn}
          fpHash={row.fpHash}
          logLineParts={row.logLineParts}
          logsState={logsState}
          offsetX={offsetX}
          offsetY={offsetY}
          shouldDisableFilterActions={shouldDisableFilterActions}
          shouldTruncateLogLinePart
          source={row.labels.source}
        />
      );
    }

    return null;
  },
  width: 800,
  value: ({ row }) => {
    if (row.message) {
      return formatLogMessage(row.message);
    }

    if (row.logLineParts) {
      return formatLogLineParts(row.logLineParts);
    }

    return '';
  },
});

const POD_NAME_COLUMN = {
  key: POD_NAME,
  label: 'Pod Name',
  renderCell: ({ row }) => row?.labels?.pod_name,
  width: 100,
};
const SOURCE_COLUMN = ({
  logsState,
}: {
  logsState: ReturnType<typeof useLogsWorkbooksState>['currentLogsState'];
}) => ({
  key: SOURCE,
  label: 'Source',
  renderCell: ({ row }: { row: Row }) => {
    return (
      <PopoverTriggerV2
        className="facet-picker__values__item__label"
        popover={({ close }) => (
          <FacetPickerValuesItemPopoverPanel
            close={close}
            excludeFacetValue={() => {
              logsState.filterOnlyFacetValueFromLogEventDetail({
                name: CoreSourceLabel.name,
                source: CoreSourceLabel.component,
                type: CoreSourceLabel.type,
                value: row?.labels?.source,
                exclude: true,
              });
            }}
            selectOnlyFacetValue={() => {
              logsState.filterOnlyFacetValueFromLogEventDetail({
                name: CoreSourceLabel.name,
                source: CoreSourceLabel.component,
                type: CoreSourceLabel.type,
                value: row?.labels?.source,
              });
            }}
            value={row?.labels?.source}
          />
        )}
        position={PopoverPosition.BOTTOM}
      >
        {row?.labels?.source}
      </PopoverTriggerV2>
    );
  },
  width: 160,
});
const TIMESTAMP_COLUMN = {
  key: TIMESTAMP,
  label: 'Date',
  renderCell: ({ row }: { row: { timestamp: number } }) => (
    <DateFormatter
      formatString={dateTimeFormatWithMilliseconds}
      unixTimestamp={row.timestamp}
    />
  ),
  width: 160,
};

export const getColumns = ({
  customColumnsState,
  disableAddCustomColumn,
  logsState,
  shouldDisableFilterActions,
}: MessageColumnArgs) => {
  return [
    CONTAINER_NAME_COLUMN,
    HOST_COLUMN,
    KUBE_NAMESPACE_COLUMN,
    KUBE_SERVICE_COLUMN,
    KUBE_CLUSTER_NAME_COLUMN,
    MESSAGE_COLUMN({
      customColumnsState,
      disableAddCustomColumn,
      logsState,
      shouldDisableFilterActions,
    }),
    POD_NAME_COLUMN,
    SOURCE_COLUMN({ logsState }),
    TIMESTAMP_COLUMN,
    ...getCustomColumns(customColumnsState, logsState),
  ];
};

const getCustomColumns = (
  customColumnsState: ReturnType<typeof useKeyExistsState>,
  logsState?: ReturnType<typeof useLogsWorkbooksState>['currentLogsState'],
) => {
  const customColumns = Object.keys(customColumnsState.state).length
    ? customColumnsState.state
    : logsState.customColumns || {};

  return Object.keys(customColumns).map((key) => {
    const { name } = parseFacetKey(key);
    const getValue = ({ row }: { row: Row }) => {
      return findFacetValueFromLogEvent({ key, logEvent: row });
    };
    return {
      key,
      label: name,
      renderCell: getValue,
      width: 160,
      value: getValue,
    };
  });
};

type TableColumnsArgs = {
  containerRef?: MutableRefObject<HTMLDivElement>;
  columnsState: ReturnType<typeof useColumnsState>;
  customColumnsState?: ReturnType<typeof useKeyExistsState>;
  disableAddCustomColumn?: boolean;
  logsState: ReturnType<typeof useLogsWorkbooksState>['currentLogsState'];
  shouldDisableFilterActions?: boolean;
};

const getFilteredCustomColumns = (customColumnsState, logsState) => {
  const columns = getCustomColumns(customColumnsState, logsState).filter(
    (column) => {
      if (column.key in customColumnsState.state) {
        return customColumnsState.state[column.key];
      }

      const logsStateCustomColumns = logsState?.customColumns;
      return logsStateCustomColumns?.[column.key];
    },
  );
  return columns;
};

export const tableColumns = ({
  containerRef,
  columnsState,
  customColumnsState,
  disableAddCustomColumn,
  logsState,
  shouldDisableFilterActions,
}: TableColumnsArgs) => {
  const predefinedColumns = [
    TIMESTAMP_COLUMN,
    CONTAINER_NAME_COLUMN,
    HOST_COLUMN,
    KUBE_NAMESPACE_COLUMN,
    KUBE_SERVICE_COLUMN,
    KUBE_CLUSTER_NAME_COLUMN,
    POD_NAME_COLUMN,
    SOURCE_COLUMN({ logsState }),
    MESSAGE_COLUMN({
      containerRef,
      customColumnsState,
      disableAddCustomColumn,
      logsState,
      shouldDisableFilterActions,
    }),
  ];

  const customFilteredColumns = getFilteredCustomColumns(
    customColumnsState,
    logsState,
  );
  const predefinedAndCustomColumns = [
    ...predefinedColumns,
    ...customFilteredColumns,
  ];
  const filteredCustom = predefinedAndCustomColumns.filter((column) =>
    Boolean(
      columnsState.state.selectedColumns[column.key] ||
        logsState?.customColumns?.[column.key],
    ),
  );

  return overrideFirstColumnWithLogLevelChip(
    filteredCustom.map((column) => ({
      ...column,
      width: columnsState.state.resizedWidths[column.key] || column.width,
    })),
  );
};
