import { fetchJson } from 'requests';

import { PolicyProps } from '../types';

const getPolicyList = async (): Promise<PolicyProps[]> => {
  const result = await fetchJson(`rbac/policies/`);

  return (result as PolicyProps[]) || [];
};

export default getPolicyList;
