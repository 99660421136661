import React from 'react';

const LegendKeyValueFormatter = ({ text }: { text: string }) => {
  const segments = (text || '').split(', ');
  return (
    <div>
      {segments.map((segment, index) => {
        const [key, value, other] = segment.split(':');
        if (key && value && !other) {
          return (
            <span
              className={index === segments.length - 1 ? '' : 'mr-1'}
              key={index}
            >
              <strong>{key}</strong>: {value}{' '}
              {index === segments.length - 1 ? '' : ','}
            </span>
          );
        } else {
          return (
            <span
              className={index === segments.length - 1 ? '' : 'mr-1'}
              key={index}
            >
              {segment}
              {index === segments.length - 1 ? '' : ','}
            </span>
          );
        }
      })}
    </div>
  );
};

export default LegendKeyValueFormatter;
