import React, { ReactElement, useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

type Item = {
  id: string;
  content: React.ReactNode;
};

const reorder = (list: Item[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const DraggableList = ({
  items,
  renderList,
}: {
  items: Item[];
  renderList: (list: Item[]) => React.ReactNode;
}): ReactElement => {
  const [list, setList] = useState<Item[]>(items);

  const onDragEnd = (result: {
    destination: { index: number };
    source: { index: number };
  }) => {
    if (!result.destination) {
      return;
    }
    const newList = reorder(
      list,
      result.source.index,
      result.destination.index,
    );

    setList(newList);
  };

  useEffect(() => {
    setList(items);
  }, [items]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="flex flex-wrap"
          >
            {renderList(list)}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
