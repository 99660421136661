import uPlot from 'uplot';
import { EventListProps, LayoutType, TooltipCoordsProps } from 'types';

import { drawVersionPointSingle } from './drawVersionPoints';
import { ctaColor, themeColorDark, themeColorLight } from '../colors';
import { canvasPadding } from './tooltipUtils';

/**
 * `getServiceVersionTooltip` is a function that calculates the tooltip coordinates for a service version point on a uPlot chart.
 * It takes an object with three properties: `darkModeEnabled`, `layout`, and `u`.
 * @param {boolean} darkModeEnabled - A boolean indicating whether dark mode is enabled.
 * @param {LayoutType} layout - The layout type of the chart.
 * @param {uPlot} u - The uPlot instance on which to calculate the tooltip coordinates.
 * @returns {TooltipCoordsProps} The coordinates for the tooltip.
 */
export const getServiceVersionTooltip = ({
  darkModeEnabled,
  layout,
  u,
}: {
  darkModeEnabled: boolean;
  layout: LayoutType;
  u: uPlot;
}): TooltipCoordsProps => {
  const POINT_RADIUS = 6;
  const padding = canvasPadding(u);
  const devicePixelRatio = window.devicePixelRatio || 1;
  if (u.cursor.left < 0 || u.cursor.top < 0) return;
  const pointIdx = u.posToIdx(u.cursor.left);
  const pointColor = darkModeEnabled
    ? themeColorDark.dark12
    : themeColorLight.light11;

  const versionPoints = u.versionPoints;

  if (!versionPoints) return;
  const event = versionPoints.get(pointIdx);
  if (!event) {
    resetServiceVersionPoints({ padding, pointColor, versionPoints, u });
    return;
  }

  const circle = { x: event.clientX, y: event.clientY };
  const mouseX = u.cursor.left * devicePixelRatio + padding.left;
  const mouseY = u.cursor.top * devicePixelRatio + padding.top;
  const dist = Math.hypot(circle.x - mouseX, circle.y - mouseY);

  if (dist > POINT_RADIUS) {
    resetServiceVersionPoints({ padding, pointColor, versionPoints, u });
    return;
  }

  const bbox = u.root.getBoundingClientRect();
  versionPoints.set(pointIdx, { ...event, hovered: true });
  drawVersionPointSingle({
    bottomY: circle.y,
    leftX: circle.x,
    pointColor: ctaColor.blue,
    topY: padding.top,
    u,
  });

  const xAdjusted = circle.x / devicePixelRatio;
  const yAdjusted = circle.y / devicePixelRatio;
  if (layout === 'modal') {
    // All modal that has a chart must maintain a margin offset of 24px
    const MODEL_MARGIN_OFFSET = 24;
    return {
      data: { label: event.event.text, value: '' },
      positionX: circle.x > u.width / 2 ? 'left' : 'right',
      positionY: 'top',
      x: xAdjusted + bbox.left - MODEL_MARGIN_OFFSET,
      y:
        circle.y +
        bbox.top -
        MODEL_MARGIN_OFFSET -
        padding.top / devicePixelRatio -
        POINT_RADIUS * devicePixelRatio,
    };
  }

  const TOP_OFFSET = 8;
  return {
    data: { label: event.event.text, value: '' },
    x: xAdjusted + bbox.left,
    y:
      yAdjusted +
      bbox.top -
      padding.top / devicePixelRatio -
      POINT_RADIUS * devicePixelRatio -
      TOP_OFFSET,
  };
};

/**
 * `resetServiceVersionPoints` is a function that resets the version points on a uPlot chart.
 * It takes an object with four properties: `padding`, `pointColor`, `versionPoints`, and `u`.
 *
 * @param {ReturnType<typeof canvasPadding>} padding - The padding of the chart.
 * @param {string} pointColor - The color to use for the points.
 * @param {uPlot} u - The uPlot instance on which to reset the points.
 * @param {Map<number, { event: EventListProps; clientX: number; clientY: number; hovered: boolean }>} versionPoints - The version points to reset.
 *
 * The function iterates over the `versionPoints`, sets the `hovered` property of each point to `false`, and redraws the point with the original color.
 */
const resetServiceVersionPoints = ({
  padding,
  pointColor,
  versionPoints,
  u,
}: {
  padding: ReturnType<typeof canvasPadding>;
  pointColor: string;
  u: uPlot;
  versionPoints: Map<
    number,
    {
      event: EventListProps;
      clientX: number;
      clientY: number;
      hovered: boolean;
    }
  >;
}) => {
  const hoveredEvents = Array.from(versionPoints).filter(
    ([, point]) => point.hovered,
  );
  hoveredEvents.forEach(([idx, point]) => {
    versionPoints.set(idx, { ...point, hovered: false });
    drawVersionPointSingle({
      bottomY: point.clientY,
      leftX: point.clientX,
      pointColor,
      topY: padding.top,
      u,
    });
  });
};
