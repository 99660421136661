import React, { ReactElement } from 'react';
import { LayoutType } from 'types';

import { useHostmapTooltip } from './hooks';
import { KfuseHostmapOptions } from './types';
import { tooltipFormatter } from '../Timeseries/utils';
import {
  CompactTooltipContainerV2,
  CompactTooltipText,
} from '../Timeseries/Tooltip';

const HostmapTooltip = ({
  unit,
  layoutType,
  config,
}: {
  config: KfuseHostmapOptions;
  layoutType?: LayoutType;
  unit?: string;
}): ReactElement => {
  const { coords } = useHostmapTooltip({ config, layoutType });

  const renderTooltip = () => {
    return (
      <CompactTooltipContainerV2 coords={coords}>
        <CompactTooltipText
          color={coords.data.color as string}
          label={coords.data.label}
          position={coords.positionX}
          value={tooltipFormatter(coords.data.value, unit)}
        />
      </CompactTooltipContainerV2>
    );
  };

  return <>{coords && renderTooltip()}</>;
};

export default HostmapTooltip;
