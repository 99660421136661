import { useSearchParams } from 'react-router-dom';
import { tryJsonParse } from 'utils';
import useFiltersStates from './useFiltersStates';
import { Filter } from './types';

type Options = {
  initialState?: Filter[];
  shouldWriteToUrl?: boolean;
  urlStateKey?: string;
};

const useFiltersState = (options?: Options) => {
  const initialState = options?.initialState || [];
  const shouldWriteToUrl = options?.shouldWriteToUrl || false;
  const urlStateKey = options?.urlStateKey || 'filters';

  const [params] = useSearchParams();
  const initialStateFromParams = params.get(urlStateKey);
  const parsedInitialStateFromParams =
    tryJsonParse(initialStateFromParams) || [];

  const { getFiltersStateByIndex } = useFiltersStates({
    initialState: [
      shouldWriteToUrl ? parsedInitialStateFromParams : initialState,
    ],
    shouldWriteToUrl,
  });

  return getFiltersStateByIndex(0);
};

export default useFiltersState;
