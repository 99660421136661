import { AutocompleteV2 } from 'components';
import React, { ReactElement } from 'react';
import {
  DashboardPanelProps,
  DashboardTemplateValueProps,
} from 'types/Dashboard';

import {
  DashboardPanelModalFooter,
  DashboardPanelModalHeader,
} from '../components';
import DashboardEditPanelName from './DashboardEditPanelName';
import { useDashboardEditState } from './hooks';

const DashboardEditRows = ({
  close,
  dashboardEditState,
  panel,
  templateValues,
}: {
  close: () => void;
  dashboardEditState: ReturnType<typeof useDashboardEditState>;
  panel: DashboardPanelProps;
  templateValues?: DashboardTemplateValueProps;
}): ReactElement => {
  const { editPanel, onSaveClickRows, setEditPanel } = dashboardEditState;

  return (
    <div className="dashboard__panel-modal__row">
      <DashboardPanelModalHeader
        close={close}
        modalDate={null}
        setModalDate={null}
        title={!panel ? 'Add Row' : editPanel.title || 'Edit Row'}
      />
      <div className="dashboard-edit__metric__body">
        <div className="p-2">
          <div className="pt-2 pb-2">
            <div>Row title</div>
            <DashboardEditPanelName
              dashboardEditState={dashboardEditState}
              noPlaceholder
            />
          </div>
          <div className="mt-1">
            <div>Repeat for</div>
            <AutocompleteV2
              isSearchable={false}
              options={Object.keys(templateValues).map((val) => ({
                label: val,
                value: val,
              }))}
              onChange={(val: string) =>
                setEditPanel((prev) => ({ ...prev, repeat: val }))
              }
              value={editPanel.repeat}
            />
          </div>
        </div>
      </div>
      <DashboardPanelModalFooter close={close} onSave={onSaveClickRows} />
    </div>
  );
};

export default DashboardEditRows;
