import { FormulaProps, ExplorerQueryProps } from 'types';

import { ConditionProps } from '../AlertsCreateCondition';
import { validateAlertThresholdValue } from './conditionValidator';
import customAnnotationsValidator from './customAnnotationsValidator';
import { AlertType, AlertsCreateDetailsProps } from '../types';
import { validateAlertMetricQueryKey } from './validateAlertMetricQueryKey';
import { validateAlertDetails } from './alertsDetailsValidator';
/**
 * Order of threshold alert to be validated.
 * 1. Selected query exist
 * 2. Promql is valid and has metric selected
 * 3. Threshold value are correct
 * 4. Validate details of alert like name, folder
 * 5. Validate runbook must be a URL
 * 6. Custom annotations are not used from reserve
 */
const thresholdAlertValidatorForCreate = ({
  alertsDetails,
  condition,
  formulas,
  queries,
}: {
  alertsDetails: AlertsCreateDetailsProps;
  condition: ConditionProps;
  formulas: FormulaProps[];
  queries: ExplorerQueryProps[];
}): string | { [key: string]: string } => {
  const { queryKey, value } = condition;

  // This will validate 1 and 2
  const validateQuery = validateAlertMetricQueryKey({
    formulas,
    queryKey,
    queries,
  });
  if (validateQuery) return validateQuery;

  // 3. Threshold value are correct
  const validateThreshold = validateAlertThresholdValue({
    value,
    alertType: AlertType.THRESHOLD,
  });
  if (Object.keys(validateThreshold).length) return validateThreshold;

  // This will validate 4 and 5
  const validateDetails = validateAlertDetails(alertsDetails);
  if (Object.keys(validateDetails).length) return validateDetails;

  // This will validate 6
  const validateCustomAnnotations = customAnnotationsValidator(
    alertsDetails.customAnnotations,
  );
  if (validateCustomAnnotations) return validateCustomAnnotations;

  return {};
};

export default thresholdAlertValidatorForCreate;
