import { useColumnsState, useTableOptions } from 'components';
import { useKeyExistsState } from 'hooks';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { useLogsTable } from 'screens/Logs/hooks';
import { getColumns, MESSAGE, SOURCE, TIMESTAMP } from 'screens/Logs/constants';
import { LogsState } from 'types';
import BasicLogsSheetInner from './BasicLogsSheetInner';

type Props = {
  logsState: LogsState;
  renderToolbarLeft: () => ReactNode;
  disableToolbar?: boolean;
  disableOnlySaveQueryInToolBar?: boolean;
  disableOnlyAddToDashboardInToolBar?: boolean;
};

const BasicLogsSheet = ({
  logsState,
  renderToolbarLeft,
  disableToolbar,
  disableOnlySaveQueryInToolBar,
  disableOnlyAddToDashboardInToolBar,
}: Props) => {
  const tableOptions = useTableOptions();
  const customColumnsState = useKeyExistsState({
    urlStateKey: 'customColumns',
    shouldWriteToUrl: false,
  });

  const baseColumns = useMemo(
    () => getColumns({ customColumnsState, logsState }),
    [customColumnsState, logsState],
  );
  const columnsState = useColumnsState({
    columns: baseColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: { [MESSAGE]: 1, [SOURCE]: 1, [TIMESTAMP]: 1 },
    },
    key: 'logs-table',
    shouldUseLocalStorage: false,
  });

  const {
    facetFilters,
    filterByFacets,
    filterOrExcludeByFingerprint,
    keyExists,
    date,
    selectedFacetValues,
    selectedFacetValuesWithOr,
    searchTerms,
    searchTermsWithOr,
  } = logsState;

  const logsTable = useLogsTable();
  const { appendLogs, generateNewId, logs, sort } = logsTable;

  const fetchLogs = () => {
    generateNewId();
    return logsTable.fetchLogs({
      logsState: {
        date,
        selectedFacetValues,
        selectedFacetValuesWithOr,
        facetFilters,
        filterOrExcludeByFingerprint,
        keyExists,
        searchTerms,
        searchTermsWithOr,
        sort,
      },
    });
  };

  const onScrollEnd = () => {
    appendLogs({
      date,
      filterByFacets,
      filterOrExcludeByFingerprint,
      keyExists,
      selectedFacetValues,
      searchTerms,
    });
  };

  useEffect(
    () => {
      fetchLogs();
    },
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    [
      date,
      filterByFacets,
      filterOrExcludeByFingerprint,
      keyExists,
      selectedFacetValues,
      searchTerms,
      sort,
    ],
  );

  return (
    <BasicLogsSheetInner
      disableToolbar={disableToolbar}
      disableOnlySaveQueryInToolBar={disableOnlySaveQueryInToolBar}
      disableOnlyAddToDashboardInToolBar={disableOnlyAddToDashboardInToolBar}
      columnsState={columnsState}
      customColumnsState={customColumnsState}
      logsState={logsState}
      logs={logs}
      logsTable={logsTable}
      onScrollEnd={onScrollEnd}
      renderToolbarLeft={renderToolbarLeft}
      shouldDisableFilterActions
      tableOptions={tableOptions}
    />
  );
};

export default BasicLogsSheet;
