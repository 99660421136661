import { ReactNode } from 'react';

export enum JsonType {
  array = 'array',
  boolean = 'boolean',
  null = 'null',
  number = 'number',
  object = 'object',
  string = 'string',
  undefined = 'undefined',
}

export type JsonOptions = {
  ignoreEmptyValues?: boolean;
  renderValue?: ({ label, path, type, value }) => ReactNode;
};
