import { onPromiseError } from 'utils';

import fetchJson from './fetchJson';

const deleteDashboardByUid = (uid: string): any => {
  return fetchJson('/grafana/api/dashboards/uid/' + uid, {
    method: 'DELETE',
  }).then((result) => result || {}, onPromiseError);
};

export default deleteDashboardByUid;
