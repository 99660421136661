import { DataFrame } from '../types';

const filterLogqlFormulaMatchingLabels = ({
  dataFrame,
  labelBitmap,
}: {
  dataFrame: DataFrame;
  labelBitmap: { [key: string]: boolean };
}): DataFrame => {
  const { data } = dataFrame;

  const filteredData = data.filter(({ displayLabel }) => {
    return labelBitmap[displayLabel];
  });

  return { ...dataFrame, data: filteredData };
};

export default filterLogqlFormulaMatchingLabels;
