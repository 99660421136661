import { Dispatch, SetStateAction } from 'react';
import { Trace } from 'types';

export type Kpi = {
  key: string;
  query: string;
};

export enum Property {
  anomaly = 'anomaly',
  clientServiceName = 'client_service_name',
  downstreamDependencies = 'downstreamDependencies',
  errors = 'errors',
  forecast = 'forecast',
  infraHosts = 'infraHosts',
  infraHostDisks = 'infraHostDisks',
  infraOLTPHostDisk = 'infraOLTPHostDisk',
  infraOLTPDockerContainer = 'infraOLTPDockerContainer',
  infraOLTPDockerNode = 'infraOLTPDockerNode',
  infraOLTPHost = 'infraOLTPHost',
  infraPod = 'infraPod',
  infraNode = 'infraNode',
  infraVolume = 'infraVolume',
  infraRuntimeGo = 'infraRuntimeGo',
  infraRuntimeJava = 'infraRuntimeJava',
  infraRuntimePython = 'infraRuntimePython',
  infraRuntimeNodejs = 'infraRuntimeNodejs',
  infraRuntimeDatadogGo = 'infraRuntimeDatadogGo',
  infraRuntimeDatadogJava = 'infraRuntimeDatadogJava',
  infraRuntimeDatadogNodejs = 'infraRuntimeDatadogNodejs',
  infraDockerContainer = 'infraDockerContainer',
  infraDockerNode = 'infraDockerNode',
  outlier = 'outlier',
  reportPerformance = 'reportPerformance',
  reportSLA = 'reportSLA',
  serviceMap = 'serviceMap',
  spanName = 'span_name',
  serviceName = 'service_name',
  upstreamDependencies = 'upstreamDependencies',
  version = 'service_version',
  traces = 'traces',
  logs = 'logs',
}

export enum RuntimeLanguage {
  go = 'go',
  java = 'java',
  python = 'python',
  nodejs = 'nodejs',
  javascript = 'javascript',
}

export enum TelemetrySdk {
  datadog = 'datadog',
  opentelemetry = 'opentelemetry',
}

export type SidebarState = {
  activeName: string;
  activeTrace?: Trace;
  colorMap: { [key: string]: string };
};
