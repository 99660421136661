import { DashboardLogsWithMetaProps } from 'types';
import { DataTransformerConfig } from 'utils/DataTransformer';

import getLogMetricsTimeSeriesLogQLV2 from './getLogMetricsTimeSeriesLogQLV2';

export type LogQLMultipleProps = {
  instant: boolean;
  logqlWithMeta: DashboardLogsWithMetaProps[];
  mainTransformer: DataTransformerConfig[];
};

const getLogMetricsTimeSeriesLogQLMultiple = async ({
  instant = false,
  logqlWithMeta = [],
  mainTransformer = [],
}: LogQLMultipleProps) => {
  const data = await Promise.all(
    logqlWithMeta.map(({ logql, ...rest }) =>
      getLogMetricsTimeSeriesLogQLV2({ ...rest, instant, logQL: logql }),
    ),
  );
  if (mainTransformer) {
    const transformed = mainTransformer.reduce(
      (prevData, item) => item.func(prevData),
      data,
    );
    return transformed;
  }
  return data;
};

export default getLogMetricsTimeSeriesLogQLMultiple;
