import query from './query';

type Args = { facetGroup: string };

const removeLogsFavoriteFacetGroup = async ({
  facetGroup,
}: Args): Promise<Record<'removeFacetGroup', void>> => {
  return query<void, 'removeFacetGroup'>(`
    mutation {
      removeFacetGroup (
        facetGroup: "${facetGroup}"
      )
    }
  `);
};

export default removeLogsFavoriteFacetGroup;
