import { Button } from 'components';
import React, { ReactElement } from 'react';
import useTableBulkActions from './useTableBulkActions';
import { Separator } from 'components/shadcn';

const TableBulkActions = ({
  buttonTitle = 'Delete',
  tableBulkActions,
  onBulkDelete,
  selectedLabel,
}: {
  buttonTitle?: string;
  tableBulkActions: ReturnType<typeof useTableBulkActions>;
  onBulkDelete: (uids: string[]) => void;
  selectedLabel: string;
}): ReactElement => {
  const { selectedRows, selectedRowsCount } = tableBulkActions;
  if (selectedRowsCount === 0) return null;

  return (
    <div
      className="flex items-center gap-2 py-1 pl-4 pr-2"
      style={{ backgroundColor: 'var(--bgHovered)' }}
    >
      <span className="font-bold">{selectedRowsCount}</span>
      <span className="font-semibold">{selectedLabel}</span>
      <Separator className="w-[2px]" orientation="vertical" />
      <Button
        variant="outline"
        size="sm"
        onClick={() => {
          const uids = Object.keys(selectedRows);
          onBulkDelete(uids);
        }}
      >
        {buttonTitle}
      </Button>
    </div>
  );
};

export default TableBulkActions;
