import dayjs from 'dayjs';
import { DateSelection, SelectedFacetValuesByName, TimeSeries } from 'types';
import {
  convertAggregateTimeSeriesFromFloatToSeconds,
  onPromiseError,
} from 'utils';

import queryTraceService from './queryTraceService';
import { buildTracesFilter, formatTraceCardinalityTimeseries } from './utils';

type Args = {
  date: DateSelection;
  format?: 'timeseries' | 'none';
  instant?: boolean;
  keys?: string[];
  rollUpSeconds?: number;
  selectedFacetValuesByName?: SelectedFacetValuesByName;
  selectedHcFacetValuesByName?: SelectedFacetValuesByName;
  type: 'Lc' | 'Hc';
};

const traceAttributeCardinality = async ({
  date,
  format = 'none',
  instant = true,
  keys = [],
  rollUpSeconds,
  selectedFacetValuesByName = {},
  selectedHcFacetValuesByName = {},
  type,
}: Args): Promise<TimeSeries[] | any> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  return queryTraceService<TimeSeries[], 'attributeCardinality'>(`
    {
      attributeCardinality(
        durationSecs: ${durationSecs},
        filter: ${buildTracesFilter({
          facetRegex: [],
          keyExists: {},
          selectedFacetRangeByName: {},
          selectedFacetValuesByName,
          selectedHcFacetValuesByName,
          spanFilter: undefined,
        })},
        rollUpSeconds: ${rollUpSeconds || durationSecs},
        timestamp: "${endTime.format()}",
        type: ${type},
        keys: ${JSON.stringify(keys)},
      ) {
        GroupVal
        BucketStartSecs
        Value
      }
    }
  `).then((data) => {
    if (!data.attributeCardinality) return [];

    const attributeCardinality = data.attributeCardinality.map(
      convertAggregateTimeSeriesFromFloatToSeconds,
    );
    if (format === 'timeseries')
      return formatTraceCardinalityTimeseries(attributeCardinality);
    return attributeCardinality;
  }, onPromiseError);
};

export default traceAttributeCardinality;
