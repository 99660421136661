import dayjs from 'dayjs';
import { onPromiseError } from 'utils';
import queryRumService from './queryRumService';
import { RumEvent, RUMQueryServiceResponse } from '../types';
import {
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types/selectedFacets';
import { buildRumFilter } from './utils';

type Args = {
  applicationFilter: string;
  errorGroupingKey?: string;
  endTimeUnix: number;
  facetRegex: FacetRegexTerm[];
  startTimeUnix: number;
  idSearch: string;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  sortBy: string;
  sortOrder: string;
  limit: number;
};

const getActionsWithErrors = async ({
  applicationFilter,
  errorGroupingKey,
  endTimeUnix,
  facetRegex,
  startTimeUnix,
  idSearch,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  limit,
}: Args): Promise<{ data: RumEvent[]; cursor: string }> => {
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  const filterOptions = {
    applicationFilter,
    errorGroupingKey,
    facetRegex,
    idSearch,
    selectedFacetRangeByName,
    selectedFacetValuesByName,
  };

  return queryRumService<RUMQueryServiceResponse, 'eventDetail'>(`
    query {
      actionsTriggeringErrors(
        timestamp: "${endTime.format()}"
        durationSecs: ${durationSecs}
        filter: ${buildRumFilter(filterOptions)}    
        limit:${limit}
      ) {
        aggregates,
        dimensions
      }
    }
  `).then((data) => {
    return {
      data,
    };
  }, onPromiseError);
};

export default getActionsWithErrors;
