import {
  FacetPickerValuesItemPopoverPanel,
  Json,
  PopoverPosition,
  PopoverTriggerV2,
  ResourceWithStatusCode,
  StackTrace,
  Tab,
  Tabs,
  TraceErrorIssue,
  useTabs,
} from 'components';
import { FilterType, Operator, useTracesState } from 'hooks';
import React from 'react';
import { Service, TraceError } from 'types';
import TraceSidebarActiveSpanJsonValue from './TraceSidebar/TraceSidebarActiveSpanJsonValue';
import {
  useApmErrorsPageStateContext,
  useTracesPageStateContext,
} from 'context/PageStateProvider';
import { useNavigate } from 'react-router-dom';

type Props = {
  applyFilterOnErrorsPage?: boolean;
  colorsByServiceName: Record<string, string>;
  customerFilter?: { key: string; value: string };
  errorGroupingKey?: string;
  serviceByHash: Record<string, Service>;
  serviceHash?: string;
  traceError: TraceError;
  tracesState: ReturnType<typeof useTracesState>;
};

const getParsedHcLabel = (path: string): string => {
  const HC_ATTRIBUTES_PREFIX = 'span.hc_attributes.';

  if (path.startsWith(HC_ATTRIBUTES_PREFIX)) {
    const label = path.slice(HC_ATTRIBUTES_PREFIX.length);
    return `$.hc_attributes.${label.includes('[') ? label : `['${label}']`}`;
  }

  return `$.${path.replace(/^span\./, '')}`;
};

const SidebarTraceError = ({
  applyFilterOnErrorsPage,
  colorsByServiceName,
  customerFilter,
  errorGroupingKey,
  serviceByHash,
  serviceHash,
  traceError,
  tracesState,
}: Props) => {
  const { tracesState: errorPageState } = useApmErrorsPageStateContext();
  const { tracesState: tracesPageState } = useTracesPageStateContext();
  const navigate = useNavigate();

  const { method, statusCode, url } = traceError;
  const {
    selectedFacetValuesByNameState,
    selectedHcFacetValuesByNameState,
    dateState,
  } = tracesState;
  const date = dateState[0];

  const stackTrace = traceError.parsedError.exception?.stacktrace || [];

  const tabs = useTabs();

  const onOpenInErrorPage = () => {
    const filters = [
      ...(serviceHash
        ? [
            {
              type: FilterType.selectedFacetValue,
              value: {
                facet: 'service_hash',
                operator: Operator.equal,
                values: {
                  [serviceHash]: 1,
                },
              },
            },
          ]
        : []),
      {
        type: FilterType.selectedFacetValue,
        value: {
          facet: 'error_grouping_key',
          operator: Operator.equal,
          values: {
            [errorGroupingKey]: 1,
          },
        },
      },
    ];

    window.open(
      `#/apm/errors/all?apmErrorsFilters=${JSON.stringify(
        filters,
      )}&apmDate=${JSON.stringify(tracesState.dateState[0])}
      &customerFilter=${JSON.stringify(customerFilter)}
      `,
    );
  };

  const excludeFacetValueHandler =
    ({ label, selectedFacetValuesByNameState }) =>
    (value: string) => {
      if (applyFilterOnErrorsPage) {
        errorPageState.selectedFacetValuesByNameState.excludeFacetValue({
          name: label,
          value,
        });
        if (customerFilter) {
          errorPageState.setCustomerFilter(customerFilter);
        }
        navigate;
      } else {
        selectedFacetValuesByNameState.excludeFacetValue({
          name: label,
          value,
        });
      }
    };

  const selectOnlyFacetValueHandler =
    ({ label, selectedFacetValuesByNameState }) =>
    (value: string) => {
      if (applyFilterOnErrorsPage) {
        errorPageState.selectedFacetValuesByNameState.selectOnlyFacetValue({
          name: label,
          value,
        });
        if (customerFilter) {
          errorPageState.setCustomerFilter(customerFilter);
        }
        navigate(`/apm/errors/all`);
      } else {
        selectedFacetValuesByNameState.selectOnlyFacetValue({
          name: label,
          value,
        });
      }
    };

  const traceId = traceError.traceId;

  const handleNavigateTraceId = () => {
    tracesPageState.setTraceIdSearch(traceId);
    if (customerFilter) {
      tracesPageState.setCustomerFilter(customerFilter);
    }
    setTimeout(() => {
      navigate('/apm/traces/list');
    }, 50);
  };

  return (
    <div>
      <div className="apm-errors__group-sidebar__trace-error__header">
        <div className="flex--justify-content-between flex">
          <div className="text--h3">Latest Error</div>
          {serviceHash && errorGroupingKey && (
            <div className="link" onClick={onOpenInErrorPage}>
              Open in error page
            </div>
          )}
        </div>
        <div className="apm-errors__group-sidebar__trace-error__issue">
          <TraceErrorIssue
            colorsByServiceName={colorsByServiceName}
            serviceByHash={serviceByHash}
            traceError={traceError}
          />
        </div>
        <div className="apm-errors__group-sidebar__trace-error__resouce-with-status-code">
          {url && method ? (
            <ResourceWithStatusCode
              endpoint={url}
              method={method}
              statusCode={statusCode}
            />
          ) : null}
        </div>
      </div>
      {traceId && (
        <div className="ml-3">
          TraceId :
          <div className="link cursor-pointer" onClick={handleNavigateTraceId}>
            {traceId}
          </div>
        </div>
      )}
      <Tabs className="tabs--underline" tabs={tabs} dataTestId="tabs">
        <Tab label="Metadata">
          <Json
            data={{
              attributes: traceError.attributes,
              error: traceError.parsedError,
            }}
            options={{
              renderValue: ({ label, path, type, value }) => {
                const isHcAttribute = path.indexOf('span.') === 0;
                const parsedLabel = isHcAttribute
                  ? getParsedHcLabel(path)
                  : label;
                return label.indexOf('stack') > -1 ? (
                  <div className="json__value">
                    <div className={`text--${type}`}>{String(value)}</div>
                  </div>
                ) : (
                  <PopoverTriggerV2
                    className="json__value"
                    popover={({ close }) => (
                      <FacetPickerValuesItemPopoverPanel
                        close={close}
                        excludeFacetValue={excludeFacetValueHandler({
                          label: parsedLabel,
                          selectedFacetValuesByNameState: isHcAttribute
                            ? selectedHcFacetValuesByNameState
                            : selectedFacetValuesByNameState,
                        })}
                        label={`${label}:${value}`}
                        selectOnlyFacetValue={selectOnlyFacetValueHandler({
                          label: parsedLabel,
                          selectedFacetValuesByNameState: isHcAttribute
                            ? selectedHcFacetValuesByNameState
                            : selectedFacetValuesByNameState,
                        })}
                        showKeyExistsLabel={label}
                        value={String(value)}
                      ></FacetPickerValuesItemPopoverPanel>
                    )}
                    position={
                      path === 'statusCode' || path === 'startTimeNs'
                        ? PopoverPosition.TOP_LEFT
                        : PopoverPosition.BOTTOM_LEFT
                    }
                  >
                    <TraceSidebarActiveSpanJsonValue
                      label={label}
                      type={type}
                      value={value}
                    />
                  </PopoverTriggerV2>
                );
              },
            }}
          />
        </Tab>
        <Tab label="Stack Trace">
          {Array.isArray(stackTrace) ? (
            <StackTrace frames={stackTrace} />
          ) : (
            <div
              className="apm-errors__group-sidebar__trace-error__stack-trace"
              data-testid="stack-trace"
            >
              {stackTrace}
            </div>
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default SidebarTraceError;
