import { colors } from 'kfuse-constants';

const getStatusColor = (statusNumber: number) => {
  if (statusNumber >= 400) {
    return colors.red;
  }

  if (statusNumber >= 300) {
    return colors.yellow;
  }

  if (statusNumber >= 200) {
    return colors.green03;
  }

  return colors.blue;
};

export default getStatusColor;
