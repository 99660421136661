import { PopoverTriggerV2, PopoverPosition } from 'components';
import { useRequest, useToggle } from 'hooks';
import React, { ReactElement, useLayoutEffect, useRef } from 'react';
import { formatLogMessage } from 'utils';
import { useLogsState } from './hooks';
import LogsFingerprintsListItemPanel from './LogsFingerprintsListItemPanel';
import classnames from 'classnames';

const LogsFingerprintsListItemMain = ({
  clearTabFetched,
  fingerprint,
  getFpListRequest,
  logsState,
  message,
  pattern,
}: {
  clearTabFetched: VoidFunction;
  fingerprint: any;
  getFpListRequest?: ReturnType<typeof useRequest>;
  logsState: ReturnType<typeof useLogsState>;
  message: string;
  pattern: string;
}): ReactElement => {
  const { hash } = fingerprint;
  const isOverflowingToggle = useToggle();
  const showingMoreToggle = useToggle();
  const messageRef = useRef();

  useLayoutEffect(() => {
    const element = messageRef.current;
    if (element) {
      const { offsetHeight, scrollHeight } = element;
      const isOverflowing = offsetHeight < scrollHeight;
      if (isOverflowing) {
        isOverflowingToggle.on();
      } else {
        isOverflowingToggle.off();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <div className="logs__fingerprints-list__item__main">
      <PopoverTriggerV2
        className="logs__fingerprints-list__item__pattern link"
        popover={({ close }) => (
          <LogsFingerprintsListItemPanel
            clearTabFetched={clearTabFetched}
            close={close}
            fingerprint={fingerprint}
            fpPattern={pattern}
            getFpListRequest={getFpListRequest}
            hash={hash}
            logsState={logsState}
          />
        )}
        position={PopoverPosition.BOTTOM_LEFT}
      >
        {pattern}
      </PopoverTriggerV2>
      {message ? (
        <div>
          <div
            className={classnames({
              'logs__fingerprints-list__item__message': true,
              'logs__fingerprints-list__item__message--show-full':
                showingMoreToggle.value,
            })}
            ref={messageRef}
          >
            {formatLogMessage(message)}
          </div>
          {isOverflowingToggle.value ? (
            <div className="logs__fingerprints-list__item__show-more">
              <button className="link" onClick={showingMoreToggle.toggle}>
                {showingMoreToggle.value ? 'Show less' : 'Show more'}
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default LogsFingerprintsListItemMain;
