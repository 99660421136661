import { AutocompleteV2, Button, UploadBox, useToaster } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { getGrafanaFolders } from 'requests';
import { X } from 'react-feather';

const DashboardConfigureImport = ({
  close,
  onDashboardJsonImport,
}: {
  close: () => void;
  onDashboardJsonImport?: (json: any, folderUid: string) => void;
}): ReactElement => {
  const grafanaFoldersRequest = useRequest(
    () =>
      getGrafanaFolders().then((res) =>
        res.map((folder) => ({ label: folder.title, value: folder.uid })),
      ),
    true,
  );
  const { addToast } = useToaster();
  const [jsonModel, setJsonModel] = useState(null);
  const [folderUid, setFolderUid] = useState(null);

  useEffect(() => {
    grafanaFoldersRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-[600px] p-4">
      <div className="flex justify-between items-center mb-2">
        <div className="text-base font-semibold">Import Dashboard JSON</div>
        <Button onClick={close} variant="ghost" size="sm">
          <X size={16} />
        </Button>
      </div>

      <div className="pt-2">
        <label>Folder</label>
        <AutocompleteV2
          options={grafanaFoldersRequest.result || []}
          onChange={(val: string) => setFolderUid(val)}
          placeholder="Select folder (by default it will be imported to General folder)"
          isLoading={grafanaFoldersRequest.isLoading}
          value={folderUid}
        />
      </div>
      <UploadBox
        onFileUpload={(file) => {
          const reader = new FileReader();
          reader.onload = function (event) {
            const contents = event.target.result;
            try {
              const parsed = JSON.parse(contents as string);
              setJsonModel(parsed);
            } catch (e) {
              addToast({
                text: 'Uploaded file is not a valid JSON file',
                status: 'error',
              });
            }
          };
          reader.readAsText(file);
        }}
      />
      <div className="flex justify-end items-center mt-4 ">
        <Button className="mr-2" onClick={close} variant="outline">
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (jsonModel) {
              onDashboardJsonImport(jsonModel, folderUid);
            } else {
              addToast({
                text: 'Please upload a valid JSON file',
                status: 'error',
              });
            }
          }}
          variant="default"
          size="sm"
        >
          Import Dashboard
        </Button>
      </div>
    </div>
  );
};

export default DashboardConfigureImport;
