import { getKfuseQlForOutlierAlerts } from 'utils/KfuseqlBuilder';

import { AlertAnomalyQueryItem } from '../../types';
import { OutlierConditionProps } from '../../AlertsCreateCondition';

export const getOutlierKfuseqlForLoad = ({
  queryItem,
  outlierCondition,
}: {
  queryItem: AlertAnomalyQueryItem;
  outlierCondition: OutlierConditionProps;
}) => {
  const { algorithm, tolerance } = outlierCondition;
  const { band, kfuseQlOperation, step } = queryItem;
  const kfuseql = getKfuseQlForOutlierAlerts({
    algorithm,
    band,
    tolerance,
    operation: kfuseQlOperation,
    query: queryItem.kfuseql,
    step: `${step}s`,
  });

  return kfuseql;
};
