import { ReactElement, useLayoutEffect, useRef, useState } from 'react';
import { UplotExtended } from 'types/Timeseries';

import { UPlotConfig } from '../types';

const useTimeseriesAnnotationPlugin = ({ config }: { config: UPlotConfig }) => {
  const plotInstance = useRef<UplotExtended>();
  const [annotation, setAnnotation] = useState<ReactElement | null>(null);

  const close = () => {
    setAnnotation(null);
  };

  useLayoutEffect(() => {
    config.addHook('init', (u: UplotExtended) => {
      plotInstance.current = u;
      u.root.parentElement?.addEventListener('mousedown', (e) => {
        if (u.kfuseHook?.kfuseAnnotation) {
          for (const fn of u.kfuseHook.kfuseAnnotation) {
            const result = fn({ u, e, close });
            if (result) {
              setAnnotation(result);
              return;
            }
          }
        }
      });
    });

    return () => {
      plotInstance.current?.root.parentElement?.removeEventListener(
        'mousedown',
        () => {},
      );
    };
  }, [config]);

  return { annotation };
};

export default useTimeseriesAnnotationPlugin;
