import classNames from 'classnames';
import { PopoverTriggerV2, Icon } from 'components';
import React, { ReactElement } from 'react';

import { KubeTableCell } from './KubeTableComponent';
import KubernetesTagsPopover from '../../KubernetesTagsPopover';
import {
  KubeTableColumnMethods,
  KubeEntityGroupProps,
  RenderCellProps,
} from '../../types';
import { useKubernetesController } from '../../KubernetesController';

export const KubeTableGroupExpanded = ({
  row,
  isExpanded,
  ...prop
}: KubeTableColumnMethods &
  RenderCellProps & { isExpanded: boolean }): ReactElement => {
  return (
    <KubeTableCell {...prop} row={row}>
      <Icon
        icon="chevron-down"
        size="sm"
        className="size-4 shrink-0"
        style={{
          transform: isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)',
          transition: 'transform 200ms ease',
        }}
      />
    </KubeTableCell>
  );
};

export const KubeTableEntityTags = ({
  row,
  ...prop
}: KubeTableColumnMethods & RenderCellProps): ReactElement => {
  const { facetValueExclude, facetValueSet } = useKubernetesController();

  return (
    <KubeTableCell
      {...prop}
      className="kubernetes__table__cell__enetity-name__group"
      row={row}
    >
      {row.groupBys &&
        row.groupBys.map((groupData: KubeEntityGroupProps) => (
          <PopoverTriggerV2
            key={groupData.value}
            popover={({ close }) => {
              const tag = `${groupData.key}:${groupData.value}`;
              return (
                <KubernetesTagsPopover
                  tag={groupData.value}
                  onCopyToClipboard={() => {
                    navigator.clipboard.writeText(tag);
                    close();
                  }}
                  onExclude={() => {
                    facetValueExclude(groupData.key, groupData.value);
                    close();
                  }}
                  onFilter={() => {
                    facetValueSet(groupData.key, {
                      [groupData.value]: 1,
                    });
                    close();
                  }}
                />
              );
            }}
          >
            <div
              className={classNames({
                chip: groupData && groupData?.value,
              })}
            >
              {groupData?.value}
            </div>
          </PopoverTriggerV2>
        ))}
      <div className={row?.count ? 'chip' : ''}>{row?.count}</div>
    </KubeTableCell>
  );
};
