import uPlot from 'uplot';
import getPositionsBySeriesIndex from './getPositionsBySeriesIndex';

const get2DPathBySeriesIndex = (u: uPlot, seriesIndex: number): Path2D => {
  const positions = getPositionsBySeriesIndex(u, seriesIndex);
  const path = new Path2D();
  positions.forEach((position, idx) => {
    if (!position) return;
    if (idx === 0) {
      path.moveTo(position.x, position.y);
    } else {
      path.lineTo(position.x, position.y);
    }
  });

  return path;
};

export default get2DPathBySeriesIndex;
