import query from 'requests/query';
import { onPromiseError } from 'utils';

const MAX_SOURCES_LIMIT = 1000;

const getLogsSources = async (): Promise<string[]> => {
  return query<string[], 'getSources'>(`
        query GetLogsSources {
          getSources(
           limit: ${MAX_SOURCES_LIMIT},
          )
        }
      `).then((data) => data.getSources || [], onPromiseError);
};

export default getLogsSources;
