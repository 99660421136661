import { getLegacyLogsStateFromFiltersState } from 'hooks';
import { LogsMetricForumlaProps, LogsMetricQueryProps } from 'types';

import { defaultLogsQuery } from './logsExplorerDefaultQuery';

export const logsQueryTransformForAnnotations = ({
  customerFilter,
  formulas,
  queries,
  queryIndex,
  type,
  queryLangType,
}: {
  customerFilter?: { key: string; value: string };
  formulas: LogsMetricForumlaProps[];
  queries: LogsMetricQueryProps[];
  queryIndex: number;
  type: 'query' | 'formula';
  queryLangType: string;
}): string => {
  if (type === 'query') {
    const query = queries[queryIndex];
    const queryTransformed = getLogsQueryTransform(query);
    return JSON.stringify({
      queries: [queryTransformed],
      formulas: [],
      ...(customerFilter &&
        customerFilter.key &&
        customerFilter.value &&
        customerFilter.value !== 'All' && { customerFilter }),
      queryLangType,
    });
  }

  if (type === 'formula') {
    const formula = formulas[queryIndex];
    const formulaExpression = formula.expression;
    const affectedQueries = queries.filter((query) =>
      formulaExpression.includes(query.queryKey),
    );

    const transformedQueries = affectedQueries.map((query) =>
      getLogsQueryTransform(query),
    );

    const transformedFormulas = getLogsFormulaTransform(formula);
    return JSON.stringify({
      queries: transformedQueries,
      formulas: [transformedFormulas],
      ...(customerFilter &&
        customerFilter.key &&
        customerFilter.value &&
        customerFilter.value !== 'All' && { customerFilter }),
      queryLangType,
    });
  }

  return '';
};

export const logsQueryTransformForAnnotationsMultiple = ({
  formulas,
  queries,
}: {
  formulas: LogsMetricForumlaProps[];
  queries: LogsMetricQueryProps[];
}): string => {
  const transformedQueries = queries.map((query) =>
    getLogsQueryTransform(query),
  );

  const transformedFormulas = formulas.map((formula) =>
    getLogsFormulaTransform(formula),
  );

  return JSON.stringify({
    queries: transformedQueries,
    formulas: transformedFormulas,
  });
};

const getLogsQueryTransformFilter = (
  filters: LogsMetricQueryProps['filters'],
) => {
  const filtersObject: LogsMetricQueryProps['filters'] = {};
  if (filters.searchTerms.length > 0) {
    filtersObject['searchTerms'] = filters.searchTerms;
  }

  if (filters.filterByFacets.length > 0) {
    filtersObject['filterByFacets'] = filters.filterByFacets;
  }

  if (Object.keys(filters.sidebarFilters || {}).length > 0) {
    filtersObject['sidebarFilters'] = filters.sidebarFilters;
  }

  if (Object.keys(filters.selectedFacetValuesByName || {}).length > 0) {
    filtersObject['sidebarFilters'] = filters.selectedFacetValuesByName;
  }

  if (Object.keys(filters.facetRangeFilters || {}).length > 0) {
    filtersObject['facetRangeFilters'] = filters.facetRangeFilters;
  }

  if (Object.keys(filters.keyExists || {}).length > 0) {
    filtersObject['keyExists'] = filters.keyExists;
  }

  return filtersObject;
};

const getLogsQueryTransform = (query: LogsMetricQueryProps) => {
  const legacyFilters = getLegacyLogsStateFromFiltersState({
    filtersState: query.filtersState,
  });
  const filter = getLogsQueryTransformFilter(legacyFilters);

  return {
    filters: filter,
    limit: query.limit,
    functions: query.functions || [],
    metric: query.metric,
    normalizeFunction: query.normalizeFunction,
    queryKey: query.queryKey,
    rangeAggregate: query.rangeAggregate,
    rangeAggregateGrouping: query.rangeAggregateGrouping,
    step: query.step,
    vectorAggregate: query.vectorAggregate,
  };
};

const getLogsFormulaTransform = (formula: LogsMetricForumlaProps) => {
  return {
    expression: formula.expression,
    queryKey: formula.queryKey,
  };
};

export const logsQueryDecodeTransform = (
  annotations: string,
): {
  queries: LogsMetricQueryProps[];
  formulas: LogsMetricForumlaProps[];
} => {
  if (!annotations) return;

  const transformedAnnotations = JSON.parse(annotations);
  if (transformedAnnotations.isTransformed === false) {
    return transformedAnnotations;
  }
  const transformedQueries = transformedAnnotations.queries?.map(
    (query: LogsMetricQueryProps) => getLogsQueryDecodeTransform(query),
  );

  const transformedFormulas = transformedAnnotations.formulas?.map(
    (formula: LogsMetricForumlaProps) => getLogsFormulaDecodeTransform(formula),
  );

  const customerFilter = transformedAnnotations.customerFilter;

  return {
    queries: transformedQueries || [],
    formulas: transformedFormulas || [],
    ...(customerFilter && { customerFilter }),
  };
};

const getLogsQueryDecodeTransformFilter = (
  filters: LogsMetricQueryProps['filters'],
) => {
  const filtersObject: LogsMetricQueryProps['filters'] = {
    searchTerms: [],
    filterByFacets: [],
    sidebarFilters: {},
    facetRangeFilters: {},
    keyExists: {},
  };
  if (filters.searchTerms?.length > 0) {
    filtersObject['searchTerms'] = filters.searchTerms;
  }

  if (filters.filterByFacets?.length > 0) {
    filtersObject['filterByFacets'] = filters.filterByFacets;
  }

  if (Object.keys(filters.sidebarFilters || {}).length > 0) {
    filtersObject['sidebarFilters'] = filters.sidebarFilters;
  }

  if (Object.keys(filters.facetRangeFilters || {}).length > 0) {
    filtersObject['facetRangeFilters'] = filters.facetRangeFilters;
  }

  if (Object.keys(filters.keyExists || {}).length > 0) {
    filtersObject['keyExists'] = filters.keyExists;
  }

  return filtersObject;
};

const getLogsQueryDecodeTransform = (query: LogsMetricQueryProps) => {
  const filter = getLogsQueryDecodeTransformFilter(query.filters);

  const transformedQuery = {
    ...defaultLogsQuery,
    filters: filter,
    metric: query.metric,
    functions: query.functions || [],
    normalizeFunction: query.normalizeFunction,
    queryKey: query.queryKey,
    rangeAggregate: query.rangeAggregate,
    rangeAggregateGrouping: query.rangeAggregateGrouping,
    step: query.step,
    vectorAggregate: query.vectorAggregate,
  };

  return transformedQuery;
};

const getLogsFormulaDecodeTransform = (formula: LogsMetricForumlaProps) => {
  const transformedFormula = {
    isActive: true,
    isValid: true,
    expression: formula.expression,
    queryKey: formula.queryKey,
  };

  return transformedFormula;
};
