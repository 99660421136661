import { PopoverPosition, PopoverTriggerV2 } from 'components';
import React from 'react';
import { DateSelection, FilterMethodByType } from 'types';
import ServerlessRightSidebarFiltersItemPanel from './ServerlessRightSidebarFiltersItemPanel';

type Props = {
  date: DateSelection;
  facetName: string;
  name: string;
  sidebarFilterState: FilterMethodByType<'map'>;
  value: string;
};

const ServerlessRightSidebarFiltersItem = ({
  date,
  facetName,
  name,
  sidebarFilterState,
  value,
}: Props) => {
  return (
    <div className="serverless__right-sidebar__filters__item">
      <PopoverTriggerV2
        popover={(props) => (
          <ServerlessRightSidebarFiltersItemPanel
            {...props}
            date={date}
            facetName={facetName}
            name={name}
            sidebarFilterState={sidebarFilterState}
            value={value}
          />
        )}
        position={PopoverPosition.BOTTOM_LEFT}
      >
        <div className="serverless__right-sidebar__filters__item__wrapper">
          <div className="serverless__right-sidebar__filters__item__label">
            {name}
          </div>
          <div className="serverless__right-sidebar__filters__item__value">
            {value}
          </div>
        </div>
      </PopoverTriggerV2>
    </div>
  );
};

export default ServerlessRightSidebarFiltersItem;
