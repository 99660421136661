import { SizeObserver } from 'components';
import React, { useEffect } from 'react';
import LogsTimelineInner from './LogsTimelineInner';

const LogsTimeline = ({
  activeQueryIndex,
  getLogStackedBarCountsUsingMetricsRequest,
  hideTimeline,
  hoveredLogDateUnix,
  logsState,
  queryScheduler,
  selectedLog,
  showTimelineToggle,
}) => {
  useEffect(() => {
    queryScheduler.setIsTimelineOpen(showTimelineToggle.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTimelineToggle.value]);
  return (
    <SizeObserver>
      {({ width }) =>
        width ? (
          <LogsTimelineInner
            activeQueryIndex={activeQueryIndex}
            getLogStackedBarCountsUsingMetricsRequest={
              getLogStackedBarCountsUsingMetricsRequest
            }
            hideTimeline={hideTimeline}
            hoveredLogDateUnix={hoveredLogDateUnix}
            logsState={logsState}
            queryScheduler={queryScheduler}
            selectedLog={selectedLog}
            showTimelineToggle={showTimelineToggle}
            width={width}
          />
        ) : null
      }
    </SizeObserver>
  );
};

export default LogsTimeline;
