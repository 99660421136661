import React from 'react';

import { Box } from 'components/layouts/Box';
import { Table, TableRow } from 'components';
import { getSelector } from 'screens/Kubernetes/utils/selectorsfunction';
import KubernetesRightSideBar from '../KubernetesRightSideBar';
import { useDateState } from 'hooks';
import { EntityTypes, StatefulSet } from 'screens/Kubernetes/types';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellTimestamp,
  KubeTableCell,
  KubeTableEntityTags,
  KubeTableFilterCell,
} from './Components';
import { generatePath, useParams } from 'react-router-dom';
import KubernetesTableGroup from './KubernetesTableGroup';
import {
  useEntitiesData,
  useEntitiesGroupData,
  useMergedGroupByFilter,
} from './useEntitiesData';

const renderEmptyCell = () => '';

function KubernetesTableForStatefulSetRow({ row }) {
  const { date, entitiesType, navigate } = useKubernetesController();

  const facets = useMergedGroupByFilter(row.groupBys);
  const [rows] = useEntitiesData<{ statefulSet: StatefulSet }>({
    entitiesType,
    kubesSort: null,
    facets,
    page: 0,
    limit: 50,
    date,
  });

  const setActiveKube = (row: { statefulSet: StatefulSet }) => {
    navigate(({ location, params, pathTemplate }) => {
      return {
        ...location,

        pathname: generatePath(pathTemplate, {
          ...params,

          activeKube: row.statefulSet.metadata.uid,
          tab: 'yaml',
        }),
      };
    });
  };

  const activeKubeParam = useParams()['activeKube'];
  const activeKube = (
    typeof activeKubeParam === 'string' && rows ? rows : []
  ).find((row) => {
    return row.statefulSet.metadata.uid === activeKubeParam;
  });

  return (
    <Box width="full" height="full">
      {activeKube && <KubernetesRightSideBar activeKube={activeKube} />}
      <Table
        className="-my-px"
        renderHeader={false}
        rows={rows ?? []}
        renderRow={(props) => (
          <TableRow
            {...props}
            className="border-y"
            onRowClickHandler={(row) => () => setActiveKube(row)}
          />
        )}
        columns={[
          { key: 'header', label: '' },
          {
            key: 'kube_stateful_set',
            label: 'Stateful Set',
            renderCell: (prop) => {
              const statefulSetName = prop?.row.statefulSet?.metadata?.name;
              return (
                <KubeTableCell {...prop} tooltipText={statefulSetName}>
                  {statefulSetName}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'kube_cluster_name',
            label: 'Cluster',
            renderCell(prop) {
              return <KubeCellCluster {...prop} />;
            },
          },
          {
            key: 'metadata.namespace',
            label: 'Namespace',
            renderCell(prop) {
              return <KubeCellNamespace {...prop} />;
            },
          },
          {
            key: 'spec.serviceName',
            label: 'Service',
            renderCell(prop) {
              return (
                <KubeTableFilterCell
                  facetKey="kube_service"
                  facetValue={prop.row.statefulSet.spec.serviceName}
                  className="kubernetes__table__cell__cluster-name truncate w-full"
                  row={row}
                  {...prop}
                />
              );
            },
          },
          {
            key: 'headerSelectors',
            label: 'Selectors',
            renderCell(prop) {
              const selector = getSelector(prop?.row.statefulSet);
              return (
                <KubeTableCell
                  {...prop}
                  className="kubernetes__table__cell__entity-name"
                  tooltipText={selector}
                >
                  {selector}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'spec.podManagementPolicy',
            label: 'Pod Policy',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop}>
                  {prop?.row.statefulSet?.spec?.podManagementPolicy}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'spec.updateStrategy',
            label: 'Update Strategy',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop}>
                  {prop?.row?.statefulSet?.spec?.updateStrategy}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'metadata.creationTimestamp',
            label: 'Age',
            renderCell(prop) {
              const timestamp =
                prop?.row?.statefulSet?.metadata?.creationTimestamp || '';
              return <KubeCellTimestamp {...prop} timestamp={timestamp} />;
            },
          },
          {
            key: 'status.currentReplicas',
            label: 'Current',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="right">
                  {prop.row.statefulSet.status.currentReplicas}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'spec.desiredReplicas',
            label: 'Desired',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="right">
                  {prop?.row?.statefulSet?.spec?.desiredReplicas}
                </KubeTableCell>
              );
            },
          },
        ]}
      />
    </Box>
  );
}

export default function KubernetesTableForStatefulSetGroup() {
  const { date, entitiesType, facets, groupBySearchTerms } =
    useKubernetesController();

  const [rows] = useEntitiesGroupData({
    entitiesType,
    date,
    facets,
    groupBySearchTerms,
  });

  return (
    <KubernetesTableGroup
      rows={rows ?? []}
      columns={[
        {
          key: 'headerkube_stateful_set',
          label: 'Stateful Set Group',
          renderCell(prop) {
            return <KubeTableEntityTags {...prop} />;
          },
        },
        {
          key: 'headerkube_cluster_name',
          label: 'Cluster',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerkube_namespace',
          label: 'Namespace',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerservice',
          label: 'Service',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerselectors',
          label: 'Selectors',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerpodpolicy',
          label: 'Pod Policy',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerupdateStrategy',
          label: 'Update Strategy',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerage',
          label: 'Age',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headercurrent',
          label: 'Current',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerdesired',
          label: 'Desired',
          renderCell: renderEmptyCell,
        },
      ]}
      renderExpandedRow={(props) => (
        <KubernetesTableForStatefulSetRow {...props} />
      )}
    />
  );
}
