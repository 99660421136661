import {
  OverlayMessageProps,
  SizeObserver,
  TimeseriesRenderer,
} from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { eventStackedCounts } from 'requests';
import useDebouncedEffect from 'use-debounced-effect';
import {
  convertArrayToObject,
  getRollupByVisualization,
  setDateRangeOnChartZoom,
} from 'utils';

import { UplotExtended } from 'types/Timeseries';
import { EventPageProps } from '../types';

const defaultChartTypes = ['Stacked Bar'];

const EventsExplorerChart = ({ eventsState }: EventPageProps): ReactElement => {
  const eventStackedCountsRequest = useRequest(eventStackedCounts, true, true);
  const { date, filterState, setDate } = eventsState;

  const [error, setError] = useState({
    getStackedCounts: null,
  });

  const { filter } = filterState;
  useDebouncedEffect(
    () => {
      const rollUp = getRollupByVisualization(date, 'bar');
      const { filterByFacets, searchTerms } = filter;

      eventStackedCountsRequest.call({
        date,
        filterByFacets: convertArrayToObject(filterByFacets),
        groupBys: ['alert_type'],
        rollUp: `${rollUp}s`,
        searchTerms,
        selectedFacetValuesByName: filter['sidebarFilters'],
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      timeout: 100,
      ignoreInitialCall: false,
    },
    [date, filter],
  );

  const hooks = useMemo(() => {
    return [
      {
        hook: (u: UplotExtended) => setDateRangeOnChartZoom(u, setDate),
        type: 'setSelect',
      },
    ];
  }, [setDate]);

  useEffect(() => {
    if (eventStackedCountsRequest?.error) {
      setError((prevError) => ({
        ...prevError,
        getTraces: { message: 'Failed to fetch stacked counts' },
      }));
    }
  }, [eventStackedCountsRequest?.error]);

  const overlayMessageProps: OverlayMessageProps = error?.getStackedCounts
    ?.message
    ? {
        isActive: true,
        iconName: 'warning',
        message: (
          <>
            {error?.getStackedCounts?.message || 'Error'}. Please double check
            your query and try again.
          </>
        ),
      }
    : { isActive: false };

  return (
    <SizeObserver>
      {({ width }) => (
        <TimeseriesRenderer
          chartTypes={defaultChartTypes}
          date={eventsState.date}
          hooks={hooks}
          chartData={
            eventStackedCountsRequest.result || { data: [], series: [] }
          }
          isLoading={eventStackedCountsRequest.isLoading}
          tooltipType="compact"
          size={{ width: width - 16, height: 140 }}
          unit="number"
          chartKey="events-chart"
          error={eventStackedCountsRequest.error}
        />
      )}
    </SizeObserver>
  );
};

export default EventsExplorerChart;
