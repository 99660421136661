import devConfig from 'conf/devConfig';
import useRequest from 'hooks/useRequest';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import getConfig, { ConfigJson } from 'requests/getConfig';
import { isLocalDevEnv } from 'utils/config';

const ConfigContext = createContext<ConfigJson | undefined>(undefined);

export const ConfigProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const configJsonRequest = useRequest(getConfig, true, true);

  useEffect(() => {
    if (!isLocalDevEnv) {
      configJsonRequest.call();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => (isLocalDevEnv ? devConfig : configJsonRequest.result),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configJsonRequest.result],
  );

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

export const useConfigContext = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};
