export const getIsComponentLabel = (component: string): boolean =>
  component === 'Core' ||
  component === 'Kubernetes' ||
  component === 'Cloud' ||
  component === 'Additional' ||
  !component;

export const getIsComponentLabelStrict = (component: string): boolean =>
  component === 'Core' ||
  component === 'Kubernetes' ||
  component === 'Cloud' ||
  component === 'Additional';
