import { Button, CodeEditor } from 'components';
import { useLogsMetricsQueryState } from 'hooks';
import React, { ReactElement } from 'react';
import { LogsMetricQueryProps } from 'types';

const LogsMetricsQueryBuilderInput = ({
  logsMetricsQueryState,
  query,
  queryIndex,
}: {
  logsMetricsQueryState: ReturnType<typeof useLogsMetricsQueryState>;
  query: LogsMetricQueryProps;
  queryIndex: number;
}): ReactElement => {
  const { updateQuery, loadLogqlQuery } = logsMetricsQueryState;

  return (
    <div className="metrics__query-builder__query-item">
      <div className="metrics__query-builder__query-item__code">
        <div className="metrics__query-builder__query-item__query-key">
          {query.queryKey}
        </div>
        <CodeEditor
          onChange={(val: string) => updateQuery(queryIndex, 'logql', val)}
          value={query.logql}
        />
        <Button
          className="h-full"
          variant="default"
          size="sm"
          onClick={() => loadLogqlQuery(query)}
        >
          Run
        </Button>
      </div>
    </div>
  );
};

export default LogsMetricsQueryBuilderInput;
