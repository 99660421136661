import React, { ReactElement } from 'react';

import { MdOutlineSpaceDashboard as DashboardIcon } from 'react-icons/md';
import { FaExternalLinkAlt as ExternalLinkIcon } from 'react-icons/fa';
import { FaQuestion as QuestionIcon } from 'react-icons/fa';
import { FaInfo as InfoIcon } from 'react-icons/fa';
import { FaBolt as BoltIcon } from 'react-icons/fa';
import { HiDocumentText as DocumentTextIcon } from 'react-icons/hi';
import { FaCloud as CloudIcon } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  DateSelection,
  DashboardLinkProps,
  DashboardTemplateValueProps,
} from 'types';

import { useDashboardState, useDashboardTemplateState } from './hooks';

const linkIconMap = {
  dashboard: <DashboardIcon />,
  'external link': <ExternalLinkIcon />,
  question: <QuestionIcon />,
  info: <InfoIcon />,
  bolt: <BoltIcon />,
  doc: <DocumentTextIcon />,
  cloud: <CloudIcon />,
};

const getUrlParamForLink = ({
  date,
  link,
  templateValues,
}: {
  date: DateSelection;
  link: DashboardLinkProps;
  templateValues: DashboardTemplateValueProps;
}) => {
  let params = '';

  let dashboardId = '';
  const urlSplit = link.url.split('/');
  if (urlSplit.length < 3) {
    return null;
  } else {
    dashboardId = urlSplit[3];
  }

  if (link.keepTime) {
    params += `date=${encodeURIComponent(JSON.stringify(date))}`;
  }

  if (link.includeVars) {
    params += `&templateValues=${encodeURIComponent(
      JSON.stringify(templateValues),
    )}`;
  }
  return `#/dashboards/${dashboardId}?${params}`;
};

const DashboardLink = ({
  dashboardState,
  dashboardTemplateState,
}: {
  dashboardState: ReturnType<typeof useDashboardState>;
  dashboardTemplateState: ReturnType<typeof useDashboardTemplateState>;
}): ReactElement => {
  const navigate = useNavigate();
  const { date, dashboardDetails } = dashboardState;
  const { templateValues } = dashboardTemplateState;

  const links = dashboardDetails?.links || [];

  if (links.length === 0) {
    return null;
  }

  const filteredLinks = links.filter((link) => {
    return link.type === 'link';
  });

  const onClickLink = (link: (typeof filteredLinks)[number]) => {
    const url = getUrlParamForLink({ date, link, templateValues });

    if (link.targetBlank) {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
  };

  return (
    <div className="flex">
      {filteredLinks.map((link) => (
        <div
          key={link.title}
          className="ml-2 flex cursor-pointer items-center gap-1 self-center border p-1"
          onClick={() => onClickLink(link)}
        >
          {linkIconMap[link.icon as keyof typeof linkIconMap]}
          {link.title}
        </div>
      ))}
    </div>
  );
};

export default DashboardLink;
