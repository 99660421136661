import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { MenuListProps } from 'react-select';
import { FixedSizeList as List } from 'react-window';
import { AutocompleteOption } from './types';

const height = 35; // Height of each option row
const TEXT_DATATYPE_OFFSET = 100; // Offset for text datatype
const LOAD_MORE_OFFSET = 40; // Offset for loading more options
const AutocompleteMenuListV2 = (
  props: MenuListProps,
): ReactElement | ReactNode => {
  const { options, children, maxHeight, getValue, selectProps } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;
  const hasFired = useRef(false);

  useEffect(() => {
    // Reset hasFired when options change
    hasFired.current = false;
  }, [options]);

  const largestLabelLength: number = (options as AutocompleteOption[]).reduce(
    (acc: number, option: AutocompleteOption) => {
      const label = option.label as string;
      return label.length > acc ? label.length : acc;
    },
    0,
  );

  const handleItemsRendered = useCallback(
    ({ visibleStopIndex }) => {
      // Fire when scroll to 50% of the list
      const remainingOptions = options.length - LOAD_MORE_OFFSET;
      if (
        !hasFired.current &&
        remainingOptions > 0 &&
        visibleStopIndex > remainingOptions
      ) {
        const { onMenuScrollToBottom } = selectProps;
        if (onMenuScrollToBottom) selectProps.onMenuScrollToBottom({});
        hasFired.current = true;
      }
    },
    [options.length, selectProps],
  );

  if (!children.length) return children;

  return (
    <List
      height={maxHeight}
      itemCount={children.length as number}
      itemSize={height}
      initialScrollOffset={initialOffset}
      width={largestLabelLength * 7 + TEXT_DATATYPE_OFFSET}
      onItemsRendered={handleItemsRendered}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

export default AutocompleteMenuListV2;
