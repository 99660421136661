import { DateSelection, MetricsQueriesDataProps } from 'types';
import { getColorForOneSeries } from 'utils';

import { CompareToPreviousProps, TimeseriesExplorerPromqlProps } from './types';

export const compareToPreviousList = [
  { label: 'Hour', value: '1h', isActive: false },
  { label: 'Day', value: '1d', isActive: false },
  { label: 'Week', value: '1w', isActive: false },
  { label: 'Month', value: '1M', isActive: false },
  { label: 'Quarter', value: '3M', isActive: false },
];

export const getCompareToPreviousList = () => {
  return compareToPreviousList.map((item) => ({
    ...item,
    isActive: false,
  }));
};

export const predefinedFunctions = [
  { label: 'Basic Anomalies', value: 'anomalies', isActive: false },
  { label: 'Seasonal Anomalies', value: 'anomalies', isActive: false },
  { label: 'Agile Anomalies', value: 'anomalies', isActive: false },
  { label: 'Outliers', value: 'outliers', isActive: false },
  { label: 'Histogram Quantile', value: 'histogram_quantile', isActive: false },
  { label: 'Derivative', value: 'deriv', isActive: false },
];

export const getPredefinedFunctions = () => {
  return predefinedFunctions.map((item) => ({
    ...item,
    isActive: false,
  }));
};

export const getTimeDiffInSeconds = (code: string) => {
  switch (code) {
    case '1h':
      return 3600;
    case '1d':
      return 86400;
    case '1w':
      return 604800;
    case '1M':
      return 2592000;
    case '3M':
      return 7776000;
    default:
      return 0;
  }
};

export const getPreviousTimeRangeWithPromql = (
  compareToPrev: CompareToPreviousProps[],
  date: DateSelection,
  promql: string,
  logql: string,
): TimeseriesExplorerPromqlProps[] => {
  const promqlQueries: TimeseriesExplorerPromqlProps[] = [];
  const { startTimeUnix, endTimeUnix } = date;

  compareToPrev.forEach(({ isActive, label, value }) => {
    if (isActive) {
      const timeDiff = getTimeDiffInSeconds(value);
      const newStartTimeUnix = startTimeUnix - timeDiff;
      const newEndTimeUnix = endTimeUnix - timeDiff;

      promqlQueries.push({
        date: { startTimeUnix: newStartTimeUnix, endTimeUnix: newEndTimeUnix },
        logql,
        promql,
        label: `${label.toLocaleLowerCase()}_ago`,
      });
    }
  });

  return promqlQueries;
};

export const getPreviousTimeWithLabel = (
  compareToPrev: CompareToPreviousProps[],
  date: DateSelection,
) => {
  const { startTimeUnix, endTimeUnix } = date;
  const previousQueries: Array<{
    date: DateSelection;
    label: string;
  }> = [];

  compareToPrev.forEach(({ isActive, label, value }) => {
    if (isActive) {
      const timeDiff = getTimeDiffInSeconds(value);
      const newStartTimeUnix = startTimeUnix - timeDiff;
      const newEndTimeUnix = endTimeUnix - timeDiff;

      previousQueries.push({
        date: { startTimeUnix: newStartTimeUnix, endTimeUnix: newEndTimeUnix },
        label: `${label.toLocaleLowerCase()}_ago`,
      });
    }
  });

  return previousQueries;
};

/**
 * Transform the data from the API to the format that the chart needs
 */
export const transformMetricsExplorerData = (
  datasets: MetricsQueriesDataProps[],
  promqlQueries: TimeseriesExplorerPromqlProps[],
  seriesBitmap: { [key: string]: boolean },
): MetricsQueriesDataProps => {
  const queryData: MetricsQueriesDataProps = {};

  datasets.forEach((dataset, promqlIndex) => {
    Object.keys(dataset).forEach((key, idx) => {
      if (!dataset[key].data) return;
      const { series } = dataset[key].data;
      const { label } = promqlQueries[promqlIndex];

      const newSeries = series.map((item: any) => {
        const seriesShow =
          seriesBitmap[item.label] !== undefined
            ? seriesBitmap[item.label]
            : true;

        return {
          ...item,
          dash: label ? [4, 8] : undefined,
          label: label ? `${label}(${item.label})` : item.label,
          stroke: getColorForOneSeries({}, idx + promqlIndex + 1),
          show: seriesShow,
        };
      });

      queryData[key] = dataset[key];
      queryData[key].data.series = newSeries;
    });
  });

  return queryData;
};

export const transformLogqlExplorerData = (
  datasets: MetricsQueriesDataProps['query']['data'][],
  logqlQueries: TimeseriesExplorerPromqlProps[],
  seriesBitmap: { [key: string]: boolean },
): MetricsQueriesDataProps => {
  const queryData: MetricsQueriesDataProps = {};

  datasets.forEach((dataset, logqlIndex) => {
    const { series, ...rest } = dataset;
    const { label } = logqlQueries[logqlIndex];

    const newSeries = series.map((item: any) => {
      const seriesShow =
        seriesBitmap[item.label] !== undefined
          ? seriesBitmap[item.label]
          : true;

      return {
        ...item,
        dash: label ? [4, 8] : undefined,
        label: label ? `${label}(${item.label})` : item.label,
        stroke: getColorForOneSeries({}, logqlIndex + 1),
        show: seriesShow,
      };
    });

    queryData[`query_${logqlIndex}`] = {
      data: { series: newSeries, ...rest },
      isLoading: false,
    };
  });
  return queryData;
};

export const getSeriesShownState = (series: Series[]) => {
  if (!series) return {};

  const seriesBitmap: { [key: string]: boolean } = {};
  series.forEach((item) => {
    seriesBitmap[item.label] = item.show;
  });

  return seriesBitmap;
};
