import { useLogsMetricsQueryState } from 'hooks';
import React, { ReactElement, useMemo } from 'react';

import {
  AlertsCreateAnomalyCondition,
  AlertCreateOutlierCondition,
  AlertsCreateForecastCondition,
} from '../AlertsCreateMetrics';
import {
  AlertsCreateCondition,
  useAlertsCreateCondition,
} from '../AlertsCreateCondition';
import { AlertsConfigureNoData } from '../components';
import { useAlertsCreate } from '../hooks';
import { AlertType, RuleType } from '../types';
import { getQueryAndFormulaKeysLabel } from '../utils';
import {
  logsAnomalyAlgorithmTypeOptions,
  logsForecastSeasonalityOptions,
} from './utils';

const AlertsCreateLogsCondition = ({
  alertsCreateState,
  conditionState,
  logsMetricsQueryState,
  unit,
}: {
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
  logsMetricsQueryState: ReturnType<typeof useLogsMetricsQueryState>;
  unit: string;
}): ReactElement => {
  const { formulas, queries } = logsMetricsQueryState;
  const { alertType } = alertsCreateState;

  const queryAndFormulaKeysLabel = useMemo(
    () => getQueryAndFormulaKeysLabel(queries, formulas),
    [queries, formulas],
  );

  return (
    <>
      {alertType == AlertType.THRESHOLD && (
        <AlertsCreateCondition
          conditionState={conditionState}
          queryAndFormulaKeysLabel={queryAndFormulaKeysLabel}
          alertsCreateState={alertsCreateState}
          unit={unit}
        />
      )}
      {alertType == AlertType.OUTLIERS && (
        <AlertCreateOutlierCondition
          alertsCreateState={alertsCreateState}
          conditionState={conditionState}
          queryAndFormulaKeysLabel={queryAndFormulaKeysLabel}
        />
      )}
      {alertType == AlertType.ANOMALY && (
        <AlertsCreateAnomalyCondition
          alertsCreateState={alertsCreateState}
          conditionState={conditionState}
          queryAndFormulaKeysLabel={queryAndFormulaKeysLabel}
          anomalyAlgorithmOptions={logsAnomalyAlgorithmTypeOptions}
        />
      )}
      {alertType == AlertType.FORECAST && (
        <AlertsCreateForecastCondition
          alertsCreateState={alertsCreateState}
          conditionState={conditionState}
          queryAndFormulaKeysLabel={queryAndFormulaKeysLabel}
          ruleType={RuleType.LOGS}
          seasonalityOptions={logsForecastSeasonalityOptions}
        />
      )}
      <AlertsConfigureNoData conditionState={conditionState} />
    </>
  );
};

export default AlertsCreateLogsCondition;
