import { SelectedFacetValuesByName } from 'types';
import { onPromiseError } from 'utils';
import queryEventService from './queryEventService';
import buildKubeFilter, {
  buildKubeFilterVariable,
} from './utils/buildKubeFilter';
import buildKubeGroupByFilter from './utils/buildKubeGroupByFilter';

type Args = {
  selectedFacetValuesByName: SelectedFacetValuesByName;
  entityType: string;
  groupKey?: string;
  groupBy: Array<{
    key: string;
    type: 'TAG';
  }>;
  aggregations?: any;
};

type EntityGroup = {
  count: number;
  groupBys: Array<{
    type: 'TAG';
    key: string;
    value: string;
  }>;
  fieldAggregations: Array<unknown>;
};

const kubeNamespaceCount = async (
  {
    entityType,
    groupKey,
    groupBy,
    aggregations,
    selectedFacetValuesByName,
  }: Args,
  init?: RequestInit,
): Promise<Array<EntityGroup>> => {
  return queryEventService<Array<any>, 'kubeEntityGroups'>(
    `
    query GetKubeNamespaceCount($entityType: KubeEntityType!, $filter: KubeFilter!) {
      kubeEntityGroups (
        entityType: $entityType
        filter: $filter
        groupBys: ${buildKubeGroupByFilter(groupBy)}  
        aggregations:[${aggregations || ''}]
      ) {
         count
         groupBys {
           type
           key
           value
         }
         fieldAggregations {
           id
           result
         }
      }
    }
  `,
    {
      entityType,
      filter: buildKubeFilterVariable([selectedFacetValuesByName]),
    },
    init,
  ).then((data) => data.kubeEntityGroups || [], onPromiseError);
};

export default kubeNamespaceCount;
