import { fetchJson } from 'requests';

import { PolicyConfigProps } from '../types';

const getPolicyConfigList = async (): Promise<PolicyConfigProps[]> => {
  const result = await fetchJson(`rbac/rbacconfig/`);

  return (result as PolicyConfigProps[]) || [];
};

export default getPolicyConfigList;
