import { FormulaState, SearchState, useDateState, useRequest } from 'hooks';
import { getKfuseDatasource } from 'kfuse-constants';
import { useCallback, useEffect, useState } from 'react';
import ReactGridLayout from 'react-grid-layout';
import { getDatasources } from 'requests';
import {
  DateSelection,
  DashboardPanelProps,
  DashboardPanelType,
  ExplorerQueryProps,
  FormulaProps,
  DashboardStreamType,
  LogsMetricQueryProps,
  LogsMetricForumlaProps,
} from 'types';

import {
  getInitialEditPanelState,
  getEditedPanel,
  getEditedPanelForLogs,
  getEditedPanelForLogsEvents,
  getEditedPanelForTrace,
  getEditedPanelForRum,
  getEditedPanelForEvents,
} from '../utils';
import { DashboardUpdateEditedPanelProps } from '../../types';

const useDashboardEditState = ({
  close,
  date: prevDate,
  nestedIndex,
  panelIndex,
  streamType,
  updateEditedPanel,
}: {
  close: () => void;
  date: DateSelection;
  nestedIndex: string;
  panelIndex: number;
  streamType: DashboardStreamType;
  updateEditedPanel: (val: DashboardUpdateEditedPanelProps) => void;
}) => {
  const [date, setDate] = useDateState(prevDate);
  const [editPanel, setEditPanel] = useState<DashboardPanelProps>({});
  const [editDashboardError, setEditDashboardError] = useState<{
    [key: string]: string;
  }>({});
  const [stream, setStream] = useState<DashboardStreamType>(streamType);

  const datasourcesRequest = useRequest(() =>
    getDatasources().then((res) => getKfuseDatasource(res, 'prometheus')),
  );

  const onSaveClick = async ({
    formulas,
    queries,
  }: {
    formulas: FormulaProps[];
    queries: ExplorerQueryProps[];
  }) => {
    if (Object.keys(editDashboardError).length > 0) return;
    if (!editPanel.title) {
      setEditDashboardError({ title: 'Title is required' });
      return;
    }

    const datasource = datasourcesRequest.result;
    if (!datasource) return;

    const transformedPanel = getEditedPanel({
      editedPanel: editPanel,
      formulas,
      queries,
      uid: datasource.uid,
    });

    updateEditedPanel({ panel: transformedPanel, panelIndex, nestedIndex });
    close();
  };

  const onSaveClickLogs = async ({
    formulas,
    queries,
    queryLangType,
  }: {
    formulas: LogsMetricForumlaProps[];
    queries: LogsMetricQueryProps[];
    queryLangType: string;
  }) => {
    if (Object.keys(editDashboardError).length > 0) return;
    if (!editPanel.title) {
      setEditDashboardError({ title: 'Title is required' });
      return;
    }

    const datasource = datasourcesRequest.result;
    if (!datasource) return;

    const transformedPanel = getEditedPanelForLogs({
      editedPanel: editPanel,
      formulas,
      queries,
      queryLangType,
      uid: datasource.uid,
    });

    updateEditedPanel({ panel: transformedPanel, panelIndex, nestedIndex });
    close();
  };

  const onSaveClickTraces = async ({
    formulas,
    queries,
  }: {
    formulas: FormulaState[];
    queries: SearchState[];
  }) => {
    if (Object.keys(editDashboardError).length > 0) return;
    if (!editPanel.title) {
      setEditDashboardError({ title: 'Title is required' });
      return;
    }

    const transformedPanel = getEditedPanelForTrace({
      editedPanel: editPanel,
      formulas,
      queries,
    });

    updateEditedPanel({ panel: transformedPanel, panelIndex, nestedIndex });
    close();
  };

  const onSaveClickRum = async ({ newExpr }: { newExpr: string }) => {
    if (Object.keys(editDashboardError).length > 0) return;
    if (!editPanel.title) {
      setEditDashboardError({ title: 'Title is required' });
      return;
    }

    const transformedPanel = getEditedPanelForRum({
      editedPanel: editPanel,
      newExpr,
    });

    updateEditedPanel({ panel: transformedPanel, panelIndex, nestedIndex });
    close();
  };

  const onSaveClickEvents = async ({ queries }: { queries: SearchState[] }) => {
    if (Object.keys(editDashboardError).length > 0) return;
    if (!editPanel.title) {
      setEditDashboardError({ title: 'Title is required' });
      return;
    }

    const transformedPanel = getEditedPanelForEvents({
      editedPanel: editPanel,
      queries,
    });

    updateEditedPanel({ panel: transformedPanel, panelIndex, nestedIndex });
    close();
  };

  const onSaveClickLogEvents = async (expr: string) => {
    if (Object.keys(editDashboardError).length > 0) return;
    if (!editPanel.title) {
      setEditDashboardError({ title: 'Title is required' });
      return;
    }

    const transformedPanel = getEditedPanelForLogsEvents({
      editedPanel: editPanel,
      expr,
    });

    updateEditedPanel({ panel: transformedPanel, panelIndex, nestedIndex });
    close();
  };

  const onSaveClickRows = () => {
    if (Object.keys(editDashboardError).length > 0) return;
    if (!editPanel.title) {
      setEditDashboardError({ title: 'Title is required' });
      return;
    }
    editPanel.collapsed = false;
    updateEditedPanel({ panel: editPanel, panelIndex, nestedIndex });
    close();
  };

  const initEditPanel = useCallback(
    ({
      itemLayout,
      panel,
      panelType,
    }: {
      itemLayout?: ReactGridLayout.Layout;
      panel?: DashboardPanelProps;
      panelType: DashboardPanelType;
    }) => {
      if (!panel) {
        const newPanel = getInitialEditPanelState(itemLayout, panelType);
        setEditPanel((prev) => ({ ...prev, ...newPanel }));
        return;
      }

      setEditPanel(panel);
    },
    [setEditPanel],
  );

  useEffect(() => {
    datasourcesRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    date,
    editDashboardError,
    editPanel,
    initEditPanel,
    onSaveClick,
    onSaveClickLogs,
    onSaveClickLogEvents,
    onSaveClickRows,
    onSaveClickTraces,
    onSaveClickRum,
    onSaveClickEvents,
    setDate,
    setEditDashboardError,
    setEditPanel,
    stream,
    setStream,
  };
};

export default useDashboardEditState;
