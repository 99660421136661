import classNames from 'classnames';
import React, { ReactElement } from 'react';
import * as styles from './PercentageBar.module.css';

type Props = {
  className?: string;
  color?: string;
  percent: number;
};

const PercentageBar = ({ className, color, percent }: Props): ReactElement => {
  return (
    <div className={classNames(styles.root, className)}>
      <div
        className={styles.fill}
        data-percent={percent.toFixed(2)}
        style={{ width: `${percent}%`, backgroundColor: color }}
      />
    </div>
  );
};

export default PercentageBar;
