import classnames from 'classnames';
import React from 'react';
import { getStatusColor } from 'utils';

type Props = {
  endpoint: string;
  method: string;
  statusCode: number;
};

const ResourceWithStatusCode = ({ endpoint, method, statusCode }: Props) => {
  const statusColor = getStatusColor(statusCode);
  return (
    <div className="resource-with-status-code">
      <div className="resource-with-status-code__method">{method}</div>
      <div className="resource-with-status-code__endpoint">{endpoint}</div>
      <div
        className={classnames({
          'resource-with-status-code__status-code': true,
          'resource-with-status-code__status-code--with-color': statusColor,
        })}
        style={{ backgroundColor: statusColor }}
      >
        {statusCode}
      </div>
    </div>
  );
};

export default ResourceWithStatusCode;
