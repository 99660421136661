export function* createNodeIterator(
  ...args: Parameters<typeof document.createNodeIterator>
) {
  const it = document.createNodeIterator(...args);

  for (let cursor = it.nextNode(); cursor; cursor = it.nextNode()) {
    yield cursor;
  }
}

export async function textFrom(evt: {
  data: string;
  dataTransfer: DataTransfer;
}): Promise<string> {
  if (typeof evt.data === 'string') {
    return evt.data;
  }

  const [item] = evt.dataTransfer.items;

  return await new Promise((resolve) => {
    item.getAsString(resolve);
  });
}

export function getSelectionRange(
  root: HTMLElement,
  range: StaticRange | Range,
  inputType: 'insert' | 'delete',
): [number, number] {
  const selection: [number, number] = [0, 0];
  const it = document.createNodeIterator(root, NodeFilter.SHOW_TEXT);

  for (let accum = 0, node = it.nextNode(); node; node = it.nextNode()) {
    if (
      range.startContainer.nodeType === document.TEXT_NODE &&
      node === range.startContainer
    ) {
      selection[0] = range.startOffset + accum;
    }
    if (
      range.startContainer.nodeType === document.ELEMENT_NODE &&
      node === range.startContainer.firstChild
    ) {
      selection[0] = range.startOffset + accum;
    }

    if (
      range.endContainer.nodeType === document.TEXT_NODE &&
      node === range.endContainer
    ) {
      selection[1] = range.endOffset + accum;
    }
    if (
      range.endContainer.nodeType === document.ELEMENT_NODE &&
      node === range.endContainer.firstChild
    ) {
      selection[1] = range.endOffset + accum;
    }

    accum += node.textContent.length;
  }

  if (inputType === 'delete' && selection[0] === selection[1]) {
    selection[0] = Math.max(0, selection[0] - 1);
  }

  return selection;
}

export function setCaretPositionExact(node: Node, offset: number): void {
  const selection = document.getSelection();
  const range = document.createRange();

  range.setStart(node, offset);
  range.collapse(true);

  selection.removeAllRanges();
  selection.addRange(range);
}

export function setCaretPosition(ref: Node, offset: number): void {
  if (ref.contains(document.activeElement)) {
    const it = document.createNodeIterator(ref, NodeFilter.SHOW_TEXT);

    let consumed = offset;
    let node = null;

    do {
      node = it.nextNode();

      if (node) {
        consumed -= node.textContent.length;
      }

      if (consumed <= 0) {
        break;
      }
    } while (node);

    if (node) {
      setCaretPositionExact(node, consumed + node.textContent.length);
      // if (node.parentElement.offsetWidth) {
      //   range.setStart(node, consumed + node.textContent.length);
      // } else {
      //   // we're in a hidden element, find the next visible node
      //   while (!node.parentElement.offsetWidth) {
      //     node = it.nextNode();
      //   }
      //
      //   range.setStart(node, 0);
      // }
    } else {
      setCaretPositionExact(ref, 0);
    }
  }
}
