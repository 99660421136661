import classnames from 'classnames';
import React, { useMemo } from 'react';
import tinycolor from 'tinycolor2';
import { FormattedRumEventForFlameGraph } from './types';
import getFlameGraphConfig from './getFlameGraphConfig';
import { RumEventType } from '../types';
import { useDebounce } from 'use-debounce';

type Props = {
  eventId?: string;
  formattedEvent: FormattedRumEventForFlameGraph;
  flameGraphConfig: Partial<ReturnType<typeof getFlameGraphConfig>>;
  getColor: (event: FormattedRumEventForFlameGraph) => string;
  hoveredEventId?: string;
  setHoveredEventId?: (eventId: string | null) => void;
  width: number;
};

const RumFlameGraphOverviewRows = ({
  eventId,
  formattedEvent,
  flameGraphConfig,
  getColor,
  hoveredEventId,
  setHoveredEventId,
  width,
}: Props) => {
  const { startTimeNs, endTimeNs } = formattedEvent;
  const {
    minPresentationalDurationForOverview: minPresentationalDuration,
    minStartTimeNs,
    niceUpperBoundForOverview: niceUpperBound,
  } = flameGraphConfig;

  const [debouncedHover] = useDebounce(setHoveredEventId, 300);

  const onMouseEnterHandler = (eventId: string) => {
    if (setHoveredEventId) {
      if (eventId === hoveredEventId) {
        return;
      }
      debouncedHover(eventId);
    }
  };

  const onMouseLeave = () => {
    setHoveredEventId?.(null);
  };

  const duration = Math.max(endTimeNs - startTimeNs, minPresentationalDuration);
  const totalDuration = niceUpperBound;

  const chartWidth = (duration / totalDuration) * width;
  const translateX = ((startTimeNs - minStartTimeNs) / niceUpperBound) * width;

  const color = getColor(formattedEvent);

  const isError = useMemo(
    () => formattedEvent.eventType === RumEventType.ERROR,
    [formattedEvent?.eventType],
  );

  return (
    <div
      className={classnames({
        flamegraph__span: true,
        'flamegraph__span--dark': tinycolor(color).isDark(),
        'flamegraph__span--hovered':
          Boolean(hoveredEventId) && hoveredEventId === eventId,
        'flamegraph__span--narrow': chartWidth < 50,
      })}
      style={{
        backgroundColor: color,
        transform: `translate3d(${translateX}px, 0, 0) ${isError ? 'rotate(45deg)' : ''}`,
        width: `${Math.max(chartWidth, 1)}px`,
        top: '-4px',
      }}
      onMouseEnter={() => onMouseEnterHandler(eventId)}
      onMouseLeave={onMouseLeave}
    >
      <div className="flamegraph__span__highlighter" />
    </div>
  );
};

export default RumFlameGraphOverviewRows;
