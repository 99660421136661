import { AutocompleteOption, AutocompleteV2 } from 'components';
import React, { ReactElement } from 'react';
import { components } from 'react-select';

const AddPermissionUsers = ({
  isLoading,
  placeholder,
  onChange,
  options,
  value,
}: {
  isLoading: boolean;
  placeholder: string;
  onChange: (val: string) => void;
  options: AutocompleteOption[];
  value: number | string;
}): ReactElement => {
  return (
    <AutocompleteV2
      className="max-h-[32px] min-w-[200px]"
      components={{
        Option: ({ children, ...props }) => (
          <components.Option {...props}>
            <div className="flex items-center gap-2">
              {props.data.avatarUrl && (
                <img
                  src={props.data.avatarUrl}
                  alt="user avatar"
                  className="size-4 rounded-full"
                />
              )}
              {children}
            </div>
          </components.Option>
        ),
        SingleValue: ({ children, ...props }) => (
          <components.SingleValue {...props}>
            <div className="flex items-center gap-2">
              {props.data.avatarUrl && (
                <img
                  src={props.data.avatarUrl}
                  alt="user avatar"
                  className="size-4 rounded-full"
                />
              )}
              {children}
            </div>
          </components.SingleValue>
        ),
      }}
      isLoading={isLoading}
      options={options || []}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
    />
  );
};

export default AddPermissionUsers;
