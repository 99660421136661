import React from 'react';
import { getNiceScale } from 'utils';
import RumResourceBreakdownLegend from './RumResourceBreakdownLegend';
import { RumEvent, RumEventType, RumResourceEvent } from '../types';
import { convertMsToNs, parseDurationField } from '../RumFlameGraph/utils';
import RumFlameGraphTicks from '../RumFlameGraph/RumFlameGraphTicks';
import RumFlameGraphOverviewRows from '../RumFlameGraph/RumFlameGraphOverviewRows';

type Props = {
  activeRumEvent: RumEvent;
  baseWidth: number;
  eventType: RumEventType;
  startTimeMs: number;
};

const RumResourceBreakdownContainer = ({
  activeRumEvent,
  baseWidth,
  eventType,
}: Props) => {
  const resourceEvent = activeRumEvent as RumResourceEvent;
  const startTime = convertMsToNs(resourceEvent.time);
  const resourceDuration = parseDurationField(
    resourceEvent.data['resource.duration'],
  );
  const endTime = startTime + resourceDuration;
  const ticks = Math.floor(baseWidth / 100);

  const firstByteStartAt = parseDurationField(
    resourceEvent.data['resource.first_byte.start'],
  );

  const firstByteStartTime = startTime + firstByteStartAt;

  const firstByteDuration = parseDurationField(
    resourceEvent.data['resource.first_byte.duration'],
  );

  const firstByteEndTime = firstByteStartTime + firstByteDuration;

  const downloadDuration = parseDurationField(
    resourceEvent.data['resource.download.duration'],
  );
  const downloadStartAt = parseDurationField(
    resourceEvent.data['resource.download.start'],
  );
  const downloadStartTime = startTime + downloadStartAt;
  const downloadEndTime = downloadStartTime + downloadDuration;

  const minStartTime = Math.min(
    startTime,
    firstByteStartTime,
    downloadStartTime,
  );
  const maxEndTime = Math.max(endTime, firstByteEndTime, downloadEndTime);

  const min = 0;
  const max = maxEndTime - minStartTime;

  const { niceUpperBound, tickSpacing } = getNiceScale(min, max, ticks);
  const minPresentationalDuration = Math.round((10 * max) / baseWidth);

  const flameGraphRows = [
    {
      name: 'Unknown',
      duration: firstByteStartAt ? firstByteStartAt : resourceDuration,
      startTimeNs: startTime,
      endTimeNs: firstByteStartAt ? startTime + firstByteStartAt : endTime,
      color: '#d1d6e6',
    },
    {
      name: 'First Byte',
      duration: firstByteDuration,
      startTimeNs: firstByteStartTime,
      endTimeNs: firstByteStartTime + firstByteDuration,
      color: '#ffcd1e',
    },
    {
      name: 'Download',
      duration: downloadDuration,
      startTimeNs: downloadStartTime,
      endTimeNs: downloadStartTime + downloadDuration,
      color: '#ffef95',
    },
  ];

  return (
    <>
      <RumFlameGraphTicks
        className="flamegraph__ticks--sticky"
        minStartTimeNs={minStartTime}
        maxEndTimeNs={maxEndTime}
        niceUpperBound={niceUpperBound}
        tickSpacing={tickSpacing}
        width={baseWidth}
      />
      <div className="flamegraph__rows mt-1">
        <div className="flamegraph__row mt-4">
          {flameGraphRows.map((row, index) => (
            <RumFlameGraphOverviewRows
              key={index}
              formattedEvent={{
                duration: row.duration,
                startTimeNs: row.startTimeNs,
                endTimeNs: row.endTimeNs,
                name: resourceEvent.data['resource.url'],
                eventType: eventType,
                originalEvent: resourceEvent,
              }}
              flameGraphConfig={{
                minPresentationalDurationForOverview: minPresentationalDuration,
                minStartTimeNs: minStartTime,
                maxEndTimeNs: maxEndTime,
                niceUpperBoundForOverview: niceUpperBound,
              }}
              getColor={() => row.color}
              width={baseWidth}
            />
          ))}
        </div>
      </div>

      <RumResourceBreakdownLegend
        unknownDuration={flameGraphRows[0].duration}
        firstByteDuration={flameGraphRows[1].duration}
        downloadDuration={flameGraphRows[2].duration}
      />
    </>
  );
};

export default RumResourceBreakdownContainer;
