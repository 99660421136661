import {
  Loader,
  Table,
  OverlayMessage,
  useColumnsState,
  useTableBESort,
  useTableSearch,
} from 'components';
import React, { useState, useMemo } from 'react';
import useAggregateTableRequest from '../hooks/useAggregateTableRequest';
import { RumTableColumnKey } from '../contants';
import { rumPerformanceTableColumns } from '../rumEventTableColumns';
import useRumState from '../hooks/useRumState';
import { TableSearch } from 'components';
import { Select } from 'components/SelectV2';
import useSortState from 'hooks/useSortState';
import { isSortingDisabledForRumColumn } from '../utils';

type Metric =
  | 'view.largest_contentful_paint'
  | 'view.cumulative_layout_shift'
  | 'view.interaction_to_next_paint';
type Value = string | number;
type Props = {
  rumState: ReturnType<typeof useRumState>;
};

const getColorForMetric = (metric: Metric, value: Value): string => {
  const numericValue = parseFloat(value?.toString());

  switch (metric) {
    case 'view.largest_contentful_paint':
      if (numericValue > 4) return '#8B0000';
      if (numericValue >= 2.5 && numericValue <= 4) return '#FFA500';
      return '#006400';

    case 'view.cumulative_layout_shift':
      if (numericValue > 0.25) return '#8B0000';
      if (numericValue >= 0.1 && numericValue <= 0.25) return '#FFA500';
      return '#006400';

    case 'view.interaction_to_next_paint':
      if (numericValue > 0.5) return '#8B0000';
      if (numericValue >= 0.2 && numericValue <= 0.5) return '#FFA500';
      return '#006400';
  }
};

const RumPerformanceOverviewTopPages = ({ rumState }: Props) => {
  const {
    applicationFilter,
    dateState,
    facetRegexState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
  } = rumState;
  const [date] = dateState;
  const aggregationMapping: { [key: string]: string } = {
    P99: 'percentile99',
    P95: 'percentile95',
    P90: 'percentile90',
    P75: 'percentile75',
    P50: 'percentile50',
  };
  const [aggregationFunction, setAggregationFunction] = useState(
    aggregationMapping['P75'],
  );
  const rumPerformanceOverviewTopPagesTableSortState = useSortState({
    urlStateKey: 'rumPerformanceOverviewTopPagesTableSort',
    initalState: {
      key: 'view.loading_time',
      isAsc: false,
    },
  });
  const rumAggregatesRequest = useAggregateTableRequest({
    applicationFilter,
    date,
    facetRegexState: facetRegexState,
    rumTableSortState: rumPerformanceOverviewTopPagesTableSortState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    aggregationFunction,
    sortByField: rumPerformanceOverviewTopPagesTableSortState.state.key,
    sortOrder: rumPerformanceOverviewTopPagesTableSortState.state.isAsc
      ? 'bottom'
      : 'top',
    sortAggregation: aggregationFunction,
  });

  const regularColumns = rumPerformanceTableColumns();
  const regularColumnsState = useColumnsState({
    columns: regularColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        ['view.name']: 1,
        ['view.loading_time']: 1,
        ['view.largest_contentful_paint']: 1,
        ['view.cumulative_layout_shift']: 1,
        ['view.interaction_to_next_paint']: 1,
      },
    },
    key: 'rum-aggregate-table',
  });
  const rows = useMemo(
    () => rumAggregatesRequest.result[0]?.data || [],
    [rumAggregatesRequest.result],
  );
  const tableSearch = useTableSearch({ rows });
  const tableSort = useTableBESort({
    columns: regularColumns,
    initialKey: RumTableColumnKey.date,
    rows: tableSearch.searchedRows,
    onSortChange: ({ sortBy, sortOrder }) => {
      rumPerformanceOverviewTopPagesTableSortState?.sortBy({
        sortBy,
        sortOrder,
      });
    },
  });

  const renderColoredMetric = (metric: Metric, value: Value) => (
    <span style={{ color: getColorForMetric(metric, value) }}>{value}</span>
  );

  const enhancedRows = useMemo(() => {
    return tableSort.sortedRows.map((row) => {
      return {
        ...row,
        'view.largest_contentful_paint': renderColoredMetric(
          'view.largest_contentful_paint',
          row['view.largest_contentful_paint'],
        ),
        'view.cumulative_layout_shift': renderColoredMetric(
          'view.cumulative_layout_shift',
          row['view.cumulative_layout_shift'],
        ),
        'view.interaction_to_next_paint': renderColoredMetric(
          'view.interaction_to_next_paint',
          row['view.interaction_to_next_paint'],
        ),
      };
    });
  }, [tableSort]);

  const overlayMessageProps: OverlayMessageProps = useMemo(() => {
    if (!rumAggregatesRequest.error) return null;
    if (rumAggregatesRequest.error) {
      return {
        isActive: true,
        iconName: 'warning',
        message: (
          <>
            Failed to fetch Performance Overview. Please double check your query
            and try again.
          </>
        ),
      };
    } else {
      return {
        isActive: false,
      };
    }
  }, [rumAggregatesRequest.error]);

  return (
    <>
      <Loader
        className="rum-events__table overflow-auto"
        isLoading={rumAggregatesRequest.isLoading}
        dataTestId="rum-events-table"
      >
        <div>
          {tableSearch ? (
            <TableSearch
              placeholder="Search"
              tableSearch={tableSearch}
              dataTestId="table-search"
            />
          ) : null}
        </div>

        <div className="mr-9 flex">
          <Select
            className="rum-performance-percentile-dropdown"
            value={Object.keys(aggregationMapping).find(
              (key) => aggregationMapping[key] === aggregationFunction,
            )}
            onChange={(value) =>
              setAggregationFunction(aggregationMapping[value])
            }
            options={Object.keys(aggregationMapping).map((key) => ({
              label: key,
              value: key,
            }))}
          />
        </div>
        <OverlayMessage {...overlayMessageProps}>
          <Table
            className="table--padded table--bordered table--bordered-cells"
            columns={regularColumnsState.renderedColumns}
            externalTableSort={tableSort}
            isFullWidth
            isResizingEnabled
            isSortingEnabled
            isSortingDisabledForColumn={(columnKey) => {
              if (columnKey === 'view.name') {
                return true;
              }
              return isSortingDisabledForRumColumn(columnKey);
            }}
            isStickyHeaderEnabled
            rows={enhancedRows}
            tableKey="rum-events-table"
          />
        </OverlayMessage>
      </Loader>
    </>
  );
};

export default RumPerformanceOverviewTopPages;
