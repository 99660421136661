import dayjs from 'dayjs';
import { Fingerprint, LogsState } from 'types';
import { onPromiseError } from 'utils';

import query from './query';
import { buildQuery } from './utils';

type Args = {
  groupBy?: string[];
  logsState: LogsState;
  sortBy?: string;
  sortOrder?: 'Asc' | 'Desc';
};

const getFpList = async ({
  groupBy,
  logsState,
  sortBy,
  sortOrder,
}: Args): Promise<Fingerprint[]> => {
  const { date } = logsState;
  const startTimeUnix = date.zoomedStartTimeUnix || date.startTimeUnix;
  const endTimeUnix = date.zoomedEndTimeUnix || date.endTimeUnix;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const logQuery = buildQuery(logsState);

  return query<Fingerprint[], 'getFpList'>(`
    {
      getFpList(
        durationSecs: ${durationSecs}
        ${logQuery !== '{}' ? `logQuery: ${logQuery},` : ''}
        ${
          groupBy && groupBy.length !== 0
            ? `groupBy: ${JSON.stringify(groupBy)},`
            : ''
        }
        ${sortBy ? `sortBy: "${sortBy}",` : ''}
        ${sortOrder ? `sortOrder: ${sortOrder},` : ''}
        limit: 10000,
        timestamp: "${endTime.format()}",
      ) {
        groups
        hash
        pattern
        source
        count
      }
    }
  `).then((data) => data.getFpList || [], onPromiseError);
};

export default getFpList;
