import { Icon, TooltipTrigger } from 'components';
import { useTracesState } from 'hooks';
import React, { useState } from 'react';
import TracesHeatmapChartsGridItemHorizontalBars from './TracesHeatmapChartsGridItemHorizontalBars';
import TracesHeatmapChartsGridItemTooltip from './TracesHeatmapChartsGridItemTooltip';
import { CompareAttributeCount } from './types';

type Props = {
  compareAttributeCount: CompareAttributeCount;
  hoveredNameState: ReturnType<typeof useState<string>>;
  renderValueLabel: (value: string) => string;
  tracesState: ReturnType<typeof useTracesState>;
};

const TracesHeatmapChartsGridItem = ({
  compareAttributeCount,
  hoveredNameState,
  renderValueLabel,
  tracesState,
}: Props) => {
  const { name } = compareAttributeCount;

  return (
    <div className="traces__heatmap__charts__grid__item">
      <div className="traces__heatmap__charts__grid__item__header">
        {name}
        <div className="traces__heatmap__charts__grid__item__header__info">
          <TooltipTrigger
            align="end"
            side="bottom"
            tooltip={
              <TracesHeatmapChartsGridItemTooltip
                compareAttributeCount={compareAttributeCount}
              />
            }
          >
            <Icon icon="info" />
          </TooltipTrigger>
        </div>
      </div>
      <TracesHeatmapChartsGridItemHorizontalBars
        compareAttributeCount={compareAttributeCount}
        hoveredNameState={hoveredNameState}
        renderValueLabel={renderValueLabel}
        tracesState={tracesState}
      />
    </div>
  );
};

export default TracesHeatmapChartsGridItem;
