import { AutocompleteOption } from 'components/Autocomplete';
import { getTemplateVariablesFromQuery } from 'utils';
import {
  DashboardPanelProps,
  DashboardTemplateProps,
  DashboardTemplateValueProps,
} from 'types/Dashboard';

/**
 * Check variable is used in query
 */
export const checkVariableUsedInQuery = (
  variable: string,
  templating: DashboardTemplateProps[],
): Array<{
  index: number;
  template: DashboardTemplateProps;
}> => {
  const result: Array<{
    index: number;
    template: DashboardTemplateProps;
  }> = [];

  templating.forEach((template, index) => {
    const { query } = template;
    const variables = getTemplateVariablesFromQuery(query.query);
    if (variables.includes(variable)) {
      result.push({ index, template });
    }
  });

  return result;
};

export const formatIntialTemplateOptions = ({
  templateOptions,
  templates,
}: {
  templateOptions: { [key: number]: AutocompleteOption[] };
  templates: DashboardTemplateProps[];
}) => {
  return Object.keys(templateOptions).map((key) => {
    const templateIndex = Number(key);
    const template = templates[templateIndex];
    const options = templateOptions[templateIndex];

    const isAllValueExist = options.findIndex(
      ({ value }) => value === '$__all',
    );
    if (isAllValueExist !== -1) {
      const newAllValue = template.allValue || '.*';
      options[isAllValueExist].value = newAllValue;
      return options;
    }

    if (template.includeAll) {
      const newAllValue = template.allValue || '.*';
      if (!template.allValue) {
        template.allValue = '.*';
      }
      return [...[{ label: 'All', value: newAllValue }], ...options];
    }
    return options;
  });
};

export const formatReloadTemplateOptions = ({
  preTemplateOptions,
  templateOptions,
  usedTemplates,
}: {
  preTemplateOptions: AutocompleteOption[][];
  templateOptions: { [key: number]: AutocompleteOption[] };
  usedTemplates: Array<{ index: number; template: DashboardTemplateProps }>;
}) => {
  const newTemplateOptions = [...preTemplateOptions];
  usedTemplates.forEach(({ index, template }, dataIdx) => {
    const options = templateOptions[dataIdx] || [];
    if (template.includeAll) {
      const newAllValue = template.allValue || '.*';
      options.unshift({ label: 'All', value: newAllValue });
    }

    newTemplateOptions[index] = options;
  });

  return newTemplateOptions;
};

export const getTemplateValueWithRepeated = (
  templateValues: DashboardTemplateValueProps,
  panel: DashboardPanelProps,
): DashboardTemplateValueProps => {
  const newTemplateValues = { ...templateValues };
  const { repeat, repeatValue } = panel;

  if (repeat && repeatValue) {
    newTemplateValues[repeat] = repeatValue;
  }

  return newTemplateValues;
};

export const getTemplateValueWithAll = ({
  templateValues,
  templateOptions,
  templating,
}: {
  templateValues: DashboardTemplateValueProps;
  templateOptions: AutocompleteOption[][];
  templating: DashboardTemplateProps[];
}) => {
  const newTemplateValues = { ...templateValues };
  templating.forEach((template, index) => {
    const { allValue, name } = template;
    const templateNameForQueryResult = getTemplateNameForQueryResult({
      template,
      templateValues,
    });

    if (templateNameForQueryResult) {
      const options = templateOptions[index] || [];
      const filteredOptions = options.filter(
        (option) => option.value !== allValue,
      );
      const joinValue = filteredOptions.map((option) => option.value).join('|');
      newTemplateValues[name] = joinValue;
    }
  });

  return newTemplateValues;
};

export const getTemplateNameForQueryResult = ({
  template,
  templateValues,
}: {
  template: DashboardTemplateProps;
  templateValues: DashboardTemplateValueProps;
}): string => {
  const { query, allValue } = template;
  if (!query) return '';
  const value = templateValues[template.name];
  const isValueAndAllSame = value === allValue;
  // qryType 3 is for query_result
  if (isValueAndAllSame && typeof query === 'object' && query.qryType === 3) {
    return template.name;
  }
  return '';
};
