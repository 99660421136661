import React, { useEffect, useState } from 'react';
import { formatDurationNs } from 'utils';

type Props = {
  startTimeMs: number;
};

const LogsQueryTimerElapsed = ({ startTimeMs }: Props) => {
  const [now, setNow] = useState(new Date().valueOf());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date().valueOf());
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="logs__query-timer__elapsed">{`Running: ${formatDurationNs(
      (now - startTimeMs) * 1000000,
      3,
      2,
    )}`}</div>
  );
};

export default LogsQueryTimerElapsed;
