import { SearchTag } from 'components';
import { Filter, FilterType, useFiltersState } from 'hooks';
import { Service } from 'types';
import defaultGetRangeTag from './defaultGetRangeTag';
import getFacetRegexTag from './getFacetRegexTag';
import defaultGetFacetValueTag from './defaultGetFacetValueTag';
import getKeyExistsTag from './getKeyExistsTag';
import getSearchTermTag from './getSearchTermTag';
import getFilterOrExcludeFingerprintTag from './getFilterOrExcludeFingerprintTag';

type Args = {
  colorsByServiceName: Record<string, string>;
  filtersState: ReturnType<typeof useFiltersState>;
  getMeta?: (filter: Filter) => SearchTag['meta'];
  renderFacet?: (facet: string) => string;
  isDurationInNs?: boolean;
  serviceByHash: Record<string, Service>;
};

type GetTagAndLabelTextArgs = {
  colorsByServiceName: Record<string, string>;
  filter: Filter;
  isDurationInNs?: boolean;
  renderFacet?: (facet: string) => string;
  serviceByHash: Record<string, Service>;
};

const getTagLabelAndText = ({
  colorsByServiceName,
  filter,
  isDurationInNs,
  renderFacet,
  serviceByHash,
}: GetTagAndLabelTextArgs) => {
  const { type } = filter;
  switch (type) {
    case FilterType.selectedRange:
      return defaultGetRangeTag({
        filter,
        isDurationInNs,
        renderFacet,
      });
    case FilterType.facetRegex:
      return getFacetRegexTag(filter);
    case FilterType.filterOrExcludeByFingerprint:
      return getFilterOrExcludeFingerprintTag({ filter });
    case FilterType.selectedFacetValue:
      return defaultGetFacetValueTag({
        colorsByServiceName,
        renderFacet,
        selectedFacetValueFilter: filter,
        serviceByHash,
      });
    case FilterType.searchTerms: {
      return getSearchTermTag(filter);
    }

    case FilterType.keyExists:
      return getKeyExistsTag({ filter, renderFacet });
    default:
      return {
        label: null,
        text: '',
      };
  }
};

type GetTagArgs = {
  colorsByServiceName: Record<string, string>;
  deleteByIndex: (i: number) => void;
  getMeta?: (filter: Filter) => SearchTag['meta'];
  isDurationInNs?: boolean;
  renderFacet?: (facet: string) => string;
  serviceByHash: Record<string, Service>;
};

const getTag =
  ({
    colorsByServiceName,
    deleteByIndex,
    getMeta,
    isDurationInNs,
    renderFacet,
    serviceByHash,
  }: GetTagArgs) =>
  (filter: Filter, i: number) => {
    const onRemove = () => {
      deleteByIndex(i);
    };

    return {
      ...getTagLabelAndText({
        colorsByServiceName,
        filter,
        isDurationInNs,
        renderFacet,
        serviceByHash,
      }),
      ...(getMeta ? { meta: getMeta(filter) } : {}),
      onRemove,
    };
  };

const getTags = ({
  colorsByServiceName,
  filtersState,
  getMeta,
  isDurationInNs,
  renderFacet,
  serviceByHash,
}: Args) => {
  const { deleteByIndex } = filtersState;
  const tags = filtersState.state.map(
    getTag({
      colorsByServiceName,
      deleteByIndex,
      getMeta,
      isDurationInNs,
      renderFacet,
      serviceByHash,
    }),
  );

  return tags;
};

export default getTags;
