import { Button } from 'components';
import { useToggle } from 'hooks';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { RequestResult } from 'types/Request';

import { AddPermission } from '../AddPermission';
import { GroupMemberProps } from './types';

const GroupManagementMembersAdd = ({
  groupMembersRequest,
}: {
  groupMembersRequest: RequestResult<GroupMemberProps[], any>;
}): ReactElement => {
  const { groupId } = useParams();
  const addMemberToggle = useToggle(false);

  return (
    <div>
      <div className="flex items-center justify-between pb-2">
        <h2 className="text-lg font-semibold">Members</h2>
        <Button variant="default" size="sm" onClick={addMemberToggle.toggle}>
          Add Member
        </Button>
      </div>
      {addMemberToggle.value && (
        <AddPermission
          type="group"
          groupId={groupId}
          onPermissionAdded={() => {
            groupMembersRequest.call({ groupId });
          }}
          onClose={addMemberToggle.toggle}
        />
      )}
    </div>
  );
};

export default GroupManagementMembersAdd;
