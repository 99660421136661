import { EventType, eventWithTime, IncrementalSource } from '@rrweb/types';
import { InactivePeriod, RumEvent, Session, SessionMetadata } from './types';

export const isUserInteraction = (rrwebEvent: eventWithTime): boolean => {
  if (rrwebEvent.type !== EventType.IncrementalSnapshot) {
    return false;
  }
  return (
    rrwebEvent.data.source > IncrementalSource.Mutation &&
    rrwebEvent.data.source <= IncrementalSource.Input
  );
};

const INACTIVE_PERIOD_THRESHOLD_MS = 10 * 1000;

export const getInactivePeriods = ({
  rrwebEvents,
}: {
  rrwebEvents: eventWithTime[];
}): InactivePeriod[] => {
  const inactivePeriods: InactivePeriod[] = [];
  if (rrwebEvents.length) {
    let lastActiveTime = rrwebEvents[0].timestamp;
    for (const rrwebEvent of rrwebEvents) {
      if (!isUserInteraction(rrwebEvent)) continue;
      if (
        rrwebEvent.timestamp - lastActiveTime >
        INACTIVE_PERIOD_THRESHOLD_MS
      ) {
        inactivePeriods.push({
          startTimeUnixMs: lastActiveTime,
          endTimeUnixMs: rrwebEvent.timestamp,
        });
      }
      lastActiveTime = rrwebEvent.timestamp;
    }
  }
  return inactivePeriods;
};

export const getSessionInactivePeriods = ({
  eventsBySegmentKeyByTabId,
  session,
}: {
  eventsBySegmentKeyByTabId: Record<string, Record<string, eventWithTime[]>>;
  session: SessionMetadata;
}) => {
  let result: InactivePeriod[] = [];
  const { tabs } = session;
  tabs.forEach((tab, index) => {
    const eventsBySegmentKey = eventsBySegmentKeyByTabId[tab.tabId] || {};
    const rrwebEvents = tab.segments.reduce(
      (arr, segment) => [
        ...arr,
        ...(eventsBySegmentKey[segment.segmentKey] || []),
      ],
      [],
    );
    const tabInactivePeriods = getInactivePeriods({
      rrwebEvents,
    });

    result = [...result, ...tabInactivePeriods];

    const previousTab = index > 0 ? tabs[index - 1] : null;
    if (
      previousTab &&
      previousTab.endTimeUnixMs &&
      tab.startTimeUnixMs &&
      tab.startTimeUnixMs > previousTab.endTimeUnixMs
    ) {
      result = [
        ...result,
        {
          startTimeUnixMs: previousTab.endTimeUnixMs,
          endTimeUnixMs: tab.startTimeUnixMs,
        },
      ];
    }
  });

  return result;
};

type FilterOutBackgroundEventsArgs = {
  events: RumEvent[];
  eventsBySegmentKeyByTabId: Record<string, Record<string, eventWithTime[]>>;
  session: SessionMetadata;
};

export const filterOutBackgroundEvents = ({
  events,
  eventsBySegmentKeyByTabId,
  session,
}: FilterOutBackgroundEventsArgs) => {
  const sessionInactivePeriods = getSessionInactivePeriods({
    eventsBySegmentKeyByTabId,
    session,
  });
  return events.filter(
    (event) =>
      !sessionInactivePeriods.find(
        (sessionInactivePeriod) =>
          event.time > sessionInactivePeriod.startTimeUnixMs &&
          event.time < sessionInactivePeriod.endTimeUnixMs,
      ),
  );
};

export const getBarLeftAndWidth = ({
  startTimeUnixMs,
  endTimeUnixMs,
  session,
}: {
  startTimeUnixMs: number;
  endTimeUnixMs: number;
  session: Session;
}) => {
  const totalMs = session.endTimeUnixMs - session.startTimeUnixMs;
  const relativeStartMs = startTimeUnixMs - session.startTimeUnixMs;
  const left = `${(relativeStartMs / totalMs) * 100}%`;
  const width = `${((endTimeUnixMs - startTimeUnixMs) / totalMs) * 100}%`;
  return {
    left,
    width,
  };
};

export const findTabIndexByMs = ({
  session,
  ms,
}: {
  session: SessionMetadata;
  ms: number;
}) => {
  const { tabs } = session;
  for (let i = 0; i < tabs.length; i += 1) {
    const tab = tabs[i];
    const { startTimeUnixMs, endTimeUnixMs } = tab;
    const relativeStartTime = startTimeUnixMs - session.startTimeUnixMs;
    const relativeEndTime = endTimeUnixMs - session.startTimeUnixMs;
    if (ms >= relativeStartTime && ms < relativeEndTime) {
      return i;
    }
  }

  return -1;
};
