import {
  useTableSearch,
  useTableSort,
  usePaginator,
  TableSearch,
  Table,
  Paginator,
  TooltipTrigger,
  Loader,
  Button,
  ConfirmationModal,
  useModalsContext,
  ErrorMessage,
} from 'components';
import { useRequest } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdDelete as DeleteIcon, MdModeEdit as EditIcon } from 'react-icons/md';

import { deleteGroupById, getGroupList } from './requests';
import { GroupProps } from './types';
import { userManagementError } from 'utils/error/userManagementError';

const columnsGroup = (onDeleteGroup: (row: GroupProps) => void) => [
  { key: 'name', label: 'Name' },
  { key: 'email', label: 'Email' },
  { key: 'membercount', label: 'Members' },
  {
    label: '',
    key: 'actions',
    renderCell: ({ row }: { row: GroupProps }) => {
      return (
        <div
          className="table__row__actions"
          style={{ '--table-actions-width': '74px' }}
        >
          <div className="table__row__actions--hidden">
            <div className="table__row__actions__slider">
              <div className="alerts__contacts__table__actions">
                {row.permission === 'Admin' ? (
                  <>
                    <div className="table__cell__actions__item--blue">
                      <Link className="link" to={`/admin/groups/${row.id}`}>
                        <TooltipTrigger tooltip="Edit">
                          <EditIcon
                            className="alerts__contacts__table__actions__icon--edit"
                            size={18}
                          />
                        </TooltipTrigger>
                      </Link>
                    </div>
                    <div
                      className="table__cell__actions__item--red"
                      onClick={() => onDeleteGroup(row)}
                    >
                      <TooltipTrigger tooltip="Delete">
                        <DeleteIcon
                          className="alerts__contacts__table__actions__icon--delete"
                          size={18}
                        />
                      </TooltipTrigger>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
];

const emptyArr: GroupProps[] = [];
const GroupManagement = () => {
  const modal = useModalsContext();
  const groupListRequest = useRequest(getGroupList, true, true);
  const deleteGroupRequest = useRequest(deleteGroupById);

  const [error, setError] = useState({
    getGroupList: null,
  });

  useEffect(() => {
    groupListRequest
      .call()
      .then((nextResult) => {
        if (nextResult) {
          setError((prevError) => ({ ...prevError, getGroupList: null }));
        }
      })
      .catch(() => {
        setError((prevError) => ({
          ...prevError,
          getGroupList: { message: userManagementError.getGroupList },
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteGroup = (row: GroupProps) => {
    modal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description={
          <span>
            Are you sure you want to delete{' '}
            <span className="font-bold">{row.name}</span> group? This action
            cannot be undone.
          </span>
        }
        dataTestId="delete-group-confirmation-modal"
        onCancel={() => modal.pop()}
        onConfirm={() => {
          deleteGroupRequest.call({ groupId: row.id }).then(() => {
            modal.pop();
            groupListRequest.call();
          });
        }}
        title="Delete User"
      />,
    );
  };

  const columns = columnsGroup(onDeleteGroup);
  const groups = groupListRequest.result || emptyArr;
  const tableSearch = useTableSearch({ rows: groups });
  const tableSort = useTableSort({ columns, rows: tableSearch.searchedRows });
  const paginator = usePaginator({
    rows: tableSort.sortedRows,
    initialNumberOfRowsPerPage: 25,
  });

  return (
    <div className="mx-auto max-w-[1200px] py-4">
      <div className="flex items-center justify-between pb-2">
        <h2 className="text-lg font-semibold">Group Management</h2>

        <div className="flex items-center gap-[2px]">
          <ErrorMessage
            message={error?.getGroupList?.message}
            className="justify-end pr-[14px]"
          />
          <Link to="/admin/groups/new">
            <Button variant="default" size="sm">
              Add New Group
            </Button>
          </Link>
        </div>
      </div>
      <Loader isLoading={groupListRequest.isLoading}>
        <TableSearch
          className="dashboard__list__search"
          placeholder="Search Groups..."
          tableSearch={tableSearch}
          dataTestId="dashboard-list-search"
        />
        <Table
          className="table--bordered table--bordered-cells table__actions--hidden alerts__list__table"
          columns={columns}
          dataTestId="alerts-list-table"
          externalTableSort={tableSort}
          isSortingEnabled
          rows={paginator.paginatedRows}
        />
        <div className="table-footer">
          <Paginator paginator={paginator} />
        </div>
      </Loader>
    </div>
  );
};

export default GroupManagement;
