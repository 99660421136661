import { TableRow, TableRowProps } from 'components';
import { useRequest, useTracesState } from 'hooks';
import React, { useMemo } from 'react';
import { traceErrorGroups } from 'requests';
import { TraceErrorGroup } from 'types';

type Props = {
  isServiceFromDatabasesList: boolean;
  serviceHash: string;
  tableRowProps: TableRowProps<TraceErrorGroup>;
  tracesState: ReturnType<typeof useTracesState>;
};

const APMErrorGroupTableRow = ({
  isServiceFromDatabasesList,
  serviceHash,
  tableRowProps,
  tracesState,
}: Props) => {
  const fetchErrorGroupingKey = useRequest(traceErrorGroups, true, true);

  const onFetchErrorGroupingKey = (errorGroupingKey: string) => {
    if (!fetchErrorGroupingKey.calledAtLeastOnce) {
      fetchErrorGroupingKey.call({
        errorGroupingKey,
        isServiceFromDatabasesList,
        serviceHash,
        tracesState,
      });
    }
  };

  const optimizedRowData = useMemo(() => {
    const data = fetchErrorGroupingKey.result || [];
    if (data.length > 0) {
      return data[0];
    }
    return null;
  }, [fetchErrorGroupingKey.result]);

  return (
    <TableRow
      {...tableRowProps}
      row={{
        ...tableRowProps.row,
        onFetchErrorGroupingKey: onFetchErrorGroupingKey,
        optimizedRowData: optimizedRowData,
      }}
    />
  );
};

export default APMErrorGroupTableRow;
