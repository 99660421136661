import dayjs from 'dayjs';
import {
  DateSelection,
  FacetRegexTerm,
  KeyExists,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  SpanFilter,
  Trace,
} from 'types';
import { onPromiseError } from 'utils';
import queryTraceService from './queryTraceService';
import { buildTracesFilter } from './utils';

type Args = {
  customerFilter?: { key: string; value: string };
  date: DateSelection;
  facetRegex: FacetRegexTerm[];
  keyExists: KeyExists;
  limit?: number;
  pageNum?: number;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  selectedHcFacetValuesByName: SelectedFacetValuesByName;
  spanFilter?: SpanFilter;
  sortBy?: string;
  sortOrder?: string;
  ParentSpanIdFilter?: string;
  traceIdSearch: string;
};

const traces = async ({
  customerFilter,
  date,
  facetRegex,
  keyExists,
  limit = 100,
  pageNum = 1,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  selectedHcFacetValuesByName,
  ParentSpanIdFilter,
  spanFilter,
  sortBy,
  sortOrder,
  traceIdSearch,
}: Args): Promise<Trace[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  return queryTraceService<Trace[], 'traces'>(`
    {
      traces (
        durationSecs: ${durationSecs},
        filter: ${buildTracesFilter({
          customerFilter,
          facetRegex,
          keyExists,
          selectedFacetRangeByName,
          selectedFacetValuesByName,
          selectedHcFacetValuesByName,
          parentSpanIdFilter: ParentSpanIdFilter,
          spanFilter,
          traceIdSearch,
        })}
        limit: ${limit},
        pageNum: ${pageNum}
        timestamp: "${endTime.format()}",
        ${sortBy ? `sortField: "${sortBy}",` : ''}
        ${sortOrder ? `sortOrder: ${sortOrder},` : ''}
      ) {
        traceId
        span {
          spanId
          parentSpanId
          startTimeNs
          endTimeNs
          attributes
          durationNs
          name
          service {
            name
            labels
            hash
            distinctLabels
          }
          statusCode
          method
          endpoint
          rootSpan
        }
        traceMetrics {
          spanCount
          serviceExecTimeNs
        }
      }
    }
  `).then((data) => data.traces || [], onPromiseError);
};

export default traces;
