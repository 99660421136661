import { useEffect } from 'react';

interface Shortcut {
  callback: (event: KeyboardEvent) => void;
  requiresModifier?: boolean;
}

type ShortcutMap = {
  [key: string]: Shortcut;
};

const useKeyboardShortcut = (shortcutMap: ShortcutMap): void => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const shortcutKeyCode = event.keyCode;
      const shortcutKey = event.key;
      const modifier = event.ctrlKey || event.metaKey;
      const shortcut = shortcutMap[shortcutKey] || shortcutMap[shortcutKeyCode];

      if (shortcut) {
        const { callback, requiresModifier } = shortcut;

        if (
          (requiresModifier && modifier) ||
          (!requiresModifier && !modifier)
        ) {
          callback(event);
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [shortcutMap]);
};

export default useKeyboardShortcut;
