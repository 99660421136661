import { Loader, TooltipTrigger } from 'components';
import { Separator } from 'components/shadcn';
import React, { ReactElement, useEffect } from 'react';
import { DateSelection } from 'types/DateSelection';

import AlertsServiceCorrelationsMap from './AlertsServiceCorrelationsMap';
import AlertsServiceCorrelationsFilters from './AlertsServiceCorrelationsFilters';
import AlertsServiceCorrelationsTabs from './AlertsServiceCorrelationsTabs';
import useAlertsServiceCorrelationState from './useAlertsServiceCorrelationState';
import { AlertEventProps } from '../types';

const AlertsServiceCorrelations = ({
  activeAlertEvent,
  date,
  promqls,
  kfSource,
  serviceHash,
}: {
  activeAlertEvent: AlertEventProps;
  date: DateSelection;
  promqls: string;
  kfSource: string;
  serviceHash: string;
}): ReactElement => {
  const alertsServiceCorrelationState = useAlertsServiceCorrelationState({
    alertEvent: activeAlertEvent,
    kfSource,
    promqls,
    serviceHash,
  });

  const {
    analysisServiceCorrelationRequest,
    getServicesRequest,
    hideServiceMap,
    serviceByHash,
  } = alertsServiceCorrelationState;

  useEffect(() => {
    if (activeAlertEvent) {
      getServicesRequest.call({ date });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAlertEvent]);

  return (
    <Loader
      className="alerts__details__analysis box-shadow"
      isLoading={analysisServiceCorrelationRequest.isLoading}
    >
      <TooltipTrigger
        className="alerts__details__subheader"
        tooltip="Historical events on this alerts."
      >
        <h2 className="text-lg font-semibold">Alert Event Analysis</h2>
      </TooltipTrigger>
      <div className="flex">
        {!hideServiceMap && (
          <>
            <AlertsServiceCorrelationsMap
              alertsServiceCorrelationState={alertsServiceCorrelationState}
              date={date}
              serviceHash={serviceHash}
              serviceByHash={serviceByHash}
            />
            <Separator orientation="vertical" className="h-auto" />
          </>
        )}
        <AlertsServiceCorrelationsFilters
          alertsServiceCorrelationState={alertsServiceCorrelationState}
        />
      </div>
      <AlertsServiceCorrelationsTabs
        alertsServiceCorrelationState={alertsServiceCorrelationState}
        date={date}
        serviceHash={serviceHash}
      />
    </Loader>
  );
};

export default AlertsServiceCorrelations;
