import { fetchGrafanaApi } from 'requests';

const getFoldersItemsCount = async ({
  uid,
}: {
  uid: string;
}): Promise<{ alertrule: number; dashboard: number; librarypanel: number }> => {
  const result = await fetchGrafanaApi(`grafana/api/folders/${uid}/counts`);

  return result;
};

export default getFoldersItemsCount;
