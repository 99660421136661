import query from 'requests/query';

type Args = {
  displayName: string;
  facetGroup: string;
};

const removeLogsFavoriteFacet = async ({
  displayName,
  facetGroup,
}: Args): Promise<Record<'removeFavoriteFacet', void>> => {
  return query<void, 'removeFavoriteFacet'>(`
    mutation {
      removeFavoriteFacet (
        display_name: "${displayName}"
        facetGroup: "${facetGroup}"
      )
    }
  `);
};

export default removeLogsFavoriteFacet;
