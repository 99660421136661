import { FacetRegexTerm } from 'types';

export enum Operator {
  equal = '=',
  notEqual = '!=',
  greaterThan = '>',
  lessThan = '<',
  greaterThanOrEqualTo = '>=',
  lessThanOrEqualTo = '<=',
  regex = '=~',
  notRegex = '!~',
  facetTermsExist = '==',
  notFacetTermsExist = '!==',
  startsWith = '*~',
  contains = '**',
  endsWith = '~*',
}

export enum FilterType {
  facetRegex = 'facetRegex',
  filterOrExcludeByFingerprint = 'filterOrExcludeByFingerprint',
  keyExists = 'keyExists',
  searchTerms = 'searchTerms',
  selectedFacetValue = 'selectedFacetValue',
  selectedRange = 'selectedRange',
}

export type FilterCommon = {
  disabled?: boolean;
};

export type BitmapFilter = FilterCommon & {
  type: FilterType.keyExists | FilterType.filterOrExcludeByFingerprint;
  value: {
    facet: string;
    operator: Operator.equal | Operator.notEqual;
  };
};

export type FacetRegexFilter = FilterCommon & {
  type: FilterType.facetRegex;
  value: FacetRegexTerm;
};

export type SelectedRangeFilterRange = {
  isLowerInclusive?: boolean;
  isUpperInclusive?: boolean;
  lower: number;
  upper: number;
};

export type SelectedRangeFilter = FilterCommon & {
  type: FilterType.selectedRange;
  value: {
    facet: string;
  } & SelectedRangeFilterRange;
};

export type SelectedFacetValueFilter = FilterCommon & {
  type: FilterType.selectedFacetValue;
  value: {
    facet: string;
    operator:
      | Operator.equal
      | Operator.notEqual
      | Operator.regex
      | Operator.notRegex
      | Operator.facetTermsExist
      | Operator.notFacetTermsExist
      | Operator.startsWith
      | Operator.contains
      | Operator.endsWith;
    values: Record<string, 1>;
  };
};

export type TermsFilter = FilterCommon & {
  type: FilterType.searchTerms;
  value: string;
};

export type Filter =
  | BitmapFilter
  | FacetRegexFilter
  | SelectedRangeFilter
  | SelectedFacetValueFilter
  | TermsFilter;
