import React, { ReactElement } from 'react';
import { Plus } from 'react-feather';
import { DashboardPanelComponentProps } from 'types';

import { getPanelWidthHeight } from '../utils';

const DashboardPanelPlaceholder = ({
  baseWidth,
  dashboardState,
  panel,
}: DashboardPanelComponentProps): ReactElement => {
  const { isRightSidebarOpenToggle } = dashboardState;
  const { heightContainer } = getPanelWidthHeight(
    panel.gridPos,
    baseWidth,
    'p',
  );

  return (
    <div
      className="dashboard__add-panel"
      onClick={() => {
        if (!isRightSidebarOpenToggle.value) {
          isRightSidebarOpenToggle.on();
        }
      }}
      style={{ height: heightContainer - 4 }}
    >
      {isRightSidebarOpenToggle.value ? (
        <div>Drag and drop widget</div>
      ) : (
        <div>
          <Plus />
          Add Widgets
        </div>
      )}
    </div>
  );
};

export default DashboardPanelPlaceholder;
