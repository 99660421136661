import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { LuCheck as Check } from 'react-icons/lu';
import { exhaustiveSwitchError } from 'utils';
import { cn } from 'utils/tailwind';

const DefaultCheckbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'peer h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground',
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn('flex items-center justify-center text-current')}
    >
      <Check className="size-4" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));

const LargeCheckMark = ({ className }: { className: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={className}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="4"
      d="M5 13l4 4L19 7"
      strokeLinecap="square"
      strokeLinejoin="miter"
    />
  </svg>
);

const CompactCheckbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'w-4 h-4 rounded-[2px] border border-interaction-primary hover:border-form-border-hover transition-colors duration-150 ease-in-out',
      'data-[state=checked]:bg-interaction-primary data-[state=checked]:text-primary-foreground',
      'shadow-sm hover:shadow-md',
      'focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-0 focus-visible:ring-interaction-primary',
      className,
    )}
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        props?.onCheckedChange(!props.checked);
      }
    }}
    style={props.disabled ? { cursor: 'not-allowed', opacity: 0.5 } : {}}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn('flex items-center justify-center')}
    >
      <LargeCheckMark className="size-3.5 text-white" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    variant: 'default' | 'compact';
  }
>(({ className, ...props }, ref) => {
  const { variant } = props;
  switch (variant) {
    case 'default':
      return <DefaultCheckbox ref={ref} {...props} />;
    case 'compact':
      return <CompactCheckbox ref={ref} {...props} />;
    default:
      throw exhaustiveSwitchError(variant);
  }
});

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
