import fetchGrafanaApi from './fetchGrafanaApi';

const mutateGrafanaDashboard = ({
  jsonModel,
  message,
  folderUid,
}: {
  jsonModel: any;
  message: string;
  folderUid?: string;
}): Promise<Record<string, any>> => {
  return fetchGrafanaApi(`grafana/api/dashboards/db/`, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({
      dashboard: jsonModel,
      folderUid: folderUid,
      overwrite: false,
      message: message || '',
    }),
  });
};

export default mutateGrafanaDashboard;
