import { colorsByLogLevel } from 'kfuse-constants';
import { RumEventType } from '../types';
import { chartColors } from 'utils';

const getChartColor = (eventType: RumEventType) => {
  switch (eventType) {
    case RumEventType.ERROR:
      return colorsByLogLevel.error;
    case RumEventType.VIEW:
      return chartColors[0];
    case RumEventType.ACTION:
      return chartColors[1];
    case RumEventType.LONGTASK:
      return chartColors[2];
    case RumEventType.SESSION:
      return chartColors[4];
    case RumEventType.RESOURCE:
      return chartColors[3];

    default:
      return chartColors[0];
  }
};

export default getChartColor;
