import dayjs from 'dayjs';
import { useTracesState } from 'hooks';
import { ChartGridItem, DateSelection, TimeSeries } from 'types';
import {
  convertAggregateTimeSeriesFromFloatToSeconds,
  formatAggregateTimeSeriesData,
  onPromiseError,
} from 'utils';
import queryTraceService from './queryTraceService';
import { buildTracesFilter } from './utils';

type Args = {
  aggregation: string;
  aggregationField: string;
  dateToCompare?: DateSelection;
  errorGroupingKey?: string;
  groupBys: string[];
  isServiceFromDatabasesList?: boolean;
  rollUpSeconds: number;
  serviceHash?: string;
  tracesState: ReturnType<typeof useTracesState>;
};

const aggregateTraceErrors = async ({
  aggregation,
  aggregationField,
  dateToCompare,
  errorGroupingKey,
  groupBys,
  isServiceFromDatabasesList,
  rollUpSeconds,
  serviceHash,
  tracesState,
}: Args): Promise<ChartGridItem> => {
  const {
    customerFilter,
    dateState,
    keyExistsState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
  } = tracesState;

  const [date] = dateToCompare ? [dateToCompare] : dateState;

  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const shouldUseCount = aggregation === 'distinctcount' && !aggregationField;

  const keyExists = keyExistsState.state;
  const selectedFacetRangeByName = selectedFacetRangeByNameState.state;
  const selectedFacetValuesByName = { ...selectedFacetValuesByNameState.state };

  if (errorGroupingKey) {
    selectedFacetValuesByName['error_grouping_key'] = {
      [errorGroupingKey]: 1,
    };
  }

  if (serviceHash) {
    if (isServiceFromDatabasesList) {
      selectedFacetValuesByName['kf_database_service_hash'] = {
        [serviceHash]: 1,
      };
    } else {
      selectedFacetValuesByName['service_hash'] = {
        [serviceHash]: 1,
      };
    }
  }

  return queryTraceService<ChartGridItem, 'aggregateTraceErrors'>(`
    {
      aggregateTraceErrors(
        aggregation: "${shouldUseCount ? 'count' : aggregation}"
        aggregationField: "${aggregationField ? aggregationField : '*'}"
        durationSecs: ${durationSecs}
        filter: ${buildTracesFilter({
          customerFilter,
          keyExists,
          selectedFacetRangeByName,
          selectedFacetValuesByName,
        })}
        groupBy: [${groupBys.map((groupBy) => `"${groupBy}"`).join(',')}]
        rollUpSeconds: ${rollUpSeconds}
        timestamp: "${endTime.format()}"
      ) {
        BucketStartSecs
        GroupVal
        Value
      }
    }
  `)
    .then(
      (data) =>
        (data?.aggregateTraceErrors || []).map(
          convertAggregateTimeSeriesFromFloatToSeconds,
        ),
      onPromiseError,
    )
    .then((points: TimeSeries[]) =>
      formatAggregateTimeSeriesData({
        date,
        points,
        step: rollUpSeconds,
        fillMissingTimestamps: true,
      }),
    );
};

export default aggregateTraceErrors;
