import classnames from 'classnames';
import formatDuration from 'format-duration';
import React, { useEffect, useRef } from 'react';
import { colorByRumEventType } from './constants';
import RumReplayEventsItemDescription from './RumReplayEventsItemDescription';
import { RumEvent } from './types';

type Props = {
  event: RumEvent;
  eventMs: number;
  isHighlighted: boolean;
  onClick: VoidFunction;
};

const RumReplayEventsItem = ({
  event,
  eventMs,
  isHighlighted,
  onClick,
}: Props) => {
  const elementRef = useRef(null);

  useEffect(() => {
    if (isHighlighted) {
      const element = elementRef.current;
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }
  }, [isHighlighted]);
  return (
    <button
      className={classnames({
        'rum-replay__event': true,
        'rum-replay__event--highlighted': isHighlighted,
      })}
      onClick={onClick}
      ref={elementRef}
    >
      <div
        className="rum-replay__event__dot"
        style={{ backgroundColor: colorByRumEventType[event.eventType] }}
      />
      {formatDuration(eventMs)}
      <div className="rum-replay__event__description">
        <RumReplayEventsItemDescription event={event} />
      </div>
    </button>
  );
};

export default RumReplayEventsItem;
