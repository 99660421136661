import { useRequest } from 'hooks';
import { useEffect, useMemo } from 'react';
import {
  aggregateTimeSeriesMultipleV2,
  getLogMetricsResultWithKfuseQlMultiple,
  getLogMetricsTimeSeriesLogQLMultiple,
  getServices,
  promqlQuery,
  promqlQueryRangeV3Multiple,
} from 'requests';
import {
  DashboardPanelType,
  DateSelection,
  QueryLangType,
  RequestResult,
  SelectedFacetValuesByName,
} from 'types';
import { DataFrame, replaceServiceHashWithLabel } from 'utils/DataTransformer';

import { DashboardTemplateValueProps } from '../types';
import {
  getActivePromqlQuery,
  getPanelWidthHeight,
  getPromqlsWithMetaForDashboard,
  getLogsQueryWithMetaForDashboard,
  getReplacedTimeFromWithDate,
  getTemplateValueWithAll,
  getTemplateValueWithRepeated,
  mapTimeseriesDrawStyle,
  transformPromql,
  getQueryLangType,
  getTraceQueryWithMetaForDashboard,
  getRumWithMetaForDashboard,
  getEventsQueryForDashboard,
} from '../utils';
import useDashboardState from './useDashboardState';
import useDashboardTemplateState from './useDashboardTemplateState';
import { rumAnalyticsFetchMulti } from '../../Rum/requests';
import { aggregateEventTimeSeries } from '../../Events/requests';

const useDashboardDataLoader = ({
  baseWidth,
  dashboardState,
  dashboardTemplateState,
  isInView,
  nestedIndex,
  panelIndex,
  templateValues,
  type,
}: {
  baseWidth?: number;
  dashboardState: ReturnType<typeof useDashboardState>;
  dashboardTemplateState: ReturnType<typeof useDashboardTemplateState>;
  isInView?: boolean;
  nestedIndex: string;
  panelIndex: number;
  templateValues: DashboardTemplateValueProps;
  type: string;
}): RequestResult<any, any> => {
  const promqlQueryRequest = useRequest(promqlQuery, true, true);
  const promqlRequest = useRequest(promqlQueryRangeV3Multiple, true, true);
  const getLogMetricsTimeSeriesLogQLRequest = useRequest(
    getLogMetricsTimeSeriesLogQLMultiple,
    true,
    true,
  );
  const getLogMetricsResultWithKfuseQlRequest = useRequest(
    getLogMetricsResultWithKfuseQlMultiple,
    true,
    true,
  );
  const rumAnalyticsFetchMultiRequest = useRequest(
    rumAnalyticsFetchMulti,
    true,
    true,
  );
  const eventQueryRequest = useRequest(aggregateEventTimeSeries, true, true);
  const getServicesRequest = useRequest(getServices);
  const tracesQueryRequest = useRequest(aggregateTimeSeriesMultipleV2);

  const { date, panels, reloadPanels, setReloadPanels, userActionRef } =
    dashboardState;
  const { templateOptions, templating } = dashboardTemplateState;
  const nestedIndexNum = Number(nestedIndex);
  let panel = panels[panelIndex];
  let panelKey = `${panelIndex}`;
  const queryLangType = getQueryLangType(panel);

  if (
    nestedIndex !== undefined &&
    nestedIndex !== null &&
    nestedIndexNum >= 0
  ) {
    panel = panels[nestedIndexNum].panels[panelIndex];
    panelKey = `${nestedIndexNum}-${panelIndex}`;
  }

  const { width: panelWidth } = useMemo(
    () => getPanelWidthHeight(panel.gridPos, baseWidth),
    [baseWidth, panel?.gridPos],
  );

  const defaultChartTypes = useMemo(
    () => mapTimeseriesDrawStyle(panel?.fieldConfig?.defaults?.custom),
    [panel?.fieldConfig],
  );

  if (!panel) return {};

  const { datasource, targets } = panel;
  const isLoki = datasource && datasource.type === 'loki';
  const isPrometheus = datasource && datasource.type === 'prometheus';
  const isTempo = datasource && datasource.type === 'tempo';
  const isRum = datasource && datasource.type === 'rum';
  const isEvents = datasource && datasource.type === 'event';
  const templateValuesWithRepeated = getTemplateValueWithRepeated(
    templateValues,
    panel,
  );
  const templateValuesWithAll = getTemplateValueWithAll({
    templateValues: templateValuesWithRepeated,
    templateOptions,
    templating,
  });
  let isRange = targets.some((target) => target.range && !target.hide);
  if (
    isRange &&
    type !== 'unflattened' &&
    type !== DashboardPanelType.TIMESERIES
  ) {
    isRange = false;
  }

  if (type === DashboardPanelType.TIMESERIES) {
    isRange = true;
  }

  const callInstantPrometheus = (panelDate: DateSelection) => {
    const promqlQueries = getActivePromqlQuery(panel.targets).map((target) =>
      transformPromql({
        date: panelDate,
        promql: target.expr,
        templateValues: templateValuesWithAll,
        width: panelWidth,
        chartType: defaultChartTypes[0],
      }),
    );

    promqlQueryRequest
      .call({
        promqlQueries,
        responseFormat: type === 'stat' ? undefined : type,
      })
      .then(() => updateReloadPanels(false))
      .catch(() => {});
  };

  const callLoki = (panelDate: DateSelection) => {
    const logsQueryWithMeta = getLogsQueryWithMetaForDashboard({
      date: panelDate,
      panel,
      templateValues: templateValuesWithAll,
      userActionRef,
      width: panelWidth,
    });

    const { logsWithMeta, mainTransformer, queryLangType } = logsQueryWithMeta;
    if (queryLangType === QueryLangType.LOGQL) {
      getLogMetricsTimeSeriesLogQLRequest
        .call({
          instant: type !== DashboardPanelType.TIMESERIES,
          logqlWithMeta: logsWithMeta,
          mainTransformer,
        })
        .then(() => updateReloadPanels(false));
    } else {
      getLogMetricsResultWithKfuseQlRequest
        .call({
          instant: type !== DashboardPanelType.TIMESERIES,
          logqlWithMeta: logsWithMeta,
          mainTransformer,
        })
        .then(() => updateReloadPanels(false));
    }
  };

  const callPromqlRequest = async () => {
    const { promqlWithMeta, mainTransformer, isRange } =
      getPromqlsWithMetaForDashboard({
        date,
        panel,
        templateValues: templateValuesWithAll,
        userActionRef,
        width: panelWidth,
      });

    promqlRequest
      .call({ promqlWithMeta, mainTransformer, instant: !isRange })
      .then(() => updateReloadPanels(false));
  };

  const callTraceRequest = async (panelDate: DateSelection) => {
    const { traceQueryWithMeta, mainTransformer, isRange, isGroupedByService } =
      getTraceQueryWithMetaForDashboard({
        date: panelDate,
        panel,
        templateValues: templateValuesWithAll,
        userActionRef,
        width: panelWidth,
      });

    const traceQuery = traceQueryWithMeta[0];
    const targetTransformer = traceQuery.transformer;

    if (isGroupedByService) {
      const selectedFacetValuesByName = { span_type: { db: 0 } };
      const servicesResponse = await getServicesRequest.call({
        date,
        selectedFacetValuesByName:
          selectedFacetValuesByName as SelectedFacetValuesByName,
      });

      if (servicesResponse && servicesResponse.length) {
        const serviceByHash = servicesResponse.reduce(
          (obj, service) => ({ ...obj, [service.hash]: service }),
          {},
        );

        targetTransformer.splice(1, 0, {
          id: 'replaceServiceHashWithLabel',
          func: (dataFrame: DataFrame) =>
            replaceServiceHashWithLabel(dataFrame, serviceByHash),
        });
      }
    }
    tracesQueryRequest
      .call({
        queries: traceQuery.queries,
        formulas: traceQuery.formulas,
        date: traceQuery.date,
        spanFilter: traceQuery.spanFilter,
        transformer: traceQuery.transformer,
        instant: !isRange,
        dataFormat: panel.type,
        mainTransformer,
      })
      .then(() => updateReloadPanels(false));
  };

  const callRumRequest = async (panelDate: DateSelection) => {
    const { rumQuery } = getRumWithMetaForDashboard({
      date: panelDate,
      panel,
      templateValues: templateValuesWithAll,
      userActionRef,
      width: panelWidth,
    });

    if (rumQuery) {
      rumAnalyticsFetchMultiRequest
        .call(rumQuery)
        .then(() => updateReloadPanels(false));
    }
  };

  const callEventsRequest = async (panelDate: DateSelection) => {
    const { eventsQuery, mainTransformer } = getEventsQueryForDashboard({
      date: panelDate,
      panel,
      templateValues: templateValuesWithAll,
      userActionRef,
      width: panelWidth,
    });

    if (eventsQuery) {
      eventQueryRequest
        .call({ ...eventsQuery, mainTransformer })
        .then(() => updateReloadPanels(false));
    }
  };

  const loadData = () => {
    const panelDate = getReplacedTimeFromWithDate({
      date,
      panel,
      templateValues: templateValuesWithAll,
    });
    if (isLoki) {
      callLoki(panelDate);
      return;
    }

    if (isPrometheus) {
      if (isRange) {
        callPromqlRequest();
      } else {
        callInstantPrometheus(panelDate);
      }
    }

    if (isTempo) {
      callTraceRequest(panelDate);
    }

    if (isRum) {
      callRumRequest(panelDate);
    }

    if (isEvents) {
      callEventsRequest(panelDate);
    }
  };

  const updateReloadPanels = (status: boolean) => {
    setReloadPanels((prevReloadPanels) => ({
      ...prevReloadPanels,
      [panelKey]: status,
    }));
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (reloadPanels[panelKey] && isInView) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks, react-hooks/exhaustive-deps
  }, [isInView, panel.targets, templateValues]);

  // here they are returned
  if (isLoki) {
    if (queryLangType === 'kfuseql') {
      return getLogMetricsResultWithKfuseQlRequest;
    }
    return getLogMetricsTimeSeriesLogQLRequest;
  }

  if (isPrometheus) {
    if (isRange) {
      return promqlRequest;
    }
    return promqlQueryRequest;
  }

  if (isTempo) {
    if (getServicesRequest.isLoading) {
      return { isLoading: true, result: null };
    }
    return tracesQueryRequest;
  }

  if (isRum) {
    return rumAnalyticsFetchMultiRequest;
  }

  if (isEvents) {
    return eventQueryRequest;
  }
};

export default useDashboardDataLoader;
