import {
  AutocompleteOption,
  AutocompleteListV2,
  OnEnterArgs,
  SearchHelp,
  SearchTag,
} from 'components';
import { useMap, useSelectedFacetValuesByNameState } from 'hooks';
import React, { useMemo } from 'react';
import { DateSelection } from 'types';
import {
  getFacetKey,
  getLastParsedValue,
  parseFacetKey,
  parsePartialSearchQuery,
  rumQueryOperators,
} from 'utils';
import RumSearchInputPanelValues from './RumSearchInputPanelValues';
import rumLabelValues from '../requests/rumLabelValues';
import { RumEventType } from '../types';
import isRangeFacet, { RangeFacetsMap } from '../utils/isRangeFacet';

const mapLabelName = (labelName: string) => ({
  dataType: isRangeFacet(labelName)
    ? RangeFacetsMap[labelName as keyof typeof RangeFacetsMap]
    : 'STRING',
  label: labelName,
  optionType: 'facet',
  value: isRangeFacet(labelName)
    ? getFacetKey({
        component: '',
        name: labelName,
        type: RangeFacetsMap[labelName as keyof typeof RangeFacetsMap],
        displayName: labelName,
      })
    : labelName,
});

type Props = {
  applicationFilter: string;
  close: () => void;
  date: DateSelection;
  editIndex: number;
  eventType: RumEventType;
  labelNames: string[];
  onEnter: (args: OnEnterArgs) => void;
  onValueSelect: (value: string) => void;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
  setTyped: (typed: string) => void;
  tags: SearchTag[];
  typed: string;
};

const RumSearchInputPanel = ({
  applicationFilter,
  close,
  date,
  editIndex,
  eventType,
  labelNames,
  onValueSelect,
  setTyped,
  tags,
  typed,
}: Props) => {
  const labelValuesByNameMap = useMap();
  const isLoadingByNameMap = useMap();

  const fetchLabelValuesByName = (labelName: string) => {
    isLoadingByNameMap.add(labelName, true);
    rumLabelValues({
      applicationFilter,
      date,
      eventType,
      labelName: labelName,
    })
      .then((result) => {
        labelValuesByNameMap.add(
          parsedFacetName,
          result.map((valueCount) => valueCount.value),
        );
      })
      .finally(() => {
        isLoadingByNameMap.add(labelName, false);
      });
  };

  const { parsedFacetName, parsedOperator, parsedValue } = useMemo(() => {
    const parsed = parsePartialSearchQuery(typed, false);
    return {
      parsedFacetName: parsed.facetName,
      parsedOperator: parsed.operator,
      parsedValue: parsed.value,
    };
  }, [typed]);

  const searchedLabelNames: string[] = useMemo(() => {
    const searchLowered = typed.toLowerCase().trim();

    if (searchLowered) {
      return labelNames.filter(
        (labelName) => labelName.toLowerCase().indexOf(searchLowered) > -1,
      );
    }

    return labelNames;
  }, [labelNames, typed]);

  const searchedLabelOptions: AutocompleteOption[] = useMemo(() => {
    return searchedLabelNames.map(mapLabelName);
  }, [searchedLabelNames]);

  const lastParsedValue = useMemo(
    () => getLastParsedValue(parsedValue),
    [parsedValue],
  );

  const searchedLabelValues: string[] = useMemo(() => {
    const labelValues = labelValuesByNameMap.state[parsedFacetName] || [];
    const searchLabelValueLowered = lastParsedValue.toLowerCase().trim();

    if (searchLabelValueLowered && labelValues.length) {
      return labelValues.filter(
        (labelValue: string) =>
          labelValue.toLowerCase().indexOf(searchLabelValueLowered) > -1,
      );
    }

    return labelValues;
  }, [labelValuesByNameMap.state, parsedFacetName, lastParsedValue]);

  const searchedLabelValueOptions = useMemo(() => {
    return searchedLabelValues.map((labelValue) => {
      return {
        label: labelValue,
        value: labelValue,
      };
    });
  }, [searchedLabelValues]);

  const onClickLabelNameHandler = ({
    option,
  }: {
    option: AutocompleteOption;
  }) => {
    const { value: optionValue, dataType } = option;

    let nextFacetName = optionValue;
    if (
      dataType === 'NUMBER' ||
      dataType === 'DURATION' ||
      dataType === 'BYTES'
    ) {
      nextFacetName = parseFacetKey(optionValue).name;
    }
    const nextOperator = parsedOperator || '=';
    const nextValue = '';

    const nextTyped = `${nextFacetName}${nextOperator}"${nextValue}"`;

    setTyped(nextTyped);
  };

  const onClickLabelValueHandler = ({
    option,
  }: {
    option: AutocompleteOption;
  }) => {
    const parsedValues = parsedValue.split(' OR ');
    const nextValue = [
      ...parsedValues.slice(0, parsedValues.length - 1),
      option.value,
    ].join(' OR ');

    onValueSelect(nextValue);
    setTyped('');
    close();
  };

  const namesBitmap: Record<string, number> = useMemo(() => {
    return tags
      .filter((tag, i) => tag.name && i !== editIndex)
      .reduce((obj, tag) => ({ ...obj, [tag.name]: 1 }), {});
  }, [editIndex, tags]);

  const warning =
    parsedFacetName && namesBitmap[parsedFacetName]
      ? `You currently have another filter with '${parsedFacetName}'`
      : '';

  const isFacetRange = useMemo(() => {
    if (parsedFacetName) {
      return isRangeFacet(parsedFacetName);
    }
    return false;
  }, [parsedFacetName]);

  return (
    <div className="rum-search__input__panel flex flex-col">
      <div className="rum-search__input__panel__suggestions">
        {!parsedOperator ? (
          <AutocompleteListV2
            close={() => {}}
            disableKeyExist
            emptyPlaceholder="No suggestions"
            onClickHandler={onClickLabelNameHandler}
            onClickKeyExistHandler={() => {}}
            options={searchedLabelOptions}
            typed={typed}
          />
        ) : null}
        {!isFacetRange &&
        parsedFacetName &&
        parsedOperator &&
        (parsedOperator === '=' || parsedOperator === '!=') ? (
          <RumSearchInputPanelValues
            fetchLabelValuesByName={fetchLabelValuesByName}
            isLoadingByNameMap={isLoadingByNameMap}
            lastParsedValue={lastParsedValue}
            onClickLabelValueHandler={onClickLabelValueHandler}
            parsedFacetName={parsedFacetName}
            searchedLabelValueOptions={searchedLabelValueOptions}
          />
        ) : null}
      </div>
      <SearchHelp operators={rumQueryOperators} warning={warning} />
    </div>
  );
};

export default RumSearchInputPanel;
