import delimiter from 'kfuse-constants/delimiter';
import { DeleteRuleProps, RuleProps } from '../types';

export const groupByFolderBulkDeleteAlertsRule = (
  rules: RuleProps[],
  uids: string[],
): { [key: string]: DeleteRuleProps[] } => {
  const groupedDeleteRules: { [key: string]: DeleteRuleProps[] } = {};
  uids.forEach((uid) => {
    const rule = rules.find((rule) => rule.uid === uid);
    if (!rule) return;

    const folderKey = `${rule.groupFile}${delimiter}${rule.folderUid}`;
    if (!groupedDeleteRules[folderKey]) {
      groupedDeleteRules[folderKey] = [];
    }

    groupedDeleteRules[folderKey].push({
      folderName: rule.groupFile,
      groupName: rule.group,
      folderUid: rule.folderUid,
      uid: rule.uid,
    });
  });

  return groupedDeleteRules;
};

export const groupByGroupBulkDeleteAlertsRule = (
  deleteRules: DeleteRuleProps[],
): { [key: string]: DeleteRuleProps[] } => {
  const groupedDeleteRules: { [key: string]: DeleteRuleProps[] } = {};
  deleteRules.forEach((rule) => {
    if (!groupedDeleteRules[rule.groupName]) {
      groupedDeleteRules[rule.groupName] = [];
    }
    groupedDeleteRules[rule.groupName].push(rule);
  });
  return groupedDeleteRules;
};
