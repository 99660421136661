import React from 'react';
import classnames from 'classnames';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import RumLargestContentfulPaint from './RumMonitorPerformanceMetrics/RumLargestContentfulPaint';
import RumCumulativeLayoutShift from './RumMonitorPerformanceMetrics/RumCumulativeLayoutShift';
import RumInteractionToNextPaint from './RumMonitorPerformanceMetrics/RumInteractionToNextPaint';
import RumPerformanceOverviewTopPages from './RumCoreWebVitals/RumPerformanceOverviewTopPages';
type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
};

const RumPerformanceGrid = ({ rumPageState }: Props) => {
  const { rumState } = rumPageState;
  return (
    <div className={classnames('rum')}>
      <h1 className="rum__performance-subtitle">Monitor Performance Metrics</h1>
      <div className="rum__performance-grid">
        <RumLargestContentfulPaint rumPageState={rumPageState} />
        <RumCumulativeLayoutShift rumPageState={rumPageState} />
        <RumInteractionToNextPaint rumPageState={rumPageState} />
      </div>
      <h2 className="rum__subtitle">Performance Overview of Top Pages</h2>
      <div>
        <RumPerformanceOverviewTopPages rumState={rumState} />
      </div>
    </div>
  );
};

export default RumPerformanceGrid;
