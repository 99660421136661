import {
  Badge,
  Paginator,
  Table,
  TableSearch,
  usePaginator,
  useTableSearch,
  useTableSort,
} from 'components';
import dayjs from 'dayjs';
import { dateTimeFormat } from 'kfuse-constants';
import React, { ReactElement } from 'react';
import { getColorRandom } from 'utils';

import { useAlertsSilenceEditState } from './hooks';
import { SilencedInstanceProps } from './types';

const columnsInstances = () => [
  {
    key: 'status',
    label: 'State',
    renderCell: ({ row }: { row: SilencedInstanceProps }) => {
      return (
        <Badge className="min-w-[80px] justify-center rounded-sm px-2 text-xs font-medium uppercase">
          {row.status.state}
        </Badge>
      );
    },
  },
  {
    key: 'labels',
    label: 'Labels',
    renderCell: ({ row }: { row: SilencedInstanceProps }) => {
      const labelKeys = Object.keys(row.labels || {}).filter(
        (label) => label !== '__alert_rule_uid__',
      );
      return (
        <div className="flex max-w-[600px] flex-wrap">
          {labelKeys.map((label) => {
            return (
              <Badge
                key={label}
                className="py-0.25 mb-1 mr-1 truncate rounded-sm px-1 text-xs font-medium text-white"
                variant="outline"
                style={{ backgroundColor: getColorRandom() }}
              >
                {label}: {row.labels[label]}
              </Badge>
            );
          })}
        </div>
      );
    },
  },
  {
    key: 'createdAt',
    label: 'Created',
    renderCell: ({ row }: { row: SilencedInstanceProps }) => {
      return <span>{dayjs(row.startsAt).format(dateTimeFormat)}</span>;
    },
  },
];

const emptyArray = [];
const AlertsSilencesEditInstances = ({
  alertsSilenceEditState,
}: {
  alertsSilenceEditState: ReturnType<typeof useAlertsSilenceEditState>;
}): ReactElement => {
  const { getFilteredSilencedInstancesRequest, silenceByUidRequest } =
    alertsSilenceEditState;

  const activeSilences =
    getFilteredSilencedInstancesRequest.result || emptyArray;
  const tableSearch = useTableSearch({ rows: activeSilences });
  const tableSort = useTableSort({
    columns: columnsInstances(),
    rows: tableSearch.searchedRows,
  });
  const paginator = usePaginator({
    rows: tableSort.sortedRows,
    initialNumberOfRowsPerPage: 25,
  });

  return (
    <div className="pt-4">
      <div className="flex items-center justify-between pb-2">
        <h2 className="text-lg font-semibold">Affected Instances</h2>
      </div>
      {getFilteredSilencedInstancesRequest.isLoading ||
      silenceByUidRequest.isLoading ? (
        <div className="admin__row-muted flex min-h-[150px] items-center justify-center">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <TableSearch
            className="dashboard__list__search"
            placeholder="Search affected instances..."
            tableSearch={tableSearch}
            dataTestId="dashboard-list-search"
          />
          <Table
            className="table--bordered table--bordered-cells table__actions--hidden alerts__contacts__table"
            columns={columnsInstances()}
            externalTableSort={tableSort}
            isSortingEnabled
            rows={paginator.paginatedRows}
            dataTestId="active-silences-list"
          />
          <div className="table-footer">
            <Paginator paginator={paginator} />
          </div>
        </>
      )}
    </div>
  );
};

export default AlertsSilencesEditInstances;
