import {
  BadgeGroup,
  Loader,
  Paginator,
  Table,
  TableBulkActions,
  TableBulkActionsCheckbox,
  TableSearch,
  TooltipTrigger,
  usePaginator,
  usePopoverContext,
  useTableBulkActions,
  useTableSearch,
  useTableSort,
  useToaster,
} from 'components';
import { ConfirmationModal, useModalsContext } from 'components/Modals';
import { TimeRangePeriod } from 'composite';
import dayjs from 'dayjs';
import { useSelectedFacetValuesByNameState } from 'hooks';
import { colorsByAlertState, dateTimeFormat } from 'kfuse-constants';
import React, { ReactElement, useMemo, useRef } from 'react';
import { Clipboard } from 'react-feather';
import { IoIosWarning } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { DateSelection } from 'types';

import AlertsListActions from './AlertsListActions';
import { useAlertsDelete, useAlertsState } from './hooks';
import { DeleteRuleProps, RuleProps, RuleType } from './types';
import { filterAlertsRules, groupByFolderBulkDeleteAlertsRule } from './utils';
import { AddToastProps } from 'components/Toasts/context';

const alertsColumns = ({
  onDeleteAlertsRule,
  onMuteClick,
  tableBulkActions,
  addToast,
}: {
  onDeleteAlertsRule: (val: DeleteRuleProps) => void;
  onMuteClick: (rule: RuleProps, muteRef: any) => void;
  tableBulkActions: ReturnType<typeof useTableBulkActions>;
  addToast: (props: AddToastProps) => void;
}) => [
  {
    key: 'bulk-action-checkbox',
    label: (
      <TableBulkActionsCheckbox
        tableBulkActions={tableBulkActions}
        isSelectAll
      />
    ),
    renderCell: ({ row }: { row: RuleProps }) => (
      <TableBulkActionsCheckbox
        tableBulkActions={tableBulkActions}
        uniqueId={row.uid as string}
      />
    ),
  },
  {
    key: 'status',
    label: 'Status',
    renderCell: ({ row }: { row: RuleProps }) => {
      return (
        <div
          className="chip alerts__list__status-chip"
          style={{ backgroundColor: colorsByAlertState[row.status] }}
        >
          {row.status}
        </div>
      );
    },
  },
  {
    key: 'name',
    label: 'Name',
    renderCell: ({ row }: { row: RuleProps }) => {
      return (
        <span className="alerts__list__name min-w-[15vw]">
          {row.contactPointLabels.length === 0 && (
            <TooltipTrigger tooltip="Contact points not setup">
              <IoIosWarning />
            </TooltipTrigger>
          )}
          <TooltipTrigger className="max-w-[25vw] truncate" tooltip={row.name}>
            <span>{row.name}</span>
          </TooltipTrigger>
        </span>
      );
    },
  },
  {
    key: 'annotations.ruleType',
    label: 'Type',
    renderCell: ({ row }: { row: RuleProps }) =>
      row.annotations?.ruleType?.toUpperCase(),
  },
  {
    key: 'alerts-tags',
    label: 'Tags',
    renderCell: ({ row }: { row: RuleProps }) => {
      const handleCopyToClipboard = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        badge: string,
      ) => {
        e.stopPropagation();
        navigator.clipboard.writeText(badge);
        addToast({ status: 'success', text: 'Copied to clipboard' });
      };

      return (
        <div
          className="alerts__list__tags"
          onClick={(e) => e.stopPropagation()}
        >
          <BadgeGroup
            badgeList={row.tags}
            contractList={true}
            renderPopoverOnBadgeClick={(badge, close) => (
              <button
                className="logs__selected-log__attribute__panel__item"
                onClick={(e) => {
                  handleCopyToClipboard(e, badge);
                  close();
                }}
              >
                <Clipboard
                  className="logs__selected-log__attribute__panel__item__icon"
                  size={14}
                />
                <span className="logs__selected-log__attribute__panel__item__label">
                  {'Copy '}
                </span>
                <span className="logs__selected-log__attribute__panel__item__value">
                  {badge}
                </span>
              </button>
            )}
          />
        </div>
      );
    },
  },
  {
    key: 'updated',
    label: 'Updated',
    renderCell: ({ row }: { row: RuleProps }) => {
      const updatedDate = dayjs(row.updated);
      return (
        <TooltipTrigger
          className="min-w-[7vw] max-w-[9vw]"
          tooltip={updatedDate.format(dateTimeFormat)}
        >
          {updatedDate.fromNow()}
        </TooltipTrigger>
      );
    },
  },
  {
    label: '',
    key: 'actions',
    renderCell: ({ row }: { row: RuleProps }) => {
      return (
        <AlertsListActions
          onDeleteAlertsRule={onDeleteAlertsRule}
          onMuteClick={onMuteClick}
          rule={row}
        />
      );
    },
  },
];

type Props = {
  alertsState: ReturnType<typeof useAlertsState>;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
};

const AlertsList = ({
  alertsState,
  selectedFacetValuesByNameState,
}: Props): ReactElement => {
  const navigate = useNavigate();
  const modal = useModalsContext();
  const popover = usePopoverContext();
  const { bulkDeleteAlertsRule, deleteAlertsRule } = useAlertsDelete();
  const { addToast } = useToaster();

  const {
    isLoading,
    muteAlert,
    rules,
    reloadAlerts,
    setAlertsProperties,
    setIsLoading,
    unMuteAlert,
  } = alertsState;

  const filteredAlertList = useMemo(() => {
    if (!rules) return [];
    const { formattedRules, status, contactpoints, mute, tags, type, folder } =
      filterAlertsRules(rules, selectedFacetValuesByNameState.state);

    setAlertsProperties({
      status,
      contactpoints,
      folder,
      mute,
      tags,
      type,
    });
    return formattedRules;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacetValuesByNameState.state, rules]);
  const tableSearch = useTableSearch({ rows: filteredAlertList });
  const tableBulkActions = useTableBulkActions({
    rows: tableSearch.searchedRows,
    uniqueIdKey: 'uid',
  });

  const onDeleteAlertsRule = (val: DeleteRuleProps): void => {
    modal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description="Are you sure you want to delete this alert?"
        onCancel={() => modal.pop()}
        onConfirm={() => {
          setIsLoading(true);
          modal.pop();
          deleteAlertsRule(val)
            .then(() => {
              reloadAlerts();
              setIsLoading(false);
            })
            .catch(() => {
              modal.pop();
              setIsLoading(false);
            });
        }}
        title="Delete Alert"
      />,
    );
  };

  const onMuteClick = (rule: RuleProps, muteRef: any): void => {
    if (rule.mute === 'Muted') {
      unMuteAlert(rule.muteId).then(() => {
        reloadAlerts();
      });
      return;
    }

    popover.open({
      component: TimeRangePeriod,
      element: muteRef.current,
      popoverPanelClassName: 'alerts__list__mute-popover',
      props: {
        close: () => popover.close(),
        onChange: (val: DateSelection) => {
          muteAlert(rule, val).then(() => {
            reloadAlerts();
          });
        },
        periodType: 'Next',
      },
      right: true,
      width: 160,
    });
  };

  const bulkDeleteAlerts = (uids: string[]): void => {
    modal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description={`Are you sure you want to delete ${uids.length} alerts? Once deleted, they cannot be recovered.`}
        onCancel={() => modal.pop()}
        onConfirm={() => {
          setIsLoading(true);
          modal.pop();
          const groupedDeleteRules = groupByFolderBulkDeleteAlertsRule(
            rules,
            uids,
          );
          setIsLoading(true);
          bulkDeleteAlertsRule(groupedDeleteRules)
            .then(() => {
              setIsLoading(false);
              tableBulkActions.clearSelectedRows();
              reloadAlerts();
            })
            .catch(() => {
              setIsLoading(false);
              modal.pop();
            });
        }}
        title="Delete bulk alerts"
      />,
    );
  };

  const columns = alertsColumns({
    onDeleteAlertsRule,
    onMuteClick,
    tableBulkActions,
    addToast,
  });
  const tableSort = useTableSort({
    columns,
    rows: tableSearch.searchedRows,
  });

  const paginator = usePaginator({
    rows: tableSort.sortedRows,
    initialNumberOfRowsPerPage: 25,
  });

  const handleRowClick = ({ row }: { row: RuleProps }): void => {
    if (
      !Object.values(RuleType).includes(row.annotations?.ruleType as RuleType)
    ) {
      addToast({ text: 'Alert type not supported', status: 'error' });
      return;
    }

    if (row.ruleData.length > 2) {
      navigate(`/alerts/details/${row.uid}`, { state: row });
    } else {
      navigate(`/alerts/details/slo/${row.uid}`, { state: row });
    }
  };

  return (
    <Loader className="alerts__list" isLoading={isLoading}>
      <TableSearch
        className="dashboard__list__search"
        placeholder="Search Alerts..."
        tableSearch={tableSearch}
        dataTestId="dashboard-list-search"
      />
      <TableBulkActions
        tableBulkActions={tableBulkActions}
        onBulkDelete={bulkDeleteAlerts}
        selectedLabel={`${tableBulkActions.selectedRowsCount > 1 ? 'alerts' : 'alert'} selected`}
      />
      <Table
        className="table--bordered table--bordered-cells table__actions--hidden alerts__list__table"
        columns={columns}
        dataTestId="alerts-list-table"
        onRowClick={handleRowClick}
        externalTableSort={tableSort}
        isSortingEnabled
        rows={paginator.paginatedRows}
      />
      <div className="table-footer">
        <Paginator paginator={paginator} />
      </div>
    </Loader>
  );
};

export default AlertsList;
