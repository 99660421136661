import dayjs from 'dayjs';
import {
  DashboardPanelType,
  DateSelection,
  FacetRegexTerm,
  HeatmapDataProps,
  KeyExists,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  SpanFilter,
} from 'types';
import { DataFrame, DataTransformerConfig } from 'utils/DataTransformer';
import onPromiseError from 'utils/onPromiseError';

import queryTraceService from './queryTraceService';
import { buildTracesFilter } from './utils';

type Args = {
  attribute: string;
  date: DateSelection;
  numTimeBuckets: number;
  numAttributeBuckets: number;
  customerFilter?: { key: string; value: string };
  spanFilter?: SpanFilter;
  ParentSpanIdFilter?: string;
  traceIdSearch: string;
  transformer?: DataTransformerConfig[];
  filter?: {
    facetRegex: FacetRegexTerm[];
    keyExists: Record<string, any>;
    selectedFacetRangeByName: any;
    selectedFacetValuesByName: any;
  };
};

const getHeatmapData = async ({
  attribute,
  date,
  numTimeBuckets,
  numAttributeBuckets,
  customerFilter,
  spanFilter,
  ParentSpanIdFilter, // (temporarily disabled)
  traceIdSearch,
  transformer,
  filter,
}: Args): Promise<HeatmapDataProps> => {
  const { endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const timestamp = endTime.format();
  const durationSec = endTimeUnix - date.startTimeUnix;

  const tracesFilter = buildTracesFilter({
    customerFilter,
    parentSpanIdFilter: null, // ParentSpanIdFilter, // (temporarily disabled)
    spanFilter,
    traceIdSearch,
    ...filter,
  });

  const adjustedDurationSecs =
    Math.ceil(durationSec / numTimeBuckets) * numTimeBuckets;
  const bucketSize = adjustedDurationSecs / numTimeBuckets;
  return queryTraceService<HeatmapDataProps, 'heatmap'>(`
    {
        heatmap (
            attribute: "${attribute}",
            timestamp: "${timestamp}",
            durationSecs: ${adjustedDurationSecs},
            filter: ${tracesFilter}
            numTimeBuckets: ${numTimeBuckets},
            numAttributeBuckets: ${numAttributeBuckets},
        ) {
           buckets {
              count
              timeBucketStartSecs
              attrBucketStart
           }
           minAttrValue
           maxAttrValue
           unit
        }
    }
    `).then((data) => {
    const executedDate = {
      ...date,
      startTimeUnix: date.startTimeUnix - bucketSize,
    };

    const meta: DataFrame['meta'] = {
      executedDate,
      step: bucketSize,
      type: DashboardPanelType.HEATMAP,
      refId: 'a',
    };

    const buckets = data.heatmap.buckets.map((item) => ({
      ...item,
      timeBucketStartSecs: item.timeBucketStartSecs - bucketSize,
    }));

    const heatmap = { ...data.heatmap, buckets };
    const initialData = { datasets: heatmap, meta };
    const transformed = transformer.reduce(
      (prevData, item) => item.func(prevData),
      initialData,
    );
    return transformed;
  }, onPromiseError);
};

export default getHeatmapData;
