import React from 'react';
import { Frame } from 'types';
import StackTraceFrame from './StackTraceFrame';

type Props = {
  frames: Frame[];
};

const StackTrace = ({ frames }: Props) => {
  return (
    <div className="stack-trace">
      {frames.map((frame, i) => (
        <StackTraceFrame
          frame={frame}
          initialIsOpen={i === 0 || frame.library_frame}
          key={i}
        />
      ))}
    </div>
  );
};

export default StackTrace;
