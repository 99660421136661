import classNames from 'classnames';
import { Stepper } from 'components';
import {
  useRequest,
  useSearches,
  useSearchFormulas,
  useTracesState,
} from 'hooks';
import React, { ReactElement, useCallback, useMemo } from 'react';
import {
  aggregateTimeSeriesMultipleV2,
  getServices,
  traceLabelNamesBase,
} from 'requests';
import {
  DashboardPanelProps,
  DashboardPanelType,
  DashboardTemplateValueProps,
  SelectedFacetValuesByName,
  QueryDataProps,
  DashboardStreamType,
} from 'types';
import useDebouncedEffect from 'use-debounced-effect';
import {
  combineInstantQueryData,
  combineRangeQueryData,
  DataFrame,
  replaceServiceHashWithLabel,
  traceDataFrameToQueryData,
  traceDataTransformer,
} from 'utils/DataTransformer';
import { TracesQuerySearchAnalytics } from 'screens/Traces';

import {
  DashboardPanelModalFooter,
  DashboardPanelModalHeader,
} from '../components';
import { useDashboardEditState } from './hooks';
import DashboardEditPanelName from './DashboardEditPanelName';
import DashboardEditMetricsVisualType from './DashboardEditMetricsVisualType';
import DashboardEditStreamType from './DashboardEditStreamType';
import {
  DashboardEditGraphSunburst,
  DashboardEditGraphTable,
  DashboardEditGraphTimeseries,
  DashboardEditGraphToplist,
} from './DashboardEditGraph';
import DashboardEditTracesHeatmap from './DashboardEditTracesHeatmap';
import {
  ALLOW_ONLY_ONE_ACTIVE_SEARCH,
  defaultTransformations,
  traceExtendSupportedEditGraphList,
  getTracesPanelExprToQueryAndFormula,
} from './utils';

const DashboardEditTraces = ({
  baseWidth,
  close,
  dashboardEditState,
  panel,
  panelType,
}: {
  baseWidth: number;
  close: () => void;
  dashboardEditState: ReturnType<typeof useDashboardEditState>;
  panel: DashboardPanelProps;
  panelType?: DashboardPanelType;
  templateValues?: DashboardTemplateValueProps;
}): ReactElement => {
  const { date, editPanel, onSaveClickTraces, setDate } = dashboardEditState;
  const { queries: parsedQueries, formulas: parsedFormulas } = useMemo(
    () => getTracesPanelExprToQueryAndFormula(panel),
    [panel],
  );

  const { searches } = useSearches({
    shouldWriteToUrl: false,
    initialSearches: parsedQueries,
  });
  const queries = searches.map((search) => search.state);
  const searchesFormulas = useSearchFormulas(queries, parsedFormulas);
  const { formulas } = searchesFormulas;
  const tracesPageState = useTracesState({ shouldWriteToUrl: false });

  const traceLabelNamesRequest = useRequest(traceLabelNamesBase, true, true);
  const getServicesRequest = useRequest(getServices, true, true);
  const aggregateTimeSeriesMultipleV2Request = useRequest(
    (args) =>
      aggregateTimeSeriesMultipleV2(args).then(traceDataFrameToQueryData),
    true,
    true,
  );

  const { formulasStatus, queriesStatus } = useMemo(() => {
    const queriesStatus = queries.map((query) => {
      return { isActive: query.isActive, queryKey: query.queryKey };
    });

    const formulasStatus = formulas.map((formula) => {
      return { isActive: formula.isActive, queryKey: formula.queryKey };
    });

    return { formulasStatus, queriesStatus };
  }, [queries, formulas]);

  const loadTraceTimeseries = () => {
    if (panelType === DashboardPanelType.HEATMAP) return;
    const instant = panelType !== DashboardPanelType.TIMESERIES;
    const baseTransformers = traceDataTransformer(instant);
    baseTransformers.splice(1, 0, {
      id: 'replaceServiceHashWithLabel',
      func: (dataFrame: DataFrame) =>
        replaceServiceHashWithLabel(dataFrame, serviceByHash),
    });

    const preparedQueries = searches
      .map((s) => (s.searchBarState ? s.state : undefined))
      .filter(Boolean);

    aggregateTimeSeriesMultipleV2Request.call({
      customerFilter: {},
      date,
      dataFormat: panelType,
      formulas: formulas.map((f) => f.state),
      instant,
      queries: preparedQueries,
      spanFilter: '',
      transformer: baseTransformers,
    });
  };

  const serviceByHash = useMemo(
    () =>
      (getServicesRequest.result || []).reduce(
        (obj, service) => ({ ...obj, [service.hash]: service }),
        {},
      ),
    [getServicesRequest.result],
  );

  useDebouncedEffect(
    () => {
      loadTraceTimeseries();
    },
    100,
    [panelType, date, searches, formulas],
  );

  useDebouncedEffect(
    () => {
      const selectedFacetValuesByName = { span_type: { db: 0 } };
      getServicesRequest.call({
        customerFilter: {},
        date,
        selectedFacetValuesByName:
          selectedFacetValuesByName as SelectedFacetValuesByName,
      });
      traceLabelNamesRequest.call({ date });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      ignoreInitialCall: false,
      timeout: 50,
    },
    [date],
  );

  const {
    result: traceQueryData,
    unit,
    refId,
  } = useMemo(() => {
    const result: QueryDataProps = {};
    let unit = '';
    let refId = '';
    if (!aggregateTimeSeriesMultipleV2Request.result) {
      queriesStatus.forEach(({ queryKey }) => {
        const queryId = `query_${queryKey}`;
        result[queryId] = {
          isLoading: aggregateTimeSeriesMultipleV2Request.isLoading,
          meta: null,
        };
      });
      return { result, unit };
    }
    const rangeKey =
      panelType === DashboardPanelType.TIMESERIES ? 'range' : 'instant';
    queriesStatus.forEach(({ queryKey }) => {
      const queryId = `query_${queryKey}`;
      const data = aggregateTimeSeriesMultipleV2Request.result[queryId];

      if (!data) return;
      result[queryId] = {
        isLoading: aggregateTimeSeriesMultipleV2Request.isLoading,
        meta: data.meta,
        [rangeKey]: rangeKey === 'range' ? data.data : data,
      };
      refId = data.meta.refId;
      unit = data.meta.unit;
    });
    formulasStatus.forEach(({ queryKey }) => {
      const formulaId = `formula_${queryKey}`;
      const data = aggregateTimeSeriesMultipleV2Request.result[formulaId];
      if (!data) return;
      result[formulaId] = {
        isLoading: aggregateTimeSeriesMultipleV2Request.isLoading,
        meta: data.meta,
        [rangeKey]: rangeKey === 'range' ? data.data : data,
      };
      refId = data.meta.refId;
      unit = data.meta.unit;
    });
    return { result, unit, refId };
  }, [
    aggregateTimeSeriesMultipleV2Request.isLoading,
    aggregateTimeSeriesMultipleV2Request.result,
    panelType,
    queriesStatus,
    formulasStatus,
  ]);

  const dataFormatterRange = useCallback(() => {
    if (editPanel.type !== DashboardPanelType.TIMESERIES) return null;
    return combineRangeQueryData({
      formulas: formulasStatus,
      queries: queriesStatus,
      queryData: traceQueryData,
      combineTimestamp: true,
      combineLabelWithQueryKey: true,
    });
  }, [editPanel.type, formulasStatus, queriesStatus, traceQueryData]);

  const dataFormatterInstant = useCallback(() => {
    if (editPanel.type === DashboardPanelType.TIMESERIES) return null;
    return combineInstantQueryData({
      dataFormat: editPanel.type,
      queries: queriesStatus,
      queryData: traceQueryData,
      formulas: formulasStatus,
      transformations: defaultTransformations({
        dataFormat: editPanel.type,
        stream: DashboardStreamType.TRACE,
        unit,
        refId,
      }),
    });
  }, [
    editPanel.type,
    formulasStatus,
    traceQueryData,
    queriesStatus,
    unit,
    refId,
  ]);

  return (
    <>
      <DashboardPanelModalHeader
        close={close}
        modalDate={date}
        setModalDate={setDate}
        title={!panel ? 'Add Panel' : editPanel.title || 'Edit Panel'}
      />
      <div
        className={classNames({
          'dashboard-edit__metric__body': true,
          'traces--heatmap': editPanel.type === DashboardPanelType.HEATMAP,
        })}
      >
        {editPanel.type === DashboardPanelType.TIMESERIES && (
          <DashboardEditGraphTimeseries
            baseWidth={baseWidth}
            onDateChange={setDate}
            panel={editPanel}
            dataFormatter={dataFormatterRange}
          />
        )}
        {editPanel.type === DashboardPanelType.TOP_LIST && (
          <DashboardEditGraphToplist
            baseWidth={baseWidth}
            dataFormatter={dataFormatterInstant}
          />
        )}
        {editPanel.type === DashboardPanelType.TABLE && (
          <DashboardEditGraphTable dataFormatter={dataFormatterInstant} />
        )}
        {editPanel.type === DashboardPanelType.PIECHART && (
          <DashboardEditGraphSunburst
            baseWidth={baseWidth}
            dataFormatter={dataFormatterInstant}
            panel={editPanel}
          />
        )}
        {editPanel.type === DashboardPanelType.HEATMAP && (
          <DashboardEditTracesHeatmap
            baseWidth={baseWidth}
            dashboardEditState={dashboardEditState}
            panel={editPanel}
            searches={searches}
          />
        )}
        <Stepper
          steps={[
            {
              title: 'Choose Visualization',
              component: (
                <DashboardEditMetricsVisualType
                  dashboardEditState={dashboardEditState}
                  hideVisualizationTypes={[
                    DashboardPanelType.GRAFANA_POLYSTAT_PANEL,
                  ]}
                  extendVisualizationTypes={traceExtendSupportedEditGraphList}
                />
              ),
            },
            {
              title: 'Graph your data',
              component: (
                <>
                  {!panel && (
                    <div className="dashboard__widget__metrics-query-builder">
                      <DashboardEditStreamType
                        setStream={dashboardEditState.setStream}
                        stream={dashboardEditState.stream}
                      />
                    </div>
                  )}
                  <TracesQuerySearchAnalytics
                    allowOnlyOneActiveSearchTypes={
                      panelType === DashboardPanelType.HEATMAP
                        ? ALLOW_ONLY_ONE_ACTIVE_SEARCH
                        : undefined
                    }
                    colorsByServiceName={{}}
                    date={date}
                    labelNames={traceLabelNamesRequest.result || []}
                    searches={searches}
                    searchesFormulas={searchesFormulas}
                    serviceByHash={serviceByHash}
                    traceLabelNamesRequest={traceLabelNamesRequest}
                    tracesState={tracesPageState}
                    tracesTab={panelType}
                    shouldSyncWithPageState={false}
                    hideQueryButtons={panelType === DashboardPanelType.HEATMAP}
                  />
                </>
              ),
            },
            {
              title: 'Enter chart title',
              component: (
                <DashboardEditPanelName
                  dashboardEditState={dashboardEditState}
                />
              ),
            },
          ]}
        />
      </div>
      <DashboardPanelModalFooter
        close={close}
        onSave={() => onSaveClickTraces({ formulas, queries })}
      />
    </>
  );
};

export default DashboardEditTraces;
