import useEventsState from './hooks/useEventsState';
import { AutocompleteOption } from 'components';

export type EventPageProps = {
  eventsState?: ReturnType<typeof useEventsState>;
  tab?: EventsTab;
};

type OptionType = Pick<AutocompleteOption, 'optionType'>;
type SearchItemProps = {
  facet: string;
  value: string;
  operator: string;
};

export type EventSearchItemProps = SearchItemProps & OptionType;

export type EventsAnalyticsQueryProps = {
  countBy: string;
  groupBy: string[];
  rollUp: string;
  limitType: 'Top' | 'Bottom';
  limit: number;
};

export enum EventsTab {
  list = 'list',
  timeseries = 'timeseries',
  table = 'table',
  toplist = 'toplist',
  piechart = 'piechart',
}
