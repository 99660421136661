import classNames from 'classnames';
import { Button, InputWithValidatorV3, TooltipTrigger } from 'components';
import { useSearches, useSearchFormulas } from 'hooks';
import React, { ReactElement } from 'react';
import { LuXCircle as XCircle } from 'react-icons/lu';
import { DashboardPanelType } from 'types/Dashboard';
import { TracesTab } from 'types/TracesTab';
import { ALLOW_ONLY_ONE_ACTIVE_SEARCH } from '../utils';

const TracesSearchGrouperFormula = ({
  formulaState,
  searches,
  tracesTab,
  allowOnlyOneActiveSearchTypes = ALLOW_ONLY_ONE_ACTIVE_SEARCH,
}: {
  formulaState: ReturnType<typeof useSearchFormulas>['formulas'][0];
  searches: ReturnType<typeof useSearches>;
  tracesTab: TracesTab;
  allowOnlyOneActiveSearchTypes?: DashboardPanelType[];
}): ReactElement => {
  const formula = formulaState.state;

  const handleActiveClick = () => {
    if (
      allowOnlyOneActiveSearchTypes.includes(
        tracesTab as unknown as DashboardPanelType,
      )
    ) {
      if (formula.isActive) {
        formulaState.updateFormulaIsActiveByIndex(!formula.isActive);
      } else {
        formulaState.activateOnlySingleFormula();
        const countOfSearches = searches?.filter(
          (s) => s.state.isActive,
        )?.length;
        if (countOfSearches > 0) {
          searches[0]?.deactivateAll();
        }
      }
    } else {
      formulaState.updateFormulaIsActiveByIndex(!formula.isActive);
    }
  };

  return (
    <div className="traces-search__grouper">
      <div className="traces-search__button-group">
        <div className="button-group">
          <TooltipTrigger
            tooltip={
              tracesTab === TracesTab.heatmap
                ? "Formulas can't be active in heatmap"
                : 'Show formula'
            }
          >
            <div
              className={classNames({
                'button-group__item  button-group__item--label': true,
                'traces-search__button-group__active':
                  formula.isActive && tracesTab !== TracesTab.heatmap,
                'traces-search__button-group__inactive':
                  !formula.isActive && tracesTab === TracesTab.heatmap,
              })}
              onClick={handleActiveClick}
            >
              {formula.queryKey}
            </div>
          </TooltipTrigger>

          <div className="button-group__item button-group__item__input--value">
            <InputWithValidatorV3
              className="metrics__query-builder__formula__item__input"
              error={typeof formula.isValid === 'string' ? formula.isValid : ''}
              placeholder="Add formula example - 2*a"
              onChange={formulaState.updateFormulaExprByIndex}
              value={formula.expression}
              type="text"
              size="5"
              variant="default"
            />
          </div>
          <div className="trace__analytics__query-action">
            <TooltipTrigger tooltip="Remove Formula">
              <Button
                className="trace__analytics__query-action--delete h-full px-1.5"
                variant="icon"
                size="sm"
              >
                <XCircle
                  onClick={formulaState.removeExistingSearch}
                  size={16}
                />
              </Button>
            </TooltipTrigger>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TracesSearchGrouperFormula;
