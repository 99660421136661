import { Loader } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect } from 'react';
import { aggregateTimeSeriesMultipleV2, getServices } from 'requests';
import {
  DashboardPanelComponentProps,
  DateSelection,
  QueryDataPropsRange,
  SelectedFacetValuesByName,
} from 'types';
import {
  traceDataTransformer,
  DataFrameMeta,
  DataFrame,
  replaceServiceHashWithLabel,
} from 'utils';

import { Timeseries } from '../Widgets/Graph';
import { areQueriesGroupedByService } from '../utils';

const DashboardPanelTracesAnalytics = ({
  dashboardState,
  isInView,
  panel,
  panelSize,
}: DashboardPanelComponentProps): ReactElement => {
  const getServicesRequest = useRequest(getServices);
  const tracesQueryRequest = useRequest((args) =>
    aggregateTimeSeriesMultipleV2(args).then(
      (res: { data: QueryDataPropsRange; meta: DataFrameMeta }[]) => {
        if (!res[0]) return {};
        if (res.length === 1) return res[0].data;
        const formulaRes = res.find(
          (item) => item.meta.queryType === 'formula',
        );
        if (formulaRes) return formulaRes.data;
        return res[0].data;
      },
    ),
  );
  const { date } = dashboardState;

  const loadPanelData = async ({
    date,
    isInView,
    parsedExpr,
  }: {
    date: DateSelection;
    isInView: boolean;
    parsedExpr: any;
  }) => {
    if (isInView) {
      const formula = parsedExpr.formula;
      const baseTransformers = traceDataTransformer();

      const isGroupedByService = areQueriesGroupedByService({
        queries:
          parsedExpr.queries || (parsedExpr.query ? [parsedExpr.query] : []),
      });

      if (isGroupedByService) {
        const selectedFacetValuesByName = {
          span_type: { db: 0 },
        };
        const servicesResponse = await getServicesRequest.call({
          date,
          selectedFacetValuesByName:
            selectedFacetValuesByName as SelectedFacetValuesByName,
        });

        if (servicesResponse && servicesResponse.length) {
          const serviceByHash = servicesResponse.reduce(
            (obj, service) => ({ ...obj, [service.hash]: service }),
            {},
          );

          baseTransformers.splice(1, 0, {
            id: 'replaceServiceHashWithLabel',
            func: (dataFrame: DataFrame) =>
              replaceServiceHashWithLabel(dataFrame, serviceByHash),
          });
        }
      }

      if (formula) {
        tracesQueryRequest.call({
          ...(parsedExpr.customerFilter
            ? { customerFilter: parsedExpr.customerFilter }
            : {}),
          date,
          queries: parsedExpr.queries || [],
          formulas: [parsedExpr.formula],
          resultFormat: 'timeseries',
          transformer: baseTransformers,
          spanFilter: parsedExpr.spanFilter,
        });
        return;
      }

      if (!parsedExpr.query) return;
      tracesQueryRequest.call({
        ...(parsedExpr.customerFilter
          ? { customerFilter: parsedExpr.customerFilter }
          : {}),
        date,
        formulas: [],
        queries: [parsedExpr.query],
        resultFormat: 'timeseries',
        transformer: baseTransformers,
        spanFilter: parsedExpr.spanFilter,
        ...(parsedExpr.filter || {}),
      });
    }
  };

  if (panel.targets.length) {
    const { expr } = panel.targets[0];
    const parsedExpr = JSON.parse(expr);
    if (!parsedExpr) {
      return null;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      loadPanelData({ date, isInView, parsedExpr });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, isInView]);

    return (
      <Loader
        className="dashboard-panel__traces"
        isLoading={getServicesRequest.isLoading || tracesQueryRequest.isLoading}
      >
        {tracesQueryRequest.result && (
          <Timeseries
            dashboardState={dashboardState}
            dashboardDataLoader={tracesQueryRequest}
            panel={panel}
            panelSize={panelSize}
          />
        )}
      </Loader>
    );
  }

  return null;
};

export default DashboardPanelTracesAnalytics;
