import { EntityTypes } from '../types';

const allUniqueFacets = [
  { key: 'pod_status', label: 'Status', clicked: false },
  { key: 'pod_phase', label: 'Phase', clicked: false },
  { key: 'kube_cluster_name', label: 'Cluster Name', clicked: false },
  { key: 'kube_namespace', label: 'Namespace', clicked: false },
  { key: 'kube_service', label: 'Service', clicked: false },
  { key: 'kube_deployment', label: 'Deployment', clicked: false },
  { key: 'kube_replica_set', label: 'Replica Set', clicked: false },
  { key: 'pod_name', label: 'Pod', clicked: false },
  { key: 'kube_node', label: 'Node', clicked: false },
  { key: 'node_schedulable', label: 'Schedulable', clicked: false },
  { key: 'node_status', label: 'Status', clicked: false },
  { key: 'kube_cron_job', label: 'Cron Job', clicked: false },
  { key: 'kube_job', label: 'Job', clicked: false },
  { key: 'kube_daemon_set', label: 'Daemon Set', clicked: false },
  { key: 'kube_prometheus', label: 'Prometheus', clicked: false },
  { key: 'kube_stateful_set', label: 'StatefulSet', clicked: false },
  { key: 'kube_service_type', label: 'Service Type', clicked: false },
  { key: 'kube_ingress', label: 'Ingresses', clicked: false },
  { key: 'kube_persistent_volume', label: 'Persistent Volume', clicked: false },
  {
    key: 'kube_storage_class_name',
    label: 'Storage Class Name',
    clicked: false,
  },
  {
    key: 'persistentvolumeclaim',
    label: 'Persistent Volume Claim',
    clicked: false,
  },
  { key: 'pvc_phase', label: 'Phase', clicked: false },
  { key: 'kube_reclaim_policy', label: 'Reclaim Policy', clicked: false },
  { key: 'pv_phase', label: 'Phase', clicked: false },
  { key: 'pv_type', label: 'Type', clicked: false },
  { key: 'kube_role', label: 'Role' },
  { key: 'kube_role_binding', label: 'Role Binding' },
  { key: 'kube_cluster_role', label: 'Cluster Role' },
  { key: 'kube_cluster_role_binding', label: 'Cluster Role Binding' },
  { key: 'kube_service_account', label: 'Service Account' },
];

const facetKeysByEntity = {
  [EntityTypes.Pod]: [
    'pod_status',
    'pod_phase',
    'kube_cluster_name',
    'kube_namespace',
    'kube_service',
    'kube_deployment',
    'kube_replica_set',
    'pod_name',
    'kube_node',
  ],
  [EntityTypes.Cluster]: ['kube_cluster_name'],
  [EntityTypes.Namespace]: ['kube_cluster_name', 'kube_namespace'],
  [EntityTypes.Node]: [
    'kube_cluster_name',
    'kube_node',
    'node_schedulable',
    'node_status',
  ],
  [EntityTypes.Deployment]: [
    'kube_cluster_name',
    'kube_deployment',
    'kube_namespace',
  ],
  [EntityTypes.ReplicaSet]: [
    'kube_cluster_name',
    'kube_deployment',
    'kube_replica_set',
    'kube_namespace',
  ],
  [EntityTypes.Job]: ['kube_cluster_name', 'kube_cron_job', 'kube_job'],
  [EntityTypes.CronJob]: ['kube_cluster_name', 'kube_cron_job'],
  [EntityTypes.DaemonSet]: [
    'kube_cluster_name',
    'kube_daemon_set',
    'kube_namespace',
  ],
  [EntityTypes.StatefulSet]: [
    'kube_cluster_name',
    'kube_namespace',
    'kube_prometheus',
    'kube_stateful_set',
  ],
  [EntityTypes.Service]: [
    'kube_cluster_name',
    'kube_namespace',
    'kube_prometheus',
    'kube_service',
    'kube_service_type',
  ],
  [EntityTypes.Ingress]: [
    'kube_cluster_name',
    'kube_ingress',
    'kube_namespace',
    'kube_service',
  ],
  [EntityTypes.PersistentVolumeClaim]: [
    'kube_cluster_name',
    'kube_namespace',
    'kube_persistent_volume',
    'kube_storage_class_name',
    'persistentvolumeclaim',
    'pvc_phase',
  ],
  [EntityTypes.PersistentVolume]: [
    'kube_cluster_name',
    'kube_namespace',
    'kube_persistent_volume',
    'kube_reclaim_policy',
    'kube_storage_class_name',
    'persistentvolumeclaim',
    'pv_phase',
    'pv_type',
  ],
  [EntityTypes.Role]: ['kube_cluster_name', 'kube_namespace', 'kube_role'],
  [EntityTypes.RoleBinding]: [
    'kube_cluster_name',
    'kube_cluster_role',
    'kube_namespace',
    'kube_role',
    'kube_role_binding',
  ],
  [EntityTypes.ClusterRole]: ['kube_cluster_name', 'kube_cluster_role'],
  [EntityTypes.ClusterRoleBinding]: [
    'kube_cluster_name',
    'kube_cluster_role',
    'kube_cluster_role_binding',
  ],

  [EntityTypes.ServiceAccount]: [
    'kube_cluster_name',
    'kube_namespace',
    'kube_service_account',
  ],
};

export const getKubenetesEntityFacets = (entityType: EntityTypes) => {
  const facetKeys = facetKeysByEntity[entityType];
  return allUniqueFacets.filter((facet) => facetKeys.includes(facet.key));
};
