import { Button } from 'components';
import React, { ReactElement } from 'react';
import { RuleProps } from 'screens/NewAlerts/types';

const ServiceAlertsStatusList = ({
  isASM,
  onRuleClick,
  onViewAllAlerts,
  rules,
}: {
  isASM: boolean;
  onRuleClick: (rule: RuleProps) => void;
  onViewAllAlerts?: () => void;
  rules: RuleProps[];
}): ReactElement => {
  return (
    <div>
      {rules.map((rule) => {
        let newRuleName = rule.name;
        if (isASM) {
          const rulename = rule.name.split(' - ');
          newRuleName = rulename.splice(0, 2).join(' - ');
        }

        return (
          <div
            className="flex h-full cursor-pointer p-1 pr-2 hover:bg-interaction-secondary"
            key={newRuleName}
            onClick={() => onRuleClick(rule)}
          >
            <div
              className="mr-2 w-1 rounded-full"
              style={{
                backgroundColor:
                  rule.status === 'alerting'
                    ? `var(--ui-status-danger)`
                    : `var(--ui-status-success-contrast)`,
              }}
            ></div>
            <div>
              <div className="mb-1 text-sm font-semibold">{newRuleName}</div>
            </div>
          </div>
        );
      })}
      {onViewAllAlerts && (
        <Button
          className="mt-1 w-full"
          onClick={onViewAllAlerts}
          size="sm"
          variant="outline"
        >
          View all Alerts&nbsp;
        </Button>
      )}
    </div>
  );
};

export default ServiceAlertsStatusList;
