import { cva } from 'class-variance-authority';
import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';
import { cn } from 'utils/tailwind';

interface SwitchProps {
  size?: '1' | '2' | '3' | '4';
  className?: string;
  radius?: 'none' | 'sm' | 'md' | 'lg' | 'full';
}

const switchVariantsRoot = cva(
  'peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors data-[state=checked]:bg-primary data-[state=unchecked]:bg-form-border focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      size: {
        '1': 'h-6 w-11',
        '2': 'h-5 w-9',
        '3': 'h-4 w-7',
        '4': 'h-3 w-5',
      },
      radius: {
        none: 'rounded-none',
        sm: 'rounded-sm',
        md: 'rounded-md',
        lg: 'rounded-lg',
        full: 'rounded-full',
      },
    },
  },
);

const switchVariantsThumb = cva(
  'pointer-events-none block size-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0',
  {
    variants: {
      size: {
        '1': 'size-5',
        '2': 'size-4 data-[state=checked]:translate-x-4',
        '3': 'size-3 data-[state=checked]:translate-x-3',
        '4': 'size-2 data-[state=checked]:translate-x-2',
      },
      radius: {
        none: 'rounded-none',
        sm: 'rounded-sm',
        md: 'rounded-md',
        lg: 'rounded-lg',
        full: 'rounded-full',
      },
    },
  },
);

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & SwitchProps
>(({ className, size, radius = 'full', ...props }, ref) => {
  return (
    <SwitchPrimitives.Root
      className={cn(switchVariantsRoot({ size, radius, className }), className)}
      {...props}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={cn(switchVariantsThumb({ size, radius }), className)}
      />
    </SwitchPrimitives.Root>
  );
});

export { Switch };
