// TODO(baibhav): type logEvent
const parseLogFacetsFromLogEvent = (logEvent: any) => {
  const { extraFacets, facets, logLineParts } = logEvent;
  if (facets) {
    return facets.map((facet) => ({
      name: facet.name,
      type: facet.dataType,
      value: facet.content,
    }));
  }

  if (logLineParts) {
    return [
      ...logLineParts
        .filter((logLinePart) => logLinePart.partType === 'facet')
        .map((logLinePart) => logLinePart.facetPart),
      ...(extraFacets || []),
    ].map((facetPart) => {
      const { name, dataType, content } = facetPart;
      return {
        name,
        type: dataType,
        value: content,
      };
    });
  }

  return [];
};

export default parseLogFacetsFromLogEvent;
