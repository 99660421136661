import { useToggle } from 'hooks';
import React, {
  createContext,
  MutableRefObject,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import RrwebPlayer from 'rrweb-player';
import { RumEventType } from 'screens/Rum';
import getActions from './getActions';
import { useRumReplaySegmentFetchercontext } from './RumReplaySegmentFetcherContext';
import { RumEvent, SessionMetadata } from './types';
import useSegmentFetcher from './useSegmentFetcher';
import { filterOutBackgroundEvents } from './utils';

const RumReplayStateContext = createContext<{
  actions: ReturnType<typeof getActions>;
  activeTabIndex: number;
  currentTimeState: ReturnType<typeof useState<number>>;
  hoverPercentState: ReturnType<typeof useState<number>>;
  isPlayingToggle: ReturnType<typeof useToggle>;
  segmentFetcher: ReturnType<typeof useSegmentFetcher>;
  shownEvents: RumEvent[];
  showBackgroundEventsToggle: ReturnType<typeof useToggle>;
  shownRumEventTypesState: ReturnType<typeof useState<Record<RumEventType, 1>>>;
  speedState: ReturnType<typeof useState<number>>;
}>(null);

type Props = {
  children: ReactNode;
  events: RumEvent[];
  playersByIndexRef: MutableRefObject<Record<string, RrwebPlayer>>;
  session: SessionMetadata;
  startReplayAtUnixMs?: number;
};

export const RumReplayStateProvider = ({
  children,
  events,
  playersByIndexRef,
  session,
  startReplayAtUnixMs,
}: Props) => {
  const currentTimeState = useState<number>(0);
  const [currentTime] = currentTimeState;

  const hoverPercentState = useState<number>(0);

  const isPlayingToggle = useToggle(true);
  const showBackgroundEventsToggle = useToggle();

  const segmentFetcher = useRumReplaySegmentFetchercontext();

  const shownRumEventTypesState = useState<Record<RumEventType, 1>>(
    Object.values(RumEventType).reduce(
      (obj, rumEventType) => ({ ...obj, [rumEventType]: 1 }),
      {} as Record<RumEventType, 1>,
    ),
  );

  const speedState = useState<number>(1);

  const tabStartTimes = useMemo(() => {
    const { startTimeUnixMs, tabs } = session;
    return tabs.map((tab) => tab.startTimeUnixMs - startTimeUnixMs);
  }, [session]);

  const activeTabIndex = useMemo(() => {
    const foundIndex = tabStartTimes.findIndex(
      (startTime) => currentTime <= startTime,
    );
    if (foundIndex > -1) {
      return foundIndex - 1;
    }

    return tabStartTimes.length - 1;
  }, [currentTime, tabStartTimes]);

  const shownEvents = useMemo(() => {
    const [shownRumEventTypes] = shownRumEventTypesState;

    return events.filter((event) => shownRumEventTypes[event.eventType]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, shownRumEventTypesState[0], showBackgroundEventsToggle.value]);

  useEffect(() => {
    segmentFetcher.fetchAllSegments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actions = getActions({
    currentTimeState,
    hoverPercentState,
    isPlayingToggle,
    playersByIndexRef,
    segmentFetcher,
    session,
    shownRumEventTypesState,
    speedState,
    startReplayAtUnixMs,
  });

  return (
    <RumReplayStateContext.Provider
      value={{
        activeTabIndex,
        actions,
        currentTimeState,
        hoverPercentState,
        isPlayingToggle,
        segmentFetcher,
        showBackgroundEventsToggle,
        shownEvents,
        shownRumEventTypesState,
        speedState,
      }}
    >
      {children}
    </RumReplayStateContext.Provider>
  );
};

export const useRumReplayStateContext = () => useContext(RumReplayStateContext);
