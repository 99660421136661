import { Input } from 'components';

import { useAutocompleteState, useFilterState, useOnClickOutside } from 'hooks';
import React, { ReactElement, ReactNode } from 'react';
import { X } from 'react-feather';
import { FilterTagsProps } from 'types';

import SearchBarPanel from './SearchBarPanel';
import SearchBarTags from './SearchBarTags';
import SearchBarEditFacetValue from './SearchBarEditFacetValue';
import classNames from 'classnames';

const SearchBar = ({
  autocompleteState,
  className,
  disableKeyExist = true,
  filterState,
  errorLabel,
  minPanelWidth,
  placeholder = 'Search Logs',
  renderEditComponent,
  tags,
}: {
  autocompleteState: ReturnType<typeof useAutocompleteState>;
  className?: string;
  disableKeyExist?: boolean;
  filterState: ReturnType<typeof useFilterState>;
  errorLabel?: ReactNode;
  minPanelWidth?: number;
  placeholder?: string;
  renderEditComponent?: ReactNode;
  tags: FilterTagsProps[];
}): ReactElement => {
  const {
    activePanel,
    applyFilterKeyExists,
    clearSearchItem,
    closePanel,
    editSearch,
    focusToggle,
    inputRef,
    initialOptions,
    markForDeletionToggle,
    onAutocompleteOptionClick,
    isOptionAlreadyApplied,
    onChange,
    onClick,
    onEnter,
    onFoucs,
    onScrollToBottom,
    operatorMap,
    reRenderPanel,
    search,
    searchBarRef,
    setEditSearch,
    setSearch,
    updateActiveOperator,
  } = autocompleteState;
  const { clearAllFilters } = filterState;

  useOnClickOutside({
    onClick: (e) => {
      const className = e.target && (e.target as HTMLElement).className;
      if (typeof className !== 'string') return;
      if (className?.startsWith('autocomplete__list__option')) {
        return;
      }

      focusToggle.off();
    },
    ref: searchBarRef,
    shouldUseClick: false,
  });

  const onBackspace = () => {
    if (search === '') {
      clearSearchItem();
      setEditSearch(null);
      if (tags.length) {
        if (markForDeletionToggle.value) {
          tags[tags.length - 1].onClick();
          markForDeletionToggle.off();
          closePanel();
        } else {
          markForDeletionToggle.on();
        }
      } else {
        if (markForDeletionToggle.value) {
          markForDeletionToggle.off();
        }
      }
      reRenderPanel({
        activeOperator: '=',
        nextTyped: '',
        optionData: { options: initialOptions, isLoading: false },
      });
    }
  };

  const renderEditFacetPanel = () => {
    if (editSearch.filterKey === 'sidebarFilters' && !focusToggle.value) {
      const panelWidth = inputRef.current?.getBoundingClientRect().width;
      return (
        <SearchBarEditFacetValue
          autocompleteState={autocompleteState}
          filterState={filterState}
          panelWidth={Math.max(panelWidth, minPanelWidth || 0) || 400}
        />
      );
    }
  };

  return (
    <div
      className={classNames({
        'logs__search-bar': true,
        [className]: className,
      })}
    >
      <div
        className="field-group__item field-group__item--flex"
        ref={searchBarRef}
      >
        <div
          className={classNames({
            'search-bar__container': true,
            'search-bar__container--focused': focusToggle.value,
          })}
          onClick={onClick}
          data-testid="search-bar"
        >
          <SearchBarTags autocompleteState={autocompleteState} tags={tags} />
          <Input
            className="input--no-border search-bar__input"
            onBackspace={onBackspace}
            onChange={onChange}
            onEnter={onEnter}
            onFocus={onFoucs}
            ref={inputRef}
            placeholder={placeholder}
            type="text"
            value={search}
            data-testid="search-bar-input"
          />
          {activePanel?.optionData.isLoading && (
            <div
              className="logs__search-bar__input__clear"
              style={{ right: tags.length ? 30 : 8 }}
            >
              <div className="spinner"></div>
            </div>
          )}
          {tags.length ? (
            <button
              className="logs__search-bar__input__clear"
              type="button"
              onClick={(e) => {
                setSearch('');
                clearAllFilters();
                focusToggle.off();
                e.stopPropagation();
              }}
            >
              <X color="#da545b" size={16} />
            </button>
          ) : null}
        </div>
        {editSearch && !renderEditComponent && renderEditFacetPanel()}
        {renderEditComponent && renderEditComponent}
        {focusToggle.value && (
          <SearchBarPanel
            activeOperator={activePanel.activeOperator}
            disableKeyExist={disableKeyExist}
            onAutocompleteOptionClick={onAutocompleteOptionClick}
            isOptionAlreadyApplied={isOptionAlreadyApplied}
            onClickKeyExistHandler={({ option }) =>
              applyFilterKeyExists(option)
            }
            onScrollToBottom={onScrollToBottom}
            operatorMap={operatorMap}
            optionData={activePanel.optionData}
            panelWidth={
              Math.max(
                inputRef.current?.getBoundingClientRect().width,
                minPanelWidth || 0,
              ) || 400
            }
            typed={activePanel.typed}
            updateActiveOperator={updateActiveOperator}
          />
        )}
        {errorLabel && errorLabel}
      </div>
    </div>
  );
};

export default SearchBar;
