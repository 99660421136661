import classnames from 'classnames';
import React, { ReactElement, useRef, useEffect } from 'react';
const ScrollViewChanning = ({
  children,
  className,
  maxHeight = 400,
  onScrollToBottom,
  scollToBottomThreshold = 100,
  scrollContainerRef,
}: {
  children: ReactElement | ReactElement[];
  className: string;
  maxHeight?: number;
  onScrollToBottom?: VoidFunction;
  scollToBottomThreshold?: number;
  scrollContainerRef: React.MutableRefObject<HTMLDivElement>;
}): ReactElement => {
  const hasScrolledToBottomRef = useRef<boolean>(false);

  useEffect(() => {
    const child = scrollContainerRef.current;

    if (!child) {
      return;
    }

    const handleScroll = (e: WheelEvent) => {
      if (
        child.scrollTop > -1 &&
        child.scrollTop < child.scrollHeight - child.clientHeight
      ) {
        e.stopPropagation();
      }

      if (
        !hasScrolledToBottomRef.current &&
        child.scrollTop + child.clientHeight >=
          child.scrollHeight - scollToBottomThreshold
      ) {
        onScrollToBottom && onScrollToBottom();
        hasScrolledToBottomRef.current = true;
      } else if (
        child.scrollTop + child.clientHeight <
        child.scrollHeight - scollToBottomThreshold
      ) {
        hasScrolledToBottomRef.current = false;
      }
    };

    child.addEventListener('wheel', handleScroll, { passive: false });
    return () => {
      child.removeEventListener('wheel', handleScroll);
    };
  }, [onScrollToBottom, scollToBottomThreshold, scrollContainerRef]);

  return (
    <div
      ref={scrollContainerRef}
      style={{
        maxHeight,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
      className={classnames(
        className,
        'scrollbar-thin scrollbar-thumb-border scrollbar-track-background',
      )}
    >
      {children}
    </div>
  );
};

export default ScrollViewChanning;
