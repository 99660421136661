import React, { ReactElement, useMemo } from 'react';
import {
  DateSelection,
  LayoutType,
  LogsMetricForumlaProps,
  LogsMetricQueryProps,
} from 'types';
import {
  calculateForecastLookbackDuration,
  getForecastLookbackAndPredictDate,
} from 'utils';

import {
  ConditionProps,
  ForecastConditionProps,
} from '../AlertsCreateCondition';
import { getQueryItemForKfuseqlForForecast } from '../AlertsCreateLogs/utils';
import AlertsCreateLogsChartForecastSplit from './AlertsCreateLogsChartForecastSplit';

const AlertsCreateLogsChartForecast = ({
  baseWidth,
  condition,
  chartLayoutType,
  date,
  forecastCondition,
  isChartCompact,
  queryKey,
  queries,
  formulas,
  unit,
}: {
  baseWidth: number;
  condition: ConditionProps;
  chartLayoutType?: LayoutType;
  date: DateSelection;
  forecastCondition: ForecastConditionProps;
  isChartCompact: boolean;
  queryKey: string;
  queries: LogsMetricQueryProps[];
  formulas: LogsMetricForumlaProps[];
  unit: string;
}): ReactElement => {
  const { forecastDuration, forecastAlgorithm, interval, seasonality } =
    forecastCondition;
  const dateForChart = useMemo(() => {
    const lookbackDuration =
      calculateForecastLookbackDuration(forecastDuration);
    return getForecastLookbackAndPredictDate({
      forecastDuration,
      lookbackDuration,
      forecastInterval:
        forecastAlgorithm === 'seasonal' ? Number(interval) : undefined,
      date,
      seasonality: forecastAlgorithm === 'seasonal' ? seasonality : undefined,
      type: 'logs',
    });
  }, [forecastDuration, interval, date, forecastAlgorithm, seasonality]);

  const kfuseqlWithForecast = useMemo(() => {
    if (!dateForChart) return;
    const queryItem = getQueryItemForKfuseqlForForecast({
      formulas,
      queries,
      selectedQuery: queryKey,
      step: dateForChart.step,
    });

    return queryItem;
  }, [dateForChart, formulas, queries, queryKey]);

  const getQueryMappings = useMemo(
    () => ({
      formulas: formulas.map((formula) => ({
        queryKey: formula.queryKey,
        isActive: formula.isActive,
      })),
      queries: queries.map((query) => ({
        queryKey: query.queryKey,
        isActive: query.isActive,
      })),
    }),
    [formulas, queries],
  );

  return (
    <AlertsCreateLogsChartForecastSplit
      baseWidth={baseWidth}
      condition={condition}
      dateForChart={dateForChart}
      chartLayoutType={chartLayoutType}
      date={date}
      forecastCondition={forecastCondition}
      getQueryMappings={getQueryMappings}
      isChartCompact={isChartCompact}
      queryItem={kfuseqlWithForecast}
      queryKey={queryKey}
      unit={unit}
    />
  );
};

export default AlertsCreateLogsChartForecast;
