import React, { ReactElement, useState, useEffect, useRef } from 'react';

const ITEM_HEIGHT = 30;
const SCROLL_OFFSET = ITEM_HEIGHT * 40;

type Props = {
  data: Array<any>;
  itemHeight?: number;
  onEnterKey?: (item: any, idx: number) => void;
  onScrollToBottom?: () => void;
  renderItem: (data: {
    item: any;
    index: number;
    isFocused: boolean;
  }) => ReactElement<any, any> | null;
};

const FlatList = ({
  data,
  itemHeight = ITEM_HEIGHT,
  onEnterKey,
  onScrollToBottom,
  renderItem,
}: Props): ReactElement => {
  const containerRef = useRef(null);
  const dataRef = useRef([]);
  const focusedIndexRef = useRef(-1);
  const [visibleIndices, setVisibleIndices] = useState({
    start: 0,
    end: Math.min(30, data.length),
  });
  const [focusedIndex, setFocusedIndex] = useState({ index: -1 });
  const hasFired = useRef(false);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    const scrollRenderedItems = Math.floor(scrollTop / itemHeight);
    const visibleItems = Math.ceil(
      containerRef.current.clientHeight / itemHeight,
    );

    // Check if scrolled to bottom
    if (
      !hasFired.current &&
      scrollTop + clientHeight >= scrollHeight - SCROLL_OFFSET
    ) {
      onScrollToBottom && onScrollToBottom();
      hasFired.current = true;
    }

    setVisibleIndices((prev) => {
      // if diff between scrollRenderedItems and prev.end is greater than visibleItems * 4
      // then we need to update the visible indices
      if (prev.end - scrollRenderedItems > visibleItems * 4) return prev;
      return {
        start: 0,
        end: Math.min(prev.end + visibleItems * 2, data.length),
      };
    });
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (
      e.key === 'Enter' &&
      focusedIndexRef.current > -1 &&
      focusedIndexRef.current < dataRef.current.length
    ) {
      e.preventDefault();
      e.stopPropagation();
      onEnterKey(dataRef.current[focusedIndexRef.current], focusedIndex.index);
      return false;
    }

    setFocusedIndex((prev) => {
      let newIndex = prev.index;
      if (e.key === 'ArrowDown' && newIndex < data.length - 1) {
        newIndex += 1;
      } else if (e.key === 'ArrowUp' && newIndex > 0) {
        newIndex -= 1;
      }

      const focusedNode = containerRef.current?.children[newIndex];
      if (focusedNode) {
        focusedNode.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }

      return { index: newIndex };
    });
  };

  useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll);
    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    focusedIndexRef.current = focusedIndex.index;
  }, [focusedIndex]);

  useEffect(() => {
    if (data.length > 0) {
      document.addEventListener('keyup', handleKeyDown, {
        capture: true,
      });
    } else {
      document.removeEventListener('keyup', handleKeyDown, { capture: true });
    }
    return () =>
      document.removeEventListener('keyup', handleKeyDown, { capture: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data.length > 0) {
      setVisibleIndices((prev) => ({
        start: prev.start,
        end: Math.min(prev.end + 30, data.length),
      }));
    }
    hasFired.current = false;

    dataRef.current = data;
  }, [data]);

  if (data.length < visibleIndices.end) return null;
  return (
    <div ref={containerRef} tabIndex="0" className="overflow-y-scroll-hide">
      {Array.from(
        { length: visibleIndices.end - visibleIndices.start },
        (v, i) =>
          renderItem({
            item: data[i + visibleIndices.start],
            index: i + visibleIndices.start,
            isFocused: focusedIndex.index === i,
          }),
      )}
    </div>
  );
};

export default FlatList;
