import { TableColumn } from 'components/Table';
import dayjs from 'dayjs';

// id -> formatTime
const formatTableTimestamp = ({
  data,
  options,
}: {
  data: {
    data: Record<string, unknown>[];
    columns: TableColumn<{ key: string; label: string }>[];
  };
  options: { outputFormat: string; timeField: string; useTimezone: boolean };
}): {
  data: Record<string, unknown>[];
  columns: TableColumn<{ key: string; label: string }>[];
} => {
  if (!Array.isArray(data.data)) {
    return { data: [], columns: [] };
  }

  const { outputFormat, timeField, useTimezone } = options;
  if (
    typeof outputFormat !== 'string' ||
    typeof timeField !== 'string' ||
    typeof useTimezone !== 'boolean'
  ) {
    return data;
  }

  const newTimestampData = data.data.map((datum) => {
    if (!datum || !datum.hasOwnProperty(timeField)) return datum;
    const timestamp = datum[timeField as keyof Record<string, unknown>];
    const newTimestamp = dayjs.unix(Number(timestamp));
    if (!newTimestamp.isValid()) return datum;
    return {
      ...datum,
      [timeField]: useTimezone
        ? newTimestamp.format(outputFormat)
        : newTimestamp.utc().format(outputFormat),
    };
  });
  return { data: newTimestampData, columns: data.columns };
};

export default formatTableTimestamp;
