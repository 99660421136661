/**
 * Validate arithmetic expression
 * @param formula string
 * @param queryVar string[]
 * @returns boolean
 * example: 'a*b', ['a', 'b'] => true
 * example: 'a*c', ['a', 'b'] => true
 * example: '(2a*b', ['a', 'b'] => false
 */
const validateArithmeticFormulas = ({
  formula,
  queryVar,
  operators,
  operatorRegex,
}: {
  formula: string;
  queryVar: string[];
  operators?: string[];
  operatorRegex?: string;
}): boolean => {
  const defaultOperators = ['+', '-', '*', '/', '%', '^'];
  const supportedOperators = operators || defaultOperators;

  // get all characters from a to z from formula
  const formulaVar = new RegExp('[a-z]', 'gi');
  const formulaVarArray = formula.match(formulaVar);
  const checkAllCharExist = formulaVarArray?.every((char) => {
    return queryVar.includes(char);
  });
  if (!checkAllCharExist) {
    return false;
  }

  // check if formula has unsupported operators
  const formulaOperator = new RegExp(operatorRegex || '[+\\-*/%^]', 'gi');
  const formulaOperatorArray = formula.match(formulaOperator);
  const checkAllOperatorExist = formulaOperatorArray?.every((operator) => {
    return supportedOperators.includes(operator);
  });
  if (!checkAllOperatorExist) {
    return false;
  }

  // Check for consecutive duplicates
  for (let i = 0; i < formula.length - 1; i++) {
    const char = formula[i];
    // skip if next char is number
    if (!isNaN(Number(char))) {
      continue;
    }
    if (char === '(' || char === ')') {
      continue;
    }
    if (
      formula[i] === formula[i + 1] &&
      !supportedOperators.includes(formula[i] + formula[i + 1])
    ) {
      return false; // Found consecutive duplicate characters
    }
  }

  // check open and close brackets
  const openBrackets = formula.match(/\(/g);
  const closeBrackets = formula.match(/\)/g);
  if (openBrackets?.length !== closeBrackets?.length) {
    return false;
  }

  // replace all char in formula with random number
  const formulaWithRandomNumber = formula.replace(/[a-z]/gi, '1');
  try {
    // evaluate math expression withouth eval
    const formulaEval = new Function(`return ${formulaWithRandomNumber}`);
    const formulaEvalResult = formulaEval();
    if (isNaN(formulaEvalResult)) {
      return false;
    }
  } catch {
    return false;
  }

  return true;
};

export default validateArithmeticFormulas;
