import { useKeyExistsState } from 'hooks';
import React, { MutableRefObject } from 'react';
import { LogLinePart } from 'types';
import { useLogsWorkbooksState } from './hooks';
import LogsLogLinePartsItemFacet from './LogsLogLinePartsItemFacet';

const NUMBER_OF_CHARACTERS_FOR_TRUNCATED_PART = 1000;

const getPart = (logLinePart: LogLinePart) => {
  const { facetPart, paramPart, partType, tokenPart } = logLinePart;
  const partTypeLowercased = partType.toLowerCase();
  switch (partTypeLowercased) {
    case 'facet':
      return facetPart;
    case 'param':
      return paramPart;
    case 'token':
      return tokenPart;
    default:
      return null;
  }
};

type Props = {
  containerRef?: MutableRefObject<HTMLDivElement>;
  customColumnsState?: ReturnType<typeof useKeyExistsState>;
  disableAddCustomColumn?: boolean;
  fpHash: string;
  index: number;
  logLinePart: LogLinePart;
  logsState: ReturnType<typeof useLogsWorkbooksState>['currentLogsState'];
  offsetX?: number;
  offsetY?: number;
  shouldDisableFilterActions: boolean;
  shouldTruncateLogLinePart?: boolean;
  source: string;
};

const LogsLogLinePartsItem = ({
  containerRef,
  customColumnsState,
  disableAddCustomColumn,
  fpHash,
  index,
  logLinePart,
  logsState,
  offsetX,
  offsetY,
  shouldDisableFilterActions,
  shouldTruncateLogLinePart,
  source,
}: Props) => {
  const { facetPart, partType } = logLinePart;
  if (partType.toLowerCase() === 'facet') {
    return (
      <LogsLogLinePartsItemFacet
        containerRef={containerRef}
        customColumnsState={customColumnsState}
        disableAddCustomColumn={disableAddCustomColumn}
        fpHash={fpHash}
        index={index}
        facetPart={facetPart}
        logFacet={{ name: facetPart.name, source, type: facetPart.dataType }}
        logsState={logsState}
        offsetX={offsetX}
        offsetY={offsetY}
        shouldDisableFilterActions={shouldDisableFilterActions}
        source={source}
      />
    );
  }

  const part = getPart(logLinePart);
  return part ? (
    <span className="logs__log-line-parts__item__content">
      {shouldTruncateLogLinePart
        ? part.content.slice(0, NUMBER_OF_CHARACTERS_FOR_TRUNCATED_PART)
        : part.content}
    </span>
  ) : null;
};

export default LogsLogLinePartsItem;
