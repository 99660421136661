import classNames from 'classnames';
import { Search, TooltipTrigger } from 'components';
import { SearchState, useSearches, useSearchFormulas } from 'hooks/useSearch';
import React, { useCallback, useMemo } from 'react';
import { DashboardPanelType } from 'types';
import { parsePartialSearchQuery } from 'utils/SearchBar';
import getTags from '../RumSearch/getTags';
import RumSearchInputPanel from '../RumSearch/RumSearchInputPanel';
import RumSearchGrouper from './RumSearchGrouper';
import useRumState from '../hooks/useRumState';
import { ALLOW_ONLY_ONE_ACTIVE_SEARCH } from './utils';
import { RumTab } from '../types';
import { numericOperatorBitmap } from 'kfuse-constants/search';
import { getFacetKey, getRange } from 'utils';

type Props = {
  groupByOptions: any;
  getRange: typeof getRange;
  index: number;
  labelNames: Array<any>;
  measureOptions: Array<any>;
  placeholder: string;
  search: SearchState & { selectOnlySingeQuery: () => void };
  searches: ReturnType<typeof useSearches>['searches'];
  searchesFormulas: ReturnType<typeof useSearchFormulas>;
  rumTab: RumTab;
  rumState: ReturnType<typeof useRumState>;
};

const RumQuerySearch = ({
  groupByOptions,
  getRange,
  index,
  labelNames,
  measureOptions,
  placeholder,
  search,
  searches,
  rumTab,
  rumState: pageRumState,
}: Props) => {
  const {
    applicationFilter,
    dateState,
    eventType,
    filtersState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    idSearch,
    setIdSearch,
  } = pageRumState;
  const [date] = dateState;

  const tags = useMemo(
    () => {
      return getTags({
        filtersState: filtersState,
        idSearch,
        setIdSearch,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageRumState.state],
  );

  const onEnter = ({
    typed,
    meta = {},
  }: {
    typed: string;
    meta: Record<string, unknown>;
  }) => {
    const parsedSearch = parsePartialSearchQuery(typed, false);
    if (parsedSearch) {
      const { facetName, operator, operatorIndex, value } = parsedSearch;

      if (facetName && operator) {
        if (operator === '=' || operator === '!=') {
          const setFacetValues = selectedFacetValuesByNameState.setFacetValues;

          const values = value
            .split(' OR ')
            .map((v) => v.trim())
            .filter((v) => v);

          setFacetValues({
            name: facetName,
            facetValues: values.reduce(
              (obj, value) => ({
                ...obj,
                [value]: operator === '=' ? 1 : 0,
              }),
              {},
            ),
          });

          return;
        }

        if (numericOperatorBitmap[operator]) {
          const { facetName: rangeFacetName, ...range } = getRange({
            firstOperator: operator,
            firstOperatorIndex: operatorIndex,
            typed,
          });

          const facet = getFacetKey({
            component: '',
            ...meta,
            name: rangeFacetName,
          });
          selectedFacetRangeByNameState.changeFacetRange({
            name: facet,
          })(range);
          return;
        }
      }
    }
    setIdSearch(typed);
  };

  const { changeActive } = search;

  const handleActiveClick = useCallback(() => {
    if (
      ALLOW_ONLY_ONE_ACTIVE_SEARCH.includes(
        rumTab as unknown as DashboardPanelType,
      )
    ) {
      if (search.isActive) {
        changeActive(!search.isActive);
      } else {
        search.selectOnlySingeQuery();
      }
    } else {
      changeActive(!search.isActive);
    }
  }, [changeActive, search, rumTab]);

  return (
    <>
      <div className="flex--direction-col mb-3 flex">
        <div className="flex">
          <div className="width-100-per flex">
            {
              <TooltipTrigger tooltip={'Show Chart'}>
                <div
                  onClick={handleActiveClick}
                  className={classNames({
                    'query-builder__logs__item__query-key': true,
                    'query-builder__logs__item__query-key--fullHeight': true,
                    'query-builder__logs__item__query-key--inactive':
                      !search.isActive,
                  })}
                >
                  {search.queryKey}
                </div>
              </TooltipTrigger>
            }
            <Search
              hideInfo
              onEnter={onEnter}
              placeholder={placeholder}
              renderTypedPanel={({
                close,
                editIndex,
                onValueSelect,
                setTyped,
                typed,
              }) => {
                return (
                  <RumSearchInputPanel
                    applicationFilter={applicationFilter}
                    close={close}
                    date={date}
                    editIndex={editIndex}
                    eventType={eventType}
                    labelNames={labelNames}
                    onEnter={onEnter}
                    onValueSelect={onValueSelect}
                    selectedFacetValuesByNameState={
                      selectedFacetValuesByNameState
                    }
                    setTyped={setTyped}
                    tags={tags}
                    typed={typed}
                  />
                );
              }}
              tags={tags}
            />
          </div>
        </div>
        <RumSearchGrouper
          key={index}
          date={date}
          groupByOptions={groupByOptions}
          measureOptions={measureOptions}
          search={search}
          searchIndex={index}
          searchesCount={searches.length}
          rumTab={rumTab}
        />
      </div>
    </>
  );
};

export default RumQuerySearch;
