import { TableColumn, useColumnsState } from 'components';
import { saveAs } from 'file-saver';
import {
  useFacetRegexState,
  useRequest,
  useSelectedFacetRangeByNameState,
  useSelectedFacetValuesByNameState,
  useSortState,
} from 'hooks';
import { useRef, useState } from 'react';
import { DownloaderState, Downloader, DateSelection } from 'types';
import { getFileExtension, getFormatter } from 'utils';
import { MAX_LOG_OR_TRACE_DOWNLOAD_SIZE } from 'kfuse-constants';
import getRumEvents from '../requests/getRumEvents';
import { RumEventType } from '../types';

const limit = 1000;

type Args = {
  columnsState: ReturnType<typeof useColumnsState>;
  onDone?: VoidFunction;
  downloadType: string;
};

const useRumDownloader = (args: Args): Downloader => {
  const { columnsState, downloadType, onDone } = args;
  const pageCursorRef = useRef<string>(null);

  const [state, setState] = useState<DownloaderState>({
    isDone: false,
    result: [],
    size: 0,
  });

  const getRumEventsRequest = useRequest(getRumEvents, true, true);
  const fileExtension = getFileExtension(downloadType);
  const format = getFormatter(downloadType);

  const download = (s: string) => {
    const blob = new Blob([s], {
      type: 'text/plain;charset=utf-8',
    });

    if (onDone) {
      onDone();
    }

    saveAs(blob, `RumEvents.${fileExtension}`);
  };

  const fetchData = ({
    applicationFilter,
    columnsState,
    date,
    eventType,
    facetRegexState,
    idSearch,
    rumTableSortState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    viewId,
    actionId,
  }: {
    applicationFilter: string;
    columnsState: ReturnType<typeof useColumnsState>;
    date: DateSelection;
    eventType: RumEventType;
    facetRegexState?: ReturnType<typeof useFacetRegexState>;
    idSearch: string;
    rumTableSortState: ReturnType<typeof useSortState>;
    selectedFacetRangeByNameState: ReturnType<
      typeof useSelectedFacetRangeByNameState
    >;
    selectedFacetValuesByNameState?: ReturnType<
      typeof useSelectedFacetValuesByNameState
    >;
    viewId: string;
    actionId: string;
  }) => {
    const cursor = pageCursorRef.current || '';

    const { startTimeUnix, endTimeUnix } = date;

    getRumEventsRequest
      .call({
        applicationFilter,
        columnsState,
        cursor,
        eventType,
        facetRegex: facetRegexState?.state,
        startTimeUnix,
        idSearch,
        endTimeUnix,
        sortBy: rumTableSortState.state.key,
        sortOrder: rumTableSortState.state.isAsc ? 'asc' : 'desc',
        selectedFacetRangeByName: selectedFacetRangeByNameState.state,
        selectedFacetValuesByName: selectedFacetValuesByNameState.state,
        viewId,
        ...(actionId ? { actionId } : null),
      })
      .then((nextResult) => {
        setState((prevState) => {
          const nextResultArr = [...prevState.result, ...nextResult.data];
          const nextResultJsonString = format({
            columns: columnsState.renderedColumns,
            rows: nextResultArr,
          });
          const size = new TextEncoder().encode(nextResultJsonString).length;
          const sizeInKiloBytes = size / 1024;
          const sizeInMegaBytes = sizeInKiloBytes / 1024;

          if (
            sizeInMegaBytes > MAX_LOG_OR_TRACE_DOWNLOAD_SIZE ||
            !nextResult.cursor
          ) {
            download(nextResultJsonString);

            return {
              isDone: true,
              size,
              result: nextResultArr,
            };
          }

          pageCursorRef.current = nextResult.cursor;
          fetchData({
            applicationFilter,
            columnsState,
            date,
            eventType,
            facetRegexState,
            idSearch,
            rumTableSortState,
            selectedFacetRangeByNameState,
            selectedFacetValuesByNameState,
            viewId,
            actionId,
          });

          return {
            isDone: false,
            size,
            result: nextResultArr,
          };
        });
      });
  };

  const stopAndDownload = () => {
    const string = format({
      columns: columnsState.renderedColumns,
      rows: state.result,
    });
    download(string);
  };

  return {
    fetch: fetchData,
    isLoading: getRumEventsRequest.isLoading,
    state,
    error: getRumEventsRequest.error,
    stopAndDownload,
  };
};

export default useRumDownloader;
