import Uplot from 'uplot';
import { ctaColorRed } from 'utils/colors';
import {
  drawTextOnTimeseries,
  drawLineOnTimeseries,
  canvasPadding,
} from 'utils';

const drawAlertChartWindow = ({
  darkModeEnabled,
  percent,
  u,
  window,
}: {
  darkModeEnabled: boolean;
  percent: number;
  u: Uplot;
  window: string;
}): void => {
  const { ctx, height, width } = u;
  ctx.save();

  const padding = canvasPadding(u);
  const TOTAL_WIDTH = width * 2;
  const TOTAL_HEIGHT = height * 2;

  const percentClamped = Math.min(100, percent);
  const windowWidth =
    (percentClamped * (TOTAL_WIDTH - padding.left - padding.right)) / 100;
  const verticalLineOffet = TOTAL_WIDTH - padding.right - windowWidth;

  const colorRed = darkModeEnabled ? ctaColorRed.dark : ctaColorRed.light;
  // draw solid vertical line
  drawLineOnTimeseries({
    ctx,
    start: { x: verticalLineOffet, y: padding.top },
    end: { x: verticalLineOffet, y: TOTAL_HEIGHT - padding.bottom },
    options: { color: colorRed, lineWidth: 1 },
  });

  // draw bottom horizontal line
  const BOTTOM_LINE_WIDTH = 8;
  drawLineOnTimeseries({
    ctx,
    start: {
      x: verticalLineOffet,
      y: TOTAL_HEIGHT - padding.bottom + BOTTOM_LINE_WIDTH - 2,
    },
    end: {
      x: TOTAL_WIDTH - padding.right,
      y: TOTAL_HEIGHT - padding.bottom + BOTTOM_LINE_WIDTH - 2,
    },
    options: { color: colorRed, lineWidth: BOTTOM_LINE_WIDTH },
  });

  const BOTTOM_OFFSET = 16;
  const RIGHT_OFFSET = 14;
  const text = `alert window (${window})`;
  drawTextOnTimeseries({
    u,
    ctx,
    darkModeEnabled,
    text,
    x: verticalLineOffet - RIGHT_OFFSET,
    y: TOTAL_HEIGHT - BOTTOM_LINE_WIDTH - padding.bottom - BOTTOM_OFFSET,
    options: {
      color: colorRed,
      fontSize: 12,
      fontWeight: '600',
      textAlign: 'left',
    },
    padding: { top: 4, right: 12, bottom: 4, left: 12 },
  });
};

export default drawAlertChartWindow;
