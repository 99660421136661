import { blackListedLabelsBitmap } from 'kfuse-constants';
import { SelectedFacetValuesByName, SpanFilter } from 'types';
import { replaceDotWithUnderscore } from 'utils';
import { escapeDoubleQuote } from './escapePeriod';
import getIsDeselecting from './getIsDeselecting';

type Args = {
  customFilter?: string;
  isDownStream?: boolean;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  spanFilter?: SpanFilter;
  spanTypeFilter?: { filter: string; operator: string };
};

const getLabelName = ({
  isDownStream,
  name,
}: {
  isDownStream?: boolean;
  name: string;
}) => {
  if (isDownStream) {
    if (!blackListedLabelsBitmap[name] && !name.startsWith('client_')) {
      return `client_${name}`;
    }
  }

  return name;
};

export const buildPromQLClausesFromSelectedFacetValuesByName = ({
  customFilter,
  isDownStream,
  selectedFacetValuesByName,
  spanFilter,
  spanTypeFilter,
}: Args): string[] => {
  const clauses: string[] = [];

  Object.keys(selectedFacetValuesByName).forEach((name) => {
    const selectedFacetValues = selectedFacetValuesByName[name];
    const selectedFacetValueKeys = Object.keys(selectedFacetValues);
    const escapedSelectedFacetValueKeys = selectedFacetValueKeys.map((key) =>
      escapeDoubleQuote(key),
    );
    const isDeselecting = getIsDeselecting(selectedFacetValues);

    const labelName = getLabelName({
      isDownStream,
      name: replaceDotWithUnderscore(name),
    });

    const clause = `${labelName}${
      isDeselecting
        ? `!${escapedSelectedFacetValueKeys.length > 1 ? '~' : '='}`
        : `=${escapedSelectedFacetValueKeys.length > 1 ? '~' : ''}`
    }"${escapedSelectedFacetValueKeys.join('|')}"`;
    clauses.push(clause);
  });

  if (spanFilter && spanFilter !== SpanFilter.allSpans) {
    clauses.push(
      `${
        spanFilter === SpanFilter.serviceEntrySpans
          ? 'span_service_entry'
          : spanFilter
      }="true"`,
    );
  }

  if (spanTypeFilter) {
    clauses.push(
      `span_type${spanTypeFilter.operator}"${spanTypeFilter.filter}"`,
    );
  }

  if (customFilter) {
    clauses.push(customFilter);
  }

  return clauses;
};

export const buildPromQLFilterFromSelectedFacetValuesByName = ({
  isDownStream,
  selectedFacetValuesByName,
  spanFilter,
  spanTypeFilter,
  customFilter,
}: Args): string => {
  const clauses = buildPromQLClausesFromSelectedFacetValuesByName({
    isDownStream,
    selectedFacetValuesByName,
    spanFilter,
    spanTypeFilter,
    customFilter,
  });

  return clauses.length ? `{${clauses.join(',')}}` : '';
};

export const getApdexFilters = (clauses: string[]) => {
  const tolerableFilter = ['le="2000"', 'error!="true"', ...clauses].join(',');
  const satisfiedFilter = ['le="500"', 'error!="true"', ...clauses].join(',');
  const denominatorFilter = clauses.join(',');

  return {
    tolerableFilter,
    satisfiedFilter,
    denominatorFilter,
  };
};
