import { MutableRefObject } from 'react';
import {
  DashboardPanelProps,
  DashboardPanelType,
  DashboardTemplateValueProps,
  DateSelection,
} from 'types';
import {
  eventsDataTransformer,
  DataFrame,
  DataTransformerConfig,
  pieChartTransformer,
  TableTransformerData,
  topListTransformer,
  traceDashboardDataTransformer,
} from 'utils/DataTransformer';
import {
  mergeSeriesData,
  organizeColumn,
} from 'utils/DataTransformer/tableTransformer';

import { getReplacedTimeFromWithDate } from './common-utils';
import { EventsAnalyticsFetchMultiProps } from '../../Events/requests';

export const getEventsQueryForDashboard = ({
  date,
  panel,
  templateValues,
  userActionRef,
  width,
}: {
  date: DateSelection;
  panel: DashboardPanelProps;
  templateValues: DashboardTemplateValueProps;
  userActionRef: MutableRefObject<{ defaultTimeChanged: boolean }>;
  width: number;
}): {
  eventsQuery: EventsAnalyticsFetchMultiProps;
  mainTransformer: DataTransformerConfig[];
  isRange: boolean;
} => {
  const { expr } = panel.targets[0];
  const parsedExpr = JSON.parse(expr || '{}');
  if (!parsedExpr) {
    return { eventsQuery: undefined, mainTransformer: [], isRange: false };
  }

  const panelDate = getReplacedTimeFromWithDate({
    date,
    panel,
    templateValues,
  });
  const isRange = panel.type === DashboardPanelType.TIMESERIES;

  const targetTransformers = eventsDataTransformer(!isRange);
  const { query, queries } = parsedExpr;
  const eventsQuery: EventsAnalyticsFetchMultiProps = {
    date: panelDate,
    instant: !isRange,
    queries: queries || [query],
    transformer: targetTransformers,
    dataFormat: panel.type,
  };

  const mainTransformer = [
    {
      id: 'traceDashboardDataTransformer',
      func: traceDashboardDataTransformer,
    },
  ];

  if (
    panel.type === DashboardPanelType.PIECHART ||
    panel.type === DashboardPanelType.TOP_LIST ||
    panel.type === DashboardPanelType.TABLE
  ) {
    const activeQuery = queries?.find((query) => query.isActive) || query;
    const renamedValues: Record<string, string> = {};
    const refId = activeQuery?.queryKey;
    const valueWithRef = `Value #${refId}`;

    mainTransformer.push({
      id: 'mergeSeriesData',
      func: (dataFrames: DataFrame) => {
        renamedValues[valueWithRef] = dataFrames.meta?.aggregate;
        return mergeSeriesData({ dataFrames: [dataFrames], columns: [] });
      },
    });
    mainTransformer.push({
      id: 'organize',
      func: (data: TableTransformerData) =>
        organizeColumn({
          data,
          options: {
            excludeByName: { Time: true },
            renameByName: renamedValues,
          },
          byField: '',
        }),
    });

    if (panel.type === DashboardPanelType.PIECHART) {
      mainTransformer.push({
        id: 'toPieChart',
        func: (data: TableTransformerData) => {
          const piechart = pieChartTransformer(data);
          return { data: piechart.pieChartData };
        },
      });
    }

    if (panel.type === DashboardPanelType.TOP_LIST) {
      mainTransformer.push({
        id: 'toTopList',
        func: (data: TableTransformerData) =>
          topListTransformer({ data, options: {} }),
      });
    }
  }

  return { eventsQuery, mainTransformer, isRange };
};
