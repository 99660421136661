import { useUrlState } from 'hooks';
import { useMemo, useState } from 'react';
import { SelectedFacetValuesByName } from 'types';
import { facetValueToggleUtil, getIsDeselecting } from 'utils';
import { pickBy, size } from 'lodash';

const toggleFacetHelper = ({ isSelecting, prevState = {}, value }) => {
  const isDeselecting =
    typeof isSelecting === 'boolean'
      ? !isSelecting
      : getIsDeselecting(prevState);
  const nextState = { ...prevState };

  if (value in prevState) {
    delete nextState[value];
    return nextState;
  }

  nextState[value] = isDeselecting ? 0 : 1;
  return nextState;
};

type Options = {
  initialState?: SelectedFacetValuesByName;
  shouldWriteToUrl?: boolean;
  urlStateKey?: string;
};

const useSelectedFacetValuesByNameState = (options?: Options) => {
  // shouldWriteToUrl by defualt
  const initialState = options?.initialState || {};
  const shouldWriteToUrl = options?.shouldWriteToUrl === false ? false : true;

  const urlStateKey = options?.urlStateKey || 'selectedFacetValuesByName';
  const [urlState, setUrlState] = useUrlState(urlStateKey, initialState);
  const [regularState, setRegularState] = useState(initialState);

  const state = useMemo(
    () => (shouldWriteToUrl ? urlState : regularState),
    [shouldWriteToUrl, regularState, urlState],
  );
  const setState = shouldWriteToUrl ? setUrlState : setRegularState;

  const clearFacet = (name) => {
    setState((prevState) => {
      const nextState = { ...prevState };
      delete nextState[name];
      return nextState;
    });
  };

  const excludeFacetValue = ({ name, value }) => {
    setState((prevState) => ({
      ...prevState,
      [name]: {
        [value]: 0,
      },
    }));
  };

  const set = ({ selectedFacetValuesByName }) => {
    setState((prevState) => ({
      ...selectedFacetValuesByName,
    }));
  };

  const selectOnlyFacetValue = ({ name, value }) => {
    setState((prevState) => ({
      ...prevState,
      [name]: {
        [value]: 1,
      },
    }));
  };

  const toggleFacetPickerValueCheckbox = ({
    facetName,
    facetValueToToggle,
    allFacetValues,
  }: {
    facetName: string;
    facetValueToToggle: string;
    allFacetValues: Array<string>;
  }) => {
    setState((prevState) => {
      const updatedState = facetValueToggleUtil.getNextSelectedFacetValues({
        facetName,
        facetValueToToggle,
        allFacetValues,
        selectedFacetValues: prevState[facetName] || {},
      });

      if (size(updatedState) === 0) {
        return pickBy(prevState, (v, k) => k !== facetName);
      }

      return {
        ...prevState,
        [facetName]: updatedState,
      };
    });
  };

  const toggleFacetValue = ({ isSelecting = null, name, value }) => {
    setState((prevState) => ({
      ...prevState,
      [name]: toggleFacetHelper({
        isSelecting,
        prevState: prevState[name],
        value,
      }),
    }));
  };

  const setFacetValues = ({ facetValues, name }) => {
    setState((prevState) => ({
      ...prevState,
      [name]: facetValues,
    }));
  };

  return {
    clearFacet,
    excludeFacetValue,
    selectOnlyFacetValue,
    setFacetValues,
    set,
    state,
    setState,
    toggleFacetValue,
    toggleFacetPickerValueCheckbox,
  };
};

export default useSelectedFacetValuesByNameState;
