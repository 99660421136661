import React, { ReactElement } from 'react';

import useTimeseriesAnnotationPlugin from './useTimeseriesAnnotationPlugin';
import { UPlotConfig } from '../types';

const TimeseriesAnnotation = ({
  config,
}: {
  config: UPlotConfig;
}): ReactElement => {
  const { annotation } = useTimeseriesAnnotationPlugin({ config });

  if (!annotation) return null;
  return <>{annotation}</>;
};

export default TimeseriesAnnotation;
