import React, { createContext, useContext, useRef } from 'react';

const PortalContext = createContext(null);

export const usePortalContext = () => useContext(PortalContext);

export const PortalProvider = ({ element, children }) => {
  const portalRef = useRef(element ?? null);

  const contextValue = {
    portalRef,
  };

  return (
    <PortalContext.Provider value={contextValue}>
      {children}
    </PortalContext.Provider>
  );
};
