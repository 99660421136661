import React, { useRef, useEffect, useState, useMemo } from 'react';

import { Property, Value } from 'components/PropertyValueSelector';
import { PropertyValueSelector, Input } from 'components';

const SearchablePropertyValueSelector = ({
  doesMatchSearchTerm,
  getValues,
  onValueSelect,
  properties,
  renderPreview,
  renderPropertyLabel,
  renderValueLabel,
  searchInputRef,
  searchTerm,
  setSearchTerm,
  shouldScrollValuesPaneToBottom,
}: {
  doesMatchSearchTerm: (
    property: Property,
    value: Value,
    searchTerm: string,
  ) => boolean;
  getValues: (property: Value) => Array<Value>;
  onValueSelect: (property: Property, value: Value) => void;
  properties: Array<Property>;
  renderPreview?: (property: Property, value: Value) => React.ReactNode;
  renderPropertyLabel?: (property: Property) => React.ReactNode;
  renderValueLabel?: (property: Property, value: Value) => React.ReactNode;
  searchInputRef: React.MutableRefObject<HTMLInputElement>;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  shouldScrollValuesPaneToBottom?: boolean;
}) => {
  const keydownContainerRef = useRef<HTMLInputElement | null>(null);

  // focus input element on mount
  useEffect(() => {
    searchInputRef.current?.focus();
  }, [searchInputRef.current]);

  const [activeProperty, setActiveProperty] = useState<Property | null>(null);
  const [activeValue, setActiveValue] = useState<Value | null>(null);

  // This effect auto selects the first property/value matching searchTerm
  useEffect(() => {
    if (searchTerm.trim().length === 0) return;
    for (const property of properties) {
      const valueToActivate = getValues(property).find((value: Value) =>
        doesMatchSearchTerm(property, value, searchTerm),
      );
      if (valueToActivate) {
        setActiveProperty(property);
        setActiveValue(valueToActivate);
        return;
      }
    }
  }, [searchTerm, properties, setActiveProperty, setActiveValue]);

  const searchInputKeydownHandler = useMemo(() => {
    return (e: KeyboardEvent) => {
      if (
        // Disables following default input behavior
        // 1) On ArrowUp, moves cursor to start
        // 2) On ArrowDown, moves cursor to end
        // to prevent interefing with value selector up/down key presses
        ['ArrowDown', 'ArrowUp'].includes(e.key) ||
        // Deactivating activeValue takes precedence over moving cursor left
        (e.key === 'ArrowLeft' && activeValue) ||
        // Activating activeValue takes precedence over moving cursor right
        (e.key === 'ArrowRight' && !activeValue)
      ) {
        e.preventDefault();
      }
    };
  }, [searchInputRef.current, activeValue]);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.addEventListener(
        'keydown',
        searchInputKeydownHandler,
      );
    }

    return () => {
      if (searchInputRef.current) {
        searchInputRef.current.removeEventListener(
          'keydown',
          searchInputKeydownHandler,
        );
      }
    };
  }, [searchInputRef.current, searchInputKeydownHandler]);

  return (
    <div tabIndex={-1} ref={keydownContainerRef}>
      <Input
        type="text"
        className="searchable-property-value-selector__search-input"
        value={searchTerm}
        onChange={setSearchTerm}
        ref={searchInputRef}
        // Force capture focus on the input element
        // until user clicks away from keydown container
        onBlur={(e) => {
          if (e.relatedTarget === keydownContainerRef.current) {
            searchInputRef.current?.focus();
          }
        }}
        placeholder="Search for a function"
      />
      {properties.length > 0 ? (
        <PropertyValueSelector
          activeProperty={activeProperty}
          setActiveProperty={setActiveProperty}
          activeValue={activeValue}
          setActiveValue={setActiveValue}
          getValues={getValues}
          keydownContainerRef={keydownContainerRef}
          onValueSelect={onValueSelect}
          properties={properties}
          renderPreview={renderPreview}
          renderPropertyLabel={renderPropertyLabel}
          renderValueLabel={renderValueLabel}
          shouldScrollValuesPaneToBottom={shouldScrollValuesPaneToBottom}
        />
      ) : (
        <div className="searchable-property-value-selector__empty-state">
          No matching search results
        </div>
      )}
    </div>
  );
};

export default SearchablePropertyValueSelector;
