import {
  Loader,
  Table,
  TooltipTrigger,
  TableSearch,
  useTableSearch,
} from 'components';
import React, { ReactElement, useMemo } from 'react';
import { convertNumberToReadableUnit } from 'utils/formatNumber';
import useSloMetricsTableData from '../hooks/useSloMetricsTableData';
import { SLOProps } from 'types/SLO';
import { SLOQueryTemplates } from '../types';

const columns = ({ sloData }: { sloData: SLOProps }) => [
  {
    key: 'group',
    label: 'Group',
  },
  {
    key: 'status',
    label: 'Status',
    renderCell: ({ row }: { row: any }) => {
      if (!row.status) return '';

      const objective = sloData?.objective || 0;
      const statusRatioFloat = parseFloat(row.status) || 0;
      const status = (1 - statusRatioFloat) * 100;
      const statusColor = status < objective ? 'red' : 'green';
      return <span style={{ color: statusColor }}>{status.toFixed(4)}</span>;
    },
  },
  {
    key: 'errorBudget',
    label: 'Error Budget Left',
    renderCell: ({ row }: { row: any }) => {
      if (!row.errorBudget) return '';

      const errorBudget = (parseFloat(row.errorBudget) || 0) * 100;
      const errorBudgetColor = errorBudget < 0 ? 'red' : 'green';
      return (
        <span style={{ color: errorBudgetColor }}>
          {errorBudget.toFixed(4)}
        </span>
      );
    },
  },
  {
    key: 'goodEvents',
    label: 'Good Events',
    renderCell: ({ row }: { row: any }) => {
      // if (isValueHrLoading) return <div className="spinner" />;
      if (!row.goodEvents) return '';
      return (
        <TooltipTrigger tooltip={row.goodEvents > 1000 ? row.goodEvents : ''}>
          {convertNumberToReadableUnit(Number(row.goodEvents))}
        </TooltipTrigger>
      );
    },
  },
  {
    key: 'badEvents',
    label: 'Bad Events',
    renderCell: ({ row }: { row: any }) => {
      // if (isValueHrLoading) return <div className="spinner" />;
      if (!row.badEvents) return '';
      return (
        <TooltipTrigger tooltip={row.badEvents > 1000 ? row.badEvents : ''}>
          {convertNumberToReadableUnit(Number(row.badEvents))}
        </TooltipTrigger>
      );
    },
  },
  {
    key: 'totalEvents',
    label: 'Total Events',
    renderCell: ({ row }: { row: any }) => {
      // if (isValueLoading) return <div className="spinner" />;
      if (!row.totalEvents) return '';
      return (
        <TooltipTrigger tooltip={row.totalEvents > 1000 ? row.totalEvents : ''}>
          {convertNumberToReadableUnit(Number(row.totalEvents))}
        </TooltipTrigger>
      );
    },
  },
];

const SLODetailsMetricsTable = ({
  queryTemplatesForSLOType,
  sloData,
}: {
  queryTemplatesForSLOType: SLOQueryTemplates;
  sloData: SLOProps;
}): ReactElement => {
  const metricsTableData = useSloMetricsTableData({
    sloData,
    queryTemplatesForSLOType,
  });
  const { rows, isRequestLoading } = metricsTableData;

  const tableSearch = useTableSearch({
    rows,
    shouldWriteToUrl: false,
    searchSpecifcRows: ['group'],
  });

  const columnsMemo = useMemo(
    () => {
      const obj = {
        sloData,
      };

      return columns(obj);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, sloData],
  );

  return (
    <>
      <Loader className="mb-8 m-2" isLoading={isRequestLoading}>
        <TableSearch
          className="uplot__value-legends__search-bar"
          placeholder={`Search ...`}
          tableSearch={tableSearch}
          dataTestId="label-search-bar"
        />
        <Table
          className="table--bordered table--bordered-cells metrics-cardinality-table"
          columns={columnsMemo}
          rows={tableSearch.searchedRows}
          dataTestId="slo-group-table"
        />
      </Loader>
    </>
  );
};

export default SLODetailsMetricsTable;
