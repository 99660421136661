import React, { ReactElement } from 'react';
import {
  AutocompleteListV2,
  AutocompleteOption,
  AutocompleteListPropsV2,
} from 'components';

import SearchBarPanelOperator from './SearchBarPanelOperator';

const PANEL_BORDER_OFFSET = 0;

const SearchBarPanel = ({
  activeOperator,
  disableKeyExist,
  isOptionAlreadyApplied,
  onAutocompleteOptionClick,
  onClickKeyExistHandler,
  onScrollToBottom,
  operatorMap,
  optionData,
  panelWidth,
  updateActiveOperator,
  typed,
}: {
  activeOperator: string;
  disableKeyExist?: boolean;
  isOptionAlreadyApplied: AutocompleteListPropsV2['isOptionAlreadyApplied'];
  onAutocompleteOptionClick: AutocompleteListPropsV2['onClickHandler'];
  onClickKeyExistHandler: AutocompleteListPropsV2['onClickKeyExistHandler'];
  onScrollToBottom?: () => void;
  operatorMap: { [key: string]: string };
  optionData: { options: AutocompleteOption[]; isLoading: boolean };
  panelWidth: number;
  updateActiveOperator: (op: string) => void;
  typed: string;
}): ReactElement => {
  return (
    <div tabIndex={1} className="search-bar__panel">
      <div
        className="search-bar__panel__container"
        style={{ width: panelWidth + PANEL_BORDER_OFFSET, maxHeight: 500 }}
      >
        <div className="search-bar__panel__container__autocomplete">
          <AutocompleteListV2
            close={close}
            disableKeyExist={disableKeyExist}
            isOptionAlreadyApplied={isOptionAlreadyApplied}
            onClickHandler={onAutocompleteOptionClick}
            onClickKeyExistHandler={onClickKeyExistHandler}
            onScrollToBottom={onScrollToBottom}
            options={optionData.options}
            typed={typed}
          />
          <SearchBarPanelOperator
            activeOperator={activeOperator}
            queryOperators={operatorMap}
            onOperatorClick={updateActiveOperator}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchBarPanel;
