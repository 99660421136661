import React, { useMemo } from 'react';
import { calculateCapacity } from 'utils/timeNs';

import { PersistentVolumeClaim } from '../types';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellStatus,
  KubeCellNamespace,
  KubeCellTimestamp,
  KubeTableCell,
  KubeTableFilterCell,
} from './Components';
import KubernetesTable from './KubernetesTable';
import { useEntitiesCount, useEntitiesData } from './useEntitiesData';

export default function KubernetesTableForPersistentVolumeClaim() {
  const { entitiesType, facets, page, limit } = useKubernetesController();

  const filters = useMemo(() => [facets], [facets]);
  const [rows] = useEntitiesData<{
    persistentVolumeClaim: PersistentVolumeClaim;
  }>({
    filters,
    page,
    limit,
  });
  const [count] = useEntitiesCount({
    entitiesType,
    facets,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'persistentvolumeclaim',
          label: 'Persistent Volume Claim',
          renderCell(prop) {
            const persistentVolumeClaimName =
              prop.row.persistentVolumeClaim.metadata.name;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name"
                tooltipText={persistentVolumeClaimName}
              >
                {persistentVolumeClaimName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell(prop) {
            return <KubeCellCluster {...prop} />;
          },
        },
        {
          key: 'metadata.namespace',
          label: 'Namespace',
          renderCell(prop) {
            return <KubeCellNamespace {...prop} />;
          },
        },
        {
          key: 'spec.storageClassName',
          label: 'Class',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="left">
                {prop.row.persistentVolumeClaim.spec.storageClassName}
              </KubeTableCell>
            );
          },
        },

        {
          key: 'status.phase',
          label: 'Phase',
          renderCell(prop) {
            const status = prop.row.persistentVolumeClaim.status.phase;
            return <KubeCellStatus {...prop} status={status} />;
          },
        },
        {
          key: 'spec.volumeName',
          label: 'Persistent Volume',
          renderCell({ row, ...prop }) {
            return (
              <KubeTableFilterCell
                facetKey="kube_persistent_volume"
                facetValue={row.persistentVolumeClaim.spec.volumeName}
                className="kubernetes__table__cell__entity-name truncate w-full"
                row={row}
                {...prop}
              />
            );
          },
        },
        {
          key: 'headerAccessModes',
          label: 'Desired Access Modes',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="left">
                {prop.row.persistentVolumeClaim.spec.accessModes}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'metadata.creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            return (
              <KubeCellTimestamp
                {...prop}
                timestamp={
                  prop.row.persistentVolumeClaim.metadata.creationTimestamp
                }
              />
            );
          },
        },
        {
          key: 'status.capacity',
          label: 'Capacity Requests',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {calculateCapacity(
                  prop.row.persistentVolumeClaim.status.capacity.storage,
                )}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'capacity',
          label: 'Capacity',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {calculateCapacity(
                  prop.row.persistentVolumeClaim.status.capacity.storage,
                )}
              </KubeTableCell>
            );
          },
        },
      ]}
    />
  );
}
