import { Table, TableSearch, ScrollView, useTableSearch } from 'components';
import React, {
  MouseEvent,
  ReactElement,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import uPlot, { AlignedData } from 'uplot';
import { checkAnomalySeriesBandByLabel } from 'utils/Timeseries';

import { SeriesIcon } from '../components';
import { UPlotConfig } from '../types';
import { tooltipFormatter } from '../utils';

const columns = [
  { key: 'label', label: 'Metric' },
  { key: 'avg', label: 'Avg' },
  { key: 'min', label: 'Min' },
  { key: 'max', label: 'Max' },
  { key: 'sum', label: 'Sum' },
  { key: 'value', label: 'Value' },
];

const LegendsRow = ({
  onFocusSeries,
  onRowClick,
  row,
  rowValue,
}: {
  onFocusSeries: () => void;
  onRowClick: (e: MouseEvent) => void;
  row: any;
  rowValue: number | string;
}) => {
  return (
    <tr
      className="table__row table__row--body"
      onClick={onRowClick}
      style={{ opacity: row.show ? 1 : 0.5 }}
      onMouseEnter={onFocusSeries}
    >
      <th className="table__cell table__cell--body">
        <span>
          <SeriesIcon backgroundColor={row.color} />
          {row.label.length > 125 ? row.label.slice(0, 125) + '...' : row.label}
        </span>
      </th>
      <th className="table__cell table__cell--body">{row.avg}</th>
      <th className="table__cell table__cell--body">{row.min}</th>
      <th className="table__cell table__cell--body">{row.max}</th>
      <th className="table__cell table__cell--body">{row.sum}</th>
      <th className="table__cell table__cell--body">{rowValue}</th>
    </tr>
  );
};

const LegendsAggregate = ({
  config,
  data,
  maxHeight,
  onItemClick,
  onFocusSeries,
  unit,
}: {
  config: UPlotConfig;
  data: AlignedData;
  maxHeight?: number;
  onItemClick: (e: MouseEvent<HTMLLIElement>, idx: number) => void;
  onFocusSeries: (idx: number) => void;
  unit: string;
}): ReactElement => {
  const [focusedPointIdx, setFocusedPointIdx] = useState<number>(0);

  const aggregateData: any = useMemo(() => {
    if (config && config.series) {
      const newAggregateData = [];
      for (let i = 1; i < data.length; i++) {
        const row: Array<any> = data[i];
        const rowNum = row.map((val) => (val ? Number(val) : 0));
        const sm = rowNum.reduce((a, b) => a + b, 0);
        newAggregateData.push({
          avg: tooltipFormatter(sm / rowNum.length, unit),
          max: tooltipFormatter(Math.max(...rowNum), unit),
          min: tooltipFormatter(Math.min(...rowNum), unit),
          sum: tooltipFormatter(sm, unit),
          label: config.series[i].label,
          color: config.series[i].stroke || config.series[i].fill,
          show: config.series[i].show,
        });
      }
      return newAggregateData;
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const tableSearch = useTableSearch({
    rows: aggregateData,
    shouldWriteToUrl: false,
  });

  useLayoutEffect(() => {
    config.addHook('setLegend', (u: uPlot) => {
      if (u.cursor.idx && u.cursor.idx !== focusedPointIdx) {
        setFocusedPointIdx(u.cursor.idx);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return (
    <div
      onMouseLeave={() => {
        onFocusSeries(null);
      }}
    >
      {config.series.length > 8 && (
        <TableSearch
          className="uplot__value-legends__search-bar"
          placeholder="Search timeseries..."
          tableSearch={tableSearch}
        />
      )}
      <ScrollView
        height={'auto'}
        maxHeight={maxHeight}
        width={config.width - 16}
        scrollIndicator={false}
      >
        <Table
          className="uplot__aggregate-legends__table"
          columns={columns}
          rows={tableSearch.searchedRows || []}
          renderRow={({ row, rowIndex }) => {
            const rowValue = data[rowIndex + 1][focusedPointIdx];
            const rowSeries = config.series[rowIndex + 1];
            if (checkAnomalySeriesBandByLabel(rowSeries.label)) {
              return null;
            }

            return (
              <LegendsRow
                key={rowIndex}
                onFocusSeries={() => onFocusSeries(rowIndex + 1)}
                onRowClick={(e: any) => {
                  const clickedRow = tableSearch.searchedRows[rowIndex];
                  const seriesRowIdx = config.series.findIndex(
                    (d) => d.label === clickedRow.label,
                  );
                  onItemClick(e, seriesRowIdx);
                }}
                row={row}
                rowValue={
                  rowValue !== undefined
                    ? tooltipFormatter(rowValue, unit)
                    : '-'
                }
              />
            );
          }}
        />
      </ScrollView>
    </div>
  );
};

export default LegendsAggregate;
