import dayjs from 'dayjs';
import { DataFrameMeta, DataTransformerConfig, onPromiseError } from 'utils';
import { PrometheusDatasetResponse } from 'types';

import fetchJson from './fetchJson';

const promqlQueryV2 = <T>({
  meta,
  promqlQuery,
  addEncoding,
  transformer,
}: {
  meta: DataFrameMeta;
  promqlQuery: string;
  addEncoding: boolean;
  transformer: DataTransformerConfig[];
}): Promise<T> => {
  const time = dayjs().unix();

  const encodedPromqlQuery = addEncoding
    ? encodeURIComponent(promqlQuery)
    : promqlQuery;
  const url = `api/v1/query?query=${encodedPromqlQuery}&time=${time}`;

  return fetchJson(url).then((result: PrometheusDatasetResponse) => {
    if (result?.data?.result) {
      meta.resultType = result.data.resultType;
      const initialData = {
        datasets: result.data.result,
        meta,
      };
      const transformed = transformer.reduce(
        (prevData, item) => item.func(prevData),
        initialData,
      );
      return transformed as unknown as T;
    } else {
      return [] as unknown as T;
    }
  }, onPromiseError);
};

export default promqlQueryV2;
