import classnames from 'classnames';
import React, { ReactNode, useMemo } from 'react';
import { AiOutlineLineChart } from 'react-icons/ai';
import { BsFilterLeft, BsGridFill, BsPieChartFill } from 'react-icons/bs';
import { HiTableCells } from 'react-icons/hi2';
import { ImTable } from 'react-icons/im';
import { RiNodeTree } from 'react-icons/ri';
import { NavLink, useSearchParams } from 'react-router-dom';
import { DashboardPanelType, TracesTab } from 'types';

const iconByVisualizeAs: { [key: string]: ReactNode } = {
  [TracesTab.serviceMap]: <RiNodeTree size={14} />,
  [TracesTab.list]: <ImTable size={14} />,
  [DashboardPanelType.TIMESERIES]: <AiOutlineLineChart size={14} />,
  [DashboardPanelType.TOP_LIST]: <BsFilterLeft size={14} />,
  [DashboardPanelType.TABLE]: <HiTableCells size={14} />,
  [DashboardPanelType.PIECHART]: <BsPieChartFill size={14} />,
  [DashboardPanelType.HEATMAP]: <BsGridFill size={14} />,
};

const labelByVisualizeAs: { [key: string]: string } = {
  [TracesTab.serviceMap]: 'Service Map',
  [TracesTab.list]: 'List',
  [DashboardPanelType.TIMESERIES]: 'Time Series',
  [DashboardPanelType.TOP_LIST]: 'Top List',
  [DashboardPanelType.TABLE]: 'Table',
  [DashboardPanelType.PIECHART]: 'Pie Chart',
  [DashboardPanelType.HEATMAP]: 'Heatmap',
};

const SUPPORTED_VISUALIZE_AS = [
  DashboardPanelType.TIMESERIES,
  DashboardPanelType.TOP_LIST,
  DashboardPanelType.TABLE,
  DashboardPanelType.PIECHART,
  DashboardPanelType.HEATMAP,
];

type Props = {
  isLiveTailEnabled: boolean;
  showHeatmap: boolean;
};

const TracesSearchVisualize = ({ isLiveTailEnabled, showHeatmap }: Props) => {
  const [searchParams] = useSearchParams();
  const searchParamsString = searchParams.toString();
  const filteredVisualizations = useMemo(() => {
    if (isLiveTailEnabled) {
      return [];
    }

    const filteredSupportedVisualiseAs = SUPPORTED_VISUALIZE_AS.filter(
      (tab) => {
        if (showHeatmap) {
          return tab;
        } else {
          return tab !== DashboardPanelType.HEATMAP;
        }
      },
    );

    return filteredSupportedVisualiseAs;
  }, [isLiveTailEnabled, showHeatmap]);

  return (
    <div className="traces-search__visualize button-group">
      {[TracesTab.list, ...filteredVisualizations].map((tracesTab) => (
        <NavLink
          className={({ isActive }) =>
            classnames({
              'button-group__item': true,
              'button-group__item--active': isActive,
            })
          }
          key={tracesTab}
          to={`/apm/traces/${tracesTab}${
            searchParamsString ? `?${searchParamsString}` : ''
          }`}
        >
          <div className="button-group__item__icon">
            {iconByVisualizeAs[tracesTab]}
          </div>
          {labelByVisualizeAs[tracesTab]}
        </NavLink>
      ))}
    </div>
  );
};

export default TracesSearchVisualize;
