import React from 'react';

import { Box } from 'components/layouts/Box';
import KubernetesTableGroup from './KubernetesTableGroup';
import { Table, TableRow } from 'components';
import KubernetesRightSideBar from '../KubernetesRightSideBar';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellTimestamp,
  KubeTableCell,
  KubeTableEntityTags,
} from './Components';
import { generatePath, useParams } from 'react-router-dom';
import {
  useEntitiesData,
  useEntitiesGroupData,
  useMergedGroupByFilter,
} from './useEntitiesData';

const renderEmptyCell = () => '';

function KubernetesTableForClusterRoleBindingRow({ row }) {
  const { date, entitiesType, navigate } = useKubernetesController();

  const facets = useMergedGroupByFilter(row.groupBys);
  const [rows] = useEntitiesData({
    entitiesType,
    kubesSort: null,
    facets,
    page: 0,
    limit: 50,
    date,
  });

  const setActiveKube = (row) => {
    navigate(({ location, params, pathTemplate }) => {
      return {
        ...location,

        pathname: generatePath(pathTemplate, {
          ...params,

          activeKube: row.clusterRoleBinding.metadata.uid,
          tab: 'yaml',
        }),
      };
    });
  };

  const activeKubeParam = useParams()['activeKube'];
  const activeKube = (
    typeof activeKubeParam === 'string' && rows ? rows : []
  ).find((row) => {
    return row.clusterRoleBinding.metadata.uid === activeKubeParam;
  });

  return (
    <Box width="full" height="full">
      {activeKube && <KubernetesRightSideBar activeKube={activeKube} />}
      <Table
        className="-my-px"
        renderHeader={false}
        rows={rows ?? []}
        renderRow={(props) => (
          <TableRow
            {...props}
            className="border-y"
            onRowClickHandler={(row) => () => setActiveKube(row)}
          />
        )}
        columns={[
          { key: 'header', label: '' },
          {
            key: 'kube_cluster_role_binding',
            label: 'Cluster Role Binding',
            renderCell(prop) {
              const clusterRoleBindingName =
                prop?.row.clusterRoleBinding?.metadata?.name;
              return (
                <KubeTableCell
                  {...prop}
                  className="kubernetes__table__cell__entity-name pt-1.5 pb-1.5"
                  tooltipText={clusterRoleBindingName}
                >
                  {clusterRoleBindingName}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'kube_cluster_name',
            label: 'Cluster',
            renderCell(prop) {
              return <KubeCellCluster {...prop} />;
            },
          },
          {
            key: 'roleRef.name',
            label: 'Role',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="left">
                  {prop.row.clusterRoleBinding.roleRef?.name}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'headerSub',
            label: 'Subjects',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="left">
                  {prop?.row.clusterRoleBinding?.subjects
                    ? prop?.row.clusterRoleBinding?.subjects[0]?.kind
                    : '0'}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'metadata.creationTimestamp',
            label: 'Age',
            renderCell(prop) {
              const timestamp =
                prop?.row?.clusterRoleBinding?.metadata?.creationTimestamp ||
                '';
              return <KubeCellTimestamp {...prop} timestamp={timestamp} />;
            },
          },
        ]}
      />
    </Box>
  );
}

export default function KubernetesTableForClusterRoleBindingGroup() {
  const { date, entitiesType, facets, groupBySearchTerms } =
    useKubernetesController();

  const [rows] = useEntitiesGroupData({
    entitiesType,
    date,
    facets,
    groupBySearchTerms,
  });

  return (
    <KubernetesTableGroup
      rows={rows ?? []}
      columns={[
        {
          key: 'headerkube_cluster_role_binding',
          label: 'Cluster Role Binding Group',
          renderCell: (prop) => <KubeTableEntityTags {...prop} />,
        },
        {
          key: 'headerkube_cluster_name',
          label: 'Cluster',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerkube_cluster_role',
          label: 'Role',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headersubjects',
          label: 'Subjects',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerage',
          label: 'Age',
          renderCell: renderEmptyCell,
        },
      ]}
      renderExpandedRow={(props) => (
        <KubernetesTableForClusterRoleBindingRow {...props} />
      )}
    />
  );
}
