export type RumViewEvent = {
  time: number;
  data: RumViewEventData;
  eventType?: RumEventType;
};

export type RumActionEvent = {
  time: number;
  data: RumActionEventData;
  eventType?: RumEventType;
};

export type RUMActionsWithErrorsEvent = {
  time: number;
  dimensions: {
    'action.id': string;
    'action.type': string;
    'action.name'?: string;
    'action.target.name': string;
    'action.error.count'?: string;
    'session.id': string;
    'view.name': string;
  };
  aggregates: number[];
};

export type RumResourceEvent = {
  time: number;
  data: RumResourceEventData;
  eventType?: RumEventType;
};

export type RumLongTaskEvent = {
  time: number;
  data: RumLongTaskEventData;
  eventType?: RumEventType;
};

export type RumErrorEvent = {
  time: number;
  data: RumErrorEventData;
  eventType?: RumEventType;
};

export type RumSessionEvent = {
  time: number;
  data: RumSessionEventData;
  eventType?: RumEventType;
};

export type RumEvent =
  | RumViewEvent
  | RumActionEvent
  | RumResourceEvent
  | RumLongTaskEvent
  | RumErrorEvent
  | RumSessionEvent;

export type RumEventWithTabToOpen = RumEvent & {
  openReplay?: boolean;
};

export type RumEventData =
  | RumViewEventData
  | RumActionEventData
  | RumResourceEventData
  | RumLongTaskEventData
  | RumErrorEventData
  | RumSessionEventData;

export type RumViewEventData = {
  'application.id': string;
  'browser.name': string;
  'browser.version': string;
  'browser.version_major': string;
  build_id: string;
  build_version: string;
  'ci_test.test_execution_id': string;
  'connectivity.cellular.carrier_name': string;
  'connectivity.cellular.technology': string;
  'connectivity.effective_type': string;
  'connectivity.interfaces': string[];
  'connectivity.status': string;
  'container.source': string;
  'container.view.id': string;
  context: string;
  date: string;
  'device.architecture': string;
  'device.brand': string;
  'device.model': string;
  'device.name': string;
  'device.type': string;
  'display.scroll.max_depth': string;
  'display.scroll.max_depth_pct': string;
  'display.scroll.max_depth_scroll_top': string;
  'display.scroll.max_scroll_height': string;
  'display.scroll.max_scroll_height_time': string;
  'display.viewport.height': string;
  'display.viewport.width': string;
  env: string;
  feature_flags: string;
  'geo.city': string;
  'geo.continent': string;
  'geo.country': string;
  'geo.country_iso_code': string;
  'geo.country_subdivision': string;
  'os.build': string;
  'os.name': string;
  'os.version': string;
  'os.version_major': string;
  'privacy.replay_level': string;
  sdk_version: string;
  service: string;
  'session.frustration.count': string;
  'session.has_replay': string;
  'session.id': string;
  'session.is_active': string;
  'session.plan': string;
  'session.sampled_for_replay': string;
  'session.type': string;
  source: string;
  'synthetics.injected': string;
  'synthetics.result_id': string;
  'synthetics.test_id': string;
  'usr.email': string;
  'usr.id': string;
  'usr.name': string;
  version: string;
  'view.action.count': string;
  'view.crash.count': string;
  'view.cumulative_layout_shift': string;
  'view.cumulative_layout_shift_target_selector': string;
  'view.cumulative_layout_shift_time': string;
  'view.custom_timings': string;
  'view.dom_complete': string;
  'view.dom_content_loaded': string;
  'view.dom_interactive': string;
  'view.error.count': string;
  'view.first_byte': string;
  'view.first_contentful_paint': string;
  'view.first_input_delay': string;
  'view.first_input_target_selector': string;
  'view.first_input_time': string;
  'view.frozen_frame.count': string;
  'view.frustration.count': string;
  'view.id': string;
  'view.in_foreground': string;
  'view.in_foreground_periods.start': string;
  'view.in_foreground_periods.duration': string;
  'view.interaction_to_next_paint': string;
  'view.interaction_to_next_paint_target_selector': string;
  'view.interaction_to_next_paint_time': string;
  'view.is_active': string;
  'view.is_slow_rendered': string;
  'view.js_refresh_rate': string;
  'view.largest_contentful_paint': string;
  'view.largest_contentful_paint_target_selector': string;
  'view.load_event': string;
  'view.loading_time': string;
  'view.loading_type': string;
  'view.long_task.count': string;
  'view.memory_average': string;
  'view.memory_max': string;
  'view.name': string;
  'view.referrer': string;
  'view.referrer_url.url_hash': string;
  'view.referrer_url.url_host': string;
  'view.referrer_url.url_path': string;
  'view.referrer_url.url_path_group': string;
  'view.referrer_url.url_scheme': string;
  'view.resource.count': string;
  'view.time_spent': string;
  'view.url': string;
  'view.url_hash': string;
  'view.url_host': string;
  'view.url_path': string;
  'view.url_path_group': string;
  'view.url_scheme': string;
  'view.cpu_ticks_count': string;
  'view.cpu_ticks_per_second': string;
  'view.flutter_build_time': string;
  'view.flutter_raster_time': string;
  'view.refresh_rate_average': string;
  'view.refresh_rate_min': string;
};

export type RumActionEventData = {
  'action.crash.count': string;
  'action.error.count': string;
  'action.frustration.type': string;
  'action.id': string;
  'action.loading_time': string;
  'action.long_task.count': string;
  'action.name': string;
  'action.resource.count': string;
  'action.target.name': string;
  'action.type': string;
  'application.id': string;
  'browser.name': string;
  'browser.version': string;
  'browser.version_major': string;
  build_id: string;
  build_version: string;
  'ci_test.test_execution_id': string;
  'connectivity.cellular.carrier_name': string;
  'connectivity.cellular.technology': string;
  'connectivity.effective_type': string;
  'connectivity.interfaces': string;
  'connectivity.status': string;
  'container.source': string;
  'container.view.id': string;
  context: string;
  date: string;
  'device.architecture': string;
  'device.brand': string;
  'device.model': string;
  'device.name': string;
  'device.type': string;
  'display.viewport.height': string;
  'display.viewport.width': string;
  env: string;
  'geo.city': string;
  'geo.continent': string;
  'geo.country': string;
  'geo.country_iso_code': string;
  'geo.country_subdivision': string;
  'os.build': string;
  'os.name': string;
  'os.version': string;
  'os.version_major': string;
  sdk_version: string;
  service: string;
  'session.has_replay': string;
  'session.id': string;
  'session.plan': string;
  'session.type': string;
  source: string;
  'synthetics.injected': string;
  'synthetics.result_id': string;
  'synthetics.test_id': string;
  type: string;
  'usr.email': string;
  'usr.id': string;
  'usr.name': string;
  version: string;
  'view.action.count': string;
  'view.crash.count': string;
  'view.error.count': string;
  'view.id': string;
  'view.in_foreground': string;
  'view.is_active': string;
  'view.loading_type': string;
  'view.long_task.count': string;
  'view.name': string;
  'view.referrer': string;
  'view.referrer_url.url_hash': string;
  'view.referrer_url.url_host': string;
  'view.referrer_url.url_path': string;
  'view.referrer_url.url_path_group': string;
  'view.referrer_url.url_scheme': string;
  'view.resource.count': string;
  'view.url': string;
  'view.url_hash': string;
  'view.url_host': string;
  'view.url_path': string;
  'view.url_path_group': string;
  'view.url_scheme': string;
};

export type RumActionsWithErrorsData = {
  dimensions: {
    'action.id': string;
    'action.type': string;
    'action.target.name': string;
    'action.error.count'?: string;
    'session.id': string;
  };
  aggregates: number[];
};

export type RumLongTaskEventData = {
  'action.id': string;
  'application.id': string;
  'browser.name': string;
  'browser.version': string;
  'browser.version_major': string;
  build_id: string;
  build_version: string;
  'ci_test.test_execution_id': string;
  'connectivity.cellular.carrier_name': string;
  'connectivity.cellular.technology': string;
  'connectivity.effective_type': string;
  'connectivity.interfaces': string;
  'connectivity.status': string;
  date: string;
  'device.architecture': string;
  'device.brand': string;
  'device.model': string;
  'device.name': string;
  'device.type': string;
  'display.viewport.height': string;
  'display.viewport.width': string;
  env: string;
  'geo.city': string;
  'geo.continent': string;
  'geo.country': string;
  'geo.country_iso_code': string;
  'geo.country_subdivision': string;
  'long_task.blocking_duration': string;
  'long_task.duration': string;
  'long_task.entry_type': string;
  'long_task.id': string;
  'long_task.is_frozen_frame': string;
  'long_task.scripts': string;
  'long_task.scripts.duration': string;
  'long_task.scripts.forced_style_and_layout_duration': string;
  'long_task.scripts.invoker': string;
  'long_task.scripts.invoker_type': string;
  'long_task.scripts.pause_duration': string;
  'long_task.scripts.source_char_position': string;
  'long_task.scripts.source_function_name': string;
  'long_task.scripts.start_time': string;
  'long_task.scripts.window_attribution': string;
  'os.build': string;
  'os.name': string;
  'os.version': string;
  'os.version_major': string;
  sdk_version: string;
  service: string;
  'session.has_replay': string;
  'session.id': string;
  'session.plan': string;
  'session.type': string;
  source: string;
  'synthetics.injected': string;
  'synthetics.result_id': string;
  'synthetics.test_id': string;
  type: string;
  'usr.email': string;
  'usr.id': string;
  'usr.name': string;
  version: string;
  'view.id': string;
  'view.name': string;
  'view.referrer': string;
  'view.url': string;
};

export type RumResourceEventData = {
  'action.id': string;
  'application.id': string;
  'browser.name': string;
  'browser.version': string;
  'browser.version_major': string;
  build_id: string;
  build_version: string;
  'ci_test.test_execution_id': string;
  'connectivity.cellular.carrier_name': string;
  'connectivity.cellular.technology': string;
  'connectivity.effective_type': string;
  'connectivity.interfaces': string;
  'connectivity.status': string;
  'container.source': string;
  'container.view.id': string;
  date: string;
  'device.architecture': string;
  'device.brand': string;
  'device.model': string;
  'device.name': string;
  'device.type': string;
  'display.viewport.height': string;
  'display.viewport.width': string;
  env: string;
  'geo.city': string;
  'geo.continent': string;
  'geo.country': string;
  'geo.country_iso_code': string;
  'geo.country_subdivision': string;
  'os.build': string;
  'os.name': string;
  'os.version': string;
  'os.version_major': string;
  'resource.connect.duration': string;
  'resource.connect.start': string;
  'resource.decoded_body_size': string;
  'resource.dns.duration': string;
  'resource.dns.start': string;
  'resource.download.duration': string;
  'resource.download.start': string;
  'resource.duration': string;
  'resource.encoded_body_size': string;
  'resource.first_byte.duration': string;
  'resource.first_byte.start': string;
  'resource.graphql.operationName': string;
  'resource.graphql.operationType': string;
  'resource.graphql.payload': string;
  'resource.graphql.variables': string;
  'resource.id': string;
  'resource.method': string;
  'resource.provider.domain': string;
  'resource.provider.name': string;
  'resource.provider.type': string;
  'resource.redirect.duration': string;
  'resource.redirect.start': string;
  'resource.render_blocking_status': string;
  'resource.size': string;
  'resource.ssl.duration': string;
  'resource.ssl.start': string;
  'resource.status_code': string;
  'resource.transfer_size': string;
  'resource.type': string;
  'resource.url': string;
  'resource.url_host': string;
  'resource.url_path': string;
  'resource.url_path_group': string;
  'resource.url_scheme': string;
  sdk_version: string;
  service: string;
  'session.has_replay': string;
  'session.id': string;
  'session.plan': string;
  'session.type': string;
  source: string;
  'synthetics.injected': string;
  'synthetics.result_id': string;
  'synthetics.test_id': string;
  type: string;
  'usr.email': string;
  'usr.id': string;
  'usr.name': string;
  version: string;
  'view.id': string;
  'view.name': string;
  'view.referrer': string;
  'view.url': string;
};

export type RumErrorEventData = {
  'action.id': string;
  'application.id': string;
  'browser.name': string;
  'browser.version': string;
  'browser.version_major': string;
  build_id: string;
  build_version: string;
  'ci_test.test_execution_id': string;
  'connectivity.cellular.carrier_name': string;
  'connectivity.cellular.technology': string;
  'connectivity.effective_type': string;
  'connectivity.interfaces': string;
  'connectivity.status': string;
  'container.source': string;
  'container.view.id': string;
  context: string;
  date: string;
  'device.architecture': string;
  'device.brand': string;
  'device.model': string;
  'device.name': string;
  'device.type': string;
  'display.viewport.height': string;
  'display.viewport.width': string;
  env: string;
  'error.binary_images': string;
  'error.category': string;
  'error.causes': string;
  'error.csp.disposition': string;
  'error.file': string;
  'error.fingerprint': string;
  'error.handling': string;
  'error.handling_stack': string;
  'error.id': string;
  'error.is_crash': string;
  'error.message': string;
  'error.meta.code_type': string;
  'error.meta.exception_codes': string;
  'error.meta.exception_type': string;
  'error.meta.incident_identifier': string;
  'error.meta.parent_process': string;
  'error.meta.path': string;
  'error.meta.process': string;
  'error.resource.method': string;
  'error.resource.provider.domain': string;
  'error.resource.provider.name': string;
  'error.resource.provider.type': string;
  'error.resource.status_code': string;
  'error.resource.url': string;
  'error.resource.url_host': string;
  'error.resource.url_path': string;
  'error.resource.url_scheme': string;
  'error.source': string;
  'error.source_type': string;
  'error.stack': string;
  'error.threads': string;
  'error.time_since_app_start': string;
  'error.type': string;
  'error.was_truncated': string;
  feature_flags: string;
  'freeze.duration': string;
  'geo.city': string;
  'geo.continent': string;
  'geo.country': string;
  'geo.country_iso_code': string;
  'geo.country_subdivision': string;
  'issue.age': string;
  'issue.first_seen': string;
  'issue.first_seen_version': string;
  'issue.id': string;
  'os.build': string;
  'os.name': string;
  'os.version': string;
  'os.version_major': string;
  'privacy.replay_level': string;
  sdk_version: string;
  service: string;
  'session.has_replay': string;
  'session.id': string;
  'session.plan': string;
  'session.type': string;
  source: string;
  'synthetics.injected': string;
  'synthetics.result_id': string;
  'synthetics.test_id': string;
  type: string;
  'usr.email': string;
  'usr.id': string;
  'usr.name': string;
  version: string;
  'view.cumulative_layout_shift': string;
  'view.cumulative_layout_shift_target_selector': string;
  'view.dom_complete': string;
  'view.dom_content_loaded': string;
  'view.dom_interactive': string;
  'view.first_byte': string;
  'view.first_contentful_paint': string;
  'view.first_input_delay': string;
  'view.first_input_target_selector': string;
  'view.id': string;
  'view.in_foreground': string;
  'view.interaction_to_next_paint': string;
  'view.interaction_to_next_paint_target_selector': string;
  'view.largest_contentful_paint': string;
  'view.largest_contentful_paint_target_selector': string;
  'view.load_event': string;
  'view.loading_time': string;
  'view.name': string;
  'view.referrer': string;
  'view.time_spent': string;
  'view.url': string;
};

export type RumSessionEventData = {
  'application.id': string;
  'browser.name': string;
  'browser.version': string;
  'browser.version_major': string;
  'connectivity.effective_type': string;
  'connectivity.status': string;
  'device.architecture': string;
  'device.brand': string;
  'device.model': string;
  'device.name': string;
  'device.type': string;
  'display.viewport.height': string;
  'display.viewport.width': string;
  env: string;
  'geo.city': string;
  'geo.continent': string;
  'geo.country': string;
  'geo.country_iso_code': string;
  'geo.country_subdivision': string;
  'os.name': string;
  'os.name/os.version_major': string;
  'os.version': string;
  'os.version_major': string;
  sdk_version: string;
  service: string;
  'session.action.count': string;
  'session.crash.count': string;
  'session.error.count': string;
  'session.frustration.count': string;
  'session.has_replay': string;
  'session.id': string;
  'session.initial_view.name': string;
  'session.initial_view.url_path': string;
  'session.initial_view.url_path_group': string;
  'session.initial_view_url': string;
  'session.is_active': string;
  'session.last_view.name': string;
  'session.last_view.url_path': string;
  'session.last_view.url_path_group': string;
  'session.last_view_url': string;
  'session.plan': string;
  'session.referrer': string;
  'session.time_spent': string;
  'session.type': string;
  'session.view.count': string;
  source: string;
  'synthetics.injected': string;
  'usr.email': string;
  'usr.id': string;
  'usr.id/name': string;
  'usr.name': string;
  version: string;
  'view.referrer_url.referrer_hash': string;
  'view.referrer_url.referrer_host': string;
  'view.referrer_url.referrer_path': string;
};

export type RUMQueryServiceResponse = {
  events: RumEvent[];
  cursor: string;
};

export enum RumTab {
  list = 'list',
  timeseries = 'timeseries',
  geomap = 'geomap',
  performanceMonitoring = 'performance-monitoring',
}

export enum RumErrorTab {
  ERROR = 'error',
  ERROR_GROUP = 'error-group',
}

export enum RumPerformanceSubtab {
  overview = 'overview',
  performance = 'performance',
  errors = 'errors',
  deployments = 'deployments',
  api = 'api',
}

export type RumAggregateResult = {
  data: {
    aggregateTable: {
      dimensions: {
        [key: string]: string;
      };
      aggregates: number[];
    }[];
  };
};

export enum RumEventType {
  SESSION = 'SESSION',
  VIEW = 'VIEW',
  ACTION = 'ACTION',
  ERROR = 'ERROR',
  RESOURCE = 'RESOURCE',
  LONGTASK = 'LONGTASK',
}

export type RumErrorGroup = {
  fingerprint: string;
  service: string;
  errorType: string;
  message: string;
  numOccurrences: number;
  firstOccurrenceError: string;
  lastOccurrenceError: string;
  firstOccurrenceMs: number;
  lastOccurrenceMs: number;
  onFetchErrorGroupingKey?: (errorGroupingKey: string) => void;
  optimizedRowData: RumErrorGroup;
};

export type ApplicationList = {
  name: string;
  id: string;
}[];
