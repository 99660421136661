import { fetchJson } from 'requests';

const deleteGroupById = async ({
  groupId,
}: {
  groupId: number;
}): Promise<Record<string, unknown>> => {
  const options = { method: 'DELETE' };
  const result = await fetchJson(`rbac/groups/${groupId}`, options);

  return result as Record<string, unknown>;
};

export default deleteGroupById;
