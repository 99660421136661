import { DashboardPanelType, DateSelection, TimeSeries } from 'types';
import { SearchState } from 'hooks/useSearch/types';
import { Series } from 'uplot';
import {
  CHART_LINE_WIDTH_MEDIUM,
  fillTimeseriesForWindow,
  getColorForOneSeries,
} from 'utils';
import { sortBy } from 'lodash';

const sortTraceDatasetByAverage = ({
  dataset,
  dataPointLength,
  limitTo,
  limitToValue,
}: {
  dataset: { [key: string]: { [key: string]: number } };
  dataPointLength?: number;
  limitTo: 'top' | 'bottom';
  limitToValue: number;
}) => {
  if (!limitTo && !limitToValue) return Object.keys(dataset);
  const labels = Object.keys(dataset);
  const sortedDatasetByAverage = labels.sort((a, b) => {
    const aValues = Object.values(dataset[a]);
    const bValues = Object.values(dataset[b]);
    const sumA = aValues.reduce((pre, cur) => pre + cur, 0);
    const sumB = bValues.reduce((pre, cur) => pre + cur, 0);

    const comp = sumA / dataPointLength > sumB / dataPointLength;
    if (comp && limitTo === 'top') return -1;
    if (!comp && limitTo === 'top') return 1;
    if (comp && limitTo === 'bottom') return 1;
    if (!comp && limitTo === 'bottom') return -1;
  });
  if (!limitToValue) return sortedDatasetByAverage;
  return sortedDatasetByAverage.slice(0, Number(limitToValue));
};

const getSortedDataSet = (dataset: TimeSeries[]) => {
  try {
    const sortedDataSet = sortBy(dataset, (data) => {
      return [data.BucketStart, JSON.stringify(data.GroupVal)].join('|');
    });
    return sortedDataSet;
  } catch (e) {
    console.error('Error sorting dataset', e);
    return dataset;
  }
};

export const transformTraceTimeseries = ({
  date,
  dataset,
  labelFormatter,
  query,
  step,
  sortBeforeGrouping,
}: {
  date?: DateSelection;
  dataset: TimeSeries[];
  labelFormatter?: (GroupVal: any) => string;
  query: SearchState;
  step?: number;
  sortBeforeGrouping?: boolean;
}) => {
  const { limitTo, limitToValue, queryKey } = query;
  const timestampsBitmap: { [key: string]: boolean } = {};
  const labelBitmap: {
    [key: string]: { [key: string]: number };
  } = {};
  if (dataset.length === 0) {
    return {
      data: [],
      series: [],
      minValue: 0,
      maxValue: 0,
    };
  }
  const sortedDataSet = sortBeforeGrouping
    ? getSortedDataSet(dataset)
    : dataset;

  sortedDataSet.forEach(({ BucketStart, GroupVal, Value }) => {
    const bucketStartSeconds = BucketStart;
    timestampsBitmap[bucketStartSeconds] = true;
    let label = '';
    if (labelFormatter) {
      label = labelFormatter(GroupVal);
    } else {
      const keys = Object.keys(GroupVal);
      label =
        keys
          .map(
            (key) =>
              `${key === 'service_hash' ? 'service' : key}:${GroupVal[key]}`,
          )
          .join(', ') || `count_of_${queryKey}`;
    }
    if (!labelBitmap[label]) {
      labelBitmap[label] = { [bucketStartSeconds]: undefined };
    }

    labelBitmap[label][bucketStartSeconds] = Value;
  });

  const data: number[][] = [];
  const series: Series[] = [];
  let minValue = Infinity;
  let maxValue = -Infinity;
  const timestamps = Object.keys(timestampsBitmap).sort().map(Number);
  let filledTimestamps: number[] = [];
  if (date) {
    filledTimestamps = fillTimeseriesForWindow(date, step, timestampsBitmap);
  } else {
    filledTimestamps = timestamps;
  }
  data.push(filledTimestamps);

  //Sorted Keys returns the same Key Order if limitTo is not set
  const sortedLabelKeys = sortTraceDatasetByAverage({
    dataset: labelBitmap,
    dataPointLength: timestamps.length,
    limitTo,
    limitToValue,
  });

  sortedLabelKeys.forEach((label, idx) => {
    const values = filledTimestamps.map((timestamp) => {
      const value = labelBitmap[label][timestamp];
      if (value > maxValue) maxValue = value;
      if (value < minValue) minValue = value;
      return value || undefined;
    });
    data.push(values);
    series.push({
      label,
      points: { show: false },
      stroke: getColorForOneSeries({}, idx),
      show: true,
      width: CHART_LINE_WIDTH_MEDIUM,
    });
  });

  return { data, series, minValue, maxValue };
};

export const transformTraceInstantQuery = (
  dataset: TimeSeries[],
  query: SearchState,
  resultFormat?: DashboardPanelType,
): Array<any> => {
  const { limitTo, limitToValue, queryKey } = query;
  const timestampsBitmap: { [key: string]: number } = {};
  dataset.forEach(({ BucketStart }) => {
    timestampsBitmap[BucketStart] = 1;
  });
  const latestTimestamp = Object.keys(timestampsBitmap).sort().pop();

  const tableData: Array<{ [key: string]: any }> = [];
  dataset.forEach(({ BucketStart, GroupVal, Value }, idx: number) => {
    if (BucketStart !== Number(latestTimestamp)) return;
    const keys = Object.keys(GroupVal);
    const labels: { [key: string]: string } = {};
    if (keys.length === 0) {
      labels['label'] = `count_of_${queryKey}`;
    } else {
      keys.forEach(
        (key) =>
          (labels[key === 'service_hash' ? 'service' : key] = GroupVal[key]),
      );
    }

    if (resultFormat === DashboardPanelType.TABLE) {
      tableData.push({ ...labels, count: Value });
    } else if (resultFormat === DashboardPanelType.TOP_LIST) {
      tableData.push({ label: Object.values(labels).join(', '), count: Value });
    } else if (resultFormat === DashboardPanelType.PIECHART) {
      tableData.push({
        name: Object.values(labels).join(', '),
        color: getColorForOneSeries({}, idx),
        size: Value,
        value: Value,
        count: Value,
      });
    }
  });

  tableData.sort((a, b) => b.count - a.count);
  if (limitTo === 'top') {
    return tableData.slice(0, Number(limitToValue));
  }

  if (limitTo === 'bottom') {
    return tableData.slice(-Number(limitToValue));
  }
  return tableData;
};
