import { DownloadModal, useModalsContext } from 'components';
import React, { useEffect } from 'react';
import useTracesDownloader from './useTracesDownloader';

const TracesDownloadModal = ({
  columns,
  customerFilter,
  date,
  downloadType,
  selectedFacetRangeByNameState,
  selectedFacetValuesByNameState,
  selectedHcFacetValuesByNameState,
  service,
  spanFilter,
  traceIdSearch,
}: Props) => {
  const modals = useModalsContext();

  const onDone = () => {
    setTimeout(() => {
      modals.pop();
    }, 2000);
  };

  const tracesDownloader = useTracesDownloader({
    columns,
    downloadType,
    onDone,
  });

  useEffect(() => {
    tracesDownloader.fetch({
      date,
      customerFilter,
      selectedFacetRangeByNameState,
      selectedFacetValuesByNameState,
      service,
      spanFilter,
      traceIdSearch,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DownloadModal
      downloader={tracesDownloader}
      modals={modals}
      title="traces"
    />
  );
};

export default TracesDownloadModal;
