import React from 'react';
import JsonIterable from './JsonIterable';
import JsonItemValue from './JsonItemValue';
import { JsonOptions, JsonType } from './types';

type Props = {
  label: string;
  level: number;
  options?: JsonOptions;
  path: string;
  type: JsonType;
  value: any;
};

const JsonItem = ({ label, level, options, path, type, value }: Props) => {
  switch (type) {
    case JsonType.boolean:
    case JsonType.null:
    case JsonType.number:
    case JsonType.string:
      return (
        <JsonItemValue
          label={label}
          level={level + 1}
          options={options}
          path={path}
          type={type}
          value={value}
        />
      );

    case JsonType.array:
    case JsonType.object:
      return (
        <JsonIterable
          label={label}
          level={level + 1}
          options={options}
          path={path}
          type={type}
          value={value}
        />
      );
    default:
      return null;
  }
};

export default JsonItem;
