import {
  Filter,
  FilterType,
  Operator,
  SelectedFacetValueFilter,
} from './types';

type Args = {
  facetName: string;
  filters: Filter[];
  operator: Operator;
  replace: boolean;
};

const getExistingFilterWithSameFacet = ({
  facetName,
  filters,
  operator,
  replace,
}: Args) => {
  const existingFilterWithSameFacetIndex = filters.findIndex(
    (filter) =>
      filter.type === FilterType.selectedFacetValue &&
      filter.value.facet === facetName &&
      (filter.value.operator === Operator.equal ||
        filter.value.operator === Operator.notEqual),
  );

  const shouldReplaceExistingFilter =
    !replace && existingFilterWithSameFacetIndex > -1;

  const existingFilter = shouldReplaceExistingFilter
    ? (filters[existingFilterWithSameFacetIndex] as SelectedFacetValueFilter)
    : null;

  const existingFilterValues =
    existingFilter && existingFilter.value.operator === operator
      ? existingFilter.value.values
      : {};

  return {
    existingFilterWithSameFacetIndex,
    existingFilterValues,
    shouldReplaceExistingFilter,
  };
};

export default getExistingFilterWithSameFacet;
