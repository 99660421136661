import dayjs from 'dayjs';
import {
  FacetRegexTerm,
  KeyExists,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  SpanFilter,
  Trace,
} from 'types';
import { onPromiseError } from 'utils';
import queryTraceService from './queryTraceService';
import { buildTracesFilter } from './utils';

type Args = {
  additionalFilterByFacets?: SelectedFacetValuesByName;
  customerFilter?: { key: string; value: string };
  endTimeMs: number;
  startTimeMs: number;
  includeSpan?: boolean;
  keyExists: KeyExists;
  limit: number;
  pageNum?: number;
  facetRegex: FacetRegexTerm[];
  functionName?: string;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  selectedHcFacetValuesByName: SelectedFacetValuesByName;
  sortBy?: string;
  sortOrder?: string;
  spanFilter?: SpanFilter;
  ParentSpanIdFilter?: string;
  traceIdSearch: string;
};

const getTraces = async ({
  additionalFilterByFacets,
  customerFilter,
  endTimeMs,
  startTimeMs,
  pageNum = 1,
  includeSpan,
  keyExists,
  limit,
  facetRegex,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  selectedHcFacetValuesByName,
  sortBy,
  sortOrder,
  ParentSpanIdFilter,
  spanFilter,
  traceIdSearch,
}: Args): Promise<Trace[]> => {
  const endTime = dayjs(endTimeMs);
  const durationSecs = Math.round((endTimeMs - startTimeMs) / 1000);

  return queryTraceService<Trace[], 'traces'>(`
    {
      traces (
        durationSecs: ${durationSecs},
        filter: ${buildTracesFilter({
          additionalFilterByFacets,
          customerFilter,
          facetRegex,
          keyExists,
          selectedFacetRangeByName,
          selectedFacetValuesByName,
          selectedHcFacetValuesByName,
          parentSpanIdFilter: ParentSpanIdFilter,
          spanFilter,
          traceIdSearch,
        })}
        limit: ${limit}
        pageNum: ${pageNum}
        timestamp: "${endTime.format()}",
        ${sortBy ? `sortField: "${sortBy}",` : ''}
        ${sortOrder ? `sortOrder: ${sortOrder},` : ''}
      ) {
        traceId
        span {
          spanId
          parentSpanId
          startTimeNs
          endTimeNs
          attributes
          durationNs
          name
          service {
            name
            labels
            hash
            distinctLabels
          }
          statusCode
          method
          endpoint
          rootSpan
          ${includeSpan ? 'span' : ''}
        }
        traceMetrics {
          spanCount
          serviceExecTimeNs
        }
      }
    }
  `).then((data) => data.traces || [], onPromiseError);
};

export default getTraces;
