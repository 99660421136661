import { FilterType, TermsFilter } from './types';
import useFiltersState from './useFiltersState';

type TermsFilterType = FilterType.searchTerms;

type GetTermsFilterArgs = {
  filterType: TermsFilterType;
  value: string;
};

const getTermsFilter = ({ filterType, value }: GetTermsFilterArgs) => ({
  disabled: false,
  type: filterType,
  value,
});

const getState = (
  state: ReturnType<typeof useFiltersState>['state'],
  filterType: TermsFilterType,
) => {
  const termsFilter = state.filter(
    (filter) => filter.type === filterType,
  ) as TermsFilter[];

  return termsFilter.map((filter) => filter.value);
};

type Args = {
  filtersState: ReturnType<typeof useFiltersState>;
  filterType: TermsFilterType;
};

const getTermsState = ({ filtersState, filterType }: Args) => {
  const { setState, state } = filtersState;

  const add = (value: string) => {
    setState((prevState) => [
      ...prevState,
      getTermsFilter({ filterType, value }),
    ]);
  };

  const removeByIndex = (index: number) => {
    setState((prevState) => {
      const prevTermsState = prevState.filter(
        (filter) => filter.type === filterType,
      );
      const prevTermsFilter = prevTermsState[index];
      if (prevTermsFilter) {
        return prevState.filter((filter) => filter !== prevTermsFilter);
      }

      return prevState;
    });
  };

  return {
    add,
    removeByIndex,
    state: getState(state, filterType),
  };
};

export default getTermsState;
