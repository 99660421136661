import { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getDateFromRange } from 'screens/Dashboard/utils';
import { LabelCardinalityQueryProps } from 'screens/LogsCardinality/types';
import { extractPathNameFromURL, parseUrlParamByKey } from 'utils';

const defaultLogsCardinalityQuery: LabelCardinalityQueryProps = {
  labels: ['=""'],
  limit: { count: '10', direction: 'topk' },
};

const useLogsCardinalityPageState = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dateState = useState(
    parseUrlParamByKey('logsCardinalityDate') ||
      getDateFromRange('now-5m', 'now'),
  );
  const logsCardinalityQueryState = useState<LabelCardinalityQueryProps>(
    parseUrlParamByKey('logsCardinalityQuery') || defaultLogsCardinalityQuery,
  );

  // Get dependencies for determining when a url change should happen
  const dependenciesForWriteStateToUrl = useMemo(
    () => [dateState[0], logsCardinalityQueryState[0]],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateState[0], logsCardinalityQueryState[0]],
  );

  // Should be called on state change when on the LogsCardinality page
  const writeStateToUrl = () => {
    params.set('logsCardinalityDate', JSON.stringify(dateState[0]));
    params.set(
      'logsCardinalityQuery',
      JSON.stringify(logsCardinalityQueryState[0]),
    );
    const navigateUrl = `${extractPathNameFromURL()}?${params.toString()}`;
    navigate(navigateUrl, { replace: true });
  };

  return {
    logsCardinalityQueryState,
    dateState,
    dependenciesForWriteStateToUrl,
    writeStateToUrl,
  };
};

export default useLogsCardinalityPageState;
