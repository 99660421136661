export const numericOperatorBitmap: Record<string, number> = {
  '>=': 1,
  '>': 1,
  '<=': 1,
  '<': 1,
};

export const limitTypeByOperator: Record<string, 'lower' | 'upper'> = {
  '>=': 'lower',
  '>': 'lower',
  '<=': 'upper',
  '<': 'upper',
};

export const isInclusiveByOperator: Record<string, boolean> = {
  '>=': true,
  '>': false,
  '<=': true,
  '<': false,
};

export const inverseByOperator: Record<string, string> = {
  '>=': '<=',
  '>': '<',
  '<=': '>=',
  '<': '>',
};
