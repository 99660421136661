import { formatDurationNs } from 'utils';

const formatLatencyYAxisTick = (n: number) => {
  if (n >= 0) {
    return formatDurationNs(n * 1000000, 1, 1);
  }

  return `-${formatDurationNs(Math.abs(n * 1000000), 1, 1)}ms`;
};

export default formatLatencyYAxisTick;
