import { useRequest } from 'hooks';
import React, { useEffect, useState } from 'react';

import { getUserList } from './requests';
import UserManagementTable from './UserManagementTable';
import { userManagementError } from 'utils/error/userManagementError';
import ErrorMessage from 'components/ErrorMessage';

const UserManagement = () => {
  const [error, setError] = useState({
    getUserList: null,
  });
  const userListRequest = useRequest(getUserList, true, true);

  useEffect(() => {
    userListRequest
      .call()
      .then((nextResult) => {
        if (nextResult) {
          setError((prevError) => ({ ...prevError, getUserList: null }));
        }
      })
      .catch(() => {
        setError((prevError) => ({
          ...prevError,
          getUserList: { message: userManagementError.getUserList },
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mx-auto max-w-[1200px] py-4">
      <div className="alerts__contacts__list__header flex justify-between items-center">
        <h2 className="text-lg font-semibold whitespace-nowrap">
          User Management
        </h2>
        <ErrorMessage
          message={error?.getUserList?.message}
          className="justify-end pr-[14px]"
        />
      </div>
      <UserManagementTable userListRequest={userListRequest} />
    </div>
  );
};

export default UserManagement;
