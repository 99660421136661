import { useToaster } from 'components';
import { useRequest } from 'hooks';
import { useState } from 'react';
import {
  getGrafanaAlertManager,
  mutateGrafanaContactPoints,
  getGrafanaAlertsRuleByGroup,
} from 'requests';

import { ReceiverManagerConfigProps } from '../types';
import { AlertRulerByGroup } from 'screens/NewAlerts/types';

const useAlertsContactsDelete = () => {
  const { addToast } = useToaster();
  const [isDeleting, setIsDeleting] = useState(false);
  const grafanaAlertManagerRequest = useRequest(getGrafanaAlertManager);
  const grafanaAlertsRuleByGroupRequest = useRequest(
    getGrafanaAlertsRuleByGroup,
  );
  const mutateContactPointsRequest = useRequest(mutateGrafanaContactPoints);

  const checkContactPointUsed = (names: string[]) => {
    return new Promise<void>((resolve, reject) => {
      grafanaAlertsRuleByGroupRequest
        .call()
        .then((result: AlertRulerByGroup) => {
          const folderList = Object.keys(result);
          if (folderList.length === 0) {
            resolve();
            return;
          }

          for (const folder of folderList) {
            result[folder].forEach((rule) => {
              rule.rules.forEach((rule) => {
                if (names.some((name) => rule.labels && rule.labels[name])) {
                  const usedName = names.find(
                    (name) => rule.labels && rule.labels[name],
                  );
                  reject(
                    new Error(`Contact point ${usedName} is used in a rule`),
                  );
                  return;
                }
              });
            });
          }
          resolve();
        })
        .catch(() => reject());
    });
  };

  const getContactPointsDeletePayload = ({
    names,
    receivers,
  }: {
    names: string[];
    receivers: ReceiverManagerConfigProps['alertmanager_config']['receivers'];
  }) => {
    const newReceivers = [...receivers];
    receivers.forEach(({ grafana_managed_receiver_configs }, idx: number) => {
      const contactPointIdx = grafana_managed_receiver_configs.findIndex(
        (contactPoint) => names.includes(contactPoint.name),
      );
      if (contactPointIdx !== -1) {
        newReceivers[idx].grafana_managed_receiver_configs.splice(
          contactPointIdx,
          1,
        );
      }
    });

    // remove empty receivers
    return newReceivers.filter(
      (receiver) => receiver.grafana_managed_receiver_configs.length > 0,
    );
  };

  const deleteContactPoint = (name: string) => {
    setIsDeleting(true);
    checkContactPointUsed([name])
      .then(() => {
        grafanaAlertManagerRequest
          .call()
          .then((result: ReceiverManagerConfigProps) => {
            const alertManager = result.alertmanager_config;
            alertManager.receivers = getContactPointsDeletePayload({
              names: [name],
              receivers: alertManager.receivers,
            });
            const routeIndex = alertManager.route.routes.findIndex(
              (route) => route.receiver === name,
            );
            if (routeIndex !== -1) {
              alertManager.route.routes.splice(routeIndex, 1);
            }
            const successMessage = `Contact point ${name} was successfully deleted`;
            mutateContactPointsRequest
              .call({ alertmanager_config: alertManager })
              .then(() => {
                addToast({ text: successMessage, status: 'success' });
                setIsDeleting(false);
              })
              .catch(() => setIsDeleting(false));
          })
          .catch(() => setIsDeleting(false));
      })
      .catch((err) => {
        setIsDeleting(false);
        addToast({ text: err.message, status: 'error', timeout: 5000 });
      });
  };

  const bulkDeleteContactPoints = (names: string[]) => {
    return new Promise((resolve, reject) => {
      setIsDeleting(true);
      checkContactPointUsed(names)
        .then(() => {
          grafanaAlertManagerRequest
            .call()
            .then((result: ReceiverManagerConfigProps) => {
              const alertManager = result.alertmanager_config;
              alertManager.receivers = getContactPointsDeletePayload({
                names,
                receivers: alertManager.receivers,
              });
              alertManager.route.routes = alertManager.route.routes.filter(
                (route) => !names.includes(route.receiver),
              );
              const successMessage = `Contact points ${names.join(', ')} were successfully deleted`;
              mutateContactPointsRequest
                .call({ alertmanager_config: alertManager })
                .then(() => {
                  addToast({ text: successMessage, status: 'success' });
                  setIsDeleting(false);
                  resolve(true);
                })
                .catch(() => setIsDeleting(false));
            })
            .catch(() => setIsDeleting(false));
        })
        .catch((err) => {
          setIsDeleting(false);
          addToast({ text: err.message, status: 'error', timeout: 5000 });
        });
    });
  };

  return {
    bulkDeleteContactPoints,
    deleteContactPoint,
    isDeleting,
    mutateContactPointsRequest,
  };
};

export default useAlertsContactsDelete;
