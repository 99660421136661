import React, { ComponentPropsWithoutRef } from 'react';
import * as Shadcn from '../shadcn';

type Props = ComponentPropsWithoutRef<typeof Shadcn.Button> & {
  className?: string;
  variant: Shadcn.ButtonProps['variant'];
};

const getShadcnVariant = (variant: Props['variant']) => {
  if (variant === 'icon') {
    return 'ghost';
  }

  return variant;
};

const Button = ({ className, variant, ...rest }: Props) => {
  return (
    <Shadcn.Button
      className={className}
      variant={getShadcnVariant(variant)}
      {...rest}
    />
  );
};

export default Button;
