import { AutocompleteOption } from 'components';
import { CoreLabels, delimiter } from 'kfuse-constants';
import {
  FacetBase,
  QueryDataProps,
  NormalizeFunction,
  RangeAggregate,
  RangeAggregatesForCount,
  VectorAggregate,
  LogsMetricQueryProps,
  FavoriteFacet,
  QueryLangType,
} from 'types';
import { Series } from 'uplot';
import { getColorForOneSeries, getIsLogRangeFacet, isSourceLabel } from '..';
import { FINGERPRINT_KEY } from 'utils/LogqlBuilder';
import { removeDuplicateByKey } from 'utils';
import { KfuseQlFunction } from 'utils/KfuseqlBuilder/types';

export const getFacetNamesOptionsForAlert = ({
  facets,
  labelOptions,
  excludeLF,
}: {
  facets: FavoriteFacet[];
  labelOptions: AutocompleteOption[];
  excludeLF?: boolean;
}): AutocompleteOption[] => {
  const facetNamesOptions: AutocompleteOption[] = [];

  const facetsOptions = facets.map((facet) => {
    const { displayName, group, name, source, type } = facet;
    const displayNameWithSource = group
      ? `${group}:${displayName}`
      : displayName;
    const label = `@${displayNameWithSource || name}`;
    const value = `@${name}:${source}${delimiter}${type}${delimiter}${displayNameWithSource}`;

    return { label, value };
  });

  const uniqueFacetNamesOptions = removeDuplicateByKey(facetsOptions, 'value');
  if (!excludeLF) {
    facetNamesOptions.push({ label: 'all logs', value: '*' });
    facetNamesOptions.push({
      label: 'all fingerprints',
      value: FINGERPRINT_KEY,
    });
  }
  facetNamesOptions.push(...labelOptions, ...uniqueFacetNamesOptions);

  return facetNamesOptions;
};

export const removeLabelFromFacetNames = (
  facetOptions: AutocompleteOption[],
): AutocompleteOption[] => {
  const labelPrefix = ['Core:', 'Cloud:', 'Kubernetes', 'Additional'];
  const labelFullname = ['all logs', 'all fingerprints'];
  return facetOptions.filter((option) => {
    const label = option.label as string;
    return !labelPrefix.some((prefix) => label.startsWith(prefix)) &&
      !labelFullname.includes(label)
      ? option
      : null;
  });
};

export const getFilteredLabelOptions = (
  labelOptions: AutocompleteOption[],
  search: string,
): AutocompleteOption[] => {
  const filteredLabelOptions = labelOptions.filter((option) => {
    const label = option.label as string;
    return label.toLowerCase().includes(search.toLowerCase());
  });

  filteredLabelOptions.unshift({
    label: 'all fingerprints',
    value: FINGERPRINT_KEY,
  });
  filteredLabelOptions.unshift({ label: 'all logs', value: '*' });
  return filteredLabelOptions;
};

export const getRangeAggregateOptions = (
  metric: string,
): AutocompleteOption[] => {
  const options: AutocompleteOption[] = [];

  Object.keys(RangeAggregate).map((key) => {
    if (metric === '*') {
      if (key in RangeAggregatesForCount) {
        options.push({ label: key, value: key });
      }
    } else if (key in RangeAggregate) {
      if (key in RangeAggregatesForCount) {
        return;
      }
      options.push({ label: key, value: key });
    }
  });
  return options;
};

export const getVectorAggregateOptions = (
  metric: string,
): AutocompleteOption[] => {
  const parsedFacet = metric !== '*' ? metric.split(delimiter) : '';
  const vectorAggregateOptions: AutocompleteOption[] = [];
  const vectorAggregateKeys = Object.keys(VectorAggregate).filter(
    (key) => key !== 'count',
  );

  if (metric === '*') {
    vectorAggregateKeys.push('count');
  }

  if (metric !== '*' && parsedFacet[1]?.toLowerCase() === 'string') {
    vectorAggregateKeys.push('count');
  }

  vectorAggregateKeys.map((key) => {
    vectorAggregateOptions.push({ label: key, value: key });
  });

  return vectorAggregateOptions;
};

export const normalizeFunctionOptions: AutocompleteOption[] = [
  { label: 'bytes', value: NormalizeFunction.Bytes },
  { label: 'duration', value: NormalizeFunction.Duration },
  { label: 'number', value: 'number' },
];

export const getLabelAndFacetGroupingOptions = (
  facetNamesOptions: AutocompleteOption[],
): AutocompleteOption[] => {
  const sourceOptionsAt: AutocompleteOption[] = [];

  facetNamesOptions.map((option) => {
    if (option.label === 'all logs' || option.label === 'all fingerprints')
      return;
    sourceOptionsAt.push(option);
  });

  return sourceOptionsAt;
};

export const getLabelAndFacetWithDelimiter = (
  labelAndFacet: string[],
): string[] => {
  return labelAndFacet.map((label) => {
    if (label.startsWith('@')) {
      const labelWithoutAt = label.replace('@', '');
      const [source, name] = labelWithoutAt.split(':');
      return `${source}${delimiter}${name}${delimiter}string`;
    }
    return label;
  });
};

export const combineLogsChartData = (
  dataset: QueryDataProps,
  queries: { queryKey: string; isActive: boolean }[],
  formulas: { queryKey: string; isActive: boolean }[],
) => {
  if (!dataset) return;
  const chartKeys = Object.keys(dataset);
  let newData: Array<number[]> = [];
  let newMaxValue = 0;
  let newMinValue = Infinity;
  const newSeries: Series[] = [];
  let isLoading = false;

  chartKeys.map((key, chartIndex) => {
    if (!dataset[key]) return;
    if (dataset[key].isLoading) {
      isLoading = true;
    }
    if (!dataset[key].range?.data) return;
    const [type, queryKey] = key.split('_');
    let query = null;

    if (type === 'query') {
      query = queries.find((q) => q.queryKey === queryKey);
    }
    if (type === 'formula') {
      query = formulas.find((q) => q.queryKey === queryKey);
    }
    if (!query || !query.isActive) return;

    const { data, maxValue, minValue, series } = dataset[key].range;
    if (newData.length === 0) {
      newData = [...newData, ...data];
    } else {
      data.forEach((value: number[], index: number) => {
        if (index !== 0) newData.push(value);
      });
    }
    newMaxValue = Math.max(newMaxValue, maxValue);
    newMinValue = Math.min(newMinValue, minValue);
    series.forEach((s: Series, idx: number) => {
      newSeries.push({
        ...s,
        stroke: getColorForOneSeries({}, chartIndex, idx),
      });
    });
  });

  return {
    data: newData,
    isLoading,
    maxValue: newMaxValue,
    minValue: newMinValue === Infinity ? 0 : newMinValue,
    series: newSeries,
  };
};

export const combineLogsChartDataInstant = (
  dataset: QueryDataProps,
  queries: { queryKey: string; isActive: boolean }[],
  formulas: { queryKey: string; isActive: boolean }[],
) => {
  if (!dataset) return;
  const chartKeys = Object.keys(dataset);
  let newData: Array<any> = [];
  let newMaxValue = 0;
  let newMinValue = Infinity;
  let isLoading = false;
  const uniqueLabels: string[] = [];

  chartKeys.map((key) => {
    if (!dataset[key]) return;
    if (dataset[key].isLoading) {
      isLoading = true;
    }

    if (!dataset[key].instant?.data) return;
    const [type, queryKey] = key.split('_');
    let query = null;

    if (type === 'query') {
      query = queries.find((q) => q.queryKey === queryKey);
    }
    if (type === 'formula') {
      query = formulas.find((q) => q.queryKey === queryKey);
    }

    if (!query || !query.isActive) return;

    const { data, minValue, maxValue, labels } = dataset[key].instant;
    newData = [...newData, ...data];
    newMaxValue = Math.max(newMaxValue, maxValue);
    newMinValue = Math.min(newMinValue, minValue);
    uniqueLabels.push(...(labels || []));
  });

  return {
    data: newData,
    maxValue: newMaxValue,
    minValue: newMinValue === Infinity ? 0 : newMinValue,
    isLoading,
    labels: uniqueLabels,
  };
};

export const getLabelWithDelimiterAndType = (labelsBitmap: {
  [key: string]: FacetBase[];
}): AutocompleteOption[] => {
  // check if core exists
  const newLabelsBitmap = { ...labelsBitmap };
  if (!newLabelsBitmap['core']) {
    newLabelsBitmap['core'] = CoreLabels;
  }

  const order = [
    'core',
    'cloud',
    'cloudLabels',
    'kubernetes',
    'kubernetesLabels',
    'additional',
    'additionalLabels',
  ];
  const labelWithDelimiterAndType: AutocompleteOption[] = [];
  const sortedComponent = Object.keys(newLabelsBitmap).sort(
    (a, b) => order.indexOf(a) - order.indexOf(b),
  );
  sortedComponent.map((key) => {
    newLabelsBitmap[key].map((label) => {
      labelWithDelimiterAndType.push({
        label: `${label.component}:${label.name}`,
        value: `${label.component}${delimiter}${label.name}${delimiter}${label.type}`,
      });
    });
  });

  return labelWithDelimiterAndType;
};

export const checkLogsMetricType = (metric: string): string => {
  if (metric === '*') {
    return 'logs';
  }

  if (metric === FINGERPRINT_KEY) {
    return 'fp';
  }

  const isLabel = isSourceLabel(metric);
  if (isLabel) {
    return 'label';
  }

  if (metric.startsWith('@')) {
    return 'facet';
  }
};

export const getVectorAggrText = ({
  query,
  selectedVal,
}: {
  query: LogsMetricQueryProps;
  selectedVal: string;
}): string => {
  const metricType = checkLogsMetricType(query.metric);
  if (metricType === 'logs' || metricType === 'fp') return 'count of';
  if (metricType === 'label') return 'count unique of';
  if (metricType === 'facet') {
    const [name, type] = query.metric.split(delimiter);
    const isNumeric = getIsLogRangeFacet(type || '');
    if (name && !isNumeric) {
      return 'count unique of';
    }
  }
  return `${selectedVal} of`;
};

export const getVectorAggrLegend = ({
  query,
  selectedVal,
}: {
  query: LogsMetricQueryProps;
  selectedVal: string;
}): string => {
  const metricType = checkLogsMetricType(query.metric);
  if (metricType === 'logs' || metricType === 'fp') return 'count';
  if (metricType === 'label') return 'count_unique';
  if (metricType === 'facet') {
    const [name, type] = query.metric.split(delimiter);
    if (name && type.toLocaleLowerCase() === 'string') {
      return 'count_unique';
    }
  }
  return `${selectedVal}`;
};

export const hasQueryAdvanceFunction = (
  query: LogsMetricQueryProps,
  queryLangType: string,
): { isAnomaly: boolean; isForecast: boolean; isOutlier: boolean } => {
  if (queryLangType === QueryLangType.LOGQL) {
    return { isAnomaly: false, isForecast: false, isOutlier: false };
  }
  const isAnomaly = query.functions?.some(
    (func) => func.name === KfuseQlFunction.ANOMALIES,
  );
  const isForecast = query.functions?.some(
    (func) => func.name === KfuseQlFunction.FORECAST,
  );
  const isOutlier = query.functions?.some(
    (func) => func.name === KfuseQlFunction.OUTLIERS,
  );
  return { isAnomaly, isForecast, isOutlier };
};

export const hasQueryAdvanceFunctionMultiple = (
  queries: LogsMetricQueryProps[],
  queryLangType: string,
): boolean => {
  return queries.some((query) => {
    const { isAnomaly, isForecast, isOutlier } = hasQueryAdvanceFunction(
      query,
      queryLangType,
    );
    return isAnomaly || isForecast || isOutlier;
  });
};

export const hasQueryAdvanceFunctionCheckForAlert = (
  queries: LogsMetricQueryProps[],
  queryLangType: string,
): boolean => {
  const DISABLE_ALERT_FOR_ANOMALY_ALGO = ['agile-robust', 'robust'];
  return queries.some((query) => {
    const { isAnomaly } = hasQueryAdvanceFunction(query, queryLangType);
    if (isAnomaly) {
      const anomalyFunc = query.functions?.find(
        (func) => func.name === KfuseQlFunction.ANOMALIES,
      );
      const algo = anomalyFunc?.params[0];
      if (algo && DISABLE_ALERT_FOR_ANOMALY_ALGO.includes(algo.value)) {
        return true;
      }
      return false;
    }
    return false;
  });
};
