import React, { ReactElement } from 'react';
import { LegendTypes } from 'types';

import { TimeseriesAnnotation } from './TimeseriesAnnotation';
import {
  LegendsAggregate,
  LegendsCompact,
  LegendsValue,
  Legends,
  LegendsCompactOneLine,
} from './Legends';
import Timeseries from './Timeseries';
import { TooltipCompact } from './Tooltip';
import {
  TimeseriesRendererProps,
  TimeseriesToolProps,
  TooltipTypes,
} from './types';
import useTimeseries from './useTimeseries';

const getLegend = ({
  config,
  data,
  legendType,
  legendHeight,
  onLegendItemClick,
  onFocusSeries,
  unit,
  updateChartSize,
}: TimeseriesToolProps & {
  legendType?: LegendTypes;
  legendHeight?: number;
}) => {
  if (!legendType) {
    return null;
  }
  if (legendType === LegendTypes.AGGREGATE) {
    return (
      <LegendsAggregate
        config={config}
        data={data}
        onItemClick={onLegendItemClick}
        onFocusSeries={onFocusSeries}
        unit={unit}
      />
    );
  }
  if (legendType === LegendTypes.VALUES) {
    return (
      <LegendsValue
        config={config}
        data={data}
        onItemClick={onLegendItemClick}
        onFocusSeries={onFocusSeries}
        unit={unit}
      />
    );
  }
  if (legendType === LegendTypes.SIMPLE) {
    return <Legends config={config} onItemClick={onLegendItemClick} />;
  }
  if (legendType === LegendTypes.COMPACT) {
    return (
      <LegendsCompact
        config={config}
        onItemClick={onLegendItemClick}
        onFocusSeries={onFocusSeries}
        legendHeight={legendHeight}
        updateChartSize={updateChartSize}
      />
    );
  }
  if (legendType === LegendTypes.COMPACT_ONE_LINE) {
    return (
      <LegendsCompactOneLine
        config={config}
        onItemClick={onLegendItemClick}
        onFocusSeries={onFocusSeries}
        legendHeight={legendHeight}
        updateChartSize={updateChartSize}
      />
    );
  }

  return null;
};

const getTooltip = ({
  activeChart,
  config,
  cursorState,
  onCursorStateChange,
  tooltipType,
  unit,
  ...rest
}: TimeseriesToolProps & {
  tooltipType?: TooltipTypes;
}) => {
  if (tooltipType === 'compact') {
    return (
      <TooltipCompact
        chartType={activeChart}
        config={config}
        cursorState={cursorState}
        onCursorStateChange={onCursorStateChange}
        unit={unit}
        {...rest}
      />
    );
  }
  return null;
};

const getTimeseriesAnnotation = ({ config }: TimeseriesToolProps) => {
  return <TimeseriesAnnotation config={config} />;
};

const TimeseriesRenderer = ({
  legend = {},
  renderLegend,
  renderTooltip,
  tooltipType,
  error,
  ...rest
}: TimeseriesRendererProps): ReactElement => {
  const timeseries = useTimeseries(rest);

  return (
    <Timeseries
      {...rest}
      timeseries={timeseries}
      renderLegend={(prop) => {
        if (legend?.legendType) return getLegend({ ...prop, ...legend });
        if (renderLegend) return renderLegend(prop);
        return null;
      }}
      renderTooltip={(prop) => {
        if (tooltipType) return getTooltip({ ...prop, tooltipType });
        if (renderTooltip) return renderTooltip(prop);
        return null;
      }}
      renderAnnotation={(prop) => getTimeseriesAnnotation(prop)}
      error={error}
    />
  );
};
export default TimeseriesRenderer;
