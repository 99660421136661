import { groupByFacetName } from 'kfuse-constants';

const ungroupedFacetNamesBitmap = {
  component: 1,
  database: 1,
  error: 1,
  service_name: 1,
  span_name: 1,
  span_type: 1,
  service_hash: 1,
};

type FacetNamesByGroup = { [key: string]: string[] };

type Result = {
  facetNamesByGroup: FacetNamesByGroup;
  ungrouped: string[];
};

const groupTraceLabels = (result: string[]): Result => {
  const ungrouped: string[] = [];

  const facetNamesByGroup: FacetNamesByGroup = {};

  result.forEach((name) => {
    if (ungroupedFacetNamesBitmap[name]) {
      ungrouped.push(name);
    } else {
      const parts = name.split('_');
      if (parts.length === 1) {
        const group = groupByFacetName[name] || 'custom';
        if (!facetNamesByGroup[group]) {
          facetNamesByGroup[group] = [];
        }

        facetNamesByGroup[group].push(name);
      } else {
        const nameFoundInGroupByFacetName = groupByFacetName[name];
        if (nameFoundInGroupByFacetName) {
          if (!facetNamesByGroup[nameFoundInGroupByFacetName]) {
            facetNamesByGroup[nameFoundInGroupByFacetName] = [];
          }

          facetNamesByGroup[nameFoundInGroupByFacetName].push(name);
        } else {
          const [group] = parts;
          if (!facetNamesByGroup[group]) {
            facetNamesByGroup[group] = [];
          }

          facetNamesByGroup[group].push(name);
        }
      }
    }
  });

  return {
    facetNamesByGroup: Object.keys(facetNamesByGroup).reduce(
      (obj, group) => ({
        ...obj,
        [group]: facetNamesByGroup[group].sort(),
      }),
      {},
    ),
    ungrouped: ungrouped.sort(),
  };
};

export default groupTraceLabels;
