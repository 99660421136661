import dayjs from 'dayjs';
import {
  DateSelection,
  LatencyDistribution,
  SelectedFacetValuesByName,
} from 'types';
import { onPromiseError } from 'utils';
import queryTraceService from './queryTraceService';
import { buildTracesFilter } from './utils';

type Args = {
  date: DateSelection;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  serviceHash: string;
  width: number;
};

const getLatencyDistribution = async ({
  date,
  selectedFacetValuesByName,
  serviceHash,
  width,
}: Args): Promise<LatencyDistribution> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const numBuckets = Math.floor(width / 10);

  const queryFilterString = buildTracesFilter({
    selectedFacetValuesByName,
  });

  return queryTraceService<LatencyDistribution, 'serviceLatencyDistribution'>(`
    {
      serviceLatencyDistribution(
        durationSecs: ${durationSecs}
        filter: ${queryFilterString}
        service: {
          hash: "${serviceHash}"
        }
        numBuckets: ${numBuckets}
        maxType: "max"
        timestamp: "${endTime.format()}"
      ) {
        buckets {
          bucketStart
          count
        }
        percentiles {
          max
          p50
          p90
          p99
        }
      }
    }
  `).then(
    (data) => ({
      ...(data.serviceLatencyDistribution || {
        buckets: [],
        percentiles: { max: 0, p99: 0, p95: 0, p90: 0, p75: 0, p50: 0 },
      }),
      durationSecs,
      numBuckets,
    }),
    onPromiseError,
  );
};

export default getLatencyDistribution;
