import { useRequest } from 'hooks';
import { useEffect, useMemo } from 'react';
import { promqlQuery } from 'requests';
import { SLOProps } from 'types/SLO';
import { SLOQueryTemplates } from '../types';
import { transformSLOPromql } from 'utils/slos';

const useSloMetricsTableData = ({
  sloData,
  queryTemplatesForSLOType,
}: {
  sloData: SLOProps;
  queryTemplatesForSLOType: SLOQueryTemplates;
}) => {
  const errorBudgetData = useRequest(promqlQuery, true, true);
  const statusData = useRequest(promqlQuery, true, true);
  const badEventsData = useRequest(promqlQuery, true, true);
  const totalEventsData = useRequest(promqlQuery, true, true);
  const goodEventsData = useRequest(promqlQuery, true, true);

  useEffect(() => {
    const errorBudgetQuery = queryTemplatesForSLOType.sloBudgetRemaining;
    const errorBudgetQueryWithAggregate = `${queryTemplatesForSLOType.sloAggregateBy}(${errorBudgetQuery})`;
    const statusQuery = queryTemplatesForSLOType.sloStatusQuery;
    const statusQueryWithAggregate = `${queryTemplatesForSLOType.sloAggregateBy}(${statusQuery})`;

    const transformedStatusQuery = transformSLOPromql({
      promql: statusQueryWithAggregate,
      slo: sloData,
      window: '30d',
    });

    const transformedErrorBudgetQuery = transformSLOPromql({
      promql: errorBudgetQueryWithAggregate,
      slo: sloData,
      window: '30d',
    });

    const goodEventsPromql = transformSLOPromql({
      promql: sloData.goodEventsSLIQuery,
      slo: sloData,
      window: '30d',
    });

    const totalEventsPromql = transformSLOPromql({
      promql: sloData.totalEventsSLIQuery,
      slo: sloData,
      window: '30d',
    });

    const badEventsPromql = transformSLOPromql({
      promql: `(${totalEventsPromql}) - (${goodEventsPromql})`,
      slo: sloData,
      window: '30d',
    });

    errorBudgetData.call({
      promqlQueries: [transformedErrorBudgetQuery],
    });
    statusData.call({
      promqlQueries: [transformedStatusQuery],
    });
    badEventsData.call({
      promqlQueries: [badEventsPromql],
    });
    totalEventsData.call({
      promqlQueries: [totalEventsPromql],
    });
    goodEventsData.call({
      promqlQueries: [goodEventsPromql],
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryTemplatesForSLOType]);

  const rows = useMemo(() => {
    const groupHashedRows = {} as Record<string, any>;

    if (badEventsData.result) {
      badEventsData.result.forEach((result: { metric: any; value: any[] }) => {
        const group = JSON.stringify(result.metric);
        groupHashedRows[group] = {
          group,
          badEvents: result.value[1],
        };
      });
    }

    if (goodEventsData.result) {
      goodEventsData.result.forEach((result: { metric: any; value: any[] }) => {
        const group = JSON.stringify(result.metric);
        groupHashedRows[group] = {
          ...groupHashedRows[group],
          goodEvents: result.value[1],
        };
      });
    }

    if (totalEventsData.result) {
      totalEventsData.result.forEach(
        (result: { metric: any; value: any[] }) => {
          const group = JSON.stringify(result.metric);
          groupHashedRows[group] = {
            ...groupHashedRows[group],
            totalEvents: result.value[1],
          };
        },
      );
    }

    if (errorBudgetData.result) {
      errorBudgetData.result.forEach(
        (result: { metric: any; value: any[] }) => {
          const group = JSON.stringify(result.metric);
          groupHashedRows[group] = {
            ...groupHashedRows[group],
            errorBudget: result.value[1],
          };
        },
      );
    }

    if (statusData.result) {
      statusData.result.forEach((result: { metric: any; value: any[] }) => {
        const group = JSON.stringify(result.metric);
        groupHashedRows[group] = {
          ...groupHashedRows[group],
          status: result.value[1],
        };
      });
    }

    const rows = Object.values(groupHashedRows);

    return rows;
  }, [
    errorBudgetData.result,
    statusData.result,
    badEventsData.result,
    totalEventsData.result,
    goodEventsData.result,
  ]);

  const isRequestLoading =
    errorBudgetData.isLoading ||
    statusData.isLoading ||
    badEventsData.isLoading ||
    totalEventsData.isLoading ||
    goodEventsData.isLoading;

  return {
    rows,
    isRequestLoading,
  };
};

export default useSloMetricsTableData;
