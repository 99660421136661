const secondsInAMonth = 60 * 60 * 24 * 30;
const secondsInAWeek = 60 * 60 * 24 * 7;
const secondsInADay = 60 * 60 * 24;
const secondsInAnHour = 60 * 60;
const secondsInAMinute = 60;

const pluralize = (n: number, s: string): string => {
  if (n === 1) {
    return s;
  }
  return `${n} ${s}s`;
};

const formatSecondsToLabel = (seconds: number): string => {
  if (seconds >= secondsInAMonth) {
    return pluralize(Math.floor(seconds / secondsInAMonth), 'month');
  }
  if (seconds >= secondsInAWeek) {
    return pluralize(Math.floor(seconds / secondsInAWeek), 'week');
  }
  if (seconds >= secondsInADay) {
    const days = Math.floor(seconds / secondsInADay);
    const hours = Math.floor((seconds % secondsInADay) / secondsInAnHour);
    return hours > 0
      ? `${pluralize(days * 24 + hours, 'hour')}`
      : pluralize(days, 'day');
  }
  if (seconds >= secondsInAnHour) {
    const hours = (seconds % secondsInADay) / secondsInAnHour;
    const minutes = (seconds - secondsInAnHour * hours) / secondsInAMinute;
    return Number.isInteger(hours)
      ? pluralize(Math.round(seconds / secondsInAnHour), 'hour')
      : `${pluralize(secondsInAMinute * hours + minutes, 'minute')}`;
  }
  if (seconds >= secondsInAMinute) {
    return pluralize(Math.floor(seconds / secondsInAMinute), 'minute');
  }
  return pluralize(seconds, 'second');
};

export default formatSecondsToLabel;
