import dayjs from 'dayjs';
import {
  DateSelection,
  FacetRegexTerm,
  KeyExists,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  SpanFilter,
  TimeSeries,
} from 'types';
import {
  getTimeDiffInSeconds,
  convertAggregateTimeSeriesFromFloatToSeconds,
  onPromiseError,
  getRollupByVisualization,
} from 'utils';
import queryTraceService from './queryTraceService';
import { buildTracesFilter, transformTraceTimeseries } from './utils';

type Args = {
  date: DateSelection;
  facetRegex: FacetRegexTerm[];
  instant?: boolean;
  keyExists: KeyExists;
  labelFormatter?: (GroupVal: any) => string;
  rollUpSeconds: number;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  selectedHcFacetValuesByName: SelectedFacetValuesByName;
  serviceHash: string;
  serviceName: string;
  spanFilter: SpanFilter;
};

const getServiceExecTimeBreakdown = async ({
  date,
  instant = false,
  keyExists,
  labelFormatter,
  rollUpSeconds,
  facetRegex = [],
  selectedFacetRangeByName = {},
  selectedFacetValuesByName = {},
  selectedHcFacetValuesByName = {},
  serviceHash,
  serviceName,
  spanFilter,
}: Args): Promise<ReturnType<typeof transformTraceTimeseries>> => {
  const { startTimeUnix, endTimeUnix } = date;

  // Todo:Ashish
  // Remove this after backend Performance Improves
  const hrInSecs = getTimeDiffInSeconds('1h');
  const isDurationGreaterThanHr = endTimeUnix - startTimeUnix > hrInSecs;
  let adjustedStartTimeUnix = startTimeUnix;
  if (isDurationGreaterThanHr) {
    adjustedStartTimeUnix = endTimeUnix - hrInSecs;
  }
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - adjustedStartTimeUnix;

  const queryFilterString = buildTracesFilter({
    facetRegex,
    keyExists,
    selectedFacetRangeByName,
    selectedFacetValuesByName,
    selectedHcFacetValuesByName,
    spanFilter,
  });

  const hourAdjustedDate = {
    startTimeUnix: adjustedStartTimeUnix,
    endTimeUnix,
  };
  const step =
    rollUpSeconds || getRollupByVisualization(hourAdjustedDate, 'line');

  return queryTraceService<TimeSeries[], 'serviceExecTimeBreakdown'>(`
    {
        serviceExecTimeBreakdown(
        durationSecs: ${instant ? 0 : durationSecs}
        filter: ${queryFilterString}
        rollUpSeconds: ${instant ? durationSecs : step}
        service: {
          hash: "${serviceHash}"
        }
        timestamp: "${endTime.format()}"
      ) {
        BucketStartSecs
        GroupVal
        Value
      }
    }
  `)
    .then((data) =>
      (data?.serviceExecTimeBreakdown || []).map(
        convertAggregateTimeSeriesFromFloatToSeconds,
      ),
    )
    .then((dataset) => {
      return transformTraceTimeseries({
        dataset,
        labelFormatter,
        query: { queryKey: serviceName },
        step,
        sortBeforeGrouping: true,
      });
    }, onPromiseError);
};

export default getServiceExecTimeBreakdown;
