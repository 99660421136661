import React, { ReactElement } from 'react';
import { Virtuoso } from 'react-virtuoso';

type ListLazyLoaderProps = React.PropsWithChildren<{
  className?: string;
  containerHeight?: number;
  virtualizationOffset?: number;
}> &
  React.ComponentProps<typeof Virtuoso>;

const ListLazyLoader = (props: ListLazyLoaderProps): ReactElement => {
  const {
    containerHeight,
    children,
    className,
    virtualizationOffset,
    ...virtuosoProps
  } = props;

  if (!children || !Array.isArray(children) || children.length < 1) return null;

  if (children.length < (virtualizationOffset || 50)) {
    return <>{children}</>;
  }

  return (
    <Virtuoso
      style={{ height: containerHeight || 300 }}
      className={className}
      totalCount={children.length}
      itemContent={(index) => {
        return children[index];
      }}
      {...virtuosoProps}
    />
  );
};

export default ListLazyLoader;
