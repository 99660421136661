import {
  PopoverTriggerV2,
  PopoverPosition,
  InputWithValidatorV3,
} from 'components';
import { CalendarPickerModal } from 'composite';
import dayjs from 'dayjs';
import { dateTimeFormat } from 'kfuse-constants';
import React, { ReactElement, useMemo } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { DateSelection } from 'types';
import {
  convertSecondToReadablePrecision,
  convertPreciseDurationToSeconds,
} from 'utils';

import { useAlertsSilenceEditState } from './hooks';

const AlertsSilencesEditSchedule = ({
  alertsSilenceEditState,
}: {
  alertsSilenceEditState: ReturnType<typeof useAlertsSilenceEditState>;
}): ReactElement => {
  const { silenceDetails, setSilenceDetails } = alertsSilenceEditState;

  const dateChange = (date: DateSelection) => {
    const { startTimeUnix, endTimeUnix } = date;
    const startsAt = dayjs.unix(startTimeUnix).toISOString();
    const endsAt = dayjs.unix(endTimeUnix).toISOString();
    const duration = endTimeUnix - startTimeUnix;
    // check end time is after now
    let scheduleValidation = '';
    if (dayjs(endsAt).isBefore(dayjs())) {
      scheduleValidation = 'End time must be in the future from now';
    }
    setSilenceDetails((prev) => ({
      ...prev,
      startsAt,
      endsAt,
      duration,
      scheduleValidation,
    }));
  };

  const onDurationChange = (value: string) => {
    const seconds = convertPreciseDurationToSeconds(value);
    if (typeof seconds === 'string') {
      setSilenceDetails((prev) => ({ ...prev, duration: value }));
      return;
    }
    setSilenceDetails((prev) => {
      const { startsAt } = prev;
      const startAtDate = dayjs(startsAt);
      const endAtDate = startAtDate.add(seconds, 'seconds');
      return {
        ...prev,
        startsAt: startAtDate.toISOString(),
        endsAt: endAtDate.toISOString(),
        duration: seconds,
      };
    });
  };

  const { date, schedule } = useMemo(() => {
    const startsAt = silenceDetails?.startsAt;
    const endsAt = silenceDetails?.endsAt;
    if (!startsAt || !endsAt) {
      const now = dayjs();
      return {
        date: { startTimeUnix: now.unix(), endTimeUnix: now.unix() },
        schedule: null,
      };
    }

    const startAtDate = dayjs(startsAt);
    const endAtDate = dayjs(endsAt);

    return {
      date: {
        startTimeUnix: startAtDate.unix(),
        endTimeUnix: endAtDate.unix(),
      },
      schedule: `${startAtDate.format(dateTimeFormat)} to ${endAtDate.format(dateTimeFormat)}`,
    };
  }, [silenceDetails]);

  return (
    <div className="flex items-center">
      <div>
        <label className="text-xs">Silence start and end</label>
        <div
          className="mt-1 max-w-[310px] rounded-sm border"
          data-testid="schedule-picker"
        >
          <PopoverTriggerV2
            className="calendar-picker__trigger"
            popoverPanelClassName="popover__panel--overflow"
            popover={({ close }) => (
              <CalendarPickerModal
                absoluteTimeRangeStorage={undefined}
                close={close}
                onChange={dateChange}
                startLiveTail={null}
                quickRangeOptions={[]}
                value={date}
              />
            )}
            position={PopoverPosition.BOTTOM_LEFT}
            right
            width={480}
          >
            <div className="calendar-picker__trigger__value">{schedule}</div>
            <ChevronUp
              className="calendar-picker__trigger__chevron calendar-picker__trigger__chevron--up"
              size={16}
            />
            <ChevronDown
              className="calendar-picker__trigger__chevron calendar-picker__trigger__chevron--down"
              size={16}
            />
          </PopoverTriggerV2>
        </div>
        {silenceDetails?.scheduleValidation && (
          <div className="text-red-500">
            {silenceDetails.scheduleValidation}
          </div>
        )}
      </div>
      <div className="ml-2">
        <label className="text-xs">Duration</label>
        <div className="mt-1 rounded-sm border">
          <InputWithValidatorV3
            className="pl-2"
            size="4"
            type="text"
            variant="outline"
            onChange={onDurationChange}
            placeholder="Duration"
            value={
              typeof silenceDetails?.duration === 'number'
                ? convertSecondToReadablePrecision(silenceDetails.duration)
                : silenceDetails?.duration
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AlertsSilencesEditSchedule;
