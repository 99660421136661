import {
  LeftSidebar,
  ShowSidebarTooltipButton,
  useLeftSidebarState,
} from 'components';
import classnames from 'classnames';
import { RefreshCw } from 'react-feather';
import React, { ReactElement, FC, Suspense, ReactNode } from 'react';
import {
  KubernetesLeftSidebar,
  KubernetesLeftSidebarResources,
} from './KubernetesLeftSidebar';
import { KubernetesSearch } from './KubernetesSearch';
import {
  KubernetesController,
  useKubernetesController,
} from './KubernetesController';

import {
  Row as FlexRow,
  Column as FlexColumn,
  Item as FlexItem,
} from 'components/layouts/Flex';
import { Box } from 'components/layouts/Box';
import { Separator } from 'components/shadcn';
import { useFeatureFlag, User } from 'configcat-react';
import { useAuth } from 'hooks';
import { EntityTypes } from './types';
import KubernetesGroupBy from './KubernetesGroupBy';
import {
  Navigate,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import KubernetesTableForPod from './KubernetesTables/KubernetesTableForPod';
import KubernetesTableForPodGroup from './KubernetesTables/KubernetesTableForPodGroup';
import KubernetesTableForCluster from './KubernetesTables/KubernetesTableForCluster';
import KubernetesTableForClusterGroup from './KubernetesTables/KubernetesTableForClusterGroup';
import KubernetesTableForNamespace from './KubernetesTables/KubernetesTableForNamespace';
import KubernetesTableForNamespaceGroup from './KubernetesTables/KubernetesTableForNamespaceGroup';
import KubernetesTableForNode from './KubernetesTables/KubernetesTableForNode';
import KubernetesTableForNodeGroup from './KubernetesTables/KubernetesTableForNodeGroup';
import KubernetesTableForDeployment from './KubernetesTables/KubernetesTableForDeployment';
import KubernetesTableForDeploymentGroup from './KubernetesTables/KubernetesTableForDeploymentGroup';
import KubernetesTableForJob from './KubernetesTables/KubernetesTableForJob';
import KubernetesTableForJobGroup from './KubernetesTables/KubernetesTableForJobGroup';
import KubernetesTableForService from './KubernetesTables/KubernetesTableForService';
import KubernetesTableForServiceGroup from './KubernetesTables/KubernetesTableForServiceGroup';
import KubernetesTableForIngress from './KubernetesTables/KubernetesTableForIngress';
import KubernetesTableForIngressGroup from './KubernetesTables/KubernetesTableForIngressGroup';
import KubernetesTableForReplicaSet from './KubernetesTables/KubernetesTableForReplicaSet';
import KubernetesTableForReplicaSetGroup from './KubernetesTables/KubernetesTableForReplicaSetGroup';
import KubernetesTableForCronJob from './KubernetesTables/KubernetesTableForCronJob';
import KubernetesTableForCronJobGroup from './KubernetesTables/KubernetesTableForCronJobGroup';
import KubernetesTableForDaemonSet from './KubernetesTables/KubernetesTableForDaemonSet';
import KubernetesTableForDaemonSetGroup from './KubernetesTables/KubernetesTableForDaemonSetGroup';
import KubernetesTableForStatefulSet from './KubernetesTables/KubernetesTableForStatefulSet';
import KubernetesTableForStatefulSetGroup from './KubernetesTables/KubernetesTableForStatefulSetGroup';
import KubernetesTableForPersistentVolumeClaim from './KubernetesTables/KubernetesTableForPersistentVolumeClaim';
import KubernetesTableForPersistentVolumeClaimGroup from './KubernetesTables/KubernetesTableForPersistentVolumeClaimGroup';
import KubernetesTableForPersistentVolume from './KubernetesTables/KubernetesTableForPersistentVolume';
import KubernetesTableForPersistentVolumeGroup from './KubernetesTables/KubernetesTableForPersistentVolumeGroup';
import KubernetesTableForRole from './KubernetesTables/KubernetesTableForRole';
import KubernetesTableForRoleGroup from './KubernetesTables/KubernetesTableForRoleGroup';
import KubernetesTableForRoleBinding from './KubernetesTables/KubernetesTableForRoleBinding';
import KubernetesTableForRoleBindingGroup from './KubernetesTables/KubernetesTableForRoleBindingGroup';
import KubernetesTableForClusterRoleBinding from './KubernetesTables/KubernetesTableForClusterRoleBinding';
import KubernetesTableForClusterRoleBindingGroup from './KubernetesTables/KubernetesTableForClusterRoleBindingGroup';
import KubernetesTableForClusterRole from './KubernetesTables/KubernetesTableForClusterRole';
import KubernetesTableForClusterRoleGroup from './KubernetesTables/KubernetesTableForClusterRoleGroup';
import KubernetesTableForServiceAccount from './KubernetesTables/KubernetesTableForServiceAccount';
import KubernetesTableForServiceAccountGroup from './KubernetesTables/KubernetesTableForServiceAccountGroup';
import { generatePath } from 'react-router-dom';
import { KUBERNETES_PATH_STRING } from './hooks/useKubesFluxState';
import { useKubernetesPageStateContext } from 'context';
import { UserRole } from 'types/user';
import NoAccessBanner from 'screens/Login/NoAccessBanner';

const KubernetesRefreshButton = (): JSX.Element => {
  const { flux } = useKubernetesController();

  return (
    <button
      type="button"
      className={classnames(
        'rounded px-4 py-2',
        'border border-form-border',
        'hover:border-form-border-hover',
      )}
      onClick={flux.entitiesReload}
      data-testid="refresh"
    >
      <RefreshCw size={16} />
    </button>
  );
};

export function useTableComponentResolver(): [
  EntityTypes | null,
  JSX.Element,
  JSX.Element | null,
] {
  const location = useLocation();
  const params = useParams();
  const { snapshot } = useKubernetesPageStateContext();

  switch (params.entitiesType) {
    case 'pod':
      return [
        EntityTypes.Pod,
        <KubernetesTableForPod key={EntityTypes.Pod} />,
        <KubernetesTableForPodGroup key={EntityTypes.Pod} />,
      ];
    case 'cluster':
      return [
        EntityTypes.Cluster,
        <KubernetesTableForCluster key={EntityTypes.Cluster} />,
        <KubernetesTableForClusterGroup key={EntityTypes.Cluster} />,
      ];
    case 'namespace':
      return [
        EntityTypes.Namespace,
        <KubernetesTableForNamespace key={EntityTypes.Namespace} />,
        <KubernetesTableForNamespaceGroup key={EntityTypes.Namespace} />,
      ];
    case 'node':
      return [
        EntityTypes.Node,
        <KubernetesTableForNode key={EntityTypes.Node} />,
        <KubernetesTableForNodeGroup key={EntityTypes.Node} />,
      ];
    case 'deployment':
      return [
        EntityTypes.Deployment,
        <KubernetesTableForDeployment key={EntityTypes.Deployment} />,
        <KubernetesTableForDeploymentGroup key={EntityTypes.Deployment} />,
      ];
    case 'replica-set':
      return [
        EntityTypes.ReplicaSet,
        <KubernetesTableForReplicaSet key={EntityTypes.ReplicaSet} />,
        <KubernetesTableForReplicaSetGroup key={EntityTypes.ReplicaSet} />,
      ];
    case 'job':
      return [
        EntityTypes.Job,
        <KubernetesTableForJob key={EntityTypes.Job} />,
        <KubernetesTableForJobGroup key={EntityTypes.Job} />,
      ];
    case 'cron-job':
      return [
        EntityTypes.CronJob,
        <KubernetesTableForCronJob key={EntityTypes.CronJob} />,
        <KubernetesTableForCronJobGroup key={EntityTypes.CronJob} />,
      ];
    case 'daemon-set':
      return [
        EntityTypes.DaemonSet,
        <KubernetesTableForDaemonSet key={EntityTypes.DaemonSet} />,
        <KubernetesTableForDaemonSetGroup key={EntityTypes.DaemonSet} />,
      ];
    case 'stateful-set':
      return [
        EntityTypes.StatefulSet,
        <KubernetesTableForStatefulSet key={EntityTypes.StatefulSet} />,
        <KubernetesTableForStatefulSetGroup key={EntityTypes.StatefulSet} />,
      ];
    case 'service':
      return [
        EntityTypes.Service,
        <KubernetesTableForService key={EntityTypes.Service} />,
        <KubernetesTableForServiceGroup key={EntityTypes.Service} />,
      ];
    case 'ingress':
      return [
        EntityTypes.Ingress,
        <KubernetesTableForIngress key={EntityTypes.Ingress} />,
        <KubernetesTableForIngressGroup key={EntityTypes.Ingress} />,
      ];
    case 'persistent-volume-claim':
      return [
        EntityTypes.PersistentVolumeClaim,
        <KubernetesTableForPersistentVolumeClaim
          key={EntityTypes.PersistentVolumeClaim}
        />,
        <KubernetesTableForPersistentVolumeClaimGroup
          key={EntityTypes.PersistentVolumeClaim}
        />,
      ];
    case 'persistent-volume':
      return [
        EntityTypes.PersistentVolume,
        <KubernetesTableForPersistentVolume
          key={EntityTypes.PersistentVolume}
        />,
        <KubernetesTableForPersistentVolumeGroup
          key={EntityTypes.PersistentVolume}
        />,
      ];
    case 'role':
      return [
        EntityTypes.Role,
        <KubernetesTableForRole key={EntityTypes.Role} />,
        <KubernetesTableForRoleGroup key={EntityTypes.Role} />,
      ];
    case 'role-binding':
      return [
        EntityTypes.RoleBinding,
        <KubernetesTableForRoleBinding key={EntityTypes.RoleBinding} />,
        <KubernetesTableForRoleBindingGroup key={EntityTypes.RoleBinding} />,
      ];
    case 'cluster-role':
      return [
        EntityTypes.ClusterRole,
        <KubernetesTableForClusterRole key={EntityTypes.ClusterRole} />,
        <KubernetesTableForClusterRoleGroup key={EntityTypes.ClusterRole} />,
      ];
    case 'cluster-role-binding':
      return [
        EntityTypes.ClusterRoleBinding,
        <KubernetesTableForClusterRoleBinding
          key={EntityTypes.ClusterRoleBinding}
        />,
        <KubernetesTableForClusterRoleBindingGroup
          key={EntityTypes.ClusterRoleBinding}
        />,
      ];
    case 'service-account':
      return [
        EntityTypes.ServiceAccount,
        <KubernetesTableForServiceAccount key={EntityTypes.ServiceAccount} />,
        <KubernetesTableForServiceAccountGroup
          key={EntityTypes.ServiceAccount}
        />,
      ];
    default: {
      if (snapshot) {
        return [null, <Navigate key={null} to={snapshot} />, null];
      }

      return [
        null,
        <Navigate
          key={null}
          to={{
            ...location,

            pathname: generatePath(KUBERNETES_PATH_STRING, {
              entitiesType: EntityTypes.Pod.toLowerCase(),
              activeKube: null,
              tab: null,
            }),
          }}
        />,
        null,
      ];
    }
  }
}

const Kubernetes = ({
  auth,
}: {
  auth: ReturnType<typeof useAuth>;
}): ReactElement => {
  const [entitiesType, table, grouped] = useTableComponentResolver();
  const leftSidebarState = useLeftSidebarState('kubernetes');
  const [searchParams] = useSearchParams();

  const user = auth?.user as User;
  const isViewer = user?.role === UserRole.VIEWER;
  const { value: hideInfrastructureForViewer } = useFeatureFlag(
    'hideinfrastructureforviewer',
    true,
  );

  const hideInfrastructure = hideInfrastructureForViewer && isViewer;
  if (hideInfrastructure) {
    return <NoAccessBanner />;
  }

  if (entitiesType === null) {
    return table;
  }
  return (
    <KubernetesController entitiesType={entitiesType}>
      <FlexRow align="start">
        <FlexItem no-shrink asChild>
          <LeftSidebar
            className="min-h-[calc(100vh-48px)]"
            leftSidebarState={leftSidebarState}
          >
            <KubernetesLeftSidebarResources />
            <Box my="4">
              <Separator />
            </Box>
            <KubernetesLeftSidebar />
          </LeftSidebar>
        </FlexItem>

        <FlexItem grow shrink asChild>
          <Box px="3" asChild>
            <FlexColumn gap="0">
              <div className="my-6 flex w-full items-start gap-2">
                {leftSidebarState.width === 0 && (
                  <ShowSidebarTooltipButton
                    className="!mr-0"
                    onClick={leftSidebarState.show}
                  />
                )}

                <FlexItem asChild grow>
                  <FlexColumn gap="2">
                    <KubernetesSearch />

                    <KubernetesGroupBy />
                  </FlexColumn>
                </FlexItem>

                <KubernetesRefreshButton />
              </div>

              {searchParams.has('groupBySearchTerms') ? grouped : table}
            </FlexColumn>
          </Box>
        </FlexItem>
      </FlexRow>
    </KubernetesController>
  );
};

export default Kubernetes;
