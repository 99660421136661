import { useRequest } from 'hooks';
import React, { ReactElement } from 'react';
import { getLogsFavouriteFacetsByCursor } from 'requests';
import { DateSelection, LogsMetricQueryProps } from 'types';
import LogsMetricsQueryBuilderSearchInner from './LogsMetricsQueryBuilderSearchInner';
import useDebouncedEffect from 'use-debounced-effect';

const LogsMetricsQueryBuilderSearch = ({
  customerFilter,
  date,
  handleFilterChange,
  query,
}: {
  customerFilter?: { key: string; value: string };
  date: DateSelection;
  handleFilterChange: VoidFunction;
  query: LogsMetricQueryProps;
}): ReactElement => {
  const getFacetNamesRequest = useRequest(getLogsFavouriteFacetsByCursor);

  useDebouncedEffect(
    () => {
      handleFilterChange();
    },
    300,
    [query.filtersState.state],
  );

  return (
    <LogsMetricsQueryBuilderSearchInner
      customerFilter={customerFilter}
      date={date}
      filtersState={query.filtersState}
      getFacetNamesRequest={getFacetNamesRequest}
    />
  );
};

export default LogsMetricsQueryBuilderSearch;
