const removeDuplicateByKey = (items: Array<any>, key: string): Array<any> => {
  const seen = new Map<string, boolean>();

  return items.filter((item) => {
    if (seen.has(item[key])) {
      return false;
    }
    seen.set(item[key], true);
    return true;
  });
};

export default removeDuplicateByKey;
