import { useToggle } from 'hooks';
import React from 'react';
import {
  HiOutlineClipboardDocumentCheck,
  HiOutlineClipboardDocumentList,
} from 'react-icons/hi2';
import { copyToClipboard } from 'utils';
import { TooltipTrigger } from './TooltipTrigger';

type Props = {
  text: string;
};

const CopyButton = ({ text }: Props) => {
  const copy = () => {
    hasCopiedToggle.on();
    copyToClipboard(text)
      .then(() => {
        setTimeout(hasCopiedToggle.off, 3000);
      })
      .catch(() => {
        hasCopiedToggle.off();
      });
  };

  const hasCopiedToggle = useToggle();
  return (
    <TooltipTrigger
      className="inline-block"
      tooltip={hasCopiedToggle.value ? 'Copied!' : 'Copy'}
      onClick={copy}
      variant="compact"
    >
      {hasCopiedToggle.value ? (
        <HiOutlineClipboardDocumentCheck size={19} />
      ) : (
        <HiOutlineClipboardDocumentList size={19} />
      )}
    </TooltipTrigger>
  );
};

export default CopyButton;
