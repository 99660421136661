import classnames from 'classnames';
import React, { ReactElement, ReactNode, useRef } from 'react';
import {
  TableElement,
  TbodyElement,
  TheadElement,
  TrElement,
} from './components';
import defaultRenderHeader from './defaultRenderHeader';
import getResizedRowStyle from './getResizedRowStyle';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import { TableColumn, TableRowProps } from './types';

import useTableResizer from './useTableResizer';
import useTableSort from './useTableSort';

interface Props<T> {
  activeRowId?: number;
  className?: string;
  columns: TableColumn<T>[];
  dataTestId?: string;
  doNotSplitKeysOfTable?: boolean;
  externalTableSort?: ReturnType<typeof useTableSort>;
  isFullWidth?: boolean;
  isResizingEnabled?: boolean;
  isSortingEnabled?: boolean;
  isSortingDisabledForColumn?: (key: string) => boolean;
  isStickyHeaderEnabled?: boolean;
  onRowClick?: ({ row }: { row: T }) => void;
  rowClassName?: string;
  onScrollEnd?: VoidFunction;
  renderHeader?: ({ column }: { column: TableColumn<T> }) => ReactNode;
  renderRow?: (props: TableRowProps<T>, index?: number) => ReactNode;
  rows: T[];
  shouldRenderAsTableElements?: boolean;
  tableKey?: string;
  tableWidth?: number;
  overlayMessage?: string;
}

const defaultProps = {
  tableWidth: 0,
};

const Table = <T extends { id?: string; [key: string]: unknown }>({
  activeRowId,
  className,
  columns,
  dataTestId,
  doNotSplitKeysOfTable,
  externalTableSort,
  isFullWidth,
  isResizingEnabled,
  isSortingEnabled = false,
  isSortingDisabledForColumn = () => false,
  isStickyHeaderEnabled = false,
  onRowClick,
  rowClassName,
  onScrollEnd,
  renderHeader,
  renderRow,
  rows,
  shouldRenderAsTableElements = false,
  tableKey,
  tableWidth,
  overlayMessage,
}: Props<T>): ReactElement => {
  const tableRef = useRef<HTMLTableElement>(null);
  const onRowClickHandler = (row: T) => () => {
    if (onRowClick) {
      onRowClick({ row });
    }
  };

  const tableSort = useTableSort({ columns, rows });
  const { isTableReady, onMouseMoveHandler, registerWidth, widths } =
    useTableResizer({
      columns,
      tableKey,
    });
  const totalWidth = Math.max(
    columns.reduce((sum, column) => sum + widths[column.key], 0),
    tableWidth,
  );
  const columnKeysBitmap: { [key: string]: number } = columns.reduce(
    (obj, column) => ({ ...obj, [column.key]: 1 }),
    {},
  );
  const shouldRenderDiv =
    !shouldRenderAsTableElements &&
    Object.keys(widths).filter((key) => columnKeysBitmap[key] && widths[key])
      .length >= columns.length;

  return (
    <>
      {isTableReady && (
        <TableElement
          ref={tableRef}
          className={classnames({ table: true, [className]: className })}
          data-testid={dataTestId}
          shouldRenderDiv={shouldRenderDiv}
        >
          <colgroup>
            {columns.map((column, index: number) => {
              return <col key={index} className={column.className} />;
            })}
          </colgroup>
          <TheadElement
            shouldRenderDiv={shouldRenderDiv}
            className={classnames({
              'sticky-container': isStickyHeaderEnabled,
            })}
          >
            <TrElement
              className="table__row table__row--header"
              shouldRenderDiv={shouldRenderDiv}
              style={getResizedRowStyle({
                isFullWidth,
                shouldRenderDiv,
                totalWidth,
              })}
            >
              {columns.map((column, index) => (
                <TableHeader
                  column={column}
                  isLast={index === columns.length - 1}
                  isResizingEnabled={isResizingEnabled}
                  key={column.key}
                  onMouseMove={onMouseMoveHandler(index)}
                  registerWidth={registerWidth}
                  shouldRenderDiv={shouldRenderDiv}
                  widths={widths}
                >
                  {renderHeader
                    ? renderHeader({ column })
                    : defaultRenderHeader({
                        column,
                        externalTableSort,
                        isSortingEnabled:
                          isSortingEnabled &&
                          !isSortingDisabledForColumn(column.key),
                        tableSort,
                      })}
                </TableHeader>
              ))}
            </TrElement>
          </TheadElement>
          <TbodyElement shouldRenderDiv={shouldRenderDiv}>
            {Array.isArray(tableSort?.sortedRows) &&
              tableSort?.sortedRows?.map((row, rowIndex: number) => {
                const renderRowProps = {
                  columns,
                  doNotSplitKeysOfTable,
                  isFullWidth,
                  onRowClickHandler,
                  onScrollEnd,
                  row,
                  rows: tableSort?.sortedRows,
                  rowIndex,
                  rowsCount: rows.length,
                  shouldRenderDiv,
                  totalWidth,
                  tableRef,
                  widths,
                  className: activeRowId === rowIndex ? rowClassName : '',
                };
                return renderRow ? (
                  renderRow(renderRowProps, rowIndex)
                ) : (
                  <TableRow<T>
                    {...renderRowProps}
                    key={row.id || rowIndex}
                    shouldRenderDiv={shouldRenderDiv}
                    totalWidth={totalWidth}
                    widths={widths}
                  />
                );
              })}
          </TbodyElement>
        </TableElement>
      )}
    </>
  );
};

Table.defaultProps = defaultProps;

export default Table;
