import { CursorStateProps, UplotExtended } from 'types';
import {
  binarySearch,
  canvasPadding,
  drawVertcalCursor,
  getTooltipCanvasX,
} from 'utils';
import { themeColorLight, themeColorDark } from 'utils/colors';

export const addTooltipToDOM = (
  width: number,
  height: number,
): { tooltipDiv: HTMLDivElement; tooltipCtx: CanvasRenderingContext2D } => {
  const tooltipDiv = document.createElement('div');
  tooltipDiv.classList.add('uplot__tooltip');
  const tooltipCanvas = document.createElement('canvas');
  tooltipCanvas.classList.add('uplot__tooltip__canvas');

  const devicePixelRatio = window.devicePixelRatio || 1;
  tooltipCanvas.width = width * devicePixelRatio;
  tooltipCanvas.height = height * devicePixelRatio;
  tooltipDiv.appendChild(tooltipCanvas);

  const tooltipCtx = tooltipCanvas.getContext('2d');
  return { tooltipDiv, tooltipCtx };
};

export const updateTooltipCanvas = (
  u: UplotExtended,
  width: number,
  height: number,
): void => {
  if (!u || !u.tooltipCtx) return;
  const devicePixelRatio = window.devicePixelRatio || 1;

  const canvasWidth = u.tooltipCtx.canvas.width / devicePixelRatio;
  const canvasHeight = u.tooltipCtx.canvas.height / devicePixelRatio;

  if (canvasWidth === width && canvasHeight === height) return;

  u.tooltipCtx.canvas.width = width * devicePixelRatio;
  u.tooltipCtx.canvas.height = height * devicePixelRatio;
};

export const handleTooltipCanvasForCursorState = ({
  cursorState,
  darkModeEnabled,
  u,
}: {
  cursorState: CursorStateProps;
  darkModeEnabled?: boolean;
  u: UplotExtended;
}): void => {
  if (!cursorState) {
    u.clearCanvasByContext(u.tooltipCtx);
    return;
  }

  const { timestamp } = cursorState;
  if (!timestamp) return null;
  const { value } = timestamp;

  const timestamps = u.data[0] as number[];
  const pointIdx = binarySearch(timestamps, value, true) as number | null;
  if (pointIdx === null) return;

  const padding = canvasPadding(u);
  const ctx = u.tooltipCtx;
  const devicePixelRatio = window.devicePixelRatio || 1;

  const leftX = getTooltipCanvasX(u, pointIdx);
  if (leftX === null) return;
  const bottomY = u.height * devicePixelRatio - padding.bottom;

  const bgColor = darkModeEnabled
    ? themeColorLight.light03
    : themeColorDark.dark03;
  u.clearCanvasByContext(u.tooltipCtx);
  drawVertcalCursor({
    ctx,
    leftX,
    bottomY,
    padding,
    bgColor,
  });
};
