/**
 *
 * @param json
 * @param noQuotes: string[]
 * @returns graphql
 * example: {"binaryExpr":{"op":"Multiplication","leftExpr":{"aggregationExpr":{"aggregation":"distinctcount","aggregationField":"*","filter":"{ and: [] }"}},"rightExpr":{"scalarExpr":2}}}
 * Output: {binaryExpr: { op: Multiplication, leftExpr: { aggregationExpr: { aggregation: "distinctcount", aggregationField: "*", filter: "{ and: [] } } }", rightExpr: { scalarExpr: 2 }
 * @noQuotesValue: ["Addition", "Subtraction", "Multiplication", "Division"]
 * All keys in json will be no quotes,
 * All values in json will be quotes except for noQuotesValue
 */
const transformJSONToGraphql = (
  json: any,
  noQuotesValue: string[] = [],
  noQuotesKeys: string[] = [],
): string => {
  const transform = (json: any): string => {
    let graphql = '';
    if (json === null) {
      graphql += 'null';
    } else if (typeof json === 'object') {
      const keys = Object.keys(json);
      graphql += '{';
      keys.forEach((key, index) => {
        graphql += key;
        graphql += ': ';
        if (noQuotesKeys.includes(key)) {
          graphql += json[key];
        } else {
          graphql += transform(json[key]);
        }
        if (index < keys.length - 1) {
          graphql += ', ';
        }
      });
      graphql += '}';
    } else if (typeof json === 'string') {
      if (noQuotesValue.includes(json)) {
        graphql += json;
      } else {
        graphql += `"${json}"`;
      }
    } else {
      graphql += json;
    }
    return graphql;
  };
  return transform(json);
};

export default transformJSONToGraphql;
