import { ChartJsData, ChartGridItem, TimeSeries, DateSelection } from 'types';
import { fillTimestampForWindow } from './DataTransformer';

const formatAggregateTimeSeriesData = ({
  date,
  points,
  step,
  fillMissingTimestamps,
}: {
  date?: DateSelection;
  points: TimeSeries[];
  step?: number;
  fillMissingTimestamps?: boolean;
}): ChartGridItem => {
  const keysBitmap: { [key: string]: number } = {};
  const valuesByBucketStart: { [key: string]: ChartJsData } = {};

  points.forEach((point) => {
    const { BucketStart, GroupVal, Value } = point;
    const keys = Object.keys(GroupVal);

    const key =
      keys.length === 0
        ? 'count'
        : keys
            .sort()
            .map((key) => `${key}=${GroupVal[key]}`)
            .join('-');

    if (!keysBitmap[key]) {
      keysBitmap[key] = 1;
    }

    if (!valuesByBucketStart[BucketStart]) {
      valuesByBucketStart[BucketStart] = {};
    }

    valuesByBucketStart[BucketStart][key] = Value;
  });

  const timestamps = Object.keys(valuesByBucketStart).map(Number).sort();

  if (fillMissingTimestamps) {
    const timestampBitmap: { [key: string]: boolean } = {};
    timestamps.forEach((timestamp) => {
      timestampBitmap[timestamp] = true;
    });

    const { timestamps: filledTimestamps } = fillTimestampForWindow({
      executedDate: date,
      step,
    });

    return {
      data: filledTimestamps.map((timestamp) => {
        if (timestamp in valuesByBucketStart) {
          return valuesByBucketStart[timestamp];
        }
        return { count: 0 };
      }),
      keys: Object.keys(keysBitmap),
      timestamps: filledTimestamps.map(Number),
    };
  }
  return {
    data: timestamps.map((timestamp) => valuesByBucketStart[timestamp]),
    keys: Object.keys(keysBitmap),
    timestamps: timestamps.map(Number),
  };
};

export default formatAggregateTimeSeriesData;
