import uPlot from 'uplot';
import { heatmapColorPalette } from 'utils';

const getHeatmapCountFills = (u: uPlot, seriesIdx: number) => {
  const counts = u.data[seriesIdx][2] as unknown as number[];
  const hideThreshold = 0;
  let minCount = Infinity;
  let maxCount = -Infinity;

  for (let i = 0; i < counts.length; i++) {
    if (counts[i] > hideThreshold) {
      minCount = Math.min(minCount, counts[i]);
      maxCount = Math.max(maxCount, counts[i]);
    }
  }

  const palette = heatmapColorPalette;
  const range = maxCount - minCount;
  const paletteSize = palette.length;
  const indexedFills = Array(counts.length);

  for (let i = 0; i < counts.length; i++) {
    if (counts[i] === 0) {
      // If count is 0, set the fill index to -1 (no fill)
      indexedFills[i] = -1;
    } else {
      // Calculate the fill index based on the count value
      const fillIndex = Math.floor(
        (paletteSize * (counts[i] - minCount)) / range,
      );

      // Ensure the fill index is within the palette range
      indexedFills[i] = Math.min(paletteSize - 1, fillIndex);
    }
  }

  return indexedFills;
};

export default getHeatmapCountFills;
