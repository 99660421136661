import {
  ConfirmationModal,
  PopoverTriggerV2,
  useToaster,
  useModalsContext,
  Button,
} from 'components';
import { TimeRangePeriod } from 'composite';
import { colorsByAlertState } from 'kfuse-constants';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateSelection } from 'types';

import { useAlertsDelete, useAlertsState } from '../hooks';
import { RuleProps, RuleType } from '../types';
import {
  editAPMAlert,
  editLogAlert,
  editMetricAlert,
  editSLOAlert,
  editTracesAlert,
} from '../utils';

const AlertsDetailsHeader = ({
  alertsState,
  disableAlertEditAndDelete,
  properties,
  rule,
  setRule,
}: {
  alertsState: ReturnType<typeof useAlertsState>;
  disableAlertEditAndDelete: boolean;
  properties: RuleProps;
  rule: RuleProps;
  setRule: (rule: RuleProps) => void;
}): ReactElement => {
  const navigate = useNavigate();
  const modal = useModalsContext();
  const { deleteAlertsRule } = useAlertsDelete();

  const { addToast } = useToaster();
  const { muteAlert, setIsLoading, unMuteAlert } = alertsState;

  const goToEditAlert = () => {
    let editURL = '';

    switch (rule.annotations?.ruleType) {
      case RuleType.SLO:
        editURL = editSLOAlert(rule);
        break;
      case RuleType.METRICS:
        editURL = editMetricAlert(rule, addToast);
        break;
      case RuleType.LOGS:
        editURL = editLogAlert(rule, addToast);
        break;
      case RuleType.APM:
        editURL = editAPMAlert(rule, addToast);
        break;
      case RuleType.TRACES:
        editURL = editTracesAlert(rule, addToast);
        break;
      default:
        editURL = editMetricAlert(rule, addToast);
    }

    navigate(editURL, { state: rule });
  };

  const onDeleteAlertsRule = () => {
    modal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description="Are you sure you want to delete this alert?"
        onCancel={() => modal.pop()}
        onConfirm={() => {
          setIsLoading(true);
          deleteAlertsRule({
            folderName: rule.groupFile,
            groupName: rule.group,
            uid: rule.uid,
            folderUid: rule.folderUid,
          })
            .then(() => {
              navigate('/alerts');
              modal.pop();
              setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
        }}
        title="Delete Alert"
      />,
    );
  };

  const onMuteAlert = (rule: RuleProps, val: DateSelection) => {
    setIsLoading(true);
    muteAlert(rule, val)
      .then((res: { silenceID: string }) => {
        setRule({ ...rule, mute: 'Muted', muteId: res.silenceID });
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  const onUnmuteAlert = (rule: RuleProps) => {
    setIsLoading(true);
    unMuteAlert(rule.muteId)
      .then(() => {
        setRule({ ...rule, mute: null, muteId: null, muteEndAt: null });
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <div className="alerts__details__properties__header">
      <div
        className="text-lg font-semibold"
        data-testid="alert-detail-name"
        style={{ color: colorsByAlertState[rule.status] }}
      >
        {rule.name}
      </div>
      <div className="alerts__details__properties__actions">
        <Button
          variant={'outline'}
          size="sm"
          onClick={() => navigate('/alerts')}
        >
          Close
        </Button>
        {rule.mute !== 'Muted' && (
          <PopoverTriggerV2
            popover={({ close }) => (
              <TimeRangePeriod
                close={() => close()}
                onChange={(val: DateSelection) => onMuteAlert(rule, val)}
                periodType="Next"
                startLiveTail={null}
              />
            )}
          >
            <Button className="mr-2" variant={'default'} size="sm">
              Mute
            </Button>
          </PopoverTriggerV2>
        )}
        {rule.mute === 'Muted' && (
          <Button
            variant={'default'}
            size="sm"
            onClick={() => onUnmuteAlert(rule)}
          >
            Unmute
          </Button>
        )}

        {disableAlertEditAndDelete ? null : (
          <Button onClick={() => goToEditAlert()} size="sm" variant={'default'}>
            Edit
          </Button>
        )}
        {disableAlertEditAndDelete ? null : (
          <Button
            onClick={() => onDeleteAlertsRule()}
            size="sm"
            variant={'destructive'}
          >
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};

export default AlertsDetailsHeader;
