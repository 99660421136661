import classnames from 'classnames';
import { Loader, SizeObserver } from 'components';
import { useForm, useRefAreaState } from 'hooks';
import React, { useMemo } from 'react';
import { BiError } from 'react-icons/bi';
import { BsBarChart } from 'react-icons/bs';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ReferenceArea,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Chart, DateSelection } from 'types';
import {
  convertNumberToReadableUnit,
  xAxisTickFormatter as defaultXAxisTickFormatter,
} from 'utils';
import ChartGridTooltip from './ChartGridTooltip';
import useChartGridItemData from './useChartGridItemData';
import { isCompareKey } from './utils';

type Props = {
  chartGridItemData: ReturnType<typeof useChartGridItemData>;
  chart: Chart;
  date: DateSelection;
  form: ReturnType<typeof useForm>;
  width: number;
};

const ChartGridItemVisualization = ({
  chart,
  chartGridItemData,
  date,
  form,
  width,
}: Props) => {
  const {
    chartedData,
    colorMap,
    data,
    error,
    isLoading,
    referenceLines,
    renderedKeys,
    renderTooltipTimestamp,
    timestamps,
  } = chartGridItemData;
  const { allowYAxisDecimals, chartType, marginLeft, onSelection } = chart;
  const { isLogScaleEnabled } = form.values;

  const xAxisTickFormatter = chart.xAxisTickFormatter
    ? chart.xAxisTickFormatter(timestamps)
    : defaultXAxisTickFormatter(timestamps);

  const defaultYAxisFormatter = (s: number) =>
    s < 1 && s > 0 ? s.toFixed(2) : convertNumberToReadableUnit(s, 1);

  const yAxisTickFormatter = chart.yAxisTickFormatter || defaultYAxisFormatter;

  const onMouseUpCallback = ({ refAreaLeft, refAreaRight }) => {
    if (onSelection) {
      const startIndex = Math.max(refAreaLeft + 1, 0);
      const endIndex = Math.min(refAreaRight, timestamps.length - 1);
      const start = timestamps[startIndex];
      const end = timestamps[endIndex];
      const effectiveDiff = Math.max(end - start, 1);
      onSelection(start, start + effectiveDiff);
    }
  };

  const refAreaState = useRefAreaState({
    onClearCallback: () => {},
    onMouseUpCallback,
    shouldResetAfterMouseUp: true,
  });

  const { onMouseMove, onMouseDown, onMouseUp, state } = refAreaState;
  const handlers = onSelection ? { onMouseDown, onMouseMove, onMouseUp } : {};

  const { errorPlaceholderText, isDataEmpty } = useMemo(() => {
    if (isLoading) return { errorPlaceholderText: '', isDataEmpty: false };
    if (error.getRange)
      return { errorPlaceholderText: error.getRange, isDataEmpty: false };
    const isDataEmpty = chartedData.every((item) => item.count === 0);
    if (isDataEmpty)
      return { errorPlaceholderText: 'No Data Found', isDataEmpty: true };
    return { errorPlaceholderText: '', isDataEmpty: false };
  }, [chartedData, error.getRange, isLoading]);

  return (
    <SizeObserver className="chart-grid__item__body">
      {({ height }) => (
        <Loader
          className={classnames({
            'chart-grid__item__visualization': true,
            'chart-grid__item__visualization--placeholder':
              errorPlaceholderText,
          })}
          isLoading={isLoading}
          dataTestId={`chart-${chart.key}`}
        >
          {errorPlaceholderText ? (
            <div
              className="chart-grid__item__visualization__placeholder"
              style={{ height: `${height}px`, width: `${width}px` }}
            >
              <div className="chart-grid__item__visualization__placeholder__icon">
                {!isDataEmpty ? (
                  <BiError color="#da545b" size={24} />
                ) : (
                  <BsBarChart size={24} />
                )}
              </div>
              <div
                className={classnames({
                  'chart-grid__item__visualization__placeholder__text': true,
                  'chart-grid__item__visualization__placeholder__text--error':
                    !isDataEmpty,
                })}
              >
                {errorPlaceholderText}
              </div>
            </div>
          ) : (
            <ComposedChart
              data={chartedData}
              margin={{
                top: 20,
                right: 10,
                left: marginLeft || 0,
                bottom: 0,
              }}
              width={width}
              height={height}
              {...handlers}
            >
              <CartesianGrid stroke="#f5f5f5" vertical={false} />
              <XAxis minTickGap={40} tickFormatter={xAxisTickFormatter} />
              <YAxis
                {...(isLogScaleEnabled
                  ? {
                      allowDataOverflow: true,
                      domain: [0.01, 'auto'],
                      scale: 'log',
                    }
                  : { domain: [0, 'auto'] })}
                allowDecimals={
                  typeof allowYAxisDecimals === 'boolean'
                    ? allowYAxisDecimals
                    : true
                }
                tickFormatter={yAxisTickFormatter}
                type="number"
                width={44}
              />
              <Tooltip
                content={
                  <ChartGridTooltip
                    compare={form.values.compare}
                    renderTooltipTimestamp={renderTooltipTimestamp}
                    timestamps={timestamps}
                  />
                }
              />
              {renderedKeys.map((key: string, i: number) =>
                chartType === 'bar' && !isCompareKey(key) ? (
                  <Bar
                    key={i}
                    dataKey={key}
                    isAnimationActive={false}
                    fill={colorMap[key]}
                    stackId="a"
                    type="monotone"
                  />
                ) : (
                  <Line
                    key={i}
                    connectNulls
                    dataKey={key}
                    dot={false}
                    isAnimationActive={false}
                    stroke={colorMap[key]}
                    strokeWidth={3}
                    type="monotone"
                  />
                ),
              )}
              {referenceLines
                .filter((referenceLine, i) => {
                  const prevReferenceLine = referenceLines[i - 1];
                  return !(
                    prevReferenceLine &&
                    (prevReferenceLine.x === referenceLine.x ||
                      prevReferenceLine.x === referenceLine.x - 1 ||
                      prevReferenceLine.x === referenceLine.x - 2)
                  );
                })
                .map((referenceLine, i) => (
                  <ReferenceLine
                    key={i}
                    x={referenceLine.x}
                    stroke="black"
                    strokeWidth={1}
                  >
                    {' '}
                    <Label
                      value={referenceLine.label}
                      position="top"
                      angle={referenceLine.angle}
                    />{' '}
                  </ReferenceLine>
                ))}
              {state.refAreaLeft && state.refAreaRight ? (
                <ReferenceArea
                  className="logs__timeline__chart__panned-area"
                  fill="#f3f4f5"
                  ifOverflow="extendDomain"
                  x1={Math.max(state.refAreaLeft, 0)}
                  x2={Math.min(state.refAreaRight - 1, chartedData.length - 1)}
                  strokeOpacity={0.3}
                />
              ) : null}
            </ComposedChart>
          )}
        </Loader>
      )}
    </SizeObserver>
  );
};

export default ChartGridItemVisualization;
