const alertQueryAndVariableParser = ({
  algoKey,
  promql,
  numberOfVariable,
  hasWindow,
}: {
  algoKey: string;
  promql: string;
  numberOfVariable: number;
  hasWindow: boolean;
}): { query: string; window: string; variables: string[] } => {
  let parsedQuery = '';
  let parsedWindow = '';
  const replacePromql = promql.replace(algoKey, '').slice(1, -1);
  const splitPromql = replacePromql.split(', ');
  let variables: string[] = [];

  if (numberOfVariable !== 0) {
    variables = splitPromql.slice(-numberOfVariable);
  }

  if (hasWindow) {
    const windowPre = splitPromql[splitPromql.length - numberOfVariable - 1];
    const largeBracketStartIndex = windowPre.lastIndexOf('[');
    const replaceFromBracket = windowPre.substring(largeBracketStartIndex);
    const largeBracketEndIndex = replaceFromBracket.indexOf(']');
    const window = replaceFromBracket
      .substring(0, largeBracketEndIndex + 1)
      .trim();

    const windowReplace = window.slice(1, -1);
    const windowSplit = windowReplace.split(':');
    parsedWindow = windowSplit[0];
  }

  if (numberOfVariable !== 0) {
    parsedQuery = splitPromql.slice(0, -numberOfVariable).join(', ');
  } else {
    parsedQuery = splitPromql.join(', ');
  }

  if (hasWindow) {
    const largeBracketStartIndex = parsedQuery.lastIndexOf('[');
    parsedQuery = parsedQuery.substring(0, largeBracketStartIndex);
  }

  return {
    query: parsedQuery,
    window: parsedWindow,
    variables: sanitizeVariable(variables),
  };
};

/**
 * Sanitize alert parsed variables
 * @param {string[]} variables
 * @returns {string[]}
 * 1. Remove trailing and leading spaces
 * 2. Remove empty string
 * 3. Remove ( or )
 * 4. Remove [ or ]
 */
const sanitizeVariable = (variables: string[]) => {
  return variables
    .map((variable) => variable.trim())
    .filter((variable) => variable !== '')
    .map((variable) => variable.replace(/[()]/g, ''))
    .map((variable) => variable.replace(/[\[\]]/g, ''));
};

export default alertQueryAndVariableParser;
