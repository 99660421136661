import { DEFAULT_NODE_DIAMETER } from './constants';

const MIN_DIAMETER = 30;
const MAX_DIAMETER = 100;

export const calculateDiameterOfNode = ({
  count,
  minValue,
  maxValue,
}: {
  count: number;
  minValue: number;
  maxValue: number;
}) => {
  if (minValue === 0 && maxValue === 0) {
    return DEFAULT_NODE_DIAMETER;
  }
  const clampedRequests = Math.max(minValue, Math.min(maxValue, count));
  const normalizedRequestValue =
    (clampedRequests - minValue) / (maxValue - minValue);
  const diameter =
    normalizedRequestValue * (MAX_DIAMETER - MIN_DIAMETER) + MIN_DIAMETER;

  return diameter;
};
