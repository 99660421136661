import React, { ReactElement } from 'react';
import { ChartType, TimeseriesRenderProps } from 'types';

import { CompactTooltipContainerV2, CompactTooltipText } from '../components';
import useTooltipPluginV2 from './useTooltipPluginV2';
import { tooltipFormatter } from '../utils';
import { UPlotConfig } from '../types';

const TooltipCompact = ({
  chartType,
  config,
  cursorState,
  minDecimalCount,
  onCursorStateChange,
  unit,
}: {
  chartType: ChartType;
  config: UPlotConfig;
  cursorState?: TimeseriesRenderProps['cursorState'];
  minDecimalCount?: number;
  onCursorStateChange?: TimeseriesRenderProps['onCursorStateChange'];
  unit?: string;
}): ReactElement => {
  const { tooltip, isActive } = useTooltipPluginV2({
    chartType,
    config,
    cursorState,
    onCursorStateChange,
  });

  if (!tooltip) return null;
  if (tooltip && tooltip.renderTooltip) return tooltip.renderTooltip();

  const renderTooltip = () => {
    return (
      <CompactTooltipContainerV2 coords={tooltip}>
        <CompactTooltipText
          color={tooltip.data.color as string}
          label={tooltip.data.label}
          position={tooltip.positionX}
          value={tooltipFormatter(tooltip.data.value, unit, minDecimalCount)}
        />
      </CompactTooltipContainerV2>
    );
  };

  return <>{isActive && tooltip && renderTooltip()}</>;
};

export default TooltipCompact;
