import React, { useMemo } from 'react';
import { calculateCapacity } from 'utils/timeNs';

import { PersistentVolume } from '../types';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellStatus,
  KubeCellTimestamp,
  KubeTableCell,
} from './Components';
import KubernetesTable from './KubernetesTable';
import { useEntitiesCount, useEntitiesData } from './useEntitiesData';

export default function KubernetesTableForPersistentVolume() {
  const { entitiesType, facets, page, limit } = useKubernetesController();

  const filters = useMemo(() => [facets], [facets]);
  const [rows] = useEntitiesData<{ persistentVolume: PersistentVolume }>({
    filters,
    page,
    limit,
  });
  const [count] = useEntitiesCount({
    entitiesType,
    facets,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'kube_persistent_volume',
          label: 'Persistent Volume',
          renderCell(prop) {
            const persistentVolumeName =
              prop.row.persistentVolume.metadata.name;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name"
                tooltipText={persistentVolumeName}
              >
                {persistentVolumeName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell(prop) {
            return <KubeCellCluster {...prop} />;
          },
        },
        {
          key: 'status.phase',
          label: 'Phase',
          renderCell(prop) {
            const status = prop.row.persistentVolume.status.phase;
            return <KubeCellStatus {...prop} status={status} />;
          },
        },
        {
          key: 'spec.storageClassName',
          label: 'Class',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="left">
                {prop.row.persistentVolume.spec.storageClassName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'spec.persistentVolumeType',
          label: 'Type',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="left">
                {prop.row.persistentVolume.spec.persistentVolumeType}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'spec.accessModes',
          label: 'Access Modes',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="left">
                {prop.row.persistentVolume.spec.accessModes}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'kube_reclaim_policy',
          label: 'Reclaim Policy',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="left">
                {prop.row.persistentVolume.spec.persistentVolumeReclaimPolicy}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'metadata.creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            return (
              <KubeCellTimestamp
                {...prop}
                timestamp={prop.row.persistentVolume.metadata.creationTimestamp}
              />
            );
          },
        },
        {
          key: 'spec.capacity',
          label: 'Capacity',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {calculateCapacity(
                  prop.row.persistentVolume.spec.capacity.storage,
                )}
              </KubeTableCell>
            );
          },
        },
      ]}
    />
  );
}
