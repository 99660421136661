import { AutocompleteV2 } from 'components';
import React, { ReactElement } from 'react';

import useLogsFacetExplorerState from './useLogsFacetExplorerState';

const LogsFacetExplorerSources = ({
  logsFacetExplorerState,
}: {
  logsFacetExplorerState: ReturnType<typeof useLogsFacetExplorerState>;
}): ReactElement => {
  const { getLogsSourcesRequest, selectedSource, onSourceChange } =
    logsFacetExplorerState;

  return (
    <div className="w-[540px]">
      <div className="pb-1 font-bold">Choose a source</div>
      <AutocompleteV2
        options={getLogsSourcesRequest.result || []}
        onChange={(value: string) => onSourceChange(value)}
        value={selectedSource}
        isLoading={getLogsSourcesRequest.isLoading}
        placeholder="Search logs source"
      />
    </div>
  );
};

export default LogsFacetExplorerSources;
