import { ValueCount } from 'types';
import { keyBy, merge, values } from 'lodash';

export const mergeFacetValues = ({
  oldFacetValues,
  newFacetValues,
}: {
  oldFacetValues: ValueCount[];
  newFacetValues: ValueCount[];
}): ValueCount[] => {
  const oldFacetValuesMap = keyBy(oldFacetValues, 'value');
  const newFacetValuesMap = keyBy(newFacetValues, 'value');

  const mergedFacetValuesMap = merge(oldFacetValuesMap, newFacetValuesMap);

  return values(mergedFacetValuesMap);
};
