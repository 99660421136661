import { ExpressionBuilder, Suggestions } from 'components';
import { kubeLabelNames } from 'requests';
import React, {
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useState,
} from 'react';
import { FacetValueInfo, FacetValueInfoByFacetKey } from 'types';
import { isEqual } from 'lodash';
import { ChipRef, ElementType, Expression } from 'components/ExpressionBuilder';

import { onKeydownHandler } from 'components/ExpressionBuilder/utils';
import useSuggestions from './useSuggestions';
import useOnSuggestionSelect from './useOnSuggestionSelect';
import {
  fromExpressions,
  toExpressions,
  getShouldShowAddOrClauseInfo,
  getShouldShowOperatorsInfo,
} from './utils';
import { useKubernetesController } from '../KubernetesController';
import useAsync from '../hooks/useAsync';

const KubernetesSearch = () => {
  const [shouldUpdateUrlIfNecessary, setShouldUpdateUrlIfNecessary] =
    useState(false);
  const {
    flux,
    entitiesType,
    facets: selectedFacetValuesByName,
  } = useKubernetesController();

  const urlExpressions: Array<Expression> = useMemo(
    () => toExpressions(selectedFacetValuesByName),
    [selectedFacetValuesByName],
  );

  const setUrlExpressions = useCallback(
    (expressions: Array<Expression>): void => {
      flux.facetValueReplace(
        fromExpressions(
          expressions.filter((expression) => {
            return expression.values.filter(
              (value) => !['"', '""'].includes(value),
            ).length;
          }),
        ),
      );
    },
    [flux],
  );

  const [facetValuesByFacetNameThenK8sIdentifier] = useAsync<{
    search: any;
    sidebar: any;
    entities: Array<KubernetesTableRow>;
  }>(
    async (signal: AbortSignal) => {
      const { kubeFacetCounts } = await kubeLabelNames(
        {
          entityType: entitiesType,
          selectedFacetValuesByName: {},
        },
        { signal },
      );

      const getFacetValuesByFacetName = (
        facetValueInfos: Array<FacetValueInfo>,
      ): FacetValueInfoByFacetKey =>
        facetValueInfos.reduce<FacetValueInfoByFacetKey>(
          (acc, { facetKey, facetValue, count }) => {
            acc[facetKey] ||= [];
            acc[facetKey].push({
              facetValue,
              count,
            });
            return acc;
          },
          {},
        );

      return {
        tags: getFacetValuesByFacetName(kubeFacetCounts.tags ?? []),
        labels: getFacetValuesByFacetName(kubeFacetCounts.labels ?? []),
        annotations: getFacetValuesByFacetName(
          kubeFacetCounts.annotations ?? [],
        ),
      };
    },
    [entitiesType],
  );

  const initializerRef = useRef<HTMLInputElement | null>(null);
  const suggestionsRef = useRef<HTMLDivElement | null>(null);
  const chipRefs = useRef<Array<ChipRef>>([]);

  const state = ExpressionBuilder.useExpressionBuilderState({
    urlExpressions,
    initializerRef,
    chipRefs,
  });

  const {
    expressions,
    getExpressionIfExists,
    createExpression,
    removeExpression,
    getPropertyIfExists,
    setProperty,
    getValueIfExists,
    setValue,
    removeValue,
    setOperator,
    focusedElementInfo,
    setFocusedElementInfo,
    setElementToFocusInfo,
  } = state;

  const updateUrlIfNecessary = useCallback(() => {
    if (!isEqual(expressions, urlExpressions)) {
      setUrlExpressions(expressions);
      initializerRef.current.focus();
      setIsSuggestionsForceClosed(true);
    }
  }, [expressions, setUrlExpressions, urlExpressions]);

  useEffect(() => {
    if (!shouldUpdateUrlIfNecessary) {
      return;
    }
    updateUrlIfNecessary();
    setShouldUpdateUrlIfNecessary(false);
  }, [shouldUpdateUrlIfNecessary, updateUrlIfNecessary]);

  const suggestions: Array<{
    id: string;
    label: string;
  }> = useSuggestions({
    getPropertyIfExists,
    getValueIfExists,
    focusedElementInfo,
    facetValuesByFacetNameThenK8sIdentifier:
      facetValuesByFacetNameThenK8sIdentifier ?? {
        tags: {},
        labels: {},
        annotations: {},
      },
  });

  const onSuggestionSelect = useOnSuggestionSelect({
    suggestions,
    expressions,
    createExpression,
    setProperty,
    setOperator,
    setValue,
    focusedElementInfo,
    setElementToFocusInfo,
    setShouldUpdateUrlIfNecessary,
  });

  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<
    number | null
  >(null);

  const [isSuggestionsForceClosed, setIsSuggestionsForceClosed] =
    useState(false);

  const shouldShowSuggestions =
    !isSuggestionsForceClosed && focusedElementInfo !== null;

  const handleContainerKeydown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      onKeydownHandler.container({
        chipRefs,
        initializerRef,
        updateUrlIfNecessary,
        activeSuggestionIndex,
        setActiveSuggestionIndex,
        onSuggestionSelect,
        suggestions,
      })(event);
    },
    [
      activeSuggestionIndex,
      onSuggestionSelect,
      suggestions,
      updateUrlIfNecessary,
    ],
  );

  return (
    <div className="flex" data-testid="kubernetes-expression-bldr">
      <ExpressionBuilder.Header isBorderedLeft={true} isRoundedLeft={true}>
        Filter by
      </ExpressionBuilder.Header>
      <ExpressionBuilder.RelativeContainer
        focusedElementInfo={focusedElementInfo}
        initializerRef={initializerRef}
      >
        <ExpressionBuilder.Chips
          focusedElementInfo={focusedElementInfo}
          setFocusedElementInfo={setFocusedElementInfo}
          initializerRef={initializerRef}
          onKeyDown={handleContainerKeydown}
          searchTerm=""
          onSearchTermChange={(e) => {
            createExpression({
              property: e.target.value,
              operator: '',
            });
            setElementToFocusInfo({
              type: ElementType.PROPERTY,
              index: expressions.length,
            });
          }}
          shouldShowSearchIcon={true}
          isSuggestionsForceClosed={isSuggestionsForceClosed}
          setIsSuggestionsForceClosed={setIsSuggestionsForceClosed}
        >
          {expressions.map((expression, index) => (
            <ExpressionBuilder.Chip
              data-testid={expression.property}
              ref={(ref) => (chipRefs.current[index] = ref)}
              key={index}
              index={index}
              expression={expression}
              getPropertyIfExists={getPropertyIfExists}
              setProperty={setProperty}
              getValueIfExists={getValueIfExists}
              setValue={setValue}
              setOperator={setOperator}
              removeExpression={(payload) => {
                removeExpression(payload);
                setShouldUpdateUrlIfNecessary(true);
              }}
              removeValue={removeValue}
              focusedElementInfo={focusedElementInfo}
              setFocusedElementInfo={setFocusedElementInfo}
              setElementToFocusInfo={setElementToFocusInfo}
            />
          ))}
        </ExpressionBuilder.Chips>
        <Suggestions.Container
          isOpen={shouldShowSuggestions}
          suggestionsRef={suggestionsRef}
          footer={
            <Suggestions.Footer
              shouldShowAddOrClauseInfo={getShouldShowAddOrClauseInfo({
                focusedElementInfo,
                getExpressionIfExists,
              })}
              shouldShowOperatorsInfo={getShouldShowOperatorsInfo({
                focusedElementInfo,
              })}
            />
          }
        >
          {suggestions.map((suggestion, suggestionIdx) => (
            <Suggestions.Suggestion
              key={suggestion.id}
              isActive={suggestionIdx === activeSuggestionIndex}
              activate={() => setActiveSuggestionIndex(suggestionIdx)}
              deactivate={() => setActiveSuggestionIndex(null)}
              onClick={() => onSuggestionSelect(suggestionIdx)}
            >
              {suggestion.label}
            </Suggestions.Suggestion>
          ))}
        </Suggestions.Container>
      </ExpressionBuilder.RelativeContainer>
    </div>
  );
};

export default KubernetesSearch;
