import dayjs from 'dayjs';
import { DateSelection, SelectedFacetValuesByName } from 'types';
import {
  buildPromQLFilterFromSelectedFacetValuesByName,
  onPromiseError,
} from 'utils';
import fetchJson from './fetchJson';

type Props = {
  customerFilter?: { key: string; value: string };
  date: DateSelection;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  serviceHash: string;
  spanTypeFilter?: { filter: string; operator: string };
};

const apmSeriesV2 = ({
  customerFilter,
  date,
  selectedFacetValuesByName,
  serviceHash,
  spanTypeFilter,
}: Props): Promise<string[]> => {
  const { endTimeUnix, startTimeUnix } = date;
  const start = dayjs.unix(startTimeUnix).toISOString();
  const end = dayjs.unix(endTimeUnix).toISOString();
  const filters = buildPromQLFilterFromSelectedFacetValuesByName({
    selectedFacetValuesByName: {
      ...selectedFacetValuesByName,
      ...(serviceHash ? { service_hash: { [serviceHash]: 1 } } : {}),
      ...(customerFilter &&
      customerFilter.key &&
      customerFilter.value &&
      customerFilter.value !== 'All'
        ? { [customerFilter.key]: { [customerFilter.value]: 1 } }
        : {}),
    },
    spanTypeFilter,
  });
  return fetchJson(
    `/api/v1/labels?match[]=edge_latency_sum${filters}&start=${start}&end=${end}`,
  ).then((result) => result?.data || [], onPromiseError);
};

export default apmSeriesV2;
