import { FormulaState, SearchState } from 'hooks/useSearch';
import {
  DashboardPanelProps,
  DashboardPanelTargetsProps,
  DashboardPanelType,
} from 'types/Dashboard';
import { getPanelFieldConfig } from './panelManipulation';

export const getEventPanelExprToQueryAndFormula = (
  panel: DashboardPanelProps,
): {
  queries: SearchState[];
  formulas: FormulaState[];
} => {
  const queries: SearchState[] = [];
  const formulas: FormulaState[] = [];
  if (!panel?.targets[0]?.expr) {
    return { queries: undefined, formulas: undefined };
  }
  const expr = panel.targets[0].expr;
  const parsedExpr = JSON.parse(expr);

  if (parsedExpr.query) {
    queries.push(parsedExpr.query);
  }

  if (parsedExpr.formula) {
    formulas.push(parsedExpr.formula);
    queries.push(...(parsedExpr.queries || []));
  }

  if (parsedExpr.queries && !parsedExpr.query) {
    queries.push(...parsedExpr.queries);
  }

  if (parsedExpr.formulas && !parsedExpr.formula) {
    formulas.push(...(parsedExpr.formulas || []));
  }

  return { queries, formulas };
};

export const getEditedPanelForEvents = ({
  editedPanel,
  queries,
}: {
  editedPanel: DashboardPanelProps;
  queries: SearchState[];
}): DashboardPanelProps => {
  const panelType = editedPanel.type;
  const newExpr = JSON.stringify({ queries });

  const targets: DashboardPanelTargetsProps[] = [];
  const range = panelType === DashboardPanelType.TIMESERIES ? true : false;
  const fieldConfig = getPanelFieldConfig({
    prevFieldConfig: editedPanel.fieldConfig,
  });
  const datasource = { type: 'event', uid: '' };

  const commonTargetProps = {
    datasource,
    editorMode: 'code',
    range,
    instant: range ? false : true,
    refId: 'a',
    expr: newExpr,
  };
  targets.push(commonTargetProps);

  const options: DashboardPanelProps['options'] = {
    legend: { calcs: [], displayMode: 'list', placement: 'bottom' },
  };

  const panel = {
    ...editedPanel,
    targets,
    fieldConfig,
    options,
    datasource,
    isEdited: true,
  };

  return panel;
};
