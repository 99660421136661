import { LogLabels } from 'kfuse-constants';
import { formatDurationNs } from 'utils';

export const MAX_SPANS_TO_RENDER = 1000;

export const spanListColumns = [
  { key: 'name', label: 'Name' },
  {
    key: 'spansCount',
    label: 'Spans',
    render: (value: number) => (typeof value === 'number' ? value : '-'),
  },
  {
    key: 'averageDuration',
    label: 'Duration',
    render: (value: number, additionalLabel?: string) =>
      typeof value === 'number'
        ? `${formatDurationNs(value, 1, 1)}${additionalLabel}`
        : '-',
  },
  {
    key: 'execTime',
    label: 'Exec Time',
    render: (value: number) =>
      typeof value === 'number' ? formatDurationNs(value, 1, 2) : '-',
  },
  {
    key: 'execPercentage',
    label: 'Exec %',
    render: (value: number) =>
      typeof value === 'number' ? `${Math.round(value * 100)}%` : '-',
  },
];

export const logFacetsBitmap: Record<string, number> = [
  ...Object.keys(LogLabels),
].reduce((obj, key) => ({ ...obj, [LogLabels[key].name]: 1 }), {});
