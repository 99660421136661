import { useState } from 'react';
import { TableColumn } from './types';

type Args = {
  columns: TableColumn<any>[];
  initialKey?: string;
  rows: any;
  onSortChange?: (sort: { sortBy: string; sortOrder: string }) => void;
};

type State = {
  key: string;
  asc: boolean;
};

const useTableBESort = ({ initialKey, rows, onSortChange }: Args) => {
  const [state, setState] = useState<State>({
    asc: false,
    key: initialKey || null,
  });
  const { asc, key } = state;
  const onSort = (nextKey: string) => {
    onSortChange({
      sortBy: nextKey,
      sortOrder: nextKey === key ? (asc ? 'desc' : 'asc') : 'asc',
    });
    setState((prevState) => ({
      key: nextKey,
      asc: nextKey === prevState.key ? !prevState.asc : true,
    }));
  };

  return {
    ...state,
    onSort,
    sortedRows: rows,
  };
};

export default useTableBESort;
