import classNames from 'classnames';
import { Icon, Input, InputProps } from 'components';
import React, { HTMLAttributes } from 'react';

type SearchInputProps = {
  containerClassName?: string;
  dataTestId?: string;
} & InputProps &
  Omit<HTMLAttributes<HTMLInputElement>, 'onChange'>;

const SearchInput = ({
  containerClassName,
  dataTestId,
  ...rest
}: SearchInputProps) => {
  return (
    <div
      className={classNames(
        'relative flex h-6 items-center',
        containerClassName,
      )}
      data-testid={dataTestId}
    >
      <span className="absolute left-2 text-text-tertiary">
        <Icon icon="search" size="sm" />
      </span>
      <Input type="text" variant="search" {...rest} />
    </div>
  );
};

export default SearchInput;
