import { PopoverTriggerV2, Icon } from 'components';
import React, { ReactElement } from 'react';
import { FingerprintQueryProps } from 'types';

const getArrow = (
  key: string,
  sortBy: string,
  sortOrder: FingerprintQueryProps['sortOrder'],
) => {
  if (key === sortBy) {
    if (sortOrder === 'Asc') {
      return (
        <div className="table__header-sortable__icon">
          <Icon icon="chevron-up" size="xs" />
        </div>
      );
    }
    return (
      <div className="table__header-sortable__icon">
        <Icon icon="chevron-down" size="xs" />
      </div>
    );
  }

  return '';
};

const LogsFingerprintsListHeader = ({
  fingerprintGroupByKeys,
  fingerprintQuery,
  onSortFingerprintChange,
}: {
  fingerprintGroupByKeys: string[];
  fingerprintQuery: FingerprintQueryProps;
  onSortFingerprintChange: (
    key: string,
    sortOrder: FingerprintQueryProps['sortOrder'],
  ) => void;
}): ReactElement => {
  const { sortBy, sortOrder } = fingerprintQuery;

  const toggleSortOrder = (key) => {
    const newSortOrder = key === sortBy && sortOrder === 'Asc' ? 'Desc' : 'Asc';
    onSortFingerprintChange(key, newSortOrder);
  };
  return (
    <div className="logs__fingerprints-list__item logs__fingerprints-list__item--header">
      {fingerprintGroupByKeys.map((key, idx) => (
        <div
          key={`${key}:${idx}`}
          className="logs__fingerprints-list__item__source flex--justify-content-between flex"
          onClick={() => toggleSortOrder(key)}
        >
          <div className="flex--align-items-center flex hover:underline">
            {key}
            {getArrow(key, sortBy, sortOrder)}
          </div>
        </div>
      ))}
      <div className="logs__fingerprints-list__item__main">Fingerprint</div>
      <div className="logs__fingerprints-list__item__count logs__fingerprints-list__item__count--header">
        {fingerprintGroupByKeys.map((key, idx) => {
          if (idx > 0) return null;
          return (
            <div
              key={`${key}:${idx}`}
              className="logs__fingerprints-list__item__count--header"
              onClick={() => toggleSortOrder('')}
            >
              <div className="flex--align-items-center flex hover:underline">
                Count {getArrow('', sortBy, sortOrder)}
              </div>
            </div>
          );
        })}
      </div>
      <div className="logs__fingerprints-list__item__chart">Chart</div>
    </div>
  );
};

export default LogsFingerprintsListHeader;
