import { DashboardLogsWithMetaProps } from 'types';

import getLogMetricsResultWithKfuseQl from './getLogMetricsResultWithKfuseQl';
import { DataTransformerConfig } from 'utils/DataTransformer';

export type KfuseQLMultipleProps = {
  instant: boolean;
  logqlWithMeta: DashboardLogsWithMetaProps[];
  mainTransformer: DataTransformerConfig[];
};

const getLogMetricsResultWithKfuseQlMultiple = async ({
  instant = false,
  logqlWithMeta,
  mainTransformer,
}: KfuseQLMultipleProps) => {
  if (logqlWithMeta) {
    const data = await Promise.all(
      logqlWithMeta.map(({ kfuseql, ...rest }) =>
        getLogMetricsResultWithKfuseQl({ ...rest, instant, kfuseQl: kfuseql }),
      ),
    );
    if (mainTransformer) {
      const transformed = mainTransformer.reduce(
        (prevData, item) => item.func(prevData),
        data,
      );
      return transformed;
    }
    return data;
  }
};

export default getLogMetricsResultWithKfuseQlMultiple;
