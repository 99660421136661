const formatFileSize = (size: string | number) => {
  let sizeNumber = 0;
  try {
    if (typeof size === 'string') {
      sizeNumber = parseFloat(size);
    }
    if (typeof size === 'number') {
      sizeNumber = size;
    }
    if (isNaN(sizeNumber)) {
      return '';
    }
    if (sizeNumber === 0) {
      return '0 B';
    }
    const i =
      sizeNumber == 0 ? 0 : Math.floor(Math.log(sizeNumber) / Math.log(1024));
    return (
      +(sizeNumber / Math.pow(1024, i)).toFixed(2) * 1 +
      ' ' +
      ['B', 'kB', 'MB', 'GB', 'TB'][i]
    );
  } catch (e) {
    console.error(e);
    return '';
  }
};

export default formatFileSize;
