import uPlot from 'uplot';

import { getActivePointPosition } from './tooltipUtils';

const getPositionsBySeriesIndex = (
  u: uPlot,
  seriesIdx: number,
): { x: number; y: number }[] => {
  const seriesData = u.data[seriesIdx];
  const positions = Array(seriesData.length).fill(undefined);
  seriesData.forEach((_, pointIdx) => {
    positions[pointIdx] = getActivePointPosition(u, seriesIdx, pointIdx);
  });
  return positions;
};

export default getPositionsBySeriesIndex;
