import { FacetGroup, FacetPicker } from 'components';
import { useRequest } from 'hooks';
import { MICROSECONDS } from 'kfuse-constants';
import { flatten, partition, startCase } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import {
  DateSelection,
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  ValueCount,
} from 'types';
import { getFacetKey } from 'utils';
import helpText from '../../helpText';
import useRumState from './hooks/useRumState';
import rumLabelValues from './requests/rumLabelValues';
import groupRumLabels from './utils/groupRumLabels';
import { RumEventType } from './types';
import isRangeFacet, { RangeFacetsMap } from './utils/isRangeFacet';
import { minMaxForLabel } from './requests';
import valueRange from './requests/valueRange';
import sidebarFacets from './requests/sidebarFacets';
import { isDurationFacet } from './utils';

type Props = {
  eventType: RumEventType;
  getLabelValues?: (args: {
    applicationFilter: string;
    date: DateSelection;
    eventType: RumEventType;
    facetRegex?: FacetRegexTerm[];
    idSearch: string;
    labelName: string;
    selectedFacetRangeByName?: SelectedFacetRangeByName;
    selectedFacetValuesByName: SelectedFacetValuesByName;
  }) => Promise<ValueCount[]>;
  rumLabelNamesRequest: ReturnType<typeof useRequest>;
  rumState: ReturnType<typeof useRumState>;
};

const attributesByName: {
  [key: string]: {
    forceExpanded: boolean;
    renderName: () => string;
  };
} = {
  env: {
    forceExpanded: true,
    renderName: () => 'Environment',
  },
  source: {
    forceExpanded: true,
    renderName: () => 'Source',
  },
};

const sideBarFacetsFieldNameMap: { [key: string]: string } =
  sidebarFacets.reduce((acc: { [key: string]: string }, facet) => {
    acc[facet.field] = facet.name;
    return acc;
  }, {});

const groupBasedOnEventType = (eventType: RumEventType) => {
  if (eventType === RumEventType.VIEW) {
    return 'view';
  }
  if (eventType === RumEventType.SESSION) {
    return 'session';
  }
  if (eventType === RumEventType.ACTION) {
    return 'action';
  }
  if (eventType === RumEventType.ERROR) {
    return 'error';
  }
  if (eventType === RumEventType.LONGTASK) {
    return 'long_task';
  }
  if (eventType === RumEventType.RESOURCE) {
    return 'resource';
  }

  return 'view';
};

const convertMinMaxNsDurationToMs = (minMax: { min: number; max: number }) => ({
  min: Math.floor(minMax.min / MICROSECONDS),
  max: Math.ceil(minMax.max / MICROSECONDS),
});

const RumSidebar = ({
  eventType,
  getLabelValues = rumLabelValues,
  rumLabelNamesRequest,
  rumState,
}: Props) => {
  const {
    applicationFilter,
    dateState,
    facetRegexState,
    idSearch,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
  } = rumState;

  const [date] = dateState;

  const clearFacetHandler = (name: string) => () => {
    selectedFacetValuesByNameState.clearFacet(name);
  };

  const clearFacetRangeHandler = (name: string) => () => {
    selectedFacetRangeByNameState.clearFacet(name);
  };

  const handlersByName = (name: string) => ({
    changeFacetRange: (range: SelectedFacetRangeByName) => {
      selectedFacetRangeByNameState.changeFacetRange({ name })(range);
    },
    excludeFacetValue: (value: string) => {
      selectedFacetValuesByNameState.excludeFacetValue({ name, value });
    },
    selectOnlyFacetValue: (value: string) => {
      selectedFacetValuesByNameState.selectOnlyFacetValue({ name, value });
    },
    toggleFacetValue: (value: string, allValues: Array<string>) => {
      selectedFacetValuesByNameState.toggleFacetPickerValueCheckbox({
        facetName: name,
        facetValueToToggle: value,
        allFacetValues: allValues,
      });
    },
  });

  const [lastRefreshedAt, setLastRefreshedAt] = useState(null);

  const eventTypeGroupName = useMemo(
    () => groupBasedOnEventType(eventType),
    [eventType],
  );

  const requestByLabelName = (labelName: string) => () =>
    getLabelValues({
      applicationFilter,
      date,
      eventType,
      facetRegex: facetRegexState?.state,
      labelName,
      idSearch,
      selectedFacetRangeByName: selectedFacetRangeByNameState.state,
      selectedFacetValuesByName: selectedFacetValuesByNameState.state,
    });

  const maxByLabelName = (labelName: string) => () => {
    if (eventType === RumEventType.SESSION) {
      return valueRange({
        applicationFilter,
        date,
        eventType,
        facetRegex: facetRegexState?.state,
        idSearch,
        labelName,
        selectedFacetValuesByName: selectedFacetValuesByNameState.state,
      }).then((result) => {
        const isDuration = isDurationFacet(labelName);
        const minMax = result
          ? result
          : { min: 0, max: isDuration ? MICROSECONDS * 10 : 10 };
        return isDuration ? convertMinMaxNsDurationToMs(minMax) : minMax;
      });
    }
    return minMaxForLabel({
      applicationFilter,
      date,
      eventType,
      facetRegex: facetRegexState?.state,
      labelName,
      idSearch,
      selectedFacetValuesByName: selectedFacetValuesByNameState.state,
    }).then((result) => {
      const isDuration = isDurationFacet(labelName);
      const minMax = result?.[0]?.aggregates?.length
        ? {
            min: result?.[0]?.aggregates[0],
            max: result?.[0]?.aggregates[1],
          }
        : { min: 0, max: isDuration ? MICROSECONDS * 10 : 10 };

      return isDuration ? convertMinMaxNsDurationToMs(minMax) : minMax;
    });
  };

  useEffect(() => {
    setLastRefreshedAt(new Date().valueOf());
  }, [
    applicationFilter,
    date,
    eventType,
    facetRegexState?.state,
    idSearch,
    selectedFacetRangeByNameState.state,
    selectedFacetValuesByNameState.state,
  ]);

  const { facetNamesByGroup, ungrouped } = useMemo(() => {
    if (rumLabelNamesRequest.result) {
      return groupRumLabels(rumLabelNamesRequest.result as string[]);
    }

    return {
      facetNamesByGroup: {},
      ungrouped: [],
    };
  }, [rumLabelNamesRequest.result]);

  const renderName = (s: string) => {
    const isDuration = isDurationFacet(s);
    if (isDuration) {
      return `${sideBarFacetsFieldNameMap[s] || s} (ms)`;
    }
    return sideBarFacetsFieldNameMap[s] || s;
  };

  const renderHelp = (s) => {
    if (helpText.hasOwnProperty(s)) {
      return helpText[s];
    }

    return null;
  };

  const sortedUngroupedNames = flatten(
    partition(
      ungrouped.sort((a, b) => renderName(a).localeCompare(renderName(b))),
      (group) => attributesByName[group]?.forceExpanded,
    ),
  );

  const renderPlaceholderText = (name: string) => `No attributes for ${name}`;

  const shouldForceExpand = (isFacetRange, name) =>
    isFacetRange
      ? selectedFacetRangeByNameState.state[name]
      : selectedFacetValuesByNameState.state[name];

  const renderGroup = (group, idx, arr) => (
    <FacetGroup
      group={startCase(group)}
      key={group}
      isLastListItem={idx === arr.length - 1}
      forceExpanded
    >
      {facetNamesByGroup[group].map((name) => {
        const isFacetRange = isRangeFacet(name);
        const facet = {
          component: '',
          name,
          type: isFacetRange
            ? RangeFacetsMap[name as keyof typeof RangeFacetsMap]
            : 'STRING',
          displayName: name,
        };
        const facetKey = getFacetKey(facet);

        return (
          <FacetPicker
            clearFacet={
              isFacetRange
                ? clearFacetRangeHandler(facetKey)
                : clearFacetHandler(name)
            }
            disabled={name === 'application.id'}
            isRangeFacet={isFacetRange}
            key={name}
            lastRefreshedAt={lastRefreshedAt}
            name={name}
            info={renderHelp(name)}
            renderName={renderName}
            renderPlaceholderText={renderPlaceholderText}
            request={
              isFacetRange ? maxByLabelName(name) : requestByLabelName(name)
            }
            selectedFacetValues={
              selectedFacetValuesByNameState.state[name] || {}
            }
            selectedFacetRange={
              selectedFacetRangeByNameState.state[facetKey] || null
            }
            forceExpanded={shouldForceExpand(isFacetRange, facetKey)}
            {...(isFacetRange
              ? handlersByName(facetKey)
              : handlersByName(name))}
          />
        );
      })}
    </FacetGroup>
  );

  return (
    <>
      {sortedUngroupedNames.map((name: string) => {
        const attributes = attributesByName[name];
        const isFacetRange = isRangeFacet(name);
        const facet = {
          component: '',
          name,
          type: isFacetRange
            ? RangeFacetsMap[name as keyof typeof RangeFacetsMap]
            : 'STRING',
          displayName: name,
        };
        const facetKey = getFacetKey(facet);

        return (
          <FacetPicker
            clearFacet={
              isFacetRange
                ? clearFacetRangeHandler(facetKey)
                : clearFacetHandler(name)
            }
            isRangeFacet={isFacetRange}
            key={name}
            lastRefreshedAt={lastRefreshedAt}
            name={name}
            info={helpText[name]}
            renderName={renderName}
            renderPlaceholderText={renderPlaceholderText}
            request={
              isFacetRange ? maxByLabelName(name) : requestByLabelName(name)
            }
            selectedFacetValues={
              selectedFacetValuesByNameState.state[name] || {}
            }
            selectedFacetRange={
              selectedFacetRangeByNameState.state[facetKey] || null
            }
            forceExpanded={shouldForceExpand(isFacetRange, facetKey)}
            {...(isFacetRange
              ? handlersByName(facetKey)
              : handlersByName(name))}
            {...(attributes ? attributes : {})}
          />
        );
      })}
      <div className="left-sidebar__divider" />
      {['geo', 'core', eventTypeGroupName]
        .filter(
          (group) =>
            facetNamesByGroup[group] && facetNamesByGroup[group].length,
        )
        .map(renderGroup)}
      <div className="left-sidebar__divider" />
      {[...Object.keys(facetNamesByGroup).sort()]
        .filter(
          (group) =>
            group !== 'geo' &&
            group !== 'core' &&
            group !== eventTypeGroupName &&
            facetNamesByGroup[group] &&
            facetNamesByGroup[group].length,
        )
        .map(renderGroup)}
    </>
  );
};

export default RumSidebar;
