import { SelectedFacetValues, SelectedFacetValuesByName } from 'types';

export const getSpanTypeFilterForServiceAPI = (
  spanType: SelectedFacetValues,
) => {
  const value = spanType?.['db'];
  switch (value) {
    case 1:
      return 'database';
    case 0:
      return 'service';
    default:
      return null;
  }
};

export const getSelectedFacetValueByNameWithCustomerFilters = ({
  customerFilter,
  selectedFacetValuesByName,
}: {
  customerFilter: { key: string; value: string };
  selectedFacetValuesByName: SelectedFacetValuesByName;
}): SelectedFacetValuesByName => {
  return {
    ...selectedFacetValuesByName,
    ...(customerFilter &&
    customerFilter.key &&
    customerFilter.value &&
    customerFilter.value !== 'All'
      ? { [customerFilter.key]: { [customerFilter.value]: 1 } }
      : {}),
  };
};
