import { Hostmap, Loader } from 'components';
import React, { ReactElement, useMemo } from 'react';
import { DashboardPanelProps, DashboardStreamType } from 'types/Dashboard';

const WIDTH_LEFT_RIGHT_OFFSET = 32;

const DashboardEditGraphHostmap = ({
  baseWidth,
  dataFormatter,
  panel,
  stream,
}: {
  baseWidth: number;
  dataFormatter: () => any;
  panel: DashboardPanelProps;
  stream?: DashboardStreamType;
}): ReactElement => {
  const hostmapQueryData = useMemo(() => {
    const formattedHostmap = dataFormatter();
    return formattedHostmap;
  }, [dataFormatter]);

  return (
    <Loader isLoading={hostmapQueryData?.isLoading}>
      <div className="dashboard-edit__metric__body__hostmap">
        <Hostmap
          data={hostmapQueryData?.data || []}
          height={340}
          layoutType="modal"
          width={baseWidth - WIDTH_LEFT_RIGHT_OFFSET}
        />
      </div>
    </Loader>
  );
};

export default DashboardEditGraphHostmap;
