import React from 'react';
import { BsFilterLeft, BsPieChartFill } from 'react-icons/bs';
import { AiOutlineLineChart } from 'react-icons/ai';
import { HiTableCells } from 'react-icons/hi2';
import { TbLayoutBoardSplit } from 'react-icons/tb';
import { DataFrameMeta } from 'utils/DataTransformer';

import {
  DashboardPanelOverrideProps,
  DashboardPanelTableTransformProps,
  DashboardPanelType,
  QueryDataProps,
} from 'types';

import { AnalyticsChartProps } from './types';

export const visualizationListMap: {
  [key in DashboardPanelType]: {
    label: string;
    icon: React.ReactElement;
  };
} = {
  [DashboardPanelType.TIMESERIES]: {
    label: 'Time Series',
    icon: <AiOutlineLineChart />,
  },
  [DashboardPanelType.TOP_LIST]: {
    label: 'Top List',
    icon: <BsFilterLeft />,
  },
  [DashboardPanelType.TABLE]: {
    label: 'Table',
    icon: <HiTableCells />,
  },
  [DashboardPanelType.PIECHART]: {
    label: 'Pie Chart',
    icon: <BsPieChartFill />,
  },
  [DashboardPanelType.TREEMAP]: {
    label: 'Treemap',
    icon: <TbLayoutBoardSplit />,
  },
};

export const mapChartTypeWithDashboard: { [key: string]: string } = {
  Line: 'lines',
  'Stacked Bar': 'bars',
  Area: 'area',
};

const getOverrideByName = (
  name: string,
  unit: string,
): DashboardPanelOverrideProps => {
  return {
    matcher: { id: 'byName', options: name },
    properties: [{ id: 'unit', value: unit, decimals: 2 }],
  };
};

export const getAnalyticsChartTransformations = ({
  chartData,
  chartFormulas,
  chartQueries,
  excludeColumns,
  visualization,
}: {
  chartData: QueryDataProps;
  chartFormulas: AnalyticsChartProps['chartFormulas'];
  chartQueries: AnalyticsChartProps['chartQueries'];
  excludeColumns?: { [key: string]: boolean };
  visualization: DashboardPanelType;
}): DashboardPanelTableTransformProps[] => {
  const chartDataKeys = Object.keys(chartData);
  const chartDataLabels: string[] = [];
  const renamedValues: Record<string, string> = {};
  const overrides: DashboardPanelOverrideProps[] = [];
  let unit = undefined;
  let direction = 'topk';

  chartDataKeys.map((key) => {
    const [type, queryKey] = key.split('_');
    const data = chartData[key];

    let query = null;
    if (type === 'query') {
      query = chartQueries.find((q) => q.queryKey === queryKey);
    }
    if (type === 'formula') {
      query = chartFormulas.find((f) => f.queryKey === queryKey);
    }

    const valueWithRef = `Value #${queryKey}`;
    if (type === 'formula' && query && query.isActive && data.instant) {
      renamedValues[valueWithRef] = data.meta.aggregate;
    }

    if (query && query.isActive && data.instant) {
      const labels = data.meta.labels || [];
      chartDataLabels.push(...labels);
      unit = data.meta.unit;
      direction = query.direction;
      if (unit) {
        overrides.push(getOverrideByName(valueWithRef, unit));
      }

      renamedValues[valueWithRef] = data.meta.aggregate;
    }
  });

  const transformations = [
    { id: 'merge', options: null },
    {
      id: 'organize',
      options: {
        excludeByName: {
          Time: true,
          ...(excludeColumns ? excludeColumns : {}),
        },
        renameByName: renamedValues,
        indexByName: chartDataLabels.reduce(
          (acc: Record<string, number>, label, index) => {
            acc[label] = index;
            return acc;
          },
          {},
        ),
      },
    },
    { id: 'override', options: overrides },
  ];

  if (visualization === DashboardPanelType.PIECHART) {
    transformations.push({ id: 'toPieChart', options: null });
  }

  if (visualization === DashboardPanelType.TOP_LIST) {
    transformations.push({ id: 'toTopList', options: { unit, direction } });
  }

  if (visualization === DashboardPanelType.TREEMAP) {
    transformations.push({ id: 'toTreeMap', options: { unit } });
  }

  if (visualization === DashboardPanelType.GEOMAP) {
    transformations.push({ id: 'toGeomap', options: { unit } });
  }
  return transformations;
};

export const hasChartAdvanceVisualization = (
  chartQueries: AnalyticsChartProps['chartQueries'],
): boolean => {
  let hasAdvanceVisualization = false;
  chartQueries.forEach((query) => {
    if (!query.isActive) return;
    if (!query.logql || !query.logql.meta) return;
    const meta = query.logql.meta as DataFrameMeta;

    if (meta.isAnomaly || meta.isOutlier || meta.isForecast) {
      hasAdvanceVisualization = true;
    }
  });
  return hasAdvanceVisualization;
};
