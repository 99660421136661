import { Loader, SizeObserver } from 'components';
import { useRequest } from 'hooks';
import React from 'react';
import useDebouncedEffect from 'use-debounced-effect';
import { RumEventType } from '../types';
import RumResourceBreakdownContainer from './RumResourceBreakdownContainer';
import getRumEvents from '../requests/getRumEvents';

type Props = {
  applicationFilter?: string;
  startTimeMs: number;
  durationNs: number;
  eventId: string;
  eventType: RumEventType;
};

const RumResourceBreakdown = ({
  applicationFilter,
  startTimeMs,
  durationNs,
  eventId,
  eventType,
}: Props) => {
  const resourceEventRequest = useRequest(getRumEvents, true, true);

  useDebouncedEffect(
    () => {
      if (!eventId) {
        return;
      }
      resourceEventRequest.call({
        applicationFilter,
        startTimeUnix: Math.floor(startTimeMs / 1000),
        endTimeUnix: Math.ceil((startTimeMs + durationNs / 1000000) / 1000),
        eventType,
        selectedFacetValuesByName: {
          'resource.id': {
            [eventId]: 1,
          },
        },
        selectedFacetRangeByName: {},
      });
    },
    {
      timeout: 200,
      ignoreInitialCall: false,
    },
    [eventId, eventType],
  );

  if (resourceEventRequest.isLoading) {
    return <Loader className="h-full" isLoading />;
  }

  if (
    !resourceEventRequest.result ||
    !resourceEventRequest.result.data ||
    !resourceEventRequest.result.data[0]
  ) {
    return null;
  }

  const activeRumEvent = resourceEventRequest.result.data[0];

  return (
    <div className="mx-3 my-1">
      <SizeObserver className="flamegraph">
        {({ width: baseWidth }) => (
          <RumResourceBreakdownContainer
            activeRumEvent={activeRumEvent}
            baseWidth={baseWidth}
            startTimeMs={startTimeMs}
            eventType={eventType}
          />
        )}
      </SizeObserver>
    </div>
  );
};

export default RumResourceBreakdown;
