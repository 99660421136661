import dayjs from 'dayjs';
import { onPromiseError } from 'utils';

import query from './query';

type Args = {
  label: string;
};

const formatValueCountResponseToValues = (
  valueCounts: Array<{ value: string }>,
) => {
  return valueCounts?.map((valueCount) => valueCount.value);
};

const getCustomerLabelValuesForLogs = async ({
  label,
}: Args): Promise<string[]> => {
  const currentTime = dayjs();
  const durationSecs = 7 * 24 * 60 * 60; // 7 days

  return query<Array<{ value: string }>, 'getLabelValues'>(`
    query GetLabelValues {
      getLabelValues(
        durationSecs:  ${durationSecs},
        labelName: "${label}",
        limit: 500,
        timestamp: "${currentTime.format()}",
      ) {
        count
        value
      }
    }
  `)
    .then((data) => data.getLabelValues || [], onPromiseError)
    .then(formatValueCountResponseToValues);
};

export default getCustomerLabelValuesForLogs;
