import { DateSelection, MetricsQueriesDataProps, RequestResult } from 'types';

export const loadAnomalyData = async ({
  date,
  metricName,
  promql,
  queryIndex,
  request,
  type,
  step,
}: {
  date: DateSelection;
  metricName: string;
  promql: string[];
  queryIndex: number;
  request: RequestResult;
  type: 'query' | 'formula';
  step?: number;
}): Promise<MetricsQueriesDataProps> => {
  if (queryIndex < 0 || !promql) return;
  const queryId = `${type}_${queryIndex}_anomaly`;

  const metricNames = [
    `upper_bound(${metricName})`,
    `lower_bound(${metricName})`,
    metricName,
  ];
  const datasets = await Promise.all(
    promql.map((p, idx) => {
      return request
        .call({
          date,
          promqlQueries: [p],
          type: 'timeseries',
          metricNames: [metricNames[idx]],
          steps: [step],
        })
        .catch(() => {});
    }),
  );

  const queryKeys = ['_upper', '_lower', ''];
  const newQueryDataAnomaly: MetricsQueriesDataProps = {};
  datasets.forEach((dataset, index) => {
    newQueryDataAnomaly[`${queryId}${queryKeys[index]}`] = {
      isLoading: false,
      data: dataset,
    };
  });

  return newQueryDataAnomaly;
};
