//to find the corresponding name required for each siderbar, their names appear by default
//on the top right corner of each facet picker/group

type Value = {
  [key: string]: string;
};

const helpText: Value = {
  //traces siderbar keywords
  duration: 'The time taken for an event or operation from start to finish.',
  'service.name':
    'Identifies the name of a service or application component being monitored, helping to distinguish different services within a system.',
  'span.name':
    'Represents the name or description of a specific span in distributed tracing, indicating a unit of work or operation within a distributed system.',
  'span.type':
    'Specifies the type or category of a span in distributed tracing, helping to classify different types of activities within a system.',

  //logs sidebar keywords
  level:
    'Log entry severity, indicating its importance (e.g., info, warning, error).',
  source:
    'Origin of the log entry, often denoting the integration or module name.',

  //services siderbar keywords
  telemetry_sdk_language:
    'Programming language of the integrated telemetry software development kit (SDK).',
  span_type:
    'Specifies the type or category of a span in distributed tracing, helping to classify different types of activities within a system.',
  service_version: 'Version',
  kf_source:
    'The specific category or classification of data collected through telemetry.',
  protocol: 'Protocol used for network communication.',

  //common keywords between sidebars
  error: 'Error type.',

  //cloud facet keywords
  availability_zone:
    "A distinct and isolated data center in a cloud provider's infrastructure, designed to provide fault tolerance and high availability for cloud-based services.",
  cloud_account_id:
    "Unique identifier for a user or organization's account in a public cloud provider's system.",
  instance_type:
    "A predefined configuration of virtual hardware resources in a cloud provider's infrastructure.",
  project:
    'A named, isolated environment within a cloud account for organizing and managing resources.',
  region: "A geographical location hosting a cloud provider's data centers.",
  'cloud.account.id':
    "Unique identifier for a user or organization's account in a public cloud provider's system.",
  'cloud.platform':
    'Specific cloud platform on which the application or system is running (e.g., AWS, Azure, Google Cloud).',
  'cloud.provider':
    'Cloud service provider being used for hosting the application or system.',

  //kubernetes facet keywords
  display_container_name:
    'The name of a container within a system or application that is used for display purposes or identification.',
  image_name:
    'The name of a container image used to create instances of containers with specific functionalities',
  image_tag:
    'A label or version number associated with a container image, indicating its specific version or configuration.',
  kube_cluster_name:
    'The name given to a Kubernetes cluster, which is a group of nodes that run containerized applications managed by Kubernetes.',
  kube_container_name:
    'The name of a container running within a Kubernetes pod, a basic building block of Kubernetes applications.',
  kube_cronjob:
    'A Kubernetes resource used to create jobs that run at specified intervals, defined by a cron-like schedule.',
  kube_daemon_set:
    'A Kubernetes resource that ensures a specific pod runs on all or selected nodes within a cluster.',
  kube_deployment:
    'A Kubernetes resource that represents a set of identical pods, ensuring application scalability and fault tolerance.',
  kube_job:
    'A Kubernetes resource used to manage batch tasks or processes that run to completion.',
  kube_namespace:
    'A logical partition or virtual cluster within a Kubernetes cluster, used to isolate and organize resources.',
  kube_node:
    'A worker machine within a Kubernetes cluster, responsible for running containers and managing their lifecycle.',
  kube_replica_set:
    'A Kubernetes resource that ensures a specified number of replicas (identical pods) are running at all times.',
  kube_stateful_set:
    'A Kubernetes resource used to manage stateful applications by providing a stable network identity for each pod.',
  pod_name:
    'The name given to a specific pod, representing a single instance of a running process in a Kubernetes cluster.',

  //custom facet keywords
  env: 'Represents the environment or deployment stage (e.g., development, staging, production) of a system or application.',
  environment:
    'Serves the same purpose as env, representing the environment or deployment context.',
  telemetry_sdk_version:
    'Version of the telemetry software development kit (SDK) integrated into an application for monitoring and data collection.',
  kf_role:
    'Identifies a specific role or function within a Kubernetes cluster.',
  request_type:
    'Represents the type or category of a specific request made to a service or application.',
  response_code:
    'Indicates the status or result code of a response generated by a service or application.',
  server_container_id:
    'Unique identifier for a container running on a server, allowing individual container tracking and management.',

  //host facet keywords
  'host.arch':
    'A host facet keyword used to represent the architecture or processor type of the host machine (e.g., x86, ARM).',
  'host.id':
    'A host facet keyword representing a unique identifier assigned to the host machine.',
  'host.name':
    'A host facet keyword representing the hostname or name of the host machine, which is typically its network name.',

  //http facet keywords
  request_headers_Host:
    'Refers to the "Host" header field in an HTTP request, which specifies the domain name of the server being accessed by the client.',

  //pg facet keywords
  query_type:
    'Represents the type or category of a query performed on a database or data store (e.g., SELECT, INSERT, UPDATE).',
  table_name:
    'Refers to the name of a specific table within a database or data store where data is organized and stored.',
};

export default helpText;
