export const addEventOverlayDom = (width: number, height: number) => {
  const eventOverlayDiv = document.createElement('div');
  eventOverlayDiv.classList.add('uplot__event-overlay');
  const eventOverlayCanvas = document.createElement('canvas');
  eventOverlayCanvas.classList.add('uplot__event-overlay__canvas');

  const devicePixelRatio = window.devicePixelRatio || 1;
  eventOverlayCanvas.width = width * devicePixelRatio;
  eventOverlayCanvas.height = height * devicePixelRatio;
  eventOverlayDiv.appendChild(eventOverlayCanvas);

  const eventOverlayCtx = eventOverlayCanvas.getContext('2d');
  return { eventOverlayDiv, eventOverlayCtx };
};
