import { ChartGridV2 } from 'components';
import { Filter, useSearch } from 'hooks';
import React, { useMemo } from 'react';
import {
  DateSelection,
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  TimeSeries,
} from 'types';
import {
  convertNumberToReadableUnit,
  formatAggregateTimeSeriesData,
  formatDurationNs,
  getRollupByVisualization,
} from 'utils';
import aggregateRumTimeSeries from './requests/aggregateRumTimeSeries';
import useRumState from './hooks/useRumState';
import { RumEventType } from './types';
import { getChartColor } from './utils';
import formatLatencyDistribution from './utils/formatLatencyDistribution';
import latencyDistribution from './requests/latencyDistribution';

type GetRowArgs = {
  applicationFilter: string;
  errorGroupingKey?: string;
  eventType: RumEventType;
  facetRegex: FacetRegexTerm[];
  filters: Filter[];
  idSearch: string;
  search: ReturnType<typeof useSearch>;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  setDate: (date: DateSelection) => void;
};

const xAxisTickFormatter = (labels: number[]) => {
  return (labelIndex: number) => formatDurationNs(labels[labelIndex], 1, 2);
};

const getRows = ({
  applicationFilter,
  errorGroupingKey,
  eventType,
  facetRegex,
  filters,
  idSearch,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  search,
  setDate,
}: GetRowArgs) => {
  const onSelection = (startTimeUnix: number, endTimeUnix: number) => {
    setDate({ startTimeUnix, endTimeUnix });
  };

  const { rollUpInSeconds } = search.state;

  const rumQueries = (aggregateField: string, aggregateFunction: string) => ({
    isActive: true,
    type: 'query',
    queryKey: 'query_a',
    query: {
      ...search.state,
      searchBarState: {
        applicationFilter,
        filters,
      },
      aggregateField,
      aggregateFunction,
    },
  });

  return [
    [
      {
        charts: [
          {
            key: 'requests',
            chartType: 'bar',
            colorMap: {
              count: getChartColor(eventType),
            },
            eventType,
            label: 'Count',
            renderTotalCount: (totalCount: number) =>
              convertNumberToReadableUnit(totalCount),
            totalKey: 'count',
            onSelection,
            rumQueries: [rumQueries('*', 'count')],
            query: ({ date }: { date: DateSelection }) =>
              aggregateRumTimeSeries({
                applicationFilter,
                aggregation: 'count',
                aggregationField: '*',
                date,
                facetRegex,
                errorGroupingKey,
                eventType,
                rollUpSeconds:
                  rollUpInSeconds || getRollupByVisualization(date, 'bar'),
                idSearch,
                selectedFacetRangeByName,
                selectedFacetValuesByName,
              }).then((data: TimeSeries[]) => {
                const points = data?.[0]?.points || [];
                return formatAggregateTimeSeriesData({
                  date,
                  points: points.map((point) => ({
                    Value: point.value,
                    BucketStart: Math.floor(point.ts / 1000),
                    GroupVal: {},
                  })),
                  step:
                    rollUpInSeconds || getRollupByVisualization(date, 'bar'),
                  fillMissingTimestamps: true,
                });
              }),
          },
        ],
      },
      eventType === RumEventType.VIEW
        ? {
            charts: [
              {
                key: 'latencyDistribution',
                allowYAxisDecimals: false,
                chartType: 'bar',
                datasetsFormatter: formatLatencyDistribution,
                eventType,
                disabledCompare: true,
                disableExplore: true,
                instant: true,
                label: 'Page loading time distribution',
                skipToast: true,
                rumQueries: [rumQueries('*', 'count')],
                query: ({
                  date,
                  width,
                }: {
                  date: DateSelection;
                  width: number;
                }) =>
                  latencyDistribution({
                    applicationFilter,
                    date,
                    facetRegex,
                    idSearch,
                    selectedFacetRangeByName,
                    selectedFacetValuesByName,
                    width,
                  }).then(formatLatencyDistribution),
                xAxisTickFormatter,
              },
            ],
          }
        : null,
    ].filter(Boolean),
  ];
};

type Props = {
  errorGroupingKey?: string;
  eventType: RumEventType;
  rumState: ReturnType<typeof useRumState>;
};

const RumChartGrid = ({ errorGroupingKey, eventType, rumState }: Props) => {
  const {
    applicationFilter,
    dateState,
    facetRegexState,
    filtersState,
    idSearch,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
  } = rumState;
  const [date, setDate] = dateState;

  const search = useSearch();
  const selectedFacetRangeByName = selectedFacetRangeByNameState.state;
  const selectedFacetValuesByName = selectedFacetValuesByNameState.state;

  const rows = useMemo(
    () =>
      getRows({
        applicationFilter,
        facetRegex: facetRegexState?.state,
        errorGroupingKey,
        eventType,
        filters: filtersState.state,
        idSearch,
        search,
        selectedFacetRangeByName: selectedFacetRangeByNameState.state,
        selectedFacetValuesByName: selectedFacetValuesByNameState.state,
        setDate,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      applicationFilter,
      date,
      facetRegexState?.state,
      eventType,
      idSearch,
      search.state,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
    ],
  );

  return (
    <div className="rum__chart-grid" data-testid="rum_chart_grid">
      <ChartGridV2.ChartGrid date={date} rows={rows} />
    </div>
  );
};

export default RumChartGrid;
