import { usePaginatorServerSide } from 'components';
import React, { createContext, useContext } from 'react';
import {
  FacetValuesByFacetKeyThenK8sIdentifier,
  SelectedFacetValuesByName,
} from 'types';

import useKubesFluxState, {
  GroupBySearchTerms,
  KubernetesTableSort,
} from './hooks/useKubesFluxState';
import { EntityTypes } from './types';

interface KubernetesController extends ReturnType<typeof useKubesFluxState> {
  facetValuesByFacetNameThenK8sIdentifierForSidebar: FacetValuesByFacetKeyThenK8sIdentifier;
  facetValuesByFacetNameThenK8sIdentifierForSearch: FacetValuesByFacetKeyThenK8sIdentifier;

  /**
   * @deprecated Refactor to use properties on KubernetesController
   */
  flux: ReturnType<typeof useKubesFluxState>;

  paginator: ReturnType<typeof usePaginatorServerSide>;

  groupBySearchTerms: GroupBySearchTerms;
  kubesSort: KubernetesTableSort;
  entitiesType: EntityTypes;
  facets: SelectedFacetValuesByName;
  page: number;
  limit: number;
  entitiesLastLoaded: number;
}

const KubernetesControllerContext = createContext<KubernetesController | null>(
  null,
);

export function useKubernetesController(): KubernetesController {
  return useContext(KubernetesControllerContext);
}

//
// TODO
// The controller can be further refactored to separate what need to be
// exposed (via useState) and what can be hidden (via useRef)
//
export function KubernetesController({ entitiesType, children }) {
  const flux = useKubesFluxState({ entitiesType });

  return (
    <KubernetesControllerContext.Provider
      value={{
        ...flux,

        flux,
      }}
    >
      {children}
    </KubernetesControllerContext.Provider>
  );
}
