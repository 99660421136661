import { AutocompleteOption } from 'components';
import { Dispatch, ReactNode } from 'react';
import { FacetRange } from './facet';

export type FilterProps = {
  customerFilter?: { [key: string]: string };
  facetName?: any;
  filterByFacets: string[];
  filterOrExcludeByFingerprint: any;
  keyExists: any;
  logLevel?: any;
  searchTerms: any;
  searchTermsWithOr?: any;
  selectedFacetRanges: { [key: string]: FacetRange };
  selectedFacetValues: any;
  selectedFacetValuesWithOr?: any;
};

type FilterStateMap = {
  [key: string]: { [key: string]: any };
};

export type FilterState = {
  [key: string]: Array<string> | { [key: string]: number } | FilterStateMap;
};

export type FilterStateAction = {
  type: FilterActionType;
  index?: number;
  filterKey: string;
  mapKey?: string;
  payload?: any;
};

export enum FILTER_DATA_TYPE {
  ARRAY = 'array',
  OBJECT = 'object',
  MAP = 'map',
}

export type FilterStructure = {
  [key: string]: {
    dataType: FILTER_DATA_TYPE;
    tagPrefix?: string;
  };
};

export type FilterPanelProps = {
  activeOperator: string;
  optionData: { options: AutocompleteOption[]; isLoading: boolean };
  typed: string;
};

export enum FilterActionType {
  ADD_FILTER = 'ADD_FILTER',
  UPDATE_FILTER_BY_INDEX = 'UPDATE_FILTER_BY_INDEX',
  REMOVE_FILTER = 'REMOVE_FILTER',
  REMOVE_FILTER_BY_INDEX = 'REMOVE_FILTER_BY_INDEX',
  REMOVE_FILTER_BY_KEY = 'REMOVE_FILTER_BY_KEY',
  REPLACE_FILTER_BY_KEY = 'REPLACE_FILTER_BY_KEY',
  ADD_MAPPED_FILTER = 'ADD_MAPPED_FILTER',
  UPDATE_MAPPED_FILTER_BY_KEY = 'UPDATE_MAPPED_FILTER_BY_KEY',
  EXCLUDE_FROM_MAPPED_FILTER = 'EXCLUDE_FROM_MAPPED_FILTER',
  REMOVED_MAPPED_FILTER_BY_KEY = 'REMOVED_MAPPED_FILTER_BY_KEY',
  TOGGLE_MAPPER_FILTER_BY_KEY = 'TOGGLE_MAPPER_FILTER_BY_KEY',
  CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS',
  CLEAR_FILTERS_BY_KEY = 'CLEAR_FILTERS_BY_KEY',
  UPDATE_FROM_URL = 'UPDATE_FROM_URL',
}

export type FilterMethodAction =
  | 'addFilter'
  | 'addMappedFilter'
  | 'removeFilterByKey'
  | 'removeMappedFilterByKey'
  | 'clearFiltersByKey'
  | 'clearAllFilters'
  | 'removeFilterByIndex'
  | 'replaceFilterByKey'
  | 'excludeFromMappedFilter'
  | 'toggleMappedFilterByKey'
  | 'updateFilterByIndex'
  | 'updateMappedFilterByKey';

type FilterCommonMethods = [
  'removeFilterByKey',
  'replaceFilterByKey',
  'clearFiltersByKey',
  'clearAllFilters',
];
export type FilterMappedMethods = {
  array: ['addFilter', 'removeFilterByIndex', ...FilterCommonMethods];
  map: [
    'addMappedFilter',
    'excludeFromMappedFilter',
    'removeMappedFilterByKey',
    'toggleMappedFilterByKey',
    'updateMappedFilterByKey',
    ...FilterCommonMethods,
  ];
  object: ['addFilter', 'removeFilterByKey', ...FilterCommonMethods];
};

export type FilterMethodArgs = {
  addFilter: (filterKey: string, payload: any) => void;
  addMappedFilter: (filterKey: string, mapKey: string, payload: any) => void;
  clearAllFilters: () => void;
  clearFiltersByKey: (filterKey: string) => void;
  removeFilterByIndex: (index: number, filterKey: string) => void;
  removeFilterByKey: (filterKey: string, payload: any) => void;
  excludeFromMappedFilter: (
    filterKey: string,
    mapKey: string,
    payload: any,
  ) => void;
  removeMappedFilterByKey: (filterKey: string, mapKey: string) => void;
  replaceFilterByKey: (filterKey: string, payload: any) => void;
  toggleMappedFilterByKey: (
    filterKey: string,
    mapKey: string,
    payload: any,
    allFacetValues: Array<string>,
    selectedFacetValues: Record<string, number>,
  ) => void;
  updateFilterByIndex: (index: number, filterKey: string, payload: any) => void;
  updateMappedFilterByKey: (
    filterKey: string,
    mapKey: string,
    payload: any,
  ) => void;
};

export type FilterMethods = { [K in FilterMethodAction]: FilterMethodArgs[K] };

export type FilterMethodByType<T extends keyof FilterMappedMethods> = {
  [K in FilterMappedMethods[T][number]]: FilterMethodArgs[K];
} & { state: FilterState['filter'] };

export type FilterRequestMap = {
  [key: string]: (
    option: AutocompleteOption,
    ignoreFilter?: { [key: string]: boolean },
  ) => Promise<AutocompleteOption[]>;
};

export type FilterTagsMap = {
  [key: string]: (val: {
    filterKey: string;
    stateAndMethods: FilterMethodByType<FILTER_DATA_TYPE>;
    filterStructure: FilterStructure;
  }) => FilterTagsProps[];
};

export type FilterTagsProps = {
  label: ReactNode;
  onClick: () => void;
  onEdit?: () => void;
};

export type FilterTagsStateAndMethods = FilterMethodByType<FILTER_DATA_TYPE> & {
  setSearch: Dispatch<string>;
  setEditSearch: Dispatch<any>;
  updateActiveOperator: (op: string) => void;
};
