import dayjs from 'dayjs';
import { snakeCase } from 'snake-case';
import { LogEventList, LogsState } from 'types';

import query from './query';
import { buildQuery } from './utils';
import { onPromiseError } from 'utils';

type Args = {
  cursor?: string;
  limit?: number;
  logsState: LogsState;
  sort?: { sortBy: string; sortOrder: string };
};

const formatSortBy = (s: string) => {
  const parts = s.split('.');
  const key = parts[parts.length - 1];

  if (key === 'timestamp') {
    return 'ts';
  }

  return snakeCase(key);
};

const getLogsV2 = async ({
  cursor = null,
  limit = 200,
  logsState,
  sort,
}: Args): Promise<LogEventList> => {
  const { date } = logsState;
  const startTimeUnix = date.zoomedStartTimeUnix || date.startTimeUnix;
  const endTimeUnix = date.zoomedEndTimeUnix || date.endTimeUnix;

  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  const logQuery = buildQuery({
    ...logsState,
    logLevel: null,
  });

  return query<LogEventList, 'getLogsV2'>(`
    query GetLogsV2 {
      getLogsV2(
        cursor: ${cursor ? `"${cursor}"` : 'null'},
        ${logQuery !== '{}' ? `query: ${logQuery},` : ''}
        limit: ${limit},
        timestamp: "${endTime.format()}",
        durationSecs: ${durationSecs}
        ${
          sort?.sortBy && sort?.sortOrder
            ? `sortBy: "${formatSortBy(sort.sortBy)}"`
            : ''
        }
        ${sort?.sortBy && sort?.sortOrder ? `sortOrder: ${sort.sortOrder}` : ''}
      ) {
        cursor
        events {
          timestamp
          logLine
          fpString
          fpHash
          level
          labels
          facets {
            name
            dataType
            content
          }
        }
      }
    }
  `).then(
    (data) => data.getLogsV2 || { cursor: '', events: [] },
    onPromiseError,
  );
};

export default getLogsV2;
