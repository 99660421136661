import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { NavigateFunction, NavLink, useNavigate } from 'react-router-dom';

import { SubMenuProps } from './types';

type Props = {
  items: (
    nav: NavigateFunction,
    urlSearchParams?: URLSearchParams,
  ) => SubMenuProps[];
  urlSearchParams?: URLSearchParams;
  className?: string;
};

const HeaderPanel = ({
  items,
  urlSearchParams,
  className,
}: Props): ReactElement => {
  const navigate = useNavigate();

  return (
    <div className={classnames('header__nav__item__panel', className)}>
      {items(navigate, urlSearchParams)
        .filter((item) => !item.hideInSubMenu)
        .map((item, i) =>
          item.onClick ? (
            <button
              className="header__nav__item__panel__item"
              key={i}
              onClick={() => {
                item.onClick();
                navigate(item.route);
              }}
            >
              <span className="header__nav__item__panel__item__icon">
                {item.icon}
              </span>
              <span className="header__nav__item__panel__item__label">
                {item.label}
              </span>
            </button>
          ) : (
            <NavLink
              className="header__nav__item__panel__item"
              key={i}
              to={item.route}
            >
              <span className="header__nav__item__panel__item__icon">
                {item.icon}
              </span>
              <span className="header__nav__item__panel__item__label">
                {item.label}
              </span>
            </NavLink>
          ),
        )}
    </div>
  );
};

export default HeaderPanel;
