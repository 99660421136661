import {
  ChartLegendTableColumn,
  DateSelection,
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types';
import { Filter, useSearch } from 'hooks';
import React, { useMemo } from 'react';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import { ChartGridV2 } from 'components';
import multipleQueryRangeWithLabelsForRUM from 'utils/chartGrid/multipleQueryRangeWithLabelsForRUM';
import { formatLatencyYAxisTick } from 'utils';
import { RumEventType } from './types';

type GetRowArgs = {
  applicationFilter: string;
  date: DateSelection;
  facetRegex: FacetRegexTerm[];
  setDate: (date: DateSelection) => void;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  filters: Filter[];
  search: ReturnType<typeof useSearch>;
  eventType: RumEventType;
};

export const getRows = ({
  applicationFilter,
  date,
  facetRegex,
  setDate,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  filters,
  search,
  eventType,
}: GetRowArgs) => {
  const onSelection = (newDate: DateSelection) => {
    if (typeof newDate === 'object') {
      setDate(newDate);
    } else {
      const { startTimeUnix, endTimeUnix } = newDate;
      setDate({ startTimeUnix, endTimeUnix });
    }
  };
  const rumQueries = (aggregateField: string, aggregateFunction: string) => ({
    isActive: true,
    type: 'query',
    queryKey: 'query_a',
    query: {
      ...search.state,
      searchBarState: {
        applicationFilter,
        filters,
      },
      aggregateField,
      aggregateFunction,
    },
  });
  return [
    [
      {
        charts: [
          {
            key: 'totalErrors',
            colorMap: {
              count: '#1f77b4',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Total Errors',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [rumQueries('error.id', 'distinctcount')],
            query: multipleQueryRangeWithLabelsForRUM(
              [
                () => ({
                  applicationFilter,
                  aggregateField: 'error.id',
                  aggregateFunction: 'distinctcount',
                  facetRegex,
                  groupByLimit: 100,
                  selectedFacetRangeByName,
                  selectedFacetValuesByName,
                  eventType: RumEventType.ERROR,
                }),
              ],
              [['totalErrors']],
            ),
            unit: '',
            eventType,
          },
        ],
      },
    ],
  ];
};

type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
};

const RumTotalErrors = ({ rumPageState }: Props) => {
  const { rumState } = rumPageState;
  const {
    applicationFilter,
    facetRegexState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    dateState,
    filtersState,
  } = rumState;
  const selectedFacetRangeByName = selectedFacetRangeByNameState.state;
  const selectedFacetValuesByName = selectedFacetValuesByNameState.state;
  const [date, setDate] = dateState;
  const search = useSearch();
  const rows = useMemo(
    () =>
      getRows({
        applicationFilter,
        date,
        facetRegex: facetRegexState.state,
        setDate,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
        filters: filtersState.state,
        search,
        eventType: RumEventType.ERROR,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      applicationFilter,
      facetRegexState,
      date,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
    ],
  );

  return (
    <div className="rum__main overflow-auto">
      <div className="rum__performance-charts">
        <ChartGridV2.ChartGrid date={date} rows={rows} />
      </div>
    </div>
  );
};

export default RumTotalErrors;
