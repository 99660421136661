import { Button, Input } from 'components';
import { useMetricsQueryStateV2 } from 'hooks';
import React, { ReactElement } from 'react';
import { X } from 'react-feather';
import { DateSelection, ExplorerQueryProps } from 'types';
import { getRollupByVisualization } from 'utils';

const MetricsQueryBuilderCustomStep = ({
  date,
  metricsQueryState,
  query,
  queryIndex,
}: {
  date: DateSelection;
  metricsQueryState: ReturnType<typeof useMetricsQueryStateV2>;
  query: ExplorerQueryProps;
  queryIndex: number;
}): ReactElement => {
  const { updateQuery } = metricsQueryState;

  const hanldeCustomStep = () => {
    if (query.steps !== null) {
      updateQuery(queryIndex, 'steps', null);
    } else {
      const step = getRollupByVisualization(date);
      updateQuery(queryIndex, 'steps', step);
    }
  };

  return (
    <div className="flex">
      {query.steps !== null && (
        <div className="flex flex--align-items-center metrics__query-builder__custom-step__panel">
          <div className="py-0 px-1.5">Seconds</div>
          <div className="metrics__query-builder__custom-step__panel__input">
            <Input
              autoFocus
              className="input--no-border rounded-none"
              onChange={(val) => updateQuery(queryIndex, 'steps', val)}
              placeholder="step (s)"
              type="text"
              value={`${query.steps}`}
              size="1"
            />
          </div>
          <Button
            className="h-full metrics__query-builder__custom-step__panel__close"
            onClick={() => hanldeCustomStep()}
            variant="icon"
            size="xs"
          >
            <X size={14} />
          </Button>
        </div>
      )}
      {query.steps === null && (
        <Button
          className="h-full px-1.5"
          onClick={() => hanldeCustomStep()}
          variant="icon"
          size="sm"
        >
          steps
        </Button>
      )}
    </div>
  );
};

export default MetricsQueryBuilderCustomStep;
