import { compact } from 'lodash';
import { domUtil } from 'utils';

import {
  getPreviousElement,
  getNextElement,
} from '../PropertyValueSelector/utils';
import {
  ElementType,
  Expression,
  Operator,
  ChipRef,
  ChipProps,
  Suggestion,
} from './types';

export const isInvalid = (expression: Expression): boolean =>
  expression &&
  expression.property.length === 0 &&
  expression.operator.length === 0 &&
  expression.values.join('').length === 0;

export const isValid = (expression: Expression): boolean =>
  !isInvalid(expression);

export const getFlatRefsList = ({
  initializerRef,
  chipRefs,
}: {
  initializerRef: React.MutableRefObject<HTMLInputElement>;
  chipRefs: React.MutableRefObject<ChipRef[]>;
}): Array<HTMLInputElement> =>
  compact([
    ...compact(chipRefs.current).reduce<Array<HTMLInputElement>>(
      (acc, chipRef) => [...acc, ...chipRef.getFlatRefList()],
      [],
    ),
    initializerRef.current,
  ]);

export const onChangeHandler = {
  property:
    ({
      index,
      setProperty,
      setValue,
      getValueIfExists,
      setOperator,
      setElementToFocusInfo,
    }: ChipProps) =>
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const userInput = e.target.value;
      if (
        userInput.includes('=') ||
        userInput.includes('!') ||
        userInput.endsWith(' ')
      ) {
        setOperator({
          index,
          operator: userInput.includes('!') ? '!=' : '=',
        });

        const currentValue = getValueIfExists({
          index,
          valueIndex: 0,
        });

        if (currentValue === null) {
          setValue({
            index,
            valueIndex: 0,
            value: '',
          });
          setElementToFocusInfo({
            type: ElementType.VALUE,
            index,
            valueIndex: 0,
          });
        } else {
          setElementToFocusInfo({
            type: ElementType.OPERATOR,
            index,
          });
        }
        return;
      }
      setProperty({
        index,
        property: e.target.value.trim(),
      });
    },
  operator:
    ({
      index,
      setProperty,
      getPropertyIfExists,
      setValue,
      getValueIfExists,
      setOperator,
      operatorRef,
      setElementToFocusInfo,
    }: ChipProps & { operatorRef: React.MutableRefObject<HTMLInputElement> }) =>
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const userInput = e.target.value;
      if (userInput.length > 1 && userInput.includes(' ')) {
        setValue({
          index,
          valueIndex: 0,
          value: getValueIfExists({ index, valueIndex: 0 }) || '',
        });
        setElementToFocusInfo({
          type: ElementType.VALUE,
          index,
          valueIndex: 0,
        });
        return;
      }

      const allowList = ['', '!', '=', '!='];
      if (allowList.includes(userInput.trim())) {
        setOperator({
          index,
          operator: userInput.trim() as Operator,
        });
        return;
      }
      if (domUtil.isCursorAtRightmost(operatorRef.current)) {
        const valueSelector = {
          index,
          valueIndex: 0,
        };
        setValue({
          ...valueSelector,
          value: `${userInput.slice(-1)}${
            getValueIfExists(valueSelector) || ''
          }`.trim(),
        });
        setElementToFocusInfo({
          type: ElementType.VALUE,
          index,
          valueIndex: 0,
          selectionStart: 1,
        });
      } else if (operatorRef.current?.selectionStart === 1) {
        const propertySelector = {
          index,
        };
        setProperty({
          ...propertySelector,
          property: `${
            getPropertyIfExists(propertySelector) || ''
          }${userInput.slice(0, 1)}`.trim(),
        });
        setElementToFocusInfo({ type: ElementType.PROPERTY, index });
      }
    },
  value:
    ({
      index,
      valueIndex,
      expression,
      setValue,
      setOperator,
      setElementToFocusInfo,
    }: ChipProps & { valueIndex: number }) =>
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const userInput = e.target.value;
      if (userInput.includes('=') || userInput.includes('!')) {
        setOperator({
          index,
          operator: userInput.includes('!') ? '!=' : '=',
        });
        setElementToFocusInfo({
          type: ElementType.OPERATOR,
          index,
        });
        return;
      }
      if (
        userInput.length > 1 &&
        userInput.includes(' ') &&
        valueIndex === expression.values.length - 1
      ) {
        setValue({
          index,
          valueIndex: valueIndex + 1,
          value: '',
        });
        setElementToFocusInfo({
          type: ElementType.VALUE,
          index,
          valueIndex: valueIndex + 1,
        });
        return;
      }
      setValue({
        index,
        value: userInput.trim(),
        valueIndex,
      });
    },
};

export const onBlurHandler = {
  operator:
    ({ index, expression, setOperator }: ChipProps) =>
    (event: React.FocusEvent<HTMLInputElement>): void => {
      const userInput = event.target.value.trim();
      if (expression.values.join('').length === 0 && userInput === '') {
        return;
      }
      if (userInput !== '=' && userInput !== '!=') {
        setOperator({
          index,
          operator: userInput.includes('!') ? '!=' : '=',
        });
      }
    },
  value:
    ({ index, removeValue, valueIndex }: ChipProps & { valueIndex: number }) =>
    (event: React.FocusEvent<HTMLInputElement>): void => {
      if (event.target.value.trim().length === 0) {
        removeValue({
          index,
          valueIndex,
        });
      }
    },
};

export const onKeydownHandler = {
  container:
    ({
      chipRefs,
      initializerRef,
      updateUrlIfNecessary,
      activeSuggestionIndex,
      setActiveSuggestionIndex,
      onSuggestionSelect,
      suggestions,
    }: {
      chipRefs: React.MutableRefObject<ChipRef[]>;
      initializerRef: React.MutableRefObject<HTMLInputElement>;
      updateUrlIfNecessary: () => void;
      activeSuggestionIndex: number;
      setActiveSuggestionIndex: React.Dispatch<React.SetStateAction<number>>;
      onSuggestionSelect: (index: number) => void;
      suggestions: Array<Suggestion>;
    }) =>
    (event: React.KeyboardEvent<HTMLDivElement>): void => {
      if (event.key === 'Enter' && activeSuggestionIndex !== null) {
        setActiveSuggestionIndex(null);
        onSuggestionSelect(activeSuggestionIndex);
        return;
      }

      if (['ArrowDown', 'ArrowUp'].includes(event.key)) {
        setActiveSuggestionIndex((currentIndex) => {
          if (currentIndex === null) {
            return 0;
          }
          const nextIndex =
            event.key === 'ArrowUp' ? currentIndex - 1 : currentIndex + 1;

          if (nextIndex === -1) {
            return suggestions.length - 1;
          }
          if (nextIndex === suggestions.length) {
            return 0;
          }
          return nextIndex;
        });
      } else {
        setActiveSuggestionIndex(null);
      }

      if (event.key === 'ArrowLeft' || event.key === 'Backspace') {
        if (domUtil.isCursorAtLeftmost(event.target as HTMLInputElement)) {
          event.preventDefault();
          const prev = getPreviousElement(
            event.target,
            getFlatRefsList({
              chipRefs,
              initializerRef,
            }),
          );
          domUtil.setCursorAtRightmost(prev as HTMLInputElement);
        }
      }
      if (event.key === 'ArrowRight') {
        if (domUtil.isCursorAtRightmost(event.target as HTMLInputElement)) {
          event.preventDefault();
          const next = getNextElement(
            event.target,
            getFlatRefsList({ chipRefs, initializerRef }),
          );
          domUtil.setCursorAtLeftmost(next as HTMLInputElement);
        }
      }
      if (event.key === 'Enter') {
        updateUrlIfNecessary();
      }
      if (event.key === 'Escape') {
        // Take focus
        initializerRef.current.focus();
        // Blur it to close suggestions
        initializerRef.current.blur();
      }
    },
  value:
    ({
      index,
      setElementToFocusInfo,
      valueIndex,
    }: ChipProps & { valueIndex: number }) =>
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      const targetValue = (event.target as HTMLInputElement).value;
      if (targetValue === '' && event.key === 'Backspace') {
        setElementToFocusInfo(
          valueIndex === 0
            ? { type: ElementType.OPERATOR, index }
            : {
                type: ElementType.VALUE,
                index,
                valueIndex: valueIndex - 1,
              },
        );
      }
    },
};
