import { CopyButton } from 'components';
import React from 'react';
import { PanelState } from 'types';
import { getSheetCellValue } from 'utils';

type Props = {
  state: PanelState;
  column: string;
  index: number;
  logs: any[];
  setSelectedLog: (log: any) => void;
  row: number;
};

const LogsSheetTooltip = ({
  column,
  index,
  logs,
  setSelectedLog,
  row,
}: Props): JSX.Element => {
  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="logs__sheet__tooltip" onClick={handleClick}>
      <CopyButton text={getSheetCellValue({ column, row })} />
    </div>
  );
};

export default LogsSheetTooltip;
