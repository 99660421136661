import { fetchJson } from 'requests';

const deleteUserById = async ({
  userId,
}: {
  userId: string;
}): Promise<Record<string, unknown>> => {
  const options = { method: 'DELETE' };
  const result = await fetchJson(`rbac/users/${userId}`, options);

  return result as Record<string, unknown>;
};

export default deleteUserById;
