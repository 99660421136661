import { useDateState, useRequest } from 'hooks';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { getGrafanaAlertsByGroup, promqlQueryRange } from 'requests';
import { colorsByAlertState } from 'kfuse-constants';
import { KfSource } from 'types';

import { AlertsChart } from '../AlertsChart';
import AlertsDetailsEventsList from './AlertsDetailsEventsList';
import AlertsDetailsProperties from './AlertsDetailsProperties';
import { useAlertsState } from '../hooks';
import { RuleProps } from '../types';
import { getDateFromRange } from 'screens/Dashboard/utils';
import { getDateForQuery } from '../utils';
import { parseAlertsQueryAndConditions } from '../AlertsCreateMetrics/utils';

const AlertsDetailsSLO = ({
  rule,
  alertsState,
}: {
  rule: RuleProps;
  alertsState: ReturnType<typeof useAlertsState>;
}): ReactElement => {
  const [date, setDate] = useDateState();

  const grafanaAlertsByGroupRequest = useRequest(getGrafanaAlertsByGroup);
  const promqlQueryRangeRequest = useRequest(promqlQueryRange);

  const alertParsedMetadata = useMemo(() => {
    const parsedMeta = parseAlertsQueryAndConditions({
      data: rule.ruleData,
      annotations: rule.annotations,
    });

    const newDate = getDateForQuery(parsedMeta.relativeTimeRange);
    setDate(newDate);
    return parsedMeta;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rule]);

  const loadEvaluationGraph = async () => {
    promqlQueryRangeRequest.call({
      date: getDateFromRange('now-5m', 'now'),
      metricNames: [''],
      promqlQueries: [rule.ruleData[0].model.expr],
      type: 'timeseries',
    });
  };

  useEffect(() => {
    grafanaAlertsByGroupRequest.call({
      groupName: rule.group,
      ruleName: rule.name,
    });
    loadEvaluationGraph();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="h-[4px] w-full"
        style={{ backgroundColor: colorsByAlertState[rule.status] }}
      ></div>
      <div className="alerts__details">
        <AlertsDetailsProperties
          alertsState={alertsState}
          condition={alertParsedMetadata?.condition}
          disableAlertEditAndDelete
          parsedMetadata={alertParsedMetadata}
          properties={rule}
        />
        <div className="alerts__details__events-list box-shadow">
          <div className="flex justify-between">
            <div className="pt-1 text-lg font-semibold">Evaluation Graph</div>
          </div>
          {rule && date && alertParsedMetadata && (
            <AlertsChart
              conditionOperator={'last'}
              conditionValue={0}
              date={date}
              isLoading={promqlQueryRangeRequest.isLoading}
              queryData={promqlQueryRangeRequest.result}
            />
          )}
        </div>
        <AlertsDetailsEventsList
          date={date}
          enableAnalysis={false}
          kfSource={KfSource.APM}
          promqls={alertParsedMetadata?.promqls[0]}
          ruleName={rule.name}
          ruleGroup={rule.group}
        />
      </div>
    </>
  );
};

export default AlertsDetailsSLO;
