import React, { useMemo } from 'react';

import { Box } from 'components/layouts/Box';
import { Table, TableRow } from 'components';
import { kubeNamespaceCount } from 'requests';
import { findUnitCategoryFormatById } from 'utils';
import KubernetesRightSideBar from '../KubernetesRightSideBar';
import { useDateState } from 'hooks';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellPercentageBar,
  KubeCellTimestamp,
  KubeTableCell,
  KubeTableEntityTags,
  KubeTableGroupExpanded,
} from './Components';
import useAsync from '../hooks/useAsync';
import { getAggregationsByEntities } from '../utils/selectorsfunction';
import { generatePath, useParams } from 'react-router-dom';
import KubernetesTableGroup from './KubernetesTableGroup';
import { useKubernetesTableDataForCluster } from './KubernetesTableForCluster';
import { useMergedGroupByFilter } from './useEntitiesData';
import { Group } from 'screens/Kubernetes/types';

const renderEmptyCell = () => '';

function KubernetesTableForClusterRow({ row }) {
  const { navigate } = useKubernetesController();

  const filters = useMergedGroupByFilter(row.groupBys);
  const [rows] = useKubernetesTableDataForCluster({
    page: 0,
    limit: 50,
    filters,
  });

  const setActiveKube = (row) => {
    navigate(({ location, params, pathTemplate }) => {
      return {
        ...location,

        pathname: generatePath(pathTemplate, {
          ...params,

          activeKube: row.cluster.id,
          tab: 'log',
        }),
      };
    });
  };

  const activeKubeParam = useParams()['activeKube'];
  const activeKube = (
    typeof activeKubeParam === 'string' && rows ? rows : []
  ).find((row) => {
    return row.cluster.id === activeKubeParam;
  });

  return (
    <Box width="full" height="full">
      {activeKube && <KubernetesRightSideBar activeKube={activeKube} />}
      <Table
        className="-my-px"
        renderHeader={false}
        rows={rows ?? []}
        renderRow={(props) => (
          <TableRow
            {...props}
            className="border-y"
            onRowClickHandler={(row) => () => setActiveKube(row)}
          />
        )}
        columns={[
          { key: 'header', label: '' },
          {
            key: 'kube_cluster_name',
            label: 'Cluster',
            renderCell: (prop: any) => {
              const clusterName = prop?.row?.cluster?.clusterName;
              return (
                <KubeTableCell
                  {...prop}
                  className="kubernetes__table__cell__entity-name"
                  tooltipText={clusterName}
                >
                  {clusterName}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'creationTimestamp',
            label: 'Age',
            renderCell(prop) {
              const timestamp = prop.row.cluster.creationTimestamp;
              return <KubeCellTimestamp {...prop} timestamp={timestamp} />;
            },
          },
          {
            key: 'resourceVersion',
            label: 'Versions',
            renderCell: (prop: any) => {
              return (
                <KubeTableCell
                  {...prop}
                  className="kubernetes__table__cell__entity-name"
                  tooltipText={Object.keys(
                    prop?.row.cluster?.kubeletVersions || {},
                  )}
                >
                  {Object.keys(prop?.row.cluster?.kubeletVersions || {})}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'nodeCount',
            label: 'Nodes',
            renderCell: (prop: any) => {
              return (
                <KubeTableCell {...prop} align="right">
                  {prop?.row.cluster?.nodeCount}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'cpuCapacity',
            label: 'Cpu Capacity',
            renderCell: (prop: any) => {
              const cluster = prop?.row?.cluster;
              if (!cluster && !cluster?.cpuAllocatable && !cluster?.cpuCapacity)
                return <>-</>;

              return (
                <KubeTableCell {...prop} align="right">
                  {(cluster?.cpuAllocatable / 1000).toFixed(2)}/{' '}
                  {(cluster?.cpuCapacity / 1000).toFixed(2)} CPUs
                </KubeTableCell>
              );
            },
          },
          {
            key: 'headerCpuUsage',
            label: '% CPU Usage',
            renderCell: (prop: any) => {
              const percent =
                (prop?.row.cpuPercentage / prop?.row.cluster?.cpuAllocatable) *
                100;

              return (
                <KubeTableCell
                  {...prop}
                  tooltipText={percent && `${percent.toFixed(2)}%`}
                >
                  <KubeCellPercentageBar percent={percent} />
                </KubeTableCell>
              );
            },
          },
          {
            key: 'memoryCapacity',
            label: 'Mem Capacity',
            renderCell: (prop: any) => {
              const cluster = prop?.row?.cluster;
              if (
                !cluster &&
                !cluster?.memoryAllocatable &&
                !cluster?.memoryCapacity
              )
                return <>-</>;

              const unit = findUnitCategoryFormatById('bytes');
              const memoryAllocatable = unit.fn(
                cluster?.memoryAllocatable || 0,
              );
              const memoryCapacity = unit.fn(cluster?.memoryCapacity || 0);
              return (
                <KubeTableCell {...prop} align="right">
                  {`${memoryAllocatable.text} ${memoryAllocatable.suffix}`}/{' '}
                  {`${memoryCapacity.text} ${memoryCapacity.suffix}`}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'headerMemUsage',
            label: '% Mem Usage',
            renderCell: (prop: any) => {
              const percent =
                (prop?.row?.memoryPercentage /
                  prop?.row.cluster?.memoryAllocatable) *
                100;

              return (
                <KubeTableCell
                  {...prop}
                  tooltipText={percent && `${percent.toFixed(2)}%`}
                >
                  <KubeCellPercentageBar percent={percent} />
                </KubeTableCell>
              );
            },
          },
          {
            key: 'headerPods',
            label: 'Pods',
            renderCell: (prop: any) => (
              <KubeTableCell {...prop} align="right">
                {prop?.row.podCount}
              </KubeTableCell>
            ),
          },
          {
            key: 'headerPodUsage',
            label: 'Pod Usage',
            renderCell: (prop: any) => {
              const percent =
                (prop?.row?.podCount / prop?.row?.cluster?.podCapacity) * 100;
              return (
                <KubeTableCell
                  {...prop}
                  tooltipText={percent && `${percent.toFixed(2)}%`}
                >
                  <KubeCellPercentageBar percent={percent} />
                </KubeTableCell>
              );
            },
          },
        ]}
      />
    </Box>
  );
}

export default function KubernetesTableForClusterGroup() {
  const [date] = useDateState();
  const { entitiesType, facets, groupBySearchTerms } =
    useKubernetesController();

  const [rows] = useAsync(
    async (signal: AbortSignal) => {
      const [cpu, memory, entities] = await Promise.all([
        null, // podCPUUsage(groupBySearchTerms, date)({ signal }),
        null, // podMemoryUsage(groupBySearchTerms, date)({ signal }),
        kubeNamespaceCount(
          {
            entityType: entitiesType,
            selectedFacetValuesByName: facets,
            groupBy: groupBySearchTerms,
            aggregations: getAggregationsByEntities(
              entitiesType,
              groupBySearchTerms,
            ),
          },
          { signal },
        ) as Promise<Array<Group>>,
      ]);

      return entities.map((podData) => {
        // const key = getKey(podData.groupBys);
        // const cpuPercentage = cpu[key] || 0;
        // const memoryPercentage = memory[key] || 0;

        return {
          ...podData,
          // cpuPercentage,
          // memoryPercentage,
        };
      });
    },
    [date, facets, groupBySearchTerms],
  );

  return (
    <KubernetesTableGroup
      rows={rows ?? []}
      columns={[
        {
          key: 'header',
          label: '',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerkube_cluster_name',
          label: 'Cluster Group',
          renderCell(prop) {
            return <KubeTableEntityTags {...prop} />;
          },
        },
        {
          key: 'headerage',
          label: 'Age',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerversion',
          label: 'Versions',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headernodes',
          label: 'Nodes',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headercpuCapacity',
          label: 'Cpu Capacity',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headercpu',
          label: '% CPU Usage',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headermemoryCap',
          label: 'Mem Capacity',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headermem',
          label: '% Mem Usage',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerpods',
          label: 'Pods',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerpodusage',
          label: 'Pod Usage',
          renderCell: renderEmptyCell,
        },
      ]}
      renderExpandedRow={(props) => <KubernetesTableForClusterRow {...props} />}
    />
  );
}
