import classnames from 'classnames';
import {
  DateControls,
  Loader,
  useColumnsState,
  useLeftSidebarState,
  ProductTour,
  ShowSidebarTooltipButton,
  ToggleSwitch,
  OverlayMessageProps,
  OverlayMessage,
} from 'components';
import { Datepicker } from 'composite';
import {
  useDateState,
  useSelectedFacetValuesByNameState,
  useUrlSearchParams,
} from 'hooks';
import { startCase } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { ImTable } from 'react-icons/im';
import { RiDatabase2Line, RiNodeTree } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';
import { DateSelection, SelectedFacetValuesByName, Service } from 'types';
import getColumns from './getColumns';
import ServicesTable from './ServicesTable';
import TracesServiceMap from '../Traces/TracesServiceMap';
import useKpisByServiceNameRequest from './useKpisByServiceNameRequest';
import { ServicesTab, getActiveTab } from './utils';
import { IoIosWarning } from 'react-icons/io';

const getServicesTabs = (isAsmChecked: boolean) => [
  {
    icon: <ImTable size={14} />,
    serviceTab: ServicesTab.table,
    url: isAsmChecked ? '/advanced-service-monitoring' : '/apm/services',
  },
  {
    icon: <RiDatabase2Line size={14} />,
    serviceTab: ServicesTab.db,
    url: `/apm/services/${ServicesTab.db}`,
  },
  {
    icon: <RiNodeTree size={14} />,
    serviceTab: ServicesTab.serviceMap,
    url: '/apm/services/service-map',
  },
];

type Props = {
  colorsByServiceName: Record<string, string>;
  customerFilter?: { key: string; value: string };
  dateState: ReturnType<typeof useDateState>;
  isAsmChecked: boolean;
  isLatencyBoundedToMinMax: boolean;
  leftSidebarState: ReturnType<typeof useLeftSidebarState>;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  selectedFacetValuesByNameState?: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
  serviceByHash: Record<string, Service>;
  showInactiveServices: boolean;
  setShowInactiveServices: (showInactiveServices: boolean) => void;
  servicesError: {
    getServices: { message: string };
  };
};

const ServicesMainServices = ({
  colorsByServiceName,
  customerFilter,
  dateState,
  isAsmChecked,
  isLatencyBoundedToMinMax,
  leftSidebarState,
  selectedFacetValuesByName,
  selectedFacetValuesByNameState,
  serviceByHash,
  showInactiveServices,
  setShowInactiveServices,
  servicesError,
}: Props) => {
  const kpisByServiceNameRequest = useKpisByServiceNameRequest({
    isLatencyBoundedToMinMax,
  });
  const kpisByServiceName = useMemo(
    () => kpisByServiceNameRequest.result || {},
    [kpisByServiceNameRequest.result],
  );

  const [error, setError] = useState({
    getKpisByServiceNames: null,
  });

  const [date, setDate] = dateState;
  const { isLoadingState } = kpisByServiceNameRequest;
  const location = useLocation();
  const urlSearchParams = useUrlSearchParams();
  const search = urlSearchParams.toString();

  const activeTab = getActiveTab(location);

  const columns = getColumns({
    activeTab,
    colorsByServiceName,
    date,
    isLatencyBoundedToMinMax,
    isLoadingState,
    kpisByServiceName,
    search,
    serviceByHash,
  });

  const columnsState = useColumnsState({
    columns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        name: 1,
        requestsPerSecond: 1,
        requests: 1,
        p50latency: 1,
        p99latency: 1,
        maxlatency: 1,
        errorRate: 1,
        apdex: 1,
      },
    },
    key: 'services-table',
    onSelectedColumnToggle: ({ key, isSelected }) => {
      if (isSelected) {
        kpisByServiceNameRequest.fetchSingleColumn({
          activeTab,
          customerFilter,
          date,
          key,
          selectedFacetValuesByName,
        });
      }
    },
  });

  useEffect(() => {
    if (kpisByServiceNameRequest.result.error) {
      setError((prevError) => ({
        ...prevError,
        getKpisByServiceNames: {
          message: 'Failed to fetch KPIs by service name',
        },
      }));
    } else {
      setError((prevError) => ({ ...prevError, getKpisByServiceNames: null }));
    }
  }, [kpisByServiceNameRequest.result.error]);

  return (
    <>
      <div className="services__header">
        <div className="services__header__left">
          {leftSidebarState.width === 0 ? (
            <ShowSidebarTooltipButton onClick={leftSidebarState.show} />
          ) : null}
          <div className="button-group">
            {getServicesTabs(isAsmChecked).map((tab) => {
              if (tab.serviceTab === ServicesTab.db && isAsmChecked) {
                return null;
              }
              return (
                <Link
                  className={classnames({
                    'button-group__item': true,
                    'button-group__item--active': tab.serviceTab === activeTab,
                  })}
                  key={tab.serviceTab}
                  to={`${tab.url}?${urlSearchParams.toString()}`}
                >
                  <div className="button-group__item__icon">{tab.icon}</div>
                  {startCase(tab.serviceTab)}
                </Link>
              );
            })}
          </div>
        </div>
        <div className="services__header__right">
          {error.getKpisByServiceNames && (
            <div className="flex gap-[4px] w-full justify-end pr-[14px]">
              <IoIosWarning
                className="overlay-message__icon-and-message__icon"
                size={16}
              />
              <div className="text-red-500">
                {error.getKpisByServiceNames.message}
              </div>
            </div>
          )}

          {activeTab === ServicesTab.table && (
            <div className="mr-4">
              <ToggleSwitch
                className="mr-2"
                value={showInactiveServices}
                onChange={setShowInactiveServices}
              />
              Show Inactive Services
            </div>
          )}
          <Datepicker
            onChange={setDate as (dateSelection: DateSelection) => void}
            value={date as DateSelection}
          />
          <DateControls date={date} setDate={setDate} />
        </div>
      </div>
      <div className="services__body">
        {activeTab === ServicesTab.table ? (
          <Loader className="services__table">
            {columnsState.isReady ? (
              <ServicesTable
                columnsState={columnsState}
                customerFilter={customerFilter}
                date={date}
                kpisByServiceName={kpisByServiceNameRequest.result || {}}
                kpisByServiceNameRequest={kpisByServiceNameRequest}
                selectedFacetValuesByName={selectedFacetValuesByName}
                serviceByHash={serviceByHash}
                showInactiveServices={showInactiveServices}
                tableType={ServicesTab.table}
                dataTestId="services-table"
                servicesError={servicesError}
              />
            ) : null}
          </Loader>
        ) : null}
        {activeTab === ServicesTab.serviceMap ? (
          <TracesServiceMap
            colorsByServiceName={colorsByServiceName}
            customerFilter={customerFilter}
            date={date}
            selectedFacetValuesByName={selectedFacetValuesByName}
            selectedFacetValuesByNameState={selectedFacetValuesByNameState}
            serviceByHash={serviceByHash}
          />
        ) : null}
        {activeTab === ServicesTab.db ? (
          <Loader className="services__table">
            {columnsState.isReady ? (
              <ServicesTable
                columnsState={columnsState}
                customerFilter={customerFilter}
                date={date}
                kpisByServiceName={kpisByServiceNameRequest.result || {}}
                kpisByServiceNameRequest={kpisByServiceNameRequest}
                selectedFacetValuesByName={selectedFacetValuesByName}
                serviceByHash={serviceByHash}
                showInactiveServices={showInactiveServices}
                tableType={ServicesTab.db}
                servicesError={servicesError}
              />
            ) : null}
          </Loader>
        ) : null}
        {<ProductTour />}
      </div>
    </>
  );
};

export default ServicesMainServices;
