import React, { ReactElement } from 'react';

import { AlertsCreateDetection } from '../components';
import {
  thresholdAlertPopupMessage,
  anomalyAlertPopupMessage,
} from '../constants';
import { AlertType } from '../types';

const apmAlertDetectionList = [
  { label: 'Threshold Alert', value: AlertType.THRESHOLD },
  { label: 'Anomaly Detection', value: AlertType.ANOMALY },
];

const detectionDescriptions = {
  threshold: {
    message: thresholdAlertPopupMessage.THRESHOLD_MESSAGE,
    title: thresholdAlertPopupMessage.THRESHOLD_TITLE,
    description: thresholdAlertPopupMessage.THRESHOLD_DESCRIPTION,
  },
  anomaly: {
    message: anomalyAlertPopupMessage.ANOMALY_MESSAGE,
    title: anomalyAlertPopupMessage.ANOMALY_TITLE,
    description: anomalyAlertPopupMessage.ANOMALY_DESCRIPTION,
  },
};

const AlertsCreateAPMType = ({
  alertType,
  setActiveDetection,
}: {
  alertType: AlertType;
  setActiveDetection: (activeDetection: string) => void;
}): ReactElement => {
  return (
    <AlertsCreateDetection
      activeDetection={alertType}
      detectionList={apmAlertDetectionList}
      detectionDescriptionMap={detectionDescriptions}
      setActiveDetection={setActiveDetection}
    />
  );
};

export default AlertsCreateAPMType;
