import { Button } from 'components';
import { useThemeContext } from 'components/Theme';
import {
  CalendarPickerInput,
  CalendarPickerPanel,
  TimeRangeHistory,
  TimeRangePeriod,
} from 'composite';
import {
  convertDateToUnix,
  convertTimeStringToUnix,
  formatDate,
  validateDateField,
  getValueFromHtmlForm,
} from 'composite/utils';
import dayjs from 'dayjs';
import { useToggle } from 'hooks';
import React, { ReactElement, useState } from 'react';
import { DateSelection } from 'types';

import CalendarPickerTimezone from './CalendarPickerTimezone';
import { QuickRangeOptionsProps } from '../types';

const CalendarPickerModal = ({
  absoluteTimeRangeStorage = [],
  close,
  onChange,
  startLiveTail,
  quickRangeOptions,
  renderInfo,
  value,
}: {
  absoluteTimeRangeStorage?: Array<DateSelection>;
  close: () => void;
  onChange: (value: DateSelection) => void;
  startLiveTail: () => void;
  quickRangeOptions?: QuickRangeOptionsProps[];
  renderInfo?: ReactElement;
  value: DateSelection;
}): ReactElement => {
  const { startLabel, endLabel, startTimeUnix, endTimeUnix } = value;
  const openToggle = useToggle();
  const [range, setRange] = useState<(Date | string)[]>([
    startLabel || new Date(startTimeUnix * 1000),
    endLabel || new Date(endTimeUnix * 1000),
  ]);
  const [validationError, setValidationError] = useState<string>(null);
  const { utcTimeEnabled } = useThemeContext();

  const onSetRange = (ranges: Date[]) => {
    if (ranges.length > 1) {
      setRange(ranges);
    }
  };

  const onApplyUsedFilterRange = (range: DateSelection) => {
    onChange({ ...range, startLabel: null, endLabel: null });
    close();
  };

  const onApplyFilterRange = (event: React.FormEvent) => {
    event.preventDefault();
    const data: { from: string; to: string } = getValueFromHtmlForm(event, [
      'from',
      'to',
    ]);

    const fromCodified = convertTimeStringToUnix(data.from, utcTimeEnabled);
    const toCodified = convertTimeStringToUnix(data.to, utcTimeEnabled);

    if (fromCodified && toCodified) {
      if (new Date(fromCodified * 1000) > new Date(toCodified * 1000)) {
        setValidationError('From date must be before To date');
        return;
      }

      setValidationError(null);
      onChange({
        startLabel: data.from,
        endLabel: data.to,
        startTimeUnix: fromCodified,
        endTimeUnix: toCodified,
      });
      close();
      return;
    }

    if (validateDateField(data.from) || validateDateField(data.to)) {
      return;
    }

    if (new Date(data.from) > new Date(data.to)) {
      setValidationError('From date must be less than To date');
      return;
    }

    const newDates = {
      startTimeUnix: convertDateToUnix(data.from, utcTimeEnabled),
      endTimeUnix: convertDateToUnix(data.to, utcTimeEnabled),
    };

    const shouldUseCodifiedForStartTime = !newDates.startTimeUnix;
    const shouldUseCodifiedForEndTime = !newDates.endTimeUnix;

    const nextStartTimeUnix = shouldUseCodifiedForStartTime
      ? fromCodified
      : newDates.startTimeUnix;
    const nextEndTimeUnix = shouldUseCodifiedForEndTime
      ? toCodified
      : newDates.endTimeUnix;

    const nextStartLabel = shouldUseCodifiedForStartTime ? data.from : null;
    const nextEndLabel = shouldUseCodifiedForEndTime ? data.to : null;

    onChange({
      startTimeUnix: nextStartTimeUnix,
      endTimeUnix: nextEndTimeUnix,
      startLabel: nextStartLabel,
      endLabel: nextEndLabel,
    });
    close();
  };

  return (
    <div className="calendar-picker__modal">
      <div className="calendar-picker__modal__main ">
        <form
          onSubmit={onApplyFilterRange}
          className="calendar-picker__modal__absolute"
        >
          <div className="calendar-picker__modal__absolute__header">
            <p className="calendar-picker__modal__absolute__header__title">
              Absolute time range
            </p>
          </div>
          <CalendarPickerInput
            name="from"
            openToggle={openToggle}
            title="From"
            value={
              typeof range[0] === 'string'
                ? range[0]
                : formatDate(range[0], utcTimeEnabled)
            }
          />
          <CalendarPickerInput
            openToggle={openToggle}
            name="to"
            title="To"
            value={
              typeof range[1] === 'string'
                ? range[1]
                : formatDate(range[1], utcTimeEnabled)
            }
          />
          {validationError && (
            <div className="calendar-picker__modal__absolute__validation">
              <p className="text--red">{validationError}</p>
            </div>
          )}
          <Button
            variant="default"
            size="sm"
            type="submit"
            className="mt-3 w-40"
          >
            Apply Time Range
          </Button>
          {absoluteTimeRangeStorage && (
            <TimeRangeHistory
              history={absoluteTimeRangeStorage}
              onApplyUsedFilterRange={onApplyUsedFilterRange}
            />
          )}
          {openToggle.value && (
            <CalendarPickerPanel
              openToggle={openToggle}
              range={range}
              setRange={onSetRange}
            />
          )}
        </form>
        <TimeRangePeriod
          onChange={onChange}
          close={close}
          startLiveTail={startLiveTail}
          quickRangeOptions={quickRangeOptions}
        />
      </div>
      {renderInfo && renderInfo}
      <CalendarPickerTimezone />
    </div>
  );
};

export default CalendarPickerModal;
