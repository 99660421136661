import { ErrorBoundary } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { promqlLabelValues } from 'requests';
import { DateSelection } from 'types';

import { DashboardByName } from '../../Kfuse';

const ServiceTabOutlier = ({
  asmMatcher,
  date,
  kfSource,
}: {
  asmMatcher: string;
  date: DateSelection;
  kfSource?: string;
}): ReactElement => {
  const promqlLabelValuesRequest = useRequest(promqlLabelValues);

  useEffect(() => {
    promqlLabelValuesRequest.call({
      date,
      label: 'kube_node',
      matcher: `kubernetes_cpu_user_total{${asmMatcher || ''}}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asmMatcher, date]);

  const kubeFilters = useMemo(() => {
    const nodes = promqlLabelValuesRequest.result || [];
    return {
      matchers: asmMatcher,
      kf_source: kfSource,
      node_matchers: nodes.join('|'),
      outlier_threshold: '0.5',
    };
  }, [asmMatcher, kfSource, promqlLabelValuesRequest.result]);

  return (
    <div className="service__tab">
      {kubeFilters && promqlLabelValuesRequest.result && (
        <ErrorBoundary errorMessage={<h1>Failed to load outliers charts</h1>}>
          <DashboardByName
            date={date}
            disableDeletePanel={true}
            folderName="kfuse-asm"
            hideHeader={true}
            name="ASM Outlier Resources"
            templateValues={kubeFilters}
          />
        </ErrorBoundary>
      )}
    </div>
  );
};

export default ServiceTabOutlier;
