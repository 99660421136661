import { Filter } from './types';
import useFiltersState from './useFiltersState';

type Args = {
  key: string;
  index: number;
  setState: (nextStateOrCallback: any[] | ((prevState: any[]) => void)) => void;
  state: any[];
};

const getFiltersStateFromArrayStateItem = ({
  key,
  index,
  setState: baseSetState,
  state: baseState,
}: Args): ReturnType<typeof useFiltersState> => {
  const setState = (
    nextStateOrCallback: Filter[] | ((filters: Filter[]) => Filter[]),
  ) => {
    baseSetState((prevState) => {
      const nextState = [...prevState];
      const nextStateItem = nextState[index];
      const prevStateItemFilters = [...(nextStateItem[key] || [])];

      const nextStateItemFilters =
        typeof nextStateOrCallback === 'function'
          ? nextStateOrCallback(prevStateItemFilters)
          : nextStateOrCallback;
      nextStateItem[key] = nextStateItemFilters;
      nextState[index] = nextStateItem;
      return nextState;
    });
  };

  const add = (filter: Filter) => {
    setState((prevState) => {
      const nextState = [...prevState, filter];
      return nextState;
    });
  };

  const deleteByIndex = (filterIndex: number) => {
    setState((prevState) => {
      const nextState = [...prevState];
      nextState.splice(filterIndex, 1);
      return nextState;
    });
  };

  const replaceByIndex = (filter: Filter, filterIndex: number) => {
    setState((prevState) => {
      const nextState = [...prevState];
      nextState[filterIndex] = filter;
      return nextState;
    });
  };

  return {
    add,
    deleteByIndex,
    replaceByIndex,
    setState,
    state: baseState[index][key],
  };
};

export default getFiltersStateFromArrayStateItem;
