import React from 'react';
import classnames from 'classnames';
import { Icon } from 'components';
import { noop } from 'lodash';
import { FocusedElementInfo, ElementToFocusInfo, ElementType } from './types';

const Chips = ({
  children,
  className,
  focusedElementInfo,
  setFocusedElementInfo,
  initializerRef,
  onSearchTermChange,
  onKeyDown,
  searchTerm,
  shouldShowSearchIcon,
  isSuggestionsForceClosed = false,
  setIsSuggestionsForceClosed = noop,
}: {
  children: React.ReactNode;
  className?: string;
  focusedElementInfo: ElementToFocusInfo;
  setFocusedElementInfo: (info: FocusedElementInfo | null) => void;
  initializerRef: React.MutableRefObject<HTMLInputElement>;
  onSearchTermChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  searchTerm: string;
  shouldShowSearchIcon?: boolean;
  isSuggestionsForceClosed?: boolean;
  setIsSuggestionsForceClosed?: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement => {
  return (
    <div
      className={classnames(
        'flex gap-1',
        'grow',
        'flex-wrap',
        'rounded-tr',
        'max-w-full',
        className,
      )}
      onKeyDown={onKeyDown}
    >
      {shouldShowSearchIcon && (
        <div className="flex items-center pl-2.5 pr-1 text-icon">
          <Icon icon="search" size="md" />
        </div>
      )}
      {children}
      <input
        type="text"
        className={classnames(
          'm-0.5 min-w-0 flex-1 font-[15px] outline-none',
          'bg-background',
          {
            'bg-searchbar-background-focus': focusedElementInfo !== null,
          },
        )}
        value={searchTerm}
        onChange={onSearchTermChange}
        ref={initializerRef}
        onFocus={() => {
          setFocusedElementInfo({
            type: ElementType.INITIALIZER,
          });
        }}
        onBlur={() => {
          setFocusedElementInfo(null);
          if (isSuggestionsForceClosed) {
            setIsSuggestionsForceClosed(false);
          }
        }}
        onKeyDown={(e) => {
          const key = e.key;
          if (key === 'Enter') {
            setIsSuggestionsForceClosed(!isSuggestionsForceClosed);
          }
        }}
      />
    </div>
  );
};

export default Chips;
