import delimiter from 'kfuse-constants/delimiter';
import dayjs from 'dayjs';
import { FacetBase, FacetDetail, LogsState } from 'types';
import { capitalize, onPromiseError } from 'utils';
import query from './query';
import { buildQuery, formatFacetName } from './utils';

type Args = LogsState & {
  facet: FacetBase;
  logsState: LogsState;
};

const getFacetDetail = async ({
  facet,
  logsState,
}: Args): Promise<FacetDetail> => {
  const { component, name, type } = facet;
  const { date } = logsState;

  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const keyExistsForFacet = `${component}${delimiter}${name}${delimiter}${type}`;

  const logQuery = buildQuery({
    ...logsState,
    selectedFacetRanges: {},
    keyExists: { ...logsState.keyExists, [keyExistsForFacet]: 1 },
  });

  return query<FacetDetail, 'getFacetDetail'>(`
    {
      getFacetDetail(
        datatype: ${capitalize(type)}
        durationSecs: ${durationSecs}
        facetName: "${formatFacetName(facet.component, facet.name, facet.type)}"
        logQuery: ${logQuery}
        timestamp: "${endTime.format()}",
      ) {
        minValue
        maxValue
        found
      }
    }
  `).then((data) => data.getFacetDetail, onPromiseError);
};

export default getFacetDetail;
