import classnames from 'classnames';
import Icon from '../base/Icon';
import { usePortalScroll, useRefContext } from 'hooks';
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useState,
  useRef,
} from 'react';
import {
  FacetPickerAction,
  SelectedFacetRange,
  SelectedFacetValues,
  SubscriptionArgs,
  ValueCount,
} from 'types';
import HorizontallyExpandOnHoverAccordion from '../FacetPicker/HorizontallyExpandOnHoverAccordion';
import FacetPickerValues from '../FacetPicker/FacetPickerValues';
import FacetPickerResetButton from '../FacetPicker/FacetPickerResetButton';
import useFacetPickerValuesStateV2 from './hooks/useFacetPickerValuesStateV2';
import { SidebarData } from './types';

type Props = {
  actions?: FacetPickerAction[];
  changeFacetRange: (value: number) => void;
  className?: string;
  clearFacet: () => void;
  disabled?: boolean;
  excludeFacetValue: (value: string) => void;
  getFacetValueCountsRequestOverride: {
    call: () => Promise<Array<{ value: string; count: number }>>;
    isLoading: false;
    result: Array<{ value: string; count: number }>;
  };
  name: string;
  forceExpanded?: boolean;
  getSearchedValue?: (value: string) => string;
  label?: string;
  ignoreCount?: boolean;
  info?: string;
  isRadio?: boolean;
  isRangeFacet?: boolean;
  lastRefreshedAt: number;
  onExpansionChange?: (expanded: boolean) => void;
  renderAction?: (name: string) => ReactNode;
  renderName?: (
    name: string,
    nameRef: React.MutableRefObject<HTMLElement>,
  ) => ReactNode;
  renderPlaceholderText?: (name: string) => string;
  renderValue?: (value: string) => ReactNode;
  request?: (args: any) => Promise<any>;
  requestAndStoreByFacetName?: () => void;
  requestSubscription?: SubscriptionArgs;
  sidebarDataState: SidebarData;
  selectOnlyFacetValue: (value: string) => void;
  selectedFacetRange?: SelectedFacetRange;
  selectedFacetValues: SelectedFacetValues;
  toggleFacetValue: (value: string, allValues: ValueCount[]) => void;
  // Only passed in when inside a group
  parentScrollContainerRef?: React.MutableRefObject<HTMLElement>;
};

//TODO: Move away from FacetPicker V1 to FacetPicker V2 Incrementally
// as we migrate the Sidebar State for other Pages
// InFacetPickerV2, we fetch the Data at the Parent Level and pass it down
//No change in User experience
const FacetPickerV2 = ({
  clearFacet,
  disabled,
  excludeFacetValue,
  getSearchedValue,
  forceExpanded,
  ignoreCount,
  isRadio,
  isRangeFacet,
  info,
  lastRefreshedAt,
  name,
  onExpansionChange,
  renderName,
  renderPlaceholderText,
  renderValue,
  requestAndStoreByFacetName,
  requestSubscription,
  sidebarDataState,
  selectOnlyFacetValue,
  selectedFacetRange,
  selectedFacetValues,
  toggleFacetValue,
  parentScrollContainerRef,
}: Props): ReactElement => {
  const { appRef, leftSidebarRef } = useRefContext();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const fixedContainerRef = useRef<HTMLDivElement | null>(null);
  const facetValuesSearchInputRef = useRef<HTMLInputElement | null>(null);

  const [expanded, setExpanded] = useState(forceExpanded);
  const [hovered, setHovered] = useState(false);

  const onWheel = usePortalScroll({
    fixedContainerRef: fixedContainerRef,
    relativeContainerRef: containerRef,
    fallbackContainerRef: leftSidebarRef,
    parentScrollContainerRef: parentScrollContainerRef || leftSidebarRef,
  });

  const renderedName =
    renderName && typeof renderName === 'function'
      ? renderName(name, null)
      : name;

  const facetPickerValuesState = useFacetPickerValuesStateV2({
    excludeFacetValue,
    getSearchedValue,
    isRadio,
    lastRefreshedAt,
    name,
    renderedName,
    renderPlaceholderText,
    renderValue,
    sidebarDataState,
    selectOnlyFacetValue,
    selectedFacetValues,
    shouldDisableLoader: Boolean(requestSubscription),
    toggleFacetValue,
    isRangeFacet,
    expanded,
    requestAndStoreByFacetName,
  });

  useEffect(() => {
    if (onExpansionChange) {
      onExpansionChange(expanded);
    }

    return () => {
      if (onExpansionChange) {
        onExpansionChange(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  return (
    <HorizontallyExpandOnHoverAccordion
      portalClassName={classnames({
        'facet-picker--disabled': disabled,
      })}
      renderedName={renderedName}
      info={info}
      appRef={appRef}
      containerRef={containerRef}
      fixedContainerRef={fixedContainerRef}
      leftSidebarRef={leftSidebarRef}
      onWheel={onWheel}
      hovered={hovered}
      setHovered={setHovered}
      isFocusCaptured={() => {
        return facetValuesSearchInputRef?.current === document.activeElement;
      }}
      renderTrigger={(facetNameRef?: React.MutableRefObject<HTMLElement>) => (
        <div className="flex w-full min-w-0 items-center justify-between">
          <div className="max-w-full overflow-hidden truncate">
            {renderName && typeof renderName === 'function'
              ? renderName(name, facetNameRef || null)
              : name}
          </div>
          <div className="flex">
            {isRadio ? null : (
              <div
                className={classnames(
                  'mr-2 flex h-[24px] items-center justify-center rounded-sm',
                )}
              >
                {disabled ? (
                  <Icon icon="lock" size="xs" />
                ) : (
                  <FacetPickerResetButton
                    clearFacet={clearFacet}
                    name={renderedName}
                    selectedFacetRange={selectedFacetRange}
                    selectedFacetValues={selectedFacetValues}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
      expanded={expanded}
      onExpansionChange={(updatedExpanded: boolean) => {
        setExpanded(updatedExpanded);
      }}
      renderContent={({
        hoveredAccordionRef,
        scrollDivRef,
      }: {
        hoveredAccordionRef?: React.MutableRefObject<HTMLDivElement>;
        scrollDivRef: React.MutableRefObject<HTMLDivElement>;
      }) => {
        return (
          <FacetPickerValues
            {...facetPickerValuesState}
            facetValuesSearchInputRef={facetValuesSearchInputRef}
            hoveredAccordionRef={hoveredAccordionRef}
            facetValuesScrollDivRef={scrollDivRef}
            setHovered={setHovered}
            onWheel={onWheel}
            clearFacet={clearFacet}
            ignoreCount={ignoreCount}
          />
        );
      }}
      triggerClassName={classnames(
        'group w-full cursor-pointer border-t-0 border-transparent pl-2 leading-[28px] hover:bg-interaction-secondary focus:bg-interaction-secondary active:bg-interaction-secondary-contrast',
        { 'mb-1': expanded },
      )}
    />
  );
};

export default FacetPickerV2;
