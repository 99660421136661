const colorByKubeState: { [key: string]: string } = {
  active: '#40c464',
  bound: '#40c464',
  completed: '#40c464',
  containercreating: '#FFBC0B',
  crashloopbackoff: '#eb354b',
  pending: '#FFBC0B',
  running: '#40c464',
  error: '#eb354b',
  ready: '#40c464',
  no_data: '#333333',
  success: '#40c464',
  failed: '#eb354b',
  suspended: '#FFBC0B',
};

export default colorByKubeState;
