const getServiceNamePropertyToSumBy = (property: string) => {
  if (property === 'service_hash') {
    return 'service_name';
  }

  if (property === 'client_service_hash') {
    return 'client_service_name';
  }

  return null;
};

export default getServiceNamePropertyToSumBy;
