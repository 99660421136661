import { fetchJson } from 'requests';

import { UserProps } from '../types';

const getUserById = async ({
  userId,
}: {
  userId: string;
}): Promise<UserProps> => {
  const result = await fetchJson(`rbac/users/${userId}`);

  return result as UserProps;
};

export default getUserById;
