const colorsByLogLevel: { [key: string]: string } = {
  debug: 'var(--ui-status-success)',
  error: 'var(--ui-status-danger)',
  fatal: 'var(--ui-status-danger-contrast)',
  info: 'var(--ui-interaction-primary-shade)',
  notice: 'var(--ui-status-success-contrast)',
  trace: 'var(--ui-interaction-nested-contrast)',
  warn: 'var(--ui-status-warning)',
  warning: 'var(--ui-status-warning)',
  // TODO(baibhav): use variables for below items
  unknown: '#92a3a8',
  none: '#333333',
  running: '#90EE90',
  crashloopbackoff: '#CC6164',
};

export default colorsByLogLevel;
