import { queryRange } from 'requests';
import { DateSelection } from 'types';
import { GroupBySearchParams } from 'screens/Kubernetes/types';

const getGroupTermsDataforPromQL = (groupByTerm: any) => {
  let result = '';
  if (groupByTerm.length > 0) {
    groupByTerm?.forEach((element) => {
      if (element.key === 'kube_node') {
        result += 'host,';
      } else {
        result += element.key + ',';
      }
    });
    return result;
  } else {
    result = 'pod_name,';
    return result;
  }
};

const bitmapKubeQueryValues = (
  data: Array<{ metric: any; value: [number, string] }>,
  isGroupBy: boolean,
  defaultGroupBy: string[],
) => {
  const result: { [key: string]: string } = {};
  data.forEach((item) => {
    const { metric, value } = item;
    let key = '';
    if (isGroupBy) {
      key = `${Object.values(metric).join(':')}`;
    } else {
      const defaultGroupByValues = defaultGroupBy.map(
        (key) => metric[key] || '',
      );
      key = `${defaultGroupByValues.join(':')}`;
    }
    result[key] = value[1];
  });

  return result;
};

export const podMemoryUsage = (
  groupByTerm: GroupBySearchParams,
  filter: Record<string, Array<string>>,
  date: DateSelection,
) => {
  let promQuery: string;
  if (groupByTerm.length > 0) {
    const promqlfilterTag = getGroupTermsDataforPromQL(groupByTerm);
    promQuery = `sum by (${promqlfilterTag}) (avg_over_time(container_memory_usage[1m]))/ sum by (${promqlfilterTag}) (avg_over_time(container_memory_limit[1m]))`;
  } else {
    promQuery = `sum by (pod_name, kube_cluster_name, kube_namespace) (avg_over_time(container_memory_usage[1m]))/ sum by (pod_name, kube_cluster_name, kube_namespace) (avg_over_time(container_memory_limit[1m]))`;
  }
  return (init: RequestInit) =>
    queryRange({
      date,
      instant: true,
      query: promQuery,
    }).then((output) => {
      return bitmapKubeQueryValues(output, groupByTerm.length > 0, [
        'kube_cluster_name',
        'kube_namespace',
        'pod_name',
      ]);
    });
};

function buildFilterString(filter: Record<string, Array<string>>): string {
  return `{${Object.entries(filter)
    .map(([key, value]) => {
      return `${key}=~"${value.join('|')}"`;
    })
    .join(',')}}`;
}

export const podCPUUsage = (
  groupByTerm: GroupBySearchParams,
  filter: Record<string, Array<string>>,
  date: DateSelection,
) => {
  let promQuery: string;
  if (groupByTerm.length > 0) {
    const promqlfilterTag = getGroupTermsDataforPromQL(groupByTerm);
    promQuery = `sum by (${promqlfilterTag}) (avg_over_time(container_cpu_usage[1m]))/ sum by (${promqlfilterTag}) (avg_over_time(container_cpu_limit[1m]))`;
  } else {
    promQuery = `sum by (pod_name, kube_cluster_name, kube_namespace) (avg_over_time(container_cpu_usage[1m]))/ sum by (pod_name, kube_cluster_name, kube_namespace) (avg_over_time(container_cpu_limit[1m]))`;
  }
  return (init: RequestInit) =>
    queryRange({ date, instant: true, query: promQuery }, init).then(
      (output) => {
        return bitmapKubeQueryValues(output, groupByTerm.length > 0, [
          'kube_cluster_name',
          'kube_namespace',
          'pod_name',
        ]);
      },
    );
};

export const clusterCPUUsage = (
  date: DateSelection,
  filter: Record<string, Array<string>>,
) => {
  const query = `sum by (kube_cluster_name) (avg_over_time(container_cpu_usage[1m]))/1000000`;
  return (init?: RequestInit) =>
    queryRange({ date, instant: true, query }, init).then((output) => {
      return bitmapKubeQueryValues(output, false, ['kube_cluster_name']);
    });
};

export const clusterMemoryUsage = (
  date: DateSelection,
  filter: Record<string, Array<string>>,
) => {
  const query = `sum by (kube_cluster_name) (avg_over_time(container_memory_usage[1m]))`;
  return (init?: RequestInit) =>
    queryRange({ date, instant: true, query }, init).then((output) => {
      return bitmapKubeQueryValues(output, false, ['kube_cluster_name']);
    });
};

export const namespaceCPUAllocation = (
  groupByTerm: GroupBySearchParams,
  filter: Record<string, Array<string>>,
  date: DateSelection,
) => {
  let promQuery: string;
  if (groupByTerm.length > 0) {
    const promqlfilterTag = getGroupTermsDataforPromQL(groupByTerm);
    promQuery = `sum by (${promqlfilterTag}) (avg_over_time(container_cpu_usage[1m]))`;
  } else {
    promQuery = `sum by (kube_cluster_name, kube_namespace) (avg_over_time(container_cpu_usage[1m]))`;
  }
  return (init?: RequestInit) =>
    queryRange({ date, instant: true, query: promQuery }, init).then(
      (output) => {
        return bitmapKubeQueryValues(output, groupByTerm.length > 0, [
          'kube_cluster_name',
          'kube_namespace',
        ]);
      },
    );
};

export const nameSpaceMemoryAllocation = (
  groupByTerm: any,
  filter: Record<string, Array<string>>,
  date: DateSelection,
) => {
  let promQuery: string;
  if (groupByTerm.length > 0) {
    const promqlfilterTag = getGroupTermsDataforPromQL(groupByTerm);
    promQuery = `sum by (${promqlfilterTag}) (avg_over_time(container_memory_usage[1m]))`;
  } else {
    promQuery = `sum by (kube_cluster_name, kube_namespace) (avg_over_time(container_memory_usage[1m]))`;
  }
  return (init?: RequestInit) =>
    queryRange(
      {
        date,
        instant: true,
        query: promQuery,
      },
      init,
    ).then((output) => {
      return bitmapKubeQueryValues(output, groupByTerm.length > 0, [
        'kube_cluster_name',
        'kube_namespace',
      ]);
    });
};

export const nodesCPUPercentage = (
  groupByTerm: GroupBySearchParams,
  filter: Record<string, Array<string>>,
  date: DateSelection,
) => {
  let promQuery: string;
  if (groupByTerm.length > 0) {
    const promqlfilterTag = getGroupTermsDataforPromQL(groupByTerm);
    promQuery = `sum by (${promqlfilterTag}) (avg_over_time(container_cpu_usage[1m]))/ sum by (${promqlfilterTag}) (avg_over_time(container_cpu_limit[1m]))`;
  } else {
    promQuery = `sum by (kube_node) (avg_over_time(container_cpu_usage[1m]))/ sum by (kube_node) (avg_over_time(container_cpu_limit[1m]))`;
  }

  return (init?: RequestInit) =>
    queryRange({ date, instant: true, query: promQuery }, init).then(
      (output) => {
        return bitmapKubeQueryValues(output, groupByTerm.length > 0, ['host']);
      },
    );
};

export const nodesMemoryPercentage = (
  groupByTerm: GroupBySearchParams,
  filter: Record<string, Array<string>>,
  date: DateSelection,
) => {
  let promQuery: string;
  if (groupByTerm.length > 0) {
    const promqlfilterTag = getGroupTermsDataforPromQL(groupByTerm);
    promQuery = `sum by (${promqlfilterTag}) (avg_over_time(container_memory_usage[1m]))/ sum by (${promqlfilterTag}) (avg_over_time(container_memory_limit[1m]))`;
  } else {
    promQuery = `sum by (kube_node) (avg_over_time(system_mem_used[1m]))/ sum by (kube_node) (avg_over_time(system_mem_total[1m]))`;
  }
  return (init?: RequestInit) =>
    queryRange({ date, instant: true, query: promQuery }, init).then(
      (output) => {
        return bitmapKubeQueryValues(output, groupByTerm.length > 0, [
          'kube_node',
        ]);
      },
    );
};

export const NamespacePODAllocation = (
  groupByTerm: GroupBySearchParams,
  date: DateSelection,
) => {
  return () =>
    queryRange({
      date,
      instant: true,
      query:
        'sum by (pod_name, kube_cluster_name, kube_namespace) (avg_over_time(container_cpu_usage[1m]))',
    }).then((output) => {});
};
