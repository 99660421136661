import { Series } from 'uplot';
import { getColorForOneSeries } from 'utils';
import { CHART_LINE_WIDTH_MEDIUM } from 'utils/chartConfig';

export const formatChartLegend = (
  idx: number,
  metric: { [key: string]: any },
  format: string | string[],
): Series => {
  let label = '';
  if (typeof format === 'string') {
    label = replaceLegendVariables(format, metric);
  } else if (Array.isArray(format)) {
    label = formatLegendMultiple(metric, format);
  }

  return {
    label,
    points: { show: false },
    stroke: getColorForOneSeries(metric, idx),
    show: true,
    width: CHART_LINE_WIDTH_MEDIUM,
  };
};

export const formatLegendMultiple = (
  metric: { [key: string]: any },
  format: (string | ((metric: Record<string, any>) => string))[],
): string => {
  const labels: string[] = [];
  format.forEach((formatPart) => {
    if (!formatPart) return;
    const label = replaceLegendVariables(formatPart, metric);
    if (label !== formatPart) {
      labels.push(label);
    }
  });

  if (labels.length) {
    return labels[0];
  } else {
    // Last format part is the default
    const lastFormat = format[format.length - 1];
    if (!lastFormat) return '';
    return replaceLegendVariables(lastFormat, metric);
  }
};

/**
 * Replaces legend variables with their values
 * @param legendFormat
 * @param metric
 * variable format: {{variable_name}} or {{ variable_name }}
 */
export const replaceLegendVariables = (
  legendFormatStringOrFunction:
    | string
    | ((metric: Record<string, any>) => string),
  metric: { [key: string]: any },
): string => {
  if (typeof legendFormatStringOrFunction === 'function') {
    return legendFormatStringOrFunction(metric);
  }

  let legendFormat = legendFormatStringOrFunction as string;
  const variables = legendFormat.match(/{{\s*[\w.]+\s*}}/g);

  if (!variables) {
    return legendFormat;
  }

  variables.forEach((variable) => {
    const variableName = variable.replace(/{{\s*|\s*}}/g, '');
    const variableValue = metric[variableName];
    if (variableValue) {
      legendFormat = legendFormat.replace(variable, variableValue);
    } else {
      legendFormat = legendFormat.replace(variable, '');
    }
  });

  return legendFormat;
};
