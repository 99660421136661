import { fetchJson } from 'requests';

const removeUserFromGroup = async ({
  groupId,
  userId,
}: {
  groupId: string;
  userId: string;
}): Promise<Record<string, unknown>> => {
  const options = { method: 'DELETE' };
  const result = await fetchJson(
    `rbac/groups/${groupId}/users/${userId}`,
    options,
  );

  return result as Record<string, unknown>;
};

export default removeUserFromGroup;
