import { SLOProps } from 'types';

/**
 * Get status and error budget
 * @param dataset
 * @param target
 * @returns
 * Error budget is
 * 1. 100 - target
 * 2. (1 - numeratorValueFloat / denominatorValueFloat) * 100;
 * 3. error budget percentage = error budget total / denominator
 */
export const getStatusAndErrorBudget = (
  dataset: Array<any>,
  target: string,
): SLOProps['statusErrorBudget'] => {
  if (!dataset || dataset.length !== 2 || !target) return null;

  const [badEventsPromql, totalEventsPromql] = dataset;

  const numeratorValue = badEventsPromql?.value?.[1];
  const denominatorValue = totalEventsPromql?.value?.[1];

  if (!numeratorValue || !denominatorValue) return null;

  const numeratorValueFloat = parseFloat(numeratorValue);
  const denominatorValueFloat = parseFloat(denominatorValue);
  const targetFloat = parseFloat(target);

  const status = (1 - numeratorValueFloat / denominatorValueFloat) * 100;
  const errorBudget = ((status - targetFloat) / (100 - targetFloat)) * 100;

  return {
    status: `${status.toFixed(4)}%`,
    statusColor: status < targetFloat ? 'red' : 'green',
    errorBudget: `${errorBudget.toFixed(4)}%`,
    errorBudgetColor: errorBudget < 0 ? 'red' : 'green',
    errorCount: numeratorValueFloat,
  };
};

export const getStatusAndErrorBudgetInListView = (
  dataset: Array<any>,
  target: string,
): SLOProps['statusErrorBudget'] => {
  if (!dataset || dataset.length !== 2 || !target) return null;

  const [budgetRemainingPromql, errorRatioPromQl] = dataset;

  const budgetRemaining = budgetRemainingPromql?.value?.[1];
  const errorRatio = errorRatioPromQl?.value?.[1];

  if (!errorRatio || !budgetRemaining) return null;

  const targetFloat = parseFloat(target);

  const errorRatioFloat = parseFloat(errorRatio);

  const status = (1 - errorRatioFloat) * 100;
  const errorBudget = parseFloat(budgetRemaining) * 100;

  return {
    status: `${status.toFixed(4)}%`,
    statusColor: status < targetFloat ? 'red' : 'green',
    errorBudget: `${errorBudget.toFixed(4)}%`,
    errorBudgetColor: errorBudget < 0 ? 'red' : 'green',
    errorCount: errorRatioFloat * 100,
  };
};

/**
 * Transform promql to slo promql
 * @param promql
 * @returns string
 * replace {{.window}} with 1d
 */
export const transformSLOPromql = ({
  promql,
  slo,
  window,
}: {
  promql: string;
  slo: SLOProps;
  window?: string;
}): string => {
  const windowToUse = window || '1d';
  const service_hash = slo?.service?.hash || '';
  const latencyThreshold = slo?.latencyThreshold || 0;
  const sloMatchers = slo.matchers ? `${slo.matchers}` : '';
  const sloPromql = promql
    .replace(/{{\.slo_window}}/g, `${windowToUse}`)
    .replace(/{{\.slo_service}}/g, service_hash)
    .replace(/{{\.latencyThreshold}}/g, `${latencyThreshold}`)
    .replace(/{{\.slo_matchers}}/g, sloMatchers)
    .replace(/{{\.slo_id}}/g, `${slo.id}`);

  return sloPromql;
};
