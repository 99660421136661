import { SearchablePropertyValueSelector } from 'components';
import { keys } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Marker } from 'react-mark.js';
import { FunctionCategory, FunctionInfo, FunctionName } from 'types';
import { FunctionNamesProps, VectorTypes } from 'types/MetricsQueryBuilder';
import {
  doesFunctionInfoMatchSearchTerm,
  getFilteredFunctionInfoByFunctionNameThenFunctionCategory,
} from './utils';

const FunctionInfoPreview = ({
  functionInfo,
  wordToHighlight,
}: {
  functionInfo: FunctionInfo;
  wordToHighlight: string;
}) => {
  return (
    <>
      <div className="metrics__query-builder__functions-panel__item__category__title">
        <Marker mark={wordToHighlight}>
          <span dangerouslySetInnerHTML={{ __html: functionInfo.shortName }} />
        </Marker>
      </div>
      <div className="metrics__query-builder__functions-panel__item__category__description">
        <Marker mark={wordToHighlight}>
          <span
            dangerouslySetInnerHTML={{ __html: functionInfo.description }}
          />
        </Marker>
      </div>
      <div className="metrics__query-builder__functions-panel__item__category__description__additional">
        {
          <ul>
            <li>
              <Marker mark={wordToHighlight}>
                <span
                  dangerouslySetInnerHTML={{ __html: functionInfo.algorithm }}
                />
              </Marker>
            </li>
            <li>
              <Marker mark={wordToHighlight}>
                <span
                  dangerouslySetInnerHTML={{ __html: functionInfo.tolerance }}
                />
              </Marker>
            </li>
          </ul>
        }
      </div>
      <div className="metrics__query-builder__functions-panel__item__category__image">
        <img src={functionInfo.imageUrl} />
      </div>
    </>
  );
};

const EMPTY_ARRAY: [] = [];
const MetricsQueryBuilderFunctionsPanel = ({
  blockedFunctionsCategories = EMPTY_ARRAY,
  blockedFunctionsNames = EMPTY_ARRAY,
  functionsNames,
  onFunctionClick,
  searchInputRef,
}: {
  blockedFunctionsCategories?: Array<FunctionCategory>;
  blockedFunctionsNames?: Array<FunctionName>;
  functionsNames: FunctionNamesProps[];
  onFunctionClick: (
    functionName: FunctionName,
    vectorType: VectorTypes,
  ) => void;
  searchInputRef: React.MutableRefObject<HTMLInputElement>;
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredFunctionInfoByFunctionNameThenFunctionCategory = useMemo(
    () =>
      getFilteredFunctionInfoByFunctionNameThenFunctionCategory({
        functionInfos: functionsNames,
        searchTerm,
        blockedCategories: blockedFunctionsCategories,
        blockedNames: blockedFunctionsNames,
      }),
    [
      blockedFunctionsCategories,
      blockedFunctionsNames,
      functionsNames,
      searchTerm,
    ],
  );

  const getFunctionNamesForFunctionCategory = useCallback(
    (functionCategory: FunctionCategory): Array<FunctionName> =>
      keys(
        filteredFunctionInfoByFunctionNameThenFunctionCategory[
          functionCategory
        ],
      ),
    [filteredFunctionInfoByFunctionNameThenFunctionCategory],
  );

  const doesFunctionMatchSearchTerm = useCallback(
    (
      functionCategory: FunctionCategory,
      functionName: FunctionName,
      searchTerm: string,
    ): boolean =>
      doesFunctionInfoMatchSearchTerm(
        filteredFunctionInfoByFunctionNameThenFunctionCategory[
          functionCategory
        ][functionName],
        searchTerm,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredFunctionInfoByFunctionNameThenFunctionCategory, searchTerm],
  );

  const functionCategories: Array<FunctionCategory> = useMemo(
    () => keys(filteredFunctionInfoByFunctionNameThenFunctionCategory),
    [filteredFunctionInfoByFunctionNameThenFunctionCategory],
  );

  const renderPreview = useCallback(
    (functionCategory: FunctionCategory, functionName: FunctionName) => (
      <>
        {filteredFunctionInfoByFunctionNameThenFunctionCategory[
          functionCategory
        ]?.[functionName] && (
          <FunctionInfoPreview
            functionInfo={
              filteredFunctionInfoByFunctionNameThenFunctionCategory[
                functionCategory
              ][functionName]
            }
            wordToHighlight={searchTerm}
          />
        )}
      </>
    ),
    [filteredFunctionInfoByFunctionNameThenFunctionCategory, searchTerm],
  );

  const onFunctionSelect = useCallback(
    (functionCategory: FunctionCategory, functionName: FunctionName) => {
      const { shortName, vectorType } =
        filteredFunctionInfoByFunctionNameThenFunctionCategory[
          functionCategory
        ][functionName];
      onFunctionClick(shortName, vectorType);
    },
    [filteredFunctionInfoByFunctionNameThenFunctionCategory, onFunctionClick],
  );

  return (
    <div className="metrics__query-builder__functions-panel">
      <SearchablePropertyValueSelector
        properties={functionCategories}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        getValues={getFunctionNamesForFunctionCategory}
        renderPreview={renderPreview}
        onValueSelect={onFunctionSelect}
        doesMatchSearchTerm={doesFunctionMatchSearchTerm}
        renderPropertyLabel={(functionCategory: FunctionCategory) => (
          <Marker mark={searchTerm}>
            <span dangerouslySetInnerHTML={{ __html: functionCategory }} />
          </Marker>
        )}
        renderValueLabel={(_, functionName) => (
          <Marker mark={searchTerm}>
            <span dangerouslySetInnerHTML={{ __html: functionName }} />
          </Marker>
        )}
        searchInputRef={searchInputRef}
        shouldScrollValuesPaneToBottom={true}
      />
    </div>
  );
};

export default MetricsQueryBuilderFunctionsPanel;
