import { ChartToolbar, TimeseriesRenderer, useToaster } from 'components';
import ResizeObserver from 'rc-resize-observer';
import React, { ReactElement, useMemo } from 'react';
import {
  combineRangeQueryData,
  getDefaultAnalyticsChartTypes,
  setDateRangeOnChartZoom,
} from 'utils';

import AnalyticsChartToolbar from './AnalyticsChartToolbar';
import { AnalyticsChartProps } from './types';
import useAnalyticsChart from './useAnalyticsChart';
import {
  mapChartTypeWithDashboard,
  hasChartAdvanceVisualization,
} from './utils';

const AnalyticsChartCombined = ({
  analyticsChart,
  chartData,
  chartQueries,
  chartFormulas,
  defaultActiveChart,
  settingOptions,
  setDate,
  rightToolbar,
}: {
  analyticsChart: ReturnType<typeof useAnalyticsChart>;
  chartData: AnalyticsChartProps['chartData'];
  chartQueries: AnalyticsChartProps['chartQueries'];
  chartFormulas: AnalyticsChartProps['chartFormulas'];
  defaultActiveChart?: AnalyticsChartProps['defaultActiveChart'];
  setDate: AnalyticsChartProps['setDate'];
  settingOptions: AnalyticsChartProps['settingOptions'];
  rightToolbar: AnalyticsChartProps['rightToolbar'];
}): ReactElement => {
  const { addToast } = useToaster();
  const { date, chartWidth, setChartWidth } = analyticsChart;

  const combinedChartData = useMemo(
    () =>
      combineRangeQueryData({
        formulas: chartFormulas,
        queries: chartQueries,
        queryData: chartData,
        combineLabelWithQueryKey: true,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartData, chartQueries, chartFormulas],
  );

  const unit = chartQueries.reduce((acc, query) => {
    if (acc === null) {
      return query.unit;
    }
    if (typeof acc === 'string' && acc !== query.unit) {
      return 'number';
    }
    return acc;
  }, null);

  const defaultChartType = useMemo(() => {
    const dataPointLength = combinedChartData?.data[1]?.filter(Boolean)?.length;
    const hasAdvanceVisualization = hasChartAdvanceVisualization(chartQueries);
    return getDefaultAnalyticsChartTypes({
      hasAdvanceVisualization,
      dataPointLength,
    });
  }, [combinedChartData?.data, chartQueries]);

  const hooks = useMemo(() => {
    return [
      {
        hook: (u) => setDateRangeOnChartZoom(u, setDate),
        type: 'setSelect',
      },
      ...(combinedChartData.hooks || []),
    ];
  }, [setDate, combinedChartData.hooks]);

  return (
    <>
      <ResizeObserver onResize={(e) => setChartWidth(e.width)}>
        <TimeseriesRenderer
          chartData={combinedChartData || { data: [], series: [] }}
          bands={combinedChartData.bands || []}
          chartTypes={defaultChartType}
          date={date}
          defaultActiveChart={defaultActiveChart}
          hooks={hooks}
          isLoading={combinedChartData.isLoading}
          legend={{ legendType: settingOptions.legendType }}
          chartKey="analytics-chart"
          renderToolbar={({
            activeChart,
            activeStroke,
            setActiveChart,
            setActiveStroke,
            unit,
          }) => (
            <ChartToolbar
              chartTypes={defaultChartType}
              activeChart={activeChart}
              activeStroke={activeStroke}
              setActiveChart={setActiveChart}
              setActiveStroke={setActiveStroke}
              toolbar={{
                rightToolbar: (
                  <AnalyticsChartToolbar
                    onSaveClick={
                      rightToolbar?.onSaveClick
                        ? () => {
                            addToast({
                              status: 'info',
                              text: 'When chart is combined first query will be saved',
                            });
                            rightToolbar.onSaveClick(chartQueries[0]);
                          }
                        : null
                    }
                    onExportClick={
                      rightToolbar?.onExportClick
                        ? () => {
                            rightToolbar.onExportClick({
                              analyticsChartQueries: [
                                ...chartQueries,
                                ...(chartFormulas || []),
                              ],
                              drawStyle: mapChartTypeWithDashboard[activeChart],
                            });
                          }
                        : null
                    }
                    onViewFullscreen={
                      rightToolbar?.onViewFullscreen
                        ? () =>
                            rightToolbar.onViewFullscreen({
                              activeChart,
                              chartQueries,
                              chartFormulas,
                              prevChartData: combinedChartData,
                              unit,
                            })
                        : null
                    }
                    onCreateAlert={
                      chartFormulas.length > 0
                        ? undefined
                        : rightToolbar?.onCreateAlert
                          ? () =>
                              rightToolbar.onCreateAlert({
                                chartQueries,
                                chartFormulas,
                              })
                          : null
                    }
                    saveButtonDisabledReasonIfDisabled={
                      rightToolbar?.saveButtonDisabledReasonIfDisabled
                    }
                  />
                ),
                toolbarMenuType: settingOptions?.toolbarChartShowType,
              }}
            />
          )}
          size={{ width: chartWidth, height: 280 }}
          tooltipType="compact"
          unit={unit}
        />
      </ResizeObserver>
    </>
  );
};

export default AnalyticsChartCombined;
