import {
  AnalyticsChartFullscreenProps,
  AnalyticsChartQueryProps,
  AnalyticsChartToolbarExportClickProps,
  DashboardExport,
  TimeseriesExplorer,
  useAnalyticsChart,
  useModalsContext,
} from 'components';
import {
  getLegacyFiltersFromFiltersState,
  SearchState,
  useRequest,
  useSearches,
} from 'hooks';
import React, { useMemo } from 'react';
import { ChartType, DashboardPanelType, QueryDataProps } from 'types';
import useDebouncedEffect from 'use-debounced-effect';
import { rumDataTransformer } from 'utils/DataTransformer';
import useRumState from 'screens/Rum/hooks/useRumState';
import { isDurationFacet } from 'screens/Rum/utils';
import { RumTab } from 'screens/Rum';

import { rumAnalyticsFetchMulti } from '../../requests';
import { getRumDashboardExportPanel } from '../utils';

type Props = {
  searches: ReturnType<typeof useSearches>['searches'];
  rumState: ReturnType<typeof useRumState>;
  rumTab: RumTab;
};

const useRumTimeseries = ({ searches, rumState, rumTab }: Props) => {
  const {
    applicationFilter,
    dateState,
    eventType,
    facetRegexState,
    filtersState,
    idSearch,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
  } = rumState;
  const modals = useModalsContext();

  const analyticsChart = useAnalyticsChart({
    date: dateState[0],
    forcedActiveVisualization: rumTab as unknown as DashboardPanelType,
    hideVisualizeToolBar: true,
    transformationConfig: {
      excludeColumns: {
        'Value #a':
          (rumTab as unknown as DashboardPanelType) ===
          DashboardPanelType.TABLE,
      },
    },
    supportedVisualizations: [
      DashboardPanelType.TIMESERIES,
      DashboardPanelType.TOP_LIST,
      DashboardPanelType.TABLE,
      DashboardPanelType.PIECHART,
    ],
  });
  const { activeVisualization } = analyticsChart;

  const aggregateTimeSeriesMultiple = useRequest(
    (args) => rumAnalyticsFetchMulti(args),
    true,
    true,
  );

  const [date, setDate] = dateState;
  const facetRegex = facetRegexState?.state;
  const selectedFacetRangeByName = selectedFacetRangeByNameState.state;
  const selectedFacetValuesByName = selectedFacetValuesByNameState.state;

  const queries = searches.map((s) => s.state);

  const filter = useMemo(() => {
    return {
      applicationFilter,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
      selectedHcFacetValuesByName: {},
    };
  }, [applicationFilter, selectedFacetRangeByName, selectedFacetValuesByName]);

  const rangeKey = activeVisualization === 'timeseries' ? 'range' : 'instant';

  const onViewFullscreen = ({
    activeChart,
    chartQueries,
    chartFormulas,
    prevChartData,
    unit,
  }: AnalyticsChartFullscreenProps) => {
    modals.push(
      <TimeseriesExplorer
        activeQueries={[...chartQueries, ...chartFormulas]}
        activeChartType={activeChart as ChartType}
        chartData={prevChartData}
        date={date}
        queryType="rum-performance"
        onClose={modals.pop}
        unit={unit}
        eventType={eventType}
      />,
    );
  };

  const openExportToDashboardModal = ({
    analyticsChartQueries,
    drawStyle,
  }: AnalyticsChartToolbarExportClickProps) => {
    const queries: SearchState[] = [];
    analyticsChartQueries.forEach((query) => {
      queries.push(query.query);
    });

    const query = queries[0];
    const outputQuery = {
      applicationFilter,
      facetRegex,
      eventType,
      queries,
      idSearch,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
      unit: isDurationFacet(query?.multiAggregations?.[0]?.measure)
        ? 'ns'
        : 'number',
    };

    const expr = JSON.stringify(outputQuery);
    modals.push(
      <DashboardExport
        closeModal={modals.pop}
        date={date}
        drawStyle={drawStyle}
        datasourceType="rum"
        panel={getRumDashboardExportPanel({
          expr,
          type: activeVisualization,
          refId: query.queryKey,
        })}
      />,
    );
  };

  const analyticsData = useMemo(() => {
    if (!aggregateTimeSeriesMultiple.result) {
      return { chartData: {}, chartQueries: [], formulaQueries: [] };
    }

    const datasets = aggregateTimeSeriesMultiple.result;
    const isLoading = aggregateTimeSeriesMultiple.isLoading;
    const chartData: QueryDataProps = {};
    const chartQueries: AnalyticsChartQueryProps[] = [];

    searches.forEach((search, idx) => {
      const queryId = `query_${search.queryKey}`;
      const dataset = datasets;

      if (!dataset) {
        chartData[queryId] = { [rangeKey]: null, isLoading, meta: null };
        return;
      }

      if (isLoading) {
        chartData[queryId] = { [rangeKey]: null, isLoading, meta: null };
        return;
      }

      if (activeVisualization !== dataset.meta?.type) {
        return;
      }

      if (dataset.error) {
        chartData[queryId] = {
          [rangeKey]: null,
          isLoading,
          error: dataset.error,
          meta: dataset.meta,
        };
        return;
      }

      chartData[queryId] = {
        [rangeKey]: dataset,
        isLoading,
        meta: dataset.meta,
      };
    });

    searches.forEach((search, idx) => {
      const query = search.state;
      const {
        facetRegex,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
      } = getLegacyFiltersFromFiltersState(filtersState.state);
      const filter = {
        applicationFilter,
        facetRegex,
        idSearch,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
      };
      chartQueries.push({
        direction: query.limitTo,
        filter,
        index: idx,
        isActive: query.isActive,
        type: 'query',
        query: query
          ? {
              ...query,
              searchBarState: {
                applicationFilter,
                idSearch,
                filters: filtersState.state,
              },
              aggregateFunction: query.measure ? query.operation : 'count',
              aggregateField: query.measure || '*',
            }
          : null,
        queryKey: query.queryKey,
        steps: query.rollUpInSeconds,
        vectorAggregate:
          (query?.multiAggregations?.[0]?.operation === 'distinctcount'
            ? 'count'
            : query?.multiAggregations?.[0]?.operation) || 'count',
        unit: isDurationFacet(query?.multiAggregations?.[0]?.measure)
          ? 'ns'
          : 'number',
      });
    });

    return {
      chartData,
      chartQueries,
      formulaQueries: [],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    aggregateTimeSeriesMultiple.result,
    aggregateTimeSeriesMultiple.isLoading,
    searches,
    rangeKey,
    filter,
    activeVisualization,
    aggregateTimeSeriesMultiple?.error,
  ]);

  const groupBysForTable = useMemo(() => {
    const res: string[] = [];
    queries
      .filter((item) => item.isActive)
      .map((q) => {
        const gr: string[] = [];
        q.groupBys.map((g) => {
          g !== '*' && gr.push(g);
        });
        res.push(...gr);
      });

    return res;
  }, [queries]);

  const loadRumTimeseries = () => {
    const instant = activeVisualization !== 'timeseries';
    const baseTransformers = rumDataTransformer(instant);

    const preparedQueries = searches.map((s) => s.state);

    aggregateTimeSeriesMultiple.call({
      applicationFilter,
      date,
      eventType,
      dataFormat: activeVisualization,
      facetRegex,
      formulas: [],
      idSearch,
      instant,
      query: preparedQueries[0],
      transformer: baseTransformers,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
    });
  };

  useDebouncedEffect(
    () => {
      loadRumTimeseries();
    },
    {
      timeout: 200,
      ignoreInitialCall: false,
    },
    [
      applicationFilter,
      activeVisualization,
      date,
      eventType,
      idSearch,
      searches,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
    ],
  );

  return {
    activeVisualization,
    analyticsChart,
    analyticsData,
    groupBysForTable,
    onViewFullscreen,
    openExportToDashboardModal,
  };
};

export default useRumTimeseries;
