import React from 'react';
import { getStatusColor } from 'utils';

type Props = {
  status: string;
};

const StatusTag = ({ status }: Props) => {
  const statusNumber = Number(status);
  if (status === '' || status === undefined || status === null) {
    return null;
  }

  return (
    <div
      className="status-tag"
      style={{ backgroundColor: getStatusColor(statusNumber) }}
    >
      {status}
    </div>
  );
};

export default StatusTag;
