import React, {
  ReactElement,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const LegendsContainer = ({
  children,
  onSizeChange,
}: {
  children: ReactElement;
  onSizeChange: (size: { width: number; height: number }) => void;
}): ReactElement => {
  const legendRef = useRef<HTMLDivElement>(null);
  const [legendSize, setLegendSize] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        for (const entry of entries) {
          const tW = Math.floor(entry.contentRect.width); //  adding padding until Safari supports borderBoxSize
          const tH = Math.floor(entry.contentRect.height);

          if (legendSize.width !== tW || legendSize.height !== tH) {
            setLegendSize({ width: tW, height: tH });
            onSizeChange({ width: tW, height: tH });
          }
        }
      }),
    [],
  );

  useLayoutEffect(() => {
    if (legendRef.current) {
      resizeObserver.observe(legendRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [resizeObserver]);

  return <div ref={legendRef}>{children}</div>;
};

export default LegendsContainer;
