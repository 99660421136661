import classnames from 'classnames';
import React from 'react';
import * as Shadcn from 'components/shadcn';

function FacetAccordion({
  renderTrigger,
  renderContent,
  expanded,
  setExpanded,
  isVisuallyHidden,
  triggerClassName,
  accordionRef,
}: {
  renderTrigger: () => React.ReactNode;
  renderContent: () => React.ReactNode;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  isVisuallyHidden: boolean;
  triggerClassName?: string;
  accordionRef?: React.MutableRefObject<HTMLDivElement>;
}) {
  return (
    <Shadcn.Accordion
      type="single"
      collapsible
      className={classnames(
        'w-full',
        isVisuallyHidden ? 'invisible' : 'visible',
      )}
      value="item-1"
      ref={accordionRef}
    >
      <Shadcn.AccordionItem value={expanded ? 'item-1' : undefined}>
        <Shadcn.AccordionTrigger
          className={triggerClassName}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          {renderTrigger()}
        </Shadcn.AccordionTrigger>
        <Shadcn.AccordionContent>{renderContent()}</Shadcn.AccordionContent>
      </Shadcn.AccordionItem>
    </Shadcn.Accordion>
  );
}

export default FacetAccordion;
