import { isNil } from 'lodash';
import dayjs from 'dayjs';

import { convertNumberToReadableUnit, findUnitCategoryFormatById } from 'utils';

/**
 * Convert unix timestamp to human readable format
 * @param timestamp
 * @example time diff less than 5 minutes => 9:30:20 am
 * @example time diff less than 1 houes => 9:30 am
 */
export const convertToReadableTime = ({
  vals,
  utcTimeEnabled,
  allowOddTimeWithSeconds = false,
}: {
  vals: Array<number>;
  utcTimeEnabled: boolean;
  allowOddTimeWithSeconds?: boolean;
}): string[] => {
  const firstTimestamp = vals[0];
  const lastTimestamp = vals[vals.length - 1];
  const timeDiff = lastTimestamp - firstTimestamp;

  return vals.map((timestamp) => {
    if (!timestamp) return null;
    const time = utcTimeEnabled
      ? dayjs.unix(timestamp).utc()
      : dayjs.unix(timestamp);
    if (timeDiff < 60 * 60) {
      if (time.format('ss') === '00') {
        return time.format('HH:mm');
      }
      return allowOddTimeWithSeconds ? time.format('HH:mm:ss') : null;
    } else if (timeDiff < 60 * 60 * 24) {
      return time.format('HH:mm');
    } else if (timeDiff < 60 * 60 * 24 * 10) {
      // 10 days
      if (time.format('HH:mm') === '00:00') {
        return time.format('MMM D');
      }
      return time.format('HH:mm');
    } else if (timeDiff < 60 * 60 * 24 * 30 * 12) {
      // 12 months
      return time.format('MMM D');
    } else {
      return time.format('MMM, YYYY');
    }
  });
};

const getPrecisionDecimal = (val: number, minDecimalCount = 1): number => {
  const decimalCount = val.toString().split('.')[1]?.length || minDecimalCount;
  return decimalCount > 4 ? 4 : decimalCount;
};

export const formatYAxis = (
  vals: Array<number>,
  unit: string,
  minDecimalCount = 1,
): string[] => {
  if (unit === 'number' || unit === 'count') {
    const valLength = vals.length - 1;
    if (vals[valLength] < 1 && vals[valLength] > 0) {
      const numFormat = findUnitCategoryFormatById('none');

      return vals.map((v) => {
        if (isNil(v)) return '';
        if (v === 0) return '0';
        const decimal = getPrecisionDecimal(v, minDecimalCount);
        return numFormat.fn(v, decimal).text;
      });
    }

    return vals.map((v) => {
      if (isNil(v)) return '';
      return convertNumberToReadableUnit(v, 2);
    });
  }

  const unitFormat = findUnitCategoryFormatById(unit);
  if (unitFormat) {
    return vals.map((v) => {
      if (isNil(v)) return '';
      if (v === 0) return '0';
      const decimal = getPrecisionDecimal(v, minDecimalCount);
      const { prefix, suffix, text } = unitFormat.fn(v, decimal);
      return `${prefix || ''}${text}${suffix || ''}`;
    });
  }

  return vals.map((v) => {
    if (isNil(v)) return '';
    return convertNumberToReadableUnit(v, 2);
  });
};

export const tooltipFormatter = (
  vals: any,
  unit: string,
  minDecimalCount?: number,
): string => {
  if (!vals) return '0';

  const valNumber = Number(vals);
  if (Number.isNaN(valNumber)) {
    return vals;
  }

  if (unit === 'number' || unit === 'count') {
    if (valNumber < 1 && valNumber > 0) {
      const decimalFormat = findUnitCategoryFormatById('none');
      return decimalFormat.fn(valNumber).text;
    }
    return convertNumberToReadableUnit(valNumber, 3);
  }

  const unitFormat = findUnitCategoryFormatById(unit);
  if (unitFormat) {
    const decimal = getPrecisionDecimal(valNumber, minDecimalCount);
    const { prefix, suffix, text } = unitFormat.fn(valNumber, decimal);
    return `${prefix || ''}${text}${suffix || ''}`;
  }

  return convertNumberToReadableUnit(valNumber, 3);
};
