import React from 'react';
import { useParams } from 'react-router-dom';

import DashboardByName from './DashboardByName';
import { getDashboardName } from './ControlPlane/utils';

const DasboardByFolder = ({
  disableFilter = true,
  disableDeletePanel,
  hideHeader = true,
  showFilterTimepicker = false,
  showReloadButton = false,
}: {
  disableFilter?: boolean;
  disableDeletePanel?: boolean;
  hideHeader?: boolean;
  showFilterTimepicker?: boolean;
  showReloadButton?: boolean;
}) => {
  const { dashboardName, folderName } = useParams();

  return (
    <div>
      <DashboardByName
        folderName={folderName}
        disableFilter={disableFilter}
        disableDeletePanel={disableDeletePanel}
        hideHeader={hideHeader}
        name={getDashboardName(dashboardName)}
        templateValues={{}}
        showFilterTimepicker={showFilterTimepicker}
        showReloadButton={showReloadButton}
      />
    </div>
  );
};

export default DasboardByFolder;
