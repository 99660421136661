import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { last, noop } from 'lodash';

import { Property, Value } from './types';
import usePropertyValueSelectorKeydownHandler from './usePropertyValueSelectorKeydownHandler';
import { computeDOMVerticalDistance } from './utils';

const PropertyValueSelector = ({
  activeProperty,
  setActiveProperty,
  activeValue,
  setActiveValue,
  getValues,
  keydownContainerRef,
  onValueSelect,
  properties,
  renderPreview,
  renderPropertyLabel,
  renderValueLabel,
  shouldScrollValuesPaneToBottom,
}: {
  activeProperty: Property | null;
  setActiveProperty: (property: Property | null) => void;
  activeValue: Value | null;
  setActiveValue: (value: Property | null) => void;
  getValues: (property: Property) => Array<Value>;
  keydownContainerRef: React.RefObject<HTMLDivElement | null>;
  onValueSelect: (property: Property, value: Value) => void;
  properties: Array<Property>;
  renderPreview?: (property: Property, value: Value) => React.ReactNode;
  renderPropertyLabel?: (property: Property) => React.ReactNode;
  renderValueLabel?: (property: Property, value: Value) => React.ReactNode;
  shouldScrollValuesPaneToBottom?: boolean;
}) => {
  const lastPropertyListItemRef = useRef<HTMLDivElement | null>(null);
  const shouldRenderPreview = !!renderPreview;
  const secondPaneContainerRef = useRef<HTMLDivElement | null>(null);
  const [secondPanePaddingTop, setSecondPanePaddingTop] = useState<number>(0);

  const keydownHandler = usePropertyValueSelectorKeydownHandler({
    activeProperty,
    setActiveProperty,
    activeValue,
    setActiveValue,
    getValues,
    onValueSelect,
    properties,
  });

  useEffect(() => {
    if (keydownContainerRef.current) {
      keydownContainerRef.current.addEventListener('keydown', keydownHandler);
    }

    return () => {
      if (keydownContainerRef.current) {
        keydownContainerRef.current.removeEventListener(
          'keydown',
          keydownHandler,
        );
      }
    };
  }, [keydownContainerRef.current, keydownHandler]);

  useEffect(() => {
    if (!shouldScrollValuesPaneToBottom) {
      return;
    }
    lastPropertyListItemRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }, [lastPropertyListItemRef.current, shouldScrollValuesPaneToBottom]);

  return (
    <div className="property-value-selector">
      <div className="property-value-selector__pane">
        {properties.map((property) => {
          return (
            <div
              key={property}
              onMouseEnter={() => {
                setActiveValue(null);
                setActiveProperty(property);
              }}
              className={classnames('property-value-selector__list-item', {
                'property-value-selector__list-item--is-active':
                  activeProperty === property,
              })}
              ref={(ref) => {
                const isActivePropertyRef =
                  secondPaneContainerRef.current &&
                  ref &&
                  property === activeProperty;
                if (!isActivePropertyRef) {
                  return;
                }
                setSecondPanePaddingTop(
                  computeDOMVerticalDistance(
                    ref,
                    secondPaneContainerRef.current,
                  ),
                );
              }}
            >
              {renderPropertyLabel?.(property) || <div>{property}</div>}
            </div>
          );
        })}
      </div>
      <div
        className="property-value-selector__pane"
        ref={secondPaneContainerRef}
        style={{ paddingTop: secondPanePaddingTop }}
      >
        {activeProperty &&
          getValues(activeProperty).map((value) => {
            return (
              <div
                key={value}
                onMouseEnter={() => {
                  setActiveValue(value);
                }}
                onClick={() => {
                  onValueSelect(activeProperty, activeValue);
                }}
                className={classnames('property-value-selector__list-item', {
                  'property-value-selector__list-item--is-active':
                    activeValue === value,
                })}
                ref={
                  value === last(getValues(activeProperty))
                    ? lastPropertyListItemRef
                    : noop
                }
              >
                {renderValueLabel?.(activeProperty, value) || (
                  <div>{value}</div>
                )}
              </div>
            );
          })}
      </div>
      {shouldRenderPreview && (
        <div className="property-value-selector__pane--wider">
          {activeProperty &&
            activeValue &&
            renderPreview(activeProperty, activeValue)}
        </div>
      )}
    </div>
  );
};

export default PropertyValueSelector;
