import React, { ReactNode } from 'react';
import {
  FaArrowCircleDown,
  FaArrowCircleUp,
  FaCog,
  FaLaptop,
  FaServer,
} from 'react-icons/fa';

const iconsBySpanKindType: { [key: string]: ReactNode } = {
  SPAN_KIND_SERVER: <FaServer size={14} />,
  SPAN_KIND_CLIENT: <FaLaptop size={14} />,
  SPAN_KIND_INTERNAL: <FaCog size={14} />,
  SPAN_KIND_PRODUCER: <FaArrowCircleUp size={14} />,
  SPAN_KIND_CONSUMER: <FaArrowCircleDown size={14} />,
};

export default iconsBySpanKindType;
