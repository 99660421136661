import classnames from 'classnames';
import React, { ReactNode, useRef, useState } from 'react';
import { PopoverPosition } from './types';

import * as Shadcn from '../shadcn';
const CONTAINER_BUFFER = 4;
const CONTAINER_HEIGHT = 200;
const CONTAINER_WIDTH = 400;

type State = {
  height: number;
  width: number;
  top: number;
  left: number;
  isOpen: boolean;
};

const getAlign = (position: PopoverPosition) => {
  switch (position) {
    case PopoverPosition.BOTTOM_LEFT:
    case PopoverPosition.TOP_LEFT:
      return 'start';
    case PopoverPosition.BOTTOM_RIGHT:
    case PopoverPosition.TOP_RIGHT:
      return 'end';
    default:
      return 'center';
  }
};

const getSide = (position: PopoverPosition) => {
  switch (position) {
    case PopoverPosition.LEFT:
      return 'left';
    case PopoverPosition.RIGHT:
      return 'right';
    case PopoverPosition.BOTTOM_LEFT:
    case PopoverPosition.BOTTOM_RIGHT:
      return 'bottom';
    case PopoverPosition.TOP_LEFT:
    case PopoverPosition.TOP_RIGHT:
      return 'top';
    default:
      return 'bottom';
  }
};

const getStyle = (position: PopoverPosition, state: State) => {
  if (state) {
    const { left, height, width, top } = state;
    switch (position) {
      case PopoverPosition.BOTTOM_LEFT:
        return {
          height: `${CONTAINER_HEIGHT}px`,
          width: `${CONTAINER_WIDTH}px`,
          top: `${top + height + CONTAINER_BUFFER}px`,
          left: `${left}px`,
        };

      case PopoverPosition.BOTTOM_RIGHT:
        return {
          height: `${CONTAINER_HEIGHT}px`,
          width: `${CONTAINER_WIDTH}px`,
          top: `${top + height + CONTAINER_BUFFER}px`,
          left: `${left - (CONTAINER_WIDTH - width)}px`,
        };

      case PopoverPosition.LEFT:
        return {
          height: `${CONTAINER_HEIGHT}px`,
          width: `${CONTAINER_WIDTH}px`,
          top: `${top + height / 2 - CONTAINER_HEIGHT / 2}px`,
          left: `${left - CONTAINER_WIDTH - CONTAINER_BUFFER}px`,
        };

      case PopoverPosition.TOP:
        return {
          height: `${CONTAINER_HEIGHT}px`,
          width: `${CONTAINER_WIDTH}px`,
          top: `${top - CONTAINER_HEIGHT - CONTAINER_BUFFER}px`,
          left: `${left + width / 2 - CONTAINER_WIDTH / 2}px`,
        };

      case PopoverPosition.TOP_LEFT:
        return {
          height: `${CONTAINER_HEIGHT}px`,
          width: `${CONTAINER_WIDTH}px`,
          top: `${top - CONTAINER_HEIGHT - CONTAINER_BUFFER}px`,
          left: `${left}px`,
        };

      case PopoverPosition.TOP_RIGHT:
        return {
          height: `${CONTAINER_HEIGHT}px`,
          width: `${CONTAINER_WIDTH}px`,
          top: `${top - CONTAINER_HEIGHT - CONTAINER_BUFFER}px`,
          left: `${left - (CONTAINER_WIDTH - width)}px`,
        };

      default:
        return {};
    }
  }

  return {};
};

type Props = {
  children:
    | ReactNode
    | ((args: {
        close: VoidFunction;
        isOpen: boolean;
        open: VoidFunction;
      }) => ReactNode);
  className?: string;
  contentClassName?: string;
  container?: HTMLDivElement;
  dataTestId?: string;
  disable?: boolean;
  forceOpen?: boolean;
  offsetX?: number;
  offsetY?: number;
  onClose?: VoidFunction;
  onOpen?: VoidFunction;
  position?: PopoverPosition;
  popover: (args: { close: VoidFunction; open: VoidFunction }) => ReactNode;
  onOpenAutoFocus?: Parameters<
    typeof Shadcn.PopoverContent
  >[0]['onOpenAutoFocus'];
  shouldStopMouseDownPropagation?: boolean;
  shouldUseClickOnOutsideClick?: boolean;
  shouldStopPropagation?: boolean;
};

export const PopoverTriggerV2 = ({
  children,
  className,
  contentClassName,
  container,
  disable,
  dataTestId,
  forceOpen,
  onClose,
  onOpen,
  offsetX = 0,
  offsetY = 0,
  popover,
  position = PopoverPosition.BOTTOM_LEFT,
  shouldStopMouseDownPropagation,
  shouldUseClickOnOutsideClick,
  onOpenAutoFocus,
  shouldStopPropagation,
}: Props) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<State>({
    height: 0,
    left: 0,
    top: 0,
    width: 0,
    isOpen: false,
  });
  const isOpen = (!disable && state.isOpen) || forceOpen;

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    if (shouldStopPropagation) {
      e.stopPropagation();
    }

    setState((prevState) => {
      if (prevState.isOpen) {
        close();
      } else {
        open();
      }
      return {
        ...prevState,
        isOpen: !prevState.isOpen,
      };
    });
  };

  const close = () => {
    setState((prevState) => ({ ...prevState, isOpen: false }));
    onClose?.();
  };

  const open = () => {
    setState((prevState) => ({ ...prevState, isOpen: true }));
    onOpen?.();
  };

  const onTriggerClick = (e: any) => {
    if (state.isOpen) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <Shadcn.PopoverRoot
      onOpenChange={(nextIsOpen) => {
        setState((prevState) => ({ ...prevState, isOpen: nextIsOpen }));
        if (nextIsOpen) {
          onOpen?.();
        } else {
          onClose?.();
        }
      }}
      open={isOpen}
    >
      <div
        className={classnames({
          'popover-trigger-v2': true,
          'popover-trigger-v2--open': isOpen,
          [className]: className,
        })}
        ref={elementRef}
        data-testid={dataTestId}
      >
        <Shadcn.PopoverTrigger asChild>
          <Shadcn.PopoverAnchor asChild>
            <div className="popover-trigger-v2__target" onClick={onClick}>
              {typeof children === 'function' ? (
                <div onClick={onTriggerClick} className="flex size-full">
                  {children({ close, isOpen, open })}
                </div>
              ) : (
                children
              )}
            </div>
          </Shadcn.PopoverAnchor>
        </Shadcn.PopoverTrigger>
      </div>
      <Shadcn.PopoverPortal container={container || elementRef.current}>
        <Shadcn.PopoverContent
          align={getAlign(position)}
          onOpenAutoFocus={onOpenAutoFocus}
          className={classnames({
            'popover-trigger-v2__popover__content theme': true,
            [contentClassName]: contentClassName,
          })}
          side={getSide(position)}
        >
          {isOpen ? popover({ close, open }) : null}
        </Shadcn.PopoverContent>
      </Shadcn.PopoverPortal>
    </Shadcn.PopoverRoot>
  );
};

export default PopoverTriggerV2;
