import { useThemeContext } from 'components';
import React, { useMemo, ReactElement, useRef, useEffect } from 'react';
import {
  AreaSelectionProps,
  TimeseriesRenderProps,
  TimeseriesToolProps,
  TooltipCoordsProps,
  UplotExtended,
} from 'types';
import { AlignedData } from 'uplot';

import UPlotChart from './UplotChart';
import { heatmapConfigBuilder } from './ChartConfigBuilder';
import { TooltipTypes } from './types';
import TooltipCompact from './Tooltip/TooltipCompact';
import { drawSelectionRectangle } from './utils';

const getAbsoluteAreaSelectionRec = (rect: AreaSelectionProps['rect']) => {
  const w = Math.abs(rect.w);
  const h = Math.abs(rect.h);
  const x = rect.w < 0 ? rect.x + rect.w : rect.x;
  const y = rect.y < 0 ? rect.y + rect.h : rect.y;

  return {
    w,
    h,
    x,
    y,
  };
};

const getSelectionMenuPosition = (
  areaSelection: AreaSelectionProps,
  bbox: DOMRect,
): TooltipCoordsProps => {
  const devicePixelRatio = window.devicePixelRatio;
  const { x, y, w, h } = getAbsoluteAreaSelectionRec(areaSelection.rect);

  const adjustedX = x / devicePixelRatio + bbox.left;
  const adjustedY = y / devicePixelRatio + bbox.top;
  const adjustedWidth = w / devicePixelRatio;
  const adjustedHeight = h / devicePixelRatio;

  const positionX =
    adjustedX + adjustedWidth > window.innerWidth / 2 ? 'left' : 'right';
  const positionY =
    adjustedY + adjustedHeight > window.innerHeight / 2 ? 'top' : 'bottom';

  const xOffset = positionX === 'right' ? adjustedWidth : 0;
  const MARGIN = 8 * devicePixelRatio;
  const marginY = positionY === 'top' ? -MARGIN : 0;
  const marginX = positionX === 'left' ? -MARGIN : 0;

  const nOffsetX = adjustedWidth < 0 ? adjustedWidth : 0;
  const nOffsetY = adjustedHeight < 0 ? adjustedHeight / 2 : 0;

  const result = {
    x: adjustedX + xOffset + marginX + nOffsetX || 0,
    y: adjustedY + marginY + nOffsetY || 0,
    positionX,
    positionY,
  };

  return result;
};

const getTooltip = ({
  activeChart,
  config,
  cursorState,
  onCursorStateChange,
  tooltipType,
  unit,
}: TimeseriesToolProps & {
  tooltipType?: TooltipTypes;
}) => {
  if (tooltipType === 'compact') {
    return (
      <TooltipCompact
        chartType={activeChart}
        config={config}
        cursorState={cursorState}
        minDecimalCount={2}
        onCursorStateChange={onCursorStateChange}
        unit={unit}
      />
    );
  }
  return null;
};

const HeatmapRenderer = ({
  areaSelection,
  chartData,
  hooks,
  layoutType,
  numXAxisSplits,
  numYAxisSplits,
  onAreaSelectionChange,
  onAreaSelectionStart,
  renderSelectionMenu,
  size,
  unit,
}: {
  areaSelection: AreaSelectionProps | null;
  chartData: TimeseriesRenderProps['chartData'] & {
    uniqueTimestamps: number[];
  };
  hooks: TimeseriesRenderProps['hooks'];
  layoutType: TimeseriesRenderProps['layoutType'];
  numXAxisSplits: number;
  numYAxisSplits: number;
  onAreaSelectionStart: VoidFunction;
  onAreaSelectionChange: (values: AreaSelectionProps) => void;
  renderSelectionMenu: (
    areaSelection: AreaSelectionProps,
    position: TooltipCoordsProps,
  ) => ReactElement;
  size: TimeseriesRenderProps['size'];
  unit: TimeseriesRenderProps['unit'];
}): ReactElement => {
  const { darkModeEnabled, utcTimeEnabled } = useThemeContext();
  const chartRef = useRef<UplotExtended>(null);
  const options = useMemo(() => {
    return heatmapConfigBuilder({
      chartData,
      darkModeEnabled,
      layoutType,
      unit,
      utcTimeEnabled,
      numXAxisSplits,
      numYAxisSplits,
      width: size.width,
      height: size.height,
      hooks,
      onAreaSelectionStart,
      onAreaSelectionChange,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chartData,
    size.width,
    size.height,
    unit,
    darkModeEnabled,
    utcTimeEnabled,
    hooks,
    numXAxisSplits,
    numYAxisSplits,
  ]);

  useEffect(() => {
    if (chartRef.current) {
      if (areaSelection) {
        chartRef.current.areaSelection.rect = areaSelection.rect;
        drawSelectionRectangle({ u: chartRef.current });
      } else {
        chartRef.current.clearCanvasByContext(chartRef.current.eventOverlayCtx);
      }
    }
  }, [areaSelection, darkModeEnabled, utcTimeEnabled]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.clearCanvasByContext(chartRef.current.eventOverlayCtx);
      onAreaSelectionChange && onAreaSelectionChange(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, size.width, size.height, unit]);

  return (
    <div>
      <UPlotChart
        activeChart="Heatmap"
        data={chartData.data as AlignedData}
        options={options}
        chartRef={chartRef}
      />
      {getTooltip({
        activeChart: 'Heatmap',
        config: options,
        tooltipType: 'compact',
        unit,
      })}
      {areaSelection &&
        renderSelectionMenu(
          areaSelection,
          getSelectionMenuPosition(
            areaSelection,
            chartRef.current?.root.getBoundingClientRect(),
          ),
        )}
    </div>
  );
};

export default HeatmapRenderer;
