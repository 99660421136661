export const facetNames = [
  {
    facetName: 'aws_account',
    name: 'aws_account',
    renderName: () => 'Account ID',
  },
  {
    facetName: 'metric_stream_name',
    name: 'metric_stream_name',
    renderName: () => 'Metric Stream Name',
  },
  { facetName: 'region', name: 'region', renderName: () => 'Region' },
];
