import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type LocalStorageStore = {
  tableEventsSettings: { listDensity: string };
  setTableEventsSettings: (newSettings: { listDensity: string }) => void;
};

const storageOptions = {
  name: 'eventsLocalStorageStore',
};

const useEventsLocalStorageStore = create(
  persist<LocalStorageStore>(
    (set) => ({
      tableEventsSettings: { listDensity: 'Expanded' },
      setTableEventsSettings: (newSettings) =>
        set({ tableEventsSettings: newSettings }),
    }),
    storageOptions,
  ),
);

export default useEventsLocalStorageStore;
