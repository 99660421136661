import React, { useMemo } from 'react';
import { getRules } from '../utils/selectorsfunction';

import { Role } from '../types';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellTimestamp,
  KubeTableCell,
} from './Components';
import KubernetesTable from './KubernetesTable';
import { useEntitiesCount, useEntitiesData } from './useEntitiesData';

export default function KubernetesTableForRole() {
  const { entitiesType, facets, page, limit } = useKubernetesController();

  const filters = useMemo(() => [facets], [facets]);
  const [rows] = useEntitiesData<{ role: Role }>({
    filters,
    page,
    limit,
  });
  const [count] = useEntitiesCount({
    entitiesType,
    facets,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'kube_role',
          label: 'Role',
          renderCell(prop) {
            const roleName = prop.row.role.metadata.name;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name pt-1.5 pb-1.5"
                style={{ maxWidth: 180 }}
                tooltipText={roleName}
              >
                {roleName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell(prop) {
            return <KubeCellCluster {...prop} />;
          },
        },
        {
          key: 'metadata.namespace',
          label: 'Namespace',
          renderCell(prop) {
            return <KubeCellNamespace {...prop} />;
          },
        },
        {
          key: 'headerRules',
          label: 'Rules',
          renderCell(prop) {
            const roleRules = getRules(prop.row.role);
            return (
              <KubeTableCell
                {...prop}
                align="left"
                className="kubernetes__table__cell__entity-name"
                style={{ maxWidth: 340 }}
                tooltipText={roleRules}
              >
                {roleRules}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'metadata.creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            return (
              <KubeCellTimestamp
                {...prop}
                timestamp={prop.row.role.metadata.creationTimestamp}
              />
            );
          },
        },
      ]}
    />
  );
}
