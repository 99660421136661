import { useEffect, useState } from 'react';

export default function useAsync<T>(
  fn: (signal: AbortSignal) => Promise<T>,
  dependencies: any[],
) {
  const [state, setState] = useState<[T, 'pending' | 'fulfilled' | 'rejected']>(
    [null, 'pending'],
  );

  useEffect(() => {
    const controller = new AbortController();

    setState([null, 'pending']);

    Promise.all(dependencies)
      .then(() => {
        if (!controller.signal.aborted) {
          return fn(controller.signal);
        }
      })
      .then((result) => {
        if (!controller.signal.aborted) {
          setState([result, 'fulfilled']);
        }
      })
      .catch((error) => {
        if (!controller.signal.aborted) {
          setState([error, 'rejected']);
        }
      });

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return state;
}
