import { FacetName } from 'types/generated';
import { escapeDoubleQuote, onPromiseError } from 'utils';

import query from './query';

const getLogsFavouriteFacetsByCursor = async ({
  contains = '',
  cursor,
  limit,
}: {
  contains: string;
  cursor?: string;
  limit?: number;
}): Promise<{ facetNames: FacetName[]; cursor: string | undefined }> => {
  const escapeContains = escapeDoubleQuote(contains);
  return query<FacetName[], 'getFavoriteFacets'>(`
        query GetFavoriteFacets {
          getFavoriteFacets(
            contains: "${escapeContains}"
            ${cursor ? `cursor: "${cursor}"` : ''}
            ${limit ? `limit: ${limit}` : ''}
          ) {
            name
            dataType
            group
            displayName
            source
          }
        }
      `).then((data) => {
    const favoriteFacets = data.getFavoriteFacets || [];
    const formattedFacets = favoriteFacets.map((f) => ({
      ...f,
      type: f.dataType,
      component: f.source,
    }));

    return { facetNames: formattedFacets, cursor: undefined };
  }, onPromiseError);
};

export default getLogsFavouriteFacetsByCursor;
