import { useThemeContext } from 'components';
import React, { ReactElement, useRef } from 'react';
import MonacoEditor, { EditorProps } from '@monaco-editor/react';
import { promLanguageDefinition } from 'monaco-promql';

interface CodeEditorProps {
  value: string;
  onChange: (value: string) => void;
}

const options: EditorProps['options'] = {
  codeLens: false,
  contextmenu: false,
  fixedOverflowWidgets: true,
  folding: false,
  fontSize: 14,
  lineDecorationsWidth: 8, // used as "padding-left "
  lineNumbers: 'off',
  minimap: { enabled: false },
  overviewRulerBorder: false,
  overviewRulerLanes: 0,
  padding: { top: 4, bottom: 5 },
  renderLineHighlight: 'none',
  scrollbar: {
    vertical: 'hidden',
    verticalScrollbarSize: 8, // used as "padding-right"
    horizontal: 'hidden',
    horizontalScrollbarSize: 0,
  },
  scrollBeyondLastLine: false,
  //   suggest: getSuggestOptions(),
  suggestFontSize: 12,
  wordWrap: 'on',
};

const EDITOR_HEIGHT_OFFSET = 2;

const PROMQL_LANG_ID = promLanguageDefinition.id;
// we must only run the promql-setup code once
let PROMQL_SETUP_STARTED = false;

function ensurePromQL(monaco: any) {
  if (PROMQL_SETUP_STARTED === false) {
    PROMQL_SETUP_STARTED = true;
    const { aliases, extensions, mimetypes, loader } = promLanguageDefinition;
    monaco.languages.register({
      id: PROMQL_LANG_ID,
      aliases,
      extensions,
      mimetypes,
    });

    loader().then((mod) => {
      monaco.languages.setMonarchTokensProvider(PROMQL_LANG_ID, mod.language);
      monaco.languages.setLanguageConfiguration(
        PROMQL_LANG_ID,
        mod.languageConfiguration,
      );
    });
  }
}

const CodeEditor = ({ value, onChange }: CodeEditorProps): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { darkModeEnabled } = useThemeContext();

  return (
    <div ref={containerRef} className="flex flex-1 flex-grow flex-wrap">
      <MonacoEditor
        language="promql"
        theme={darkModeEnabled ? 'vs-dark' : 'vs-light'}
        value={value}
        onChange={onChange}
        options={options}
        beforeMount={(monaco) => {
          ensurePromQL(monaco);
        }}
        onMount={(editor) => {
          const updateElementHeight = () => {
            const containerDiv = containerRef.current;
            if (containerDiv !== null) {
              const pixelHeight = editor.getContentHeight();
              containerDiv.style.height = `${
                pixelHeight + EDITOR_HEIGHT_OFFSET
              }px`;
              containerDiv.style.width = '90%';
              const pixelWidth = containerDiv.clientWidth;
              editor.layout({ width: pixelWidth, height: pixelHeight });
            }
          };

          editor.onDidContentSizeChange(updateElementHeight);
          updateElementHeight();
        }}
      />
    </div>
  );
};

export default CodeEditor;
