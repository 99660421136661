import { Button } from 'components';
import React from 'react';

import AlertsSilencesEditSchedule from './AlertsSilencesEditSchedule';
import AlertsSilencesEditMatcher from './AlertsSilencesEditMatcher';
import AlertsSilencesEditInstances from './AlertsSilencesEditInstances';
import { useAlertsSilenceEditState } from './hooks';

const AlertsSilencesEdit = () => {
  const alertsSilenceEditState = useAlertsSilenceEditState();
  const { createOrUpdateSilenceRequest, onSaveSilence, silenceByUidRequest } =
    alertsSilenceEditState;

  return (
    <div className="mx-auto max-w-[1000px] py-4">
      <div className="flex items-center justify-between pb-2">
        <h2 className="text-lg font-semibold">Edit Silence</h2>
      </div>
      {silenceByUidRequest.isLoading ||
      createOrUpdateSilenceRequest.isLoading ? (
        <div className="admin__row-muted flex min-h-[240px] items-center justify-center">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <div className="mt-2 flex flex-col">
            <AlertsSilencesEditSchedule
              alertsSilenceEditState={alertsSilenceEditState}
            />
          </div>
          <div className="mb-2 mt-4 text-sm font-medium">Matching Labels *</div>
          <AlertsSilencesEditMatcher
            alertsSilenceEditState={alertsSilenceEditState}
          />
          <div className="flex items-center justify-end pt-4">
            <Button variant="default" size="sm" onClick={onSaveSilence}>
              Save Silence
            </Button>
          </div>
        </>
      )}
      <AlertsSilencesEditInstances
        alertsSilenceEditState={alertsSilenceEditState}
      />
    </div>
  );
};

export default AlertsSilencesEdit;
