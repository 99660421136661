import React, { useMemo } from 'react';
import { useKubernetesController } from '../KubernetesController';
import { Service } from '../types';

import { KubeCellTimestamp, KubeTableCell } from './Components';
import KubernetesTable from './KubernetesTable';
import { useEntitiesCount, useEntitiesData } from './useEntitiesData';

export default function KubernetesTableForService() {
  const { entitiesType, facets, page, limit } = useKubernetesController();

  const filters = useMemo(() => [facets], [facets]);
  const [rows] = useEntitiesData<{ service: Service }>({
    filters,
    page,
    limit,
  });
  const [count] = useEntitiesCount({
    entitiesType,
    facets,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'kube_service',
          label: 'Service',
          renderCell(prop) {
            const serviceName = prop.row.service.metadata.name;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name pb-1.5 pt-1.5"
                tooltipText={serviceName}
              >
                {serviceName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'metadata.creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            const timestamp = prop.row.service.metadata.creationTimestamp;
            return <KubeCellTimestamp {...prop} timestamp={timestamp} />;
          },
        },
        {
          key: 'spec.type',
          label: 'Type',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="left">
                {prop.row.service.spec.type}
              </KubeTableCell>
            );
          },
        },

        {
          key: 'spec.clusterIp',
          label: 'Cluster IP',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="left">
                {prop.row.service.spec.clusterIp}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'spec.externalIPs',
          label: 'External IPS',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="left">
                {prop.row.service.spec.externalIPs || '-'}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerPorts',
          label: 'Ports',
          renderCell(prop) {
            const ports = prop.row.service.spec.ports;

            if (!ports) return '-';
            return (
              <KubeTableCell {...prop} align="left">
                {ports[0]?.name || ports[0]?.port + '/' + ports[0]?.protocol}
              </KubeTableCell>
            );
          },
        },
      ]}
    />
  );
}
