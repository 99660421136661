import {
  MetricSeriesProps,
  MertricsSummaryProps,
  SearchFilterProps,
} from '../types';
/**
 * Transform for metric list
 * @returns
 * example: {name: {}, value: {}} => [name, value]
 */
export const parseMetricList = (grafanaMetadata: {
  [key: string]: MertricsSummaryProps[];
}): MertricsSummaryProps[] => {
  return Object.keys(grafanaMetadata).map((key) => {
    return { ...grafanaMetadata[key][0], name: key };
  });
};

/**
 * Group by property key
 * @param grafanaSeries
 */
export const groupMetricSeries = (grafanaSeries: any): MetricSeriesProps => {
  const metricSeries: { [key: string]: Array<string> } = {};
  const metricKeys: string[] = [];
  grafanaSeries.map((series: any) => {
    const seriesKeys = Object.keys(series);
    seriesKeys.forEach((key) => {
      if (key === '__name__') {
        return;
      }
      if (!metricSeries[key]) {
        metricSeries[key] = [];
        metricKeys.push(key);
      }
      metricSeries[key].push(series[key]);
    });
  });

  metricKeys.sort((a, b) => (a > b ? 1 : -1));

  metricKeys.forEach((key) => {
    metricSeries[key] = metricSeries[key].filter(
      (value: string, index: number, self: any) => {
        return self.indexOf(value) === index;
      },
    );
  });
  return { data: metricSeries, metricKeys };
};

export const filterMetricSummaryList = (
  metricName: string,
  filter: SearchFilterProps,
): boolean => {
  const { search } = filter;
  const searchLowered = search.toLowerCase().trim();
  const isSearchMatch = metricName.toLowerCase().indexOf(searchLowered) > -1;

  return isSearchMatch;
};

export const metricOriginOptions = [
  { label: 'APM', value: 'apm' },
  { label: 'Log', value: 'log' },
];
