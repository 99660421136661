import { useUrlState, useToggle } from 'hooks';

import { ExplorerQueryProps, FormulaProps } from 'types/MetricsQueryBuilder';
import {
  prometheusQueryOperator,
  prometheusQueryOperatorRegex,
  validateArithmeticFormulas,
} from 'utils';

import useMetricsDataState from './useMetricsDataState';
import { DateSelection } from 'types/DateSelection';

type MetricsFormulaStateReturnType = {
  addFormula: () => void;
  checkAndLoadedAffectedFormulas: (
    queryIndex: number,
    newQueries: ExplorerQueryProps[],
    callType: 'normal' | 'debounce',
  ) => void;
  formulas: FormulaProps[];
  removeFormula: (formulaIndex: number) => void;
  toggleAddFormula: ReturnType<typeof useToggle>;
  updateFormula: (
    formulaIndex: number,
    propertyKey: string,
    value: any,
  ) => void;
  setFormulas: React.Dispatch<React.SetStateAction<FormulaProps[]>>;
  validateAffectedFormulas: ({
    queryIndex,
    newQueries,
    validateType,
  }: {
    queryIndex: number;
    newQueries: ExplorerQueryProps[];
    validateType: 'add' | 'remove';
  }) => void;
};

const useMetricsFormulaState = ({
  date,
  activeQueryType,
  formulasState,
  queries,
  setQueries,
  metricsDataState,
  urlCleanUp = false,
}: {
  date: DateSelection;
  activeQueryType: 'multi' | 'single';
  formulasState?: ReturnType<typeof useUrlState<FormulaProps[]>>;
  queries: ExplorerQueryProps[];
  setQueries: (
    queries:
      | ExplorerQueryProps[]
      | ((prevState: ExplorerQueryProps[]) => ExplorerQueryProps[]),
  ) => void;
  metricsDataState: ReturnType<typeof useMetricsDataState>;
  urlCleanUp?: boolean;
}): MetricsFormulaStateReturnType => {
  const [formulasUrl, setFormulasUrl] = useUrlState(
    'metricsFormulas',
    [],
    urlCleanUp,
  );

  const formulas = formulasState ? formulasState[0] : formulasUrl;
  const setFormulas = formulasState ? formulasState[1] : setFormulasUrl;

  const toggleAddFormula = useToggle(false);
  const { callOnePromqlQuery, queryData, removeQueryData, setQueryData } =
    metricsDataState;

  const addFormula = () => {
    setFormulas((prevFormulas: FormulaProps[]) => {
      const newFormulas = [...prevFormulas];

      if (activeQueryType === 'single') {
        setQueries((prevQueries: ExplorerQueryProps[]) => {
          const newQueries = [...prevQueries];
          newQueries.forEach((query) => (query.isActive = false));
          return newQueries;
        });
        newFormulas.map((formula) => (formula.isActive = false));
      }

      newFormulas.push({
        expression: '',
        isActive: true,
        isValid: false,
        queryKey: (newFormulas.length + 1).toString(),
      });

      return newFormulas;
    });
  };

  const removeFormula = (formulaIndex: number) => {
    setFormulas((prevFormulas: FormulaProps[]) => {
      const newFormulas = [...prevFormulas];
      const formula = newFormulas[formulaIndex];
      newFormulas.splice(formulaIndex, 1);
      removeQueryData(`formula_${formula.queryKey}`);
      return newFormulas;
    });
  };

  const updateFormula = (
    formulaIndex: number,
    propertyKey: string,
    value: any,
  ) => {
    setFormulas((prevFormulas: FormulaProps[]) => {
      const newFormulas = [...prevFormulas];
      newFormulas[formulaIndex][propertyKey] = value;

      if (propertyKey === 'isActive') {
        setQueryData({ ...queryData });
        return newFormulas;
      }

      const queryKeys = queries.map((query) => query.queryKey);
      const isValid = validateArithmeticFormulas({
        formula: value,
        queryVar: queryKeys,
        operators: prometheusQueryOperator,
        operatorRegex: prometheusQueryOperatorRegex,
      });
      if (isValid) {
        newFormulas[formulaIndex].isValid = true;
        if (newFormulas[formulaIndex].isActive) {
          callOnePromqlQuery({
            callType: 'debounce',
            date,
            formulas: newFormulas,
            queryIndex: formulaIndex,
            queries,
            type: 'formula',
          });
        }
      }

      return newFormulas;
    });
  };

  const validateAffectedFormulas = ({
    queryIndex,
    newQueries,
    validateType,
  }: {
    queryIndex: number;
    newQueries: ExplorerQueryProps[];
    validateType: 'add' | 'remove';
  }) => {
    const queryKey = newQueries[queryIndex].queryKey;
    const queryKeys = queries
      .map((query) => query.queryKey)
      .filter((key) => key !== queryKey);

    if (validateType === 'add') {
      queryKeys.push(queryKey);
    }

    setFormulas((prevFormulas: FormulaProps[]) => {
      const newFormulas = [...prevFormulas];
      let isOneFormulaChanged = false;

      newFormulas.forEach((formula: FormulaProps, formulaIndex: number) => {
        const validatedExpression = validateArithmeticFormulas({
          formula: formula.expression,
          queryVar: queryKeys,
          operators: prometheusQueryOperator,
          operatorRegex: prometheusQueryOperatorRegex,
        });

        if (formula.isValid !== validatedExpression) {
          isOneFormulaChanged = true;
          const formulaId = `formula_${formula.queryKey}`;
          setQueryData({
            ...queryData,
            [formulaId]: { ...queryData[formulaId], range: null },
          });
          if (validatedExpression) {
            newFormulas[formulaIndex].isValid = validatedExpression;
            callOnePromqlQuery({
              callType: 'normal',
              date,
              formulas: newFormulas,
              queryIndex: formulaIndex,
              queries: newQueries,
              type: 'formula',
            });
          }
        }
        formula.isValid = validatedExpression;
      });

      if (isOneFormulaChanged) {
        return newFormulas;
      }
      return prevFormulas;
    });
  };

  const checkAndLoadedAffectedFormulas = (
    queryIndex: number,
    newQueries: ExplorerQueryProps[],
    callType: 'normal' | 'debounce',
  ) => {
    const queryKey = newQueries[queryIndex].queryKey;
    formulas.forEach((formula: FormulaProps, formulaIndex: number) => {
      const { expression, isValid } = formula;
      if (isValid && expression.includes(queryKey) && formula.isActive) {
        callOnePromqlQuery({
          callType,
          date,
          formulas,
          queryIndex: formulaIndex,
          queries: newQueries,
          type: 'formula',
        });
      }
    });
  };

  return {
    addFormula,
    checkAndLoadedAffectedFormulas,
    formulas,
    removeFormula,
    toggleAddFormula,
    updateFormula,
    setFormulas,
    validateAffectedFormulas,
  };
};

export default useMetricsFormulaState;
