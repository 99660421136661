import { fetchJson } from 'requests';

import { UserGroupProps } from '../types';

const getUserGroupsById = async ({
  userId,
}: {
  userId: string;
}): Promise<UserGroupProps[]> => {
  const result = await fetchJson(`rbac/users/${userId}/groups`);

  return result as UserGroupProps[];
};

export default getUserGroupsById;
