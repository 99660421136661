import {
  LeftSidebar,
  ShowSidebarTooltipButton,
  useLeftSidebarState,
} from 'components';
import { Datepicker } from 'composite';
import React, { ReactElement, useEffect, useState } from 'react';
import { RefreshCw } from 'react-feather';
import { DateSelection } from 'types';
import { refreshDate } from 'utils/refreshDate';
import { EventsSearchBar, EventsSidebar } from '../components';
import EventsTabs from '../components/EventsTabs';
import { useEventsState } from '../hooks';
import EventsExplorerChart from './EventsExplorerChart';
import EventsExplorerTable from './EventsExplorerTable';

const EventsExplorer = (): ReactElement => {
  const [error, setError] = useState({
    getEvents: null,
  });
  const leftSidebarState = useLeftSidebarState('events-explorer');
  const eventsState = useEventsState({
    urlKeysToSync: ['DATE', 'EVENTS_FILTER'],
  });
  const { date, setDate, filterState } = eventsState;

  useEffect(() => {
    setError((prevError) => ({
      ...prevError,
      getEvents: eventsState.error
        ? { message: 'Failed to fetch events' }
        : null,
    }));
  }, [eventsState?.error]);

  return (
    <div className="events">
      <LeftSidebar
        className="events__left-sidebar"
        leftSidebarState={leftSidebarState}
      >
        <EventsSidebar eventsState={eventsState} />
      </LeftSidebar>
      <div className="events__main">
        <div className="events__header">
          {leftSidebarState.width === 0 ? (
            <ShowSidebarTooltipButton onClick={leftSidebarState.show} />
          ) : null}
          <EventsSearchBar
            eventsState={eventsState}
            filterState={filterState}
          />
          <div>
            <Datepicker
              onChange={setDate as (dateSelection: DateSelection) => void}
              value={date as DateSelection}
            />
          </div>
          <button
            className="events__header__refresh-button"
            onClick={() => refreshDate(date, setDate)}
            data-testid="refresh"
          >
            <RefreshCw size={14} />
          </button>
        </div>
        <div className="flex flex-col flex-1 relative">
          <EventsTabs />
          <EventsExplorerChart eventsState={eventsState} />
          <EventsExplorerTable
            eventsState={eventsState}
            errorMessage={error?.getEvents?.message}
          />
        </div>
      </div>
    </div>
  );
};

export default EventsExplorer;
