import {
  FunctionProps,
  FunctionNamesProps,
  FunctionParamsProps,
  DateSelection,
} from 'types';

import {
  getForecastParams,
  getForecastPromQL,
  FORECAST_FUNCTIONS,
} from './function-forecast';
import {
  AGGREGATE_FUNCTIONS,
  getAggregationParam,
  getAggregationPromQL,
} from './functions-aggregation-utils';
import {
  ANOMALIES_FUNCTIONS,
  getAnomaliesParams,
  getAnomaliesPromQL,
} from './functions-anomalies';
import functionsNames from './functionsNames';
import { DURATION_OPTIONS } from './functions-params-utils';
import {
  getRollupParam,
  getRollupPromQL,
  ROLLUP_FUNCTIONS,
} from './functions-rollup-utils';
import {
  getTrigonometricPromQL,
  TRIGONOMETRIC_FUNCTIONS,
} from './functions-trig-utils';

const EMPTY_PARAMS_METRICS = [
  'abs',
  'absent',
  'ceil',
  'log2',
  'log10',
  'exp',
  'floor',
  'scalar',
  'sort',
  'sort_desc',
  'ln',
  'sgn',
  'sqrt',
  'timestamp',
];

const TIME_FUNCTIONS = [
  'day_of_month',
  'day_of_week',
  'day_of_year',
  'days_in_month',
  'hour',
  'minute',
  'month',
  'year',
];

export const OFFSET_FUNCTIONS = [
  'day_before',
  'hour_before',
  'week_before',
  'month_before',
];

const RANGE_VECTOR_FUNCTIONS = [
  'absent_over_time',
  'changes',
  'count_not_null',
  'deriv',
  'diff',
  'increase',
  'irate',
  'monotonic_diff',
  'rate_per_hour',
  'rate_per_minute',
  'rate_per_second',
  'resets',
];

export const DURATION_PARAM_FUNCTIONS = [
  ...ROLLUP_FUNCTIONS,
  ...RANGE_VECTOR_FUNCTIONS,
];

export const OUTLIER_OPTIONS = [{ label: 'DBSCAN', value: 'DBSCAN' }];

export const getFunctionNameByShortName = (
  shortName: string,
): FunctionNamesProps => {
  const functionName = functionsNames.find(
    (functionProps) => functionProps.shortName === shortName,
  );
  return functionName ? functionName : null;
};

export const getFunctionParams = (
  functionName: string,
): FunctionParamsProps[] => {
  if (AGGREGATE_FUNCTIONS.includes(functionName)) {
    return getAggregationParam(functionName);
  }

  if (ROLLUP_FUNCTIONS.includes(functionName)) {
    return getRollupParam(functionName);
  }

  if (ANOMALIES_FUNCTIONS.includes(functionName)) {
    return getAnomaliesParams(functionName);
  }

  if (FORECAST_FUNCTIONS.includes(functionName)) {
    return getForecastParams();
  }

  if (TRIGONOMETRIC_FUNCTIONS.includes(functionName)) {
    return null;
  }

  switch (functionName) {
    case 'abs':
    case 'log2':
    case 'log10':
    case 'scalar':
    case 'exp':
    case 'floor':
    case 'count_nonzero':
    case 'absent':
    case 'day_of_month':
    case 'day_of_week':
    case 'days_in_month':
    case 'day_of_year':
    case 'hour':
    case 'minute':
    case 'month':
    case 'year':
    case 'sort':
    case 'sort_desc':
    case 'ln':
    case 'sgn':
    case 'sqrt':
    case 'timestamp':
    case 'ceil':
      return null;
    case 'absent_over_time':
      return [
        {
          name: 'duration',
          default: '1m',
          options: DURATION_OPTIONS,
          type: 'select',
          value: '1m',
        },
      ];
    case 'changes':
    case 'resets':
      return [
        {
          name: 'duration',
          default: '5m',
          options: DURATION_OPTIONS,
          type: 'select',
          value: '5m',
        },
      ];
    case 'histogram_quantile':
      return [
        {
          name: 'quantile',
          default: 0.99,
          value: 0.99,
          type: 'text',
        },
      ];
    case 'monotonic_diff':
    case 'increase':
    case 'diff':
    case 'irate':
    case 'rate_per_second':
    case 'rate_per_minute':
    case 'rate_per_hour':
    case 'count_not_null':
    case 'offset':
    case 'deriv':
      return [
        {
          name: 'duration',
          default: '10m',
          options: DURATION_OPTIONS,
          type: 'select',
          value: '10m',
        },
      ];
    case 'clamp_max':
      return [{ name: 'max', default: 100, value: 100, type: 'text' }];
    case 'clamp_min':
      return [{ name: 'min', default: 0, value: 0, type: 'text' }];
    case 'cutoff_max':
      return [{ name: 'max', default: 100, value: 100, type: 'text' }];
    case 'cutoff_min':
      return [{ name: 'min', default: 100, value: 100, type: 'text' }];
    case 'clamp':
      return [
        { name: 'min', default: 0, value: 0, type: 'text' },
        { name: 'max', default: 100, value: 100, type: 'text' },
      ];
    case 'topk':
      return [{ name: 'k', default: 10, value: 10, type: 'text' }];
    case 'round':
      return [{ name: 'nearest', default: 1, value: 1, type: 'text' }];
    case 'predict_linear':
      return [
        {
          name: 'interval',
          default: '5m',
          options: DURATION_OPTIONS,
          value: '5m',
          type: 'select',
        },
        { name: 'deviations', default: 1, value: 1, type: 'text' },
      ];
    case 'outliers':
      return [
        {
          name: 'outlier',
          default: 'DBSCAN',
          options: OUTLIER_OPTIONS,
          value: 'DBSCAN',
          type: 'select',
        },
        { name: 'tolerance', default: 0.8, value: 0.8, type: 'text' },
      ];
    case 'holt_winters':
      return [
        {
          name: 'duration',
          default: '10m',
          options: DURATION_OPTIONS,
          type: 'select',
          value: '10m',
        },
        { name: 'sf', default: 0.1, value: 0.1, type: 'text' },
        { name: 'tf', default: 0.9, value: 0.9, type: 'text' },
      ];
  }
};

function buildRangeVectorQuery({
  isRangeVector,
  name,
  offset,
  params,
  query,
}: {
  isRangeVector: boolean;
  name: string;
  offset?: FunctionProps;
  params: FunctionParamsProps[];
  query: string;
}): string {
  const [duration] = params;
  let rangeVectorSuffix = `[${duration.value}${isRangeVector ? ':' : ''}]`;
  if (offset) {
    const [offsetDuration] = offset.params;
    rangeVectorSuffix = `${rangeVectorSuffix} offset ${offsetDuration.value} `;
  }

  switch (name) {
    case 'absent_over_time':
    case 'irate':
    case 'increase':
    case 'deriv':
    case 'changes':
    case 'resets':
      return `${name}(${query} ${rangeVectorSuffix})`;
    case 'diff':
      return `delta(${query} ${rangeVectorSuffix})`;
    case 'monotonic_diff':
      return `idelta(${query} ${rangeVectorSuffix})`;
    case 'rate_per_second':
      return `rate(${query} ${rangeVectorSuffix})`;
    case 'rate_per_minute':
      return `rate(${query} ${rangeVectorSuffix}) * 60`;
    case 'rate_per_hour':
      return `rate(${query} ${rangeVectorSuffix}) * 3600`;
    case 'count_not_null':
      return `present_over_time(${query} ${rangeVectorSuffix})`;
    default:
      return `${name}(${query})`;
  }
}

export const buildFunctionQuery = ({
  date,
  func,
  isRangeVector,
  offset,
  query,
  steps,
}: {
  date?: DateSelection;
  func: FunctionProps;
  isRangeVector: boolean;
  offset?: FunctionProps;
  query: string;
  steps?: number;
}): string => {
  const { name, params } = func;
  if (EMPTY_PARAMS_METRICS.includes(name)) {
    return `${name}(${query})`;
  }

  if (ROLLUP_FUNCTIONS.includes(name)) {
    return getRollupPromQL({
      func,
      isRangeVector,
      query,
      offset,
    });
  }

  if (AGGREGATE_FUNCTIONS.includes(name)) {
    return getAggregationPromQL(query, params);
  }

  if (TIME_FUNCTIONS.includes(name)) {
    return `${name}(${query})`;
  }

  if (TRIGONOMETRIC_FUNCTIONS.includes(name)) {
    return getTrigonometricPromQL(name, query);
  }

  if (ANOMALIES_FUNCTIONS.includes(name)) {
    return getAnomaliesPromQL(func, isRangeVector, query, steps, date);
  }
  if (FORECAST_FUNCTIONS.includes(name)) {
    return getForecastPromQL(func, isRangeVector, query, steps, date);
  }

  if (name === 'histogram_quantile') {
    const [quantile] = params;
    return `${name}(${quantile.value}, ${query} )`;
  }

  if (name === 'offset') {
    const [duration] = params;
    return `${query} offset ${duration.value}`;
  }
  if (name === 'day_before') {
    return `${query} offset 1d`;
  }
  if (name === 'hour_before') {
    return `${query} offset 1h`;
  }
  if (name === 'month_before') {
    return `${query} offset 4w`;
  }
  if (name === 'week_before') {
    return `${query} offset 1w`;
  }
  if (name === 'count_nonzero') {
    return `(count(${query}) != 0)`;
  }

  if (RANGE_VECTOR_FUNCTIONS.includes(name)) {
    return buildRangeVectorQuery({
      isRangeVector,
      name,
      offset,
      params,
      query,
    });
  }

  if (name === 'clamp_max') {
    const [max] = params;
    return `clamp_max(${query}, ${max.value})`;
  }
  if (name === 'clamp_min') {
    const [min] = params;
    return `clamp_min(${query}, ${min.value})`;
  }
  if (name === 'cutoff_max') {
    const [max] = params;
    return `${query} <= ${max.value}`;
  }
  if (name === 'cutoff_min') {
    const [min] = params;
    return `${query} >= ${min.value}`;
  }
  if (name === 'clamp') {
    const [min, max] = params;
    return `clamp(${query}, ${min.value || 0}, ${max.value || 100})`;
  }
  if (name === 'topk') {
    const [k] = params;
    return `topk(${k.value}, ${query})`;
  }
  if (name === 'round') {
    const [nearest] = params;
    return `round(${query}, ${nearest.value})`;
  }
  if (name === 'predict_linear') {
    const [interval, deviations] = params;
    return `predict_linear(${query} [${interval.value}${
      isRangeVector ? ':' : ''
    }], ${deviations.value})`;
  }

  if (name === 'outliers') {
    const [outlier, tolerance] = params;
    return `dbscan(${query}, ${tolerance.value}, 0.6)`;
  }

  if (name === 'holt_winters') {
    const [duration, sf, tf] = params;
    return `holt_winters(${query} [${duration.value}${
      isRangeVector ? ':' : ''
    }], ${sf.value}, ${tf.value})`;
  }

  return `${name}(${query})`;
};
