import { Loader } from 'components';
import { useColumnsState } from 'components/TableOptionsPopover';
import useUrlSearchParams from 'hooks/useUrlSearchParams';
import React, { useEffect, useMemo } from 'react';
import getColumns from 'screens/Serverless/ServerlessTableWrapper/getColumns';
import useKpisByFunctionNameRequest from 'screens/Serverless/hooks/useKpisByFunctionNameRequest';
import ServerlessTable from './ServerlessTable';

const ServerlessTableWrapper = ({
  colorsByFunctionName,
  getServerlessFunctionsRequest,
  setActiveServerlessFunction,
  serverlessState,
}: {
  colorsByFunctionName: any;
  getServerlessFunctionsRequest: any;
  setActiveServerlessFunction: any;
  serverlessState: any;
}) => {
  const kpisByFunctionNameRequest = useKpisByFunctionNameRequest();

  const urlSearchParams = useUrlSearchParams();
  const search = urlSearchParams.toString();

  const { date, filterState } = serverlessState;

  const { isLoadingState } = kpisByFunctionNameRequest;
  const kpisByFunctionName = useMemo(
    () => kpisByFunctionNameRequest.result || [],
    [kpisByFunctionNameRequest.result],
  );

  const setActiveFunctionNameHandler =
    ({ row }: { row: any }) =>
    () => {
      setActiveServerlessFunction(row);
    };

  const columns = getColumns(
    colorsByFunctionName,
    date,
    isLoadingState,
    kpisByFunctionName,
    search,
    setActiveFunctionNameHandler,
  );

  const selectedFacetValuesByName = useMemo(
    () => ({
      ...filterState.filter.sidebarFilters,
    }),
    [filterState.filter],
  );

  const columnsState = useColumnsState({
    columns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        name: 1,
        invocations: 1,
        duration: 1,
        errors: 1,
      },
    },
    key: 'services-table',
    onSelectedColumnToggle: ({ key, isSelected }) => {
      if (isSelected) {
        kpisByFunctionNameRequest.fetchSingleColumn({
          date,
          key,
          selectedFacetValuesByName,
        });
      }
    },
  });

  const kpiLastModifiedMapping = useMemo(() => {
    const serverlessMetrics = getServerlessFunctionsRequest.result || {};
    return Object.keys(serverlessMetrics).reduce((obj, metric) => {
      const { LastModified } = serverlessMetrics[metric];
      return { ...obj, [metric]: LastModified };
    }, {});
  }, [getServerlessFunctionsRequest]);

  useEffect(() => {
    kpisByFunctionNameRequest.call({
      columns,
      date,
      selectedColumns: columnsState.state.selectedColumns,
      selectedFacetValuesByName,
    });
  }, [date, selectedFacetValuesByName]);

  return (
    <div className="serverless__body">
      <Loader
        className="serverless__table"
        isLoading={kpisByFunctionNameRequest.isLoading}
      >
        <ServerlessTable
          columnsState={columnsState}
          kpisByFunctionName={kpisByFunctionNameRequest.result || {}}
          kpisByFunctionNameRequest={kpisByFunctionNameRequest}
          kpiLastModifiedMapping={kpiLastModifiedMapping}
        />
      </Loader>
    </div>
  );
};

export default ServerlessTableWrapper;
