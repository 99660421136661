import classnames from 'classnames';
import React from 'react';
import {
  LuTags as Tags,
  LuFilter as Filter,
  LuServer as Server,
} from 'react-icons/lu';

const ICON_BY_SECTION_NAME: {
  filters: React.ReactNode;
  labels: React.ReactNode;
  sources: React.ReactNode;
} = {
  filters: <Filter size={18} />,
  labels: <Tags size={18} />,
  sources: <Server size={18} />,
} as const;

const LeftSidebarSectionHeader = ({
  className,
  children,
  iconName,
  textSize = 'sm',
}: {
  className?: string;
  children: React.ReactNode;
  iconName: 'labels' | 'sources' | 'filters';
  textSize?: 'base' | 'sm';
}) => {
  return (
    <div
      className={classnames(
        'mb-3 ml-1 flex items-center gap-1 font-noto font-semibold ',
        {
          'text-base': textSize === 'base',
          'text-sm': textSize === 'sm',
        },
        className,
      )}
    >
      {ICON_BY_SECTION_NAME[iconName]}
      <div className="w-full">{children}</div>
    </div>
  );
};

export default LeftSidebarSectionHeader;
