import { useEffect, useState } from 'react';
import { convertValueWithUnit } from 'utils';

import { validateAlertThresholdValue } from '../../AlertsCreateValidators';
import { useAlertsCreate } from '../../hooks';
import {
  AnomalyConditionProps,
  ConditionProps,
  ForecastConditionProps,
  MetricChangeConditionProps,
  OutlierConditionProps,
} from '../types';
import { AlertType } from '../../types';
import {
  defaultAnomalyCondition,
  defaultCondition,
  defaultForecastCondition,
  defaultMetrics,
  defaultOutliersCondition,
} from '../utils/';

const useAlertsCreateCondition = ({
  alertsCreateState,
}: {
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
}) => {
  const { errorHandling, ruleType, alertType, setErrorHandling } =
    alertsCreateState;

  const [condition, setCondition] = useState<ConditionProps>({
    ...defaultCondition(ruleType),
  });
  const [metricsChangeCondition, setMetricsChangeCondition] =
    useState<MetricChangeConditionProps>({ ...defaultMetrics });
  const [outlierCondition, setOutlierCondition] =
    useState<OutlierConditionProps>({ ...defaultOutliersCondition });
  const [anomalyCondition, setAnomalyCondition] =
    useState<AnomalyConditionProps>({ ...defaultAnomalyCondition });
  const [forecastCondition, setForecastCondition] =
    useState<ForecastConditionProps>({ ...defaultForecastCondition });

  const clearErrorHandling = (propertyKey: string) => {
    setErrorHandling({ ...errorHandling, [propertyKey]: '' });
  };

  const updateCondition = (
    propertyKey: string,
    value: string,
    unit?: string,
  ) => {
    setCondition((prevCondition) => {
      const newConditions = { ...prevCondition };
      if (propertyKey === 'value') {
        const validationResult = validateAlertThresholdValue({
          value,
          alertType,
          unit,
        });
        if (Object.keys(validationResult).length > 0) {
          setErrorHandling({ ...errorHandling, ...validationResult });
        } else {
          clearErrorHandling(propertyKey);
        }

        if (alertType !== AlertType.ANOMALY) {
          newConditions.rawValue = value;
          const parsedValue = convertValueWithUnit(value, unit);
          if (typeof parsedValue === 'number') {
            newConditions.value = parsedValue.toString();
          } else {
            newConditions.value = '';
          }
          return newConditions;
        }
      }

      newConditions[propertyKey] = value;
      return newConditions;
    });
  };

  const updateForecastCondition = (propertyKey: string, value: string) => {
    setForecastCondition((prevCondition) => {
      const newConditions = { ...prevCondition };
      newConditions[propertyKey] = value;
      return newConditions;
    });
  };

  const setUpdateConditionState = (condition: ConditionProps) => {
    setCondition(condition);
  };

  useEffect(() => {
    if (condition.value) {
      updateCondition('value', condition.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertType]);

  return {
    condition,
    errorHandling,
    metricsChangeCondition,
    outlierCondition,
    anomalyCondition,
    forecastCondition,
    setAnomalyCondition,
    setForecastCondition,
    setMetricsChangeCondition,
    setUpdateConditionState,
    setCondition,
    setOutlierCondition,
    updateCondition,
    updateForecastCondition,
  };
};

export default useAlertsCreateCondition;
