export type GraphQlError = {
  message: string;
  path: string[];
  locations: { line: number; column: number }[];
  extensions: {
    code: string;
    exception: {
      stacktrace: string[];
    };
  };
};

// GRAPHQL_VALIDATION_FAILED
const checkForValidationErrors = (errors: GraphQlError[]): boolean => {
  return errors.some(
    (error) => error.extensions?.code === 'GRAPHQL_VALIDATION_FAILED',
  );
};

export const parseGraphQlError = (errors: GraphQlError[]): string => {
  if (checkForValidationErrors(errors)) {
    return 'The query is invalid';
  }
  const errorMessage = errors.map((error) => error.message).join('\n');
  return errorMessage;
};
