import classnames from 'classnames';
import { SquareWithLabel, Table, TableColumnType } from 'components';
import { useMap } from 'hooks';
import React, { CSSProperties, useMemo } from 'react';
import { ChartLegendTableColumn } from 'types';
import { formatNumber } from 'utils';
import { LegendRow } from './types';

type RenderCellProps = {
  row: LegendRow;
  rowIndex: number;
  value: any;
};

const renderCellAsFormattedNumber = ({ value }: RenderCellProps) =>
  formatNumber(value);

const getColumnsMap = (
  colorMap: { [key: string]: string },
  deselectedKeysMap: ReturnType<typeof useMap>,
) => ({
  [ChartLegendTableColumn.key]: {
    key: 'key',
    label: 'Name',
    renderCell: ({ row, value }: RenderCellProps) => {
      const label = row.label || value;
      return (
        <SquareWithLabel
          className={classnames({
            'square-with-label--is-deselected':
              value in deselectedKeysMap.state,
          })}
          color={colorMap[value]}
          label={label}
          title={label}
        />
      );
    },
  },
  [ChartLegendTableColumn.avg]: {
    type: TableColumnType.NUMBER,
    key: 'avg',
    label: 'Avg',
    renderCell: renderCellAsFormattedNumber,
  },
  [ChartLegendTableColumn.min]: {
    type: TableColumnType.NUMBER,
    key: 'min',
    label: 'Min',
    renderCell: renderCellAsFormattedNumber,
  },
  [ChartLegendTableColumn.max]: {
    type: TableColumnType.NUMBER,
    key: 'max',
    label: 'Max',
    renderCell: renderCellAsFormattedNumber,
  },
  [ChartLegendTableColumn.sum]: {
    type: TableColumnType.NUMBER,
    key: 'sum',
    label: 'Sum',
    renderCell: renderCellAsFormattedNumber,
  },
});

type Props = {
  colorMap: { [key: string]: string };
  deselectedKeysMap: ReturnType<typeof useMap>;
  keys: string[];
  legendRows: LegendRow[];
  legendTableColumns: ChartLegendTableColumn[];
  toggleKey: (key: string) => void;
};

const ChartGridItemLegendTable = ({
  colorMap,
  deselectedKeysMap,
  keys,
  legendRows,
  legendTableColumns,
  toggleKey,
}: Props) => {
  const toggleKeyHandler = ({ row }: { row: LegendRow }) => {
    const { key } = row;
    toggleKey(key);
  };
  const columnKeys = legendTableColumns || [
    ChartLegendTableColumn.key,
    ChartLegendTableColumn.avg,
    ChartLegendTableColumn.min,
    ChartLegendTableColumn.max,
    ChartLegendTableColumn.sum,
  ];
  const columnMap = useMemo(
    () => getColumnsMap(colorMap, deselectedKeysMap),
    [colorMap, deselectedKeysMap.state],
  );

  const columns = columnKeys.map((columnKey) => columnMap[columnKey]);
  const valueColumns = columns.filter((column) => column.key !== 'key');

  const largestValueLength = legendRows.reduce(
    (max, row) =>
      Math.max(
        valueColumns.reduce(
          (rowMax, column) =>
            Math.max(
              (Number(row[column.key as keyof LegendRow]) || 0).toString()
                .length,
              rowMax,
            ),
          0,
        ),
        max,
      ),
    0,
  );

  const tableCellWidth = Math.max(largestValueLength * 10, 40);

  if (!legendRows.length) {
    return null;
  }

  return (
    <div
      className="chart-grid__item__legend__table"
      style={{ '--table-cell-width': `${tableCellWidth}px` } as CSSProperties}
    >
      <Table
        columns={columns}
        onRowClick={toggleKeyHandler}
        isSortingEnabled
        rows={legendRows}
      />
    </div>
  );
};

export default ChartGridItemLegendTable;
