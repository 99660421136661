import { QueryDataProps } from 'types';
import { Series } from 'uplot';
import { getNonRedColorForOneSeries } from 'utils/colors';

const mergeTwoTimeSeries = ({
  timeSeries1,
  timeSeries2,
}: {
  timeSeries1: QueryDataProps['k']['range'];
  timeSeries2: QueryDataProps['k']['range'];
}): QueryDataProps['k']['range'] => {
  const mergedData = [];
  if (!timeSeries1 || !timeSeries2) return;

  const timeSeries1Timestamps = timeSeries1.data[0];
  const timeSeries2Timestamps = timeSeries2.data[0];
  if (!timeSeries1Timestamps || !timeSeries2Timestamps) return;

  const combinedTimestamps = [
    ...timeSeries1Timestamps,
    ...timeSeries2Timestamps,
  ];
  mergedData.push(combinedTimestamps);
  const labelDataBitmap: {
    [key: string]: { timeSeries1: number; timeSeries2: number };
  } = {};
  timeSeries1.series.forEach((serie, idx) => {
    if (labelDataBitmap[serie.label] === undefined) {
      labelDataBitmap[serie.label] = {
        timeSeries1: idx,
        timeSeries2: undefined,
      };
    } else {
      labelDataBitmap[serie.label].timeSeries1 = idx;
    }
  });
  timeSeries2.series.forEach((serie, idx) => {
    if (labelDataBitmap[serie.label] === undefined) {
      labelDataBitmap[serie.label] = {
        timeSeries1: undefined,
        timeSeries2: idx,
      };
    } else {
      labelDataBitmap[serie.label].timeSeries2 = idx;
    }
  });

  const newSeries: Series[] = [];
  const labelKeys = Object.keys(labelDataBitmap);
  labelKeys.forEach((label, idx) => {
    const labelBitmap = labelDataBitmap[label];
    // if one of the time series is undefined, skip the label
    if (
      labelBitmap.timeSeries1 === undefined ||
      labelBitmap.timeSeries2 === undefined
    ) {
      return;
    }

    const timeSeries1Data = timeSeries1.data[labelBitmap.timeSeries1 + 1];
    const timeSeries2Data = timeSeries2.data[labelBitmap.timeSeries2 + 1];
    mergedData.push([...timeSeries1Data, ...timeSeries2Data]);
    const serie = timeSeries1.series[labelBitmap.timeSeries1];
    newSeries.push({
      ...serie,
      stroke: '',
      points: { show: false, fill: 'none' },
      _stroke: getNonRedColorForOneSeries({}, idx),
    });
  });

  return {
    data: newSeries.length > 0 ? mergedData : [],
    series: newSeries,
    minValue: Math.min(timeSeries1.minValue, timeSeries2.minValue),
    maxValue: Math.max(timeSeries1.maxValue, timeSeries2.maxValue),
  };
};

export default mergeTwoTimeSeries;
