import { useSearchFormulas, useRequest, useSearches } from 'hooks';

import React, { useMemo } from 'react';

import { useRumState } from '../hooks';
import { RumQuerySearch } from '../RumAnalytics';
import { RumTab } from '../types';
import { getRange } from 'utils';
import isRangeFacet from '../utils/isRangeFacet';
import { sortingEnabledColumns } from '../utils';

type Props = {
  placeholder: string;
  searches: ReturnType<typeof useSearches>['searches'];
  rumLabelNamesRequest: ReturnType<typeof useRequest>;
  rumTab: RumTab;
  rumState: ReturnType<typeof useRumState>;
  searchesFormulas: ReturnType<typeof useSearchFormulas>;
};

const getOptions = (rumLabelNamesRequest: ReturnType<typeof useRequest>) => {
  if (!rumLabelNamesRequest.result) {
    return {
      groupByOptions: [] as { label: string; value: string }[],
      measureOptions: [] as { label: string; value: string }[],
    };
  }

  const labelOptions = [...((rumLabelNamesRequest.result as string[]) || [])]
    .sort()
    .map((label) => ({
      label: label,
      value: label,
    }));

  const groupByOptions = [
    { label: 'Everything', value: '*' },
    ...labelOptions.filter(
      (item) =>
        !isRangeFacet(item.value) &&
        sortingEnabledColumns[item.value as keyof typeof sortingEnabledColumns],
    ),
  ];
  const measureOptions = [
    { label: 'All ', value: null },
    ...labelOptions.filter(
      (item) =>
        sortingEnabledColumns[item.value as keyof typeof sortingEnabledColumns],
    ),
  ];

  return {
    groupByOptions,
    measureOptions,
  };
};

const RumSearchAnalytics = ({
  placeholder,
  rumLabelNamesRequest,
  searches,
  rumTab,
  rumState,
  searchesFormulas,
}: Props) => {
  const labelNames: string[] = useMemo(
    () => ((rumLabelNamesRequest.result || []) as string[]).sort(),
    [rumLabelNamesRequest.result],
  );

  const { groupByOptions, measureOptions } = useMemo(
    () => getOptions(rumLabelNamesRequest),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rumLabelNamesRequest.result],
  );

  return (
    <>
      {searches.map((search, i) => (
        <RumQuerySearch
          getRange={getRange}
          groupByOptions={groupByOptions}
          index={i}
          key={i}
          labelNames={labelNames}
          measureOptions={measureOptions}
          placeholder={placeholder}
          search={search}
          searches={searches}
          rumTab={rumTab}
          rumState={rumState}
          searchesFormulas={searchesFormulas}
        />
      ))}
    </>
  );
};

export default RumSearchAnalytics;
