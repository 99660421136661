import classnames from 'classnames';
import { Input, useModalsContext, useToaster } from 'components';
import React, { useState } from 'react';
import { editWorkbook } from 'requests';

type Props = {
  getWorkbooksRequest: () => void;
  workbook: any;
};

const LogsSavedViewsRenameModal = ({
  getWorkbooksRequest,
  workbook,
}: Props) => {
  const modals = useModalsContext();
  const toastmaster = useToaster();
  const [name, setName] = useState(workbook.name);

  const submit = () => {
    const onSuccess = () => {
      getWorkbooksRequest.call();
      modals.pop();

      toastmaster.addToast({
        status: 'success',
        text: `Successfully renamed ${name}`,
      });
    };

    editWorkbook({ ...workbook, name }).then(onSuccess, () => {});
  };

  return (
    <div className="modal modal--small">
      <div className="modal__header">
        <div className="modal__header__text">{`Rename ${workbook.name}`}</div>
      </div>
      <div className="modal__body" style={{ marginTop: '20px' }}>
        <Input onChange={setName} type="text" value={name} />
      </div>
      <div className="modal__footer">
        <button
          className="modal__footer__button button"
          onClick={modals.pop}
          type="button"
        >
          Cancel
        </button>
        <button
          className={classnames({
            modal__footer__button: true,
            button: true,
            'button--primary': true,
            'button--disabled': !name.trim(),
          })}
          onClick={submit}
          type="button"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default LogsSavedViewsRenameModal;
