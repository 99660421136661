import { fetchJson } from 'requests';
import { DateSelection } from 'types/DateSelection';

import { HydrationJobProps } from '../types';

const getJobsList = async (
  date: DateSelection,
): Promise<HydrationJobProps[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const result = await fetchJson('/hydration/query/list-jobs', {
    method: 'POST',
    body: JSON.stringify({
      startTsMs: startTimeUnix * 1000,
      endTsMs: endTimeUnix * 1000,
    }),
  });

  return (result as unknown as HydrationJobProps[]) || [];
};

export default getJobsList;
