import classNames from 'classnames';
import { useToaster } from 'components/Toasts';
import React, { ReactElement, useMemo, useRef, useState } from 'react';
import ReactGridLayout, { Responsive, WidthProvider } from 'react-grid-layout';
import {
  DashboardPanelProps,
  DashboardPanelType,
  DashboardStreamType,
} from 'types';

import { GRID_CELL_HEIGHT_BREAKPOINTS, GRID_CELL_VMARGIN } from './constants';
import { DashboardEdit } from './DashboardEdit';
import { useDashboardGridlineContext } from './DashboardGridline';
import DashboardPanelWrapper from './DashboardPanelWrapper';
import { useDashboardState, useDashboardTemplateState } from './hooks';
import { DashboardPanelRow } from './Panels';
import { getTemplateValueWithAll } from './utils';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const DashboardPanelRender = ({
  baseHeight,
  baseWidth,
  dashboardState,
  dashboardTemplateState,
  disableEditPanel,
  disableDeletePanel,
  nestedIndex,
  panels,
}: {
  baseHeight: number;
  baseWidth: number;
  dashboardState: ReturnType<typeof useDashboardState>;
  dashboardTemplateState: ReturnType<typeof useDashboardTemplateState>;
  disableEditPanel?: boolean;
  disableDeletePanel?: boolean;
  nestedIndex?: string;
  panels: DashboardPanelProps[];
}): ReactElement => {
  const dashboardGridlineState = useDashboardGridlineContext();
  const {
    addPlaceholder,
    date,
    dragItemSize,
    isRightSidebarOpenToggle,
    panelSetupModal,
    updateLayoutChanges,
    updateEditedPanel,
    userActionRef,
  } = dashboardState;
  const { addToast } = useToaster();
  const [breakPoint, setBreakPoint] = useState('lg');

  const onDrop = (
    layout: ReactGridLayout.Layout[],
    item: ReactGridLayout.Layout,
    e: React.DragEvent<HTMLDivElement>,
  ) => {
    const widgetType = e.dataTransfer.getData(
      'widgetType',
    ) as DashboardPanelType;

    if (widgetType === 'group' && nestedIndex) {
      addToast({ text: 'You cannot nest groups', status: 'error' });
      return;
    }

    const templateValuesWithAll = getTemplateValueWithAll({
      templateValues: dashboardTemplateState.templateValues,
      templateOptions: dashboardTemplateState.templateOptions,
      templating: dashboardTemplateState.templating,
    });

    panelSetupModal.push(
      <DashboardEdit
        close={() => {
          panelSetupModal.pop();
          addPlaceholder();
          isRightSidebarOpenToggle.off();
        }}
        date={date}
        panelIndex={null}
        itemLayout={item}
        panelType={widgetType}
        panel={null}
        streamType={DashboardStreamType.METRIC}
        templateValues={templateValuesWithAll}
        updateEditedPanel={updateEditedPanel}
      />,
    );
    dashboardGridlineState.setIsDragging(false);
  };

  const layouts = useMemo(() => {
    return panels.map((panel) => {
      return { ...panel.gridPos, i: `${panel.id}-${Date.now()}` };
    });
  }, [panels]);

  let isCollapsed = false;
  return (
    <ResponsiveReactGridLayout
      className={classNames({
        'dashboard__react-grid-layout': true,
        'dashboard__react-grid-layout--nested': nestedIndex,
      })}
      cols={{ lg: 24, md: 24, sm: 24, xs: 24, xxs: 0 }}
      droppingItem={{
        i: 'placeholder',
        w: dragItemSize.w,
        h: dragItemSize.h,
      }}
      draggableHandle=".dashboard-panel__draggable-handle"
      isDroppable={true}
      isDraggable={true}
      layouts={{ lg: layouts }}
      margin={[GRID_CELL_VMARGIN, GRID_CELL_VMARGIN]}
      containerPadding={[12, 12]}
      onDrop={onDrop}
      onDragStart={(a, b, c, d, e) => {
        e.stopPropagation();
        dashboardGridlineState.setIsDragging(true);
        userActionRef.current.isDragging = true;
      }}
      onDragStop={() => {
        dashboardGridlineState.setIsDragging(false);
      }}
      onLayoutChange={(layout) => {
        if (!userActionRef.current.isDragging) {
          userActionRef.current.isDragging = false;
          return;
        }
        updateLayoutChanges(layout, nestedIndex);
      }}
      onBreakpointChange={(newBreakpoint: string) => {
        setBreakPoint(newBreakpoint || 'lg');
      }}
      onResizeStart={() => {
        userActionRef.current.isDragging = true;
      }}
      rowHeight={GRID_CELL_HEIGHT_BREAKPOINTS[breakPoint]}
      width={baseWidth - 48}
    >
      {panels.map((panel, idx) => {
        const prop = {
          baseHeight,
          baseWidth,
          dashboardState,
          dashboardTemplateState,
          disableEditPanel,
          disableDeletePanel,
          panel,
          panelIndex: idx,
          nestedIndex,
        };

        if (panel.type === DashboardPanelType.ROW) {
          isCollapsed = panel.collapsed;
          return (
            <div
              className="dashboard__react-grid-layout__item"
              key={`${idx}`}
              data-grid={panel.gridPos}
            >
              <DashboardPanelRow {...prop} />
            </div>
          );
        }

        if (isCollapsed) {
          return null;
        }

        if (panel.type === DashboardPanelType.GROUP && !panel.title) {
          return (
            <div
              className="dashboard__react-grid-layout__item"
              key={`${idx}`}
              data-grid={panel.gridPos}
              draggable={false}
              data-testid={`dashboard-${idx}`}
            >
              <DashboardPanelRender
                baseHeight={baseHeight}
                baseWidth={baseWidth}
                dashboardState={dashboardState}
                dashboardTemplateState={dashboardTemplateState}
                disableDeletePanel={disableDeletePanel}
                nestedIndex={`${idx}`}
                panels={panel.panels}
              />
            </div>
          );
        }

        return (
          <div
            className="dashboard__react-grid-layout__item"
            key={`${idx}`}
            data-grid={panel.gridPos}
            draggable={false}
            data-testid={`dashboard-${idx}`}
          >
            <DashboardPanelWrapper {...prop} />
          </div>
        );
      })}
    </ResponsiveReactGridLayout>
  );
};

export default DashboardPanelRender;
