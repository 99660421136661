import { AutocompleteV2, Button, InputWithValidatorV3 } from 'components';
import React, { ReactElement } from 'react';
import { MdDelete as DeleteIcon } from 'react-icons/md';

import { useAlertsSilenceEditState } from './hooks';
import {
  getIsEqualAndIsRegex,
  getSilenceMatcherOperatorOptions,
  getSilenceOperator,
} from './utils';

const AlertsSilencesEditMatcher = ({
  alertsSilenceEditState,
}: {
  alertsSilenceEditState: ReturnType<typeof useAlertsSilenceEditState>;
}): ReactElement => {
  const { loadSilencedInstances, setSilenceDetails, silenceDetails } =
    alertsSilenceEditState;
  const operatorOptions = getSilenceMatcherOperatorOptions();

  const handleOperatorChange = (idx: number, key: string, value: string) => {
    setSilenceDetails((prev) => {
      const newMatchers = [...prev.matchers];
      if (key === 'operator') {
        const { isEqual, isRegex } = getIsEqualAndIsRegex(value);
        newMatchers[idx].isEqual = isEqual;
        newMatchers[idx].isRegex = isRegex;
      } else {
        newMatchers[idx][key] = value;
      }

      const newSilence = { ...prev, matchers: newMatchers };
      if (key === 'operator') loadSilencedInstances(newSilence);
      return newSilence;
    });
  };

  const handleAddMatcher = () => {
    setSilenceDetails((prev) => {
      const newMatchers = [...prev.matchers];
      const { isEqual, isRegex } = getIsEqualAndIsRegex('=');
      newMatchers.push({ name: '', value: '', isEqual, isRegex });
      return { ...prev, matchers: newMatchers };
    });
  };

  const handleDeleteMatcher = (idx: number) => {
    setSilenceDetails((prev) => {
      const newMatchers = [...prev.matchers];
      newMatchers.splice(idx, 1);
      return { ...prev, matchers: newMatchers };
    });
  };

  return (
    <div className="admin__row-muted rounded-sm pb-2 pl-2">
      {silenceDetails?.matchers.map((matcher, idx) => (
        <div key={idx} className="flex items-center gap-1 pt-2">
          <div>
            <label>Label</label>
            <div>
              <InputWithValidatorV3
                size="6"
                type="text"
                variant="default"
                onBlur={() => loadSilencedInstances(silenceDetails)}
                onChange={(e) => handleOperatorChange(idx, 'name', e)}
                placeholder="Label"
                value={matcher.name}
              />
            </div>
          </div>
          <div>
            <label>Operator</label>
            <div>
              <AutocompleteV2
                className="w-[80px]"
                options={operatorOptions}
                onChange={(e: string) =>
                  handleOperatorChange(idx, 'operator', e)
                }
                value={getSilenceOperator(matcher.isEqual, matcher.isRegex)}
              />
            </div>
          </div>
          <div>
            <label>Value</label>
            <div>
              <InputWithValidatorV3
                size="6"
                type="text"
                variant="default"
                onBlur={() => loadSilencedInstances(silenceDetails)}
                onChange={(e) => handleOperatorChange(idx, 'value', e)}
                placeholder="Value"
                value={matcher.value}
              />
            </div>
          </div>
          <div className="pt-2.5">
            <Button
              variant="ghost-destructive"
              size="sm"
              className="mt-2 px-2"
              onClick={() => handleDeleteMatcher(idx)}
            >
              <DeleteIcon size={16} />
            </Button>
          </div>
        </div>
      ))}
      <Button
        variant="outline"
        size="sm"
        className="mt-2"
        onClick={handleAddMatcher}
      >
        Add matcher
      </Button>
    </div>
  );
};

export default AlertsSilencesEditMatcher;
