import { DashboardProps } from 'types/Dashboard';
import fetchGrafanaApi from './fetchGrafanaApi';

const getGrafanaDashboardByUid = (
  uid: string,
): Promise<{ dashboard: DashboardProps; meta: any }> => {
  return fetchGrafanaApi(`grafana/api/dashboards/uid/${uid}`);
};

export default getGrafanaDashboardByUid;
