import { AutocompleteV2, Button, useToaster } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { UserRole } from 'types';

import { updateRoleById } from './requests';
import useUserManagementState from './useUserManagementState';
import { PermissionRolesOptions } from '../AddPermission/utils';

const UserManagementEditOrg = ({
  userManagementState,
}: {
  userManagementState: ReturnType<typeof useUserManagementState>;
}): ReactElement => {
  const { addToast } = useToaster();
  const { userByIdRequest } = userManagementState;
  const user = userByIdRequest.result;
  const updateRoleByIdRequest = useRequest(updateRoleById);
  const [isChangeRoleActive, setIsChangeRoleActive] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string>(null);

  const onSaveRole = () => {
    updateRoleByIdRequest
      .call({
        userId: user.id,
        user: { ...user, role: selectedRole },
      })
      .then((res) => {
        if (res) {
          addToast({ text: 'Role updated successfully', status: 'success' });
          userByIdRequest.call({ userId: user.id });
        }
        setIsChangeRoleActive(false);
      });
  };

  useEffect(() => {
    if (user) {
      setSelectedRole(user.role);
    }
  }, [user]);

  return (
    <div className="pb-4">
      <div className="alerts__contacts__list__header">
        <h2 className="text-lg font-semibold">Role</h2>
      </div>
      <div>
        <table className="w-full table-auto">
          {updateRoleByIdRequest.isLoading ? (
            <tbody>
              <tr className="admin__row-muted">
                <td className="flex h-8 items-center justify-center">
                  <div className="spinner"></div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              <tr className="admin__row-muted">
                <td className="min-w-[300px] pl-4 text-left font-medium">
                  {isChangeRoleActive ? (
                    <AutocompleteV2
                      className="max-w-[200px]"
                      options={PermissionRolesOptions}
                      value={selectedRole}
                      onChange={(value) => setSelectedRole(value as UserRole)}
                    />
                  ) : (
                    user?.role
                  )}
                </td>
                <td className="text-right font-medium">
                  {isChangeRoleActive ? (
                    <span className="flex justify-end pr-2">
                      <Button
                        className="rounded-none rounded-l-sm"
                        variant="default"
                        size="sm"
                        onClick={onSaveRole}
                      >
                        Save
                      </Button>
                      <Button
                        className="rounded-none rounded-r-sm"
                        variant="outline"
                        size="sm"
                        onClick={() => setIsChangeRoleActive(false)}
                      >
                        Cancel
                      </Button>
                    </span>
                  ) : (
                    <Button
                      variant="link"
                      size="sm"
                      onClick={() => {
                        setIsChangeRoleActive(true);
                        setSelectedRole(user.role as UserRole);
                      }}
                    >
                      Change Role
                    </Button>
                  )}
                </td>
              </tr>
            </tbody>
          )}
        </table>
        <div className="flex gap-2 pt-4"></div>
      </div>
    </div>
  );
};

export default UserManagementEditOrg;
