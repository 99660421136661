import React, { ReactElement, useMemo } from 'react';
import ServiceTraces from 'screens/Service/ServiceTraces';
import { DateSelection } from 'types';
import useAlertsServiceCorrelationState from './useAlertsServiceCorrelationState';

const AlertsServiceCorrelationsTraces = ({
  alertsServiceCorrelationState,
  date,
  serviceHash,
}: {
  alertsServiceCorrelationState: ReturnType<
    typeof useAlertsServiceCorrelationState
  >;
  date: DateSelection;
  serviceHash: string;
}): ReactElement => {
  const { activeCorrelation } = alertsServiceCorrelationState;

  const selectedFacetValuesByNameState = useMemo(() => {
    const result: Record<string, Record<string, number>> = {
      service_hash: {},
    };
    const activeCorrelationKeys = Object.keys(activeCorrelation);
    activeCorrelationKeys.forEach((key) => {
      result.service_hash[key] = 1;
    });
    return result;
  }, [activeCorrelation]);

  return (
    <div>
      <ServiceTraces
        colorsByServiceName={{}}
        date={date}
        selectedFacetValuesByNameState={{
          state: selectedFacetValuesByNameState,
        }}
        serviceHash={serviceHash}
        setSidebar={() => {}}
      />
    </div>
  );
};

export default AlertsServiceCorrelationsTraces;
