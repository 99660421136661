import { AutocompleteV2 } from 'components';
import React, { ReactElement } from 'react';
import { AnomalyAlgorithmType } from 'types/MetricsQueryBuilder';

import {
  AlertsCreateConditionInfo,
  useAlertsCreateCondition,
} from '../AlertsCreateCondition';
import { anomalyAlertPopupMessage } from '../constants';
import {
  basicBoundType,
  basicBandType,
  basicWindowType,
  anomalyRobustSeasonalityOptions,
  anomalyAgileRobustSeasonalityOptions,
} from './utils';

const AlertsCreateAnomalyConditionBasic = ({
  conditionState,
}: {
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
}): ReactElement => {
  const { anomalyCondition, setAnomalyCondition } = conditionState;
  const { anomalyAlgorithm } = anomalyCondition;

  return (
    <div>
      <div className="alerts__create__section__item">
        {anomalyAlgorithm !== AnomalyAlgorithmType.AGILE &&
          anomalyAlgorithm !== AnomalyAlgorithmType.AGILE_ROBUST && (
            <>
              <div className="alerts__create__conditions__item__text">
                Window:
              </div>
              <div className="alert__create__anomaly__detaction_desciption">
                <AlertsCreateConditionInfo
                  description={
                    anomalyAlertPopupMessage.BASIC_WINDOW_DESCRIPTION
                  }
                />
              </div>
              <div className="alerts__create__conditions__item__input">
                <AutocompleteV2
                  onChange={(val: string) =>
                    setAnomalyCondition({ ...anomalyCondition, window: val })
                  }
                  options={basicWindowType}
                  value={anomalyCondition.window}
                />
              </div>
            </>
          )}
        {anomalyCondition.anomalyAlgorithm ===
          AnomalyAlgorithmType.AGILE_ROBUST && (
          <>
            <div className="alerts__create__conditions__item__text">
              Seasonality:
            </div>
            <div className="alert__create__anomaly__detaction_desciption">
              <AlertsCreateConditionInfo
                description={
                  anomalyAlertPopupMessage.ANOMALY_ROBUST_SEASONALITY
                }
              />
            </div>
            <div className="alerts__create__conditions__item__input">
              <AutocompleteV2
                onChange={(val: string) =>
                  setAnomalyCondition({
                    ...anomalyCondition,
                    agileRobustSeasonality: val,
                  })
                }
                options={anomalyAgileRobustSeasonalityOptions}
                value={anomalyCondition.agileRobustSeasonality}
              />
            </div>
          </>
        )}
        <div className="alerts__create__conditions__item__text">Bound:</div>
        <div className="alert__create__anomaly__detaction_desciption">
          <AlertsCreateConditionInfo
            description={anomalyAlertPopupMessage.BASIC_BOUNDS_DESCRIPTION}
          />
        </div>
        <div className="alerts__create__conditions__item__input">
          <AutocompleteV2
            onChange={(val: string) =>
              setAnomalyCondition({ ...anomalyCondition, bound: val })
            }
            options={basicBoundType}
            value={anomalyCondition.bound}
          />
        </div>
        {anomalyCondition.anomalyAlgorithm === AnomalyAlgorithmType.ROBUST && (
          <>
            <div className="alerts__create__conditions__item__text">
              Seasonality:
            </div>
            <div className="alert__create__anomaly__detaction_desciption">
              <AlertsCreateConditionInfo
                description={
                  anomalyAlertPopupMessage.ANOMALY_ROBUST_SEASONALITY
                }
              />
            </div>
            <div className="alerts__create__conditions__item__input">
              <AutocompleteV2
                onChange={(val: string) =>
                  setAnomalyCondition({ ...anomalyCondition, seasonality: val })
                }
                options={anomalyRobustSeasonalityOptions}
                value={anomalyCondition.seasonality}
              />
            </div>
          </>
        )}

        <div className="alerts__create__conditions__item__text">Band:</div>
        <div className="alert__create__anomaly__detaction_desciption">
          <AlertsCreateConditionInfo
            description={anomalyAlertPopupMessage.BASIC_BAND_DESCRIPTION}
          />
        </div>
        <div className="alerts__create__conditions__item__input">
          <AutocompleteV2
            onChange={(val: string) =>
              setAnomalyCondition({ ...anomalyCondition, band: val })
            }
            options={basicBandType}
            value={anomalyCondition.band}
          />
        </div>
      </div>
    </div>
  );
};

export default AlertsCreateAnomalyConditionBasic;
