import React, { useMemo } from 'react';

import { kubeEntities } from 'requests';
import { podCPUUsage, podMemoryUsage } from '../utils';
import type { Pod } from 'screens/Kubernetes/types';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellPercentageBar,
  KubeCellStatus,
  KubeCellTimestamp,
  KubeTableCell,
} from './Components';
import useAsync from '../hooks/useAsync';
import KubernetesTable from './KubernetesTable';
import { useEntitiesCount } from './useEntitiesData';
import { SelectedFacetValuesByName } from 'types';

const getKey = ({ pod }: { pod: Pod }): string => {
  if (pod) {
    const { tags } = pod;
    const { name, namespace } = pod.metadata;
    const tag = tags.find((tag) => tag.includes('kube_cluster_name'));
    const clusterName = tag ? tag.split(':')[1] : '';

    return `${clusterName}:${namespace}:${name}`;
  }

  return '';
};

export function useKubernetesTableDataForPod({
  page,
  limit,
  filters,
}: {
  page: number;
  limit: number;
  filters: Array<SelectedFacetValuesByName>;
}) {
  const { date, kubesSort, entitiesType, entitiesLastLoaded } =
    useKubernetesController();

  return useAsync(
    async (signal: AbortSignal) => {
      const entities = (await kubeEntities(
        {
          entityType: entitiesType,
          sortOrder: kubesSort?.order,
          sortBy: {
            key: kubesSort?.key,
            type: kubesSort?.type,
          },
          offset: page * limit,
          pageLimit: limit,
          filters,
        },
        { signal },
      )) as Array<{ pod: Pod }>;
      const name: Array<string> = [];
      const cluster: Array<string> = [];
      const namespace: Array<string> = [];

      entities.forEach(({ pod }) => {
        const clusterTagName = 'kube_cluster_name';
        const clusterName = pod.tags.find((tag) =>
          tag.startsWith(clusterTagName),
        );

        name.push(pod.metadata.name);
        cluster.push(clusterName.substring(clusterTagName.length + 1));
        namespace.push(pod.metadata.namespace);
      });
      const [cpu, memory] = await Promise.all([
        podCPUUsage(
          [],
          {
            pod_name: name,
            kube_cluster_name: cluster,
            kube_namespace: namespace,
          },
          date,
        )({ signal }),
        podMemoryUsage(
          [],
          {
            pod_name: name,
            kube_cluster_name: cluster,
            kube_namespace: namespace,
          },
          date,
        )({ signal }),
      ]);

      return entities.map((podData) => {
        const key = getKey(podData);
        const cpuPercentage = Number(cpu[key] || 0);
        const memoryPercentage = Number(memory[key] || 0);

        return {
          ...podData,
          cpuPercentage,
          memoryPercentage,
        };
      });
    },
    [date, page, limit, filters, kubesSort, entitiesLastLoaded],
  );
}

export default function KubernetesTableForPod() {
  const { date, entitiesType, kubesSort, facets, page, limit } =
    useKubernetesController();

  const filters = useMemo(() => [facets], [facets]);
  const [count] = useEntitiesCount({ entitiesType, facets });
  const [rows] = useKubernetesTableDataForPod({
    entitiesType,
    date,
    page,
    limit,
    filters,
    kubesSort,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'pod_name',
          label: 'Pod',
          renderCell(prop) {
            const podName = prop.row.pod.metadata.name;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name"
                tooltipText={podName}
              >
                {podName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'pod_status',
          label: 'Status',
          renderCell(prop) {
            return <KubeCellStatus {...prop} status={prop?.row?.pod?.status} />;
          },
        },
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell(prop) {
            return <KubeCellCluster {...prop} />;
          },
        },
        {
          key: 'metadata.namespace',
          label: 'Namespace',
          renderCell(prop) {
            return <KubeCellNamespace {...prop} />;
          },
        },
        {
          key: 'metadata.creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            return (
              <KubeCellTimestamp
                {...prop}
                timestamp={prop.row.pod.metadata.creationTimestamp}
              />
            );
          },
        },
        {
          key: 'headerReady',
          label: 'Ready',
          renderCell(prop) {
            const containerStatuses = prop.row?.pod?.containerStatuses; // NOTE apparently changing this can break the storybook compiler
            if (!containerStatuses) return <>-</>;
            const denominator = containerStatuses.length;
            const numerator = containerStatuses.filter(
              (status: { ready?: boolean }) => status?.ready,
            ).length;

            return (
              <KubeTableCell {...prop} align="right">
                {`${numerator}/${denominator}`}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'restartCount',
          label: 'Restarts',
          renderCell(prop) {
            const containerStatuses = prop.row?.pod?.containerStatuses; // NOTE apparently changing this can break the storybook compiler
            if (!containerStatuses) return 0;

            return (
              <KubeTableCell {...prop} align="right">
                {containerStatuses.reduce(
                  (acc: number, elem: { restartCount?: number }): number =>
                    acc + (elem?.restartCount || 0),
                  0,
                )}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerCpuUsage',
          label: '% CPU Usage',
          renderCell(prop) {
            const percentage = prop.row.cpuPercentage * 100;
            return (
              <KubeTableCell {...prop} align="right">
                <KubeCellPercentageBar percent={percentage} />
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerMemUsage',
          label: '% Mem Usage',
          renderCell(prop) {
            const percent = prop.row.memoryPercentage * 100;
            return (
              <KubeTableCell {...prop} align="right">
                <KubeCellPercentageBar percent={percent} />
              </KubeTableCell>
            );
          },
        },
      ]}
    />
  );
}
