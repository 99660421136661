import { useEffect } from 'react';
import { ChipRef, ElementToFocusInfo, ElementType } from './types';

const useFocusElementToFocusEffect = ({
  elementToFocusInfo,
  setElementToFocusInfo,
  initializerRef,
  chipRefs,
}: {
  elementToFocusInfo: ElementToFocusInfo;
  setElementToFocusInfo: React.Dispatch<
    React.SetStateAction<ElementToFocusInfo>
  >;
  initializerRef: React.MutableRefObject<HTMLInputElement>;
  chipRefs: React.MutableRefObject<Array<ChipRef>>;
}): void => {
  useEffect(() => {
    if (!elementToFocusInfo) {
      return;
    }
    if (elementToFocusInfo.type === ElementType.INITIALIZER) {
      initializerRef.current?.focus();
      setElementToFocusInfo(null);
      return;
    }
    switch (elementToFocusInfo.type) {
      case ElementType.PROPERTY: {
        const chipRef = chipRefs.current[elementToFocusInfo.index];
        if (!chipRef) {
          return;
        }
        chipRef.focusProperty();
        setElementToFocusInfo(null);
        break;
      }
      case ElementType.OPERATOR: {
        const chipRef = chipRefs.current[elementToFocusInfo.index];
        if (!chipRef) {
          return;
        }
        chipRef.focusOperator();
        setElementToFocusInfo(null);
        break;
      }
      case ElementType.VALUE: {
        const chipRef = chipRefs.current[elementToFocusInfo.index];
        if (!chipRef) {
          return;
        }
        chipRef.focusValue(
          elementToFocusInfo.valueIndex,
          elementToFocusInfo.selectionStart,
        );
        setElementToFocusInfo(null);
        break;
      }
      default:
        return;
    }
  }, [chipRefs, elementToFocusInfo, initializerRef, setElementToFocusInfo]);
};

export default useFocusElementToFocusEffect;
