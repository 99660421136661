import React, { ReactElement, useRef, useState } from 'react';
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography,
} from 'react-simple-maps';
import { Tooltip } from 'react-tooltip';
import { geoMapColor } from 'utils';
import GeoCountries from './GeoCountries';

const Geomap = ({
  data,
  width,
  height,
}: {
  data: Record<string, string | number>;
  width?: number;
  height?: number;
}): ReactElement => {
  const [toolTipContent, setToolTipContent] = useState(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { clientHeight, clientWidth } = containerRef.current || {};

  const heightToUse = Math.min(height || clientHeight || 400, 400);
  const widthToUse = Math.min(width || clientWidth || 800, 800);

  return (
    <>
      <div id="map" className="geo-map" ref={containerRef}>
        <ComposableMap
          projectionConfig={{ center: [0, 10] }}
          width={widthToUse}
          height={heightToUse}
        >
          <ZoomableGroup>
            <Geographies geography={GeoCountries}>
              {({ geographies }: { geographies: any[] }) =>
                geographies.map((geo) => {
                  const isHighlighted = data[geo.properties.name];
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={() => {
                        setToolTipContent({
                          name: geo.properties.name,
                          value: data[geo.properties.name] || 'N/A',
                        });
                      }}
                      onMouseLeave={() => {
                        setToolTipContent(null);
                      }}
                      style={{
                        default: {
                          fill: isHighlighted
                            ? geoMapColor.highlightedFill
                            : geoMapColor.fill,
                          outline: 'none',
                        },
                        hover: {
                          fill: geoMapColor.hover,
                          outline: geoMapColor.hover,
                        },
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
      </div>
      <Tooltip anchorId="map" float className="geo-map-tooltip">
        {Boolean(toolTipContent) && (
          <>
            <div className="geo-map-tooltip__name">{toolTipContent.name}</div>
            <div className="geo-map-tooltip__value">{toolTipContent.value}</div>
          </>
        )}
      </Tooltip>
    </>
  );
};

export default Geomap;
