import { DashboardPanelType } from 'types';
import { tourStep } from './types';

const TourSteps: tourStep[] = [
  {
    title: 'Application Performance Monitoring (APM)',
    content:
      'Application Performance Monitoring (APM) gives deep visibility into your applications with out-of-the-box performance dashboards and many tools to troubleshoot performance issues in microservices based distributed applications/systems.',
    previousUrl: '',
    nextUrl: '',
    posX: 10,
    posY: 8,
  },
  {
    title: 'Services - Filters Sidebar',
    content:
      'On the left hand side of the screen, filters allow to get to specific service(s) based on a variety of factors. Hovering over a filter gives more detailed information about it.',
    previousUrl: '',
    nextUrl: '',
    posX: 10,
    posY: 40,
  },
  {
    title: 'Table View',
    content:
      'Table view shows the list of services, the RED metrics and the APDEX scores for each service when applicable. Use "Options" to configure the view. Update time range using the time picker as required.',
    previousUrl: '',
    nextUrl: '#/apm/services/service-map?pT=true&new=false',
    posX: 315,
    posY: 62,
  },
  {
    title: 'Service Map View',
    content:
      'Service Map shows the topological view of service interactions with other services over various protocols or span types. Hovering over each service node or edge shows the analytics (RED metrics) captured for the service or the edge (communication between the two services).',
    previousUrl: '#/apm/services?pT=true&new=false',
    nextUrl: '#/apm/traces/list?pT=true&new=false',
    posX: 523,
    posY: 62,
  },
  {
    title: 'Traces - Filters Sidebar',
    content:
      'Use filters to select the service(s), span type(s), kubernetes resource(s) and many available filters to view just the relevant traces.',
    previousUrl: '#/apm/services/service-map?pT=true&new=false',
    nextUrl: '',
    posX: 10,
    posY: 40,
  },
  {
    title: 'Traces List View',
    content:
      "Trace list view shows the list of relevant traces based on the applied filters (if any). Table columns can be adjusted using the 'options'. The default view shows many high level attributes of each trace including span type, duration, status, endpoint, etc.",
    previousUrl: '',
    nextUrl: '#/apm/traces/timeseries?pT=true&new=false',
    posX: 314,
    posY: 109,
  },
  {
    title: 'Trace Analytics',
    content:
      'Use trace analytics to get more out of traces by aggregating (space/time) or filtering. Use "save" to record it as a metric or compare with historical (predefined) traces.',
    previousUrl: '#/apm/traces/list?pT=true&new=false',
    nextUrl: '#/metrics?pT=true&new=false',
    posX: 313,
    posY: 200,
  },
  {
    title: 'Metrics Explorer',
    content:
      'The Metrics Explorer is a basic interface for examining metrics from all sources (custom apps, platform, infrastructure, self-managed or cloud-hosted components, etc.) including the metrics generated from other telemetry streams.',
    previousUrl: '#/apm/traces/timeseries?pT=true&new=false',
    nextUrl: '',
    posX: 46,
    posY: 186,
  },
  {
    title: 'Filters and Aggregations',
    content:
      'Apply filters from auto-popuated contextual list of label names and values, and PromQL aggregates.',
    previousUrl: '',
    nextUrl: '',
    posX: 312,
    posY: 150,
  },
  {
    title: 'Function Generator',
    content:
      'Explore your metric data with advance (state of the art) algorithms in addition to all PromQL functions. Click  to learn more. View detailed information by howering over an item.',
    previousUrl: '',
    nextUrl: '#/metrics/summary?pT=true&new=false',
    posX: 634,
    posY: 146,
  },
  {
    title: 'Metrics Summary',
    content:
      'Metrics summary page displays a list of metrics reported from all sources to Kloudfuse platform under a specified time frame: the past hour, day, or week. Start typing a metric name to inpect or search through metric metadata, ex: name, description, metric type, tags (label names), label values, etc.',
    previousUrl: '#/metrics?pT=true&new=false',
    nextUrl: '#/logs?pT=true&new=false',
    posX: 516,
    posY: 70,
  },
  {
    title: 'Logs Explorer',
    content:
      'The Log Explorer is your home base for log based troubleshooting and exploration. Logs explorer is also a landing page from other telemetry streams or dashboards and alerts. Search, filter, group, visualize, and export logs in the Log Explorer.',
    previousUrl: '#/metrics/summary?pT=true&new=false',
    nextUrl: '',
    posX: 300,
    posY: 100,
  },
  {
    title: 'Logs - Filters Sidebar',
    content:
      'Filter on logs to narrow down, broaden, or shift your focus on a subset of logs. Kloudfuse platform auto extract the labels and facets from logs. Additionally one can configure complex parsers to extract structure out of log lines.',
    previousUrl: '',
    nextUrl: '#/logs/fingerprints?pT=true&new=false',
    posX: 10,
    posY: 40,
  },
  {
    title: 'Fingerprints',
    content:
      'A fingerprint is the unique signature of a log line (static parts). Kloudfuse platform automatically extracts fingerprints from the log lines in realtime. This gives you a birds-eye-view of what type of logs are flowing in. Explore more actions and options by clicking on a specific fingerprint.',
    previousUrl: '#/logs?pT=true&new=false',
    nextUrl: `#/logs/${DashboardPanelType.TIMESERIES}?pT=true&new=false`,
    posX: 378,
    posY: 100,
  },
  {
    title: 'Log Analytics',
    content:
      'Use the query builder to do ad-hoc analysis, slice and dice over the auto extracted log facets (just as with metric)s. Additionally, save the analysis as metrics (which can then be used for even alerting).',
    previousUrl: '#/logs/fingerprints?pT=true&new=false',
    nextUrl: '#/logs?live-tail=true&pT=true&new=false',
    posX: 500,
    posY: 100,
  },
  {
    title: 'Live Logs',
    content:
      'Here, you are able to view log reports in real time with the data actively updating.',
    previousUrl: `#/logs/${DashboardPanelType.TIMESERIES}?pT=true&new=false`,
    nextUrl: '',
    posX: window.innerWidth - 0.3 * window.innerWidth,
    posY: 50,
  },
  {
    title: 'Download logs',
    content:
      'Avoid reconstrucing complex queries or share your log queries by saving them using the "Save Query" feature. Or download the logs using the "Download" feature.',
    previousUrl: '#/logs?live-tail=true&pT=true&new=false',
    nextUrl: '#/logs/saved-queries?pT=true&new=false',
    posX: window.innerWidth - 0.3 * window.innerWidth,
    posY: 147,
  },
  {
    title: 'Manage Saved Queries',
    content:
      'Logs queries which are saved can be viewed and managed (edit/delete) using the saved queries tab under Logs.',
    previousUrl: '#/logs?live-tail=true&pT=true&new=false',
    nextUrl: '#/logs?pT=true&new=false',
    posX: 210,
    posY: 80,
  },
];

export default TourSteps;
