import * as yup from 'yup';
import { yupValidator } from 'utils';

import { AlertsCreateDetailsProps } from '../types';

const alertDetailsValidator = yup.object().shape({
  folderName: yup.string().required('Folder name is required'),
  ruleName: yup.string().required('Rule name is required'),
  description: yup.string(),
  runbookUrl: yup.string().url('Runbook URL is not valid'),
});

export const validateAlertDetails = (values: AlertsCreateDetailsProps) => {
  return yupValidator({
    schema: alertDetailsValidator,
    value: values,
    options: { abortEarly: false },
  });
};
