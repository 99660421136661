import React from 'react';
import getTypeAndValue from './getTypeAndValue';
import JsonItem from './JsonItem';
import JsonIterable from './JsonIterable';
import { JsonOptions, JsonType } from './types';

type Props = {
  data: any;
  options?: JsonOptions;
};

const Json = ({ data, options }: Props) => {
  const { type, value } = getTypeAndValue(data, '');
  switch (type) {
    case JsonType.array:
    case JsonType.object:
      return (
        <JsonIterable
          label=""
          level={0}
          path=""
          options={options}
          type={type}
          value={value}
        />
      );
    default:
      return (
        <JsonItem
          label=""
          level={0}
          options={options}
          path=""
          type={type}
          value={value}
        />
      );
  }
};

export default Json;
