import React from 'react';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import RumCumulativeLayoutShiftOverview from './RumCoreWebVitals/RumCumulativeLayoutShiftOverview';
import RumInteractionToNextPaintOverview from './RumCoreWebVitals/RumInteractionToNextPaintOverview';
import RumLargestContentfulPaintOverview from './RumCoreWebVitals/RumLargestContentfulPaintOverview';

type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
};

const RumPerformanceOverview = ({ rumPageState }: Props) => {
  return (
    <div className="rum-performance-overview-panel">
      <div className="rum__performance-subtitle">Core Web Vitals</div>
      <div className="rum-performance-overview-panel-content">
        <div className="rum-performance-overview">
          <div className="rum-performance-overview-item">
            <RumLargestContentfulPaintOverview rumPageState={rumPageState} />
          </div>
          <div className="rum-performance-overview-item">
            <RumCumulativeLayoutShiftOverview rumPageState={rumPageState} />
          </div>
          <div className="rum-performance-overview-item">
            <RumInteractionToNextPaintOverview rumPageState={rumPageState} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RumPerformanceOverview;
