import React, { ReactElement, useState } from 'react';
import MetricsTagText from './MetricsTagText';

const MAX_TAG_VALUES = 40;

type Props = {
  metricName: string;
  tagName: string;
  values: string[];
};

const MetricsSummaryTagsValues = ({
  metricName,
  tagName,
  values,
}: Props): ReactElement => {
  const [showAll, setShowAll] = useState(false);
  const valuesLength = values.length;
  const trimmerdValues =
    valuesLength > MAX_TAG_VALUES ? values.slice(0, MAX_TAG_VALUES) : values;
  const leftValues =
    valuesLength > MAX_TAG_VALUES
      ? values.slice(MAX_TAG_VALUES, valuesLength)
      : [];

  return (
    <div className="flex flex-wrap">
      {trimmerdValues.map((val) => (
        <MetricsTagText
          metricName={metricName}
          key={val}
          tag={tagName}
          tagValue={val}
        />
      ))}
      {showAll &&
        leftValues.map((val) => (
          <MetricsTagText
            metricName={metricName}
            key={val}
            tag={tagName}
            tagValue={val}
          />
        ))}
      {valuesLength > MAX_TAG_VALUES && (
        <div
          key={`${valuesLength - MAX_TAG_VALUES} more`}
          className="chip metrics-summary__tags__values__more"
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? 'Show less' : `${valuesLength - MAX_TAG_VALUES} more`}
        </div>
      )}
    </div>
  );
};

export default MetricsSummaryTagsValues;
