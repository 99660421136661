import { Loader } from 'components';
import { startCase, toNumber } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAlertsCreateAPM } from 'screens/NewAlerts/AlertsCreateAPM/hooks';
import { useAlertsCreate } from 'screens/NewAlerts/hooks';
import { useAlertsCreateCondition } from 'screens/NewAlerts/AlertsCreateCondition';
import { RuleType } from 'screens/NewAlerts/types';
import useDebouncedEffect from 'use-debounced-effect';

import { useCreateSLOState } from '../hooks';
import useSLOQueryTemplates from '../hooks/useSLOQueryTemplates';
import { getDefaultNameForSLOType, getSLOPromql } from '../utils';
import SLOCreateStatusInfo from './SLOCreateStatusInfo';
import SLOCreateStepper from './SLOCreateStepper';
import { ExplorerQueryProps, FormulaProps } from 'types/MetricsQueryBuilder';

const isMetricStateValid = (metricState: {
  queries: ExplorerQueryProps[];
  formulas: FormulaProps[];
}) => {
  const promQl = getSLOPromql({
    queryState: metricState,
  });
  return promQl !== '';
};

const SLOCreate = ({ isEditMode }: { isEditMode?: boolean }) => {
  const location = useLocation();
  const { queryTemplates } = useSLOQueryTemplates();
  const initialSloEditState = location.state as ReturnType<
    typeof useCreateSLOState
  >;
  const [params] = useSearchParams();
  const sloType = params.get('sloType');
  const [activeDetection, setActiveDetection] = useState<string>(sloType);
  const createSLOState = useCreateSLOState(
    initialSloEditState,
    activeDetection,
  );
  const { createSloRequest, numeratorQueryState, denominatorQueryState } =
    createSLOState;

  const { createSLO, dryRunSLO } = createSLOState;
  const [objective, setObjective] = useState(
    initialSloEditState?.objective || 99.9,
  );
  const [latencyThreshold, setLatencyThreshold] = useState(
    initialSloEditState?.latencyThreshold || 100,
  );
  const defaultMatchers = initialSloEditState?.matchers
    ? initialSloEditState?.matchers.split(',')
    : [];
  const [isSloNameChanged, setIsSloNameChanged] = useState(false);
  const alertsCreateState = useAlertsCreate(initialSloEditState?.contactPoints);
  const conditionState = useAlertsCreateCondition({ alertsCreateState });
  const alertsCreateAPMState = useAlertsCreateAPM({
    defaultAdditionalLabels: defaultMatchers,
    alertsCreateState,
    conditionState,
  });

  const handleActiveDetectionChange = (value: string) => {
    setActiveDetection(value);
  };

  const sloErrors = useMemo(() => {
    const objectiveNumber = toNumber(objective);
    const latencyNumber = toNumber(latencyThreshold);
    const isObjectiveValid = objectiveNumber > 0 && objectiveNumber < 100;
    const isLatencyThresholdValid =
      activeDetection === 'latency' ? latencyNumber >= 0 : true;
    const serviceHash =
      activeDetection === 'metrics'
        ? true
        : alertsCreateAPMState.apmAlertDetails.serviceHash;
    const isMetricsValid =
      activeDetection === 'metrics'
        ? isMetricStateValid(numeratorQueryState) &&
          isMetricStateValid(denominatorQueryState)
        : true;
    return {
      metrics: !isMetricsValid,
      objective: !isObjectiveValid,
      latencyThreshold: !isLatencyThresholdValid,
      serviceHash: !serviceHash,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    numeratorQueryState.queries,
    numeratorQueryState.formulas,
    denominatorQueryState.queries,
    denominatorQueryState.formulas,
    latencyThreshold,
    activeDetection,
    objective,
    alertsCreateAPMState.apmAlertDetails.serviceHash,
  ]);

  useEffect(() => {
    if (isSloNameChanged || initialSloEditState) {
      return;
    }
    if (alertsCreateAPMState?.apmAlertDetails?.serviceName) {
      const defaultSLOName = getDefaultNameForSLOType({
        type: activeDetection,
        serviceName: alertsCreateAPMState.apmAlertDetails.serviceName,
      });
      createSLOState.setFormValue('sloName', defaultSLOName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeDetection,
    alertsCreateAPMState?.apmAlertDetails?.serviceName,
    isSloNameChanged,
    initialSloEditState,
  ]);

  const handleDryRunSLO = () => {
    dryRunSLO({
      activeDetection,
      alertsCreateState,
      alertsCreateAPMState,
      denominatorQueryState,
      numeratorQueryState,
      latencyThreshold,
      isEditMode,
      objective,
      queryTemplates,
    });
  };

  useDebouncedEffect(
    () => {
      if (!queryTemplates.queryTemplatesFetched) {
        return;
      }
      const noErrors = !Object.values(sloErrors).some((error) => error);
      if (noErrors) {
        handleDryRunSLO();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      timeout: 100,
      ignoreInitialCall: false,
    },
    [
      numeratorQueryState.queries,
      numeratorQueryState.formulas,
      denominatorQueryState.queries,
      denominatorQueryState.formulas,
      sloErrors,
      activeDetection,
      latencyThreshold,
      objective,
      alertsCreateAPMState?.apmAlertDetails?.additionalLabels,
      alertsCreateAPMState?.apmAlertDetails?.serviceHash,
      queryTemplates.queryTemplatesFetched,
    ],
  );

  const handleSubmitClick = () => {
    createSLO({
      activeDetection,
      alertsCreateState,
      alertsCreateAPMState,
      latencyThreshold,
      isEditMode,
      objective,
      createSLOState,
      initialSloEditState,
    });
  };

  return (
    <div className="slo__create">
      <div className="slo__create__header">
        <div className="slo__create__header__title">
          {' '}
          {isEditMode ? 'Edit SLO:' : 'Create SLO'}{' '}
          {isEditMode && createSLOState?.getFormValue('sloName')}
          {!isEditMode && Boolean(activeDetection) && (
            <span>({startCase(activeDetection)})</span>
          )}
        </div>
      </div>
      <SLOCreateStatusInfo createSLOState={createSLOState} />
      <div className="slo__create__body">
        <Loader isLoading={createSloRequest.isLoading} className="w-full">
          <SLOCreateStepper
            createSLOState={createSLOState}
            isEditMode={isEditMode}
            handleSubmitClick={handleSubmitClick}
            activeDetection={activeDetection}
            handleActiveDetectionChange={handleActiveDetectionChange}
            setObjective={setObjective}
            setLatencyThreshold={setLatencyThreshold}
            alertsCreateAPMState={alertsCreateAPMState}
            latencyThreshold={latencyThreshold}
            objective={objective}
            alertsCreateState={alertsCreateState}
            setIsSloNameChanged={setIsSloNameChanged}
            sloType={sloType}
            sloErrors={sloErrors}
          />
        </Loader>
      </div>
    </div>
  );
};

export default SLOCreate;
