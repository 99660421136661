import { ChartGridV2 } from 'components';
import { useSearch, useTracesState } from 'hooks';
import { colorsByLogLevel } from 'kfuse-constants';
import React, { useMemo } from 'react';
import { aggregateTraceErrors } from 'requests';
import { DateSelection } from 'types';
import { getRollupByVisualization } from 'utils';

type GetRowArgs = {
  customerFilter: { key: string; value: string };
  errorGroupingKey?: string;
  isServiceFromDatabasesList?: boolean;
  serviceHash?: string;
  search: ReturnType<typeof useSearch>;
  setDate: (date: DateSelection) => void;
  tracesState: ReturnType<typeof useTracesState>;
};

const getRows = ({
  customerFilter,
  errorGroupingKey,
  isServiceFromDatabasesList,
  serviceHash,
  search,
  setDate,
  tracesState,
}: GetRowArgs) => {
  const onSelection = (startTimeUnix: number, endTimeUnix: number) => {
    setDate({ startTimeUnix, endTimeUnix });
  };

  const { groupBys, measure, operation, rollUpInSeconds } = search.state;

  return [
    [
      {
        charts: [
          {
            key: 'Errors',
            chartType: 'bar',
            colorMap: { count: colorsByLogLevel.error },
            disableExplore: true,
            label: 'Errors',
            totalKey: 'count',
            onSelection,
            query: ({ date, width }) =>
              aggregateTraceErrors({
                aggregation: operation,
                aggregationField: measure,
                dateToCompare: date,
                errorGroupingKey,
                groupBys,
                rollUpSeconds:
                  rollUpInSeconds || getRollupByVisualization(date, 'bar'),
                isServiceFromDatabasesList,
                serviceHash,
                tracesState,
              }),
          },
        ],
      },
    ],
  ];
};

type Props = {
  errorGroupingKey?: string;
  isServiceFromDatabasesList?: boolean;
  search: ReturnType<typeof useSearch>;
  serviceHash?: string;
  tracesState: ReturnType<typeof useTracesState>;
};

const TraceErrorChartGrid = ({
  errorGroupingKey,
  isServiceFromDatabasesList,
  search,
  serviceHash,
  tracesState,
}: Props) => {
  const {
    customerFilter,
    dateState,
    keyExistsState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    selectedHcFacetValuesByNameState,
    spanFilters,
  } = tracesState;
  const [date, setDate] = dateState;
  const keyExists = keyExistsState.state;
  const selectedFacetRangeByName = selectedFacetRangeByNameState.state;
  const selectedFacetValuesByName = selectedFacetValuesByNameState.state;
  const selectedHcFacetValuesByName = selectedHcFacetValuesByNameState.state;
  const { spanFilter } = spanFilters;

  const rows = useMemo(
    () =>
      getRows({
        customerFilter,
        errorGroupingKey,
        isServiceFromDatabasesList,
        serviceHash,
        search,
        setDate,
        tracesState,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      customerFilter,
      date,
      keyExists,
      selectedFacetValuesByName,
      selectedFacetRangeByName,
      selectedHcFacetValuesByName,
      search.state,
      spanFilter,
    ],
  );

  return (
    <div className="traces__chart-grid" data-testid="traces_chart_error_grid">
      <ChartGridV2.ChartGrid date={date} rows={rows} />
    </div>
  );
};

export default TraceErrorChartGrid;
