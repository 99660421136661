import { useState } from 'react';
import { useUrlState } from 'hooks';

type Options = {
  initalState?: Record<string, any>;
  urlStateKey?: string;
  shouldWriteToUrl?: boolean;
};

const initialState: {
  key: string | null;
  isAsc: boolean;
} = {
  key: null,
  isAsc: true,
};

const useSortState = (options?: Options) => {
  const shouldWriteToUrl =
    typeof options?.shouldWriteToUrl === 'boolean'
      ? options?.shouldWriteToUrl
      : true;
  const urlStateKey = options?.urlStateKey || 'sortState';
  const [urlState, setUrlState] = useUrlState(
    urlStateKey,
    options?.initalState || initialState,
  );
  const [regularState, setRegularState] = useState(
    options?.initalState || initialState,
  );

  const state = shouldWriteToUrl ? urlState : regularState;
  const setState = shouldWriteToUrl ? setUrlState : setRegularState;

  const sortBy = ({
    sortBy,
    sortOrder,
  }: {
    sortBy: string;
    sortOrder?: string;
  }) => {
    setState((prevState) => ({
      key: sortBy,
      isAsc: sortOrder ? (sortOrder === 'asc' ? true : false) : true,
    }));
  };

  return {
    sortBy,
    state,
  };
};

export default useSortState;
