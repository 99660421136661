import { fetchGrafanaApi } from 'requests';

const mutateGrafanaAlertManagerMuteTiming = (payload: Record<string, any>) => {
  return fetchGrafanaApi(
    `grafana/api/alertmanager/grafana/config/api/v1/alerts`,
    {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(payload),
    },
  );
};

export default mutateGrafanaAlertManagerMuteTiming;
