export const NANOSECONDS = 1000000000;
export const MICROSECONDS = 1000000;
export const MILLISECONDS = 1000;
export const SECONDS = 1;
export const MINUTES = 60;
export const HOURS = 3600;
export const DAYS = 86400;
export const WEEKS = 604800;
export const MONTHS = 2629746;
export const YEARS = 31556952;
