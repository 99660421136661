export enum KfuseQlFunction {
  ANOMALIES = 'anomalies',
  FORECAST = 'forecast',
  OUTLIERS = 'outliers',
  ABS = 'abs',
  ACOS = 'acos',
  ASIN = 'asin',
  ATAN = 'atan',
  CEIL = 'ceil',
  COS = 'cos',
  COSH = 'cosh',
  EXP = 'exp',
  FLOOR = 'floor',
  LOG = 'log',
  ROUND = 'round',
  SIN = 'sin',
  SINH = 'sinh',
  SQRT = 'sqrt',
  CBRT = 'cbrt',
  TAN = 'tan',
  TANH = 'tanh',
  EXPM1 = 'expm1',
}
