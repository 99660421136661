import classnames from 'classnames';
import { TooltipTrigger } from 'components';
import { X } from 'react-feather';
import React, { ReactNode } from 'react';
import { SelectedFacetRange, SelectedFacetValues } from 'types';

type Props = {
  clearFacet: () => void;
  name: ReactNode;
  selectedFacetRange: SelectedFacetRange;
  selectedFacetValues: SelectedFacetValues;
};

const FacetPickerResetButton = ({
  clearFacet,
  name,
  selectedFacetRange,
  selectedFacetValues,
}: Props) => {
  const tooltip = typeof name === 'string' ? `Reset ${name}` : 'Reset';
  if (selectedFacetRange) {
    return (
      <TooltipTrigger tooltip={tooltip}>
        <div
          className={classnames('flex items-center justify-center', {
            'facet-picker__title__reset-button': true,
          })}
          data-testid="facet_picker_reset"
        >
          <div className="facet-picker__title__reset-button__number px-1.5 h-full flex items-center">
            1
          </div>
          <button
            className="facet-picker__title__reset-button__x px-1"
            onClick={(e) => {
              e.stopPropagation();
              clearFacet();
            }}
          >
            <X size={10} />
          </button>
        </div>
      </TooltipTrigger>
    );
  }

  const modifiedKeys = Object.keys(selectedFacetValues);
  const hasBeenModified = modifiedKeys.length;

  if (hasBeenModified) {
    const isExcluding = selectedFacetValues[modifiedKeys[0]] === 0;

    return (
      <TooltipTrigger tooltip={tooltip} variant="compact" asChild={true}>
        <div
          className={classnames('flex items-center justify-center', {
            'facet-picker__title__reset-button': true,
            'facet-picker__title__reset-button--excluding': isExcluding,
          })}
          data-testid="facet_picker_reset"
        >
          <div className="facet-picker__title__reset-button__number px-1.5 h-full flex items-center">
            {modifiedKeys.length}
          </div>
          <button
            className="facet-picker__title__reset-button__x px-1"
            onClick={(e) => {
              e.stopPropagation();
              clearFacet();
            }}
            data-testid="facet-picker-reset-button"
          >
            <X size={10} />
          </button>
        </div>
      </TooltipTrigger>
    );
  }

  return null;
};

export default FacetPickerResetButton;
