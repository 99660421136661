const MetricLabelOperator = ['=~', '!~', '!=', '='];
export const MetricLabelOperatorOptions = MetricLabelOperator.map((item) => ({
  value: item,
  label: item,
}));

/**
 * Parse label query
 * @param query
 * @returns { label: string, operator: string, value: string | string[] }
 * @example 'label1="value1"' => { label: 'label1', operator: '=', value: 'value1' }
 * @example 'label1!="value1"' => { label: 'label1', operator: '!=', value: 'value1' }
 * @example 'label1=~"value1|value2|value3"' => { label: 'label1', operator: '=~', value: ["value1", "value2", "value3"] }
 * @opetaor = LabelSelectOperator
 */
export const parseMetricLabelQuery = (
  query: string,
): {
  label: string;
  operator: string;
  value: string | string[];
} => {
  const operator = MetricLabelOperator.find((item) => query.includes(item));
  if (!operator) {
    return null;
  }
  const [label, value] = query.split(operator);
  let labelValues: string | string[] = value.trim().replace(/"/g, '');
  if (operator === '=~' || operator === '!~') {
    labelValues = [labelValues];
  }
  return {
    label: label.trim(),
    operator,
    value: labelValues,
  };
};

export const buildPromqlLabelMatcher = ({
  series,
  seriesIndex,
  includesAll = false,
}: {
  series: string[];
  seriesIndex: number;
  includesAll?: boolean;
}): string => {
  let labelMatcher = '';

  if (!series || series.length === 0) {
    return labelMatcher;
  }

  if (seriesIndex === -1) {
    labelMatcher = series
      .map((item) => {
        if (item === '=""') return null;
        const parsed = parseMetricLabelQuery(item);
        if (!parsed) return null;

        if (parsed.label && parsed.operator && parsed.value) {
          return item;
        }

        if (parsed.label && parsed.operator && !parsed.value) {
          if (includesAll) return `${parsed.label}=~".*"`;
          return item;
        }
        return item;
      })
      .filter(Boolean)
      .join(', ');
  }

  if (seriesIndex > -1) {
    labelMatcher = series
      .map((item, index) => {
        if (index < seriesIndex && item !== '=""') {
          const parsed = parseMetricLabelQuery(item);
          if (!parsed) return null;

          if (parsed.label && parsed.operator && parsed.value) {
            return item;
          }

          if (parsed.label && parsed.operator && !parsed.value) {
            if (includesAll) return `${parsed.label}=~".*"`;
            return item;
          }
          return item;
        } else {
          return null;
        }
      })
      .filter(Boolean)
      .join(', ');
  }

  return labelMatcher ? labelMatcher : '';
};
