import { useSearches } from 'hooks';
import React, { ReactElement } from 'react';

import { EventPageProps } from '../types';
import EventsTabs from '../components/EventsTabs';
import EventsTimeseries from './EventsTimeseries';

const EventsAnalyticsQuery = ({
  searches,
  eventsState,
  tab,
}: EventPageProps & {
  searches: ReturnType<typeof useSearches>;
}): ReactElement => {
  return (
    <div>
      <EventsTabs />
      <EventsTimeseries
        searches={searches}
        eventsState={eventsState}
        tab={tab}
      />
    </div>
  );
};

export default EventsAnalyticsQuery;
