import { Button, ClickableDiv } from 'components';
import { X } from 'react-feather';
import React, { useEffect, useState } from 'react';
import TourSteps from './TourSteps';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

const ProductTour = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showTourStep, setShowTourStep] = useState(true);

  const [isDragging, setIsDragging] = useState(false);
  const [initialX, setInitialX] = useState(0);
  const [initialY, setInitialY] = useState(0);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);

  const location = useLocation();

  const previousStep = () => {
    if (currentIndex > 0) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
    }
    if (TourSteps[currentIndex].previousUrl != '') {
      window.location.href = TourSteps[currentIndex].previousUrl;
      localStorage.setItem(
        'lastViewedTourStepIndex',
        (currentIndex - 1).toString(),
      );
    }
    setPositionX(TourSteps[currentIndex - 1].posX);
    setPositionY(TourSteps[currentIndex - 1].posY);
  };

  const nextStep = () => {
    if (currentIndex < TourSteps.length - 1) {
      setCurrentIndex((currentIndex) => currentIndex + 1);
    } else {
      setCurrentIndex(TourSteps.length);
      setShowTourStep(false);

      return;
    }
    if (TourSteps[currentIndex].nextUrl != '') {
      window.location.href = TourSteps[currentIndex].nextUrl;
      localStorage.setItem(
        'lastViewedTourStepIndex',
        (currentIndex + 1).toString(),
      );
    }
    setPositionX(TourSteps[currentIndex + 1].posX);
    setPositionY(TourSteps[currentIndex + 1].posY);
  };

  const hideTourStep = () => {
    const currentUrl = window.location.href;
    const updatedUrl = currentUrl.replace(
      /(\?|&)pT=true|(\?|&)new=(true|false)/g,
      '',
    );

    window.location.href = updatedUrl;
    localStorage.setItem('lastViewedTourStepIndex', '0');
    setShowTourStep(false);
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    setIsDragging(true);
    setInitialX(event.clientX - positionX);
    setInitialY(event.clientY - positionY);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (isDragging) {
      const maxX = window.innerWidth - event.currentTarget.clientWidth;
      const maxY = window.innerHeight - event.currentTarget.clientHeight - 50;

      let newX = event.clientX - initialX;
      let newY = event.clientY - initialY;

      newX = Math.max(0, Math.min(newX, maxX));
      newY = Math.max(0, Math.min(newY, maxY));

      setPositionX(newX);
      setPositionY(newY);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let lastViewedIndex = parseInt(
      localStorage.getItem('lastViewedTourStepIndex') || '0',
      10,
    );

    if (queryParams.get('pT') === 'true') {
      setShowTourStep(true);
    } else {
      setShowTourStep(false);
    }

    if (queryParams.get('new') === 'true') {
      setCurrentIndex(0);
      lastViewedIndex = 0;
    } else {
      setCurrentIndex(lastViewedIndex);
    }

    setPositionX(TourSteps[lastViewedIndex].posX);
    setPositionY(TourSteps[lastViewedIndex].posY);
  }, [location.search]);

  return (
    <div
      className={classnames(
        'absolute z-50 max-w-[450px] font-noto shadow-4',
        'rounded border border-border bg-background p-4',
        { hidden: !showTourStep },
        { 'cursor-grabbing': isDragging },
        { 'cursor-grab': !isDragging },
      )}
      style={{ left: `${positionX}px`, top: `${positionY}px` }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      <div className="text-base font-bold">{TourSteps[currentIndex].title}</div>
      <ClickableDiv
        onClick={hideTourStep}
        className="absolute right-0 top-0 p-4"
      >
        <X size={18} />
      </ClickableDiv>

      <div className="mt-3">{TourSteps[currentIndex].content}</div>

      <div className="mt-3 flex justify-end gap-x-2">
        <Button variant="default" size="sm" onClick={previousStep}>
          Previous
        </Button>
        <Button
          variant="default"
          size="sm"
          onClick={
            currentIndex < TourSteps.length - 1 ? nextStep : hideTourStep
          }
        >
          {currentIndex < TourSteps.length - 1 ? 'Next' : 'End'}
        </Button>
      </div>
    </div>
  );
};

export default ProductTour;
