import { SidebarData } from 'components/FacetPickerV2/types';
import { ValueCount } from 'types';
import { create } from 'zustand';

type EventsSidebarData = {
  sidebarData: SidebarData;
  sidebarDataLoading: Record<string, boolean>;
  fetchSidebarValues: ({
    name,
    request,
  }: {
    name: string;
    request: () => Promise<ValueCount[]>;
  }) => void;
};

const useEventsSidebarData = create((set) => ({
  sidebarData: {},
  sidebarDataLoading: {},
  fetchSidebarValues: ({
    name,
    request,
  }: {
    name: string;
    request: () => Promise<ValueCount[]>;
  }) => {
    set(
      (state: {
        sidebarDataLoading: EventsSidebarData['sidebarDataLoading'];
      }) => ({
        sidebarDataLoading: {
          ...state.sidebarDataLoading,
          [name]: true,
        },
      }),
    );

    request().then((data) => {
      set(
        (state: {
          sidebarData: EventsSidebarData['sidebarData'];
          sidebarDataLoading: EventsSidebarData['sidebarDataLoading'];
        }) => ({
          sidebarData: {
            ...state.sidebarData,
            [name]: data,
          },
          sidebarDataLoading: {
            ...state.sidebarDataLoading,
            [name]: false,
          },
        }),
      );
    });
  },
}));

export default useEventsSidebarData;
