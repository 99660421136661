import { isNil } from 'lodash';
import { TableTransformerOrganizeOptions } from '../types';

// id -> oragnize
const organizeColumn = ({
  data,
  options,
  byField,
}: {
  data: {
    data: Record<string, unknown>[];
    columns: Array<{ key: string; label: string }>;
  };
  options: TableTransformerOrganizeOptions;
  byField: string;
}): {
  data: Record<string, unknown>[];
  columns: Array<{ key: string; label: string }>;
} => {
  if (!Array.isArray(data.data)) {
    return { data: [], columns: [] };
  }
  const columnsFromData = getColumnsFromObject(data.data);
  const columns = getTableColumnsByOrganize(options, columnsFromData);

  if (!columns) return { data: data.data, columns: [] };

  // move byField to the first column
  if (byField) {
    const byFieldIndex = columns.findIndex((col) => col.key === byField);
    if (byFieldIndex > -1) {
      const byFieldColumn = columns.splice(byFieldIndex, 1)[0];
      columns.unshift(byFieldColumn);
    }
  }

  const newData = data.data.map((datum) => {
    const newDatum: { [key: string]: string } = {};
    columns.forEach((col) => {
      if (!datum || !datum.hasOwnProperty(col.key)) return;
      newDatum[col.key] = datum[col.key];
    });

    return newDatum;
  });
  return { data: newData, columns };
};

export const getColumnsFromObject = (
  data: Record<string, unknown>[],
): { key: string; label: string }[] => {
  // get all columns from data
  const cols = data.reduce((acc, curr) => {
    Object.keys(curr).forEach((key) => {
      if (!acc.includes(key)) {
        acc.push(key);
      }
    });
    return acc;
  }, [] as string[]);

  return cols.map((col) => ({ key: col, label: col }));
};

export const sortTableColumn = (
  indexByName: TableTransformerOrganizeOptions['indexByName'],
  columns: string[],
): string[] => {
  if (indexByName) {
    columns.sort((a, b) => {
      const indexA = isNil(indexByName[a]) ? columns.length : indexByName[a];
      const indexB = isNil(indexByName[b]) ? columns.length : indexByName[b];
      return indexA - indexB;
    });
  }
  return columns;
};

const getTableColumnsByOrganize = (
  options: TableTransformerOrganizeOptions,
  columns: Array<{ key: string; label: string }>,
): Array<{ key: string; label: string }> => {
  if (!options || typeof options !== 'object') {
    return [];
  }
  const newColumns: Array<{ key: string; label: string }> = [];
  const excludeByName = options.excludeByName || {};
  const newRenameByName = { ...(options.renameByName || {}) };
  columns.forEach((col) => {
    if (newRenameByName[col.key]) return;
    newRenameByName[col.key] = col.label;
  });

  // Exclude columns by name
  const excludeByNameKeys = Object.keys(excludeByName || {});
  excludeByNameKeys.forEach((key) => {
    if (excludeByName[key] === true) {
      delete newRenameByName[key];
    }
  });
  let renameByNameKeys = Object.keys(newRenameByName || {});

  const newIndexByName = { ...options.indexByName };
  // push Value column to the end
  const nonValueCols = renameByNameKeys.filter((s) => !s.startsWith('Value'));
  const valuesCols = renameByNameKeys.filter((s) => s.startsWith('Value'));
  renameByNameKeys = [...nonValueCols, ...valuesCols];

  const sortedColumns = sortTableColumn(newIndexByName, renameByNameKeys);
  sortedColumns.forEach((key) => {
    newColumns.push({ key, label: newRenameByName[key] });
  });

  return newColumns;
};

export default organizeColumn;
