import { useMemo, useState } from 'react';

const useTableBulkActions = ({
  rows,
  uniqueIdKey,
}: {
  rows: Record<string, any>[];
  uniqueIdKey: string;
}) => {
  const [selectedRows, setSelectedRows] = useState<{
    [key: string]: boolean;
  }>({});

  const onCheckedChange = (uniqueId: string, value: boolean) => {
    setSelectedRows((prev) => {
      const newSelectedRows = { ...prev };
      if (value) {
        newSelectedRows[uniqueId] = true;
      } else {
        delete newSelectedRows[uniqueId];
      }
      return newSelectedRows;
    });
  };

  const onSelectDeselectAll = (value: boolean) => {
    if (value === false) {
      setSelectedRows({});
      return;
    }

    setSelectedRows((prev) => {
      const newSelectedRows = { ...prev };
      rows.forEach((row) => {
        newSelectedRows[row[uniqueIdKey]] = value;
      });
      return newSelectedRows;
    });
  };
  const clearSelectedRows = () => {
    setSelectedRows(() => ({ ...{} }));
    return;
  };

  const selectedRowsCount = useMemo(() => {
    return Object.keys(selectedRows).length;
  }, [selectedRows]);

  const isAllSelected = useMemo(() => {
    return selectedRowsCount === rows.length;
  }, [selectedRowsCount, rows]);

  return {
    clearSelectedRows,
    isAllSelected,
    selectedRows,
    setSelectedRows,
    onCheckedChange,
    onSelectDeselectAll,
    rows,
    selectedRowsCount,
  };
};

export default useTableBulkActions;
