const tokenizer = (input: string): string[] => {
  const tokens: string[] = [];
  let currentToken = '';

  const isDigit = (char: string) => char.match(/[0-9]/) !== null;
  const isLetter = (char: string) => char.match(/[a-zA-Z]/) !== null;
  const isOperator = (char: string) => '+-*/()'.includes(char);
  const isDot = (char: string) => char === '.';
  const isWhitespace = (char: string) => char === ' ';

  for (const char of input) {
    if (isWhitespace(char)) {
      // If space and current token is not empty, push it to tokens
      if (currentToken !== '') {
        tokens.push(currentToken);
        currentToken = '';
      }
    } else if (isOperator(char)) {
      // If operator, push current token if it exists and push the operator
      if (currentToken !== '') {
        tokens.push(currentToken);
        currentToken = '';
      }
      tokens.push(char);
    } else if (isDigit(char) || isLetter(char) || isDot(char)) {
      if (currentToken === '') {
        // Start a new token
        currentToken = char;
      } else {
        // If the current character is a dot, ensure it's part of a number
        if (isDot(char) && !currentToken.includes('.')) {
          currentToken += char;
        } else if (
          isDigit(char) &&
          (isDigit(currentToken[currentToken.length - 1]) ||
            currentToken[currentToken.length - 1] === '.')
        ) {
          // Continue the current number token
          currentToken += char;
        } else if (
          isLetter(char) &&
          isLetter(currentToken[currentToken.length - 1])
        ) {
          // Continue the current variable token
          currentToken += char;
        } else {
          // Otherwise, push the current token and start a new one
          tokens.push(currentToken);
          currentToken = char;
        }
      }
    } else {
      throw new Error(`Unknown character: ${char}`);
    }
  }

  // Push the last token if it exists
  if (currentToken !== '') {
    tokens.push(currentToken);
  }

  return tokens;
};

export default tokenizer;
