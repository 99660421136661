import React, { useState } from 'react';
import { cn } from 'utils/tailwind';
import { Badge, badgeVariants } from 'components/shadcn/badge';
import { X } from 'react-feather';
import { Slot } from '@radix-ui/react-slot';

type ChipProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  className?: string;
  variant?: 'code';
  asChild?: true;
  children: React.ReactNode;
  onRemove?: React.MouseEventHandler<HTMLButtonElement>;
};

/**
 * A `Chip` differentiate from `Badge` by adding behavior. In this case,
 * adding the remove button.
 */
export default function Chip({
  className,
  variant,
  asChild,
  children,
  onRemove,
  ref,
  ...props
}: ChipProps) {
  const Tag = asChild ? Slot : 'div';

  return (
    <Tag className={cn('group group/chip relative', className)} {...props}>
      <Badge
        variant="code"
        className={cn(
          'text-[15px]',
          'hover:border-chip-border hover:shadow-chip',
          'group-focus-within:bg-chip-active-bg group-focus-within:border-interaction-secondary group-focus-within:hover:border-interaction-primary',
          'max-w-full',
          {
            // extend shadow to close button by adding 18px
            'hover:w-[calc(100%+18px)] hover:max-w-[calc(100%+18px)]': onRemove,
          },
        )}
      >
        {children}
        {onRemove && (
          <button
            type="button"
            aria-label="remove"
            onClick={typeof onRemove === 'function' ? onRemove : null}
            className={`
              absolute right-1 top-0 z-[2]
              hidden
              h-full translate-x-full items-center
              justify-center rounded-r border-y border-r
              border-chip-border bg-chip-delete-bg
              px-1 group-focus-within:border-interaction-primary group-focus-within:bg-chip-delete-bg-active group-hover/chip:flex
              hover:bg-chip-groupby-active-bg hover:text-primary-foreground group-focus-within:hover:bg-interaction-primary
            `}
          >
            <X size={15} />
          </button>
        )}
      </Badge>
    </Tag>
  );
}
