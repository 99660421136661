export type Operator = '' | '!' | '=' | '!=';

export type Expression = {
  property: string;
  operator: Operator;
  values: Array<string>;
};

export type State = Array<Expression>;

export enum ActionType {
  SET_EXPRESSIONS = 'SET_EXPRESSIONS',
  CREATE_EXPRESSION = 'CREATE_EXPRESSION',
  SET_PROPERTY = 'SET_PROPERTY',
  SET_OPERATOR = 'SET_OPERATOR',
  SET_VALUE = 'SET_VALUE',
  REMOVE_EXPRESSION = 'REMOVE_EXPRESSION',
  REMOVE_VALUE = 'REMOVE_VALUE',
}

export type PayloadByActionType = {
  [ActionType.SET_EXPRESSIONS]: { expressions: Array<Expression> };
  [ActionType.CREATE_EXPRESSION]: { property: string; operator: Operator };
  [ActionType.SET_PROPERTY]: { index: number; property: string };
  [ActionType.SET_OPERATOR]: { index: number; operator: Operator };
  [ActionType.SET_VALUE]: { index: number; valueIndex: number; value: string };
  [ActionType.REMOVE_EXPRESSION]: { index: number };
  [ActionType.REMOVE_VALUE]: { index: number; valueIndex: number };
};

type Action = {
  [K in ActionType]: {
    type: K;
    payload: PayloadByActionType[K];
  };
}[ActionType];

export type Reducer = (state: State, action: Action) => State;

export enum ElementType {
  PROPERTY = 'PROPERTY',
  OPERATOR = 'OPERATOR',
  VALUE = 'VALUE',
  INITIALIZER = 'INITIALIZER',
}

export type ElementToFocusInfo =
  | { type: ElementType.PROPERTY; index: number }
  | { type: ElementType.OPERATOR; index: number }
  | {
      type: ElementType.VALUE;
      index: number;
      valueIndex: number;
      selectionStart?: number;
    }
  | { type: ElementType.INITIALIZER };

export type FocusedElementInfo = ElementToFocusInfo;

export type ChipRef = {
  focusProperty: () => void;
  focusOperator?: () => void;
  focusValue?: (valueIndex: number, selectionStart?: number) => void;
  getFlatRefList: () => Array<HTMLInputElement>;
};

export type ChipProps = {
  expression: Expression;
  index: number;
  setProperty: (payload: PayloadByActionType['SET_PROPERTY']) => void;
  setOperator: (payload: PayloadByActionType['SET_OPERATOR']) => void;
  setValue: (payload: PayloadByActionType['SET_VALUE']) => void;
  setElementToFocusInfo: (info: ElementToFocusInfo | null) => void;
  removeExpression: (payload: PayloadByActionType['REMOVE_EXPRESSION']) => void;
  getValueIfExists: (args: {
    index: number;
    valueIndex: number;
  }) => string | null;
  getPropertyIfExists: (args: { index: number }) => string | null;
  removeValue: (payload: PayloadByActionType['REMOVE_VALUE']) => void;
  focusedElementInfo: FocusedElementInfo;
  setFocusedElementInfo: React.Dispatch<
    React.SetStateAction<ElementToFocusInfo>
  >;
};

export type Suggestion = {
  id: string;
  label: string;
};

export type ExpressionBuilderState = {
  expressions: Array<Expression>;
  getExpressionIfExists: (args: { index: number }) => Expression | null;
  createExpression: (payload: PayloadByActionType['CREATE_EXPRESSION']) => void;
  removeExpression: ChipProps['removeExpression'];
  getPropertyIfExists: ChipProps['getPropertyIfExists'];
  setProperty: ChipProps['setProperty'];
  getValueIfExists: ChipProps['getValueIfExists'];
  setValue: ChipProps['setValue'];
  removeValue: ChipProps['removeValue'];
  setOperator: ChipProps['setOperator'];
  focusedElementInfo: FocusedElementInfo;
  setFocusedElementInfo: (info: FocusedElementInfo | null) => void;
  setElementToFocusInfo: ChipProps['setElementToFocusInfo'];
};
