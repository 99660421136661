import { useDateState } from 'hooks';
import { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ExplorerQueryProps, FormulaProps } from 'types/MetricsQueryBuilder';
import {
  extractPathNameFromURL,
  parseUrlParamByKey,
  getMetricsExplorerDefaultQuery,
} from 'utils';

const useMetricsExplorerPageState = ({
  metricDateState,
}: {
  metricDateState: ReturnType<typeof useDateState>;
}) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const isMultiChartState = useState(
    parseUrlParamByKey('isMultiChart') || false,
  );

  const metricsQueriesParsed = parseUrlParamByKey('metricsQueries') || [
    getMetricsExplorerDefaultQuery(''),
  ];
  const queriesState = useState<ExplorerQueryProps[]>(metricsQueriesParsed);

  const metricsFormulasParsed = parseUrlParamByKey('metricsFormulas') || [];
  const formulasState = useState<FormulaProps[]>(metricsFormulasParsed);

  const metricDate = metricDateState[0];
  const isMultiChart = isMultiChartState[0];
  const queries = queriesState[0];
  const formulas = formulasState[0];
  // Get dependencies for determining when a url change should happen
  const dependenciesForWriteStateToUrl = useMemo(
    () => [
      metricDateState[0],
      isMultiChartState[0],
      queriesState[0],
      formulasState[0],
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [metricDate, isMultiChart, queries, formulas],
  );

  // Should be called on state change when on the Traces page
  const writeStateToUrl = () => {
    params.set('metricDate', JSON.stringify(metricDateState[0]));
    params.set('isMultiChart', JSON.stringify(isMultiChartState[0]));
    params.set('metricsQueries', JSON.stringify(queriesState[0]));
    params.set('metricsFormulas', JSON.stringify(formulasState[0]));
    const navigateUrl = `${extractPathNameFromURL()}?${params.toString()}`;
    navigate(navigateUrl, { replace: true });
  };

  return {
    dateState: metricDateState,
    dependenciesForWriteStateToUrl,
    queriesState,
    formulasState,
    isMultiChartState,
    writeStateToUrl,
  };
};

export default useMetricsExplorerPageState;
