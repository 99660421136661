import {
  ChipWithLabel,
  CursorTooltip,
  FacetPickerValuesItemPopoverPanel,
  PopoverTriggerV2,
  PopoverPosition,
} from 'components';
import { useTracesState } from 'hooks';
import React, { useState } from 'react';
import { roundToPrecision } from 'more-rounding';
import { colors } from './constants';
import { CompareAttributeCount } from './types';

type Props = {
  compareAttributeCount: CompareAttributeCount;
  hoveredNameState: ReturnType<typeof useState<string>>;
  renderValueLabel: (value: string) => string;
  tracesState: ReturnType<typeof useTracesState>;
};

const TracesHeatmapChartsGridItemHorizontalBars = ({
  compareAttributeCount,
  hoveredNameState,
  renderValueLabel,
  tracesState,
}: Props) => {
  const { counts, name } = compareAttributeCount;
  const { keyExistsState, selectedFacetValuesByNameState } = tracesState;
  const [hoveredName, setHoveredName] = hoveredNameState;

  const onMouseEnterHandler =
    ({ name, value }) =>
    () => {
      setHoveredName({ name, value });
    };

  const onMouseLeave = () => {
    setHoveredName(null);
  };

  const excludeFacetValueHandler =
    ({ name }) =>
    (value: string) => {
      selectedFacetValuesByNameState.excludeFacetValue({
        name,
        value,
      });
    };

  const selectOnlyFacetValueHandler =
    ({ name }) =>
    (value: string) => {
      selectedFacetValuesByNameState.selectOnlyFacetValue({
        name,
        value,
      });
    };

  const showKeyExistsHandler =
    ({ name }) =>
    () => {
      keyExistsState.setKeyExists(name);
    };

  return (
    <div className="traces__heatmap__charts__grid__item__horizontal-bars">
      {(counts || []).map((count) => {
        const { value } = count;
        const valueLabel = renderValueLabel
          ? renderValueLabel(count.value)
          : count.value;

        return (
          <>
            <div
              className="traces__heatmap__charts__grid__item__horizontal-bars__count"
              key={count.value}
            >
              <div className="traces__heatmap__charts__grid__item__horizontal-bars__count__value">
                <PopoverTriggerV2
                  popover={({ close }) => (
                    <FacetPickerValuesItemPopoverPanel
                      close={close}
                      excludeFacetValue={excludeFacetValueHandler({ name })}
                      label={`${name}:${value}`}
                      selectOnlyFacetValue={selectOnlyFacetValueHandler({
                        name,
                      })}
                      showKeyExists={showKeyExistsHandler({ name })}
                      showKeyExistsLabel={name}
                      value={String(value)}
                    />
                  )}
                  position={PopoverPosition.BOTTOM_LEFT}
                >
                  <span title={valueLabel}>{valueLabel}</span>
                </PopoverTriggerV2>
              </div>
              <div
                className="traces__heatmap__charts__grid__item__horizontal-bars__count__bars"
                onMouseEnter={onMouseEnterHandler({ name, value })}
                onMouseLeave={onMouseLeave}
              >
                {['baseline', 'selection'].map((key) => (
                  <div
                    className="traces__heatmap__charts__grid__item__horizontal-bars__count__bar"
                    key={key}
                    style={{
                      backgroundColor: colors[key],
                      width: `${count[`${key}Incidence`] * 100}%`,
                    }}
                  />
                ))}
              </div>
            </div>
            {hoveredName &&
            hoveredName.name === name &&
            hoveredName.value === value ? (
              <CursorTooltip>
                <div className="traces__service-map__node__tooltip">
                  <div
                    className="traces__service-map__node__tooltip__name"
                    data-testid="tooltip-name"
                  >
                    {value}
                  </div>
                  <div>
                    {['baseline', 'selection'].map((key, i) => (
                      <div
                        className="traces__heatmap__charts__grid__item__horizontal-bars__tooltip__item"
                        key={i}
                      >
                        <ChipWithLabel
                          className="traces__heatmap__charts__grid__item__horizontal-bars__tooltip__item__label"
                          color={colors[key]}
                          data-testid="tooltip-item-label"
                          label={key}
                        />
                        <div
                          className="traces__service-map__node__tooltip__values__item__value"
                          data-testid="tooltip-item-value"
                        >
                          {`${roundToPrecision(count[`${key}Incidence`] * 100)}%`}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </CursorTooltip>
            ) : null}
          </>
        );
      })}
    </div>
  );
};

export default TracesHeatmapChartsGridItemHorizontalBars;
