import {
  isSortingDisabledForTraceColumn,
  mapClientKeyToServerKey,
  TracesTableColumnKey,
  tracesTableColumns,
} from 'kfuse-constants';
import {
  Loader,
  SpanFilters,
  Table,
  TableHeader,
  useColumnsState,
  useTableBESort,
  useTableOptions,
} from 'components';
import {
  useRequest,
  useSelectedFacetValuesByNameState,
  useSortState,
  useSpanFilters,
} from 'hooks';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { traces } from 'requests';
import { DateSelection, SpanFilter, Trace } from 'types';
import { SidebarState } from './types';

type Props = {
  colorsByServiceName: { [key: string]: string };
  date: DateSelection;
  isServiceFromDatabasesList?: boolean;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
  serviceHash: string;
  setSidebar: Dispatch<SetStateAction<SidebarState>>;
};

const ServiceTraces = ({
  colorsByServiceName,
  date,
  isServiceFromDatabasesList,
  selectedFacetValuesByNameState,
  serviceHash,
  setSidebar,
}: Props) => {
  const pageNumRef = useRef<number>(1);
  const [result, setResult] = useState([]);
  const tracesRequest = useRequest(traces);
  const selectedFacetValuesByNameForSpanFilters =
    useSelectedFacetValuesByNameState({
      shouldWriteToUrl: false,
    });
  const spanFilters = useSpanFilters({
    initialSpanFilter: isServiceFromDatabasesList
      ? SpanFilter.allSpans
      : SpanFilter.serviceEntrySpans,
    selectedFacetValuesByNameState: selectedFacetValuesByNameForSpanFilters,
    spanFilterOptionsList: isServiceFromDatabasesList
      ? [SpanFilter.allSpans]
      : [
          SpanFilter.allSpans,
          SpanFilter.serviceEntrySpans,
          SpanFilter.traceRootSpans,
        ],
  });
  const { spanFilter } = spanFilters;

  const tableColumns = tracesTableColumns({
    colorsByServiceName,
  });
  const skipColumn = isServiceFromDatabasesList ? 0 : 1;
  const tableOptions = useTableOptions();
  const columnsState = useColumnsState({
    columns: tableColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        [TracesTableColumnKey.spanStartTimeNs]: 1,
        [TracesTableColumnKey.spanAttributesServiceName]: 1,
        [TracesTableColumnKey.spanName]: 1,
        [TracesTableColumnKey.duration]: 1,
        [TracesTableColumnKey.spanMethod]: skipColumn,
        [TracesTableColumnKey.spanAttributesStatusCode]: skipColumn,
        [TracesTableColumnKey.spanEndpoint]: skipColumn,
        [TracesTableColumnKey.spanCount]: 1,
        [TracesTableColumnKey.traceMetrics]: 1,
      },
    },
    shouldUseLocalStorage: !isServiceFromDatabasesList,
    key: 'service-traces-table',
  });

  const traceSortBy = useSortState({
    shouldWriteToUrl: false,
    urlStateKey: 'tracesSort',
    initalState: {
      key: TracesTableColumnKey.spanStartTimeNs,
      isAsc: false,
    },
  });

  const call = () => {
    const pageNum = pageNumRef.current || 1;
    tracesRequest
      .call({
        date,
        pageNum,
        selectedFacetValuesByName: {
          ...(isServiceFromDatabasesList
            ? {
                ['kf_database_service_hash']: {
                  [serviceHash]: 1,
                },
              }
            : {
                ['service_hash']: {
                  [serviceHash]: 1,
                  ...(selectedFacetValuesByNameState.state.service_hash || {}),
                },
              }),
          ...selectedFacetValuesByNameState.state,
          ...selectedFacetValuesByNameForSpanFilters.state,
        },
        spanFilter,
        sortBy: mapClientKeyToServerKey(traceSortBy.state?.key),
        sortOrder: traceSortBy?.state?.isAsc ? 'Asc' : 'Desc',
      })
      .then((nextResult) => {
        if (nextResult && nextResult.length) {
          pageNumRef.current = pageNum + 1;
          setResult((prevResult) => [...prevResult, ...nextResult]);
        }
      });
  };

  useEffect(() => {
    pageNumRef.current = null;
    setResult([]);
    call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    date,
    selectedFacetValuesByNameState.state,
    spanFilter,
    selectedFacetValuesByNameForSpanFilters.state,
    traceSortBy.state,
  ]);

  const onScrollEnd = () => {
    call();
  };

  const rows: Trace[] = result || [];

  const tableSort = useTableBESort({
    columns: tableColumns,
    initialKey: TracesTableColumnKey.spanStartTimeNs,
    rows: rows,
    onSortChange: ({ sortBy, sortOrder }) => {
      traceSortBy.sortBy({ sortBy, sortOrder });
    },
  });

  return (
    <div className="service__traces">
      <div className="service__traces__header">
        <TableHeader columnsState={columnsState} tableOptions={tableOptions} />
        <SpanFilters spanFilters={spanFilters} />
      </div>
      <Loader
        className="service__traces__table overflow-x-auto"
        isLoading={tracesRequest.isLoading}
      >
        <Table
          className="table--padded table--bordered table--bordered-cells"
          columns={columnsState.renderedColumns}
          isSortingEnabled
          externalTableSort={tableSort}
          isSortingDisabledForColumn={isSortingDisabledForTraceColumn}
          onRowClick={({ row }) => {
            setSidebar((prevSidebar) => {
              const nextPrevSidebar = prevSidebar ? { ...prevSidebar } : {};
              nextPrevSidebar.activeTrace = row;
              return nextPrevSidebar;
            });
          }}
          rows={tableSort.sortedRows}
          onScrollEnd={onScrollEnd}
        />
      </Loader>
    </div>
  );
};

export default ServiceTraces;
