import { QueryDataProps } from 'types';
import { getUsedQueryKeysFromFormula } from 'utils';

import { intersectLabels } from 'utils';

const getUsedQueriesLabelForFormula = ({
  formulaExpression,
  tempLogsChartData,
  tempLabelKeys,
}: {
  formulaExpression: string;
  tempLogsChartData: QueryDataProps;
  tempLabelKeys: { [key: string]: string[] };
}): { [key: string]: boolean } | null => {
  for (const key in tempLogsChartData) {
    if (tempLogsChartData[key]?.meta?.labels?.length === 0) {
      return null;
    }
  }

  const usedQueryKeys = getUsedQueryKeysFromFormula(formulaExpression);
  const labelBitmapForQuery: { [query: string]: { [key: string]: boolean } } =
    {};
  usedQueryKeys.forEach((key) => {
    const labelBitmap: { [key: string]: boolean } = {};

    const queryId = `query_${key}`;
    const rangeAggregateGrouping = tempLabelKeys[queryId];

    // if there is no rangeAggregateGrouping, then there is no intersection
    if (!rangeAggregateGrouping || !rangeAggregateGrouping.length) return;

    const queryData = tempLogsChartData[queryId];
    if (!queryData) return;

    if (queryData.range) {
      queryData.range.series.map((s) => {
        labelBitmap[s.label] = true;
      });
    }

    if (queryData.instant) {
      queryData.instant.data.map((s) => {
        labelBitmap[s.displayLabel] = true;
      });
    }
    labelBitmapForQuery[queryId] = labelBitmap;
  });

  return intersectLabels(...Object.values(labelBitmapForQuery));
};

export default getUsedQueriesLabelForFormula;
