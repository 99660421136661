import { isNil } from 'lodash';
import { UplotChartStyles, ChartType } from 'types/Timeseries';
import { getChartScaleConfig } from './config-utils';

const roundDec = (val: number, dec = 0) => {
  if (Number.isInteger(val)) return val;

  const p = 10 ** dec;
  const n = val * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
};

const getDecimalCount = (num: number) => {
  return (('' + num).split('.')[1] || '').length;
};

const numIntDigits = (x: number) => {
  return Math.floor(Math.log10(Math.abs(x))) + 1;
};

export const fixedDec = new Map();

const fixFloat = (v: number) => roundDec(v, 14);

const incrRoundUp = (num: number, incr: number) => {
  return fixFloat(Math.ceil(fixFloat(num / incr)) * incr);
};

const incrRoundDn = (num: number, incr: number) => {
  return fixFloat(Math.floor(fixFloat(num / incr)) * incr);
};

/**
 * Generates an array of increment values based on the specified base, exponent range, and multipliers.
 * Each increment value is rounded to a specific number of decimal places to avoid floating-point precision issues.
 * @param {number} base - The base value for the exponentiation.
 * @param {number} minExp - The minimum exponent value.
 * @param {number} maxExp - The maximum exponent value.
 * @param {number[]} mults - An array of multipliers to apply to the base raised to each exponent.
 * @returns {number[]} An array of increment values.
 */
const genIncrs = ({
  base,
  minExp,
  maxExp,
  mults,
}: {
  base: number;
  minExp: number;
  maxExp: number;
  mults: number[];
}) => {
  const incrs = [];
  const multDecimals = mults.map(getDecimalCount);

  for (let exp = minExp; exp < maxExp; exp++) {
    const expa = Math.abs(exp);
    const mag = roundDec(Math.pow(base, exp), expa);

    for (let i = 0; i < mults.length; i++) {
      const _incr = mults[i] * mag;
      const dec =
        (_incr >= 0 && exp >= 0 ? 0 : expa) +
        (exp >= multDecimals[i] ? 0 : multDecimals[i]);
      const incr = roundDec(_incr, dec);
      incrs.push(incr);
      fixedDec.set(incr, dec);
    }
  }

  return incrs;
};

const closestIdx = (num: number, arr: number[], lo?: number, hi?: number) => {
  let mid;
  lo = lo || 0;
  hi = hi || arr.length - 1;
  const bitwise = hi <= 2147483647;

  while (hi - lo > 1) {
    mid = bitwise ? (lo + hi) >> 1 : Math.floor((lo + hi) / 2);

    if (arr[mid] < num) lo = mid;
    else hi = mid;
  }

  if (num - arr[lo] <= arr[hi] - num) return lo;

  return hi;
};

/**
 * Finds the best increment value and corresponding space for axis ticks.
 * @param {number} minVal - The minimum value of the axis.
 * @param {number} maxVal - The maximum value of the axis.
 * @param {number[]} incrs - An array of possible increment values.
 * @param {number} dim - The dimension (length) of the axis in pixels.
 * @param {number} minSpace - The minimum space (in pixels) required between ticks.
 * @returns {{ foundIncr: number; foundSpace: number }} An object containing the found increment value and the corresponding space.
 */
const findIncr = (
  minVal: number,
  maxVal: number,
  incrs: number[],
  dim: number,
  minSpace: number,
): { foundIncr: number; foundSpace: number } => {
  const intDigits = Math.max(numIntDigits(minVal), numIntDigits(maxVal));

  const delta = maxVal - minVal;

  let incrIdx = closestIdx((minSpace / dim) * delta, incrs);

  if (delta > 1e16) {
    return { foundIncr: 1e14, foundSpace: 0 };
  }

  do {
    const foundIncr = incrs[incrIdx];
    const foundSpace = (dim * foundIncr) / delta;

    if (
      foundSpace >= minSpace &&
      intDigits + (foundIncr < 5 ? fixedDec.get(foundIncr) : 0) <= 17
    ) {
      return { foundIncr, foundSpace };
    }
  } while (++incrIdx < incrs.length);

  return { foundIncr: 0, foundSpace: 0 };
};

/**
 * Determines the best increment value and corresponding space for axis ticks based on the given range and dimension.
 *
 * @param {number} min - The minimum value of the axis.
 * @param {number} max - The maximum value of the axis.
 * @param {number} fullDim - The dimension (length) of the axis in pixels.
 * @returns {{ foundIncr: number; foundSpace: number }} An object containing the found increment value and the corresponding space.
 *
 * @description
 * This function calculates the optimal increment value (`foundIncr`) and the corresponding space (`foundSpace`) for axis ticks.
 * It first checks if the dimension is less than or equal to zero, in which case it returns zero for both increment and space.
 * Otherwise, it generates possible increment values using the `genIncrs` function for both decimal and whole number ranges.
 * It then finds the best increment value and space using the `findIncr` function.
 */
const getIncrSpace = (
  min: number,
  max: number,
  fullDim: number,
): { foundIncr: number; foundSpace: number } => {
  let incrSpace;

  if (fullDim <= 0) incrSpace = { foundIncr: 0, foundSpace: 0 };
  else {
    const minSpace = 30;
    const allMults = [1, 2, 2.5, 5];
    const decIncrs = genIncrs({
      base: 10,
      minExp: -16,
      maxExp: 0,
      mults: allMults,
    });
    const oneIncrs = genIncrs({
      base: 10,
      minExp: 0,
      maxExp: 16,
      mults: allMults,
    });
    const incrs = decIncrs.concat(oneIncrs);
    incrSpace = findIncr(min, max, incrs, fullDim, minSpace);
  }
  return incrSpace;
};

/**
 * Generates an array of axis split values (tick marks) based on the given scale range and increment value.
 *
 * @param {number} scaleMin - The minimum value of the scale.
 * @param {number} scaleMax - The maximum value of the scale.
 * @param {number} foundIncr - The increment value for the axis splits.
 * @param {boolean} forceMin - Whether to force the minimum value to be used as the starting point.
 * @returns {number[]} An array of axis split values.
 *
 * @description
 * This function calculates the positions of axis splits (tick marks) for a given scale range. It starts from the minimum value
 * (or the next rounded-up value based on the increment if `forceMin` is false) and generates values up to the maximum value,
 * incrementing by the specified `foundIncr` value. The function ensures that floating-point precision issues are handled by
 * rounding the values to the appropriate number of decimal places. It also coalesces -0 to 0 to avoid negative zero values.
 */
export function numAxisSplits({
  scaleMin,
  scaleMax,
  foundIncr,
  forceMin,
}: {
  scaleMin: number;
  scaleMax: number;
  foundIncr: number;
  forceMin: boolean;
}): number[] {
  const splits = [];

  const numDec = fixedDec.get(foundIncr) || 0;

  scaleMin = forceMin
    ? scaleMin
    : roundDec(incrRoundUp(scaleMin, foundIncr), numDec);
  for (
    let val = scaleMin;
    val <= scaleMax;
    val = roundDec(val + foundIncr, numDec)
  ) {
    splits.push(Object.is(val, -0) ? 0 : val); // coalesces -0
  }

  return splits;
}

const getSoftLimit = (
  val: number,
  newVal: number,
  softLimit: number,
  mode: number,
  isMin: boolean,
) => {
  if (isMin) {
    const isValGreaterThanSoftLimit = val >= softLimit;
    if (isValGreaterThanSoftLimit) {
      if (mode === 1) return softLimit;
      if (mode === 3 && newVal <= softLimit) return softLimit;
      if (mode === 2 && newVal >= softLimit) return softLimit;
    }
    return Infinity;
  }

  const isValLessThanSoftLimit = val <= softLimit;
  if (isValLessThanSoftLimit) {
    if (mode === 1) return softLimit;
    if (mode === 3 && newVal >= softLimit) return softLimit;
    if (mode === 2 && newVal <= softLimit) return softLimit;
  }
  return -Infinity;
};

/**
 * Calculates the minimum and maximum scale values for a given range, considering various constraints and padding.
 *
 * @param {number} _min - The minimum value of the range.
 * @param {number} _max - The maximum value of the range.
 * @param {Object} cfg - The configuration object containing constraints and padding values.
 * @returns {Object} An object containing the calculated minimum and maximum scale values.
 * @returns {number} return.scaleMin - The calculated minimum scale value.
 * @returns {number} return.scaleMax - The calculated maximum scale value.
 *
 * @description
 * This function calculates the minimum and maximum scale values for a given range, considering various constraints such as
 * hard and soft limits, padding, and precision errors. It handles cases where the data is flat or has precision errors by
 * adjusting the delta and applying appropriate padding. The function ensures that the calculated scale values respect the
 * provided hard and soft limits and are rounded to avoid floating-point precision issues.
 */
const _rangeNum = ({
  _min,
  _max,
  cfg,
}: {
  _min: number;
  _max: number;
  cfg: any;
}): { scaleMin: number; scaleMax: number } => {
  const cmin = cfg.min;
  const cmax = cfg.max;

  let padMin = cmin.pad ?? 0;
  let padMax = cmax.pad ?? 0;

  const hardMin = cmin.hard ?? -Infinity;
  const hardMax = cmax.hard ?? Infinity;

  const softMin = cmin.soft ?? Infinity;
  const softMax = cmax.soft ?? -Infinity;

  const softMinMode = cmin.mode ?? 0;
  const softMaxMode = cmax.mode ?? 0;

  let delta = _max - _min;
  const deltaMag = Math.log10(delta);

  const scalarMax = Math.max(Math.abs(_min), Math.abs(_max));
  const scalarMag = Math.log10(scalarMax);

  const scalarMagDelta = Math.abs(scalarMag - deltaMag);

  // Handle precision errors and flat data
  if (delta < 1e-9 || scalarMagDelta > 10) {
    delta = 0;
    if (_min === 0 || _max === 0) {
      delta = 1e-9;
      if (softMinMode === 2 && softMin !== Infinity) padMin = 0;
      if (softMaxMode === 2 && softMax !== -Infinity) padMax = 0;
    }
  }

  const nonZeroDelta = delta || scalarMax || 1e3;
  const mag = Math.log10(nonZeroDelta);
  const base = Math.pow(10, Math.floor(mag));

  const _padMin = nonZeroDelta * (delta == 0 ? (_min == 0 ? 0.1 : 1) : padMin);
  const _newMin = roundDec(incrRoundDn(_min - _padMin, base / 10), 9);
  const _softMin = getSoftLimit(_min, _newMin, softMin, softMinMode, true);

  const _minLim = (() => {
    if (_newMin < _softMin && _min >= _softMin) return _softMin;
    return Math.min(_softMin, _newMin);
  })();

  const minLim = Math.max(hardMin, _minLim);

  const _padMax = nonZeroDelta * (delta == 0 ? (_max == 0 ? 0.1 : 1) : padMax);
  const _newMax = roundDec(incrRoundUp(_max + _padMax, base / 10), 9);
  const _softMax = getSoftLimit(_max, _newMax, softMax, softMaxMode, false);

  const _maxLim = (() => {
    if (_newMax > _softMax && _max <= _softMax) return _softMax;
    return Math.max(_softMax, _newMax);
  })();

  let maxLim = Math.min(hardMax, _maxLim);

  if (minLim === maxLim && minLim === 0) maxLim = 100;

  return { scaleMin: minLim, scaleMax: maxLim };
};

const rangeNum = ({
  minValue,
  maxValue,
  mult,
  extra,
}: {
  minValue: number;
  maxValue: number;
  mult: number;
  extra: boolean;
}) => {
  const _eqRangePart = { pad: 0, soft: null, mode: 0 };

  const _eqRange = { min: _eqRangePart, max: _eqRangePart };

  if (typeof mult === 'object') {
    return _rangeNum({ _min: minValue, _max: maxValue, cfg: mult });
  }

  _eqRangePart.pad = mult;
  _eqRangePart.soft = extra ? 0 : null;
  _eqRangePart.mode = extra ? 3 : 0;

  return _rangeNum({ _min: minValue, _max: maxValue, cfg: _eqRange });
};

export const getYAxisSplits = ({
  minValue,
  maxValue,
  forceMin,
  dimension,
  scaleDistribution,
  type,
}: {
  minValue: number;
  maxValue: number;
  forceMin: boolean;
  dimension: number;
  scaleDistribution: UplotChartStyles['scaleDistribution'];
  type: ChartType;
}): number[] => {
  if (isNil(minValue) || isNil(maxValue)) return [];

  const scaleConfig = getChartScaleConfig(scaleDistribution, type);
  const { scaleMin, scaleMax } = rangeNum({
    minValue,
    maxValue,
    mult: scaleConfig?.y?.range,
    extra: false,
  });
  const { foundIncr } = getIncrSpace(scaleMin, scaleMax, dimension);
  const splits = numAxisSplits({
    scaleMin,
    scaleMax,
    foundIncr,
    forceMin,
  });

  return splits;
};
