import {
  ElementType,
  Expression,
  FocusedElementInfo,
  Operator,
} from 'components/ExpressionBuilder/types';
import { SelectedFacetValuesByNameState } from 'types/facet';
import { first } from 'lodash';

const getOperator = (selectedFacetValues: {
  [key: string]: number;
}): Operator => {
  return first(Object.values(selectedFacetValues)) === 1 ? '=' : '!=';
};

export const toExpressions = (
  selectedFacetValuesByNameState: SelectedFacetValuesByNameState['state'],
): Array<Expression> =>
  Object.entries(selectedFacetValuesByNameState).map(
    ([facetName, selectedFacetValues]) => ({
      property: facetName,
      operator: getOperator(selectedFacetValues),
      values: Object.keys(selectedFacetValues),
    }),
  );

export const fromExpressions = (
  expressions: Array<Expression>,
): SelectedFacetValuesByNameState['state'] =>
  Object.fromEntries(
    expressions.map((expression) => [
      expression.property,
      Object.fromEntries(
        expression.values.map((v) => [v, expression.operator === '=' ? 1 : 0]),
      ),
    ]),
  );

export const getShouldShowAddOrClauseInfo = ({
  focusedElementInfo,
  getExpressionIfExists,
}: {
  focusedElementInfo: FocusedElementInfo | null;
  getExpressionIfExists: ({ index }: { index: number }) => Expression;
}): boolean => {
  if (!focusedElementInfo || focusedElementInfo.type !== ElementType.VALUE) {
    return false;
  }
  const { index, valueIndex } = focusedElementInfo;
  const expression = getExpressionIfExists({ index });
  const values = expression?.values || null;
  if (!values) {
    return false;
  }
  const value = values[valueIndex] || '';
  return value.length > 0 && valueIndex === values.length - 1;
};

export const getShouldShowOperatorsInfo = ({
  focusedElementInfo,
}: {
  focusedElementInfo: FocusedElementInfo | null;
}): boolean => {
  if (!focusedElementInfo) {
    return false;
  }
  if (
    [ElementType.OPERATOR, ElementType.INITIALIZER].includes(
      focusedElementInfo.type,
    )
  ) {
    return false;
  }
  return true;
};
