const chartingPaletteAlt01 = [
  '#54bebe',
  '#76c8c8',
  '#98d1d1',
  '#badbdb',
  '#dedad2',
  '#a86464',
  '#6d4b4b',
  '#503f3f',
  '#333333',
  '#3c4e4b',
  '#466964',
  '#599e94',
  '#6cd4c5',
];

export default chartingPaletteAlt01;
