import React, { useMemo } from 'react';
import { useKubernetesController } from '../KubernetesController';

import { CronJob, EntityTypes } from '../types';
import {
  KubeCellCluster,
  KubeCellStatus,
  KubeCellNamespace,
  KubeCellTimestamp,
  KubeTableCell,
} from './Components';
import KubernetesTable from './KubernetesTable';
import { useEntitiesCount, useEntitiesData } from './useEntitiesData';

export default function KubernetesTableForCronJob() {
  const { entitiesType, facets, page, limit } = useKubernetesController();

  const filters = useMemo(() => [facets], [facets]);
  const [rows] = useEntitiesData<{ cronJob: CronJob }>({
    filters,
    page,
    limit,
  });
  const [count] = useEntitiesCount({
    entitiesType,
    facets,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'kube_cron_job',
          label: 'Cron Job',
          renderCell(prop) {
            const cronJobName = prop.row.cronJob.metadata.name;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name"
                tooltipText={cronJobName}
              >
                {cronJobName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell(prop) {
            return <KubeCellCluster {...prop} />;
          },
        },
        {
          key: 'metadata.namespace',
          label: 'Namespace',
          renderCell(prop) {
            return <KubeCellNamespace {...prop} />;
          },
        },
        {
          key: 'metadata.creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            return (
              <KubeCellTimestamp
                {...prop}
                timestamp={prop.row.cronJob.metadata.creationTimestamp}
              />
            );
          },
        },
        {
          key: 'spec.schedule',
          label: 'Schedule',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop}>
                {prop.row.cronJob.spec.schedule}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'spec.suspend',
          label: 'Suspend',
          renderCell(prop) {
            const status =
              prop?.row?.cronJob?.spec.suspend === true
                ? 'Suspended'
                : 'Active';
            return <KubeCellStatus {...prop} status={status} />;
          },
        },
        {
          key: 'headerActiveJobs',
          label: 'Active Jobs',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.cronJob.status.active?.name || 0}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerKubeLabels',
          label: 'Kubernetes Labels',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop}>
                {prop.row.cronJob.metadata.labels ?? ''}
              </KubeTableCell>
            );
          },
        },
      ]}
    />
  );
}
