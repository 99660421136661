import React, { ReactElement, useMemo } from 'react';
import { QueryLangType } from 'types';

import { AlertsCreateDetection } from '../components';
import {
  thresholdAlertPopupMessage,
  outliersAlertPopupMessage,
  anomalyAlertPopupMessage,
  forecastAlertPopupMessage,
} from '../constants';
import { AlertType } from '../types';

const metricsAlertDetectionList = [
  { label: 'Threshold Alert', value: AlertType.THRESHOLD },
  { label: 'Outliers Alert', value: AlertType.OUTLIERS },
  { label: 'Anomaly Detection', value: AlertType.ANOMALY },
  { label: 'Forecast Alert', value: AlertType.FORECAST },
];

const detectionDescriptions = {
  threshold: {
    message: thresholdAlertPopupMessage.THRESHOLD_MESSAGE,
    title: thresholdAlertPopupMessage.THRESHOLD_TITLE,
    description: thresholdAlertPopupMessage.THRESHOLD_DESCRIPTION,
  },
  outliers: {
    message: outliersAlertPopupMessage.OUTLIERS_MESSAGE,
    title: outliersAlertPopupMessage.OUTLIERS_TITLE,
    description: outliersAlertPopupMessage.OUTLIERS_DESCRIPTION,
  },
  anomaly: {
    message: anomalyAlertPopupMessage.ANOMALY_MESSAGE,
    title: anomalyAlertPopupMessage.ANOMALY_TITLE,
    description: anomalyAlertPopupMessage.ANOMALY_DESCRIPTION,
  },
  forecast: {
    message: forecastAlertPopupMessage.FORECAST_MESSAGE,
    title: forecastAlertPopupMessage.FORECAST_TITLE,
    description: forecastAlertPopupMessage.FORECAST_DESCRIPTION,
  },
};

type Props = {
  selectedAlertType: AlertType;
  handleTabClick: (tab: string) => void;
  queryLangType: QueryLangType;
};

const AlertsCreateLogsDetection = ({
  selectedAlertType,
  handleTabClick,
  queryLangType,
}: Props): ReactElement => {
  const detectionTabs = useMemo(() => {
    if (queryLangType === QueryLangType.LOGQL) {
      return metricsAlertDetectionList.filter(
        (tab) =>
          tab.value !== AlertType.ANOMALY &&
          tab.value !== AlertType.OUTLIERS &&
          tab.value !== AlertType.FORECAST,
      );
    }
    return metricsAlertDetectionList;
  }, [queryLangType]);

  return (
    <AlertsCreateDetection
      activeDetection={selectedAlertType}
      detectionList={detectionTabs}
      detectionDescriptionMap={detectionDescriptions}
      setActiveDetection={handleTabClick}
    />
  );
};

export default AlertsCreateLogsDetection;
