import { useSubscriptionRequest } from 'hooks';
import React, { useRef, useMemo, ReactElement } from 'react';
import { v4 as uuidV4 } from 'uuid';

import LogsTimelineChart from './LogsTimelineChart';
import getTimeline from './getTimeline';
import useCompareTimeline from './useCompareTimeline';
import { useLogsState, useQueryScheduler } from '../hooks';
import { getBucketSecs } from '../utils';
import useDebouncedEffect from 'use-debounced-effect';

const LogsTimelineInner = ({
  activeQueryIndex,
  getLogStackedBarCountsUsingMetricsRequest,
  hideTimeline,
  hoveredLogDateUnix,
  logsState,
  queryScheduler,
  selectedLog,
  showTimelineToggle,
  width,
}: {
  activeQueryIndex: number;
  getLogStackedBarCountsUsingMetricsRequest: ReturnType<
    typeof useSubscriptionRequest
  >;
  hideTimeline: boolean;
  hoveredLogDateUnix: number;
  logsState: ReturnType<typeof useLogsState>;
  queryScheduler: ReturnType<typeof useQueryScheduler>;
  selectedLog: any;
  showTimelineToggle: any;
  width: number;
}): ReactElement => {
  const compareTimeline = useCompareTimeline(logsState, width);
  const elementRef = useRef<HTMLDivElement>();

  const { date, setDateZoomed } = logsState;

  useDebouncedEffect(
    () => {
      if (width) {
        if (
          queryScheduler.firstQueryCompletedAt &&
          !queryScheduler.zoomHasBeenUpdated
        ) {
          const bucketSecs = getBucketSecs(date, width);
          const callId = queryScheduler.callIdRef.current;
          getLogStackedBarCountsUsingMetricsRequest
            .call({
              bucketSecs,
              logsState,
            })
            .then(() => {
              if (callId === queryScheduler.callIdRef.current) {
                queryScheduler.setSecondQueryCompletedAt();
              }
            });

          compareTimeline.reset();
        }
      }
    },
    { timeout: 400, ignoreInitialCall: false },
    [queryScheduler.firstQueryCompletedAt, activeQueryIndex],
  );

  const logCounts = getLogStackedBarCountsUsingMetricsRequest.result || [];

  const timeline = useMemo(
    () => getTimeline(date, logCounts, selectedLog, width),
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    [getLogStackedBarCountsUsingMetricsRequest.result, selectedLog],
  );

  return (
    <LogsTimelineChart
      compareTimeline={compareTimeline}
      date={date}
      elementRef={elementRef}
      hideTimeline={hideTimeline}
      isLoadingLogCounts={false}
      setDateZoomed={setDateZoomed}
      showTimelineToggle={showTimelineToggle}
      timeline={timeline}
    />
  );
};

export default LogsTimelineInner;
