/**
 * Determines the edges of a hexagon that should be drawn on the second to last row of a grid.
 * @param {Object} params - The function parameters.
 * @param {number} params.column - The column index of the hexagon.
 * @param {number} params.columns - The total number of columns in the grid.
 * @param {boolean} params.isRowEven - A boolean indicating whether the row index is even.
 * @param {number} params.numberOfHexagon - The total number of hexagons in the grid.
 * @param {number} params.nextLastRow - The index of the second to last row in the grid.
 *
 * @returns {Object} An object containing two properties:
 * - isEdge: A boolean indicating whether the hexagon is on the edge of the grid.
 * - edges: An array of numbers representing the edges of the hexagon that should be drawn.
 */
const getHexagonLastSecondRowEdges = ({
  column,
  columns,
  isRowEven,
  numberOfHexagon,
  nextLastRow,
  row,
}: {
  column: number;
  columns: number;
  isRowEven: boolean;
  numberOfHexagon: number;
  nextLastRow: number;
  row: number;
}): { isEdge: boolean; edges: number[] } => {
  const isLastRowEven = nextLastRow % 2 === 0;
  const lastRowColumnLength =
    (numberOfHexagon % columns) - (isLastRowEven ? 0 : 1);
  if (column < lastRowColumnLength) return null;

  const isLastColumn = column === columns - 1;
  const isLastColumnInLastRow = column === lastRowColumnLength;

  if (isLastColumn) {
    if (row === 0) {
      return { isEdge: true, edges: [1, 4, 5, 6] };
    }

    if (isLastColumnInLastRow) {
      return { isEdge: true, edges: [1, 6] };
    }

    const edges = isRowEven ? [1, 2, 6] : [1, 2, 5, 6];
    return { isEdge: true, edges };
  }

  const edges = isLastColumnInLastRow ? [1] : [1, 2];
  return { isEdge: true, edges };
};

/**
 * This function determines which edges of a hexagon should be drawn, based on the hexagon's position in a grid.
 *
 * @param {Object} params - An object containing the following properties:
 *    - column: The column index of the hexagon in the grid.
 *    - columns: The total number of columns in the grid.
 *    - index: The index of the hexagon in the grid.
 *    - numberOfHexagon: The total number of hexagons in the grid.
 *    - row: The row index of the hexagon in the grid.
 *
 * @returns {Object} An object containing the following properties:
 *    - isEdge: A boolean indicating whether the hexagon is on the edge of the grid.
 *    - edges: An array of integers representing the edges of the hexagon that should be drawn. The edges are numbered from 1 to 6, starting from the top edge and going clockwise.
 */
export const getDrawableHexagonEdges = ({
  column,
  columns,
  index,
  numberOfHexagon,
  row,
}: {
  column: number;
  columns: number;
  index: number;
  numberOfHexagon: number;
  row: number;
}): { isEdge: boolean; edges: number[] } => {
  const rows = Math.ceil(numberOfHexagon / columns);
  const isOnLastColumn = column === columns - 1;
  const isOnFirstColumn = column === 0;
  const isOnFirstRow = row === 0;
  const isOnLastHexagon = index === numberOfHexagon - 1;
  const isOnLastRowOfHexagon = row === rows - 1;
  const isRowEven = row % 2 === 0;

  if (numberOfHexagon === 1) {
    return { isEdge: true, edges: [1, 2, 3, 4, 5, 6] };
  }

  if (rows === 1) {
    if (isOnFirstColumn) {
      return { isEdge: true, edges: [1, 2, 3, 4, 5] };
    } else if (isOnLastColumn) {
      return { isEdge: true, edges: [1, 2, 4, 5, 6] };
    }
    return { isEdge: true, edges: [1, 2, 4, 5] };
  }

  const nextLastRow = rows - 2;
  if (rows > 1 && numberOfHexagon % columns !== 0 && row === nextLastRow) {
    const secondLastEdges = getHexagonLastSecondRowEdges({
      column,
      columns,
      isRowEven,
      numberOfHexagon,
      nextLastRow,
      row,
    });

    if (secondLastEdges) return secondLastEdges;
  }

  if (isOnLastHexagon && isOnLastRowOfHexagon && isOnFirstColumn) {
    const edges = isRowEven ? [1, 2, 3, 4, 6] : [1, 2, 3, 6];
    return { isEdge: true, edges };
  }

  if (isOnLastHexagon) {
    const edges = isRowEven || !isOnLastColumn ? [1, 2, 6] : [1, 2, 5, 6];
    return { isEdge: true, edges };
  }

  // Hexagon is on the top left corner
  if (isOnFirstColumn && isOnFirstRow) {
    return { isEdge: true, edges: [2, 3, 4, 5] };
  }

  // Hexagon is on the bottom left corner
  if (isOnFirstColumn && isOnLastRowOfHexagon) {
    const edges = isRowEven ? [1, 2, 3, 4] : [1, 2, 3];
    return { isEdge: true, edges };
  }

  // Hexagon is on the top right corner
  if (isOnLastColumn && isOnFirstRow) {
    return { isEdge: true, edges: [4, 5, 6] };
  }

  // Hexagon is on the bottom right corner
  if (isOnLastColumn && isOnLastRowOfHexagon) {
    return { isEdge: true, edges: [1, 5, 6] };
  }

  if (isOnFirstColumn) {
    const edges = isRowEven ? [2, 3, 4] : [3];
    return { isEdge: true, edges };
  }

  if (isOnLastColumn) {
    const edges = isRowEven ? [6] : [1, 5, 6];
    return { isEdge: true, edges };
  }

  // Hexagon is on middle and on top
  if (column !== 0 && isOnFirstRow) {
    return { isEdge: true, edges: [4, 5] };
  }

  // Hexagon is on middle and on bottom
  if (column !== 0 && isOnLastRowOfHexagon) {
    return { isEdge: true, edges: [1, 2] };
  }

  return { isEdge: false, edges: [] };
};
