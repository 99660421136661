import { DataFrame } from '../types';

type SortAndLimitTimeseriesProps = {
  direction: 'topk' | 'bottomk';
  count?: string;
};

export const sortAndLimitTimeseries = (
  dataFrame: DataFrame,
  limit: SortAndLimitTimeseriesProps,
): DataFrame => {
  const { data, ...rest } = dataFrame;
  if (!data) return dataFrame;

  const transformedData = sortLogsDatasetByAverage(data, limit);
  return { data: transformedData, ...rest };
};

const sortLogsDatasetByAverage = (
  data: DataFrame['data'],
  limit: SortAndLimitTimeseriesProps,
): DataFrame['data'] => {
  if (!limit) return data;
  const { direction, count } = limit;
  const sortedDatasetByAverage = data.sort((a, b) => {
    const pointA = a.values;
    const pointB = b.values;
    const sumA = pointA.reduce((pre, cur) => pre + (cur ? Number(cur) : 0), 0);
    const sumB = pointB.reduce((pre, cur) => pre + (cur ? Number(cur) : 0), 0);
    const length = pointA.length;
    const comp = sumA / length > sumB / length;
    if (comp && direction === 'topk') return -1;
    if (!comp && direction === 'topk') return 1;
    if (comp && direction === 'bottomk') return 1;
    if (!comp && direction === 'bottomk') return -1;
  });
  if (!count) return sortedDatasetByAverage;
  return sortedDatasetByAverage.slice(0, Number(count));
};

export const sortAndLimitTimeseriesMetrics = (
  data: DataFrame[],
  limit: SortAndLimitTimeseriesProps,
): DataFrame[] => {
  if (!data) return undefined;

  const newData = data[0];
  if (!newData) return data;
  const transformedData = sortLogsDatasetByAverage(newData.data, limit);

  return [{ ...newData, data: transformedData }];
};
