import { DateFormatter } from 'components';
import { startCase } from 'lodash';
import React from 'react';
import { dateTimeFormatWithMilliseconds } from 'kfuse-constants';
import { formatDurationNs, isNullOrUndefined } from 'utils';
import { RumSessionEvent } from '../types';
import sidebarFacets from '../requests/sidebarFacets';
import isDurationFacet from '../utils/isDurationFacet';

const sessionColumns = sidebarFacets
  .filter(
    (facet) => facet.session_facet && facet.field !== 'session.has_replay',
  )
  .map((facet) => {
    return {
      key: facet.field,
      label: facet.name,
    };
  })
  .sort((a, b) => a.label.localeCompare(b.label));

const RumSessionStringColumnKey = sessionColumns.reduce(
  (acc, column) => {
    acc[column.key] = column.label as string;
    return acc;
  },
  {} as Record<string, string>,
);

export const RumSessionColumnKey = {
  date: 'time',
  'application.id': 'Application ID',
  ...RumSessionStringColumnKey,
};

type RenderCellPropsOfSession = {
  row: RumSessionEvent;
  value: any;
};

export const rumDynamicSessionTableColumns = () => [
  {
    key: RumSessionColumnKey.date,
    label: 'Date',
    renderCell: ({ row, value }: RenderCellPropsOfSession) => {
      return (
        <DateFormatter
          formatString={dateTimeFormatWithMilliseconds}
          unixTimestamp={value}
        />
      );
    },
  },
  ...Object.keys(RumSessionStringColumnKey).map((key) => {
    if (isDurationFacet(key as keyof typeof isDurationFacet)) {
      return {
        key,
        label: startCase(
          RumSessionStringColumnKey[
            key as keyof typeof RumSessionStringColumnKey
          ],
        ),
        value: ({ row }: RenderCellPropsOfSession) => {
          const value = Number(row.data[key as keyof typeof row.data]);
          return isNaN(value) || isNullOrUndefined(value)
            ? '-'
            : formatDurationNs(value, 1, 2);
        },
      };
    }
    return {
      key,
      label: startCase(
        RumSessionStringColumnKey[
          key as keyof typeof RumSessionStringColumnKey
        ],
      ),
      value: ({ row }: RenderCellPropsOfSession) =>
        row.data[key as keyof typeof row.data],
    };
  }),
];
