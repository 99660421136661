import { AutocompleteOption } from 'components';
import { useRequest } from 'hooks';
import { useState } from 'react';
import { metricsSeriesCardinality, promqlLabelValues } from 'requests';
import { DateSelection } from 'types/DateSelection';

const useLabelValuePicker = () => {
  const [labelListMap, setLabelListMap] = useState<{
    [key: string]: { data: AutocompleteOption[]; isLoading: boolean };
  }>({});
  const [labelValueListMap, setLabelValueListMap] = useState<{
    [key: string]: { data: AutocompleteOption[]; isLoading: boolean };
  }>({});

  const labelListRequest = useRequest(
    (args) =>
      metricsSeriesCardinality(args).then((res) => {
        if (!res || Array.isArray(res)) return [];
        const seriesCardinalityKeys = Object.keys(res.labelNames);
        return seriesCardinalityKeys.map((metric) => ({
          label: metric,
          value: metric,
        }));
      }),
    true,
    true,
  );
  const labelValueListRequest = useRequest(
    (args) =>
      promqlLabelValues(args).then((res) => {
        if (!res) return [];
        return res.map((l: string) => ({ label: l, value: l }));
      }),
    true,
    true,
  );

  const loadLabelList = ({
    date,
    matcher,
  }: {
    date: DateSelection;
    matcher: string;
  }) => {
    if (labelListMap[matcher]) return;

    setLabelListMap((prev) => ({
      ...prev,
      [matcher]: { data: [], isLoading: true },
    }));
    labelListRequest.call({ date, matcher }).then((res) => {
      setLabelListMap((prev) => ({
        ...prev,
        [matcher]: { data: res, isLoading: false },
      }));
    });
  };

  const loadLabelValueList = ({
    date,
    label,
    matcher,
  }: {
    date: DateSelection;
    label: string;
    matcher: string;
  }) => {
    if (labelValueListMap[matcher]) return;

    setLabelValueListMap((prev) => ({
      ...prev,
      [label]: { data: [], isLoading: true },
    }));
    labelValueListRequest.call({ date, label, matcher }).then((res) => {
      setLabelValueListMap((prev) => ({
        ...prev,
        [label]: { data: res, isLoading: false },
      }));
    });
  };

  return {
    labelListMap,
    labelValueListMap,
    loadLabelList,
    loadLabelValueList,
    setLabelListMap,
    setLabelValueListMap,
  };
};

export default useLabelValuePicker;
