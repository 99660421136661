import classNames from 'classnames';
import { TooltipTrigger } from 'components';
import React, { ReactElement } from 'react';
import SLOCreateNumerator from '../SLOCreateNumerator';
import { useCreateSLOState } from 'screens/SLO/hooks';
import SLOCreateDenominator from '../SLOCreateDenominator';

const detectionList = [
  { label: 'Latency', value: 'latency' },
  { label: 'Availability', value: 'availability' },
  { label: 'Metric', value: 'metrics' },
];

const SLODetection = ({
  createSLOState,
  activeDetection,
  isEditMode,
  setActiveDetection,
}: {
  activeDetection: string;
  createSLOState: ReturnType<typeof useCreateSLOState>;
  isEditMode?: boolean;
  setActiveDetection: (activeDetection: string) => void;
}): ReactElement => {
  return (
    <div>
      <TooltipTrigger
        tooltip={isEditMode ? 'Detection Method cannot be edited' : ''}
        className={classNames({
          'slo__type--disabled': isEditMode,
        })}
      >
        <div className="alerts__create__metrics__detection__tabs">
          {detectionList.map(({ label, value }) => (
            <button
              className={classNames({
                alerts__tabs__item: true,
                'alerts__tabs__item--active': value === activeDetection,
                'slo__type--disabled': isEditMode,
              })}
              key={label}
              disabled={isEditMode}
              onClick={() => setActiveDetection(value)}
            >
              {label}
            </button>
          ))}
        </div>
      </TooltipTrigger>
    </div>
  );
};

export default SLODetection;
