import { Filter, FilterType, useFiltersState } from 'hooks';
import getFacetValueTag from './getFacetValueTag';
import React from 'react';
import defaultGetRangeTag from 'hooks/useFiltersState/getTags/defaultGetRangeTag';
import getFacetRegexTag from 'hooks/useFiltersState/getTags/getFacetRegexTag';
import { parseFacetKey } from 'utils/facets';

type Args = {
  filtersState: ReturnType<typeof useFiltersState>;
  idSearch?: string;
  setIdSearch: (idSearch: string) => void;
};

type GetTagAndLabelTextArgs = {
  renderFacet?: (facet: string) => string;
  filter: Filter;
};

const getMeta = (filter: Filter) => {
  const { type } = filter;
  switch (type) {
    case FilterType.selectedRange:
      const parsedFacet = parseFacetKey(filter.value.facet);
      return {
        displayName: parsedFacet.displayName || parsedFacet.name,
        facetName: parsedFacet.name,
        type: parsedFacet.type,
      };
    default:
      return {};
  }
};

const getTagLabelAndText = ({
  renderFacet,
  filter,
}: GetTagAndLabelTextArgs) => {
  const { type } = filter;
  switch (type) {
    case FilterType.facetRegex:
      return getFacetRegexTag(filter);
    case FilterType.selectedRange:
      return defaultGetRangeTag({
        filter,
        renderFacet,
        getMeta,
      });
    case FilterType.selectedFacetValue:
      return getFacetValueTag({
        selectedFacetValueFilter: filter,
      });
    default:
      return {
        label: null as null,
        text: '',
      };
  }
};

type GetTagArgs = {
  deleteByIndex: (i: number) => void;
  renderFacet?: (facet: string) => string;
};

const getTag =
  ({ deleteByIndex, renderFacet }: GetTagArgs) =>
  (filter: Filter, i: number) => {
    const onRemove = () => {
      deleteByIndex(i);
    };

    return {
      ...getTagLabelAndText({ filter, renderFacet }),
      onRemove,
    };
  };

const getTags = ({ filtersState, idSearch, setIdSearch }: Args) => {
  const { deleteByIndex } = filtersState;

  const renderFacet = (facet: string) => {
    const parsedFacet = parseFacetKey(facet);
    return parsedFacet.displayName || parsedFacet.name;
  };
  const tags = filtersState.state.map(getTag({ deleteByIndex, renderFacet }));

  if (idSearch) {
    const label = (
      <>
        <span className="rum-search__input__trigger__tag__name">
          {'View ID'}
        </span>
        <span>:</span>
        <span className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          {idSearch}
        </span>
      </>
    );

    tags.push({
      name: 'View ID',
      label,
      onRemove: () => {
        setIdSearch('');
      },
      text: idSearch,
    });
  }

  return tags;
};

export default getTags;
