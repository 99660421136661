import { Alert, Loader, RumReplay } from 'components';
import { useRequest } from 'hooks';
import React, { useEffect } from 'react';
import { getRrwebSegmentsMetadata } from './requests';
import { RawSegmentMetadata } from './requests/getRrwebSegmentsMetadata';
import getRumEvents from './requests/getRumEvents';
import { RumEventType } from './types';

const getTabSnapshot = (segments: RawSegmentMetadata[]) => {
  const startTimeUnixMs = segments[0].startTs;
  const endTimeUnixMs = segments[segments.length - 1].endTs;

  const snapshot = {
    startTimeUnixMs,
    endTimeUnixMs,
    segments: segments,
  };

  return snapshot;
};

const getTabSnapshots = (segments: RawSegmentMetadata[]) => {
  const result = [];
  let fullSnapshopIndex: number = null;

  segments.forEach((segment, index) => {
    const isLast = index === segments.length - 1;

    if (isLast) {
      if (segment.firstFullSnapshotTs) {
        const snapshot = {
          startTimeUnix: segment.startTs,
          endTimeUnix: segment.endTs,
          segments: [segment],
        };
        result.push(snapshot);
        return;
      }

      const snapshotSegments = segments.slice(fullSnapshopIndex, index + 1);
      const snapshot = getTabSnapshot(snapshotSegments);
      result.push(snapshot);
      return;
    }

    if (segment.firstFullSnapshotTs) {
      if (typeof fullSnapshopIndex === 'number') {
        const snapshotSegments = segments.slice(fullSnapshopIndex, index);
        if (snapshotSegments.length) {
          const snapshot = getTabSnapshot(snapshotSegments);
          result.push(snapshot);
        }
      }

      fullSnapshopIndex = index;
    }
  });

  return result;
};

type Props = {
  applicationId: string;
  sessionId: string;
  startReplayAtUnixMs?: number;
};

const RumActiveReplay = ({
  applicationId,
  sessionId,
  startReplayAtUnixMs,
}: Props) => {
  const getRumEventsRequest = useRequest(({ startTimeUnix, endTimeUnix }) => {
    return Promise.all(
      [
        // RumEventType.SESSION,
        RumEventType.ACTION,
        // RumEventType.LONGTASK,
        // RumEventType.RESOURCE,
        RumEventType.VIEW,
        RumEventType.ERROR,
      ].map((eventType) =>
        getRumEvents({
          limit: 2000,
          startTimeUnix,
          endTimeUnix,
          eventType,
          selectedFacetValuesByName: {
            'session.id': {
              [sessionId]: 1,
            },
            'application.id': {
              [applicationId]: 1,
            },
          },
          selectedFacetRangeByName: {},
        }).then((result) =>
          (result.data || []).map((event) => ({ ...event, eventType })),
        ),
      ),
    ).then((result) =>
      result
        .reduce((arr, events) => [...arr, ...events], [])
        .sort((a, b) => a.time - b.time),
    );
  });

  const getRrwebSessionMetadataRequest = useRequest((args) =>
    getRrwebSegmentsMetadata(args).then((session) => {
      if (!session.tabs.length) {
        return { tabs: [], startTimeUnixMs: 0, endTimeUnixMs: 0 };
      }

      const tabs = session.tabs
        .sort((a, b) => a.startTs - b.startTs)
        .map((tab) => {
          return {
            ...tab,
            startTimeUnixMs: tab.startTs,
            endTimeUnixMs: tab.endTs,
            segments: tab.segments.map((segment) => ({
              ...segment,
              startTimeUnixMs: segment.startTs,
              endTimeUnixMs: segment.endTs,
              firstFullSnapshotTimeUnixMs: segment.firstFullSnapshotTs,
            })),
            snapshots: getTabSnapshots(tab.segments),
          };
        });

      const startTimeUnixMs = Math.min(
        ...tabs.map((tab) => tab.startTimeUnixMs),
      );
      const endTimeUnixMs = Math.max(...tabs.map((tab) => tab.endTimeUnixMs));

      getRumEventsRequest.call({
        startTimeUnix: Math.floor(startTimeUnixMs / 1000),
        endTimeUnix: Math.ceil(endTimeUnixMs / 1000),
      });

      return { tabs, startTimeUnixMs, endTimeUnixMs };
    }),
  );

  useEffect(() => {
    getRrwebSessionMetadataRequest.call({ applicationId, sessionId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Loader
      className="rum__active-replay"
      isLoading={
        getRrwebSessionMetadataRequest.isLoading ||
        getRumEventsRequest.isLoading
      }
    >
      {getRrwebSessionMetadataRequest.result?.tabs &&
      getRrwebSessionMetadataRequest.result.tabs.length === 0 ? (
        <div className="p-5">
          <Alert
            description="Replay not yet available for this session"
            icon="alert-circle"
          />
        </div>
      ) : null}
      {getRrwebSessionMetadataRequest.result && getRumEventsRequest.result ? (
        <RumReplay
          events={getRumEventsRequest.result}
          session={getRrwebSessionMetadataRequest.result}
          startReplayAtUnixMs={startReplayAtUnixMs}
        />
      ) : null}
    </Loader>
  );
};

export default RumActiveReplay;
