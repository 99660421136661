import React, { useMemo } from 'react';
import { ingressRules } from '../utils/selectorsfunction';

import { Ingress } from '../types';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellTimestamp,
  KubeTableCell,
} from './Components';
import KubernetesTable from './KubernetesTable';
import { useEntitiesCount, useEntitiesData } from './useEntitiesData';

export default function KubernetesTableForIngress() {
  const { entitiesType, facets, page, limit } = useKubernetesController();
  const filters = useMemo(() => [facets], [facets]);
  const [rows] = useEntitiesData<{ ingress: Ingress }>({
    filters,
    page,
    limit,
  });
  const [count] = useEntitiesCount({
    entitiesType,
    facets,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'kube_ingress',
          label: 'Ingress',
          renderCell(prop) {
            const ingressName = prop.row.ingress.metadata.name;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name pb-1.5 pt-1.5"
                tooltipText={ingressName}
              >
                {ingressName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell(prop) {
            return <KubeCellCluster {...prop} />;
          },
        },
        {
          key: 'metadata.namespace',
          label: 'Namespace',
          renderCell(prop) {
            return <KubeCellNamespace {...prop} />;
          },
        },

        {
          key: 'spec.ingressClassName',
          label: 'Class',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="left">
                {prop.row.ingress.spec.ingressClassName || '-'}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerRule',
          label: 'Rules',
          renderCell(prop) {
            const ingressRule = ingressRules(prop.row.ingress);
            return (
              <KubeTableCell
                {...prop}
                align="left"
                style={{ maxWidth: 260 }}
                className="kubernetes__table__cell__entity-name"
                tooltipText={ingressRule}
              >
                {ingressRule}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'metadata.creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            return (
              <KubeCellTimestamp
                {...prop}
                timestamp={prop.row.ingress.metadata.creationTimestamp}
              />
            );
          },
        },
      ]}
    />
  );
}
