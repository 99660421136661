import React, { createContext, ReactNode, useContext, useState } from 'react';

interface DashboardGridlineContextProps {
  layout: ReactGridLayout.Layout;
}

interface DashboardGridlineContextValue {
  gridlineState: DashboardGridlineContextProps | null;
  setGridlineState: React.Dispatch<
    React.SetStateAction<DashboardGridlineContextProps | null>
  >;
  isDragging: boolean;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardGridlineContext = createContext<
  DashboardGridlineContextValue | undefined
>(undefined);

export const DashboardGridlineProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [gridlineState, setGridlineState] =
    useState<DashboardGridlineContextProps | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  return (
    <DashboardGridlineContext.Provider
      value={{ gridlineState, setGridlineState, isDragging, setIsDragging }}
    >
      {children}
    </DashboardGridlineContext.Provider>
  );
};

/**
 * Custom hook to use the dashboard gridline context.
 * This hook returns the current value of the DashboardGridlineContext.
 * It should be used within a component that is a child of DashboardGridlineProvider.
 * If it's used outside of a DashboardGridlineProvider, it will throw an error.
 *
 * @returns The current value of the DashboardGridlineContext, or throws an error if the context is undefined.
 */
export const useDashboardGridlineContext =
  (): DashboardGridlineContextValue => {
    const context = useContext(DashboardGridlineContext);
    if (!context) {
      throw new Error(
        'useDashboardGridlineContext must be used within a DashboardGridlineProvider',
      );
    }
    return context;
  };
