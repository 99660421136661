import { defaultAnalyticsQuery } from '../utils';
import { useUrlState } from 'hooks';

const useEventsAnalytics = () => {
  const [analyticsQuery, setAnalyticsQuery] = useUrlState(
    'analyticsQuery',
    defaultAnalyticsQuery,
  );

  return {
    analyticsQuery,
    setAnalyticsQuery,
  };
};

export default useEventsAnalytics;
