import {
  ChartToolbar,
  TimeseriesRenderer,
  TooltipTrigger,
  useCursorContextState,
  useThemeContext,
} from 'components';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { MdLegendToggle } from 'react-icons/md';
import { DateSelection, LayoutType, LegendTypes } from 'types';
import {
  combineRangeQueryData,
  drawForecastSeriesByIndex,
  drawForecastVerticalLine,
  getForecastLookbackAndPredictDate,
} from 'utils';

import {
  ConditionProps,
  ForecastConditionProps,
} from '../AlertsCreateCondition';
import useAlertsForecastDataLoader from './useAlertsForecastDataLoader';
import { getForecastKfuseqlForLoad } from '../AlertsCreateLogs/utils';
import { AlertAnomalyQueryItem } from '../types';
import { FORECAST_OPTION_UNSELECTED_ERROR_MESSAGE } from '../constants/alertsConstants';

const AlertChartRightToolbar = ({
  isActive,
  onClick,
}: {
  isActive: boolean;
  onClick: () => void;
}) => {
  return (
    <div className="new-metrics__chart__right-toolbar__icon" onClick={onClick}>
      <TooltipTrigger tooltip={`${isActive ? 'Hide' : 'Show'} Legends`}>
        <MdLegendToggle />
      </TooltipTrigger>
    </div>
  );
};

const defaultChartTypes = ['Line'];

const AlertsCreateLogsChartForecastSplit = ({
  baseWidth,
  condition,
  dateForChart,
  chartLayoutType,
  date,
  forecastCondition,
  isChartCompact,
  queryItem,
  queryKey,
  getQueryMappings,
  unit,
}: {
  baseWidth: number;
  dateForChart: ReturnType<typeof getForecastLookbackAndPredictDate>;
  condition: ConditionProps;
  chartLayoutType?: LayoutType;
  date: DateSelection;
  forecastCondition: ForecastConditionProps;
  isChartCompact: boolean;
  queryItem: AlertAnomalyQueryItem;
  queryKey: string;
  getQueryMappings: {
    formulas: { queryKey: string; isActive: boolean }[];
    queries: { queryKey: string; isActive: boolean }[];
  };
  unit: string;
}): ReactElement => {
  const { darkModeEnabled } = useThemeContext();

  const { cursorState, setCursorState } = useCursorContextState();
  const forecastDataLoader = useAlertsForecastDataLoader({
    condition,
    forecastCondition,
  });
  const {
    breakpoint,
    evaluationData,
    evalLegendToggle,
    loadHistoricalDataLogs,
    loadForecastDataLogs,
    historicalData,
    histLegendToggle,
  } = forecastDataLoader;

  const { seasonality, forecastAlgorithm, forecastDuration } =
    forecastCondition;

  const kfuseqlWithForecast = useMemo(() => {
    if (!dateForChart) return;
    const kfuseqlForecast = getForecastKfuseqlForLoad({
      kfuseQlOperation: queryItem.kfuseQlOperation,
      kfuseql: queryItem.kfuseql,
      forecastCondition,
      step: dateForChart.step,
      band: '3',
    });
    return {
      ...queryItem,
      kfuseqlForecast,
      forecastSeasonality: forecastAlgorithm === 'seasonal' ? seasonality : '',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryItem, dateForChart]);

  useEffect(() => {
    if (!kfuseqlWithForecast) return;
    const { kfuseql } = kfuseqlWithForecast;
    if (!kfuseql || typeof kfuseql !== 'string') return;
    loadHistoricalDataLogs({
      dateForChart,
      kfuseqlWithMeta: kfuseqlWithForecast,
    });

    loadForecastDataLogs({
      dateForChart,
      kfuseqlWithMeta: kfuseqlWithForecast,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastCondition, queryItem?.kfuseql]);

  const histCombinedData = useMemo(() => {
    return combineRangeQueryData({
      ...getQueryMappings,
      queryData: historicalData,
      darkModeEnabled,
    });
  }, [historicalData, darkModeEnabled, getQueryMappings]);

  const evalCombinedData = useMemo(() => {
    return combineRangeQueryData({
      ...getQueryMappings,
      queryData: evaluationData,
      darkModeEnabled,
    });
  }, [evaluationData, darkModeEnabled, getQueryMappings]);

  const forecastHooks = useMemo(() => {
    if (!evaluationData || evalCombinedData?.series?.length === 0) {
      return [];
    }

    const hooks = [
      {
        type: 'draw',
        hook: (u: uPlot) => {
          drawForecastVerticalLine({
            u,
            darkModeEnabled,
            forecastDuration,
            breakPoint: breakpoint,
          });
        },
      },
      {
        type: 'drawSeries',
        hook: (u: uPlot, seriesIndex: number) => {
          drawForecastSeriesByIndex({ u, seriesIndex, breakPoint: breakpoint });
        },
      },
    ];

    if (evalCombinedData?.hooks[2]) {
      hooks.push(evalCombinedData.hooks[2]);
    }
    return hooks;
  }, [
    evaluationData,
    evalCombinedData,
    darkModeEnabled,
    forecastDuration,
    breakpoint,
  ]);

  return (
    <div className="alerts__chart__forecast">
      <TimeseriesRenderer
        chartData={histCombinedData}
        chartTypes={defaultChartTypes}
        cursorState={cursorState}
        date={date || null}
        isLoading={histCombinedData.isLoading}
        chartKey="alerts-chart"
        legend={{
          legendType: histLegendToggle.value
            ? LegendTypes.COMPACT_ONE_LINE
            : 'none',
          legendHeight: 120,
        }}
        chartLayoutType={chartLayoutType}
        onCursorStateChange={setCursorState}
        size={{
          height: isChartCompact ? 220 : 280,
          width: baseWidth / 2 - 32,
        }}
        renderToolbar={({ activeChart, setActiveChart }) => (
          <ChartToolbar
            activeChart={activeChart}
            chartTypes={['Line']}
            setActiveChart={setActiveChart}
            toolbar={{
              rightToolbar: (
                <AlertChartRightToolbar
                  isActive={histLegendToggle.value}
                  onClick={() => histLegendToggle.toggle()}
                />
              ),
              leftToolbar: <div className="text--h3">Historical View</div>,
            }}
          />
        )}
        tooltipType="compact"
        unit={unit}
      />
      {forecastAlgorithm ? (
        <TimeseriesRenderer
          bands={evalCombinedData.bands || []}
          chartData={evalCombinedData}
          chartTypes={defaultChartTypes}
          cursorState={cursorState}
          date={date || null}
          isLoading={evalCombinedData.isLoading}
          chartKey="forecast-chart"
          legend={{
            legendType: evalLegendToggle.value
              ? LegendTypes.COMPACT_ONE_LINE
              : 'none',
            legendHeight: 120,
          }}
          chartLayoutType={chartLayoutType}
          onCursorStateChange={setCursorState}
          hooks={forecastHooks}
          size={{
            height: isChartCompact ? 220 : 280,
            width: baseWidth / 2 - 32,
          }}
          renderToolbar={({ activeChart, setActiveChart }) => (
            <ChartToolbar
              activeChart={activeChart}
              chartTypes={['Line']}
              setActiveChart={setActiveChart}
              toolbar={{
                rightToolbar: (
                  <AlertChartRightToolbar
                    isActive={evalLegendToggle.value}
                    onClick={() => evalLegendToggle.toggle()}
                  />
                ),
                leftToolbar: <div className="text--h3">Forecast View</div>,
              }}
            />
          )}
          tooltipType="compact"
          unit={unit}
        />
      ) : (
        <div className="text--h4 flex px-4">
          {FORECAST_OPTION_UNSELECTED_ERROR_MESSAGE}
        </div>
      )}
    </div>
  );
};

export default AlertsCreateLogsChartForecastSplit;
