import { fetchGrafanaApi } from 'requests';

import { GroupMemberProps } from '../types';

const getGroupMembersById = async ({
  groupId,
}: {
  groupId: string;
}): Promise<GroupMemberProps[]> => {
  const result = await fetchGrafanaApi(`rbac/groups/${groupId}/users`);

  return result || [];
};

export default getGroupMembersById;
