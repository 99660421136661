import {
  Loader,
  OverlayMessage,
  OverlayMessageProps,
  PopoverPosition,
  PopoverTriggerV2,
  RightSidebar,
  Table,
} from 'components';
import dayjs from 'dayjs';
import { useRequest } from 'hooks';
import { colorsByLogLevel } from 'kfuse-constants';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Settings } from 'react-feather';
import { eventsData } from 'requests';
import {
  EventListProps,
  FilterMethodByType,
  SelectedFacetValuesByName,
} from 'types';
import { convertArrayToObject } from 'utils';

import useTextSelectionVsClick from 'hooks/useTextSelectionVsClick';
import useDebouncedEffect from 'use-debounced-effect';
import singleEvent from '../requests/singleEvent';
import { EventPageProps } from '../types';
import EventsExplorerTableDetails from './EventsExplorerTableDetails';
import EventsExplorerTableOptions from './EventsExplorerTableOptions';
import EventsExplorerTableTags from './EventsExplorerTableTags';

const TITLE_LENGTH_LIMIT = 40;

enum RowDensity {
  SINGLE_LINE = 'Single-line',
  COMPACT = 'Compact',
}

const SourceCell = ({ row, rowDensity, setActiveEvent }: any) => {
  const { handleMouseDown, handleClick } = useTextSelectionVsClick(() =>
    setActiveEvent(row),
  );

  if (rowDensity === RowDensity.SINGLE_LINE) {
    return (
      <div
        className="events__table__source"
        onMouseDown={handleMouseDown}
        onClick={handleClick}
      >
        <div
          className="events__table__source__sign"
          style={{
            backgroundColor: colorsByLogLevel[row.alertType],
          }}
        ></div>
        <div>{row.sourceTypeName}</div>
      </div>
    );
  }

  return row.sourceTypeName;
};

const MessageCell = ({
  row,
  rowDensity,
  setActiveEvent,
  sidebarFilterState,
}: any) => {
  const { handleMouseDown, handleClick } = useTextSelectionVsClick(() =>
    setActiveEvent(row),
  );

  if (rowDensity === RowDensity.SINGLE_LINE) {
    return (
      <div
        className="events__table__message--signle-line"
        onMouseDown={handleMouseDown}
        onClick={handleClick}
      >
        <div className="events__table__message__title--signle-line">
          {row.title.length > TITLE_LENGTH_LIMIT
            ? `${row.title.slice(0, TITLE_LENGTH_LIMIT)}...`
            : row.title}
        </div>
        <EventsExplorerTableTags
          maxTags={row.title.length > TITLE_LENGTH_LIMIT ? 1 : 2}
          row={row}
          sidebarFilterState={sidebarFilterState}
          contractList={true}
        />
      </div>
    );
  }

  if (rowDensity === RowDensity.COMPACT) {
    return (
      <div
        className="events__table__message--compact"
        onMouseDown={handleMouseDown}
        onClick={handleClick}
      >
        <div className="events__table__message__header">
          <div
            className="events__table__message__header__status"
            style={{ backgroundColor: colorsByLogLevel[row.alertType] }}
          >
            {row.alertType.toUpperCase()}
          </div>
          <div className="events__table__message__title--compact">
            {row.title}
          </div>
        </div>
        <div className="events__table__message__preview--compact">
          {row.text.trim()}
        </div>
        <EventsExplorerTableTags
          maxTags={4}
          row={row}
          sidebarFilterState={sidebarFilterState}
          contractList={true}
        />
      </div>
    );
  }

  return (
    <div
      className="events__table__message"
      onMouseDown={handleMouseDown}
      onClick={handleClick}
    >
      <div className="events__table__message__header">
        <div
          className="events__table__message__header__status"
          style={{ backgroundColor: colorsByLogLevel[row.alertType] }}
        >
          {row.alertType.toUpperCase()}
        </div>
      </div>
      <div className="events__table__message__title">{row.title}</div>
      <EventsExplorerTableTags
        row={row}
        sidebarFilterState={sidebarFilterState}
        contractList={true}
      />
      <pre className="text--prewrap events__table__message__preview cursor-text">
        {row.text.trim()}
      </pre>
    </div>
  );
};

const DateCell = ({ row }: { row: { timestamp: string } }) => {
  return dayjs(row.timestamp).fromNow(false);
};

const columns = ({
  sidebarFilterState,
  rowDensity,
  setActiveEvent,
}: {
  rowDensity: string;
  setActiveEvent: any;
  sidebarFilterState: FilterMethodByType<'map'>;
}) => [
  {
    key: 'sourceTypeName',
    label: 'Source',
    renderCell: (params: any) => (
      <SourceCell
        {...params}
        rowDensity={rowDensity}
        setActiveEvent={setActiveEvent}
      />
    ),
  },
  {
    key: 'text',
    label: 'Message',
    renderCell: (params: any) => (
      <MessageCell
        {...params}
        rowDensity={rowDensity}
        setActiveEvent={setActiveEvent}
        sidebarFilterState={sidebarFilterState}
      />
    ),
  },
  {
    key: 'timestamp',
    label: 'Date',
    renderCell: DateCell,
  },
];

type Props = {
  onRowClick?: any;
  additionalFilterByFacets?: SelectedFacetValuesByName;
  errorMessage?: string;
} & EventPageProps;

const EventsExplorerTable = ({
  eventsState,
  additionalFilterByFacets,
  onRowClick,
  errorMessage,
}: Props): ReactElement => {
  const [activeEvent, setActiveEvent] = useState(null);
  const { activeEventInfo } = eventsState;
  const eventId = activeEventInfo?.id;
  const pageNumRef = useRef<number>(0);
  const eventsDataRequest = useRequest(eventsData, true, true);
  const singleEventRequest = useRequest(singleEvent, true, true);

  const [eventsResult, setEventsResult] = useState<EventListProps[]>([]);
  const { date, filterState, tableEventsSettings, setTableEventsSettings } =
    eventsState;
  const { filter, getMethodAndStateByFilterKey } = filterState;

  const sidebarFilterState = getMethodAndStateByFilterKey(
    'sidebarFilters',
    'map',
  );

  const call = () => {
    const pageNum = pageNumRef.current || 0;
    const { filterByFacets, searchTerms } = filter;
    eventsDataRequest
      .call({
        date,
        filterByFacets: convertArrayToObject(filterByFacets),
        additionalFilterByFacets,
        pageNum,
        searchTerms: searchTerms,
        selectedFacetValuesByName: filter['sidebarFilters'],
      })
      .then((nextResult) => {
        if (nextResult && nextResult.length) {
          pageNumRef.current = pageNum + 1;
          setEventsResult((prevResult) => [...prevResult, ...nextResult]);
        }
      });
  };

  const onScrollEnd = () => {
    call();
  };

  const fetchSingleEvent = (eventId: string, timestamp: string) => {
    singleEventRequest
      .call({
        eventId,
        timestamp,
      })
      .then(
        (event) => {
          setActiveEvent(event);
        },
        () => {},
      );
  };

  useDebouncedEffect(
    () => {
      pageNumRef.current = null;
      setEventsResult([]);
      call();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      timeout: 100,
      ignoreInitialCall: false,
    },
    [date, filter, additionalFilterByFacets],
  );

  useEffect(() => {
    if (eventId && eventsDataRequest.result) {
      const event = eventsDataRequest.result.find(
        (event) => event.id === eventId,
      );
      if (event) {
        setActiveEvent(event);
      } else {
        fetchSingleEvent(eventId, activeEventInfo?.timeStamp);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, eventsDataRequest.result]);

  const setActiveEventAndUpdateUrl = (event: EventListProps) => {
    setActiveEvent(event);
    eventsState.setActiveEventInfo(
      event
        ? {
            id: event.id,
            timeStamp: event.timestamp,
          }
        : {
            id: '',
            timeStamp: '',
          },
    );
  };

  const overlayMessageProps: OverlayMessageProps = errorMessage
    ? {
        isActive: true,
        iconName: 'warning',
        message: <>{errorMessage || 'Error'}</>,
      }
    : { isActive: false };

  return (
    <Loader
      className="relative flex flex-1 flex-col"
      isLoading={eventsDataRequest.isLoading}
    >
      <div
        className="events__table__setting-options"
        data-testid="events-table-options"
      >
        <PopoverTriggerV2
          className="events__table__setting-options__popover"
          popover={({ close }) => (
            <EventsExplorerTableOptions
              close={close}
              setTableEventsSettings={setTableEventsSettings}
              tableEventsSettings={tableEventsSettings}
            />
          )}
          position={PopoverPosition.BOTTOM_LEFT}
          offsetY={-2}
        >
          <div className="events__table__setting-options__button">
            <Settings size={12} />
            Options
          </div>
        </PopoverTriggerV2>
      </div>
      <div className="table__container relative flex-1 basis-0 overflow-auto">
        <OverlayMessage {...overlayMessageProps}>
          <Table
            className="table--bordered  font-robotoMono"
            columns={columns({
              rowDensity: tableEventsSettings.listDensity,
              sidebarFilterState,
              setActiveEvent: onRowClick || setActiveEventAndUpdateUrl,
            })}
            onRowClick={onRowClick}
            onScrollEnd={onScrollEnd}
            rows={eventsResult}
            dataTestId="events-table"
          />
        </OverlayMessage>
      </div>
      {activeEvent && (
        <RightSidebar
          className="events__right-sidebar"
          close={() => setActiveEventAndUpdateUrl(null)}
          title={activeEvent.title}
        >
          <EventsExplorerTableDetails
            activeEvent={activeEvent}
            eventsState={eventsState}
          />
        </RightSidebar>
      )}
    </Loader>
  );
};

export default EventsExplorerTable;
