import {
  Loader,
  Paginator,
  Table,
  TableOptionsPopover,
  TableSearch,
  useColumnsState,
  usePaginator,
  useTableOptions,
  useTableSearch,
  useTableSort,
} from 'components';
import React, { useMemo } from 'react';
import { DateSelection } from 'types';
import useKpisByFunctionNameRequest from '../hooks/useKpisByFunctionNameRequest';
import { KpisByFunctionName } from '../types';

const getRows = (
  serverlessFunctions: Record<string, any>,
  kpiLastModifiedMapping: Record<string, DateSelection>,
) => {
  return Object.keys(serverlessFunctions).map((serviceName) => ({
    name: serviceName,
    functionArn: serverlessFunctions[serviceName]?.functionArn,
    lastModified: kpiLastModifiedMapping?.[serviceName],
  }));
};

type Props = {
  columnsState: ReturnType<typeof useColumnsState>;
  kpisByFunctionName: KpisByFunctionName;
  kpisByFunctionNameRequest: ReturnType<typeof useKpisByFunctionNameRequest>;
  kpiLastModifiedMapping: Record<string, DateSelection>;
};

const ServerlessTable = ({
  columnsState,
  kpisByFunctionName,
  kpisByFunctionNameRequest,
  kpiLastModifiedMapping,
}: Props): JSX.Element => {
  const { columns } = columnsState;
  const rows = useMemo(
    () => getRows(kpisByFunctionName, kpiLastModifiedMapping),
    [kpisByFunctionName, kpiLastModifiedMapping],
  );

  const { isLoading } = kpisByFunctionNameRequest;
  const tableOptions = useTableOptions();
  const tableSearch = useTableSearch({ rows });
  const tableSort = useTableSort({
    columns,
    initialKey: columns.length ? columns[0].key : null,
    rows: tableSearch.searchedRows,
  });

  const paginator = usePaginator({
    key: 'services-table-pagination',
    rows: tableSort.sortedRows,
  });

  return (
    <Loader isLoading={isLoading && !paginator.paginatedRows.length}>
      <div className="table-toolbar">
        <div className="table-toolbar__left">
          <TableOptionsPopover
            columnsState={columnsState}
            shouldHideLinesToShow
            tableOptions={tableOptions}
          />
        </div>
        <TableSearch tableSearch={tableSearch} dataTestId="serverless-search" />
      </div>
      <Table
        className="table--bordered table--padded"
        columns={columns.filter(
          (column) => columnsState.state.selectedColumns[column.key],
        )}
        externalTableSort={tableSort}
        isSortingEnabled
        rows={paginator.paginatedRows}
        dataTestId="serverless-table"
      />
      <div className="table-footer">
        <Paginator paginator={paginator} />
      </div>
    </Loader>
  );
};

export default ServerlessTable;
