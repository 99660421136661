import {
  DashboardPanelTableTransformProps,
  DashboardPanelType,
} from 'types/Dashboard';
import { QueryCombinedStatusProps, QueryDataProps } from 'types/QueryData';

import { tableTransformer } from '../tableTransformer';
import { DataFrame } from '../types';
import { dateTimeFormat } from 'kfuse-constants/dateTimeFormat';

const transformationsForTable: DashboardPanelTableTransformProps[] = [
  { id: 'merge', options: null },
  {
    id: 'formatTime',
    options: {
      outputFormat: dateTimeFormat,
      timeField: 'Time',
      useTimezone: true,
    },
  },
];

const combineInstantQueryData = ({
  dataFormat,
  formulas,
  queries,
  queryData,
  transformations = transformationsForTable,
}: {
  dataFormat: DashboardPanelType;
  formulas: QueryCombinedStatusProps[];
  queries: QueryCombinedStatusProps[];
  queryData: QueryDataProps;
  transformations?: DashboardPanelTableTransformProps[];
}): Record<string, any> => {
  const activeDataFrames: DataFrame[] = [];
  const queryKeys = Object.keys(queryData);
  let isLoading = false;

  queryKeys.forEach((key) => {
    const [type, queryKey] = key.split('_');

    if (queryData[key].isLoading) {
      isLoading = true;
    }
    let query = null;
    if (type === 'query') {
      query = queries.find((q) => q.queryKey === queryKey);
    }
    if (type === 'formula') {
      query = formulas.find((f) => f.queryKey === queryKey);
    }

    const data = queryData[key];
    if (!data.instant || !query.isActive) return;
    if (data.meta.type !== dataFormat) return;

    activeDataFrames.push(data.instant);
  });

  const transformedData = tableTransformer({
    dataFrames: activeDataFrames,
    transformations,
  });

  return { ...transformedData, isLoading };
};

export default combineInstantQueryData;
