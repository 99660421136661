import { useMemo } from 'react';
import {
  ElementToFocusInfo,
  ElementType,
} from 'components/ExpressionBuilder/types';
import { FacetValuesByFacetKeyThenK8sIdentifier } from 'types';
import { assert, exhaustiveSwitchError } from 'utils';

type Suggestion = {
  id: string;
  label: string;
};

type UseSuggestionsProps = {
  getPropertyIfExists: (args: { index: number }) => string;
  getValueIfExists: (args: {
    index: number;
    valueIndex: number;
  }) => string | null;
  focusedElementInfo: ElementToFocusInfo;
  facetValuesByFacetNameThenK8sIdentifier: FacetValuesByFacetKeyThenK8sIdentifier;
};

const getSuggestions = ({
  getPropertyIfExists,
  getValueIfExists,
  focusedElementInfo,
  facetValuesByFacetNameThenK8sIdentifier,
}: UseSuggestionsProps) => {
  if (focusedElementInfo === null) {
    return [];
  }
  const type = focusedElementInfo.type;
  switch (type) {
    case ElementType.INITIALIZER: {
      return [
        ...Object.keys(facetValuesByFacetNameThenK8sIdentifier['tags']).map(
          (elem) => ({ id: `tags#${elem}`, label: elem }),
        ),
      ];
    }
    case ElementType.PROPERTY: {
      assert(focusedElementInfo.type === ElementType.PROPERTY);
      const { index } = focusedElementInfo;
      const searchTerm = getPropertyIfExists({ index });
      const properties = Object.keys(
        facetValuesByFacetNameThenK8sIdentifier['tags'],
      )
        .filter((elem) => elem.includes(searchTerm))
        .map((elem) => ({ id: `tags#${elem}`, label: elem }));
      return properties;
    }
    case ElementType.OPERATOR: {
      assert(focusedElementInfo.type === ElementType.OPERATOR);

      const { index } = focusedElementInfo;
      const property = getPropertyIfExists({ index });
      const searchTerm = getValueIfExists({ index, valueIndex: 0 }) || '';

      const values = (
        facetValuesByFacetNameThenK8sIdentifier['tags']?.[property] || []
      )
        .filter((elem) => elem.facetValue.includes(searchTerm))
        .map((elem) => ({
          id: `tags#${elem.facetValue}`,
          label: elem.facetValue,
        }));

      return values;
    }
    case ElementType.VALUE: {
      assert(focusedElementInfo.type === ElementType.VALUE);
      const { index, valueIndex } = focusedElementInfo;
      const property = getPropertyIfExists({ index });
      const searchTerm = getValueIfExists({ index, valueIndex }) || '';

      const values = (
        facetValuesByFacetNameThenK8sIdentifier['tags']?.[property] || []
      )
        .filter((elem) => elem.facetValue.includes(searchTerm))
        .map((elem) => ({
          id: `tags#${elem.facetValue}`,
          label: elem.facetValue,
        }));

      return values;
    }
    default:
      throw exhaustiveSwitchError(type);
  }
};

const useSuggestions = (props: UseSuggestionsProps): Array<Suggestion> => {
  return useMemo(() => {
    return getSuggestions(props);
  }, [props]);
};
export default useSuggestions;
