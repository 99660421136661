import React from 'react';

import { Box } from 'components/layouts/Box';
import { colorsByAlertState } from 'kfuse-constants';
import { getSelector } from '../utils/selectorsfunction';
import { ChipWithLabel } from 'components';
import { Table, TableRow } from 'components';
import KubernetesRightSideBar from '../KubernetesRightSideBar';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellTimestamp,
  KubeTableCell,
  KubeTableEntityTags,
} from './Components';
import { generatePath, useParams } from 'react-router-dom';
import KubernetesTableGroup from './KubernetesTableGroup';
import {
  useEntitiesData,
  useEntitiesGroupData,
  useMergedGroupByFilter,
} from './useEntitiesData';

const renderEmptyCell = () => '';

function KubernetesTableForDaemonSetRow({ row }) {
  const { date, entitiesType, navigate } = useKubernetesController();

  const facets = useMergedGroupByFilter(row.groupBys);
  const [rows] = useEntitiesData({
    entitiesType,
    kubesSort: null,
    facets,
    page: 0,
    limit: 50,
    date,
  });

  const setActiveKube = (row) => {
    navigate(({ location, params, pathTemplate }) => {
      return {
        ...location,

        pathname: generatePath(pathTemplate, {
          ...params,

          activeKube: row.daemonSet.metadata.uid,
          tab: 'yaml',
        }),
      };
    });
  };

  const activeKubeParam = useParams()['activeKube'];
  const activeKube = (
    typeof activeKubeParam === 'string' && rows ? rows : []
  ).find((row) => {
    return row.daemonSet.metadata.uid === activeKubeParam;
  });

  return (
    <Box width="full" height="full">
      {activeKube && <KubernetesRightSideBar activeKube={activeKube} />}
      <Table
        className="-my-px"
        renderHeader={false}
        rows={rows ?? []}
        renderRow={(props) => (
          <TableRow
            {...props}
            className="border-y"
            onRowClickHandler={(row) => () => setActiveKube(row)}
          />
        )}
        columns={[
          { key: 'header', label: '' },
          {
            key: 'kube_daemon_set',
            label: 'Daemon Set',
            renderCell(prop) {
              const demonSetName = prop?.row.daemonSet?.metadata?.name;
              return (
                <KubeTableCell {...prop} tooltipText={demonSetName}>
                  {demonSetName}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'kube_cluster_name',
            label: 'Cluster',
            renderCell(prop) {
              return <KubeCellCluster {...prop} />;
            },
          },
          {
            key: 'metadata.namespace',
            label: 'Namespace',
            renderCell(prop) {
              return <KubeCellNamespace {...prop} />;
            },
          },
          {
            key: 'status.currentNumberScheduled',
            label: 'Status',
            renderCell(prop) {
              const row = prop?.row;
              return (
                <KubeTableCell {...prop}>
                  <ChipWithLabel
                    className="kubernetes__table__cell__daemonset__status"
                    color={colorsByAlertState['ok']}
                    label={row.daemonSet?.status?.currentNumberScheduled || ''}
                  />
                  <ChipWithLabel
                    className="kubernetes__table__cell__daemonset__status"
                    color={colorsByAlertState['pending']}
                    label={row.daemonSet?.status?.desiredNumberScheduled || ''}
                  />
                  <ChipWithLabel
                    className="kubernetes__table__cell__daemonset__status"
                    color={colorsByAlertState['alerting']}
                    label={row.daemonSet?.status?.updatedNumberScheduled || ''}
                  />
                </KubeTableCell>
              );
            },
          },
          {
            key: 'headerSelectors',
            label: 'Selectors',
            renderCell(prop) {
              const selector = getSelector(prop?.row.daemonSet);
              return (
                <KubeTableCell
                  {...prop}
                  className="kubernetes__table__cell__entity-name"
                  tooltipText={selector}
                >
                  {selector}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'spec?.deploymentStrategy',
            label: 'Strategy',
            renderCell(prop) {
              return (
                <KubeTableCell
                  {...prop}
                  className="kubernetes__table__cell__entity-name"
                >
                  {prop?.row?.daemonSet?.spec?.deploymentStrategy}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'metadata.creationTimestamp',
            label: 'Age',
            renderCell(prop) {
              const timestamp =
                prop?.row?.daemonSet?.metadata?.creationTimestamp || '';
              return <KubeCellTimestamp {...prop} timestamp={timestamp} />;
            },
          },
        ]}
      />
    </Box>
  );
}

export default function KubernetesTableForDaemonSetGroup() {
  const { date, entitiesType, facets, groupBySearchTerms } =
    useKubernetesController();

  const [rows] = useEntitiesGroupData({
    entitiesType,
    date,
    facets,
    groupBySearchTerms,
  });

  return (
    <KubernetesTableGroup
      rows={rows ?? []}
      columns={[
        {
          key: 'headerkube_daemon_set',
          label: 'Daemon Set Group',
          renderCell: (prop) => <KubeTableEntityTags {...prop} />,
        },
        {
          key: 'headerkube_cluster_name',
          label: 'Cluster',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerkube_namespace',
          label: 'Namespace',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerstatus',
          label: 'Status',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerselectors',
          label: 'Selectors',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerstrategy',
          label: 'Strategy',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerage',
          label: 'Age',
          renderCell: renderEmptyCell,
        },
      ]}
      renderExpandedRow={(props) => (
        <KubernetesTableForDaemonSetRow {...props} />
      )}
    />
  );
}
