import { useRequest } from 'hooks';
import { useMemo, useState } from 'react';
import { analysisServiceCorrelation, getServices } from 'requests';
import dayjs from 'dayjs';
import { Service as ServiceType } from 'types';
import { getColorsByServiceName } from 'utils';

import { AlertEventProps } from '../types';
import { ActiveCorrelationProps } from './types';

const getCorrelationScope = (
  serviceByHash: Record<string, ServiceType>,
  activeCorrelation: ActiveCorrelationProps,
) => {
  const metricsScope = { asm_services: [], integrations: [] };

  const activeCorrelationKeys = Object.keys(activeCorrelation);
  if (activeCorrelationKeys.length === 0) {
    metricsScope.asm_services.push(['*', '1', '1']);
    return metricsScope;
  }
  activeCorrelationKeys.map((key) => {
    const service = serviceByHash[key];
    if (service.kfType === 'database') {
      metricsScope.integrations.push([service.hash, '1', '0']);
    } else {
      const useMetric = activeCorrelation[key].useMetric ? '1' : '0';
      const redMetric = activeCorrelation[key].redMetric ? '1' : '0';
      metricsScope.asm_services.push([service.hash, useMetric, redMetric]);
    }
  });
  return metricsScope;
};

const useAlertsServiceCorrelationState = ({
  alertEvent,
  kfSource,
  promqls,
  serviceHash,
}: {
  alertEvent: AlertEventProps;
  kfSource: string;
  promqls: string;
  serviceHash: string;
}) => {
  const analysisServiceCorrelationRequest = useRequest(
    analysisServiceCorrelation,
  );
  const [activeCorrelation, setActiveCorrelation] =
    useState<ActiveCorrelationProps>({});
  const [hideServiceMap, setHideServiceMap] = useState<boolean>(false);
  const getServicesRequest = useRequest(getServices, true, true);

  const onRunCorrelation = () => {
    const { activeAt, labels } = alertEvent;
    const newLabels = { ...labels };
    if (newLabels['alertname']) {
      delete newLabels['alertname'];
    }

    if (newLabels['__name__']) {
      delete newLabels['__name__'];
    }

    // check if activeAt is a valid date 0001-01-01T00:00:00Z is considered invalid
    const isDateValid = activeAt !== '0001-01-01T00:00:00Z';
    const activeDate = isDateValid ? dayjs(activeAt) : dayjs();

    const startDate = dayjs(activeDate).subtract(15, 'minute').unix();
    const metricsScope = getCorrelationScope(serviceByHash, activeCorrelation);
    analysisServiceCorrelationRequest
      .call({
        anomalyStartTime: startDate,
        anomalyEndTime: activeDate.unix(),
        labelsScope: Object.keys(newLabels || {}).map(
          (key) => `${key}="${newLabels[key]}"`,
        ),
        kfSource,
        metricsScope,
        serviceHash,
        sourceExpr: promqls,
        step: 15,
      })
      .then((correlations) => {
        if (correlations && correlations.length > 0) {
          setHideServiceMap(true);
        }
      });
  };

  const serviceByHash: Record<string, ServiceType> = useMemo(
    () =>
      (getServicesRequest.result || []).reduce(
        (obj, service) => ({ ...obj, [service.hash]: service }),
        {},
      ),
    [getServicesRequest.result],
  );

  const colorsByServiceName = useMemo(
    () =>
      getColorsByServiceName(
        (getServicesRequest.result as ServiceType[]) || [],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getServicesRequest.result, analysisServiceCorrelationRequest.result],
  );

  const onCorrelationKeyChange = ({
    propertyKey,
    value,
    serviceHash,
  }: {
    propertyKey: string;
    value: boolean;
    serviceHash: string;
  }) => {
    setActiveCorrelation((prev) => {
      const newActiveCorrelation = { ...prev };
      if (value) {
        newActiveCorrelation[serviceHash] = {
          ...newActiveCorrelation[serviceHash],
          [propertyKey]: value,
        };
      } else {
        delete newActiveCorrelation[serviceHash][propertyKey];
      }
      if (Object.keys(newActiveCorrelation[serviceHash]).length === 0) {
        delete newActiveCorrelation[serviceHash];
      }
      return newActiveCorrelation;
    });
  };

  return {
    activeCorrelation,
    analysisServiceCorrelationRequest,
    colorsByServiceName,
    getServicesRequest,
    hideServiceMap,
    onCorrelationKeyChange,
    onRunCorrelation,
    serviceByHash,
    setActiveCorrelation,
    setHideServiceMap,
  };
};

export default useAlertsServiceCorrelationState;
