import { SearchTag } from 'components';
import { Operator, SelectedFacetValueFilter } from 'hooks';
import React from 'react';

type Args = {
  selectedFacetValueFilter: SelectedFacetValueFilter;
};

const getFacetValueTag = ({ selectedFacetValueFilter }: Args): SearchTag => {
  const { facet } = selectedFacetValueFilter.value;
  const facetValuesBitmap = selectedFacetValueFilter.value.values;

  const isDeselecting =
    selectedFacetValueFilter.value.operator === Operator.notEqual;
  const operator = isDeselecting ? '!=' : '=';

  const label = (
    <>
      <span className="rum-search__input__trigger__tag__name">{facet}</span>
      <span>{operator}</span>
      <span className="whitespace-nowrap overflow-hidden overflow-ellipsis">
        {`"${Object.keys(facetValuesBitmap).join(' OR ')}"`}
      </span>
    </>
  );

  return {
    label,
    name: facet,
    text: `${facet}${operator}"${Object.keys(facetValuesBitmap).join(' OR ')}"`,
  };
};

export default getFacetValueTag;
