import { Button } from 'components';
import { useSearches } from 'hooks';
import React, { useCallback, useEffect } from 'react';
import { Plus } from 'react-feather';
import { ALLOW_ONLY_ONE_ACTIVE_SEARCH } from 'screens/Traces/utils';
import { DashboardPanelType } from 'types';
import { EventsTab } from '../types';

type Props = {
  searches: ReturnType<typeof useSearches>;
  eventsTab: EventsTab;
};

const EventsSearchQueryButtons = ({ searches, eventsTab }: Props) => {
  const addNewSearch = useCallback(() => {
    if (
      ALLOW_ONLY_ONE_ACTIVE_SEARCH.includes(
        eventsTab as unknown as DashboardPanelType,
      )
    ) {
      searches[0].addNewSearch(true);
    } else {
      searches[0].addNewSearch(false);
    }
  }, [searches, eventsTab]);

  useEffect(() => {
    if (
      ALLOW_ONLY_ONE_ACTIVE_SEARCH.includes(
        eventsTab as unknown as DashboardPanelType,
      )
    ) {
      const countOfSearches = searches.filter((s) => s.state.isActive).length;
      if (countOfSearches > 1) {
        searches?.[0]?.selectOnlySingeQuery();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsTab]);

  return (
    <div>
      <Button
        variant="default"
        size="sm"
        className="mt-2"
        onClick={addNewSearch}
      >
        <Plus size={16} /> Add query
      </Button>
    </div>
  );
};

export default EventsSearchQueryButtons;
