import dayjs from 'dayjs';
import get from 'lodash/get';

import { KubeEntityGroupProps } from '../types';

const includeSpecialCharacters = (value: string, entity: any) => {
  let result = '';
  if (value.includes('-')) {
    const [firstPart, lastPart] = value.split('-');
    const [secondPart, thirdPart] = lastPart.split('/');
    const name = entity.spec.ports[0][firstPart];
    const ports = entity.spec.ports[0][secondPart];
    const protocol = entity.spec.ports[0][thirdPart];
    result = `${name} ${ports}/${protocol}`;
  } else {
    const [value1, value2] = value.split('/');
    const result1 = eval(`entity.${value1}`) ? eval(`entity.${value1}`) : 0;
    const result2 = eval(`entity.${value2}`) ? eval(`entity.${value2}`) : 0;
    result = result1 + '/' + result2;
  }
  return result;
};

const valueInsideArray = (value: string, entity: any) => {
  const evalValue = get(entity, value);
  if (value.endsWith('ready')) {
    return evalValue ? '1/1' : '0/0';
  }

  if (value.endsWith('restartCount')) {
    return evalValue ? evalValue : '0';
  }

  return evalValue || '-';
};

const lastScheduleTime = (result: any) => {
  const date = new Date(result * 1000);
  result = date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  return result;
};

const getResult = (value: string, entity: any) => {
  let result = entity;
  for (const key of value.split('.')) {
    result = result?.[key];
  }
  if (value.includes('storage')) {
    result = result ? `${result / 1024 ** 3} GiB` : 'false';
  }
  if (value.includes('externalIPs') && !result) {
    result = 'None';
  }
  if (value.includes('lastScheduleTime')) {
    result = lastScheduleTime(result);
  }
  if (result) {
    return result;
  } else {
    return 'False';
  }
};

export const calculateKubeCount = (entity: any, result: any): number => {
  if (!entity || !result) {
    return 0;
  }
  const entityName = entity.metadata.name;
  const count = result
    .filter(
      ({ groupBys }: { groupBys: KubeEntityGroupProps[] }) =>
        groupBys?.some(({ value }) => value === entityName),
    )
    .map(({ count }) => count)
    .reduce((a, b) => a + b, 0);
  return count;
};

export const getEntityDetailsValue = (
  entity: any,
  label: { tags: boolean; value: string },
  podCount?: number,
  deploymentCount?: number,
): string => {
  const { tags, value } = label;
  if (!entity || !value) return '-';

  if (tags) {
    const targetTag = entity.tags.find((t: string) => t.startsWith(value));
    if (!targetTag) return '';
    return targetTag.split(':')[1];
  }

  if (value === 'namespace-pods') {
    return `${podCount}` ?? '0';
  }

  if (value === 'namespace-deployments') {
    return `${deploymentCount}` ?? '0';
  }

  if (value === 'automountServiceAccountToken' || value === 'selectors') {
    return 'None';
  }

  if (value === 'secrets') {
    return entity?.secrets?.length;
  }

  if (value.includes('[0]') || value.includes('.')) {
    return valueInsideArray(value, entity);
  }

  if (value.includes('creationTimestamp')) {
    const timestamp = entity.metadata[value];
    const timestampAge = dayjs.unix(timestamp);
    return timestampAge.fromNow(true) || '-';
  }

  if (value.includes('/')) {
    return includeSpecialCharacters(value, entity);
  }

  return getResult(value, entity);
};
