import { Loader, Table, TooltipTrigger } from 'components';
import { LuEye as Eye } from 'react-icons/lu';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { SLOProps } from 'types';
import { useAlertsState } from 'screens/NewAlerts/hooks';

const columns = (editSLOAlertNewTab: (row: any) => void) => [
  { key: 'name', label: 'Name' },
  {
    key: 'contactPoint',
    label: 'Contact Point',
    renderCell: ({ row }: { row: any }) => {
      if (row.contactPointLabels.length === 0) return 'No Contact Point';
      return (
        <>
          {row.contactPointLabels.map((contact: string) => (
            <span className="chip" key={contact}>
              {contact}
            </span>
          ))}
        </>
      );
    },
  },
  {
    label: 'Actions',
    key: 'actions',
    renderCell: ({ row }: { row: SLOProps }) => {
      return (
        <div className="alerts__contacts__table__actions">
          <TooltipTrigger tooltip="View">
            <Eye
              className="alerts__contacts__table__actions__icon--edit"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                editSLOAlertNewTab(row);
              }}
              size={18}
            />
          </TooltipTrigger>
        </div>
      );
    },
  },
];

const SLODetailsAlertsTab = ({
  sloData,
}: {
  sloData: SLOProps;
}): ReactElement => {
  const alertsState = useAlertsState();

  const editSLOAlertNewTab = (row: any): void => {
    window.open(`#/alerts/details/slo/${row.uid}`, '_blank');
  };

  useEffect(() => {
    alertsState.reloadAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sloData]);

  const filteredAlerts = useMemo(() => {
    if (!sloData?.alertUid) return [];
    if (!alertsState.rules) return [];

    return alertsState.rules.filter((rule) => rule.uid === sloData.alertUid);
  }, [alertsState.rules, sloData]);

  return (
    <div className="slo__details__alerts-tab">
      <Loader isLoading={alertsState.isLoading}>
        <Table
          className="slo__details__alerts-tab__table"
          columns={columns(editSLOAlertNewTab)}
          rows={filteredAlerts}
          onRowClick={({ row }) =>
            window.open(`#/alerts/details/slo/${row.uid}`, '_blank')
          }
        />
      </Loader>
    </div>
  );
};

export default SLODetailsAlertsTab;
