import { DateSelection } from 'types/DateSelection';
import {
  getAdjustedStartAndEndTimeUnix,
  getRollupByVisualization,
  onPromiseError,
} from 'utils';
import {
  MetricsDatasetTransformProps,
  MetricsTransformSeriesFuncProps,
  PrometheusDataset,
} from 'types';

import fetchJson from './fetchJson';
import { transformTimeseriesDataset } from './utils';

const promqlQueryRangeV2 = async ({
  allowFutureTime = false,
  date,
  metricNames,
  promqlQueries,
  seriesFormatter,
  steps,
  transformPromqlTimeseries,
  type,
  addEncoding = false,
  useCeilInAdjustingTime = false,
  chartType,
}: {
  allowFutureTime?: boolean;
  date: DateSelection;
  metricNames?: string[];
  promqlQueries: string[];
  seriesFormatter?: MetricsTransformSeriesFuncProps;
  steps?: number[];
  transformPromqlTimeseries?: (data: MetricsDatasetTransformProps) => any;
  type?: string;
  addEncoding?: boolean;
  useCeilInAdjustingTime?: boolean;
  chartType?: string;
}): Promise<any> => {
  const defaultStep = getRollupByVisualization(date, chartType);

  const datasets: PrometheusDataset[][] = await Promise.all(
    promqlQueries.map((promqlQuery: string, idx: number) => {
      const queryStep = steps ? steps[idx] || defaultStep : defaultStep;
      const adjusted = getAdjustedStartAndEndTimeUnix({
        allowFutureTime,
        date,
        step: `${queryStep}s`,
        useCeilInAdjustingTime,
      });

      const encodedPromqlQuery = addEncoding
        ? encodeURIComponent(promqlQuery)
        : promqlQuery;

      return fetchJson(
        `api/v1/query_range?query=${encodedPromqlQuery}&start=${adjusted.startTimeUnix}&end=${adjusted.endTimeUnix}&step=${queryStep}s`,
      ).then((result) => result?.data?.result || [], onPromiseError);
    }),
  );

  metricNames.forEach((metricName, index) => {
    const dataset = datasets[index];
    dataset.map((data: any) => {
      data.metric.__name__ = metricName;
    });
  });

  if (transformPromqlTimeseries) {
    const queryStep = steps ? steps[0] || defaultStep : defaultStep;
    return transformPromqlTimeseries({
      datasets: datasets[0],
      date,
      defaultStep: queryStep,
      steps,
      seriesFormatter,
    });
  }

  if (type === 'unflattened-timeseries') {
    return datasets.map((dataset: any, idx) =>
      transformTimeseriesDataset({
        datasets: dataset,
        date,
        seriesFormatter: seriesFormatter || undefined,
        step: steps ? steps[idx] || defaultStep : defaultStep,
      }),
    );
  }

  const flattenedDatasets = datasets.reduce((acc, dataset, index: number) => {
    dataset.forEach((data: any) => acc.push({ ...data, promIndex: index }));
    return acc;
  }, []);

  if (type === 'timeseries') {
    return transformTimeseriesDataset({
      datasets: flattenedDatasets,
      date,
      seriesFormatter: seriesFormatter || undefined,
      step: steps ? steps[0] || defaultStep : defaultStep,
    });
  }

  return flattenedDatasets;
};

export default promqlQueryRangeV2;
