import { useToggle } from 'hooks';
import { MutableRefObject, useState } from 'react';
import RrwebPlayer from 'rrweb-player';
import { RumEventType } from 'screens/Rum';
import { SessionMetadata } from './types';
import useSegmentFetcher from './useSegmentFetcher';
import { findTabIndexByMs } from './utils';

type Args = {
  currentTimeState: ReturnType<typeof useState<number>>;
  hoverPercentState: ReturnType<typeof useState<number>>;
  isPlayingToggle: ReturnType<typeof useToggle>;
  playersByIndexRef: MutableRefObject<Record<number, RrwebPlayer>>;
  segmentFetcher: ReturnType<typeof useSegmentFetcher>;
  session: SessionMetadata;
  shownRumEventTypesState: ReturnType<
    typeof useState<Record<RumEventType, number>>
  >;
  speedState: ReturnType<typeof useState<number>>;
  startReplayAtUnixMs?: number;
};

const getActions = ({
  currentTimeState,
  hoverPercentState,
  isPlayingToggle,
  segmentFetcher,
  playersByIndexRef,
  session,
  shownRumEventTypesState,
  speedState,
  startReplayAtUnixMs,
}: Args) => {
  const onBarClick = (e) => {
    const { startTimeUnixMs, endTimeUnixMs } = session;
    const totalMs = endTimeUnixMs - startTimeUnixMs;
    const { left } = e.target.getBoundingClientRect();
    const clickedMs = ((e.clientX - left) / e.target.offsetWidth) * totalMs;

    seekTo({ ms: clickedMs, shouldPlay: isPlayingToggle.value });
  };

  const seekTo = ({ ms, shouldPlay }) => {
    const { tabs } = session;
    const tabIndex = findTabIndexByMs({ ms, session });
    if (tabIndex > -1) {
      const player: RrwebPlayer = playersByIndexRef.current[tabIndex];
      if (player) {
        const { startTimeUnixMs } = tabs[tabIndex];
        const relativeStartTime = startTimeUnixMs - session.startTimeUnixMs;
        player.goto(ms - relativeStartTime, shouldPlay);

        if (!isPlayingToggle.value) {
          const [_, setCurrentTime] = currentTimeState;
          setCurrentTime(ms);
        }
      }
    }
  };

  const onBarHover = (e) => {
    const [_, setHoverPercent] = hoverPercentState;
    const { left } = e.target.getBoundingClientRect();
    setHoverPercent((e.clientX - left) / e.target.offsetWidth);
  };

  const onFinish = ({ index }: { index: number }) => {
    const nextIndex = index + 1;
    if (playersByIndexRef.current[nextIndex]) {
      playersByIndexRef.current[nextIndex].play();
    } else {
      isPlayingToggle.off();
    }
  };

  const onCurrentTimeUpdate = (nextCurrentTime: number) => {
    const [_, setCurrentTimeState] = currentTimeState;
    setCurrentTimeState(nextCurrentTime);
  };

  const pause = () => {
    Object.values(playersByIndexRef.current).forEach((player) => {
      player.pause();
    });
    isPlayingToggle.off();
  };

  const play = () => {
    const [currentTime] = currentTimeState;
    const { startTimeUnixMs, endTimeUnixMs } = session;
    const totalMs = endTimeUnixMs - startTimeUnixMs;
    if (currentTime >= totalMs - 1000) {
      seekTo({ ms: 0, shouldPlay: true });
    } else {
      seekTo({ ms: currentTime, shouldPlay: true });
    }

    isPlayingToggle.on();
  };

  const registerRrwebPlayer = ({
    index,
    player,
  }: {
    index: number;
    player: RrwebPlayer;
  }) => {
    const existingPlayer = playersByIndexRef.current[index];
    if (existingPlayer) {
      existingPlayer.$destroy();
    }

    playersByIndexRef.current[index] = player;

    // Start playing when all players have been registered
    if (Object.keys(playersByIndexRef.current).length === session.tabs.length) {
      if (startReplayAtUnixMs) {
        const { startTimeUnixMs } = session;
        const ms = startReplayAtUnixMs - startTimeUnixMs;
        const tabIndex = findTabIndexByMs({ ms, session });
        if (tabIndex > -1) {
          seekTo({ ms, shouldPlay: true });
          isPlayingToggle.on();
          return;
        }
      }
      playersByIndexRef.current[0].play();
      isPlayingToggle.on();
    }
  };

  const setSpeed = (speed: number) => {
    Object.values(playersByIndexRef.current).forEach((player) => {
      player.setSpeed(speed);
    });

    speedState[1](speed);
  };

  const toggleShowRumEventType = ({
    rumEventType,
    isSelected,
  }: {
    rumEventType: RumEventType;
    isSelected: boolean;
  }) => {
    const [_, setShownRumEventTypes] = shownRumEventTypesState;
    setShownRumEventTypes((prevShowRumEventTypes) => {
      const result = { ...prevShowRumEventTypes };
      if (isSelected) {
        result[rumEventType] = 1;
      } else {
        delete result[rumEventType];
      }

      return result;
    });
  };

  return {
    onBarHover,
    onBarClick,
    onCurrentTimeUpdate,
    onFinish,
    play,
    pause,
    registerRrwebPlayer,
    seekTo,
    setSpeed,
    toggleShowRumEventType,
  };
};

export default getActions;
