import { AutocompleteOption } from 'components';
import { DateSelection } from 'types/DateSelection';
import { buildPromqlLabelMatcher, parseMetricLabelQuery } from 'utils';

import { AlertsAPMDetailsProps } from '../../types';

export const conditionForAPMReducer: AutocompleteOption[] = [
  { label: 'Request per Second', value: 'RequestPerSecond' },
  { label: 'p50 Latency', value: 'p50' },
  { label: 'p75 Latency', value: 'p75' },
  { label: 'p90 Latency', value: 'p90' },
  { label: 'p95 Latency', value: 'p95' },
  { label: 'p99 Latency', value: 'p99' },
  { label: 'Error Rate', value: 'ErrorRate' },
  { label: 'Min Latency', value: 'min' },
  { label: 'Max Latency', value: 'max' },
  { label: 'Average Latency', value: 'avg' },
  { label: 'APDEX', value: 'apdex' },
];

export const getAPMReducerLabel = (value: string): string => {
  const condition = conditionForAPMReducer.find((c) => c.value === value);
  return condition ? (condition.label as string) : '';
};

export const apmChartUnit: { [key: string]: string } = {
  RequestPerSecond: 'number',
  p50: 'ms',
  p75: 'ms',
  p90: 'ms',
  p95: 'ms',
  p99: 'ms',
  ErrorRate: 'number',
  apdex: 'number',
};

export const getUniqueLabelsFilter = (
  uniqueLabels: AlertsAPMDetailsProps['uniqueLabels'],
): string => {
  return Object.keys(uniqueLabels || {})
    .map((key) => `${key}="${uniqueLabels[key]}"`)
    .filter(Boolean)
    .join(',');
};

const getAggregateBy = (labelshash: AlertsAPMDetailsProps['uniqueLabels']) => {
  const labels = Object.keys(labelshash || {});

  if (labels.length === 0) {
    return 'service_name';
  }

  return `service_name,${labels.join(',')}`;
};

const filterAdditionalLabels = (
  additionalLabels: AlertsAPMDetailsProps['additionalLabels'],
  triggerType: AlertsAPMDetailsProps['triggerType'],
) => {
  if (triggerType !== 'apdex') {
    return additionalLabels;
  }

  const DISALLOWED_LABELS = ['span_name', 'span_type'];
  return additionalLabels.filter((label) => {
    const parsedLabel = parseMetricLabelQuery(label);
    return !DISALLOWED_LABELS.includes(parsedLabel.label);
  });
};

export const getAPMAlertInnerPromql = (
  apmAlertDetails: AlertsAPMDetailsProps,
  date: DateSelection,
): string => {
  const { endTimeUnix, startTimeUnix } = date;
  const rateInterval = endTimeUnix - startTimeUnix;
  const {
    additionalLabels,
    uniqueLabels: labelshash,
    serviceName,
    triggerType,
  } = apmAlertDetails;

  if (!serviceName) {
    return '';
  }

  const customLabelsFilter = buildPromqlLabelMatcher({
    series: filterAdditionalLabels(additionalLabels, triggerType),
    seriesIndex: -1,
    includesAll: true,
  });

  const distinctLabelsFilter = getUniqueLabelsFilter(labelshash);
  const filter = `service_name="${serviceName}",${
    distinctLabelsFilter ? `${distinctLabelsFilter},` : ''
  }${customLabelsFilter ? `${customLabelsFilter},` : ''}kf_source="apm"`;

  const aggreagateBy = getAggregateBy(labelshash);

  if (triggerType === 'RequestPerSecond') {
    return `sum by (${aggreagateBy}) (rate(edge_latency_count{${filter}}[${rateInterval}s]))`;
  }

  if (['p50', 'p75', 'p90', 'p95', 'p99'].includes(triggerType)) {
    const percentile = triggerType.replace('p', '');
    return `histogram_quantile(0.${percentile}, sum(rate(edge_latency_bucket{${filter}}[${rateInterval}s])) by (service_name, le))`;
  }

  if (triggerType === 'ErrorRate') {
    const errorRateNom = `sum by (${aggreagateBy}) (rate(edge_latency_count{error="true",${filter}}[${rateInterval}s]))`;
    const errorRateDenom = `sum by (${aggreagateBy}) (rate(edge_latency_count{${filter}}[${rateInterval}s]))`;
    return `((${errorRateNom}) OR (${errorRateDenom} * 0)) * 100.0 / ${errorRateDenom}`;
  }

  if (['min', 'max'].includes(triggerType)) {
    return `${triggerType} by (${aggreagateBy}) (${triggerType}_over_time(edge_latency_${triggerType}{${filter}}[${rateInterval}s]))`;
  }

  if (triggerType === 'avg') {
    const sumQuery = `sum by (${aggreagateBy}) (edge_latency_sum{${filter}})`;
    const countQuery = `sum by (${aggreagateBy}) (edge_latency_count{${filter}})`;
    return `(${sumQuery})/(${countQuery})`;
  }

  if (triggerType === 'apdex') {
    const sumBucket2000 = `(sum by (service_hash) (increase(edge_latency_bucket{le="2000",error!="true",${filter}}[${rateInterval}s])))`;
    const sumBucket500 = `(sum by (service_hash) (increase(edge_latency_bucket{le="500",error!="true",${filter}}[${rateInterval}s])))`;
    const twoSumCount = `(2*sum by (service_hash) (increase(edge_latency_count{${filter}}[${rateInterval}s])))`;
    return `clamp_max(${sumBucket2000} %2B ${sumBucket500} / ${twoSumCount}, 1)`;
  }

  return '';
};
