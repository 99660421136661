import { DateSelection } from 'types';
import { onPromiseError } from 'utils';

import fetchJson from './fetchJson';

const metricsAnalytics = ({
  date,
  excludeNull,
  labels,
  groupBy,
  matcher,
  instant = true,
}: {
  date?: DateSelection;
  excludeNull?: boolean;
  labels: string[];
  groupBy?: string[];
  matcher?: string;
  instant?: boolean;
}): Promise<any> => {
  const newFormData = new FormData();

  if (date) {
    const { endTimeUnix, startTimeUnix } = date;
    const duration = endTimeUnix - startTimeUnix;
    newFormData.append('duration', instant ? '0' : duration.toString());
    newFormData.append('rollUpSeconds', duration.toString());
    newFormData.append('timestamp', endTimeUnix.toString());
  }
  if (typeof excludeNull === 'boolean') {
    newFormData.append('excludeNull', excludeNull.toString());
  }
  if (matcher) {
    newFormData.append('match[]', matcher);
  }
  newFormData.append('aggregation', 'distinctcount');

  if (groupBy && groupBy.length > 0) {
    groupBy.forEach((item) => newFormData.append('groupBy', item));
  }

  if (labels && labels.length > 0) {
    labels.forEach((label) => newFormData.append('aggregationField', label));
  }

  const params = new URLSearchParams(newFormData);
  return fetchJson(`/api/v1/analytics?${params.toString()}`).then(
    (result) => (result ? result : []),
    onPromiseError,
  );
};

export default metricsAnalytics;
