import classNames from 'classnames';
import {
  AutocompleteV2,
  AutocompleteOption,
  InputWithValidatorV3,
  TooltipTrigger,
} from 'components';
import React, { Dispatch, ReactElement, SetStateAction, useMemo } from 'react';
import { BsQuestionCircleFill } from 'react-icons/bs';

import { AlertsDetectionPopup } from '../components';
import {
  conditionForReducerLabel,
  conditionByLabel,
  useAlertsCreateCondition,
} from '../AlertsCreateCondition';
import { AlertType, AlertsEvaluateProps, RuleType } from '../types';
import { evaluateForType } from '../AlertsCreateMetrics/utils';

export const AlertsCreateConditionWhen = ({
  conditionState,
}: {
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
}): ReactElement => {
  const { condition, updateCondition } = conditionState;

  return (
    <div
      className="alerts__create__conditions__item__input"
      data-testid="condition-reducer"
    >
      <AutocompleteV2
        className="autocomplete__fixed-height-30"
        onChange={(val: string) => updateCondition('when', val)}
        options={conditionForReducerLabel}
        value={condition.when}
      />
    </div>
  );
};

export const AlertsCreateConditionQueryKey = ({
  conditionState,
  queryAndFormulaKeysLabel = [],
}: {
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
  queryAndFormulaKeysLabel: AutocompleteOption[];
}): ReactElement => {
  const { condition, updateCondition } = conditionState;

  if (queryAndFormulaKeysLabel.length === 0) {
    return <></>;
  }

  const isQueryKeyExist = queryAndFormulaKeysLabel.find(
    (item) => item.value === condition.queryKey,
  );
  return (
    <div
      className="alerts__create__conditions__item__input"
      data-testid="condition-query-or-formula"
    >
      <AutocompleteV2
        className={classNames({
          'autocomplete--error': !isQueryKeyExist,
          'autocomplete__fixed-height-30': true,
        })}
        onChange={(val: string) => updateCondition('queryKey', val)}
        options={queryAndFormulaKeysLabel}
        placeholder="Query key"
        value={condition.queryKey}
      />
    </div>
  );
};

export const AlertsCreateConditionOf = ({
  conditionState,
  options = conditionByLabel,
}: {
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
  options?: AutocompleteOption[];
}): ReactElement => {
  const { condition, updateCondition } = conditionState;
  return (
    <div
      className="alerts__create__conditions__item__input"
      data-testid="condition-operator"
    >
      <AutocompleteV2
        className="autocomplete__fixed-height-30"
        onChange={(val: string) => updateCondition('of', val)}
        options={options}
        placeholder=""
        value={condition.of}
      />
    </div>
  );
};

export const AlertsCreateConditionFor = ({
  evaluate,
  ruleType,
  setEvaluate,
}: {
  evaluate: AlertsEvaluateProps;
  ruleType: RuleType;
  setEvaluate: Dispatch<SetStateAction<AlertsEvaluateProps>>;
}): ReactElement => {
  const updatedEvaluateType = useMemo(() => {
    if (ruleType !== RuleType.LOGS) {
      return evaluateForType;
    }

    const discardEvaluateType = ['2d', '1w', '1M'];
    return evaluateForType.filter(
      (item) => !discardEvaluateType.includes(item.value),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleType, evaluate]);

  return (
    <div
      className="alerts__create__change__item__input"
      data-testid="condition-for-duration"
    >
      <AutocompleteV2
        className="autocomplete__fixed-height-30"
        onChange={(val: string) => {
          setEvaluate((prevState) => ({ ...prevState, for: val }));
        }}
        options={updatedEvaluateType}
        value={evaluate.for}
      />
    </div>
  );
};

export const AlertsCreateConditionThreshold = ({
  conditionState,
  placeholder = 'Value with unit (ms, s, B, T)',
  alertType,
  unit = 'number',
}: {
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
  placeholder?: string;
  alertType: AlertType;
  unit?: string;
}): ReactElement => {
  const { condition, errorHandling, updateCondition } = conditionState;

  if (!['lt', 'gt', 'eq', 'neq', 'gte', 'lte'].includes(condition.of)) {
    return null;
  }

  const isRawValueNonNumeric = Number.isNaN(Number(condition.rawValue));
  return (
    <div
      className="alerts__create__conditions__item__input"
      data-testid="condition-threshold"
    >
      <InputWithValidatorV3
        className="py-0"
        onChange={(val) => updateCondition('value', val, unit)}
        placeholder={placeholder}
        size="5"
        type="text"
        value={
          alertType === AlertType.ANOMALY ? condition.value : condition.rawValue
        }
        error={errorHandling.value}
        variant="default"
      />
      {alertType !== AlertType.ANOMALY &&
        condition.value &&
        isRawValueNonNumeric && (
          <span className="text-[12px] text-text-secondary">
            {`Parsed value ${condition.value}`}
          </span>
        )}
    </div>
  );
};

export const AlertsCreateConditionInfo = ({
  description,
  algorithm,
}: {
  description: string;
  algorithm?: string;
}): ReactElement => {
  return (
    <div className="alerts__create__tab__description">
      <TooltipTrigger
        tooltip={() => (
          <AlertsDetectionPopup
            title={''}
            message={description}
            algorithm={algorithm ? algorithm : ''}
          />
        )}
      >
        <BsQuestionCircleFill className="blue-question-circle" />
      </TooltipTrigger>
    </div>
  );
};
