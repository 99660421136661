import React from 'react';
import { useParams } from 'react-router-dom';
import EventsExplorer from '../EventsExplorer';
import EventsAnalytics from '../EventsAnalytics';
import { EventsTab } from '../types';
import { useEventsPageStateContext } from 'context';

const EventsContainer = () => {
  const { tab } = useParams();
  const eventsPageState = useEventsPageStateContext();
  if (
    tab === EventsTab.timeseries ||
    tab === EventsTab.toplist ||
    tab === EventsTab.table ||
    tab === EventsTab.piechart
  ) {
    return <EventsAnalytics eventsPageState={eventsPageState} tab={tab} />;
  }
  return <EventsExplorer />;
};

export default EventsContainer;
