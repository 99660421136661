import classNames from 'classnames';
import {
  CheckboxWithLabel,
  CursorStateProvider,
  Loader,
  OverlayMessage,
  OverlayMessageProps,
  SizeObserver,
  TopList,
} from 'components';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { DashboardPanelType } from 'types/Dashboard';
import { combineInstantQueryData } from 'utils';

import AnalyticsChartCombined from './AnalyticsChartCombined';
import AnalyticsChartPieChart from './AnalyticsChartPieChart';
import AnalyticsChartSingle from './AnalyticsChartSingle';
import AnalyticsChartTable from './AnalyticsChartTable';
import { AnalyticsChartProps } from './types';
import {
  getAnalyticsChartTransformations,
  visualizationListMap,
} from './utils';
import InstantQueriesDashboardExport from './InstantQueriesDashboardExport';
import AnalyticsGeoMap from './AnalyticsGeoMap';
import AnalyticsChartTreeMap from './AnalyticsChartTreeMap';

const AnalyticsChart = ({
  analyticsChart,
  chartData,
  chartQueries,
  chartFormulas,
  defaultActiveChart,
  doNotSplitKeysOfAnalyticTable,
  groupBy,
  hideCombinedChart = false,
  infoMessage,
  labelMap,
  onVisualizationChange,
  overlayMessage,
  renderValue,
  rightToolbar,
  setDate,
  settingOptions = { legendType: 'values', toolbarChartShowType: 'dropdown' },
}: AnalyticsChartProps): ReactElement => {
  const {
    activeVisualization,
    transformationConfig,
    hideVisualizeToolBar,
    isMultiChart,
    setIsMultiChart,
    setActiveVisualization,
    supportedVisualizations,
  } = analyticsChart;

  const overlayMessageProps: OverlayMessageProps =
    'string' === typeof overlayMessage
      ? {
          isActive: true,
          iconName: 'warning',
          message: <>{overlayMessage}</>,
        }
      : { isActive: false };

  const combinedInstantData = useMemo(() => {
    if (activeVisualization === DashboardPanelType.TIMESERIES) {
      return undefined;
    }

    const transformations = getAnalyticsChartTransformations({
      chartData,
      chartFormulas,
      chartQueries,
      excludeColumns: transformationConfig?.excludeColumns,
      visualization: activeVisualization,
    });
    return combineInstantQueryData({
      dataFormat: activeVisualization,
      formulas: chartFormulas,
      queries: chartQueries,
      queryData: chartData,
      transformations,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVisualization, chartData, chartFormulas, chartQueries]);

  const getActiveQueryOrFormulaToExport = useCallback(() => {
    if (!chartQueries) {
      return null;
    }
    const activeQuery = chartQueries.find((query) => query.isActive);
    if (activeQuery) {
      return activeQuery;
    }
    if (!chartFormulas) {
      return null;
    }
    const activeFormula = chartFormulas.find((formula) => formula.isActive);
    if (activeFormula) {
      return activeFormula;
    }
    return chartQueries[0];
  }, [chartQueries, chartFormulas]);

  const isInstantQueryExportEnabled = useMemo(() => {
    return Boolean(
      rightToolbar?.enableExportForInstantQueries &&
        rightToolbar?.onExportClick,
    );
  }, [rightToolbar]);

  const handleInstantQueryExport = useCallback(() => {
    if (isInstantQueryExportEnabled) {
      rightToolbar?.onExportClick({
        analyticsChartQueries: [
          ...(chartQueries || []),
          ...(chartFormulas || []),
        ],
        drawStyle: 'lines',
      });
    }
  }, [chartFormulas, chartQueries, isInstantQueryExportEnabled, rightToolbar]);

  return (
    <div>
      {!hideVisualizeToolBar && supportedVisualizations.length > 1 && (
        <div className="button-group my-2">
          <div className="button-group__item button-group__item--label">
            Visualize as
          </div>
          {supportedVisualizations.map((visualization) => (
            <div
              className={classNames({
                'button-group__item': true,
                'button-group__item--active':
                  visualization === activeVisualization,
              })}
              key={visualization}
            >
              <button
                className="flex"
                onClick={() => {
                  setActiveVisualization(visualization);
                  onVisualizationChange && onVisualizationChange(visualization);
                }}
              >
                <div className="svg--size-8">
                  {visualizationListMap[visualization]?.icon}
                </div>
                <div className="pl-1">
                  {visualizationListMap[visualization].label}
                </div>
              </button>
            </div>
          ))}
        </div>
      )}
      <div className="flex justify-between">
        <div>{infoMessage ? infoMessage : null}</div>
        {!hideCombinedChart &&
          activeVisualization === DashboardPanelType.TIMESERIES && (
            <div className="new-metrics__combine-query-checkbox">
              <CheckboxWithLabel
                label="Combine all queries into one chart"
                onChange={(checked) => setIsMultiChart(!checked)}
                value={!isMultiChart}
              />
            </div>
          )}
      </div>
      {activeVisualization === DashboardPanelType.TIMESERIES &&
        typeof chartData === 'object' && (
          <>
            {!isMultiChart && (
              <OverlayMessage {...overlayMessageProps}>
                <div className="new-metrics__combined-chart">
                  <AnalyticsChartCombined
                    analyticsChart={analyticsChart}
                    chartData={chartData}
                    chartQueries={chartQueries}
                    chartFormulas={chartFormulas}
                    defaultActiveChart={defaultActiveChart}
                    setDate={setDate}
                    settingOptions={settingOptions}
                    rightToolbar={rightToolbar}
                  />
                </div>
              </OverlayMessage>
            )}
            {isMultiChart && (
              <CursorStateProvider>
                <div className="new-metrics__charts">
                  {chartQueries.map((query, index: number) => {
                    if (!query.isActive) return null;
                    return (
                      <OverlayMessage key={index} {...overlayMessageProps}>
                        <div className="logs__analytics__charts__item">
                          <AnalyticsChartSingle
                            analyticsChart={analyticsChart}
                            chartData={chartData}
                            defaultActiveChart={defaultActiveChart}
                            query={query}
                            queries={chartQueries}
                            setDate={setDate}
                            settingOptions={settingOptions}
                            rightToolbar={rightToolbar}
                          />
                        </div>
                      </OverlayMessage>
                    );
                  })}
                  {chartFormulas.map((formula, index: number) => {
                    if (!formula.isActive) return null;
                    return (
                      <div
                        className="logs__analytics__charts__item"
                        key={index}
                      >
                        <AnalyticsChartSingle
                          analyticsChart={analyticsChart}
                          chartData={chartData}
                          defaultActiveChart={defaultActiveChart}
                          query={formula}
                          queries={chartQueries}
                          setDate={setDate}
                          settingOptions={settingOptions}
                          rightToolbar={rightToolbar}
                        />
                      </div>
                    );
                  })}
                </div>
              </CursorStateProvider>
            )}
          </>
        )}
      {activeVisualization === DashboardPanelType.TOP_LIST && (
        <OverlayMessage {...overlayMessageProps}>
          <div className="min-h-[340px] pt-3">
            <Loader
              isLoading={combinedInstantData.isLoading}
              dataTestId="toplist_loader"
            >
              <SizeObserver>
                {({ width }) => (
                  <>
                    {isInstantQueryExportEnabled && (
                      <InstantQueriesDashboardExport
                        onExport={handleInstantQueryExport}
                      />
                    )}
                    <TopList
                      data={combinedInstantData.data}
                      height={340}
                      width={width}
                      renderValue={renderValue}
                    />
                  </>
                )}
              </SizeObserver>
            </Loader>
          </div>
        </OverlayMessage>
      )}
      {activeVisualization === DashboardPanelType.TABLE && (
        <OverlayMessage {...overlayMessageProps}>
          <div className="min-h-[340px] pt-3">
            <Loader isLoading={combinedInstantData.isLoading}>
              {isInstantQueryExportEnabled && (
                <InstantQueriesDashboardExport
                  onExport={handleInstantQueryExport}
                />
              )}
              <AnalyticsChartTable
                data={combinedInstantData.data}
                doNotSplitKeysOfAnalyticTable={doNotSplitKeysOfAnalyticTable}
                labelMap={labelMap || {}}
                groupBy={groupBy}
                columns={combinedInstantData.columns}
              />
            </Loader>
          </div>
        </OverlayMessage>
      )}
      {activeVisualization === DashboardPanelType.PIECHART && (
        <OverlayMessage {...overlayMessageProps}>
          <div className="min-h-[340px] pt-3">
            <Loader
              isLoading={combinedInstantData.isLoading}
              dataTestId="piechart-loader"
            >
              {isInstantQueryExportEnabled && (
                <InstantQueriesDashboardExport
                  onExport={handleInstantQueryExport}
                />
              )}
              <AnalyticsChartPieChart
                data={combinedInstantData.data}
                columns={combinedInstantData.columns}
                pieChartData={combinedInstantData.pieChartData}
              />
            </Loader>
          </div>
        </OverlayMessage>
      )}
      {activeVisualization === DashboardPanelType.TREEMAP && (
        <OverlayMessage {...overlayMessageProps}>
          <div className="min-h-[340px] pt-3">
            {isInstantQueryExportEnabled && (
              <InstantQueriesDashboardExport
                onExport={handleInstantQueryExport}
              />
            )}
            <Loader isLoading={combinedInstantData.isLoading}>
              <AnalyticsChartTreeMap
                data={combinedInstantData.data}
                columns={combinedInstantData.columns}
                treeChartData={combinedInstantData.treeChartData}
              />
            </Loader>
          </div>
        </OverlayMessage>
      )}
      {activeVisualization === DashboardPanelType.GEOMAP && (
        <OverlayMessage {...overlayMessageProps}>
          <div className="min-h-[340px] pt-3">
            {isInstantQueryExportEnabled && (
              <InstantQueriesDashboardExport
                onExport={handleInstantQueryExport}
              />
            )}
            <Loader isLoading={combinedInstantData.isLoading}>
              <AnalyticsGeoMap data={combinedInstantData.data} />
            </Loader>
          </div>
        </OverlayMessage>
      )}
    </div>
  );
};

export default AnalyticsChart;
