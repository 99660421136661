import { fetchJson } from 'requests';

import { HydrateFormProps, HydrationJobProps } from '../types';

const hydrateArchive = async (
  data: HydrateFormProps,
): Promise<Record<string, string>> => {
  const { addTags, filter, ...rest } = data;
  const filterBitmap: Record<string, string> = {};
  filter.forEach((f) => {
    if (f.key && f.value) {
      filterBitmap[f.key] = f.value;
    }
  });
  const addTagsBitmap: Record<string, string> = {};
  addTags.forEach((t) => {
    if (t.key && t.value) {
      addTagsBitmap[t.key] = t.value;
    }
  });
  const result = await fetchJson('/hydration/query/hydrate', {
    method: 'POST',
    body: JSON.stringify({
      ...rest,
      addTags: addTagsBitmap,
      filter: filterBitmap,
    }),
  });

  return (result as unknown as HydrationJobProps[]) || [];
};

export default hydrateArchive;
