import React, { useState } from 'react';
import {
  FacetPickerValuesItemPopoverPanel,
  Json,
  JsonType,
  PopoverPosition,
  PopoverTriggerV2,
} from 'components';
import {
  useSelectedFacetRangeByNameState,
  useSelectedFacetValuesByNameState,
} from 'hooks';
import { MICROSECONDS } from 'kfuse-constants';
import useRumState from './hooks/useRumState';
import { RawEvent } from './requests/rawEvent';
import isRangeFacet, { RangeFacetsMap } from './utils/isRangeFacet';
import { getFacetKey } from 'utils/facets';
import { ChevronDown, ChevronRight } from 'react-feather';
import { RumEventType } from './types';
import { isDurationFacet } from './utils';
import { formatDurationFromNsString } from './RumFlameGraph/utils';

type Props = {
  json: RawEvent;
  rumState: ReturnType<typeof useRumState>;
  eventType: RumEventType;
};

type Args = {
  label: string;
  path: string;
  type: JsonType;
  value: any;
};

type SectionRenderArgs = {
  sectionTitle: string;
  sectionData: Record<string, any>;
  isOpen: boolean;
  sectionPrefix: string;
  toggleOpen: () => void;
};

const RumEventJson: React.FC<Props> = ({ json, rumState, eventType }) => {
  const { selectedFacetValuesByNameState, selectedFacetRangeByNameState } =
    rumState;
  const [sectionsOpen, setSectionsOpen] = useState({
    application: true,
    user: true,
    session: true,
    view: true,
    custom: true,
    fullJson: false,
  });

  const toggleSection = (section: keyof typeof sectionsOpen) => {
    setSectionsOpen((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const excludeFacetValueHandler =
    ({
      path,
      selectedFacetValuesByNameState,
    }: {
      label: string;
      path: string;
      selectedFacetValuesByNameState: ReturnType<
        typeof useSelectedFacetValuesByNameState
      >;
    }) =>
    (value: string) => {
      selectedFacetValuesByNameState.excludeFacetValue({
        name: path,
        value,
      });
    };
  const selectOnlyFacetValueHandler = ({
    path,
    selectedFacetValuesByNameState,
    selectedFacetRangeByNameState,
  }: {
    label: string;
    path: string;
    selectedFacetValuesByNameState: ReturnType<
      typeof useSelectedFacetValuesByNameState
    >;
    selectedFacetRangeByNameState: ReturnType<
      typeof useSelectedFacetRangeByNameState
    >;
  }) => {
    if (isRangeFacet(path)) {
      return (value: string) => {
        const isFacetDuration = isDurationFacet(path);
        const lower = parseFloat(value) || 0;
        const upper = parseFloat(value) || 0;
        selectedFacetRangeByNameState.changeFacetRange({
          name: getFacetKey({
            component: '',
            name: path,
            type: RangeFacetsMap[path as keyof typeof RangeFacetsMap],
            displayName: path,
          }),
        })({
          isLowerInclusive: true,
          isUpperInclusive: true,
          lower: isFacetDuration ? lower / MICROSECONDS : lower,
          upper: isFacetDuration ? upper / MICROSECONDS : upper,
        });
      };
    }
    return (value: string) => {
      selectedFacetValuesByNameState.selectOnlyFacetValue({
        name: path,
        value,
      });
    };
  };

  const transformFullPath = (sectionPrefix: string, key: string) => {
    const lowerKeyMap = {
      'Application Id': 'id',
      'Session Id': 'id',
      'View Id': 'id',
      'User Id': 'id',
      'User Email': 'email',
      URL: 'url',
    };
    const transformedKey = lowerKeyMap[key] || key;
    return `${sectionPrefix}.${transformedKey}`;
  };

  const renderPopoverTrigger = ({ key, value, fullPath }: any) => {
    const formattedValue = isDurationFacet(key)
      ? formatDurationFromNsString(value)
      : value;

    return (
      <PopoverTriggerV2
        className="json__value"
        popover={({ close }) => (
          <FacetPickerValuesItemPopoverPanel
            close={close}
            excludeFacetValue={excludeFacetValueHandler({
              label: `${fullPath}:"${value}"`,
              path: fullPath,
              selectedFacetValuesByNameState,
            })}
            formattedValue={formattedValue}
            isExcludeFacetValueShown={!isRangeFacet(key)}
            label={`${fullPath}:"${formattedValue}"`}
            selectOnlyFacetValue={selectOnlyFacetValueHandler({
              label: `${fullPath}:"${value}"`,
              path: fullPath,
              selectedFacetValuesByNameState,
              selectedFacetRangeByNameState,
            })}
            value={String(value)}
          />
        )}
        position={PopoverPosition.BOTTOM_LEFT}
      >
        <div className={`text--${typeof value}`}>{String(formattedValue)}</div>
      </PopoverTriggerV2>
    );
  };

  const renderSectionContent = (
    sectionData: Record<string, any>,
    sectionPrefix: string,
  ) =>
    Object.entries(sectionData).map(([key, value]) => {
      const fullPath = transformFullPath(sectionPrefix, key);
      return (
        <div key={key} className="rum-event-drawer__item">
          <span className="rum-event-drawer__item-key">{key}:</span>
          <span className="rum-event-drawer__item-value">
            {renderPopoverTrigger({ key, value, fullPath })}
          </span>
        </div>
      );
    });

  const renderSection = ({
    sectionTitle,
    sectionData,
    isOpen,
    sectionPrefix,
    toggleOpen,
  }: SectionRenderArgs) => (
    <div className="rum-event-drawer__section">
      <div className="rum-event-drawer__section-header" onClick={toggleOpen}>
        <span>{isOpen ? <ChevronDown /> : <ChevronRight />}</span>
        <span>{sectionTitle}</span>
      </div>
      {isOpen && (
        <div className="rum-event-drawer__section-content">
          {renderSectionContent(sectionData, sectionPrefix)}
        </div>
      )}
    </div>
  );

  return (
    <div className="rum-event-drawer__attributes">
      {renderSection({
        sectionTitle: 'Application',
        sectionData: { 'Application Id': json?.application?.id },
        isOpen: sectionsOpen.application,
        sectionPrefix: 'application',
        toggleOpen: () => toggleSection('application'),
      })}

      {renderSection({
        sectionTitle: 'User',
        sectionData: {
          'User Id': json?.usr?.id ? json?.usr?.id : 'N/A',
          'User Email': json?.usr?.email ? json?.usr?.email : 'N/A',
        },
        isOpen: sectionsOpen.user,
        sectionPrefix: 'usr',
        toggleOpen: () => toggleSection('user'),
      })}

      {renderSection({
        sectionTitle: 'Session',
        sectionData: { 'Session Id': json?.session?.id },
        isOpen: sectionsOpen.session,
        sectionPrefix: 'session',
        toggleOpen: () => toggleSection('session'),
      })}

      {sectionsOpen.session && (
        <div className="rum-event-drawer__section">
          <div className="rum-event-drawer__session-grid">
            {[
              {
                title: 'GEO',
                items: [
                  {
                    key: 'geo.country',
                    label: 'Country',
                    value: json?.geo?.country || 'N/A',
                  },
                  {
                    key: 'geo.city',
                    label: 'City',
                    value: json?.geo?.city || 'N/A',
                  },
                ],
              },
              {
                title: 'DEVICE',
                items: [
                  {
                    key: 'device.type',
                    label: 'Device Type',
                    value: json?.device?.type || 'N/A',
                  },
                ],
              },
              {
                title: 'BROWSER',
                items: [
                  {
                    key: 'browser.name',
                    label: 'Browser Name',
                    value: json?.browser?.name || 'N/A',
                  },
                ],
              },
              {
                title: 'CONNECTIVITY',
                items: [
                  {
                    key: 'connectivity.status',
                    label: 'Status',
                    value: json?.connectivity?.status || 'N/A',
                  },
                  {
                    key: 'connectivity.effective_type',
                    label: 'Effective Type',
                    value: json?.connectivity?.effective_type || 'N/A',
                  },
                ],
              },
            ].map(({ title, items }, index) => (
              <div
                key={index}
                className="rum-event-drawer__session-grid-column"
              >
                <div className="rum-event-drawer__column-title">{title}</div>
                {items.map(({ key, label, value }) => (
                  <div className="rum-event-drawer__item" key={key}>
                    <span className="rum-event-drawer__item-key">{label}:</span>
                    <span className="rum-event-drawer__item-value">
                      {renderPopoverTrigger({ key, value, fullPath: key })}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}

      {eventType !== RumEventType.SESSION &&
        renderSection({
          sectionTitle: 'View',
          sectionData: {
            'View Id': json?.view?.id,
            URL: json?.view?.url,
          },
          isOpen: sectionsOpen.view,
          sectionPrefix: 'view',
          toggleOpen: () => toggleSection('view'),
        })}

      {sectionsOpen.view && eventType !== RumEventType.SESSION && (
        <div className="rum-event-drawer__section">
          <div
            className={`rum-event-drawer__session-grid ${eventType === RumEventType.VIEW ? '' : 'rum-event-drawer__session-grid--single-column'}`}
          >
            <div className="rum-event-drawer__session-grid-column">
              <div className="rum-event-drawer__column-title">URL</div>
              {[
                {
                  key: 'view.url_host',
                  label: 'View Host',
                  value: json?.view?.url_host || 'N/A',
                },
                {
                  key: 'view.url_path',
                  label: 'View Path',
                  value: json?.view?.url_path || 'N/A',
                },
                {
                  key: 'view.url_hash',
                  label: 'View Hash',
                  value: json?.view?.url_hash || 'N/A',
                },
              ].map(({ key, label, value }) => (
                <div className="rum-event-drawer__item" key={key}>
                  <span className="rum-event-drawer__item-key">{label}:</span>
                  <span className="rum-event-drawer__item-value">
                    {renderPopoverTrigger({ key, value, fullPath: key })}
                  </span>
                </div>
              ))}
            </div>

            {eventType === RumEventType.VIEW && (
              <>
                {/* RELATED EVENTS Section */}
                <div className="rum-event-drawer__session-grid-column">
                  <div className="rum-event-drawer__column-title">
                    RELATED EVENTS
                  </div>
                  {[
                    {
                      key: 'view.error.count',
                      label: 'Error Count',
                      value: json?.view?.error.count
                        ? json?.view?.error.count
                        : 'N/A',
                    },
                    {
                      key: 'view.action.count',
                      label: 'Action Count',
                      value: json?.view?.action.count
                        ? json?.view?.action.count
                        : 'N/A',
                    },
                    {
                      key: 'view.long_task.count',
                      label: 'Longtask Count',
                      value: json?.view?.long_task.count
                        ? json?.view?.long_task.count
                        : 'N/A',
                    },
                    {
                      key: 'view.resource.count',
                      label: 'Resource Count',
                      value: json?.view?.resource.count
                        ? json?.view?.resource.count
                        : 'N/A',
                    },
                  ].map(({ key, label, value }) => (
                    <div className="rum-event-drawer__item" key={key}>
                      <span className="rum-event-drawer__item-key">
                        {label}:
                      </span>
                      <span className="rum-event-drawer__item-value">
                        {renderPopoverTrigger({ key, value, fullPath: key })}
                      </span>
                    </div>
                  ))}
                </div>

                {/* TIMINGS Section */}
                <div className="rum-event-drawer__session-grid-column">
                  <div className="rum-event-drawer__column-title">TIMINGS</div>
                  {[
                    {
                      key: 'view.time_spent',
                      label: 'View Time Spent',
                      value: json?.view?.time_spent
                        ? json?.view?.time_spent
                        : 'N/A',
                    },
                  ].map(({ key, label, value }) => (
                    <div className="rum-event-drawer__item" key={key}>
                      <span className="rum-event-drawer__item-key">
                        {label}:
                      </span>
                      <span className="rum-event-drawer__item-value">
                        {renderPopoverTrigger({ key, value, fullPath: key })}
                      </span>
                    </div>
                  ))}
                </div>

                {/* CORE WEB VITALS Section */}
                <div className="rum-event-drawer__session-grid-column">
                  <div className="rum-event-drawer__column-title">
                    CORE WEB VITALS
                  </div>
                  {[
                    {
                      key: 'view.cumulative_layout_shift',
                      label: 'Cumulative Layout Shift',
                      value: json?.view?.cumulative_layout_shift
                        ? json?.view?.cumulative_layout_shift
                        : 0,
                    },
                    {
                      key: 'view.interaction_to_next_paint',
                      label: 'Interaction To Next Paint',
                      value: json?.view?.interaction_to_next_paint
                        ? json?.view?.interaction_to_next_paint
                        : 0,
                    },
                    {
                      key: 'view.largest_contentful_paint',
                      label: 'Largest Contentful Paint',
                      value: json?.view?.largest_contentful_paint
                        ? json?.view?.largest_contentful_paint
                        : 0,
                    },
                  ].map(({ key, label, value }) => (
                    <div className="rum-event-drawer__item" key={key}>
                      <span className="rum-event-drawer__item-key">
                        {label}:
                      </span>
                      <span className="rum-event-drawer__item-value">
                        {renderPopoverTrigger({ key, value, fullPath: key })}
                      </span>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {renderSection({
        sectionTitle: 'Custom Attributes',
        sectionData: {
          rrweb_has_replay: json?.session?.has_replay,
          rrweb_tab_id: json?.context?.rrweb_tab_id,
        },
        isOpen: sectionsOpen.custom,
        sectionPrefix: 'context',
        toggleOpen: () => toggleSection('custom'),
      })}

      <div className="rum-event-drawer__section">
        <div
          className="rum-event-drawer__section-header"
          onClick={() => toggleSection('fullJson')}
        >
          <h3>JSON</h3>
          <span>
            {sectionsOpen.fullJson ? <ChevronDown /> : <ChevronRight />}
          </span>
        </div>
        {sectionsOpen.fullJson && (
          <Json
            data={json}
            options={{
              ignoreEmptyValues: true,
              renderValue: ({ path, label, type, value }: Args) => {
                const formattedValue = isDurationFacet(path)
                  ? formatDurationFromNsString(value)
                  : value;
                return (
                  <PopoverTriggerV2
                    className="json__value"
                    popover={({ close }) => (
                      <FacetPickerValuesItemPopoverPanel
                        close={close}
                        excludeFacetValue={excludeFacetValueHandler({
                          label,
                          path,
                          selectedFacetValuesByNameState:
                            selectedFacetValuesByNameState,
                        })}
                        formattedValue={formattedValue}
                        isExcludeFacetValueShown={!isRangeFacet(path)}
                        label={`${label}:${formattedValue}`}
                        selectOnlyFacetValue={selectOnlyFacetValueHandler({
                          label,
                          path,
                          selectedFacetValuesByNameState:
                            selectedFacetValuesByNameState,
                          selectedFacetRangeByNameState,
                        })}
                        value={String(value)}
                      ></FacetPickerValuesItemPopoverPanel>
                    )}
                    position={PopoverPosition.BOTTOM_LEFT}
                  >
                    <div className={`text--${type}`}>
                      {String(formattedValue)}
                    </div>
                  </PopoverTriggerV2>
                );
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default RumEventJson;
