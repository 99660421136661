import { TableColumnType } from 'components/Table';
import React from 'react';
import { SunburstDataProps } from 'types/Sunburst';
import { getColorForOneSeries } from 'utils/colors';
import { TableTransformerData } from '../types';

const pieChartTransformer = ({
  data,
  columns,
}: TableTransformerData): TableTransformerData & {
  pieChartData: SunburstDataProps[];
} => {
  const pieChartData: SunburstDataProps[] = [];
  let total = 0;
  const labelCols = columns.filter((column) => !column.key.includes('Value #'));
  const valueCols = columns.find((column) => column.key.includes('Value #'));

  const labelKeys = labelCols.map((col) => col.key);
  const valueKeys = valueCols?.key;

  data.forEach((item, idx) => {
    const value = Number(item[valueKeys]);
    const color = getColorForOneSeries(item, idx);
    pieChartData.push({
      name: labelKeys.map((key) => item[key]).join(', '),
      size: value || 0,
      color,
    });
    total += value || 0;
    data[idx].color = color;
  });

  data.forEach((item) => {
    item.percentage = (Number(item[valueKeys]) / total) * 100;
    item.label = labelKeys.map((key) => item[key]).join(', ');
  });

  const newColumns = [
    {
      key: 'label',
      label: labelKeys.join(', '),
      renderCell: ({ row }: { row: Record<string, any> }) => {
        return (
          <div className="flex">
            <div
              className="mr-1.5 pl-1 pt-1"
              style={{ backgroundColor: row.color }}
            ></div>
            <div>{row.label}</div>
          </div>
        );
      },
    },
    { ...valueCols, type: TableColumnType.NUMBER },
    {
      key: 'percentage',
      label: 'percentage',
      renderCell: ({ row }: { row: Record<string, any> }) => {
        if (!row?.percentage) return;
        return `${row?.percentage?.toFixed(2)}%`;
      },
      type: TableColumnType.NUMBER,
    },
  ];

  return { data, columns: newColumns, pieChartData };
};

export default pieChartTransformer;
