export const PermissionOptionsGroup = [
  { label: 'Member', value: 'Member' },
  { label: 'Admin', value: 'Admin' },
];

export const PermissionOptionsFolder = [
  { label: 'View', value: 'View' },
  { label: 'Edit', value: 'Edit' },
  { label: 'Admin', value: 'Admin' },
];
