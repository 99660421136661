const STD_CLOUD_AVAILABILITY_ZONE = 'availability_zone';
const STD_CLOUD_INSTANCE_TYPE = 'instance_type';
const STD_CLOUD_PROJECT = 'project';
const STD_CLOUD_REGION = 'region';

const groupByFacetName: { [key: string]: string } = {
  // host
  host: 'cloud',
  // kubernetes
  display_container_name: 'kubernetes',
  image_name: 'kubernetes',
  image_tag: 'kubernetes',
  short_image: 'kubernetes',
  kube_app_component: 'kubernetes',
  kube_app_instance: 'kubernetes',
  kube_app_managed_by: 'kubernetes',
  kube_app_name: 'kubernetes',
  kube_app_part_of: 'kubernetes',
  kube_app_version: 'kubernetes',
  kube_cluster_name: 'kubernetes',
  kube_container_name: 'kubernetes',
  kube_cronjob: 'kubernetes',
  kube_daemon_set: 'kubernetes',
  kube_deployment: 'kubernetes',
  kube_job: 'kubernetes',
  kube_namespace: 'kubernetes',
  kube_node: 'kubernetes',
  kube_node_role: 'kubernetes',
  kube_ownerref_kind: 'kubernetes',
  kube_ownerref_name: 'kubernetes',
  kube_replica_set: 'kubernetes',
  kube_replication_controller: 'kubernetes',
  kube_service: 'kubernetes',
  kube_stateful_set: 'kubernetes',
  oshift_deployment: 'kubernetes',
  oshift_deployment_config: 'kubernetes',
  persistentvolumeclaim: 'kubernetes',
  pod_name: 'kubernetes',
  pod_phase: 'kubernetes',
  // cloud
  cloud_account_id: 'cloud',
  eks_fargate_node: 'cloud',
  [STD_CLOUD_AVAILABILITY_ZONE]: 'cloud',
  [STD_CLOUD_INSTANCE_TYPE]: 'cloud',
  [STD_CLOUD_PROJECT]: 'cloud',
  [STD_CLOUD_REGION]: 'cloud',
  //container
  container_id: 'container',
};

export default groupByFacetName;
