import { useMergeState } from 'hooks';
import { EdgeType, Orientation, ServiceMapState } from './types';

const getInitialState = (orientation: Orientation): ServiceMapState => {
  return {
    customFilterState: {},
    edgeType:
      orientation === Orientation.horizontal
        ? EdgeType.straight
        : EdgeType.smoothstep,
    focusedNodeId: '',
    hideDanglingNodes: false,
    hideExternalAndUnknown: true,
    hoveredEdgeId: null,
    hoveredNodeId: null,
    orientation,
    outerRingKey: null,
    search: '',
    selectedNodeId: null,
    showMiniMap: true,
    showOnlyPathsWithErrors: false,
  };
};

const useServiceMapState = ({
  orientation = Orientation.vertical,
  renderNodeTooltip,
  searchedNode,
}) => {
  const [state, setState] = useMergeState<ServiceMapState>(
    getInitialState(orientation),
  );

  const changeHandler =
    (key: keyof ServiceMapState) =>
    (nextValue: ServiceMapState[typeof key]) => {
      setState({ [key]: nextValue });
    };

  const changeSearch = (nextSearch: string) => {
    setState({ search: nextSearch });
  };

  const changeEdgeType = (nextEdgeType: EdgeType) => {
    setState({ edgeType: nextEdgeType });
  };

  const changeFocusedNodeId = (nodeId: string) => {
    setState((prevState) => ({ focusedNodeId: prevState.selectedNodeId }));
  };

  const changeOrientation = (nextOrientation: Orientation) => {
    setState({
      edgeType:
        nextOrientation === Orientation.horizontal
          ? EdgeType.straight
          : EdgeType.smoothstep,
      orientation: nextOrientation,
    });
  };

  const changeOuterRingKey = (nextOuterRingKey: string) => {
    setState({ outerRingKey: nextOuterRingKey });
  };

  const clear = () => {
    const nextState = getInitialState(orientation);
    if (searchedNode) {
      nextState.search = searchedNode;
    }
    setState(nextState);
  };

  const onNodeClick = (nodeId: string) => {
    setState({ selectedNodeId: nodeId });
  };

  const onNodeMouseEnter = (nodeId: string) => {
    setState({ hoveredEdgeId: null, hoveredNodeId: nodeId });
  };

  const onNodeMouseLeave = () => {
    setState({ hoveredNodeId: null });
  };

  const onEdgeMouseEnter = (edgeId: string) => {
    setState({ hoveredEdgeId: edgeId });
  };

  const onEdgeMouseLeave = () => {
    setState({ hoveredEdgeId: null });
  };

  const resetFocusedNodeId = () => {
    setState({ focusedNodeId: null });
  };

  const createToggleHandlerByKey = (key) => () => {
    setState((prevState) => ({ [key]: !prevState[key] }));
  };

  const toggleCustomFilterHandler = (key: string) => () => {
    setState((prevState: ServiceMapState) => ({
      customFilterState: {
        ...prevState.customFilterState,
        [key]: !prevState.customFilterState[key],
      },
    }));
  };

  return {
    changeEdgeType,
    changeFocusedNodeId,
    changeOrientation,
    changeOuterRingKey,
    changeSearch,
    clear,
    onNodeClick,
    onEdgeMouseEnter,
    onEdgeMouseLeave,
    onNodeMouseEnter,
    onNodeMouseLeave,
    renderNodeTooltip,
    resetFocusedNodeId,
    state,
    toggleHideExternalAndUnknown: createToggleHandlerByKey(
      'hideExternalAndUnknown',
    ),
    toggleCustomFilterHandler,
    toggleHideDanglingNodes: createToggleHandlerByKey('hideDanglingNodes'),
    toggleShowMiniMap: createToggleHandlerByKey('showMiniMap'),
    toggleShowOnlyPathsWithErrors: createToggleHandlerByKey(
      'showOnlyPathsWithErrors',
    ),
  };
};

export default useServiceMapState;
