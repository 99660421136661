import {
  ChartToolbar,
  Loader,
  TimeseriesRenderer,
  TooltipTrigger,
  useThemeContext,
} from 'components';
import classNames from 'classnames';
import { useToggle } from 'hooks';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { MdLegendToggle } from 'react-icons/md';
import ResizeObserver from 'rc-resize-observer';
import {
  DateSelection,
  LayoutType,
  LegendTypes,
  MetricsQueriesDataProps,
} from 'types';
import uPlot from 'uplot';

import { drawChartThresold } from './utils';

const AlertChartRightToolbar = ({
  isActive,
  onClick,
}: {
  isActive: boolean;
  onClick: () => void;
}) => {
  return (
    <div className="new-metrics__chart__right-toolbar__icon" onClick={onClick}>
      <TooltipTrigger tooltip={`${isActive ? 'Hide' : 'Show'} Legends`}>
        <MdLegendToggle />
      </TooltipTrigger>
    </div>
  );
};

const defaultChartTypes = ['Line'];

const AlertsChart = ({
  conditionOperator,
  conditionValue,
  chartLayoutType,
  date,
  enableEvaluationLine = true,
  isChartCompact,
  isLoading,
  queryData,
  unit = 'number',
}: {
  conditionOperator: string;
  conditionValue: number;
  chartLayoutType?: LayoutType;
  date?: DateSelection;
  enableEvaluationLine?: boolean;
  isChartCompact: boolean;
  isLoading: boolean;
  queryData: MetricsQueriesDataProps['query_1']['data'];
  unit?: string;
}): ReactElement => {
  const { darkModeEnabled } = useThemeContext();
  const legendToggle = useToggle(true);
  const [chartWidth, setChartWidth] = useState(1000);
  const [chartData, setChartData] =
    useState<MetricsQueriesDataProps['query_1']['data']>(null);

  useEffect(() => {
    if (queryData?.data) {
      setChartData({ ...queryData });
    }
  }, [conditionOperator, conditionValue, chartWidth, queryData]);

  const chartThresholdHooks = useMemo(() => {
    if (!enableEvaluationLine) {
      return [];
    }

    return [
      {
        hook: (u: uPlot) => {
          drawChartThresold({
            u,
            conditionOperator,
            conditionValue,
            darkModeEnabled,
            unit,
          });
        },
        type: 'draw',
      },
    ];
  }, [
    enableEvaluationLine,
    conditionOperator,
    conditionValue,
    darkModeEnabled,
    unit,
  ]);

  return (
    <>
      <Loader
        className={classNames({
          alerts__chart__container: true,
          'alerts__chart__container--placeholder': isLoading,
        })}
        isLoading={isLoading}
      >
        <ResizeObserver onResize={(size) => setChartWidth(size.width)}>
          <TimeseriesRenderer
            bands={queryData?.bands || []}
            unit={unit}
            chartTypes={defaultChartTypes}
            date={date || null}
            chartData={chartData || { data: [], series: [] }}
            hooks={chartThresholdHooks}
            layoutType={chartLayoutType}
            isLoading={false}
            chartKey="alerts-chart"
            legend={{
              legendType: legendToggle.value
                ? LegendTypes.COMPACT_ONE_LINE
                : null,
              legendHeight: 120,
            }}
            renderToolbar={({ activeChart, setActiveChart }) => (
              <ChartToolbar
                activeChart={activeChart}
                chartTypes={['Line']}
                setActiveChart={setActiveChart}
                toolbar={{
                  rightToolbar: (
                    <AlertChartRightToolbar
                      isActive={legendToggle.value}
                      onClick={() => legendToggle.toggle()}
                    />
                  ),
                }}
              />
            )}
            tooltipType="compact"
            size={{
              height: isChartCompact ? 220 : 280,
              width: chartWidth,
            }}
          />
        </ResizeObserver>
      </Loader>
    </>
  );
};

export default AlertsChart;
