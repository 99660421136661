import { useToaster } from 'components';
import { useLabelValuePicker, useRequest } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import {
  createGrafanaFolder,
  getGrafanaFolders,
  getService,
  getServices,
  promqlQueryRange,
} from 'requests';
import {
  buildPromqlLabelMatcher,
  getColorForOneSeries,
  getUrlParamByKey,
} from 'utils';
import { GrafanaFolderProps } from 'types';

import {
  AnomalyConditionProps,
  ConditionProps,
  useAlertsCreateCondition,
} from '../../AlertsCreateCondition';
import { apmAlertValidatorForCreate } from '../../AlertsCreateValidators';
import { useAlertsCreateMetricsAnomaly } from '../../AlertsCreateMetrics/hooks';
import { useAlertsCreate } from '../../hooks';
import { AlertType, AlertsAPMDetailsProps, RuleType } from '../../types';
import { getAPMAlertInnerPromql, getUniqueLabelsFilter } from '../utils';

const useAlertsCreateAPM = ({
  alertsCreateState,
  conditionState,
  defaultAdditionalLabels,
}: {
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
  defaultAdditionalLabels?: string[];
}) => {
  const { addToast } = useToaster();
  const serviceHash = getUrlParamByKey('serviceHash');
  const [apmAlertDetails, setApmAlertDetails] = useState<AlertsAPMDetailsProps>(
    {
      additionalLabels: defaultAdditionalLabels || [
        'span_name=""',
        'span_type=""',
      ],
      serviceName: '',
      serviceHash: '',
      triggerType: 'RequestPerSecond',
      uniqueLabels: {},
    },
  );

  const { createAnomalyDetectionRule } = useAlertsCreateMetricsAnomaly({
    alertsCreateState,
  });
  const labelValuePicker = useLabelValuePicker();
  const { loadLabelValueList } = labelValuePicker;
  const { updateCondition } = conditionState;

  const createFolderRequest = useRequest(createGrafanaFolder);
  const getGrafanaFoldersRequest = useRequest(getGrafanaFolders);
  const promqlQueryRangeRequest = useRequest(promqlQueryRange);
  const serviceListRequest = useRequest((args) =>
    getServices(args).then(
      (res) =>
        res?.map(({ name, ...rest }, idx) => ({
          label: name,
          value: JSON.stringify({ name, ...rest }),
          color: getColorForOneSeries({}, idx),
        })) || [],
    ),
  );
  const serviceByHashRequest = useRequest(getService);
  const { alertsDetails, alertType, date, mutateAlertsRule, setErrorHandling } =
    alertsCreateState;

  const checkFolderExistsAndCreate = async (folderName: string) => {
    return new Promise((resolve, reject) => {
      getGrafanaFoldersRequest
        .call()
        .then((folders: GrafanaFolderProps[]) => {
          const folder = folders.find((folder) => folder.title === folderName);
          if (folder) {
            resolve(folder);
          } else {
            createFolderRequest
              .call({ folderName })
              .then((folder) => resolve(folder))
              .catch(reject);
          }
        })
        .catch(reject);
    });
  };

  const createAPMAlert = ({
    anomalyCondition,
    condition,
  }: {
    anomalyCondition: AnomalyConditionProps;
    condition: ConditionProps;
  }) => {
    if (!apmAlertDetails.serviceName) {
      addToast({ text: 'Please select a service', status: 'error' });
      return;
    }

    const validation = apmAlertValidatorForCreate({
      alertsDetails,
      alertType,
      apmAlertDetails,
      anomalyCondition,
      condition,
    });
    if (typeof validation === 'string') {
      addToast({ text: validation, status: 'error' });
      return;
    }

    const validationKeys = Object.keys(validation);
    if (validationKeys.length) {
      setErrorHandling((prev) => ({ ...prev, ...validation }));
      validationKeys.forEach((key) => {
        addToast({ text: validation[key], status: 'error' });
      });
      return;
    }

    alertsCreateState.setIsSaving(true);
    checkFolderExistsAndCreate(alertsCreateState.alertsDetails.folderName)
      .then(() => {
        if (alertsCreateState.alertType === AlertType.THRESHOLD) {
          createAPMThresholdAlert(condition);
        }
        if (alertsCreateState.alertType === AlertType.ANOMALY) {
          createAPMAnomalyAlert({ anomalyCondition, condition });
        }
      })
      .catch(() => {
        addToast({
          text: 'Failed to create folder for alerts',
          status: 'error',
        });
        alertsCreateState.setIsSaving(false);
      });
  };

  const createAPMThresholdAlert = (condition: ConditionProps) => {
    const promql = getAPMAlertInnerPromql(apmAlertDetails, date);
    if (!promql) {
      addToast({
        text: 'Failed to create alerts invalid promql',
        status: 'error',
      });
      alertsCreateState.setIsSaving(false);
      return;
    }

    mutateAlertsRule({
      condition,
      datasourceType: 'prometheus',
      date,
      promqlQuery: promql,
      ruleAnnotations: {
        alertType: AlertType.THRESHOLD,
        ruleType: RuleType.APM,
        extraData: JSON.stringify({
          apmTriggerType: apmAlertDetails.triggerType,
          additionalLabels: apmAlertDetails.additionalLabels,
          serviceName: apmAlertDetails.serviceName,
          uniqueLabels: apmAlertDetails.uniqueLabels,
          serviceHash: apmAlertDetails.serviceHash,
        }),
      },
    });
  };

  const createAPMAnomalyAlert = ({
    condition,
    anomalyCondition,
  }: {
    condition: ConditionProps;
    anomalyCondition: AnomalyConditionProps;
  }) => {
    const promql = getAPMAlertInnerPromql(apmAlertDetails, date);

    const extraAnnotations = {
      apmTriggerType: apmAlertDetails.triggerType,
      additionalLabels: apmAlertDetails.additionalLabels,
      serviceName: apmAlertDetails.serviceName,
      uniqueLabels: apmAlertDetails.uniqueLabels,
      serviceHash: apmAlertDetails.serviceHash,
    };

    createAnomalyDetectionRule({
      anomalyCondition,
      condition,
      extraAnnotations,
      promql,
    });
  };

  const updateTriggerType = (triggerType: string) => {
    setApmAlertDetails((prev) => ({ ...prev, triggerType }));
    // if trigger type is p99 or any other percentile that follow the same pattern pXX
    // we need to update the condition value to match the trigger type
    if (triggerType.match(/p\d{2}/)) {
      updateCondition('when', 'min');
    } else {
      updateCondition('when', 'max');
    }
  };

  const getApmMetricMatcher = useCallback(
    ({
      labels,
      labelIndex,
      newUniqueLabels,
      newService,
    }: {
      labels: string[];
      labelIndex?: number;
      newUniqueLabels: AlertsAPMDetailsProps['uniqueLabels'];
      newService: string;
    }) => {
      const matcher = buildPromqlLabelMatcher({
        series: labels || [],
        seriesIndex: labelIndex,
        includesAll: true,
      });
      const uniqueLabelsFilter = getUniqueLabelsFilter(newUniqueLabels);
      return `edge_latency_count{kf_source="apm",service_name="${newService}"${
        uniqueLabelsFilter ? `,${uniqueLabelsFilter}` : ''
      }${matcher ? `,${matcher}` : ''}}`;
    },
    [],
  );

  const loadInitialLabelsAndValues = (
    newService: string,
    newServiceHash: string,
    newUniqueLabels: AlertsAPMDetailsProps['uniqueLabels'],
  ) => {
    const { additionalLabels } = apmAlertDetails;
    const matcher = getApmMetricMatcher({
      labels: additionalLabels,
      labelIndex: 0,
      newUniqueLabels,
      newService,
    });
    labelValuePicker.loadLabelList({ date, matcher });
    loadLabelValueList({ date, matcher, label: 'span_name' });
    loadLabelValueList({ date, matcher, label: 'span_type' });
    const folderName = `${newService}_${newServiceHash}`;
    alertsCreateState.setAlertsDetails((prev) => ({ ...prev, folderName }));
    checkFolderExistsAndCreate(folderName).then((folder) => {
      alertsCreateState.setAlertsDetails((prev) => ({
        ...prev,
        folderUid: folder.uid,
      }));
    });
  };

  useEffect(() => {
    if (alertType === 'threshold') {
      const promql = getAPMAlertInnerPromql(apmAlertDetails, date);
      if (!promql) return;
      promqlQueryRangeRequest.call({
        date,
        promqlQueries: [promql],
        type: 'timeseries',
        metricNames: [''],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apmAlertDetails, date]);

  useEffect(() => {
    serviceListRequest.call({
      date,
      selectedFacetValuesByName: {
        [`kf_source`]: { [`apm`]: 1 },
        ['span_type']: { db: 0 },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    if (!serviceHash) return;
    serviceByHashRequest.call({ date, serviceHash }).then((service) => {
      setApmAlertDetails((prev) => ({
        ...prev,
        uniqueLabels: service.labels || {},
        serviceName: service.name,
        serviceHash,
      }));
      loadInitialLabelsAndValues(
        service.name,
        serviceHash,
        service.labels || {},
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceHash]);

  return {
    apmAlertDetails,
    createAPMAlert,
    createAPMAnomalyAlert,
    createAPMThresholdAlert,
    date: alertsCreateState.date,
    getApmMetricMatcher,
    labelValuePicker,
    loadInitialLabelsAndValues,
    promqlQueryRangeRequest,
    setApmAlertDetails,
    serviceListRequest,
    serviceByHashRequest,
    updateTriggerType,
  };
};

export default useAlertsCreateAPM;
