import { isNil } from 'lodash';
import uPlot from 'uplot';
import { anomalyBandColor } from 'utils/colors';

import drawBand from './drawBand';
import getAnomalyBandIndexes from './getAnomalyBandIndexes';

const drawAnomalyBand = ({
  darkModeEnabled,
  seriesIndex,
  u,
}: {
  darkModeEnabled: boolean;
  seriesIndex: number;
  u: uPlot;
}): boolean => {
  const series = u.series[seriesIndex];
  if (isNil(series)) return false;

  const anomalyBandIndexes = getAnomalyBandIndexes(series.label, u.series);
  if (!anomalyBandIndexes) return;
  if (anomalyBandIndexes.totalAnomalySeries === 1) return true;

  const tooltipCtx = u.tooltipCtx as CanvasRenderingContext2D;
  drawBand({
    ctx: tooltipCtx,
    series: u.series,
    upperBandIndex: anomalyBandIndexes.upperBandIndex,
    lowerBandIndex: anomalyBandIndexes.lowerBandIndex,
    bandColor: darkModeEnabled ? anomalyBandColor.dark : anomalyBandColor.light,
    u,
  });

  return true;
};

export default drawAnomalyBand;
