import { delimiter } from 'kfuse-constants';
import React, { Dispatch, SetStateAction } from 'react';
import {
  clearSelectedFacetValuesByFacetKey,
  getIsInQuotes,
  getWithoutQuotes,
  getSelectedFacetValuesByFacetKey,
  parseFacetKey,
  getLogsOperatorInQuery,
} from 'utils';

import {
  FacetBase,
  FilterStructure,
  FilterTagsProps,
  FilterTagsStateAndMethods,
  SelectedFacetRange,
} from 'types';

export const getSearchTags = (
  removeSearchTermByIndex: (index: number) => void,
  searchTerms: string[],
  setSearch: Dispatch<SetStateAction<string>>,
): Array<FilterTagsProps> => {
  return searchTerms.map((searchTerm: string, i: number) => ({
    label: `search:${searchTerm}`,
    onClick: () => {
      removeSearchTermByIndex(i);
    },
    onEdit: () => {
      removeSearchTermByIndex(i);
      setSearch(searchTerm);
    },
  }));
};

export const getFingerprintTags = (
  filterOrExcludeByFingerprint: { [key: string]: boolean },
  clearFilterOrExcludeByFingerprint: (val: string) => void,
): Array<FilterTagsProps> => {
  return Object.keys(filterOrExcludeByFingerprint).map((fpHash) => ({
    label: `${
      filterOrExcludeByFingerprint[fpHash] ? '' : '-'
    }fingerprint:${fpHash}`,
    onClick: () => {
      clearFilterOrExcludeByFingerprint(fpHash);
    },
  }));
};

export const getKeyExistsTags = (
  keyExists: { [key: string]: boolean },
  toggleKeyExists: (val: FacetBase) => void,
): Array<FilterTagsProps> => {
  return Object.keys(keyExists)
    .filter((facetKeyWithType) => keyExists[facetKeyWithType])
    .map((facetKeyWithType) => {
      const [component, name, type] = facetKeyWithType.split(delimiter);
      return {
        label: `key exists: ${component}:${name}`,
        onClick: () => {
          toggleKeyExists({ component, name, type });
        },
      };
    });
};

export const getSelectedFacetValueTags = (
  selectedFacetValues: { [key: string]: number },
  editFacet: any,
  resetFacet: (val: FacetBase) => void,
  setEditFacet: Dispatch<SetStateAction<any>>,
): Array<FilterTagsProps> => {
  const selectedFacetValuesByFacetKey =
    getSelectedFacetValuesByFacetKey(selectedFacetValues);
  return Object.keys(selectedFacetValuesByFacetKey).map((facetKey) => {
    const [component, name, type] = facetKey.split(delimiter);
    const facetValuesBitMap = selectedFacetValuesByFacetKey[facetKey];
    const facetValues = Object.keys(facetValuesBitMap);
    const showAsMinus = facetValuesBitMap[facetValues[0]] === 0;

    const label = `${showAsMinus ? '-' : ''}${component}:${name}:${
      facetValues.length === 1 ? facetValues[0] : facetValues.length
    }`;

    return {
      label,
      onClick: () => {
        if (
          editFacet?.component === component &&
          editFacet?.facetName === name
        ) {
          setEditFacet(null);
        }

        resetFacet({ component, name, type });
      },
      onEdit: () => {
        setEditFacet({
          component,
          facetName: name,
          facetValuesBitMap,
        });
      },
    };
  });
};

export const getFilterByFacetTags = (
  facetQueries: string[],
  removeFilterByFacetByIndex: (val: number) => void,
  onEditFilterByFacet: (val: string) => void,
): Array<FilterTagsProps> => {
  return facetQueries.map((facetQuery, index) => ({
    label: `${facetQuery}`,
    onClick: () => {
      removeFilterByFacetByIndex(index);
    },
    onEdit: () => {
      onEditFilterByFacet(facetQuery);
      removeFilterByFacetByIndex(index);
    },
  }));
};

const buildSimpleFilterTags = ({
  filterKey,
  prefix,
  stateAndMethods,
}: {
  filterKey: string;
  prefix: string;
  stateAndMethods: FilterTagsStateAndMethods;
}): Array<FilterTagsProps> => {
  const {
    state,
    removeFilterByKey,
    removeFilterByIndex,
    setSearch,
    setEditSearch,
  } = stateAndMethods;

  const isArray = Array.isArray(state);
  if (isArray) {
    return (state as Array<string>).map((val, idx) => ({
      label: `${prefix}:${val}`,
      onClick: () => {
        removeFilterByIndex(idx, filterKey);
      },
      onEdit: () => {
        setEditSearch({ type: 'array', filterKey, index: idx });
        setSearch(val);
      },
    }));
  }

  return Object.keys(state as { [key: string]: number }).map((val) => ({
    label: `${prefix}:${val}`,
    onClick: () => {
      removeFilterByKey(filterKey, val);
    },
    onEdit: () => {
      setEditSearch({ type: 'object', filterKey, key: val });
      setSearch(val);
    },
  }));
};

const buildSearchTermsFilterTags = (
  filterKey: string,
  stateAndMethods: FilterTagsStateAndMethods,
) => {
  const {
    state,
    removeFilterByIndex,
    setSearch,
    setEditSearch,
    updateActiveOperator,
  } = stateAndMethods;

  return (state as string[]).map((searchTerm: string, idx: number) => {
    const [op] = searchTerm.split(':');
    const val = searchTerm.replace(`${op}:`, '');

    const isInQuotes = getIsInQuotes(val);
    const label = 'search';
    const value = isInQuotes ? getWithoutQuotes(val) : val;
    return {
      label: `${label}${op}"${value}"`,
      onClick: () => {
        removeFilterByIndex(idx, filterKey);
      },
      onEdit: () => {
        setEditSearch({ type: 'array', filterKey, index: idx });
        setSearch(val);
        updateActiveOperator(op);
      },
    };
  });
};

const buildSidebarsFilterTags = (
  filterKey: string,
  stateAndMethods: FilterTagsStateAndMethods,
): Array<FilterTagsProps> => {
  const { state, removeMappedFilterByKey, setEditSearch } = stateAndMethods;
  const sourceKeys = Object.keys(state);

  const sidebarTags: FilterTagsProps[] = [];
  sourceKeys.map((sourceKey) => {
    const [component, name] = sourceKey.split(delimiter);
    const sourceKeyBitMap = state[sourceKey];
    const sourceValues = Object.keys(sourceKeyBitMap);
    const showAsMinus = sourceKeyBitMap[sourceValues[0]] === 0;

    let label;
    if (sourceKey.includes(delimiter)) {
      label = `${showAsMinus ? '-' : ''}${component}:${name}:${
        sourceValues.length === 1 ? sourceValues[0] : sourceValues.length
      }`;
    } else {
      label = `${showAsMinus ? '-' : ''}${sourceKey}:${
        sourceValues.length === 1 ? sourceValues[0] : sourceValues.length
      }`;
    }

    sidebarTags.push({
      label,
      onClick: () => {
        removeMappedFilterByKey(filterKey, sourceKey);
      },
      onEdit: () => {
        setEditSearch({
          type: 'map',
          filterKey,
          facetName: sourceKey,
          isDeselected: showAsMinus,
        });
      },
    });
  });

  return sidebarTags;
};

export const buildLogsSidebarsFilterTags = (
  filterKey: string,
  stateAndMethods: FilterTagsStateAndMethods,
) => {
  const { state, replaceFilterByKey, setEditSearch } = stateAndMethods;
  const logsSidebarTags: FilterTagsProps[] = [];

  const mappedFilterObject = getSelectedFacetValuesByFacetKey(state);
  const sourceKeys = Object.keys(mappedFilterObject);
  sourceKeys.map((facetKey) => {
    const [component, name] = facetKey.split(delimiter);
    const sourceKeyBitMap = mappedFilterObject[facetKey];
    const sourceValues = Object.keys(sourceKeyBitMap);
    const showAsMinus = sourceKeyBitMap[sourceValues[0]] === 0;

    let label;
    if (facetKey.includes(delimiter)) {
      label = `${showAsMinus ? '-' : ''}${component}:${name}:${
        sourceValues.length === 1 ? sourceValues[0] : sourceValues.length
      }`;
    } else {
      label = `${showAsMinus ? '-' : ''}${facetKey}:${
        sourceValues.length === 1 ? sourceValues[0] : sourceValues.length
      }`;
    }

    logsSidebarTags.push({
      label,
      onClick: () => {
        const clearedFilter = clearSelectedFacetValuesByFacetKey(
          facetKey,
          state,
        );
        replaceFilterByKey(filterKey, clearedFilter);
      },
      onEdit: () => {
        setEditSearch({
          type: 'object',
          filterKey,
          facetName: facetKey,
          isDeselected: showAsMinus,
        });
      },
    });
  });

  return logsSidebarTags;
};

const getExtentedOperatorLabel = (operator: string): string => {
  if (operator === '*~') {
    return 'starts with';
  }

  if (operator === '**') {
    return 'contains';
  }

  if (operator === '~*') {
    return 'ends with';
  }

  return '';
};

const getFilterByFacetLabel = ({ facetQuery }: { facetQuery: string }) => {
  const splitOperator = getLogsOperatorInQuery(facetQuery);
  const [facetName, value] = facetQuery.split(splitOperator);
  const operator = splitOperator;

  if (operator === '*~' || operator === '**' || operator === '~*') {
    const operatorLabel = getExtentedOperatorLabel(operator);
    return (
      <span>
        <span>{`${facetName} `}</span>
        <span className="text--weight-bold">{operatorLabel}</span>
        <span>{` ${value}`}</span>
      </span>
    );
  }

  return null;
};

const buildFilterByFacetTags = (
  filterKey: string,
  stateAndMethods: FilterTagsStateAndMethods,
  splitByOperatorInQuery?: boolean,
) => {
  const { state, removeFilterByIndex, setSearch, setEditSearch } =
    stateAndMethods;

  return state.map((val: string, idx: number) => {
    const splitOperator = splitByOperatorInQuery
      ? getLogsOperatorInQuery(val)
      : delimiter;
    const [dataType, facetQuery] = val.split(splitOperator);
    const label = getFilterByFacetLabel({ facetQuery });

    return {
      label: label || facetQuery,
      onClick: () => {
        removeFilterByIndex(idx, filterKey);
      },
      onEdit: () => {
        setSearch(`${facetQuery}`);
        setEditSearch({ type: 'array', filterKey, index: idx, dataType });
      },
    };
  });
};

const getSelectedFacetRangeTags = (
  filterKey: string,
  stateAndMethods: FilterTagsStateAndMethods,
) => {
  const { state, removeFilterByKey } = stateAndMethods;

  const selectedFacetRanges = state as { [key: string]: SelectedFacetRange };
  return Object.keys(selectedFacetRanges).map((facetKey) => {
    const facet = parseFacetKey(facetKey);
    const { lower, upper } = selectedFacetRanges[facetKey];

    return {
      label: `${lower} <= @${facet.component}.${facet.name} <= ${upper}`,
      onClick: () => {
        removeFilterByKey(filterKey, facetKey);
      },
      onEdit: () => {},
    };
  });
};

const buildKeyExistsFilterTags = (
  filterKey: string,
  stateAndMethods: FilterTagsStateAndMethods,
) => {
  const { state, removeFilterByKey } = stateAndMethods;

  const selectedKeyExistsFilters = state as { [key: string]: number };
  return Object.keys(selectedKeyExistsFilters).map((facetKey) => {
    const facet = parseFacetKey(facetKey);

    return {
      label: `key exist:${facet.name}`,
      onClick: () => {
        removeFilterByKey(filterKey, facetKey);
      },
      onEdit: () => {},
    };
  });
};

export const buildSearchFilterTags = ({
  filterKey,
  filterStructure,
  stateAndMethods,
  splitByOperatorInQuery,
}: {
  filterKey: string;
  filterStructure: FilterStructure;
  stateAndMethods: FilterTagsStateAndMethods;
  splitByOperatorInQuery?: boolean;
}): Array<FilterTagsProps> => {
  if (filterKey === 'sidebarFilters') {
    return buildSidebarsFilterTags(filterKey, stateAndMethods);
  }

  if (filterKey === 'filterByFacets') {
    return buildFilterByFacetTags(
      filterKey,
      stateAndMethods,
      splitByOperatorInQuery,
    );
  }

  if (filterKey === 'searchTerms') {
    return buildSearchTermsFilterTags(filterKey, stateAndMethods);
  }

  if (filterKey === 'facetRangeFilters') {
    return getSelectedFacetRangeTags(filterKey, stateAndMethods);
  }

  if (filterKey === 'keyExists') {
    return buildKeyExistsFilterTags(filterKey, stateAndMethods);
  }

  return buildSimpleFilterTags({
    filterKey,
    prefix: filterStructure[filterKey].tagPrefix,
    stateAndMethods,
  });
};
