import dayjs from 'dayjs';
import { onPromiseError } from 'utils';
import queryRumService from './queryRumService';
import { RumEventType } from '../types';
import { RumEvent } from '@datadog/browser-rum';

type Args = {
  eventTimeUnix: number;
  eventId: string;
  eventType: RumEventType;
};

export type RawEvent = RumEvent | Record<string, unknown>;

const rawEvent = async ({
  eventTimeUnix,
  eventId,
  eventType,
}: Args): Promise<RawEvent> => {
  const eventTime = dayjs(eventTimeUnix);

  return queryRumService<RawEvent, 'rawEvent'>(`
  query {
    rawEvent(
      eventType: ${eventType},
      timestamp: "${eventTime.toISOString()}"
      eventId: "${eventId}"
    )
  }
  `).then((data) => data?.rawEvent || null, onPromiseError);
};

export default rawEvent;
