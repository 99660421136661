import { useLeftSidebarState } from 'components';
import { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const useQueryScheduler = (
  leftSidebarState: ReturnType<typeof useLeftSidebarState>,
) => {
  const callIdRef = useRef(null);
  const [state, setState] = useState({
    isLogLevelOpen: false,
    isTimelineOpen: false,
    startTimeMs: null,
    endTimeMs: null,
    firstQueryCompletedAt: null,
    secondQueryCompletedAt: null,
    zoomHasBeenUpdated: false,
  });

  const setStartTime = () => {
    const callId = uuidv4();
    callIdRef.current = callId;

    setState((prevState) => ({
      ...prevState,
      startTimeMs: new Date().valueOf(),
      endTimeMs: null,
    }));
  };

  const setEndTime = () => {
    setState((prevState) => ({
      ...prevState,
      endTimeMs: new Date().valueOf(),
    }));
  };

  const setFirstQueryCompletedAt =
    ({ allowQueriesToContinue, zoomHasBeenUpdated }) =>
    () => {
      setState((prevState) => {
        if (allowQueriesToContinue) {
          return { ...prevState, endTimeMs: new Date().valueOf() };
        }

        const completedAt = new Date().valueOf();
        return {
          ...prevState,
          firstQueryCompletedAt: completedAt,
          zoomHasBeenUpdated,
        };
      });
    };

  const setSecondQueryCompletedAt = () => {
    setState((prevState) => ({
      ...prevState,
      secondQueryCompletedAt: new Date().valueOf(),
      endTimeMs: !prevState.isLogLevelOpen
        ? new Date().valueOf()
        : prevState.endTimeMs,
    }));
  };

  const setIsLogLevelOpen = (isLogLevelOpen: boolean) => {
    setState((prevState) => ({
      ...prevState,
      isLogLevelOpen,
    }));
  };

  const setIsTimelineOpen = (isTimelineOpen: boolean) => {
    setState((prevState) => ({
      ...prevState,
      isTimelineOpen,
    }));
  };

  return {
    ...state,
    callIdRef,
    setEndTime,
    setStartTime,
    setFirstQueryCompletedAt,
    setSecondQueryCompletedAt,
    setIsLogLevelOpen,
    setIsTimelineOpen,
  };
};

export default useQueryScheduler;
