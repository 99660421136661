import { AutocompleteOption } from 'components';
import React, { ReactElement, useMemo } from 'react';
import { parseMetricLabelQuery } from 'utils';

import { LabelValueChip } from '../LabelValueChip';

const MetricsQueryBuilderSeriesBuilder = ({
  labelIndex,
  labelList,
  labelValueList,
  onLabelChange,
  onLabelOpen,
  onOperatorChange,
  onValueChange,
  parsedSeries,
  removeLabel,
  usedLabels,
}: {
  labelIndex: number;
  labelList: {
    [key: string]: { options: AutocompleteOption[]; isLoading: boolean };
  };
  labelValueList: {
    [key: string]: { options: AutocompleteOption[]; isLoading: boolean };
  };
  onLabelChange: (labelIndex: number, newLabel: string) => void;
  onLabelOpen: () => void;
  onOperatorChange: (
    labelIndex: number,
    newOperator: string,
    operator: string,
  ) => void;
  onValueChange: (labelIndex: number, newValue: string) => void;
  parsedSeries: ReturnType<typeof parseMetricLabelQuery>;
  removeLabel: (labelIndex: number) => void;
  usedLabels: string[];
}): ReactElement => {
  const { label, matcher, operator, value } = parsedSeries;

  const labelListOptions = useMemo(() => {
    const labels = labelList[matcher];
    if (!labels) return { options: [], isLoading: false };

    const options = labels?.options?.filter((option) => {
      if (option.label === label) return true;
      return !usedLabels.includes(option.label as string);
    });
    return { options, isLoading: labels.isLoading };
  }, [labelList, matcher, label, usedLabels]);

  const errors = useMemo(() => {
    const errors = { label: false, value: false };
    if (!label) errors.label = true;
    if (!value) errors.value = true;
    return errors;
  }, [label, value]);

  return (
    <>
      <div className="button-group">
        <div
          className="button-group__item button-group__item--unpadded metrics__query-builder__series__builder--button-group"
          data-labelindex={labelIndex}
          data-testid="label-value-picker"
        >
          <LabelValueChip
            errors={errors}
            isLabelLoading={labelListOptions.isLoading}
            isValueLoading={labelValueList[label]?.isLoading}
            label={label}
            labelOptions={labelListOptions?.options || []}
            onLabelChange={(val: string) => {
              const trimedVal = val.slice(0, -3);
              onLabelChange(labelIndex, `${trimedVal}${operator}""`);
            }}
            onLabelClick={onLabelOpen}
            onLabelValueChipDelete={() => removeLabel(labelIndex)}
            onOperatorChange={(val) =>
              onOperatorChange(labelIndex, val, operator)
            }
            onValueChange={(val: string) => onValueChange(labelIndex, val)}
            operator={operator}
            value={value as string}
            valueOptions={labelValueList[label]?.options || []}
          />
        </div>
      </div>
      <div className="search__button-group__divider">
        <div />
      </div>
    </>
  );
};

export default MetricsQueryBuilderSeriesBuilder;
