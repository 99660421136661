import { FacetAccordion, FacetPicker } from 'components';
import { SearchInput } from 'components/FacetPicker/FacetPickerValues';
import React, { ReactElement, useMemo, useRef, useState } from 'react';
import { Separator } from 'components/shadcn';
import { getKubenetesEntityFacets } from '../utils';
import { noop } from 'lodash';
import { useKubernetesController } from '../KubernetesController';
import { kubeLabelNames } from 'requests';
import { Box } from 'components/layouts/Box';
import useAsync from '../hooks/useAsync';

const KubernetesLeftSidebarAdditionals = ({
  children,
}: {
  children: ReactElement;
}): JSX.Element => {
  const [expanded, setExpanded] = useState(false);

  return (
    <FacetAccordion
      expanded={expanded}
      setExpanded={setExpanded}
      isVisuallyHidden={false}
      renderTrigger={() => 'Additional'}
      renderContent={() => {
        return children;
      }}
      triggerClassName="leading-[28px] pl-2 mb-1 w-full active:bg-interaction-nested-contrast hover:bg-interaction-nested data-[state=open]:bg-interaction-nested border-t-0 border-transparent cursor-pointer group"
    />
  );
};

const KubernetesFacetPicker = ({
  facetName,
  collection,
  lastRefreshedAt,
}: {
  facetName: string;
  collection: Array<{
    facetKey: string;
    facetValue: string;
    count: number;
  }>;
  lastRefreshedAt: number;
}): JSX.Element => {
  const {
    entitiesType,
    facets,
    facetValueExclude,
    facetValueOnly,
    facetValueToggle,
    facetValueClear,
  } = useKubernetesController();

  const controller = useRef(null);

  const shouldRefresh = useMemo(() => {
    if (collection) {
      return lastRefreshedAt + collection.length;
    }

    return lastRefreshedAt;
  }, [lastRefreshedAt, collection]);

  return (
    <FacetPicker
      name={facetName}
      request={async () => {
        if (controller.current) {
          controller.current.abort();
        }

        controller.current = new AbortController();

        try {
          const { kubeFacetCounts } = await kubeLabelNames(
            {
              entityType: entitiesType,
              fields: ['tags'],
              selectedFacetValuesByName: facets,
              selectors: {
                tags: [facetName],
              },
            },
            { signal: controller.current.signal },
          );

          return collection.map((facet) => {
            const update = kubeFacetCounts.tags?.find(
              (tag) => tag.facetValue === facet.facetValue,
            );

            return {
              value: facet.facetValue,
              count: update?.count ?? 0,
            };
          });
        } catch (err) {
          if (err.name === 'AbortError') {
            // Aborted request will indefinitely hang here to prevent
            // `Request was replaced by another request` error
            await new Promise(noop);
          }

          throw err;
        }
      }}
      lastRefreshedAt={shouldRefresh}
      forceExpanded={false}
      changeFacetRange={noop}
      clearFacet={() => {
        facetValueClear(facetName);
      }}
      excludeFacetValue={(value: string) => {
        facetValueExclude(facetName, value);
      }}
      toggleFacetValue={(value: string, allValues: Array<string>) => {
        facetValueToggle(facetName, value, allValues);
      }}
      selectOnlyFacetValue={(value: string) => {
        facetValueOnly(facetName, value);
      }}
      selectedFacetValues={facets[facetName] || {}}
    />
  );
};

const KubernetesLeftSidebar = (): JSX.Element => {
  const { entitiesType, facets } = useKubernetesController();

  const [searchTerm, setSearchTerm] = useState('');

  // TODO: rewrite the logic for the useAsync to handle errors? (same as with useRequest), listening for status === 'rejected' in a useEffect doesn't work
  const [tags, status] = useAsync(async () => {
    const { kubeFacetCounts } = await kubeLabelNames({
      entityType: entitiesType,
      selectedFacetValuesByName: {},
      fields: ['tags.facetKey', 'tags.facetValue'],
    });

    return kubeFacetCounts.tags;
  }, [entitiesType]);

  const filteredFacets = Array.isArray(tags)
    ? tags?.filter(({ facetKey }) => {
        return facetKey?.includes(searchTerm);
      })
    : [];

  const commonFacetsMatchingSearchTerm = new Set(
    getKubenetesEntityFacets(entitiesType).map(({ key }) => key),
  );

  const top = !filteredFacets
    ? Object.fromEntries(
        Array.from(commonFacetsMatchingSearchTerm)
          .filter((facetKey) => facetKey.includes(searchTerm))
          .sort()
          .map((term) => {
            return [term, []];
          }),
      )
    : filteredFacets
        .filter(({ facetKey }) => commonFacetsMatchingSearchTerm.has(facetKey))
        .reduce(
          (accum, facet) => {
            if (accum[facet.facetKey]) {
              accum[facet.facetKey].push(facet);
            } else {
              accum[facet.facetKey] = [facet];
            }

            return accum;
          },
          {} as Record<string, typeof filteredFacets>,
        );
  const bottom = filteredFacets
    ?.filter(({ facetKey }) => !commonFacetsMatchingSearchTerm.has(facetKey))
    .reduce(
      (accum, facet) => {
        if (accum[facet.facetKey]) {
          accum[facet.facetKey].push(facet);
        } else {
          accum[facet.facetKey] = [facet];
        }

        return accum;
      },
      {} as Record<string, typeof filteredFacets>,
    );

  const lastRefreshedAt = useMemo(() => {
    return performance.now() + JSON.stringify(facets).length;
  }, [facets]);

  return (
    <div>
      <SearchInput
        data-testid="kubernetes-facet-search"
        value={searchTerm}
        placeholder="Search facets"
        onChange={({ target }) => setSearchTerm(target.value)}
        containerClassName="my-2"
      />
      {Object.keys(top ?? {}).map((facetName) => {
        return (
          <KubernetesFacetPicker
            key={`common-${facetName}`}
            facetName={facetName}
            collection={top[facetName]}
            lastRefreshedAt={lastRefreshedAt}
          />
        );
      })}
      <Box my="4">
        <Separator />
      </Box>
      <KubernetesLeftSidebarAdditionals>
        {Object.keys(bottom ?? {}).map((facetName) => {
          return (
            <KubernetesFacetPicker
              key={`common-${facetName}`}
              facetName={facetName}
              collection={bottom[facetName]}
              lastRefreshedAt={lastRefreshedAt}
            />
          );
        })}
      </KubernetesLeftSidebarAdditionals>
    </div>
  );
};

export default KubernetesLeftSidebar;
