import dayjs from 'dayjs';
import { onPromiseError } from 'utils';
import {
  DashboardPanelType,
  PrometheusDatasetExtended,
  PrometheusDatasetResponse,
} from 'types';

import fetchJson from './fetchJson';
import { transformPromqlInstantQuery } from './utils';

const promqlQuery = async ({
  labels,
  metricNames = [],
  promqlQueries,
  responseFormat = 'none',
  type = 'multi',
}: {
  labels?: string[];
  metricNames?: string[];
  promqlQueries: string[];
  responseFormat?: DashboardPanelType | 'none' | 'unflattened';
  type?: 'single' | 'multi';
}): Promise<any> => {
  const time = dayjs().unix();

  const datasets = await Promise.all(
    promqlQueries.map((promqlQuery: string) =>
      fetchJson(`api/v1/query?query=${promqlQuery}&time=${time}`).then(
        (result: PrometheusDatasetResponse) => result?.data?.result || [],
        onPromiseError,
      ),
    ),
  );

  if (responseFormat === 'unflattened') {
    return datasets;
  }

  if (
    responseFormat === DashboardPanelType.TABLE ||
    responseFormat === DashboardPanelType.PIECHART ||
    responseFormat === DashboardPanelType.TOP_LIST ||
    responseFormat === DashboardPanelType.GRAFANA_POLYSTAT_PANEL
  ) {
    return transformPromqlInstantQuery({
      datasets,
      dataFormat: responseFormat,
      metricNames,
      promqls: promqlQueries,
    });
  }

  const flattenedDatasets = datasets.reduce<PrometheusDatasetExtended[]>(
    (acc, dataset, index: number) => {
      dataset.forEach((data) => acc.push({ ...data, promIndex: index }));
      return acc;
    },
    [],
  );

  if (responseFormat === 'none') {
    if (type === 'single') {
      return flattenedDatasets[0];
    }
    return flattenedDatasets;
  }

  return flattenedDatasets;
};

export default promqlQuery;
