import { Separator } from 'components/shadcn';
import React, { ReactElement } from 'react';

import { alertTypeAlgorithm } from '../AlertsCreateMetrics/utils';
import { alertDetailsTimeConversion } from '../utils';
import { AlertsMetricsParsedProps } from '../types';

const AlertsDetailsChangeParameters = ({
  parsed,
}: {
  parsed: AlertsMetricsParsedProps;
}): ReactElement => {
  const { changeCondition } = parsed;
  return (
    <>
      <div className="alerts__details__properties__summary__item">
        <span className="text-sm font-bold text-text-secondary">
          Algorithm:{' '}
        </span>{' '}
        {alertTypeAlgorithm[changeCondition.change]}
      </div>
      <Separator orientation="horizontal" />
      <div className="alerts__details__properties__summary__item">
        <span className="text-sm font-bold text-text-secondary">Offset: </span>{' '}
        {alertDetailsTimeConversion(changeCondition.time)}
      </div>
      <Separator orientation="horizontal" />
    </>
  );
};

export default AlertsDetailsChangeParameters;
