import { SelectedFacetValuesByName } from 'types';
import { onPromiseError } from 'utils';
import queryEventService from './queryEventService';
import buildKubeFilter, {
  buildKubeFilterVariable,
} from './utils/buildKubeFilter';

type Args = {
  selectedFacetValuesByName: SelectedFacetValuesByName;
  entityType: string;
  tags: string;
};

const kubeFacetCount = async (
  { entityType, tags, selectedFacetValuesByName }: Args,
  init?: RequestInit,
): Promise<any> => {
  return queryEventService<Array<any>, 'kubeFacetCounts'>(
    `
  query GetKubeFacetCount($entityType: KubeEntityType!, $filter: KubeFilter!) {
    kubeFacetCounts (
      entityType: $entityType
      filter: $filter
      selector: {tags: {keys:${tags}}}) {
      labels {
        facetKey
        facetValue
        count
      }
      tags {
        facetKey
        facetValue
        count
      }
      annotations {
        facetKey
        facetValue
        count
      }
    }
  }`,
    {
      entityType,
      filter: buildKubeFilterVariable([selectedFacetValuesByName]),
    },
    init,
  ).then(
    (data) =>
      data.kubeFacetCounts.tags.map(({ facetValue: value, ...rest }) => ({
        value,
        ...rest,
      })) || [],
    onPromiseError,
  );
};

export default kubeFacetCount;
