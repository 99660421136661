import { TableColumn } from 'components';
import { useKeyExistsState, useLocalStorageState } from 'hooks';
import { useMemo, useState } from 'react';

const getResizedWidth = ({ columnsByKey, deltaX, key, prevResizedWidths }) => {
  const prevWidth = prevResizedWidths[key] || columnsByKey[key].width;
  return Math.max(deltaX + prevWidth, 40);
};

type State = {
  resizedWidths: { [key: string]: number };
  selectedColumns: { [key: string]: number };
};

type Args = {
  columns: TableColumn<any>[];
  initialState: State;
  key: string;
  customColumnsState?: ReturnType<typeof useKeyExistsState>;
  onSelectedColumnToggle?: (args) => void;
  shouldUseLocalStorage?: boolean;
};

const useColumnsState = ({
  columns,
  key,
  initialState,
  onSelectedColumnToggle,
  customColumnsState,
  shouldUseLocalStorage = true,
}: Args) => {
  const [regularState, setRegularState] = useState(initialState);
  const [localState, setLocalState, localIsReady] = useLocalStorageState(
    key,
    initialState,
  );

  const state = shouldUseLocalStorage ? localState : regularState;
  const setState = shouldUseLocalStorage ? setLocalState : setRegularState;
  const isReady = shouldUseLocalStorage ? localIsReady : true;

  const columnsByKey = useMemo(() => {
    return columns.reduce(
      (obj, column) => ({ ...obj, [column.key]: column }),
      {},
    );
  }, [columns]);

  const resizeColumnByKey = (key: string, deltaX) => {
    setState((prevState) => ({
      ...prevState,
      resizedWidths: {
        ...prevState.resizedWidths,
        [key]: getResizedWidth({
          columnsByKey,
          deltaX,
          key,
          prevResizedWidths: prevState.resizedWidths,
        }),
      },
    }));
  };

  const setSelectedColumnByKey = (key: string) => {
    setState((prevState) => {
      const isSelected = 1;
      if (onSelectedColumnToggle) {
        onSelectedColumnToggle({ key, isSelected });
      }

      return {
        ...prevState,
        selectedColumns: {
          ...prevState.selectedColumns,
          [key]: isSelected,
        },
      };
    });
  };

  const toggleSelectedColumnByKey = (key: string) => {
    setState((prevState) => {
      const isSelected = prevState.selectedColumns[key] ? 0 : 1;
      if (onSelectedColumnToggle) {
        onSelectedColumnToggle({ key, isSelected });
      }

      const customColumnKey = customColumnsState?.state?.[key];

      if (customColumnKey) {
        customColumnsState.toggleKeyExists(key);
      }

      return {
        ...prevState,
        selectedColumns: {
          ...prevState.selectedColumns,
          [key]: isSelected,
        },
      };
    });
  };

  return {
    columns,
    isReady,
    renderedColumns: columns.filter(
      (column) => state.selectedColumns[column.key],
    ),
    resizeColumnByKey,
    setSelectedColumnByKey,
    state,
    setState,
    toggleSelectedColumnByKey,
  };
};

export default useColumnsState;
