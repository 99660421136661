import { useCallback, useEffect } from 'react';
import { domUtil } from 'utils';

/**
 * Manages scrolling behavior inside a portal in nested scrollable containers.
 * Adjusts the portal's container (fixed container) position based on scroll direction
 * and position to maintain a natural scroll experience.
 */
const usePortalScroll = ({
  fixedContainerRef,
  relativeContainerRef,
  fallbackContainerRef,
  parentScrollContainerRef,
}: {
  fixedContainerRef: React.MutableRefObject<HTMLElement>;
  relativeContainerRef: React.MutableRefObject<HTMLElement>;
  fallbackContainerRef: React.MutableRefObject<HTMLElement>;
  parentScrollContainerRef: React.MutableRefObject<HTMLElement>;
}) => {
  useEffect(() => {
    const handler = () => {
      if (!fixedContainerRef.current || !relativeContainerRef.current) {
        return;
      }

      domUtil.updateTop(
        fixedContainerRef.current,
        domUtil.getTop(relativeContainerRef.current),
      );
    };

    const parentScrollContainer = parentScrollContainerRef.current;
    const fallbackScrollContainer = fallbackContainerRef.current;
    if (parentScrollContainer) {
      parentScrollContainer.addEventListener('scroll', handler);
      if (parentScrollContainer !== fallbackScrollContainer) {
        fallbackScrollContainer?.addEventListener('scroll', handler);
      }
    }

    return () => {
      if (parentScrollContainer) {
        parentScrollContainer.removeEventListener('scroll', handler);
        if (parentScrollContainer !== fallbackScrollContainer) {
          fallbackScrollContainer?.removeEventListener('scroll', handler);
        }
      }
    };
  }, [
    fixedContainerRef,
    relativeContainerRef,
    fallbackContainerRef,
    parentScrollContainerRef,
  ]);

  return useCallback(
    (event: React.WheelEvent<HTMLDivElement>) => {
      const isScrollingUp = domUtil.isTryingToScrollUp(event);
      const isScrollingDown = domUtil.isTryingToScrollDown(event);
      const isCurrentTargetAtTop = domUtil.isScrolledToTop(event.currentTarget);
      const isCurrentTargetAtBottom = domUtil.isScrolledToBottom(
        event.currentTarget,
      );

      if (
        !(isCurrentTargetAtTop && isScrollingUp) &&
        !(isCurrentTargetAtBottom && isScrollingDown)
      ) {
        return;
      }

      if (
        parentScrollContainerRef.current !== fallbackContainerRef.current &&
        domUtil.isVerticallyScrollable(parentScrollContainerRef.current) &&
        ((isScrollingUp &&
          !domUtil.isScrolledToTop(parentScrollContainerRef.current)) ||
          (isScrollingDown &&
            !domUtil.isScrolledToBottom(parentScrollContainerRef.current)))
      ) {
        parentScrollContainerRef.current.scrollTop += event.deltaY;
      } else {
        fallbackContainerRef.current.scrollTop += event.deltaY;
      }

      if (!fixedContainerRef.current || !relativeContainerRef.current) {
        return;
      }

      domUtil.updateTop(
        fixedContainerRef.current,
        domUtil.getTop(relativeContainerRef.current),
      );
    },
    [
      fixedContainerRef,
      relativeContainerRef,
      fallbackContainerRef,
      parentScrollContainerRef,
    ],
  );
};

export default usePortalScroll;
