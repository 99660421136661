import { Button, PopoverTriggerV2, TooltipTrigger } from 'components';
import type { Focusable } from 'components';
import { useLogsMetricsQueryState } from 'hooks';
import React, { ReactElement, useRef } from 'react';
import { MdFunctions } from 'react-icons/md';
import { logsFunctionNames } from 'utils';
import { FunctionName } from 'types/MetricsQueryBuilder';

import MetricsQueryBuilderFunctionsPanel from '../MetricsQueryBuilder/MetricsQueryBuilderFunctionsPanel';
import LogsMetricsQueryBuilderFunctionsParam from './LogsMetricsQueryBuilderFunctionsParam';

const LogsMetricsQueryBuilderFunctions = ({
  blockedFunctionsCategories,
  blockedFunctionsNames,
  logsMetricsQueryState,
  queryIndex,
  minStep,
}: {
  blockedFunctionsCategories?: string[];
  blockedFunctionsNames?: Array<FunctionName>;
  logsMetricsQueryState: ReturnType<typeof useLogsMetricsQueryState>;
  queryIndex: number;
  minStep: string;
}): ReactElement => {
  const { addFunction, queries } = logsMetricsQueryState;
  const searchInputRef = useRef<Focusable | null>(null);

  return (
    <>
      <LogsMetricsQueryBuilderFunctionsParam
        logsMetricsQueryState={logsMetricsQueryState}
        queryIndex={queryIndex}
        query={queries[queryIndex]}
        minStep={minStep}
      />
      <PopoverTriggerV2
        popover={({ close }) => (
          <MetricsQueryBuilderFunctionsPanel
            blockedFunctionsCategories={blockedFunctionsCategories}
            blockedFunctionsNames={blockedFunctionsNames}
            functionsNames={logsFunctionNames}
            onFunctionClick={(functionName: string) => {
              addFunction(queryIndex, functionName);
              close();
            }}
            searchInputRef={searchInputRef}
          />
        )}
        offsetY={-4}
      >
        <div className="button-group">
          <div
            ref={searchInputRef}
            className="button-group__item button-group__item--unpadded"
          >
            <TooltipTrigger className="h-full" tooltip="Add functions">
              <Button className="h-full" variant="icon" size="sm">
                <MdFunctions size={18} />
              </Button>
            </TooltipTrigger>
          </div>
        </div>
      </PopoverTriggerV2>
    </>
  );
};

export default LogsMetricsQueryBuilderFunctions;
