import classnames from 'classnames';
import React from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';

export enum RumPerformancePanelType {
  OVERVIEW = 'overview',
  PERFORMANCE = 'performance',
  ERRORS = 'errors',
  // DEPLOYMENT = 'deployment',
  // API = 'api',
}

export enum RumPerformanceSubtab {
  overview = 'overview',
  performance = 'performance',
  errors = 'errors',
  // deployment = 'deployment',
  // api = 'api',
}

const labelByVisualizeAs: { [key: string]: string } = {
  [RumPerformanceSubtab.overview]: 'Overview',
  [RumPerformanceSubtab.performance]: 'Performance',
  [RumPerformanceSubtab.errors]: 'Errors',
  // [RumPerformanceSubtab.deployment]: 'Deployments',
  // [RumPerformanceSubtab.api]: 'API',
};

const SUPPORTED_VISUALIZE_AS = [
  RumPerformancePanelType.OVERVIEW,
  RumPerformancePanelType.PERFORMANCE,
  RumPerformancePanelType.ERRORS,
  // RumPerformancePanelType.DEPLOYMENT,
  // RumPerformancePanelType.API,
];

const RumApplicationPerformanceTab = () => {
  const [searchParams] = useSearchParams();
  const searchParamsString = searchParams.toString();

  return (
    <div className="rum-search__visualize button-group">
      {[...SUPPORTED_VISUALIZE_AS].map((rumTab) => (
        <NavLink
          className={({ isActive }) =>
            classnames({
              'button-group__item': true,
              'button-group__item--active': isActive,
            })
          }
          key={rumTab}
          to={`/rum/performance-monitoring/${rumTab}${
            searchParamsString ? `?${searchParamsString}` : ''
          }`}
        >
          {labelByVisualizeAs[rumTab]}
        </NavLink>
      ))}
    </div>
  );
};

export default RumApplicationPerformanceTab;
