import { eventsFacets } from 'kfuse-constants';
import dayjs from 'dayjs';
import {
  DashboardPanelType,
  DateSelection,
  EventCountDataProps,
  FilterProps,
  SelectedFacetValuesByName,
} from 'types';
import {
  DataFrameMeta,
  DataTransformerConfig,
  getAdjustedStartAndEndTimeUnix,
  getRollupToSecond,
  getRollupByVisualization,
} from 'utils';

import queryEventService from './queryEventService';
import {
  buildEventsFilter,
  formatEventsChartInTimeseries,
  formatEventsInTable,
} from './utils';

type Args = FilterProps & {
  countUnique?: string;
  date: DateSelection;
  filterByFacets?: any;
  groupBys?: string[];
  meta?: DataFrameMeta;
  returnFormat?: DashboardPanelType;
  rollUp?: string;
  searchTerms: string[];
  selectedFacetValuesByName: SelectedFacetValuesByName;
  topOrBottomK?: { type: 'Top' | 'Bottom'; k: number };
  transformer?: DataTransformerConfig[];
};

const eventStackedCounts = async ({
  countUnique,
  date,
  filterByFacets = {},
  groupBys,
  meta = {},
  returnFormat = DashboardPanelType.TIMESERIES,
  rollUp,
  searchTerms = [],
  selectedFacetValuesByName,
  topOrBottomK,
  transformer,
}: Args): Promise<any> => {
  const { startTimeUnix, endTimeUnix } = date;
  const durationSecs = endTimeUnix - startTimeUnix;
  const filterQuery = buildEventsFilter(
    filterByFacets,
    searchTerms,
    selectedFacetValuesByName,
  );

  const groupBysArray = groupBys.map((groupBy) => {
    return eventsFacets.includes(groupBy) ? `@${groupBy}` : groupBy;
  });

  const stepSecond = getRollupByVisualization(date, 'bar');
  const roundSecs = rollUp ? getRollupToSecond(rollUp) : stepSecond;

  const adjusted = getAdjustedStartAndEndTimeUnix({
    date,
    step: `${stepSecond}s`,
  });
  const endTime = dayjs.unix(adjusted.endTimeUnix);
  meta.executedDate = adjusted;

  const eventLevelCounts = await queryEventService<
    EventCountDataProps[],
    'eventCounts'
  >(`
    {
    eventCounts(
        durationSecs: ${durationSecs},
        filter: ${filterQuery},
        ${rollUp ? `roundSecs: ${roundSecs}` : ''},
        ${
          groupBys.length > 0
            ? `groupBys: ["${groupBysArray.join('","')}"]`
            : ''
        }
        ${
          countUnique
            ? `countUnique: "${
                eventsFacets.includes(countUnique) ? '@' : ''
              }${countUnique}"`
            : ''
        },
        timestamp: "${endTime.format()}",
        ${
          topOrBottomK
            ? `topOrBottomK: { type: ${topOrBottomK.type}, k: ${topOrBottomK.k}}`
            : ''
        }
        limit: 9999
      ) {
        timestamp
        count
        values
      }
    }
  `)
    .then((data) => data.eventCounts || [])
    .then((res) => {
      if (transformer) {
        const intialData = { datasets: res, meta };
        return transformer.reduce((acc, transformer) => {
          return transformer.func(acc);
        }, intialData);
      }

      if (
        returnFormat === DashboardPanelType.TABLE ||
        returnFormat === DashboardPanelType.TOP_LIST ||
        returnFormat === DashboardPanelType.PIECHART
      ) {
        return formatEventsInTable(res, groupBys, returnFormat);
      }

      return formatEventsChartInTimeseries(res, roundSecs, date, groupBys);
    });

  return eventLevelCounts;
};

export default eventStackedCounts;
