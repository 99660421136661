import {
  CheckboxWithLabel,
  Icon,
  PopoverTriggerV2,
  SelectV2,
} from 'components';
import formatDuration from 'format-duration';
import { useToggle } from 'hooks';
import React from 'react';
import { RumEventType } from 'screens/Rum';
import { colorByRumEventType } from './constants';
import RumReplayControlsBar from './RumReplayControlsBar';
import RumReplayEventsFilterPanel from './RumReplayEventsFilterPanel';
import RumReplaySessionController from './RumReplaySessionController';
import { useRumReplayStateContext } from './RumReplayStateContext';
import { RumEvent, Session } from './types';

type Props = {
  events: RumEvent[];
  session: Session;
};

const RumReplayControls = ({ events, session }: Props) => {
  const showSessionBreakDownToggle = useToggle();
  const { startTimeUnixMs, endTimeUnixMs } = session;
  const totalMs = endTimeUnixMs - startTimeUnixMs;
  const {
    actions,
    currentTimeState,
    hoverPercentState,
    isPlayingToggle,
    speedState,
  } = useRumReplayStateContext();

  const { pause, play, setSpeed } = actions;

  const [currentTime] = currentTimeState;
  const [_, setHoverPercent] = hoverPercentState;
  const [speed] = speedState;

  const togglePlay = () => {
    if (isPlayingToggle.value) {
      pause();
    } else {
      play();
    }
  };

  const onMouseLeave = () => {
    setHoverPercent(null);
  };

  return (
    <div className="rum-replay__controls" onMouseLeave={onMouseLeave}>
      {showSessionBreakDownToggle.value ? (
        <RumReplaySessionController session={session} />
      ) : (
        <RumReplayControlsBar session={session} />
      )}
      <div className="rum-replay__controls__bottom">
        <div className="rum-replay__controls__bottom__left">
          <div
            className="rum-replay__controls__play-pause"
            onClick={togglePlay}
          >
            {isPlayingToggle.value ? (
              <Icon icon="pause" />
            ) : (
              <Icon icon="play" />
            )}
          </div>
          <div className="rum-replay__time">
            {`${formatDuration(currentTime)} / ${formatDuration(totalMs)}`}
          </div>
        </div>
        <div className="rum-replay__controls__bottom__right">
          <div className="rum-replay__controls__bottom__item">
            {session.tabs.length > 1 ? (
              <CheckboxWithLabel
                label="Show session breakdown"
                onChange={showSessionBreakDownToggle.set}
                value={showSessionBreakDownToggle.value}
              />
            ) : null}
          </div>
          <div className="rum-replay__controls__bottom__item">
            <SelectV2.Select
              className="select--thin"
              onChange={setSpeed}
              options={[1, 2, 4, 8].map((value) => ({
                label: `${value}x`,
                value,
              }))}
              top
              value={speed}
            />
          </div>
          <div className="rum-replay__controls__bottom__item">
            <PopoverTriggerV2 popover={() => <RumReplayEventsFilterPanel />}>
              <Icon icon="settings" />
            </PopoverTriggerV2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RumReplayControls;
