import { produce } from 'immer';
import { ActionType, Reducer } from './types';

const reducer: Reducer = produce((draft, action) => {
  switch (action.type) {
    case ActionType.SET_EXPRESSIONS: {
      const { expressions } = action.payload;
      return expressions;
    }
    case ActionType.CREATE_EXPRESSION: {
      const { property, operator } = action.payload;
      draft.push({ property, operator, values: [''] });
      break;
    }
    case ActionType.SET_PROPERTY: {
      const { index, property } = action.payload;
      draft[index].property = property;
      break;
    }
    case ActionType.SET_OPERATOR: {
      const { index, operator } = action.payload;
      draft[index].operator = operator;
      break;
    }
    case ActionType.SET_VALUE: {
      const { index, valueIndex, value } = action.payload;
      draft[index].values ||= [];
      draft[index].values[valueIndex] = value;
      break;
    }
    case ActionType.REMOVE_VALUE: {
      const { index, valueIndex } = action.payload;
      if (!draft[index]?.values) {
        return;
      }
      draft[index].values.splice(valueIndex, 1);
      break;
    }
    case ActionType.REMOVE_EXPRESSION: {
      const { index } = action.payload;
      draft.splice(index, 1);
      break;
    }
    default:
      return draft;
  }
});

export default reducer;
