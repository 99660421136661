import { DataFrame } from '../types';
import timeseriesDataTransformer from '../visualTransformer/timeseriesDataTransformer';
import { labelTransformer } from '../labelTransformer';
import shiftForecastBandTimeseriesTimestamps from './shiftForecastBandTimeseriesTimestamps';
import adjustForecastTimeseriesTimestamp from './adjustForecastTimeseriesTimestamp';
import mergeTwoTimeSeries from '../visualTransformer/mergeTwoTimeSeries';

const ANOMALY_RESULT_TYPE = 'result_type';

const logsForecastDataTransformer = ({
  dataFrame,
  forecastStartTimeUnix,
}: {
  dataFrame: DataFrame;
  forecastStartTimeUnix: number;
}) => {
  const { data, meta, maxValue, minValue, timestamps } = dataFrame;

  const base: DataFrame = { meta, data: [], maxValue, minValue, timestamps };
  const forecast: DataFrame = {
    meta,
    data: [],
    maxValue,
    minValue,
    timestamps,
  };
  const upper: DataFrame = { meta, data: [], maxValue, minValue, timestamps };
  const lower: DataFrame = { meta, data: [], maxValue, minValue, timestamps };

  data.forEach((item) => {
    const { label } = item;
    if (
      label[ANOMALY_RESULT_TYPE] !== 'forecast' &&
      label[ANOMALY_RESULT_TYPE] !== 'upper' &&
      label[ANOMALY_RESULT_TYPE] !== 'lower'
    ) {
      base.data.push(item);
    }
    if (label[ANOMALY_RESULT_TYPE] === 'forecast') {
      delete item.label[ANOMALY_RESULT_TYPE];
      forecast.data.push(item);
    }
    if (meta.forecastSeasonality) {
      if (label[ANOMALY_RESULT_TYPE] === 'upper') {
        delete item.label[ANOMALY_RESULT_TYPE];
        upper.data.push(item);
      }
      if (label[ANOMALY_RESULT_TYPE] === 'lower') {
        delete item.label[ANOMALY_RESULT_TYPE];
        lower.data.push(item);
      }
    }
  });

  const baseLabelTransformed = labelTransformer(base);
  const forecastLabelTransformed = labelTransformer(forecast);

  const baseData = timeseriesDataTransformer(baseLabelTransformed);
  const forecastShiftedDataFrame = adjustForecastTimeseriesTimestamp({
    dataFrame: forecastLabelTransformed,
    startTimeUnix: forecastStartTimeUnix,
    step: meta.step,
  });

  const forecastData = timeseriesDataTransformer(forecastShiftedDataFrame);
  const mergedData = mergeTwoTimeSeries({
    timeSeries1: baseData,
    timeSeries2: forecastData,
  });

  if (meta.forecastSeasonality) {
    const upperShiftedDataFrame = adjustForecastTimeseriesTimestamp({
      dataFrame: upper,
      startTimeUnix: forecastStartTimeUnix,
      step: meta.step,
    });
    const lowerShiftedDataFrame = adjustForecastTimeseriesTimestamp({
      dataFrame: lower,
      startTimeUnix: forecastStartTimeUnix,
      step: meta.step,
    });

    const lowerLabelTransformed = labelTransformer(lowerShiftedDataFrame);
    const upperLabelTransformed = labelTransformer(upperShiftedDataFrame);
    const lowerData = timeseriesDataTransformer(lowerLabelTransformed);
    const upperData = timeseriesDataTransformer(upperLabelTransformed);
    const { lowerShifted, upperShifted } =
      shiftForecastBandTimeseriesTimestamps({
        lower: lowerData,
        upper: upperData,
        shiftByTimestamps: baseData.data[0],
      });
    return [upperShifted, lowerShifted, mergedData];
  }
  return [mergedData];
};

export default logsForecastDataTransformer;
