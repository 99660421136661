import { Button, Loader, MultiselectWithoutDropdown } from 'components';
import delimiter from 'kfuse-constants/delimiter';
import { useAutocompleteState, useFilterState } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';

const PANEL_BORDER_OFFSET = 2;

const SearchBarEditFacetValue = ({
  autocompleteState,
  filterState,
  panelWidth,
}: {
  autocompleteState: ReturnType<typeof useAutocompleteState>;
  filterState: ReturnType<typeof useFilterState>;
  panelWidth: number;
}): ReactElement => {
  const [value, setValue] = useState<string[]>([]);
  const {
    autocompleteOptions,
    editSearch,
    initialOptions,
    requestMap,
    setEditSearch,
    setAutocompleteOptions,
  } = autocompleteState;
  const { filter, removeMappedFilterByKey, updateMappedFilterByKey } =
    filterState;
  const { filterKey, facetName, isDeselected } = editSearch;
  const componentAndName = facetName.split(delimiter).join(':');

  const loadFacetValuesAndSet = async (): any => {
    const option = initialOptions.find(
      (option) => option.value === componentAndName,
    );
    if (!option) {
      return [];
    }

    setAutocompleteOptions((prev) => {
      return { ...prev, [facetName]: { options: [], isLoading: true } };
    });
    const request = requestMap[option.optionType];
    const valueOptions = await request(option, { sidebarFilters: true });
    setAutocompleteOptions((prev) => {
      return {
        ...prev,
        [facetName]: { options: valueOptions, isLoading: false },
      };
    });

    return valueOptions;
  };

  const setInitialValue = () => {
    const filterValue = filter[filterKey];
    if (filterValue) {
      const selectedFacetValues = filterValue[facetName];
      if (selectedFacetValues) {
        setValue(Object.keys(selectedFacetValues));
      }
    }
  };

  const apply = () => {
    if (!value.length) {
      removeMappedFilterByKey(filterKey, facetName);
      setEditSearch(null);
      return;
    }
    const newFacetFilter: { [key: string]: number } = {};
    value.forEach((valueItem) => {
      newFacetFilter[valueItem] = isDeselected ? 0 : 1;
    });
    updateMappedFilterByKey(filterKey, facetName, newFacetFilter);
    setEditSearch(null);
  };

  useEffect(() => {
    loadFacetValuesAndSet();
    setInitialValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSearch]);

  return (
    <div tabIndex={1} className="search-bar__panel">
      <div
        className="search-bar__panel__container"
        style={{ width: panelWidth + PANEL_BORDER_OFFSET, maxHeight: 500 }}
      >
        <div className="search-bar__panel__edit-container">
          <div className="search-bar__panel__edit-container__title">
            <b>{componentAndName}</b>
            {` to ${isDeselected ? 'exclude' : 'include'}`}
          </div>
          <Loader
            isLoading={autocompleteOptions[facetName]?.isLoading || false}
          >
            <div className="logs__search-bar__edit__facet-value__box">
              <div>
                <MultiselectWithoutDropdown
                  maxLength={30}
                  options={autocompleteOptions[facetName]?.options || []}
                  onChange={setValue}
                  placeholder="Search for a value"
                  value={value}
                />
              </div>
              <div className="logs__search-bar__edit__facet-value__buttons">
                <Button
                  variant="outline"
                  size="sm"
                  className="logs__search-bar__edit__facet-value__buttons__button"
                  onClick={() => setEditSearch(null)}
                >
                  Cancel
                </Button>
                <Button
                  variant="default"
                  size="sm"
                  className="logs__search-bar__edit__facet-value__buttons__button"
                  onClick={apply}
                >
                  Apply
                </Button>
              </div>
            </div>
          </Loader>
        </div>
      </div>
    </div>
  );
};

export default SearchBarEditFacetValue;
