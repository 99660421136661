import classnames from 'classnames';
import React, { ReactNode, useEffect } from 'react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { AiOutlineLineChart } from 'react-icons/ai';
import { ImTable } from 'react-icons/im';
import { DashboardPanelType } from 'types';
import { BsFilterLeft, BsPieChartFill } from 'react-icons/bs';
import { HiTableCells } from 'react-icons/hi2';
import { RumEventType, RumTab } from '../types';
import { TbLayoutBoardSplit } from 'react-icons/tb';
import { FaGlobeAmericas } from 'react-icons/fa';
import useRumState from '../hooks/useRumState';

const iconByVisualizeAs: { [key: string]: ReactNode } = {
  [RumTab.list]: <ImTable size={14} />,
  [DashboardPanelType.TIMESERIES]: <AiOutlineLineChart size={14} />,
  [DashboardPanelType.TOP_LIST]: <BsFilterLeft size={14} />,
  [DashboardPanelType.TABLE]: <HiTableCells size={14} />,
  [DashboardPanelType.PIECHART]: <BsPieChartFill size={14} />,
  [RumTab.geomap]: <FaGlobeAmericas size={14} />,
  [DashboardPanelType.TREEMAP]: <TbLayoutBoardSplit size={14} />,
};

const labelByVisualizeAs: { [key: string]: string } = {
  [RumTab.list]: 'List',
  [DashboardPanelType.TIMESERIES]: 'Time Series',
  [DashboardPanelType.TOP_LIST]: 'Top List',
  [DashboardPanelType.TABLE]: 'Table',
  [DashboardPanelType.PIECHART]: 'Pie Chart',
  [DashboardPanelType.GEOMAP]: 'Geo Map',
  [DashboardPanelType.TREEMAP]: 'Tree Map',
};

const SUPPORTED_VISUALIZE_AS = [
  DashboardPanelType.TIMESERIES,
  DashboardPanelType.TOP_LIST,
  DashboardPanelType.TABLE,
  DashboardPanelType.PIECHART,
  DashboardPanelType.GEOMAP,
  DashboardPanelType.TREEMAP,
];

const RumSearchVisualize = ({
  eventType,
  rumState,
  rumTab,
}: {
  eventType: RumEventType;
  rumState: ReturnType<typeof useRumState>;
  rumTab: RumTab;
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const searchParamsString = searchParams.toString();

  useEffect(() => {
    if (
      eventType === RumEventType.SESSION &&
      SUPPORTED_VISUALIZE_AS.includes(rumTab as unknown as DashboardPanelType)
    ) {
      navigate(`/rum/${RumTab.list}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventType]);

  useEffect(() => {
    if (
      eventType === RumEventType.SESSION &&
      SUPPORTED_VISUALIZE_AS.includes(rumTab as unknown as DashboardPanelType)
    ) {
      rumState.setEventType(RumEventType.VIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rumTab]);

  return (
    <div className="rum-search__visualize button-group">
      {[RumTab.list, ...SUPPORTED_VISUALIZE_AS].map((rumTab) => {
        if (
          SUPPORTED_VISUALIZE_AS.includes(
            rumTab as unknown as DashboardPanelType,
          ) &&
          eventType === RumEventType.SESSION
        ) {
          return null;
        }
        return (
          <NavLink
            className={({ isActive }) =>
              classnames({
                'button-group__item': true,
                'button-group__item--active': isActive,
              })
            }
            key={rumTab}
            to={`/rum/${rumTab}${
              searchParamsString ? `?${searchParamsString}` : ''
            }`}
          >
            <div className="button-group__item__icon">
              {iconByVisualizeAs[rumTab]}
            </div>
            {labelByVisualizeAs[rumTab]}
          </NavLink>
        );
      })}
    </div>
  );
};

export default RumSearchVisualize;
