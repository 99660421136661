import classNames from 'classnames';
import { SearchInput } from 'components';
import React, {
  MutableRefObject,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import useMetricsSummary from './useMetricsSummary';
import { filterMetricSummaryList } from './utils';

const MetricsSummaryNameItem = ({
  name,
  selectedMetric,
  onMetricNameChange,
  userActions,
}: {
  name: string;
  selectedMetric: string;
  onMetricNameChange: (name: string) => void;
  userActions: MutableRefObject<{
    initialScrolled: boolean;
  }>;
}) => {
  const metricsListItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedMetric === name && !userActions.current.initialScrolled) {
      metricsListItemRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      userActions.current.initialScrolled = true;
    }
  }, [selectedMetric, name, userActions]);

  return (
    <div
      key={name}
      className={classNames({
        'metrics-summary__body__metrics-names__result__item': true,
        'metrics-summary__body__metrics-names__result__item--selected':
          selectedMetric === name,
      })}
      onClick={() => onMetricNameChange(name)}
      ref={metricsListItemRef}
    >
      <div>{name}</div>
    </div>
  );
};

const MetricsSummaryNames = ({
  metricSummaryState,
}: {
  metricSummaryState: ReturnType<typeof useMetricsSummary>;
}): ReactElement => {
  const {
    getMetricsListRequest,
    metricSearch,
    onMetricNameChange,
    selectedMetric,
    setMetricSearch,
    userActions,
  } = metricSummaryState;

  const filteredMetrics = useMemo(() => {
    const { search, origin, type } = metricSearch;
    if (search || origin.length || type.length) {
      return getMetricsListRequest.result?.filter((metric: string) =>
        filterMetricSummaryList(metric, metricSearch),
      );
    }
    return getMetricsListRequest.result || [];
  }, [getMetricsListRequest.result, metricSearch]);

  return (
    <div className="metrics-summary__body__metrics-names">
      <div className="metrics-summary__body__title">METRICS NAME</div>
      <SearchInput
        containerClassName="metrics-summary__body__metrics-names__search-bar"
        onChange={(e) => setMetricSearch((prev) => ({ ...prev, search: e }))}
        placeholder="Search metrics name"
        value={metricSearch.search}
        type="text"
        size="3"
        dataTestId="metrics-search-bar"
      />
      <div className="metrics-summary__body__seperator" />
      <div
        className="metrics-summary__body__metrics-names__result overflow-y-scroll"
        data-testid="metrics-summary-table"
      >
        {filteredMetrics.map((name: string) => (
          <MetricsSummaryNameItem
            key={name}
            name={name}
            selectedMetric={selectedMetric}
            onMetricNameChange={onMetricNameChange}
            userActions={userActions}
          />
        ))}
      </div>
    </div>
  );
};

export default MetricsSummaryNames;
