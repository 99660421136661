import { AutocompleteOption } from 'components';
import React, { ReactElement } from 'react';

import {
  AlertsCreateConditionFor,
  AlertsCreateConditionOf,
  AlertsCreateConditionQueryKey,
  AlertsCreateConditionThreshold,
  AlertsCreateConditionWhen,
} from './AlertsCreateConditionCommon';
import { changeConditionByLabel } from '../AlertsCreateMetrics/utils';
import { useAlertsCreate } from '../hooks';
import { useAlertsCreateCondition } from './hooks';
import { AlertType, RuleType } from '../types';

const AlertsCreateCondition = ({
  alertsCreateState,
  conditionState,
  queryAndFormulaKeysLabel,
  renderWhen,
  unit = 'number',
}: {
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
  queryAndFormulaKeysLabel: AutocompleteOption[];
  renderWhen?: ReactElement;
  unit?: string;
}): ReactElement => {
  const { alertType, evaluate, setEvaluate, ruleType } = alertsCreateState;

  return (
    <div>
      <div className="alerts__create__section__item">
        <div className="alerts__create__conditions__item__text">
          Trigger when
        </div>
        {renderWhen || (
          <AlertsCreateConditionWhen conditionState={conditionState} />
        )}
        <div className="alerts__create__conditions__item__text">
          evaluated value {ruleType === RuleType.METRICS ? 'of' : ''}{' '}
        </div>
        <AlertsCreateConditionQueryKey
          conditionState={conditionState}
          queryAndFormulaKeysLabel={queryAndFormulaKeysLabel}
        />
        <div className="alerts__create__change__item__text">is</div>
        <AlertsCreateConditionOf
          conditionState={conditionState}
          options={
            alertType === AlertType.THRESHOLD
              ? changeConditionByLabel
              : undefined
          }
        />
        <AlertsCreateConditionThreshold
          conditionState={conditionState}
          unit={unit}
          alertType={alertType}
        />
        <div className="alerts__create__change__item__text">over</div>
        <AlertsCreateConditionFor
          evaluate={evaluate}
          ruleType={ruleType}
          setEvaluate={setEvaluate}
        />
      </div>
    </div>
  );
};

export default AlertsCreateCondition;
