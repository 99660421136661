import { DateSelection } from 'types/DateSelection';
import { convertSecondToReadable } from './timeCodeToUnix';

/**
 * Get the second given a rollup
 * @param rollup string
 * @returns number
 * @example 1s -> 1
 * @example 10s -> 10
 * @example 1m -> 60
 * @example 3m -> 600
 */
export const getRollupToSecond = (rollup: string): number => {
  const splitRollup = rollup.split('');
  let unit = '';
  if (rollup.includes('ms')) {
    unit = 'ms';
    splitRollup.pop();
    splitRollup.pop();
  } else {
    unit = splitRollup.pop();
  }
  const multiplier = getRollupUnitMultiplier(unit);
  return Number(splitRollup.join('')) * multiplier;
};

/**
 *
 * @param rollup string
 * @returns string
 * @example 1000ms -> 1s
 * @example 1000s -> 1m
 * @example 120000ms -> 2m
 * @example 120000s -> 2h
 * @example 120000m -> 2d
 */
export const getRollupToMinute = (rollup: string): string => {
  const second = getRollupToSecond(rollup);

  if (second < 1) return `${second * 1000}ms`;

  return second < 60 ? `${second}s` : `${Math.round(second / 60)}m`;
};

export const getRollupUnitMultiplier = (unit: string): number => {
  if (unit === 'ms') return 0.001;
  if (unit === 's') return 1;
  if (unit === 'm') return 60;
  if (unit === 'h') return 3600;
  if (unit === 'd') return 86400;
  if (unit === 'w') return 604800;
  if (unit === 'M') return 2592000;
  if (unit === 'y') return 31536000;
  return 1;
};

const rollupForLineChart = (timeframeInSeconds: number): number => {
  if (timeframeInSeconds <= 60 * 5) return 1; // 0s to 300s -> 1s
  if (timeframeInSeconds <= 60 * 12) return 2; // 6 minutes to 12 minutes -> 2s
  if (timeframeInSeconds <= 60 * 25) return 5; // 13 minutes to 25 minutes -> 5s
  if (timeframeInSeconds <= 60 * 50) return 10; // 26 minutes to 50 minutes -> 10s
  if (timeframeInSeconds <= 60 * 75) return 20; // 51 minutes to 75 minutes -> 20s
  if (timeframeInSeconds <= 60 * 150) return 30; // 76 minutes to 150 minutes -> 30s
  if (timeframeInSeconds <= 60 * 60 * 5) return 60; // 151 minutes to 300 minutes -> 1m
  if (timeframeInSeconds <= 60 * 60 * 12.5) return 120; // 301 minutes to 750 minutes -> 2m
  if (timeframeInSeconds <= 60 * 60 * 25) return 300; // 751 minutes to 25 hours -> 5m
  if (timeframeInSeconds <= 60 * 60 * 50) return 600; // 26 hours to 50 hours -> 10m
  if (timeframeInSeconds <= 60 * 60 * 75) return 1200; // 51 hours to 75 hours -> 20m
  if (timeframeInSeconds <= 60 * 60 * 150) return 1800; // 76 hours to 150 hours -> 30m
  if (timeframeInSeconds <= 60 * 60 * 300) return 3600; // 151 hours to 300 hours -> 1h
  if (timeframeInSeconds <= 60 * 60 * 24 * 25) return 7200; // 301 hours to 25 days -> 2h
  if (timeframeInSeconds <= 60 * 60 * 24 * 50) return 14400; // 26 days to 50 days -> 4h
  if (timeframeInSeconds <= 60 * 60 * 24 * 75) return 28800; // 50 days to 75 days -> 8h
  if (timeframeInSeconds <= 60 * 60 * 24 * 150) return 43200; // 75 days to 150 days -> 12h
  if (timeframeInSeconds <= 60 * 60 * 24 * 365) return 86400; // 150+ days to 365 days -> 24h
  if (timeframeInSeconds <= 60 * 60 * 24 * 365 * 2) return 86400 * 3; // 365+ days to 730 days -> 3d
  return 86400 * 7; // 730+ days -> 1w
};

const rollupForBarChart = (timeframeInSeconds: number): number => {
  if (timeframeInSeconds <= 60) return 1; // 0s to 60s -> 1s
  if (timeframeInSeconds <= 60 * 4) return 2; // 61s to 4 minutes -> 2s
  if (timeframeInSeconds <= 60 * 9) return 5; // 5 minutes to 9 minutes -> 5s
  if (timeframeInSeconds <= 60 * 16) return 10; // 10 minutes to 16 minutes -> 10s
  if (timeframeInSeconds <= 60 * 25) return 20; // 17 minutes to 25 minutes -> 20s
  if (timeframeInSeconds <= 60 * 50) return 30; // 26 minutes to 50 minutes -> 30s
  if (timeframeInSeconds <= 60 * 100) return 60; // 51 minutes to 100 minutes -> 1m
  if (timeframeInSeconds <= 60 * 250) return 120; // 101 minutes to 250 minutes -> 2m
  if (timeframeInSeconds <= 60 * 500) return 300; // 251 minutes to 500 minutes -> 5m
  if (timeframeInSeconds <= 60 * 1000) return 600; // 501 minutes to 1000 minutes -> 10m
  if (timeframeInSeconds <= 60 * 60 * 25) return 1200; // 1001 minutes to 25 hours -> 20m
  if (timeframeInSeconds <= 60 * 60 * 50) return 1800; // 26 hours to 50 hours -> 30m
  if (timeframeInSeconds <= 60 * 60 * 100) return 3600; // 51 hours to 100 hours -> 1h
  if (timeframeInSeconds <= 60 * 60 * 200) return 7200; // 101 hours to 200 hours -> 2h
  if (timeframeInSeconds <= 60 * 60 * 400) return 14400; // 201 hours to 400 hours -> 4h
  if (timeframeInSeconds <= 60 * 60 * 24 * 25) return 28800; // 401 hours to 25 days -> 8h
  if (timeframeInSeconds <= 60 * 60 * 24 * 50) return 43200; // 26 days to 50 days -> 12h
  if (timeframeInSeconds <= 60 * 60 * 24 * 365) return 86400; // 51 days to 365 days -> 24h
  if (timeframeInSeconds <= 60 * 60 * 24 * 365 * 2) return 86400 * 3; // 365+ days to 730 days -> 3d
  return 86400 * 7; // 730+ days -> 1w
};

export const getRollupByVisualization = (
  date: DateSelection,
  visualization: 'line' | 'bar' | 'Line' | 'Stacked Bar' = 'line',
): number => {
  const { startTimeUnix, endTimeUnix } = date;
  const timeframeInSeconds = endTimeUnix - startTimeUnix;
  if (visualization === 'line' || visualization === 'Line')
    return rollupForLineChart(timeframeInSeconds);
  return rollupForBarChart(timeframeInSeconds);
};

export const getRollupByVisualizationByMinLimit = (
  date: DateSelection,
  visualization: 'line' | 'bar' | 'Line' | 'Stacked Bar' = 'line',
  minLimit: string,
): number => {
  const step = getRollupByVisualization(date, visualization);
  return Math.max(step, getRollupToSecond(minLimit));
};

export const getRollupByVisualizationReadable = (
  date: DateSelection,
  visualization: 'line' | 'bar' | 'Line' | 'Stacked Bar' = 'line',
): { step: number; readable: string } => {
  const step = getRollupByVisualization(date, visualization);

  return {
    step,
    readable: convertSecondToReadable(step),
  };
};
