import { Button, TooltipTrigger } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect } from 'react';
import { RefreshCcw as RefreshIcon } from 'react-feather';

import { HydrationJobProps, JobStatus } from './types';
import { jobProgress } from './requests';
import { formatNumberWithCommas } from './utils';

const LogsHydrationJobsListJobProgress = ({
  job,
  onJobStatusChange,
}: {
  job: HydrationJobProps;
  onJobStatusChange: (job: HydrationJobProps) => void;
}): ReactElement => {
  const jobProgressRequest = useRequest(jobProgress);

  const refreshJobProgress = () => {
    jobProgressRequest.call(job.JobId).then((res) => {
      if (res.jobStatus) {
        onJobStatusChange({ ...job, JobStatus: res.jobStatus });
      }
    });
  };

  useEffect(() => {
    if (
      job.JobStatus === JobStatus.HYDRATING ||
      job.JobStatus === JobStatus.INDEXING
    ) {
      refreshJobProgress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job.JobId, job.JobStatus]);

  if (
    job.JobStatus !== JobStatus.HYDRATING &&
    job.JobStatus !== JobStatus.INDEXING
  ) {
    return null;
  }

  return (
    <div>
      {jobProgressRequest.isLoading ? (
        <div className="flex w-full items-center justify-center">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="flex items-center justify-between">
          <TooltipTrigger
            tooltip={
              <div className="flex flex-col p-1">
                <div className="text-sm">
                  Total objects:{' '}
                  {formatNumberWithCommas(
                    jobProgressRequest.result?.totalObjects,
                  )}
                </div>
                <div className="text-sm">
                  Total objects processed:{' '}
                  {formatNumberWithCommas(
                    jobProgressRequest.result?.TotalObjectsProcessed,
                  )}
                </div>
                <div className="text-sm">
                  Total records filtered:{' '}
                  {formatNumberWithCommas(
                    jobProgressRequest.result?.TotalRecordsFiltered,
                  )}
                </div>
                <div className="text-sm">
                  Total records processed:{' '}
                  {formatNumberWithCommas(
                    jobProgressRequest.result?.TotalRecordsProcessed,
                  )}
                </div>
                <div className="text-sm">
                  Total records indexed:{' '}
                  {formatNumberWithCommas(
                    jobProgressRequest.result?.TotalRecordsIndexed,
                  )}
                </div>
              </div>
            }
          >
            <div className="text-sm">
              {jobProgressRequest.result ? (
                <>
                  Processed{' '}
                  {formatNumberWithCommas(
                    jobProgressRequest.result?.TotalObjectsProcessed,
                  )}{' '}
                  /{' '}
                  {formatNumberWithCommas(
                    jobProgressRequest.result?.totalObjects,
                  )}
                </>
              ) : null}
            </div>
          </TooltipTrigger>
          <div className="flex justify-end pl-1">
            <TooltipTrigger tooltip="Refresh">
              <Button
                className="h-7 p-1"
                variant="link"
                onClick={refreshJobProgress}
              >
                <RefreshIcon size={14} />
              </Button>
            </TooltipTrigger>
          </div>
        </div>
      )}
    </div>
  );
};

export default LogsHydrationJobsListJobProgress;
