import formatLogMessage from './formatLogMessage';

const getPart = (logLinePart) => {
  const { facetPart, paramPart, partType, tokenPart } = logLinePart;
  switch (partType) {
    case 'facet':
      return facetPart;
    case 'param':
      return paramPart;
    case 'token':
      return tokenPart;
    default:
      return null;
  }
};

const formatLogLineParts = (logLineParts) => {
  if (!logLineParts) return '';
  return logLineParts.reduce((s, logLinePart) => {
    const part = getPart(logLinePart);
    if (part) {
      return `${s}${formatLogMessage(part.content || '')}`;
    }

    return s;
  }, '');
};

export default formatLogLineParts;
