import uPlot, { Series } from 'uplot';
import { themeColorDark, themeColorLight } from 'utils/colors';

import { convertToReadableTime, formatYAxis } from './axis-formatting';
import { getYAxisSplits } from './axisSplit';
import { ChartType, StrokeType, UplotChartStyles, UplotExtended } from 'types';
import { UPlotConfig } from '../types';

/**
 * Convert hex color to rgba
 */
export const hexToRgba = (hex: string, opacity: number): string => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const addGradientToSeries = (
  series: Series,
): { fill: string; stroke: string } => {
  const { stroke } = series;
  const fill = hexToRgba(stroke as string, 0.1);
  const strokeNew = hexToRgba(stroke as string, 1);

  return { fill, stroke: strokeNew };
};

export const getLineSeries = (
  series: Series[],
  fillOpacity: number,
  pointSize: UplotChartStyles['pointSize'],
  showPoints: UplotChartStyles['showPoints'],
  lineWidth: UplotChartStyles['lineWidth'],
): Series[] => {
  const newSeries: Series[] = [];
  series.forEach((s, i) => {
    const newS = { ...s };
    if (fillOpacity) {
      const { fill, stroke } = addGradientToSeries(s);
      newS.fill = fill;
      newS.stroke = stroke;
    }

    if (showPoints === 'always') {
      newS.points = { show: true, size: pointSize, fill: newS.stroke };
    }

    if (lineWidth !== undefined && lineWidth !== null) {
      newS.width = lineWidth;
    }
    newSeries.push(newS);
  });

  return newSeries;
};

export const getUplotChartBar = (
  config: UPlotConfig,
  data: Array<number[]>,
): Series.PathBuilder => {
  const { bars } = uPlot.paths;
  const xOffset = config.axes[0].size as number;
  const barWidth = Math.min((config.width - xOffset * 2) / data[0]?.length, 60);
  const barMinWidth = Math.max(3, barWidth - barWidth * 0.15);
  return bars({ size: [0.6, 24, barMinWidth] });
};

export const getBarSeries = (
  config: UPlotConfig,
  data: Array<number[]>,
  series: Series[],
): Series[] => {
  const bars60_100 = getUplotChartBar(config, data);
  const newSeries: Series[] = [];
  series.forEach((s, i) => {
    newSeries.push({
      paths: bars60_100,
      points: { show: false },
      fill: s.stroke,
      label: s.label,
      show: s.show,
    });
  });

  return newSeries;
};

export const getAreaSeries = (series: Series[]): Series[] => {
  const newSeries: Series[] = [];
  series.forEach((s, i) => {
    newSeries.push({
      fill: s.stroke,
      label: s.label,
      show: s.show,
      points: { show: false },
    });
  });

  return newSeries;
};

const getCanvasFont = (fontSize: number) => {
  return `${fontSize}px system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji"`;
};

const measureTextInCanvas = (text: string, fontSize: number) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  ctx.font = getCanvasFont(fontSize);

  return ctx.measureText(text).width;
};

export const getChartYAxisWidth = ({
  maxValue,
  minValue,
  unit,
  chartHeight,
  type,
  scaleDistribution,
  offsetWidth = 0,
  yAxisSplits,
}: {
  maxValue: number;
  minValue: number;
  unit: string;
  chartHeight: number;
  type: ChartType;
  scaleDistribution?: UplotChartStyles['scaleDistribution'];
  offsetWidth?: number;
  yAxisSplits?: number[];
}): number => {
  const DEFAULT_LEFT_WIDTH = 20;
  const CHART_LEFT_PADDING = 20;

  // if max or min is not number, return default width
  if (typeof maxValue !== 'number' || typeof minValue !== 'number') {
    return DEFAULT_LEFT_WIDTH + CHART_LEFT_PADDING;
  }

  const BOTTOM_OFFSET = 36;
  const rangeArray =
    yAxisSplits ||
    getYAxisSplits({
      minValue,
      maxValue,
      forceMin: true,
      dimension: chartHeight - BOTTOM_OFFSET,
      scaleDistribution,
      type,
    });

  let largestWidth = DEFAULT_LEFT_WIDTH;
  const yaxisFormat = formatYAxis(rangeArray, unit);
  yaxisFormat.forEach((format) => {
    const width = measureTextInCanvas(format, 12);
    if (width > largestWidth) {
      largestWidth = width;
    }
  });

  const yaxisFormatWidth = largestWidth + CHART_LEFT_PADDING;
  if (scaleDistribution?.type === 'log') {
    return Math.max(
      DEFAULT_LEFT_WIDTH,
      yaxisFormatWidth + CHART_LEFT_PADDING + 8,
    );
  }

  return Math.max(DEFAULT_LEFT_WIDTH, yaxisFormatWidth + offsetWidth);
};

export const getStrokeLineConfig = (strokeType: StrokeType) => {
  switch (strokeType) {
    case 'none':
      return { lineWidth: 0, pointSize: 5, showPoints: 'always' };
    case 'thick':
      return { lineWidth: 2.5 };
    case 'thin':
      return { lineWidth: 0.5 };
    default:
      return {};
  }
};

export const getChartScaleConfig = (
  scaleDistribution: UplotChartStyles['scaleDistribution'],
  type: ChartType,
): UPlotConfig['scales'] => {
  if (scaleDistribution?.type === 'log') {
    return {
      x: { time: true, auto: true },
      y: { distr: 3, auto: true, log: scaleDistribution.log || 2 },
    };
  }

  if (scaleDistribution?.type === 'percent') {
    return {
      y: {
        range: {
          min: { pad: 0.2, soft: 0, hard: 0, mode: 1 },
          max: { pad: 0.2, soft: 0, hard: 100, mode: 2 },
        },
      },
    };
  }

  if (type === 'Stacked Bar' || type === 'Area') {
    // it allows chart to start from 0
    return {
      y: {
        range: {
          min: { pad: 0.2, soft: 0, mode: 1 },
          max: { pad: 0.2, soft: 0, mode: 2 },
        },
      },
    };
  }

  return {};
};

export const getChartXAxisConfig = ({
  darkModeEnabled,
  utcTimeEnabled,
}: {
  darkModeEnabled: boolean;
  utcTimeEnabled: boolean;
}): UPlotConfig['axes'][0] => {
  const { dark05, dark12 } = themeColorDark;
  const { light03, light11 } = themeColorLight;

  return {
    size: 36,
    stroke: darkModeEnabled ? dark12 : light11,
    grid: {
      stroke: darkModeEnabled ? dark05 : light03,
      width: 0.8,
    },
    values: (u, vals, space) => convertToReadableTime({ vals, utcTimeEnabled }),
  };
};

const getMinMaxByArray = (
  arr: (number | undefined)[],
): { min: number; max: number } => {
  let min = Infinity;
  let max = -Infinity;

  for (const val of arr) {
    if (val !== undefined) {
      if (val < min) min = val;
      if (val > max) max = val;
    }
  }

  // Handle case where all values were undefined
  if (min === Infinity) min = 0;
  if (max === -Infinity) max = 0;

  return { min, max };
};

export const getMinMaxForData = (
  data: Array<number[]>,
): { min: number; max: number }[] => {
  if (data.length === 0) {
    return [];
  }
  const dataMinMax: { min: number; max: number }[] = [];
  data.forEach((d, idx) => {
    if (idx === 0) {
      dataMinMax.push({ min: d[0], max: d[d.length - 1] });
      return;
    }
    dataMinMax.push(getMinMaxByArray(d));
  });
  return dataMinMax;
};

export const getYAxisWidthBySeries = ({
  u,
  series,
  unit,
}: {
  u: UplotExtended;
  series: Series[];
  unit: string;
}): number => {
  let minValue = Infinity;
  let maxValue = -Infinity;

  const { dataMinMax } = u;
  series.forEach((s, idx) => {
    if (!s.show) return;
    const { min: sMin, max: sMax } = dataMinMax[idx];
    if (sMin < minValue) minValue = sMin;
    if (sMax > maxValue) maxValue = sMax;
  });

  const yAxisWidth = getChartYAxisWidth({
    maxValue,
    minValue,
    unit,
    chartHeight: u.height,
    type: u.chartType,
  });

  return yAxisWidth;
};

export const getStackedMinMax = (data: Array<number[]>) => {
  // sum all number vertically
  const sumArray = new Array(data[0].length).fill(0);
  data.forEach((d, idx) => {
    if (idx === 0) return; // skip the first array (timestamps)
    d.forEach((num, idx) => {
      if (num !== undefined) {
        sumArray[idx] += num;
      }
    });
  });

  return getMinMaxByArray(sumArray);
};
