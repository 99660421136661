import {
  chartColors,
  fillTimeseriesForWindow,
  getColorForOneSeries,
  CHART_LINE_WIDTH_MEDIUM,
} from 'utils';
import {
  DashboardPanelType,
  DateSelection,
  LogQLDataset,
  LogsMetricQueryProps,
  QueryDataPropsInstant,
  QueryDataPropsRange,
} from 'types';

type LogsDatasetsProps = {
  dataset: LogQLDataset[];
  name?: string;
  limit?: LogsMetricQueryProps['limit'];
  idx?: number;
};

const sortObjectsByAllValues = (data: LogQLDataset[]): any => {
  return data.sort((a, b) => {
    const aValues = Object.values(a.tags).join(',');
    const bValues = Object.values(b.tags).join(',');
    return aValues.localeCompare(bValues);
  });
};

const sortLogsDatasetByAverage = ({ dataset, limit }: LogsDatasetsProps) => {
  if (!limit) return dataset;
  const { direction, count } = limit;
  const sortedDatasetByAverage = dataset.sort((a, b) => {
    const pointA = a['points'];
    const pointB = b['points'];
    const sumA = pointA.reduce((pre, cur) => pre + cur.value, 0);
    const sumB = pointB.reduce((pre, cur) => pre + cur.value, 0);
    const length = pointA.length;
    const comp = sumA / length > sumB / length;
    if (comp && direction === 'topk') return -1;
    if (!comp && direction === 'topk') return 1;
    if (comp && direction === 'bottomk') return 1;
    if (!comp && direction === 'bottomk') return -1;
  });
  if (!count) return sortedDatasetByAverage;
  return sortedDatasetByAverage.slice(0, Number(count));
};

const sortLogsTimeseriesDataset = (
  datasets: LogsDatasetsProps[],
): Array<{ dataset: LogQLDataset[]; name: string }> => {
  return datasets.map((datum) => {
    const { dataset, limit, name, idx } = datum;
    if (!dataset) return { dataset, name, idx };
    if (limit) {
      return { dataset: sortLogsDatasetByAverage(datum), name };
    }
    const sortedDataset = sortObjectsByAllValues(dataset);
    return { dataset: sortedDataset, name, idx };
  });
};

export const formatLogsTimeseriesDataset = ({
  datasets,
  date,
  metricNames,
  queryKey,
  step,
}: {
  datasets: LogsDatasetsProps[];
  date: DateSelection;
  metricNames?: string[];
  queryKey?: string;
  step: number;
}): QueryDataPropsRange => {
  const timestampBitmap: { [key: string]: boolean } = {};
  const data = [];
  const series: QueryDataPropsRange['series'] = [];
  let minValue = Infinity;
  let maxValue = -Infinity;
  const sortedDatasets = sortLogsTimeseriesDataset(datasets);
  sortedDatasets.forEach((datum) => {
    const { dataset } = datum;
    if (!dataset) return;
    dataset.forEach((timeseries) => {
      const { points } = timeseries;
      points.forEach((point) => {
        timestampBitmap[point.ts / 1000] = true;
      });
    });
  });

  if (Object.keys(timestampBitmap).length === 0) {
    return { data: [], series: [], minValue: 0, maxValue: 0 };
  }

  const stepSeconds = step / 1000;
  const timestamps = fillTimeseriesForWindow(
    date,
    stepSeconds,
    timestampBitmap,
  );
  data.push(timestamps);

  let counter = 0;
  const colorStartIdx = Math.floor(Math.random() * chartColors.length);
  sortedDatasets.forEach((datum, datasetIdx: number) => {
    const { dataset, name } = datum;
    if (!dataset) return;
    dataset.forEach((timeseries) => {
      const { points, tags } = timeseries;
      const valueByTimestamp: { [key: string]: number } = points.reduce(
        (obj, point) => ({ ...obj, [point.ts / 1000]: point.value }),
        {},
      );

      const timeseriesData: number[] = [];
      timestamps.map((ts) => {
        if (typeof valueByTimestamp[ts] === 'number') {
          timeseriesData.push(valueByTimestamp[ts]);
          minValue = Math.min(minValue, valueByTimestamp[ts]);
          maxValue = Math.max(maxValue, valueByTimestamp[ts]);
        } else {
          timeseriesData.push(undefined);
        }
      });

      data.push(timeseriesData);
      const metricName = (metricNames && metricNames[datasetIdx]) || '';
      const metricNameStr = metricName ? `${metricName}__` : '';
      series.push({
        label: `${metricNameStr}${name ? name : ''}${
          Object.values(tags)?.length
            ? JSON.stringify(tags)
            : `count_of_${queryKey}`
        }`,
        points: { show: false },
        stroke: getColorForOneSeries(tags, counter, colorStartIdx),
        show: true,
        width: CHART_LINE_WIDTH_MEDIUM,
      });

      counter++;
    });
  });

  return {
    data,
    series,
    minValue: minValue === Infinity ? 0 : minValue,
    maxValue: maxValue === -Infinity ? 0 : maxValue,
  };
};

export const formatLogsInstantQuery = (
  datasets: LogsDatasetsProps,
  queryKey: string,
  returnFormat: string,
): QueryDataPropsInstant => {
  const sortedDataset = sortLogsDatasetByAverage(datasets);
  const tableData: QueryDataPropsInstant['data'] = [];

  const timestampBitmap: { [key: number]: number } = {};
  sortedDataset.forEach((timeseries) => {
    const { points } = timeseries;
    points.forEach((point) => {
      timestampBitmap[point.ts] = 1;
    });
  });
  const timestamps = Object.keys(timestampBitmap).map(Number).sort();
  const latestTimestamp = timestamps[0];
  const uniqueTags: { [key: string]: string } = {};
  sortedDataset.forEach((timeseries, idx: number) => {
    const { points, tags } = timeseries;
    const valueByTimestamp: { [key: string]: number } = points.reduce(
      (obj, point) => ({ ...obj, [point.ts]: point.value }),
      {},
    );
    const count = valueByTimestamp[latestTimestamp];
    const tagsKeys = Object.keys(tags);
    let labels: { [key: string]: string } = {};
    if (tagsKeys.length === 0) {
      labels['label'] = `count_of_${queryKey}`;
    } else {
      labels = tags;
    }

    tagsKeys.forEach((key) => {
      if (!uniqueTags[key]) uniqueTags[key] = tags[key];
    });

    const commonFunc = {
      getMetric: () => tags,
      getValue: () => count,
      getQueryKey: () => queryKey,
    };

    if (returnFormat === DashboardPanelType.TABLE) {
      tableData.push({ ...commonFunc, ...labels, count });
      return;
    } else if (returnFormat === DashboardPanelType.TOP_LIST) {
      tableData.push({
        ...commonFunc,
        label: Object.values(labels).join(', '),
        count,
      });
      return;
    } else if (returnFormat === DashboardPanelType.GRAFANA_POLYSTAT_PANEL) {
      tableData.push({
        ...commonFunc,
        count,
        label: Object.keys(labels)
          .map((key) => `${key}:${labels[key]}`)
          .join(', '),
        value: count,
      });
      return;
    } else if (returnFormat === DashboardPanelType.PIECHART) {
      tableData.push({
        ...commonFunc,
        name: Object.values(labels).join(', '),
        color: getColorForOneSeries({}, idx),
        size: count,
        value: count,
        count,
      });
      return;
    }
  });

  tableData.sort((a, b) => b.count - a.count);

  if (returnFormat === DashboardPanelType.PIECHART) {
    // add percentage
    const total = tableData.reduce((acc, cur) => acc + cur.count, 0);
    tableData.forEach((r) => {
      r.percentage = (r.count / total) * 100;
    });
  }

  return { data: tableData, labels: Object.keys(uniqueTags) };
};
