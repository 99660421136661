import queryEventService from 'requests/queryEventService';
import { EventListProps } from 'types';
import { onPromiseError } from 'utils';

type Args = {
  eventId: string;
  timestamp: string;
};

const singleEvent = async ({
  eventId,
  timestamp,
}: Args): Promise<EventListProps> => {
  return queryEventService<EventListProps[], 'events'>(`
  query GetEventsData {
    events(
      filter: {eq: {name: "@id", value: "${eventId}"}},
      timestamp: "${timestamp}",
    ) {
      id
      title
      timestamp
      text
      host
      priority
      sourceTypeName
      alertType
      aggregationKey
      eventType
      labels {
        name
        value
      }
    }
  }
  `).then(
    (data: { events: EventListProps[] }) => data.events?.[0] || null,
    onPromiseError,
  );
};

export default singleEvent;
