import {
  LeftSidebar,
  ShowSidebarTooltipButton,
  useLeftSidebarState,
} from 'components';
import { Datepicker } from 'composite';
import React, { ReactElement } from 'react';
import { RefreshCw } from 'react-feather';
import { DateSelection } from 'types';
import { refreshDate } from 'utils/refreshDate';

import { EventsSidebar, EventsSearchBar } from '../components';
import EventsAnalyticsQuery from './EventsAnalyticsQuery';
import { useEventsState } from '../hooks';
import { EventsTab } from '../types';
import { useEventsPageState } from 'hooks';
import EventsQuerySearchAnalytics from './EventsQuerySearchAnalytics';

const EventsAnalytics = ({
  eventsPageState,
  tab,
}: {
  eventsPageState: ReturnType<typeof useEventsPageState>;
  tab: EventsTab;
}): ReactElement => {
  const leftSidebarState = useLeftSidebarState('events-analytics');
  const eventsState = useEventsState({
    urlKeysToSync: ['DATE', 'EVENTS_FILTER', 'EVENTS_ANALYTICS'],
  });
  const { date, setDate, filterState } = eventsState;

  const { searches } = eventsPageState;

  return (
    <div className="events">
      <LeftSidebar
        className="events__left-sidebar"
        leftSidebarState={leftSidebarState}
      >
        <EventsSidebar eventsState={eventsState} />
      </LeftSidebar>
      <div className="events__main">
        <div className="events__header">
          <div className="flex flex-1">
            {leftSidebarState.width === 0 ? (
              <ShowSidebarTooltipButton onClick={leftSidebarState.show} />
            ) : null}
            <div className="flex flex-1 flex-col">
              {tab === EventsTab.list ? (
                <EventsSearchBar
                  eventsState={eventsState}
                  filterState={filterState}
                />
              ) : null}
              <EventsQuerySearchAnalytics
                searches={searches}
                eventsState={eventsState}
                tab={tab}
              />
            </div>
          </div>
          <div>
            <Datepicker
              onChange={setDate as (dateSelection: DateSelection) => void}
              value={date as DateSelection}
            />
          </div>
          <button
            className="events__header__refresh-button"
            onClick={() => refreshDate(date, setDate)}
            data-testid="refresh"
          >
            <RefreshCw size={14} />
          </button>
        </div>
        <div className="events__body">
          <EventsAnalyticsQuery
            searches={searches}
            eventsState={eventsState}
            tab={tab}
          />
        </div>
      </div>
    </div>
  );
};

export default EventsAnalytics;
