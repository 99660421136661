import { BadgeGroup, usePopoverContext } from 'components';
import React, { ReactElement, useState } from 'react';
import { FilterMethodByType } from 'types';
import EventsExplorerTableTagsPanel from './EventsExplorerTableTagsPanel';

const MAX_TAGS = 8;

type Props = {
  maxTags?: number;
  row: any;
  sidebarFilterState: FilterMethodByType<'map'>;
  contractList?: boolean;
};

const EventsExplorerTableTags = ({
  maxTags = MAX_TAGS,
  row,
  sidebarFilterState,
  contractList,
}: Props): ReactElement => {
  const popover = usePopoverContext();
  const [showAll, setShowAll] = useState(false);
  const { labels } = row;
  const valuesLength = labels.length;
  const trimmerdLabels =
    valuesLength > maxTags ? labels.slice(0, maxTags) : labels;
  const leftLabels =
    valuesLength > maxTags ? labels.slice(maxTags, valuesLength) : [];

  const onExcludeByTag = (tag: string) => {
    const [name, value] = tag.split(':');
    sidebarFilterState.excludeFromMappedFilter('sidebarFilters', name, value);
    popover.close();
  };

  const onFilterByTag = (tag: string) => {
    const [name, value] = tag.split(':');
    sidebarFilterState.addMappedFilter('sidebarFilters', name, value);
    popover.close();
  };

  const badgeList = labels.map((label: any) => `${label.name}:${label.value}`);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <BadgeGroup
        badgeList={badgeList}
        contractList={contractList}
        renderPopoverOnBadgeClick={(badge, close) => (
          <EventsExplorerTableTagsPanel
            onExcludeByTag={onExcludeByTag}
            onFilterByTag={onFilterByTag}
            close={close}
            tag={badge}
          />
        )}
      />
    </div>
  );
};

export default EventsExplorerTableTags;
