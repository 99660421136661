import classNames from 'classnames';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import {
  AccordianContextProvider,
  useAccordianContext,
} from './useAccordianContext';

const AccordionHeader = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}): ReactElement => {
  const { isAccordianOpen } = useAccordianContext();

  return (
    <div
      data-state={isAccordianOpen.value ? 'open' : 'closed'}
      className={classNames({ [className]: className })}
    >
      {children}
    </div>
  );
};

const AccordionTigger = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}): ReactElement => {
  const { isAccordianOpen } = useAccordianContext();

  return (
    <div
      data-state={isAccordianOpen.value ? 'open' : 'closed'}
      onClick={() => isAccordianOpen.toggle()}
      className={classNames({ [className]: className })}
    >
      {children}
    </div>
  );
};

const AccordionContent = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}): ReactElement => {
  const { isAccordianOpen } = useAccordianContext();
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    if (contentRef.current && !isAccordianOpen.value) {
      contentRef.current.style.setProperty(
        '--accordion-content-height',
        `${contentRef.current.scrollHeight}px`,
      );
      contentRef.current.style.setProperty(
        '--accordion-content-width',
        `${contentRef.current.scrollWidth}px`,
      );
    }

    if (!isAccordianOpen.value) {
      setTimeout(() => setIsHidden(true), 300);
    } else {
      setIsHidden(false);
    }
  }, [isAccordianOpen.value]);

  return (
    <div
      className={classNames({ [className]: className })}
      data-state={isAccordianOpen.value ? 'open' : 'closed'}
      ref={contentRef}
      hidden={isHidden}
    >
      {children}
    </div>
  );
};

const AccordionItem = ({
  children,
  defaultOpen = false,
}: {
  children: ReactNode;
  defaultOpen: boolean;
}): ReactElement => {
  return (
    <AccordianContextProvider defaultOpen={defaultOpen}>
      <div>{children}</div>
    </AccordianContextProvider>
  );
};

const AccordionV2 = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}): ReactElement => {
  return (
    <div className={classNames({ [className]: className })}>{children}</div>
  );
};

AccordionV2.Root = AccordionV2;
AccordionV2.Item = AccordionItem;
AccordionV2.Header = AccordionHeader;
AccordionV2.Trigger = AccordionTigger;
AccordionV2.Content = AccordionContent;
export default AccordionV2;
