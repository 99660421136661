import { Loader, TopList } from 'components';
import React, { ReactElement, useMemo } from 'react';

const WIDTH_LEFT_RIGHT_OFFSET = 32;

const DashboardEditGraphToplist = ({
  baseWidth,
  dataFormatter,
}: {
  baseWidth: number;
  dataFormatter: () => any;
}): ReactElement => {
  const toplistQueryData = useMemo(() => dataFormatter(), [dataFormatter]);

  return (
    <Loader isLoading={toplistQueryData?.isLoading}>
      <div className="dashboard-edit__metric__body__top-list">
        {toplistQueryData?.data ? (
          <TopList
            data={toplistQueryData.data}
            height={400}
            width={baseWidth - WIDTH_LEFT_RIGHT_OFFSET - 16}
          />
        ) : null}
      </div>
    </Loader>
  );
};

export default DashboardEditGraphToplist;
