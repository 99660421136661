import { useKeyExistsState } from 'hooks';
import React, { MutableRefObject } from 'react';
import { useLogsWorkbooksState } from './hooks';
import { LogLinePart } from 'types';
import LogsLogLinePartsItem from './LogsLogLinePartsItem';

type Props = {
  containerRef?: MutableRefObject<HTMLDivElement>;
  customColumnsState?: ReturnType<typeof useKeyExistsState>;
  disableAddCustomColumn?: boolean;
  fpHash: string;
  logLineParts: LogLinePart[];
  logsState: ReturnType<typeof useLogsWorkbooksState>['currentLogsState'];
  offsetX?: number;
  offsetY?: number;
  source: string;
  shouldDisableFilterActions?: boolean;
  shouldTruncateLogLinePart?: boolean;
};

const LogsLogLineParts = ({
  containerRef,
  customColumnsState,
  disableAddCustomColumn,
  fpHash,
  logLineParts,
  logsState,
  offsetX,
  offsetY,
  shouldDisableFilterActions,
  shouldTruncateLogLinePart,
  source,
}: Props) => {
  if (logLineParts) {
    return (
      <>
        {logLineParts.map((logLinePart, i) => (
          <LogsLogLinePartsItem
            containerRef={containerRef}
            customColumnsState={customColumnsState}
            disableAddCustomColumn={disableAddCustomColumn}
            key={i}
            fpHash={fpHash}
            index={i}
            logLinePart={logLinePart}
            logsState={logsState}
            offsetX={offsetX}
            offsetY={offsetY}
            shouldDisableFilterActions={shouldDisableFilterActions}
            shouldTruncateLogLinePart={shouldTruncateLogLinePart}
            source={source}
          />
        ))}
      </>
    );
  }

  return null;
};

export default LogsLogLineParts;
