import { ConfigCatProvider, User } from 'configcat-react';
import React from 'react';
import { render } from 'react-dom';
import RootWithContexts from './RootWithContexts';
import '../styles/shadcn-globals.css';
import '../styles/index.scss';

if (import.meta.env.PROD) {
  document.head.append(
    document.head.querySelector('[rel="stylesheet"][crossorigin]'),
  );
}

(async () => {
  render(
    <ConfigCatProvider
      options={{
        defaultUser: new User(location.hostname),
      }}
      sdkKey="s5PbCFC34E2FYdGLiywtpQ/I8vDXVDUoEyoqniQVmRy0Q"
    >
      <RootWithContexts />
    </ConfigCatProvider>,
    document.getElementById('root'),
  );
})();
