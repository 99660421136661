type Args = {
  a: any;
  b: any;
  asc: boolean;
};

export const sortLexicographically = ({ a, b, asc }: Args) =>
  asc ? String(a).localeCompare(String(b)) : String(b).localeCompare(String(a));

export const sortNumerically = ({ a, b, asc }: Args) =>
  asc ? Number(a) - Number(b) : Number(b) - Number(a);
