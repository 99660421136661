import { getAdjustedStartAndEndTimeUnix, getColorForOneSeries } from 'utils';
import { Series } from 'uplot';
import { DateSelection, MetricsTransformSeriesProps } from 'types';
import { CHART_LINE_WIDTH_MEDIUM } from '../chartConfig';

export const formatTimeseriesLabel = ({
  metric,
}: MetricsTransformSeriesProps): string => {
  const metricName = metric.__name__;
  delete metric.__name__;

  const labelKeys = Object.keys(metric);
  if (labelKeys.length === 0) {
    return `${metricName}{*}`;
  }

  if (labelKeys.length === 1) {
    return metric[labelKeys[0]];
  }

  const labelValues = labelKeys.map((key) => `${key}:${metric[key]}`);
  return labelValues.join(', ');
};

export const defaultSeriesFormatter = (
  val: MetricsTransformSeriesProps,
): Series => {
  const { idx, metric } = val;
  const label = formatTimeseriesLabel(val);
  return {
    label,
    points: { show: false },
    stroke: getColorForOneSeries(metric, idx),
    show: true,
    width: CHART_LINE_WIDTH_MEDIUM,
  };
};

export const promqlDatasetCheck = (
  datasetsKeys: string[],
  datasets: { [key: number]: any },
): {
  maxUniqueLabels: number;
  isDatasetOutlier: boolean;
} => {
  let maxUniqueLabels = 0;
  let isDatasetOutlier = false;

  datasetsKeys.forEach((key: any, idx) => {
    const dataset = datasets[key];
    const { metric } = dataset;
    const isSeriesOutlier = metric['outlier'];
    if (isSeriesOutlier === 'true') {
      isDatasetOutlier = true;
    }

    const uniqueLabels = Object.keys(metric).length;
    if (uniqueLabels > maxUniqueLabels) {
      maxUniqueLabels = uniqueLabels;
    }
  });
  return { maxUniqueLabels, isDatasetOutlier };
};

export const fillTimeseriesForWindow = (
  date: DateSelection,
  step: number,
  timestampBitmap: { [key: string]: boolean },
): number[] => {
  if (!date || !step) return [];
  if (Object.keys(timestampBitmap).length === 0) return [];

  const { startTimeUnix, endTimeUnix } = getAdjustedStartAndEndTimeUnix({
    date,
    step: `${step}s`,
  });

  const newTimestampBitmap: { [key: string]: number } = {};
  let ts = startTimeUnix;
  while (ts <= endTimeUnix) {
    if (!timestampBitmap[ts]) {
      newTimestampBitmap[ts] = 1;
    }
    ts += step;
  }

  const combinedTimestampBitmap = { ...timestampBitmap, ...newTimestampBitmap };
  const timestamps = Object.keys(combinedTimestampBitmap).map(Number).sort();

  return timestamps;
};
