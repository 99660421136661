import { ApmErrorsGroupTable } from 'components';
import { useServicesPageStateContext } from 'context/PageStateProvider';
import { useTracesState } from 'hooks';
import React from 'react';
import { Service } from 'types';

type Props = {
  colorsByServiceName: Record<string, string>;
  isServiceFromDatabasesList?: boolean;
  serviceHash?: string;
  serviceByHash: Record<string, Service>;
  serviceName?: string;
  tracesState: ReturnType<typeof useTracesState>;
};

const ServiceTabErrors = ({
  colorsByServiceName,
  isServiceFromDatabasesList,
  serviceName,
  serviceByHash,
  serviceHash,
  tracesState,
}: Props) => {
  const { customerFilter } = useServicesPageStateContext();
  return (
    <ApmErrorsGroupTable
      applyFilterOnErrorsPage
      className="service__tab"
      customerFilter={customerFilter}
      colorsByServiceName={colorsByServiceName}
      isServiceFromDatabasesList={isServiceFromDatabasesList}
      serviceHash={serviceHash}
      serviceByHash={serviceByHash}
      serviceName={serviceName}
      tracesState={tracesState}
    />
  );
};

export default ServiceTabErrors;
