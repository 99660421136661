import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { ClickableDiv } from 'components';
import SuggestionsFooter from 'components/SuggestionsFooter';

const Suggestion = ({
  children,
  onClick,
  isActive,
  activate,
  deactivate,
}: {
  children: React.ReactNode;
  onClick: () => void;
  isActive: boolean;
  activate: () => void;
  deactivate: () => void;
}): React.ReactElement => {
  const suggestionRef = useRef<HTMLDivElement | null>(null);
  const [isMouseActivated, setIsMouseActivated] = useState(false);

  useEffect(() => {
    if (isActive && !isMouseActivated) {
      suggestionRef.current?.scrollIntoView(false);
    }
    if (!isActive) {
      setIsMouseActivated(false);
    }
  }, [isActive, isMouseActivated]);

  return (
    <ClickableDiv
      className={classnames(
        'flex w-full cursor-pointer items-center px-1 py-2 outline-none',
        {
          'bg-interaction-primary text-primary-foreground': isActive,
        },
      )}
      ref={suggestionRef}
      onClick={onClick}
      onMouseEnter={() => {
        activate();
        setIsMouseActivated(true);
      }}
      onMouseLeave={() => {
        deactivate();
        setIsMouseActivated(false);
      }}
    >
      {children}
    </ClickableDiv>
  );
};

const Container = ({
  isOpen,
  suggestionsRef,
  children,
  footer,
}: {
  isOpen: boolean;
  suggestionsRef: React.MutableRefObject<HTMLDivElement>;
  children: React.ReactNode;
  footer?: React.ReactNode;
}): React.ReactElement => {
  return (
    <div
      className={classnames(
        'absolute left-0 top-full z-10 flex w-full flex-col shadow-4',
        'border border-r-interaction-primary border-t-transparent',
        'border-b-interaction-primary border-l-interaction-primary',
        'h-[386px] max-h-[386px] bg-background',
        'mb-1 pt-1',
        {
          invisible: !isOpen,
        },
      )}
      ref={suggestionsRef}
    >
      <div className={classnames('grow overflow-y-scroll bg-background px-2')}>
        {children}
      </div>
      {footer}
    </div>
  );
};

const Suggestions = {
  Container,
  Footer: SuggestionsFooter,
  Suggestion,
};

export default Suggestions;
