import { Button } from 'components/shadcn';
import {
  CompactTooltipContainerV2,
  tooltipFormatter,
} from 'components/Timeseries';
import React, { ReactElement } from 'react';
import { Trace } from 'types';
import { getEventPopoverPosition } from 'utils/Timeseries';

const ChartGridOutlierSpanActions = ({
  coords,
  close,
  onSetSidebar,
  queryEventOverlayList,
}: {
  coords: ReturnType<typeof getEventPopoverPosition>;
  close: () => void;
  onSetSidebar: (trace: Trace) => void;
  queryEventOverlayList: Trace[];
}): ReactElement => {
  return (
    <CompactTooltipContainerV2
      coords={{
        x: coords.x || 0,
        y: coords.y || 0,
        positionX: coords.positionX || 'right',
        positionY: 'top',
      }}
      onOutsideClick={close}
    >
      <div className="border bg-background p-2">
        <Button
          variant="default"
          onClick={() => {
            if (!onSetSidebar) return;
            const activeTrace = queryEventOverlayList.find(
              (trace) => trace.traceId === coords.data.traceId,
            );
            onSetSidebar(activeTrace);
            close();
          }}
          size="sm"
        >
          View span with duration:{' '}
          {tooltipFormatter(coords.data.duration, 'ns')}
        </Button>
      </div>
    </CompactTooltipContainerV2>
  );
};

export default ChartGridOutlierSpanActions;
