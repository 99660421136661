const niceNum = (range: number, round: boolean) => {
  const exponent = Math.floor(Math.log10(range));
  const fraction = range / Math.pow(10, exponent);
  let niceFraction;

  if (round) {
    if (fraction < 1.5) niceFraction = 1;
    else if (fraction < 3) niceFraction = 2;
    else if (fraction < 7) niceFraction = 5;
    else niceFraction = 10;
  } else {
    if (fraction <= 1) niceFraction = 1;
    else if (fraction <= 2) niceFraction = 2;
    else if (fraction <= 5) niceFraction = 5;
    else niceFraction = 10;
  }

  return niceFraction * Math.pow(10, exponent);
};

const getNiceScale = (
  lowerBound: number,
  upperBound: number,
  maxTicks: number,
) => {
  const range = niceNum(upperBound - lowerBound, false);
  const tickSpacing = niceNum(range / (maxTicks - 1), true);
  const niceLowerBound = Math.floor(lowerBound / tickSpacing) * tickSpacing;
  const niceUpperBound = Math.ceil(upperBound / tickSpacing) * tickSpacing;

  return { niceLowerBound, niceUpperBound, tickSpacing };
};

export default getNiceScale;
