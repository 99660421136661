import query from 'requests/query';
import { onPromiseError } from 'utils';

const getLogsFavoriteFacetGroups = async ({
  limit = 1000,
}: {
  limit?: number;
}): Promise<string[]> => {
  return query<string[], 'getFavoriteFacetGroups'>(`
        query GetFavoriteFacetGroups {
          getFavoriteFacetGroups(limit: ${limit})
        }
      `).then((data) => data.getFavoriteFacetGroups || [], onPromiseError);
};

export default getLogsFavoriteFacetGroups;
