import React, { ReactElement } from 'react';
import { Property } from './types';

const ServiceTabsMenu = ({
  onClick,
  subTabs,
}: {
  onClick: (property: Property) => void;
  subTabs?: { label: string; property: Property }[];
}): ReactElement => {
  return (
    <div className="header__nav__item__panel z-[1000]">
      {subTabs?.map((subTab, i) => (
        <div
          key={subTab.property}
          className="header__nav__item__panel__item"
          onClick={() => onClick(subTab.property)}
        >
          <div
            className="header__nav__item__panel__item__label"
            data-testid={subTab.label}
          >
            {subTab.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServiceTabsMenu;
