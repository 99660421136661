import { Input } from 'components/Input';
import React, { ReactElement } from 'react';

import { useDashboardEditState } from './hooks';

const DashboardEditPanelName = ({
  dashboardEditState,
  noPlaceholder,
}: {
  dashboardEditState: ReturnType<typeof useDashboardEditState>;
  noPlaceholder?: boolean;
}): ReactElement => {
  const { editPanel, editDashboardError, setEditPanel, setEditDashboardError } =
    dashboardEditState;

  return (
    <div>
      <Input
        placeholder={noPlaceholder ? '' : 'Give chart a name'}
        onChange={(val) => {
          setEditPanel((prev) => ({ ...prev, title: val }));
          if (val.length > 0) {
            setEditDashboardError((prev) => {
              const newState = { ...prev };
              delete newState.title;
              return newState;
            });
          } else {
            setEditDashboardError((prev) => ({
              ...prev,
              title: 'Title is required',
            }));
          }
        }}
        type="text"
        value={editPanel.title}
        size="4"
      />
      {editDashboardError?.title && (
        <div className="text--red">{editDashboardError?.title}</div>
      )}
    </div>
  );
};

export default DashboardEditPanelName;
