import { DashboardPanelProps } from 'types';

const annotations = {
  list: [
    {
      builtIn: 1,
      datasource: { type: 'grafana', uid: '-- Grafana --' },
      enable: true,
      hide: true,
      iconColor: 'rgba(0, 211, 255, 1)',
      name: 'Annotations & Alerts',
      target: { limit: 100, matchAny: false, tags: [], type: 'dashboard' },
      type: 'dashboard',
    },
  ],
};

export const timeseriesFieldConfig = (drawStyle: string) => {
  return {
    defaults: {
      color: { mode: 'palette-classic' },
      custom: {
        axisLabel: '',
        axisPlacement: 'auto',
        barAlignment: 0,
        drawStyle,
        fillOpacity: 0,
        gradientMode: 'none',
        hideFrom: { legend: false, tooltip: false, viz: false },
        lineInterpolation: 'linear',
        lineWidth: 1,
        pointSize: 5,
        scaleDistribution: { type: 'linear' },
        showPoints: 'auto',
        spanNulls: false,
        stacking: { group: 'A', mode: 'none' },
        thresholdsStyle: { mode: 'off' },
      },
      mappings: [],
      thresholds: {
        mode: 'absolute',
        steps: [{ color: '', value: null }],
      },
    },
    overrides: [],
  };
};

export const dashboardPanelOptions: any = {
  legend: { calcs: [], displayMode: 'list', placement: 'bottom' },
  tooltip: { mode: 'single', sort: 'none' },
};

export const getNewDashboardJSONModel = ({
  dashboardTitle,
  timeDiff,
}: {
  dashboardTitle: string;
  timeDiff: { from: string; to: string };
}) => {
  return {
    annotations: annotations,
    editable: true,
    fiscalYearStartMonth: 0,
    graphTooltip: 0,
    links: [],
    liveNow: false,
    panels: [],
    schemaVersion: 36,
    style: 'dark',
    tags: [],
    templating: { list: [] },
    time: timeDiff,
    timepicker: {},
    timezone: '',
    title: dashboardTitle,
    version: 0,
    weekStart: '',
    id: null,
    uid: '',
    hideControls: false,
  };
};

export const findLargestBottomOfDashboard = (
  panels: DashboardPanelProps[],
): DashboardPanelProps['gridPos'] => {
  // find the largest y of gridPos
  let largestBottom = 0;
  panels.forEach((panel) => {
    const bottom = panel.gridPos.y + panel.gridPos.h;
    if (bottom > largestBottom) {
      largestBottom = bottom;
    }
  });

  return { x: 0, y: largestBottom, w: 12, h: 8, i: '0' };
};
