import React, { useMemo } from 'react';
import { kubeEntities } from 'requests';
import { calculateCapacity } from 'utils/timeNs';

import { nodesCPUPercentage, nodesMemoryPercentage } from '../utils';
import { useKubernetesController } from '../KubernetesController';
import KubernetesTable from './KubernetesTable';
import {
  KubeCellPercentageBar,
  KubeCellStatus,
  KubeCellTimestamp,
  KubeTableCell,
} from './Components';
import useAsync from '../hooks/useAsync';
import { useEntitiesCount } from './useEntitiesData';
import { Node } from 'screens/Kubernetes/types';
import { SelectedFacetValuesByName } from 'types';

export function useKubernetesTableDataForNode({
  page,
  limit,
  filters,
}: {
  filters: Array<SelectedFacetValuesByName>;
  page: number;
  limit: number;
}) {
  const { entitiesLastLoaded, date, kubesSort, entitiesType } =
    useKubernetesController();

  return useAsync(
    async (signal) => {
      const entities = (await kubeEntities(
        {
          entityType: entitiesType,
          sortOrder: kubesSort?.order,
          sortBy: {
            key: kubesSort?.key,
            type: kubesSort?.type,
          },
          offset: page * limit,
          pageLimit: limit,
          filters,
        },
        { signal },
      )) as Array<{ node: Node }>;
      const host = entities.map(({ node }) => {
        return node.metadata.name;
      });
      const [cpu, memory] = await Promise.all([
        nodesCPUPercentage(
          [],
          {
            host,
          },
          date,
        )().then((results) => {
          return Object.fromEntries(
            Object.entries(results).map(([key, value]) => {
              return [key.substring(0, key.indexOf('.')), value];
            }),
          );
        }),
        nodesMemoryPercentage(
          [],
          {
            kube_node: host,
          },
          date,
        )(),
      ]);

      return entities.map(({ node }) => {
        return {
          node,
          cpuPercentage: cpu[node.metadata.name],
          memoryPercentage: memory[node.metadata.name],
        };
      });
    },
    [date, page, limit, filters, kubesSort, entitiesLastLoaded],
  );
}

export default function KubernetesTableForNode() {
  const { entitiesType, facets, page, limit } = useKubernetesController();

  const filters = useMemo(() => [facets], [facets]);
  const [count] = useEntitiesCount({
    entitiesType,
    facets,
  });
  const [rows] = useKubernetesTableDataForNode({
    page,
    limit,
    filters,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'kube_node',
          label: 'Node',
          renderCell(prop) {
            const nodeName = prop.row.node.metadata.name;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name"
                tooltipText={nodeName}
              >
                {nodeName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'metadata.creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            const age = prop.row.node.metadata;
            const timestamp = age.creationTimestamp;
            return <KubeCellTimestamp {...prop} timestamp={timestamp} />;
          },
        },
        {
          key: 'status.status',
          label: 'Status',
          renderCell(prop) {
            const status = prop.row.node.status.status;
            return <KubeCellStatus {...prop} status={status} />;
          },
        },
        {
          key: 'status.capacity.cpu',
          label: 'Cpu Capacity',
          renderCell(prop) {
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name"
              >
                {(prop.row.node.status.allocatable.cpu / 1000).toFixed(2)}/
                {(prop.row.node.status.capacity.cpu / 1000).toFixed(2)} CPUs
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerCpuUsage',
          label: '% CPU Usage',
          renderCell(prop) {
            const percent = prop.row.cpuPercentage * 100;
            return (
              <KubeTableCell {...prop}>
                <KubeCellPercentageBar percent={percent} />
              </KubeTableCell>
            );
          },
        },
        {
          key: 'status.capacity.memory',
          label: 'Mem Capacity',
          renderCell(prop) {
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name"
              >
                {calculateCapacity(prop.row.node.status.allocatable.memory)} /
                {calculateCapacity(prop.row.node.status.capacity.memory)}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerMemUsage',
          label: '% Mem Usage',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop}>
                <KubeCellPercentageBar
                  percent={prop.row.memoryPercentage * 100}
                />
              </KubeTableCell>
            );
          },
        },
        {
          key: 'unschedulable',
          label: 'Schedulability',
          renderCell(prop) {
            const status =
              prop.row.node.unschedulable === 'true' ? 'inactive' : 'active';
            return <KubeCellStatus {...prop} status={status} />;
          },
        },
        {
          key: 'headerRoles',
          label: 'Roles',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop}>{prop.row.node.roles}</KubeTableCell>
            );
          },
        },
        {
          key: 'status.kubeletVersions',
          label: 'Kubelet Version',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop}>
                {prop.row.node.status.kubeletVersions}
              </KubeTableCell>
            );
          },
        },
      ]}
    />
  );
}
