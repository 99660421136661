import { QueryDataProps } from 'types/QueryData';

const legendCustomColorTimeseries = (
  rangeData: QueryDataProps['query']['range'],
  colorMap: Record<string, string>,
): QueryDataProps['query']['range'] => {
  if (!colorMap) return rangeData;
  const { series } = rangeData;
  const transformedSeries = series.map((series) => ({
    ...series,
    stroke: colorMap[series.label] || series.stroke,
  }));

  return {
    ...rangeData,
    series: transformedSeries,
  };
};

export default legendCustomColorTimeseries;
