import { SearchState, FormulaState } from 'hooks';
import { DataFrameMeta, getUsedQueryKeysFromFormula } from 'utils';

export const getSelectedTracesQueryOrFormula = (
  formulas: FormulaState[],
  queries: SearchState[],
  selectedQueryKey: string,
): { formulas: FormulaState[]; queries: SearchState[] } | null => {
  if (selectedQueryKey.includes('Query')) {
    const queryKeyParsed = selectedQueryKey.split('(')[1].split(')')[0];
    const query = queries.find((q) => q.queryKey === queryKeyParsed);
    return { queries: [query], formulas: [] };
  }

  if (selectedQueryKey.includes('Formula')) {
    const formulaKeyParsed = selectedQueryKey.split('(')[1].split(')')[0];
    const formula = formulas.find((f) => f.queryKey === formulaKeyParsed);
    const usedQueryKeys = getUsedQueryKeysFromFormula(formula.expression);
    const filteredQueries = queries.filter((q) =>
      usedQueryKeys.includes(q.queryKey),
    );
    return { formulas: [formula], queries: filteredQueries };
  }

  return null;
};

export const getGraphQLQueryStringForTracesAlert = ({
  queryStrings,
  queryType,
}: {
  queryStrings: { datasets: string; meta: DataFrameMeta }[];
  queryType: 'query' | 'formula';
}): string => {
  const graphQLQuery = { operationName: null, query: '', variables: {} };
  if (queryType === 'query') {
    const query = queryStrings.find(
      (query) => query.meta.queryType === 'query',
    );
    if (query) {
      graphQLQuery.query = query.datasets;
    }
  }

  if (queryType === 'formula') {
    const formula = queryStrings.find(
      (query) => query.meta.queryType === 'formula',
    );
    if (formula) {
      graphQLQuery.query = formula.datasets;
    }
  }

  return JSON.stringify(graphQLQuery);
};

export const getQueriesAndFormulasFromAnnotations = (annotations: any) => {
  if (!annotations) {
    return { queries: undefined, formulas: undefined };
  }
  const extraData = JSON.parse(annotations?.extraData || '{}');
  const queries = [];
  const formulas = [];
  if (extraData.queryType === 'query') {
    queries.push(...extraData.queries);
  } else {
    formulas.push(...extraData.formulas);
    queries.push(...extraData.queries);
  }
  return { queries, formulas };
};
