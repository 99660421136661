import classNames from 'classnames';
import { ErrorBoundary, Loader } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  getGrafanaDashboard,
  getGrafanaDashboardByUid,
  getGrafanaFolders,
} from 'requests';
import { DateSelection } from 'types';
import { convertTimestampToCode } from 'utils';

import { Dashboard } from '../Dashboard';

const DashboardByName = ({
  date,
  disableFilter = true,
  disableDeletePanel,
  folderName = 'kfuse',
  hideHeader = false,
  fetchLimitedTemplates = [],
  name,
  shouldWriteToUrl = true,
  showFilterTimepicker = false,
  templateValues,
  showReloadButton = false,
}: {
  date?: DateSelection;
  disableFilter?: boolean;
  disableDeletePanel?: boolean;
  folderName?: string;
  hideHeader?: boolean;
  fetchLimitedTemplates?: { name: string; joinValues?: boolean }[];
  name: string;
  shouldWriteToUrl?: boolean;
  showFilterTimepicker?: boolean;
  templateValues: { [key: string]: string | string[] };
  showReloadButton?: boolean;
}): ReactElement => {
  const [jsonModel, setJsonModel] = useState(null);
  const [dashboardError, setDashboardError] = useState<string>('');

  const grafanaDashboardRequest = useRequest(getGrafanaDashboard);
  const dashboardByUidRequest = useRequest(getGrafanaDashboardByUid);
  const grafanaFoldersRequest = useRequest(getGrafanaFolders);

  const loadDashboardList = async () => {
    setJsonModel(null);
    let dashboardList = [];
    let folderList = [];
    if (grafanaFoldersRequest.result) {
      folderList = grafanaFoldersRequest.result;
    } else {
      folderList = await grafanaFoldersRequest.call();
    }
    const folder = folderList.find((item: any) => item.title === folderName);

    if (grafanaDashboardRequest.result) {
      dashboardList = grafanaDashboardRequest.result;
    } else {
      dashboardList = await grafanaDashboardRequest.call(
        `type=dash-db&folderIds=${folder.id}&limit=5000`,
      );
    }

    const dashboard = dashboardList.find((item: any) => item.title === name);
    if (!dashboard) {
      setDashboardError(`${name} dashboard not found`);
      return;
    }
    const dashboardJson = await dashboardByUidRequest.call(dashboard.uid);
    updateTemplateValues(dashboardJson.dashboard);
  };

  const updateTemplateValues = (dashboard: any) => {
    Object.keys(templateValues).forEach((key) => {
      const templateIdx = dashboard.templating.list.findIndex(
        (item: any) => item.name === key,
      );
      const template = dashboard.templating.list[templateIdx];

      if (!template) {
        return;
      }

      dashboard.templating.list[templateIdx] = {
        ...template,
        current: {
          text: templateValues[key],
          value: templateValues[key],
          selected: true,
        },
      };

      if (date) {
        dashboard.time = convertTimestampToCode(date);
      }
    });

    setJsonModel({ ...dashboard });
  };

  useEffect(() => {
    if (!templateValues || !jsonModel || !jsonModel.templating) {
      return;
    }

    const templateValuesKeys = Object.keys(templateValues);
    const templatingKeysValues: { [key: string]: string } = {};
    jsonModel.templating.list.forEach((item: any) => {
      templatingKeysValues[item.name] = item.current.value;
    });

    let isChanged = false;
    templateValuesKeys.forEach((key) => {
      if (templateValues[key] !== templatingKeysValues[key]) {
        isChanged = true;
      }
    });

    if (!isChanged) {
      return;
    }

    updateTemplateValues(jsonModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateValues]);

  useEffect(() => {
    grafanaDashboardRequest.result = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderName]);

  useEffect(() => {
    loadDashboardList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (jsonModel) {
      const newJsonModel = { ...jsonModel };
      newJsonModel.time = convertTimestampToCode(date);
      setJsonModel(newJsonModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const isLoading =
    grafanaDashboardRequest.isLoading || dashboardByUidRequest.isLoading;
  return (
    <div className={classNames({ 'control-plane--loading': isLoading })}>
      <ErrorBoundary errorMessage={<h1>Error loading dashboard {name}</h1>}>
        <Loader isLoading={isLoading}>
          {jsonModel && (
            <Dashboard
              disableEditPanel={true}
              disableDeletePanel={disableDeletePanel}
              disableFilter={disableFilter}
              jsonModal={jsonModel}
              hideHeader={hideHeader}
              hideSidebar={true}
              fetchLimitedTemplates={fetchLimitedTemplates}
              shouldWriteToUrl={shouldWriteToUrl}
              showFilterTimepicker={showFilterTimepicker}
              showReloadButton={showReloadButton}
            />
          )}
        </Loader>
      </ErrorBoundary>
      {!isLoading && dashboardError && (
        <div className="control-plane__no-dashboard">{dashboardError}</div>
      )}
    </div>
  );
};

export default DashboardByName;
