import { ConfirmationModal, Stepper, useModalsContext } from 'components';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { DashboardPanelType } from 'types';

import AlertsCreateTracesChart from './AlertsCreateTracesChart';
import {
  AlertsCreateCondition,
  useAlertsCreateCondition,
} from '../AlertsCreateCondition';
import {
  AlertsCreateContacts,
  AlertsCreateDetails,
  CreateRuleButton,
} from '../components';
import { useAlertsCreate } from '../hooks';
import { TracesQuerySearchAnalytics } from 'screens/Traces';
import useAlertsCreateTraces from './useAlertsCreateTraces';
import { getQueryAndFormulaKeysLabel } from '../utils';
import { confirmSelectedLogsAlertQueryKey } from '../AlertsCreateLogs/utils';
import { RuleProps } from '../types';
import { parseAlertsQueryAndConditions } from '../AlertsCreateMetrics/utils';

const AlertsCreateTraces = ({
  baseWidth,
  alertsCreateState,
  conditionState,
}: {
  baseWidth: number;
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
}): ReactElement => {
  const modal = useModalsContext();
  const location = useLocation();
  const rule = useMemo(() => location.state as RuleProps, [location.state]);
  const { date, setUpdateAlertsRuleState } = alertsCreateState;
  const { condition, setUpdateConditionState } = conditionState;
  const alertsCreateTracesState = useAlertsCreateTraces({
    alertsCreateState,
    rule,
  });
  const {
    createTracesAlertThreshold,
    formulas,
    queries,
    searches,
    searchesFormulas,
    serviceByHash,
    traceLabelNamesRequest,
    tracesPageState,
  } = alertsCreateTracesState;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (rule) {
      const parsed = parseAlertsQueryAndConditions({
        data: rule.ruleData,
        annotations: rule.annotations,
      });

      if (rule.noData && parsed.condition) {
        parsed.condition.noData = rule.noData;
      }

      if (rule.executionError && parsed.condition) {
        parsed.condition.executionError = rule.executionError;
      }

      setUpdateAlertsRuleState(rule);
      setUpdateConditionState(parsed.condition);
    }
  }, [rule]);

  const queryAndFormulaKeysLabel = useMemo(
    () => getQueryAndFormulaKeysLabel(queries, formulas),
    [queries, formulas],
  );

  const onConfirmLogsAlertCreate = () => {
    const { queryKey } = condition;
    const confirmSelected = confirmSelectedLogsAlertQueryKey(
      formulas.length,
      queries.length,
      queryKey,
    );
    if (confirmSelected) {
      modal.push(
        <ConfirmationModal
          description={confirmSelected}
          onCancel={() => modal.pop()}
          onConfirm={() => {
            createTracesAlertThreshold(condition);
            modal.pop();
          }}
          submitText="Confirm and Create"
          title="Confirm Query Selection"
        />,
      );
    } else {
      createTracesAlertThreshold(condition);
    }
  };

  return (
    <div className="alerts__create__logs">
      <div className="alerts__create__logs__chart">
        <AlertsCreateTracesChart
          condition={conditionState.condition}
          date={date}
          formulas={formulas}
          queries={queries}
          serviceByHash={serviceByHash}
        />
      </div>
      <Stepper
        steps={[
          {
            title: 'pick',
            component: (
              <TracesQuerySearchAnalytics
                colorsByServiceName={{}}
                date={date}
                labelNames={traceLabelNamesRequest.result || []}
                searches={searches}
                searchesFormulas={searchesFormulas}
                serviceByHash={serviceByHash}
                traceLabelNamesRequest={traceLabelNamesRequest}
                tracesState={tracesPageState}
                tracesTab={DashboardPanelType.TIMESERIES}
                shouldSyncWithPageState={false}
              />
            ),
          },
          {
            title: 'Set Condition',
            component: (
              <AlertsCreateCondition
                conditionState={conditionState}
                queryAndFormulaKeysLabel={queryAndFormulaKeysLabel}
                alertsCreateState={alertsCreateState}
              />
            ),
          },
          {
            title: 'Add Details',
            component: (
              <AlertsCreateDetails alertsCreateState={alertsCreateState} />
            ),
          },

          {
            title: 'Add Contacts',
            component: (
              <AlertsCreateContacts alertsCreateState={alertsCreateState} />
            ),
          },
        ]}
      />
      <CreateRuleButton
        isEditing={alertsCreateState.isEditing}
        onClick={onConfirmLogsAlertCreate}
      />
    </div>
  );
};

export default AlertsCreateTraces;
