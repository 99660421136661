import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { Checkbox as ShadcnCheckbox } from './shadcn';

type Props = {
  className?: string;
  onChange?: (checked: boolean) => void;
  value: boolean;
  variant?: 'default' | 'compact';
  disabled?: boolean;
};

const Checkbox = ({
  className,
  onChange,
  value,
  variant = 'default',
  disabled,
}: Props): ReactElement => {
  return (
    <ShadcnCheckbox
      checked={value}
      className={classnames({ [className]: className })}
      onCheckedChange={onChange}
      variant={variant}
      disabled={disabled}
    />
  );
};

export default Checkbox;
