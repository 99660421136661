import fetchAdvanceFunctions from './fetchAdvanceFunctions';

const getAsmAutoAlert = ({
  matcher,
  kfSource,
}: {
  matcher: string;
  kfSource: string;
}): Promise<any> => {
  const matcherEncoded = encodeURIComponent(matcher);
  const kfSourceEncoded = encodeURIComponent(kfSource);

  return fetchAdvanceFunctions(
    `api/v1/asm_auto_alerts?matcher=${matcherEncoded}&kf_source=${kfSourceEncoded} `,
    { method: 'GET' },
  );
};

export default getAsmAutoAlert;
