import { Loader } from 'components';
import { useRequest } from 'hooks';
import delimiter from 'kfuse-constants/delimiter';
import debounce from 'lodash.debounce';
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getFpList } from 'requests';
import LogsFingerprintsListItem from 'screens/Logs/LogsFingerprintsListItem';
import LogsFingerprintsListHeader from 'screens/Logs/LogsFingerprintsListHeader';
import { DashboardPanelComponentProps } from 'types';

const DashboardPanelFingerprints = ({
  dashboardState,
  panel,
}: DashboardPanelComponentProps): ReactElement => {
  const getFpListRequest = useRequest(getFpList);
  const elementRef = useRef(null);
  const [lastIndex, setLastIndex] = useState(100);
  const { date } = dashboardState;

  if (panel.targets.length) {
    const { expr } = panel.targets[0];
    const logsState = { date, ...JSON.parse(expr) };
    const { fingerprintQuery } = logsState;
    const { groupBy } = fingerprintQuery;

    const onScroll = debounce(() => {
      const element = elementRef.current;
      if (element) {
        const { offsetHeight, scrollHeight, scrollTop } = element;
        if (scrollTop > scrollHeight - offsetHeight - 40) {
          setLastIndex((prevLastIndex) => prevLastIndex + 100);
        }
      }
    }, 200);

    const fingerprintGroupByKeys = useMemo(() => {
      if (!groupBy || groupBy.length == 0) {
        return ['source'];
      }
      return groupBy.map((f: string) => f.split(delimiter)[1]);
    }, [fingerprintQuery]);

    useEffect(() => {
      getFpListRequest.call({
        logsState,
        groupBy: fingerprintGroupByKeys,
        sortBy: fingerprintQuery.sortBy,
        sortOrder: fingerprintQuery.sortOrder,
      });
    }, [date]);

    return (
      <Loader
        className="logs__fingerprints-list"
        isLoading={getFpListRequest.isLoading}
        onScroll={onScroll}
        ref={elementRef}
      >
        <div className="logs__fingerprints-list__body">
          <LogsFingerprintsListHeader
            fingerprintGroupByKeys={fingerprintGroupByKeys}
            fingerprintQuery={fingerprintQuery}
            onSortFingerprintChange={null}
          />
          {(getFpListRequest.result || [])
            .slice(0, lastIndex)
            .map((fingerprint, idx: number) => (
              <LogsFingerprintsListItem
                fingerprint={fingerprint}
                fingerprintGroupByKeys={fingerprintGroupByKeys}
                key={`${fingerprint.source}:${fingerprint.hash}:${idx}`}
                logsState={logsState}
              />
            ))}
        </div>
      </Loader>
    );
  }

  return null;
};

export default DashboardPanelFingerprints;
