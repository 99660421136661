import classNames from 'classnames';
import {
  Button,
  PopoverTriggerV2,
  SizeObserver,
  TooltipTrigger,
} from 'components';
import React, { CSSProperties, ReactElement, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { calculateTextWidthInHtml } from 'utils';
import { Badge } from '../base';
import BadgeGroupPanel from './BadgeGroupPanel';

const GAP_X = 4.5;
const GAP_Y = 4;
const PADDING_X = 8;
const PADDING_Y = 8;
const BORDER = 2;

const calculateBadgeWidth = (text: string) => {
  const textWidth = calculateTextWidthInHtml({
    text,
    className: 'text-xs font-medium font-robotoMono',
  });
  const badgeWidth = textWidth + GAP_X + PADDING_X + PADDING_Y + BORDER;

  return badgeWidth;
};

type Props = {
  badgeList: string[];
  renderPopoverOnBadgeClick?: (
    badge: string,
    close: () => void,
  ) => ReactElement;
  contractList?: boolean;
};

const BadgeGroup = ({
  badgeList,
  renderPopoverOnBadgeClick,
  contractList,
}: Props): ReactElement => {
  const [popOverOpen, setPopOverOpen] = useState(false);
  const { ref, inView } = useInView();

  if (!badgeList || badgeList.length === 0) {
    return <></>;
  }

  return (
    <SizeObserver>
      {({ width }) => {
        let accumulatedWidth = 0;
        let overflowCount = 0;

        return (
          <div
            className="layout__overflow-list-content"
            style={
              {
                '--line-height': '26px',
                '--gap-x': '4px',
                '--gap-y': '4px',
                '--lines': 1,
                '--align-items': 'flex-start',
                '--justify-content': 'flex-start',
                height: contractList ? 'var(--height)' : '100%',
              } as CSSProperties
            }
            ref={ref}
          >
            {(inView ? badgeList : []).map((badge, idx) => {
              const badgeWidth = calculateBadgeWidth(badge);
              const plusButtonWidth =
                (inView
                  ? calculateBadgeWidth(`+${badgeList.length - idx}`)
                  : 0) - GAP_X; // GAP_X is the gap between the badge and the plus button
              accumulatedWidth += badgeWidth;
              const isOverflow =
                accumulatedWidth + plusButtonWidth > width && contractList;

              if (isOverflow) {
                overflowCount += 1;
              }

              const hasFirstOverflowOccurance = overflowCount === 1;
              if (hasFirstOverflowOccurance) {
                const lastBadgeWidth = accumulatedWidth - badgeWidth;
                const availableWidth =
                  width - (lastBadgeWidth + plusButtonWidth);

                return (
                  <div key={badge}>
                    <div className="absolute flex">
                      <PopoverTriggerV2
                        forceOpen={popOverOpen}
                        onClose={() => setPopOverOpen(false)}
                        popover={({ close, ...props }) =>
                          renderPopoverOnBadgeClick?.(badge, close)
                        }
                        key={badge}
                      >
                        <TooltipTrigger tooltip={badge}>
                          <Badge
                            className="mb-1 mr-1 inline-block truncate rounded-sm px-2 font-robotoMono text-xs font-medium text-text-secondary hover:bg-primary hover:text-white"
                            key={badge}
                            variant="outline"
                            style={{ width: availableWidth }}
                          >
                            {badge}
                          </Badge>
                        </TooltipTrigger>
                      </PopoverTriggerV2>
                    </div>

                    <div className="layout__overflow-list-content__more absolute bg-white pl-[4px] group-hover:bg-background-active right-0">
                      <PopoverTriggerV2
                        forceOpen={false}
                        onClose={() => setPopOverOpen(false)}
                        popover={({ close, ...props }) => (
                          <BadgeGroupPanel
                            badgeList={badgeList}
                            renderPopoverOnBadgeClick={() =>
                              renderPopoverOnBadgeClick?.(badge, close)
                            }
                          />
                        )}
                        key={badge}
                      >
                        <Button
                          className="h-[26px] px-2"
                          variant="outline"
                          size="sm"
                        >
                          +{badgeList.length - idx}
                        </Button>
                      </PopoverTriggerV2>
                    </div>
                  </div>
                );
              }

              return (
                <PopoverTriggerV2
                  forceOpen={popOverOpen}
                  onClose={() => setPopOverOpen(false)}
                  popover={({ close, ...props }) =>
                    renderPopoverOnBadgeClick?.(badge, close)
                  }
                  key={badge}
                >
                  <Badge
                    className={classNames({
                      'mb-1 mr-1 inline-block truncate rounded-sm px-2 text-xs font-medium text-text-secondary font-robotoMono hover:bg-primary hover:text-white':
                        true,
                      invisible: isOverflow,
                    })}
                    key={badge}
                    variant="outline"
                  >
                    {badge}
                  </Badge>
                </PopoverTriggerV2>
              );
            })}
          </div>
        );
      }}
    </SizeObserver>
  );
};

export default BadgeGroup;
