import React, { ReactElement, useRef, useState } from 'react';

const DelayedHoverComponent = ({
  children,
  className,
  delay = 500,
  onMouseLeft,
  onDelayComplete,
}: {
  children: (props: { isHovered: boolean }) => React.ReactNode;
  className?: string;
  delay?: number;
  onDelayComplete?: () => void;
  onMouseLeft?: () => void;
}): ReactElement => {
  const [isHovered, setIsHovered] = useState(false);
  const hoverTimerRef = useRef<number>();
  const lastHoveredSeriesRef = useRef<number | null>(null);

  return (
    <div
      className={className}
      onMouseLeave={() => {
        window.clearTimeout(hoverTimerRef.current);
        setIsHovered(false);
        lastHoveredSeriesRef.current = null;
        onMouseLeft?.();
      }}
      onMouseEnter={() => {
        hoverTimerRef.current = window.setTimeout(() => {
          setIsHovered(true);
          onDelayComplete?.();
        }, delay);
      }}
    >
      {(children as (props: { isHovered: boolean }) => React.ReactNode)({
        isHovered,
      })}
    </div>
  );
};

export default DelayedHoverComponent;
