import React, { useMemo } from 'react';
import { Button, SelectV2, TooltipTrigger } from 'components';
import { SelectOption } from 'components/SelectV2';
import { useSearch } from 'hooks';
import { logsTopBottomCountOptions } from 'kfuse-constants/logsAnalytics';
import { Plus } from 'react-feather';
import { IoMdClose, IoMdMore, IoMdRemove } from 'react-icons/io';
import { LimitTo, Operation } from 'types';
import isRangeFacet from '../utils/isRangeFacet';
import { RumTab } from '../types';

const limitToOptions = Object.keys(LimitTo).map((limitTo) => ({
  label: limitTo,
  value: limitTo,
}));

const RumMultiGroup = ({
  addAdvancedGroupBy,
  advancedGroupBys,
  changeAdvancedGroupBys,
  measureOptions,
  rumTab,
  groupByOptions,
  operationOptions,
}: {
  addAdvancedGroupBy: ReturnType<typeof useSearch>['addAdvancedGroupBy'];
  advancedGroupBys: ReturnType<typeof useSearch>['advancedGroupBys'];
  changeAdvancedGroupBys: ReturnType<
    typeof useSearch
  >['changeAdvancedGroupBys'];
  measureOptions: SelectOption[];
  rumTab: RumTab;
  groupByOptions: SelectOption[];
  operationOptions: SelectOption[];
}) => {
  const handleGroupByChange = (value: string, index: number) => {
    const nextGroupBys = [...advancedGroupBys];
    nextGroupBys[index] = {
      ...nextGroupBys[index],
      by: value,
    };
    changeAdvancedGroupBys(nextGroupBys);
  };

  const handleLimitToChange = (value: LimitTo, index: number) => {
    const nextGroupBys = [...advancedGroupBys];
    nextGroupBys[index] = {
      ...nextGroupBys[index],
      limitTo: value,
    };
    changeAdvancedGroupBys(nextGroupBys);
  };

  const handleLimitToValueChange = (value: number, index: number) => {
    const nextGroupBys = [...advancedGroupBys];
    nextGroupBys[index] = {
      ...nextGroupBys[index],
      limitToValue: value,
    };
    changeAdvancedGroupBys(nextGroupBys);
  };

  const handleAddGroupBy = () => {
    const firstGroupBy = advancedGroupBys[0];
    if (firstGroupBy.by === '*') {
      if (groupByOptions.length > 0) {
        handleGroupByChange(groupByOptions[1].value, 0);
      }
    } else {
      addAdvancedGroupBy(groupByOptions[1].value);
    }
  };

  const handleDeleteGroupBy = (index: number) => {
    if (advancedGroupBys.length === 1) {
      return;
    }
    const nextGroupBys = [...advancedGroupBys];
    nextGroupBys.splice(index, 1);
    changeAdvancedGroupBys(nextGroupBys);
  };

  const handleToggleAdvancedOptions = (index: number) => {
    const nextGroupBys = [...advancedGroupBys];
    nextGroupBys.forEach((advancedGroupBy, index) => {
      const isSortAggregationExists = advancedGroupBy.sortAggregation;
      nextGroupBys[index] = {
        ...advancedGroupBy,
        sortAggregation: isSortAggregationExists ? null : 'count',
        sortField: null,
      };
    });
    changeAdvancedGroupBys(nextGroupBys);
  };

  const handleSortAggregationChange = (value: string, index: number) => {
    const nextGroupBys = [...advancedGroupBys];
    nextGroupBys[index] = {
      ...nextGroupBys[index],
      sortAggregation: value,
    };
    changeAdvancedGroupBys(nextGroupBys);
  };

  const handleSortFieldChange = (value: string, index: number) => {
    const nextGroupBys = [...advancedGroupBys];
    nextGroupBys[index] = {
      ...nextGroupBys[index],
      sortField: value,
      sortAggregation: isRangeFacet(value)
        ? Operation.avg
        : Operation.distinctcount,
    };
    changeAdvancedGroupBys(nextGroupBys);
  };

  const isGroupByDisabled = useMemo(() => {
    return rumTab === RumTab.geomap;
  }, [rumTab]);

  return (
    <>
      {advancedGroupBys.map((advancedGroupBy, index) => {
        const showLimitOptions = advancedGroupBy.by !== '*';
        const isSortAggregationExists = advancedGroupBy.sortAggregation;
        const operationPlaceholder =
          advancedGroupBy.sortField === null ? 'Count of' : 'Count unique of';

        return (
          <React.Fragment key={index}>
            <div className="button-group">
              <div className="button-group__item button-group__item--label">
                by
              </div>
              <div className="button-group__item button-group__item--no-border-radius">
                {isGroupByDisabled ? (
                  <TooltipTrigger tooltip="Geo Map Supports One Groupby">
                    <div className="mx-2 cursor-not-allowed">geo.country</div>
                  </TooltipTrigger>
                ) : (
                  <SelectV2.Select
                    isAutocompleteEnabled
                    onChange={(val) => handleGroupByChange(val, index)}
                    options={groupByOptions}
                    value={advancedGroupBy.by}
                  />
                )}
              </div>
            </div>

            <div className="button-group">
              {showLimitOptions && (
                <>
                  <div className="button-group__item  button-group__item--label button-group__item--no-border-radius">
                    limit to
                  </div>
                  <div className="button-group__item">
                    <SelectV2.Select
                      onChange={(val) => handleLimitToChange(val, index)}
                      options={limitToOptions}
                      value={advancedGroupBy.limitTo}
                    />
                  </div>
                  <div className="button-group__item">
                    <SelectV2.Select
                      onChange={(val) => handleLimitToValueChange(val, index)}
                      options={logsTopBottomCountOptions}
                      value={advancedGroupBy.limitToValue}
                    />
                  </div>
                  <div className="button-group__item button-group__item--no-border-radius">
                    <TooltipTrigger
                      tooltip={
                        isSortAggregationExists ? 'Remove Sort' : 'Add Sort'
                      }
                    >
                      <Button
                        className="h-full px-1"
                        onClick={() => handleToggleAdvancedOptions(index)}
                        variant="icon"
                        size="sm"
                      >
                        {isSortAggregationExists ? (
                          <IoMdRemove size={20} />
                        ) : (
                          <IoMdMore size={20} />
                        )}
                      </Button>
                    </TooltipTrigger>
                  </div>
                  {isSortAggregationExists && (
                    <>
                      <div className="button-group__item  button-group__item--label">
                        sorted by
                      </div>
                      <div className="button-group__item button-group__item--value">
                        {isRangeFacet(advancedGroupBy.sortField) ? (
                          <SelectV2.Select
                            isAutocompleteEnabled
                            onChange={(val) =>
                              handleSortAggregationChange(val, index)
                            }
                            options={operationOptions}
                            value={advancedGroupBy.sortAggregation}
                          />
                        ) : (
                          operationPlaceholder
                        )}
                      </div>
                      <div className="button-group__item">
                        <SelectV2.Select
                          isAutocompleteEnabled
                          onChange={(val) => handleSortFieldChange(val, index)}
                          options={measureOptions}
                          value={advancedGroupBy.sortField}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
              {advancedGroupBys.length > 1 && (
                <div className="button-group__item button-group__item--no-border-radius">
                  <Button
                    className="h-full px-1"
                    onClick={() => handleDeleteGroupBy(index)}
                    variant="icon"
                    size="sm"
                  >
                    <IoMdClose size={20} />
                  </Button>
                </div>
              )}
            </div>
            <div className="rum-search__button-group__divider flex flex-row items-center">
              <div />
            </div>
          </React.Fragment>
        );
      })}
      {!isGroupByDisabled && (
        <div className="button-group">
          <div className="button-group__item">
            <Button
              className="h-full px-1"
              onClick={handleAddGroupBy}
              variant="icon"
              size="sm"
            >
              <Plus size={20} />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default RumMultiGroup;
