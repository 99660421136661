import { chartingPalette } from 'kfuse-constants';
import { RumEventType } from 'screens/Rum';

export const colorByRumEventType = Object.values(RumEventType).reduce(
  (obj, rumEventType: RumEventType, i) => ({
    ...obj,
    [rumEventType]: chartingPalette[i % chartingPalette.length],
  }),
  {} as Record<RumEventType, string>,
);
