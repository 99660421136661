import { onPromiseError } from 'utils';
import queryTraceService from './queryTraceService';

type Args = {
  label: string;
};

const getServiceGroupLabelValues = async ({
  label,
}: Args): Promise<string[]> => {
  return queryTraceService<string[], 'serviceGroupLabelValues'>(`{
    serviceGroupLabelValues(label: "${label}")
  }`).then(
    (data) => (data.serviceGroupLabelValues || []).filter(Boolean),
    onPromiseError,
  );
};

export default getServiceGroupLabelValues;
