import { Frame, StackTrace } from 'components';
import React from 'react';

type Props = {
  errors: (string | Frame[])[];
};

const isParsableError = (error: (unknown | Frame)[]) => {
  if (error.length) {
    const frame = error[0] as Frame;
    if (typeof frame === 'object') {
      return frame.filename && frame.function;
    }
  }
};

const TraceSidebarActiveSpanErrors = ({ errors }: Props) => {
  return (
    <div className="trace-sidebar__active-span__errors">
      {errors.map((error, i) =>
        Array.isArray(error) && isParsableError(error) ? (
          <StackTrace key={i} frames={error} />
        ) : (
          <div className="trace-sidebar__active-span__errors__item" key={i}>
            {JSON.stringify(error)}
          </div>
        ),
      )}
    </div>
  );
};

export default TraceSidebarActiveSpanErrors;
