import dayjs from 'dayjs';
import { DateSelection } from 'types';
import { onPromiseError } from 'utils';
import queryTraceService from './queryTraceService';

type Args = {
  contains: string;
  date: DateSelection;
};

const errorLabelNames = async ({ contains, date }: Args): Promise<string[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  return queryTraceService<string[], 'errorLabelNames'>(`
    {
      errorLabelNames(
        ${contains ? `contains: "${contains}"` : ''}
        durationSecs: ${durationSecs},
        timestamp: "${endTime.format()}",
      )
    }
  `).then((data) => data.errorLabelNames || [], onPromiseError);
};

export default errorLabelNames;
