import { HostmapDataProps } from 'components/KfuseHostmap';
import { TableTransformerData } from '../types';
import { themeHexagonColorDark, themeHexagonColorLight } from 'utils/colors';

const hostmapTransformer = ({
  data,
  columns,
}: TableTransformerData): { data: HostmapDataProps[] } => {
  const hostmapData: HostmapDataProps[] = [];
  const labelCols = columns.filter((column) => !column.key.includes('Value #'));
  const valueCols = columns.find((column) => column.key.includes('Value #'));

  const labelKeys = labelCols.map((col) => col.key);
  const valueKeys = valueCols?.key;
  const darkModeEnabled = true;
  const hostmapColor = darkModeEnabled
    ? themeHexagonColorDark.fill
    : themeHexagonColorLight.fill;
  const borderColor = darkModeEnabled
    ? themeHexagonColorDark.border
    : themeHexagonColorLight.border;

  const outerBorderColor = darkModeEnabled
    ? themeHexagonColorDark.outline
    : themeHexagonColorLight.outline;

  data.forEach((item) => {
    hostmapData.push({
      label: labelKeys.map((key) => `${key}: ${item[key]}`).join(', '),
      value: Number(item[valueKeys]),
      color: hostmapColor,
      borderColor,
      outerBorderColor,
    });
  });
  return { data: hostmapData };
};

export default hostmapTransformer;
