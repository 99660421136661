import { Filter, useDateState, useFiltersState, useSpanFilters } from 'hooks';
import { TracesTableColumnKey } from 'kfuse-constants';
import { useMemo, useState } from 'react';
import { SpanFilter } from 'types';
import { FilterType } from './useFiltersState';
import getFacetRegexState from './useFiltersState/getFacetRegexState';
import getKeyExistsState from './useFiltersState/getKeyExistsState';
import getSelectedFacetRangeByNameState from './useFiltersState/getSelectedFacetRangeByNameState';
import getSelectedFacetValuesByNameState from './useFiltersState/getSelectedFacetValuesByNameState';
import useSortState from './useSortState';

type Options = {
  dateState?: ReturnType<typeof useDateState>;
  initialCustomerFilter?: { key: string; value: string };
  initialFilters?: Filter[];
  initialSpanFilter?: SpanFilter;
  initialTraceIdSearch?: string;
  initialSelectedQueryIndex?: string;
  shouldWriteToUrl?: boolean;
  urlStateKey?: string;
};

const useTracesState = ({
  dateState: dateStateOverride,
  initialCustomerFilter,
  initialFilters,
  initialSpanFilter,
  initialSelectedQueryIndex = null,
  initialTraceIdSearch,
  shouldWriteToUrl = true,
  urlStateKey,
}: Options) => {
  const baseDateState = useDateState();
  const dateState = dateStateOverride ? dateStateOverride : baseDateState;
  const filtersState = useFiltersState({
    initialState: initialFilters,
    shouldWriteToUrl,
    urlStateKey,
  });

  const [customerFilter, setCustomerFilter] = useState<{
    key: string;
    value: string;
  }>(initialCustomerFilter);

  const facetRegexState = useMemo(
    () => getFacetRegexState(filtersState),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersState.state],
  );
  const keyExistsState = useMemo(
    () => getKeyExistsState({ filtersState, filterType: FilterType.keyExists }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersState.state],
  );
  const selectedFacetRangeByNameState = useMemo(
    () => getSelectedFacetRangeByNameState(filtersState),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersState.state],
  );

  const selectedHcFacetValuesByNameState = useMemo(
    () => getSelectedFacetValuesByNameState(filtersState),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersState.state],
  );

  const selectedFacetValuesByNameState = useMemo(
    () => getSelectedFacetValuesByNameState(filtersState),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersState.state],
  );

  const tracesSortState = useSortState({
    urlStateKey: 'tracesSort',
    initalState: {
      key: TracesTableColumnKey.spanStartTimeNs,
      isAsc: false,
    },
  });

  const [traceIdSearch, setTraceIdSearch] = useState(
    initialTraceIdSearch || '',
  );
  const spanFilters = useSpanFilters({
    initialSpanFilter,
    selectedFacetValuesByNameState,
    spanFilterOptionsList: [
      SpanFilter.allSpans,
      SpanFilter.serviceEntrySpans,
      SpanFilter.traceRootSpans,
    ],
  });

  const [selectedQueryIndexString, setSelectedQueryIndexString] = useState(
    initialSelectedQueryIndex,
  );

  const state = useMemo(() => {
    return {
      customerFilter,
      date: dateState[0],
      facetRegex: facetRegexState.state,
      filters: filtersState.state,
      keyExists: keyExistsState.state,
      selectedFacetRangeByName: selectedFacetRangeByNameState.state,
      selectedHcFacetValuesByName: selectedHcFacetValuesByNameState.state,
      selectedFacetValuesByName: selectedFacetValuesByNameState.state,
      spanFilter: spanFilters.spanFilter,
      traceIdSearch,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerFilter,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dateState[0],
    facetRegexState.state,
    filtersState.state,
    keyExistsState.state,
    selectedFacetRangeByNameState.state,
    selectedFacetValuesByNameState.state,
    selectedHcFacetValuesByNameState.state,
    traceIdSearch,
    spanFilters.spanFilter,
  ]);

  return {
    customerFilter,
    dateState,
    facetRegexState,
    filtersState,
    keyExistsState,
    selectedQueryIndexString,
    setCustomerFilter,
    setSelectedQueryIndexString,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    selectedHcFacetValuesByNameState,
    setTraceIdSearch,
    spanFilters,
    state,
    traceIdSearch,
    tracesSortState,
  };
};

export default useTracesState;
