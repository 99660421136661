import {
  Button,
  ConfirmationModal,
  DateControls,
  PopoverPosition,
  PopoverTriggerV2,
  TooltipTrigger,
  useModalsContext,
  useToaster,
} from 'components';
import Datepicker from 'composite/Datepicker';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Check, Plus } from 'react-feather';
import { MdModeEdit } from 'react-icons/md';
import { IoSettingsSharp } from 'react-icons/io5';
import { DashboardPanelProps } from 'types/Dashboard';
import { getUrlParamByKey } from 'utils';

import {
  DashboardConfigureImport,
  DashboardConfigurePopover,
} from './DashboardConfigure';
import { validateDashboardJson } from './DashboardConfigure/utils';
import DashboardDetailsModal from './DashboardDetailsModal';
import { DasboardTemplate } from './DashboardTemplate';
import { useDashboardState, useDashboardTemplateState } from './hooks';
import { getDefaultDashboardSettings } from './utils';

const DISABLE_TEMPLATE_VARIABLES = true;

const DashboardHeader = ({
  disableEditPanel,
  dashboardState,
  dashboardTemplateState,
  hideSidebar,
}: {
  dashboardState: ReturnType<typeof useDashboardState>;
  dashboardTemplateState: ReturnType<typeof useDashboardTemplateState>;
  disableEditPanel?: boolean;
  hideSidebar?: boolean;
}): ReactElement => {
  const navigate = useNavigate();
  const modal = useModalsContext();
  const { dashboardId } = useParams();
  const { addToast } = useToaster();
  const jsonFile = getUrlParamByKey('jsonFile');
  const {
    date,
    dashboardDetails,
    initialDashboardSetup,
    panels,
    isRightSidebarOpenToggle,
    onDateChange,
    onConfirmDashboardDelete,
    onUpdateDashboards,
    panelSetupModal,
    setDashboardDetails,
    setDashboardMeta,
  } = dashboardState;
  const { templating, setTemplateValues, setTemplating } =
    dashboardTemplateState;

  const openDashboardDetailsModal = () => {
    panelSetupModal.push(
      <DashboardDetailsModal
        close={() => panelSetupModal.pop()}
        dashboardState={dashboardState}
      />,
    );
  };

  const openEditTemplateVariablesModal = () => {
    panelSetupModal.push(
      <DasboardTemplate
        date={date}
        close={() => panelSetupModal.pop()}
        dashboardTemplateState={dashboardTemplateState}
        applyTemplating={(newTemplating) => {
          panelSetupModal.pop();
          setDashboardDetails((prev) => ({
            ...prev,
            isEdited: true,
            templating: { list: newTemplating },
          }));
          initialTemplateSetup(newTemplating);
        }}
      />,
    );
  };

  const onDashboardDelete = () => {
    modal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description={
          <span>
            Are you sure you want to delete &quot;
            <b className="text--red">{dashboardDetails.title}</b>&quot;
            dashboard? Once deleted, it cannot be recovered.
          </span>
        }
        onCancel={() => modal.pop()}
        onConfirm={() => {
          onConfirmDashboardDelete();
          modal.pop();
        }}
        title={`Delete dashboard`}
      />,
    );
  };

  const onDashboardJsonImport = (json: any, folderUid: string) => {
    if (!validateDashboardJson(json)) {
      addToast({ text: 'Invalid Dashboard JSON', status: 'error' });
      return;
    }
    json.panels = json.panels.map((panel: DashboardPanelProps) => ({
      ...panel,
      isEdited: true,
    }));
    json.uid = null;
    json.id = null;

    initialDashboardSetup(json);
    setDashboardDetails((prevState) => ({ ...prevState, folderUid }));
    setDashboardMeta((prevState) => ({ ...prevState, folderUid }));
    modal.pop();
    navigate('/dashboards/import?jsonFile=imported');
  };

  const onDashboardImport = () => {
    modal.push(
      <DashboardConfigureImport
        close={() => {
          modal.pop();
          navigate('/dashboards/new');
        }}
        onDashboardJsonImport={onDashboardJsonImport}
      />,
    );
  };

  const isDashboardEdited = useMemo(() => {
    if (dashboardDetails.isEdited) return true;
    return panels.some((panel) => panel.isEdited);
  }, [dashboardDetails.isEdited, panels]);

  const resetDashboard = () => {
    setTemplateValues({});
    setTemplating([]);
    initialDashboardSetup({ panels: [], ...getDefaultDashboardSettings() });
  };

  useEffect(() => {
    if (dashboardId === 'import' && jsonFile === 'none') {
      isRightSidebarOpenToggle.off();
      onDashboardImport();
    }

    if (dashboardId === 'new') {
      isRightSidebarOpenToggle.on();
      resetDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardId, jsonFile]);

  return (
    <div className="dashboard__header">
      <div className="dashboard__header__left">
        <div
          className="dashboard__header__left__title"
          onClick={openDashboardDetailsModal}
        >
          {dashboardDetails.title}
          <MdModeEdit />
        </div>
        {!hideSidebar && (
          <>
            {isRightSidebarOpenToggle.value ? (
              <Button
                className="mr-1"
                onClick={isRightSidebarOpenToggle.off}
                variant="outline"
                size="sm"
              >
                <Check size={16} /> &nbsp; Close
              </Button>
            ) : (
              <Button
                className="mr-1"
                onClick={isRightSidebarOpenToggle.on}
                variant="outline"
                size="sm"
              >
                <Plus size={16} /> &nbsp; Add Panels
              </Button>
            )}
          </>
        )}
      </div>

      <div className="flex">
        {!disableEditPanel && isDashboardEdited ? (
          <div className="mr-4">
            <button
              className="button button--blue"
              onClick={onUpdateDashboards}
            >
              Save Dashboard
            </button>
          </div>
        ) : null}
        {!DISABLE_TEMPLATE_VARIABLES && (
          <TooltipTrigger tooltip="A template variable is a placeholder that you can use in your dashboards to represent a value or a set of values. It allows you to create more interactive and dynamic dashboards.">
            <Button
              className="mr-1"
              onClick={openEditTemplateVariablesModal}
              variant="link"
              size="sm"
            >
              {templating.length === 0 ? 'Add' : 'Edit'} Template Variables
            </Button>
          </TooltipTrigger>
        )}
        {!disableEditPanel && (
          <PopoverTriggerV2
            className="flex flex--align-items-center"
            popover={({ close }) => (
              <DashboardConfigurePopover
                close={close}
                dashboardState={dashboardState}
                onDashboardDelete={() => {
                  onDashboardDelete();
                  close();
                }}
              />
            )}
            position={PopoverPosition.BOTTOM}
          >
            <TooltipTrigger tooltip="Settings">
              <Button className="mr-1" variant="ghost" size="sm">
                <IoSettingsSharp size={16} />{' '}
                <span className="ml-1">Actions</span>
              </Button>
            </TooltipTrigger>
          </PopoverTriggerV2>
        )}
        <Datepicker
          className="dashboard__header__right__datepicker"
          hasStartedLiveTail={false}
          onChange={onDateChange}
          startLiveTail={null}
          value={date}
        />
        <DateControls date={date} setDate={onDateChange} />
      </div>
    </div>
  );
};

export default DashboardHeader;
