import { Stepper, Textarea } from 'components';
import React, { ReactElement, useEffect } from 'react';
import {
  DashboardPanelProps,
  DashboardPanelType,
  DashboardTemplateValueProps,
} from 'types';

import {
  DashboardPanelModalFooter,
  DashboardPanelModalHeader,
} from '../components';
import { DashboardPanelFreeText } from '../Panels';

import DashboardEditPanelName from './DashboardEditPanelName';
import { useDashboardEditState } from './hooks';
import classNames from 'classnames';

const streamTypeOptions: {
  label: string;
  value: DashboardPanelProps['options']['mode'];
}[] = [
  { label: 'Markdown', value: 'markdown' },
  { label: 'HTML', value: 'html' },
];

const DashboardEditTextType = ({
  dashboardEditState,
}: {
  dashboardEditState: ReturnType<typeof useDashboardEditState>;
}): ReactElement => {
  const { editPanel, setEditPanel } = dashboardEditState;
  return (
    <div className="flex">
      <div className="button-group mt-2 mb-2">
        {streamTypeOptions.map(({ label, value }) => {
          return (
            <div
              className={classNames({
                'button-group__item': true,
                'button-group__item--active': editPanel.options?.mode === value,
              })}
              key={value}
            >
              <button
                className="flex flex--align-items-center"
                onClick={() =>
                  setEditPanel((prevState) => ({
                    ...prevState,
                    options: { ...prevState.options, mode: value },
                  }))
                }
              >
                {label}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const sampleText = `<h1>Text to display</h1>`;

const DashboardEditText = ({
  close,
  dashboardEditState,
  panel,
}: {
  baseWidth: number;
  close: () => void;
  dashboardEditState: ReturnType<typeof useDashboardEditState>;
  panel: DashboardPanelProps;
  panelType?: DashboardPanelType;
  templateValues?: DashboardTemplateValueProps;
}): ReactElement => {
  const { editPanel, onSaveClickLogEvents, setEditPanel } = dashboardEditState;

  useEffect(() => {
    if (panel) return;
    setEditPanel((prev) => ({
      ...prev,
      options: { ...prev.options, content: sampleText, mode: 'html' },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardPanelModalHeader
        close={close}
        modalDate={null}
        setModalDate={null}
        title={!panel ? 'Add Panel' : editPanel.title || 'Edit Panel'}
      />
      <div className="dashboard-edit__metric__body">
        <div className="mt-4 ml-4 mb-4 border" style={{ minHeight: 250 }}>
          <DashboardPanelFreeText panel={editPanel} />
        </div>
        <Stepper
          steps={[
            {
              title: 'Text to display',
              component: (
                <div>
                  <DashboardEditTextType
                    dashboardEditState={dashboardEditState}
                  />
                  <Textarea
                    onChange={(val) =>
                      setEditPanel((prevState) => ({
                        ...prevState,
                        options: { ...prevState.options, content: val },
                      }))
                    }
                    type="text"
                    value={editPanel.options?.content || ''}
                  />
                </div>
              ),
            },
            {
              title: 'Enter chart title',
              component: (
                <DashboardEditPanelName
                  dashboardEditState={dashboardEditState}
                />
              ),
            },
          ]}
        />
      </div>
      <DashboardPanelModalFooter
        close={close}
        onSave={() => {
          onSaveClickLogEvents('');
        }}
      />
    </>
  );
};

export default DashboardEditText;
