import classnames from 'classnames';
import React from 'react';
import { Check, X, Info, AlertTriangle } from 'react-feather';

import { toast } from 'sonner';
import { ToastStatus } from './types';

export type AddToastProps = {
  id?: string;
  description?: string;
  dismissible?: boolean;
  onDismiss?: () => void;
  onAutoClose?: () => void;
  status: 'success' | 'error' | 'warn' | 'info';
  text: string;
  timeout?: number;
  position?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
};

const ToastIcon = ({ status }: { status: ToastStatus }) => {
  return (
    <div
      className={classnames({
        toast__icon: true,
        [`toast__icon--${status}`]: true,
      })}
    >
      {status === 'success' ? (
        <Check className="toast__icon--color" />
      ) : status === 'error' ? (
        <X className="toast__icon--color" />
      ) : status === 'warn' ? (
        <AlertTriangle className="toast__icon--color" />
      ) : status === 'info' ? (
        <Info className="toast__icon--color" />
      ) : null}
    </div>
  );
};

export const useToaster = () => {
  return {
    addToast: ({
      description,
      dismissible = false,
      id,
      status,
      text,
      timeout = 2500,
      position = 'top-right',
      onDismiss,
      onAutoClose,
    }: AddToastProps) =>
      toast(text, {
        description,
        dismissible,
        duration: timeout,
        classNames: {
          title: 'ml-2',
          actionButton: 'toast__close svg--size-8',
        },
        style: { padding: '0.5rem 1rem' },
        icon: <ToastIcon status={status} />,
        action: {
          label: (<X size={8} />) as unknown as string,
          onClick: () => toast.dismiss(text),
        },
        id,
        position,
        onDismiss,
        onAutoClose,
      }),
  };
};
