import classnames from 'classnames';
import React from 'react';
import * as Shadcn from '../shadcn';

type Props = {
  className?: string;
  renderTrigger: () => JSX.Element | JSX.Element[];
};

const AccordionTrigger = ({ className, renderTrigger }: Props) => (
  <Shadcn.AccordionTrigger
    className={classnames({
      accordion__trigger: true,
      [className]: className,
    })}
  >
    {renderTrigger()}
  </Shadcn.AccordionTrigger>
);

export default AccordionTrigger;
