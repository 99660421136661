import { useRequest, useToggle } from 'hooks';
import {
  MutableRefObject,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SelectedFacetValues, ValueCount } from 'types';
import { mergeFacetValues } from '../utils';
import useDebouncedEffect from 'use-debounced-effect';

const searchFacetValues = (
  facetValues: ValueCount[],
  facetValueSearch: string,
  getSearchedValue?: (value: string) => void,
) => {
  const searchLowered = facetValueSearch.trim().toLowerCase();
  if (!searchLowered) {
    return facetValues;
  }

  return facetValues.filter((facetValue) => {
    const valueToSearch =
      typeof getSearchedValue === 'function'
        ? getSearchedValue(facetValue.value)
        : facetValue.value;

    return (valueToSearch || '').toLowerCase().indexOf(searchLowered) > -1;
  });
};

type Props = {
  disableSearch?: boolean;
  excludeFacetValue: (value: string) => void;
  getFacetValuesRequest: ReturnType<typeof useRequest>;
  getSearchedValue?: (value: string) => string;
  isRadio?: boolean;
  lastRefreshedAt: number;
  name: string;
  ref?: MutableRefObject<HTMLDivElement>;
  renderedName: ReactNode;
  renderPlaceholderText: (name: string) => string;
  renderValue?: (label: string) => ReactNode;
  selectedFacetValues: SelectedFacetValues;
  selectOnlyFacetValue: (value: string) => void;
  shouldDisableLoader?: boolean;
  toggleFacetValue: (value: string, values: ValueCount[]) => void;
  isRangeFacet?: boolean;
  expanded: boolean;
};

const useFacetPickerValuesState = ({
  disableSearch,
  excludeFacetValue,
  getFacetValuesRequest,
  getSearchedValue,
  isRadio,
  lastRefreshedAt,
  name,
  renderedName,
  renderPlaceholderText,
  renderValue,
  selectedFacetValues,
  selectOnlyFacetValue,
  shouldDisableLoader,
  toggleFacetValue,
  isRangeFacet,
  expanded,
}: Props) => {
  const [initialFacetValues, setInitialFacetValues] = useState<ValueCount[]>(
    [],
  );
  const [facetValueSearch, setFacetValueSearch] = useState<string>('');
  const showAllToggle = useToggle();

  const isLoading = shouldDisableLoader
    ? getFacetValuesRequest.isLoading && !initialFacetValues.length
    : getFacetValuesRequest.isLoading;

  const facetValues = useMemo(
    () => (getFacetValuesRequest.result as ValueCount[]) || [],
    [getFacetValuesRequest.result],
  );

  const allValues = useMemo(() => {
    return mergeFacetValues({
      oldFacetValues: initialFacetValues,
      newFacetValues: facetValues,
    });
  }, [initialFacetValues, facetValues]);

  const searchedFacetValues = useMemo(() => {
    if (isRangeFacet) {
      return [];
    }
    const result = searchFacetValues(
      allValues.filter((facetValue) => facetValue.value !== 'UNKNOWN'),
      facetValueSearch,
      getSearchedValue,
    );

    return result.sort((a, b) => b.count - a.count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initialFacetValues,
    getFacetValuesRequest.result,
    facetValueSearch,
    getSearchedValue,
  ]);

  useDebouncedEffect(
    () => {
      if (!expanded) {
        return;
      }
      getFacetValuesRequest.call().then((facetValues: ValueCount[]) => {
        if (!shouldDisableLoader) {
          if (initialFacetValues.length < facetValues.length) {
            setInitialFacetValues(facetValues);
          }
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      ignoreInitialCall: false,
      timeout: 200,
    },
    [expanded, lastRefreshedAt],
  );

  useEffect(() => {
    if (shouldDisableLoader) {
      const results: ValueCount[] =
        (getFacetValuesRequest.result as ValueCount[]) || [];
      if (initialFacetValues.length < results.length) {
        setInitialFacetValues(results);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFacetValuesRequest.result]);

  const countsByFacetValue: { [value: string]: number } = useMemo(
    () =>
      isRangeFacet
        ? {}
        : facetValues.reduce(
            (obj, facetValue: ValueCount) => ({
              ...obj,
              [facetValue.value]: facetValue.count,
            }),
            {},
          ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [facetValues],
  );

  const handlersByValue = (value: string) => ({
    excludeFacetValue: () => {
      excludeFacetValue(value);
    },
    selectOnlyFacetValue: () => {
      selectOnlyFacetValue(value);
    },
    toggleFacetValue: () => {
      toggleFacetValue(value, allValues);
    },
  });

  const searchedFacetValuesCount = searchedFacetValues.length;

  return {
    disableSearch,
    facetValues,
    setFacetValueSearch,
    name,
    renderedName,
    facetValueSearch,
    isLoading,
    searchedFacetValuesCount,
    renderPlaceholderText,
    searchedFacetValues,
    showAllToggle,
    countsByFacetValue,
    isRadio,
    renderValue,
    selectedFacetValues,
    handlersByValue,
    allValues,
    // fetchGetFacetValues,
  };
};

export default useFacetPickerValuesState;
