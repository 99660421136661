import {
  DateSelection,
  ExplorerQueryProps,
  FormulaProps,
  MetricsQueriesDataProps,
  QueryDataProps,
} from 'types';
import { Series } from 'uplot';
import {
  buildFormulaQuery,
  buildPromqlWithFunctions,
  getFunctionParams,
  getFunctionNameByShortName,
  getColorForOneSeries,
  isMetricsQueryHasAdvanceFunc,
} from 'utils';

import {
  CompareToPreviousProps,
  MetricsChartsQueryItemProps,
  MetricsMultiDatePromqlProps,
} from './types';
import { AlertType } from 'screens/NewAlerts/types';

export const compareToPreviousList = [
  { label: 'Hour', value: '1h', isActive: false },
  { label: 'Day', value: '1d', isActive: false },
  { label: 'Week', value: '1w', isActive: false },
  { label: 'Month', value: '1M', isActive: false },
  { label: 'Quarter', value: '3M', isActive: false },
];

export const predefinedFunctions = [
  { label: 'Basic Anomalies', value: 'anomalies', isActive: false },
  { label: 'Seasonal Anomalies', value: 'anomalies', isActive: false },
  { label: 'Agile Anomalies', value: 'anomalies', isActive: false },
  { label: 'Outliers', value: 'outliers', isActive: false },
  { label: 'Histogram Quantile', value: 'histogram_quantile', isActive: false },
  { label: 'Derivative', value: 'deriv', isActive: false },
];

const getTimeDiffInSeconds = (code: string) => {
  switch (code) {
    case '1h':
      return 3600;
    case '1d':
      return 86400;
    case '1w':
      return 604800;
    case '1M':
      return 2592000;
    case '3M':
      return 7776000;
    default:
      return 0;
  }
};

export const getPreviousTimeRangeWithPromql = (
  compareToPrev: CompareToPreviousProps[],
  date: DateSelection,
  promql: string,
): MetricsMultiDatePromqlProps[] => {
  const promqlQueries: MetricsMultiDatePromqlProps[] = [];
  const { startTimeUnix, endTimeUnix } = date;

  compareToPrev.forEach(({ isActive, label, value }) => {
    if (isActive) {
      const timeDiff = getTimeDiffInSeconds(value);
      const newStartTimeUnix = startTimeUnix - timeDiff;
      const newEndTimeUnix = endTimeUnix - timeDiff;

      promqlQueries.push({
        date: { startTimeUnix: newStartTimeUnix, endTimeUnix: newEndTimeUnix },
        promql,
        label: `${label.toLocaleLowerCase()}_ago`,
      });
    }
  });

  return promqlQueries;
};

export const getPromqlQueryByIndex = (
  queryItem: MetricsChartsQueryItemProps,
  activeFunction: CompareToPreviousProps,
): string => {
  const { formulas, queries, queryIndex, type } = queryItem;
  if (type === 'query') {
    const query = { ...queries[queryIndex] };
    const functions = [...query.functions];

    if (!query.metric) {
      return '';
    }

    if (activeFunction) {
      const { label, value } = activeFunction;
      const funtionParams = getFunctionParams(value);
      if (value === 'anomalies') {
        if (label === 'Seasonal Anomalies') {
          funtionParams[0].value = 'robust';
        }

        if (label === 'Agile Anomalies') {
          funtionParams[0].value = 'agile';
        }
      }
      const functionName = getFunctionNameByShortName(value);
      functions.push({
        name: value,
        params: funtionParams,
        vectorType: functionName.vectorType,
      });
      query.functions = functions;
    }

    const promqlQuery = buildPromqlWithFunctions(query);
    if (promqlQuery) {
      return promqlQuery;
    }
  }

  if (type === 'formula') {
    const formula = formulas[queryIndex];
    const queriesForFormula: string[] = [];
    queries.forEach((query) => {
      const promqlQuery = buildPromqlWithFunctions(query);
      queriesForFormula.push(promqlQuery);
    });
    const queryKeys = queries.map((query) => query.queryKey);
    const promqlFormula = buildFormulaQuery(queriesForFormula, queryKeys, [
      formula,
    ]);

    if (promqlFormula[0]) {
      return promqlFormula[0];
    }
  }

  return '';
};

export const getSeriesShownState = (series: Series[]) => {
  if (!series) return {};

  const seriesBitmap: { [key: string]: boolean } = {};
  series.forEach((item) => {
    seriesBitmap[item.label] = item.show;
  });

  return seriesBitmap;
};

/**
 * Transform the data from the API to the format that the chart needs
 */
export const transformMetricsExplorerData = (
  datasets: MetricsQueriesDataProps[],
  promqlQueries: MetricsMultiDatePromqlProps[],
  seriesBitmap: { [key: string]: boolean },
): MetricsQueriesDataProps => {
  const queryData: MetricsQueriesDataProps = {};

  datasets.forEach((dataset, promqlIndex) => {
    Object.keys(dataset).forEach((key, idx) => {
      if (!dataset[key].data) return;
      const { series } = dataset[key].data;
      const { label } = promqlQueries[promqlIndex];

      const newSeries = series.map((item: any) => {
        const seriesShow =
          seriesBitmap[item.label] !== undefined
            ? seriesBitmap[item.label]
            : true;

        return {
          ...item,
          dash: label ? [4, 8] : undefined,
          label: label ? `${label}(${item.label})` : item.label,
          stroke: getColorForOneSeries({}, idx + promqlIndex + 1),
          show: seriesShow,
        };
      });

      queryData[key] = dataset[key];
      queryData[key].data.series = newSeries;
    });
  });

  return queryData;
};

const getMetricAlertTypeFromQuery = (query: ExplorerQueryProps): string => {
  const { functions } = query;

  if (functions.length === 0) return AlertType.THRESHOLD;

  const lastFunction = functions[functions.length - 1];
  if (!lastFunction) return AlertType.THRESHOLD;

  const { isAnomaly, isForecast, isOutlier } =
    isMetricsQueryHasAdvanceFunc(query);

  if (isAnomaly) return AlertType.ANOMALY;
  if (isOutlier) return AlertType.OUTLIERS;
  if (isForecast) return AlertType.FORECAST;

  return AlertType.THRESHOLD;
};

export const onCreateAlert = (
  date: DateSelection,
  queryItem: MetricsChartsQueryItemProps,
) => {
  const { formulas, queries, queryIndex, type } = queryItem;

  const alertType = getMetricAlertTypeFromQuery(queries[queryIndex]);
  if (
    alertType === AlertType.ANOMALY ||
    alertType === AlertType.OUTLIERS ||
    alertType === AlertType.FORECAST
  ) {
    queries[queryIndex].functions.pop();
  }

  const encodeAlertTypeURI = encodeURIComponent(
    JSON.stringify({ value: alertType }),
  );
  const encodeRuleTypeURI = encodeURIComponent(
    JSON.stringify({ value: 'metrics' }),
  );
  const encodeDateURI = encodeURIComponent(JSON.stringify(date));

  if (type === 'query') {
    const query = { ...queries[queryIndex] };
    const encodeQueryURI = encodeURIComponent(JSON.stringify([query]));
    window.open(
      `/#/alerts/create?ruleType=${encodeRuleTypeURI}&alertType=${encodeAlertTypeURI}&metricsQueries=${encodeQueryURI}&date=${encodeDateURI}`,
      '_blank',
    );
    return;
  }

  const encodeFormulaURI = encodeURIComponent(JSON.stringify(formulas));
  const encodeQueryURI = encodeURIComponent(JSON.stringify(queries));

  window.open(
    `/#/alerts/create?ruleType=${encodeRuleTypeURI}&alertType=${encodeAlertTypeURI}&metricsQueries=${encodeQueryURI}&metricsFormulas=${encodeFormulaURI}&date=${encodeDateURI}`,
    '_blank',
  );
};

export const onCreateAlertCombined = (
  date: DateSelection,
  formulas: FormulaProps[],
  queries: ExplorerQueryProps[],
) => {
  const encodeFormulaURI = encodeURIComponent(JSON.stringify(formulas));
  const encodeQueryURI = encodeURIComponent(JSON.stringify(queries));
  const encodeAlertTypeURI = encodeURIComponent(
    JSON.stringify({ value: 'metrics' }),
  );
  const encodeDateURI = encodeURIComponent(JSON.stringify(date));
  window.open(
    `/#/alerts/create?alertType=${encodeAlertTypeURI}&metricsQueries=${encodeQueryURI}&metricsFormulas=${encodeFormulaURI}&date=${encodeDateURI}`,
    '_blank',
  );
};

export const hasMetricsChartAdvanceVisualization = ({
  queries,
  queryData,
}: {
  queries: ExplorerQueryProps[];
  queryData: QueryDataProps;
}): boolean => {
  let hasAdvanceVisualization = false;
  const dataObjectKeys = Object.keys(queryData);
  dataObjectKeys.forEach((key) => {
    if (!queryData[key]) return;
    const { meta } = queryData[key];
    if (!meta) return;
    const query = queries.find((query) => query.queryKey === meta.refId);
    if (!query || !query.isActive) return;
    if (meta.isAnomaly || meta.isOutlier || meta.isForecast) {
      hasAdvanceVisualization = true;
    }
  });
  return hasAdvanceVisualization;
};
