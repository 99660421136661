import { convertNumberToReadableUnit } from 'utils/formatNumber';
import {
  DashboardPanelProps,
  DashboardStreamType,
  QueryDataPropsInstant,
} from 'types';

import { getFormattedLegendByRefId, getFomattedLegend } from './legend-utils';
import { statEvaluatedValue } from './stat-utils';

export const getPolyStatData = (
  data: QueryDataPropsInstant['data'],
  panel: DashboardPanelProps,
  stream: DashboardStreamType,
) => {
  if (!data) return [];
  const polyStats = [];
  const { targets } = panel;

  data.forEach((d) => {
    const queryKey = d.getQueryKey?.();
    const promIndex = d.getPromIndex?.();
    const refId = queryKey?.split('_')[1].toUpperCase();
    const metric = d?.getMetric();
    const value = d.getValue();
    let legend = '';
    if (stream === DashboardStreamType.LOG) {
      legend = getFormattedLegendByRefId(refId, metric, targets);
    } else {
      legend = getFomattedLegend(promIndex, metric, targets);
    }

    const { color, text, prefix, suffix } = statEvaluatedValue({
      panel,
      result: [{ metric, value }],
    });

    let newValue = `${prefix || ''}${text}${suffix || ''}`;

    if (Number(newValue) > 0) {
      newValue = convertNumberToReadableUnit(Number(text), 2);
    }

    polyStats.push({
      ...d,
      color: color ? color : d.color,
      label: legend,
      value: newValue,
    });
  });

  return polyStats;
};
