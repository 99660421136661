import { useColumnsState, useTableOptions, RightSidebar } from 'components';
import { useKeyExistsState, useRequest } from 'hooks';
import React, { ReactNode, useMemo, useRef, useState } from 'react';
import { getWorkbooks } from 'requests';
import { tableColumns } from 'screens/Logs/constants';
import { useLogsTable } from 'screens/Logs/hooks';
import LogsSheet from 'screens/Logs/LogsSheet';
import LogsSelectedLog from 'screens/Logs/LogsSelectedLog';
import LogsSelectedLogTitle from 'screens/Logs/LogsSelectedLogTitle';
import { LogEvent, LogsState, SelectedLog } from 'types';
import { clamp, getLogsPrevAndNextHandlers } from 'utils/index';

type Props = {
  columnsState: ReturnType<typeof useColumnsState>;
  customColumnsState: ReturnType<typeof useKeyExistsState>;
  disableAddCustomColumn?: boolean;
  logs: LogEvent[];
  logsState: LogsState;
  logsTable: ReturnType<typeof useLogsTable>;
  onScrollEnd: VoidFunction;
  renderToolbarLeft: () => ReactNode;
  shouldDisableFilterActions?: boolean;
  tableOptions: ReturnType<typeof useTableOptions>;
  disableToolbar?: boolean;
  disableOnlySaveQueryInToolBar?: boolean;
  disableOnlyAddToDashboardInToolBar?: boolean;
};

const BasicLogsSheetInner = ({
  customColumnsState,
  columnsState,
  disableAddCustomColumn,
  logs,
  logsState,
  logsTable,
  onScrollEnd,
  renderToolbarLeft,
  shouldDisableFilterActions,
  tableOptions,
  disableToolbar,
  disableOnlySaveQueryInToolBar,
  disableOnlyAddToDashboardInToolBar,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedLog, setSelectedLog] = useState<SelectedLog>(null);
  const bindKeyHandlersRef = useRef<VoidFunction>();
  const getWorkbooksRequest = useRequest(getWorkbooks);

  const columns = useMemo(
    () =>
      tableColumns({
        containerRef,
        columnsState,
        customColumnsState: customColumnsState,
        disableAddCustomColumn,
        logsState,
        shouldDisableFilterActions,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsState.state, customColumnsState.state],
  );

  const { date } = logsState;
  const { id, isLoading, sort, sortByColumn } = logsTable;

  const onEnter = () => {};

  const logsRef = useRef([]);
  logsRef.current = logs;

  const { next, prev } = useMemo(
    () => getLogsPrevAndNextHandlers({ logsRef, setSelectedLog }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <LogsSheet
        bindKeyHandlersRef={bindKeyHandlersRef}
        columns={columns}
        columnsState={columnsState}
        customColumnsState={customColumnsState}
        date={date}
        disableToolbar={disableToolbar}
        disableOnlySaveQueryInToolBar={disableOnlySaveQueryInToolBar}
        disableOnlyAddToDashboardInToolBar={disableOnlyAddToDashboardInToolBar}
        getWorkbooksRequest={getWorkbooksRequest}
        key={id}
        isLoading={isLoading}
        logs={logs}
        logsState={logsState}
        onEnter={onEnter}
        onScrollEnd={onScrollEnd}
        ref={containerRef}
        renderToolbarLeft={renderToolbarLeft}
        setSelectedLog={setSelectedLog}
        sort={sort}
        sortByColumn={sortByColumn}
        tableOptions={tableOptions}
      />
      {selectedLog && selectedLog.log ? (
        <RightSidebar
          className="logs__right-drawer"
          close={() => {
            const bindKeyHandlers = bindKeyHandlersRef.current;
            if (bindKeyHandlers) {
              bindKeyHandlers();
            }
            setSelectedLog(null);
          }}
          closeOnOutsideClick={() => {
            const bindKeyHandlers = bindKeyHandlersRef.current;
            if (bindKeyHandlers) {
              bindKeyHandlers();
            }
            setSelectedLog(null);
          }}
          title={<LogsSelectedLogTitle logEvent={selectedLog.log} />}
          prev={prev}
          next={next}
        >
          <LogsSelectedLog
            logs={logs}
            logsState={logsState}
            selectedLog={selectedLog}
            setSelectedLog={setSelectedLog}
            shouldDisableFilterActions={shouldDisableFilterActions}
            prev={prev}
            next={next}
          />
        </RightSidebar>
      ) : null}
    </>
  );
};

export default BasicLogsSheetInner;
