import { version } from 'kfuse-constants';
import * as Sentry from '@sentry/react';

type Args = {
  dsn: string;
};

const initSentry = ({ dsn }: Args) => {
  try {
    Sentry.init({
      dsn,
      environment: location.hostname,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      release: version,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      ignoreErrors: ['Request was replaced by another request'],
    });
  } catch (e) {
    // ignore error
  }
};

export default initSentry;
