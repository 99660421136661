import { fetchJson } from 'requests';
import { UserProps } from '../types';

const updateRoleById = async ({
  userId,
  user,
}: {
  userId: string;
  user: UserProps;
}): Promise<Record<string, unknown>> => {
  const options = {
    method: 'PUT',
    body: JSON.stringify({ ...user }),
  };
  const result = await fetchJson(`rbac/users/${userId}`, options);

  return result as Record<string, unknown>;
};

export default updateRoleById;
