import { useRequest } from 'hooks';
import { useToaster } from 'components';
import {
  getGrafanaMutedAlerts,
  unmuteGrafanaAlert,
  muteGrafanaAlert,
} from 'requests';
import { useMemo } from 'react';

import { SilencesProps } from '../types';
import { getSilencedInstance } from '../requests';
import { filterSilencedInstances } from '../utils';
const useAlertsSilencesState = () => {
  const { addToast } = useToaster();

  const grafanaMutedAlertsRequest = useRequest<SilencesProps[], any>(
    getGrafanaMutedAlerts,
    true,
    true,
  );
  const unmuteGrafanaAlertRequest = useRequest(unmuteGrafanaAlert);
  const muteGrafanaAlertRequest = useRequest(muteGrafanaAlert);

  const getSilencedInstanceRequest = useRequest(
    getSilencedInstance,
    true,
    true,
  );

  const onUnmuteSilence = (silence: SilencesProps) => {
    unmuteGrafanaAlertRequest
      .call(silence.id)
      .then((res: { message: string }) => {
        if (res.message) {
          addToast({ text: 'Alert unmuted successfully', status: 'success' });
        }
        grafanaMutedAlertsRequest.call();
      });
  };

  const { activeSilences, expiredSilences } = useMemo(() => {
    const silences = grafanaMutedAlertsRequest.result;
    const silencedInstances = getSilencedInstanceRequest.result;

    if (!silences) return { activeSilences: [], expiredSilences: [] };
    const filteredSilences = filterSilencedInstances({
      silencedInstances,
      silences,
    });
    const activeSilences = filteredSilences?.filter(
      ({ status }) => status.state === 'active',
    );
    const expiredSilences = filteredSilences?.filter(
      ({ status }) => status.state === 'expired',
    );
    return { activeSilences, expiredSilences };
  }, [grafanaMutedAlertsRequest.result, getSilencedInstanceRequest.result]);

  return {
    grafanaMutedAlertsRequest,
    activeSilences,
    expiredSilences,
    getSilencedInstanceRequest,
    onUnmuteSilence,
    isLoading:
      grafanaMutedAlertsRequest.isLoading ||
      unmuteGrafanaAlertRequest.isLoading ||
      muteGrafanaAlertRequest.isLoading,
  };
};

export default useAlertsSilencesState;
