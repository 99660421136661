// https://stackoverflow.com/questions/1379934/large-numbers-erroneously-rounded-in-javascript
const doesStringHasAllDigits = (s: string): boolean => {
  return /^\d+$/.test(s);
};

const tryJsonParse = (s: string): any => {
  try {
    const isNumber = doesStringHasAllDigits(s);
    if (isNumber) {
      return String(Number(s)) === s ? Number(s) : s;
    }
    return JSON.parse(s);
  } catch (e) {
    return s;
  }
};

export default tryJsonParse;
