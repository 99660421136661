import React, { useMemo } from 'react';
import { nameSpaceMemoryAllocation, namespaceCPUAllocation } from '../utils';
import { kubeEntities, kubeNamespaceCount } from 'requests';

import {
  namespaceCPUUtilizedCalculation,
  namespaceMemUtilizedCalculation,
} from '../utils';
import { Namespace } from 'screens/Kubernetes/types';
import { useKubernetesController } from '../KubernetesController';
import KubernetesTable from './KubernetesTable';
import {
  KubeCellCluster,
  KubeCellStatus,
  KubeCellTimestamp,
  KubeTableCell,
} from './Components';
import useAsync from '../hooks/useAsync';
import { useEntitiesCount } from './useEntitiesData';
import { SelectedFacetValuesByName } from 'types';

const getKey = (namespaceData: { namespace: Namespace }) => {
  if (namespaceData.namespace) {
    const { tags } = namespaceData.namespace;
    const { name } = namespaceData.namespace.metadata;
    const tag = tags.find((tag) => tag.includes('kube_cluster_name'));
    const clusterName = tag ? tag.split(':')[1] : '';

    return `${clusterName}:${name}`;
  }

  return '';
};

const getNameKey = (namespaceData: { namespace: Namespace }) => {
  if (namespaceData.namespace) {
    const { name } = namespaceData.namespace.metadata;
    return `${name}`;
  }

  return '';
};

const getClusterPodCount = (podCountData: {
  namespace: Namespace;
}): Record<string, number> => {
  if (podCountData != null) {
    const result: Record<string, number> = {};

    podCountData.forEach((item) => {
      const { count } = item;
      const { value } = item.groupBys[0];
      const key = `${value}`;
      result[key] = count;
    });

    return result;
  }

  return null;
};

const getClusterStatefulSetCount = (statefulSetCountData: {
  namespace: Namespace;
}) => {
  if (statefulSetCountData != null) {
    const result: Record<string, number> = {};

    statefulSetCountData.forEach((item) => {
      const { count } = item;
      const { value } = item.groupBys[0];
      const key = `${value}`;
      result[key] = count;
    });

    return result;
  }

  return null;
};

export function useKubernetesTableDataForNamespace({
  page,
  limit,
  filters,
}: {
  filters: Array<SelectedFacetValuesByName>;
  page: number;
  limit: number;
}) {
  const { date, kubesSort, entitiesType, entitiesLastLoaded, facets } =
    useKubernetesController();

  return useAsync(
    async (signal) => {
      const groupBy: Array<{ key: string; type: 'TAG' }> = [
        { key: 'kube_namespace', type: 'TAG' },
      ];

      const [
        entities,
        podsCount,
        deploymentCount,
        daemonSetCount,
        cronjobsCount,
        statefulSetCount,
      ] = await Promise.all([
        kubeEntities(
          {
            entityType: entitiesType,
            sortOrder: kubesSort?.order,
            sortBy: {
              key: kubesSort?.key,
              type: kubesSort?.type,
            },
            offset: page * limit,
            pageLimit: limit,
            filters,
          },
          { signal },
        ) as Promise<Array<{ namespace: Namespace }>>,
        kubeNamespaceCount({
          entityType: 'Pod',
          groupBy,
          selectedFacetValuesByName: facets,
        }).then((podCountData) => {
          if (podCountData != null) {
            const result: Record<string, number> = {};

            podCountData.forEach((item) => {
              const { count } = item;
              const { value } = item.groupBys[0];
              const key = `${value}`;
              result[key] = count;
            });

            return result;
          }

          return null;
        }),
        // deploymentsCount.call
        kubeNamespaceCount({
          entityType: 'Deployment',
          groupBy,
          selectedFacetValuesByName: facets,
        }).then((deploymentCountData) => {
          if (deploymentCountData != null) {
            const result: Record<string, number> = {};

            deploymentCountData.forEach((item) => {
              const { count } = item;
              const { value } = item.groupBys[0];
              const key = `${value}`;
              result[key] = count;
            });

            return result;
          }

          return null;
        }),
        // daemonSetCount.call
        kubeNamespaceCount({
          entityType: 'DaemonSet',
          groupBy,
          selectedFacetValuesByName: facets,
        }).then((daemonSetCountData) => {
          if (daemonSetCountData != null) {
            const result: Record<string, number> = {};

            daemonSetCountData.forEach((item) => {
              const { count } = item;
              const { value } = item.groupBys[0];
              const key = `${value}`;
              result[key] = count;
            });

            return result;
          }

          return null;
        }),
        // cronjobsCount.call
        kubeNamespaceCount({
          entityType: 'CronJob',
          groupBy,
          selectedFacetValuesByName: facets,
        }).then((cronJobCountData) => {
          if (cronJobCountData != null) {
            const result: Record<string, number> = {};
            cronJobCountData.forEach((item) => {
              const { count } = item;
              const { value } = item.groupBys[0];
              const key = `${value}`;
              result[key] = count;
            });

            return result;
          }

          return null;
        }),
        // statefullSetCount.call
        kubeNamespaceCount({
          entityType: 'StatefulSet',
          groupBy,
          selectedFacetValuesByName: facets,
        }).then((statefulSetCountData) => {
          if (statefulSetCountData != null) {
            const result: Record<string, number> = {};

            statefulSetCountData.forEach((item) => {
              const { count } = item;
              const { value } = item.groupBys[0];
              const key = `${value}`;
              result[key] = count;
            });

            return result;
          }

          return null;
        }),
      ]);
      const names: Array<string> = [];
      const clusters: Array<string> = [];

      entities.forEach(({ namespace }) => {
        const clusterTagName = 'kube_cluster_name';

        names.push(namespace.metadata.name);
        clusters.push(
          namespace.tags
            .find((tag) => tag.startsWith(clusterTagName))
            .substring(clusterTagName.length + 1),
        );
      });
      const [cpu, memory] = await Promise.all([
        namespaceCPUAllocation(
          [],
          {
            kube_namespace: names,
            kube_cluster_name: clusters,
          },
          date,
        )(),
        nameSpaceMemoryAllocation(
          [],
          {
            kube_namespace: names,
            kube_cluster_name: clusters,
          },
          date,
        )(),
      ]);

      entities.forEach(({ namespace }) => {
        const name = namespace.metadata.name;
        const clusterTagName = 'kube_cluster_name';
        const clusterName = namespace.tags.find((tag) =>
          tag.startsWith(clusterTagName),
        );

        names.push(name);
        clusters.push(clusterName);
      });

      return entities.map((namespaceData) => {
        const key = getKey(namespaceData);
        const nameKey = getNameKey(namespaceData);

        return {
          ...namespaceData,
          cpuPercentage: cpu[key] || 0,
          memoryPercentage: memory[key] || 0,
          podCount: podsCount[nameKey] || 0,
          deploymentCount: deploymentCount[nameKey] || 0,
          cronJobCount: cronjobsCount[nameKey] || 0,
          daemonSetCount: daemonSetCount[nameKey] || 0,
          statefulsetCount: statefulSetCount[nameKey] || 0,
        };
      });
    },
    [date, page, limit, facets, filters, kubesSort, entitiesLastLoaded],
  );
}

export default function KubernetesTableForNamespace() {
  const { date, entitiesType, kubesSort, facets, page, limit } =
    useKubernetesController();

  const filters = useMemo(() => [facets], [facets]);
  const [count] = useEntitiesCount({
    entitiesType,
    facets,
  });
  const [rows] = useKubernetesTableDataForNamespace({
    entitiesType,
    kubesSort,
    filters,
    page,
    limit,
    date,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'metadata.namespace',
          label: 'Namespace',
          renderCell(prop: any) {
            const namespace = prop?.row?.namespace
              ? prop?.row.namespace.metadata?.name
              : prop?.row.metadata?.name;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name"
                tooltipText={namespace}
              >
                {namespace}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell(prop) {
            return <KubeCellCluster {...prop} />;
          },
        },
        {
          key: 'status',
          label: 'Status',
          renderCell(prop) {
            const status = prop.row.namespace.status;
            return <KubeCellStatus {...prop} status={status} />;
          },
        },
        {
          key: 'metadata.creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            const age = prop.row.namespace
              ? prop.row.namespace.metadata
              : prop.row.metadata;
            const timestamp = age.creationTimestamp;

            return <KubeCellTimestamp {...prop} timestamp={timestamp} />;
          },
        },
        {
          key: 'headerCpuUsage',
          label: 'CPU Usage',
          renderCell(prop: any) {
            const nsCPUUtilization = namespaceCPUUtilizedCalculation(prop?.row);
            return (
              <KubeTableCell
                {...prop}
                align="right"
                className="kubernetes__table__cell__entity-name"
                tooltipText={nsCPUUtilization}
              >
                {nsCPUUtilization}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerMemUsage',
          label: 'Mem Usage',
          renderCell(prop: any) {
            const nsMemUtilization = namespaceMemUtilizedCalculation(prop?.row);
            return (
              <KubeTableCell
                {...prop}
                align="right"
                className="kubernetes__table__cell__entity-name"
                tooltipText={nsMemUtilization}
              >
                {nsMemUtilization}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerPods',
          label: 'Pods',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.podCount}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerDeployments',
          label: 'Deployments',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.deploymentCount}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerDaemonsets',
          label: 'Daemon Sets',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.daemonSetCount}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerCronjobs',
          label: 'Cronjobs',
          renderCell(prop: any) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop?.row?.cronJobCount}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerStatefulsets',
          label: 'Stateful Sets',
          renderCell(prop: any) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop?.row?.statefulsetCount}
              </KubeTableCell>
            );
          },
        },
      ]}
    />
  );
}
