import { TooltipTrigger } from 'components';
import React, { ReactElement } from 'react';
import { AiOutlineInfo } from 'react-icons/ai';
import { DashboardPanelProps } from 'types';

const DashboardPanelTooltipInfo = ({
  panel,
}: {
  panel: DashboardPanelProps;
}): ReactElement => {
  if (!panel.description) return <></>;

  return (
    <TooltipTrigger
      className="dashboard-panel__header__info--tooltip"
      tooltip={
        <div className="dashboard-panel__header__panel-info">
          <div className="dashboard-panel__header__panel-info__description">
            {panel.description}
          </div>
          {panel.links && panel.links.length > 0 && (
            <div>
              {panel.links.map((link) => (
                <a
                  className="link"
                  href={link.url}
                  key={link.url}
                  target={link.targetBlank ? '_blank' : '_self'}
                  rel="noreferrer"
                >
                  {link.title}
                </a>
              ))}
            </div>
          )}
        </div>
      }
    >
      <div className="dashboard-panel__header__info">
        <AiOutlineInfo />
        <span className="dashboard-panel__header__info--inner"></span>
      </div>
    </TooltipTrigger>
  );
};

export default DashboardPanelTooltipInfo;
