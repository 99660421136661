import { useMetricsSummaryPageStateContext } from 'context/PageStateProvider';
import { useRequest } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import {
  getMetricsList,
  metricsAnalytics,
  metricsLabelValueCardinality,
  promqlLabelValues,
  promqlMetadata,
} from 'requests';
import { DateSelection } from 'types';
import { metricsErrors } from 'utils/error/metricsErrors';

type MetricLabelProps = {
  label: string;
  count: number;
};

const useMetricsSummary = () => {
  const [error, setError] = useState({
    getMetricsList: null,
  });
  const {
    dateState,
    dependenciesForWriteStateToUrl,
    selectedMetricState,
    selectedMetricLabelState,
    writeStateToUrl,
  } = useMetricsSummaryPageStateContext();

  const [date, setDate] = dateState;
  const userActions = useRef<{ initialScrolled: boolean }>({
    initialScrolled: false,
  });

  const [metricSearch, setMetricSearch] = useState({
    search: '',
    type: [],
    origin: [],
  });
  const [tagSearch, setTagSearch] = useState('');
  const [selectedMetric, setSelectedMetric] = selectedMetricState;
  const [selectedMetricLabel, setSelectedMetricLabel] =
    selectedMetricLabelState;
  const [metricLabels, setMetricLabels] = useState<{
    [key: string]: MetricLabelProps[];
  }>({});
  const [metricLabelValues, setMetricLabelValues] = useState<{
    [key: string]: { [key: string]: string[] };
  }>({});
  const [seriesCountByMetricName, setSeriesCountByMetricName] = useState<{
    [key: string]: number;
  }>({});

  const promqlMetadataRequest = useRequest(promqlMetadata);
  const getMetricsListRequest = useRequest(getMetricsList, true, true);
  const promqlLabelValuesRequest = useRequest(promqlLabelValues);
  const metricsLabelValueCardinalityRequest = useRequest(
    metricsLabelValueCardinality,
  );
  const metricsAnalyticsRequest = useRequest(metricsAnalytics);

  const onDateChange = (nextDate: DateSelection) => {
    setDate(nextDate);
  };

  const loadMetricAnalytics = async (
    metric: string,
    newSeriesCountByMetricName: { [key: string]: number },
  ) => {
    if (newSeriesCountByMetricName[metric]) return;

    const metricAnalyticsResponse = await metricsAnalyticsRequest.call({
      date,
      labels: ['labels'],
      matcher: metric,
    });

    const metricSeriesCount = metricAnalyticsResponse[0]?.aggrVal?.labels;
    if (!metricSeriesCount) return;

    setSeriesCountByMetricName((prev) => ({
      ...prev,
      [metric]: metricSeriesCount,
    }));
  };

  const loadMetricsLabels = async (
    metric: string,
    newMetricLabels: { [key: string]: MetricLabelProps[] },
  ) => {
    if (newMetricLabels[metric]) return;

    const metricsLabelValueCardinalityResponse =
      await metricsLabelValueCardinalityRequest.call({
        date,
        format: 'none',
        instant: true,
        matcher: metric,
        noAggregation: true,
      });

    const labelValuesCount =
      metricsLabelValueCardinalityResponse?.labelNames || {};

    const labelValues = Object.keys(labelValuesCount);
    labelValues.push('labels');

    if (!labelValues) return;
    setMetricLabels((prev) => ({
      ...prev,
      [metric]: labelValues.map((label) => ({ label, count: null })),
    }));

    const valuesWithCount = Object.keys(labelValuesCount).map((key) => ({
      label: key,
      count: labelValuesCount[key],
    }));

    setMetricLabels((prev) => ({ ...prev, [metric]: valuesWithCount }));
  };

  const loadMetricsLabelValues = async ({
    metric,
    label,
    newMetricLabelValues,
  }: {
    metric: string;
    label: string;
    newMetricLabelValues: { [key: string]: { [key: string]: string[] } };
  }) => {
    if (newMetricLabelValues[metric] && newMetricLabelValues[metric][label])
      return;

    const labelValues = await promqlLabelValuesRequest.call({
      date,
      matcher: metric,
      label,
    });
    if (!labelValues) return;
    setMetricLabelValues((prev) => ({
      ...prev,
      [metric]: { ...prev[metric], [label]: labelValues },
    }));
  };

  const onMetricNameChange = (metric: string) => {
    setSelectedMetric(metric);
    loadMetricsLabels(metric, metricLabels);
    loadMetricAnalytics(metric, seriesCountByMetricName);
    setSelectedMetricLabel('');
    promqlMetadataRequest.call(metric);
  };

  useEffect(() => {
    getMetricsListRequest
      .call(date)
      .then((nextResult) => {
        if (nextResult) {
          setError((prevError) => ({ ...prevError, getMetricsList: null }));
        }
      })
      .catch(() => {
        setError((prevError) => ({
          ...prevError,
          getMetricsList: { message: metricsErrors.getMetricsList },
        }));
      });
    setMetricLabels({});
    setMetricLabelValues({});
    setSeriesCountByMetricName({});

    if (selectedMetric) {
      loadMetricsLabels(selectedMetric, {});
      loadMetricAnalytics(selectedMetric, {});
    }

    if (selectedMetric && selectedMetricLabel) {
      loadMetricsLabelValues({
        metric: selectedMetric,
        label: selectedMetricLabel,
        newMetricLabelValues: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesForWriteStateToUrl);

  return {
    date,
    loadMetricsLabelValues,
    metricLabels,
    metricLabelValues,
    metricSearch,
    onDateChange,
    onMetricNameChange,
    getMetricsListRequest,
    metricsLabelValueCardinalityRequest,
    promqlMetadataRequest,
    promqlLabelValuesRequest,
    selectedMetric,
    selectedMetricLabel,
    seriesCountByMetricName,
    setMetricSearch,
    setSelectedMetricLabel,
    setTagSearch,
    tagSearch,
    userActions,
    error,
  };
};

export default useMetricsSummary;
