import { useUrlState } from 'hooks';
import { useMemo, useState } from 'react';

const emptyArray: string[] = [];

const useTableSearch = ({
  rows,
  shouldWriteToUrl = false,
  searchSpecifcRows = emptyArray,
}: {
  rows: Array<any>;
  shouldWriteToUrl?: boolean;
  searchSpecifcRows?: string[];
}) => {
  const [regularState, setRegularState] = useState('');
  const [urlState, setUrlState] = useUrlState('tableSearch', '');
  const search = shouldWriteToUrl ? urlState : regularState;
  const setSearch = shouldWriteToUrl ? setUrlState : setRegularState;

  const searchedRows: string[] = useMemo(() => {
    const searchLowered = search.toLowerCase().trim();

    if (searchSpecifcRows?.length > 0) {
      return rows.filter((row) =>
        searchSpecifcRows.some(
          (key) =>
            row[key] &&
            JSON.stringify(row[key]).toLowerCase().indexOf(searchLowered) > -1,
        ),
      );
    }

    if (searchLowered) {
      return rows.filter(
        (row) => JSON.stringify(row).toLowerCase().indexOf(searchLowered) > -1,
      );
    }

    return rows;
  }, [rows, search, searchSpecifcRows]);

  return {
    search,
    searchedRows,
    setSearch,
  };
};

export default useTableSearch;
