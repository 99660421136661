import React, { useMemo } from 'react';
import { findUnitCategoryFormatById } from 'utils';
import { kubeFacetCount } from 'requests';

import { kubeEntities } from 'requests';
import { Cluster } from 'screens/Kubernetes/types';
import { clusterCPUUsage, clusterMemoryUsage } from '../utils';
import { useDateState } from 'hooks';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellPercentageBar,
  KubeCellTimestamp,
  KubeTableCell,
} from './Components';
import useAsync from '../hooks/useAsync';
import KubernetesTable from './KubernetesTable';
import { useEntitiesCount } from './useEntitiesData';
import { SelectedFacetValuesByName } from 'types';

const getClusterPodCount = (podCountData) => {
  const result = {};
  if (podCountData) {
    podCountData.forEach((item) => {
      const { value, count } = item;
      const key = `${value}`;
      result[key] = count;
    });
  }

  return result;
};

const getKey = (clusterData) => {
  if (clusterData.cluster) {
    const { tags } = clusterData?.cluster;
    const tag = tags.find((tag) => tag.includes('kube_cluster_name'));
    const clusterName = tag ? tag.split(':')[1] : '';

    return `${clusterName}`;
  }

  return '';
};

export function useKubernetesTableDataForCluster({
  page,
  limit,
  filters,
}: {
  filters: Array<SelectedFacetValuesByName>;
  page: number;
  limit: number;
}) {
  const { kubesSort, entitiesLastLoaded, facets, entitiesType, date } =
    useKubernetesController();

  return useAsync(
    async (signal) => {
      const [entities, facetCount] = await Promise.all([
        kubeEntities(
          {
            entityType: entitiesType,
            sortOrder: kubesSort?.order,
            sortBy: {
              key: kubesSort?.key,
              type: kubesSort?.type,
            },
            offset: page * limit,
            pageLimit: limit,
            filters,
          },
          { signal },
        ) as Promise<Array<{ cluster: Cluster }>>,
        kubeFacetCount({
          entityType: 'Pod',
          tags: JSON.stringify(['kube_cluster_name']),
          selectedFacetValuesByName: facets,
        }),
      ]);
      const cluster: Array<string> = entities.map(({ cluster }) => {
        return cluster.clusterName;
      });
      const [cpu, memory] = await Promise.all([
        clusterCPUUsage(date, {
          kube_cluster_name: cluster,
        })(),
        clusterMemoryUsage(date, {
          kube_cluster_name: cluster,
        })(),
      ]);

      const clusterPodCountByKey = getClusterPodCount(facetCount);

      return entities.map((clusterData) => {
        const key = getKey(clusterData);
        const cpuPercentage = Number(cpu[key] || 0);
        const memoryPercentage = Number(memory[key] || 0);
        const podCount = Number(clusterPodCountByKey[key] || 0);

        return {
          ...clusterData,
          cpuPercentage,
          memoryPercentage,
          podCount,
        };
      });
    },
    [date, page, limit, facets, filters, kubesSort, entitiesLastLoaded],
  );
}

export default function KubernetesTableForCluster() {
  const [date] = useDateState();

  const { entitiesType, kubesSort, facets, page, limit } =
    useKubernetesController();

  const filters = useMemo(() => [facets], [facets]);
  const [count] = useEntitiesCount({
    entitiesType,
    facets,
  });
  const [rows] = useKubernetesTableDataForCluster({
    entitiesType,
    date,
    page,
    limit,
    filters,
    kubesSort,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell(prop) {
            const clusterName = prop.row.cluster.clusterName;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name"
                tooltipText={clusterName}
              >
                {clusterName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            const timestamp = prop.row.cluster.creationTimestamp;

            return <KubeCellTimestamp {...prop} timestamp={timestamp} />;
          },
        },
        {
          key: 'resourceVersion',
          label: 'Versions',
          renderCell(prop) {
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name"
                tooltipText={Object.keys(
                  prop.row.cluster.kubeletVersions || {},
                )}
              >
                {Object.keys(prop.row.cluster.kubeletVersions || {})}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'nodeCount',
          label: 'Nodes',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.cluster.nodeCount}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'cpuCapacity',
          label: 'Cpu Capacity',
          renderCell(prop) {
            const cluster = prop.row.cluster;
            if (!cluster && !cluster.cpuAllocatable && !cluster.cpuCapacity)
              return <>-</>;

            return (
              <KubeTableCell {...prop} align="right">
                {(cluster.cpuAllocatable / 1000).toFixed(2)}/{' '}
                {(cluster.cpuCapacity / 1000).toFixed(2)} CPUs
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerCpuUsage',
          label: '% CPU Usage',
          renderCell(prop) {
            const percent =
              (prop.row.cpuPercentage / prop.row.cluster.cpuAllocatable) * 100;

            return (
              <KubeTableCell
                {...prop}
                tooltipText={percent && `${percent.toFixed(2)}%`}
              >
                <KubeCellPercentageBar percent={percent} />
              </KubeTableCell>
            );
          },
        },
        {
          key: 'memoryCapacity',
          label: 'Mem Capacity',
          renderCell(prop) {
            const cluster = prop?.row?.cluster;
            if (
              !cluster &&
              !cluster.memoryAllocatable &&
              !cluster.memoryCapacity
            )
              return <>-</>;

            const unit = findUnitCategoryFormatById('bytes');
            const memoryAllocatable = unit.fn(cluster?.memoryAllocatable || 0);
            const memoryCapacity = unit.fn(cluster?.memoryCapacity || 0);
            return (
              <KubeTableCell {...prop} align="right">
                {`${memoryAllocatable.text} ${memoryAllocatable.suffix}`}/{' '}
                {`${memoryCapacity.text} ${memoryCapacity.suffix}`}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerMemUsage',
          label: '% Mem Usage',
          renderCell(prop) {
            const percent =
              (prop?.row?.memoryPercentage /
                prop?.row.cluster?.memoryAllocatable) *
              100;

            return (
              <KubeTableCell
                {...prop}
                tooltipText={percent && `${percent.toFixed(2)}%`}
              >
                <KubeCellPercentageBar percent={percent} />
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerPods',
          label: 'Pods',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.podCount}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'headerPodUsage',
          label: 'Pod Usage',
          renderCell(prop) {
            const percent =
              (prop.row.podCount / prop.row.cluster.podCapacity) * 100;
            return (
              <KubeTableCell
                {...prop}
                tooltipText={percent && `${percent.toFixed(2)}%`}
              >
                <KubeCellPercentageBar percent={percent} />
              </KubeTableCell>
            );
          },
        },
      ]}
    />
  );
}
