import { FilterType, useFiltersState } from '../useFiltersState';
import getFiltersByFacets from '../useFiltersState/getFiltersByFacets';
import getKeyExistsState from '../useFiltersState/getKeyExistsState';
import getSelectedFacetRangeByNameState from '../useFiltersState/getSelectedFacetRangeByNameState';
import getSelectedFacetValuesByNameState from '../useFiltersState/getSelectedFacetValuesByNameState';
import getTermsState from '../useFiltersState/getTermsState';

type Args = {
  filtersState: ReturnType<typeof useFiltersState>;
};

const getLegacyLogsStateFromFiltersState = ({ filtersState }: Args) => {
  const keyExistsState = getKeyExistsState({
    filtersState,
    filterType: FilterType.keyExists,
  });

  const filterOrExcludeByFingerprintState = getKeyExistsState({
    filtersState,
    filterType: FilterType.filterOrExcludeByFingerprint,
  });

  const searchTermsState = getTermsState({
    filtersState,
    filterType: FilterType.searchTerms,
  });

  const selectedFacetRangeByNameState =
    getSelectedFacetRangeByNameState(filtersState);

  const selectedFacetValuesByNameState =
    getSelectedFacetValuesByNameState(filtersState);
  return {
    filterOrExcludeByFingerprint: filterOrExcludeByFingerprintState.state,
    keyExists: keyExistsState.state,
    filterByFacets: getFiltersByFacets({ filtersState }),
    searchTerms: searchTermsState.state,
    selectedFacetRangeByName: selectedFacetRangeByNameState.state,
    selectedFacetValuesByName: selectedFacetValuesByNameState.state,
  };
};

export default getLegacyLogsStateFromFiltersState;
