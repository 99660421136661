import React, { createContext, ReactNode, useContext, useState } from 'react';
import {
  CursorStateContextValue,
  CursorStateProps,
} from 'types/CursorStateContext';

const CursorStateContext = createContext<CursorStateContextValue | undefined>(
  undefined,
);

export const CursorStateProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [cursorState, setCursorState] = useState<CursorStateProps | null>(null);

  return (
    <CursorStateContext.Provider value={{ cursorState, setCursorState }}>
      {children}
    </CursorStateContext.Provider>
  );
};

/**
 * Custom hook to use the cursor position context.
 * This hook returns the current value of the CursorPositionContext.
 * It should be used within a component that is a child of CursorPositionProvider.
 * If it's used outside of a CursorPositionProvider, it will throw an error.
 *
 * @returns The current value of the CursorPositionContext, or throws an error if the context is undefined.
 */
export const useCursorContextState = ():
  | CursorStateContextValue
  | undefined => {
  const context = useContext(CursorStateContext);
  if (!context) {
    throw new Error(
      'useCursorPosition must be used within a CursorPositionProvider',
    );
  }
  return context;
};
