import { useLogsMetricsQueryState } from 'hooks';
import { useState } from 'react';
import { LogsMetricQueryProps, QueryLangType } from 'types';
import {
  defaultLogsQuery,
  getRollupByVisualization,
  getRollupToSecond,
  parseUrlParamByKey,
  unescapeDoubleQuotes,
} from 'utils';

import {
  AnomalyConditionProps,
  ConditionProps,
  ForecastConditionProps,
  OutlierConditionProps,
  useAlertsCreateCondition,
} from '../../AlertsCreateCondition';
import {
  anomalyAlertValidatorForCreate,
  forecastAlertValidatorForCreate,
  logsAlertValidatorForCreate,
  thresholdAlertValidatorForCreate,
} from '../../AlertsCreateValidators';
import { useAlertsCreate } from '../../hooks';
import { AlertType, RuleType } from '../../types';
import {
  getAnomalyAlertsCreateKfuseql,
  getForecastKfuseqlForLoad,
  getLogqlWithFields,
  getOutlierKfuseqlForLoad,
  getQueryItemForKfuseqlForForecast,
  getSelectedLogQueryLogql,
} from '../utils';
import { getAlertInterval } from '../../utils';

const useAlertsCreateLogs = (
  alertsCreateState: ReturnType<typeof useAlertsCreate>,
  baseWidth: number,
) => {
  const {
    evaluate,
    alertsDetails,
    addToast,
    date,
    mutateAlertsRule,
    setIsSaving,
    setErrorHandling,
  } = alertsCreateState;
  const parsedQueries = parseUrlParamByKey('LogsMetricsQueries');
  const [logsExplorerType, setLogsExplorerType] = useState('builder');
  const [logQLText, setLogQLText] = useState('');

  const queriesState = useState<LogsMetricQueryProps[]>(
    parsedQueries || [defaultLogsQuery],
  );

  const logsMetricsQueryState = useLogsMetricsQueryState({
    chartWidth: baseWidth,
    date,
    onChangeLoad: true,
    isRange: true,
    minStep: '15s',
    queriesState,
  });
  const { queries, formulas, queryLangType } = logsMetricsQueryState;

  const getQueriesWithInterval = (queries: LogsMetricQueryProps[]) => {
    const { interval } = getAlertInterval(evaluate.for);
    const queriesWithInterval = queries.map((query) => ({
      ...query,
      step: interval,
    }));
    return { queriesWithInterval, interval };
  };

  const createLogsAlertThreshold = (condition: ConditionProps) => {
    const { queryKey } = condition;
    const { queriesWithInterval, interval } = getQueriesWithInterval(queries);
    const selectedQuery = getSelectedLogQueryLogql({
      formulas,
      queries: queriesWithInterval,
      selectedQuery: queryKey,
      queryLangType,
    });

    if (typeof selectedQuery === 'string') {
      addToast({ text: selectedQuery, status: 'error' });
      return;
    }

    const validation = logsAlertValidatorForCreate({
      alertsDetails,
      condition,
    });
    if (typeof validation === 'string') {
      addToast({ text: validation, status: 'error' });
      return;
    }

    const validationKeys = Object.keys(validation);
    if (validationKeys.length) {
      setErrorHandling((prev) => ({ ...prev, ...validation }));
      validationKeys.forEach((key) => {
        addToast({ text: validation[key], status: 'error' });
      });
      return;
    }

    if (!selectedQuery) {
      addToast({
        text: 'Failed to create alerts invalid logql',
        status: 'error',
      });
      return;
    }
    const { logql, query, meta } = selectedQuery;
    const logqlWithFields = getLogqlWithFields(logql, meta.kfuseQlOperation);
    const queryType = queryKey.includes('Query') ? 'query' : 'formula';
    const extraData = {
      queryType,
      queries: queryType === 'query' ? [query] : queries,
      formulas: queryType === 'formula' ? formulas : [],
      queryKey,
      queryLangType,
    };
    const unescapedLogql = unescapeDoubleQuotes(logqlWithFields);

    setIsSaving(true);
    mutateAlertsRule({
      condition,
      datasourceType:
        queryLangType === QueryLangType.LOGQL ? 'loki' : 'kfuseql',
      interval,
      promqlQuery: unescapedLogql,
      ruleAnnotations: {
        ruleType: RuleType.LOGS,
        extraData: JSON.stringify(extraData),
      },
    });
  };

  const createLogsAlertAnomaly = ({
    anomalyCondition,
    condition,
  }: {
    anomalyCondition: AnomalyConditionProps;
    condition: ConditionProps;
  }) => {
    const { queriesWithInterval, interval } = getQueriesWithInterval(queries);
    const validation = anomalyAlertValidatorForCreate({
      alertsDetails,
      condition,
      anomalyCondition,
      formulas,
      queries: queriesWithInterval,
    });

    if (typeof validation === 'string') {
      addToast({ text: validation, status: 'error' });
      return;
    }

    const validationKeys = Object.keys(validation);
    if (validationKeys.length) {
      setErrorHandling((prev) => ({ ...prev, ...validation }));
      validationKeys.forEach((key) => {
        addToast({ text: validation[key], status: 'error' });
      });
      return;
    }

    const { queryKey } = condition;
    const selectedQuery = getSelectedLogQueryLogql({
      formulas,
      queries: queriesWithInterval,
      selectedQuery: queryKey,
      queryLangType,
    });

    if (!selectedQuery || typeof selectedQuery === 'string') {
      addToast({
        text: 'Failed to create alerts invalid logql',
        status: 'error',
      });
      return;
    }
    const { logql, query, meta } = selectedQuery;
    const kfuseQlForAnomaly = getAnomalyAlertsCreateKfuseql({
      anomalyCondition,
      query: logql,
      step: meta.step,
      kfuseQlOperation: meta.kfuseQlOperation,
    });
    const queryType = queryKey.includes('Query') ? 'query' : 'formula';
    const extraData = {
      queryType,
      queries: queryType === 'query' ? [query] : queries,
      formulas: queryType === 'formula' ? formulas : [],
      queryKey,
      queryLangType,
    };
    const unescapedLogql = unescapeDoubleQuotes(kfuseQlForAnomaly);
    setIsSaving(true);
    mutateAlertsRule({
      condition,
      datasourceType:
        queryLangType === QueryLangType.KFUSEQL ? 'kfuseql' : 'loki',
      interval,
      promqlQuery: unescapedLogql,
      ruleAnnotations: {
        ruleType: RuleType.LOGS,
        alertType: AlertType.ANOMALY,
        extraData: JSON.stringify(extraData),
      },
    });
  };

  const createLogsAlertOutlier = ({
    outlierCondition,
    condition,
  }: {
    outlierCondition: OutlierConditionProps;
    condition: ConditionProps;
  }) => {
    const { queriesWithInterval, interval } = getQueriesWithInterval(queries);
    condition.value = '0'; // Outlier condition value is not required from the user.
    const validation = thresholdAlertValidatorForCreate({
      alertsDetails,
      condition,
      formulas,
      queries: queriesWithInterval,
    });

    if (typeof validation === 'string') {
      addToast({ text: validation, status: 'error' });
      return;
    }

    const validationKeys = Object.keys(validation);
    if (validationKeys.length) {
      setErrorHandling((prev) => ({ ...prev, ...validation }));
      validationKeys.forEach((key) => {
        addToast({ text: validation[key], status: 'error' });
      });
      return;
    }

    const { queryKey } = condition;
    const selectedQuery = getSelectedLogQueryLogql({
      formulas,
      queries: queriesWithInterval,
      selectedQuery: queryKey,
      queryLangType,
    });

    if (typeof selectedQuery === 'string') {
      addToast({ text: selectedQuery, status: 'error' });
      return;
    }

    const { logql, query, meta } = selectedQuery;
    const kfuseQlForOutlier = getOutlierKfuseqlForLoad({
      outlierCondition,
      queryItem: {
        band: 1,
        kfuseQlOperation: meta.kfuseQlOperation,
        step: meta.step,
        kfuseql: logql,
      },
    });
    const queryType = queryKey.includes('Query') ? 'query' : 'formula';
    const extraData = {
      queryType,
      queries: queryType === 'query' ? [query] : queries,
      formulas: queryType === 'formula' ? formulas : [],
      queryKey,
      queryLangType,
    };
    const unescapedLogql = unescapeDoubleQuotes(kfuseQlForOutlier);
    setIsSaving(true);
    mutateAlertsRule({
      condition,
      datasourceType:
        queryLangType === QueryLangType.LOGQL ? 'loki' : 'kfuseql',
      interval,
      promqlQuery: unescapedLogql,
      ruleAnnotations: {
        ruleType: RuleType.LOGS,
        alertType: AlertType.OUTLIERS,
        extraData: JSON.stringify(extraData),
      },
    });
  };

  const createLogsAlertForecast = ({
    forecastCondition,
    condition,
  }: {
    forecastCondition: ForecastConditionProps;
    condition: ConditionProps;
  }) => {
    const validation = forecastAlertValidatorForCreate({
      alertsDetails,
      condition,
      forecastCondition,
      formulas,
      queries,
    });

    if (typeof validation === 'string') {
      addToast({ text: validation, status: 'error' });
      return;
    }

    const validationKeys = Object.keys(validation);
    if (validationKeys.length) {
      setErrorHandling((prev) => ({ ...prev, ...validation }));
      validationKeys.forEach((key) => {
        addToast({ text: validation[key], status: 'error' });
      });
      return;
    }

    const { queryKey } = condition;
    const { forecastAlgorithm, interval } = forecastCondition;

    let stepForcastDuration = undefined;
    if (forecastAlgorithm === 'seasonal') {
      stepForcastDuration = Number(interval);
    } else {
      const forecastDurationSeconds = getRollupToSecond(
        forecastCondition.forecastDuration,
      );
      stepForcastDuration = getRollupByVisualization({
        startTimeUnix: 0,
        endTimeUnix: forecastDurationSeconds,
      });
    }

    const selectedQuery = getQueryItemForKfuseqlForForecast({
      formulas,
      queries,
      selectedQuery: queryKey,
      step: stepForcastDuration,
    });

    if (typeof selectedQuery === 'string') {
      addToast({ text: selectedQuery, status: 'error' });
      return;
    }

    const { kfuseql, query, queryType, kfuseQlOperation } = selectedQuery;
    const kfuseqlForecast = getForecastKfuseqlForLoad({
      kfuseQlOperation: kfuseQlOperation,
      kfuseql,
      forecastCondition,
      step: stepForcastDuration,
      band: condition.of === 'gt' ? '4' : '5',
    });

    const extraData = {
      queryType,
      queries: queryType === 'query' ? [query] : queries,
      formulas: queryType === 'formula' ? formulas : [],
      queryKey,
      queryLangType,
    };
    const unescapedLogql = unescapeDoubleQuotes(kfuseqlForecast);

    setIsSaving(true);
    mutateAlertsRule({
      condition,
      datasourceType:
        queryLangType === QueryLangType.LOGQL ? 'loki' : 'kfuseql',
      interval: `${stepForcastDuration}s`,
      promqlQuery: unescapedLogql,
      ruleAnnotations: {
        ruleType: RuleType.LOGS,
        alertType: AlertType.FORECAST,
        extraData: JSON.stringify(extraData),
      },
    });
  };

  const setAdvancedCondition = ({
    newAlertType,
    newAnomalyCondition,
    newOutlierCondition,
    newForecastCondition,
    conditionState,
  }: {
    newAlertType: AlertType;
    newAnomalyCondition: AnomalyConditionProps;
    newOutlierCondition: OutlierConditionProps;
    newForecastCondition: ForecastConditionProps;
    conditionState: ReturnType<typeof useAlertsCreateCondition>;
  }) => {
    const { anomalyCondition, outlierCondition, forecastCondition } =
      conditionState;

    if (newAlertType === AlertType.ANOMALY) {
      conditionState.setAnomalyCondition({
        ...anomalyCondition,
        ...newAnomalyCondition,
      });
    }

    if (newAlertType === AlertType.OUTLIERS) {
      conditionState.setOutlierCondition({
        ...outlierCondition,
        ...newOutlierCondition,
      });
    }

    if (newAlertType === AlertType.FORECAST) {
      conditionState.setForecastCondition({
        ...forecastCondition,
        ...newForecastCondition,
      });
    }
  };

  return {
    createLogsAlertAnomaly,
    createLogsAlertOutlier,
    createLogsAlertThreshold,
    createLogsAlertForecast,
    logQLText,
    logsExplorerType,
    logsMetricsQueryState,
    setAdvancedCondition,
    setLogQLText,
    setLogsExplorerType,
  };
};

export default useAlertsCreateLogs;
