import { uniq } from 'lodash';
import { LogsMetricQueryProps } from 'types/LogsMetricsQueryBuilder';

import validateArithmeticFormulas from './validateArithmeticFormulas';

const getLogqlFormulaOperatorNormalized = (formula: string) => {
  const logqlLogicalOperators = ['and', 'or', 'unless'];

  return formula
    .split(' ')
    .map((word) => {
      if (logqlLogicalOperators.includes(word)) {
        if (word === 'and') {
          return '+';
        }
        if (word === 'or') {
          return '*';
        }
        if (word === 'unless') {
          return '^';
        }
      }
      return word;
    })
    .join(' ');
};

export const getUsedQueryKeysInFormulas = (formulas: string[]): string[] => {
  const usedQueryKeys = formulas.map((formula) =>
    getUsedQueryKeysFromFormula(formula),
  );
  return uniq(usedQueryKeys.flat());
};

export const getUsedQueryKeysFromFormula = (formula: string): string[] => {
  const normalizedFormula = getLogqlFormulaOperatorNormalized(formula);
  const findUsedQuery = new RegExp('[a-z]', 'gi');
  const usedQueryKeys = normalizedFormula.match(findUsedQuery);
  const uniqueUsedQueryKeys = uniq(usedQueryKeys);
  return uniqueUsedQueryKeys || [];
};

export const validateLogqlFormulaExpression = (
  formula: string,
  queryVar: string[],
  queries: LogsMetricQueryProps[],
): boolean | string => {
  // check if and, or, unless exists in formula
  // if exists, replace with +, *, ^ respectively
  const formulaWithLogicalOperators =
    getLogqlFormulaOperatorNormalized(formula);
  if (
    !validateArithmeticFormulas({
      formula: formulaWithLogicalOperators,
      queryVar,
    })
  ) {
    return 'Invalid formula';
  }

  const usedQueryKeys = getUsedQueryKeysFromFormula(formula);
  if (!usedQueryKeys) return true;

  const rangeAggregateGroupings = usedQueryKeys?.map((key) => {
    const query = queries.find((q) => q.queryKey === key);
    if (!query) return [];
    return query.rangeAggregateGrouping;
  });

  if (!rangeAggregateGroupings) return true;

  // check if all queries have same rangeAggregateGrouping with each other
  const rangeAggregateGroupingZero = rangeAggregateGroupings[0];
  const checkSameRangeAggregateGrouping = rangeAggregateGroupings?.every(
    (grouping) => grouping.join(',') === rangeAggregateGroupingZero.join(','),
  );

  if (!checkSameRangeAggregateGrouping) {
    return 'Grouping must be the same for all queries';
  }

  return true;
};
