const binarySearch = (
  arr: number[],
  x: number,
  isNearest: boolean,
): number | null => {
  let start = 0,
    end = arr.length - 1;
  let mid;

  while (start <= end) {
    mid = Math.floor((start + end) / 2);

    if (arr[mid] === x) return mid;
    else if (arr[mid] < x) start = mid + 1;
    else end = mid - 1;
  }

  // If we reach here, then the element was not present
  if (isNearest) {
    // Check which end point is closer to x
    const startDiff = Math.abs(arr[start] - x);
    const endDiff = Math.abs(arr[end] - x);

    if (startDiff < endDiff) return start;
    else return end;
  }

  return null;
};

export default binarySearch;
