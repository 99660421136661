import delimiter from 'kfuse-constants/delimiter';
import { logsQueryOperator, findFirstOperator } from 'utils';

import formatFacetName from './formatFacetName';

export const mapDataTypeToGraphQLType = (
  dataType: string,
  lowercase = false,
): string => {
  const graphQLTypes: { [key: string]: string } = {
    NUMBER: 'Number',
    IP_ADDRESS: 'IpAddr',
    STRING: 'String',
    DURATION: 'Duration',
    SIZE: 'Size',
    URI: 'Uri',
    UUID: 'Uuid',
  };

  if (lowercase) {
    const graphQLType = graphQLTypes[dataType.toUpperCase()] || 'String';
    const type = graphQLType.toLowerCase();
    if (type === 'ipaddr') {
      return 'ip_address';
    }
    return type;
  }

  return graphQLTypes[dataType] || '';
};

const facetTermsExistQuery = ({
  facetName,
  dataTypeQuery,
  operator,
  value,
}: {
  dataTypeQuery: string;
  facetName: string;
  operator: string;
  value: string;
}) => {
  let result = '';

  const facetTermsExistClause = `{ facetTermsExist: { facetName: "${facetName}", value: "${value}" ${dataTypeQuery} } }`;
  if (operator === 'notFacetTermsExist') {
    result += `{ not: ${facetTermsExistClause} }`;
  } else {
    result += facetTermsExistClause;
  }

  return result;
};

/**
 * Builds a query for a facet.
 * @param {string[]} facetNames
 * @returns {string}
 * example input for buildFacetQuery:
 * cluster-name=demo
 * cluster-name!=observes
 * cluster-name=~^.*observes.*$
 * cluster-name>=0.1
 * cluster-name<=0.2
 * Output:
 * { eq: { facetName: "cluster-name", value: "demo" } }
 * { neq: { facetName: "cluster-name", value: "observes" } }
 * { regex: { facetName: "cluster-name", value: "^.*observes.*$" } }
 * { gte: { facetName: "cluster-name", value: "0.1" } }
 * { lte: { facetName: "cluster-name", value: "0.2" } }
 */

export const buildFacetQuery = (facetList: string[]): string => {
  if (!facetList || facetList.length === 0) {
    return '';
  }
  const facetListBitmap: { [key: string]: Array<any> } = {};
  facetList.map((facet) => {
    const splitForDataType = facet.split(delimiter);

    const [dataTypeRaw] = splitForDataType;
    const dataType = mapDataTypeToGraphQLType(dataTypeRaw?.toUpperCase());
    const facetQueryStr = splitForDataType.slice(1).join(delimiter);

    const { operatorIndex, operator } = findFirstOperator(facetQueryStr);
    if (operatorIndex > -1) {
      const rawFacetName = facetQueryStr.slice(0, operatorIndex);
      const rawFacetNameParts = rawFacetName.split(':');

      const component =
        rawFacetNameParts.length > 1 ? rawFacetNameParts[0] : '';

      const facetName = rawFacetNameParts
        .slice(rawFacetNameParts.length > 1 ? 1 : 0)
        .join(':');
      const rawValue = facetQueryStr.slice(operatorIndex + operator.length);

      // remove wrapping first / last quotes
      const value = rawValue.replace(/^"(.*)"$/, '$1');

      const sanitizedFacetName = facetName.startsWith('@')
        ? facetName.slice(1)
        : facetName;
      const formattedFacetName = formatFacetName(
        component,
        sanitizedFacetName,
        dataType,
      );

      if (!facetListBitmap[formattedFacetName]) {
        facetListBitmap[formattedFacetName] = [];
      }

      facetListBitmap[formattedFacetName].push({
        facetName: formattedFacetName,
        operator: logsQueryOperator[operator],
        value,
        dataType,
      });
    }
  });

  let result = '';
  const sourceList = Object.keys(facetListBitmap);
  sourceList.map((source) => {
    const facetList = facetListBitmap[source];

    if (facetList.length === 1) {
      const { dataType } = facetList[0];
      const dataTypeQuery = dataType ? `, dataType: ${dataType}` : '';

      const values = facetList[0].value.split(' OR ');
      if (values.length > 1) {
        result += `{ or : [`;
      }

      values.forEach((value: string) => {
        const facetQuery = `{ facetName: "${facetList[0].facetName}", value: "${value}" ${dataTypeQuery} }`;
        if (facetList[0].operator === 'notregex') {
          result += `{ not: { regex: ${facetQuery} }}`;
        } else if (
          facetList[0].operator === 'facetTermsExist' ||
          facetList[0].operator === 'notFacetTermsExist'
        ) {
          result += facetTermsExistQuery({
            dataTypeQuery,
            facetName: facetList[0].facetName,
            operator: facetList[0].operator,
            value,
          });
        } else {
          result += `{ ${facetList[0].operator}: ${facetQuery} }`;
        }
      });

      if (values.length > 1) {
        result += `]}`;
      }
      return;
    }

    result += `{ and: [`;
    facetList.map((facet) => {
      const { dataType } = facet;
      const dataTypeQuery = facet.dataType ? `, dataType: ${dataType}` : '';

      const values = facet.value.split(' OR ');
      if (values.length > 1) {
        result += `{ or : [`;
      }

      values.forEach((value: string) => {
        const facetQuery = `{ facetName: "${facet.facetName}", value: "${value}" ${dataTypeQuery} }`;

        if (facet.operator === 'notregex') {
          result += `{ not: { regex: ${facetQuery} }}`;
        } else if (
          facet.operator === 'notFacetTermsExist' ||
          facet.operator === 'facetTermsExist'
        ) {
          result += facetTermsExistQuery({
            dataTypeQuery,
            facetName: facet.facetName,
            operator: facet.operator,
            value,
          });
        } else {
          result += `{ ${facet.operator}: ${facetQuery} }`;
        }
      });

      if (values.length > 1) {
        result += `]}`;
      }
    });
    result += `]} `;
  });

  return result;
};
