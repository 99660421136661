export type CssVariable = `var(--${string})`;

export const isCssVariable = (value: string): value is CssVariable =>
  /^var\(--.+\)$/.test(value);

const getCssVariableValue = (cssVariable: CssVariable) => {
  const style = getComputedStyle(document.documentElement);
  const variableName = cssVariable.slice(4, -1);
  return style.getPropertyValue(variableName);
};

export default getCssVariableValue;
