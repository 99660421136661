import { TableRow, TableRowProps } from 'components';
import { useRequest } from 'hooks';
import React, { useMemo } from 'react';
import useRumState from './hooks/useRumState';
import rumErrorGroups from './requests/rumErrorGroups';
import { RumErrorGroup } from './types';

type Props = {
  tableRowProps: TableRowProps<RumErrorGroup>;
  rumState: ReturnType<typeof useRumState>;
};

const RumErrorGroupTableRow = ({ tableRowProps, rumState }: Props) => {
  const fetchErrorGroupingKey = useRequest(rumErrorGroups, true, true);

  const onFetchErrorGroupingKey = (errorGroupingKey: string) => {
    if (!fetchErrorGroupingKey.calledAtLeastOnce) {
      fetchErrorGroupingKey.call({
        errorGroupingKey,
        rumState,
      });
    }
  };

  const optimizedRowData = useMemo(() => {
    const data = fetchErrorGroupingKey.result || [];
    if (data.length > 0) {
      return data[0];
    }
    return null;
  }, [fetchErrorGroupingKey.result]);

  return (
    <TableRow
      {...tableRowProps}
      row={{
        ...tableRowProps.row,
        onFetchErrorGroupingKey: onFetchErrorGroupingKey,
        optimizedRowData: optimizedRowData,
      }}
    />
  );
};

export default RumErrorGroupTableRow;
