import { ChipWithLabel, TableColumnType } from 'components';
import React from 'react';
import { KpisByFunctionName } from 'screens/Serverless/types';
import { DateSelection } from 'types';
import ServerlessTableKpis, {
  ServerlessTableKpiKeys,
} from '../requests/serverlessTableKpis';

const getRenderCell = (kpi) => (args) => {
  if (args.value === undefined || isNaN(args.value)) {
    if (kpi.key === ServerlessTableKpiKeys.errorRate) {
      return `${0}%`;
    }

    return '-';
  }

  return kpi.renderCell(args);
};

const getColumns = (
  colorsByFunctionName: { [key: string]: string },
  date: DateSelection,
  isLoadingState: Record<keyof KpisByFunctionName, number>,
  kpisByFunctionName: KpisByFunctionName,
  search: string,
  setActiveFunctionNameHandler,
) => [
  {
    key: 'name',
    label: 'Function Arn',
    renderCell: ({ row }: { row: Record<string, string> }) => (
      <ChipWithLabel
        color={colorsByFunctionName[row?.functionArn]}
        label={
          <button onClick={setActiveFunctionNameHandler({ row })}>
            {row?.functionArn}
          </button>
        }
      />
    ),
  },
  {
    key: 'lastModified',
    label: 'Last Modified',
    renderCell: ({ row }: { row: Record<string, string> }) => row.lastModified,
  },
  ...ServerlessTableKpis.map((kpi) => ({
    type: TableColumnType.NUMBER,
    key: kpi.key,
    label: kpi.label,
    renderCell: isLoadingState[kpi.key]
      ? () => <div className="spinner" />
      : getRenderCell(kpi),
    value: ({ row }) => {
      if (kpisByFunctionName[row.name]) {
        return kpisByFunctionName[row.name][kpi.key] || 0;
      }
      return 0;
    },
  })),
];

export default getColumns;
