import { UplotExtended, TooltipDataReturnProps } from 'types/Timeseries';
import {
  canvasPadding,
  drawChevron,
  drawLineOnTimeseries,
  drawTooltipTimestamp,
  drawVertcalCursor,
  getChartDuration,
  getTooltipPositionByLayoutType,
  themeColorDark,
  themeColorLight,
} from 'utils';
import { CursorStateProps } from 'types/CursorStateContext';

import { calculateNextTooltipPosition } from './tooltip-utils';

const findClosestSplit = (value: number, splits: number[]) => {
  for (let i = 1; i < splits.length; i++) {
    if (splits[i] >= value) {
      return splits[i];
    } else {
      continue;
    }
  }
  return splits[splits.length - 1];
};

const findBucketByCursor = ({
  u,
  cursorLeft,
  cursorTop,
}: {
  u: UplotExtended;
  cursorLeft: number;
  cursorTop: number;
}) => {
  const xVal = u.posToVal(cursorLeft, 'x', true);
  const yVal = u.posToVal(cursorTop, 'y', true);
  const dataHeatmap = u.data[1] as unknown as number[][];
  const timestamps = dataHeatmap[0] as unknown as number[];

  const { axes, data } = u;
  const xAxisSplits = axes[0]._splits;
  const yAxisSplits = axes[1]._splits;

  const { width, height } = u.bbox;
  const xSize = width / xAxisSplits.length;
  const ySize = height / yAxisSplits.length;

  const timestamp = findNearestTimestamp(timestamps, xVal);
  const { count, yValue } = findNearestYValueAndCount({
    dataHeatmap: data[1] as unknown as number[][],
    value: yVal,
    timestamp,
  });
  const closestYSplit = findClosestSplit(yValue, yAxisSplits);
  const yPos = u.valToPos(closestYSplit, 'y', true);
  const xPos = u.valToPos(timestamp, 'x', true);

  const HALF_X_SIZE = xSize / 2;
  const HALF_Y_SIZE = ySize / 2;

  return {
    xPos: xPos + HALF_X_SIZE,
    yPos: yPos + HALF_Y_SIZE,
    timestamp,
    count,
    yValue,
  };
};

const findNearestTimestamp = (timestamps: number[], value: number) => {
  const timestampBitmap: { [key: number]: number } = {};

  for (let i = 0; i < timestamps.length; i++) {
    const timestamp = timestamps[i];
    timestampBitmap[timestamp] = timestamp;
  }

  let nearestTimestamp = timestamps[0];
  let smallestDiff = Infinity;
  for (let i = 1; i < timestamps.length; i++) {
    const diff = Math.abs(timestamps[i] - value);
    if (diff < smallestDiff) {
      smallestDiff = diff;
      nearestTimestamp = timestamps[i];
    }
  }
  return nearestTimestamp;
};

const findNearestYValueAndCount = ({
  dataHeatmap,
  value,
  timestamp,
}: {
  dataHeatmap: number[][];
  value: number;
  timestamp: number;
}) => {
  const [timestamps, yAxisValues, counts] = dataHeatmap;
  let index = Infinity;
  let smallestDiff = Infinity;
  for (let i = 0; i < yAxisValues.length; i++) {
    if (timestamps[i] !== timestamp) continue;
    const diff = Math.abs(yAxisValues[i] - value);
    if (diff < smallestDiff) {
      smallestDiff = diff;
      index = i;
    }
  }

  return { yValue: yAxisValues[index], count: counts[index] };
};

const heatmapTooltip = ({
  bbox,
  seriesIdx,
  u,
}: {
  bbox: DOMRect;
  seriesIdx: number | null;
  u: UplotExtended;
}): TooltipDataReturnProps => {
  const devicePixelRatio = window.devicePixelRatio || 1;
  // clear canvas
  u.clearCanvasByContext(u.tooltipCtx);

  if (u.ignoreTooltip) return;

  const padding = canvasPadding(u);
  const leftX = u.cursor.left * devicePixelRatio + padding.left;
  const topY = u.cursor.top * devicePixelRatio + padding.top;
  const bottomY = u.height * devicePixelRatio - padding.bottom;

  const { xPos, yPos, timestamp, count, yValue } = findBucketByCursor({
    u,
    cursorLeft: leftX,
    cursorTop: topY,
  });

  const dataHeatmap = u.data[1] as unknown as number[][];
  const timestamps = dataHeatmap[0] as unknown as number[];

  const cursorState: CursorStateProps = {
    timestamp: { value: timestamp, type: 'timestamp' },
  };
  const { darkModeEnabled } = u;

  // calculate color
  const bgColor = darkModeEnabled
    ? themeColorLight.light03
    : themeColorDark.dark03;

  // // draw vertical cursor
  drawVertcalCursor({ ctx: u.tooltipCtx, leftX, bottomY, padding, bgColor });

  const CHEVRON_SIZE = 10 * devicePixelRatio;
  // draw chevron
  drawChevron({
    ctx: u.tooltipCtx,
    x: leftX,
    y: bottomY,
    size: CHEVRON_SIZE,
    bgColor,
  });

  //   draw tooltip timestamp
  const duration = getChartDuration([timestamps]);
  drawTooltipTimestamp({ u, bbox, duration, timestamp, leftX });

  const nextPos = calculateNextTooltipPosition({
    cursorLeft: xPos,
    cursorTop: yPos,
    bbox,
  });

  // draw lines till the tooltip data point
  if (nextPos) {
    const { x, y } = nextPos;
    drawLineOnTimeseries({
      ctx: u.tooltipCtx,
      start: { x: xPos, y: yPos },
      end: { x, y },
      options: { color: bgColor, lineWidth: 2 },
    });
  }

  const positionByLayoutType = getTooltipPositionByLayoutType({
    bbox,
    nextPos: {
      ...nextPos,
      data: { label: 'Latency', color: bgColor, value: yValue },
    },
    u,
  });

  if (!positionByLayoutType) return;
  return {
    cursorState,
    tooltip: {
      ...nextPos,
      ...positionByLayoutType,
      data: { label: `Count: ${count}`, value: yValue.toString() },
    },
  };
};

export default heatmapTooltip;
