import {
  DashboardPanelType,
  LogsMetricForumlaProps,
  LogsMetricQueryProps,
  QueryLangType,
} from 'types';
import { getKfuseQlForAnomalyAlerts } from 'utils';

import { anomalyBandMap } from '../../AlertsCreateMetrics/utils';
import { AnomalyConditionProps } from '../../AlertsCreateCondition';
import { getSelectedLogQueryLogql } from './alertsCreateLogs';
import { AlertAnomalyQueryItem } from '../../types';

export const logsAnomalyAlgorithmTypeOptions = [
  { label: 'Please select', value: '' },
  { label: 'basic', value: 'basic' },
  { label: 'agile', value: 'agile' },
];

export const getQueryItemForKfuseql = ({
  formulas,
  queries,
  selectedQuery,
  queryLangType,
}: {
  formulas: LogsMetricForumlaProps[];
  queries: LogsMetricQueryProps[];
  selectedQuery: string;
  queryLangType: QueryLangType;
}): AlertAnomalyQueryItem => {
  const item = getSelectedLogQueryLogql({
    formulas,
    queries,
    selectedQuery,
    queryLangType,
  });
  if (!item || typeof item === 'string') return;

  const { logql, meta } = item;
  return {
    metricName: meta.metricName,
    queryType: selectedQuery.includes('Formula') ? 'formula' : 'query',
    refId: meta.refId,
    step: meta.step,
    type: DashboardPanelType.TIMESERIES,
    kfuseql: logql,
    ...meta,
  };
};

export const getAnomalyAlertsLoadKfuseql = ({
  anomalyCondition,
  query,
  step,
  kfuseQlOperation,
}: {
  anomalyCondition: AnomalyConditionProps;
  query: string;
  step?: number;
  kfuseQlOperation: string;
}): string => {
  if (!query) return '';
  const { anomalyAlgorithm, bound, model, seasonality, window } =
    anomalyCondition;

  const bandMapped = '3';

  const kfuseQl = getKfuseQlForAnomalyAlerts({
    algorithm: anomalyAlgorithm,
    band: bandMapped,
    bounds: bound,
    operation: kfuseQlOperation,
    query,
    step: `${step}s`,
    window: window,
  });

  return kfuseQl;
};

export const getAnomalyAlertsCreateKfuseql = ({
  anomalyCondition,
  query,
  step,
  kfuseQlOperation,
}: {
  anomalyCondition: AnomalyConditionProps;
  query: string;
  step?: number;
  kfuseQlOperation: string;
}): string => {
  if (!query) return '';
  const { anomalyAlgorithm, bound, band, model, seasonality, window } =
    anomalyCondition;

  const bandMapped = anomalyBandMap[band];
  const kfuseQl = getKfuseQlForAnomalyAlerts({
    algorithm: anomalyAlgorithm,
    band: bandMapped,
    bounds: bound,
    operation: kfuseQlOperation,
    query,
    step: `${step}s`,
    window: window,
  });

  return kfuseQl;
};
