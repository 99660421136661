import { PopoverPosition, PopoverTriggerV2 } from 'components';
import React from 'react';
import { DateSelection, FilterMethodByType } from 'types';
import ServerlessRightSidebarFiltersItemPanel from './ServerlessRightSidebarFiltersItemPanel';

type Props = {
  date: DateSelection;
  facetName: string;
  name: string;
  sidebarFilterState: FilterMethodByType<'map'>;
  value: string;
};

const ServerlessRightSidebarChipItem = ({
  date,
  facetName,
  name,
  sidebarFilterState,
  value,
}: Props) => {
  return (
    <PopoverTriggerV2
      className="serverless__selected-function__attribute"
      popover={(props) => (
        <ServerlessRightSidebarFiltersItemPanel
          {...props}
          date={date}
          facetName={facetName}
          name={name}
          sidebarFilterState={sidebarFilterState}
          value={value}
        />
      )}
      position={PopoverPosition.BOTTOM_RIGHT}
    >
      <span className="serverless__selected-function__attribute__label">
        {name}: {value}
      </span>
    </PopoverTriggerV2>
  );
};

export default ServerlessRightSidebarChipItem;
