import { useServicesPageStateContext } from 'context';
import React, { useMemo } from 'react';
import {
  createSearchParams,
  Link,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { getASMFilterFromURLString, ServicesTab } from 'screens/Services/utils';
import {
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  Service,
} from 'types';
import { getIsAsmChecked, pickUrlSearchParamsByKeys } from 'utils';
import HighlightedText from './HighlightedText';
import { useServicesPageState } from 'hooks/pageStates';

const renderName = ({ highlighted, name, onClick, serviceHash, showLink }) => {
  if (!showLink) {
    return <HighlightedText highlighted={highlighted} text={name} />;
  }

  if (
    serviceHash !== 'UNKNOWN' &&
    serviceHash !== 'EXTERNAL' &&
    serviceHash !== 'UNKNOWN-client' &&
    serviceHash !== 'UNKNOWN-server'
  ) {
    return (
      <button className="link" onClick={onClick} type="button">
        <HighlightedText highlighted={highlighted} text={name} />
      </button>
    );
  }

  return <HighlightedText highlighted={highlighted} text={name} />;
};

type Props = {
  distinctLabels: Record<string, string>;
  labels: Record<string, string>;
  name: string;
  search: string;
  serviceHash: string;
  serviceByHash: Record<string, Service>;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  showLink?: boolean;
};

const ServiceServiceMapNodeLabel = ({
  distinctLabels,
  labels,
  name,
  search,
  serviceHash,
  serviceByHash,
  selectedFacetValuesByName,
  showLink,
}: Props) => {
  const navigate = useNavigate();
  const searchedService = serviceByHash[search];
  const highlighted = searchedService?.name || search;
  const servicePageState = useServicesPageStateContext();

  const serviceType = serviceByHash?.[serviceHash]?.kfType;

  const onClick = () => {
    const nextSelectedFacetValuesByName: SelectedFacetValuesByName = {};
    const isAsmChecked = getIsAsmChecked(selectedFacetValuesByName);

    nextSelectedFacetValuesByName['kf_source'] = {
      [isAsmChecked ? 'knight' : 'apm']: 1,
    };

    servicePageState.selectedFacetValuesByNameState.set({
      selectedFacetValuesByName: nextSelectedFacetValuesByName,
    });

    const activeTabParmas = createSearchParams({
      ...(serviceType === 'database' ? { activeTab: ServicesTab.db } : {}),
    });

    navigate(`/apm/services/${serviceHash}?${activeTabParmas.toString()}`);
  };

  return (
    <div className="service-service-map-node-label">
      <div>
        {renderName({
          highlighted,
          onClick,
          name,
          serviceHash,
          showLink,
        })}
      </div>
      {Object.keys(distinctLabels).length ? (
        <div className="service-service-map-node-label__distinct-labels">
          <HighlightedText
            highlighted={highlighted}
            text={Object.keys(distinctLabels)
              .map((label) => distinctLabels[label])
              .join(', ')}
          />
        </div>
      ) : null}
      <div className="service-service-map-node-label__labels">
        {Object.keys(labels)
          .filter((label) => labels[label])
          .map((label) => (
            <div className="flex" key={`${label}-${labels[label]}`}>
              <div className="flex-1 pr-3 font-medium">{label}</div>
              <div>{labels[label]}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ServiceServiceMapNodeLabel;
