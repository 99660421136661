import { UserFacingDateTime, LogLevel, PopoverTriggerV2 } from 'components';
import React, { useRef } from 'react';
import { LogEvent } from 'types';
import { useKeyExistsState, useLogsState } from 'hooks';
import LogsSelectedLogAttributePanel from './LogsSelectedLogAttributePanel';

type Props = {
  logEvent: LogEvent;
  customColumnsState?: ReturnType<typeof useKeyExistsState>;
  logsState: ReturnType<typeof useLogsState>;
  shouldDisableFilterActions?: boolean;
};

const LogsSelectedLogTitle = ({
  logEvent,
  customColumnsState,
  logsState,
  shouldDisableFilterActions,
}: Props): JSX.Element => {
  const { timestamp, labels } = logEvent;
  const logSource = labels?.source;
  const enableKeyExistsFilter = false;
  const fpHash = logEvent.fpHash;
  const containerRef = useRef(null);

  return (
    <div className="logs__selected-log__title" ref={containerRef}>
      <LogLevel
        className="logs__selected-log__title__log-level"
        level={logEvent.level}
      />
      <UserFacingDateTime context="selectedLogTitle" timestamp={timestamp} />
      {logSource && (
        <PopoverTriggerV2
          container={containerRef.current}
          popover={(props) => (
            <LogsSelectedLogAttributePanel
              customColumnsState={customColumnsState}
              disableAddCustomColumn={false}
              enableKeyExistsFilter={enableKeyExistsFilter}
              fpHash={fpHash}
              logFacet={{ name: 'source', value: logSource }}
              logsState={logsState}
              shouldDisableFilterActions={shouldDisableFilterActions}
              source="Additional"
              {...props}
            />
          )}
        >
          <span className="cursor-pointer overflow-hidden text-ellipsis text-sm hover:scale-105 hover:underline">
            {`Source: ${logSource}`}
          </span>
        </PopoverTriggerV2>
      )}
    </div>
  );
};

export default LogsSelectedLogTitle;
