import dayjs from 'dayjs';
import { FacetBase, LogsState, SubscriptionArgs } from 'types';
import { buildQuery, mergeValueCountSubscription } from './utils';

type Args = {
  facet: FacetBase;
  logsState: LogsState;
  onDone?: VoidFunction;
};

const getLabelValuesSubscription = ({
  facet,
  onDone,
  logsState,
}: Args): SubscriptionArgs => {
  const { date } = logsState;
  const startTimeUnix = date.zoomedStartTimeUnix || date.startTimeUnix;
  const endTimeUnix = date.zoomedEndTimeUnix || date.endTimeUnix;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const logQuery = buildQuery(logsState);
  const { type } = facet;

  return {
    queryBuilder: () =>
      `
    subscription {
      getLabelValuesStream(
        durationSecs: ${durationSecs}
        includeCount: true
        labelName: "${facet.name}"
        ${logQuery !== '{}' ? `logQuery: ${logQuery},` : ''}
        limit: 500,
        timestamp: "${endTime.format()}",
      ) {
        count
        value
      }
    }
  `,
    initialState: [],
    key: 'getLabelValuesStream',
    onDone,
    merge: mergeValueCountSubscription(type),
  };
};

export default getLabelValuesSubscription;
