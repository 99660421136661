import { DateControls, ErrorMessage, Loader, ProductTour } from 'components';
import { Datepicker } from 'composite';
import React, { ReactElement } from 'react';
import MetricsSummaryTags from './MetricsSummaryTags';
import MetricsSummaryMetadata from './MetricsSummaryMetadata';
import MetricsSummaryNames from './MetricsSummaryNames';
import useMetricsSummary from './useMetricsSummary';

const MetricsSummary = (): ReactElement => {
  const metricSummaryState = useMetricsSummary();
  const {
    date,
    getMetricsListRequest,
    metricsLabelValueCardinalityRequest,
    onDateChange,
    selectedMetric,
    error,
  } = metricSummaryState;

  return (
    <div className="metrics-summary">
      <Loader isLoading={getMetricsListRequest.isLoading}>
        <div className="metrics-summary__header">
          <div className="flex gap-[4px] items-center">
            <div className="metrics-summary__header__title whitespace-nowrap">
              Metric Summary
            </div>

            <ErrorMessage
              message={error?.getMetricsList?.message}
              className="justify-end pr-[14px]"
            />
          </div>

          <div className="metrics-summary__header__date-picker flex">
            <Datepicker
              className="logs__search__datepicker"
              hasStartedLiveTail={false}
              onChange={onDateChange}
              startLiveTail={null}
              value={date}
            />
            <DateControls date={date} setDate={onDateChange} />
          </div>
        </div>

        <div className="metrics-summary__body">
          <MetricsSummaryNames metricSummaryState={metricSummaryState} />
          <div className="metrics-summary__body__details">
            <div className="metrics-summary__body__title">METRICS DETAILS</div>
            {selectedMetric && (
              <>
                <MetricsSummaryMetadata
                  metricSummaryState={metricSummaryState}
                />
                <Loader
                  isLoading={metricsLabelValueCardinalityRequest.isLoading}
                >
                  <MetricsSummaryTags metricSummaryState={metricSummaryState} />
                </Loader>
              </>
            )}
          </div>
        </div>
      </Loader>
      {<ProductTour />}
    </div>
  );
};

export default MetricsSummary;
