import { Edge, Node } from 'reactflow';
import useServiceMapState from './useServiceMapState';

const getEdgesWithState = ({ edges, serviceMapState }) => {
  const { hoveredEdgeId, hoveredNodeId } = serviceMapState.state;
  if (hoveredNodeId || hoveredEdgeId) {
    return edges.map((edge) => {
      const animated =
        hoveredNodeId === edge.source ||
        hoveredNodeId === edge.target ||
        hoveredEdgeId === edge.id;
      return {
        ...edge,
        animated,
        style: animated
          ? {
              stroke: '#3d8bc9',
              strokeWidth: 3,
            }
          : {
              opacity: '0.2',
            },
        zIndex: animated ? 1 : 0,
      };
    });
  }

  return edges;
};

const getNodesWithState = ({ edges, nodes, serviceMapState }) => {
  const { hoveredEdgeId, hoveredNodeId } = serviceMapState.state;

  const nodesConnectedToHoveredEdge = edges.reduce(
    (
      nodes: { [x: string]: boolean },
      edge: { id: any; source: string | number; target: string | number },
    ) => {
      if (hoveredEdgeId === edge.id) {
        nodes[edge.source] = true;
        nodes[edge.target] = true;
      }
      return nodes;
    },
    {},
  );

  const nodesConnectedToHoveredNode = edges.reduce(
    (
      nodes: { [x: string]: boolean },
      edge: { source: string | number; target: string | number },
    ) => {
      if (hoveredNodeId === edge.source || hoveredNodeId === edge.target) {
        nodes[edge.source] = true;
        nodes[edge.target] = true;
      }
      return nodes;
    },
    {},
  );

  const nodesToHighlightHash = {
    ...nodesConnectedToHoveredEdge,
    ...nodesConnectedToHoveredNode,
  };

  if (hoveredNodeId || hoveredEdgeId) {
    return nodes.map((node: { id: string | number }) => {
      const animated = nodesToHighlightHash[node.id];
      return {
        ...node,
        style: animated
          ? {}
          : {
              opacity: '0.2',
            },
      };
    });
  }
  return nodes;
};

type Args = {
  edges: Edge[];
  nodes: Node[];
  serviceMapState: ReturnType<typeof useServiceMapState>;
};

const getLayoutWithState = ({ edges, nodes, serviceMapState }: Args) => {
  return {
    edges: getEdgesWithState({ edges, serviceMapState }),
    nodes: getNodesWithState({ edges, nodes, serviceMapState }),
  };
};

export default getLayoutWithState;
