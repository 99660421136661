import { ChipWithLabel, ToggleSwitch } from 'components';
import React from 'react';
import { colorByRumEventType } from './constants';
import { useRumReplayStateContext } from './RumReplayStateContext';
import { RumEventType } from './types';

const RumReplayEventsFilterPanel = () => {
  const { actions, shownRumEventTypesState } = useRumReplayStateContext();

  const { toggleShowRumEventType } = actions;
  const [shownRumEventTypes] = shownRumEventTypesState;

  const onChangeHandler =
    (rumEventType: RumEventType) => (isSelected: boolean) => {
      toggleShowRumEventType({ rumEventType, isSelected });
    };

  return (
    <div>
      {[RumEventType.ACTION, RumEventType.ERROR, RumEventType.VIEW]
        .sort()
        .map((rumEventType: RumEventType) => (
          <div
            className="rum-replay__controls__event-types-popover__item"
            key={rumEventType}
          >
            <ToggleSwitch
              key={rumEventType}
              onChange={onChangeHandler(rumEventType)}
              value={Boolean(shownRumEventTypes[rumEventType])}
            />
            <ChipWithLabel
              className="rum-replay__controls__event-types-popover__item__label"
              color={colorByRumEventType[rumEventType]}
              label={rumEventType.toLowerCase()}
            />
          </div>
        ))}
    </div>
  );
};

export default RumReplayEventsFilterPanel;
