import { useLeftSidebarState, ShowSidebarTooltipButton } from 'components';
import { useLogsState, useRequest } from 'hooks';
import React, { ReactElement } from 'react';
import { RequestResult } from 'types';

import { useLogsLiveTail } from './hooks';
import LogsSearchBarV2 from './LogsSearchBarV2';

const LogsSearch = ({
  leftSidebarState,
  logsState,
  originalGetFacetNamesRequest,
}: {
  leftSidebarState: ReturnType<typeof useLeftSidebarState>;
  logsLiveTail: ReturnType<typeof useLogsLiveTail>;
  logsState: ReturnType<typeof useLogsState>['logsState'];
  originalGetFacetNamesRequest: RequestResult;
}): ReactElement => {
  const getFacetNamesRequest = useRequest((args) =>
    originalGetFacetNamesRequest.call(args),
  );

  return (
    <div className="flex">
      {!leftSidebarState.width ? (
        <ShowSidebarTooltipButton onClick={leftSidebarState.show} />
      ) : null}
      <LogsSearchBarV2
        date={logsState.date}
        customerFilter={logsState.customerFilter}
        filtersState={logsState.filtersState}
        getFacetNamesRequest={getFacetNamesRequest}
        logsState={logsState}
      />
    </div>
  );
};

export default LogsSearch;
