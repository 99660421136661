import delimiter from 'kfuse-constants/delimiter';
import {
  DashboardPanelType,
  DateSelection,
  LogQLWithMetaProps,
  LogsMetricForumlaProps,
  LogsMetricQueryProps,
} from 'types';
import { getLogQLWithMetaToLoad } from 'utils';

/**
 * Get the grouped label and facet
 * @param rangeAggregateGrouping string[]
 * @returns
 */
export const getGroupedLabelAndFacet = (
  rangeAggregateGrouping: string[],
): string[] => {
  return rangeAggregateGrouping.map((grouping) => {
    const [component, name] = grouping.split(delimiter);
    if (!component || !name) {
      return grouping;
    }
    if (['Cloud', 'Core', 'Kubernetes', 'Additional'].includes(component)) {
      return `${component}:${name}`;
    }
    return `@${component}:${name}`;
  });
};

/**
 * get the label and facet
 * @param labelAndFacet string []
 * @example Cloud:cluster_name => cluster_name
 * @example @agent:body => @body
 */
export const getLabelAndFacetOnly = (labelAndFacet: string[]): string => {
  const labelAndFacetList = labelAndFacet.map((label) => {
    const [_, name] = label.split(':');
    if (label.charAt(0) === '@') {
      return `@${name}`;
    }
    return name;
  });
  return labelAndFacetList.join(',');
};

export const getLogqlForChartModal = ({
  dataFormat,
  date,
  formula,
  query,
  queries,
  queryLangType,
  customerFilter,
  type,
}: {
  dataFormat: DashboardPanelType;
  date: DateSelection;
  formula?: LogsMetricForumlaProps;
  query?: LogsMetricQueryProps;
  queries?: LogsMetricQueryProps[];
  queryLangType?: string;
  customerFilter?: { key: string; value: string };
  type: 'query' | 'formula';
}): LogQLWithMetaProps => {
  const logQLWithMetaToLoad = getLogQLWithMetaToLoad({
    customerFilter,
    queries: type === 'query' ? [query] : queries || [],
    formulas: type === 'formula' ? [formula] : [],
    date,
    dataFormat,
    formulaOnly: type === 'formula',
    instant: dataFormat !== DashboardPanelType.TIMESERIES,
    queryLangType: queryLangType || '',
  });

  if (!logQLWithMetaToLoad[0]) {
    return null;
  }
  if (type === 'formula') {
    const formulaLogQL = logQLWithMetaToLoad.find(
      (logQL) => logQL.queryType === 'formula',
    );
    return formulaLogQL;
  }
  return logQLWithMetaToLoad[0];
};
