import { AutocompleteOption } from 'components/Autocomplete';
import dayjs from 'dayjs';
import { useDateState, useFilterState, useRequest, useUrlState } from 'hooks';
import { useEffect, useState } from 'react';
import getServerlessMetrics from 'requests/getServerlessMetrics';
import serverlessLabels from 'screens/Serverless/requests/serverlessLabels';
import serverlessLabelValues from 'screens/Serverless/requests/serverlesslabelValues';
import { DateSelection } from 'types';
import { convertTimeStringToUnix } from 'utils';
import { infrastructureErrors } from 'utils/error/infrastructureErrors';

const getServerlessInitalDate = (): DateSelection => {
  const endTimeUnix = dayjs().unix();
  const startTimeUnix = dayjs()
    .subtract(60 * 15, 'seconds')
    .unix();

  return {
    startLabel: 'now-15m',
    endLabel: 'now',
    endTimeUnix,
    startTimeUnix,
  };
};
const useServerlessState = ({
  shouldWriteToUrl,
}: {
  shouldWriteToUrl?: boolean;
}) => {
  const [error, setError] = useState({
    serverlessLabels: null,
    getServerlessMetrics: null,
  });
  const [filterByFacets, setFilterByFacets] = useUrlState('filterByFacets', {});
  const [date, setDate] = useDateState(getServerlessInitalDate());
  const filterState = useFilterState({ shouldWriteToUrl });
  const { filter } = filterState;

  const getServerlessLabelValuesOptions = (
    option: AutocompleteOption,
  ): Promise<AutocompleteOption[]> => {
    return new Promise((resolve) => {
      serverlessLabels({
        date,
      })
        .then((labelValues) => {
          const options: AutocompleteOption[] = labelValues.map(
            ({ count, value }) => ({
              label: value,
              value: value,
              count,
              optionType: 'value',
            }),
          );
          resolve(options);
        })
        .catch(() => {
          resolve([]);
        });
    });
  };

  const getServerlessFacetValuesOptions = (
    option: AutocompleteOption,
  ): Promise<AutocompleteOption[]> => {
    return new Promise((resolve) => {
      serverlessLabelValues({
        name: option.value,
        date,
      })
        .then((facetValues) => {
          const options: AutocompleteOption[] = facetValues.map(
            ({ aggrVal, groupVal }) => {
              const value = groupVal[option.value];
              return {
                label: value,
                value: value,
                count: aggrVal.function_arn,
                optionType: 'value',
              };
            },
          );
          resolve(options);
        })
        .catch(() => {
          resolve([]);
        });
    });
  };

  const refreshDate = (): void => {
    if (date.startLabel && date.endLabel) {
      const endTimeUnix = convertTimeStringToUnix(date.endLabel);
      const startTimeUnix = convertTimeStringToUnix(date.startLabel);
      if (endTimeUnix && startTimeUnix) {
        setDate({ ...date, endTimeUnix, startTimeUnix });
      }
    } else {
      setDate({ ...date, endTimeUnix: dayjs().unix() });
    }
  };

  const requestByLabelName = (name: string) => {
    return () =>
      serverlessLabelValues({ name, date }).then((result) =>
        result.map(({ aggrVal, groupVal }) => {
          const value = groupVal[name];
          const returnVal = {
            count: aggrVal.function_arn,
            value: value || '',
          };
          return returnVal;
        }),
      );
  };

  const getFacetValueCountsRequest = useRequest(serverlessLabels, true, true);
  const getServerlessMetricsRequest = useRequest(
    getServerlessMetrics,
    true,
    true,
  );

  useEffect(() => {
    if (date) {
      getFacetValueCountsRequest
        .call({ date })
        .then((nextResult) => {
          if (nextResult) {
            setError((prevError) => ({ ...prevError, serverlessLabels: null }));
          }
        })
        .catch(() => {
          setError((prevError) => ({
            ...prevError,
            serverlessLabels: {
              message: infrastructureErrors.serverlessLabels,
            },
          }));
        });
      getServerlessMetricsRequest
        .call(date)
        .then((nextResult) => {
          if (nextResult) {
            setError((prevError) => ({
              ...prevError,
              getServerlessMetrics: null,
            }));
          }
        })
        .catch(() => {
          setError((prevError) => ({
            ...prevError,
            getServerlessMetrics: {
              message: infrastructureErrors.getServerlessMetrics,
            },
          }));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return {
    date,
    getServerlessLabelValuesOptions,
    getFacetValueCountsRequest,
    getServerlessMetricsRequest,
    getServerlessFacetValuesOptions,
    filterByFacets: filter.filterByFacets,
    refreshDate,
    requestByLabelName,
    searchTerms: filterByFacets.searchItems,
    setDate,
    setFilterByFacets,
    filterState,
    error,
  };
};

export default useServerlessState;
