import React, { ReactElement, useEffect, useMemo } from 'react';
import Select, { Props as SelectProps } from 'react-select';

import { AutocompleteOption, AutocompleteMenuListV2 } from '../Autocomplete';

const MultiselectV2 = ({
  className,
  components,
  onChange,
  options,
  optimize,
  placeholder,
  value,
  fallBackOptions,
  ...rest
}: {
  fallBackOptions?: AutocompleteOption[];
  className?: string;
  onChange: (value: string[], options?: AutocompleteOption[]) => void;
  options: AutocompleteOption[];
  optimize?: boolean;
  placeholder?: string;
  value: string[];
} & SelectProps): ReactElement => {
  const labelsByValue: AutocompleteOption[] = useMemo(() => {
    return value.map((valueItem: string) => ({
      value: valueItem,
      label:
        options?.find((option) => option.value === valueItem)?.label ||
        fallBackOptions?.find((option) => option.value === valueItem)?.label ||
        valueItem,
    }));
  }, [value, options, fallBackOptions]);

  const newComponents = useMemo(() => {
    if (optimize) {
      return { MenuList: AutocompleteMenuListV2, ...components };
    }
    return components;
  }, [optimize, components]);
  return (
    <div className={className} data-testid="dropdown-container">
      <Select
        className="autocomplete-container"
        classNamePrefix="autocomplete-container"
        components={newComponents}
        isMulti
        onChange={(e) =>
          onChange(
            e.map((item) => item.value),
            e.map((item) => item),
          )
        }
        options={options}
        placeholder={placeholder}
        value={labelsByValue}
        {...rest}
        styles={{
          input: (provided) => ({
            ...provided,
            color: 'var(--ui-text)',
          }),
        }}
      />
    </div>
  );
};

export default MultiselectV2;
