import { DownloadModal, TableColumn, useModalsContext } from 'components';
import React, { useEffect } from 'react';
import { DateSelection, DownloadType, LogsState } from 'types';
import { useLogsDownloader } from './hooks';

type Props = {
  columns: TableColumn<any>;
  date: DateSelection;
  downloadType: string;
  logsState: LogsState;
};

const LogsDownloadModal = ({
  columns,
  date,
  downloadType,
  logsState,
}: Props) => {
  const modals = useModalsContext();

  const onDone = () => {
    setTimeout(() => {
      modals.pop();
    }, 2000);
  };

  const logsDownloader = useLogsDownloader({ columns, downloadType, onDone });

  useEffect(() => {
    logsDownloader.fetch({ cursor: null, date, logsState });
  }, []);

  return (
    <DownloadModal downloader={logsDownloader} modals={modals} title="logs" />
  );
};

export default LogsDownloadModal;
