import { SelectedFacetValuesByName, TimeSeries } from 'types';
import { parseMetricLabelQuery } from 'utils';

import { TraceCardinalityQueryProps } from './types';
import { builderCardinalityMatcher } from '../MetricsCardinality/utils';

export const sortTraceCardinalityRows = ({
  rows,
  cardinalityQuery,
}: {
  rows: TimeSeries[];
  cardinalityQuery: TraceCardinalityQueryProps;
}): {
  cardinalityRows: Array<any>;
  sortedKeys: string[];
} => {
  const { limit } = cardinalityQuery;
  const sortedData = rows.sort((a, b) => {
    return limit.direction === 'topk'
      ? b['Value'] - a['Value']
      : a['Value'] - b['Value'];
  });

  let sortedKeysSliced = [];
  if (limit.count === 'all') {
    sortedKeysSliced = sortedData;
  } else {
    sortedKeysSliced = sortedData.slice(0, Number(limit.count));
  }
  const sortedKeys = sortedKeysSliced.map((row) => row['GroupVal']['key']);
  return { cardinalityRows: sortedKeysSliced, sortedKeys };
};

export const traceCardinalityMatcher = (
  labels: string[],
  labelIndex?: number,
  type?: 'Lc' | 'Hc',
): string => {
  const index = typeof labelIndex === 'number' ? labelIndex : labels.length - 1;
  const labelMatcher = builderCardinalityMatcher(labels, index);
  return `${type}${labelMatcher}`;
};

export const convertLabelsToSelectedFacetValuesByName = (
  labels: string[],
  labelIndex: number,
  type: 'Lc' | 'Hc',
): SelectedFacetValuesByName => {
  const selectedFacetValuesByName: SelectedFacetValuesByName = {};

  labels.forEach((l, i) => {
    if (i > labelIndex) return;
    const { label, value, operator } = parseMetricLabelQuery(l);
    if (!label || !value) return;

    let facetLabel = label;
    if (type === 'Hc') {
      facetLabel = `$.hc_attributes.['${label}']`;
    }
    if (!selectedFacetValuesByName[facetLabel]) {
      selectedFacetValuesByName[facetLabel] = {};
    }

    selectedFacetValuesByName[facetLabel][value as string] =
      operator === '!=' ? 0 : 1;
  });

  return selectedFacetValuesByName;
};
