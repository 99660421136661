import classnames from 'classnames';
import { Icon, Skeleton } from 'components';
import React, { ReactElement, useEffect, useRef } from 'react';
import { IoIosWarning } from 'react-icons/io';
import FacetPickerValuesItem from './FacetPickerValuesItem';
import useFacetPickerValuesState from './hooks/useFacetPickerValuesState';

type FacetPickerValuesPresentationalProps = ReturnType<
  typeof useFacetPickerValuesState
> & {
  ignoreCount?: boolean;
};

// TODO(Baibhav): Consolidate with components/SearchInput
export const SearchInput = ({
  containerClassName,
  onChange,
  onKeyDown,
  placeholder,
  value,
  inputRef,
  ...props
}: {
  containerClassName?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
  value: string;
  inputRef?: React.MutableRefObject<HTMLInputElement>;
}) => {
  return (
    <div
      data-testid={props['data-testid'] ?? 'search-input'}
      className={classnames(
        'relative flex h-6 items-center',
        containerClassName,
      )}
    >
      <span className="absolute left-2 text-text-tertiary">
        <Icon icon="search" size="sm" />
      </span>
      <input
        type="text"
        className="placeholder:text-tertiary size-full rounded border border-form-border bg-background pl-8 pr-4 text-xs hover:border-form-border-hover focus:border-interaction-primary focus:bg-interaction-secondary focus:outline-none"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        ref={inputRef}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

type Props = {
  hoveredAccordionRef?: React.MutableRefObject<HTMLDivElement>;
  facetValuesSearchInputRef?: React.MutableRefObject<HTMLInputElement>;
  facetValuesScrollDivRef?: React.MutableRefObject<HTMLDivElement>;
  setHovered: (isHovered: boolean) => void;
  onWheel: (event: React.WheelEvent<HTMLDivElement>) => void;
  clearFacet: () => void;
  errorMessage?: string;
};

export const FacetPickerValues = ({
  disableSearch,
  setFacetValueSearch,
  renderedName,
  facetValueSearch,
  isLoading,
  ignoreCount,
  searchedFacetValuesCount,
  renderPlaceholderText,
  searchedFacetValues,
  countsByFacetValue,
  isRadio,
  renderValue,
  selectedFacetValues,
  handlersByValue,
  name,
  facetValuesSearchInputRef,
  facetValuesScrollDivRef,
  setHovered,
  onWheel,
  clearFacet,
  allValues,
  errorMessage,
}: FacetPickerValuesPresentationalProps & Props): ReactElement => {
  const shouldShowLoader = isLoading && searchedFacetValuesCount === 0;
  const shouldShowSearchBoxInThisRender =
    !disableSearch && allValues.length > 6;
  const didShowSearchBoxInAPreviousRender = useRef(false);

  useEffect(() => {
    if (shouldShowSearchBoxInThisRender) {
      didShowSearchBoxInAPreviousRender.current = true;
    }
  }, [shouldShowSearchBoxInThisRender]);

  const shouldShowSearchBox =
    didShowSearchBoxInAPreviousRender.current ||
    shouldShowSearchBoxInThisRender;

  if (shouldShowLoader) {
    return (
      <div className="mx-2 mb-2">
        <Skeleton count={3} />
      </div>
    );
  }
  const shouldShowPlaceholderText = searchedFacetValuesCount === 0;

  return (
    <div>
      {shouldShowSearchBox && (
        <SearchInput
          containerClassName="ml-2 mr-5 mb-2"
          onChange={(e) => {
            setFacetValueSearch(e.target.value);
          }}
          placeholder={`Search${
            typeof renderedName === 'string' ? ` ${renderedName}` : ''
          }`}
          value={facetValueSearch}
          inputRef={facetValuesSearchInputRef}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              setHovered(false);
            }
          }}
        />
      )}
      {(() => {
        if (shouldShowPlaceholderText) {
          return (
            <div className="facet-picker__values__placeholder ml-4 flex min-h-[50px] italic">
              {errorMessage ? (
                <div className="flex gap-[4px]">
                  <IoIosWarning size={20} />
                  <div className="text-red-500">
                    {errorMessage && errorMessage}
                  </div>
                </div>
              ) : renderPlaceholderText &&
                typeof renderPlaceholderText === 'function' ? (
                renderPlaceholderText(name)
              ) : (
                `No facet values for ${renderedName}`
              )}
            </div>
          );
        }
        return (
          <div
            className="facet-picker__values ml-2 max-h-[284px] overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-track-background scrollbar-thumb-border"
            onWheel={(event) => {
              event.stopPropagation();
              onWheel(event);
            }}
            ref={facetValuesScrollDivRef}
          >
            {searchedFacetValues
              .filter((facetValue) => facetValue)
              .map((facetValue) => {
                return (
                  <FacetPickerValuesItem
                    key={facetValue.value}
                    count={countsByFacetValue[facetValue.value]}
                    isRadio={isRadio}
                    renderValue={renderValue}
                    selectedFacetValues={selectedFacetValues}
                    value={facetValue.value}
                    {...handlersByValue(facetValue.value)}
                    clearFacet={clearFacet}
                    allValues={allValues}
                    ignoreCount={ignoreCount}
                  />
                );
              })}
          </div>
        );
      })()}
    </div>
  );
};

export default FacetPickerValues;
