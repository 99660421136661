import { promqlVariableReplacer } from './grafana-variable';
import { DashboardTemplateValueProps } from '../types';
import { DashboardTemplateProps } from 'types/Dashboard';

/**
 * @param {string} title
 */
export const transformTitle = ({
  title,
  templateValues,
  templating,
}: {
  title: string;
  templateValues: DashboardTemplateValueProps;
  templating: DashboardTemplateProps[];
}): string => {
  const templateKeys = Object.keys(templateValues);
  templateKeys.forEach((name) => {
    const value = templateValues[name];
    const replaceValue = typeof value === 'string' ? value : value?.join('|');
    title = promqlVariableReplacer(title, name, replaceValue);

    // check if template is include ${templateName:text}
    const isTitleIncludeText = title.includes(`$\{${name}:text}`);
    if (isTitleIncludeText) {
      const template = templating.find((t) => t.name === name);
      if (template) {
        const templateOption = template.options.find(
          (option) => option.value === value,
        );
        if (templateOption) {
          title = title.replace(`$\{${name}:text}`, templateOption.text);
        }
      }
    }
  });

  return title;
};
