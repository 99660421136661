import { fetchJson } from 'requests';

export type ConfigJson = {
  rum: {
    enabled: boolean;
  };
};

const getConfig = (): Promise<ConfigJson> => {
  return fetchJson('/conf/config.json');
};
export default getConfig;
