import { SelectedFacetValuesByName } from 'types';
import getIsDeselecting from './getIsDeselecting';

export const filterListWithSelectedSidebar = (
  array: Array<any>,
  conditions: SelectedFacetValuesByName,
) => {
  const groupedConditions: {
    [key: string]: Array<{ facetValue: string; facet: string; type: string }>;
  } = {};

  Object.keys(conditions).forEach((source) => {
    const selectedFacetValues = conditions[source];
    const sourceLowerCase = source.toLowerCase();
    Object.keys(selectedFacetValues).forEach((facetValue) => {
      if (!groupedConditions[sourceLowerCase]) {
        groupedConditions[sourceLowerCase] = [];
      }
      groupedConditions[sourceLowerCase].push({
        facetValue: facetValue,
        facet: sourceLowerCase,
        type: getIsDeselecting(selectedFacetValues) ? 'neq' : 'eq',
      });
    });
  });

  return array.filter((item) => {
    return Object.entries(groupedConditions).every(([facet, conditions]) => {
      // Handle 'eq' and 'neq' conditions separately
      const eqConditions = conditions.filter((c) => c.type === 'eq');
      const neqConditions = conditions.filter((c) => c.type === 'neq');

      if (!item.hasOwnProperty(facet)) {
        return false;
      }

      const value = item[facet];
      const isArrayValue = Array.isArray(value);

      // Check 'eq' conditions
      const eqMatch =
        eqConditions.length === 0 ||
        eqConditions.some((condition) => {
          return isArrayValue
            ? value.includes(condition.facetValue)
            : value === condition.facetValue;
        });

      // Check 'neq' conditions
      const neqMatch =
        neqConditions.length === 0 ||
        neqConditions.every((condition) => {
          return isArrayValue
            ? !value.includes(condition.facetValue)
            : value !== condition.facetValue;
        });

      return eqMatch && neqMatch;
    });
  });
};

export const mergeTwoSidebarCountsValues = (
  obj1: { [key: string]: { [key: string]: number } },
  obj2: { [key: string]: { [key: string]: number } },
): { [key: string]: { [key: string]: number } } => {
  const mergedObj: { [key: string]: { [key: string]: number } } = {};
  Object.keys(obj1).forEach((key) => {
    const obj1Key = obj1[key];
    const obj2Key = obj2[key];
    mergedObj[key] = {};
    Object.keys(obj1Key).forEach((subKey) => {
      if (obj2Key[subKey] === undefined) {
        mergedObj[key][subKey] = undefined;
      } else {
        mergedObj[key][subKey] = obj2Key[subKey];
      }
    });
  });
  return mergedObj;
};
