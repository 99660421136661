import classnames from 'classnames';
import {
  Loader,
  Table,
  OverlayMessage,
  OverlayMessageProps,
  useColumnsState,
  useTableSort,
} from 'components';
import React, { useMemo } from 'react';
import { RumEventType } from './types';
import { RumActionWithErrorsColumnKey } from './contants';
import { rumActionWithErrorsTableColumns } from './rumEventTableColumns';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import useSortState from 'hooks/useSortState';
import useRumActionsWithErrorsRequest from './useRumActionsWithErrorsRequest';
import { isSortingDisabledForRumColumn } from './utils';

type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
  className?: string;
};

type Row = {
  [key in RumActionWithErrorsColumnKey]: string | number;
};

const RumActionsWithErrorsTable = ({
  rumPageState,
  className,
}: Props): JSX.Element => {
  const { rumState } = rumPageState;
  const {
    applicationFilter,
    dateState,
    facetRegexState,
    idSearch,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
  } = rumState;

  const [date] = dateState;
  const rumActionsWithErrorsTableSortState = useSortState({
    urlStateKey: 'rumActionsWithErrorsTableSort',
    initialState: {
      key: RumActionWithErrorsColumnKey.actionErrorCount,
      isAsc: false,
    },
  });

  const rumEventsRequest = useRumActionsWithErrorsRequest({
    applicationFilter,
    date,
    eventType: RumEventType.ACTION,
    facetRegexState,
    idSearch,
    rumTableSortState: rumActionsWithErrorsTableSortState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
  });

  const actionWithErrorColumns = rumActionWithErrorsTableColumns();
  const actionsWithErrorColumnsState = useColumnsState({
    columns: actionWithErrorColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        [RumActionWithErrorsColumnKey.actionName]: 1,
        [RumActionWithErrorsColumnKey.sessionId]: 1,
        [RumActionWithErrorsColumnKey.actionErrorCount]: 1,
        [RumActionWithErrorsColumnKey.viewName]: 1,
      },
    },
    key: 'rum-action-table',
  });

  const columnsState = actionsWithErrorColumnsState;

  const rows = useMemo<Row[]>(
    () => rumEventsRequest.result?.data || [],
    [rumEventsRequest.result],
  );
  const columns = actionWithErrorColumns;
  const tableSort = useTableSort({
    columns: columns || [],
    rows: rows || [],
  });

  const overlayMessageProps: OverlayMessageProps | null = useMemo(() => {
    if (!rumEventsRequest.error) return null;
    return {
      isActive: !!rumEventsRequest.error,
      iconName: 'warning',
      message: (
        <>
          Failed to fetch Actions. Please double check your query and try again.
        </>
      ),
    };
  }, [rumEventsRequest.error]);

  return (
    <>
      <div className={classnames({ [className]: className })}>
        <Loader isLoading={rumEventsRequest.isLoading}>
          {!rumEventsRequest.isLoading &&
          rumEventsRequest.result &&
          !rumEventsRequest.result.data.length ? (
            <div className="placeholder">{`No data`}</div>
          ) : (
            <OverlayMessage {...overlayMessageProps}>
              <Table
                className="table--padded table--bordered table--bordered-cells"
                columns={columnsState.renderedColumns}
                isFullWidth
                isResizingEnabled
                isSortingEnabled
                isSortingDisabledForColumn={isSortingDisabledForRumColumn}
                isStickyHeaderEnabled
                onScrollEnd={rumEventsRequest.onScrollEnd}
                externalTableSort={tableSort}
                rows={tableSort.sortedRows}
                tableKey="rum-actions-with-errors-table"
              />
            </OverlayMessage>
          )}
        </Loader>
      </div>
    </>
  );
};

export default RumActionsWithErrorsTable;
