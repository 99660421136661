import { useToaster } from 'components';
import { useLogsFacetExplorerPageStateContext } from 'context';
import { useRequest } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { getLogsSources, getLogsFavoriteFacets } from 'requests';
import { DateSelection } from 'types';
import {
  addLogsFavoriteFacet,
  getLogsFacetNames,
  removeLogsFavoriteFacet,
} from './requests';
import { FavoriteFacetExtend } from './types';
import { logsErrors } from 'utils/error/logsErrors';

const MAX_TIME_RANGE = 60 * 60 * 24; // 1 day

const useLogsFacetExplorerState = () => {
  const [logsFacetError, setLogsFacetError] = useState({
    getLogsFacetNames: null,
    getLogsFavoriteFacets: null,
    addLogsFavoriteFacet: null,
    removeLogsFavoriteFacet: null,
  });

  const { addToast } = useToaster();
  const {
    dateState,
    dependenciesForWriteStateToUrl,
    selectedSourceState,
    selectedFacetState,
    writeStateToUrl,
  } = useLogsFacetExplorerPageStateContext();
  const [date, setDate] = dateState;
  const [selectedSource, setSelectedSource] = selectedSourceState;
  const [selectedFacet, setSelectedFacet] = selectedFacetState;

  const onDateChange = (nextDate: DateSelection) => {
    const { startTimeUnix, endTimeUnix } = nextDate;
    if (endTimeUnix - startTimeUnix > MAX_TIME_RANGE) {
      addToast({
        text: 'Please select a time range less than 1 day',
        status: 'error',
      });
      return;
    }
    setDate(nextDate);
  };

  const getLogsSourcesRequest = useRequest(
    () =>
      getLogsSources().then((sources) => {
        const sourcesWithAll = [
          { label: 'All sources', value: '*' },
          ...sources.map((s) => ({ label: s, value: s })),
        ];
        return sourcesWithAll;
      }),
    true,
    true,
  );

  const getFacetNamesBySourceRequest = useRequest(
    getLogsFacetNames,
    true,
    true,
  );
  const getLogsFavoriteFacetRequest = useRequest(
    getLogsFavoriteFacets,
    true,
    true,
  );
  const makeFavoriteFacetRequest = useRequest(addLogsFavoriteFacet);
  const removeLogsFavoriteFacetRequest = useRequest(removeLogsFavoriteFacet);

  const userActions = useRef<{ initialScrolled: boolean }>({
    initialScrolled: false,
  });

  const onSourceChange = (source: string) => {
    setSelectedSource(source);
    setSelectedFacet(undefined);
    getFacetNamesBySourceRequest
      .call({
        source: source === '*' ? '' : source,
        limit: 1000,
      })
      .then((nextResult) => {
        if (nextResult) {
          setLogsFacetError((prevError) => ({
            ...prevError,
            getLogsFacetNames: null,
          }));
        }
      })
      .catch(() => {
        setLogsFacetError((prevError) => ({
          ...prevError,
          getLogsFacetNames: {
            message: logsErrors.getLogsFacetNames,
          },
        }));
      });
    getLogsFavoriteFacetRequest
      .call({
        group: source === '*' ? '' : source,
        contains: '',
      })
      .then((nextResult) => {
        if (nextResult) {
          setLogsFacetError((prevError) => ({
            ...prevError,
            getLogsFavoriteFacet: null,
          }));
        }
      })
      .catch(() => {
        setLogsFacetError((prevError) => ({
          ...prevError,
          getLogsFavoriteFacet: {
            message: logsErrors.getLogsFavoriteFacet,
          },
        }));
      });
  };

  const onRemoveFavorite = (facet: FavoriteFacetExtend) => {
    removeLogsFavoriteFacetRequest
      .call({
        displayName: facet.displayName,
        facetGroup: facet.group,
      })
      .then((res) => {
        getLogsFavoriteFacetRequest
          .call({
            group: selectedSource,
            contains: '',
          })
          .then((nextResult) => {
            if (nextResult) {
              setLogsFacetError((prevError) => ({
                ...prevError,
                getLogsFavoriteFacet: null,
              }));
            }
          })
          .catch(() => {
            setLogsFacetError((prevError) => ({
              ...prevError,
              getLogsFavoriteFacet: {
                message: logsErrors.getLogsFavoriteFacet,
              },
            }));
          });
        addToast({
          text: `Facet "${facet.name}" removed as favorite.`,
          status: 'success',
        });
      });
  };

  useEffect(() => {
    if (selectedSource) {
      getFacetNamesBySourceRequest
        .call({
          source: selectedSource === '*' ? '' : selectedSource,
          limit: 1000,
        })
        .then((nextResult) => {
          if (nextResult) {
            setLogsFacetError((prevError) => ({
              ...prevError,
              getLogsFacetNames: null,
            }));
          }
        })
        .catch(() => {
          setLogsFacetError((prevError) => ({
            ...prevError,
            getLogsFacetNames: {
              message: logsErrors.getLogsFacetNames,
            },
          }));
        });
      getLogsFavoriteFacetRequest
        .call({
          group: selectedSource === '*' ? '' : selectedSource,
          contains: '',
        })
        .then((nextResult) => {
          if (nextResult) {
            setLogsFacetError((prevError) => ({
              ...prevError,
              getLogsFavoriteFacet: null,
            }));
          }
        })
        .catch(() => {
          setLogsFacetError((prevError) => ({
            ...prevError,
            getLogsFavoriteFacet: {
              message: logsErrors.getLogsFavoriteFacet,
            },
          }));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesForWriteStateToUrl);

  return {
    date,
    getFacetNamesBySourceRequest,
    getLogsFavoriteFacetRequest,
    getLogsSourcesRequest,
    makeFavoriteFacetRequest,
    onDateChange,
    onRemoveFavorite,
    onSourceChange,
    selectedFacet,
    selectedSource,
    setSelectedFacet,
    userActions,
    logsFacetError,
  };
};

export default useLogsFacetExplorerState;
