import usePopupOverlayMessage from 'hooks/usePopupOverlayMessage';
import React, { useEffect } from 'react';
import { formatDurationNs, isNullOrUndefined } from 'utils';
import OverlayMessage, { OverlayMessageProps } from './OverlayMessage';

type Values = { [key: string]: number };

const valueitems = [
  {
    label: 'Requests',
    render: (values: Values) =>
      isNullOrUndefined(values.requests)
        ? '-'
        : `${values.requests?.toFixed(2)}/s`,
  },
  {
    label: 'Max Latency',
    render: (values: Values) =>
      isNullOrUndefined(values.latency)
        ? '-'
        : formatDurationNs(values.latency * 1000000, 1, 2),
  },
  {
    label: 'Errors',
    render: (values: Values) =>
      `${isNullOrUndefined(values.errors) ? '0' : values.errors.toFixed(2)}/s`,
  },
];

type Props = {
  isLoading: boolean;
  style?: Record<string, string>;
  title: string;
  values: {
    errors: number;
    latency: number;
    requests: number;
  };
};

const ApmServiceMapTooltip = ({ isLoading, style, title, values }: Props) => {
  const overlayMessage = usePopupOverlayMessage({
    data: values,
  });

  const overlayMessageProps: OverlayMessageProps =
    !isLoading && Object.keys(values).length === 0
      ? {
          isActive: true,
          iconName: 'warning',
          message: <>{overlayMessage || 'Error.'}</>,
          minHeight: 60,
          maxHeight: 60,
        }
      : {
          isActive: false,
          minHeight: 60,
          maxHeight: 60,
        };

  return (
    <div
      className="traces__service-map__node__tooltip"
      data-testid="tooltip"
      style={style || {}}
    >
      <div
        className="traces__service-map__node__tooltip__name"
        data-testid="tooltip-name"
      >
        {title}
      </div>

      <OverlayMessage {...overlayMessageProps}>
        <div className="traces__service-map__node__tooltip__values">
          {valueitems.map((valueItem, i) => (
            <div
              className="traces__service-map__node__tooltip__values__item"
              data-testid="tooltip-item"
              key={i}
            >
              <div
                className="traces__service-map__node__tooltip__values__item__label"
                data-testid="tooltip-item-label"
              >
                {valueItem.label}
              </div>
              <div
                className="traces__service-map__node__tooltip__values__item__value"
                data-testid="tooltip-item-value"
              >
                {isLoading ? '-' : valueItem.render(values)}
              </div>
            </div>
          ))}
        </div>
      </OverlayMessage>
    </div>
  );
};

export default ApmServiceMapTooltip;
