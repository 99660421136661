import { FacetValue, TimeSeries } from 'types';

type Args = {
  anchorTs?: number;
  stepMs: number;
  timeseries: TimeSeries[];
};

const formatSeriesToLogCountsWithStepSize = ({
  anchorTs,
  stepMs,
  timeseries,
}: Args): FacetValue[] => {
  const getOffset = ({ anchorTs, stepMs, timeseries }: Args): number => {
    const point =
      timeseries.length && timeseries[0].points.length
        ? timeseries[0].points[0]
        : null;
    if (point) {
      return Math.abs(point.ts - anchorTs) % stepMs;
    }

    return 0;
  };

  const result: FacetValue[] = [];

  const offset = anchorTs ? getOffset({ anchorTs, stepMs, timeseries }) : 0;

  timeseries.forEach((timeseriesItem) => {
    const { points, tags } = timeseriesItem;
    const tagKeys = Object.keys(tags);
    const facetName = tagKeys.length ? tagKeys[0] : '';
    const facetValue = tagKeys.length ? tags[tagKeys[0]] : '';

    points.forEach((point) => {
      result.push({
        bucketStart: point.ts + offset,
        count: point.value,
        facetName,
        facetValue,
      });
    });
  });

  return result;
};

export default formatSeriesToLogCountsWithStepSize;
