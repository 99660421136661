import { DateFormatter } from 'components';
import { startCase } from 'lodash';
import React from 'react';
import { dateTimeFormatWithMilliseconds } from 'kfuse-constants';
import { formatDurationNs, formatFileSize, isNullOrUndefined } from 'utils';
import { RumViewEvent } from '../types';
import isDurationFacet from '../utils/isDurationFacet';
import isSizeFacet from '../utils/isSizeFacet';
import sidebarFacets from '../requests/sidebarFacets';

const viewColumns = sidebarFacets
  .filter((facet) => facet.view_facet && facet.field !== 'session.has_replay')
  .map((facet) => {
    return {
      key: facet.field,
      label: facet.name,
    };
  })
  .sort((a, b) => a.label.localeCompare(b.label));

const RumViewStringColumnKey = viewColumns.reduce(
  (acc, column) => {
    acc[column.key] = column.label as string;
    return acc;
  },
  {} as Record<string, string>,
);

export const RumViewColumnKey = {
  date: 'time',
  'application.id': 'Application ID',
  ...RumViewStringColumnKey,
};

type RenderCellPropsOfView = {
  row: RumViewEvent;
  value: any;
};

export const rumDynamicViewTableColumns = () => [
  {
    key: RumViewColumnKey.date,
    label: 'Date',
    renderCell: ({ value }: RenderCellPropsOfView) => (
      <DateFormatter
        formatString={dateTimeFormatWithMilliseconds}
        unixTimestamp={value}
      />
    ),
  },
  ...Object.keys(RumViewStringColumnKey).map((key) => {
    return {
      key,
      label: RumViewStringColumnKey[key as keyof typeof RumViewStringColumnKey],
      value: ({ row }: RenderCellPropsOfView) => {
        const isDurationColumn = isDurationFacet(key);
        const value = row.data[key as keyof typeof row.data];
        if (isDurationColumn) {
          const convertedValue = Number(value);
          return isNaN(convertedValue) || isNullOrUndefined(convertedValue)
            ? '-'
            : formatDurationNs(convertedValue, 1, 2);
        }
        const isSizeColumn = isSizeFacet(key);
        if (isSizeColumn) {
          return formatFileSize(value as string);
        }
        return value;
      },
    };
  }),
];
