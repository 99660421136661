import React, { useMemo } from 'react';

import { Box } from 'components/layouts/Box';
import { Table, TableRow } from 'components';
import KubernetesRightSideBar from '../KubernetesRightSideBar';
import { useDateState } from 'hooks';
import { EntityTypes } from '../types';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellTimestamp,
  KubeTableCell,
  KubeTableEntityTags,
} from './Components';
import { generatePath, useParams } from 'react-router-dom';
import KubernetesTableGroup from './KubernetesTableGroup';
import {
  useEntitiesData,
  useEntitiesGroupData,
  useMergedGroupByFilter,
} from './useEntitiesData';

const renderEmptyCell = () => '';

function KubernetesTableForDeploymentRow({ row }) {
  const { date, entitiesType, navigate } = useKubernetesController();

  const facets = useMergedGroupByFilter(row.groupBys);
  const [rows] = useEntitiesData({
    entitiesType,
    kubesSort: null,
    facets,
    page: 0,
    limit: 50,
    date,
  });

  const setActiveKube = (row) => {
    navigate(({ location, params, pathTemplate }) => {
      return {
        ...location,

        pathname: generatePath(pathTemplate, {
          ...params,

          activeKube: row.deployment.metadata.uid,
          tab: 'yaml',
        }),
      };
    });
  };

  const activeKubeParam = useParams()['activeKube'];
  const activeKube = (
    typeof activeKubeParam === 'string' && rows ? rows : []
  ).find((row) => {
    return row.deployment.metadata.uid === activeKubeParam;
  });

  return (
    <Box width="full" height="full">
      {activeKube && <KubernetesRightSideBar activeKube={activeKube} />}
      <Table
        className="-my-px"
        renderHeader={false}
        rows={rows ?? []}
        renderRow={(props) => (
          <TableRow
            {...props}
            className="border-y"
            onRowClickHandler={(row) => () => setActiveKube(row)}
          />
        )}
        columns={[
          { key: 'header', label: '' },
          {
            key: 'kube_deployment',
            label: 'Deployment',
            renderCell: (prop) => {
              const deploymentName = prop?.row.deployment?.metadata?.name;
              return (
                <KubeTableCell {...prop} tooltipText={deploymentName}>
                  {deploymentName}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'kube_cluster_name',
            label: 'Cluster',
            renderCell(prop) {
              return <KubeCellCluster {...prop} />;
            },
          },
          {
            key: 'metadata.namespace',
            label: 'Namespace',
            renderCell(prop) {
              return <KubeCellNamespace {...prop} />;
            },
          },
          {
            key: 'deploymentStrategy',
            label: 'Strategy',
            renderCell(prop) {
              const deploymentStrategy =
                prop?.row.deployment?.deploymentStrategy;
              return (
                <KubeTableCell
                  {...prop}
                  className="kubernetes__table__cell__entity-name"
                  tooltipText={deploymentStrategy}
                >
                  {deploymentStrategy}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'metadata.creationTimestamp',
            label: 'Age',
            renderCell(prop) {
              const age = prop?.row?.deployment?.metadata;
              const timestamp = age?.creationTimestamp || '';
              return <KubeCellTimestamp {...prop} timestamp={timestamp} />;
            },
          },
          {
            key: 'replicas',
            label: 'Current',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="right">
                  {prop.row.deployment.replicas}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'replicasDesired',
            label: 'Desired',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="right">
                  {prop.row.deployment.replicasDesired}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'updatedReplicas',
            label: 'Up to Date',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="right">
                  {prop.row.deployment.updatedReplicas}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'availableReplicas',
            label: 'Available',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="right">
                  {prop.row.deployment.availableReplicas}
                </KubeTableCell>
              );
            },
          },
        ]}
      />
    </Box>
  );
}

export default function KubernetesTableForDeploymentGroup() {
  const [date] = useDateState();
  const { entitiesType, facets, groupBySearchTerms } =
    useKubernetesController();

  const [rows] = useEntitiesGroupData({
    entitiesType,
    date,
    facets,
    groupBySearchTerms,
  });

  return (
    <KubernetesTableGroup
      rows={rows ?? []}
      columns={[
        {
          key: 'headerkube_deployment',
          label: 'Deployment Group',
          renderCell: (prop) => <KubeTableEntityTags {...prop} />,
        },
        {
          key: 'headerkube_cluster_name',
          label: 'Cluster',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerkube_namespace',
          label: 'Namespace',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerstrategy',
          label: 'Strategy',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerage',
          label: 'Age',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headercurrent',
          label: 'Current',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerdesired',
          label: 'Desired',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headeruptoDate',
          label: 'Up to Date',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headeravailable',
          label: 'Available',
          renderCell: renderEmptyCell,
        },
      ]}
      renderExpandedRow={(props) => (
        <KubernetesTableForDeploymentRow {...props} />
      )}
    />
  );
}
