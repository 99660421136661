import { Button, AutocompleteV2, useToaster } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { MdClose as CloseIcon } from 'react-icons/md';

import AddPermissionUsers from './AddPermissionUsers';
import { FolderPermissionCategory } from '../FolderManagement/types';
import {
  updateFolderPermissionById,
  updateFolderPermissionRole,
} from '../FolderManagement/requests';
import {
  PermissionOptionsGroup,
  PermissionOptionsFolder,
} from '../GroupManagement/utils';
import {
  addUserToGroup,
  getGrafanaGroupList,
} from '../GroupManagement/requests';
import { getUserList } from '../UserManagement/requests';
import {
  getFilteredUserListOptions,
  getFilteredGroupListOptions,
  PermissionRolesOptions,
  getPermissionCategoryOptions,
} from './utils';

const AddPermission = ({
  groupId,
  folderUid,
  type,
  onPermissionAdded,
  onClose,
}: {
  groupId?: string;
  folderUid?: string;
  type: 'folder' | 'group';
  onPermissionAdded: () => void;
  onClose: () => void;
}): ReactElement => {
  const { addToast } = useToaster();

  const [memberDetails, setMemberDetails] = useState<{
    id: number | string;
    permission: string;
    permissionCategory: FolderPermissionCategory;
  }>({
    id: '',
    permission: type === 'folder' ? 'View' : 'Member',
    permissionCategory: FolderPermissionCategory.USER,
  });

  const addMemberToGroupRequest = useRequest(addUserToGroup);
  const addMemberToFolderRequest = useRequest(updateFolderPermissionById);
  const addMemberToRoleRequest = useRequest(updateFolderPermissionRole);

  const userListRequest = useRequest(() =>
    getUserList().then((userList) =>
      getFilteredUserListOptions(userList, type),
    ),
  );
  const groupListRequest = useRequest(() =>
    getGrafanaGroupList().then(getFilteredGroupListOptions),
  );

  const onPermissionsAddedSuccess = (toastText: string) => (res) => {
    if (res?.message) {
      addToast({ text: toastText, status: 'success' });
    }
    onPermissionAdded();
  };

  const saveMemberToGroup = () => {
    const { id } = memberDetails;
    if (!groupId) return;
    addMemberToGroupRequest
      .call({
        groupId,
        userId: id as string,
        permission: memberDetails.permission,
        method: 'POST',
      })
      .then(onPermissionsAddedSuccess('User added to group'));
  };

  const saveMemberToFolder = () => {
    const { id, permission } = memberDetails;
    if (!folderUid) return;
    addMemberToFolderRequest
      .call({ folderUid, id, permission, type: 'users' })
      .then(onPermissionsAddedSuccess('User added to folder'));
  };

  const saveGroupToFolder = () => {
    const { id, permission } = memberDetails;
    if (!folderUid) return;
    addMemberToFolderRequest
      .call({ folderUid, id, permission, type: 'teams' })
      .then(onPermissionsAddedSuccess('Group added to folder'));
  };

  const saveRoleToFolder = () => {
    const { id, permission } = memberDetails;
    if (!folderUid) return;
    addMemberToRoleRequest
      .call({ folderUid, role: id as string, permission })
      .then(onPermissionsAddedSuccess('Role added to folder'));
  };

  const onPermissionCategoryChange = (val: FolderPermissionCategory) => {
    setMemberDetails((prev) => ({ ...prev, id: '', permissionCategory: val }));
    if (val === FolderPermissionCategory.USER) {
      userListRequest.call();
    } else if (val === FolderPermissionCategory.TEAM) {
      groupListRequest.call();
    }
  };

  const userOrGroupList = useMemo(() => {
    if (memberDetails.permissionCategory === FolderPermissionCategory.USER) {
      return userListRequest.result;
    } else if (
      memberDetails.permissionCategory === FolderPermissionCategory.TEAM
    ) {
      return groupListRequest.result;
    } else {
      return PermissionRolesOptions;
    }
  }, [
    memberDetails.permissionCategory,
    groupListRequest.result,
    userListRequest.result,
  ]);

  const onSave = () => {
    const { permissionCategory } = memberDetails;
    if (
      permissionCategory === FolderPermissionCategory.USER &&
      type === 'group'
    ) {
      if (!memberDetails.id) {
        addToast({ text: 'Please select a user', status: 'error' });
        return;
      }
      saveMemberToGroup();
    } else if (
      permissionCategory === FolderPermissionCategory.USER &&
      type === 'folder'
    ) {
      if (!memberDetails.id) {
        addToast({ text: 'Please select a user', status: 'error' });
        return;
      }
      saveMemberToFolder();
    } else if (
      permissionCategory === FolderPermissionCategory.TEAM &&
      type === 'folder'
    ) {
      if (!memberDetails.id) {
        addToast({ text: 'Please select a group', status: 'error' });
        return;
      }
      saveGroupToFolder();
    } else if (
      permissionCategory === FolderPermissionCategory.ROLE &&
      type === 'folder'
    ) {
      if (!memberDetails.id) {
        addToast({ text: 'Please select a role', status: 'error' });
        return;
      }
      saveRoleToFolder();
    }
  };

  useEffect(() => {
    if (userListRequest.result) return;
    userListRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const placeholder = useMemo(() => {
    if (memberDetails.permissionCategory === FolderPermissionCategory.USER) {
      return 'Search user...';
    } else if (
      memberDetails.permissionCategory === FolderPermissionCategory.TEAM
    ) {
      return 'Search group...';
    } else {
      return 'Search role...';
    }
  }, [memberDetails.permissionCategory]);

  const isLoading =
    addMemberToGroupRequest.isLoading ||
    addMemberToFolderRequest.isLoading ||
    addMemberToRoleRequest.isLoading;
  return (
    <div className="admin__row-muted mb-4 flex justify-between rounded-md px-2 py-4">
      {isLoading ? (
        <div className="flex w-full items-center justify-center">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <div className="flex items-center gap-2">
            <AutocompleteV2
              className="max-h-[32px] w-[100px]"
              options={getPermissionCategoryOptions(type)}
              onChange={onPermissionCategoryChange}
              value={memberDetails.permissionCategory}
              isDisabled={type === 'group'}
              components={type === 'group' ? { DropdownIndicator: null } : {}}
            />
            <AddPermissionUsers
              isLoading={
                userListRequest.isLoading || groupListRequest.isLoading
              }
              placeholder={placeholder}
              onChange={(val: string) =>
                setMemberDetails((prev) => ({ ...prev, id: val }))
              }
              options={userOrGroupList}
              value={memberDetails.id}
            />
            <AutocompleteV2
              className="max-h-[32px] min-w-[160px]"
              options={
                type === 'folder'
                  ? PermissionOptionsFolder
                  : PermissionOptionsGroup
              }
              onChange={(val: string) =>
                setMemberDetails((prev) => ({ ...prev, permission: val }))
              }
              value={memberDetails.permission}
            />
            <Button
              className="h-8"
              variant="default"
              size="sm"
              onClick={onSave}
            >
              Save
            </Button>
          </div>
          <div>
            <Button variant="ghost-destructive" size="icon" onClick={onClose}>
              <CloseIcon size={16} />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default AddPermission;
