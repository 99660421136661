import { MAX_SPANS_TO_RENDER } from 'components/TraceSidebar/constants';
import { Span } from 'types';
import { getNiceScale } from 'utils';
import { RelatedSpansBySpanId, SpanBitMap, SpanRow, SpanRows } from './types';

const sortByStartTimeNs = (spanBitMap: SpanBitMap) => (a: string, b: string) =>
  spanBitMap[a].startTimeNs - spanBitMap[b].startTimeNs;

type CanAddSpanToRowArgs = {
  minPresentationalSpanDuration: number;
  row: SpanRow;
  spanBitMap: SpanBitMap;
  spanId: string;
};

const canAddSpanToRow = ({
  minPresentationalSpanDuration,
  row,
  spanBitMap,
  spanId,
}: CanAddSpanToRowArgs): boolean => {
  if (!row.length) {
    return true;
  }

  const { startTimeNs } = spanBitMap[spanId];
  const prevSpanId = row[row.length - 1];
  const prevSpan = spanBitMap[prevSpanId];
  return (
    startTimeNs >
    Math.max(
      prevSpan.endTimeNs,
      prevSpan.startTimeNs + minPresentationalSpanDuration,
    )
  );
};

type AddChildToSpanRowArgs = {
  minPresentationalSpanDuration: number;
  result: SpanRows;
  spanBitMap: SpanBitMap;
  spanId: string;
};

const addChildToSpanRow = ({
  minPresentationalSpanDuration,
  result,
  spanBitMap,
  spanId,
}: AddChildToSpanRowArgs) => {
  const lastRow = result[result.length - 1];
  if (
    canAddSpanToRow({
      row: lastRow,
      minPresentationalSpanDuration,
      spanBitMap,
      spanId,
    })
  ) {
    lastRow.push(spanId);
  } else {
    result.push([spanId]);
  }
};

const findMaxEndTime = ({
  childrenSpanIds,
  childrenBySpanId,
  maxEndTimeNs,
  spanBitMap,
}) => {
  let nextChildrenSpanIds: string[] = [];
  childrenSpanIds.forEach((spanId) => {
    const { endTimeNs } = spanBitMap[spanId];
    if (endTimeNs > maxEndTimeNs) {
      maxEndTimeNs = endTimeNs;
    }

    nextChildrenSpanIds = [
      ...nextChildrenSpanIds,
      ...(childrenSpanIds[spanId] || []),
    ];
  });

  if (nextChildrenSpanIds.length) {
    return findMaxEndTime({
      childrenSpanIds: nextChildrenSpanIds,
      childrenBySpanId,
      maxEndTimeNs,
      spanBitMap,
    });
  }

  return maxEndTimeNs;
};

type LayoutChildrenSpanArgs = {
  childrenBySpanId: RelatedSpansBySpanId;
  childrenSpanIds: string[];
  minPresentationalSpanDuration: number;
  spanBitMap: SpanBitMap;
  result: SpanRows;
};

const layoutChildrenSpan = ({
  childrenBySpanId,
  childrenSpanIds,
  minPresentationalSpanDuration,
  spanBitMap,
  result,
}: LayoutChildrenSpanArgs) => {
  const nextChildrenSpanIdBitmap: { [key: string]: number } = {};
  childrenSpanIds.sort(sortByStartTimeNs(spanBitMap)).forEach((spanId) => {
    addChildToSpanRow({
      minPresentationalSpanDuration,
      result,
      spanBitMap,
      spanId,
    });

    (childrenBySpanId[spanId] || []).forEach((childSpanId) => {
      nextChildrenSpanIdBitmap[childSpanId] = 1;
    });
  });

  const nextChildrenSpanIds = Object.keys(nextChildrenSpanIdBitmap);

  if (nextChildrenSpanIds.length) {
    layoutChildrenSpan({
      childrenBySpanId,
      childrenSpanIds: nextChildrenSpanIds,
      minPresentationalSpanDuration,
      spanBitMap,
      result,
    });
  }
};

type Result = {
  minPresentationalSpanDuration: number;
  minStartTimeNs: number;
  maxEndTimeNs: number;
  niceUpperBound: number;
  spanBitMap: SpanBitMap;
  spanRows: SpanRows;
  tickSpacing: number;
};

const getSpanRows = ({
  scale,
  spans,
  width,
}: {
  scale: number;
  spans: Span[];
  width: number;
}): Promise<Result> => {
  return new Promise((resolve) => {
    const childrenBySpanId: RelatedSpansBySpanId = {};
    const parentsBySpanId: RelatedSpansBySpanId = {};
    const spanBitMap: SpanBitMap = {};
    const result: SpanRows = [];

    let minStartTimeNs: number = null;
    let maxEndTimeNs: number = null;
    let rootSpanId: string = null;
    let index = 0;

    const processChunk = () => {
      for (
        let end = Math.min(index + MAX_SPANS_TO_RENDER, spans.length);
        index < end;
        index++
      ) {
        const { startTimeNs, endTimeNs, parentSpanId, rootSpan, spanId } =
          spans[index];
        spanBitMap[spanId] = spans[index];

        if (minStartTimeNs === null || startTimeNs < minStartTimeNs) {
          minStartTimeNs = startTimeNs;
        }

        if (maxEndTimeNs === null || endTimeNs > maxEndTimeNs) {
          maxEndTimeNs = endTimeNs;
        }

        if (rootSpan) {
          rootSpanId = spanId;
        } else {
          if (!childrenBySpanId[parentSpanId]) {
            childrenBySpanId[parentSpanId] = [];
          }

          childrenBySpanId[parentSpanId].push(spanId);

          if (!parentsBySpanId[spanId]) {
            parentsBySpanId[spanId] = [];
          }

          parentsBySpanId[spanId].push(parentSpanId);
        }
      }

      if (index < spans.length) {
        requestAnimationFrame(processChunk);
      } else {
        finalize();
      }
    };

    const finalize = () => {
      const diffInNs = maxEndTimeNs - minStartTimeNs;
      const min = 0;
      const max = scale < 1 ? Math.round(diffInNs / scale) : diffInNs;

      const maxTicks = Math.floor(width / 100);
      const { niceUpperBound, tickSpacing } = getNiceScale(min, max, maxTicks);

      const minPresentationalSpanDuration = Math.round((10 * max) / width);

      const childrenByMissingParentSpanId: { [key: string]: string[] } = {};

      const processSpans = (
        spans: Span[],
        index = 0,
        chunkSize = MAX_SPANS_TO_RENDER,
      ) => {
        const totalSpans = spans.length;
        if (index >= spans.length) {
          return;
        }

        for (let i = index; i < Math.min(index + chunkSize, totalSpans); i++) {
          const span = spans[i];
          if (
            !span.rootSpan &&
            span.parentSpanId &&
            !spanBitMap[span.parentSpanId]
          ) {
            const { parentSpanId, spanId } = span;
            if (!childrenByMissingParentSpanId[parentSpanId]) {
              childrenByMissingParentSpanId[parentSpanId] = [];
            }
            childrenByMissingParentSpanId[parentSpanId].push(spanId);
          }
        }

        requestAnimationFrame(() =>
          processSpans(spans, index + chunkSize, chunkSize),
        );
      };
      processSpans(spans);

      const childrenByMissingParentSpanIdKeys = Object.keys(
        childrenByMissingParentSpanId,
      );

      if (!rootSpanId && spans.length) {
        const onlyRootSpanMissing =
          childrenByMissingParentSpanIdKeys.length === 1;
        const spanId = onlyRootSpanMissing
          ? childrenByMissingParentSpanIdKeys[0]
          : '0';

        const rootSpan = {
          attributes: {
            isGhostSpan: true,
          },
          spanId,
          startTimeNs: minStartTimeNs,
          endTimeNs: maxEndTimeNs,
          latency: 0,
          parentSpanId: '',
          rootSpan: true,
        };

        spanBitMap[spanId] = rootSpan;
        rootSpanId = spanId;

        if (onlyRootSpanMissing) {
          delete childrenByMissingParentSpanId[
            childrenByMissingParentSpanIdKeys[0]
          ];
        }
      }

      Object.keys(childrenByMissingParentSpanId).forEach((missingSpanId) => {
        const childrenSpanIds = childrenByMissingParentSpanId[missingSpanId];
        const childrenSpans = childrenSpanIds.map(
          (spanId) => spanBitMap[spanId],
        );

        const startTimeNs = Math.min(
          ...childrenSpans.map((span) => span.startTimeNs),
        );

        const maxEndTimeNs = Math.max(
          ...childrenSpans.map((span) => span.endTimeNs),
        );

        const endTimeNs = findMaxEndTime({
          childrenSpanIds,
          childrenBySpanId,
          maxEndTimeNs,
          spanBitMap,
        });

        const parentSpanId = rootSpanId;

        const ghostSpan = {
          attributes: {
            isGhostSpan: true,
          },
          spanId: missingSpanId,
          startTimeNs,
          endTimeNs,
          latency: 0,
          parentSpanId,
        };

        spanBitMap[missingSpanId] = ghostSpan;

        if (!childrenBySpanId[parentSpanId]) {
          childrenBySpanId[parentSpanId] = [];
        }

        childrenBySpanId[parentSpanId].push(missingSpanId);
      });

      if (rootSpanId) {
        result.push([rootSpanId]);

        const childrenSpanIds = childrenBySpanId[rootSpanId] || [];

        layoutChildrenSpan({
          childrenBySpanId,
          childrenSpanIds,
          minPresentationalSpanDuration,
          spanBitMap,
          result,
        });
      }

      resolve({
        minPresentationalSpanDuration,
        minStartTimeNs,
        maxEndTimeNs: minStartTimeNs + niceUpperBound,
        niceUpperBound,
        spanBitMap,
        spanRows: result,
        tickSpacing,
      });
    };

    processChunk();
  });
};

export default getSpanRows;
