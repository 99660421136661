import classnames from 'classnames';
import { CalendarPicker } from 'composite';
import React, { ReactElement } from 'react';
import { DateSelection } from 'types';
import { QuickRangeOptionsProps } from '../types';
import { useLocalStorageState } from 'hooks';

type Props = {
  className?: string;
  hasStartedLiveTail?: boolean;
  onChange: (value: DateSelection) => void;
  startLiveTail?: () => void;
  quickRangeOptions?: QuickRangeOptionsProps[];
  value: DateSelection;
};

const Datepicker = ({
  className,
  hasStartedLiveTail,
  onChange,
  startLiveTail,
  quickRangeOptions,
  value,
}: Props): ReactElement => {
  const [absoluteTimeRangeStorage, setAbsoluteTimeRangeStorage] =
    useLocalStorageState('AbsoluteTimeRange', []);

  const handleDateChange = (dateSelection: DateSelection) => {
    const { startLabel, endLabel } = dateSelection;

    onChange(dateSelection);
    if (!startLabel && !endLabel) {
      setAbsoluteTimeRangeStorage((preHistory: void[]) => {
        if (preHistory.length > 3) {
          preHistory.pop();
        }
        return [
          ...[
            {
              startTimeUnix: dateSelection.startTimeUnix,
              endTimeUnix: dateSelection.endTimeUnix,
            },
          ],
          ...preHistory,
        ];
      });
    }
  };

  return (
    <div
      className={classnames({
        datepicker: true,
        'field-group': true,
        [className]: className,
      })}
    >
      <div className="field-group__item">
        <CalendarPicker
          absoluteTimeRangeStorage={absoluteTimeRangeStorage}
          hasStartedLiveTail={hasStartedLiveTail}
          onChange={handleDateChange}
          startLiveTail={startLiveTail}
          quickRangeOptions={quickRangeOptions}
          value={value}
        />
      </div>
    </div>
  );
};

export default Datepicker;
