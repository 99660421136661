import { useToggle } from 'hooks';
import React from 'react';
import { Frame } from 'types';
import StackTraceFrameCode from './StackTraceFrameCode';
import StackTraceFrameTitle from './StackTraceFrameTitle';
import { Json } from '../Json';

type Props = {
  frame: Frame;
  initialIsOpen?: boolean;
};

const StackTraceFrame = ({ frame, initialIsOpen = false }: Props) => {
  const { lineno } = frame;
  const contextLine = frame.context_line || '';
  const preContext = frame.pre_context || [];
  const postContext = frame.post_context || [];
  const vars = frame.vars || {};

  const isExpandable = Boolean(contextLine || Object.keys(vars).length);
  const isOpenToggle = useToggle(initialIsOpen);
  return (
    <div className="stack-trace__frame">
      <StackTraceFrameTitle
        frame={frame}
        isExpandable={isExpandable}
        isOpenToggle={isOpenToggle}
      />
      {isExpandable && isOpenToggle.value ? (
        <div className="stack-trace__frame__content">
          {contextLine ? (
            <StackTraceFrameCode
              contextLine={contextLine}
              lineno={lineno}
              postContext={postContext}
              preContext={preContext}
            />
          ) : null}
          <div className="stack-trace__frame__vars">
            <div className="stack-trace__frame__vars__title">
              Local Variables
            </div>
            <div className="stack-trace__frame__vars__content">
              {Object.keys(vars).length > 0 ? (
                <Json data={vars} />
              ) : (
                <div className="stack-trace__frame__vars__placeholder"></div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default StackTraceFrame;
