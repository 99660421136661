import { useDateState, useRequest } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import {
  aggregateTimeSeriesFormula,
  aggregateTimeSeriesMultiple,
  promqlQueryRange,
  getLogMetricsTimeSeriesLogQL,
  getLogMetricsResultWithKfuseQl,
} from 'requests';
import {
  DashboardPanelType,
  MetricsQueriesDataProps,
  MetricsTransformSeriesProps,
} from 'types';
import {
  eventsDataTransformer,
  formatChartLegend,
  getRollupByVisualization,
  getRollupToSecond,
  kfuseqlDataTransformer,
  loadAnomalyData,
  logsDataTransformer,
} from 'utils';
import rumDataTransformer from 'utils/DataTransformer/rumDataTransformer';
import {
  TimeseriesExplorerProps,
  TimeseriesExplorerPromqlProps,
} from './types';
import {
  getCompareToPreviousList,
  getPredefinedFunctions,
  getPreviousTimeWithLabel,
  getPreviousTimeRangeWithPromql,
  transformMetricsExplorerData,
  transformLogqlExplorerData,
} from './utils';
import aggregateEventTimeSeries from 'screens/Events/requests/aggregateEventTimeSeries';
import eventsDataFrameToQuery from 'utils/DataTransformer/events/eventsDataFrameToQuery';
import fetchRumTimeseriesData from 'screens/Rum/requests/fetchRumTimeseriesData';
import fetchRumErrorsSeries from 'screens/Rum/requests/fetchRumErrorsSeries';

const useTimeseriesExplorer = ({
  activeChartType,
  activeQueries,
  date: prevDate,
  chartData: prevChartData,
  queryType,
  eventType,
}: Omit<TimeseriesExplorerProps | 'unit'>) => {
  const [date, setDate] = useDateState(prevDate);
  const [compareToPrev, setCompareToPrev] = useState(
    getCompareToPreviousList(),
  );
  const userActionRef = useRef({ defaultTimeChanged: false });
  const [functionList, setFunctionList] = useState(getPredefinedFunctions());
  const promqlQueryRangeRequest = useRequest(promqlQueryRange, false);
  const logqlQueryRangeRequest = useRequest(
    getLogMetricsTimeSeriesLogQL,
    false,
  );
  const kfuseqlQueryRangeRequest = useRequest(
    getLogMetricsResultWithKfuseQl,
    false,
  );
  const traceQueryRequest = useRequest(aggregateTimeSeriesMultiple, false);
  const eventQueryRequest = useRequest(
    (args) => aggregateEventTimeSeries(args).then(eventsDataFrameToQuery),
    false,
  );
  const rumPerformanceQueryRequest = useRequest(
    (args) => fetchRumTimeseriesData(args),
    false,
  );
  const rumTopListQueryRequest = useRequest(
    (args) => fetchRumErrorsSeries(args),
    false,
  );

  const [chartData, setChartData] = useState(
    prevChartData || { data: [], series: [] },
  );
  const [chartSize, setChartSize] = useState({ width: 800, height: 300 });
  const [seriesBitmap, setSeriesBitmap] = useState({});
  const [yScale, setYScale] = useState<'log' | 'linear'>('linear');
  const [init, setInit] = useState(false);

  const buildPromqlForCompareAndFunctions = () => {
    const promqlQueries: TimeseriesExplorerPromqlProps[] = [];
    activeQueries.forEach(
      ({ legendFormat, logql, metricName, promql, steps }) => {
        promqlQueries.push({
          date,
          legendFormat,
          logql,
          metricName,
          promql,
          steps,
        });
        const compareToPrevQueries = getPreviousTimeRangeWithPromql(
          compareToPrev,
          date,
          promql,
          logql,
        );

        if (compareToPrevQueries.length > 0) {
          compareToPrevQueries.forEach((item) => {
            promqlQueries.push({ ...item, legendFormat, metricName, steps });
          });
        }
      },
    );
    return promqlQueries;
  };

  const callPromqlQueryRange = async () => {
    const promqlQueries = buildPromqlForCompareAndFunctions();
    const defaultStep = getRollupByVisualization(date, activeChartType);

    const datasets = await Promise.all(
      promqlQueries.map(
        ({ date, legendFormat, metricName, promql, steps }, queryIndex) => {
          if (!promql) return { data: [], series: [] };
          const chartStep = steps ? [steps] : undefined;
          if (typeof promql === 'string') {
            return promqlQueryRangeRequest
              .call({
                date: date,
                promqlQueries: typeof promql === 'string' ? [promql] : promql,
                metricNames: [metricName || ''],
                type: 'timeseries',
                steps: userActionRef.current.defaultTimeChanged
                  ? [defaultStep]
                  : chartStep,
                seriesFormatter: legendFormat
                  ? ({ idx, metric }: MetricsTransformSeriesProps) => {
                      return formatChartLegend(idx, metric, legendFormat);
                    }
                  : undefined,
              })
              .then((res) => {
                const queryId = `query_${queryIndex}`;
                const newQueryData: MetricsQueriesDataProps = {};
                newQueryData[queryId] = { isLoading: false, data: res };
                return newQueryData;
              })
              .catch(() => ({ data: [], series: [] }));
          } else {
            return loadAnomalyData({
              date,
              metricName,
              promql,
              queryIndex,
              request: promqlQueryRangeRequest,
              type: 'query',
              step: userActionRef.current.defaultTimeChanged
                ? undefined
                : steps,
            }).catch(() => ({ data: [], series: [] }));
          }
        },
      ),
    );

    const transformedData = transformMetricsExplorerData(
      datasets,
      promqlQueries,
      seriesBitmap,
    );
    setChartData(transformedData);
  };

  const callLogqlQueryRange = async () => {
    const compareWithPrevDate = getPreviousTimeWithLabel(compareToPrev, date);
    const logqlQueries: TimeseriesExplorerPromqlProps[] = activeQueries.map(
      (item) => ({ ...item, date }),
    );
    activeQueries.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        logqlQueries.push({ ...item, date: prevDate, label });
      });
    });

    const defaultLogqlStep = getRollupByVisualization(date, 'bar');
    const datasets = await Promise.all(
      logqlQueries.map(({ date, logql, steps }, idx: number) =>
        logqlQueryRangeRequest.call({
          date,
          format: 'timeseries',
          instant: false,
          logQlQuery: {
            logQL: logql,
            step: userActionRef.current.defaultTimeChanged
              ? undefined
              : steps
                ? getRollupToSecond(steps as string) * 1000
                : defaultLogqlStep,
            queryKey: `query_${idx}`,
          },
          width: chartSize.width,
        }),
      ),
    );
    const transformedData = transformLogqlExplorerData(
      datasets,
      logqlQueries,
      seriesBitmap,
    );

    setChartData(transformedData);
  };

  const callKfuseqlQueryRange = async () => {
    const compareWithPrevDate = getPreviousTimeWithLabel(compareToPrev, date);
    const kfuseqlQueries: TimeseriesExplorerPromqlProps[] = activeQueries.map(
      (item) => ({ ...item, date }),
    );
    activeQueries.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        kfuseqlQueries.push({ ...item, date: prevDate, label });
      });
    });

    const defaultLogqlStep = getRollupByVisualization(date, 'bar');
    const datasets = await Promise.all(
      kfuseqlQueries.map(({ date, logql, steps, metricName }, idx: number) => {
        const transformer = logsDataTransformer(false);
        transformer[0] = {
          id: 'kfuseqlDataTransformer',
          func: kfuseqlDataTransformer,
        };
        return kfuseqlQueryRangeRequest.call({
          date,
          kfuseQl: logql,
          transformer,
          meta: {
            refId: `query_${idx}`,
            step: userActionRef.current.defaultTimeChanged
              ? undefined
              : steps
                ? getRollupToSecond(steps as string)
                : defaultLogqlStep,
            type: DashboardPanelType.TIMESERIES,
            metricName,
          },
        });
      }),
    );

    const transformedData = transformLogqlExplorerData(
      datasets,
      kfuseqlQueries,
      seriesBitmap,
    );
    setChartData(transformedData);
  };

  const callTraceQuery = async () => {
    const compareWithPrevDate = getPreviousTimeWithLabel(compareToPrev, date);
    const traceQueries: TimeseriesExplorerPromqlProps[] = activeQueries.map(
      (item) => ({ ...item, date }),
    );
    const queries: any = [];
    const formulas: any = [];
    traceQueries.map((item) => {
      if (item.type === 'formula') {
        formulas.push(item);
      } else {
        queries.push(item);
      }
    });

    queries.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        queries.push({ ...item, date: prevDate, label });
      });
    });

    formulas.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        formulas.push({ ...item, date: prevDate, label });
      });
    });

    const datasets = await Promise.all(
      queries.map(({ date, filter, query }) =>
        traceQueryRequest.call({
          date,
          ...filter,
          queries: [query],
          resultFormat: 'timeseries',
        }),
      ),
    );

    const datasetsFormula = await Promise.all(
      formulas.map(({ date, formula, filter, queries }) =>
        aggregateTimeSeriesFormula({
          date,
          ...filter,
          formula,
          queries,
          resultFormat: 'timeseries',
        }),
      ),
    );

    const queryDatasets = [];
    datasets.map((dataset) => {
      queryDatasets.push(dataset[0] || {});
    });
    datasetsFormula.map((dataset) => {
      queryDatasets.push(dataset || {});
    });

    const transformedData = transformLogqlExplorerData(
      queryDatasets,
      [...queries, ...formulas],
      seriesBitmap,
    );
    setChartData(transformedData);
  };

  const callEventsQuery = async () => {
    const compareWithPrevDate = getPreviousTimeWithLabel(compareToPrev, date);
    const eventQueries: TimeseriesExplorerPromqlProps[] = activeQueries.map(
      (item) => ({ ...item, date }),
    );
    const queries: any = [];
    eventQueries.map((item) => {
      queries.push(item);
    });

    queries.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        queries.push({ ...item, date: prevDate, label });
      });
    });

    const baseTransformers = eventsDataTransformer(false);
    const datasets = await Promise.all(
      queries.map(({ date, query }) =>
        eventQueryRequest.call({
          date,
          dataFormat: 'timeseries',
          formulas: [],
          instant: false,
          queries: [query],
          transformer: baseTransformers,
        }),
      ),
    );

    const queryDatasets = [];
    datasets.map((dataset) => {
      queryDatasets.push(Object.values(dataset)?.[0]?.data || {});
    });

    const transformedData = transformLogqlExplorerData(
      queryDatasets,
      [...queries],
      seriesBitmap,
    );
    setChartData(transformedData);
  };

  const callRumPerformanceQuery = async () => {
    const compareWithPrevDate = getPreviousTimeWithLabel(compareToPrev, date);
    const rumPerformanceQueries: TimeseriesExplorerPromqlProps[] =
      activeQueries.map((item) => ({ ...item, date }));
    const queries: any = [];
    rumPerformanceQueries.map((item) => {
      queries.push(item);
    });

    queries.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        queries.push({ ...item, date: prevDate, label });
      });
    });
    const baseTransformers = rumDataTransformer(false);
    const datasets = await Promise.all(
      queries.map(({ date, query }) =>
        rumPerformanceQueryRequest.call({
          date,
          dataFormat: 'timeseries',
          formulas: [],
          instant: false,
          queries: [query],
          transformer: baseTransformers,
          eventType,
        }),
      ),
    );
    const queryDatasets = [];
    datasets.map((dataset) => {
      queryDatasets.push(Object.values(dataset)?.[0].data || {});
    });

    const transformedData = transformLogqlExplorerData(
      queryDatasets,
      [...queries],
      seriesBitmap,
    );
    setChartData(transformedData);
  };

  const callRumTopListQuery = async () => {
    const compareWithPrevDate = getPreviousTimeWithLabel(compareToPrev, date);
    const rumTopListQueries: TimeseriesExplorerPromqlProps[] =
      activeQueries.map((item) => ({ ...item, date }));
    const queries: any = [];
    rumTopListQueries.map((item) => {
      queries.push(item);
    });

    queries.map((item) => {
      compareWithPrevDate.map(({ date: prevDate, label }) => {
        queries.push({ ...item, date: prevDate, label });
      });
    });
    const baseTransformers = rumDataTransformer(false);
    const datasets = await Promise.all(
      queries.map(({ date, query }) =>
        rumTopListQueryRequest.call({
          date,
          dataFormat: 'timeseries',
          formulas: [],
          instant: false,
          queries: [query],
          transformer: baseTransformers,
          eventType,
        }),
      ),
    );
    const queryDatasets = [];
    datasets.map((dataset) => {
      queryDatasets.push(Object.values(dataset)?.[0].data || {});
    });

    const transformedData = transformLogqlExplorerData(
      queryDatasets,
      [...queries],
      seriesBitmap,
    );
    setChartData(transformedData);
  };
  const loadQueryCall = () => {
    if (queryType === 'logql') {
      callLogqlQueryRange();
    } else if (queryType === 'trace') {
      callTraceQuery();
    } else if (queryType === 'events') {
      callEventsQuery();
    } else if (queryType === 'rum-performance') {
      callRumPerformanceQuery();
    } else if (queryType === 'rum-toplist') {
      callRumTopListQuery();
    } else if (queryType === 'kfuseql') {
      callKfuseqlQueryRange();
    } else {
      callPromqlQueryRange();
    }
  };

  useEffect(() => {
    if (!init) {
      setInit(true);
      return;
    }
    userActionRef.current.defaultTimeChanged = true;
    loadQueryCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    if (prevChartData) return;
    loadQueryCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevChartData]);

  return {
    loadQueryCall,
    callRumPerformanceQuery,
    callRumTopListQuery,
    callEventsQuery,
    callLogqlQueryRange,
    callPromqlQueryRange,
    callTraceQuery,
    chartData,
    chartSize,
    compareToPrev,
    date,
    functionList,
    logqlQueryRangeRequest,
    promqlQueryRangeRequest,
    kfuseqlQueryRangeRequest,
    rumPerformanceQueryRequest,
    traceQueryRequest,
    queryType,
    seriesBitmap,
    setChartData,
    setChartSize,
    setCompareToPrev,
    setDate,
    setFunctionList,
    setSeriesBitmap,
    setYScale,
    yScale,
  };
};

export default useTimeseriesExplorer;
