import { TableColumn } from 'components/Table';
import { delimiter } from 'kfuse-constants';
import { DataFrame } from '../types';
import { getColumnsFromObject } from './organizeColumn';

// id -> merge
const mergeSeriesData = ({
  dataFrames,
  columns = [],
}: {
  dataFrames: DataFrame[];
  columns: TableColumn<{ key: string; label: string }>[];
}): {
  data: Record<string, unknown>[];
  columns: TableColumn<{ key: string; label: string }>[];
} => {
  if (!dataFrames || !Array.isArray(dataFrames)) {
    return { data: [], columns };
  }

  const labelKeysBitmap: { [key: string]: Record<string, unknown> } = {};
  const uniqueLabels: { [key: string]: boolean } = {};
  dataFrames.map((dataFrame) => {
    const { data, meta } = dataFrame;
    if (!data) return;
    const refIdAndValue = `Value #${meta.refId}`;
    if (data.length === 0) {
      // placeholder for empty dataset
      Object.keys(labelKeysBitmap).map((key) => {
        labelKeysBitmap[key][refIdAndValue] = undefined;
      });
      return;
    }

    data.map(({ label, values, timestamp }) => {
      const metricKeys = Object.keys(label).sort();
      metricKeys.map((k) => (uniqueLabels[k] = true));

      const uniqueLabelKeys = metricKeys
        .map((k) => `${k}:${label[k]}`)
        .join(',');
      const uniqueLabelKeysWithTime = `${uniqueLabelKeys}${delimiter}${timestamp}`;

      if (!labelKeysBitmap[uniqueLabelKeysWithTime]) {
        labelKeysBitmap[uniqueLabelKeysWithTime] = {
          ...label,
          [refIdAndValue]: values[0],
          Time: timestamp,
        };
      } else {
        labelKeysBitmap[uniqueLabelKeysWithTime] = {
          ...labelKeysBitmap[uniqueLabelKeysWithTime],
          [refIdAndValue]: values[0],
          Time: timestamp,
        };
      }
    });
  });

  const data = Object.keys(labelKeysBitmap).map((key) => labelKeysBitmap[key]);
  return {
    data,
    columns: getColumnsFromObject(data),
  };
};

export default mergeSeriesData;
