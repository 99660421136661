import { fetchGrafanaApi } from 'requests';

const deleteFolderById = async ({
  uid,
}: {
  uid: string;
}): Promise<Record<string, unknown>> => {
  const options = { method: 'DELETE' };
  const result = await fetchGrafanaApi(
    `grafana/api/folders/${uid}?forceDeleteRules=false`,
    options,
  );

  return result;
};

export default deleteFolderById;
