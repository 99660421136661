import { useCallback, useEffect } from 'react';

const useScrollListener = ({
  onScroll,
  scrollRef,
}: {
  onScroll: any;
  scrollRef: any;
}) => {
  useEffect(() => {
    const element = scrollRef?.current;
    if (!element) return;

    element.addEventListener('scroll', handleScroll);

    return () => {
      element.removeEventListener('scroll', handleScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef, onScroll]);

  const handleScroll = useCallback(() => {
    if (typeof onScroll === 'function') {
      onScroll();
    }
  }, [onScroll]);
};

export default useScrollListener;
