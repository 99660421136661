import { TimeseriesRenderer, useCursorContextState } from 'components';
import React, { ReactElement, useMemo } from 'react';
import { setDateRangeOnChartZoom } from 'utils';
import { DashboardPanelProps, UplotExtended } from 'types';

import { useDashboardState, useDashboardDataLoader } from '../../hooks';
import { DashboardPanelNoData } from '../../components';
import { DashboardPanelComponentProps } from '../../types';
import {
  checkIfDataNotAvailable,
  getPanelStyles,
  mapTimeseriesDrawStyle,
  mapTimeseriesLegendMode,
} from '../../utils';

const Timeseries = ({
  dashboardState,
  dashboardDataLoader,
  isInView,
  panel,
  panelSize,
}: {
  dashboardState: ReturnType<typeof useDashboardState>;
  dashboardDataLoader: ReturnType<typeof useDashboardDataLoader>;
  isInView: boolean;
  panel: DashboardPanelProps;
  panelSize: DashboardPanelComponentProps['panelSize'];
}): ReactElement => {
  const { cursorState, setCursorState } = useCursorContextState();
  const { onDateChange } = dashboardState;
  const { options, fieldConfig } = panel;

  const panelStyles = useMemo(
    () => getPanelStyles(panel.fieldConfig?.defaults),
    [panel],
  );
  const defaultChartTypes = useMemo(
    () => mapTimeseriesDrawStyle(fieldConfig?.defaults?.custom),
    [fieldConfig],
  );

  const hooks = useMemo(() => {
    return [
      {
        hook: (u: UplotExtended) => setDateRangeOnChartZoom(u, onDateChange),
        type: 'setSelect',
      },
      ...(dashboardDataLoader.result?.hooks || []),
    ];
  }, [dashboardDataLoader.result?.hooks, onDateChange]);

  return (
    <>
      {!checkIfDataNotAvailable(dashboardDataLoader.result) ? (
        <TimeseriesRenderer
          bands={dashboardDataLoader.result?.bands || []}
          chartTypes={defaultChartTypes}
          chartData={dashboardDataLoader.result || { data: [], series: [] }}
          cursorState={isInView ? cursorState : undefined}
          hooks={hooks}
          isLoading={dashboardDataLoader.isLoading}
          layoutType="dashboard"
          legend={{
            legendHeight: panelSize.height * 0.25,
            legendType: mapTimeseriesLegendMode(options.legend.displayMode),
          }}
          onCursorStateChange={setCursorState}
          size={{
            width: panelSize.width,
            height:
              options.legend.displayMode === 'list'
                ? panelSize.heightContainer - panelSize.heightContainer * 0.25
                : panelSize.heightContainer,
          }}
          styles={panelStyles}
          tooltipType={'compact'}
          unit={panel.fieldConfig?.defaults?.unit || 'number'}
        />
      ) : (
        <DashboardPanelNoData panelSize={panelSize} />
      )}
    </>
  );
};

export default Timeseries;
