import { ScrollView } from 'components';
import React, { MouseEvent, ReactElement, useEffect, useState } from 'react';
import { checkAnomalySeriesBandByLabel } from 'utils/Timeseries';

import { SeriesIcon } from '../components';
import LegendsContainer from './LegendsContainer';
import { UPlotConfig } from '../types';
import LegendKeyValueFormatter from './LegendKeyValueFormatter';

const BOTTOM_PADDING = 12;
const MIN_LEGEND_HEIGHT = 24;

const LegendsCompact = ({
  config,
  onItemClick,
  onFocusSeries,
  updateChartSize,
  legendHeight,
}: {
  config: UPlotConfig;
  onItemClick: (e: MouseEvent<HTMLLIElement>, idx: number) => void;
  onFocusSeries: (idx: number) => void;
  updateChartSize: (height: number) => void;
  legendHeight?: number;
}): ReactElement => {
  const [legendHeightState, setLegendHeightState] = useState(legendHeight);
  const [prevChartHeight, setPrevChartHeight] = useState(config.height);

  useEffect(() => {
    if (legendHeightState === legendHeight || legendHeight === undefined) {
      return;
    }

    const heightDiff = legendHeight - legendHeightState - BOTTOM_PADDING;
    if (config.height === prevChartHeight + heightDiff) {
      return;
    }

    if (legendHeightState < legendHeight) {
      updateChartSize(config.height + heightDiff);
      setPrevChartHeight(config.height);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.height]);

  return (
    <ScrollView
      height={'auto'}
      maxHeight={
        legendHeightState > MIN_LEGEND_HEIGHT ? legendHeightState : undefined
      }
      width={config.width - 16}
      scrollIndicator={false}
    >
      <LegendsContainer
        onSizeChange={({ height }) => {
          if (
            height === legendHeightState ||
            legendHeight === undefined ||
            config.width < 0
          ) {
            return;
          }

          const maxHeight = Math.min(height, legendHeight);
          const heightDiff = legendHeightState - maxHeight - BOTTOM_PADDING;

          updateChartSize(config.height + heightDiff);
          setPrevChartHeight(config.height);
          setLegendHeightState(maxHeight);
        }}
      >
        <div
          className="uplot__legends__compact"
          onMouseLeave={() => onFocusSeries(null)}
        >
          {config?.series.map((s, idx) => {
            if (!s.label) {
              return null;
            }
            if (checkAnomalySeriesBandByLabel(s.label)) {
              return null;
            }
            return (
              <div
                style={{ opacity: s.show ? 1 : 0.5 }}
                className="uplot__legends__compact__listitem"
                key={idx}
                onClick={(e) => onItemClick(e, idx)}
                onMouseEnter={() => onFocusSeries(idx)}
              >
                <SeriesIcon backgroundColor={s.stroke || s._stroke} />
                <LegendKeyValueFormatter text={s.label} />
              </div>
            );
          })}
        </div>
      </LegendsContainer>
    </ScrollView>
  );
};

export default LegendsCompact;
