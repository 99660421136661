import {
  Button,
  ChartToolbar,
  TimeseriesRenderer,
  TooltipTrigger,
} from 'components';
import React, { ReactElement, useState } from 'react';
import { FaArrowLeft as ArrowLeftIcon } from 'react-icons/fa';
import ResizeObserver from 'rc-resize-observer';
import { DateSelection, LegendTypes } from 'types';
import { getMetricsExplorerUrlByPromql } from 'utils/index';
import { BiLinkExternal } from 'react-icons/bi';

const defaultChartTypes = ['Line'];

const AlertsServiceCorrelationsMetricsChartLarge = ({
  chartData,
  date,
  title,
  onBackToSummary,
  promql,
}: {
  chartData: { data: any; series: any };
  date: DateSelection;
  title: string;
  onBackToSummary: () => void;
  promql: string;
}): ReactElement => {
  const [chartWidth, setChartWidth] = useState(1000);

  return (
    <div>
      <div className="flex items-center">
        <Button variant="link" size="sm" onClick={onBackToSummary}>
          <ArrowLeftIcon className="mr-1" /> Return to summary
        </Button>
        <div className="pl-4 text-sm font-semibold">{title}</div>
      </div>
      <ResizeObserver onResize={(size) => setChartWidth(size.width)}>
        <TimeseriesRenderer
          className="border"
          chartTypes={defaultChartTypes}
          date={date || null}
          chartData={chartData}
          isLoading={false}
          unit="number"
          legend={{
            legendType: LegendTypes.COMPACT_ONE_LINE,
            legendHeight: 120,
          }}
          layoutType="drawer-large"
          tooltipType="compact"
          renderToolbar={({ activeChart, setActiveChart }) => (
            <ChartToolbar
              activeChart={activeChart}
              chartTypes={['Line']}
              setActiveChart={setActiveChart}
              toolbar={{
                rightToolbar: (
                  <div className="new-metrics__chart__right-toolbar">
                    <div
                      className="new-metrics__chart__right-toolbar__icon"
                      onClick={() => {
                        window.open(
                          getMetricsExplorerUrlByPromql(promql),
                          '_blank',
                        );
                      }}
                    >
                      <TooltipTrigger tooltip="Open in metrics explorer">
                        <BiLinkExternal onClick={null} />
                      </TooltipTrigger>
                    </div>
                  </div>
                ),
              }}
            />
          )}
          size={{ height: 340, width: chartWidth }}
        />
      </ResizeObserver>
    </div>
  );
};

export default AlertsServiceCorrelationsMetricsChartLarge;
