import { fetchGrafanaApi } from 'requests';

import { GroupProps } from '../types';

const getGroupById = async ({
  groupId,
}: {
  groupId: string;
}): Promise<GroupProps> => {
  const result = await fetchGrafanaApi(`rbac/groups/${groupId}`);

  return result;
};

export default getGroupById;
