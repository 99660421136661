import {
  AnalyticsChartFullscreenProps,
  DashboardExport,
  TimeseriesExplorer,
  useAnalyticsChart,
  useModalsContext,
  useToaster,
  useLeftSidebarState,
  AnalyticsChartToolbarExportClickProps,
} from 'components';
import { useLogsMetricsQueryState } from 'hooks';
import delimiter from 'kfuse-constants/delimiter';
import React, { useMemo, useState } from 'react';
import {
  DashboardPanelType,
  DateSelection,
  LabelsProps,
  LogsMetricQueryProps,
} from 'types';
import useDebouncedEffect from 'use-debounced-effect';
import {
  parseFacetKey,
  logqlErrorMessage,
  parseErrorFromQueryData,
} from 'utils';

import {
  getLogqlForChartModal,
  getLogQLDashboardExportPanel,
  onCreateAlertLogs,
  getLogqlOrKfusePanelTargets,
} from '../utils';

type Args = {
  customerFilter: { key: string; value: string };
  date: DateSelection;
  labels: LabelsProps;
  leftSidebarState: ReturnType<typeof useLeftSidebarState>;
  queriesState: ReturnType<typeof useState<LogsMetricQueryProps[]>>;
  queryLangTypeState: ReturnType<typeof useState<string>>;
  setDate: (date: DateSelection) => void;
  tab: string;
};

const useLogsAnalyticsChart = ({
  customerFilter,
  date,
  labels,
  leftSidebarState,
  queriesState,
  queryLangTypeState,
  setDate,
  tab,
}: Args) => {
  const modal = useModalsContext();
  const { addToast } = useToaster();

  const { width } = leftSidebarState;
  const analyticsChart = useAnalyticsChart({
    date,
    forcedActiveVisualization: tab as DashboardPanelType,
    hideVisualizeToolBar: true,
    supportedVisualizations: [
      DashboardPanelType.TIMESERIES,
      DashboardPanelType.TOP_LIST,
      DashboardPanelType.TABLE,
      DashboardPanelType.PIECHART,
    ],
  });
  const { activeVisualization } = analyticsChart;
  const { chartWidth } = analyticsChart;
  const logsMetricsQueryState = useLogsMetricsQueryState({
    chartWidth,
    customerFilter,
    date,
    ignoreLoadingLabels: width !== 0, // 0 means sidebar is hidden
    isLogsPage: true,
    labels,
    dataFormat: activeVisualization,
    isRange: activeVisualization === DashboardPanelType.TIMESERIES,
    queriesState,
    queryLangTypeState,
    setDate,
  });
  const { formulas, queries, queryLangType } = logsMetricsQueryState;

  const openExportToDashboardModal = ({
    analyticsChartQueries,
    drawStyle,
  }: AnalyticsChartToolbarExportClickProps) => {
    const targets = getLogqlOrKfusePanelTargets({
      analyticsChartQueries,
      panelType: activeVisualization,
    });

    const annotations = JSON.stringify({
      customerFilter,
      queries,
      formulas,
      queryLangType,
      isTransformed: false,
    });
    const panel = getLogQLDashboardExportPanel({
      targets,
      type: activeVisualization,
    });

    modal.push(
      <DashboardExport
        annotations={annotations}
        closeModal={modal.pop}
        date={date}
        drawStyle={drawStyle}
        panel={panel}
      />,
    );
  };

  const onViewFullscreen = ({
    activeChart,
    prevChartData,
    chartQueries,
    chartFormulas,
    unit,
  }: AnalyticsChartFullscreenProps) => {
    const logqlForQuery = chartQueries?.map((query) =>
      getLogqlForChartModal({
        dataFormat: activeVisualization,
        customerFilter,
        date,
        query: queries[query.index],
        queryLangType,
        type: 'query',
      }),
    );
    const logqlFormula = chartFormulas?.map((formula) =>
      getLogqlForChartModal({
        customerFilter,
        date,
        dataFormat: activeVisualization,
        formula: formulas[formula.index],
        queries,
        queryLangType,
        type: 'formula',
      }),
    );
    const activeQueries = logqlForQuery.map((queryWithMeta, idx: number) => {
      return {
        logql: queryWithMeta?.logql,
        steps: queries[idx].step,
        metricName: queryWithMeta?.meta.metricName,
      };
    });
    logqlFormula.forEach((formulaWithMeta) => {
      activeQueries.push({
        logql: formulaWithMeta?.logql,
        steps: undefined,
        metricName: formulaWithMeta?.meta.metricName,
      });
    });

    modal.push(
      <TimeseriesExplorer
        activeQueries={activeQueries}
        activeChartType={activeChart}
        chartData={prevChartData}
        date={date}
        queryType={queryLangType}
        onClose={modal.pop}
        unit={unit}
      />,
    );
  };

  const onClickCreateAlertLogs = ({
    chartFormulas,
    chartQueries,
  }: {
    chartQueries: AnalyticsChartFullscreenProps['chartQueries'];
    chartFormulas: AnalyticsChartFullscreenProps['chartFormulas'];
  }) => {
    const queriesToAlert = chartQueries.map((query) => {
      return queries[query.index];
    });
    const formulasToAlert = chartFormulas.map((formula) => {
      return formulas[formula.index];
    });

    onCreateAlertLogs({
      customerFilter,
      date,
      queryItem: { queries: queriesToAlert, formulas: formulasToAlert },
      queryLangType,
    });
  };

  const chartQueries = useMemo(() => {
    if (!queries || queries.length === 0) return [];
    return queries.map((query, index: number) => {
      const qlogql = getLogqlForChartModal({
        customerFilter,
        dataFormat: activeVisualization,
        date,
        query,
        queryLangType,
        type: 'query',
      });
      const { isActive, normalizeFunction, queryKey, vectorAggregate } = query;
      const unit = normalizeFunction === 'duration' ? 's' : normalizeFunction;
      return {
        direction: query.limit.direction === 'topk' ? 'top' : 'bottom',
        isActive,
        index,
        logql: qlogql,
        queryKey,
        type: 'query',
        unit,
        vectorAggregate,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries]);

  const chartFormulas = useMemo(() => {
    if (!formulas || formulas.length === 0) return [];

    return formulas.map((formula, index: number) => {
      const flogql = getLogqlForChartModal({
        dataFormat: activeVisualization,
        customerFilter,
        date,
        formula,
        queries,
        queryLangType,
        type: 'formula',
      });
      const { isActive, queryKey } = formula;
      return {
        direction: 'top',
        isActive,
        index,
        logql: flogql,
        queryKey,
        type: 'formula',
        unit: 'number',
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formulas]);

  const groupBysForTable = useMemo(() => {
    if (!queries || queries.length === 0) return [];
    const res: string[] = [];
    queries
      .filter((query) => query.isActive)
      .map(({ rangeAggregateGrouping }: LogsMetricQueryProps) => {
        const gr: string[] = [];
        const isLabel = res.find((g) => g === 'label');
        if (!isLabel && rangeAggregateGrouping.length === 0) {
          gr.push('label');
        }

        rangeAggregateGrouping.forEach((g) => {
          const name = g.startsWith('@')
            ? g.substring(1).split(delimiter)[0]
            : parseFacetKey(g).name;
          gr.push(name);
        });
        res.push(...gr);
      });
    return res;
  }, [queries]);

  useDebouncedEffect(
    () => {
      if (tab === 'chart') {
        if (activeVisualization === 'timeseries') {
          logsMetricsQueryState.loadMultipleLogsChartData({
            formulas,
            queries,
            chartWidth,
          });
          return;
        }
        logsMetricsQueryState.loadInstantMultipleLogsChartData({
          chartWidth,
          queries,
          formulas,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    100,
    [activeVisualization, tab],
  );

  const logsAnalyticsOverlayMessage = useMemo(() => {
    return parseErrorFromQueryData({
      queryData: logsMetricsQueryState.logsChartData,
      queries: queries,
      formulas: formulas,
      dataType:
        activeVisualization === DashboardPanelType.TIMESERIES
          ? 'range'
          : 'instant',
      customErrorMessage: logqlErrorMessage,
    });
  }, [
    activeVisualization,
    queries,
    formulas,
    logsMetricsQueryState.logsChartData,
  ]);

  return {
    analyticsChart,
    chartFormulas,
    chartQueries,
    chartWidth,
    groupBysForTable,
    logsMetricsQueryState,
    onClickCreateAlertLogs,
    openExportToDashboardModal,
    onViewFullscreen,
    logsAnalyticsOverlayMessage,
  };
};

export default useLogsAnalyticsChart;
