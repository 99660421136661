import React from 'react';

import { Box } from 'components/layouts/Box';
import { Table, TableRow } from 'components';
import { kubeNamespaceCount } from 'requests';
import KubernetesRightSideBar from '../KubernetesRightSideBar';
import { useDateState } from 'hooks';
import { useKubernetesController } from '../KubernetesController';
import {
  namespaceCPUUtilizedCalculation,
  namespaceMemUtilizedCalculation,
} from '../utils';
import {
  KubeCellCluster,
  KubeCellStatus,
  KubeCellTimestamp,
  KubeTableCell,
  KubeTableEntityTags,
} from './Components';
import useAsync from '../hooks/useAsync';
import { getAggregationsByEntities } from '../utils/selectorsfunction';
import { generatePath, useParams, useSearchParams } from 'react-router-dom';
import KubernetesTableGroup from './KubernetesTableGroup';
import { useKubernetesTableDataForNamespace } from './KubernetesTableForNamespace';
import { useMergedGroupByFilter } from './useEntitiesData';

const renderEmptyCell = () => '';

function KubernetesTableForNamespaceRow({ row }) {
  const { date, entitiesType, navigate } = useKubernetesController();

  const filters = useMergedGroupByFilter(row.groupBys);
  const [rows] = useKubernetesTableDataForNamespace({
    entitiesType,
    filters,
    kubesSort: null,
    page: 0,
    limit: 50,
    date,
  });

  const setActiveKube = (row) => {
    navigate(({ location, params, pathTemplate }) => {
      return {
        ...location,

        pathname: generatePath(pathTemplate, {
          ...params,

          activeKube: row.namespace.metadata.uid,
          tab: 'yaml',
        }),
      };
    });
  };

  const activeKubeParam = useParams()['activeKube'];
  const activeKube = (
    typeof activeKubeParam === 'string' && rows ? rows : []
  ).find((row) => {
    return row.namespace.metadata.uid === activeKubeParam;
  });

  return (
    <Box width="full" height="full">
      {activeKube && <KubernetesRightSideBar activeKube={activeKube} />}
      <Table
        className="-my-px"
        renderHeader={false}
        rows={rows ?? []}
        renderRow={(props) => (
          <TableRow
            {...props}
            className="border-y"
            onRowClickHandler={(row) => () => setActiveKube(row)}
          />
        )}
        columns={[
          {
            key: 'metadata.namespace',
            label: 'Namespace',
            renderCell(prop) {
              const namespace = prop.row.namespace
                ? prop.row.namespace.metadata.name
                : prop.row.metadata.name;
              return (
                <KubeTableCell
                  {...prop}
                  className="kubernetes__table__cell__entity-name"
                  tooltipText={namespace}
                >
                  {namespace}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'kube_cluster_name',
            label: 'Cluster',
            renderCell(prop) {
              return <KubeCellCluster {...prop} />;
            },
          },
          {
            key: 'status',
            label: 'Status',
            renderCell(prop) {
              const status = prop.row.namespace.status;
              return <KubeCellStatus {...prop} status={status} />;
            },
          },
          {
            key: 'metadata.creationTimestamp',
            label: 'Age',
            renderCell(prop) {
              const age = prop.row.namespace
                ? prop.row.namespace.metadata
                : prop.row.metadata;
              const timestamp = age.creationTimestamp;
              return <KubeCellTimestamp {...prop} timestamp={timestamp} />;
            },
          },
          {
            key: 'headerCpuUsage',
            label: 'CPU Usage',
            renderCell(prop) {
              const nsCPUUtilization = namespaceCPUUtilizedCalculation(
                prop.row,
              );
              return (
                <KubeTableCell
                  {...prop}
                  align="right"
                  className="kubernetes__table__cell__entity-name"
                  tooltipText={nsCPUUtilization}
                >
                  {nsCPUUtilization}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'headerMemUsage',
            label: 'Mem Usage',
            renderCell(prop) {
              const nsMemUtilization = namespaceMemUtilizedCalculation(
                prop.row,
              );
              return (
                <KubeTableCell
                  {...prop}
                  align="right"
                  className="kubernetes__table__cell__entity-name"
                  tooltipText={nsMemUtilization}
                >
                  {nsMemUtilization}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'headerPods',
            label: 'Pods',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="right">
                  {prop.row.podCount}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'headerDeployments',
            label: 'Deployments',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="right">
                  {prop.row.deploymentCount}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'headerDaemonsets',
            label: 'Daemon Sets',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="right">
                  {prop.row.daemonSetCount}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'headerCronjobs',
            label: 'Cronjobs',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="right">
                  {prop.row.cronJobCount}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'headerStatefulsets',
            label: 'Stateful Sets',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="right">
                  {prop.row.statefulsetCount}
                </KubeTableCell>
              );
            },
          },
        ]}
      />
    </Box>
  );
}

export default function KubernetesTableForNamespaceGroup() {
  const [date] = useDateState();
  const { entitiesType, facets, groupBySearchTerms } =
    useKubernetesController();

  const [rows] = useAsync(
    async (signal: AbortSignal) => {
      const [cpu, memory, entities] = await Promise.all([
        null, // namespaceCPUAllocation(groupBySearchTerms, date)({ signal }),
        null, // nameSpaceMemoryAllocation(groupBySearchTerms, date)({ signal }),
        kubeNamespaceCount(
          {
            entityType: entitiesType,
            selectedFacetValuesByName: facets,
            groupBy: groupBySearchTerms,
            aggregations: getAggregationsByEntities(
              entitiesType,
              groupBySearchTerms,
            ),
          },
          { signal },
        ),
        // kubeNamespaceCount(
        //   {
        //     entityType: 'Pod',
        //     groupBy: groupBySearchTerms,
        //     selectedFacetValuesByName: facets,
        //   },
        //   { signal },
        // ),
        // kubeNamespaceCount(
        //   {
        //     entityType: 'Deployment',
        //     groupBy: groupBySearchTerms,
        //     selectedFacetValuesByName: facets,
        //   },
        //   { signal },
        // ),
        // kubeNamespaceCount(
        //   {
        //     entityType: 'DaemonSet',
        //     groupBy: groupBySearchTerms,
        //     selectedFacetValuesByName: facets,
        //   },
        //   { signal },
        // ),
        // kubeNamespaceCount(
        //   {
        //     entityType: 'CronJob',
        //     groupBy: groupBySearchTerms,
        //     selectedFacetValuesByName: facets,
        //   },
        //   { signal },
        // ),
        // kubeNamespaceCount(
        //   {
        //     entityType: 'StatefulSet',
        //     groupBy: groupBySearchTerms,
        //     selectedFacetValuesByName: facets,
        //   },
        //   { signal },
        // ),
      ]);

      return entities.map((podData) => {
        // const key = getGroupKey(podData.groupBys);
        // const cpuPercentage = cpu[key] || 0;
        // const memoryPercentage = memory[key] || 0;

        return {
          ...podData,
          // cpuPercentage,
          // memoryPercentage,
        };
      });
    },
    [date, facets, groupBySearchTerms],
  );

  return (
    <KubernetesTableGroup
      rows={rows ?? []}
      columns={[
        {
          key: 'headerkube_namespace',
          label: 'Namespace Group',
          renderCell: (prop: any) => (
            <KubeTableEntityTags
              {...prop}
              selectedFacetValuesByNameState={facets}
            />
          ),
        },
        {
          key: 'headerkube_cluster_name',
          label: 'Cluster',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerstatus',
          label: 'Status',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerage',
          label: 'Age',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headercpu',
          label: 'CPU Usage',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headermemusage',
          label: 'Mem Usage',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerpods',
          label: 'Pods',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerdeployments',
          label: 'Deployments',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerdaemonset',
          label: 'Daemon Sets',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headercronjob',
          label: 'Cronjobs',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerstatefulsets',
          label: 'Stateful Sets',
          renderCell: renderEmptyCell,
        },
      ]}
      renderExpandedRow={(props) => (
        <KubernetesTableForNamespaceRow {...props} />
      )}
    />
  );
}
