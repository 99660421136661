import React, { FC, ReactNode, useRef, useState } from 'react';
import { parsePartialSearchQuery } from 'utils';
import SearchTags from './SearchTags';
import { FiltersOnEnterArgs, OnEnterArgs, SearchTag } from './types';
import SizeObserver from '../SizeObserver';
import { PopoverPosition, PopoverTriggerV2 } from '../PopoverTriggerV2';

import { default as styles } from './Search.module.css';

type TypedPanelArgs = {
  close: VoidFunction;
  editIndex: number;
  error?: string;
  focus: VoidFunction;
  onValueSelect: (value: string) => void;
  open: VoidFunction;
  typed: string;
  setMeta: (meta: Record<string, unknown>) => void;
  setTyped: (typed: string) => void;
  width: number;
};

type Props = {
  clear?: VoidFunction;
  hideInfo?: boolean;
  onEnter: (args: OnEnterArgs | FiltersOnEnterArgs) => void;
  placeholder?: string;
  renderTypedPanel: (typedPanelArgs: TypedPanelArgs) => ReactNode;
  shouldUseReplace?: boolean;
  tags: SearchTag[];
  validateTyped?: (typed: string) => string | null;
};

const Search: FC<Props> = ({
  clear,
  hideInfo,
  onEnter,
  placeholder,
  shouldUseReplace,
  tags,
  renderTypedPanel,
  validateTyped,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [editIndex, setEditIndex] = useState<number>(null);
  const isEditing = typeof editIndex === 'number';

  const [error, setError] = useState<string>('');
  const clearError = () => {
    setError('');
  };

  const [editTyped, setEditTyped] = useState<string>('');
  const [newTyped, setNewTyped] = useState<string>('');
  const [meta, setMeta] = useState(null);

  const typed = isEditing ? editTyped : newTyped;
  const setTyped = isEditing ? setEditTyped : setNewTyped;

  const onClose = () => {};

  const focus = () => {
    const input = inputRef.current;
    if (input) {
      input.focus();
    }
  };

  const onEnterHandler = (close: VoidFunction) => (nextTyped: string) => {
    const error = validateTyped ? validateTyped(nextTyped) : null;
    if (error) {
      setError(error);
      return;
    }

    const clearTypedAndClose = () => {
      setTyped('');
      setEditIndex(null);
      close();
    };

    const tag = tags[editIndex];
    if (tag) {
      if (tag.text !== nextTyped) {
        // this is to support the new style of filters
        if (shouldUseReplace) {
          onEnter({
            index: editIndex,
            meta: meta || tag.meta,
            replace: true,
            typed: nextTyped,
          });
        } else {
          // once all pages are using the new filtersState,  we can get rid of this
          tag.onRemove();
          setTimeout(() => {
            onEnter({
              meta: meta || tag.meta,
              replace: editIndex !== null,
              typed: nextTyped,
            });
          }, 200);
        }
      }
      clearTypedAndClose();
    } else {
      onEnter({
        meta,
        typed: nextTyped,
      });
      clearTypedAndClose();
    }
  };

  const onValueSelectHandler = (close: VoidFunction) => (value: string) => {
    const parsed = parsePartialSearchQuery(typed, false);
    if (parsed) {
      const parsedFacetName = parsed.facetName;
      const parsedOperator = parsed.operator;

      const nextTyped = `${parsedFacetName}${parsedOperator}"${value}"`;

      onEnterHandler(close)(nextTyped);
    }
  };

  const onOutsideClickHandler = (close: VoidFunction) => () => {
    close();
    setEditIndex(null);
    setTyped('');
  };

  const onInputEnterHandler = (close: VoidFunction) => () => {
    onEnterHandler(close)(typed);
  };

  return (
    <>
      <div
        className={`search group ${styles.root}`}
        ref={containerRef}
        data-testid="search"
      >
        {hideInfo ? null : <div className="search__left">Search for</div>}
        <SizeObserver className="search__right">
          {({ width }) => (
            <PopoverTriggerV2
              container={containerRef.current}
              forceOpen={Boolean(isEditing || typed)}
              onClose={onClose}
              onOpenAutoFocus={(evt) => evt.preventDefault()}
              popover={({ close, open }) => {
                return renderTypedPanel({
                  close: () => {
                    setEditIndex(null);
                    close();
                  },
                  editIndex,
                  error,
                  focus,
                  onValueSelect: onValueSelectHandler(close),
                  open,
                  setMeta,
                  setTyped,
                  typed,
                  width,
                });
              }}
              position={PopoverPosition.BOTTOM_LEFT}
            >
              {({ close, isOpen }) => (
                <SearchTags
                  clear={clear}
                  clearError={clearError}
                  close={close}
                  containerRef={containerRef}
                  editIndex={editIndex}
                  editTyped={editTyped}
                  inputRef={inputRef}
                  isEditing={isEditing}
                  isOpen={isOpen}
                  onInputEnter={onInputEnterHandler(close)}
                  onClickOutside={onOutsideClickHandler(close)}
                  placeholder={placeholder}
                  setEditIndex={setEditIndex}
                  setEditTyped={setEditTyped}
                  setTyped={setTyped}
                  typed={typed}
                  tags={tags}
                  width={width - 20}
                />
              )}
            </PopoverTriggerV2>
          )}
        </SizeObserver>
      </div>
      {error ? <div className="search_error">{error}</div> : null}
    </>
  );
};

export default Search;
