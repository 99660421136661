import { SelectedFacetValuesByName, ValueCount } from 'types';
import {
  filterListWithSelectedSidebar,
  mergeTwoSidebarCountsValues,
} from 'utils';

import { ArchiveInfoProps, HydrationCloudType } from '../types';

export const LogsHydrationArchivesFacet = (cloudType: HydrationCloudType) => {
  const facet = [
    { name: 'inconfig', label: 'In Config', forceExpanded: false },
    { name: 'incloud', label: 'In Cloud', forceExpanded: false },
  ];
  const inCloudLabel = getInCloudLabel(cloudType);
  facet[1].label = inCloudLabel;
  return facet;
};

export const getInCloudLabel = (cloudType: HydrationCloudType) => {
  if (cloudType === HydrationCloudType.S3) return 'In S3';
  if (cloudType === HydrationCloudType.GCS) return 'In GCS';
  if (cloudType === HydrationCloudType.AZURE) return 'In Azure';
  return 'In Cloud';
};

const getCountForHydrationJobListSidebar = (
  archives: ArchiveInfoProps[],
): {
  inconfig: { [key: string]: number };
  incloud: { [key: string]: number };
} => {
  const inconfig: { [key: string]: number } = {};
  const incloud: { [key: string]: number } = {};

  archives.forEach((archive, idx) => {
    if (inconfig[archive.inconfig] === undefined) {
      inconfig[archive.inconfig] = 1;
    } else {
      inconfig[archive.inconfig] += 1;
    }

    if (incloud[archive.incloud] === undefined) {
      incloud[archive.incloud] = 1;
    } else {
      incloud[archive.incloud] += 1;
    }
  });

  return { inconfig, incloud };
};

export const filterHydrationArchives = (
  archives: ArchiveInfoProps[],
  selectedFacetValuesByName: SelectedFacetValuesByName,
): {
  formattedArchives: ArchiveInfoProps[];
  inconfig: ValueCount[];
  incloud: ValueCount[];
  cloudType: HydrationCloudType;
} => {
  const filtered = filterListWithSelectedSidebar(
    archives,
    selectedFacetValuesByName,
  );

  let cloudType: HydrationCloudType;
  filtered.forEach((archive) => {
    cloudType = archive.CloudType;
  });

  const countFromRules = getCountForHydrationJobListSidebar(archives);
  const countFromFiltered = getCountForHydrationJobListSidebar(filtered);
  const mergedCount = mergeTwoSidebarCountsValues(
    countFromRules,
    countFromFiltered,
  );

  const { inconfig, incloud } = mergedCount;

  return {
    formattedArchives: filtered,
    inconfig: Object.keys(inconfig).map((key) => ({
      value: key,
      count: inconfig[key],
    })),
    incloud: Object.keys(incloud).map((key) => ({
      value: key,
      count: incloud[key],
    })),
    cloudType,
  };
};
