import {
  Button,
  DraggableList,
  Picker,
  PopoverTriggerV2,
  useColumnsState,
  useTableOptions,
} from 'components';
import { useKeyExistsState } from 'hooks';
import React, { ReactElement, useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { IoMdClose } from 'react-icons/io';
import { tableColumns } from 'screens/Logs/constants';
import delimiter from 'kfuse-constants/delimiter';

const DashboardEditLogEventColumns = ({
  columnsState,
  customColumnsState,
  tableOptions,
}: {
  columnsState: ReturnType<typeof useColumnsState>;
  customColumnsState: ReturnType<typeof useKeyExistsState>;
  tableOptions?: ReturnType<typeof useTableOptions>;
}): ReactElement => {
  const { setLinesToShow, state: tableOptionsState } = tableOptions;
  const { columns, renderedColumns, toggleSelectedColumnByKey } = columnsState;
  const columnList = useMemo(
    () =>
      tableColumns({ columnsState, customColumnsState, logsState: {} }).map(
        (column) => ({ id: column.key, content: column.label }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsState.state, customColumnsState.state],
  );

  const unSelectedColumns = useMemo(() => {
    return columns.filter((column) =>
      renderedColumns.every((c) => c.key !== column.key),
    );
  }, [columns, renderedColumns]);

  return (
    <div>
      <div>
        <div className="text-sm font-medium pb-1">Select columns</div>
        <div className="flex">
          <DraggableList
            items={columnList}
            renderList={(list) => (
              <>
                {list.map((item, index: number) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className="flex"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <div {...provided.dragHandleProps}></div>
                        <div className="flex mr-1 pt-1 pb-1 pl-2 bg-primary text-primary-foreground items-center border rounded-md">
                          <div>{item.content}</div>
                          <Button
                            className="h-full ml-1 pl-1 pr-1"
                            size="sm"
                            variant="ghost"
                            onClick={() => {
                              const isColumnFacet = item.id.includes(delimiter);
                              if (isColumnFacet) {
                                customColumnsState.toggleKeyExists(item.id);
                              }
                              toggleSelectedColumnByKey(item.id);
                            }}
                          >
                            <IoMdClose onClick={null} size={16} />
                          </Button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              </>
            )}
          />
          <div>
            <PopoverTriggerV2
              popover={({ close }) => (
                <div>
                  {unSelectedColumns.map((column) => (
                    <div
                      key={column.key}
                      className="cursor-pointer pl-1 pt-1 pb-1 hover:bg-interaction-primary hover:text-white"
                      onClick={() => {
                        const isColumnFacet = column.key.includes(delimiter);

                        if (isColumnFacet) {
                          customColumnsState.toggleKeyExists(column.key);
                        }
                        columnsState.setSelectedColumnByKey(column.key);

                        close();
                      }}
                    >
                      <div>{column.label}</div>
                    </div>
                  ))}
                </div>
              )}
            >
              <Button variant="outline" size="sm">
                Add more column
              </Button>
            </PopoverTriggerV2>
          </div>
        </div>
      </div>
      <div>
        <div className="text-sm font-medium pt-3">Set lines</div>
        <div>
          <Picker
            onChange={setLinesToShow}
            options={[1, 3, 5, 10].map((value) => ({
              label: String(value),
              value,
            }))}
            value={tableOptionsState.linesToShow}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardEditLogEventColumns;
