import React, { ReactElement } from 'react';

import { Geomap } from '../Chart';

const AnalyticsGeoMap = ({
  data,
}: {
  data: Record<string, string | number>;
}): ReactElement => {
  return <Geomap data={data} />;
};

export default AnalyticsGeoMap;
