import ct from 'countries-and-timezones';
import { isNil } from 'lodash';

import { AlertMuteTimeIntervalProps, AlertMuteTimingProps } from './types';

const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const monthsOfYear = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const defaultTimeInterval: AlertMuteTimeIntervalProps = {
  location: '',
  months: [],
  days_of_month: [],
  weekdays: [],
  years: [],
  times: [{ start_time: '', end_time: '' }],
};

export const defaultMuteTiming: AlertMuteTimingProps = {
  name: '',
  time_intervals: [defaultTimeInterval],
};

const replaceTimeZone = [['Asia/Kolkata', 'Asia/Calcutta']];
const AdditionalTimeZones = ['UTC', 'GMT', 'Local'];

export const getDaysOfWeek = () => {
  return daysOfWeek.map((day) => {
    return { label: day.slice(0, 3), value: day.toLowerCase() };
  });
};

export const getTimeZonesOption = () => {
  let allTimeZones = Object.keys(ct.getAllTimezones());
  // filter out Etc/GMT timezones
  allTimeZones = allTimeZones.filter((tz) => !tz.includes('Etc/'));

  // Replace the timezones
  replaceTimeZone.forEach(([oldTz, newTz]) => {
    allTimeZones = allTimeZones.map((tz) => {
      if (tz === oldTz) return newTz;
      return tz;
    });
  });
  const timeZones = allTimeZones.map((tz) => {
    return { label: tz, value: tz };
  });
  const additionalTimeZonesOption = AdditionalTimeZones.map((tz) => {
    return { label: tz, value: tz };
  });
  const timeZonesWithAdditional = [...timeZones, ...additionalTimeZonesOption];
  return timeZonesWithAdditional;
};

/**
 * Validate the months
 * @param months
 * The months of the year in either numerical or the full calendar month
 * example: 1, 2 valid
 * -1, -2 invalid
 * 12, 13 invalid
 * min: 1, max: 12
 * full calendar month: January, February, March, April, May, June, July, August, September, October, November, December
 */
const validateMonths = (months: string[]) => {
  const parseMonth = (month: string): number | null => {
    if (monthsOfYear.includes(month)) {
      return monthsOfYear.indexOf(month) + 1;
    }
    const monthNum = Number(month);
    if (isNaN(monthNum) || monthNum < 1 || monthNum > 12) {
      return null;
    }
    return monthNum;
  };

  return months.every((month) => {
    const isRange = month.includes(':');
    if (isRange) {
      const [start, end] = month.split(':').map((m) => m.trim());
      if (!start || !end) return false;
      const startMonth = parseMonth(start);
      const endMonth = parseMonth(end);

      if (startMonth === null || endMonth === null) return false;
      return endMonth >= startMonth;
    }

    const monthInt = parseMonth(month);
    if (monthInt === null) return false;
    return true;
  });
};

/**
 * Validate the days of the month
 * @param days
 * The days of the month, 1-31, of a month. Negative values can be used to represent days which begin at the end of the month
 * example: 1, 2 valid
 * -1, -2 valid
 * 32, 33 invalid
 * 1:30 valid
 * 31:30 invalid
 * 10:02 invalid
 */
const validateDaysOfMonth = (days: string[]) => {
  const isValidDay = (day: number, isNegative: boolean): boolean => {
    const absDay = Math.abs(day);
    const maxDay = isNegative ? 30 : 31;
    return absDay >= 1 && absDay <= maxDay;
  };

  const isValidRange = (start: number, end: number): boolean => {
    const startAbs = Math.abs(start);
    const endAbs = Math.abs(end);
    return (
      isValidDay(start, start < 0) &&
      isValidDay(end, end < 0) &&
      endAbs >= startAbs
    );
  };

  return days.every((day) => {
    if (day.includes(':')) {
      const [start, end] = day.split(':').map(Number);
      return !isNaN(start) && !isNaN(end) && isValidRange(start, end);
    } else {
      const dayInt = parseInt(day);
      return !isNaN(dayInt) && isValidDay(dayInt, dayInt < 0);
    }
  });
};

/**
 * Validate years
 * @param years
 * The years of the year, 2000-9999
 * example: 2024, 2025 valid
 * 1999, 1998 invalid
 * 10000, 10001 invalid
 * 2000:2024 valid
 * 2024:2025 invalid
 * 2024:2024 valid
 */
const validateYears = (years: string[]) => {
  const isValidYear = (year: number): boolean => {
    return year >= 2000 && year <= 9999;
  };

  return years.every((year) => {
    if (year.includes(':')) {
      const [start, end] = year.split(':').map(Number);

      if (!start || !end) return false;
      if (isNaN(start) || isNaN(end)) return false;
      const startYear = isValidYear(start);
      const endYear = isValidYear(end);
      if (!startYear || !endYear) return false;

      return end >= start;
    }

    const yearInt = parseInt(year);
    return isValidYear(yearInt);
  });
};

/**
 * Validate time hour and minute
 * @param time
 * The time in the format of hour:minute -> HH:mm
 * example: 00:00, 01:00 valid
 * 25:00, 26:00 invalid
 * 00:60, 01:60 invalid
 * 00:00:00 invalid
 * 00:00:01 invalid
 */
const validateTime = (time: string): boolean => {
  const [hour, minute] = time.split(':');
  if (hour === '' || minute === '') return false;
  if (hour.length !== 2 || minute.length !== 2) return false;
  const hourInt = Number(hour);
  const minuteInt = Number(minute);

  if (isNaN(hourInt) || isNaN(minuteInt)) return false;

  if (hourInt < 0 || hourInt >= 24) return false;
  if (minuteInt < 0 || minuteInt >= 60) return false;
  return true;
};

export const validateMuteTimingInterval = (
  muteTimingInterval: AlertMuteTimeIntervalProps[],
) => {
  const error: { [key: number]: { [key: string]: string } } = {};
  muteTimingInterval.forEach((interval, idx) => {
    if (!validateMonths(interval.months)) {
      if (!error[idx]) error[idx] = {};
      error[idx].months = 'Invalid months format';
    }
    if (!validateDaysOfMonth(interval.days_of_month)) {
      if (!error[idx]) error[idx] = {};
      error[idx].days_of_month = 'Invalid days of month format';
    }
    if (!validateYears(interval.years)) {
      if (!error[idx]) error[idx] = {};
      error[idx].years = 'Invalid years format';
    }
    interval.times.forEach((time, timeIdx) => {
      if (!time.start_time && !time.end_time) return;
      if (!validateTime(time.start_time)) {
        if (!error[idx]) error[idx] = {};
        const key = `times.${timeIdx}.start_time`;
        error[idx][key] = 'Invalid start time format';
      }
      if (!validateTime(time.end_time)) {
        if (!error[idx]) error[idx] = {};
        const key = `times.${timeIdx}.end_time`;
        error[idx][key] = 'Invalid end time format';
      }
    });
  });

  return Object.keys(error).length > 0 ? error : null;
};
