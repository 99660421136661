import { AutocompleteOption } from 'components';
import { ContactNotifierType } from '../types';

const SUPPORTED_NOTIFIER = [
  'email',
  'slack',
  'webhook',
  'pagerduty',
  'teams',
  'opsgenie',
  'googlechat',
];

export const getNotifiersTypeOptions = (
  notifiers: Array<any>,
): AutocompleteOption[] => {
  const notifiersTypeOptions: AutocompleteOption[] = [];
  notifiers.forEach((notifier) => {
    if (SUPPORTED_NOTIFIER.includes(notifier.type)) {
      notifiersTypeOptions.push({
        label: notifier.name,
        value: notifier.type,
      });
    }
  });
  return notifiersTypeOptions;
};

export const getContactPointSettings = (
  notifierOptions: ContactNotifierType['options'],
  selectedData: { [key: string]: any },
): {
  settings: { [key: string]: any };
  secureSettings: { [key: string]: any };
} => {
  const settings: { [key: string]: any } = {};
  const secureSettings: { [key: string]: any } = {};
  notifierOptions.forEach((option) => {
    if (selectedData[option.propertyName]) {
      if (option.secure) {
        secureSettings[option.propertyName] = selectedData[option.propertyName];
      } else {
        settings[option.propertyName] = selectedData[option.propertyName];
      }
    }
  });

  return { settings, secureSettings };
};

export const validateContactRequiredFields = ({
  dependencyRelation,
  getNotifierData,
  secureFieldsArray,
  selectedNotifierData,
  selectedNotifierTypes,
}: {
  dependencyRelation: Record<string, string[]>;
  getNotifierData: (notifierType: string) => ContactNotifierType;
  secureFieldsArray: Array<Record<string, boolean>>;
  selectedNotifierTypes: string[];
  selectedNotifierData: Array<{ [key: string]: string }>;
}): boolean => {
  let isValid = true;
  selectedNotifierTypes.forEach((notifierType, index) => {
    const { options } = getNotifierData(notifierType);
    const selectedData = selectedNotifierData[index];
    const secureFields = secureFieldsArray[index];
    options.forEach((option) => {
      if (
        isDisabledField({
          field: option.propertyName,
          fieldsToDisableForSecure: dependencyRelation,
          secureFields,
        })
      ) {
        return;
      }
      const { dependsOn, propertyName, required } = option;
      const hasData = selectedData[propertyName];

      if (hasData) {
        return;
      }

      if (required && !hasData) {
        isValid = false;
      }

      if (dependsOn) {
        const hasDependsOnData = selectedData[dependsOn];
        if (hasDependsOnData) {
          isValid = true;
        }
      }
    });
  });
  return isValid;
};

export const buildOptionsDependencyRelation = (
  notifiers: {
    type: string;
    options: { dependsOn: string; propertyName: string }[];
  }[],
): Record<string, string[]> => {
  const dependsOnHash: Record<string, string[]> = {};
  try {
    const filteredNotifiers = notifiers.filter((notifier: { type: string }) =>
      SUPPORTED_NOTIFIER.includes(notifier.type),
    );
    filteredNotifiers.forEach(
      (notifier: {
        options: Array<{ dependsOn: string; propertyName: string }>;
      }) => {
        notifier.options.forEach((option) => {
          if (option.dependsOn) {
            if (!dependsOnHash[option.dependsOn]) {
              dependsOnHash[option.dependsOn] = [];
            }
            dependsOnHash[option.dependsOn].push(option.propertyName);
          }
        });
      },
    );
  } catch (e) {
    console.error(e);
  }
  return dependsOnHash;
};

export const isDisabledField = ({
  secureFields = {},
  field,
  fieldsToDisableForSecure,
}: {
  secureFields: Record<string, boolean>;
  field: string;
  fieldsToDisableForSecure: Record<string, string[]>;
}): boolean => {
  if (secureFields[field]) {
    return true;
  }

  const secureFieldsKeysWithOnlyTrue = Object.keys(secureFields).filter(
    (key) => secureFields[key],
  );

  const isDisabled = secureFieldsKeysWithOnlyTrue.some((key) => {
    const keyField: keyof typeof fieldsToDisableForSecure = key;
    if (
      fieldsToDisableForSecure[keyField] &&
      fieldsToDisableForSecure[keyField].includes(field)
    ) {
      return true;
    }
  });
  return isDisabled;
};
