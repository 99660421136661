export type FolderProps = {
  id: string;
  title: string;
  uid: string;
};

export enum FolderPermissionCategory {
  ROLE = 'role',
  USER = 'user',
  TEAM = 'team',
}

export type FolderPermissionsProps = {
  actions: string[];
  builtInRole: string;
  id: string;
  isInherited: boolean;
  isManaged: boolean;
  isServiceAccount: boolean;
  permission: number;
  roleName: string;
  team: string;
  teamAvatarUrl: string;
  teamId: string;
  userAvatarUrl: string;
  userId: number;
  userLogin: string;
};
