import { ChipWithLabel, ServiceWithLabels } from 'components';
import dayjs from 'dayjs';
import { useRequest } from 'hooks';
import chartingPalette from 'kfuse-constants/chartingPalette';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getService } from 'requests';

const AlertDetailsServiceLabel = ({
  serviceHash,
}: {
  serviceHash: string;
}): ReactElement => {
  const getServiceRequest = useRequest(getService);

  useEffect(() => {
    const date = {
      endTimeUnix: dayjs().unix(),
      startTimeUnix: dayjs().subtract(1, 'hour').unix(),
    };

    if (serviceHash) {
      getServiceRequest.call({ date, serviceHash });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceHash]);

  const service = getServiceRequest.result || null;

  const urlSearchParamsToUse = useMemo(() => {
    const urlSearchParams = new URLSearchParams();
    const dateParams = {
      endTimeUnix: dayjs().unix(),
      startTimeUnix: dayjs().subtract(1, 'hour').unix(),
    };
    urlSearchParams.set('date', JSON.stringify(dateParams));
    return urlSearchParams;
  }, []);

  if (!service) {
    return null;
  }

  return (
    <div className="flex pb-1 pt-2">
      <span className="text-sm font-bold text-text-secondary">Service:</span>
      <ChipWithLabel
        color={chartingPalette[0]}
        className="ml-2"
        label={
          <Link
            className="link text--weight-medium services__table__link"
            to={{
              pathname: `/apm/services/${serviceHash}`,
              search: urlSearchParamsToUse.toString(),
            }}
          >
            <ServiceWithLabels
              className="alerts__details__service__label"
              name={service.name}
              distinctLabels={service.distinctLabels}
              labels={service.labels}
            />
          </Link>
        }
      />
    </div>
  );
};

export default AlertDetailsServiceLabel;
