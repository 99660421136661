import { FunctionNamesProps } from 'types/MetricsQueryBuilder';

const functionsNames: FunctionNamesProps[] = [
  {
    name: 'Anomalies',
    category: 'Algorithms',
    description:
      'Overlay a band on the metric, showing the expected behavior of a series based on past values.',
    expression: 'anomalies()',
    shortName: 'anomalies',
    vectorType: 'range',
    algorithm: 'Algorithm: method to detect anomalies',
    tolerance: 'Bounds: width value where anomolies occur',
    imageUrl: '',
  },
  {
    name: 'Outliers',
    category: 'Algorithms',
    description: 'Highlight outliers series.',
    expression: 'outliers()',
    shortName: 'outliers',
    vectorType: 'range',
    algorithm: 'Algorithm: algorithm used to detect outliers',
    tolerance: 'Tolerance: tolerance of outliers algorithm',
    imageUrl: '',
  },
  {
    name: 'Forecast',
    category: 'Algorithms',
    description: 'Forecast future values based on past values.',
    expression: 'forecast()',
    shortName: 'forecast',
    vectorType: 'range',
    algorithm: 'Algorithm: methodology used to forecast the metric',
    imageUrl: '',
  },
  {
    name: 'Average',
    category: 'Aggregation',
    description:
      'Average value for a specific metric across aggregated or grouped data points.',
    expression: 'avg_by()',
    shortName: 'avg_by',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Minimum',
    category: 'Aggregation',
    description:
      'Smallest or lowest value observed among the aggregated data points.',
    expression: 'min_by()',
    shortName: 'min_by',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Maximum',
    category: 'Aggregation',
    description:
      'Largest or highest value observed among the aggregated data points.',
    expression: 'max_by()',
    shortName: 'max_by',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Sum',
    category: 'Aggregation',
    description: 'Sum of values observed among the aggregated data points.',
    expression: 'sum_by()',
    shortName: 'sum_by',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Count',
    category: 'Aggregation',
    description: 'Count of values observed among the aggregated data points.',
    expression: 'count_by()',
    shortName: 'count_by',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Standard Variance',
    category: 'Aggregation',
    description:
      'Standard variance of values observed among the aggregated data points.',
    expression: 'stdvar_by()',
    shortName: 'stdvar_by',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Standard Deviation',
    category: 'Aggregation',
    description:
      'Standard deviation of values observed among the aggregated data points.',
    expression: 'stddev_by()',
    shortName: 'stddev_by',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Quantile',
    category: 'Aggregation',
    description:
      'Specific value or point in a dataset that divides the data into ordered subsets with equal probabilities.',
    expression: 'quantile_by()',
    shortName: 'quantile_by',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Absolute Value',
    category: 'Arithmetic',
    description: 'Absolute value of the metric.',
    expression: 'abs()',
    shortName: 'abs',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Exponential',
    category: 'Arithmetic',
    description:
      'Calculates the exponential function for all elements of the time series.',
    expression: 'exp()',
    shortName: 'exp',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Floor',
    category: 'Arithmetic',
    description:
      'Rounds <strong>down</strong> the sample values of all elements in the time series to the nearest integer.',
    expression: 'floor()',
    shortName: 'floor',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Histogram Quantile',
    category: 'Arithmetic',
    description:
      'Calculates the φ-quantile (0 ≤ φ ≤ 1) from the buckets of the histogram.',
    expression: 'histogram_quantile(φ, le)',
    shortName: 'histogram_quantile',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Log 2',
    category: 'Arithmetic',
    description:
      'Calculates Log 2, the binary logarithm, of the metric; log<sub>2</sub>.',
    expression: 'log2()',
    shortName: 'log2',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Log 10',
    category: 'Arithmetic',
    description:
      'Calculates Log 10, decimal or common logarithm, of the metric; log<sub>10</sub>.',
    expression: 'log10()',
    shortName: 'log10',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Scalar',
    category: 'Arithmetic',
    description:
      'Determines the sample value of a single-element input time series <strong>v</strong>, <code>scalar(v instant-vector)</code>, and converts it to a scalar value.',
    expression: 'scalar()',
    shortName: 'scalar',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'ln',
    category: 'Arithmetic',
    description:
      'Calculates the natural logarithm for all elements in the time series; log<sub>e</sub>.',
    expression: 'ln()',
    shortName: 'ln',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Round',
    category: 'Arithmetic',
    description:
      'Rounds the sample values of all elements in the time series to the nearest integer.',
    expression: 'round()',
    shortName: 'round',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'SGN',
    category: 'Arithmetic',
    description:
      'Returns a vector with all sample values in the time series converted to their sign, <strong>+</strong> or <strong>-</strong>.',
    expression: 'sgn()',
    shortName: 'sgn',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Square Root',
    category: 'Arithmetic',
    description:
      'Calculates the square root of all values in the time series, <strong>&#8730;<em>value</em></strong>.',
    expression: 'sqrt()',
    shortName: 'sqrt',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Ceil',
    category: 'Arithmetic',
    description:
      'Rounds <strong>up</strong> the sample values of all elements in the time series to the nearest integer.',
    expression: 'ceil()',
    shortName: 'ceil',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Timestamp',
    category: 'Arithmetic',
    description:
      'Returns the timestamp of each value in the time series in epoch format, or the number of seconds after January 1, 1970 UTC.',
    expression: 'timestamp()',
    shortName: 'timestamp',
    vectorType: 'instant',
    imageUrl: '',
  },
  // {
  //   name: 'Day before',
  //   category: 'Timeshift',
  //   description: 'Shifts the time range by one day',
  //   expression: 'offset 1d',
  //   shortName: 'day_before',
  //   vectorType: 'instant',
  // },
  // {
  //   name: 'Hour before',
  //   category: 'Timeshift',
  //   description: 'Shifts the time range by one hour',
  //   expression: 'offset 1h',
  //   shortName: 'hour_before',
  //   vectorType: 'instant',
  // },
  // {
  //   name: 'Month before',
  //   category: 'Timeshift',
  //   description: 'Shifts the time range by one month',
  //   expression: 'offset 4w',
  //   shortName: 'month_before',
  //   vectorType: 'instant',
  // },
  // {
  //   name: 'Week before',
  //   category: 'Timeshift',
  //   description: 'Shifts the time range by one week',
  //   expression: 'offset 1w',
  //   shortName: 'week_before',
  //   vectorType: 'instant',
  // },
  {
    name: 'Count non zero',
    category: 'Count',
    description: 'Computes the count of all non-zero values.',
    expression: 'count_nonzero()',
    shortName: 'count_nonzero',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Count not null',
    category: 'Count',
    description: 'Computes the count of all non-null values.',
    expression: 'count_not_null()',
    shortName: 'count_not_null',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Absent',
    category: 'Count',
    description:
      'Returns an empty vector if the time series has any elements, or a 1-element vector with value 1 if the time series has no elements.',
    expression: 'absent()',
    shortName: 'absent',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Absent Over Time',
    category: 'Count',
    description:
      'Returns an empty vector if the range time series has any elements, or a 1-element vector with value 1 if the range time series has no elements.',
    expression: 'absent_over_time()',
    shortName: 'absent_over_time',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Changes',
    category: 'Count',
    description:
      'Returns the count of instances when the time series value changed within the specified time range, as an instant vector.',
    expression: 'changes()',
    shortName: 'changes',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Resets',
    category: 'Count',
    description:
      'Returns the number of counter resets, <code>resets(v range-vector)</code>, within the specified time range, for each input time series, as an instant vector.',
    expression: 'resets()',
    shortName: 'resets',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Day of week',
    category: 'Time',
    description:
      'Returns the day of the week for each element in the time series, in UTC. Valid values range from 0 to 6, where 0 is Sunday, 1 is Monday, and so on.',
    expression: 'day_of_week()',
    shortName: 'day_of_week',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Day of month',
    category: 'Time',
    description:
      'Returns the day of the month for each element in the time series, in UTC. Valid values range from 1 to 31.',
    expression: 'day_of_month()',
    shortName: 'day_of_month',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Day of year',
    category: 'Time',
    description:
      'Returns the day of the year for each element in the time series, in UTC. Valid values range from 1 to 365 for non-leap years, and from 1 to 366 for leap years.',
    expression: 'day_of_year()',
    shortName: 'day_of_year',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Days in month',
    category: 'Time',
    description:
      'Returns the number of days in the month for element in the time series, in UTC. Valid values range from 28 to 31.',
    expression: 'days_in_month()',
    shortName: 'days_in_month',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Hour',
    category: 'Time',
    description:
      'Returns the hour of the day for each element in the time series, in UTC. Valid values range from 0 to 23.',
    expression: 'hour()',
    shortName: 'hour',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Minute',
    category: 'Time',
    description:
      'Returns the minute of the hour for each element of the times series, in UTC. Valid values range from 0 to 59.',
    expression: 'minute()',
    shortName: 'minute',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Month',
    category: 'Time',
    description:
      'Returns the month of the year for each element of the time series, in UTC. Valid values range from 1 to 12.',
    expression: 'month()',
    shortName: 'month',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Year',
    category: 'Time',
    description:
      'Returns the year for each element of the time series, in UTC.',
    expression: 'year()',
    shortName: 'year',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Diff',
    category: 'Rate',
    description: 'Graphs the delta of the metric, <strong>&#8796;</strong>.',
    expression: 'delta()',
    shortName: 'diff',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Derivative',
    category: 'Rate',
    description:
      'Graphs the time derivative of the metric, <strong><math><mfrac><mrow><mn>&#8796;</mn></mrow><mrow><mn>dt</mn></mrow></mfrac></math></strong>.',
    expression: 'deriv()',
    shortName: 'deriv',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Monotonic Diff',
    category: 'Rate',
    description:
      'Graphs the delta (&#8796;) of the metric, but only if it is positive.',
    expression: 'idelta()',
    shortName: 'monotonic_diff',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Irate',
    category: 'Rate',
    description:
      'Calculates the per-second instant rate of increase of the time series in the range vector, based on the last two data points. Automatically adjusts for breaks in monotonicity, like counter resets due to target restarts.',
    expression: 'irate()',
    shortName: 'irate',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Per hour',
    category: 'Rate',
    description: 'Graphs the rate of metric change per hour.',
    expression: 'rate()',
    shortName: 'rate_per_hour',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Per minute',
    category: 'Rate',
    description: 'Graphs the rate of metric change per minute.',
    expression: 'rate()',
    shortName: 'rate_per_minute',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Per second',
    category: 'Rate',
    description: 'Graphs the rate of metric change per second.',
    expression: 'rate()',
    shortName: 'rate_per_second',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Increase',
    category: 'Rate',
    description:
      'Calculates the increase in the time series across the range vector.',
    expression: 'increase()',
    shortName: 'increase',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Clamp Max',
    category: 'Exclusion',
    description:
      'Lowers all metric values greater than the threshold to that treshold value.',
    expression: 'clamp_max()',
    shortName: 'clamp_max',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Clamp Min',
    category: 'Exclusion',
    description:
      'Raises all metric values lower than the threshold to that treshold value.',
    expression: 'clamp_min()',
    shortName: 'clamp_min',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Cutoff Max',
    category: 'Exclusion',
    description: 'Removes all metric values greater than the threshold value.',
    expression: 'cutoff_max()',
    shortName: 'cutoff_max',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Cutoff Min',
    category: 'Exclusion',
    description: 'Removes all metric values less than the threshold value.',
    expression: 'cutoff_min()',
    shortName: 'cutoff_min',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Clamp',
    category: 'Exclusion',
    description:
      'Clamps the values of all elements in the time series to <strong>min</strong> and <strong>max</strong>. This lowers values greater than and <strong>max</strong> to and <strong>max</strong>, and raises values lower than and <strong>min</strong> to and <strong>min</strong>.',
    expression: 'clamp()',
    shortName: 'clamp',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Top K',
    category: 'Rank',
    description: 'Graphs the top K elements.',
    expression: 'topk()',
    shortName: 'topk',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Sort',
    category: 'Rank',
    description: 'Sorts the time series by sample value, in ascending order.',
    expression: 'sort()',
    shortName: 'sort',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Sort Desc',
    category: 'Rank',
    description: 'Sorts the time series by sample value, in descending order.',
    expression: 'sort_desc()',
    shortName: 'sort_desc',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Average Over Time',
    category: 'Rollup',
    description:
      'Calculates the average value of all points in the specified interval.',
    expression: 'avg_over_time()',
    shortName: 'avg_over_time',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Min Over Time',
    category: 'Rollup',
    description:
      'Calculates the minimum value of all points in the specified interval.',
    expression: 'min_over_time()',
    shortName: 'min_over_time',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Max Over Time',
    category: 'Rollup',
    description:
      'Calculates the maximum value of all points in the specified interval.',
    expression: 'max_over_time()',
    shortName: 'max_over_time',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Sum Over Time',
    category: 'Rollup',
    description: 'Calculates the sum of all values in the specified interval.',
    expression: 'sum_over_time()',
    shortName: 'sum_over_time',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Count Over Time',
    category: 'Rollup',
    description: 'Counts of all values in the specified interval.',
    expression: 'count_over_time()',
    shortName: 'count_over_time',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Quantile Over Time',
    category: 'Rollup',
    description:
      'Calculates the φ-quantile (0 ≤ φ ≤ 1) of values in the specified interval.',
    expression: 'quantile_over_time()',
    shortName: 'quantile_over_time',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Deviation Over Time',
    category: 'Rollup',
    description:
      'Calculates the population standard deviation of values in the specified interval.',
    expression: 'stddev_over_time()',
    shortName: 'stddev_over_time',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Variance Over Time',
    category: 'Rollup',
    description: 'Calculates the variance of values in the specified interval.',
    expression: 'stdvar_over_time()',
    shortName: 'stdvar_over_time',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Last Over Time',
    category: 'Rollup',
    description:
      'Returns the most recent point value in the specified interval.',
    expression: 'last_over_time()',
    shortName: 'last_over_time',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Predict Linear',
    category: 'Regression',
    description:
      'Predicts the value of time series <strong>t</strong> seconds from <strong>now</strong> using simple linear regression.',
    expression: 'predict_linear()',
    shortName: 'predict_linear',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Holt Winters',
    category: 'Smoothing',
    description:
      'Produces a smoothed value for time series based on the range in the time series, accounting for seasonal adjustments. Implements the Holt-Winters method of exponentially-weighted averages as smoothing factors.<br>Lower values of smoothing factor <strong>sf</strong> weigh towards the importance of older data.<br>Higher values of the trend factor <strong>tf</strong> weigh in favor of trends in the time series.<br>Valid values for scalars <strong>sf</strong> and <strong>tf</strong> must be between 0 and 1.',
    expression: 'holt_winters()',
    shortName: 'holt_winters',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Acos',
    category: 'Trigonometric',
    description: 'Calculates the arccosine of all elements in the time series.',
    expression: 'acos()',
    shortName: 'acos',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Acosh',
    category: 'Trigonometric',
    description:
      'Calculates the inverse hyperbolic cosine of all elements in the time series.',
    expression: 'acosh()',
    shortName: 'acosh',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Asin',
    category: 'Trigonometric',
    description: 'Calculates the arcsine of all elements in the time series.',
    expression: 'asin()',
    shortName: 'asin',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Asinh',
    category: 'Trigonometric',
    description:
      'Calculates the inverse hyperbolic sine of all elements in the time series.',
    expression: 'asinh()',
    shortName: 'asinh',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Atan',
    category: 'Trigonometric',
    description:
      'Calculates the arctangent of all elements in the time series.',
    expression: 'atan()',
    shortName: 'atan',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Atanh',
    category: 'Trigonometric',
    description:
      'Calculates the inverse hyperbolic tangent of all elements in the time series.',
    expression: 'atanh()',
    shortName: 'atanh',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Cos',
    category: 'Trigonometric',
    description: 'Calculates the cosine of all elements in the time series.',
    expression: 'cos()',
    shortName: 'cos',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Cosh',
    category: 'Trigonometric',
    description:
      'Calculates the hyperbolic cosine of all elements in the time series.',
    expression: 'cosh()',
    shortName: 'cosh',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Sin',
    category: 'Trigonometric',
    description: 'Calculates the sine of all elements in the time series.',
    expression: 'sin()',
    shortName: 'sin',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Sinh',
    category: 'Trigonometric',
    description:
      'Calculates the hyperbolic sine of all elements in the time series.',
    expression: 'sinh()',
    shortName: 'sinh',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Tan',
    category: 'Trigonometric',
    description: 'Calculates the tangent of all elements in the time series.',
    expression: 'tan()',
    shortName: 'tan',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Tanh',
    category: 'Trigonometric',
    description:
      'Calculates the hyperbolic tangent of all elements in the time series.',
    expression: 'tanh()',
    shortName: 'tanh',
    vectorType: 'instant',
    imageUrl: '',
  },
];

export default functionsNames;
