import { TimeSeries } from 'types/generated';
import { DataFrame, DataFrameMeta } from '../types';
import { fillTimestampForWindow } from '../fillTimestampForWindow';
export const traceqlDataTransformer = ({
  datasets,
  meta,
}: {
  datasets: TimeSeries[];
  meta: DataFrameMeta;
}): DataFrame => {
  let minValue = Infinity;
  let maxValue = -Infinity;
  const { executedDate, step } = meta;
  const { timestamps, timestampBitmap } = fillTimestampForWindow({
    executedDate,
    step,
  });
  if (!datasets || datasets.length === 0) {
    return { data: [], meta, minValue, maxValue, timestamps };
  }
  const labelBitmap: Record<string, Record<number, number | undefined>> = {};

  datasets.forEach(({ BucketStartSecs, GroupVal, Value }) => {
    const label = JSON.stringify(GroupVal);
    timestampBitmap[BucketStartSecs] = true;
    labelBitmap[label] = labelBitmap[label] || {};
    labelBitmap[label][BucketStartSecs] = Value;
  });

  const data = Object.entries(labelBitmap).map(([label, valuesMap]) => {
    const values = timestamps.map((timestamp) => {
      const value = valuesMap[timestamp];
      if (value !== undefined) {
        if (value > maxValue) maxValue = value;
        if (value < minValue) minValue = value;
      }
      return value;
    });
    return { label: JSON.parse(label), values };
  });

  return { data, meta, minValue, maxValue, timestamps };
};

export const traceqlDataTransformerInstant = ({
  datasets,
  meta,
}: {
  datasets: TimeSeries[];
  meta: DataFrameMeta;
}): DataFrame => {
  const data: DataFrame['data'] = [];
  let maxValue = -Infinity;
  let minValue = Infinity;
  if (!datasets || datasets.length === 0) {
    return { data, meta, minValue, maxValue, timestamps: [] };
  }

  datasets.forEach((dataset) => {
    const { BucketStartSecs, GroupVal, Value } = dataset;
    minValue = Math.min(minValue, Value);
    maxValue = Math.max(maxValue, Value);

    if (Object.keys(GroupVal).length === 0) {
      GroupVal.label = meta.metricName;
    }
    data.push({
      label: GroupVal,
      values: [Value],
      timestamp: BucketStartSecs,
    });
  });

  return { data, meta, minValue, maxValue };
};
