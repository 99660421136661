import dayjs from 'dayjs';
import { DateSelection, KfuseQlResult } from 'types';
import {
  DataFrameMeta,
  DataTransformerConfig,
  getAdjustedStartAndEndTimeUnix,
  onPromiseError,
} from 'utils';

import query from './query';

type Args = {
  date: DateSelection;
  instant?: boolean;
  kfuseQl: string;
  meta: DataFrameMeta;
  transformer: DataTransformerConfig[];
};

const getLogMetricsResultWithKfuseQl = <T>({
  date,
  instant,
  meta,
  kfuseQl,
  transformer,
}: Args): Promise<T> => {
  const stepMs = meta.step * 1000;
  const adjusted = getAdjustedStartAndEndTimeUnix({
    allowFutureTime: true,
    date,
    step: `${stepMs}ms`,
  });
  const { endTimeUnix, startTimeUnix } = date;
  const endTime = dayjs.unix(instant ? endTimeUnix : adjusted.endTimeUnix);
  const startTime = dayjs.unix(
    instant ? startTimeUnix : adjusted.startTimeUnix,
  );

  meta.executedDate = adjusted;
  meta.executedQueryStr = kfuseQl;
  return query<KfuseQlResult[], 'getLogMetricsResultWithKfuseQl'>(`
    {
      getLogMetricsResultWithKfuseQl(
        query: "${kfuseQl}",
        startTs: "${startTime.format()}"
        endTs: "${endTime.format()}"
      ) {
        TableResult
        ColumnHeaders
      }
    }
  `).then((data) => {
    const initialData = { datasets: data.getLogMetricsResultWithKfuseQl, meta };
    const transformed = transformer.reduce(
      (prevData, item) => item.func(prevData),
      initialData,
    );
    return transformed as unknown as T;
  }, onPromiseError);
};

export default getLogMetricsResultWithKfuseQl;
