import { OutlierSpanProps, UplotExtended } from 'types/Timeseries';
import uPlot from 'uplot';

import { getNearestSpanPoint } from './getDrawnEventTooltip';

const getEventPopoverPosition = (
  e: { offsetX: number; offsetY: number },
  u: UplotExtended,
) => {
  const outlierSpanData = u.eventsData?.outlierSpan;
  if (!outlierSpanData) return;
  const hashMapList = Array.from(outlierSpanData.data.keys());

  const bbox = u.root.getBoundingClientRect();
  const x = u.bbox.left + e.offsetX * uPlot.pxRatio;
  let nearestSpanPoint:
    | { spanData: OutlierSpanProps; left: number }
    | undefined = undefined;
  const nearestSpanPointIndex = getNearestSpanPoint(x, hashMapList);
  if (!nearestSpanPointIndex) return;

  const nearestSpanPointData = outlierSpanData.data.get(
    hashMapList[nearestSpanPointIndex],
  );
  nearestSpanPoint = {
    spanData: nearestSpanPointData.span,
    left: hashMapList[nearestSpanPointIndex],
  };

  if (!nearestSpanPoint) return;

  const xAdjusted = nearestSpanPoint.left / uPlot.pxRatio;
  const top = bbox.top + e.offsetY;
  const positionX = x < window.innerWidth / 2 ? 'right' : 'left';
  return {
    x: xAdjusted + bbox.left,
    y: top,
    data: nearestSpanPoint.spanData,
    positionX,
  };
};

export default getEventPopoverPosition;
