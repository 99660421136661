import {
  Resizer,
  ResizerOrientation,
  TooltipTrigger,
  useLeftSidebarState,
} from 'components';
import { useRefContext } from 'hooks';
import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import { Filter, Minimize2 } from 'react-feather';
import * as Shadcn from 'components/shadcn';
import LeftSidebarSectionHeader from './LeftSidebarSectionHeader';
import { cn } from 'utils/tailwind';

type Props = {
  children: ReactNode;
  className?: string;
  leftSidebarState: ReturnType<typeof useLeftSidebarState>;
};

const LeftSidebar = ({
  className,
  children,
  leftSidebarState,
}: Props): ReactElement => {
  const { leftSidebarRef, bodyDivRef } = useRefContext();
  const { hide, onResize, width } = leftSidebarState;
  if (width === 0) {
    return null;
  }

  return (
    <Shadcn.ScrollArea
      viewportRef={leftSidebarRef}
      className={cn(
        'ml-3 pr-4 border-r border-solid border-border relative',
        className,
      )}
      data-testid="left-sidebar"
      style={
        {
          width: `${width}px`,
          '--left-sidebar-width': `${width}px`,
        } as CSSProperties
      }
    >
      <div className="mt-3 flex justify-between">
        <LeftSidebarSectionHeader iconName="filters" textSize="base">
          Filters
        </LeftSidebarSectionHeader>
        <TooltipTrigger
          asChild={true}
          className="left-sidebar__title__button"
          tooltip="Hide Filters"
          variant="compact"
          collisionBoundaryRef={bodyDivRef}
        >
          <button
            className="button button--icon"
            tabIndex={-1}
            onClick={hide}
            data-testid="show-hide-sidebar"
          >
            <Minimize2 size={12} />
          </button>
        </TooltipTrigger>
      </div>
      {children}
      <Resizer
        onMouseMove={onResize}
        orientation={ResizerOrientation.vertical}
      />
    </Shadcn.ScrollArea>
  );
};

export default LeftSidebar;
