import { fetchGrafanaApi } from 'requests';

const updateGroupById = async ({
  groupId,
  name,
  email,
}: {
  groupId: string;
  name: string;
  email: string;
}): Promise<Record<string, unknown>> => {
  const options = { method: 'PUT', body: JSON.stringify({ name, email }) };
  const result = await fetchGrafanaApi(`rbac/groups/${groupId}`, options);

  return result;
};

export default updateGroupById;
