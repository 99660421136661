import { getIsLogRangeFacet } from 'utils';
import { ValueCount } from 'types';

const mergeValueCountSubscription =
  (type: string) =>
  (prevValueCounts: ValueCount[], update: ValueCount[]): ValueCount[] => {
    const countsByValues: Record<string, number> = prevValueCounts.reduce(
      (obj, valueCount) => ({
        ...obj,
        [valueCount.value]: valueCount.count,
      }),
      {},
    );

    update.forEach((valueCount) => {
      const { count } = valueCount;
      const value = getIsLogRangeFacet(type.toLowerCase())
        ? valueCount.floatValue
        : valueCount.value;

      if (!countsByValues[value]) {
        countsByValues[value] = 0;
      }

      countsByValues[value] += count;
    });

    return Object.keys(countsByValues).map((value) => ({
      value,
      count: countsByValues[value],
    }));
  };

export default mergeValueCountSubscription;
