import { FolderPermissionsProps } from './types';

export const FolderPermissionOptions = [
  { label: 'Viewer', value: 'View' },
  { label: 'Editor', value: 'Edit' },
  { label: 'Admin', value: 'Admin' },
];

export const filterFolderPermissions = (
  permissions: FolderPermissionsProps[],
): {
  roles: FolderPermissionsProps[];
  users: FolderPermissionsProps[];
  groups: FolderPermissionsProps[];
} => {
  const roles: FolderPermissionsProps[] = [];
  const users: FolderPermissionsProps[] = [];
  const groups: FolderPermissionsProps[] = [];

  if (!permissions) {
    return { roles, users, groups };
  }

  permissions.forEach((permission) => {
    if (permission.builtInRole) {
      roles.push(permission);
    } else if (permission.teamId) {
      groups.push(permission);
    } else {
      users.push(permission);
    }
  });

  // sort roles keep admin at the top
  const sortedRoles = roles.sort((a, b) => {
    if (a.builtInRole === 'Admin') return -1;
    if (b.builtInRole === 'Admin') return 1;
    return 0;
  });

  // remove duplicates and keep the first one
  const uniqueRoles = sortedRoles.filter((role, index, self) => {
    return self.findIndex((t) => t.builtInRole === role.builtInRole) === index;
  });

  return { roles: uniqueRoles, users, groups };
};

export const filterLibraryTextFromPermissionActions = (actions: string[]) => {
  if (!actions) return [];
  return actions.filter((action) => !action.startsWith('library.panels'));
};
