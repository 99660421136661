import { AutocompleteOption } from 'components/Autocomplete';
import { logsRollupOptionsByRange } from 'kfuse-constants';
import { DateSelection } from 'types/index';

export const getRollupOptionsByTimeRange = ({
  date,
  minStep,
  step,
}: {
  date: DateSelection;
  minStep: string;
  step?: string;
}): AutocompleteOption[] => {
  const logsRollupOptions = logsRollupOptionsByRange(date, minStep);

  const isAutoExist = logsRollupOptions.find((item) => item.value === step);
  if (!isAutoExist && step) {
    return [{ value: step, label: `${step} (auto)` }, ...logsRollupOptions];
  }
  return logsRollupOptions;
};
