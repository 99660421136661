import { escapeDoubleQuote, getIsInQuotes, getWithoutQuotes } from 'utils';

const buildSearchTermsFilter = (searchTerms: string[]): string => {
  let result = '';

  searchTerms.forEach((rawSearchTerm: string) => {
    const isNeq = rawSearchTerm[0] === '!';
    const searchTerm = isNeq ? rawSearchTerm.slice(1) : rawSearchTerm;
    const isInQuotes = getIsInQuotes(searchTerm);
    const searchTermWithoutQuotes = isInQuotes
      ? getWithoutQuotes(searchTerm)
      : searchTerm;

    const sanitzedSearchTerm = escapeDoubleQuote(
      searchTermWithoutQuotes.replace(/\\=/g, '=').replace(/\\/g, '\\\\'),
    );

    const terms = sanitzedSearchTerm.split(' OR ');

    const clauses = terms.map((term) =>
      isInQuotes
        ? `{contains: {facetName: "msg", value: "${term}"}}`
        : `{termsExist: "${term}"}`,
    );

    const query =
      clauses.length > 1 ? `{or: [${clauses.join('')}]}` : clauses[0];

    if (isNeq) {
      result += `{not: ${query}}`;
    } else {
      result += query;
    }
  });

  return result;
};

export default buildSearchTermsFilter;
