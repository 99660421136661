import { fetchJson } from 'requests';

import { PolicyProps } from '../types';

const getPolicyByName = async (name: string): Promise<PolicyProps> => {
  const result = await fetchJson(`rbac/policies/${name}`, {
    method: 'GET',
  });

  return result as PolicyProps;
};

export default getPolicyByName;
