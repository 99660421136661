export const logsErrors = {
  getLogsFacetNames: 'Failed to fetch facet names by source',
  getLogsSources: 'Failed to fetch logs sources',
  getLogsFavoriteFacet: 'Failed to fetch favorite logs facets',
  getLogMetricsExplorer: 'Failed to fetch logs metrics explorer',
  getLogFacetValueCounts: 'Failed to fetch log facet value counts',
  getLabelCardinality: 'Failed to fetch label cardinality',
  getLabelNames: 'Failed to fetch label names',
  getArchivesList: 'Failed to fetch archive list',
};
