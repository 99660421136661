import {
  FacetRegexTerm,
  KeyExists,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  SpanFilter,
} from 'types';
import { getIsDeselecting, getIsHcFacetName } from 'utils';

type Args = {
  customerFilter?: { key: string; value: string };
  facetRegex: FacetRegexTerm[];
  keyExists: KeyExists;
  parentSpanIdFilter?: string;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  spanFilter?: SpanFilter;
  traceIdSearch?: string;
};

const buildSelectedFacetValuesByNameFilter = ({
  selectedFacetValuesByName,
  result,
}) => {
  const names = Object.keys(selectedFacetValuesByName);
  names.forEach((name) => {
    const key = getIsHcFacetName(name) ? 'jsonFilter' : 'attributeFilter';
    const selectedFacetValues = selectedFacetValuesByName[name];
    const isDeselecting = getIsDeselecting(selectedFacetValues);

    const clauseKey = isDeselecting ? 'and' : 'or';
    const clause = { [clauseKey]: [] };

    Object.keys(selectedFacetValues).forEach((value) => {
      const innerClause: { [key: string]: any } = {};

      const innerClauseKey = key;
      const equalityClause = {
        [isDeselecting ? 'neq' : 'eq']:
          key === 'jsonFilter'
            ? { keyPath: name, value }
            : { key: name, value },
      };

      innerClause[innerClauseKey] = equalityClause;
      clause[clauseKey].push(innerClause);
    });

    result.and.push(clause);
  });
};

const buildTracesFilterToJSON = ({
  customerFilter,
  facetRegex,
  keyExists,
  parentSpanIdFilter,
  selectedFacetRangeByName = {},
  selectedFacetValuesByName = {},
  spanFilter,
  traceIdSearch,
}: Args): any => {
  const result = { and: [] };

  buildSelectedFacetValuesByNameFilter({
    selectedFacetValuesByName,
    result,
  });

  if (selectedFacetRangeByName) {
    Object.keys(selectedFacetRangeByName).forEach((name) => {
      const { lower, upper } = selectedFacetRangeByName[name];
      result.and.push({
        durationFilter: {
          lowerBound: lower * 1000000,
          upperBound: upper * 1000000,
        },
      });
    });
  }

  if (keyExists) {
    Object.keys(keyExists)
      .filter((key) => keyExists[key])
      .forEach((name) => {
        result.and.push({
          attributeFilter: { keyExists: { key: name, value: '' } },
        });
      });
  }

  if (
    customerFilter &&
    customerFilter.key &&
    customerFilter.value &&
    customerFilter.value !== 'All'
  ) {
    result.and.push({
      attributeFilter: { key: customerFilter.key, value: customerFilter.value },
    });
  }

  if (parentSpanIdFilter) {
    result.and.push({ ParentSpanIdFilter: { id: parentSpanIdFilter } });
  }

  if (spanFilter && spanFilter !== SpanFilter.allSpans) {
    result.and.push({ spanTypeFilter: { spanType: spanFilter } });
  }

  if (traceIdSearch) {
    result.and.push({ traceIdFilter: { id: traceIdSearch } });
  }

  facetRegex?.forEach((facetRegexTerm) => {
    result.and.push({
      attributeFilter: {
        [facetRegexTerm.isEqual ? 'regex' : 'nregex']: {
          key: facetRegexTerm.name,
          value: facetRegexTerm.value,
        },
      },
    });
  });

  return result;
};

export default buildTracesFilterToJSON;
