import { useMemo, useState } from 'react';
import {
  generatePath,
  NavigateOptions,
  To,
  Location,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { facetValueToggleUtil, tryJsonParse } from 'utils';
import { SelectedFacetValuesByName } from 'types';
import { EntityTypes } from '../types';
import { pickBy, size } from 'lodash';
import { useDateState } from 'hooks';
import { KubernetesTableRow } from '../types/tables';
import { getFacetValuesFromFilterKey } from '../utils';

const DEFAULT_PAGE_LIMIT = 10;

export const KUBERNETES_PATH_STRING =
  '/kubernetes/:entitiesType?/:activeKube?/:tab?';

type KubernetesTo =
  | To
  | ((options: {
      location: Location;
      searchParams: URLSearchParams;
      pathTemplate: typeof KUBERNETES_PATH_STRING;
      params: {
        entitiesType: EntityTypes;
        activeKube?: string;
        tab?: string;
      };
    }) => To);

type KubernetesSearchParams = {
  page: number;
  limit: number;
  sort: {
    key: string;
    type: 'TAG' | 'LABEL' | 'ANNOTATION' | 'DATA';
    order: 'Asc' | 'Desc';
  };
  facets: SelectedFacetValuesByName;
};

function isRouterTo(options: KubernetesTo): options is To {
  return typeof options === 'object';
}

export type KubernetesTableSort = KubernetesSearchParams['sort'];

// This serves as adapter from old interface to new interface
export default function useKubesFluxState({
  entitiesType,
}: {
  entitiesType: EntityTypes;
}) {
  const [searchParams, setSearchParams] = useSearchParams({
    page: '0',
    limit: String(DEFAULT_PAGE_LIMIT),
    kubesSort: JSON.stringify({
      key: '',
      type: '',
      order: 'Asc',
    }),
  });
  const params = useParams();
  const location = useLocation();
  const routeNavigate = useNavigate();

  const entityType = entitiesType;
  const kubesSort: KubernetesTableSort = useMemo(() => {
    return tryJsonParse(searchParams.get('kubesSort'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get('kubesSort')]);
  const selectedFacetValuesByName: SelectedFacetValuesByName = useMemo(() => {
    return tryJsonParse(searchParams.get('selectedFacetValuesByName')) ?? {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get('selectedFacetValuesByName')]);
  const page = Number(searchParams.get('page') ?? 0);
  const limit = searchParams.get('limit') ?? DEFAULT_PAGE_LIMIT;
  const groupBySearchTerms: GroupBySearchParams = useMemo(() => {
    return tryJsonParse(searchParams.get('groupBySearchTerms')) ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get('groupBySearchTerms')]);

  const [entitiesLastLoaded, setEntitiesLastLoaded] = useState(
    performance.now(),
  );

  const [date] = useDateState();

  const navigate = (fn: KubernetesTo, options?: NavigateOptions) => {
    if (isRouterTo(fn)) {
      routeNavigate(fn, options);
    } else {
      routeNavigate(
        fn({
          location,
          params: {
            ...params,
          },
          searchParams: new URLSearchParams(searchParams.toString()),
          pathname: location.pathname,
          pathTemplate: KUBERNETES_PATH_STRING,
          hash: location.hash,
        }),
        options,
      );
    }
  };

  const flux = {
    navigate,

    facets: selectedFacetValuesByName,
    date,

    entityType,
    setEntityType(entityType: EntityTypes) {
      navigate(`/kubernetes/${entityType}`);
    },

    entitiesType: entityType,
    setEntitiesType(entityType: EntityTypes) {
      navigate(`/kubernetes/${entityType}`);
    },

    entitiesLastLoaded,
    entitiesReload() {
      setEntitiesLastLoaded(performance.now());
      flux.setPage(0); // reset page on reload
    },

    kubesSort,
    setKubesSort(sort: KubernetesTableSort) {
      setSearchParams(
        (params) => {
          params.set('kubesSort', JSON.stringify(sort));

          params.sort();

          return params;
        },
        { replace: true },
      );
    },

    limit,
    setLimit(limit: number | null) {
      setSearchParams(
        (params) => {
          if (limit == null) {
            params.set('limit', 'all');
          } else {
            params.set('limit', String(limit));
          }

          params.sort();

          return params;
        },
        { replace: true },
      );
    },

    page,
    setPage(page: number) {
      setSearchParams(
        (params) => {
          const pageString = String(page);

          if (pageString === '0') {
            params.delete('page');
          } else {
            params.set('page', pageString);
          }

          params.sort();

          return params;
        },
        { replace: true },
      );
    },

    groupBySearchTerms,
    setGroupBySearchTerms(searchTerms: GroupBySearchParams) {
      setSearchParams((params) => {
        params.set('groupBySearchTerms', JSON.stringify(searchTerms));

        if (params.get('groupBySearchTerms') === '[]') {
          params.delete('groupBySearchTerms');
        }

        return params;
      });
    },

    facetValueReplace(values) {
      setSearchParams(
        (params) => {
          params.set('selectedFacetValuesByName', JSON.stringify(values));

          params.delete('page');
          params.delete('expandedGroup');

          if (params.get('selectedFacetValuesByName') === '{}') {
            params.delete('selectedFacetValuesByName');
          }

          params.sort();

          return params;
        },
        { replace: true },
      );
    },

    facetValueSet(name: string, values) {
      navigate(({ searchParams, location, params }) => {
        searchParams.set(
          'selectedFacetValuesByName',
          JSON.stringify({
            ...tryJsonParse(searchParams.get('selectedFacetValuesByName')),

            [name]: values,
          }),
        );

        searchParams.delete('page');
        searchParams.delete('expandedGroup');

        if (searchParams.get('selectedFacetValuesByName') === '{}') {
          searchParams.delete('selectedFacetValuesByName');
        }

        searchParams.sort();

        return {
          ...location,

          pathname: generatePath(KUBERNETES_PATH_STRING, {
            ...params,

            activeKube: null,
            tab: null,
          }),
          search: searchParams.toString(),
        };
      });
    },

    facetValueExclude(name: string, value: string) {
      navigate(
        ({ searchParams, location, params }) => {
          const selectedFacetValuesByName = tryJsonParse(
            searchParams.get('selectedFacetValuesByName'),
          );

          searchParams.delete('page');
          searchParams.delete('expandedGroup');

          searchParams.set(
            'selectedFacetValuesByName',
            JSON.stringify({
              ...selectedFacetValuesByName,
              [name]: {
                [value]: 0,
              },
            }),
          );

          if (searchParams.get('selectedFacetValuesByName') === '{}') {
            searchParams.delete('selectedFacetValuesByName');
          }

          searchParams.sort();

          return {
            ...location,

            pathname: generatePath(KUBERNETES_PATH_STRING, {
              ...params,

              activeKube: null,
              tab: null,
            }),
            search: searchParams.toString(),
          };
        },
        {
          replace: true,
        },
      );
    },

    facetValueOnly(name: string, value: string) {
      setSearchParams(
        (params) => {
          const selectedFacetValuesByName = tryJsonParse(
            params.get('selectedFacetValuesByName'),
          );

          params.delete('page');
          params.delete('expandedGroup');

          params.set(
            'selectedFacetValuesByName',
            JSON.stringify({
              ...selectedFacetValuesByName,

              [name]: {
                [value]: 1,
              },
            }),
          );

          if (params.get('selectedFacetValuesByName') === '{}') {
            params.delete('selectedFacetValuesByName');
          }

          params.sort();

          return params;
        },
        { replace: true },
      );
    },

    facetValueToggle(name: string, value: string, all: Array<string>) {
      setSearchParams(
        (params) => {
          const selectedFacetValuesByName = tryJsonParse(
            params.get('selectedFacetValuesByName') ?? '{}',
          );
          const updatedState = facetValueToggleUtil.getNextSelectedFacetValues({
            facetName: name,
            facetValueToToggle: value,
            allFacetValues: all,
            selectedFacetValues: selectedFacetValuesByName[name] ?? {},
          });

          params.delete('page');
          params.delete('expandedGroup');

          if (size(updatedState) === 0) {
            params.set(
              'selectedFacetValuesByName',
              JSON.stringify(
                pickBy(selectedFacetValuesByName, (v, k) => k !== name),
              ),
            );
          } else {
            params.set(
              'selectedFacetValuesByName',
              JSON.stringify({
                ...selectedFacetValuesByName,

                [name]: updatedState,
              }),
            );
          }

          if (params.get('selectedFacetValuesByName') === '{}') {
            params.delete('selectedFacetValuesByName');
          }

          params.sort();

          return params;
        },
        { replace: true },
      );
    },

    facetValueClear(name: string) {
      setSearchParams(
        (params) => {
          const selectedFacetValuesByName = tryJsonParse(
            params.get('selectedFacetValuesByName'),
          );

          params.delete('page');
          params.delete('expandedGroup');

          params.set(
            'selectedFacetValuesByName',
            JSON.stringify({
              ...selectedFacetValuesByName,

              [name]: undefined,
            }),
          );

          if (params.get('selectedFacetValuesByName') === '{}') {
            params.delete('selectedFacetValuesByName');
          }

          params.sort();

          return params;
        },
        { replace: true },
      );
    },
  };

  return flux;
}
