import { timeseriesFieldConfig } from 'components/DashboardExport';
import {
  DashboardPanelType,
  DashboardPanelTargetsProps,
  ExplorerQueryProps,
  FormulaProps,
  DashboardPanelProps,
  LogsMetricQueryProps,
  LogsMetricForumlaProps,
} from 'types';
import { buildFormulaQuery, buildPromqlWithFunctions } from 'utils';
import { buildLogql, buildLogqlFormula } from 'utils/LogqlBuilder';

/**
 * Get promql for queries and formulas
 * @param queries
 * @param formulas
 * @returns Array<{ expr: string; hide: boolean }>
 */
export const getPromqlForQueryAndFormula = (
  queries: ExplorerQueryProps[],
  formulas: FormulaProps[],
) => {
  const promqlQueries: { expr: string; hide: boolean }[] = [];
  queries.map((query: ExplorerQueryProps) => {
    if (query.showInput) {
      promqlQueries.push({ expr: query.promql, hide: !query.isActive });
      return;
    }

    const promql = buildPromqlWithFunctions(query);
    promqlQueries.push({ expr: promql, hide: !query.isActive });
  });

  const promqls = promqlQueries.map((promql) => promql.expr);
  const queryKeys = queries.map((query: ExplorerQueryProps) => query.queryKey);
  formulas.map((formula: FormulaProps) => {
    const promql = buildFormulaQuery(promqls, queryKeys, [formula]);
    if (!promql || promql.length === 0) return;
    promqlQueries.push({ expr: promql[0], hide: !formula.isActive });
  });

  return promqlQueries.map(({ expr, hide }) => ({
    expr: decodeURIComponent(expr),
    hide,
  }));
};

export const getLogqlforQueryAndFormula = (
  queries: LogsMetricQueryProps[],
  formulas: LogsMetricForumlaProps[],
) => {
  const logqlQueries: { expr: string; hide: boolean }[] = [];
  queries.map((query: LogsMetricQueryProps) => {
    if (!query.isActive) return;

    if (query.showInput) {
      logqlQueries.push({ expr: query.logql, hide: false });
      return;
    }

    const logql = buildLogql({ queries: [query], step: query.step || '1s' });
    logqlQueries.push({ expr: logql[0], hide: false });
  });

  formulas.map((formula: LogsMetricForumlaProps) => {
    if (!formula.isActive) return;

    const affectedQueries = queries.filter((query) =>
      formula.expression.includes(query.queryKey),
    );
    const formulaStep = affectedQueries[0].step || '1m';
    const logql = buildLogqlFormula({ formulas, queries, step: formulaStep });
    logqlQueries.push({ expr: logql[0], hide: false });
  });

  return logqlQueries.map(({ expr, hide }) => ({
    expr: decodeURIComponent(expr),
    hide,
  }));
};

export const nextRefId = (refId: string) => {
  if (!refId) return 'A';
  const refIdChar = refId.charCodeAt(0);
  return String.fromCharCode(refIdChar + 1);
};

/**
 * This function generates an array of targets for a dashboard panel.
 * @param {Object} params - An object containing the following properties:
 *    - formulas: An array of objects representing the formulas for the queries.
 *    - panelType: A string representing the type of the panel.
 *    - prevTargets: An array of objects representing the previous targets of the panel.
 *    - queries: An array of objects representing the queries for the panel.
 *    - uid: A string representing the unique identifier of the panel.
 * @returns {DashboardPanelTargetsProps[]} An array of objects representing the targets of the panel.
 */
const getPanelTargets = ({
  formulas,
  panelType,
  prevTargets,
  queries,
  uid,
}: {
  formulas: FormulaProps[];
  panelType: DashboardPanelType;
  prevTargets: DashboardPanelTargetsProps[];
  queries: ExplorerQueryProps[];
  uid: string;
}): DashboardPanelTargetsProps[] => {
  const targets: DashboardPanelTargetsProps[] = [];
  const range = panelType === DashboardPanelType.TIMESERIES ? true : false;

  const promqlQueries = getPromqlForQueryAndFormula(queries, formulas);
  const commonTargetProps = {
    datasource: { type: 'prometheus', uid },
    editorMode: 'code',
    range,
    instant: range ? false : true,
  };
  promqlQueries.forEach((query, idx) => {
    const refId = nextRefId(targets[idx - 1]?.refId);
    const target: DashboardPanelTargetsProps = {
      ...(prevTargets.find((target) => target.refId === refId) || {}),
      ...commonTargetProps,
      ...query,
      refId,
    };
    targets.push(target);
  });

  return targets;
};

const getPanelTargetsForLogs = ({
  formulas,
  panelType,
  prevTargets,
  queries,
  uid,
}: {
  formulas: LogsMetricForumlaProps[];
  panelType: DashboardPanelType;
  prevTargets: DashboardPanelTargetsProps[];
  queries: LogsMetricQueryProps[];
  uid: string;
}): DashboardPanelTargetsProps[] => {
  const targets: DashboardPanelTargetsProps[] = [];
  const range = panelType === DashboardPanelType.TIMESERIES ? true : false;

  const logqlQueries = getLogqlforQueryAndFormula(queries, formulas);
  const commonTargetProps = {
    datasource: { type: 'loki', uid },
    editorMode: 'code',
    range,
    instant: range ? false : true,
  };
  logqlQueries.forEach((query, idx) => {
    const refId = nextRefId(targets[idx - 1]?.refId);
    const target: DashboardPanelTargetsProps = {
      ...(prevTargets.find((target) => target.refId === refId) || {}),
      ...commonTargetProps,
      ...query,
      refId,
    };
    targets.push(target);
  });

  return targets;
};

export const getPanelFieldConfig = ({
  prevFieldConfig,
}: {
  prevFieldConfig: DashboardPanelProps['fieldConfig'];
}): DashboardPanelProps['fieldConfig'] => {
  if (prevFieldConfig) {
    return prevFieldConfig;
  }

  return timeseriesFieldConfig('line');
};

export const getEditedPanel = ({
  editedPanel,
  formulas,
  queries,
  uid,
}: {
  editedPanel: DashboardPanelProps;
  formulas: FormulaProps[];
  queries: ExplorerQueryProps[];
  uid: string;
}): DashboardPanelProps => {
  const targets = getPanelTargets({
    formulas,
    panelType: editedPanel.type,
    prevTargets: editedPanel.targets,
    queries,
    uid,
  });

  const fieldConfig = getPanelFieldConfig({
    prevFieldConfig: editedPanel.fieldConfig,
  });

  const datasource = { type: 'prometheus', uid };
  const options: DashboardPanelProps['options'] = {
    legend: { calcs: [], displayMode: 'list', placement: 'bottom' },
  };
  return {
    fieldConfig,
    options,
    ...editedPanel,
    isEdited: true,
    targets,
    datasource,
  };
};

export const getEditedPanelForLogs = ({
  editedPanel,
  formulas,
  queries,
  queryLangType,
  uid,
}: {
  editedPanel: DashboardPanelProps;
  formulas: LogsMetricForumlaProps[];
  queries: LogsMetricQueryProps[];
  queryLangType: string;
  uid: string;
}): DashboardPanelProps => {
  const targets = getPanelTargetsForLogs({
    formulas,
    panelType: editedPanel.type,
    prevTargets: editedPanel.targets,
    queries,
    uid,
  });
  const prevAnnotations = editedPanel.fieldConfig.defaults.custom.kAnnotations;
  const parsedPrevAnnotations = prevAnnotations
    ? JSON.parse(prevAnnotations)
    : null;
  const annotations = {
    ...parsedPrevAnnotations,
    queries,
    formulas,
    queryLangType,
    isTransformed: false,
  };

  const fieldConfig = getPanelFieldConfig({
    prevFieldConfig: editedPanel.fieldConfig,
  });

  fieldConfig.defaults.custom.kAnnotations = JSON.stringify(annotations);
  const datasource = { type: 'loki', uid };
  const options: DashboardPanelProps['options'] = {
    legend: { calcs: [], displayMode: 'list', placement: 'bottom' },
  };
  return {
    fieldConfig,
    options,
    ...editedPanel,
    isEdited: true,
    targets,
    datasource,
  };
};

export const getEditedPanelForLogsEvents = ({
  editedPanel,
  expr,
}: {
  editedPanel: DashboardPanelProps;
  expr: string;
}): DashboardPanelProps => {
  const targets = [
    {
      expr,
      refId: 'A',
      datasource: { type: 'loki', uid: '' },
      editorMode: 'code',
      range: false,
      instant: true,
    },
  ];

  const fieldConfig = getPanelFieldConfig({
    prevFieldConfig: editedPanel.fieldConfig,
  });
  const datasource = { type: 'loki', uid: '' };
  const options: DashboardPanelProps['options'] = {
    legend: { calcs: [], displayMode: 'list', placement: 'bottom' },
  };

  return {
    fieldConfig,
    options,
    ...editedPanel,
    isEdited: true,
    targets,
    datasource,
  };
};
