import {
  RightSidebar,
  SidebarTraceError,
  TraceErrorChartGrid,
} from 'components';
import { useRequest, useSearch, useTracesState } from 'hooks';
import React, { useEffect } from 'react';
import { traceErrors } from 'requests';
import { Service } from 'types';

type Props = {
  applyFilterOnErrorsPage?: boolean;
  close: VoidFunction;
  customerFilter?: { [key: string]: string };
  colorsByServiceName: Record<string, string>;
  errorGroupingKey: string;
  isServiceFromDatabasesList?: boolean;
  serviceByHash: Record<string, Service>;
  serviceHash?: string;
  tracesState: ReturnType<typeof useTracesState>;
};

const ApmErrorsGroupTableSidebar = ({
  applyFilterOnErrorsPage,
  close,
  colorsByServiceName,
  customerFilter,
  errorGroupingKey,
  isServiceFromDatabasesList,
  serviceByHash,
  serviceHash,
  tracesState,
}: Props) => {
  const getTraceError = async (args) => {
    return traceErrors({
      errorGroupingKey,
      isServiceFromDatabasesList,
      serviceHash,
      tracesState: args.tracesState,
    }).then((result) => (result.length ? result[0] : Promise.reject(null)));
  };
  const search = useSearch();

  const getTraceErrorRequest = useRequest(getTraceError);
  const traceError = getTraceErrorRequest.result || null;

  useEffect(() => {
    getTraceErrorRequest.call({ tracesState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RightSidebar
      className="apm-errors__group-sidebar"
      close={close}
      title={`Error group: ${errorGroupingKey}`}
      dataTestId="apm-errors-group-sidebar"
    >
      <div>
        <TraceErrorChartGrid
          errorGroupingKey={errorGroupingKey}
          isServiceFromDatabasesList={isServiceFromDatabasesList}
          search={search}
          serviceHash={serviceHash}
          tracesState={tracesState}
        />
        {traceError ? (
          <SidebarTraceError
            applyFilterOnErrorsPage={applyFilterOnErrorsPage}
            colorsByServiceName={colorsByServiceName}
            customerFilter={customerFilter}
            errorGroupingKey={errorGroupingKey}
            serviceByHash={serviceByHash}
            serviceHash={serviceHash}
            traceError={traceError}
            tracesState={tracesState}
          />
        ) : null}
      </div>
    </RightSidebar>
  );
};

export default ApmErrorsGroupTableSidebar;
