import { Button, InputWithValidatorV3 } from 'components';
import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { MdDelete as DeleteIcon } from 'react-icons/md';

import { HydrateFormProps } from './types';

const LogsHydrationArchivesHydrateFilter = ({
  hydrateForm,
  setHydrateForm,
}: {
  hydrateForm: HydrateFormProps;
  setHydrateForm: Dispatch<SetStateAction<HydrateFormProps>>;
}): ReactElement => {
  const setFilterByKey = (property: string, idx: number, value: string) => {
    setHydrateForm((prev) => {
      const newState = { ...prev };
      newState.filter[idx][property] = value;
      return newState;
    });
  };

  return (
    <div>
      <div className="flex flex-wrap gap-2">
        {hydrateForm.filter.map((filter, idx) => (
          <div key={idx} className="flex gap-1">
            <div>
              <label>Key</label>
              <InputWithValidatorV3
                size="4"
                type="text"
                variant="default"
                onChange={(e) => setFilterByKey('key', idx, e)}
                placeholder="Key"
                value={filter.key}
              />
            </div>
            <div>
              <label>Value</label>
              <InputWithValidatorV3
                size="4"
                type="text"
                variant="default"
                onChange={(e) => setFilterByKey('value', idx, e)}
                placeholder="Value"
                value={filter.value}
              />
            </div>
            <div className="self-end">
              <Button
                variant="ghost-destructive"
                size="sm"
                className="h-[32px] border"
                onClick={() =>
                  setHydrateForm({
                    ...hydrateForm,
                    filter: hydrateForm.filter.filter((_, i) => i !== idx),
                  })
                }
              >
                <DeleteIcon />
              </Button>
            </div>
          </div>
        ))}
      </div>
      <div className="pt-2">
        <Button
          variant="default"
          size="xs"
          onClick={() =>
            setHydrateForm({
              ...hydrateForm,
              filter: [...hydrateForm.filter, { key: '', value: '' }],
            })
          }
        >
          Add Filter
        </Button>
      </div>
    </div>
  );
};

export default LogsHydrationArchivesHydrateFilter;
