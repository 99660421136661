import { getUrlParamByKey } from './getUrlParamByKey';

const parseUrlParamByKey = (key: string) => {
  const paramValue = getUrlParamByKey(key);
  if (paramValue) {
    try {
      const parsedParamValue = JSON.parse(paramValue);
      return parsedParamValue;
    } catch (e) {
      return null;
    }
  }

  return null;
};

export default parseUrlParamByKey;
