const convertValueWithUnit = (
  valueWithUnit: string,
  baseUnit: string,
): number | string => {
  if (valueWithUnit === '') {
    return '';
  }

  const unitMultipliers: { [key: string]: number } = {
    ns: 1e-9, // nanoseconds to seconds
    µs: 1e-6, // microseconds to seconds
    ms: 1e-3, // milliseconds to seconds
    s: 1, // seconds to seconds
    m: 60, // minutes to seconds
    h: 3600, // hours to seconds
    d: 86400, // days to seconds
    w: 604800, // weeks to seconds
    mo: 2.628e6, // months to seconds (approx. 30.44 days)
    K: 1e3, // kilo to number
    M: 1e6, // mega to number
    B: 1e9, // billion to number
    G: 1e9, // giga to number
    T: 1e12, // trillion to number
  };

  const baseUnitMultipliers: { [key: string]: number } = {
    ns: 1e-9, // nanoseconds to seconds
    µs: 1e-6, // microseconds to seconds
    ms: 1e-3, // milliseconds to seconds
    s: 1, // seconds to seconds
    m: 60, // minutes to seconds
    h: 3600, // hours to seconds
    d: 86400, // days to seconds
    w: 604800, // weeks to seconds
    mo: 2.628e6, // months to seconds (approx. 30.44 days)
    number: 1, // number to number
  };

  const unitPattern = /[a-zA-Zµ]+/;
  const valuePattern = /-?[\d.]+/;

  const unitMatch = valueWithUnit.match(unitPattern);
  const valueMatch = valueWithUnit.match(valuePattern);

  if (!valueMatch) {
    return 'Invalid value with unit';
  }

  const value = parseFloat(valueMatch[0]);

  // If no unit is found, return the value as it is
  if (!unitMatch) {
    return value;
  }

  const unit = unitMatch[0];

  if (!(unit in unitMultipliers)) {
    return `Unsupported unit: ${unit}`;
  }

  if (!(baseUnit in baseUnitMultipliers)) {
    return `Unsupported base unit: ${baseUnit}`;
  }

  const multiplier = unitMultipliers[unit];
  const baseMultiplier = baseUnitMultipliers[baseUnit];

  return (value * multiplier) / baseMultiplier;
};

export default convertValueWithUnit;
