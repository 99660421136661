import { first } from 'lodash';
import { useMemo } from 'react';

import { Property, Value } from './types';
import { getNextElement, getPreviousElement } from './utils';

type KeydownHandlerProps = {
  activeProperty: Property | null;
  setActiveProperty: (property: Property) => void;
  activeValue: Value | null;
  setActiveValue: (value: Value | null) => void;
  getValues: (property: Property) => Array<Value>;
  onValueSelect: (property: Property, value: Value) => void;
  properties: Array<Property>;
};

const getKeydownHandler = ({
  activeProperty,
  setActiveProperty,
  activeValue,
  setActiveValue,
  getValues,
  onValueSelect,
  properties,
}: KeydownHandlerProps) => {
  return (e: KeyboardEvent) => {
    const key = e.key;

    if (!activeProperty) {
      setActiveProperty(first(properties));
      return;
    }

    if (!activeValue) {
      switch (key) {
        case 'ArrowDown':
        case 'Tab':
          setActiveProperty(getNextElement(activeProperty, properties));
          break;
        case 'ArrowUp':
          setActiveProperty(getPreviousElement(activeProperty, properties));
          break;
        case 'ArrowRight':
          setActiveValue(first(getValues(activeProperty))!);
          break;
        case 'Enter':
          setActiveValue(first(getValues(activeProperty))!);
          break;
        default:
          return;
      }
      return;
    }

    switch (key) {
      case 'ArrowDown':
      case 'Tab':
        setActiveValue(getNextElement(activeValue, getValues(activeProperty)));
        break;
      case 'ArrowUp':
        setActiveValue(
          getPreviousElement(activeValue, getValues(activeProperty)),
        );
        break;
      case 'ArrowLeft':
        if (activeValue) {
          setActiveValue(null);
        }
        break;
      case 'Enter':
        onValueSelect(activeProperty, activeValue);
        break;
      default:
        return;
    }
    return;
  };
};

const usePropertyValueSelectorKeydownHandler = ({
  activeProperty,
  setActiveProperty,
  activeValue,
  setActiveValue,
  getValues,
  onValueSelect,
  properties,
}: KeydownHandlerProps) => {
  return useMemo(
    () =>
      getKeydownHandler({
        activeProperty,
        properties,
        setActiveProperty,
        activeValue,
        setActiveValue,
        getValues,
        onValueSelect,
      }),
    [
      activeProperty,
      setActiveProperty,
      activeValue,
      setActiveValue,
      getValues,
      onValueSelect,
      properties,
    ],
  );
};

export default usePropertyValueSelectorKeydownHandler;
