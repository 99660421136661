import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import SheetCell from './SheetCell';
import getValue from './getValue';

const defaultProps = {
  renderCell: null,
};

const propTypes = {
  activeRowIndex: PropTypes.number,
  columns: PropTypes.array.isRequired,
  lockedColumns: PropTypes.shape({}).isRequired,
  offsetTop: PropTypes.number.isRequired,
  onScrollEnd: PropTypes.func,
  renderCellFromSheetRow: PropTypes.func,
  dataTestId: PropTypes.string,
  row: PropTypes.any.isRequired,
  scrollX: PropTypes.number.isRequired,
  sheetDimensions: PropTypes.shape({}).isRequired,
  y: PropTypes.number.isRequired,
};

const renderCell = ({ column, columnIndex, left, offsetX, offsetY, row }) => {
  const { renderCell } = column;
  const value = getValue({ column, row });

  if (renderCell) {
    return renderCell({
      column,
      columnIndex,
      left,
      offsetX,
      offsetY,
      row,
      value,
    });
  }

  return value;
};

const SheetRow = ({
  activeRowIndex,
  baseOffsetTop,
  columns,
  isHeader,
  locked,
  key,
  dataTestId,
  lockedColumns,
  offsetTop,
  onScrollEnd,
  renderCellFromSheetRow,
  row,
  scrollX,
  scrollY,
  sheetDimensions,
  tableOptions,
  tooltip,
  y,
}) => {
  useEffect(() => {
    if (onScrollEnd) {
      onScrollEnd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { firstRightColumn, lastLeftColumn, lockedColumnsMap } = lockedColumns;
  const { columnLefts, rowHeight } = sheetDimensions;

  const handleClick = (column, y, offsetX, offsetY, offsetTop, row) => {
    const result = tooltip({
      column,
      index: y,
      offsetX,
      offsetY,
      offsetTop,
      row,
    });
    if (result.props && typeof result.props.setSelectedLog === 'function') {
      result.props.setSelectedLog({
        index: result.props.index,
        log: result.props.logs[result.props.index],
      });
    }
  };

  return (
    <div
      className={classnames({
        sheet__row: true,
        [`sheet__row--${tableOptions?.state?.linesToShow}`]:
          tableOptions?.state?.linesToShow,
        ['sheet__row--active']: activeRowIndex === y,
      })}
      style={{
        height: `${isHeader ? 32 : rowHeight}px`,
        transform: `translate3d(-${scrollX}px, ${offsetTop}px, 0)`,
      }}
      data-testid={dataTestId}
    >
      {columns.map((column, i) => {
        const { key, width } = column;

        const left =
          i in lockedColumnsMap ? lockedColumnsMap[i] : columnLefts[i];

        const isLastLeftColumn = i === lastLeftColumn;
        const isLocked = i in lockedColumnsMap;
        const isFirstRightColumn = i === firstRightColumn;

        if (renderCellFromSheetRow) {
          return (
            <div key={key}>
              {renderCellFromSheetRow({
                column,
                columnIndex: i,
                columnLocked: Boolean(locked[i]),
                left,
                isLastLeftColumn,
                isLocked,
                isFirstRightColumn,
                row,
                scrollX,
                width,
                x: i,
                y,
              })}
            </div>
          );
        }

        const { sheetOffsetX, sheetOffsetY } = sheetDimensions;
        const offsetX = -sheetOffsetX + scrollX;
        const offsetY =
          -sheetOffsetY - (offsetTop - baseOffsetTop) + scrollY - 36;

        return (
          <SheetCell
            baseOffsetTop={baseOffsetTop}
            className={classnames({
              'sheet__cell--locked': isLocked,
              'sheet__cell--locked-left': isLastLeftColumn,
              'sheet__cell--locked-right': isFirstRightColumn,
            })}
            cellWidth={width}
            column={column}
            key={key}
            left={left}
            offsetTop={offsetTop}
            row={row}
            offsetX={offsetX}
            offsetY={offsetY}
            scrollX={scrollX}
            scrollY={scrollY}
            sheetDimensions={sheetDimensions}
            tooltip={tooltip}
            x={i}
            y={y}
            onClick={() =>
              handleClick(column, y, offsetX, offsetY, offsetTop, row)
            }
          >
            <div className="sheet__cell__text">
              {renderCell({
                column,
                columnIndex: i,
                left,
                offsetX,
                offsetY,
                row,
              })}
            </div>
          </SheetCell>
        );
      })}
    </div>
  );
};

SheetRow.defaultProps = defaultProps;
SheetRow.propTypes = propTypes;

export default SheetRow;
