import {
  Accordion,
  Badge,
  Button,
  Loader,
  Paginator,
  Table,
  TableSearch,
  usePaginator,
  useTableSearch,
  useTableSort,
} from 'components';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { colorsByLogLevel, dateTimeFormat } from 'kfuse-constants';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getColorRandom } from 'utils';

import AlertsSilencesActions from './AlertsSilencesActions';
import { useAlertsSilencesState } from './hooks';
import { SilencesProps } from './types';
import { getSilenceOperator } from './utils';
import { IoIosWarning } from 'react-icons/io';

const columnSilences = ({
  onEditSilence,
  onUnmuteSilence,
}: {
  onEditSilence: (silence: SilencesProps) => void;
  onUnmuteSilence: (silence: SilencesProps) => void;
}) => [
  {
    key: 'status',
    label: 'Status',
    renderCell: ({ row }: { row: SilencesProps }) => {
      const status = row.status.state;
      const color = colorsByLogLevel['debug'];
      return (
        <Badge
          className={classNames({
            'min-w-[80px] justify-center rounded-sm px-2 text-xs font-medium uppercase ':
              true,
            'text-white': status === 'active',
          })}
          style={{ backgroundColor: status === 'active' ? color : '' }}
          variant="outline"
        >
          {row.status.state}
        </Badge>
      );
    },
  },
  {
    key: 'matchers',
    label: 'Matching labels',
    renderCell: ({ row }: { row: SilencesProps }) => {
      return (
        <div className="flex max-w-[600px] flex-wrap">
          {row.matchers.map((matcher) => {
            const { isEqual, isRegex, name, value } = matcher;
            const operator = getSilenceOperator(isEqual, isRegex);
            return (
              <Badge
                key={name}
                className="py-0.25 mb-1 mr-1 truncate rounded-sm px-1 text-xs font-medium text-white"
                variant="outline"
                style={{ backgroundColor: getColorRandom() }}
              >
                {name}
                {operator}
                {value}
              </Badge>
            );
          })}
        </div>
      );
    },
  },
  {
    key: 'alerts',
    label: 'Alerts',
    renderCell: ({ row }: { row: SilencesProps }) => {
      return <span>{row.silencedInstance?.length || 0}</span>;
    },
  },
  {
    key: 'schedule',
    label: 'Schedule',
    renderCell: ({ row }: { row: SilencesProps }) => {
      const startsAt = dayjs(row.startsAt);
      const endsAt = dayjs(row.endsAt);
      return (
        <div>
          {startsAt.format(dateTimeFormat)} - {endsAt.format(dateTimeFormat)}
        </div>
      );
    },
    width: 220,
  },
  {
    key: 'actions',
    label: '',
    renderCell: ({ row }: { row: SilencesProps }) => (
      <AlertsSilencesActions
        silence={row}
        onEditSilence={onEditSilence}
        onUnmuteSilence={onUnmuteSilence}
      />
    ),
  },
];

const AlertsSilences = () => {
  const alertsSilencesState = useAlertsSilencesState();
  const {
    isLoading,
    grafanaMutedAlertsRequest,
    getSilencedInstanceRequest,
    activeSilences,
    expiredSilences,
    onUnmuteSilence,
  } = alertsSilencesState;

  useEffect(() => {
    grafanaMutedAlertsRequest.call();
    getSilencedInstanceRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = columnSilences({
    onEditSilence: () => {},
    onUnmuteSilence,
  });
  const tableSearch = useTableSearch({ rows: activeSilences });
  const tableSort = useTableSort({
    columns,
    rows: tableSearch.searchedRows,
  });
  const paginator = usePaginator({
    rows: tableSort.sortedRows,
    initialNumberOfRowsPerPage: 25,
  });

  return (
    <div className="mx-auto max-w-[1000px] py-4">
      <div className="flex items-center justify-between pb-2">
        <h2 className="text-lg font-semibold">Silences</h2>

        {(grafanaMutedAlertsRequest?.error ||
          getSilencedInstanceRequest?.error) && (
          <div className="flex justify-end w-full gap-[4px]">
            {grafanaMutedAlertsRequest?.error && (
              <div className="flex gap-[4px] justify-end pr-[14px]">
                <IoIosWarning
                  className="overlay-message__icon-and-message__icon"
                  size={16}
                />
                <div className="text-red-500 whitespace-nowrap">
                  Failed to fetch muted alerts
                </div>
              </div>
            )}

            {getSilencedInstanceRequest?.error && (
              <div className="flex gap-[4px] justify-end pr-[14px]">
                <IoIosWarning
                  className="overlay-message__icon-and-message__icon"
                  size={16}
                />
                <div className="text-red-500 whitespace-nowrap">
                  Failed to fetch silenced instance
                </div>
              </div>
            )}
          </div>
        )}

        <Link to="/alerts/silences/new">
          <Button variant="default" size="sm" onClick={null}>
            Add New Silence
          </Button>
        </Link>
      </div>
      <Loader isLoading={isLoading}>
        <TableSearch
          className="dashboard__list__search"
          placeholder="Search Silences..."
          tableSearch={tableSearch}
          dataTestId="dashboard-list-search"
        />
        <Table
          className="table--bordered table--bordered-cells table__actions--hidden alerts__contacts__table"
          columns={columns}
          externalTableSort={tableSort}
          isSortingEnabled
          rows={paginator.paginatedRows}
          dataTestId="active-silences-list"
        />
        {activeSilences.length === 0 && (
          <div className="flex items-center justify-center py-4">
            <p className="text-lg text-gray-500">No active silences</p>
          </div>
        )}
        <div className="table-footer">
          <Paginator paginator={paginator} />
        </div>
      </Loader>
      <Accordion
        renderContent={() => (
          <>
            <Table
              className="table--bordered table--bordered-cells table__actions--hidden alerts__contacts__table"
              columns={columns}
              rows={expiredSilences}
              dataTestId="expired-silences-list"
            />
            {expiredSilences.length !== 0 && (
              <div className="flex items-center justify-center py-4">
                <p className="text-lg text-gray-500">No expired silences</p>
              </div>
            )}
          </>
        )}
        renderTrigger={() => (
          <div className="flex items-center justify-between py-2">
            <h2 className="text-lg font-semibold">
              Expired Silences ({expiredSilences.length})
            </h2>
          </div>
        )}
      />
    </div>
  );
};

export default AlertsSilences;
