import classnames from 'classnames';
import isEqual from 'lodash.isequal';
import React, { ReactNode, useMemo } from 'react';
import { SelectOption } from './types';
import { Input } from '../Input';

type Props = {
  close?: VoidFunction;
  isAutocompleteEnabled?: boolean;
  isOpen: boolean;
  onEnter?: (search: string) => void;
  options: SelectOption[];
  onSearchChange?: (search: string) => void;
  placeholder: string;
  setSearch: (s: string) => void;
  search: string;
  renderValue?: (value: any) => ReactNode;
  value: any;
};

const SelectTriggerValue = ({
  close,
  isAutocompleteEnabled,
  isOpen,
  onEnter,
  options,
  onSearchChange,
  placeholder,
  renderValue,
  search,
  setSearch,
  value,
}: Props) => {
  const selectedOption = useMemo(() => {
    return options.find((option) => isEqual(option.value, value));
  }, [options, value]);

  const onChange = (nextSearch: string) => {
    setSearch(nextSearch);
    if (onSearchChange) {
      onSearchChange(nextSearch);
    }
  };

  const onEnterHandler = () => {
    if (onEnter) {
      onEnter(search);
      close();
    }
  };

  if (isAutocompleteEnabled && isOpen) {
    return (
      <div className="select__trigger__value">
        <Input
          autoFocus
          className="select__trigger__value__input"
          onEnter={onEnterHandler}
          onChange={onChange}
          type="text"
          value={search}
        />
      </div>
    );
  }

  return (
    <div
      className={classnames({
        select__trigger__value: true,
        'select__trigger__value--placeholder':
          !selectedOption && !value && placeholder,
      })}
    >
      {value && typeof renderValue === 'function'
        ? renderValue(value)
        : selectedOption?.label || value || placeholder}
    </div>
  );
};

export default SelectTriggerValue;
