import classNames from 'classnames';
import { Button, TooltipTrigger } from 'components';
import { useMetricsQueryStateV2, useStateCallback } from 'hooks';
import React, { ReactElement, useMemo } from 'react';
import { Plus } from 'react-feather';
import {
  buildPromqlLabelMatcher,
  parseMetricLabelQuery,
} from 'utils/MetricsQueryBuilder';
import MetricsQueryBuilderSeriesBuilder from './MetricsQueryBuilderSeriesBuilder';
import { extractValueFromSeries } from './utils';

const MetricsQueryBuilderSeries = ({
  metricName,
  queryIndex,
  metricsQueryState,
  series,
}: {
  metricName: string;
  queryIndex: number;
  metricsQueryState: ReturnType<typeof useMetricsQueryStateV2>;
  series: string[];
}): ReactElement => {
  const {
    labelValueList,
    labelsList,
    removeSeries,
    updateQuery,
    updateSeries,
    loadLabelList,
    loadLabelValues,
  } = metricsQueryState;

  const [draftSeries, setDraftSeries] = useStateCallback<string>(null);

  const handleLabelChange = (labelIndex: number, newLabel: string) => {
    if (labelIndex < series.length) {
      const oldLabel = series[labelIndex] || '';
      const value = extractValueFromSeries(oldLabel);
      const newLabelKey = newLabel.replace(/".*"/, `"${value}"`);

      updateSeries(queryIndex, labelIndex, 'label', newLabelKey);
    } else {
      setDraftSeries(newLabel);
      loadLabelValues(metricName, series.length, [...series, newLabel]);
    }
  };

  const handleOperatorChange = (
    labelIndex: number,
    newOperator: string,
    operator: string,
  ) => {
    if (labelIndex < series.length) {
      const labelKey = series[labelIndex];
      const newLabelKey = labelKey.replace(operator, newOperator);

      updateSeries(queryIndex, labelIndex, '', newLabelKey);
    } else {
      const labelKey = draftSeries;
      let newLabelKey = labelKey.replace(operator, newOperator);
      if (newOperator === '=' || newOperator === '!=') {
        newLabelKey = newLabelKey.replace(/".*"/, '""');
      }
      setDraftSeries(newLabelKey);
    }
  };

  const handleValueChange = (labelIndex: number, newValue: string) => {
    if (labelIndex < series.length) {
      const labelKey = series[labelIndex];
      const newLabelKey = labelKey.replace(
        /".*"/,
        `"${newValue.replace(/"/g, '')}"`,
      );
      updateSeries(queryIndex, labelIndex, 'value', newLabelKey);
    } else {
      const labelKey = draftSeries;
      const newLabelKey = labelKey.replace(
        /".*"/,
        `"${newValue.replace(/"/g, '')}"`,
      );
      setDraftSeries(newLabelKey, () => {
        addDraftSeriesToSeries(newLabelKey);
      });
    }
  };

  const addDraftSeriesToSeries = (draftSeries: string) => {
    const newSeries = [...series];
    newSeries.push(draftSeries);
    updateQuery(queryIndex, 'series', newSeries);
    setDraftSeries(null);
  };

  const addNewLabel = () => {
    setDraftSeries('=""');
  };

  const { usedLabels, parsedSeries } = useMemo(() => {
    const usedLabels: string[] = [];
    const parsedSeries: ReturnType<typeof parseMetricLabelQuery>[] = [];
    series.forEach((item, idx) => {
      const parsed = parseMetricLabelQuery(item);
      const matcher = buildPromqlLabelMatcher({
        series,
        seriesIndex: idx,
      });
      parsed.matcher = `${metricName}{${matcher}}`;

      if (parsed.label) usedLabels.push(parsed.label);
      parsedSeries.push(parsed);
    });
    return { usedLabels, parsedSeries };
  }, [metricName, series]);

  const onLabelOpen = (idx: number) => {
    loadLabelList(series, metricName, idx);
  };

  const draftMatcher = useMemo(() => {
    const matcher = buildPromqlLabelMatcher({
      series,
      seriesIndex: -1,
    });
    return `${metricName}{${matcher}}`;
  }, [metricName, series]);

  return (
    <>
      <div className="button-group">
        <div
          className={classNames({
            'button-group__item button-group__item--label': true,
            'metrics__query-builder__series__builder--from':
              series.length > 0 || Boolean(draftSeries),
          })}
        >
          From
        </div>
      </div>
      {series.map((item, idx) => {
        return (
          <MetricsQueryBuilderSeriesBuilder
            key={idx}
            labelIndex={idx}
            labelList={labelsList || {}}
            labelValueList={labelValueList[metricName] || {}}
            onLabelChange={handleLabelChange}
            onOperatorChange={handleOperatorChange}
            onValueChange={handleValueChange}
            parsedSeries={parsedSeries[idx]}
            removeLabel={(labelIndex) => removeSeries(queryIndex, labelIndex)}
            usedLabels={usedLabels}
            onLabelOpen={() => onLabelOpen(idx)}
          />
        );
      })}
      {Boolean(draftSeries) ? (
        <MetricsQueryBuilderSeriesBuilder
          key={series.length}
          labelIndex={series.length}
          labelList={labelsList || {}}
          labelValueList={labelValueList[metricName] || {}}
          onLabelChange={handleLabelChange}
          onOperatorChange={handleOperatorChange}
          onValueChange={handleValueChange}
          parsedSeries={{
            ...parseMetricLabelQuery(draftSeries),
            matcher: draftMatcher,
          }}
          removeLabel={() => setDraftSeries(null)}
          usedLabels={usedLabels}
          onLabelOpen={() => onLabelOpen(series.length)}
        />
      ) : null}
      {series.length === 0 && !Boolean(draftSeries) ? (
        <div className="search__button-group__divider">
          <div />
        </div>
      ) : null}
      {!Boolean(draftSeries) ? (
        <>
          <div className="button-group">
            <div
              className="button-group__item button-group__item--unpadded"
              data-testid="add-label-value-filter-button"
            >
              <TooltipTrigger className="flex h-full" tooltip="Add Label">
                <Button
                  className="h-full"
                  onClick={addNewLabel}
                  variant="icon"
                  size="sm"
                >
                  <Plus size={20} />
                </Button>
              </TooltipTrigger>
            </div>
          </div>
          <div className="search__button-group__divider">
            <div />
          </div>
        </>
      ) : null}
    </>
  );
};

export default MetricsQueryBuilderSeries;
