import { SelectedFacetValuesByName, EntityType } from 'types';
import { onPromiseError } from 'utils';
import queryEventService from './queryEventService';
import buildKubeFilter from './utils/buildKubeFilter';

type Args = {
  selectedFacetValuesByName: SelectedFacetValuesByName;
  entityType: string;
};

const lowerFirst = (entityType: string) => {
  return entityType.charAt(0).toLowerCase() + entityType.slice(1);
};

const kubeYaml = async (
  { entityType, selectedFacetValuesByName }: Args,
  init?: RequestInit,
): Promise<any> => {
  const yamlEntity = lowerFirst(entityType);
  return queryEventService<Array<any>, 'kubeEntities'>(
    `
    query GetKubeYaml {
        kubeEntities (
        entityType: ${entityType}
        filter: ${buildKubeFilter(selectedFacetValuesByName)}
      ) {
        ${yamlEntity}{yaml}
      }
    }
  `,
    {},
    init,
  ).then((data) => data.kubeEntities || [], onPromiseError);
};

export default kubeYaml;
