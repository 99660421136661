import { DateSelection, SelectedFacetValuesByName } from 'types';
import {
  buildPromQLFilterFromSelectedFacetValuesByName,
  onPromiseError,
} from 'utils';
import fetchJson from '../../../requests/fetchJson';

interface AggrVal {
  functionname: number;
}

interface GroupVal {
  span_type: string;
}

interface Item {
  aggrVal: AggrVal;
  groupVal: GroupVal;
  bucketStart: number;
}

type Props = {
  date: DateSelection;
  name: string;
  selectedFacetValuesByName?: SelectedFacetValuesByName;
  service?: string;
  instant?: boolean;
};

const serverlesslabelValues = ({
  date,
  name,
  selectedFacetValuesByName,
}: Props): Promise<Item[]> => {
  const filters = buildPromQLFilterFromSelectedFacetValuesByName({
    selectedFacetValuesByName: selectedFacetValuesByName || {},
  });
  const { endTimeUnix, startTimeUnix } = date;
  const duration = endTimeUnix - startTimeUnix;

  const queryParams = new URLSearchParams({
    'match[]': `aws_lambda_invocations${filters}`,
    aggregation: 'distinctcount',
    duration: '0',
    timestamp: endTimeUnix.toString(),
    aggregationField: 'function_arn',
    groupBy: name,
    rollUpSeconds: duration.toString(),
  });

  return fetchJson(`/api/v1/analytics?${queryParams.toString()}`).then(
    (result) =>
      (result || []).filter((item) => item.groupVal[name] !== 'UNKNOWN'),
    onPromiseError,
  );
};

export default serverlesslabelValues;
