import { fetchGrafanaApi } from 'requests';

import { UserOrgProps } from '../types';

const getOrgByUserId = async ({
  userId,
}: {
  userId: number;
}): Promise<UserOrgProps[]> => {
  const result = await fetchGrafanaApi(`grafana/api/users/${userId}/orgs`);

  return result;
};

export default getOrgByUserId;
