import { Button } from 'components';
import classNames from 'classnames';
import React, { ReactElement } from 'react';

type Props = {
  className?: string;
  cancelText?: string;
  description: string | ReactElement;
  onCancel: () => void;
  onConfirm: () => void;
  submitText?: string;
  title: string | ReactElement;
  dataTestId?: string;
};

const ConfirmationModal = ({
  className,
  cancelText = 'Cancel',
  description,
  onCancel,
  onConfirm,
  submitText = 'Delete',
  title,
  dataTestId,
}: Props): ReactElement => {
  return (
    <div
      className={classNames({
        'confirmation-modal': true,
        [className]: className,
      })}
      data-testid={dataTestId}
    >
      <div className="confirmation-modal__header">{title}</div>
      <div className="confirmation-modal__body">{description}</div>
      <div className="confirmation-modal__footer">
        <Button variant="outline" size="sm" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button
          variant="default"
          size="sm"
          className="ml-2"
          onClick={onConfirm}
          data-testid="confirm-modal-yes"
        >
          {submitText}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
