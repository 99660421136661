import sidebarFacets from '../requests/sidebarFacets';

const ungroupedFacetNamesBitmap: {
  [key: string]: number;
} = {
  env: 1,
  service: 1,
  source: 1,
};

const groupByFacetName = sidebarFacets.reduce(
  (acc, { name, category }) => ({
    ...acc,
    [name]: category,
  }),
  {},
);

const groupRumLabels = (result: string[]) => {
  const ungrouped: string[] = [];
  const facetNamesByGroup: {
    [key: string]: string[];
  } = {};
  result.forEach((name) => {
    if (ungroupedFacetNamesBitmap[name]) {
      ungrouped.push(name);
    } else {
      const parts = name.split('.');
      if (parts.length === 1) {
        const group = groupByFacetName[name] || 'custom';
        if (!facetNamesByGroup[group]) {
          facetNamesByGroup[group] = [];
        }
        facetNamesByGroup[group].push(name);
      } else {
        const nameFoundInGroupByFacetName = groupByFacetName[name];
        if (nameFoundInGroupByFacetName) {
          if (!facetNamesByGroup[nameFoundInGroupByFacetName]) {
            facetNamesByGroup[nameFoundInGroupByFacetName] = [];
          }
          facetNamesByGroup[nameFoundInGroupByFacetName].push(name);
        } else {
          const [group] = parts;
          if (!facetNamesByGroup[group]) {
            facetNamesByGroup[group] = [];
          }
          facetNamesByGroup[group].push(name);
        }
      }
    }
  });
  return {
    facetNamesByGroup: Object.keys(facetNamesByGroup).reduce(
      (obj, group) => ({
        ...obj,
        [group]: facetNamesByGroup[group].sort(),
      }),
      {},
    ),
    ungrouped: ungrouped.sort(),
  };
};
export default groupRumLabels;
