import React from 'react';
import { Clock, Link2, Mail } from 'react-feather';
import { BiTag } from 'react-icons/bi';
import { GrStorage } from 'react-icons/gr';
import { TfiWorld } from 'react-icons/tfi';
import { TiSortAlphabetically, TiSortNumerically } from 'react-icons/ti';

type Props = {
  type: string;
};

const FacetWithIconIcon = ({ type }: Props) => {
  switch (type.toLowerCase()) {
    case 'duration':
      return (
        <Clock
          className="facet-with-icon__icon--duration facet-with-icon__icon--feather"
          size={13}
        />
      );
    case 'email':
      return <Mail className="facet-with-icon__icon--feather" size={13} />;
    case 'ip_address':
      return (
        <TfiWorld
          className="facet-with-icon__icon--ip_address facet-with-icon__icon--react-icons"
          size={13}
        />
      );
    case 'loglevel':
      return <BiTag className="facet-with-icon__icon--react-icons" size={18} />;
    case 'number':
      return (
        <TiSortNumerically
          className="facet-with-icon__icon--number facet-with-icon__icon--react-icons"
          size={18}
        />
      );
    case 'size':
      return (
        <GrStorage
          className="facet-with-icon__icon--size facet-with-icon__icon--gr"
          size={10}
        />
      );
    case 'string':
      return (
        <TiSortAlphabetically
          className="facet-with-icon__icon--string facet-with-icon__icon--react-icons"
          size={18}
        />
      );
    case 'url':
      return (
        <Link2
          className="facet-with-icon__icon facet-with-icon__icon--feather"
          size={13}
        />
      );
    case 'uuid':
      return (
        <span className="facet-with-icon__icon facet-with-icon__icon--uuid facet-with-icon__icon--text">
          id
        </span>
      );
    default:
      return null;
  }
};

export default FacetWithIconIcon;
