import { Separator } from 'components/shadcn';
import React, { ReactElement } from 'react';
import { AnomalyAlgorithmType } from 'types/MetricsQueryBuilder';

import {
  agileRobustSeasonalityMapping,
  alertTypeAlgorithm,
  robustAlgorithmMapping,
  robustSeasonalityMapping,
} from '../AlertsCreateMetrics/utils';
import { alertDetailsTimeConversion } from '../utils';
import { AlertsMetricsParsedProps } from '../types';

const AlertsDetailsAnomalyParameters = ({
  parsed,
}: {
  parsed: AlertsMetricsParsedProps;
}): ReactElement => {
  const { anomalyCondition } = parsed;
  const { anomalyAlgorithm } = anomalyCondition;
  const isRobust = anomalyAlgorithm === AnomalyAlgorithmType.ROBUST;
  const isBasic = anomalyAlgorithm === AnomalyAlgorithmType.BASIC;
  const isAgile = anomalyAlgorithm === AnomalyAlgorithmType.AGILE;
  const isAgileRobust = anomalyAlgorithm === AnomalyAlgorithmType.AGILE_ROBUST;

  return (
    <>
      <div className="alerts__details__properties__summary__item">
        <span className="text-sm font-bold text-text-secondary">
          Algorithm:{' '}
        </span>{' '}
        {alertTypeAlgorithm[anomalyCondition.anomalyAlgorithm]}
      </div>
      <Separator orientation="horizontal" />
      {(isBasic || isRobust) && (
        <>
          <div className="alerts__details__properties__summary__item">
            <span className="text-sm font-bold text-text-secondary">
              Window:{' '}
            </span>
            {alertDetailsTimeConversion(anomalyCondition.window)}
          </div>
          <Separator orientation="horizontal" />
        </>
      )}
      {isRobust && (
        <>
          <div className="alerts__details__properties__summary__item">
            <span className="text-sm font-bold text-text-secondary">
              Model:{' '}
            </span>{' '}
            {robustAlgorithmMapping[anomalyCondition.model]}, &nbsp;&nbsp;
            <span className="text-sm font-bold text-text-secondary">
              Seasonality:{' '}
            </span>{' '}
            {robustSeasonalityMapping[anomalyCondition.seasonality || '3600']}
          </div>
          <Separator orientation="horizontal" />
        </>
      )}
      {isAgileRobust && (
        <>
          <div className="alerts__details__properties__summary__item">
            <span className="text-sm font-bold text-text-secondary">
              Seasonality:{' '}
            </span>{' '}
            {
              agileRobustSeasonalityMapping[
                anomalyCondition.agileRobustSeasonality || '3600'
              ]
            }
          </div>
          <Separator orientation="horizontal" />
        </>
      )}
      {(isBasic || isAgile) && (
        <>
          <div className="alerts__details__properties__summary__item">
            <span className="text-sm font-bold text-text-secondary">
              Bound:{' '}
            </span>{' '}
            {anomalyCondition.bound}
          </div>
          <Separator orientation="horizontal" />
        </>
      )}
      <div className="alerts__details__properties__summary__item">
        <span className="text-sm font-bold text-text-secondary">Band: </span>{' '}
        {anomalyCondition.band}
      </div>
      <Separator orientation="horizontal" />
    </>
  );
};

export default AlertsDetailsAnomalyParameters;
