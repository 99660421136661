import { fetchGrafanaApi } from 'requests';

import { SilencedInstanceProps } from '../types';

const getSilencedInstance = async (): Promise<SilencedInstanceProps[]> => {
  const result = await fetchGrafanaApi(
    `grafana/api/alertmanager/grafana/api/v2/alerts?silenced&active&inhibited`,
  );

  return result;
};

export default getSilencedInstance;
