import { AutocompleteV2, Button, Input, TooltipTrigger } from 'components';
import React, { ReactElement, useMemo } from 'react';
import { parseMetricLabelQuery } from 'utils';

import useLogsCardinalityState from './useLogsCardinalityState';
import classNames from 'classnames';
import { IoMdClose } from 'react-icons/io';

const LogsCardinalityLabelValue = ({
  logsCardinalityState,
  series,
  labels,
  labelIndex,
}: {
  logsCardinalityState: ReturnType<typeof useLogsCardinalityState>;
  series: string;
  labels: string[];
  labelIndex: number;
}): ReactElement => {
  const { labelValuePicker, loadLabelValues, updateCardinalityQuery } =
    logsCardinalityState;
  const { labelListMap, labelValueListMap } = labelValuePicker;
  const { label, value, operator } = parseMetricLabelQuery(series);

  const onQueryUpdate = ({
    label,
    operator,
    value,
    isReload = true,
  }: {
    label: string;
    operator: string;
    value: string;
    isReload?: boolean;
  }) => {
    const newLabel = `${label}${operator}"${value}"`;
    const newLabels = [...labels];
    newLabels[labelIndex] = newLabel;
    updateCardinalityQuery('labels', newLabels, isReload);
  };

  const errors = useMemo(() => {
    const errors = { label: false, value: false };
    if (!label) errors.label = true;
    if (!value) errors.value = true;
    return errors;
  }, [label, value]);

  return (
    <div className="button-group">
      {labelIndex === 0 ? (
        <div className="button-group__item button-group__item--label">
          Show Cardinality of
        </div>
      ) : null}
      <div className="button-group__item button-group__item--value">
        <AutocompleteV2
          className={classNames({
            'autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value':
              true,
            'metrics__query-builder__series__builder--error': errors?.label,
            'metrics__query-builder__series__builder__placeholder--highlighted':
              !!label,
          })}
          isLoading={labelListMap['{}']?.isLoading}
          onChange={(val: string) => {
            onQueryUpdate({ label: val, operator, value: value as string });
            loadLabelValues(val);
          }}
          options={labelListMap['{}']?.data || []}
          placeholder={'Select a label'}
          value={label}
        />
      </div>
      <div className="button-group__item button-group__item--value">
        <AutocompleteV2
          className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value"
          isSearchable={false}
          onChange={(op: string) => {
            onQueryUpdate({ label, operator: op, value: value as string });
          }}
          options={[
            { label: '=', value: '=' },
            { label: '!=', value: '!=' },
            { label: '=~', value: '=~' },
            { label: '!~', value: '!~' },
          ]}
          value={operator}
          placeholder={'Select a operator'}
        />
      </div>
      <div className="button-group__item button-group__item--value">
        {(operator === '=' || operator === '!=') && (
          <AutocompleteV2
            className={classNames({
              'autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value':
                true,
              'metrics__query-builder__series__builder--error': errors?.value,
              'metrics__query-builder__series__builder__placeholder--highlighted':
                !!value,
            })}
            isLoading={labelValueListMap[label]?.isLoading}
            onChange={(val: string) => {
              onQueryUpdate({ label, operator, value: val });
            }}
            options={labelValueListMap[label]?.data || []}
            placeholder={(value as string) || 'Select a value'}
            value={value as string}
          />
        )}
        {(operator === '=~' || operator === '!~') && (
          <Input
            autoFocus
            className="input--no-border metrics__query-builder__series__builder--input"
            onChange={(val) =>
              onQueryUpdate({ label, operator, value: val, isReload: false })
            }
            onBlur={() => {
              onQueryUpdate({ label, operator, value: value as string });
            }}
            type="text"
            value={value as string}
            placeholder="regex"
            variant="default"
            size="4"
          />
        )}
      </div>

      <TooltipTrigger
        className="button-group__item button-group__item__no-padding flex"
        tooltip="Delete"
      >
        <Button
          className="metrics__function-builder__item__close h-full"
          variant="ghost"
          size="sm"
          onClick={() => {
            if (labels.length === 1) {
              updateCardinalityQuery('labels', ['=""']);
              return;
            }
            const newLabels = [...labels];
            newLabels.splice(labelIndex, 1);
            updateCardinalityQuery('labels', newLabels);
          }}
        >
          <IoMdClose size={16} />
        </Button>
      </TooltipTrigger>
    </div>
  );
};

export default LogsCardinalityLabelValue;
