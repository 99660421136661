import {
  Filter,
  getLegacyFiltersFromFiltersState,
  SearchState,
  useRequest,
  useSearches,
  useTracesState,
} from 'hooks';
import { useMemo, useState } from 'react';
import getHeatmapData from 'requests/getHeatmapData';
import { getUrlParamByKey, heatmapDataTransformer } from 'utils';

const NUM_X_AXIS_SPLITS = 300;
const NUM_Y_AXIS_SPLITS = 100;

type Args = {
  searches: ReturnType<typeof useSearches>['searches'];
  tracesState: ReturnType<typeof useTracesState>;
};

const useTracesHeatmapRequest = ({ searches, tracesState }: Args) => {
  const [heatmapError, setHeatmapError] = useState({});
  const activeSearches = useMemo(() => {
    const activeSearchesArr = searches.filter(
      (search: SearchState) => search.isActive,
    );

    return activeSearchesArr;
  }, [searches]);

  const heatmapRequest = useRequest(
    async (params) => {
      const {
        attribute,
        date,
        numTimeBuckets,
        numAttributeBuckets,
        customerFilter,
        spanFilter,
        ParentSpanIdFilter,
        traceIdSearch,
        transformer,
      } = params;

      const searchFilters: { filter: Filter[]; queryKey: string }[] =
        activeSearches.map(
          (search: SearchState): { filter: Filter[]; queryKey: string } => ({
            filter: search.searchBarState?.filters,
            queryKey: search.queryKey,
          }),
        );

      const eventRequests = searchFilters.map(
        (searchFilter: { filter: Filter[]; queryKey: string }) => {
          const filter = getLegacyFiltersFromFiltersState(searchFilter?.filter);

          const resultRequest = {
            queryKey: searchFilter.queryKey,
            request: getHeatmapData({
              attribute,
              date,
              numTimeBuckets,
              numAttributeBuckets,
              customerFilter,
              spanFilter,
              ParentSpanIdFilter,
              traceIdSearch,
              transformer,
              filter,
            }),
          };

          return resultRequest;
        },
      );

      const settledResults = await Promise.allSettled(
        eventRequests.map((event) => event.request),
      );

      const resData = settledResults.map((result, index) => {
        const { queryKey } = eventRequests[index];
        return { queryKey, data: result?.value };
      });

      return resData;
    },
    true,
    true,
  );

  const {
    customerFilter,
    dateState,
    spanFilters,
    traceIdSearch,
    selectedFacetValuesByNameState,
    selectedHcFacetValuesByNameState,
    keyExistsState,
    facetRegexState,
    selectedFacetRangeByNameState,
  } = tracesState;

  const [date] = dateState;
  const { spanFilter } = spanFilters;
  const selectedFacetValuesByName = selectedFacetValuesByNameState.state;
  const selectedHcFacetValuesByName = selectedHcFacetValuesByNameState.state;
  const selectedFacetRangeByName = selectedFacetRangeByNameState.state;
  const keyExists = keyExistsState.state;
  const facetRegex = facetRegexState.state;

  const fetchHeatmapData = () => {
    const numTimeBucketsParam = getUrlParamByKey('numTimeBuckets');
    const numAttributeBucketsParam = getUrlParamByKey('numAttributeBuckets');
    const attribute = 'duration_ns';
    const numTimeBuckets =
      numTimeBucketsParam && !isNaN(Number(numTimeBucketsParam))
        ? Number(numTimeBucketsParam)
        : NUM_X_AXIS_SPLITS;
    const numAttributeBuckets =
      numAttributeBucketsParam && !isNaN(Number(numAttributeBucketsParam))
        ? Number(numAttributeBucketsParam)
        : NUM_Y_AXIS_SPLITS;

    heatmapRequest
      ?.call({
        attribute,
        date,
        numTimeBuckets,
        numAttributeBuckets,
        customerFilter,
        facetRegex,
        keyExists,
        selectedFacetValuesByName,
        selectedHcFacetValuesByName,
        selectedFacetRangeByName,
        spanFilter,
        ParentSpanIdFilter: '0000000000000000',
        traceIdSearch,
        transformer: [
          {
            id: 'heatmapDataTransformer',
            func: heatmapDataTransformer,
          },
        ],
      })
      .then((nextResult) => {
        if (!nextResult) return;

        const newError = nextResult.reduce((acc, result, index) => {
          acc[result?.queryKey] = result?.data
            ? null
            : 'Failed to fetch heatmap data';

          return acc;
        }, {});

        setHeatmapError(newError);
      });
  };

  return {
    fetchHeatmapData,
    heatmapError,
    heatmapRequest,
  };
};

export default useTracesHeatmapRequest;
