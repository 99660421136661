import classNames from 'classnames';
import { ConfirmationModal, FlyoutCaret } from 'components';
import React, { ReactElement } from 'react';
import { DashboardPanelComponentProps } from 'types';

import {
  getPanelLayoutOnRowChange,
  getReloadPanelsForNotNested,
  transformTitle,
  getTemplateValueWithRepeated,
  SUPPORTED_EDIT_PANELS,
  getTemplateValueWithAll,
} from '../utils';
import { DashboardEdit } from '../DashboardEdit';
import { MdDelete, MdModeEdit } from 'react-icons/md';

const DashboardPanelRow = ({
  dashboardState,
  dashboardTemplateState,
  disableDeletePanel,
  nestedIndex,
  panel,
  panelIndex,
}: DashboardPanelComponentProps): ReactElement => {
  const { type } = panel;
  const {
    deletePanel,
    panelSetupModal,
    panels,
    reloadPanels,
    setPanels,
    setReloadPanels,
    updateEditedPanel,
  } = dashboardState;
  const { templateValues, templateOptions, templating } =
    dashboardTemplateState;
  const collapsed = panel.collapsed;

  const onEdit = () => {
    const { gridPos } = panel;
    const templateValuesWithAll = getTemplateValueWithAll({
      templateValues,
      templateOptions: templateOptions,
      templating,
    });
    panelSetupModal.push(
      <DashboardEdit
        close={() => panelSetupModal.pop()}
        date={null}
        itemLayout={gridPos}
        panelType={panel.type}
        panel={panel}
        nestedIndex={nestedIndex}
        panelIndex={panelIndex}
        templateValues={templateValuesWithAll}
        updateEditedPanel={updateEditedPanel}
      />,
    );
  };

  const onDashboardDelete = () => {
    panelSetupModal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description={
          <span>
            Are you sure you want to delete &quot;
            <b className="text--red">{panel.title}</b>&quot; row? Once deleted,
            it cannot be recovered.
          </span>
        }
        onCancel={() => panelSetupModal.pop()}
        onConfirm={() => {
          deletePanel(panelIndex);
          panelSetupModal.pop();
        }}
        title={`Delete Panel`}
      />,
    );
  };

  return (
    <div
      className={classNames({
        'dashboard-panel__draggable-handle': true,
        dashboard__panel__row: true,
        'dashboard__panel__row--collapsed': panel.collapsed,
      })}
      data-testid="dashboard-panel-row"
      onClick={() => {
        if (nestedIndex) {
          return;
        }
        const layoutAdjustedPanels = getPanelLayoutOnRowChange({
          panels,
          panelIndex,
          collapsed: !collapsed,
        });

        setPanels(layoutAdjustedPanels);

        if (!collapsed) {
          return;
        }

        const reloadPanelsNotNested = getReloadPanelsForNotNested(
          layoutAdjustedPanels,
          panelIndex,
        );

        setReloadPanels({ ...reloadPanels, ...reloadPanelsNotNested });
      }}
    >
      <div className="flex flex-1 justify-between pr-4">
        <div className="flex items-center">
          <FlyoutCaret isOpen={!panel.collapsed} size={20} />
          {transformTitle({
            title: panel.title || '',
            templateValues: getTemplateValueWithRepeated(templateValues, panel),
            templating,
          })}
          {panel.panels && panel.panels.length > 0 && (
            <div className="dashboard__panel__row__count">
              ({panel.panels.length} Panels)
            </div>
          )}
        </div>
        {disableDeletePanel ? null : (
          <div className="dashboard-panel__header__actions">
            {SUPPORTED_EDIT_PANELS.includes(type) &&
              (!panel.repeatedRowId || panel.repeatedRowId === 0) && (
                <>
                  <div className="dashboard-panel__header__actions__icon--edit">
                    <MdModeEdit onClick={onEdit} />
                  </div>
                  <div className="dashboard-panel__header__actions__icon--delete">
                    <MdDelete onClick={onDashboardDelete} />
                  </div>
                </>
              )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardPanelRow;
