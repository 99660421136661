import { useSelectedFacetValuesByNameState } from 'hooks';
import { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getDateFromRange } from 'screens/Dashboard/utils';
import { DateSelection } from 'types/index';
import { extractPathNameFromURL, parseUrlParamByKey } from 'utils';

const useHydrationPageState = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const hydrationDateState = useState<DateSelection>(
    parseUrlParamByKey('hydrationDate') || getDateFromRange('now-1M', 'now'),
  );

  const parsedParamValueJobs = parseUrlParamByKey(
    'hydrationJobsSelectedFacetValuesByName',
  );
  const initialStateJobs = parsedParamValueJobs || {};
  const selectedFacetValuesByNameStateJobs = useSelectedFacetValuesByNameState({
    shouldWriteToUrl: false,
    initialState: initialStateJobs,
  });

  const parsedParamValueArchives = parseUrlParamByKey(
    'hydrationArchivesSelectedFacetValuesByName',
  );
  const initialStateArchives = parsedParamValueArchives || {};
  const selectedFacetValuesByNameStateArchives =
    useSelectedFacetValuesByNameState({
      shouldWriteToUrl: false,
      initialState: initialStateArchives,
    });

  // Get dependencies for determining when a url change should happen
  const dependenciesForWriteStateToUrl = useMemo(
    () => [
      hydrationDateState[0],
      selectedFacetValuesByNameStateJobs.state,
      selectedFacetValuesByNameStateArchives.state,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      hydrationDateState[0],
      selectedFacetValuesByNameStateJobs.state,
      selectedFacetValuesByNameStateArchives.state,
    ],
  );

  const writeStateToUrl = () => {
    params.set('hydrationDate', JSON.stringify(hydrationDateState[0]));
    params.set(
      'hydrationJobsSelectedFacetValuesByName',
      JSON.stringify(selectedFacetValuesByNameStateJobs.state),
    );
    params.set(
      'hydrationArchivesSelectedFacetValuesByName',
      JSON.stringify(selectedFacetValuesByNameStateArchives.state),
    );
    const navigateUrl = `${extractPathNameFromURL()}?${params.toString()}`;
    navigate(navigateUrl, { replace: true });
  };

  return {
    dateState: hydrationDateState,
    dependenciesForWriteStateToUrl,
    selectedFacetValuesByNameStateJobs,
    selectedFacetValuesByNameStateArchives,
    writeStateToUrl,
  };
};

export default useHydrationPageState;
