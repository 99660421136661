import { Button, TooltipTrigger } from 'components';
import React, { ReactElement } from 'react';
import { Plus } from 'react-feather';

import MetricsCardinalityMetric from './MetricsCardinalityMetric';
import MetricsCardinalityLabelValue from './MetricsCardinalityLabelValue';
import useMetricsCardinality from './useMetricsCardinality';
import { cardinalityMatcher } from './utils';

const MetricsCardinalityLabel = ({
  disableMetric = false,
  metricsCardinality,
}: {
  disableMetric?: boolean;
  metricsCardinality: ReturnType<typeof useMetricsCardinality>;
}): ReactElement => {
  const { cardinalityQuery, date, labelValuePicker, setCardinalityQuery } =
    metricsCardinality;

  const { loadLabelList } = labelValuePicker;
  return (
    <>
      {cardinalityQuery.labels.map((series: string, index: number) => (
        <>
          <MetricsCardinalityLabelValue
            key={`${series}-${index}`}
            labelIndex={index}
            series={series}
            metricsCardinality={metricsCardinality}
          />
          <div className="search__button-group__divider">
            <div />
          </div>
        </>
      ))}
      {cardinalityQuery.labels[cardinalityQuery.labels.length - 1] !==
        '=""' && (
        <>
          <div className="button-group">
            <TooltipTrigger className="flex" tooltip="Add New Label">
              <Button
                variant="icon-outline"
                onClick={() => {
                  const newLabels = [...cardinalityQuery.labels, '=""'];
                  setCardinalityQuery((prev) => {
                    const newQuery = { ...prev, labels: newLabels };
                    const { labelMatcher, metricMatcher } =
                      cardinalityMatcher(newQuery);

                    loadLabelList({
                      date,
                      matcher:
                        newQuery.type === 'metric'
                          ? metricMatcher
                          : labelMatcher,
                    });
                    return newQuery;
                  });
                }}
              >
                <Plus size={16} />
              </Button>
            </TooltipTrigger>
          </div>
          <div className="search__button-group__divider">
            <div />
          </div>
        </>
      )}
      {!disableMetric && (
        <MetricsCardinalityMetric
          disableLabel={true}
          metricsCardinality={metricsCardinality}
          placeholder="Select a metric (optional)"
        />
      )}
    </>
  );
};

export default MetricsCardinalityLabel;
