import { Loader, SunburstGraph, Table } from 'components';
import React, { ReactElement, useMemo } from 'react';
import { DashboardPanelProps } from 'types/Dashboard';

const DashboardEditGraphSunburst = ({
  baseWidth,
  dataFormatter,
  panel,
}: {
  baseWidth: number;
  dataFormatter: () => any;
  panel: DashboardPanelProps;
}): ReactElement => {
  const sunburstQueryData = useMemo(() => dataFormatter(), [dataFormatter]);

  const unit = panel.fieldConfig?.defaults?.unit || 'number';
  return (
    <Loader isLoading={sunburstQueryData?.isLoading}>
      <div className="dashboard-edit__metric__body__sunburst">
        <div className="dashboard-edit__metric__body__sunburst__graph">
          {sunburstQueryData?.data ? (
            <SunburstGraph
              data={{
                name: '',
                children: sunburstQueryData.pieChartData,
                color: 'transparent',
              }}
              height={340}
              width={baseWidth / 2 - 100}
              unit={unit}
            />
          ) : null}
        </div>
        <div className="dashboard-edit__metric__body__sunburst__table">
          <Table
            className="table--bordered table--bordered-cells"
            columns={sunburstQueryData?.columns || []}
            rows={sunburstQueryData?.data || []}
          />
        </div>
      </div>
    </Loader>
  );
};

export default DashboardEditGraphSunburst;
