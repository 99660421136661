import React from 'react';
import { Maximize2 } from 'react-feather';

import { TooltipTrigger } from 'components';
import { useRefContext } from 'hooks';
import { cn } from '../utils/tailwind';

const ShowSidebarTooltipButton = ({
  className,
  onClick,
  tooltip,
}: {
  className?: string;
  onClick: () => void;
  tooltip?: React.ReactNode;
}) => {
  const { bodyDivRef } = useRefContext();
  return (
    <TooltipTrigger
      className={cn('logs__search__show-filters-button', className)}
      tooltip={tooltip || 'Show Filters'}
      variant="compact"
      collisionBoundaryRef={bodyDivRef}
      asChild={true}
    >
      <button
        className="button button--icon"
        onClick={onClick}
        data-testid="show-hide-sidebar"
      >
        <Maximize2 size={12} />
      </button>
    </TooltipTrigger>
  );
};

export default ShowSidebarTooltipButton;
