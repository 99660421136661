import { fetchJson } from 'requests';
import { ArchiveInfoProps } from '../types';

const getArchivesList = async (): Promise<ArchiveInfoProps[]> => {
  const result = (await fetchJson('/hydration/query/list-archives')) as {
    archiveInfo: ArchiveInfoProps[];
  };

  return result?.archiveInfo || [];
};

export default getArchivesList;
