import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const parseFromUrl = (key: string) => {
  const url = new URL(window.location.href);
  const firstQuestionMark = url.hash.indexOf('?');
  return new URLSearchParams(
    url.hash.substring(firstQuestionMark, url.hash.length),
  ).get(key);
};

const stringifyToUrl = (key: string, state: any) => {
  const url = new URL(window.location.href);
  const firstQuestionMark = url.hash.indexOf('?');
  if (firstQuestionMark === -1) {
    return `${key}=${JSON.stringify(state)}`;
  }

  const params = new URLSearchParams(
    url.hash.substring(firstQuestionMark, url.hash.length),
  );
  params.set(key, JSON.stringify(state));
  return params.toString();
};

const useUrlQueryStringReducer = ({
  key,
  state,
  dispatch,
  shouldWriteToUrl = true,
}: {
  key: string;
  state: any;
  dispatch: (action: any) => void;
  shouldWriteToUrl?: boolean;
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!shouldWriteToUrl) return;
    const urlState = parseFromUrl(key);
    if (urlState) {
      dispatch({ type: 'UPDATE_FROM_URL', payload: urlState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!shouldWriteToUrl) return;
    // Update the URL when the state changes
    const newQueryString = stringifyToUrl(key, state);
    navigate(`?${newQueryString}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, navigate, key, location.search]);

  useEffect(() => {
    if (!shouldWriteToUrl) return;
    // Update the state when the URL changes
    const handlePopState = () => {
      const urlState = parseFromUrl(key);
      dispatch({ type: 'UPDATE_FROM_URL', payload: urlState });
    };

    window.addEventListener('popstate', handlePopState);
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, dispatch]);
};

export default useUrlQueryStringReducer;
