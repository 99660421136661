import {
  CompactTooltipContainerV2,
  CompactTooltipText,
  useThemeContext,
} from 'components';
import React, { MutableRefObject, ReactElement, useLayoutEffect } from 'react';
import { EventListProps, TimeseriesToolProps, TooltipCoordsProps } from 'types';
import uPlot from 'uplot';
import { drawVersionPoints, getServiceVersionTooltip } from 'utils';

const ServiceTabChartGridVersion = ({
  eventListRef,
  infoMessage,
  timeseriesProps,
}: {
  eventListRef: MutableRefObject<EventListProps[] | null>;
  infoMessage?: string;
  timeseriesProps: TimeseriesToolProps;
}): ReactElement => {
  const { layoutType } = timeseriesProps;
  const { darkModeEnabled } = useThemeContext();
  const [coords, setCoords] = React.useState<TooltipCoordsProps>(null);
  useLayoutEffect(() => {
    const eventList = eventListRef?.current || [];

    timeseriesProps.config.addHook('draw', (u: uPlot) => {
      drawVersionPoints({ darkModeEnabled, eventList, u });
    });

    timeseriesProps.config.addHook('setCursor', (u: uPlot) => {
      if (!eventListRef?.current) return;
      const tooltipData = getServiceVersionTooltip({
        darkModeEnabled,
        layout: layoutType,
        u,
      });
      setCoords(tooltipData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkModeEnabled, eventListRef?.current, timeseriesProps.config]);
  const renderTooltip = () => {
    return (
      <CompactTooltipContainerV2 coords={coords}>
        <CompactTooltipText
          color={coords.data.color as string}
          label={coords.data.label}
          position={coords.positionX}
        />
      </CompactTooltipContainerV2>
    );
  };

  return (
    <>
      {coords && renderTooltip()}
      {infoMessage ? (
        <span className="ml-2 text-blue-500">{infoMessage}</span>
      ) : null}
    </>
  );
};

export default ServiceTabChartGridVersion;
