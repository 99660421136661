import React from 'react';
import { Clock, Link2, Mail } from 'react-feather';
import { BiTag } from 'react-icons/bi';
import { GrStorage } from 'react-icons/gr';
import { TfiWorld } from 'react-icons/tfi';
import { TiSortAlphabetically, TiSortNumerically } from 'react-icons/ti';

type Props = {
  type: string;
};

const LogsFacetGroupFacetIcon = ({ type }: Props) => {
  switch (type.toLowerCase()) {
    case 'duration':
      return (
        <Clock
          className="logs__facet-group__facet__title__icon--duration logs__facet-group__facet__title__icon--feather"
          size={13}
        />
      );
    case 'email':
      return (
        <Mail
          className="logs__facet-group__facet__title__icon--feather"
          size={13}
        />
      );
    case 'ip_address':
      return (
        <TfiWorld
          className="logs__facet-group__facet__title__icon--ip_address logs__facet-group__facet__title__icon--react-icons"
          size={13}
        />
      );
    case 'loglevel':
      return (
        <BiTag
          className="logs__facet-group__facet__title__icon--react-icons"
          size={18}
        />
      );
    case 'number':
      return (
        <TiSortNumerically
          className="logs__facet-group__facet__title__icon--number logs__facet-group__facet__title__icon--react-icons"
          size={18}
        />
      );
    case 'size':
      return (
        <GrStorage
          className="logs__facet-group__facet__title__icon--size logs__facet-group__facet__title__icon--gr"
          size={10}
        />
      );
    case 'string':
      return (
        <TiSortAlphabetically
          className="logs__facet-group__facet__title__icon--string logs__facet-group__facet__title__icon--react-icons"
          size={18}
        />
      );
    case 'url':
      return (
        <Link2
          className="logs__facet-group__facet__title__icon logs__facet-group__facet__title__icon--feather"
          size={13}
        />
      );
    case 'uuid':
      return (
        <span className="logs__facet-group__facet__title__icon logs__facet-group__facet__title__icon--uuid logs__facet-group__facet__title__icon--text">
          id
        </span>
      );
    default:
      return null;
  }
};

export default LogsFacetGroupFacetIcon;
