import { DateSelection } from 'types';
import {
  getAdjustedStartAndEndTimeUnix,
  getRollupByVisualization,
  getRollupToSecond,
} from 'utils';

import { getForecastDefaultIntervalBySeasonality } from './function-forecast';

const NUMBER_OF_HISTORY_TIMES = 4;
const DEFAULT_LOOKBACK_DURATION = 3600 * 1; // 12 hour
const DEFAULT_FORECAST_DURATION = 3600 * 1; // 12 hour

const getForecastLookbackAndPredictDate = ({
  forecastDuration,
  lookbackDuration,
  date,
  seasonality,
  forecastInterval,
  type,
}: {
  forecastDuration: string;
  lookbackDuration: string;
  date: DateSelection;
  seasonality?: string;
  forecastInterval?: number;
  type: 'logs' | 'metrics';
}): {
  lookbackDate: DateSelection;
  predictedDate: DateSelection;
  historyDate: DateSelection;
  step: number;
} => {
  if (!forecastDuration) return;
  let forecastDurationSeconds = 0;
  if (typeof forecastDuration === 'string') {
    forecastDurationSeconds = getRollupToSecond(forecastDuration);
  } else {
    forecastDurationSeconds = forecastDuration;
  }

  if (forecastDurationSeconds < DEFAULT_FORECAST_DURATION) {
    forecastDurationSeconds = DEFAULT_FORECAST_DURATION;
  }

  let lookbackDurationSeconds = getRollupToSecond(lookbackDuration);
  if (lookbackDurationSeconds < DEFAULT_LOOKBACK_DURATION) {
    lookbackDurationSeconds = DEFAULT_LOOKBACK_DURATION;
  }

  const lookbackDate = {
    startTimeUnix: date.endTimeUnix - lookbackDurationSeconds,
    endTimeUnix: date.endTimeUnix,
  };

  const predictedDate = {
    startTimeUnix: date.endTimeUnix - forecastDurationSeconds,
    endTimeUnix: date.endTimeUnix,
  };

  const combinedDate = {
    startTimeUnix: lookbackDate.startTimeUnix,
    endTimeUnix: lookbackDate.endTimeUnix + forecastDurationSeconds,
  };

  let step = getRollupByVisualization(combinedDate);
  if (forecastInterval) {
    step = forecastInterval;
  }
  if (seasonality) {
    step = getForecastDefaultIntervalBySeasonality(seasonality, type);
  }

  const predictedDateAdjusted = getAdjustedStartAndEndTimeUnix({
    date: predictedDate,
    step: `${step}s`,
  });

  const lookbackDateAdjusted = getAdjustedStartAndEndTimeUnix({
    date: lookbackDate,
    step: `${step}s`,
  });

  // 4x the forecast duration to account for the fact that we are forecasting the future
  const historyDuration = forecastDurationSeconds * NUMBER_OF_HISTORY_TIMES;
  const historyDate = {
    startTimeUnix: lookbackDate.endTimeUnix - historyDuration,
    endTimeUnix: lookbackDate.endTimeUnix,
  };

  return {
    lookbackDate: lookbackDateAdjusted,
    predictedDate: predictedDateAdjusted,
    historyDate,
    step,
  };
};

export default getForecastLookbackAndPredictDate;
