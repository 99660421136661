import classnames from 'classnames';
import {
  DateWarningAlert,
  LeftSidebar,
  Loader,
  ShowSidebarTooltipButton,
  useLeftSidebarState,
} from 'components';
import { useFeatureFlag } from 'configcat-react';
import { useServicesPageStateContext } from 'context';
import { useRequest, useToggle } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { IoIosWarning } from 'react-icons/io';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import {
  getServiceGroupLabels,
  getServiceGroupLabelValues,
  getServices,
  traceLabelValuesV2,
} from 'requests';
import { Service as ServiceType } from 'types';
import useDebouncedEffect from 'use-debounced-effect';
import { getIsAsmChecked } from 'utils';
import ServiceContainer from '../Service/ServiceContainer';
import CustomerFilter from './CustomerFilter';
import ServicesMainServicesContainer from './ServicesMainServicesContainer';
import ServicesSidebar from './ServicesSidebar';
import { ServicesTab } from './utils';

type Props = {
  isAsm?: boolean;
  tab?: string;
  isInServiceDetails?: boolean;
};

const Services = ({ isAsm = false, tab, isInServiceDetails }: Props) => {
  const { value: showService7Day } = useFeatureFlag('showservice7day', false);
  const {
    value: isCustomerFilteringEnabled,
    loading: isCustomerFilteringConfigLoading,
  } = useFeatureFlag('isCustomerFilteringEnabled', false);
  const leftSidebarState = useLeftSidebarState('services');
  const { serviceNameAndHash } = useParams();
  const serviceNameAndHashParts = (serviceNameAndHash || '').split('_');
  const serviceHash =
    serviceNameAndHashParts[serviceNameAndHashParts.length - 1];
  const showSidebarToggle = useToggle(true);
  const location = useLocation();

  const [error, setError] = useState({
    getMetricsAgent: null,
    getSdkName: null,
    getServiceGroupLabel: null,
    getServiceGroupValues: null,
  });

  const {
    apmDateState,
    customerFilter,
    dependenciesForWriteStateToUrl,
    showInactiveServices,
    selectedFacetValuesByNameState,
    setCustomerFilter,
    setShowInactiveServices,
    chartGridKeysState,
    writeStateToUrl,
  } = useServicesPageStateContext();

  const kfMetricsAgentRequest = useRequest(traceLabelValuesV2, true, true);
  const telemetrySdkNameRequest = useRequest(traceLabelValuesV2, true, true);

  // TODO: This is so hack.
  useEffect(() => {
    if (isAsm) {
      selectedFacetValuesByNameState.selectOnlyFacetValue({
        name: 'kf_source',
        value: 'knight',
      });
    } else {
      if (location.pathname === '/apm/services') {
        selectedFacetValuesByNameState.selectOnlyFacetValue({
          name: 'kf_source',
          value: 'apm',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAsm]);

  const isAsmChecked = useMemo(
    () => getIsAsmChecked(selectedFacetValuesByNameState.state),
    [selectedFacetValuesByNameState.state],
  );

  const dateState = apmDateState;
  const [date] = dateState;

  useDebouncedEffect(
    () => {
      telemetrySdkNameRequest
        .call({
          customerFilter: customerFilter,
          date,
          name: 'telemetry_sdk_name',
          selectedFacetValuesByName: selectedFacetValuesByNameState.state,
          serviceHash,
        })
        .then((nextResult) => {
          if (nextResult) {
            setError((prevError) => ({ ...prevError, getSdkName: null }));
          }
        })
        .catch(() => {
          setError((prevError) => ({
            ...prevError,
            getSdkName: { message: 'Failed to fetch telemetry sdk names' },
          }));
        });
      kfMetricsAgentRequest
        .call({
          customerFilter: customerFilter,
          date,
          name: 'kf_metrics_agent',
          selectedFacetValuesByName: selectedFacetValuesByNameState.state,
          serviceHash,
        })
        .then((nextResult) => {
          if (nextResult) {
            setError((prevError) => ({ ...prevError, getMetricsAgent: null }));
          }
        })
        .catch(() => {
          setError((prevError) => ({
            ...prevError,
            getMetricsAgent: { message: 'Failed to fetch metrics agent' },
          }));
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    { ignoreInitialCall: false, timeout: 100 },
    [customerFilter, date, selectedFacetValuesByNameState.state, serviceHash],
  );

  useEffect(() => {
    if (telemetrySdkNameRequest.error) {
      setError((prevError) => ({
        ...prevError,
        getSdkName: {
          message: 'Failed to fetch telemetry name',
          status_code: 401,
        },
      }));
    }
  }, [telemetrySdkNameRequest.error]);

  useEffect(() => {
    if (kfMetricsAgentRequest.error) {
      setError((prevError) => ({
        ...prevError,
        getMetricsAgent: {
          message: 'Failed to fetch metrics agent',
          status_code: 401,
        },
      }));
    }
  }, [kfMetricsAgentRequest.error]);

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependenciesForWriteStateToUrl]);

  const [params] = useSearchParams();
  const isServiceFromDatabasesList = useMemo(() => {
    const isDatabase = params?.get('activeTab') === ServicesTab.db;
    return isDatabase;
  }, [params]);

  const serviceTab = useMemo(() => {
    if (isServiceFromDatabasesList) {
      return ServicesTab.db;
    }
    if (tab) {
      return tab;
    }
    return null;
  }, [tab, isServiceFromDatabasesList]);

  const getServicesRequest = useRequest(getServices, true, true);
  const getServiceGroupLabelRequest = useRequest(
    getServiceGroupLabels,
    true,
    true,
  );
  const getServiceGroupValuesRequest = useRequest(
    getServiceGroupLabelValues,
    true,
    true,
  );

  const serviceByHash: Record<string, ServiceType> = useMemo(
    () =>
      (getServicesRequest.result || []).reduce(
        (obj, service) => ({ ...obj, [service.hash]: service }),
        {},
      ),
    [getServicesRequest.result],
  );

  useEffect(() => {
    if (isCustomerFilteringEnabled) {
      getServiceGroupLabelRequest
        .call()
        .then((nextResult) => {
          if (nextResult) {
            setError((prevError) => ({
              ...prevError,
              getServiceGroupLabel: null,
            }));
          }
        })
        .catch(() => {
          setError((prevError) => ({
            ...prevError,
            getServiceGroupLabel: {
              message: 'Failed to fetch service group label',
            },
          }));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerFilteringEnabled]);

  const selectedLabel = useMemo(() => {
    if (
      getServiceGroupLabelRequest.result &&
      getServiceGroupLabelRequest.result.length
    ) {
      const firstLabel = getServiceGroupLabelRequest.result[0];
      return firstLabel;
    }
    return null;
  }, [getServiceGroupLabelRequest.result]);

  useDebouncedEffect(
    () => {
      if (selectedLabel) {
        getServiceGroupValuesRequest
          .call({ label: selectedLabel })
          .then((nextResult) => {
            if (nextResult) {
              setError((prevError) => ({
                ...prevError,
                getServiceGroupValues: null,
              }));
            }
          })
          .catch(() => {
            setError((prevError) => ({
              ...prevError,
              getServiceGroupValues: {
                message: 'Failed to fetch service group values',
              },
            }));
          });
      }
    },
    50,
    [selectedLabel],
  );

  const service = serviceByHash[serviceHash];

  if (isCustomerFilteringConfigLoading) {
    return <Loader className="h-full" isLoading={true} />;
  }

  const isCustomerFilterSelected = Boolean(customerFilter?.value);

  const showServicesLandingPage =
    !isCustomerFilteringEnabled || isCustomerFilterSelected;

  return (
    <div
      className={classnames({
        services: true,
        'services--disable-7-day': !showService7Day,
      })}
    >
      <LeftSidebar leftSidebarState={leftSidebarState}>
        <>
          {isCustomerFilteringEnabled && !serviceHash && (
            <CustomerFilter
              customerFilter={customerFilter}
              filterLabel={selectedLabel}
              valueOptions={getServiceGroupValuesRequest.result || []}
              isLoading={getServiceGroupValuesRequest.isLoading}
              setCustomerFilter={setCustomerFilter}
              error={error}
            />
          )}

          {error?.getServiceGroupValues && (
            <div className="flex gap-[4px] w-full justify-start ml-[10px] pr-[14px]">
              <IoIosWarning
                className="overlay-message__icon-and-message__icon"
                size={16}
              />
              <div className="text-red-500">Failed to fetch group values</div>
            </div>
          )}

          {error?.getServiceGroupLabel && (
            <div className="flex gap-[4px] w-full justify-start ml-[10px] pr-[14px]">
              <IoIosWarning
                className="overlay-message__icon-and-message__icon"
                size={16}
              />
              <div className="text-red-500">Failed to fetch group label</div>
            </div>
          )}
          {showServicesLandingPage || serviceHash ? (
            <ServicesSidebar
              customerFilter={customerFilter}
              date={date}
              isAsmChecked={isAsmChecked}
              selectedFacetValuesByNameState={selectedFacetValuesByNameState}
              serviceHash={serviceHash}
              serviceDistinctLabels={service?.distinctLabels || {}}
              showSidebarToggle={showSidebarToggle}
              serviceTab={serviceTab}
              telemetrySdkName={
                telemetrySdkNameRequest.result?.[0]?.groupVal
                  ?.telemetry_sdk_name
              }
              isInServiceDetails={isInServiceDetails}
            />
          ) : null}
        </>
      </LeftSidebar>

      <div className="services__main">
        <DateWarningAlert
          className={classnames({
            services__banner: true,
            'services__banner--no-left-padding': true,
          })}
          date={date}
        />

        {serviceHash ? (
          <ServiceContainer
            apmDateState={apmDateState}
            chartGridKeysState={chartGridKeysState}
            customerFilter={customerFilter}
            isAsmChecked={isAsmChecked}
            getServicesRequest={getServicesRequest}
            key={serviceHash}
            kfMetricsAgentName={
              kfMetricsAgentRequest.result?.[0]?.groupVal?.kf_metrics_agent
            }
            leftSidebarState={leftSidebarState}
            selectedFacetValuesByNameState={selectedFacetValuesByNameState}
            serviceHash={serviceHash}
            service={service}
            serviceByHash={serviceByHash}
            telemetrySdkName={
              telemetrySdkNameRequest.result?.[0]?.groupVal?.telemetry_sdk_name
            }
            error={error}
          />
        ) : showServicesLandingPage ? (
          <ServicesMainServicesContainer
            customerFilter={customerFilter}
            dateState={dateState}
            isAsmChecked={isAsmChecked}
            leftSidebarState={leftSidebarState}
            serviceTab={serviceTab}
            showInactiveServices={showInactiveServices}
            selectedFacetValuesByNameState={selectedFacetValuesByNameState}
            setCustomerFilter={setCustomerFilter}
            setShowInactiveServices={setShowInactiveServices}
          />
        ) : (
          <div className="flex flex-col height-100-per w-full">
            {leftSidebarState.width === 0 ? (
              <div className="flex justify-start">
                <ShowSidebarTooltipButton
                  className="customer-filter__show-sidebar"
                  onClick={leftSidebarState.show}
                />
                <div />
              </div>
            ) : null}
            <div className="flex-1 flex items-center justify-center">
              <div className="facet-picker__values__placeholder italic text-2xl">
                Select Service Group Filter To View Available Services
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Services;
