import { Button } from 'components';
import classnames from 'classnames';
import { colors } from 'kfuse-constants';
import React, { useMemo } from 'react';
import { Settings } from 'react-feather';
import { BiFullscreen, BiZoomIn, BiZoomOut } from 'react-icons/bi';
import { FaSearch } from 'react-icons/fa';
import { MdOutlineMyLocation } from 'react-icons/md';
import ServiceMapToolbarOptionsPanel from './ServiceMapToolbarOptionsPanel';
import { PopoverPosition, PopoverTriggerV2 } from '../PopoverTriggerV2';
import { Select } from '../SelectV2';
import { CustomBEFilterType } from './types';

type Props = {
  customFilters: any;
  customBEFilters?: CustomBEFilterType[];
  initialNodes: any[];
  hideSearchNode?: boolean;
  reactFlow: any;
  resetZoom: () => void;
  serviceMapState: any;
};

const ServiceMapToolbar = ({
  customFilters,
  customBEFilters,
  initialNodes,
  hideSearchNode,
  reactFlow,
  resetZoom,
  serviceMapState,
}: Props) => {
  const { changeFocusedNodeId, changeSearch, resetFocusedNodeId, state } =
    serviceMapState;
  const { focusedNodeId, search, selectedNodeId } = state;

  const zoomIn = () => {
    reactFlow.zoomIn({ duration: 200 });
  };

  const zoomOut = () => {
    reactFlow.zoomOut({ duration: 200 });
  };

  const changeFocusedNodeIdHandler = () => {
    changeFocusedNodeId();
    setTimeout(resetZoom, 500);
  };

  const clearSearch = () => {
    changeSearch('');
    setTimeout(resetZoom, 500);
  };

  const resetFocusedNodeIdHandler = () => {
    resetFocusedNodeId();
    setTimeout(resetZoom, 500);
  };

  const nodesById = useMemo(
    () => initialNodes.reduce((obj, node) => ({ ...obj, [node.id]: node }), {}),
    [initialNodes],
  );

  const searchOptions = useMemo(
    () =>
      initialNodes
        .filter((node) => node.id)
        .sort((a, b) => a.id.localeCompare(b.id))
        .map((node) => ({
          label: node.data?.optionLabel || node.id,
          value: node.id,
        })),
    [initialNodes],
  );

  const onSearchSelection = (nodeId: string) => {
    changeSearch(nodeId);
    setTimeout(resetZoom, 500);
  };

  return (
    <div className="service-map__toolbar">
      <div className="service-map__toolbar__left">
        <div>
          <Button
            variant="icon-outline"
            size="xs"
            onClick={zoomIn}
            data-testId="zoomIn"
          >
            <BiZoomIn size={16} />
          </Button>
        </div>
        <div className="ml-1">
          <Button
            variant="icon-outline"
            size="xs"
            onClick={zoomOut}
            data-testId="zoomOut"
          >
            <BiZoomOut size={16} />
          </Button>
        </div>
        <div className="ml-1">
          <Button
            variant="icon-outline"
            size="xs"
            onClick={resetZoom}
            data-testId="resetZoom"
          >
            <BiFullscreen className="button__icon" size={16} />
            <span>Reset Zoom</span>
          </Button>
        </div>
        {selectedNodeId ? (
          <div className="service-map__toolbar__item">
            <Button
              variant={focusedNodeId ? 'accent' : 'icon-outline'}
              size="xs"
              className={classnames({
                'ml-1': true,
              })}
              onClick={
                focusedNodeId
                  ? resetFocusedNodeIdHandler
                  : changeFocusedNodeIdHandler
              }
            >
              <MdOutlineMyLocation
                className="button__icon"
                size={16}
                color={colors.blue}
              />
              <span>{focusedNodeId ? 'Reset focus' : 'Focus on selected'}</span>
            </Button>
          </div>
        ) : null}
      </div>
      {!hideSearchNode && (
        <div className="service-map__toolbar__center">
          <div className="service-map__toolbar__item">
            <div
              className="service-map__toolbar__search"
              data-testid="service-map_search_bar"
            >
              <FaSearch className="service-map__toolbar__search__icon" />
              <Select
                className="service-map__toolbar__search__input select--small select--naked select--underline"
                clear={clearSearch}
                isAutocompleteEnabled
                getSearchedValue={(value) =>
                  nodesById[value].searchValue || value
                }
                onSearchChange={changeSearch}
                onChange={onSearchSelection}
                options={searchOptions}
                placeholder={search || 'Search Nodes'}
                value={search}
                dataTestId="service-map_options"
              />
            </div>
          </div>
        </div>
      )}
      <div
        className="service-map__toolbar__right"
        data-testId="service-map_legend"
      >
        <div className="service-map__toolbar__item">
          <div className="service-map__toolbar__legend">
            <div className="service-map__toolbar__legend__selected" />
            Selected
          </div>
        </div>
        <div className="service-map__toolbar__item">
          <div className="service-map__toolbar__legend">
            <div className="service-map__toolbar__legend__error" />
            Errors
          </div>
        </div>
        <div className="service-map__toolbar__item">
          <div className="service-map__toolbar__legend">
            <div className="service-map__toolbar__legend__outer-ring" />
            {'Highlight '}
            <Select
              className="select--naked select--small select--underline"
              onChange={serviceMapState.changeOuterRingKey}
              options={[
                { label: 'Nothing', value: null },
                { label: 'Requests', value: 'requests' },
              ]}
              placeholder="Nothing"
              right
              value={serviceMapState.state.outerRingKey}
            />
          </div>
        </div>
      </div>
      <div className="service-map__toolbar__item">
        <PopoverTriggerV2
          dataTestId="options"
          popover={(props) => (
            <ServiceMapToolbarOptionsPanel
              {...props}
              customFilters={customFilters}
              customBEFilters={customBEFilters}
              resetZoom={resetZoom}
              serviceMapState={serviceMapState}
            />
          )}
          position={PopoverPosition.BOTTOM_RIGHT}
        >
          <div className="button button--short">
            <Settings className="button__icon" size={14} />
            <span>Options</span>
          </div>
        </PopoverTriggerV2>
      </div>
    </div>
  );
};

export default ServiceMapToolbar;
