import { ChartRenderProps } from 'components';
import { cloneDeep } from 'lodash';
import { Layout } from 'react-grid-layout';
import {
  DashboardFieldConfigProps,
  DashboardPanelType,
  DashboardPanelProps,
  DashboardStreamType,
  DashboardTemplateProps,
  DashboardPanelOverrideProps,
} from 'types';

import {
  GRID_CELL_HEIGHT,
  GRID_COLUMN_COUNT,
  GRID_HEADER_HEIGHT,
  GRID_CELL_VMARGIN,
} from '../constants';

/**
 * Convert legacy panel type graph to timeseries
 * @param panel
 * @returns panel
 */
const convertLegacyGraphToTimeseries = (
  panel: DashboardPanelProps,
): DashboardPanelProps => {
  const newPanel: DashboardPanelProps = {};
  newPanel.type = DashboardPanelType.TIMESERIES;
  newPanel.gridPos = panel.gridPos;
  newPanel.id = panel.id;
  newPanel.title = panel.title;
  newPanel.targets = panel.targets.map((target) => {
    return { ...target, hide: false };
  });
  newPanel.options = {
    legend: { calcs: ['last'], displayMode: 'list', placement: 'bottom' },
    tooltip: { mode: 'single', sort: 'desc' },
  };
  newPanel.datasource = { type: 'prometheus', uid: '' };
  newPanel.fieldConfig = {
    defaults: {
      custom: {
        drawStyle: 'Line',
        barAlignment: 0,
        fillOpacity: 10,
        lineInterpolation: 'linear',
      },
    },
  };
  return newPanel;
};

const convertBarChartToTimeseries = (
  panel: DashboardPanelProps,
): DashboardPanelProps => {
  return {
    ...panel,
    type: DashboardPanelType.TIMESERIES,
    fieldConfig: {
      ...panel.fieldConfig,
      defaults: {
        ...panel.fieldConfig.defaults,
        custom: {
          ...panel.fieldConfig.defaults.custom,
          drawStyle: 'bars',
          stacking: { mode: 'normal', group: 'A' },
        },
      },
    },
  };
};

const convertGrafanaSingleStatToStat = (
  panel: DashboardPanelProps,
): DashboardPanelProps => {
  return {
    ...panel,
    type: DashboardPanelType.STAT,
    fieldConfig: {
      ...(panel.fieldConfig || {}),
      defaults: {
        ...(panel.fieldConfig?.defaults || {}),
        unit: panel.format as string,
      },
    },
  };
};

const convertLegacyTableToTable = (
  panel: DashboardPanelProps & {
    styles: {
      alias: string;
      decimals: number;
      pattern: string;
      unit: string;
      type: string;
    }[];
  },
): DashboardPanelProps => {
  const renameByName: { [key: string]: string } = {};
  const excludeByName: { [key: string]: boolean } = {};
  const indexByName: { [key: string]: number } = {};
  const overrides: DashboardPanelOverrideProps[] = [];
  panel.styles?.forEach((style, idx) => {
    if (style.type === 'hidden') {
      excludeByName[style.pattern] = true;
    } else {
      if (!style.alias) {
        return;
      }
      renameByName[style.pattern] = style.alias;
      indexByName[style.pattern] = idx;
    }
    if (style.unit) {
      overrides.push({
        matcher: { id: 'byName', options: style.alias },
        properties: [
          { id: 'unit', value: style.unit, decimals: style.decimals },
        ],
      });
    }
  });

  const organizeTransformation = {
    id: 'organize',
    options: { renameByName, excludeByName },
  };

  return {
    ...panel,
    type: DashboardPanelType.TABLE,
    transformations: [organizeTransformation],
    fieldConfig: {
      ...(panel.fieldConfig || {}),
      overrides,
    },
  };
};

const gridDepth = (panels: DashboardPanelProps[]): number => {
  let max = 0;
  let bottomY = 0;
  for (let i = 0, len = panels.length; i < len; i++) {
    const layout = panels[i].gridPos;
    bottomY = layout.y + layout.h;
    if (bottomY > max) max = bottomY;
  }
  return max;
};

const groupRowPanels = (
  panels: DashboardPanelProps[],
): DashboardPanelProps[] => {
  const newPanels: DashboardPanelProps[] = [];
  for (let i = 0; i < panels.length; i++) {
    const panel = panels[i];
    if (panel.type === DashboardPanelType.ROW) {
      newPanels.push({ ...panel, panels: [] });
    } else {
      const lastPanel = newPanels[newPanels.length - 1];
      if (lastPanel && lastPanel.type === DashboardPanelType.ROW) {
        lastPanel.panels.push(panel);
      } else {
        newPanels.push(panel);
      }
    }
  }
  return newPanels;
};

const gridDepthWithRow = (panels: DashboardPanelProps[]): number => {
  const depthMap = new Map<number, number>();
  const deepCopiedPanels = cloneDeep(panels);
  // Iterate through the rectangles and sum the heights with the same x value
  const grouppedPanels = groupRowPanels(deepCopiedPanels);
  for (const panel of grouppedPanels) {
    if (panel.type === DashboardPanelType.ROW) {
      const currentDepth = depthMap.get(panel.gridPos.x) || 0;
      if (panel.collapsed) {
        depthMap.set(panel.gridPos.x, currentDepth + 1);
      } else {
        const rowPanelDepth = gridDepth(panel.panels);
        depthMap.set(panel.gridPos.x, rowPanelDepth);
      }
    } else {
      const currentDepth = depthMap.get(panel.gridPos.x) || 0;
      depthMap.set(panel.gridPos.x, currentDepth + panel.gridPos.h);
    }
  }
  // Find the maximum depth among all x values
  let maxDepth = 0;
  for (const depth of depthMap.values()) {
    maxDepth = Math.max(maxDepth, depth);
  }
  return maxDepth;
};

const reAssignGridPos = (
  panels: DashboardPanelProps[],
  currentVerticalHeight: number,
) => {
  const yAxisBitmap: { [key: number]: DashboardPanelProps[] } = {};
  panels.forEach((panel) => {
    const currentDepth = yAxisBitmap[panel.gridPos.y] || [];
    currentDepth.push(panel);
    yAxisBitmap[panel.gridPos.y] = currentDepth;
  });

  const sortedYAxisBitmap = Object.keys(yAxisBitmap)
    .map((d) => Number(d))
    .sort((a, b) => a - b);
  const accumaletedHeight: { [key: number]: number } = {};
  sortedYAxisBitmap.forEach((yAxis, idx: number) => {
    if (idx === 0) {
      accumaletedHeight[yAxis] = currentVerticalHeight;
      return;
    }
    const prevAccumulatedHeight = accumaletedHeight[sortedYAxisBitmap[idx - 1]];
    const prevPanels = yAxisBitmap[sortedYAxisBitmap[idx - 1]];
    const prevDepth = gridDepthWithRow(prevPanels);
    accumaletedHeight[yAxis] = prevAccumulatedHeight + prevDepth;
  });

  panels.forEach((panel) => {
    const currentDepth = accumaletedHeight[panel.gridPos.y];
    panel.gridPos.y = currentDepth;
  });

  return panels;
};

const reAssignGridPosWithRow = (
  panels: DashboardPanelProps[],
  offsetVerticalHeight: number,
) => {
  const reAssignedPanel: DashboardPanelProps[] = [];
  panels.forEach((panel, idx) => {
    if (panel.type === DashboardPanelType.ROW) {
      if (!panel.panels && panel.panels?.length === 0) {
        reAssignedPanel.push(panel);
      }
      let groupRowsPanels = panel.panels;

      const currentVerticalHeight =
        gridDepthWithRow(reAssignedPanel) + offsetVerticalHeight;

      groupRowsPanels = reAssignGridPos(
        groupRowsPanels,
        currentVerticalHeight + 1,
      );
      panel.gridPos.y = currentVerticalHeight;
      panel.panels = groupRowsPanels;
      reAssignedPanel.push(panel);
      reAssignedPanel.push(...groupRowsPanels);
    } else {
      reAssignedPanel.push(panel);
    }
  });
  return reAssignedPanel;
};

/**
 * Transform panel
 */
export const transformPanels = (
  panels: DashboardPanelProps[],
): DashboardPanelProps[] => {
  const newPanels: DashboardPanelProps[] = [];
  panels?.forEach((panel, idx) => {
    if (!panel.gridPos) {
      panels[idx].gridPos = { h: 4, w: 3, x: 0, y: 0, i: `${idx}` };
    }

    if (panel.type === DashboardPanelType.LEGACY_GRAPH) {
      newPanels.push(convertLegacyGraphToTimeseries(panel));
    } else if (panel.type === DashboardPanelType.GRAFANA_SINGLESTAT_PANEL) {
      newPanels.push(convertGrafanaSingleStatToStat(panel));
    } else if (
      panel.type === DashboardPanelType.TABLE &&
      panel.styles?.length
    ) {
      newPanels.push(convertLegacyTableToTable(panel));
    } else if (panel.type === DashboardPanelType.BARCHART) {
      newPanels.push(convertBarChartToTimeseries(panel));
    } else if (panel.type !== DashboardPanelType.ROW) {
      newPanels.push(panel);
    }

    if (panel.type === DashboardPanelType.ROW) {
      let currentVerticalHeight = gridDepthWithRow(newPanels);
      panel.gridPos.y = currentVerticalHeight;
      if (!panel.panels && panel?.panels?.length === 0) {
        newPanels.push(panel);
        return;
      }
      let groupRowsPanels = transformPanels(panel.panels);
      if (currentVerticalHeight !== 0) {
        currentVerticalHeight = currentVerticalHeight + 1;
      }

      groupRowsPanels = reAssignGridPos(
        groupRowsPanels,
        currentVerticalHeight + 1,
      );
      panel.panels = groupRowsPanels;
      newPanels.push(panel);
      newPanels.push(...groupRowsPanels);
    }
  });

  return newPanels;
};

export const getPanelLayoutOnRowChange = ({
  panels,
  panelIndex,
  collapsed,
}: {
  panels: DashboardPanelProps[];
  panelIndex: number;
  collapsed: boolean;
}): DashboardPanelProps[] => {
  panels[panelIndex].collapsed = collapsed;
  const panelsBefore = panels.slice(0, panelIndex);
  const panelsAfter = panels.slice(panelIndex);
  const panelsAfterGrouped = groupRowPanels(panelsAfter);

  if (!panelsBefore.length && !panelsAfter.length) {
    return panels;
  }
  const panelBeforeDepth = gridDepthWithRow(panelsBefore);
  const adjustedAfterPanels = reAssignGridPosWithRow(
    panelsAfterGrouped,
    panelBeforeDepth,
  );

  return [...panelsBefore, ...adjustedAfterPanels];
};

export const getPanelWidthHeight = (
  gridPos: DashboardPanelProps['gridPos'],
  baseWidth: number,
  title?: string,
): { width: number; height: number; heightContainer: number } => {
  const gridH = gridPos.h || 1;
  const gridW = gridPos.w || 1;
  const OFFSET = 16;
  const marginPaddingOffset =
    baseWidth -
    OFFSET -
    GRID_CELL_VMARGIN * (GRID_COLUMN_COUNT - 1) -
    GRID_CELL_VMARGIN * 2;

  const gridWidth = marginPaddingOffset / GRID_COLUMN_COUNT;
  let gridHeight = GRID_CELL_HEIGHT * gridH + GRID_CELL_VMARGIN * (gridH - 1);

  if (!title) {
    gridHeight = gridHeight + GRID_HEADER_HEIGHT - 8;
  }

  return {
    width: gridWidth * gridW + GRID_CELL_VMARGIN * (gridW - 1),
    height: gridHeight,
    heightContainer: gridHeight - GRID_HEADER_HEIGHT,
  };
};

/**
 * Get panel styles
 * @param panelConfig
 */
export const getPanelStyles = (
  panelConfig: DashboardFieldConfigProps,
): ChartRenderProps['styles']['chartStyles'] => {
  if (!panelConfig || !panelConfig.custom) {
    return {};
  }

  const {
    pointSize,
    fillOpacity,
    gradientMode,
    lineWidth,
    lineInterpolation,
    showPoints,
    scaleDistribution,
  } = panelConfig.custom;

  const styles: ChartRenderProps['styles']['chartStyles'] = {
    pointSize: pointSize || 5,
    fillOpacity: fillOpacity || 0,
    gradientMode: gradientMode || 'none',
    lineWidth: lineWidth ? (lineWidth === 1 ? 1.8 : lineWidth) : 2,
    lineInterpolation: lineInterpolation || 'linear',
    showPoints: showPoints || 'auto',
    lineStyle: 'solid',
    scaleDistribution,
  };

  return styles;
};

export const getSaveDashboardPanels = (panels: DashboardPanelProps[]) => {
  let savePanels: DashboardPanelProps[] = [];

  const isRepeatedRowExist = panels.some(
    (panel) => panel.type === DashboardPanelType.ROW && panel.repeat,
  );
  if (isRepeatedRowExist) {
    panels = panels.filter(
      (panel) => panel.repeatedRowId === undefined || panel.repeatedRowId === 0,
    );
  }

  panels.forEach((panel, idx) => {
    if (panel.type === DashboardPanelType.PLACEHOLDER) {
      return;
    }

    if (panel.isEdited) {
      delete panel.isEdited;
    }

    const newGridPos = {
      x: panel.gridPos.x,
      y: panel.gridPos.y,
      w: panel.gridPos.w,
      h: panel.gridPos.h,
    };
    panel.gridPos = newGridPos;
    panel.id = idx + 1;

    savePanels.push(panel);

    if (panel.type !== DashboardPanelType.ROW) {
      delete panel.repeat;
      delete panel.repeatValue;
      delete panel.repeatedRowId;
    }
    if (panel.type === DashboardPanelType.ROW) {
      delete panel.repeatedRowId;
      delete panel.repeatValue;
    }
  });

  const isRowExist = savePanels.some(
    (panel) => panel.type === DashboardPanelType.ROW,
  );
  if (isRowExist) {
    const grouppedPanels = groupRowPanels(savePanels);
    savePanels = [];
    grouppedPanels.forEach((panel) => {
      if (panel.type === DashboardPanelType.ROW) {
        const rowsPanels = [...(panel.panels || [])];
        panel.panels = [];
        savePanels.push(panel);
        savePanels.push(...rowsPanels);
      } else {
        savePanels.push(panel);
      }
    });
  }
  return savePanels;
};

/**
 * Find changed dashboard panels
 * @param prevPanels
 * @param newPanels
 */
export const findChangedDashboardPanels = (
  prevPanels: Layout[],
  newPanels: Layout[],
): Layout[] => {
  if (!prevPanels || !newPanels) return [];
  if (prevPanels.length === 0) return [];
  const changedPanels: Layout[] = [];
  prevPanels.forEach((prevPanel, idx) => {
    const newPanel = newPanels[idx];
    if (!newPanel) return;
    if (
      prevPanel.x !== newPanel.x ||
      prevPanel.y !== newPanel.y ||
      prevPanel.w !== newPanel.w ||
      prevPanel.h !== newPanel.h
    ) {
      changedPanels.push(newPanel);
    }
  });

  return changedPanels;
};

export const getPanelStreamType = (
  panel: DashboardPanelProps,
): DashboardStreamType => {
  if (!panel.targets || panel.targets.length === 0)
    return DashboardStreamType.METRIC;

  let streamType = DashboardStreamType.METRIC;
  panel.targets.forEach((target) => {
    if (target.datasource?.type === 'loki') {
      streamType = DashboardStreamType.LOG;
    }

    if (target.datasource?.type === 'prometheus') {
      streamType = DashboardStreamType.METRIC;
    }

    if (target.datasource?.type === 'tempo') {
      streamType = DashboardStreamType.TRACE;
    }

    if (target.datasource?.type === 'rum') {
      streamType = DashboardStreamType.RUM;
    }

    if (target.datasource?.type === 'event') {
      streamType = DashboardStreamType.EVENT;
    }
  });

  return streamType;
};

export const getRepeatedRowsWithPanels = ({
  panels,
  template,
  values,
}: {
  panels: DashboardPanelProps[];
  template: DashboardTemplateProps;
  values: string[];
}): DashboardPanelProps[] => {
  const deepClonedPanels = cloneDeep(panels);
  const grouppedPanels = groupRowPanels(deepClonedPanels);
  const repeatedRows = grouppedPanels.filter(
    (panel) =>
      panel.type === DashboardPanelType.ROW && panel.repeat === template.name,
  );
  const uniqueRepeatedRows = repeatedRows.filter(
    (panel, index, self) =>
      index ===
      self.findIndex(
        (t) => t.title === panel.title && t.repeat === panel.repeat,
      ),
  );

  const repeatedPanels: DashboardPanelProps[] = [];
  let currYAxis = 0;
  uniqueRepeatedRows.forEach((row) => {
    const noneRepeatedPanels = getAllPanelsByYAxis({
      panels: grouppedPanels,
      direction: 'up',
      yAxisEnd: row.gridPos.y,
      yAxisStart: currYAxis,
    });
    currYAxis = row.gridPos.y + row.gridPos.h;
    if (noneRepeatedPanels.length > 0) {
      repeatedPanels.push(...noneRepeatedPanels);
    }
  });
  uniqueRepeatedRows.forEach((row) => {
    values.forEach((value, idx: number) => {
      const clonedRow = cloneDeep(row);
      clonedRow.repeatValue = value;
      clonedRow.repeatedRowId = idx;
      clonedRow.panels.forEach((panel) => {
        panel.repeat = template.name;
        panel.repeatValue = value;
        panel.repeatedRowId = idx;
      });
      repeatedPanels.push(clonedRow);
    });
  });

  const transformedPanels = transformPanels(repeatedPanels);

  return transformedPanels;
};

const getAllPanelsByYAxis = ({
  panels,
  direction,
  yAxisEnd,
  yAxisStart,
}: {
  panels: DashboardPanelProps[];
  direction: 'up' | 'down';
  yAxisEnd: number;
  yAxisStart: number;
}) => {
  const panelsByYAxis = panels.filter((panel) => {
    // out of the start and end range of y axis value should be ignored
    if (panel.gridPos.y > yAxisEnd) {
      return false;
    }

    if (panel.gridPos.y < yAxisStart) {
      return false;
    }

    if (direction === 'up') {
      return panel.gridPos.y < yAxisEnd;
    }

    return panel.gridPos.y > yAxisStart;
  });

  return panelsByYAxis;
};
