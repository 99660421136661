import useAsync from '../hooks/useAsync';
import { kubeEntities, kubeNamespaceCount } from 'requests';
import { useKubernetesController } from '../KubernetesController';
import { getAggregationsByEntities } from '../utils/selectorsfunction';
import { useMemo } from 'react';

import _ from 'lodash';
import { SelectedFacetValuesByName } from 'types';
import { EntityTypes } from '../types';

export function useEntitiesGroupData({
  entitiesType,
  date,
  facets,
  groupBySearchTerms,
}) {
  return useAsync(
    async (signal: AbortSignal) => {
      const [cpu, memory, entities] = await Promise.all([
        null, // podCPUUsage(groupBySearchTerms, date)({ signal }),
        null, // podMemoryUsage(groupBySearchTerms, date)({ signal }),
        kubeNamespaceCount(
          {
            entityType: entitiesType,
            selectedFacetValuesByName: facets,
            groupBy: groupBySearchTerms,
            aggregations: getAggregationsByEntities(
              entitiesType,
              groupBySearchTerms,
            ),
          },
          { signal },
        ),
      ]);

      return entities.map((podData) => {
        // const key = getKey(podData.groupBys);
        // const cpuPercentage = cpu[key] || 0;
        // const memoryPercentage = memory[key] || 0;

        return {
          ...podData,
          // cpuPercentage,
          // memoryPercentage,
        };
      });
    },
    [date, facets, groupBySearchTerms],
  );
}

export function useEntitiesCount({
  entitiesType,
  facets,
}: {
  entitiesType: EntityTypes;
  facets: SelectedFacetValuesByName;
}) {
  return useAsync(
    async (signal: AbortSignal) => {
      const [{ count }] = await kubeNamespaceCount(
        {
          entityType: entitiesType,
          selectedFacetValuesByName: facets,
          groupBy: [],
          aggregations: getAggregationsByEntities(entitiesType, []),
        },
        { signal },
      );

      return count;
    },
    [entitiesType, facets],
  );
}

export function useMergedGroupByFilter(groupBys: Array<any>) {
  const { facets } = useKubernetesController();

  return useMemo(() => {
    return [
      facets,
      Object.fromEntries(
        groupBys.map(({ key, value }) => {
          return [
            key,
            {
              [value]: 1,
            },
          ];
        }),
      ),
    ];
  }, [facets, groupBys]);
}

export function useEntitiesData<T>({
  filters,
  page,
  limit,
}: {
  filters: Array<SelectedFacetValuesByName>;
  page: number;
  limit: number;
}) {
  const { entitiesLastLoaded, date, entitiesType, kubesSort } =
    useKubernetesController();

  return useAsync(
    async (signal: AbortSignal): Promise<Array<T>> => {
      return await kubeEntities(
        {
          entityType: entitiesType,
          sortOrder: kubesSort?.order,
          sortBy: {
            key: kubesSort?.key,
            type: kubesSort?.type,
          },
          offset: page * limit,
          pageLimit: limit,
          filters,
        },
        { signal },
      );
    },
    [date, entitiesType, page, limit, filters, kubesSort, entitiesLastLoaded],
  );
}
