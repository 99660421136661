import { Loader } from 'components';
import { useToggle } from 'hooks';
import React from 'react';
import RumReplayFetched from './RumReplayFetched';
import { RumEvent, SessionMetadata } from './types';
import { RumReplaySegmentFetcherContextProvider } from './RumReplaySegmentFetcherContext';

type Args = {
  events: RumEvent[];
  session: SessionMetadata;
  startReplayAtUnixMs?: number;
};

const RumReplay = ({ events, session, startReplayAtUnixMs }: Args) => {
  const isInitedToggle = useToggle();
  return (
    <Loader className="rum-replay__container" isLoading={!isInitedToggle.value}>
      <RumReplaySegmentFetcherContextProvider
        isInitedToggle={isInitedToggle}
        session={session}
        startReplayAtUnixMs={startReplayAtUnixMs}
      >
        {isInitedToggle.value ? (
          <RumReplayFetched
            events={events}
            session={session}
            startReplayAtUnixMs={startReplayAtUnixMs}
          />
        ) : null}
      </RumReplaySegmentFetcherContextProvider>
    </Loader>
  );
};

export default RumReplay;
