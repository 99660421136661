export const FORECAST_TITLE =
  'A forecast alert predicts the future behavior of a metric and compares it to a static threshold.';
export const FORECAST_DESCRIPTION =
  'An alert is triggered whenever a metric is forecast to cross a threshold in the future.';
export const FORECAST_MESSAGE =
  'An alert is triggered whenever a metric is forecast to cross a threshold in the future. Please see the details of each algorithm when selecting the algo name below in "Set Conditions.';
export const LINEAR_PREDICTION_ALGORITHM_DESCRIPTION =
  'Use linear regression algorithm for prediction for metrics that have steady trends but no repeating seasonal pattern. It then extrapolates the linear trend to predict future points for the specified duration.';
export const SEASONAL_PREDICTION_ALGORITHM_DESCRIPTION =
  'Use the seasonal algorithm for metrics with repeating patterns. It produces a smoothed value for time series based on the history using the provided smoothening and trend factors.';
export const LINEAR_HISTORY_DURATION =
  'Typical time range which covers the steady trend of data. This data is used for performing linear regression.';
export const LINEAR_FORECAST_DURATION =
  'Predict the value of time series "forecast duration" from now.';
export const SEASONAL_HISTORY_DURATION =
  'Typical time range which covers the seasonality trend of data. This data is used for performing holt_winters regression.';
export const SEASONAL_SCALE_FACTOR =
  'Smoothening factor to use for prediction. The lower the smoothing factor, the more importance is given to old data. Value needs to within 0 to 1.';
export const SEASONAL_TREND_FACTOR =
  'Trend factor to use for prediction. The higher the trend factor, the more trends in the data is considered. Value needs to be within 0 to 1.';
