import { onPromiseError } from 'utils';
import queryTraceService from './queryTraceService';

const getServiceGroupLabels = async (): Promise<string[]> => {
  return queryTraceService<string[], 'serviceGroupLabels'>(`{
    serviceGroupLabels
  }`).then((data) => data?.serviceGroupLabels || [], onPromiseError);
};

export default getServiceGroupLabels;
