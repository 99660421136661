import React, { useEffect, useMemo, useState } from 'react';
import { Loader, RightSidebar, Tab, Tabs, useTabs } from 'components';
import { useRumPageStateContext } from 'context';
import { useRequest } from 'hooks';
import useDebouncedEffect from 'use-debounced-effect';
import {
  RumEvent,
  RumActionEventData,
  RumErrorEventData,
  RumEventType,
  RumLongTaskEventData,
  RumResourceEventData,
  RumSessionEventData,
  RumViewEventData,
} from './types';
import RumActiveLogs from './RumActiveLogs';
import RumActiveTraces from './RumActiveTraces';
import RumActionsTable from './RumActionsTable';
import RumResourcesTable from './RumResourcesTable';
import RumFlameGraph from './RumFlameGraph';
import RumResourceBreakdown from './RumResourceBreakdown';
import RumActiveReplay from './RumActiveReplay';
import rawEvent from './requests/rawEvent';
import RumEventJson from './RumEventJson';
import RumEventDrawerErrors from './RumEventDrawerErrors';
import { getEventDuration } from './RumFlameGraph/formatAndGroupEvents';

const TABS = {
  ACTIONS: 'Actions',
  ERRORS: 'Errors',
  INFO: 'Info',
  LOGS: 'Logs',
  PERFORMANCE: 'Performance',
  RESOURCES: 'Resources',
  RESOURCE_BREAKDOWN: 'Resource Breakdown',
  REPLAY: 'Replay',
};

const getTabsForEventType = (eventType: RumEventType) => {
  switch (eventType) {
    case RumEventType.ACTION:
      return [
        TABS.PERFORMANCE,
        TABS.INFO,
        TABS.RESOURCES,
        TABS.ERRORS,
        TABS.LOGS,
        TABS.REPLAY,
      ];
    case RumEventType.ERROR:
      return [TABS.INFO, TABS.LOGS, TABS.REPLAY];

    case RumEventType.RESOURCE:
      return [TABS.RESOURCE_BREAKDOWN, TABS.INFO, TABS.REPLAY];

    case RumEventType.SESSION:
      return [TABS.INFO, TABS.ERRORS, TABS.REPLAY];
    case RumEventType.VIEW:
      return [
        TABS.PERFORMANCE,
        TABS.INFO,
        TABS.ACTIONS,
        TABS.RESOURCES,
        TABS.ERRORS,
        TABS.LOGS,
        TABS.REPLAY,
      ];
    default:
      return [TABS.INFO, TABS.REPLAY];
  }
};

export const getEventIdParam = (
  eventType: RumEventType,
  data: RumEvent['data'],
) => {
  switch (eventType) {
    case RumEventType.VIEW:
      return (data as RumViewEventData)['view.id'];
    case RumEventType.ACTION:
      return (data as RumActionEventData)['action.id'];
    case RumEventType.LONGTASK:
      return (data as RumLongTaskEventData)['long_task.id'];
    case RumEventType.RESOURCE:
      return (data as RumResourceEventData)['resource.id'];
    case RumEventType.SESSION:
      return (data as RumSessionEventData)['session.id'];
    case RumEventType.ERROR:
      return (data as RumErrorEventData)['error.id'];
    default:
      return null;
  }
};

type Props = {
  close: VoidFunction;
  activeRumEvent: RumEvent;
  openReplay?: boolean;
  eventType: RumEventType;
};

const RumEventDrawer = ({
  activeRumEvent,
  close,
  openReplay,
  eventType,
}: Props) => {
  const rumRawEventRequest = useRequest(rawEvent);

  const [nestedEventDrawer, setNestedDrawerEvent] = useState<RumEvent | null>(
    null,
  );

  const tabsForEventType = useMemo(() => {
    return getTabsForEventType(eventType);
  }, [eventType]);

  const sessionTabIndex = tabsForEventType.indexOf('Replay');

  const tabs = useTabs(openReplay ? sessionTabIndex : 0);
  const rumPageState = useRumPageStateContext();
  const { rumState } = rumPageState;
  const { applicationFilter, dateState } = rumState;
  const [date] = dateState;

  useDebouncedEffect(
    () => {
      if (!activeRumEvent) {
        return;
      }
      rumRawEventRequest.call({
        eventTimeUnix: activeRumEvent.time as number,
        eventId: getEventIdParam(eventType, activeRumEvent.data),
        eventType,
      });
    },
    {
      timeout: 50,
      ignoreInitialCall: false,
    },
    [activeRumEvent, eventType],
  );

  const eventDrawerData = useMemo(() => {
    if (!rumRawEventRequest.result) {
      return null;
    }

    const ddAttributesRemoved = {
      ...rumRawEventRequest.result,
    };
    delete ddAttributesRemoved._dd;

    return ddAttributesRemoved;
  }, [rumRawEventRequest.result]);

  const handleNestedEventDrawerClose = () => {
    setNestedDrawerEvent(null);
  };

  return (
    <RightSidebar
      className="rum-sidebar flex flex-col"
      close={close}
      title={'Event Attributes'}
      dataTestId="rum-sidebar"
    >
      {rumRawEventRequest.isLoading || !rumRawEventRequest.calledAtLeastOnce ? (
        <Loader className="h-full" isLoading />
      ) : (
        <div className="trace-sidebar__active-span__body">
          <Tabs className="tabs--underline" tabs={tabs}>
            {(eventType === RumEventType.VIEW ||
              eventType === RumEventType.ACTION) && (
              <Tab label="Performance">
                <RumFlameGraph
                  applicationFilter={applicationFilter}
                  activeRumEvent={activeRumEvent}
                  eventType={eventType}
                  setNestedDrawerEvent={setNestedDrawerEvent}
                  startTimeMs={activeRumEvent.time}
                />
              </Tab>
            )}
            {eventType === RumEventType.RESOURCE && (
              <Tab label="Resource Breakdown">
                <RumResourceBreakdown
                  applicationFilter={applicationFilter}
                  durationNs={getEventDuration(activeRumEvent)}
                  eventId={getEventIdParam(eventType, activeRumEvent.data)}
                  eventType={eventType}
                  startTimeMs={activeRumEvent.time}
                />
              </Tab>
            )}
            <Tab label="Info">
              {eventDrawerData ? (
                <RumEventJson
                  eventType={eventType}
                  json={eventDrawerData}
                  rumState={rumState}
                />
              ) : (
                <></>
              )}
            </Tab>
            {eventType === RumEventType.VIEW && (
              <Tab label="Actions">
                <RumActionsTable
                  activeRumEvent={activeRumEvent}
                  rumState={rumState}
                  setActiveRumEvent={setNestedDrawerEvent}
                />
              </Tab>
            )}
            {(eventType === RumEventType.VIEW ||
              eventType === RumEventType.ACTION) && (
              <Tab label="Resources">
                <RumResourcesTable
                  activeRumEvent={activeRumEvent}
                  rumState={rumState}
                  setActiveRumEvent={setNestedDrawerEvent}
                />
              </Tab>
            )}

            {(eventType === RumEventType.VIEW ||
              eventType === RumEventType.ACTION ||
              eventType === RumEventType.SESSION) && (
              <Tab label="Errors">
                <RumEventDrawerErrors
                  activeRumEvent={activeRumEvent}
                  rumState={rumState}
                  setActiveRumEvent={setNestedDrawerEvent}
                />
              </Tab>
            )}
            {(eventType === RumEventType.VIEW ||
              eventType === RumEventType.ERROR) && (
              <Tab label="Logs">
                <RumActiveLogs
                  applicationId={rumRawEventRequest?.result?.application.id}
                  sessionId={rumRawEventRequest?.result?.session.id}
                  viewId={rumRawEventRequest?.result?.view.id}
                  activeRumEvent={activeRumEvent}
                />
              </Tab>
            )}
            {/* {(eventType === RumEventType.VIEW ||
            eventType === RumEventType.ACTION) && (
            <Tab label="Traces">
              <RumActiveTraces
                date={date}
                selectedFacetValuesByNameState={selectedFacetValuesByNameState}
              />
            </Tab>
          )} */}
            <Tab label="Replay">
              {rumRawEventRequest.result &&
              rumRawEventRequest.result.context?.rrweb_has_replay ? (
                <RumActiveReplay
                  applicationId={rumRawEventRequest.result.application.id}
                  sessionId={rumRawEventRequest.result.session.id}
                  startReplayAtUnixMs={activeRumEvent.time}
                />
              ) : (
                <></>
              )}
            </Tab>
          </Tabs>
        </div>
      )}
      {nestedEventDrawer ? (
        <RumEventDrawer
          activeRumEvent={nestedEventDrawer}
          close={handleNestedEventDrawerClose}
          eventType={nestedEventDrawer.eventType}
        />
      ) : null}
    </RightSidebar>
  );
};

export default RumEventDrawer;
