import { useThemeContext } from 'components';
import { useLayoutEffect, useState } from 'react';
import { useMountedState } from 'react-use';
import { LayoutType, TooltipCoordsProps } from 'types';

import { KfuseHostmapProps, KfuseHostmapOptions, Hexagon } from '../types';
import { handleHostmapTooltip } from '../utils';

const useHostmapTooltip = ({
  config,
  layoutType,
}: {
  config: KfuseHostmapOptions;
  layoutType: LayoutType;
}): {
  coords: TooltipCoordsProps | null;
} => {
  const { darkModeEnabled } = useThemeContext();

  const [coords, setCoords] = useState<TooltipCoordsProps | null>(null);
  const isMounted = useMountedState();

  useLayoutEffect(() => {
    config.addHook('setCursor', (h: KfuseHostmapProps, hexagon: Hexagon) => {
      if (!isMounted()) {
        return;
      }

      const tooltipData = handleHostmapTooltip({
        darkModeEnabled,
        h: h,
        hexagon,
        layoutType,
      });

      if (tooltipData) {
        setCoords(tooltipData);
      } else {
        setCoords(null);
      }
    });

    return () => {
      setCoords(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return { coords };
};

export default useHostmapTooltip;
