import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { cn } from 'utils/tailwind';
import exhaustiveSwitchError from 'utils/exhaustiveSwitchError';

const TooltipArrow = TooltipPrimitive.Arrow;

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipPortal = TooltipPrimitive.Portal;

const TooltipTrigger = TooltipPrimitive.Trigger;

const DefaultTooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'max-w-80 z-[1002] overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-xs text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className,
    )}
    {...props}
  />
));

const CompactTooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'overflow-hidden shadow-md rounded py-1 px-2 text-white',
      'font-noto text-[12px]',
      className,
    )}
    {...props}
    style={{ lineHeight: '16.8px', background: 'var(--ui-background-shade)' }}
  />
));

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
    variant: 'default' | 'compact';
  }
>(({ className, sideOffset = 4, ...props }, ref) => {
  const { variant } = props;

  switch (variant) {
    case 'default':
      return (
        <DefaultTooltipContent ref={ref} {...props} className={className} />
      );
    case 'compact':
      return (
        <CompactTooltipContent ref={ref} {...props} className={className} />
      );
    default:
      throw exhaustiveSwitchError(variant);
  }
});

TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export {
  TooltipArrow,
  Tooltip,
  TooltipPortal,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
};
