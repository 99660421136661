import { InputWithValidatorV3 } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useState } from 'react';
import { XCircle } from 'react-feather';
import { IoIosWarning } from 'react-icons/io';
import { createGrafanaFolder, getGrafanaFolders } from 'requests';
import { GrafanaFolderProps } from 'types/Grafana';

const CreateNewFolderModal = ({
  closeModal,
  updateFolder,
  editFolderName,
  editFolderUid,
}: {
  closeModal: () => void;
  updateFolder: (folderName: string, folderUid?: string) => void;
  editFolderName?: string;
  editFolderUid?: string;
}): ReactElement => {
  const [folderName, setFolderName] = useState(editFolderName || '');
  const createFolderRequest = useRequest(createGrafanaFolder, true, true);
  const getGrafanaFoldersRequest = useRequest(getGrafanaFolders);

  const handleFolder = async () => {
    if (!folderName) return;

    let folder = undefined;
    if (editFolderUid) {
      folder = await getGrafanaFoldersRequest.call(editFolderUid);
    }

    createFolderRequest
      .call({
        folderName,
        uid: editFolderUid,
        version: folder?.version,
      })
      .then((res: Record<string, unknown>) =>
        updateFolder(folderName, res.uid as string),
      );
  };

  return (
    <div className="alerts__create-new-folder">
      <div className="alerts__create-new-folder__container">
        {createFolderRequest.isLoading || getGrafanaFoldersRequest.isLoading ? (
          <div className="flex h-[160px] w-full items-center justify-center">
            <div className="spinner"></div>
          </div>
        ) : (
          <>
            <div className="alerts__create-new-folder__container__header">
              <div>{editFolderName ? 'Update' : 'Create'} Folder</div>
              <XCircle onClick={closeModal} />
            </div>

            <div className="flex flex-col">
              <div className="pb-0.5 flex items-center justify-between gap-[8px]">
                <div className="whitespace-nowrap">Folder name</div>
                {createFolderRequest?.error && (
                  <div className="flex gap-[4px] pt-0.5 justify-start pr-[14px]">
                    <IoIosWarning
                      className="overlay-message__icon-and-message__icon"
                      size={16}
                    />
                    <div className="text-red-500">Failed to create folder</div>
                  </div>
                )}
              </div>

              <InputWithValidatorV3
                size="4"
                variant="default"
                onChange={(val) => setFolderName(val)}
                placeholder="Enter Folder name"
                type="text"
                value={folderName}
              />
            </div>

            <div className="alerts__create-new-folder__container__action">
              <button className="button button--blue" onClick={handleFolder}>
                {editFolderName ? 'Update Folder' : 'Create new Folder'}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateNewFolderModal;
