import {
  LeftSidebar,
  Loader,
  Paginator,
  Table,
  TableSearch,
  TooltipTrigger,
  useLeftSidebarState,
  useTableSearch,
  useTableSort,
  usePaginator,
  Button,
  ShowSidebarTooltipButton,
  TableBulkActionsCheckbox,
  useTableBulkActions,
  TableBulkActions,
  useModalsContext,
  ConfirmationModal,
  OverlayMessageProps,
  OverlayMessage,
} from 'components';
import { useDashboardListPageStateContext } from 'context/PageStateProvider';
import { useRequest } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { Plus } from 'react-feather';
import { MdDelete as DeleteIcon, MdModeEdit as EditIcon } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { getGrafanaDashboard } from 'requests';
import { ValueCount } from 'types/generated';

import { DashboardListSidebar } from './DashboardListSidebar';
import { filterDashboardList } from './utils';
import useDashboardDelete from './useDashboardDelete';

const columns = ({
  onDeleteDashboard,
  tableBulkActions,
}: {
  onDeleteDashboard: (row: Record<string, string>) => void;
  tableBulkActions: ReturnType<typeof useTableBulkActions>;
}) => [
  {
    key: 'bulk-action-checkbox',
    label: (
      <TableBulkActionsCheckbox
        tableBulkActions={tableBulkActions}
        isSelectAll
      />
    ),
    renderCell: ({ row }: { row: Record<string, string> }) => (
      <TableBulkActionsCheckbox
        tableBulkActions={tableBulkActions}
        uniqueId={row.uid as string}
      />
    ),
  },
  { key: 'title', label: 'Title' },
  { key: 'folderTitle', label: 'Folder' },
  {
    key: 'tag',
    label: 'Tags',
    renderCell: ({ row }) => {
      return row.tags?.map((tag: string) => (
        <div className="chip" key={tag}>
          {tag}
        </div>
      ));
    },
  },
  {
    label: '',
    key: 'actions',
    renderCell: ({ row }: { row: Record<string, string> }) => {
      return (
        <div
          className="table__row__actions"
          style={{ '--table-actions-width': '74px' }}
        >
          <div className="table__row__actions--hidden">
            <div className="table__row__actions__slider">
              <div className="flex h-full items-center justify-end">
                <TooltipTrigger
                  className="table__cell__actions__item--blue"
                  tooltip="Edit"
                >
                  <EditIcon
                    className="alerts__contacts__table__actions__icon--edit"
                    size={18}
                  />
                </TooltipTrigger>
                <TooltipTrigger
                  className="table__cell__actions__item--red"
                  tooltip="Delete"
                >
                  <DeleteIcon
                    className="alerts__contacts__table__actions__icon--delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteDashboard(row);
                    }}
                    size={18}
                  />
                </TooltipTrigger>
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
];

const ADD_DASHBOARD_DISABLED = false;
const emptyArray = [];

const DashboardList = () => {
  const {
    dependenciesForWriteStateToUrl,
    selectedFacetValuesByNameState,
    writeStateToUrl,
  } = useDashboardListPageStateContext();
  const leftSidebarState = useLeftSidebarState('dashboard-list');
  const navigate = useNavigate();
  const modal = useModalsContext();
  const {
    dashboardDeleteByUidRequest,
    onDeleteDashboard,
    onDeleteDashboardBulk,
  } = useDashboardDelete();

  const grafanaDashboardRequest = useRequest(getGrafanaDashboard, true, true);

  const [dashboardFilterProperties, dashboardSloFilterProperties] = useState<{
    [key: string]: ValueCount[];
  }>({ foldertitle: [], tags: [] });

  const getDashboardStateFacetValues = (facetName: string) => () => {
    return new Promise((resolve) => {
      resolve(dashboardFilterProperties[facetName]);
    });
  };

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesForWriteStateToUrl);

  useEffect(() => {
    grafanaDashboardRequest.call(`type=dash-db&limit=5000`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredDashboardList = useMemo(() => {
    if (!grafanaDashboardRequest.result) return [];
    const {
      dashboardList: newDashboardList,
      folders,
      tags,
    } = filterDashboardList(
      grafanaDashboardRequest.result,
      selectedFacetValuesByNameState.state,
    );

    dashboardSloFilterProperties({ foldertitle: folders, tags });
    return newDashboardList;
  }, [selectedFacetValuesByNameState.state, grafanaDashboardRequest.result]);

  const onDeleteDashboardClick = (row: Record<string, string>) => {
    modal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description="Are you sure you want to delete this dashboard?"
        onCancel={() => modal.pop()}
        onConfirm={() => {
          onDeleteDashboard(row.uid, row.title).then(() => {
            grafanaDashboardRequest.call(`type=dash-db&limit=5000`);
          });
          modal.pop();
        }}
        title="Delete Dashboard"
        dataTestId="delete-dashboard"
      />,
    );
  };

  const onDeleteDashboardBulkClick = (uids: string[]) => {
    modal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description={`Are you sure you want to delete ${uids.length} dashboards? Once deleted, they cannot be recovered.`}
        onCancel={() => modal.pop()}
        onConfirm={() => {
          const dashboards = filteredDashboardList.filter((dashboard) =>
            uids.includes(dashboard.uid),
          );
          onDeleteDashboardBulk(dashboards).then(() => {
            tableBulkActions.clearSelectedRows();
            grafanaDashboardRequest.call(`type=dash-db&limit=5000`);
          });
          modal.pop();
        }}
        title="Delete Dashboards"
        dataTestId="delete-dashboards"
      />,
    );
  };

  const tableSearch = useTableSearch({
    rows: filteredDashboardList || emptyArray,
  });
  const tableBulkActions = useTableBulkActions({
    rows: tableSearch.searchedRows,
    uniqueIdKey: 'uid',
  });
  const tableSort = useTableSort({
    columns: columns({
      onDeleteDashboard: onDeleteDashboardClick,
      tableBulkActions,
    }),
    rows: tableSearch.searchedRows,
  });
  const paginator = usePaginator({ rows: tableSort.sortedRows });

  const overlayMessageProps: OverlayMessageProps =
    grafanaDashboardRequest?.error
      ? {
          isActive: true,
          iconName: 'warning',
          message: <>Failed to fetch dashboard list</>,
        }
      : { isActive: false };

  return (
    <div className="dashboard__list">
      <LeftSidebar
        className="slos__left-sidebar"
        leftSidebarState={leftSidebarState}
      >
        <DashboardListSidebar
          dashboardFilterProperties={dashboardFilterProperties}
          request={getDashboardStateFacetValues}
          selectedFacetValuesByNameState={selectedFacetValuesByNameState}
        />
      </LeftSidebar>

      <div className="dashboard__list__main">
        <Loader
          isLoading={
            grafanaDashboardRequest.isLoading ||
            dashboardDeleteByUidRequest.isLoading
          }
        >
          <div className="dashboard__list__header">
            <div className="dashboard__list__header__left">
              {leftSidebarState.width === 0 ? (
                <ShowSidebarTooltipButton onClick={leftSidebarState.show} />
              ) : null}
              <div className="dashboard__list__title">Dashboard List</div>
            </div>
            <div className="mt-2">
              {!ADD_DASHBOARD_DISABLED && (
                <Button
                  onClick={() => navigate('/dashboards/new')}
                  size="sm"
                  variant="default"
                >
                  <Plus size={16} />
                  Add Dashboard
                </Button>
              )}
            </div>
          </div>

          <TableSearch
            className="dashboard__list__search"
            placeholder="Search dashboards by title"
            tableSearch={tableSearch}
            dataTestId="dashboard-list-search"
          />
          <TableBulkActions
            tableBulkActions={tableBulkActions}
            onBulkDelete={onDeleteDashboardBulkClick}
            selectedLabel={`${tableBulkActions.selectedRowsCount > 1 ? 'dashboards' : 'dashboard'} selected`}
          />

          <OverlayMessage {...overlayMessageProps}>
            <Table
              className="table--bordered table--bordered-cells table__actions--hidden alerts__list__table"
              columns={columns({
                onDeleteDashboard: onDeleteDashboardClick,
                tableBulkActions,
              })}
              onRowClick={({ row }) => navigate(`/dashboards/${row.uid}`)}
              externalTableSort={tableSort}
              isSortingEnabled
              rows={paginator.paginatedRows}
              dataTestId="dashboard-list"
            />
          </OverlayMessage>

          <div className="table-footer">
            <Paginator paginator={paginator} />
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default DashboardList;
