import { CursorStateProvider, useThemeContext } from 'components';
import { useMetricsQueryStateV2 } from 'hooks';
import React, { ReactElement, useMemo } from 'react';
import { DateSelection } from 'types';
import { combineRangeQueryData } from 'utils';

import {
  AlertsChart,
  AlertsChartAnomaly,
  AlertsChartForecast,
} from '../AlertsChart';
import { useAlertsCreateCondition } from '../AlertsCreateCondition';
import { AlertType } from '../types';

const AlertsCreateMetricsChart = ({
  alertType,
  baseWidth,
  conditionState,
  date,
  forWindow,
  isEditing = false,
  metricsQueryState,
}: {
  alertType: AlertType;
  baseWidth: number;
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
  date: DateSelection;
  forWindow?: string;
  isEditing?: boolean;
  metricsQueryState: ReturnType<typeof useMetricsQueryStateV2>;
}): ReactElement => {
  const { darkModeEnabled } = useThemeContext();
  const { formulas, queries, queryData } = metricsQueryState;
  const { condition, anomalyCondition, forecastCondition } = conditionState;

  if (alertType === AlertType.ANOMALY) {
    return (
      <AlertsChartAnomaly
        anomalyCondition={anomalyCondition}
        baseWidth={baseWidth}
        condition={condition}
        date={date}
        forWindow={forWindow}
        isEditing={isEditing}
        formulas={formulas}
        queries={queries}
      />
    );
  }

  if (alertType === AlertType.FORECAST) {
    return (
      <CursorStateProvider>
        <AlertsChartForecast
          baseWidth={baseWidth}
          condition={condition}
          date={date}
          forecastCondition={forecastCondition}
          formulas={formulas}
          queries={queries}
          queryKey={condition.queryKey}
        />
      </CursorStateProvider>
    );
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const combinedData = useMemo(() => {
    return combineRangeQueryData({
      formulas,
      queries,
      queryData,
      darkModeEnabled,
      combineTimestamp: true,
      combineLabelWithQueryKey: true,
    });
  }, [darkModeEnabled, formulas, queries, queryData]);

  return (
    <AlertsChart
      conditionOperator={condition.of}
      conditionValue={condition.value ? Number(condition.value) : undefined}
      date={date}
      enableEvaluationLine={
        alertType === AlertType.CHANGE || alertType === AlertType.THRESHOLD
      }
      isLoading={combinedData.isLoading}
      queryData={combinedData}
    />
  );
};

export default AlertsCreateMetricsChart;
