import { Button } from 'components';
import classnames from 'classnames';
import React from 'react';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import usePaginator from './usePaginator';
import { Select } from '../SelectV2';
import { getOptions, getSlicePageIndexes, resultsPerPageLimits } from './utils';

type Props = {
  paginator: ReturnType<typeof usePaginator>;
};

const Paginator = ({ paginator }: Props) => {
  const {
    activePageIndex,
    showNextPage,
    showPrevPage,
    maxNumberOfPages,
    numberOfRowsPerPage,
    rowsCount,
    setActivePageIndex,
    setNumberOfRowsPerPage,
  } = paginator;
  const slicePageIndexes = getSlicePageIndexes({
    activePageIndex,
    maxNumberOfPages,
  });

  const onClickHandler = (index: number) => () => {
    setActivePageIndex(index);
  };

  if (rowsCount <= resultsPerPageLimits[0]) {
    return null;
  }

  return (
    <div className="paginator">
      <div className="paginator__label">Results per page:</div>
      <Select
        className="paginator__select"
        onChange={setNumberOfRowsPerPage}
        options={getOptions(numberOfRowsPerPage, rowsCount)}
        top
        value={numberOfRowsPerPage}
      />
      {numberOfRowsPerPage !== null ? (
        <div className="paginator__buttons">
          <Button
            variant="icon"
            size="xs"
            className={classnames({
              paginator__buttons__arrow: true,
              'paginator__buttons__arrow--disabled': activePageIndex === 0,
            })}
            onClick={showPrevPage}
          >
            <BsArrowLeftShort />
          </Button>
          {slicePageIndexes.map((index) => (
            <Button
              variant="icon"
              size="xs"
              className={classnames({
                paginator__buttons__number: true,
                'paginator__buttons__number--active': index === activePageIndex,
              })}
              key={index}
              onClick={onClickHandler(index)}
            >
              {index + 1}
            </Button>
          ))}
          <Button
            variant="icon"
            size="xs"
            className={classnames({
              paginator__buttons__arrow: true,
              'paginator__buttons__arrow--disabled':
                activePageIndex === maxNumberOfPages - 1,
            })}
            onClick={showNextPage}
          >
            <BsArrowRightShort />
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default Paginator;
