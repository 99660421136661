import {
  MAX_HEXAGON_RADIUS,
  MIN_HEXAGON_RADIUS,
  HEXAGON_SPACING,
  MARGIN_PERCENTAGE_HEIGHT,
  MARGIN_PERCENTAGE_WIDTH,
  RADIUS_HEIGHT_WEIGHT,
  RADIUS_WIDTH_WEIGHT,
} from '../constants';
import { getDrawableHexagonEdges } from './hostmapOuterBorder';
import { Hexagon, HostmapDataProps } from '../types';

/**
 * Calculate the properties of a hexagon grid.
 * @param width - The width of the area to fill with hexagons.
 * @param height - The height of the area to fill with hexagons.
 * @param numberOfHexagon - The number of hexagons to fit in the area.
 * @returns An object containing the properties of the hexagon grid.
 */
const calculateHexagonBox = (
  width: number,
  height: number,
  numberOfHexagon: number,
): {
  columns: number;
  hexagonRadius: number;
  horizontalDistance: number;
  totalHeight: number;
  totalWidth: number;
  verticalDistance: number;
} => {
  const marginWidth = width * MARGIN_PERCENTAGE_WIDTH;
  const marginHeight = height * MARGIN_PERCENTAGE_HEIGHT;
  width = width - marginWidth;
  height = height - marginHeight;

  const aspectRatio = width / height;
  const n = Math.round(Math.sqrt(numberOfHexagon / aspectRatio));
  const m = Math.ceil(numberOfHexagon / n);

  let hexagonRadius = Math.min(
    (RADIUS_WIDTH_WEIGHT * width) / ((n + 0.5) * Math.sqrt(3)),
    (RADIUS_HEIGHT_WEIGHT * height) / ((m * 3) / 2),
  );

  // Limit the radius to a maximum of 30 and a minimum of 10
  hexagonRadius = Math.max(
    Math.min(hexagonRadius, MAX_HEXAGON_RADIUS),
    MIN_HEXAGON_RADIUS,
  );

  if (hexagonRadius === MAX_HEXAGON_RADIUS) {
    hexagonRadius = hexagonRadius - hexagonRadius * 0.1;
  }

  const radiusIncreased = hexagonRadius * HEXAGON_SPACING;
  const horizontalDistance = radiusIncreased * Math.sqrt(3);
  const verticalDistance = radiusIncreased * 1.5;
  let columns = Math.floor(width / horizontalDistance);
  const rows = Math.ceil(numberOfHexagon / columns);

  if (rows > 1) {
    columns = Math.ceil(numberOfHexagon / rows);
  } else {
    columns = numberOfHexagon;
  }

  const OFFSET_COLUMNS = columns * 2 > numberOfHexagon ? 1 : 0.5;
  const totalWidth = (columns - OFFSET_COLUMNS) * horizontalDistance;
  const totalHeight = (rows - 1) * verticalDistance;

  return {
    columns,
    hexagonRadius,
    horizontalDistance,
    totalHeight,
    totalWidth,
    verticalDistance,
  };
};

/**
 * This function calculates the positions of a set of hexagons to be drawn on a 2D rendering context, such as a canvas.
 * @param {number} width - The width of the area where the hexagons will be drawn.
 * @param {number} height - The height of the area where the hexagons will be drawn.
 * @param {HostmapDataProps[]} hexagons - An array of objects representing the hexagons to be drawn. Each object should contain any data that will be associated with the hexagon.
 *
 * @returns {Hexagon[]} An array of objects representing the hexagons to be drawn. Each object contains the following properties:
 *    - data: The data associated with the hexagon.
 *    - edges: An array of integers representing the edges of the hexagon that should be drawn. The edges are numbered from 1 to 6, starting from the top edge and going clockwise.
 *    - isEdge: A boolean indicating whether the hexagon is on the edge of the grid.
 *    - radius: The radius of the hexagon.
 *    - x: The x-coordinate of the center of the hexagon.
 *    - y: The y-coordinate of the center of the hexagon.
 */
export const calculateHexagonPositions = (
  width: number,
  height: number,
  hexagons: HostmapDataProps[],
): Hexagon[] => {
  if (!hexagons || hexagons.length === 0) return [];

  const {
    columns,
    hexagonRadius,
    horizontalDistance,
    totalHeight,
    totalWidth,
    verticalDistance,
  } = calculateHexagonBox(width, height, hexagons.length);

  const horizontalOffset = (width - totalWidth) / 2;
  const verticalOffset = (height - totalHeight) / 2;
  return hexagons.map((data, i) => {
    const column = i % columns;
    const row = Math.floor(i / columns);

    let x = column * horizontalDistance;
    if (row % 2 !== 0) {
      x += horizontalDistance / 2;
    }

    let y = row * verticalDistance;
    // Subtract half of the total width and height to center the hexagons
    x += horizontalOffset;
    y += verticalOffset;
    const { isEdge, edges } = getDrawableHexagonEdges({
      column,
      columns,
      index: i,
      numberOfHexagon: hexagons.length,
      row,
    });

    return {
      data,
      edges,
      isEdge,
      radius: hexagonRadius,
      x,
      y,
    };
  });
};
