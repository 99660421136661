import React from 'react';
import {
  ChartGridItem,
  ChartGridReferenceLine,
  ChartJsData,
  LatencyDistribution,
  PercentileSummary,
} from 'types';
import { formatDurationNs } from 'utils';

const calculatePercentileRange = (
  percentiles: PercentileSummary,
  barDurationInNs: number,
  index: number,
) => {
  const sortedPercentiles = Object.entries(percentiles).sort(
    (a, b) => Number(a[1]) - Number(b[1]),
  );

  for (let i = 0; i < sortedPercentiles.length; i++) {
    const [currentPercentile, currentPercentileValue] = sortedPercentiles[i];
    const [previousPercentile, previousPercentileValue] =
      i > 0 ? sortedPercentiles[i - 1] : ['0', 0];
    const isInRange =
      barDurationInNs * index < +currentPercentileValue &&
      barDurationInNs * (index + 1) > +previousPercentileValue;

    if (isInRange) {
      return `${previousPercentile}${currentPercentile ? `-${currentPercentile}` : ''}`;
    }
  }

  return null;
};

const formatLatencyDistribution = (
  result: LatencyDistribution & { durationSecs: number; numBuckets: number },
): ChartGridItem => {
  const data: ChartJsData[] = [];
  const referenceLines: ChartGridReferenceLine[] = [];
  const timestamps: number[] = [];

  const { buckets, percentiles } = result;
  const barDurationInNs =
    buckets.length > 1 ? buckets[1].bucketStart - buckets[0].bucketStart : 1;

  buckets.forEach((bucket, i) => {
    data.push({ Count: bucket.count });
    timestamps.push(bucket.bucketStart);

    const startValue = i === 0 ? 0 : buckets[i - 1].bucketStart;
    const endValue = bucket.bucketStart;

    Object.keys(percentiles).forEach((p: keyof typeof percentiles) => {
      const value = +percentiles[p];
      if (value >= startValue && value < endValue) {
        referenceLines.push({ x: i, label: p, angle: -90 });
      }
    });
  });

  Object.keys(percentiles).forEach((p: keyof typeof percentiles) => {
    const value = +percentiles[p];
    if (
      buckets.length > 0 &&
      value >= buckets[buckets.length - 1].bucketStart &&
      value < buckets[buckets.length - 1].bucketStart + barDurationInNs
    ) {
      referenceLines.push({ x: buckets.length - 1, label: p, angle: -90 });
    }
  });

  return {
    data,
    keys: ['Count'],
    referenceLines,
    renderTooltipTimestamp: ({ index }: { index: number }) => {
      const start = formatDurationNs(barDurationInNs * index, 1, 2);
      const end = formatDurationNs(barDurationInNs * (index + 1), 1, 2);
      const percentileRange = calculatePercentileRange(
        percentiles,
        barDurationInNs,
        index,
      );

      return (
        <div className="rum__latency-tooltip">
          <div>{`Duration: ${start} - ${end} (${formatDurationNs(barDurationInNs, 1, 2)})`}</div>
          {!!percentileRange && (
            <div>{`Percentile Range: ${percentileRange}`}</div>
          )}
        </div>
      );
    },
    timestamps,
  };
};

export default formatLatencyDistribution;
