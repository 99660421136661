import {
  AutocompleteOption,
  AutocompleteV2,
  MultiselectV2,
  useColumnsState,
} from 'components';
import { useKeyExistsState } from 'hooks';
import React, { ReactElement, useEffect, useMemo } from 'react';
import {
  FavoriteFacet,
  FingerprintQueryProps,
  LabelsProps,
  RequestResult,
} from 'types';
import { getFacetKey, getLabelWithDelimiterAndType } from 'utils';
import { useLogsState } from './hooks';
import LogsAddToDashboardPopover from './LogsAddToDashboardPopover';

const LogsFingerprintsListBuilder = ({
  customColumnsState,
  columnsState,
  fingerprintQuery,
  getFacetNamesRequest,
  labels,
  logsState,
  onFingerprintGroupByChange,
}: {
  customColumnsState: ReturnType<typeof useKeyExistsState>;
  columnsState: ReturnType<typeof useColumnsState>;
  fingerprintQuery: FingerprintQueryProps;
  getFacetNamesRequest: RequestResult;
  labels: LabelsProps;
  logsState: ReturnType<typeof useLogsState>;
  onFingerprintGroupByChange: (val: string[]) => void;
}): ReactElement => {
  const { date } = logsState;

  const fingerprintGroupByOptions = useMemo(() => {
    if (!labels) return [];
    const labelOptions = getLabelWithDelimiterAndType(labels);
    const facetNamesOptions: AutocompleteOption[] = [];

    if (!getFacetNamesRequest.result) return labelOptions;
    const options =
      getFacetNamesRequest.result &&
      Array.isArray(getFacetNamesRequest.result.facetNames)
        ? getFacetNamesRequest.result.facetNames
        : [];

    options.forEach((facet: FavoriteFacet) => {
      if ((facet.type || '').toLowerCase() === 'string') {
        const facetKey = getFacetKey({ ...facet, name: `@${facet.name}` });
        facetNamesOptions.push({
          label: `@${facet.displayName || facet.name}`,
          value: facetKey,
        });
      }
    });
    facetNamesOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
    return [...labelOptions, ...facetNamesOptions];
  }, [labels, getFacetNamesRequest.result]);

  useEffect(() => {
    if (!getFacetNamesRequest.result && !getFacetNamesRequest.isLoading) {
      getFacetNamesRequest.call({ date, logsState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="logs__fingerprints-list__builder">
      <div className="logs__fingerprints-list__builder__left">
        <div className="search__button-group">
          <div className="button-group">
            <div className="button-group__item button-group__item--label">
              Show Fingerprints
            </div>
            <div className="button-group__item button-group__item--value">
              Count of
            </div>
            <div className="button-group__item button-group__item--value">
              <AutocompleteV2
                className="autocomplete__fixed-height-30 autocomplete-container--no-border button-group__item__autocomplete--value"
                onChange={null}
                isDisabled={true}
                components={{ DropdownIndicator: null }}
                options={[{ label: 'all logs', value: '*' }]}
                value={fingerprintQuery.countOf}
              />
            </div>
          </div>
          <div className="search__button-group__divider">
            <div />
          </div>
          <div className="button-group">
            <div className="button-group__item button-group__item--label">
              By
            </div>
            <div className="button-group__item button-group__item--value">
              <MultiselectV2
                className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value"
                onChange={onFingerprintGroupByChange}
                options={fingerprintGroupByOptions}
                placeholder="Group by everything"
                value={fingerprintQuery.groupBy}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="logs__fingerprints-list__builder__right">
        <LogsAddToDashboardPopover
          customColumnsState={customColumnsState}
          columnsState={columnsState}
          fingerprintQuery={fingerprintQuery}
          logsState={logsState}
        />
      </div>
    </div>
  );
};

export default LogsFingerprintsListBuilder;
