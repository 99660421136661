import { useTabs } from 'components';
import React from 'react';
import { ArrowRight } from 'react-feather';
import { logFacetsBitmap } from './constants';
import { LogsAttribute } from './types';

type Props = {
  label: string;
  setLogsAttribute: (logsAttribute: LogsAttribute) => void;
  tabs: ReturnType<typeof useTabs>;
  value: string;
};

const TraceSidebarActiveSpanJsonLogsSearchButton = ({
  label,
  setLogsAttribute,
  tabs,
  value,
}: Props) => {
  const isLogFacet = logFacetsBitmap[label];
  const onClick = () => {
    setLogsAttribute({ key: label, value });
    tabs.setActiveIndex(2);
  };

  return (
    <button
      className="logs__selected-log__attribute__panel__item"
      onClick={onClick}
    >
      <ArrowRight
        className="logs__selected-log__attribute__panel__item__icon"
        size={14}
      />
      {isLogFacet ? (
        <>
          <span className="logs__selected-log__attribute__panel__item__label">
            {'Show logs where '}
          </span>
          <span className="logs__selected-log__attribute__panel__item__value">
            {label}
          </span>
          <span className="logs__selected-log__attribute__panel__item__label">
            {' is '}
          </span>
          <span className="logs__selected-log__attribute__panel__item__value">
            {value}
          </span>
        </>
      ) : (
        <>
          <span className="logs__selected-log__attribute__panel__item__label">
            {'Search logs for '}
          </span>
          <span className="logs__selected-log__attribute__panel__item__value">
            {value}
          </span>
        </>
      )}
    </button>
  );
};

export default TraceSidebarActiveSpanJsonLogsSearchButton;
