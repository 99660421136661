import { chartingPalette } from 'kfuse-constants';
import { Service } from 'types';

const getColorsByServiceHash = (services: Service[]): Record<string, string> =>
  services.reduce(
    (obj, service, i) => ({
      ...obj,
      [service.hash]: chartingPalette[i % chartingPalette.length],
    }),
    {},
  );

export default getColorsByServiceHash;
