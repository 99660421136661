import { KeyPairProps } from '../types';
import { ReservedAnnotationKeys } from '../utils';

const customAnnotationsValidator = (annotations: KeyPairProps[]): string => {
  const isReservedKeyword = annotations.find((annotation) =>
    ReservedAnnotationKeys.includes(annotation.label),
  );

  if (isReservedKeyword) {
    return `Annotation key ${isReservedKeyword.label} is reserved. Please use a different key.`;
  }

  return '';
};

export default customAnnotationsValidator;
