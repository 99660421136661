import { HeatmapRenderer, Loader } from 'components';
import {
  getLegacyFiltersFromFiltersState,
  SearchState,
  useRequest,
} from 'hooks';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { getHeatmapData } from 'requests';
import { DashboardPanelProps } from 'types';
import {
  buildHeatmapPath,
  getHeatmapCountFills,
  heatmapColorPalette,
  heatmapDataTransformer,
} from 'utils';

import { useDashboardEditState } from './hooks';

let NUM_X_AXIS_SPLITS = 300;
let NUM_Y_AXIS_SPLITS = 100;

const DashboardEditTracesHeatmap = ({
  baseWidth,
  dashboardEditState,
  panel,
  searches,
}: {
  baseWidth: number;
  dashboardEditState: ReturnType<typeof useDashboardEditState>;
  panel: DashboardPanelProps;
  searches: SearchState[];
}): ReactElement => {
  const { date } = dashboardEditState;
  const heatmapDataRequest = useRequest(getHeatmapData, true, true);

  const filters = useMemo(() => {
    return getLegacyFiltersFromFiltersState(
      searches[0].searchBarState?.filters || [],
    );
  }, [searches]);

  const fetchData = () => {
    try {
      const parsedExpr = JSON.parse(panel.targets[0].expr);
      const {
        numTimeBuckets,
        numAttributeBuckets,
        customerFilter,
        spanFilter,
        traceIdSearch,
        ParentSpanIdFilter,
        query,
      } = parsedExpr;

      NUM_X_AXIS_SPLITS = numTimeBuckets;
      NUM_Y_AXIS_SPLITS = numAttributeBuckets;

      heatmapDataRequest.call({
        attribute: query.measure,
        date,
        numTimeBuckets,
        numAttributeBuckets,
        customerFilter,
        filter: filters,
        spanFilter,
        ParentSpanIdFilter,
        traceIdSearch,
        transformer: [
          { id: 'heatmapDataTransformer', func: heatmapDataTransformer },
        ],
      });
    } catch (e) {}
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, filters]);

  const chartData = useMemo(() => {
    if (!heatmapDataRequest.result) return null;
    return {
      data: [null, heatmapDataRequest.result.data || []],
      series: [
        {
          label: 'Latency',
          paths: buildHeatmapPath({
            disp: {
              fill: {
                lookup: heatmapColorPalette,
                values: getHeatmapCountFills,
              },
            },
          }),
          facets: [
            { scale: 'x', auto: true, sorted: 1 },
            { scale: 'y', auto: true },
          ],
        },
      ],
      minValue: heatmapDataRequest.result.minValue,
      maxValue: heatmapDataRequest.result.maxValue,
      uniqueTimestamps: heatmapDataRequest.result.uniqueTimestamps,
    };
  }, [heatmapDataRequest.result]);

  return (
    <Loader
      isLoading={heatmapDataRequest.isLoading}
      sizes="small"
      className="min-h-[360px]"
    >
      {chartData ? (
        <HeatmapRenderer
          chartData={chartData}
          size={{ width: baseWidth - 40, height: 360 }}
          unit={heatmapDataRequest.result.unit}
          numXAxisSplits={NUM_X_AXIS_SPLITS}
          numYAxisSplits={NUM_Y_AXIS_SPLITS}
          layoutType="modal"
        />
      ) : (
        <div></div>
      )}
    </Loader>
  );
};

export default DashboardEditTracesHeatmap;
