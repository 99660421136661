import { convertTimeStringToUnix } from 'utils';
import { DateSelection } from 'types/DateSelection';
import { AddToastProps } from 'components/Toasts/context';

export const getStepFromDate = ({ date }: { date: DateSelection }) => {
  const { startTimeUnix, endTimeUnix } = date;
  const durationSecs = endTimeUnix - startTimeUnix;
  return `${durationSecs}s`;
};

export const NANO_TO_MILLI_SECONDS = 1000000;

export const getRelativeVsAbsolute = ({
  addToast,
  date,
  utcTimeEnabled,
}: {
  addToast: (props: AddToastProps) => void;
  date: DateSelection;
  utcTimeEnabled: boolean;
}) => {
  const { startLabel, endLabel, startTimeUnix, endTimeUnix } = date;

  if (startLabel && endLabel) {
    const convertedStartTimeUnix = convertTimeStringToUnix(
      startLabel,
      utcTimeEnabled,
    );
    const convertedEndTimeUnix = convertTimeStringToUnix(
      endLabel,
      utcTimeEnabled,
    );
    if (convertedStartTimeUnix && convertedEndTimeUnix) {
      return {
        startLabel,
        endLabel,
        startTimeUnix: convertedStartTimeUnix,
        endTimeUnix: convertedEndTimeUnix,
      };
    } else {
      addToast?.({
        text: 'Invalid date Range',
        status: 'error',
      });
      return date;
    }
  }

  return {
    startTimeUnix,
    endTimeUnix,
  };
};
