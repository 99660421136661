import { ExplorerQueryProps, FormulaProps } from 'types';

import {
  AnomalyConditionProps,
  ConditionProps,
} from '../../AlertsCreateCondition';
import { anomalyAlertValidatorForCreate } from '../../AlertsCreateValidators';
import { useAlertsCreate } from '../../hooks';
import { getAnomalyAlertsCreatePromQL } from '../utils';
import { getPromQlQuery } from '../../utils';

const useAlertsCreateMetricsAnomaly = ({
  alertsCreateState,
}: {
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
}) => {
  const {
    alertsDetails,
    alertType,
    addToast,
    date,
    mutateAlertsRule,
    ruleType,
    setIsSaving,
    setErrorHandling,
  } = alertsCreateState;

  const createAnomalyDetectionRuleForQuery = ({
    anomalyCondition,
    condition,
    formulas,
    queries,
  }: {
    anomalyCondition: AnomalyConditionProps;
    condition: ConditionProps;
    formulas: FormulaProps[];
    queries: ExplorerQueryProps[];
  }) => {
    const validation = anomalyAlertValidatorForCreate({
      alertsDetails,
      condition,
      anomalyCondition,
      formulas,
      queries,
    });

    if (typeof validation === 'string') {
      addToast({ text: validation, status: 'error' });
      return;
    }

    const validationKeys = Object.keys(validation);
    if (validationKeys.length) {
      setErrorHandling((prev) => ({ ...prev, ...validation }));
      validationKeys.forEach((key) => {
        addToast({ text: validation[key], status: 'error' });
      });
      return;
    }

    const { queryKey } = condition;
    const { promql } = getPromQlQuery({ formulas, queryKey, queries, date });
    if (!promql) {
      addToast({ text: 'Not a valid query', status: 'error' });
      return;
    }
    createAnomalyDetectionRule({ anomalyCondition, condition, promql });
  };

  const createAnomalyDetectionRule = ({
    anomalyCondition,
    condition,
    extraAnnotations = {},
    promql,
  }: {
    anomalyCondition: AnomalyConditionProps;
    condition: ConditionProps;
    extraAnnotations?: { [key: string]: any };
    promql: string;
  }) => {
    const promqlQuery = getAnomalyAlertsCreatePromQL({
      anomalyCondition,
      query: promql,
    });
    condition.of = 'gt';

    if (!promqlQuery) return;
    setIsSaving(true);
    mutateAlertsRule({
      condition,
      datasourceType: 'prometheus',
      date,
      promqlQuery,
      ruleAnnotations: {
        alertType,
        ruleType,
        extraData: JSON.stringify({ ...extraAnnotations }),
      },
    });
  };

  return { createAnomalyDetectionRule, createAnomalyDetectionRuleForQuery };
};

export default useAlertsCreateMetricsAnomaly;
