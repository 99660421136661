import React, { useMemo } from 'react';
import { getSelector } from '../utils/selectorsfunction';

import { StatefulSet } from '../types';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellTimestamp,
  KubeTableCell,
  KubeTableFilterCell,
} from './Components';
import KubernetesTable from './KubernetesTable';
import { useEntitiesCount, useEntitiesData } from './useEntitiesData';

export default function KubernetesTableForStatefulSet() {
  const { entitiesType, facets, page, limit } = useKubernetesController();
  const filters = useMemo(() => [facets], [facets]);
  const [rows] = useEntitiesData<{ statefulSet: StatefulSet }>({
    filters,
    page,
    limit,
  });
  const [count] = useEntitiesCount({
    entitiesType,
    facets,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'kube_stateful_set',
          label: 'Stateful Set',
          renderCell(prop) {
            const statefulSetName = prop.row.statefulSet.metadata.name;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name pb-1.5 pt-1.5"
                tooltipText={statefulSetName}
              >
                {statefulSetName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell(prop) {
            return <KubeCellCluster {...prop} />;
          },
        },
        {
          key: 'metadata.namespace',
          label: 'Namespace',
          renderCell(prop) {
            return <KubeCellNamespace {...prop} />;
          },
        },
        {
          key: 'spec.serviceName',
          label: 'Service',
          renderCell({ row, ...prop }) {
            return (
              <KubeTableFilterCell
                facetKey="kube_service"
                facetValue={row.statefulSet.spec.serviceName}
                className="kubernetes__table__cell__cluster-name truncate w-full"
                row={row}
                {...prop}
              />
            );
          },
        },
        {
          key: 'headerSelectors',
          label: 'Selectors',
          renderCell(prop) {
            const selector = getSelector(prop.row.statefulSet);
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name"
                tooltipText={selector}
              >
                {selector}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'spec.podManagementPolicy',
          label: 'Pod Policy',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop}>
                {prop.row.statefulSet.spec.podManagementPolicy}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'spec.updateStrategy',
          label: 'Update Strategy',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop}>
                {prop.row.statefulSet.spec.updateStrategy}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'metadata.creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            return (
              <KubeCellTimestamp
                {...prop}
                timestamp={prop.row.statefulSet.metadata.creationTimestamp}
              />
            );
          },
        },
        {
          key: 'status.currentReplicas',
          label: 'Current',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.statefulSet.status.currentReplicas}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'spec.desiredReplicas',
          label: 'Desired',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.statefulSet.spec.desiredReplicas}
              </KubeTableCell>
            );
          },
        },
      ]}
    />
  );
}
