import { TimeSeries } from 'types';

export const formatCardinalityTimeseries = (
  data: Array<{
    bucketStart: number;
    labelNames: { [key: string]: number };
    metricNames: { [key: string]: number };
    overallCardinality: number;
  }>,
  type: 'label' | 'metric' = 'label',
) => {
  if (!data) return null;

  const cardinalityTimeseriseMap: {
    [key: string]: { data: { [key: string]: number }[]; max: number };
  } = {};

  const objName = type === 'label' ? 'labelNames' : 'metricNames';
  data.map((item) => {
    const labelNamesKeys = Object.keys(item[objName]);
    labelNamesKeys.map((label) => {
      if (!cardinalityTimeseriseMap[label]) {
        cardinalityTimeseriseMap[label] = { data: [], max: 0 };
      }

      cardinalityTimeseriseMap[label].data.push({
        count: item[objName][label],
      });
      cardinalityTimeseriseMap[label].max = Math.max(
        cardinalityTimeseriseMap[label].max,
        item[objName][label],
      );
    });
  });

  return cardinalityTimeseriseMap;
};

export const formatTraceCardinalityTimeseries = (data: TimeSeries[]) => {
  if (!data) return null;

  const cardinalityTimeseriseMap: {
    [key: string]: { data: { [key: string]: number }[]; max: number };
  } = {};

  data.map((item) => {
    const labelNamesKey = item['GroupVal']['key'];
    if (!cardinalityTimeseriseMap[labelNamesKey]) {
      cardinalityTimeseriseMap[labelNamesKey] = { data: [], max: 0 };
    }
    cardinalityTimeseriseMap[labelNamesKey].data.push({
      count: item['Value'],
    });
    cardinalityTimeseriseMap[labelNamesKey].max = Math.max(
      cardinalityTimeseriseMap[labelNamesKey].max,
      item['Value'],
    );
  });

  return cardinalityTimeseriseMap;
};
