import dayjs from 'dayjs';
import { Layout } from 'react-grid-layout';
import { DateSelection, ExplorerQueryProps, FormulaProps } from 'types';
import {
  buildPromqlWithFunctions,
  buildFormulaQuery,
} from 'utils/MetricsQueryBuilder';
import {
  DashboardPanelProps,
  DashboardPanelTargetsProps,
  DashboardPanelType,
  DashboardReloadPanelsProps,
  DashboardTemplateValueProps,
} from 'types';
import {
  convertTimeStringToUnix,
  getRollupToSecond,
  isNumber,
  msIntervalVariableValue,
  secondRangeVariableValue,
  calculateRateIntervalVariableValue,
  validateCodifiedDate,
} from 'utils';

import calculateTimeShift from './calculateTimeShift';
import {
  promqlVariableReplacer,
  replaceFromAndToVariable,
} from './grafana-variable';

export const getPanelWidgetSize = (panelType: DashboardPanelType) => {
  switch (panelType) {
    case 'timeseries':
      return { w: 8, h: 6 };
    case 'table':
      return { w: 8, h: 6 };
    case 'piechart':
      return { w: 8, h: 6 };
    case 'text':
      return { w: 8, h: 4 };
    case 'stat':
      return { w: 6, h: 4 };
    case 'group':
      return { w: 8, h: 4 };
    case DashboardPanelType.ROW:
      return { w: 24, h: 1 };
    default:
      return { w: 8, h: 6 };
  }
};

export const getDefaultDashboardSettings = () => ({
  annotations: {},
  editable: true,
  fiscalYearStartMonth: 0,
  description: '',
  link: [],
  templating: { list: [] },
  time: { from: 'now-5m', to: 'now' },
  title: `New Dashboard ${new Date().toLocaleDateString()}`,
  uid: '',
  version: 1,
  style: 'dark',
  timezone: '',
});

export const getDateFromRange = (from: string, to: string): DateSelection => {
  if (validateCodifiedDate(from) && validateCodifiedDate(to)) {
    const startTimeUnix = convertTimeStringToUnix(from);
    const endTimeUnix = convertTimeStringToUnix(to);

    return { startTimeUnix, endTimeUnix, startLabel: from, endLabel: to };
  }

  if (
    new Date(from).toString() !== 'Invalid Date' &&
    new Date(to).toString() !== 'Invalid Date'
  ) {
    return {
      startTimeUnix: dayjs(from).unix(),
      endTimeUnix: dayjs(to).unix(),
      startLabel: validateCodifiedDate(from) ? from : null,
      endLabel: validateCodifiedDate(to) ? to : null,
    };
  }

  return {
    startTimeUnix: convertTimeStringToUnix('now-5m'),
    endTimeUnix: convertTimeStringToUnix('now'),
    startLabel: 'now-5m',
    endLabel: 'now',
  };
};

export const isDragItemPlaceholder = (layouts: Layout[]): boolean => {
  return layouts.some((layout) => layout.i === 'placeholder');
};

export const getPromqlForQueryAndFormula = (
  queries: ExplorerQueryProps[],
  formulas: FormulaProps[],
): { promqlQueries: string[]; promqlFormulas: string[] } => {
  const promqlQueries: string[] = [];
  queries.forEach((query: ExplorerQueryProps) => {
    const promql = buildPromqlWithFunctions(query);
    promqlQueries.push(promql);
  });
  const queryKeys = queries.map((query: ExplorerQueryProps) => query.queryKey);
  const promqlFormulas = buildFormulaQuery(promqlQueries, queryKeys, formulas);

  return { promqlQueries, promqlFormulas };
};

/**
 * Replace condition with the instruction
 * @param promql
 * 1. replace $cluster, $cluster, $pod, $node, etc with *
 * 2. replace ${service}, ${namespace}, ${pod}, ${node}, etc with +
 */
export const transformPromql = ({
  date,
  promql,
  templateValues,
  width,
  chartType,
}: {
  date: DateSelection;
  promql: string;
  templateValues: DashboardTemplateValueProps;
  width: number;
  chartType: string;
}): string => {
  if (!promql) return promql;

  let santizedPromql = promql;
  // replace template variables
  if (Object.keys(templateValues).length > 0) {
    santizedPromql = replaceTemplateVariables(santizedPromql, templateValues);
  }

  // $__interval, $__rate_interval, $__range exists, replace with the value
  santizedPromql = replacePromqlVariables({
    date,
    promql: santizedPromql,
    width,
    chartType,
  });

  return encodeURIComponent(santizedPromql);
};

/**
 * Replace variables in promql
 * @param promql string
 * @returns string
 * Variables are $__interval, $__rate_interval, $__range
 */
const replacePromqlVariables = ({
  chartType,
  date,
  promql,
  width,
}: {
  chartType: string;
  date: DateSelection;
  promql: string;
  width: number;
}): string => {
  // replace $__interval
  if (promql.includes('$__interval')) {
    promql = promql.replaceAll(
      '$__interval',
      msIntervalVariableValue(date, width),
    );
  }

  // replace $__rate_interval
  if (promql.includes('$__rate_interval')) {
    const rateInterval = calculateRateIntervalVariableValue(date, chartType);
    const { rateIntervalSeconds } = rateInterval;
    promql = promql.replaceAll('$__rate_interval', rateIntervalSeconds);
  }

  // replace $__range
  if (promql.includes('${__range_ms}')) {
    promql = promql.replaceAll('${__range_ms}', secondRangeVariableValue(date));
  }

  if (promql.includes('${__range_s}')) {
    promql = promql.replaceAll(
      '${__range_s}',
      `${date.endTimeUnix - date.startTimeUnix}`,
    );
  }

  if (promql.includes('$__range:')) {
    promql = promql.replaceAll(
      '$__range:',
      `${date.endTimeUnix - date.startTimeUnix}s:`,
    );
  }

  if (promql.includes('${__from') || promql.includes('${__to')) {
    promql = replaceFromAndToVariable(promql, date);
  }

  return promql;
};

/**
 * Replace dashboard template variables in promql
 * @param promql string
 * @param templating DashboardTemplateProps[]
 */
export const replaceTemplateVariables = (
  promql: string,
  templateValues: DashboardTemplateValueProps,
): string => {
  const templateKeys = Object.keys(templateValues);
  templateKeys.forEach((name) => {
    const value = templateValues[name];
    if (!value) {
      promql = promqlVariableReplacer(promql, name, '');
      return;
    }
    const replaceValue = typeof value === 'string' ? value : value?.join('|');
    promql = promqlVariableReplacer(promql, name, replaceValue);
  });

  return promql;
};

/**
 * Get reload panels all true
 */
export const getReloadPanels = (
  panels: DashboardPanelProps[],
  newReload: DashboardReloadPanelsProps,
): DashboardReloadPanelsProps => {
  panels.forEach((panel, idx) => {
    newReload[`${idx}`] = true;
    if (panel.panels) {
      getReloadPanelsNested(panel.panels, newReload, idx);
    }
  });

  return newReload;
};

export const getReloadPanelsNested = (
  panels: DashboardPanelProps[],
  newReload: DashboardReloadPanelsProps,
  nestedIndex: string,
): DashboardReloadPanelsProps => {
  panels.forEach((panel, idx) => {
    newReload[`${nestedIndex}-${idx}`] = true;
    if (panel.panels) {
      getReloadPanelsNested(panel.panels, newReload, `${nestedIndex}-${idx}`);
    }
  });

  return newReload;
};

export const getReloadPanelsForNotNested = (
  panels: DashboardPanelProps[],
  panelIndex: number,
): DashboardReloadPanelsProps => {
  const newReload: DashboardReloadPanelsProps = {};
  for (let i = panelIndex + 1; i < panels.length; i++) {
    if (panels[i].type === DashboardPanelType.ROW) {
      return newReload;
    }
    newReload[`${i}`] = true;
  }
  return newReload;
};

/**
 * Get reload panels for templating
 */
export const getReloadPanelsForTemplating = (
  panels: DashboardPanelProps[],
  label: string,
  newReload: DashboardReloadPanelsProps,
  nestedIndex?: number,
): DashboardReloadPanelsProps => {
  panels.forEach((panel, idx) => {
    let panelKey = `${idx}`;
    if (nestedIndex) {
      panelKey = `${nestedIndex}-${idx}`;
    }

    if (panel.panels) {
      getReloadPanelsForTemplating(panel.panels, label, newReload, idx);
    } else if (panel.targets) {
      panel.targets.forEach((target) => {
        if (target.expr && target.expr.indexOf(label) !== -1) {
          newReload[`${panelKey}`] = true;
        }
      });
    }
  });

  return newReload;
};

/**
 * Replace logQL variables
 */
export const replaceLogQLVariables = (
  logQL: string,
  templateValues: DashboardTemplateValueProps,
): string => {
  const templateKeys = Object.keys(templateValues);
  templateKeys.forEach((name) => {
    const value = templateValues[name];
    const replaceValue = typeof value === 'string' ? value : value.join('|');
    logQL = promqlVariableReplacer(logQL, name, replaceValue);
  });

  return logQL;
};

/**
 * Transform formula expression to promQL query
 */
export const transformFormulaExpression = ({
  date,
  expression,
  targets,
  templateValues,
  width,
  chartType,
}: {
  date: DateSelection;
  chartType: string;
  expression: string;
  targets: DashboardPanelTargetsProps[];
  templateValues: DashboardTemplateValueProps;
  width: number;
}): string => {
  const allPromQL: { [key: string]: string } = {};

  targets.map((target) => {
    if (target.expr) {
      const promql = transformPromql({
        date,
        promql: target.expr,
        templateValues,
        width,
        chartType,
      });
      allPromQL[target.refId] = promql;
    }
  });

  // replace $E / $B with promQL
  let formula = expression;
  Object.keys(allPromQL).forEach((key) => {
    formula = formula.replaceAll(`$${key}`, allPromQL[key]);
  });

  return formula;
};

export const getReplacedInterval = (
  target: DashboardPanelTargetsProps,
  templateValues: DashboardTemplateValueProps,
): number => {
  if (target.interval) {
    const interval = replaceTemplateVariables(target.interval, templateValues);
    if (isNumber(interval)) {
      return Number(interval);
    }
    const intervalInSecond = getRollupToSecond(interval);
    if (isNumber(intervalInSecond)) {
      return Number(intervalInSecond);
    }
  }
  return null;
};

export const getReplacedTimeFromWithDate = ({
  date,
  panel,
  templateValues,
}: {
  date: DateSelection;
  panel: DashboardPanelProps;
  templateValues: DashboardTemplateValueProps;
}): DateSelection & { allowFutureTime?: boolean } => {
  const { timeFrom, timeShift } = panel;
  if (!timeFrom && !timeShift) return date;

  if (timeShift?.includes('/')) {
    const timeShiftResult = calculateTimeShift(timeShift);
    return { ...timeShiftResult, allowFutureTime: true };
  }

  const timeDiffSecond = date.endTimeUnix - date.startTimeUnix;
  const templateValuesInSecond: { [key: string]: string } = {};
  if (timeFrom?.includes('$')) {
    Object.keys(templateValues).map((key) => {
      const valueType = typeof templateValues[key];
      if (valueType !== 'string' && valueType !== 'number') return;
      const valueToSecond = getRollupToSecond(templateValues[key] as string);
      if (typeof valueToSecond === 'number' && !Number.isNaN(valueToSecond)) {
        templateValuesInSecond[key] = `${valueToSecond}`;
      }
    });
  }

  let replacedTimeFrom = '';
  let replacedTimeShift = '';
  if (timeFrom) {
    replacedTimeFrom = replaceTemplateVariables(
      timeFrom,
      templateValuesInSecond,
    );
  }
  if (timeShift) {
    replacedTimeShift = replaceTemplateVariables(
      timeShift,
      templateValuesInSecond,
    );
  }

  let evaluatedTimeFrom = timeDiffSecond;
  let evaluatedTimeShift = 0;
  if (replacedTimeFrom) {
    evaluatedTimeFrom = new Function(`return ${replacedTimeFrom}`)();
  }
  if (replacedTimeShift) {
    evaluatedTimeShift = new Function(`return ${replacedTimeShift}`)();
  }

  const unixNow = dayjs().unix() - evaluatedTimeShift || 0;
  const newDate: DateSelection = {
    startTimeUnix: unixNow - evaluatedTimeFrom,
    endTimeUnix: unixNow,
  };

  return newDate;
};
