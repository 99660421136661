import { useUrlState } from 'hooks';
import { useEffect, useState } from 'react';
import { DashboardPanelType } from 'types/Dashboard';

import { AnalyticsChartState } from './types';

const useAnalyticsChart = ({
  date,
  defaultCombineAllQueries = false,
  forcedActiveVisualization,
  hideVisualizeToolBar = false,
  supportedVisualizations = [DashboardPanelType.TIMESERIES],
  transformationConfig,
}: AnalyticsChartState) => {
  const [isMultiChart, setIsMultiChart] = useUrlState(
    'isMultiChart',
    defaultCombineAllQueries,
  );
  const [chartWidth, setChartWidth] = useState(800);
  const [activeVisualization, setActiveVisualization] = useState(
    supportedVisualizations[0],
  );

  useEffect(() => {
    if (forcedActiveVisualization) {
      setActiveVisualization(forcedActiveVisualization);
    }
  }, [forcedActiveVisualization]);

  return {
    activeVisualization,
    chartWidth,
    date,
    hideVisualizeToolBar,
    isMultiChart,
    setActiveVisualization,
    setChartWidth,
    setIsMultiChart,
    supportedVisualizations,
    transformationConfig,
  };
};

export default useAnalyticsChart;
