import { chartingPalette } from 'kfuse-constants';
import { Service } from 'types';

const getColorsByServiceName = (
  services: Service[],
): Record<string, string> => {
  const serviceNamesBitmap = services.reduce(
    (obj, service) => ({ ...obj, [service.name]: 1 }),
    {},
  );

  return Object.keys(serviceNamesBitmap).reduce(
    (obj, serviceName, i) => ({
      ...obj,
      [serviceName]: chartingPalette[i % chartingPalette.length],
    }),
    {},
  );
};

export default getColorsByServiceName;
