import { RADIUS_MULTIPLIER } from '../constants';
import { Hexagon } from '../types';

/**
 * This function draws a hexagon on a 2D rendering context.
 *
 * @param {CanvasRenderingContext2D} ctx - The 2D rendering context where the hexagon will be drawn.
 * @param {Hexagon} hexagon - An object representing the hexagon to be drawn. It should have the following properties:
 *    - x: The x-coordinate of the center of the hexagon.
 *    - y: The y-coordinate of the center of the hexagon.
 *    - radius: The radius of the hexagon.
 *    - data: An object containing additional data for the hexagon. It should have the following property:
 *        - color: The color of the hexagon.
 */
export const drawHexagon = (
  ctx: CanvasRenderingContext2D,
  hexagon: Hexagon,
): void => {
  const numberOfSides = 6;
  const { x, y, radius, data } = hexagon;
  const { color, borderColor } = data;

  ctx.beginPath();
  ctx.moveTo(
    x + radius * Math.cos(Math.PI / 6),
    y + radius * Math.sin(Math.PI / 6),
  );

  for (let i = 1; i <= numberOfSides; i += 1) {
    ctx.lineTo(
      x + radius * Math.cos((i * 2 * Math.PI) / numberOfSides + Math.PI / 6),
      y + radius * Math.sin((i * 2 * Math.PI) / numberOfSides + Math.PI / 6),
    );
  }

  ctx.fillStyle = color;
  ctx.fill();

  if (borderColor) {
    ctx.strokeStyle = borderColor;
    ctx.lineWidth = 1.5;
    ctx.stroke();
  }

  ctx.closePath();
};

/**
 * This function draws a border around a hexagon on a 2D rendering context.
 *
 * @param {CanvasRenderingContext2D} ctx - The 2D rendering context where the border will be drawn.
 * @param {Hexagon} hexagon - An object representing the hexagon to be drawn. It should have the following properties:
 *    - x: The x-coordinate of the center of the hexagon.
 *    - y: The y-coordinate of the center of the hexagon.
 *    - isEdge: A boolean indicating whether the hexagon is on the edge of the grid.
 *    - edges: An array of integers representing the edges of the hexagon where the border should be drawn.
 *    - radius: The radius of the hexagon.
 *    - data: An object containing additional data for the hexagon. It should have the following property:
 *        - outerBorderColor: The color of the border.
 */
export const drawBorder = (
  ctx: CanvasRenderingContext2D,
  hexagon: Hexagon,
): void => {
  const { x, y, isEdge, edges, radius, data } = hexagon;
  const { outerBorderColor } = data;

  const radiusIncreased = radius * RADIUS_MULTIPLIER;
  if (isEdge) {
    ctx.beginPath();

    // Draw the border only on the specific edges
    edges.forEach((i: number) => {
      ctx.moveTo(
        x + radiusIncreased * Math.cos((i * 2 * Math.PI) / 6 - Math.PI / 6),
        y + radiusIncreased * Math.sin((i * 2 * Math.PI) / 6 - Math.PI / 6),
      );
      ctx.lineTo(
        x +
          radiusIncreased *
            Math.cos((((i + 1) % 6) * 2 * Math.PI) / 6 - Math.PI / 6),
        y +
          radiusIncreased *
            Math.sin((((i + 1) % 6) * 2 * Math.PI) / 6 - Math.PI / 6),
      );
    });

    ctx.strokeStyle = outerBorderColor;
    ctx.lineWidth = 1.5;
    ctx.stroke();
    ctx.closePath();
  }
};

/**
 * This function draws a hexagon with a larger radius on a 2D rendering context, typically used to highlight a hexagon when it's hovered over.
 *
 * @param {CanvasRenderingContext2D} ctx - The 2D rendering context where the hexagon will be drawn.
 * @param {Hexagon} hexagon - An object representing the hexagon to be drawn. It should have the following properties:
 *    - x: The x-coordinate of the center of the hexagon.
 *    - y: The y-coordinate of the center of the hexagon.
 *    - radius: The radius of the hexagon.
 *    - data: An object containing additional data for the hexagon. It should have the following properties:
 *        - borderColor: The color of the border of the hexagon.
 *        - color: The color of the hexagon.
 */
export const drawHoveredHexagon = (
  ctx: CanvasRenderingContext2D,
  hexagon: Hexagon,
): void => {
  if (!hexagon) return;
  const { x, y, radius, data } = hexagon;
  const { borderColor, color } = data;
  const radiusHovered = radius * RADIUS_MULTIPLIER;

  ctx.beginPath();
  ctx.moveTo(
    x + radiusHovered * Math.cos(Math.PI / 6),
    y + radiusHovered * Math.sin(Math.PI / 6),
  );

  for (let i = 1; i <= 6; i += 1) {
    ctx.lineTo(
      x + radiusHovered * Math.cos((i * 2 * Math.PI) / 6 + Math.PI / 6),
      y + radiusHovered * Math.sin((i * 2 * Math.PI) / 6 + Math.PI / 6),
    );
  }

  ctx.fillStyle = color;
  ctx.fill();

  if (borderColor) {
    ctx.strokeStyle = borderColor;
    ctx.lineWidth = 1.5;
    ctx.stroke();
  }

  ctx.closePath();
};
