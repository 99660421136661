import { ForecastConditionProps } from '../AlertsCreateCondition';

export const validateForecastConditionLinear = (
  forecastCondition: ForecastConditionProps,
): string => {
  if (!forecastCondition.forecastDuration) {
    return 'Please select forecast duration';
  }

  return '';
};

export const validateForecastConditionSeasonal = (
  forecastCondition: ForecastConditionProps,
): { [key: string]: string } | string => {
  const { forecastDuration } = forecastCondition;

  if (!forecastDuration) {
    return 'Please select forecast duration';
  }

  return {};
};
