import dayjs from 'dayjs';
import {
  DateSelection,
  FacetRegexTerm,
  KeyExists,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  SpanFilter,
  ValueCount,
} from 'types';
import { onPromiseError } from 'utils';
import queryTraceService from './queryTraceService';
import { buildTracesFilter } from './utils';

type Args = {
  customerFilter?: { key: string; value: string };
  date: DateSelection;
  facetRegex: FacetRegexTerm[];
  keyExists: KeyExists;
  labelName: string;
  selectedFacetRangeByName?: SelectedFacetRangeByName;
  selectedFacetValuesByName?: SelectedFacetValuesByName;
  selectedHcFacetValuesByName?: SelectedFacetValuesByName;
  spanFilter: SpanFilter;
};

const errorLabelValues = async ({
  customerFilter,
  date,
  facetRegex = [],
  keyExists = {},
  labelName,
  selectedFacetRangeByName = {},
  selectedFacetValuesByName = {},
  selectedHcFacetValuesByName = {},
  spanFilter,
}: Args): Promise<ValueCount[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  return queryTraceService<ValueCount[], 'errorLabelValues'>(`
    {
      errorLabelValues(
        limit: 10000,
        durationSecs: ${durationSecs},
        filter: ${buildTracesFilter({
          customerFilter,
          facetRegex,
          keyExists,
          selectedFacetRangeByName,
          selectedFacetValuesByName,
          selectedHcFacetValuesByName,
          spanFilter,
        })},
        labelName: "${labelName}",
        timestamp: "${endTime.format()}",
      ) {
        count
        value
      }
    }
  `).then((data) => data.errorLabelValues || [], onPromiseError);
};

export default errorLabelValues;
