import classnames from 'classnames';
import { useToggle } from 'hooks';
import React from 'react';
import * as constants from './constants';
import getTypeAndValue from './getTypeAndValue';
import JsonItem from './JsonItem';
import { JsonOptions, JsonType } from './types';

const getItems = (type: JsonType, value: value) => {
  if (type === JsonType.array) {
    return value.map((item: any) => ({
      label: '',
      value: item,
    }));
  }

  if (type === JsonType.object) {
    return Object.keys(value)
      .sort()
      .map((key) => ({
        label: key,
        value: value[key],
      }));
  }

  return [];
};

type Props = {
  label: string;
  level: number;
  options?: JsonOptions;
  path: string;
  type: JsonType;
  value: any;
};

const getNextPath = (
  path: string,
  index: number,
  isArray: boolean,
  label: string,
  type: JsonType,
) => {
  let result = path;
  if (isArray) {
    result += `[${index}]`;
  } else {
    result += label.indexOf('.') > -1 ? `['${label}']` : label;
  }

  if (type === JsonType.object) {
    result += '.';
  }

  return result;
};

const JsonIterable = ({ label, level, options, path, type, value }: Props) => {
  const isExpandedToggle = useToggle(level === 0 || level === 1 || level === 2);
  const items = getItems(type, value);

  const isArray = type === JsonType.array;
  const openCharacter = isArray ? '[' : '{';
  const closeCharacter = isArray ? ']' : '}';

  const isOpen = isExpandedToggle.value && items.length;

  return (
    <>
      <div
        className={classnames({
          json__item: true,
          'json__item--iterable': items.length,
        })}
        onClick={isExpandedToggle.toggle}
        style={{ paddingLeft: `${level * constants.marginLeft}px` }}
      >
        <div className="json__label">{label}</div>
        <div className="json__value">{`${openCharacter}${
          !isOpen && items.length ? '...' : ''
        }${isOpen ? '' : closeCharacter}`}</div>
      </div>
      {isOpen
        ? items.map((item, i) => {
            const { type, value } = getTypeAndValue(item.value, path);
            const nextPath = getNextPath(path, i, isArray, item.label, type);
            return (
              <JsonItem
                label={item.label}
                level={level + 1}
                key={i}
                options={options}
                path={nextPath}
                type={type}
                value={value}
              />
            );
          })
        : null}
      <div
        className="json__item"
        style={{ paddingLeft: `${level * constants.marginLeft}px` }}
      >
        {isOpen ? <div className="json__label">{closeCharacter}</div> : null}
      </div>
    </>
  );
};

export default JsonIterable;
