import { delimiter } from 'kfuse-constants';

import { SelectedFacetValues, TimeSeries } from 'types';
import {
  getFacetKey,
  Operator,
  parseFacetKey,
  parseMetricLabelQuery,
} from 'utils';
import { LabelCardinalityQueryProps } from './types';

export const sortLogsCardinalityRows = ({
  rows,
  cardinalityQuery,
}: {
  rows: TimeSeries[];
  cardinalityQuery: LabelCardinalityQueryProps;
}): {
  cardinalityRows: Array<any>;
  sortedKeys: string[];
} => {
  const { limit } = cardinalityQuery;
  const rowsWithValues = rows.map((row) => {
    return { ...row, Value: row.points[0]?.value };
  });
  const sortedData = rowsWithValues.sort((a, b) => {
    return limit.direction === 'topk'
      ? b['Value'] - a['Value']
      : a['Value'] - b['Value'];
  });

  let sortedKeysSliced = [];
  if (limit.count === 'all') {
    sortedKeysSliced = sortedData;
  } else {
    sortedKeysSliced = sortedData.slice(0, Number(limit.count));
  }

  const sortedKeys = sortedKeysSliced.map((row) => row['tags']['key']);
  return { cardinalityRows: sortedKeysSliced, sortedKeys };
};

export const getLogsLabelToSelectedFacetValuesAndFilterByFacets = (
  labels: string[],
  labelIndex: number,
): {
  selectedFacetValues: SelectedFacetValues;
  filterByFacets: string[];
} => {
  const selectedFacetValues: SelectedFacetValues = {};
  const filterByFacets: string[] = [];

  labels.forEach((l, i) => {
    if (i > labelIndex) return;
    const { label, value, operator } = parseMetricLabelQuery(l);
    if (!label || !value) return;
    const parsedFacet = parseFacetKey(label);
    if (operator === Operator.Regex || operator === Operator.NotRegex) {
      const { component, name, type } = parsedFacet;
      const facetLabel = `${type}${delimiter}${component}:${name}`;
      filterByFacets.push(`${facetLabel}${operator}"${value}"`);
      return;
    }

    const facetKey = getFacetKey({
      component: parsedFacet.component,
      name: parsedFacet.name,
      type: parsedFacet.type,
      displayName: '',
    });
    const facetWithValue = `${facetKey}${delimiter}${value}`;
    selectedFacetValues[facetWithValue] =
      operator === Operator.NotEqual ? 0 : 1;
  });

  return { selectedFacetValues, filterByFacets };
};

export const formatLogsCardinalityChartData = (
  points: TimeSeries['points'],
): { data: { count: number }[]; max: number } => {
  let max = 0;
  const data = points.map((point) => {
    max = Math.max(max, point.value);
    return { count: point.value };
  });
  return { data, max };
};
