import React, { useRef } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import { TraceErrorGroup } from 'types/TraceErrorGroup';
import ApmErrorsGroupTableChart from './ApmErrorsGroupTableChart';
import useRequest from 'hooks/useRequest';
import { ChartGridItem } from 'types/ChartGrid';
import aggregateTraceErrors from 'requests/aggregateTraceErrors';
import useTracesState from 'hooks/useTracesState';
import calcAutoRollUpInSeconds from 'utils/calcAutoRollUpInSeconds';

const ApmErrorGroupChartSensor = ({
  row,
  operation,
  measure,
  isServiceFromDatabasesList,
  serviceHash,
  rollUpInSeconds,
  tracesState,
}: {
  row: TraceErrorGroup;
  operation: string;
  measure: string;
  isServiceFromDatabasesList: boolean;
  serviceHash: string;
  rollUpInSeconds: number;
  tracesState: ReturnType<typeof useTracesState>;
}) => {
  const aggregateTraceErrorsRequest = useRequest(
    (args) =>
      aggregateTraceErrors(args).then((chartGridItem: ChartGridItem) => {
        return chartGridItem.keys.reduce((obj, rawKey) => {
          const key = rawKey.split('=')[1];
          return {
            ...obj,
            [key]: {
              keys: [rawKey],
              data: chartGridItem.data,
              timestamps: chartGridItem.timestamps,
            },
          };
        }, {});
      }),
    true,
    true,
  );
  const fetchedRef = useRef(false);
  const { errorGroupingKey } = row;

  const onChange = (isVisible: boolean) => {
    if (isVisible && !fetchedRef.current) {
      fetchedRef.current = true;
      aggregateTraceErrorsRequest.call({
        aggregation: operation,
        aggregationField: measure,
        errorGroupingKey,
        isServiceFromDatabasesList,
        groupBys: ['error_grouping_key'],
        serviceHash,
        rollUpSeconds:
          rollUpInSeconds || calcAutoRollUpInSeconds(tracesState.dateState[0]),
        tracesState,
      });
    }
  };

  const chartGridItemByGroupingKey =
    aggregateTraceErrorsRequest.result ||
    ({} as {
      [key: string]: ChartGridItem;
    });

  return (
    <VisibilitySensor onChange={onChange}>
      <ApmErrorsGroupTableChart
        chartGridItem={chartGridItemByGroupingKey[row.errorGroupingKey]}
        isLoading={aggregateTraceErrorsRequest.isLoading}
      />
    </VisibilitySensor>
  );
};

export default ApmErrorGroupChartSensor;
