import React, { ReactElement, useMemo } from 'react';

import { computeToplist } from './utils';

const TopList = ({
  data,
  height,
  width,
}: {
  data: Array<{ label: string; count: number; evaluatedValue?: string }>;
  height: number;
  width: number;
}): ReactElement => {
  const computedToplist = useMemo(
    () => computeToplist(data, height, width),
    [data, height, width],
  );

  if (!computedToplist) return null;

  return (
    <div className="flex--direction-col flex">
      {computedToplist.topList.map(({ label, readableText, percentage }) => {
        return (
          <div
            key={label}
            className="top-list__item"
            style={{ height: computedToplist.itemHeight, width: width }}
          >
            <div className="flex grow flex-row items-center text-center">
              <div
                className="top-list__item__content__count"
                style={{
                  width: computedToplist.maxTextWidth,
                  fontSize: computedToplist.fontSize,
                }}
              >
                {readableText}
              </div>
              <div
                className="top-list__item__content__label"
                style={{
                  width: percentage * (computedToplist.maxFillWidth / 100),
                  fontSize: computedToplist.fontSize,
                }}
              >
                {label}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TopList;
