import classNames from 'classnames';
import React from 'react';
import { formatNsWithRound, getUnit } from 'utils';
import { FormattedRumEventForFlameGraph } from './types';

export const getTicks = (
  niceUpperBound: number,
  tickSpacing: number,
): number[] => {
  const result = [];

  for (let i = 0; i < niceUpperBound / tickSpacing; i += 1) {
    result.push(i * tickSpacing);
  }

  return result;
};

type Props = {
  className?: string;
  containerRef?: React.RefObject<HTMLDivElement>;
  hoveredEvent?: FormattedRumEventForFlameGraph;
  minStartTimeNs: number;
  maxEndTimeNs: number;
  niceUpperBound: number;
  tickSpacing: number;
  width: number;
};

const RumFlameGraphTicks = ({
  className,
  containerRef,
  hoveredEvent,
  minStartTimeNs,
  maxEndTimeNs,
  niceUpperBound,
  tickSpacing,
  width,
}: Props) => {
  const diffInNs = maxEndTimeNs - minStartTimeNs;
  const unit = getUnit(diffInNs);
  const min = 0;

  const numTicks = isNaN(niceUpperBound) ? 0 : niceUpperBound / tickSpacing;

  const startTimeNs = hoveredEvent?.startTimeNs || 0;

  const translateX = hoveredEvent
    ? ((startTimeNs - minStartTimeNs) / niceUpperBound) * width
    : 0;

  return (
    <div
      className={classNames({
        flamegraph__ticks: true,
        relative: true,
        [className]: className,
      })}
    >
      {numTicks ? (
        new Array(numTicks).fill(null).map((_, i) => (
          <div className="rum-flamegraph__ticks__item" key={i}>
            <div className="flamegraph__ticks__item__label">
              <div className="flamegraph__ticks__item__label__text">
                {`${formatNsWithRound({
                  ns: min + i * tickSpacing,
                  unit: unit,
                  roundTo: 2,
                })}${unit}`}
              </div>
            </div>
            {i === numTicks - 1 ? (
              <div className="flamegraph__ticks__item__label flamegraph__ticks__item__label--right">
                <div className="flamegraph__ticks__item__label__text">
                  {`${formatNsWithRound({
                    ns: niceUpperBound,
                    unit,
                    roundTo: 2,
                  })}${unit}`}
                </div>
              </div>
            ) : null}
          </div>
        ))
      ) : (
        <div className="flamegraph__ticks__item">
          <div className="flamegraph__ticks__item__label">
            <div className="flamegraph__ticks__item__label__text">{'0ns'}</div>
          </div>
        </div>
      )}

      <div
        className="rum-flamegraph__cursor"
        style={{
          left: `${translateX}px`,
          height: `${(containerRef?.current?.clientHeight || 90) - 40}px`,
          display: hoveredEvent ? 'block' : 'none',
        }}
      />
    </div>
  );
};

export default RumFlameGraphTicks;
