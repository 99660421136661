import { AutocompleteOption } from 'components';
import { getOperatorInQuery, removeDuplicateByKeys } from 'utils';

import {
  FacetBase,
  FavoriteFacet,
  LabelsProps,
  OptionType,
  SearchItemProps,
} from 'types';

export const getFacetNamesOptions = (
  facetNames: Array<{ name: string }>,
  optionType: OptionType,
): AutocompleteOption[] => {
  const facetNamesOptions: AutocompleteOption[] = [];
  if (facetNames) {
    facetNames.forEach(
      ({
        component,
        source,
        name,
        type,
        displayName,
        group,
      }: FavoriteFacet) => {
        const displayNameWithGroup = group
          ? `${group}:@${displayName || name}`
          : `@${displayName || name}`;
        if (optionType === 'facet') {
          facetNamesOptions.push({
            label: displayNameWithGroup,
            value: `@${name}`,
            dataType: (optionType === 'facet' && type) || '',
            optionType,
            displayName: displayNameWithGroup,
            source,
          });
        } else {
          facetNamesOptions.push({
            label: `${component}:${name}`,
            value: `${component}:${name}`,
            dataType: type || '',
            optionType,
          });
        }
      },
    );
  }

  const uniqueFacetNamesOptions = removeDuplicateByKeys(facetNamesOptions, [
    'label',
    'dataType',
  ]);

  return uniqueFacetNamesOptions;
};

export const getFacetValuesOptions = (
  facetValues: { count: number; value: string }[],
): AutocompleteOption[] => {
  const facetValuesOptions: AutocompleteOption[] = [];
  if (facetValues) {
    facetValues.forEach(
      ({ count, value }: { count: number; value: string }) => {
        facetValuesOptions.push({
          count: count || 0,
          label: value,
          value: value,
          optionType: 'value',
        });
      },
    );
  }
  return facetValuesOptions;
};

export const isOptionsExist = (
  record: Array<any>,
  searchValue: string | undefined,
): boolean => {
  if (!searchValue) {
    return false;
  }
  return record.some((r) => r['value'] === searchValue);
};

/**
 * Convert component to autocomplete option
 * @param components
 * @returns {AutocompleteOption[]}
 */
export const getComponentOptions = (
  components: string[],
): AutocompleteOption[] => {
  const sortedComponents = components.sort((a, b) => (a > b ? 1 : -1));
  const options = [
    { label: 'Core', value: 'Core' },
    { label: 'Cloud', value: 'Cloud' },
    { label: 'Kubernetes', value: 'Kubernetes' },
  ];
  sortedComponents.forEach((component) => {
    if (!['Core', 'Cloud', 'Kubernetes'].includes(component)) {
      options.push({ label: component, value: component });
    }
  });
  return options;
};

/**
 * Parse operator from search term
 * @operator "=" | "!=" | ">" | "<" | ">=" | "<=" | "~="
 * @example
 * cluster-name="dem"
 * cluster-name!="obs"
 * cluster-name=~"^.*observes.*$""
 * cluster-name>="0.1"
 * cluster-name<="0.2"
 */
export const parseOperatorAndValue = (
  search: string,
  facetName: string,
): {
  parsedOperator: string;
  parsedValue: string;
} => {
  const startValueIndex = search.indexOf('"');
  if (startValueIndex === -1) {
    return { parsedOperator: '', parsedValue: '' };
  }
  const parsedOperator = search.substring(facetName.length, startValueIndex);
  let parsedValue = '';
  if (
    search[search.length - 1] === '"' &&
    startValueIndex != search.length - 1
  ) {
    parsedValue = search.substring(startValueIndex + 1, search.length - 1);
  }

  return { parsedOperator, parsedValue };
};

/**
 * Check facetname exist in search string
 * @param search string
 * @param facetName string
 * @returns boolean
 */
export const isFacetnameExist = (
  search: string,
  facetName: string,
): boolean => {
  const parsedFacetName = search.substring(0, facetName.length);
  return parsedFacetName === facetName;
};

/**
 * Check facetvalue exist in search string
 * @param search string
 * @param value string
 * @returns boolean
 */
export const isValueExist = (search: string, value: string): boolean => {
  const startValueIndex = search.indexOf('"');
  if (startValueIndex === -1 || search[search.length - 1] !== '"') {
    return false;
  }
  const parsedValue = search.substring(startValueIndex + 1, search.length);
  return parsedValue === value;
};

export const parseFilterByFacetQuery = (
  search: string,
  facetNames: AutocompleteOption[],
): SearchItemProps => {
  const operator = getOperatorInQuery(search);
  if (!operator) {
    return null;
  }

  const splitQuery = search.split(operator);
  const facetName = splitQuery[0];
  const value = splitQuery[1];

  if (!facetName) {
    return null;
  }
  const facetNameExist = facetNames.find((facet) => facet.value === facetName);
  if (!facetNameExist) {
    return null;
  }

  if (typeof value === 'string') {
    if (!value.startsWith('"') || !value.endsWith('"') || value.length === 1) {
      return null;
    }
    const parsedValue = value.substring(1, value.length - 1);
    if (!parsedValue) {
      return null;
    }
  }

  return {
    facetName,
    value: parsedValue,
    operator,
  };
};

/**
 * parse value from complete/incomplete search string
 * @param search string
 * @param facetName string
 * @param operatorSign string
 * @returns string
 * @example agent:body="dem" => dem
 * @example agent:body="dem => dem
 */
export const parseValueFromQuery = (search: string): string => {
  const startQuoteIndex = search.indexOf('"');
  if (startQuoteIndex === -1) {
    return '';
  }
  const endQuoteIndex = search.lastIndexOf('"');
  if (startQuoteIndex === endQuoteIndex) {
    return '';
  }

  const lastCropIndex = search.charAt(search.length - 1) === '"' ? 1 : 0;
  const parsedValue = search.substring(
    startQuoteIndex + 1,
    search.length - lastCropIndex,
  );
  return parsedValue;
};

export const mergeLabels = (labelsObject: LabelsProps): FacetBase[] => {
  if (labelsObject) {
    const { additional, cloud, core, kubernetes } = labelsObject;
    return [...core, ...cloud, ...kubernetes, ...(additional || [])];
  }
  return [];
};

export const sortAutocompleteOptions = (
  facetNames: any,
): AutocompleteOption[] => {
  const facetNamesOptions = getFacetNamesOptions(facetNames, 'facet');
  const sortedFacetNamesOptions = facetNamesOptions.sort((a, b) =>
    a.label > b.label ? 1 : -1,
  );

  return sortedFacetNamesOptions;
};

export const isSourceLabel = (facetName: string): boolean => {
  const splitFacetName = facetName.split(':');
  if (
    splitFacetName[0] === 'Core' ||
    splitFacetName[0] === 'Cloud' ||
    splitFacetName[0] === 'Kubernetes' ||
    splitFacetName[0] === 'Additional'
  ) {
    return true;
  }
  return false;
};

/**
 * Get las double quotes from search string
 * @param search string
 * @returns string
 * @example agent:body="dem" => "
 * @example agent:body="dem => "
 * @example agent:body=" =>
 * @example agent:body= =>
 */
export const getEndDoubleQuote = (search: string, value: string): string => {
  const lastChar = search.charAt(search.length - 1);
  // close double quote
  if (lastChar === '"' && value) {
    return '"';
  }

  // open double quote and value is empty
  if (lastChar === '"' && !value) {
    return '';
  }

  // open double quote and value is not empty
  if (lastChar !== '"' && value) {
    return '"';
  }

  return '';
};

/**
 * Convert array of string to object with boolean value
 * @param array string[]
 * @returns object
 */
export const convertArrayToObject = (
  array: string[],
): { [key: string]: boolean } => {
  const object: { [key: string]: boolean } = {};
  array.forEach((item) => {
    object[item] = true;
  });
  return object;
};
