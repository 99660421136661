import { Loader } from 'components';
import { colorsByLogLevel } from 'kfuse-constants';
import React from 'react';
import { Bar, ComposedChart } from 'recharts';
import { ChartGridItem } from 'types';

type Props = {
  chartGridItem: ChartGridItem;
  isLoading: boolean;
};

const ApmErrorsGroupTableChart = ({ chartGridItem, isLoading }: Props) => {
  const data = chartGridItem?.data || [];
  const key = chartGridItem?.keys.length ? chartGridItem.keys[0] : '';
  return (
    <Loader isLoading={isLoading}>
      <ComposedChart
        data={data}
        margin={{
          top: 20,
          right: 10,
          left: 0,
          bottom: 0,
        }}
        width={120}
        height={60}
      >
        <Bar
          dataKey={key}
          isAnimationActive={false}
          fill={colorsByLogLevel.error}
          stackId="a"
          type="monotone"
        />
      </ComposedChart>
    </Loader>
  );
};

export default ApmErrorsGroupTableChart;
