import ReactGridLayout from 'react-grid-layout';

import { DashboardPanelProps, DashboardPanelType } from 'types';

/**
 * This function generates an initial state for a dashboard panel.
 * @param {ReactGridLayout.Layout} itemLayout - An object representing the layout of the panel.
 * @param {DashboardPanelType} panelType - A string representing the type of the panel.
 */
export const getInitialEditPanelState = (
  itemLayout: ReactGridLayout.Layout,
  panelType: DashboardPanelType,
): DashboardPanelProps => {
  const newPanel: DashboardPanelProps = {
    id: '',
    title: '',
    type: panelType,
    gridPos: {
      i: panelType,
      x: itemLayout.x,
      y: itemLayout.y,
      w: itemLayout.w,
      h: itemLayout.h,
    },
    targets: [],
  };

  return newPanel;
};
