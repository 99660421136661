import parseUnit from 'parse-unit';

/**
 * Check value is a number
 * @param val
 * @returns boolean
 */
export const isNumber = (val: any): boolean => {
  return (typeof val === 'number' || typeof val === 'string') && !isNaN(val);
};

export const isNumberWithUnit = (s: string): boolean => {
  const [num, unit] = parseUnit(s);

  return typeof num === 'number' && !isNaN(num) && Boolean(unit);
};
