import {
  Loader,
  OverlayMessage,
  OverlayMessageProps,
  Paginator,
  Table,
  TableColumn,
  TableOptionsPopover,
  TableSearch,
  useColumnsState,
  usePaginator,
  useTableOptions,
  useTableSearch,
  useTableSort,
} from 'components';
import React, { useEffect, useMemo } from 'react';
import { DateSelection, SelectedFacetValuesByName, Service } from 'types';
import useDebouncedEffect from 'use-debounced-effect';
import { KpisByServiceName } from './types';
import useKpisByServiceNameRequest from './useKpisByServiceNameRequest';
import { ServicesTab } from './utils';

const getRowsUnionOfServiceByHashAndMetrics = (
  serviceByHash: Record<string, Service>,
  tableType: string,
) => {
  return Object.keys(serviceByHash)
    .filter((serviceHash) => serviceHash !== 'UNKNOWN')
    .filter((serviceHash) => {
      if (tableType === ServicesTab.db) {
        return serviceByHash[serviceHash].kfType === 'database';
      } else return serviceByHash[serviceHash].kfType === 'service';
    })
    .map((serviceHash) => {
      return {
        serviceHash,
        service: serviceByHash[serviceHash] || null,
        serviceName: serviceByHash[serviceHash].name || 'Unknown',
      };
    });
};

const getRowsWithIntersectionOfMetrics = (
  kpisByServiceName: KpisByServiceName,
  serviceByHash: Record<string, Service>,
) => {
  return Object.keys(kpisByServiceName)
    .filter(
      (serviceHash) => serviceHash !== 'UNKNOWN' && serviceByHash[serviceHash],
    )
    .map((serviceHash) => {
      return {
        serviceHash,
        service: serviceByHash[serviceHash] || null,
        serviceName: kpisByServiceName[serviceHash].serviceName,
      };
    });
};

type Props = {
  columnsState: ReturnType<typeof useColumnsState>;
  customerFilter: { key: string; value: string };
  date: DateSelection;
  kpisByServiceName: KpisByServiceName;
  kpisByServiceNameRequest: ReturnType<typeof useKpisByServiceNameRequest>;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  serviceByHash: Record<string, Service>;
  showInactiveServices: boolean;
  tableType: string;
  dataTestId?: string;
  servicesError?: {
    getServices: {
      message: string;
    };
  };
};

const getDefaultSortKey = (columns: TableColumn<any>[]) => {
  if (columns.length) {
    const isRequestsPresentInColumns = columns.some(
      (column) => column.key === 'requests',
    );
    if (isRequestsPresentInColumns) {
      return 'requests';
    }
    return columns[0].key;
  }
  return null;
};

const ServicesTable = ({
  columnsState,
  customerFilter,
  date,
  kpisByServiceName,
  kpisByServiceNameRequest,
  selectedFacetValuesByName,
  serviceByHash,
  showInactiveServices,
  tableType,
  dataTestId,
  servicesError,
}: Props) => {
  const { columns } = columnsState;

  const rows = useMemo(
    () =>
      showInactiveServices
        ? getRowsUnionOfServiceByHashAndMetrics(serviceByHash, tableType)
        : getRowsWithIntersectionOfMetrics(kpisByServiceName, serviceByHash),
    [kpisByServiceName, serviceByHash, tableType, showInactiveServices],
  );

  const { isLoading } = kpisByServiceNameRequest;
  const tableOptions = useTableOptions();
  const tableSearch = useTableSearch({ rows });
  const tableSort = useTableSort({
    columns,
    initialKey: getDefaultSortKey(columns),
    rows: tableSearch.searchedRows,
    intialAscOrder: false,
  });

  const paginator = usePaginator({
    initialNumberOfRowsPerPage: 50,
    key: 'services-table-pagination-v2',
    rows: tableSort.sortedRows,
  });

  useDebouncedEffect(
    () => {
      if (columnsState.isReady) {
        kpisByServiceNameRequest.call({
          customerFilter,
          columns,
          date,
          selectedColumns: columnsState.state.selectedColumns,
          selectedFacetValuesByName,
          tableType,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    { timeout: 400, ignoreInitialCall: false },
    [customerFilter, date, selectedFacetValuesByName],
  );

  const overlayMessageProps: OverlayMessageProps = useMemo(() => {
    const overlayConfig = servicesError.getServices
      ? {
          isActive: true,
          iconName: 'warning',
          message: <>{servicesError?.getServices?.message || 'Error.'}</>,
        }
      : { isActive: false };

    return overlayConfig;
  }, [servicesError?.getServices]);

  return (
    <Loader isLoading={isLoading && !paginator.paginatedRows.length}>
      <div className="table-toolbar">
        <div className="table-toolbar__left">
          <TableOptionsPopover
            columnsState={columnsState}
            shouldHideLinesToShow
            tableOptions={tableOptions}
          />
        </div>
        <TableSearch tableSearch={tableSearch} />
      </div>

      <OverlayMessage {...overlayMessageProps}>
        <Table
          className="table--bordered table--padded"
          columns={columns.filter(
            (column) => columnsState.state.selectedColumns[column.key],
          )}
          externalTableSort={tableSort}
          isSortingEnabled
          rows={paginator.paginatedRows}
          data-testid={dataTestId}
        />
      </OverlayMessage>

      <div className="table-footer">
        <Paginator paginator={paginator} />
      </div>
    </Loader>
  );
};

export default ServicesTable;
