import { FlamegraphTicks, Loader, SizeObserver } from 'components';
import { useRequest } from 'hooks';
import React, { useEffect } from 'react';
import { Span } from 'types';
import { getNiceScale } from 'utils';
import { MAX_SPANS_TO_RENDER } from './constants';
import TraceSidebarMainWaterfallItem from './TraceSidebarMainWaterfallItem';
import { WaterfallNode } from './types';

const addNodesToSubTree = ({ childrenBySpanId, node }: any) => {
  const { children, spanId } = node;
  (childrenBySpanId[spanId] ? Object.keys(childrenBySpanId[spanId]) : []).map(
    (childSpanId) => {
      const childNode: any = { spanId: childSpanId, children: [] };
      children.push(childNode);
      addNodesToSubTree({ childrenBySpanId, node: childNode });
    },
  );
};

const findMaxEndTime = ({
  childrenSpanIds,
  childrenBySpanId,
  maxEndTimeNs,
  spanById,
}: any) => {
  let nextChildrenSpanIds: string[] = [];
  childrenSpanIds.forEach((spanId: any) => {
    const { endTimeNs } = spanById[spanId];
    if (endTimeNs > maxEndTimeNs) {
      maxEndTimeNs = endTimeNs;
    }

    nextChildrenSpanIds = [
      ...nextChildrenSpanIds,
      ...(childrenSpanIds[spanId] || []),
    ];
  });

  if (nextChildrenSpanIds.length) {
    return findMaxEndTime({
      childrenSpanIds: nextChildrenSpanIds,
      childrenBySpanId,
      maxEndTimeNs,
      spanById,
    });
  }

  return maxEndTimeNs;
};

const getRows = (spans: Span[]) => {
  return new Promise((resolve) => {
    const spanById: { [key: string]: Span } = {};
    const childrenBySpanId: { [spanId: string]: { [spanId: string]: number } } =
      {};
    const parentsBySpanId: { [spanId: string]: { [spanId: string]: number } } =
      {};
    let minStartTimeNs: number = null;
    let maxEndTimeNs: number = null;
    let rootSpanId: string = null;

    let currentIndex = 0;

    const processChunk = () => {
      const chunkEnd = Math.min(
        spans.length,
        currentIndex + MAX_SPANS_TO_RENDER,
      );
      for (let i = currentIndex; i < chunkEnd; i++) {
        const { endTimeNs, parentSpanId, rootSpan, spanId, startTimeNs } =
          spans[i];

        spanById[spanId] = spans[i];

        if (rootSpan) {
          rootSpanId = spanId;
        }

        if (parentSpanId && parentSpanId !== '0') {
          if (!childrenBySpanId[parentSpanId]) {
            childrenBySpanId[parentSpanId] = {};
          }
          childrenBySpanId[parentSpanId][spanId] = 1;

          if (!parentsBySpanId[spanId]) {
            parentsBySpanId[spanId] = {};
          }
          parentsBySpanId[spanId][parentSpanId] = 1;
        }

        if (minStartTimeNs === null || startTimeNs < minStartTimeNs) {
          minStartTimeNs = startTimeNs;
        }

        if (maxEndTimeNs === null || endTimeNs > maxEndTimeNs) {
          maxEndTimeNs = endTimeNs;
        }
      }

      currentIndex += MAX_SPANS_TO_RENDER;

      if (currentIndex < spans.length) {
        requestAnimationFrame(processChunk);
      } else {
        finalizeProcessing();
      }
    };

    function finalizeProcessing() {
      const childrenByMissingParentSpanId: { [key: string]: string[] } = {};
      const processSpans = (
        spans: Span[],
        index = 0,
        chunkSize = MAX_SPANS_TO_RENDER,
      ) => {
        const totalSpans = spans.length;
        if (index >= spans.length) {
          return;
        }

        for (let i = index; i < Math.min(index + chunkSize, totalSpans); i++) {
          const span = spans[i];
          if (
            !span.rootSpan &&
            span.parentSpanId &&
            !spanById[span.parentSpanId]
          ) {
            const { parentSpanId, spanId } = span;
            if (!childrenByMissingParentSpanId[parentSpanId]) {
              childrenByMissingParentSpanId[parentSpanId] = [];
            }
            childrenByMissingParentSpanId[parentSpanId].push(spanId);
          }
        }

        requestAnimationFrame(() =>
          processSpans(spans, index + chunkSize, chunkSize),
        );
      };
      processSpans(spans);

      const childrenByMissingParentSpanIdKeys = Object.keys(
        childrenByMissingParentSpanId,
      );

      if (!rootSpanId && spans.length) {
        const onlyRootSpanMissing =
          childrenByMissingParentSpanIdKeys.length === 1;
        const spanId = onlyRootSpanMissing
          ? childrenByMissingParentSpanIdKeys[0]
          : '0';

        const rootSpan = {
          attributes: {
            isGhostSpan: true,
          },
          spanId,
          traceId: spans[0].traceId,
          startTimeNs: minStartTimeNs,
          endTimeNs: maxEndTimeNs,
          latency: 0,
          parentSpanId: '',
          rootSpan: true,
        };

        spanById[spanId] = rootSpan;
        rootSpanId = spanId;

        if (onlyRootSpanMissing) {
          delete childrenByMissingParentSpanId[
            childrenByMissingParentSpanIdKeys[0]
          ];
        }
      }

      Object.keys(childrenByMissingParentSpanId).forEach((missingSpanId) => {
        const childrenSpanIds = childrenByMissingParentSpanId[missingSpanId];
        const childrenSpans = childrenSpanIds.map((spanId) => spanById[spanId]);

        const startTimeNs = Math.min(
          ...childrenSpans.map((span) => span.startTimeNs),
        );

        const maxEndTimeNs = Math.max(
          ...childrenSpans.map((span) => span.endTimeNs),
        );

        const endTimeNs = findMaxEndTime({
          childrenSpanIds,
          childrenBySpanId,
          maxEndTimeNs,
          spanById,
        });

        const parentSpanId = rootSpanId;

        const ghostSpan = {
          attributes: {
            isGhostSpan: true,
          },
          spanId: missingSpanId,
          startTimeNs,
          endTimeNs,
          latency: 0,
          parentSpanId,
          traceId: spans[0].traceId,
        };

        spanById[missingSpanId] = ghostSpan;

        if (!childrenBySpanId[parentSpanId]) {
          childrenBySpanId[parentSpanId] = {};
        }

        childrenBySpanId[parentSpanId][missingSpanId] = 1;
      });

      const rootSpans = [rootSpanId];

      const tree: WaterfallNode[] = [];

      rootSpans.forEach((spanId) => {
        const node: WaterfallNode = { spanId, children: [] };
        addNodesToSubTree({ childrenBySpanId, node });

        tree.push(node);
      });

      resolve({
        minStartTimeNs,
        maxEndTimeNs,
        spanById,
        tree,
      });
    }

    requestAnimationFrame(processChunk);
  });
};

type Props = {
  clickedSpanId?: string;
  getColor: (span: Span) => string;
  setClickedSpanId: (spanId: string) => void;
  spans: Span[];
};

const TraceSidebarMainWaterfall = ({
  clickedSpanId,
  getColor,
  setClickedSpanId,
  spans,
}: Props) => {
  const processRows = useRequest(getRows);

  useEffect(() => {
    processRows.call(spans);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spans]);

  const rows = processRows.result as {
    maxEndTimeNs: number;
    minStartTimeNs: number;
    spanById: { [key: string]: Span };
    tree: WaterfallNode[];
  };

  if (processRows.isLoading) {
    return <Loader className="h-full" isLoading />;
  }

  if (!rows) {
    return null;
  }

  const { maxEndTimeNs, minStartTimeNs, spanById, tree } = rows;

  const diffInNs = maxEndTimeNs - minStartTimeNs;
  const min = 0;
  const max = diffInNs;

  return (
    <SizeObserver className="trace-sidebar__main__waterfall">
      {({ width }) => {
        const maxTicks = Math.floor(width / 100);
        const { niceUpperBound, tickSpacing } = getNiceScale(
          min,
          max,
          maxTicks,
        );
        if (width) {
          return (
            <div className="trace-sidebar__main__waterfall__inner">
              <div className="trace-sidebar__main__waterfall__ticks">
                <FlamegraphTicks
                  minStartTimeNs={minStartTimeNs}
                  maxEndTimeNs={maxEndTimeNs}
                  niceUpperBound={niceUpperBound}
                  tickSpacing={tickSpacing}
                  width={width - 24}
                />
              </div>
              <div className="trace-sidebar__main__waterfall__items">
                {tree.map((node) => (
                  <TraceSidebarMainWaterfallItem
                    clickedSpanId={clickedSpanId}
                    getColor={getColor}
                    key={node.spanId}
                    minStartTimeNs={minStartTimeNs}
                    niceUpperBound={niceUpperBound}
                    node={node}
                    setClickedSpanId={setClickedSpanId}
                    spanById={spanById}
                    width={width - 24}
                  />
                ))}
              </div>
            </div>
          );
        }

        return null;
      }}
    </SizeObserver>
  );
};

export default TraceSidebarMainWaterfall;
