import queryEventService from 'requests/queryEventService';
import { SelectedFacetValuesByName } from 'types';
import { buildKubeFilterVariable } from './utils/buildKubeFilter';

type Args = {
  entityType: string;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  fields?: Array<
    | 'tags'
    | 'labels'
    | 'annotations'
    | `${'tags' | 'labels' | 'annotations'}.${
        | 'facetKey'
        | 'facetValue'
        | 'count'}`
  >;
  selectors?: {
    tags?: Array<string>;
    labels?: Array<string>;
    annotations?: Array<string>;
  };
};

type KubeFacetCountsResponse = {
  kubeFacetCounts: {
    tags?: Array<{
      facetKey: string;
      facetValue: string;
      count: number;
    }>;
    labels?: Array<{
      facetKey: string;
      facetValue: string;
      count: number;
    }>;
    annotations?: Array<{
      facetKey: string;
      facetValue: string;
      count: number;
    }>;
  };
};

const kubeLabelNames = async (
  {
    entityType,
    selectedFacetValuesByName,
    fields = ['tags', 'labels', 'annotations'],
    selectors = {
      tags: [],
      labels: [],
      annotations: [],
    },
  }: Args,
  init?: RequestInit,
): Promise<KubeFacetCountsResponse> => {
  const tagSelector = selectors.tags?.length ? { keys: selectors.tags } : {};
  const labelSelector = selectors.labels?.length
    ? { keys: selectors.labels }
    : {};
  const annotationSelector = selectors.annotations?.length
    ? { keys: selectors.annotations }
    : {};
  const grouped = fields
    .map((field) => field.split('.'))
    .reduce<Record<string, Array<string>>>((accum, [first, second]) => {
      if (accum[first]) {
        accum[first].push(second);
      } else if (second) {
        accum[first] = [second];
      } else {
        accum[first] = [];
      }

      return accum;
    }, {});

  return queryEventService<Array<any>, 'kubeFacetCounts'>(
    `
    query GetKubeLabelNames($entityType: KubeEntityType!, $filter: KubeFilter, $tagSelector: KubeFacetKeySelector, $labelSelector: KubeFacetKeySelector, $annotationSelector: KubeFacetKeySelector) {
      kubeFacetCounts (
        entityType: $entityType
        filter: $filter
        selector: {
          tags: $tagSelector,
          labels: $labelSelector,
          annotations: $annotationSelector
        }
        ) {
${Object.entries(grouped)
  .map(([key, value]): [string, string[]] => {
    if (value.length) {
      return [key, value];
    }

    return [key, ['facetKey', 'facetValue', 'count']];
  })
  .map(([key, value]) => {
    return `${key}{${value.join(',')}}`;
  })}
        }
    }
  `,
    {
      entityType,
      filter: buildKubeFilterVariable([selectedFacetValuesByName]),
      tagSelector,
      labelSelector,
      annotationSelector,
    },
    init,
  );
};

export default kubeLabelNames;
