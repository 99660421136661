import React, { ReactNode } from 'react';
import {
  TableOptionsPopover,
  useColumnsState,
  useTableOptions,
} from './TableOptionsPopover';
import { TableSearch, useTableSearch } from './TableSearch';

type Props = {
  children?: ReactNode;
  columnsState: ReturnType<typeof useColumnsState>;
  tableOptions: ReturnType<typeof useTableOptions>;
  tableSearch?: ReturnType<typeof useTableSearch>;
  tableOptionsClassName?: string;
};

const TableHeader = ({
  children,
  columnsState,
  tableOptions,
  tableSearch,
  tableOptionsClassName,
}: Props) => {
  return (
    <div className="table-toolbar">
      <div className="table-toolbar__left">
        <TableOptionsPopover
          popOverClassName={tableOptionsClassName}
          columnsState={columnsState}
          shouldHideLinesToShow
          tableOptions={tableOptions}
        />
      </div>
      {tableSearch ? (
        <TableSearch tableSearch={tableSearch} dataTestId="table-search" />
      ) : null}
      {children ? <div className="table-toolbar__right">{children}</div> : null}
    </div>
  );
};

export default TableHeader;
