import classNames from 'classnames';
import {
  AutocompleteOption,
  Button,
  TooltipTrigger,
  useToaster,
} from 'components';
import { useMetricsQueryStateV2 } from 'hooks';
import React, { ReactElement } from 'react';
import { Code, XCircle } from 'react-feather';
import { MdTune } from 'react-icons/md';
import { ExplorerQueryProps, FunctionName } from 'types/MetricsQueryBuilder';
import {
  buildPromqlWithFunctions,
  decodePromqlToReadable,
  getMetricsExplorerDefaultQuery,
  parsePromqlAndBuildQuery,
} from 'utils';

import MetricsQueryBuilderCustomStep from './MetricsQueryBuilderCustomStep';
import MetricsQueryBuilderInput from './MetricsQueryBuilderInput';
import MetricsQueryBuilderMetric from './MetricsQueryBuilderMetric';
import MetricsQueryBuilderSeries from './MetricsQueryBuilderSeries';
import MetricsQueryBuilderFunctions from './MetricsQueryBuilderFunctions';

const MetricsQueryBuilder = ({
  onlyOneFunction,
  blockedFunctionsCategories = [],
  blockedFunctionsNames,
  customAggregateFunctions,
  editableMetrics,
  queries,
  metricsQueryState,
  ComponentAfterFrom,
}: {
  onlyOneFunction?: boolean;
  blockedFunctionsCategories?: string[];
  blockedFunctionsNames?: Array<FunctionName>;
  customAggregateFunctions?: AutocompleteOption[];
  editableMetrics: boolean;
  queries: ExplorerQueryProps[];
  metricsQueryState: ReturnType<typeof useMetricsQueryStateV2>;
  ComponentAfterFrom?: ReactElement;
}): ReactElement => {
  const { addToast } = useToaster();
  const {
    date,
    toggleQueryAndCallOnePromqlQuery,
    removeQuery,
    updateQuery,
    updateParsedQuery,
    loadInitialLabelsAndValues,
    loadLabelList,
  } = metricsQueryState;

  const handleEditPromql = (query: ExplorerQueryProps, queryIndex: number) => {
    if (!query.showInput) {
      const promql = decodePromqlToReadable(buildPromqlWithFunctions(query));
      updateQuery(queryIndex, 'showInput', true);
      updateQuery(queryIndex, 'promql', promql);
    } else {
      if (query.promql === '') {
        const defaultQuery = getMetricsExplorerDefaultQuery('');
        updateParsedQuery(queryIndex, '', defaultQuery.functions, []);
        updateQuery(queryIndex, 'showInput', false);
        return;
      }

      const { queries, error } = parsePromqlAndBuildQuery([query.promql]);
      if (queries.length > 0) {
        const { metric, series, functions } = queries[0];
        updateParsedQuery(queryIndex, metric, functions, series);
        updateQuery(queryIndex, 'showInput', false);
        loadLabelList([], metric, -1);
        loadInitialLabelsAndValues(metric, series);
      } else {
        addToast({ status: 'error', text: error });
      }
    }
  };

  return (
    <>
      {queries.map((query: ExplorerQueryProps, index: number) => {
        return (
          <div
            key={query.queryKey}
            className="flex justify-between flex-1 flex-grow flex-wrap"
            data-testid="metric-query-builder"
          >
            {query.showInput ? (
              <MetricsQueryBuilderInput
                metricsQueryState={metricsQueryState}
                query={query}
                queryIndex={index}
              />
            ) : (
              <div className="search__button-group">
                <div
                  className="button-group"
                  style={{
                    width: Math.max(200, query.metric.length * 7.5 + 32),
                  }}
                >
                  <div className="button-group__item button-group__item--unpadded">
                    <TooltipTrigger
                      tooltip={query.isActive ? 'Hide Query' : 'Show Query'}
                    >
                      <div
                        className={classNames({
                          'metrics__query-builder__query-item__query-key': true,
                          'metrics__query-builder__query-item__query-key--inactive':
                            !query.isActive,
                        })}
                        onClick={() =>
                          toggleQueryAndCallOnePromqlQuery({
                            index,
                            type: 'query',
                          })
                        }
                      >
                        {query.queryKey}
                      </div>
                    </TooltipTrigger>
                  </div>
                  <div className="button-group__item button-group__item--unpadded w-full">
                    <MetricsQueryBuilderMetric
                      editableMetrics={editableMetrics}
                      metric={query.metric}
                      queryIndex={index}
                      metricsQueryState={metricsQueryState}
                    />
                  </div>
                </div>
                <div className="search__button-group__divider">
                  <div />
                </div>
                <MetricsQueryBuilderSeries
                  metricName={query.metric}
                  queryIndex={index}
                  metricsQueryState={metricsQueryState}
                  series={query.series}
                />
                {ComponentAfterFrom && ComponentAfterFrom}
                <MetricsQueryBuilderFunctions
                  onlyOneFunction={onlyOneFunction}
                  blockedFunctionsCategories={blockedFunctionsCategories}
                  blockedFunctionsNames={blockedFunctionsNames}
                  customAggregateFunctions={customAggregateFunctions}
                  query={query}
                  queryIndex={index}
                  metricsQueryState={metricsQueryState}
                />
              </div>
            )}
            <div className="metrics__query-builder__query-action">
              <MetricsQueryBuilderCustomStep
                date={date}
                metricsQueryState={metricsQueryState}
                query={query}
                queryIndex={index}
              />
              <TooltipTrigger
                tooltip={query.showInput ? 'Show Builder' : 'Show Promql'}
              >
                <Button
                  onClick={() => handleEditPromql(query, index)}
                  className="h-full px-1.5"
                  variant="icon"
                  size="sm"
                  data-testid="toggle-promql-button"
                >
                  {query.showInput ? <MdTune size={16} /> : <Code size={16} />}
                </Button>
              </TooltipTrigger>
              {queries.length > 1 && (
                <TooltipTrigger tooltip="Delete">
                  <Button
                    className="metrics__query-builder__query-action__icon--delete h-full px-1.5"
                    variant="icon"
                    size="sm"
                  >
                    <XCircle onClick={() => removeQuery(index)} size={16} />
                  </Button>
                </TooltipTrigger>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MetricsQueryBuilder;
