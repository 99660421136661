import dayjs from 'dayjs';
import {
  DateSelection,
  FacetRegexTerm,
  SelectedFacetValuesByName,
  TimeSeries,
} from 'types';
import { onPromiseError } from 'utils';
import queryRumService from './queryRumService';
import { buildRumFilter } from './utils';
import { RumEventType } from '../types';

type Args = {
  applicationFilter: string;
  labelName: string;
  date: DateSelection;
  eventType: RumEventType;
  facetRegex?: FacetRegexTerm[];
  idSearch?: string;
  selectedFacetValuesByName: SelectedFacetValuesByName;
};

const minMaxForLabel = async ({
  applicationFilter,
  labelName,
  date,
  eventType,
  facetRegex,
  idSearch,
  selectedFacetValuesByName,
}: Args): Promise<TimeSeries[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  return queryRumService<TimeSeries[], 'aggregateTable'>(`
  query {
    aggregateTable(
        eventType: ${eventType},
        timestamp: "${endTime.format()}",
        durationSecs: ${durationSecs},
        aggregates: [
            {
                field: "${labelName}",
                aggregation: "min",
              }
              {
                field: "${labelName}",
                aggregation: "max",
              }
        ]
       filter: ${buildRumFilter({ applicationFilter, facetRegex, idSearch, selectedFacetValuesByName })},
        groupBy: []
      ) {
        aggregates
      }
  }
  `).then((data) => data?.aggregateTable || [], onPromiseError);
};

export default minMaxForLabel;
