import { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getDateFromRange } from 'screens/Dashboard/utils';
import { CardinalityQueryProps } from 'screens/MetricsCardinality/types';
import { extractPathNameFromURL, parseUrlParamByKey } from 'utils';

const defaultCardinalityQuery: CardinalityQueryProps = {
  type: 'label',
  metric: '',
  labels: ['=""'],
  limit: { count: '10', direction: 'topk', byKey: 'seriesCount' },
};

const useMetricsCardinalityPageState = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dateState = useState(
    parseUrlParamByKey('date') || getDateFromRange('now-5m', 'now'),
  );
  const cardinalityQueryState = useState<CardinalityQueryProps>(
    parseUrlParamByKey('cardinalityQuery') || defaultCardinalityQuery,
  );

  // Get dependencies for determining when a url change should happen
  const dependenciesForWriteStateToUrl = useMemo(
    () => [dateState[0], cardinalityQueryState[0]],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateState[0], cardinalityQueryState[0]],
  );

  // Should be called on state change when on the Traces page
  const writeStateToUrl = () => {
    params.set('date', JSON.stringify(dateState[0]));
    params.set('cardinalityQuery', JSON.stringify(cardinalityQueryState[0]));
    const navigateUrl = `${extractPathNameFromURL()}?${params.toString()}`;
    navigate(navigateUrl, { replace: true });
  };

  return {
    cardinalityQueryState,
    dateState,
    dependenciesForWriteStateToUrl,
    writeStateToUrl,
  };
};

export default useMetricsCardinalityPageState;
