import { useRequest } from 'hooks';
import { useEffect, useState } from 'react';
import getSLOQueryTemplates from 'requests/getSLOQueryTemplates';

const useSLOQueryTemplates = () => {
  const [queryTemplates, setQueryTemplates] = useState({
    latency: {
      sliBadEventsPromQuery: null,
      sliTotalEventsPromQuery: null,
      sliBadEventsPromRecQuery: null,
      sliTotalEventsPromRecQuery: null,
      sloBudgetRemaining: null,
      sloCurrentBurnRate: null,
      sloStatusQuery: null,
    },
    availability: {
      sliBadEventsPromQuery: null,
      sliTotalEventsPromQuery: null,
      sliBadEventsPromRecQuery: null,
      sliTotalEventsPromRecQuery: null,
      sloBudgetRemaining: null,
      sloCurrentBurnRate: null,
      sloStatusQuery: null,
    },
    metrics: {
      sliBadEventsPromQuery: null,
      sliTotalEventsPromQuery: null,
      sliBadEventsPromRecQuery: null,
      sliTotalEventsPromRecQuery: null,
      sloBudgetRemaining: null,
      sloCurrentBurnRate: null,
      sloStatusQuery: null,
      sloAggregate: null,
      sloAggregateBy: null,
    },
    queryTemplatesFetched: false,
  });

  const sloQueryTemplateRequest = useRequest(getSLOQueryTemplates, true, true);

  const loadSLOTemplates = async () => {
    const [latencyResponse, availabilityResponse, metricsResponse] =
      await Promise.all([
        sloQueryTemplateRequest.call('Latency'),
        sloQueryTemplateRequest.call('Availability'),
        sloQueryTemplateRequest.call('Metrics'),
      ]);
    setQueryTemplates({
      latency: latencyResponse,
      availability: availabilityResponse,
      metrics: metricsResponse,
      queryTemplatesFetched: true,
    });
  };

  useEffect(() => {
    loadSLOTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    queryTemplates,
    loadSLOTemplates,
    error: sloQueryTemplateRequest?.error,
  };
};

export default useSLOQueryTemplates;
