import { dateTimeFormat } from 'kfuse-constants/dateTimeFormat';
import {
  DashboardPanelOverrideProps,
  DashboardPanelType,
  DashboardStreamType,
} from 'types/Dashboard';
import { sortAndLimitTimeseriesMetrics } from 'utils/DataTransformer';

export const defaultTransformations = ({
  dataFormat,
  stream,
  unit,
  refId,
}: {
  dataFormat: DashboardPanelType;
  stream: DashboardStreamType;
  unit?: string;
  refId?: string;
}) => {
  const overrides: DashboardPanelOverrideProps[] = [];
  if (unit && refId) {
    const valueWithRef = `Value #${refId}`;
    overrides.push({
      matcher: { id: 'byName', options: valueWithRef },
      properties: [{ id: 'unit', value: unit, decimals: 2 }],
    });
  }
  const transformations = [
    { id: 'merge', options: null },
    {
      id: 'formatTime',
      options: {
        outputFormat: dateTimeFormat,
        timeField: 'Time',
        useTimezone: false,
      },
    },
    {
      id: 'organize',
      options: {
        excludeByName: { Time: true },
        renameByName: {},
        indexByName: { Time: 0 },
      },
    },
    { id: 'override', options: overrides },
  ];

  if (
    dataFormat === DashboardPanelType.PIECHART ||
    dataFormat === DashboardPanelType.TOP_LIST ||
    dataFormat === DashboardPanelType.GRAFANA_POLYSTAT_PANEL
  ) {
    if (stream === DashboardStreamType.METRIC) {
      transformations.unshift({
        id: 'sortAndLimitTimeseries',
        func: (dataFrame) =>
          sortAndLimitTimeseriesMetrics(dataFrame, { direction: 'topk' }),
      });
    }

    const organizeTransformation = transformations.find(
      (transformation) => transformation.id === 'organize',
    );
    if (organizeTransformation) {
      organizeTransformation.options.excludeByName = {
        ...organizeTransformation.options.excludeByName,
        Time: true,
      };
    }
  }

  if (dataFormat === DashboardPanelType.PIECHART) {
    transformations.push({ id: 'toPieChart', options: null });
  }

  if (dataFormat === DashboardPanelType.TOP_LIST) {
    transformations.push({ id: 'toTopList', options: { unit, decimals: 2 } });
  }

  if (dataFormat === DashboardPanelType.GRAFANA_POLYSTAT_PANEL) {
    transformations.push({ id: 'toHostmap', options: null });
  }

  if (dataFormat === DashboardPanelType.TREEMAP) {
    transformations.push({ id: 'toTreeMap', options: { unit } });
  }

  if (dataFormat === DashboardPanelType.GEOMAP) {
    transformations.push({ id: 'toGeomap', options: { unit } });
  }

  return transformations;
};
