import React, { useMemo } from 'react';

import { ReplicaSet } from '../types';
import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellTimestamp,
  KubeTableCell,
  KubeTableFilterCell,
} from './Components';
import KubernetesTable from './KubernetesTable';

import { useEntitiesCount, useEntitiesData } from './useEntitiesData';
import { useKubernetesController } from '../KubernetesController';
import { entityMappedToGraphQL } from '../utils';

export default function KubernetesTableForReplicaSet() {
  const { entitiesType, facets, page, limit } = useKubernetesController();

  const filters = useMemo(() => [facets], [facets]);
  const [rows] = useEntitiesData<{ replicaSet: ReplicaSet }>({
    filters,
    page,
    limit,
  });
  const [count] = useEntitiesCount({
    entitiesType,
    facets,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'kube_replica_set',
          label: 'Replica Set',
          renderCell(prop) {
            const replicaSetName = prop.row.replicaSet.metadata.name;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name pb-1.5 pt-1.5"
                tooltipText={replicaSetName}
              >
                {replicaSetName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell(prop) {
            return <KubeCellCluster {...prop} />;
          },
        },
        {
          key: 'metadata.namespace',
          label: 'Namespace',
          renderCell(prop) {
            return <KubeCellNamespace {...prop} />;
          },
        },
        {
          key: 'kube_deployment',
          label: 'Deployment',
          renderCell({ row, ...prop }) {
            const entityMapped = entityMappedToGraphQL(entitiesType);
            const deploymentNameKey = 'kube_deployment';
            const deploymentNameTag: string = row[entityMapped].tags.find(
              (tag: string) => tag.startsWith(deploymentNameKey),
            );
            const deploymentName = deploymentNameTag.substring(
              deploymentNameKey.length + 1,
            );

            return (
              <KubeTableFilterCell
                facetKey={deploymentNameKey}
                facetValue={deploymentName}
                className="kubernetes__table__cell__cluster-name truncate w-full"
                row={row}
                {...prop}
              />
            );
          },
        },
        {
          key: 'metadata.creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            return (
              <KubeCellTimestamp
                {...prop}
                timestamp={prop.row.replicaSet.metadata.creationTimestamp}
              />
            );
          },
        },
        {
          key: 'replicas',
          label: 'Current',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.replicaSet.replicas}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'replicasDesired',
          label: 'Desired',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.replicaSet.replicasDesired}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'readyReplicas',
          label: 'Ready',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.replicaSet.readyReplicas}
              </KubeTableCell>
            );
          },
        },
      ]}
    />
  );
}
