import { TooltipTrigger } from 'components';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import {
  MdModeEdit as EditIcon,
  MdNotificationsOff as MuteIcon,
} from 'react-icons/md';
import { SilencesProps } from './types';

const AlertsSilencesActions = ({
  silence,
  onEditSilence,
  onUnmuteSilence,
}: {
  silence: SilencesProps;
  onEditSilence: (silence: SilencesProps) => void;
  onUnmuteSilence: (silence: SilencesProps) => void;
}): ReactElement => {
  const status = silence.status.state;
  return (
    <div
      className="table__row__actions"
      style={{
        '--table-actions-width': status === 'active' ? '74px' : '41px',
      }}
    >
      <div className="table__row__actions--hidden">
        <div className="table__row__actions__slider">
          <div className="alerts__contacts__table__actions">
            <Link to={`/alerts/silences/${silence.id}`}>
              <div
                className="table__cell__actions__item--blue"
                onClick={() => onEditSilence(silence)}
              >
                <TooltipTrigger tooltip="Recreate">
                  <EditIcon
                    className="alerts__contacts__table__actions__icon--blue"
                    size={18}
                  />
                </TooltipTrigger>
              </div>
            </Link>
            {status === 'active' && (
              <div
                className="table__cell__actions__item--blue"
                onClick={() => onUnmuteSilence(silence)}
              >
                <TooltipTrigger tooltip="Unmute">
                  <MuteIcon
                    className="alerts__contacts__table__actions__icon--blue"
                    size={18}
                    style={{ opacity: 0.5 }}
                  />
                </TooltipTrigger>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertsSilencesActions;
