import { tryJsonParse } from 'utils';
import { JsonType } from './types';

const getType = (a: any): JsonType => {
  if (a === undefined) {
    return JsonType.undefined;
  }

  if (a === null) {
    return JsonType.null;
  }

  if (Array.isArray(a)) {
    return JsonType.array;
  }

  if (typeof a === 'string') {
    return JsonType.string;
  }

  if (typeof a === 'number') {
    return JsonType.number;
  }

  if (typeof a === 'object') {
    return JsonType.object;
  }

  if (typeof a === 'boolean') {
    return JsonType.boolean;
  }
};

const getTypeAndValue = (a: any, path: string) => {
  const value =
    typeof a === 'string' && path.indexOf('hc_attributes') === -1
      ? tryJsonParse(a)
      : a;
  const type = getType(value);
  return {
    type,
    value,
  };
};

export default getTypeAndValue;
