import { sortBy, sortedUniqBy } from 'lodash';
import { ValueCount } from 'types';
import isNullOrUndefined from 'utils/isNullOrUndefined';
import { formatDurationNs } from 'utils/timeNs';
import { RumEvent, RumEventData, RumEventType } from '../types';

export const ONE_SEC_IN_NS = 1000000000;
export const ONE_SEC_IN_MS = 1000;
export const ONE_MS_IN_NS = 1000000;

export const convertMsToNs = (ms: number): number => ms * ONE_MS_IN_NS;

export const convertMsToSec = (ms: number): number => ms / ONE_SEC_IN_MS;

export const parseDurationField = (duration: string): number => {
  if (!duration) return 0;

  return parseInt(duration) || 0;
};

export const getFilterOptions = (
  rumLabelsResult: ValueCount[],
  value: string | null,
): {
  label: string;
  value: string | null;
}[] => {
  const options = [
    { label: 'All', value: null as string | null },
    value ? { label: value, value } : null,
  ];

  const filteredList = rumLabelsResult
    .filter((item) => item.value !== 'null')
    .map((item) => {
      return { label: item.value, value: item.value };
    });

  const optionsWithFilteredList = sortBy(
    [...options, ...filteredList].filter(Boolean),
    'label',
  );

  return sortedUniqBy(optionsWithFilteredList, 'label');
};

export const formatDurationFromNsString = (
  duration: string | number,
): string => {
  if (duration === 0) return '0';
  if (!duration) return '-';

  const convertedDuration = Number(duration);
  const formattedDuration =
    isNaN(convertedDuration) || isNullOrUndefined(convertedDuration)
      ? '-'
      : formatDurationNs(convertedDuration, 1, 2);

  return formattedDuration;
};

export const getDurationField = (eventType: RumEventType) => {
  switch (eventType) {
    case RumEventType.VIEW:
      return 'view.time_spent';
    case RumEventType.ACTION:
      return 'action.loading_time';
    case RumEventType.LONGTASK:
      return 'long_task.duration';
    case RumEventType.SESSION:
      return 'session.time_spent';
    case RumEventType.RESOURCE:
      return 'resource.duration';
    default:
      return null;
  }
};

export const extractStartAndTimeUnixFromEvent = (event: RumEvent) => {
  const eventData = event.data as RumEventData;
  const durationField = getDurationField(event.eventType);
  const durationSecs = Math.ceil(
    parseDurationField(
      durationField ? eventData[durationField as keyof RumEventData] : '0',
    ) / ONE_SEC_IN_NS,
  );

  const startTimeUnix = Math.floor(convertMsToSec(event.time));
  const endTimeUnix = Math.ceil(convertMsToSec(event.time)) + durationSecs;

  return { startTimeUnix, endTimeUnix };
};
