import { Button, TooltipTrigger } from 'components';
import React, { ReactElement } from 'react';
import { Plus } from 'react-feather';
import { parseMetricLabelQuery } from 'utils';

import TracesCardinalityLabelValue from './TracesCardinalityLabelValue';
import useTracesCardinality from './useTracesCardinality';

const TracesCardinalityLabelAddButton = ({
  propertyKey,
  tracesCardinality,
}: {
  propertyKey: 'hcLabels' | 'lcLabels';
  tracesCardinality: ReturnType<typeof useTracesCardinality>;
}): ReactElement => {
  const { loadTraceLabels, setTracesCardinalityQuery, tracesCardinalityQuery } =
    tracesCardinality;
  return (
    <>
      <div className="button-group">
        <TooltipTrigger className="flex" tooltip="Add New Label">
          <Button
            variant="icon-outline"
            onClick={() => {
              const labels = tracesCardinalityQuery[propertyKey];
              const newLabels = [...labels, '=""'];
              setTracesCardinalityQuery((prev) => {
                const newQuery = { ...prev, lcLabels: newLabels };

                loadTraceLabels(newQuery, newQuery.lcLabels.length - 1);
                return newQuery;
              });
            }}
          >
            <Plus size={16} />
          </Button>
        </TooltipTrigger>
      </div>
      <div className="search__button-group__divider">
        <div />
      </div>
    </>
  );
};

const TracesCardinalityLabel = ({
  tracesCardinality,
}: {
  tracesCardinality: ReturnType<typeof useTracesCardinality>;
}): ReactElement => {
  const {
    loadTraceLabelValues,
    tracesCardinalityQuery,
    updateTracesCardinalityQuery,
  } = tracesCardinality;
  const { hcLabels, lcLabels, type } = tracesCardinalityQuery;

  const lastHcLabel = hcLabels[hcLabels.length - 1];
  const { label, value } = parseMetricLabelQuery(lastHcLabel);

  const lastLcLabel = lcLabels[lcLabels.length - 1];
  const { label: lcLabel, value: lcValue } = parseMetricLabelQuery(lastLcLabel);

  const onLabelChange = (
    lb: string,
    labelIndex: number,
    operator: string,
    propertyKey: string,
  ) => {
    const newLabel = `${lb}${operator}""`;
    const labels = type === 'Hc' ? hcLabels : lcLabels;
    const newLabels = [...labels];
    newLabels[labelIndex] = newLabel;
    updateTracesCardinalityQuery(propertyKey, newLabels, true);

    if (propertyKey === 'hcLabels') return;

    const newQuery = { ...tracesCardinalityQuery, [propertyKey]: newLabels };
    loadTraceLabelValues(lb, labelIndex, newQuery);
  };

  return (
    <>
      {lcLabels.map((series: string, index: number) => (
        <>
          <TracesCardinalityLabelValue
            key={`${series}-${index}`}
            labelIndex={index}
            labels={lcLabels}
            labelType="lcLabels"
            onLabelChange={(lb, op) => onLabelChange(lb, index, op, 'lcLabels')}
            series={series}
            tracesCardinality={tracesCardinality}
          />
          <div className="search__button-group__divider">
            <div />
          </div>
          {lcLabel && lcValue && (
            <TracesCardinalityLabelAddButton
              propertyKey="lcLabels"
              tracesCardinality={tracesCardinality}
            />
          )}
        </>
      ))}
      {type === 'Hc' &&
        hcLabels.map((series: string, index: number) => (
          <>
            <TracesCardinalityLabelValue
              key={`${series}-${index}`}
              labelIndex={index}
              labels={hcLabels}
              labelType="hcLabels"
              onLabelChange={(lb, op) =>
                onLabelChange(lb, index, op, 'hcLabels')
              }
              series={series}
              tracesCardinality={tracesCardinality}
            />
            <div className="search__button-group__divider">
              <div />
            </div>
            {label && value && (
              <TracesCardinalityLabelAddButton
                propertyKey="hcLabels"
                tracesCardinality={tracesCardinality}
              />
            )}
          </>
        ))}
    </>
  );
};

export default TracesCardinalityLabel;
