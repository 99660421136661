import uPlot from 'uplot';
import { drawLineWithMultiplePoints } from './drawUtils';
import { ctaColor } from 'utils/colors';
import { CHART_LINE_WIDTH_MEDIUM } from 'utils/chartConfig';

const drawAnomalyOutofBoundPoints = ({
  u,
  outOfBoundPoints,
}: {
  u: uPlot;
  outOfBoundPoints: { x: number; y: number }[][];
}): void => {
  outOfBoundPoints.map((points) => {
    drawLineWithMultiplePoints({
      ctx: u.ctx,
      points: points,
      options: {
        color: ctaColor.red,
        lineWidth: CHART_LINE_WIDTH_MEDIUM * 2,
        lineStyle: 'solid',
      },
    });
  });
};

export default drawAnomalyOutofBoundPoints;
