import dayjs from 'dayjs';
import { FacetBase, FacetValue, LogsState } from 'types';
import { onPromiseError } from 'utils';

import query from './query';
import { buildQuery, formatValueCounts } from './utils';

type Args = {
  facet: FacetBase;
  logsState: LogsState;
};

const getLabelValues = async ({
  facet,
  logsState,
}: Args): Promise<FacetValue[]> => {
  const { date } = logsState;
  const startTimeUnix = date.zoomedStartTimeUnix || date.startTimeUnix;
  const endTimeUnix = date.zoomedEndTimeUnix || date.endTimeUnix;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const logQuery = buildQuery(logsState);

  return query<FacetValue[], 'getLabelValues'>(`
    query GetLabelValues {
      getLabelValues(
        durationSecs: ${durationSecs}
        includeCount: true
        labelName: "${facet.name}"
        ${logQuery !== '{}' ? `logQuery: ${logQuery},` : ''}
        limit: 500,
        timestamp: "${endTime.format()}",
      ) {
        count
        value
      }
    }
  `)
    .then((data) => data.getLabelValues || [], onPromiseError)
    .then(formatValueCounts(facet.type));
};

export default getLabelValues;
