import { SearchTag } from 'components';
import React from 'react';
import { getIsInQuotes } from 'utils';
import { TermsFilter } from '../types';

const getSearchTermTag = (
  filter: TermsFilter,
): Pick<SearchTag, 'label' | 'text'> => {
  const { value } = filter;
  const isNeq = value[0] === '!';
  const searchTerm = isNeq ? value.slice(1) : value;
  const isInQuotes = getIsInQuotes(searchTerm);
  const type = isInQuotes ? 'Search' : 'Terms exist';
  const label = `${type}: ${value}`;

  return {
    label: <span data-testid={type}>{label}</span>,
    text: value,
  };
};

export default getSearchTermTag;
