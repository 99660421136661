import dayjs from 'dayjs';
import { DashboardPanelType } from 'types/Dashboard';
import { DateSelection } from 'types/DateSelection';
import { Series } from 'uplot';
import { getColorForOneSeries } from 'utils';

const getOneDataTimestamp = (data: {
  timestamp: string;
  count: number;
  values: string[];
}): number => {
  return dayjs(data?.timestamp).unix();
};

export const formatEventsChartInTimeseries = (
  data: Array<{
    timestamp: string;
    count: number;
    values: string[];
  }>,
  roundSecs: number,
  date: DateSelection,
  groupBys?: string[],
): {
  data: number[][];
  series: Series[];
  minValue: number;
  maxValue: number;
} => {
  let timestampBitmaps: { [key: string]: any } = {};
  const eventLevels: { [key: string]: number } = {};
  const alignedData: number[][] = [];
  const newSeries: Series[] = [];
  let minValue = Infinity;
  let maxValue = 0;

  const emptyFilledTimestamps = fillEmptyTimestamps(date, roundSecs, data[0]);
  timestampBitmaps = { ...timestampBitmaps, ...emptyFilledTimestamps };

  data.forEach((series) => {
    const { timestamp, count, values } = series;
    const timestampUnix = dayjs(timestamp).unix();
    const timestampBitmap = timestampBitmaps[timestampUnix] || {};

    if (values.length > 0) {
      const key = values.join(', ');
      if (!timestampBitmap[key]) {
        timestampBitmap[key] = 0;
      }
      timestampBitmap[key] = count;

      if (!eventLevels[key]) {
        eventLevels[key] = 0;
      }
    }

    if (values.length === 0 && groupBys.length === 0) {
      timestampBitmap['count'] = count;
      eventLevels['count'] = 0;
    }
    timestampBitmaps[timestampUnix] = timestampBitmap;
  });

  const timestamps = Object.keys(timestampBitmaps)
    .map((ts) => Number(ts))
    .sort();

  alignedData.push(timestamps);
  // create array size of timestamps and fill with undefined
  const emptyArray = new Array(timestamps.length).fill(undefined);
  const eventLevelsList = Object.keys(eventLevels);

  eventLevelsList.forEach((key, idx: number) => {
    alignedData.push([...emptyArray]);
    newSeries.push({
      label: key,
      points: { show: false },
      show: true,
      stroke: getColorForOneSeries({ level: key }, idx),
    });
  });

  eventLevelsList.map((key, index) => (eventLevels[key] = index + 1));
  timestamps.forEach((timestamp, dataIdx) => {
    const timestampBitmap = timestampBitmaps[timestamp];
    const timestampBitmapKeys = Object.keys(timestampBitmap);
    timestampBitmapKeys.forEach((key) => {
      const seriesIndex = eventLevels[key];
      alignedData[seriesIndex][dataIdx] = timestampBitmap[key];
      maxValue = Math.max(maxValue, timestampBitmap[key]);
      minValue = Math.min(minValue, timestampBitmap[key]);
    });
  });

  return { data: alignedData, series: newSeries, minValue, maxValue };
};

const fillEmptyTimestamps = (
  date: DateSelection,
  roundSecs: number,
  data: {
    timestamp: string;
    count: number;
    values: string[];
  },
): { [key: string]: any } => {
  const { startTimeUnix, endTimeUnix } = date;
  const dataTimestamp = getOneDataTimestamp(data);
  const timestampBitmaps: { [key: string]: any } = {};
  // go back direction
  for (
    let timestampUnix = dataTimestamp;
    timestampUnix >= startTimeUnix;
    timestampUnix -= roundSecs
  ) {
    timestampBitmaps[timestampUnix] = {};
  }
  // go forward direction
  for (
    let timestampUnix = dataTimestamp;
    timestampUnix <= endTimeUnix;
    timestampUnix += roundSecs
  ) {
    timestampBitmaps[timestampUnix] = {};
  }

  return timestampBitmaps;
};

export const formatEventsInTable = (
  data: Array<{ count: number; values: string[] }>,
  groupBys?: string[],
  returnFormat?: DashboardPanelType,
): Array<{ count: number; label: string }> => {
  const tableData = data.map((row, idx) => {
    const { count, values } = row;
    const labels: { [key: string]: string } = {};
    if (groupBys.length === 0) {
      labels['label'] = 'count';
    } else {
      values.forEach((value, idx) => {
        labels[groupBys[idx]] = value;
      });
    }

    if (returnFormat === DashboardPanelType.TOP_LIST) {
      return { label: Object.values(labels).join(', '), count };
    } else if (returnFormat === DashboardPanelType.TABLE) {
      return { ...labels, count };
    } else if (returnFormat === DashboardPanelType.PIECHART) {
      return {
        name: Object.values(labels).join(', '),
        color: getColorForOneSeries({}, idx),
        size: count,
        value: count,
      };
    }
  });
  return tableData;
};
