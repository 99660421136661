export const alertTypeAlgorithm = {
  change: 'Change',
  change_percent: 'Change %',
  DBSCAN: 'DBSCAN',
  rrcf: 'RRCF',
  basic: 'Basic',
  agile: 'Agile',
  robust: 'Robust',
  linear: 'Linear',
  seasonal: 'Seasonal',
  'agile-robust': 'Agile Robust',
};

export const robustAlgorithmMapping: { [key: string]: string } = {
  '0': 'Additive',
  '1': 'Multiplicative',
};

export const robustSeasonalityMapping: { [key: string]: string } = {
  '3600': 'Hourly',
  '86400': 'Daily',
  '604800': 'Weekly',
};

export const agileRobustSeasonalityMapping: { [key: string]: string } = {
  '0': 'None',
  '1': 'Hourly',
  '2': 'Monthly',
  '3': 'Quarterly',
};
