import { CursorStateProvider } from 'components';
import React, { ReactElement, useEffect } from 'react';
import { DateSelection, LayoutType } from 'types';

import AlertsChartAnomalySplit from './AlertsChartAnomalySplit';
import { getAnomalyAlertsLoadKfuseql } from '../AlertsCreateLogs/utils';
import { useAlertsAnomalyDataLoader } from '../hooks';
import { AlertAnomalyQueryItem } from '../types';
import { AnomalyConditionProps } from '../AlertsCreateCondition';

const AlertsChartAnomalyKfuseql = ({
  anomalyCondition,
  baseWidth,
  date,
  forWindow,
  isChartCompact,
  chartLayoutType,
  queryItem,
  unit = 'number',
}: {
  anomalyCondition: AnomalyConditionProps;
  baseWidth: number;
  chartLayoutType?: LayoutType;
  date: DateSelection;
  isChartCompact: boolean;
  forWindow?: string;
  queryItem: AlertAnomalyQueryItem;
  unit?: string;
}): ReactElement => {
  const { anomalyAlgorithm } = anomalyCondition;

  const anomalyDataState = useAlertsAnomalyDataLoader({ date });
  const { loadAnomalyDataLogs, loadHistoricalDataLogs, userAction } =
    anomalyDataState;

  const loadAnomalyAlertChartDataLogs = () => {
    if (!anomalyAlgorithm) return;
    const kfuseqls = getAnomalyAlertsLoadKfuseql({
      anomalyCondition,
      query: queryItem.kfuseql,
      kfuseQlOperation: queryItem.kfuseQlOperation,
      step: queryItem.step,
    });

    loadAnomalyDataLogs({ queryItem, anomalyKfuseql: kfuseqls });
  };

  useEffect(() => {
    // if (!userAction.current.initLoaded) return;
    loadAnomalyAlertChartDataLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anomalyCondition]);

  useEffect(() => {
    if (!queryItem.kfuseql) return;
    loadHistoricalDataLogs(queryItem);

    loadAnomalyAlertChartDataLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, queryItem.kfuseql]);

  return (
    <CursorStateProvider>
      <AlertsChartAnomalySplit
        anomalyDataState={anomalyDataState}
        anomalyCondition={anomalyCondition}
        baseWidth={baseWidth}
        chartLayoutType={chartLayoutType}
        date={date}
        forWindow={forWindow}
        isChartCompact={isChartCompact}
        queryItem={queryItem}
        unit={unit}
      />
    </CursorStateProvider>
  );
};

export default AlertsChartAnomalyKfuseql;
