import { facetValueToggleUtil, getIsDeselecting } from 'utils';
import { FilterStateAction, FilterActionType, FilterState } from 'types';

const toggleFacetHelper = ({
  isSelecting,
  prevState = {},
  value,
}: {
  isSelecting: boolean;
  prevState: any;
  value: string;
}) => {
  const isDeselecting =
    typeof isSelecting === 'boolean' ? false : getIsDeselecting(prevState);
  const nextState = { ...prevState };

  if (value in prevState) {
    delete nextState[value];
    return nextState;
  }

  nextState[value] = isDeselecting ? 0 : 1;
  return nextState;
};

const filterReducer = (
  state: FilterState,
  action: FilterStateAction,
): FilterState => {
  if (action.type === FilterActionType.ADD_FILTER) {
    const newState = { ...state };
    const { filterKey, payload } = action;

    if (!newState[filterKey]) return newState;

    const isArray = Array.isArray(newState[filterKey]);
    if (isArray) {
      newState[filterKey].push(payload);
    }

    if (!isArray) {
      newState[filterKey] = { ...newState[filterKey], ...payload };
    }

    return newState;
  }

  if (action.type === FilterActionType.UPDATE_FILTER_BY_INDEX) {
    const newState = { ...state };
    const { index, filterKey, payload } = action;
    if (!newState[filterKey]) return newState;

    const isArray = Array.isArray(newState[filterKey]);
    if (isArray) {
      newState[filterKey][index] = payload;
    }

    return newState;
  }

  if (action.type === FilterActionType.REMOVE_FILTER_BY_INDEX) {
    const newState = { ...state };
    const { index, filterKey } = action;
    if (newState[filterKey]) {
      newState[filterKey].splice(index, 1);
    }
    return newState;
  }

  if (action.type === FilterActionType.REMOVE_FILTER_BY_KEY) {
    const newState = { ...state };
    const { filterKey, payload } = action;
    if (!newState[filterKey]) return newState;

    const isArray = Array.isArray(newState[filterKey]);
    if (!isArray) {
      delete newState[filterKey][payload];
    }

    return newState;
  }

  if (action.type === FilterActionType.CLEAR_FILTERS_BY_KEY) {
    const newState = { ...state };
    const { filterKey } = action;
    if (!newState[filterKey]) return newState;

    const isArray = Array.isArray(newState[filterKey]);
    if (isArray) {
      newState[filterKey] = [];
    }

    if (!isArray) {
      newState[filterKey] = {};
    }

    return newState;
  }

  if (action.type === FilterActionType.CLEAR_ALL_FILTERS) {
    const newState = { ...state };
    Object.keys(newState).forEach((key) => {
      const isArray = Array.isArray(newState[key]);
      if (isArray) {
        newState[key] = [];
      }

      if (!isArray) {
        newState[key] = {};
      }
    });
    return newState;
  }

  if (action.type === FilterActionType.ADD_MAPPED_FILTER) {
    const newState = { ...state };
    const { filterKey, mapKey, payload } = action;
    if (!newState[filterKey]) return newState;

    const isArray = Array.isArray(newState[filterKey]);
    if (!isArray) {
      newState[filterKey][mapKey] = {
        [payload]: 1,
      };
    }

    return newState;
  }

  if (action.type === FilterActionType.UPDATE_MAPPED_FILTER_BY_KEY) {
    const newState = { ...state };
    const { filterKey, mapKey, payload } = action;
    if (!newState[filterKey]) return newState;

    const isArray = Array.isArray(newState[filterKey]);
    if (!isArray) {
      newState[filterKey][mapKey] = payload;
    }

    return newState;
  }

  if (action.type === FilterActionType.EXCLUDE_FROM_MAPPED_FILTER) {
    const newState = { ...state };
    const { filterKey, mapKey, payload } = action;
    if (!newState[filterKey]) return newState;

    const isArray = Array.isArray(newState[filterKey]);
    if (!isArray) {
      newState[filterKey][mapKey] = {
        [payload]: 0,
      };
    }

    return newState;
  }

  if (action.type === FilterActionType.REMOVED_MAPPED_FILTER_BY_KEY) {
    const newState = { ...state };
    const { filterKey, mapKey } = action;
    if (!newState[filterKey]) return newState;

    const isArray = Array.isArray(newState[filterKey]);
    if (!isArray) {
      delete newState[filterKey][mapKey];
    }

    return newState;
  }

  if (action.type === FilterActionType.TOGGLE_MAPPER_FILTER_BY_KEY) {
    const { filterKey, mapKey, payload, allFacetValues, selectedFacetValues } =
      action;

    return {
      ...state,
      [filterKey]: {
        ...state[filterKey],
        [mapKey]: facetValueToggleUtil.getNextSelectedFacetValues({
          facetName: mapKey,
          facetValueToToggle: payload,
          allFacetValues,
          selectedFacetValues,
        }),
      },
    };
  }

  if (action.type === FilterActionType.UPDATE_FROM_URL) {
    const newState = { ...state };
    const { payload } = action;
    try {
      const parsed = JSON.parse(payload);
      if (parsed && typeof parsed === 'object') {
        return parsed;
      }
    } catch (e) {
      return newState;
    }
  }

  if (action.type === FilterActionType.REPLACE_FILTER_BY_KEY) {
    const newState = { ...state };
    const { filterKey, payload } = action;
    if (!newState[filterKey]) return newState;

    newState[filterKey] = payload;
    return newState;
  }

  return state;
};

export default filterReducer;
