import {
  useFacetRegexState,
  useRequest,
  useSelectedFacetRangeByNameState,
  useSelectedFacetValuesByNameState,
} from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { DateSelection } from 'types';
import { RumEventType } from '../types';
import getRUMTopViewWithHighestError from '../requests/getRUMTopViewWithHighestError';
import { calculateRateIntervalVariableValue } from 'utils/rateInterval';

type Args = {
  applicationFilter: string;
  date: DateSelection;
  eventType: RumEventType;
  facetRegexState: ReturnType<typeof useFacetRegexState>;
  selectedFacetRangeByNameState: ReturnType<
    typeof useSelectedFacetRangeByNameState
  >;
  selectedFacetValuesByNameState?: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
  chart: any;
};
const useRumTopViewWithHighestError = ({
  applicationFilter,
  date,
  facetRegexState,
  selectedFacetRangeByNameState,
  selectedFacetValuesByNameState,
  chart,
}: Args) => {
  const pageCursorRef = useRef<string>(null);
  const request = useRequest(getRUMTopViewWithHighestError, true, true);
  const [result, setResult] = useState({ errorSeries: [], error: null });

  const { chartType, eventType } = chart;
  const { stepInMs } = calculateRateIntervalVariableValue(date, chartType);
  const call = () => {
    request
      .call({
        applicationFilter,
        eventType,
        date,
        facetRegex: facetRegexState?.state,
        selectedFacetRangeByName: selectedFacetRangeByNameState.state,
        selectedFacetValuesByName: selectedFacetValuesByNameState.state,
        rollupSeconds: stepInMs / 1000,
      })
      .then((nextResult) => {
        if (nextResult && nextResult.errorSeries) {
          setResult((prevResult) => ({
            ...prevResult,
            isLoading: false,
            errorSeries: [
              ...(prevResult.errorSeries || []),
              ...nextResult.errorSeries,
            ],
            error: null,
          }));
        } else {
          setResult((prevResult) => ({
            ...prevResult,
            error: 'No errorSeries found in nextResult',
            isLoading: false,
          }));
        }
      });
  };

  const onScrollEnd = () => {
    if (pageCursorRef.current) {
      call();
    }
  };

  useEffect(() => {
    pageCursorRef.current = null;
    setResult({ errorSeries: [], error: null });
    call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applicationFilter,
    date,
    eventType,
    selectedFacetRangeByNameState.state,
    selectedFacetValuesByNameState.state,
  ]);

  return {
    call,
    isLoading: request.isLoading,
    onScrollEnd,
    result,
  };
};

export default useRumTopViewWithHighestError;
