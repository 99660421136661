import dayjs from 'dayjs';
import { useRequest, useSubscriptionRequest, useUrlState } from 'hooks';
import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getLogsV2, buildQuery } from 'requests';
import { snakeCase } from 'snake-case';
import { onPromiseError } from 'utils';
import { v4 as uuidv4 } from 'uuid';

const formatSortBy = (s: string) => {
  const parts = s.split('.');
  const key = parts[parts.length - 1];

  if (key === 'timestamp') {
    return 'ts';
  }

  return snakeCase(key);
};

const getShouldUseSubscription = (sort) => {
  if (
    (sort.sortBy === 'timestamp' || sort.sortBy === null) &&
    sort.sortOrder === 'Desc'
  ) {
    return true;
  }

  return false;
};

const queryBuilder = (...args) => {
  const { cursor = null, limit = 200, logsState, sort } = args[0];
  const { date } = logsState;
  const startTimeUnix = date.zoomedStartTimeUnix || date.startTimeUnix;
  const endTimeUnix = date.zoomedEndTimeUnix || date.endTimeUnix;

  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  const logQuery = buildQuery({
    ...logsState,
    logLevel: null,
  });
  return `
    subscription {
      getLogsV2Stream(
        cursor: ${cursor ? `"${cursor}"` : 'null'},
        ${logQuery !== '{}' ? `query: ${logQuery},` : ''}
        limit: ${limit},
        timestamp: "${endTime.format()}",
        durationSecs: ${durationSecs}
        ${
          sort?.sortBy && sort?.sortOrder
            ? `sortBy: "${formatSortBy(sort.sortBy)}"`
            : ''
        }
        ${sort?.sortBy && sort?.sortOrder ? `sortOrder: ${sort.sortOrder}` : ''}
      ) {
        cursor
        events {
          timestamp
          logLine
          fpString
          fpHash
          level
          labels
          facets {
            name
            dataType
            content
          }
        }
      }
    }
  `;
};

const useLogsTable = () => {
  const appendLogsSubscriptionRequest = useSubscriptionRequest({
    queryBuilder,
    initialState: [],
    key: 'getLogsV2Stream',
    merge: () => {},
    onReceivedMessagePayload: (next) => {
      const { cursor, events } = next;
      cursorRef.current = cursor;

      setLogs((prevLogs) => [
        ...prevLogs,
        ...events.map((event) => ({
          ...event,
          message: event.logLine,
          extraFacets: event.facets,
          fpPattern: event.fpString,
        })),
      ]);
    },
  });

  const appendLogsBasicRequest = useRequest(getLogsV2);

  const logsForEntitySubscriptionRequest = useSubscriptionRequest({
    queryBuilder,
    initialState: [],
    key: 'getLogsV2Stream',
    merge: () => {},
    onReceivedMessagePayload: (next) => {
      const { cursor, events } = next;
      cursorRef.current = cursor;

      const nextLogs = events
        .filter((event) => event)
        .map((event) => ({
          ...event,
          message: event.logLine,
          extraFacets: event.facets,
          fpPattern: event.fpString,
        }));

      const nextContextIndex = nextLogs.findIndex((event) => {
        return (
          event.timestamp === contextTimestamp &&
          event.logLine === contextMessage
        );
      });

      setContextIndex(nextContextIndex);
      setLogs((prevLogs) => [...prevLogs, ...nextLogs]);
    },
  });

  const logsForEntityBasicRequest = useRequest(getLogsV2);

  const cursorRefBitMapRef = useRef<{ [key: string]: number }>({});
  const cursorRef = useRef(null);
  const idRef = useRef(uuidv4());
  const [searchParams] = useSearchParams();
  const contextTimestamp = searchParams.get('contextTimestamp');
  const contextMessage = searchParams.get('contextMessage');
  const [contextIndex, setContextIndex] = useState(null);

  const [logs, setLogs] = useState([]);

  const [sort, setSort] = useUrlState('sort', {
    sortBy: null,
    sortOrder: 'Desc',
  });

  const appendLogs = (logsState: any) => {
    const shouldUseSubscription = getShouldUseSubscription(sort);
    const logsForEntityRequest = shouldUseSubscription
      ? logsForEntitySubscriptionRequest
      : logsForEntityBasicRequest;

    const appendLogsRequest = shouldUseSubscription
      ? appendLogsSubscriptionRequest
      : appendLogsBasicRequest;

    const activeCursor = cursorRef.current;

    const onSuccess = shouldUseSubscription
      ? () => {}
      : ({ cursor, events }) => {
          cursorRef.current = cursor;
          setLogs((prevLogs) => [
            ...prevLogs,
            ...events
              .filter((event) => event)
              .map((event) => ({
                ...event,
                message: event.logLine,
                extraFacets: event.facets,
                fpPattern: event.fpString,
              })),
          ]);
        };

    if (
      !logsForEntityRequest.isLoading &&
      !appendLogsRequest.isLoading &&
      activeCursor &&
      !cursorRefBitMapRef.current[activeCursor]
    ) {
      cursorRefBitMapRef.current[activeCursor] = 1;
      appendLogsRequest
        .call({
          cursor: activeCursor,
          logsState,
          sort,
        })
        .then(onSuccess, onPromiseError);
    }
  };

  const fetchLogs = ({
    logsState,
    limit = 200,
    enableWebSocket = true,
  }: {
    logsState: any;
    limit?: number;
    enableWebSocket?: boolean;
  }) => {
    const shouldUseSubscription = getShouldUseSubscription(sort);
    const logsForEntityRequest =
      shouldUseSubscription && enableWebSocket
        ? logsForEntitySubscriptionRequest
        : logsForEntityBasicRequest;

    const onSuccess =
      shouldUseSubscription && enableWebSocket
        ? () => {}
        : ({ cursor, events }) => {
            cursorRef.current = cursor;

            const nextLogs = events
              .filter((event) => event)
              .map((event) => ({
                ...event,
                message: event.logLine,
                extraFacets: event.facets,
                fpPattern: event.fpString,
              }));
            const nextContextIndex = nextLogs.findIndex((event) => {
              return (
                event.timestamp === contextTimestamp &&
                event.logLine === contextMessage
              );
            });

            setContextIndex(nextContextIndex);
            setLogs(nextLogs);
          };

    setLogs([]);
    return logsForEntityRequest
      .call({ logsState, sort, limit })
      .then(onSuccess, onPromiseError);
  };

  const sortByColumn = (nextSortBy: string, nextSortOrder: string) => {
    cursorRefBitMapRef.current = {};
    setSort((prevSort) => ({
      sortBy: nextSortBy,
      sortOrder: nextSortOrder,
    }));
  };

  const generateNewId = () => {
    idRef.current = uuidv4();
    cursorRefBitMapRef.current = {};
  };

  const shouldUseSubscription = getShouldUseSubscription(sort);
  const isAppending = shouldUseSubscription
    ? appendLogsSubscriptionRequest.isLoading
    : appendLogsBasicRequest.isLoading;

  const isLoading = shouldUseSubscription
    ? logsForEntitySubscriptionRequest.isLoading
    : logsForEntityBasicRequest.isLoading;

  return {
    appendLogs,
    context: {
      index: contextIndex,
      message: contextMessage,
      timestamp: contextTimestamp,
    },
    cursor: cursorRef.current,
    fetchLogs,
    generateNewId,
    id: idRef.current,
    isAppending,
    isLoading,
    logs,
    sort,
    sortByColumn,
  };
};

export default useLogsTable;
