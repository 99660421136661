import { dateTimeFormat } from 'kfuse-constants';
import dayjs from 'dayjs';
import {
  DateSelection,
  PrometheusDataset,
  MetricsDatasetInstantProps,
  MetricsTransformSeriesFuncProps,
  SunburstDataProps,
  DashboardPanelType,
} from 'types';
import { Series } from 'uplot';
import {
  AGGREGATE_OPTIONS,
  defaultSeriesFormatter,
  fillTimeseriesForWindow,
  getColorForOneSeries,
  INACTIVE_COLOR_LIGHT,
  isNumber,
  promqlDatasetCheck,
} from 'utils';
import {
  CHART_LINE_WIDTH_THICKER,
  CHART_LINE_WIDTH_MEDIUM,
} from 'utils/chartConfig';

export const transformTimeseriesDataset = ({
  datasets,
  date,
  seriesFormatter = defaultSeriesFormatter,
  sortDatasets,
  step,
}: {
  datasets: PrometheusDataset[];
  date: DateSelection;
  seriesFormatter?: MetricsTransformSeriesFuncProps;
  sortDatasets?: (datasets: PrometheusDataset[]) => PrometheusDataset[];
  step: number;
}): {
  data: Array<any>;
  maxValue?: number;
  minValue: number;
  series: Series[];
} => {
  const timestampBitmap: { [key: string]: boolean } = {};

  const data = [];
  const series: Series[] = [];
  let maxValue = -Infinity;
  let minValue = Infinity;

  const newDatasets = sortDatasets ? sortDatasets(datasets) : datasets;
  const datasetsKeys = Object.keys(newDatasets);
  const { isDatasetOutlier, maxUniqueLabels } = promqlDatasetCheck(
    datasetsKeys,
    newDatasets,
  );

  datasetsKeys.forEach((key, idx) => {
    const dataset = newDatasets[key] as PrometheusDataset & {
      promIndex: number;
    };
    const { metric, promIndex, values } = dataset;

    values.forEach((value) => {
      const [ts, val] = value;
      if (!timestampBitmap[ts]) {
        timestampBitmap[ts] = true;
      }
    });

    const serie = seriesFormatter({ idx, promIndex, metric, maxUniqueLabels });
    if (isDatasetOutlier) {
      serie.width = CHART_LINE_WIDTH_THICKER;
    }

    if (isDatasetOutlier && metric['outlier'] !== 'true') {
      serie.stroke = INACTIVE_COLOR_LIGHT;
      serie.width = CHART_LINE_WIDTH_MEDIUM;
      serie.dash = [4, 8];
    }
    series.push(serie);
  });

  const timestamps = fillTimeseriesForWindow(date, step, timestampBitmap);
  data.push(timestamps);

  datasetsKeys.forEach((key: any) => {
    const dataset = newDatasets[key];
    const { values } = dataset;
    const valueByTimestamp: { [key: string]: string } = values.reduce(
      (obj, value) => {
        const [ts, val] = value;
        maxValue = Math.max(maxValue, Number(val) || 0);
        minValue = Math.min(minValue, Number(val) || Infinity);
        return { ...obj, [ts]: val };
      },
      {},
    );

    const timeseriesData = timestamps.map((ts) =>
      isNumber(valueByTimestamp[ts]) ? valueByTimestamp[ts] : undefined,
    );
    data.push(timeseriesData);
  });

  return {
    data,
    maxValue: maxValue === -Infinity ? 0 : maxValue,
    minValue: minValue === Infinity ? 0 : minValue,
    series,
  };
};

export const transformPromqlInstantQuery = ({
  datasets,
  dataFormat,
  metricNames,
  promqls,
}: {
  datasets: PrometheusDataset[][];
  dataFormat?: DashboardPanelType;
  metricNames?: string[];
  promqls?: string[];
}): MetricsDatasetInstantProps => {
  const rows: MetricsDatasetInstantProps['data'] = [];
  const labelKeys: { [key: string]: boolean } = {};
  datasets.map((dataset, promIndex) => {
    dataset.forEach((d, idx) => {
      const { metric, value, values } = d;
      const metricKeys = Object.keys(metric);
      metricKeys.forEach((key) => {
        if (labelKeys[key]) return;
        labelKeys[key] = true;
      });

      let labels: { [key: string]: string } = {};
      if (metricKeys.length === 0) {
        labels['label'] = metricNames[promIndex] || '{}';
      } else {
        labels = metric;
      }
      const [timestamp, valueStr] = value || values[0];
      const valueNum = parseFloat(valueStr as string);

      const commonFunc = {
        getMetric: () => metric,
        getValues: () => value || values,
        getValue: () => valueNum,
        getPromIndex: () => promIndex,
      };

      if (dataFormat === DashboardPanelType.TABLE) {
        rows.push({ ...commonFunc, ...labels, count: valueNum });
        return;
      } else if (dataFormat === DashboardPanelType.TOP_LIST) {
        rows.push({
          ...commonFunc,
          label: Object.values(labels).join(', '),
          count: valueNum,
        });
        return;
      } else if (dataFormat === DashboardPanelType.GRAFANA_POLYSTAT_PANEL) {
        rows.push({
          ...commonFunc,
          count: valueNum,
          label: Object.keys(labels)
            .map((key) => `${key}:${labels[key]}`)
            .join(', '),
          value: valueNum,
        });
        return;
      } else if (dataFormat === DashboardPanelType.PIECHART) {
        rows.push({
          ...commonFunc,
          name: Object.values(labels).join(', '),
          color: getColorForOneSeries({}, idx),
          size: valueNum,
          value: valueNum,
          count: valueNum,
        });
        return;
      }
    });
  });

  rows.sort((a, b) => b.count - a.count);
  if (dataFormat === DashboardPanelType.PIECHART) {
    // add percentage
    const total = rows.reduce((acc, cur) => acc + cur.size, 0);
    rows.forEach((r) => {
      r.percentage = (r.size / total) * 100;
    });
  }

  const sortGroupBy = getGroupByNameSortedFromPromql(
    promqls[0],
    Object.keys(labelKeys),
  );
  return { data: rows, labels: sortGroupBy };
};

export const transformSunburstDataset = (
  datasets: PrometheusDataset[],
  promql?: string,
  metricNames?: string[],
): {
  data: SunburstDataProps;
  tableData: Array<any>;
  labels?: string[];
} => {
  const metricKeys: { [key: string]: boolean } = {};
  datasets.forEach(({ metric }) => {
    Object.keys(metric).forEach((key) => (metricKeys[key] = true));
  });
  const order = getGroupByNameSortedFromPromql(promql, Object.keys(metricKeys));
  const result: SunburstDataProps = {
    name: '',
    children: [],
    color: 'transparent',
  };
  datasets.forEach((dataset, idx) => {
    const { metric, value } = dataset;
    const size = parseFloat(value[1]);
    if (size === 0) return;

    let current = result;
    order.forEach((key) => {
      const name = metric[key];
      if (name === undefined) return;

      let child = current.children?.find((c) => c.name === name);
      if (!child) {
        child = { name, color: getColorForOneSeries(metric, idx) };
        if (current.children) {
          current.children.push(child);
        } else {
          current.children = [child];
        }
      }
      current = child;
    });
    if (current.children) {
      current.children.push({
        name: metricNames[idx] || '',
        size,
        color: getColorForOneSeries(metric, idx),
      });
    } else {
      current.size = size;
    }
  });

  const table = getSunburstTableData(result.children);
  // add percentage
  const total = table.reduce((acc, cur) => acc + cur.size, 0);
  table.forEach((r) => {
    r.percentage = (r.size / total) * 100;
  });

  return { data: result, labels: order, tableData: table };
};

const getSunburstTableData = (
  dataset: SunburstDataProps[],
  metricNames: string[] = [],
) => {
  const result: Array<{
    metric_names: string[];
    size: number;
    percentage?: number;
  }> = [];
  dataset.forEach((d) => {
    if (d.children) {
      result.push(
        ...getSunburstTableData(d.children, [...metricNames, d.name]),
      );
    } else {
      result.push({
        metric_names: [...metricNames, d.name],
        size: d.size,
      });
    }
  });

  return result;
};

const getGroupByNameSortedFromPromql = (
  promql: string,
  groupByKeys: string[],
): string[] => {
  const groupBy = groupByKeys.find((by) => promql.includes(by));
  if (!groupBy) return [];

  const groupByIndex = promql.indexOf(groupBy);
  // write a while loop to find the first '(' after groupByIndex
  let start = groupByIndex;
  while (promql[start] !== '(' && start > 0) {
    start--;
  }
  // write a while loop to find the first ')' after groupByIndex
  let end = groupByIndex;
  while (promql[end] !== ')' && end < promql.length) {
    end++;
  }

  const groupByStr = promql.substring(start + 1, end);
  const groupByStrSorted = groupByStr.split(',').map((item) => item.trim());
  if (groupByStrSorted.length > 2) return groupByStrSorted;

  // check if groupByStrSorted and groupByKeys are the same and item also in groupByStrSorted
  const groupByStrSortedFiltered = groupByStrSorted.filter((item) =>
    groupByKeys.includes(item),
  );

  if (groupByStrSortedFiltered.length !== groupByKeys.length) {
    return groupByKeys;
  }
  return groupByStrSorted;
};

/**
 * Get aggreate by from promql
 * @param promql
 * example -> avg by (app_kubernetes_io_component,kube_app_component,kube_cluster_name) ( container_cpu_usage )
 * result ["app_kubernetes_io_component", "kube_app_component", "kube_cluster_name"]
 * example -> avg by (container_name,container_id) ( container_io_write )
 * result ["container_name", "container_id"]
 */
const getAggregateLabelFromPromql = (promql: string): string[] => {
  const aggregateLabels = [];
  const aggregateBys = AGGREGATE_OPTIONS.map(({ value }) => `${value} by`);
  const aggregateBy = aggregateBys.find((by) => promql.includes(by));

  if (aggregateBy) {
    const aggregateByIndex = promql.indexOf(aggregateBy);
    const start = promql.indexOf('(', aggregateByIndex);
    const end = promql.indexOf(')', aggregateByIndex);
    const aggregateByStr = promql.substring(start + 1, end);
    aggregateLabels.push(...aggregateByStr.split(','));
  }

  return aggregateLabels;
};

export const transformInstantQueryToTable = (
  datasets: Array<{
    metric: { [key: string]: string };
    value: [number, string];
  }>,
): Array<any> => {
  const result: { [key: string]: any }[] = datasets.map((dataset) => {
    const { metric, value } = dataset;
    const row = { ...metric };
    const [timestamp, valueStr] = value;
    row.time = dayjs(timestamp * 1000).format(dateTimeFormat);
    row.value = parseFloat(valueStr).toFixed(2);
    return row;
  });

  result.sort((a, b) => b.value - a.value);

  return result;
};
