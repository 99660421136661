import { SelectedFacetValuesByName } from 'types';

const getIsAsmChecked = (
  selectedFacetValuesByName: SelectedFacetValuesByName,
): boolean => {
  return Boolean(
    selectedFacetValuesByName['kf_source'] &&
      selectedFacetValuesByName['kf_source']['knight'],
  );
};

export default getIsAsmChecked;
