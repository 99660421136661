import { AutocompleteOption } from 'components';
import { Filter, useDateState, useFiltersState, useSortState } from 'hooks';
import getSelectedFacetValuesByNameState from 'hooks/useFiltersState/getSelectedFacetValuesByNameState';
import { useMemo, useState } from 'react';
import { RumTableColumnKey } from '../contants';
import { RumErrorTab, RumEventType } from '../types';
import getSelectedFacetRangeByNameState from 'hooks/useFiltersState/getSelectedFacetRangeByNameState';
import getFacetRegexState from 'hooks/useFiltersState/getFacetRegexState';

type Options = {
  dateState?: ReturnType<typeof useDateState>;
  initialApplicationFilter?: string;
  initialApplicationFilterValues?: AutocompleteOption[];
  initialEventIdFilter?: { [key: string]: string };
  initialEventType?: RumEventType;
  initialErrorTab?: RumErrorTab;
  initialFilters?: Filter[];
  initialSelectedQueryIndex?: string;
  initialIdSearch?: string;
  shouldWriteToUrl?: boolean;
  urlStateKey?: string;
};

const useRumState = ({
  dateState,
  initialApplicationFilter = null,
  initialEventIdFilter,
  initialEventType,
  initialErrorTab,
  initialFilters,
  initialIdSearch,
  shouldWriteToUrl = true,
  urlStateKey,
}: Options) => {
  const [applicationFilter, setApplicationFilter] = useState<string | null>(
    initialApplicationFilter,
  );

  const [eventIdFilter, setEventIdFilter] = useState(
    initialEventIdFilter || null,
  );
  const [eventType, setEventType] = useState<RumEventType>(initialEventType);
  const [errorTab, setErrorTab] = useState<string | null>(initialErrorTab);
  const filtersState = useFiltersState({
    initialState: initialFilters,
    shouldWriteToUrl,
    urlStateKey,
  });

  const facetRegexState = useMemo(
    () => getFacetRegexState(filtersState),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersState.state],
  );

  const selectedFacetRangeByNameState = useMemo(
    () => getSelectedFacetRangeByNameState(filtersState),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersState.state],
  );

  const selectedFacetValuesByNameState = useMemo(
    () => getSelectedFacetValuesByNameState(filtersState),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersState.state],
  );

  const rumTableSortState = useSortState({
    urlStateKey: 'rumTableSort',
    initalState: {
      key: RumTableColumnKey.date,
      isAsc: false,
    },
  });

  const [idSearch, setIdSearch] = useState(initialIdSearch || '');

  const state = useMemo(() => {
    return {
      applicationFilter,
      date: dateState[0],
      facetRegex: facetRegexState?.state,
      eventIdFilter,
      filters: filtersState.state,
      idSearch,
      selectedFacetRangeByNameState: selectedFacetRangeByNameState.state,
      selectedFacetValuesByName: selectedFacetValuesByNameState.state,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applicationFilter,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dateState[0],
    facetRegexState?.state,
    eventIdFilter,
    filtersState.state,
    idSearch,
    selectedFacetRangeByNameState.state,
    selectedFacetValuesByNameState.state,
  ]);

  return {
    applicationFilter,
    dateState,
    facetRegexState,
    eventIdFilter,
    errorTab,
    eventType,
    filtersState,
    setApplicationFilter,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    setEventType,
    setEventIdFilter,
    state,
    idSearch,
    rumTableSortState,
    setErrorTab,
    setIdSearch,
  };
};

export default useRumState;
