import { parseFiltersFromLogsState } from 'hooks';
import { LogsMetricQueryProps } from 'types';
import { LogsState } from 'types/logs-workbook';
import { defaultLogsQuery, getFacetKey, parseFacetKey } from 'utils';

const logsStateParams = [
  {
    key: 'date',
    isNonEmpty: (date) => Object.keys(date).length,
  },
  {
    key: 'customColumns',
    isNonEmpty: (customColumns) => Object.keys(customColumns).length,
  },
];

export const convertSavedQueryToUrlSearchParams = (logsStateString: string) => {
  const logsState = logsStateString
    ? (JSON.parse(logsStateString) as LogsState)
    : ({} as LogsState);

  let filters = parseFiltersFromLogsState(logsState);
  const customerFilter = logsState?.customerFilter;

  if (filters) {
    const filterAdjusted = filters.map((filter) => {
      const facet = filter.value?.facet;

      if (facet) {
        const parsedFacet = parseFacetKey(facet);
        if (parsedFacet.displayName === undefined) {
          const facetKey = getFacetKey({ ...parsedFacet, displayName: '' });
          return { ...filter, value: { ...filter.value, facet: facetKey } };
        }
      }
      return filter;
    });
    filters = filterAdjusted;
  }
  const queries: LogsMetricQueryProps[] = [{ ...defaultLogsQuery, filters }];
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('LogsMetricsQueries', JSON.stringify(queries));

  logsStateParams.forEach(({ key, isNonEmpty }) => {
    const logStateParamValue = logsState[key];
    if (logStateParamValue && isNonEmpty(logStateParamValue)) {
      urlSearchParams.set(key, JSON.stringify(logStateParamValue));
    } else {
      urlSearchParams.delete(key);
    }
  });

  return { customerFilter, urlSearchParams, queries };
};
