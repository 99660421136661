import React, { useMemo } from 'react';
import { useKubernetesController } from '../KubernetesController';
import { useDateState } from 'hooks';

import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellTimestamp,
  KubeTableCell,
} from './Components';
import KubernetesTable from './KubernetesTable';
import { useEntitiesCount, useEntitiesData } from './useEntitiesData';
import { Deployment } from '../types';

export default function KubernetesTableForDeployment() {
  const { entitiesType, facets, page, limit } = useKubernetesController();

  const filters = useMemo(() => [facets], [facets]);
  const [rows] = useEntitiesData<{ deployment: Deployment }>({
    filters,
    page,
    limit,
  });
  const [count] = useEntitiesCount({
    entitiesType,
    facets,
  });

  return (
    <KubernetesTable
      count={count}
      rows={rows ?? []}
      columns={[
        {
          key: 'kube_deployment',
          label: 'Deployment',
          renderCell(prop) {
            const deploymentName = prop.row.deployment.metadata.name;
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name pb-1.5 pt-1.5"
                tooltipText={deploymentName}
              >
                {deploymentName}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell(prop) {
            return <KubeCellCluster {...prop} />;
          },
        },
        {
          key: 'metadata.namespace',
          label: 'Namespace',
          renderCell(prop) {
            return <KubeCellNamespace {...prop} />;
          },
        },
        {
          key: 'deploymentStrategy',
          label: 'Strategy',
          renderCell(prop) {
            return (
              <KubeTableCell
                {...prop}
                className="kubernetes__table__cell__entity-name"
                tooltipText={prop.row.deployment.deploymentStrategy}
              >
                {prop.row.deployment.deploymentStrategy}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'metadata.creationTimestamp',
          label: 'Age',
          renderCell(prop) {
            return (
              <KubeCellTimestamp
                {...prop}
                timestamp={prop.row.deployment.metadata.creationTimestamp}
              />
            );
          },
        },
        {
          key: 'replicas',
          label: 'Current',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.deployment.replicas}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'replicasDesired',
          label: 'Desired',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.deployment.replicasDesired}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'updatedReplicas',
          label: 'Up to Date',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.deployment.updatedReplicas}
              </KubeTableCell>
            );
          },
        },
        {
          key: 'availableReplicas',
          label: 'Available',
          renderCell(prop) {
            return (
              <KubeTableCell {...prop} align="right">
                {prop.row.deployment.availableReplicas}
              </KubeTableCell>
            );
          },
        },
      ]}
    />
  );
}
