import dayjs from 'dayjs';
import {
  DateSelection,
  FacetRegexTerm,
  KeyExists,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  SpanFilter,
  TimeSeries,
} from 'types';
import {
  convertAggregateTimeSeriesFromFloatToSeconds,
  onPromiseError,
} from 'utils';
import queryTraceService from './queryTraceService';
import { buildTracesFilter } from './utils';

type Args = {
  aggregation: string;
  aggregationField: string;
  customerFilter?: { key: string; value: string };
  date: DateSelection;
  facetRegex: FacetRegexTerm[];
  groupBys: string[];
  instant?: boolean;
  keyExists: KeyExists;
  returnQueryString?: boolean;
  rollUpSeconds: number;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  spanFilter: SpanFilter;
  traceIdSearch?: string;
};

const aggregateTimeSeries = async ({
  aggregation,
  aggregationField,
  customerFilter,
  date,
  groupBys,
  instant = false,
  keyExists,
  rollUpSeconds,
  facetRegex = [],
  returnQueryString = false,
  selectedFacetRangeByName = {},
  selectedFacetValuesByName = {},
  spanFilter,
  traceIdSearch,
}: Args): Promise<TimeSeries[] | string> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const shouldUseCount = aggregation === 'distinctcount' && !aggregationField;

  const queryFilterString = buildTracesFilter({
    customerFilter,
    facetRegex,
    keyExists,
    selectedFacetRangeByName,
    selectedFacetValuesByName,
    spanFilter,
    traceIdSearch,
  });

  const queryStr = `
    {
      aggregateTimeSeries(
        aggregation: "${shouldUseCount ? 'count' : aggregation}"
        aggregationField: "${aggregationField ? aggregationField : '*'}"
        durationSecs: ${durationSecs}
        filter: ${queryFilterString}
        groupBy: [${groupBys.map((groupBy) => `"${groupBy}"`).join(',')}]
        rollUpSeconds: ${instant ? durationSecs : rollUpSeconds}
        timestamp: "${endTime.format()}"
      ) {
        BucketStartSecs
        GroupVal
        Value
      }
    }
  `;

  if (returnQueryString) return queryStr;

  return queryTraceService<TimeSeries[], 'aggregateTimeSeries'>(queryStr).then(
    (data) =>
      (data?.aggregateTimeSeries || []).map(
        convertAggregateTimeSeriesFromFloatToSeconds,
      ),
    onPromiseError,
  );
};

export default aggregateTimeSeries;
