import { colorsByLogLevel } from 'kfuse-constants';

import { chartColors, chartColorsRed } from './chart-colors';
import { isCssVariable, getCssVariableValue } from 'utils';

export const getColorForOneSeries = (
  metric: { [key: string]: any },
  idx: number,
  startIdx = 0,
): string => {
  let color;
  if (
    metric.level &&
    colorsByLogLevel[metric.level] &&
    Object.keys(metric).length === 1
  ) {
    color = colorsByLogLevel[metric.level];
  } else {
    color = chartColors[(idx + startIdx) % chartColors.length];
  }

  return isCssVariable(color) ? getCssVariableValue(color) : color;
};

export const getNonRedColorForOneSeries = (
  metric: { [key: string]: any },
  idx: number,
  startIdx = 0,
): string => {
  const newColor = getColorForOneSeries(metric, idx, startIdx);

  while (chartColorsRed.includes(newColor)) {
    return chartColors[(idx + startIdx + 1) % chartColors.length];
  }

  return newColor;
};

export const getColorRandom = () => {
  const min = 0;
  const max = chartColors.length - 1;
  const randomIndex = Math.floor(Math.random() * (max - min + 1)) + min;
  return chartColors[randomIndex];
};
