import { DashboardPanelProps, DashboardProps } from 'types/Dashboard';
import { getSaveDashboardPanels } from '../utils';

export const copyDashboardJson = (
  panels: DashboardPanelProps[],
  dashboardDetails: DashboardProps,
) => {
  const newPanels = getSaveDashboardPanels(panels);
  const jsonModel = { ...dashboardDetails, panels: newPanels };

  const copyText = JSON.stringify(jsonModel, null, 2);
  navigator.clipboard.writeText(copyText);
};

export const exportDashboardJson = (
  panels: DashboardPanelProps[],
  dashboardDetails: DashboardProps,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      const newPanels = getSaveDashboardPanels(panels);
      const jsonModel = { ...dashboardDetails, panels: newPanels };

      const dataStr =
        'data:text/json;charset=utf-8,' +
        encodeURIComponent(JSON.stringify(jsonModel, null, 2));
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataStr);
      const fileName = dashboardDetails.title.replace(/\s/g, '_');
      downloadAnchorNode.setAttribute('download', `${fileName}.json`);
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const validateDashboardJson = (
  json: DashboardPanelProps & {
    panels: DashboardPanelProps[];
  },
): boolean => {
  if (!json || typeof json !== 'object') {
    return false;
  }

  if (!Array.isArray(json.panels)) {
    return false;
  }

  for (const panel of json.panels) {
    if (!validatePanel(panel)) {
      return false;
    }
  }

  return true;
};

const validatePanel = (panel: DashboardPanelProps): boolean => {
  if (!panel) {
    return false;
  }

  if (!panel.type) {
    return false;
  }
  return true;
};
