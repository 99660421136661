import { CompactTooltipContainerV2 } from 'components/Timeseries';
import { tooltipFormatter } from 'components/Timeseries/utils';
import React, { ReactElement } from 'react';
import { getDrawnEventTooltip } from 'utils';

const ChartGridOutlierSpanTooltip = ({
  tooltip,
}: {
  tooltip: ReturnType<typeof getDrawnEventTooltip>;
}): ReactElement => {
  return (
    <CompactTooltipContainerV2 coords={tooltip}>
      <div
        className="uplot__compact-tooltip"
        style={{
          alignItems: tooltip.positionX === 'left' ? 'flex-end' : 'flex-start',
        }}
      >
        <div className="uplot__compact-tooltip__value">
          {tooltip.spanData.spanCount} span
        </div>
        <div className="uplot__compact-tooltip__item">
          <b>Duration:</b> {tooltipFormatter(tooltip.spanData.duration, 'ns')}
        </div>
        <div className="uplot__compact-tooltip__item">
          <b>Trace Id:</b> {tooltip.spanData.traceId}
        </div>
        <div className="uplot__compact-tooltip__item">
          <b>Status Code:</b> {tooltip.spanData.statusCode}
        </div>
      </div>
    </CompactTooltipContainerV2>
  );
};

export default ChartGridOutlierSpanTooltip;
