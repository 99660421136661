import {
  DownloadPopover,
  Loader,
  Table,
  TableHeader,
  useColumnsState,
  useModalsContext,
  useTableOptions,
  useTableBESort,
  OverlayMessageProps,
  OverlayMessage,
} from 'components';
import React, { useMemo } from 'react';
import { DownloadType } from 'types';
import useRumTableRequest from './useRumTableRequest';
import { RumTableColumnKey } from './contants';
import {
  rumErrorTableColumns,
  rumActionTableColumns,
  rumLongTaskTableColumns,
  rumResourceTableColumns,
  rumSessionTableColumns,
  rumViewTableColumns,
} from './rumEventTableColumns';
import {
  actionDefaultColumns,
  errorDefaultColumns,
  isSortingDisabledForRumColumn,
  longTaskDefaultColumns,
  resourcesDefaultColumns,
  sessionDefaultColumns,
  viewDefaultColumns,
} from './utils';
import useRumState from './hooks/useRumState';
import { RumEvent, RumEventType, RumEventWithTabToOpen } from './types';
import RumDownloadModal from './RumDownloadModal';

type Props = {
  setActiveRumEvent: (rumEvent: RumEventWithTabToOpen) => void;
  rumState: ReturnType<typeof useRumState>;
};

const RumTable = ({ setActiveRumEvent, rumState }: Props) => {
  const modals = useModalsContext();

  const {
    applicationFilter,
    dateState,
    eventType,
    facetRegexState,
    idSearch,
    rumTableSortState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
  } = rumState;

  const [date] = dateState;

  const onRowClick = ({ row }: { row: RumEvent }) => {
    setActiveRumEvent(row);
  };
  const viewColumns = rumViewTableColumns(setActiveRumEvent);
  const actionColumns = rumActionTableColumns(setActiveRumEvent);
  const longTaskColumns = rumLongTaskTableColumns(setActiveRumEvent);
  const resourcesColumns = rumResourceTableColumns(setActiveRumEvent);
  const errorColumns = rumErrorTableColumns(setActiveRumEvent);
  const sessionColumns = rumSessionTableColumns();

  const viewsColumnsState = useColumnsState({
    columns: viewColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        ...viewDefaultColumns,
      },
    },
    key: 'rum-view-table',
  });

  const actionsColumnsState = useColumnsState({
    columns: actionColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        ...actionDefaultColumns,
      },
    },
    key: 'rum-action-table',
  });

  const longTaskColumnsState = useColumnsState({
    columns: longTaskColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        ...longTaskDefaultColumns,
      },
    },
    key: 'rum-long-task-table',
  });

  const resourcesColumnsState = useColumnsState({
    columns: resourcesColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        ...resourcesDefaultColumns,
      },
    },
    key: 'rum-resources-table',
  });

  const errorColumnsState = useColumnsState({
    columns: errorColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        ...errorDefaultColumns,
      },
    },
    key: 'rum-error-table',
  });

  const sessionColumnState = useColumnsState({
    columns: sessionColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        ...sessionDefaultColumns,
      },
    },
    key: 'rum-session-table',
  });

  const columnsState = useMemo(() => {
    switch (eventType) {
      case RumEventType.VIEW:
        return viewsColumnsState;
      case RumEventType.ACTION:
        return actionsColumnsState;
      case RumEventType.LONGTASK:
        return longTaskColumnsState;
      case RumEventType.RESOURCE:
        return resourcesColumnsState;
      case RumEventType.ERROR:
        return errorColumnsState;
      case RumEventType.SESSION:
        return sessionColumnState;
    }
  }, [
    errorColumnsState,
    eventType,
    viewsColumnsState,
    actionsColumnsState,
    longTaskColumnsState,
    resourcesColumnsState,
    sessionColumnState,
  ]);

  const rumEventsRequest = useRumTableRequest({
    applicationFilter,
    columnsState,
    date,
    eventType,
    facetRegexState,
    idSearch,
    rumTableSortState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
  });

  const columns = useMemo(() => {
    switch (eventType) {
      case RumEventType.VIEW:
        return viewColumns;
      case RumEventType.ACTION:
        return actionColumns;
      case RumEventType.LONGTASK:
        return longTaskColumns;
      case RumEventType.RESOURCE:
        return resourcesColumns;
      case RumEventType.ERROR:
        return errorColumns;
      case RumEventType.SESSION:
        return sessionColumns;
    }
  }, [
    eventType,
    viewColumns,
    actionColumns,
    longTaskColumns,
    resourcesColumns,
    errorColumns,
    sessionColumns,
  ]);

  const rows = rumEventsRequest.result.data || [];

  const tableOptions = useTableOptions();
  const tableSort = useTableBESort({
    columns,
    initialKey: RumTableColumnKey.date,
    rows: rows,
    onSortChange: ({ sortBy, sortOrder }) => {
      rumTableSortState?.sortBy({ sortBy, sortOrder });
    },
  });

  const openRumDownloadModal = (downloadType: string) => {
    modals.push(
      <RumDownloadModal
        applicationFilter={applicationFilter}
        columnsState={columnsState}
        date={date}
        downloadType={downloadType}
        facetRegexState={facetRegexState}
        eventType={eventType}
        idSearch={idSearch}
        rumTableSortState={rumTableSortState}
        selectedFacetRangeByNameState={selectedFacetRangeByNameState}
        selectedFacetValuesByNameState={selectedFacetValuesByNameState}
      />,
      true,
    );
  };

  const overlayMessageProps: OverlayMessageProps = rumEventsRequest?.result
    ?.error
    ? {
        isActive: true,
        iconName: 'warning',
        message: <>Failed to fetch RUM table data</>,
      }
    : { isActive: false };

  return (
    <>
      <TableHeader
        columnsState={columnsState}
        tableOptions={tableOptions}
        tableOptionsClassName="rum-events__toolbar overflow-auto"
      >
        <div className="flex">
          <div className="ml-3">
            <DownloadPopover
              downloadTypes={[DownloadType.csv, DownloadType.json]}
              openModal={openRumDownloadModal}
            />
          </div>
        </div>
      </TableHeader>
      <Loader
        className="rum-events__table overflow-auto"
        isLoading={rumEventsRequest.isLoading}
        dataTestId="rum-events-table"
      >
        <OverlayMessage {...overlayMessageProps}>
          <Table
            className="table--padded table--bordered-cells font-robotoMono"
            columns={columnsState.renderedColumns}
            externalTableSort={tableSort}
            isFullWidth
            isResizingEnabled
            isSortingEnabled
            isSortingDisabledForColumn={isSortingDisabledForRumColumn}
            isStickyHeaderEnabled
            onRowClick={onRowClick}
            onScrollEnd={rumEventsRequest.onScrollEnd}
            rows={tableSort.sortedRows}
            tableKey="rum-events-table"
          />
        </OverlayMessage>
      </Loader>
    </>
  );
};

export default RumTable;
