import { AutocompleteOption } from 'components';
import {
  DashboardTemplateProps,
  DateSelection,
  PrometheusDataset,
} from 'types';
import {
  checkAndAssignDefaultValue,
  getTemplateDependencies,
  transformTemplateQuery,
  transformTemplateQueryResultValue,
} from 'utils';

import promqlLabelValues from './promqlLabelValues';
import promqlQuery from './promqlQuery';

type Args = {
  date: DateSelection;
  formatTemplateOptions: ({
    templateOptions,
    templates,
  }: {
    templateOptions: { [key: number]: AutocompleteOption[] };
    templates: DashboardTemplateProps[];
  }) => AutocompleteOption[][];
  templates: DashboardTemplateProps[];
  templateValues: TemplateValueProps;
  type?: 'init' | 'reload';
};
type TemplateValueProps = { [key: string]: string | string[] };

const loadTemplateValues = async (
  date: DateSelection,
  template: DashboardTemplateProps,
  newTemplateValues: TemplateValueProps,
): Promise<AutocompleteOption[]> => {
  return new Promise((resolve, reject) => {
    const { options, query, type: templateType } = template;

    if (templateType === 'textbox' || templateType === 'adhoc') {
      resolve([]);
      return;
    }

    if (options && options.length > 0) {
      resolve(options.map(({ text, value }) => ({ label: text, value })));
      return;
    }

    const parsedTemplateQuery = transformTemplateQuery(
      query.query,
      newTemplateValues,
    );
    const { type, query: queryStr, label } = parsedTemplateQuery;

    if (type === 'query_result') {
      const newQueryStr = queryStr.replace('+', '%2B');
      promqlQuery({ promqlQueries: [newQueryStr], responseFormat: 'none' })
        .then((result: PrometheusDataset[]) => {
          const queryResultOptions = transformTemplateQueryResultValue(
            result,
            template,
          );
          resolve(queryResultOptions);
        })
        .catch(() => resolve([]));
      return;
    }

    if (type !== 'label_values' && type !== 'series') {
      resolve([]);
      return;
    }

    promqlLabelValues({
      date,
      label,
      matcher: type === 'series' ? queryStr : '',
    })
      .then((labelList) => {
        if (!labelList) resolve([]);
        resolve(
          labelList
            .filter((label: string) => label.trim() !== '')
            .map((label: string) => ({ label, value: label })),
        );
      })
      .catch(() => resolve([]));
  });
};

const loadTemplateDataAsynchronously = async ({
  date,
  dependencies,
  templates,
  initialData,
  newTemplateValues,
  templateValues = {},
}: {
  date: DateSelection;
  dependencies: { [key: number]: number[] };
  templates: DashboardTemplateProps[];
  initialData: { [key: number]: AutocompleteOption[] };
  newTemplateValues: TemplateValueProps;
  templateValues: TemplateValueProps;
}) => {
  if (Object.keys(dependencies).length === 0) {
    return initialData;
  }

  const dependenciesKeys = Object.keys(dependencies);
  const noDependencies = dependenciesKeys.filter(
    (key) => dependencies[Number(key)].length === 0,
  );

  const datasets = await Promise.all(
    noDependencies.map(async (key) => {
      const template = templates[Number(key)];
      const data = await loadTemplateValues(date, template, newTemplateValues);
      return { key, options: data };
    }),
  );

  datasets.forEach((dataset) => {
    initialData[Number(dataset.key)] = dataset.options;
    checkAndAssignDefaultValue({
      templateIndex: Number(dataset.key),
      options: dataset.options,
      templates: templates,
      newTemplateValues,
      templateValues,
    });

    // remove the dependency
    dependenciesKeys.forEach((depsKey) => {
      if (dependencies[Number(depsKey)]) {
        const dependentIndex = dependencies[Number(depsKey)].findIndex(
          (d) => d === Number(dataset.key),
        );
        if (dependentIndex !== -1) {
          dependencies[Number(depsKey)].splice(dependentIndex, 1);
        }
      }
    });
    delete dependencies[Number(dataset.key)];
  });
  await loadTemplateDataAsynchronously({
    date,
    dependencies,
    templates,
    initialData,
    newTemplateValues,
    templateValues,
  });

  return initialData;
};

const getDashboardFilterLabelsValues = async ({
  date,
  formatTemplateOptions,
  templates,
  templateValues,
  type,
}: Args) => {
  const dependencies = getTemplateDependencies(templates);
  const initialData: { [key: number]: AutocompleteOption[] } = {};
  const newTemplateValues: TemplateValueProps =
    type === 'init' ? {} : { ...templateValues };

  const templateOptions = await loadTemplateDataAsynchronously({
    date,
    dependencies,
    templates,
    initialData,
    newTemplateValues,
    templateValues,
  });

  return {
    templateOptions: formatTemplateOptions({ templateOptions, templates }),
    templateValues: newTemplateValues,
  };
};

export default getDashboardFilterLabelsValues;
