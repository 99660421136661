import { fetchGrafanaApi } from 'requests';

import { AlertMuteTimingProps } from '../types';

const getGrafanaAlertManagerMuteTiming = (
  muteTimingOnly = true,
): Promise<AlertMuteTimingProps[]> => {
  return fetchGrafanaApi(
    `grafana/api/alertmanager/grafana/config/api/v1/alerts`,
    { headers: { 'Content-Type': 'application/json' }, method: 'GET' },
  ).then(
    (result: {
      alertmanager_config: { mute_time_intervals: AlertMuteTimingProps[] };
    }) => {
      if (muteTimingOnly) {
        return result.alertmanager_config?.mute_time_intervals || [];
      }
      return result;
    },
  );
};

export default getGrafanaAlertManagerMuteTiming;
