import React from 'react';

import { Box } from 'components/layouts/Box';
import { Table, TableRow } from 'components';
import { kubeNamespaceCount } from 'requests';
import KubernetesRightSideBar from '../KubernetesRightSideBar';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellPercentageBar,
  KubeCellStatus,
  KubeCellTimestamp,
  KubeTableCell,
  KubeTableEntityTags,
} from './Components';
import useAsync from '../hooks/useAsync';
import { getAggregationsByEntities } from '../utils/selectorsfunction';
import { generatePath, useParams } from 'react-router-dom';
import { useKubernetesTableDataForPod } from './KubernetesTableForPod';
import KubernetesTableGroup from './KubernetesTableGroup';
import { useMergedGroupByFilter } from './useEntitiesData';

const renderEmptyCell = () => '';

function KubernetesTableForPodRow({ row }) {
  const { date, entitiesType, navigate } = useKubernetesController();

  const filters = useMergedGroupByFilter(row.groupBys);
  const [rows] = useKubernetesTableDataForPod({
    entitiesType,
    date,
    page: 0,
    limit: 50,
    filters,
    kubesSort: null,
  });

  const setActiveKube = (row) => {
    navigate(({ location, params, pathTemplate }) => {
      return {
        ...location,

        pathname: generatePath(pathTemplate, {
          ...params,

          activeKube: row.pod.metadata.uid,
          tab: 'yaml',
        }),
      };
    });
  };

  const activeKubeParam = useParams()['activeKube'];
  const activeKube = (
    typeof activeKubeParam === 'string' && rows ? rows : []
  ).find((row) => {
    return row.pod.metadata.uid === activeKubeParam;
  });

  return (
    <Box width="full" height="full">
      {activeKube && <KubernetesRightSideBar activeKube={activeKube} />}
      <Table
        className="-my-px"
        renderHeader={false}
        rows={rows ?? []}
        renderRow={(props) => (
          <TableRow
            {...props}
            className="border-y"
            onRowClickHandler={(row) => () => setActiveKube(row)}
          />
        )}
        columns={[
          { key: 'header', label: '' },
          {
            key: 'pod_name',
            label: 'Pod',
            renderCell(prop) {
              const podName = prop?.row.pod?.metadata?.name;
              return (
                <KubeTableCell
                  {...prop}
                  className="kubernetes__table__cell__entity-name"
                  tooltipText={podName}
                >
                  {podName}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'pod_status',
            label: 'Status',
            renderCell(prop) {
              return (
                <KubeCellStatus {...prop} status={prop?.row?.pod?.status} />
              );
            },
          },
          {
            key: 'kube_cluster_name',
            label: 'Cluster',
            renderCell(prop) {
              return <KubeCellCluster {...prop} />;
            },
          },
          {
            key: 'metadata.namespace',
            label: 'Namespace',
            renderCell(prop) {
              return <KubeCellNamespace {...prop} />;
            },
          },
          {
            key: 'metadata.creationTimestamp',
            label: 'Age',
            renderCell(prop) {
              const timestamp = prop.row.pod.metadata.creationTimestamp || '';
              return <KubeCellTimestamp {...prop} timestamp={timestamp} />;
            },
          },
          {
            key: 'headerReady',
            label: 'Ready',
            renderCell(prop) {
              const containerStatuses = prop?.row?.pod?.containerStatuses;
              if (!containerStatuses) return <>-</>;
              const denominator = containerStatuses.length;
              const numerator = containerStatuses.filter(
                (status: { ready?: boolean }) => status?.ready,
              ).length;

              return (
                <KubeTableCell {...prop} align="right">
                  {`${numerator}/${denominator}`}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'restartCount',
            label: 'Restarts',
            renderCell(prop) {
              const containerStatuses = prop?.row?.pod?.containerStatuses;
              if (!containerStatuses) return 0;
              return (
                <KubeTableCell {...prop} align="right">
                  {containerStatuses.reduce(
                    (acc: number, elem: { restartCount?: number }): number =>
                      acc + (elem?.restartCount || 0),
                    0,
                  )}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'headerCpuUsage',
            label: '% CPU Usage',
            renderCell(prop) {
              const percentage = prop.row.cpuPercentage * 100;
              return (
                <KubeTableCell {...prop} align="right">
                  <KubeCellPercentageBar percent={percentage} />
                </KubeTableCell>
              );
            },
          },
          {
            key: 'headerMemUsage',
            label: '% Mem Usage',
            renderCell(prop) {
              const percent = prop.row.memoryPercentage * 100;
              return (
                <KubeTableCell {...prop} align="right">
                  <KubeCellPercentageBar percent={percent} />
                </KubeTableCell>
              );
            },
          },
        ]}
      />
    </Box>
  );
}

export default function KubernetesTableForPodGroup() {
  const { date, entitiesType, facets, groupBySearchTerms } =
    useKubernetesController();

  const [rows] = useAsync(
    async (signal: AbortSignal) => {
      const [cpu, memory, entities] = await Promise.all([
        null, // podCPUUsage(groupBySearchTerms, date)({ signal }),
        null, // podMemoryUsage(groupBySearchTerms, date)({ signal }),
        kubeNamespaceCount(
          {
            entityType: entitiesType,
            selectedFacetValuesByName: facets,
            groupBy: groupBySearchTerms,
            aggregations: getAggregationsByEntities(
              entitiesType,
              groupBySearchTerms,
            ),
          },
          { signal },
        ),
      ]);

      return entities.map((podData) => {
        // const key = getGroupKey(podData.groupBys);
        // const cpuPercentage = cpu[key] || 0;
        // const memoryPercentage = memory[key] || 0;

        return {
          ...podData,
          // cpuPercentage,
          // memoryPercentage,
        };
      });
    },
    [date, facets, groupBySearchTerms],
  );

  return (
    <KubernetesTableGroup
      rows={rows ?? []}
      columns={[
        {
          key: 'headerpod_name',
          label: 'Pod Group',
          renderCell(prop) {
            return <KubeTableEntityTags {...prop} />;
          },
        },
        {
          key: 'headerpod_status',
          label: 'Status',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerkube_cluster_name',
          label: 'Cluster',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerkube_namespace',
          label: 'Namespace',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerage',
          label: 'Age',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerready',
          label: 'Ready',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headerrestarts',
          label: 'Restarts',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headercpu',
          label: '% CPU Usage',
          renderCell: renderEmptyCell,
        },
        {
          key: 'headermemusage',
          label: '% Mem Usage',
          renderCell: renderEmptyCell,
        },
      ]}
      renderExpandedRow={(props) => <KubernetesTableForPodRow {...props} />}
    />
  );
}
