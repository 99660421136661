import dayjs from 'dayjs';
import { pickBy } from 'lodash';
import { onPromiseError } from 'utils';
import queryRumService from './queryRumService';
import { RumEvent, RUMQueryServiceResponse, RumEventType } from '../types';
import {
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types/selectedFacets';
import { buildRumFilter, isEventTableUIPaginated } from './utils';
import { useColumnsState } from 'components/TableOptionsPopover';
import { getDurationField } from '../RumFlameGraph/utils';

type Args = {
  applicationFilter?: string;
  columnsState?: ReturnType<typeof useColumnsState>;
  cursor?: string;
  errorGroupingKey?: string;
  eventType: RumEventType;
  endTimeUnix: number;
  facetRegex?: FacetRegexTerm[];
  startTimeUnix: number;
  idSearch?: string;
  limit?: number;
  selectedFields?: string[];
  selectedFacetRangeByName?: SelectedFacetRangeByName;
  selectedFacetValuesByName?: SelectedFacetValuesByName;
  sortBy?: string;
  sortOrder?: string;
  viewId?: string;
  actionId?: string;
  actionIds?: string;
  longTaskId?: string;
  resourceId?: string;
  sessionId?: string;
};

const VIEW_FIELDS = `[
  "view.id",
  "view.url_path",
  "view.url",
  "view.loading_type",
  "view.largest_contentful_paint",
  "geo.country",
  "env",
  "service",
  "version",
  "session.type",
  "usr.id",
  "usr.name",
  "usr.email",
  "geo.country",
  "geo.city",
  "device.type",
  "browser.name",
  "os.name",
  "view.loading_type",
  "view.loading_time",
  "view.cumulative_layout_shift",
  "view.first_input_delay",
  "view.largest_contentful_paint",
  "view.interaction_to_next_paint",
  "view.time_spent",
]`;

const ACTION_FIELDS = `[
  "action.id",
  "action.target.name",
  "action.type",
  "action.loading_time",
  "action.frustration.type",
  "application.id",
  "service",
  "device.type",
  "device.model",
  "display.viewport.width",
  "display.viewport.height",
  "view.name",
  "view.id",
  "view.url",
]`;

const LONGTASK_FIELDS = `[
  "long_task.id",
  "long_task.duration",
  "long_task.blocking_duration",
  "application.id",
  "service",
  "device.type",
  "device.model",
  "device.brand",
  "source",
  "version",
  "session.id",
  "view.url",
  "view.id",
]`;

const RESOURCE_FIELDS = `[
  "resource.name",
  "resource.id",
  "resource.type",
  "resource.size",
  "resource.duration",
  "resource.first_byte.start",
  "resource.first_byte.duration",
  "resource.download.start",
  "resource.download.duration",
  "resource.size",
  "resource.url",
  "application.id",
  "service",
  "device.type",
  "device.model",
  "device.brand",
  "source",
  "version",
  "session.id",
  "view.id",
]`;

const ERROR_FIELDS = `[
  "application.id",
  "build_version",
  "device.type",
  "device.model",
  "device.brand",
  "error.id",
  "error.message",
  "error.source",
  "error.type",
  "service",
  "source",
  "session.id",
  "view.name",
  "view.url_path",
]`;

const SESSION_FIELDS = `[
  "application.id",
  "browser.name",
  "device.type",
  "service",
  "session.is_active",
  "session.id",
  "session.has_replay",
  "session.type",
  "session.time_spent",
  "session.view_count",
  "session.error_count",
  "session.action_count",
  "session.long_task_count",
  "session.frustration_count",
  "session.initial_view_url",
  "session.last_view_url",
]`;

const getFieldsFromSelection = (
  type: RumEventType,
  selectedFields?: string[],
) => {
  const durationField = getDurationField(type);
  const fieldsFromSelection = selectedFields || [];

  if (durationField && !fieldsFromSelection.includes(durationField)) {
    fieldsFromSelection.push(durationField);
  }

  const fieldsSting = fieldsFromSelection
    .map((field) => `"${field}"`)
    .join(',');
  return `${fieldsSting}`;
};

const getFields = (type: RumEventType, selectedFields?: string[]): string => {
  const fieldsFromSelection = selectedFields
    ? getFieldsFromSelection(type, selectedFields)
    : null;

  switch (type) {
    case RumEventType.VIEW:
      return fieldsFromSelection ? `[${fieldsFromSelection}]` : VIEW_FIELDS;
    case RumEventType.ACTION:
      return fieldsFromSelection ? `[${fieldsFromSelection}]` : ACTION_FIELDS;
    case RumEventType.LONGTASK:
      return fieldsFromSelection ? `[${fieldsFromSelection}]` : LONGTASK_FIELDS;
    case RumEventType.RESOURCE:
      return fieldsFromSelection ? `[${fieldsFromSelection}]` : RESOURCE_FIELDS;
    case RumEventType.ERROR:
      return fieldsFromSelection ? `[${fieldsFromSelection}]` : ERROR_FIELDS;
    case RumEventType.SESSION:
      return fieldsFromSelection ? `[${fieldsFromSelection}]` : SESSION_FIELDS;
    default:
      return '[]';
  }
};

const getSortByField = (sortBy: string) => {
  if (sortBy === 'time') {
    return 'date';
  }
  return sortBy;
};

const getRumEvents = async ({
  applicationFilter,
  columnsState,
  cursor,
  errorGroupingKey,
  facetRegex,
  eventType,
  endTimeUnix,
  limit,
  startTimeUnix,
  idSearch,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  sortBy,
  sortOrder,
  viewId,
  actionId,
  actionIds,
  longTaskId,
  resourceId,
  sessionId,
}: Args): Promise<{ data: RumEvent[]; cursor: string }> => {
  const selectedColumns = columnsState?.state?.selectedColumns
    ? Object.keys(
        pickBy(
          columnsState.state.selectedColumns,
          (value: number) => value === 1,
        ),
      ).filter((item) => item !== 'time')
    : null;

  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  const filterOptions = {
    applicationFilter,
    errorGroupingKey,
    facetRegex,
    idSearch,
    selectedFacetRangeByName,
    selectedFacetValuesByName,
    ...(viewId ? { viewId } : null),
    ...(actionId ? { actionId } : null),
    ...(actionIds ? { actionIds } : null),
    ...(longTaskId ? { longTaskId } : null),
    ...(resourceId ? { resourceId } : null),
    ...(sessionId ? { sessionId } : null),
    eventType,
  };

  const eventTableLimit = isEventTableUIPaginated(eventType) ? 2000 : 200;
  const activeLimit = limit || eventTableLimit;

  return queryRumService<RUMQueryServiceResponse, 'eventDetail'>(`
  query {
    eventDetail(
      limit: ${activeLimit},
      ${cursor ? `cursor: "${cursor}",` : ''}
      eventType: ${eventType},
      timestamp: "${endTime.format()}"
      durationSecs: ${durationSecs}
      filter: ${buildRumFilter(filterOptions)}
      fields: ${getFields(eventType, selectedColumns)}
      ${sortOrder ? `sortOrder: "${sortOrder}"` : ''}
      ${sortBy ? `sortByField: "${getSortByField(sortBy)}"` : ''}
    ) {
      events {
        time
        data
      }
      cursor
    }
  }
  `).then((data) => {
    const events = data?.eventDetail?.events || [];
    const eventMappedWithEventType = events.map((event) => ({
      ...event,
      eventType,
    }));

    return {
      data: eventMappedWithEventType,
      cursor: isEventTableUIPaginated(eventType)
        ? ''
        : data?.eventDetail?.cursor || null,
    };
  }, onPromiseError);
};

export default getRumEvents;
