/* eslint consistent-default-export-name/default-export-match-filename: 0 */
export const cancelAnimationFramePolyfill =
  globalThis.cancelAnimationFrame ||
  globalThis.webkitCancelAnimationFrame ||
  globalThis.mozCancelAnimationFrame ||
  globalThis.oCancelAnimationFrame ||
  globalThis.msCancelAnimationFrame ||
  globalThis.clearTimeout;

const nativeRequestAnimationFrame =
  globalThis.requestAnimationFrame ||
  globalThis.webkitRequestAnimationFrame ||
  globalThis.mozRequestAnimationFrame ||
  globalThis.oRequestAnimationFrame ||
  globalThis.msRequestAnimationFrame;

let lastTime = 0;

const customRequestAnimationFrame = (callback) => {
  const currentTime = Date.now();
  const timeDelay = Math.max(0, 16 - (currentTime - lastTime));
  lastTime = currentTime + timeDelay;

  return setTimeout(() => {
    callback(Date.now());
  }, timeDelay);
};

export const requestAnimationFramePolyfill =
  nativeRequestAnimationFrame || customRequestAnimationFrame;

export default requestAnimationFramePolyfill;
