import { useState } from 'react';
import { Filter } from './types';

type Args = {
  initialState?: Filter[][];
  shouldWriteToUrl?: boolean;
};

const useFiltersStates = (options?: Args) => {
  const [state, setState] = useState<Filter[][]>(options?.initialState || [[]]);

  const getState = (stateIndex: number) => {
    return state[stateIndex];
  };

  const getSetState =
    (stateIndex: number) =>
    (nextStateOrCallback: Filter[] | ((filters: Filter[]) => Filter[])) => {
      setState((prevState) => {
        const nextState = [...prevState];
        const nextStateItem =
          typeof nextStateOrCallback === 'function'
            ? nextStateOrCallback(nextState[stateIndex])
            : nextStateOrCallback;
        nextState[stateIndex] = nextStateItem;
        return nextState;
      });
    };

  const add = (newFiltersState = []) => {
    setState((prevState) => [...prevState, newFiltersState]);
  };

  const removeByIndex = (index: number) => {
    setState((prevState) => {
      const nextState = [...prevState];
      nextState.splice(index, 1);
      return nextState;
    });
  };

  // State item methods
  const addHandler = (stateIndex: number) => (filter: Filter) => {
    setState((prevState) => {
      const nextState = [...prevState];
      const nextStateItem = [...nextState[stateIndex], filter];
      nextState[stateIndex] = nextStateItem;
      return nextState;
    });
  };

  const deleteByIndexHandler = (stateIndex: number) => (index: number) => {
    setState((prevState) => {
      const nextState = [...prevState];

      const nextStateItem = [...nextState[stateIndex]];
      nextStateItem.splice(index, 1);

      nextState[stateIndex] = nextStateItem;
      return nextState;
    });
  };

  const replaceByIndexHandler =
    (stateIndex: number) => (filter: Filter, filterIndex: number) => {
      setState((prevState) => {
        const nextState = [...prevState];

        const nextStateItem = [...nextState[stateIndex]];
        nextStateItem[filterIndex] = filter;

        nextState[stateIndex] = nextStateItem;
        return nextState;
      });
    };

  const getFiltersStateByIndex = (index: number) => {
    if (index < state.length) {
      return {
        add: addHandler(index),
        deleteByIndex: deleteByIndexHandler(index),
        replaceByIndex: replaceByIndexHandler(index),
        setState: getSetState(index),
        state: getState(index),
      };
    }

    return {
      add: () => {},
      deleteByIndex: () => {},
      replaceByIndex: () => {},
      setState: () => {},
      state: [],
    };
  };

  return {
    add,
    getFiltersStateByIndex,
    removeByIndex,
    setState,
    state,
  };
};

export default useFiltersStates;
