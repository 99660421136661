import {
  ChipWithLabel,
  DateFormatter,
  IconWithLabel,
  LatencyBreakdown,
  ServiceWithLabels,
  StatusTag,
  TableColumnType,
} from 'components';
import {
  dateTimeFormatWithMilliseconds,
  iconsBySpanType,
} from 'kfuse-constants';
import React from 'react';
import { Link } from 'react-router-dom';
import { DateSelection, Trace } from 'types';
import { formatDurationNs, resolveColorFromMap } from 'utils';

type RenderCellProps = {
  row: Trace;
  value: any;
};

export enum TracesTableColumnKey {
  spanStartTimeNs = 'span.startTimeNs',
  spanAttributesServiceName = 'span.service.name',
  spanName = 'span.name',
  duration = 'duration',
  spanCount = 'traceMetrics.spanCount',
  spanMethod = 'span.method',
  spanAttributesStatusCode = 'span.statusCode',
  spanEndpoint = 'span.endpoint',
  traceMetrics = 'traceMetrics',
  spanId = 'span.spanId',
  spanType = 'span.attributes.span_type',
  parentSpanId = 'span.parentSpanId',
  traceId = 'traceId',
  rootSpan = 'span.rootSpan',
}

export const mapClientKeyToServerKey = (sortBy: string) => {
  switch (sortBy) {
    case 'span.startTimeNs':
      return 'timestamp';
    case 'span.service.name':
      return 'service_name';
    default:
      return sortBy;
  }
};

const SORT_BY_ALLOWED_COLUMNS: Record<string, boolean> = {
  [TracesTableColumnKey.spanStartTimeNs]: true,
  [TracesTableColumnKey.spanAttributesServiceName]: true,
  [TracesTableColumnKey.spanName]: true,
  [TracesTableColumnKey.duration]: true,
  [TracesTableColumnKey.spanType]: true,
  [TracesTableColumnKey.rootSpan]: true,
};

export const isSortingDisabledForTraceColumn = (columnKey: string) => {
  return !SORT_BY_ALLOWED_COLUMNS[columnKey as TracesTableColumnKey];
};

type Args = {
  colorsByServiceName?: Record<string, string>;
};

export const tracesTableColumns = ({ colorsByServiceName = {} }: Args) => [
  {
    key: TracesTableColumnKey.spanStartTimeNs,
    label: 'Date',
    renderCell: ({ value }: RenderCellProps) => (
      <DateFormatter
        formatString={dateTimeFormatWithMilliseconds}
        unixTimestamp={Math.floor(value / 1000000)}
      />
    ),
  },
  {
    key: TracesTableColumnKey.spanAttributesServiceName,
    label: 'Service',
    renderCell: ({ row, value }: RenderCellProps) => {
      const { service } = row.span;

      return (
        <ChipWithLabel
          color={resolveColorFromMap(colorsByServiceName, service?.name)}
          label={
            <IconWithLabel
              icon={iconsBySpanType[row.span.attributes['span_type']]}
              label={
                <Link className="link" to={`/apm/services/${service?.hash}`}>
                  <ServiceWithLabels
                    distinctLabels={service?.distinctLabels || {}}
                    labels={service?.labels || {}}
                    name={value}
                  />
                </Link>
              }
            />
          }
        />
      );
    },
  },
  { key: TracesTableColumnKey.spanName, label: 'Span Name' },
  {
    key: TracesTableColumnKey.duration,
    label: 'Duration',
    renderCell: ({ row }: RenderCellProps) =>
      formatDurationNs(row.span.endTimeNs - row.span.startTimeNs, 1, 2),
    type: TableColumnType.NUMBER,
    value: ({ row }: RenderCellProps) =>
      row.span.endTimeNs - row.span.startTimeNs,
  },
  { key: TracesTableColumnKey.spanMethod, label: 'Method' },
  {
    key: TracesTableColumnKey.spanAttributesStatusCode,
    label: 'Status Code',
    renderCell: ({ value }: RenderCellProps) => <StatusTag status={value} />,
  },
  { key: TracesTableColumnKey.spanEndpoint, label: 'Endpoint' },
  {
    key: TracesTableColumnKey.spanCount,
    label: 'Trace Span Count',
    type: TableColumnType.NUMBER,
  },
  {
    key: TracesTableColumnKey.traceMetrics,
    label: 'Trace Latency Breakdown',
    renderCell: ({ value }: RenderCellProps) => (
      <LatencyBreakdown
        colorsByServiceName={colorsByServiceName}
        traceMetrics={value}
      />
    ),
  },
  {
    key: TracesTableColumnKey.spanType,
    label: 'Span Type',
    renderCell: ({ value }: { value: string }) => (
      <IconWithLabel icon={iconsBySpanType[value]} label={value} />
    ),
    value: ({ row }: RenderCellProps) => row.span.attributes['span_type'],
  },
  {
    key: TracesTableColumnKey.spanId,
    label: 'Span ID',
  },
  {
    key: TracesTableColumnKey.parentSpanId,
    label: 'Parent Span ID',
  },
  {
    key: TracesTableColumnKey.traceId,
    label: 'Trace ID',
  },
  {
    key: TracesTableColumnKey.rootSpan,
    label: 'Root Span',
    renderCell: ({ value }: { value: boolean }) => String(value),
  },
];
