import { QueryDataPropsRange } from 'types/QueryData';
import { Series } from 'uplot';
import { getColorForOneSeries } from 'utils/colors';
import {
  CHART_LINE_WIDTH_MEDIUM,
  CHART_LINE_WIDTH_THICK,
} from 'utils/chartConfig';

import { DataFrame } from '../types';

const timeseriesDataTransformer = (
  dataFrame: DataFrame,
): QueryDataPropsRange => {
  const { data, timestamps } = dataFrame;
  const transformedData = [timestamps];
  const series: Series[] = [];

  if (data.length === 0) {
    return { data: [], series: [], minValue: 0, maxValue: 1 };
  }

  data.forEach(({ values, label, displayLabel }, idx) => {
    transformedData.push(values);
    const isOutlier = label['outlier'] === 'true';
    const isOutlierFalse = label['outlier'] === 'false';
    series.push({
      label: displayLabel,
      points: { show: false },
      stroke: getColorForOneSeries(label, idx),
      dash: isOutlierFalse ? [5, 5] : undefined,
      show: true,
      width: isOutlier ? CHART_LINE_WIDTH_THICK : CHART_LINE_WIDTH_MEDIUM,
    });
  });

  return {
    data: transformedData,
    series,
    minValue: dataFrame.minValue,
    maxValue: dataFrame.maxValue,
  };
};

export default timeseriesDataTransformer;
