import { SelectedFacetValuesByName } from 'types';
import { onPromiseError } from 'utils';
import queryEventService from './queryEventService';
import buildKubeFilter, {
  buildKubeFilterVariable,
} from './utils/buildKubeFilter';
import { currentEntity } from './utils/kubernetesEntityQuery';

type Args = {
  /**
   * @deprecated Use `filters` instead
   */
  selectedFacetValuesByName?: SelectedFacetValuesByName;
  filters?: SelectedFacetValuesByName | Array<SelectedFacetValuesByName>;
  entityType: string;
  sortBy?: {
    key: string;
    type: 'TAG' | 'LABEL' | 'ANNOTATION' | 'DATA';
  };
  sortOrder?: 'Asc' | 'Desc'; // sorting direction
  offset: number;
  pageLimit: number;
};

function sortQuery(
  sortBy?: Args['sortBy'],
  sortOrder?: Args['sortOrder'],
): string {
  if (sortBy != null && sortOrder != null && sortBy.key && sortBy.type) {
    return `
      sortOrder: ${sortOrder}
      sortBy: {
        key: "${sortBy.key}"
        type: ${sortBy.type}
      }
    `;
  }

  return '';
}

function paginationQuery(offset: number, limit: number | 'all') {
  if (limit === 'all') {
    return '';
  }

  return `
    offset: ${offset}
    limit: ${limit}
  `;
}

const kubeEntities = async (
  {
    entityType,
    offset,
    pageLimit,
    selectedFacetValuesByName,
    sortBy,
    sortOrder,
    filters,
  }: Args,
  init?: RequestInit,
): Promise<unknown> => {
  const variables = {
    entityType,
    filter: buildKubeFilterVariable(
      [selectedFacetValuesByName].concat(filters ?? []).filter(Boolean),
    ),
  };

  if (pageLimit !== 'all') {
    variables.limit = pageLimit;
    variables.offset = offset;
  }

  return queryEventService<Array<any>, 'kubeEntities'>(
    `
    query GetKubeEntities($entityType: KubeEntityType!, $filter: KubeFilter!, $offset: Int, $limit: Int) {
      kubeEntities (
        entityType: $entityType
        filter: $filter
        offset: $offset
        limit: $limit
        ${sortQuery(sortBy, sortOrder)}
      ) {
        ${currentEntity(entityType)}
        }
    }
  `,
    variables,
    init,
  ).then((data) => data.kubeEntities || [], onPromiseError);
};

export default kubeEntities;
