import { PrometheusDataset } from 'types';
import { buildPromQLFilterFromSelectedFacetValuesByName } from 'utils/buildPromQLFilterFromSelectedFacetValuesByName';

export const formatDataset =
  (setState, key) =>
  (result: PrometheusDataset[]): void => {
    result.forEach((dataset) => {
      const { metric, value } = dataset;
      const { functionname, function_arn } = metric;
      if (functionname) {
        setState((prevState) => {
          const kpis = prevState[functionname]
            ? { ...prevState[functionname] }
            : {};
          kpis[key] = Number(value[1]);
          kpis['functionArn'] = function_arn;

          return {
            ...prevState,
            [functionname]: kpis,
          };
        });
      }
    });
  };

export const formatKpiAsEmpty = (setState, key) => () => {
  setState((prevState) => {
    return Object.keys(prevState).reduce((obj, serviceName) => {
      const nextKpis = { ...prevState[serviceName] };
      delete nextKpis[key];

      return {
        ...obj,
        [serviceName]: nextKpis,
      };
    }, {});
  });
};

export const getQueryForServerlessMetric = ({
  functionArn,
  param,
  step,
  selectedFacetValuesByName = {},
}) => {
  const functionArnQuery = functionArn ? `{function_arn="${functionArn}"}` : '';
  const filter = buildPromQLFilterFromSelectedFacetValuesByName({
    selectedFacetValuesByName,
  });
  switch (param) {
    case 'aws_lambda_invocations':
      return `ceil(sum by (functionname, function_arn) (sum_over_time(aws_lambda_invocations${functionArnQuery}${filter}[${step}])))`;
    case 'aws_lambda_duration':
      return `ceil(max by (functionname, function_arn) (max_over_time(aws_lambda_duration_maximum${functionArnQuery}${filter}[${step}])))`;
    case 'aws_lambda_errors':
      return `ceil(max by (functionname, function_arn) (max_over_time(aws_lambda_errors${functionArnQuery}${filter}[${step}])))`;
    default:
      return `ceil(sum by (functionname, function_arn) (sum_over_time(${param}${functionArnQuery}${filter}[${step}])))`;
  }
};
