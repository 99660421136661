import { DateFormatter } from 'components';
import { startCase } from 'lodash';
import React from 'react';
import { dateTimeFormatWithMilliseconds } from 'kfuse-constants';
import { formatDurationNs, formatFileSize, isNullOrUndefined } from 'utils';
import { RumActionEvent } from '../types';
import isDurationFacet from '../utils/isDurationFacet';
import isSizeFacet from '../utils/isSizeFacet';
import sidebarFacets from '../requests/sidebarFacets';

const actionColumns = sidebarFacets
  .filter((facet) => facet.action_facet && facet.field !== 'session.has_replay')
  .map((facet) => {
    return {
      key: facet.field,
      label: facet.name,
    };
  })
  .sort((a, b) => a.label.localeCompare(b.label));
const RumActionStringColumnKey = actionColumns.reduce(
  (acc, column) => {
    acc[column.key] = column.label as string;
    return acc;
  },
  {} as Record<string, string>,
);

export const RumActionColumnKey = {
  date: 'time',
  'application.id': 'Application ID',
  ...RumActionStringColumnKey,
};

type RenderCellPropsOfAction = {
  row: RumActionEvent;
  value: any;
};

export const rumDynamicActionTableColumns = () => [
  {
    key: RumActionColumnKey.date,
    label: 'Date',
    renderCell: ({ row, value }: RenderCellPropsOfAction) => {
      return (
        <DateFormatter
          formatString={dateTimeFormatWithMilliseconds}
          unixTimestamp={value}
        />
      );
    },
  },
  ...Object.keys(RumActionStringColumnKey).map((key) => ({
    key,
    label: startCase(
      RumActionStringColumnKey[key as keyof typeof RumActionStringColumnKey],
    ),
    value: ({ row }: RenderCellPropsOfAction) => {
      const isDurationColumn = isDurationFacet(key);
      const value = row.data[key as keyof typeof row.data];
      if (isDurationColumn) {
        const convertedValue = Number(value);
        return isNaN(convertedValue) || isNullOrUndefined(convertedValue)
          ? '-'
          : formatDurationNs(convertedValue, 1, 2);
      }
      const isSizeColumn = isSizeFacet(key);
      if (isSizeColumn) {
        return formatFileSize(value as string);
      }
      return value;
    },
  })),
];
