import { AutocompleteListV2 } from 'components';
import React, { useEffect } from 'react';

const RumSearchInputPanelValues = ({
  fetchLabelValuesByName,
  isLoadingByNameMap,
  lastParsedValue,
  onClickLabelValueHandler,
  parsedFacetName,
  searchedLabelValueOptions,
}: {
  fetchLabelValuesByName: (parsedFacetName: string) => void;
  isLoadingByNameMap: {
    state: Record<string, boolean>;
  };
  lastParsedValue: string;
  onClickLabelValueHandler: (option: { option: { value: string } }) => void;
  parsedFacetName: string;
  searchedLabelValueOptions: { label: string; value: string }[];
}) => {
  useEffect(() => {
    fetchLabelValuesByName(parsedFacetName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedFacetName]);

  return (
    <AutocompleteListV2
      close={() => {}}
      disableKeyExist
      emptyPlaceholder="No suggestions"
      isLoading={parsedFacetName && isLoadingByNameMap.state[parsedFacetName]}
      onClickHandler={onClickLabelValueHandler}
      onClickKeyExistHandler={() => {}}
      options={searchedLabelValueOptions}
      typed={lastParsedValue}
    />
  );
};

export default RumSearchInputPanelValues;
