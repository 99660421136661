import { Geomap, Loader } from 'components';
import React, { ReactElement, useMemo } from 'react';

const WIDTH_LEFT_RIGHT_OFFSET = 32;

const DashboardEditGraphGeomap = ({
  baseWidth,
  dataFormatter,
}: {
  baseWidth: number;
  dataFormatter: () => any;
}): ReactElement => {
  const geomapQueryData = useMemo(() => dataFormatter(), [dataFormatter]);

  return (
    <Loader isLoading={geomapQueryData?.isLoading}>
      <div className="dashboard-edit__metric__body__geomap">
        {geomapQueryData?.data ? (
          <Geomap
            data={geomapQueryData.data}
            height={200}
            width={baseWidth - WIDTH_LEFT_RIGHT_OFFSET - 8}
          />
        ) : null}
      </div>
    </Loader>
  );
};

export default DashboardEditGraphGeomap;
