import { getKfuseQlForForecastAlerts } from 'utils/KfuseqlBuilder';
import {
  LogsMetricForumlaProps,
  LogsMetricQueryProps,
  QueryLangType,
} from 'types';

import { ForecastConditionProps } from '../../AlertsCreateCondition';
import { AlertAnomalyQueryItem } from '../../types';
import { getSelectedLogQueryLogql } from './alertsCreateLogs';

export const logsForecastSeasonalityMapping = {
  hourly: '1',
  daily: '2',
  weekly: '3',
};

export const logsForecastSeasonalityOptions = [
  { label: 'Hourly', value: '1' },
  { label: 'Daily', value: '2' },
  { label: 'Weekly', value: '3' },
];

export const logsForecastSeasonalityReverseMapping = {
  '1': 'Hourly',
  '2': 'Daily',
  '3': 'Weekly',
};

export const getQueryItemForKfuseqlForForecast = ({
  formulas,
  queries,
  selectedQuery,
  step,
}: {
  formulas: LogsMetricForumlaProps[];
  queries: LogsMetricQueryProps[];
  selectedQuery: string;
  step: number;
}): AlertAnomalyQueryItem => {
  const newQueries = queries.map((query) => {
    return { ...query, step: `${step}s` };
  });

  const item = getSelectedLogQueryLogql({
    formulas,
    queries: newQueries,
    selectedQuery,
    queryLangType: QueryLangType.KFUSEQL,
  });
  if (!item || typeof item === 'string') return;

  return {
    kfuseql: item.logql,
    step,
    queryType: selectedQuery.includes('Formula') ? 'formula' : 'query',
    metricName: '',
    query: item.query,
    ...item.meta,
  };
};

export const getForecastKfuseqlForLoad = ({
  kfuseQlOperation,
  kfuseql,
  forecastCondition,
  step,
  band,
}: {
  kfuseQlOperation: string;
  kfuseql: string;
  forecastCondition: ForecastConditionProps;
  step: number;
  band: string;
}): string => {
  const { forecastAlgorithm, seasonality, forecastDuration } =
    forecastCondition;
  const kfuseqlForecast = getKfuseQlForForecastAlerts({
    algorithm: forecastAlgorithm,
    operation: kfuseQlOperation,
    query: kfuseql,
    step: `${step}s`,
    seasonality: Number(seasonality),
    forecastDuration,
    band,
  });

  return kfuseqlForecast;
};
