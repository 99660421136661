import classNames from 'classnames';
import { CodeEditor, TooltipTrigger } from 'components';
import { useMetricsQueryStateV2 } from 'hooks';
import React, { ReactElement } from 'react';
import { ExplorerQueryProps } from 'types/MetricsQueryBuilder';

const MetricsQueryBuilderInput = ({
  metricsQueryState,
  query,
  queryIndex,
}: {
  metricsQueryState: ReturnType<typeof useMetricsQueryStateV2>;
  query: ExplorerQueryProps;
  queryIndex: number;
}): ReactElement => {
  const {
    queries,
    reloadOneQuery,
    toggleQueryAndCallOnePromqlQuery,
    updateQuery,
  } = metricsQueryState;

  return (
    <div className="metrics__query-builder__query-item">
      <div className="metrics__query-builder__query-item__code">
        <TooltipTrigger tooltip={query.isActive ? 'Hide Query' : 'Show Query'}>
          <div
            className={classNames({
              'metrics__query-builder__query-item__query-key': true,
              'metrics__query-builder__query-item__query-key--inactive':
                !query.isActive,
            })}
            onClick={() =>
              toggleQueryAndCallOnePromqlQuery({
                index: queryIndex,
                type: 'query',
              })
            }
          >
            {query.queryKey}
          </div>
        </TooltipTrigger>
        <CodeEditor
          onChange={(val: string) => updateQuery(queryIndex, 'promql', val)}
          value={query.promql}
        />
        <div
          className="button button--blue metrics__query-builder__query-item__code__run"
          onClick={() => {
            reloadOneQuery({ queries, formulas: [], queryIndex });
          }}
        >
          Run
        </div>
      </div>
    </div>
  );
};

export default MetricsQueryBuilderInput;
