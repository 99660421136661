import React from 'react';
import { formatDurationNs } from 'utils';
import { useQueryScheduler } from './hooks';
import LogsQueryTimerElapsed from './LogsQueryTimerElapsed';

type Props = {
  queryScheduler: ReturnType<typeof useQueryScheduler>;
};

const LogsQueryTimer = ({ queryScheduler }: Props) => {
  const { startTimeMs, endTimeMs } = queryScheduler;
  if (startTimeMs && endTimeMs) {
    return (
      <div
        className="logs__query-timer"
        data-testid="logs-query-timer"
      >{`Took: ${formatDurationNs(
        (endTimeMs - startTimeMs) * 1000000,
        3,
        2,
      )}`}</div>
    );
  }

  if (startTimeMs) {
    return (
      <div className="logs__query-timer">
        <div className="logs__query-time__spinner spinner" />
        <LogsQueryTimerElapsed startTimeMs={startTimeMs} />
      </div>
    );
  }

  return null;
};

export default LogsQueryTimer;
