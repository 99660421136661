import dayjs from 'dayjs';
import {
  DateSelection,
  EventListProps,
  SelectedFacetValuesByName,
} from 'types';
import { onPromiseError } from 'utils';
import queryEventService from './queryEventService';
import { buildEventsFilter } from './utils';

type Args = {
  date: DateSelection;
  filterByFacets?: any;
  pageNum?: number;
  perPage?: number;
  searchTerms: string[];
  selectedFacetValuesByName: SelectedFacetValuesByName;
  additionalFilterByFacets?: SelectedFacetValuesByName;
};

const eventsData = async ({
  date,
  additionalFilterByFacets,
  filterByFacets = {},
  pageNum = 0,
  perPage = 200,
  searchTerms = [],
  selectedFacetValuesByName = {},
}: Args): Promise<EventListProps[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  return queryEventService<EventListProps[], 'events'>(`
    query GetEventsData {
    events(
        durationSecs: ${durationSecs},
        filter: ${buildEventsFilter(
          filterByFacets,
          searchTerms,
          selectedFacetValuesByName,
          additionalFilterByFacets,
        )},
        limit: ${perPage},
        offset: ${pageNum * perPage},
        timestamp: "${endTime.format()}",
      ) {
        id
        title
        timestamp
        text
        host
        priority
        sourceTypeName
        alertType
        aggregationKey
        eventType
        labels {
            name
            value
        }
      }
    }
  `).then((data) => data.events || [], onPromiseError);
};

export default eventsData;
