import { useMemo } from 'react';

type Props = {
  data: {
    isLoading: boolean;
    result: any[];
    error: Error;
  };
};

const parseJson = (json: string) => {
  let parsedJson = '';

  try {
    parsedJson = JSON.parse(json);
  } catch (error) {
    return null;
  }

  return parsedJson;
};

const useTableOverlayMessage = ({ data }: Props) => {
  const overlayMessage = useMemo(() => {
    const dataIsEmpty = data.result.length === 0 && !!data?.error;
    if (dataIsEmpty) {
      const res = parseJson(data?.error?.message);
      const errorMessage = !!res
        ? res?.errors[0]?.message
        : 'Failed to load data.';
      return errorMessage;
    }
    return 'Data is not found.';
  }, [data]);

  return overlayMessage;
};

export default useTableOverlayMessage;
