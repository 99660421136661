import { CSSProperties } from 'react';

const calculateTextWidthInHtml = ({
  text,
  options,
  className,
}: {
  text: string;
  className?: string;
  options?: CSSProperties;
}): number => {
  const span = document.createElement('span');
  span.style.position = 'absolute';
  span.style.visibility = 'hidden';
  span.innerHTML = text;
  span.className = className;
  Object.assign(span.style, options);

  document.body.appendChild(span);
  const width = span.offsetWidth;
  document.body.removeChild(span);
  return width;
};

export default calculateTextWidthInHtml;
