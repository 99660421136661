import dayjs from 'dayjs';
import queryRumService from './queryRumService';
import {
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types/selectedFacets';
import { buildRumFilter } from './utils';
import { formatNs } from 'utils/timeNs';
import { TimeUnit } from 'types';

type Args = {
  applicationFilter: string;
  endTimeUnix: number;
  facetRegex: FacetRegexTerm[];
  startTimeUnix: number;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  aggregateFunction: string;
  sortByField?: string;
  sortOrder?: string;
  sortAggregation?: string;
};

const rumAggregateTable = async ({
  applicationFilter,
  endTimeUnix,
  facetRegex,
  startTimeUnix,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  aggregateFunction,
  sortByField,
  sortOrder,
  sortAggregation,
}: Args): Promise<any> => {
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const rawData = await queryRumService<any, 'aggregateTable'>(`
    query {
      aggregateTable(
        eventType: VIEW,
        timestamp: "${endTime.toISOString()}"
        durationSecs: ${durationSecs}
        aggregates: [
          {
            field: "view.loading_time"
            aggregation: "${aggregateFunction}",
          },
          {
            field: "view.largest_contentful_paint"
            aggregation: "${aggregateFunction}",
          },
          {
            field: "view.cumulative_layout_shift"
            aggregation: "${aggregateFunction}",
          },
          {
            field: "view.interaction_to_next_paint"
            aggregation: "${aggregateFunction}",
          }
        ]
        filter: ${buildRumFilter({
          applicationFilter,
          facetRegex,
          selectedFacetRangeByName,
          selectedFacetValuesByName,
        })}
        groupBy: [
          {
            field: "view.name"
            limit: 10
            sort: {
              field: "${sortByField}"
              aggregation: "${sortAggregation}"
              order: "${sortOrder}"
            }
          }
        ]
      ) {
        dimensions
        aggregates
      }
    }
  `);

  if (!rawData?.aggregateTable || rawData.aggregateTable.length === 0) {
    return { data: {} };
  }

  const formattedAggregates = rawData.aggregateTable.reduce((acc, item) => {
    acc.push({
      'view.loading_time': `${formatNs(item.aggregates[0], TimeUnit.SECONDS, 2)}s`,
      'view.largest_contentful_paint': `${formatNs(item.aggregates[1], TimeUnit.SECONDS, 2)}s`,
      'view.cumulative_layout_shift': `${formatNs(item.aggregates[2], TimeUnit.NANOSECONDS, 2)}`,
      'view.interaction_to_next_paint': `${formatNs(item.aggregates[3], TimeUnit.SECONDS, 2)}s`,
      'view.name': item.dimensions['view.name'],
    });
    return acc;
  }, []);
  return [
    {
      data: formattedAggregates,
    },
  ];
};

export default rumAggregateTable;
