import {
  ErrorMessage,
  Loader,
  Table,
  TableBulkActions,
  TableBulkActionsCheckbox,
  TableSearch,
  TooltipTrigger,
  useTableBulkActions,
  useTableSearch,
} from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement } from 'react';

import useLogsFacetExplorerState from './useLogsFacetExplorerState';
import { IoIosWarning } from 'react-icons/io';

const column = ({
  tableBulkActions,
}: {
  tableBulkActions: ReturnType<typeof useTableBulkActions>;
}) => [
  {
    key: 'bulk-action-checkbox',
    label: (
      <TableBulkActionsCheckbox
        tableBulkActions={tableBulkActions}
        isSelectAll
      />
    ),
    renderCell: ({ row }: { row: { value: string; count: number } }) => (
      <TableBulkActionsCheckbox
        tableBulkActions={tableBulkActions}
        uniqueId={row.value}
      />
    ),
  },
  {
    key: 'value',
    label: 'Value',
    renderCell: ({ row }: { row: { value: string; count: number } }) => {
      return (
        <div className="flex gap-1">
          <TooltipTrigger tooltip={row.value}>
            <div className="w-[200px] max-w-[240px] truncate">{row.value}</div>
          </TooltipTrigger>
        </div>
      );
    },
  },
  { key: 'count', label: 'Count' },
];

const LogsFacetExplorerSourceFacetsValues = ({
  getLogFacetValuesCountsRequest,
  logsFacetExplorerState,
  tableBulkActions,
  error,
}: {
  getLogFacetValuesCountsRequest: ReturnType<typeof useRequest>;
  logsFacetExplorerState: ReturnType<typeof useLogsFacetExplorerState>;
  tableBulkActions: ReturnType<typeof useTableBulkActions>;
  error: {
    getLogFacetValueCounts: { message: string };
  };
}): ReactElement => {
  const { selectedFacet } = logsFacetExplorerState;

  const tableSearch = useTableSearch({
    rows: getLogFacetValuesCountsRequest.result || [],
  });

  return (
    <div className="flex w-[340px] flex-col gap-2 pl-2 pt-2">
      <div className="text-lg font-semibold text-text-secondary">
        Top values
      </div>
      <ErrorMessage
        message={error?.getLogFacetValueCounts?.message}
        className="justify-end pr-[14px]"
      />
      <TableSearch
        placeholder={`Search values from ${selectedFacet?.name}`}
        tableSearch={tableSearch}
        size="4"
      />
      <TableBulkActions
        tableBulkActions={tableBulkActions}
        onBulkDelete={() => tableBulkActions.clearSelectedRows()}
        selectedLabel={`${tableBulkActions.selectedRowsCount > 1 ? 'facet values' : 'facet value'} selected`}
        buttonTitle="Clear"
      />
      <Loader isLoading={getLogFacetValuesCountsRequest.isLoading}>
        <div className="metrics-summary__body__metrics-names__result overflow-x-scroll-hide min-h-[500px] overflow-y-scroll">
          <Table
            columns={column({ tableBulkActions })}
            className="table--bordered table--bordered-cells alerts__list__table"
            rows={tableSearch.searchedRows}
          />
        </div>
      </Loader>
    </div>
  );
};

export default LogsFacetExplorerSourceFacetsValues;
