import React from 'react';
import { formatDurationNs } from 'utils/timeNs';

const RumResourceBreakdownLegend = ({
  unknownDuration,
  firstByteDuration,
  downloadDuration,
}: {
  unknownDuration: number;
  firstByteDuration: number;
  downloadDuration: number;
}) => {
  return (
    <div className="rum-resource-breakdown__legend-container">
      <div className="rum-resource-breakdown__legend-item rum-resource-breakdown__legend-item--lightblue-border">
        <span className="rum-resource-breakdown__legend-label">Unknown:</span>
        <span className="rum-resource-breakdown__legend-value">
          {formatDurationNs(unknownDuration, 1, 2)}
        </span>
      </div>
      <div className="rum-resource-breakdown__legend-item rum-resource-breakdown__legend-item--yellow-border">
        <span className="rum-resource-breakdown__legend-label">
          First Byte:
        </span>
        <span className="rum-resource-breakdown__legend-value">
          {formatDurationNs(firstByteDuration, 1, 2)}
        </span>
      </div>
      <div className="rum-resource-breakdown__legend-item rum-resource-breakdown__legend-item--lightyellow-border">
        <span className="rum-resource-breakdown__legend-label">Download:</span>
        <span className="rum-resource-breakdown__legend-value">
          {formatDurationNs(downloadDuration, 1, 2)}
        </span>
      </div>
    </div>
  );
};

export default RumResourceBreakdownLegend;
