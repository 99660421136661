import { MICROSECONDS } from 'kfuse-constants';
import {
  FacetRegexTerm,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types';
import {
  escapeLinesTabsAndQuotes,
  getIsDeselecting,
  parseFacetKey,
} from 'utils';
import { RumEventType } from '../types';
import { isDurationFacet } from '../utils';

const getFilter = ({ name, value }: { name: string; value: string }) => {
  const nameKey = 'key';
  const nameString = name;

  return `{ ${nameKey}: "${nameString}", value:"${escapeLinesTabsAndQuotes(
    value,
  )}"}`;
};

const buildSelectedFacetValuesByNameFilter = (
  selectedFacetValuesByName: SelectedFacetValuesByName,
) => {
  let result = '';

  const names = Object.keys(selectedFacetValuesByName);

  names.forEach((name) => {
    const selectedFacetValues = selectedFacetValuesByName[name];
    const isDeselecting = getIsDeselecting(selectedFacetValues);
    const keys = Object.keys(selectedFacetValues);

    if (keys.length > 1) {
      result += `{ ${isDeselecting ? 'and' : 'or'}: [`;
    }

    keys.forEach((value) => {
      result += '{';
      result += 'attributeFilter';
      result += ': {';
      result += isDeselecting ? 'neq' : 'eq';
      result += ':';

      result += getFilter({ name, value });
      result += '}';
      result += '}';
    });

    if (keys.length > 1) {
      result += `]}`;
    }
  });

  return result;
};

export const buildRumFilter = ({
  applicationFilter,
  errorGroupingKey = null,
  facetRegex = [],
  selectedFacetRangeByName = {},
  selectedFacetValuesByName = {},
  idSearch = '',
  viewId = '',
  actionId = '',
  actionIds = '',
  resourceId = '',
  errorId = '',
  longTaskId = '',
  sessionId = '',
}: {
  applicationFilter?: string;
  errorGroupingKey?: string;
  facetRegex?: FacetRegexTerm[];
  selectedFacetRangeByName?: SelectedFacetRangeByName;
  selectedFacetValuesByName?: SelectedFacetValuesByName;
  idSearch?: string;
  viewId?: string;
  actionId?: string;
  actionIds?: string;
  resourceId?: string;
  errorId?: string;
  longTaskId?: string;
  sessionId?: string;
}): string => {
  let result = '{ and: [';
  result += buildSelectedFacetValuesByNameFilter(selectedFacetValuesByName);

  if (selectedFacetRangeByName) {
    Object.keys(selectedFacetRangeByName).forEach((name) => {
      const key = parseFacetKey(name).name;
      const isDurationField = isDurationFacet(key);
      const { lower, upper } = selectedFacetRangeByName[name];
      result += `{ rangeFilter: { 
        key: "${key}",
        min: ${lower * (isDurationField ? MICROSECONDS : 1)}, max: ${upper * (isDurationField ? MICROSECONDS : 1)} }}`;
    });
  }

  if (applicationFilter) {
    result += `{attributeFilter: { eq: {key: "application.id", value: "${applicationFilter}"}}}`;
  }

  if (errorGroupingKey) {
    result += `{attributeFilter: { eq: {key: "error.fingerprint", value: "${errorGroupingKey}"}}}`;
  }

  if (idSearch) {
    result += `{attributeFilter: { eq: {key: "view.id", value: "${idSearch}"}}}`;
  }
  if (actionId) {
    result += `{attributeFilter: { eq: {key: "action.id", value: "${actionId}"}}}`;
  }
  if (actionIds) {
    result += `{attributeFilter: { eq: {key: "action.ids", value: "${actionIds}"}}}`;
  }
  if (viewId) {
    result += `{attributeFilter: { eq: {key: "view.id", value: "${viewId}"}}}`;
  }
  if (resourceId) {
    result += `{attributeFilter: { eq: {key: "resource.id", value: "${resourceId}"}}}`;
  }
  if (errorId) {
    result += `{attributeFilter: { eq: {key: "error.id", value: "${errorId}"}}}`;
  }
  if (longTaskId) {
    result += `{attributeFilter: { eq: {key: "long_task.id", value: "${longTaskId}"}}}`;
  }

  if (sessionId) {
    result += `{attributeFilter: { eq: {key: "session.id", value: "${sessionId}"}}}`;
  }
  if (facetRegex && facetRegex.length > 0) {
    facetRegex.forEach((facetRegexTerm: FacetRegexTerm) => {
      const values = facetRegexTerm.value.split(' OR ');

      if (values.length > 1) {
        result += '{ or: [';
      }
      values.forEach((value) => {
        result += `{attributeFilter: {${
          facetRegexTerm.isEqual ? 'regex' : 'nregex'
        } : {key: "${facetRegexTerm.name}", value: "${value}"}}}`;
      });
      if (values.length > 1) {
        result += ']}';
      }
    });
  }

  result += '] }';

  return result;
};

export const isEventTableUIPaginated = (eventType: string) => {
  return eventType === RumEventType.VIEW || eventType === RumEventType.SESSION;
};
