import { fetchJson } from 'requests';

import { GroupProps } from '../types';

const getGroupList = async (): Promise<GroupProps[]> => {
  const result = await fetchJson(`rbac/groups/`);

  return (result as GroupProps[]) || [];
};

export default getGroupList;
