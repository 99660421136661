import dayjs from 'dayjs';
import { DateSelection } from 'types/DateSelection';
import { getAdjustedStartAndEndTimeUnix, onPromiseError } from 'utils';

import fetchJson from './fetchJson';
import { queryRangeStep } from './utils';

type Args = {
  date: DateSelection;
  instant?: boolean;
  query: string;
  step?: string;
  useCeilInAdjustingTime?: boolean;
};

const queryRange = async (
  { date, instant, query, step, useCeilInAdjustingTime = false }: Args,
  init: RequestInit = {},
): Promise<any> => {
  const defaultStep = `${queryRangeStep(date)}s`;
  const adjusted = getAdjustedStartAndEndTimeUnix({
    date,
    step: step || defaultStep,
    useCeilInAdjustingTime,
  });

  const startTimeUnix = instant ? date.startTimeUnix : adjusted.startTimeUnix;
  const endTimeUnix = instant ? date.endTimeUnix : adjusted.endTimeUnix;

  const start = dayjs.unix(startTimeUnix).toISOString();
  const end = dayjs.unix(endTimeUnix).toISOString();
  const stepString = instant ? '' : `&step=${step || defaultStep}`;

  const timeString = instant ? `time=${end}` : `start=${start}&end=${end}`;

  const queryRes = fetchJson(
    `api/v1/${instant ? 'query' : 'query_range'}?query=${encodeURIComponent(
      `${query}`,
    )}&${timeString}${stepString}`,
    init,
  ).then((result) => result?.data?.result || [], onPromiseError);
  return queryRes;
};

export default queryRange;
