import { AutocompleteV2, Button, TooltipTrigger } from 'components';
import React, { ReactElement } from 'react';
import { IoMdClose } from 'react-icons/io';

import MetricsCardinalityLabel from './MetricsCardinalityLabel';
import useMetricsCardinality from './useMetricsCardinality';

const MetricsCardinalityMetric = ({
  disableLabel = false,
  metricsCardinality,
  placeholder = 'Select a metric',
}: {
  disableLabel?: boolean;
  metricsCardinality: ReturnType<typeof useMetricsCardinality>;
  placeholder?: string;
}): ReactElement => {
  const { cardinalityQuery, updateCardinalityQuery, getMetricsListRequest } =
    metricsCardinality;
  return (
    <>
      <div className="button-group">
        <div className="button-group__item button-group__item--value">
          <AutocompleteV2
            className="autocomplete-container--no-border autocomplete__fixed-height-30"
            isLoading={getMetricsListRequest.isLoading}
            options={getMetricsListRequest.result || []}
            onChange={(value: string) =>
              updateCardinalityQuery('metric', value)
            }
            placeholder={placeholder}
            value={cardinalityQuery.metric}
          />
        </div>
        {cardinalityQuery.metric && (
          <TooltipTrigger
            className="button-group__item button-group__item__no-padding flex"
            tooltip="Delete"
          >
            <Button
              variant="icon"
              size="default"
              onClick={() => updateCardinalityQuery('metric', '')}
            >
              <IoMdClose size={16} />
            </Button>
          </TooltipTrigger>
        )}
      </div>
      <div className="search__button-group__divider">
        <div />
      </div>
      {!disableLabel && (
        <MetricsCardinalityLabel
          disableMetric={true}
          metricsCardinality={metricsCardinality}
        />
      )}
    </>
  );
};

export default MetricsCardinalityMetric;
