import { usePopoverContext } from 'components';
import React, { ReactElement, useState, useRef, MutableRefObject } from 'react';
import { Chip } from 'components';
import { Row as FlexRow } from 'components/layouts/Flex';

import { useKubernetesController } from './KubernetesController';
import KubernetesTagsPopover from './KubernetesTagsPopover';
import { getFacetValuesFromFilterKey } from './utils';
import KubernetesTagText from './KubernetesTagText';

const MAX_TAGS = 8;

const KubernetesTags = ({ tags }: { tags: string[] }): ReactElement => {
  const { facetValueSet, facetValueExclude } = useKubernetesController();
  const popover = usePopoverContext();
  const [showAll, setShowAll] = useState(false);
  const tagsLength = tags.length;
  const trimmerdLabels = tagsLength > MAX_TAGS ? tags.slice(0, MAX_TAGS) : tags;
  const leftLabels =
    tagsLength > MAX_TAGS ? tags.slice(MAX_TAGS, tagsLength) : [];

  const onFilterByTag = (tag: string) => {
    const queryStr = tag.replace(':', '=');
    const { facetKey, facetValue } = getFacetValuesFromFilterKey(queryStr);
    facetValueSet(facetKey, {
      [facetValue]: 1,
    });
    popover.close();
  };

  const onExcludeByTag = (tag: string) => {
    const queryStr = tag.replace(':', '!=');
    const { facetKey, facetValue } = getFacetValuesFromFilterKey(
      queryStr,
      '!=',
    );
    facetValueExclude(facetKey, facetValue);
    popover.close();
  };

  const onCopyToClipboard = (tag: string) => {
    navigator.clipboard.writeText(tag);
    popover.close();
  };

  const onTagClick = (tag: string, tagRef: any) => {
    popover.open({
      component: KubernetesTagsPopover,
      element: tagRef.current,
      props: {
        onExclude: onExcludeByTag,
        onFilter: onFilterByTag,
        onCopyToClipboard,
        tag,
      },
      width: 400,
      popoverPanelClassName: 'popover__panel--overflow',
    });
  };

  return (
    <FlexRow wrap>
      {trimmerdLabels.map((label: any, index: number) => {
        return (
          <KubernetesTagText
            key={index}
            onTagClick={onTagClick}
            tag={`${label}`}
          />
        );
      })}
      {showAll &&
        leftLabels.map((label: any, index: number) => {
          return (
            <KubernetesTagText
              key={index}
              onTagClick={onTagClick}
              tag={`${label}`}
            />
          );
        })}
      {tagsLength > MAX_TAGS && (
        <Chip asChild>
          <button
            type="button"
            key={`${tagsLength - MAX_TAGS} more`}
            onClick={(e) => {
              e.stopPropagation();
              setShowAll(!showAll);
            }}
          >
            {showAll ? 'Show less' : `${tagsLength - MAX_TAGS} more`}
          </button>
        </Chip>
      )}
    </FlexRow>
  );
};

export default KubernetesTags;
