import { getTags } from 'hooks';
import React from 'react';

const getTracesTags = ({
  colorsByServiceName,
  filtersState,
  serviceByHash,
  setTraceIdSearch,
  traceIdSearch,
}) => {
  const result = getTags({
    colorsByServiceName,
    filtersState,
    serviceByHash,
  });

  if (traceIdSearch) {
    const label = (
      <>
        <span className="traces-search__input__trigger__tag__name">
          {'trace ID'}
        </span>
        <span>:</span>
        <span className="traces-search__input__trigger__tag__value">
          {traceIdSearch}
        </span>
      </>
    );

    result.push({
      label,
      onRemove: () => {
        setTraceIdSearch('');
      },
      text: traceIdSearch,
    });
  }

  return result;
};

export default getTracesTags;
