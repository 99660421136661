import * as d3 from 'd3';
import { AreaSelectionProps } from 'types';
import { AreaSelection } from './types';

const getGraphConstants = () => ({
  GRAPH_MARGIN: 30,
  LEFT_MARGIN: 80,
  GRAPH_WIDTH: 900,
  GRAPH_HEIGHT: 450,
  GRID_SIZE: 100,
  X_TICKMARK_SCALE: 8,
  Y_TICKMARK_SCALE: 5,
});

const colorsScaleMap = {
  fieldColor: 'white',
  colorRange1: '#81FC62',
  colorRange100: '#094399',
};
const colorsDomain = [0, 1, 100];

const calculateGraphDimensions = ({
  GRAPH_MARGIN,
  LEFT_MARGIN,
  GRAPH_WIDTH,
  GRAPH_HEIGHT,
}: {
  GRAPH_MARGIN: number;
  LEFT_MARGIN: number;
  GRAPH_WIDTH: number;
  GRAPH_HEIGHT: number;
}) => {
  const margin = {
    top: GRAPH_MARGIN,
    right: GRAPH_MARGIN,
    bottom: GRAPH_MARGIN,
    left: LEFT_MARGIN,
  };
  const width = GRAPH_WIDTH - margin.left - margin.right;
  const height = GRAPH_HEIGHT - margin.top - margin.bottom;
  return { width, height, margin };
};

const createSvg = (
  width: number,
  height: number,
  margin: { top: number; right: number; bottom: number; left: number },
) => {
  d3.select('#heatmap').selectAll('svg').remove();
  return d3
    .select('#heatmap')
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`);
};

const drawHeatmapRects = ({
  svg,
  data,
  width,
  height,
  GRID_SIZE,
  colorScale,
}: {
  svg: any;
  data: any;
  width: number;
  height: number;
  GRID_SIZE: number;
  colorScale: any;
}) => {
  svg
    .selectAll('rect')
    .data(data)
    .enter()
    .append('rect')
    .attr('x', (d) => d.time * (width / GRID_SIZE))
    .attr('y', (d) => d.duration * (height / GRID_SIZE))
    .attr('width', width / GRID_SIZE)
    .attr('height', height / GRID_SIZE)
    .style('fill', (d) => colorScale(d.value))
    .style('cursor', 'crosshair');
};

const createScales = ({
  heatmapData,
  width,
  height,
  GRID_SIZE,
}: {
  heatmapData: any;
  width: number;
  height: number;
  GRID_SIZE: number;
}) => {
  const timeExtent = d3.extent(
    heatmapData.buckets,
    (d: { timeBucketStartSecs: number }) => d.timeBucketStartSecs,
  );
  const durationExtent = d3.extent(
    heatmapData.buckets,
    (d: { attrBucketStart: number }) => d.attrBucketStart,
  );

  const x = d3
    .scaleLinear()
    .domain([Math.min(...timeExtent), Math.max(...timeExtent)])
    .range([0, width])
    .nice(GRID_SIZE);

  const y = d3
    .scaleLinear()
    .domain([Math.min(...durationExtent), Math.max(...durationExtent)])
    .range([height, 0])
    .nice(GRID_SIZE);

  const colorScale = d3
    .scaleLinear()
    .range(Array.from(Object.values(colorsScaleMap)))
    .domain(colorsDomain);

  return { x, y, colorScale };
};

const normalizeData = ({
  heatmapData,
  x,
  y,
  width,
  height,
  GRID_SIZE,
}: {
  heatmapData: any;
  x: any;
  y: any;
  width: number;
  height: number;
  GRID_SIZE: number;
}) => {
  const originalDataMap = new Map();
  const normalizedData = heatmapData.buckets.map((bucket) => {
    const normalizedTime = Math.floor(
      x(bucket.timeBucketStartSecs) / (width / GRID_SIZE),
    );
    const normalizedDuration = Math.floor(
      y(bucket.attrBucketStart) / (height / GRID_SIZE),
    );
    const uniqueKey = `${bucket.timeBucketStartSecs}-${bucket.attrBucketStart}`;

    originalDataMap.set(uniqueKey, {
      originalTime: bucket.timeBucketStartSecs,
      originalDuration: bucket.attrBucketStart,
      count: bucket.count,
    });

    return {
      time: normalizedTime,
      duration: normalizedDuration,
      value: bucket.count,
      uniqueKey,
    };
  });

  return { normalizedData, originalDataMap };
};

const fillDataWithZeroes = ({
  normalizedData,
  GRID_SIZE,
}: {
  normalizedData: any;
  GRID_SIZE: number;
}) => {
  const filledData = [];
  for (let i = 0; i < GRID_SIZE; i++) {
    for (let j = 0; j < GRID_SIZE; j++) {
      const existingData = normalizedData.find(
        (d) => d.time === i && d.duration === j,
      );
      filledData.push({
        time: i,
        duration: j,
        value: existingData ? existingData.value : 0,
        uniqueKey: existingData?.uniqueKey,
      });
    }
  }
  return filledData;
};

const formatXAxisTicks = (d: number, i: number) => {
  const date = new Date(d * 1000);
  const formattedDate = d3.timeFormat('%M:%S')(date);
  const tickmarkScale = 8;
  return i % tickmarkScale === 0 ? formattedDate : '';
};

const formatYAxisTicks = (d: number, i: number) => {
  const skipCondition = i % 5 === 0;
  const seconds = +d / 1e9;
  return skipCondition ? `${seconds.toFixed(2)}s` : '';
};

const drawAxes = ({
  svg,
  x,
  y,
  width,
  height,
  GRID_SIZE,
  X_TICKMARK_SCALE,
  Y_TICKMARK_SCALE,
}: {
  svg: any;
  x: any;
  y: any;
  width: number;
  height: number;
  GRID_SIZE: number;
  X_TICKMARK_SCALE: number;
  Y_TICKMARK_SCALE: number;
}) => {
  svg
    .append('defs')
    .append('marker')
    .attr('id', 'arrowhead')
    .attr('viewBox', '0 0 10 10')
    .attr('refX', 0)
    .attr('refY', 5)
    .attr('markerWidth', 6)
    .attr('markerHeight', 6)
    .attr('orient', 'auto')
    .attr('markerUnits', 'strokeWidth')
    .append('polygon')
    .attr('points', '0 0, 10 5, 0 10')
    .attr('fill', 'black');

  const xAxis = svg
    .append('g')
    .attr('transform', `translate(0,${height})`)
    .call(
      d3
        .axisBottom(x)
        .tickSizeOuter(0)
        .ticks(GRID_SIZE)
        .tickFormat(formatXAxisTicks),
    );

  xAxis
    .selectAll('.tick')
    .filter((d, i) => i % X_TICKMARK_SCALE !== 0)
    .select('line')
    .remove();

  xAxis.selectAll('text').style('user-select', 'none');

  svg
    .append('line')
    .attr('x1', width - 10)
    .attr('y1', height)
    .attr('x2', width)
    .attr('y2', height)
    .attr('marker-end', 'url(#arrowhead)')
    .style('stroke', 'black');

  const yAxis = svg
    .append('g')
    .call(
      d3
        .axisLeft(y)
        .tickSizeOuter(0)
        .ticks(GRID_SIZE)
        .tickFormat(formatYAxisTicks),
    );

  yAxis
    .selectAll('.tick')
    .filter((d, i) => i % Y_TICKMARK_SCALE !== 0)
    .select('line')
    .remove();

  yAxis.selectAll('text').style('user-select', 'none');

  svg
    .append('line')
    .attr('x1', 0)
    .attr('y1', 10)
    .attr('x2', 0)
    .attr('y2', 0)
    .attr('marker-end', 'url(#arrowhead)')
    .style('stroke', 'black');
};

const createTooltip = () => {
  return d3
    .select('#heatmap')
    .append('div')
    .classed('bg-background', true)
    .classed('rounded-md', true)
    .style('position', 'absolute')
    .style('visibility', 'hidden')
    .style(
      'box-shadow',
      '0 0 4px 0 rgba(0, 0, 0, .1), 0 3px 12px 0 rgba(0, 0, 0, .2)',
    );
};

const addRaiseAnimation = ({ event }: { event: any }) => {
  const rect = d3.select(event.target);

  const x = +rect.attr('x');
  const y = +rect.attr('y');
  const width = +rect.attr('width');
  const height = +rect.attr('height');

  const centerX = x + width / 2;
  const centerY = y + height / 2;

  rect
    .raise()
    .transition()
    .duration(200)
    .attr(
      'transform',
      `translate(${centerX}, ${centerY}) scale(1.5) translate(${-centerX}, ${-centerY})`,
    )
    .style('stroke', 'white')
    .style('stroke-width', 2);
};

const removeRaiseAnimation = ({ event }: { event: any }) => {
  const rect = d3.select(event.target);
  rect
    .transition()
    .duration(200)
    .attr('transform', 'translate(0,0) scale(1)')
    .style('stroke', 'none')
    .style('stroke-width', 0);
};

const isMenuOpen = false;
const handleMouseOver = ({
  event,
  d,
  tooltip,
  originalDataMap,
  heatmapData,
}: {
  event: any;
  d: any;
  tooltip: any;
  originalDataMap: any;
  heatmapData: any;
}) => {
  const disableTooltipFor0Values = d.value === 0;
  if (disableTooltipFor0Values) return;

  addRaiseAnimation({ event });

  const originalData = originalDataMap.get(d.uniqueKey);

  const sortedBuckets = [...heatmapData.buckets].sort((a, b) => {
    if (a.timeBucketStartSecs !== b.timeBucketStartSecs) {
      return a.timeBucketStartSecs - b.timeBucketStartSecs;
    }
    return a.attrBucketStart - b.attrBucketStart;
  });

  const currentIndex = sortedBuckets.findIndex(
    (bucket) =>
      bucket.timeBucketStartSecs === originalData?.originalTime &&
      bucket.attrBucketStart === originalData?.originalDuration,
  );

  const nextBucket =
    currentIndex < sortedBuckets.length - 1
      ? sortedBuckets[currentIndex + 1]
      : null;

  const actualTime = originalData ? originalData.originalTime : 0;
  const formattedDate = originalData
    ? d3.timeFormat('%b %d %Y %H:%M:%S')(new Date(actualTime * 1000))
    : 'N/A';

  const actualDuration = originalData ? originalData.originalDuration : 0;
  const displayedDurationValue = actualDuration / 1e9;
  const displayedDuration = displayedDurationValue.toFixed(2);

  const nextBucketDurationValue = nextBucket
    ? nextBucket.attrBucketStart / 1e9
    : 'N/A';

  const nextBucketDuration = !isNaN(nextBucketDurationValue)
    ? (+nextBucketDurationValue).toFixed(2)
    : nextBucketDurationValue;

  tooltip.style('visibility', 'visible').html(`
      <div class="bg-background-secondary p-2 border rounded-md text-text-secondary">
        <div>${formattedDate}</div>
        <div>Bucket: ${displayedDuration}s - ${nextBucketDuration}s</div>
        <div>Count: ${d.value}</div>
      </div>
    `);
};

const handleMouseMove = ({ event, tooltip }: { event: any; tooltip: any }) => {
  if (isMenuOpen) return;

  tooltip
    .style('top', `${event.pageY - 288}px`)
    .style('left', `${event.pageX - 270}px`);
};

const handleMouseOut = ({ tooltip, event }: { tooltip: any; event: any }) => {
  if (isMenuOpen) return;

  removeRaiseAnimation({ event });
  tooltip.style('visibility', 'hidden');
};

const handleClick = ({ event, tooltip }: { event: any; tooltip: any }) => {
  tooltip.style('visibility', 'hidden');
  event.stopPropagation();
  const [mouseX, mouseY] = d3.pointer(event);
  const customMenu = d3.select('#customMenu');
  customMenu
    .style('left', `${mouseX + 110}px`)
    .style('top', `${mouseY + 30}px`)
    .style('visibility', 'visible')
    .style('cursor', 'pointer')
    .style(
      'box-shadow',
      '0 0 4px 0 rgba(0, 0, 0, .1), 0 3px 12px 0 rgba(0, 0, 0, .2)',
    );
};

const setupTooltipAndInteractions = ({
  svg,
  data,
  originalDataMap,
  heatmapData,
  width,
  height,
  setAreaSelection,
  x,
  y,
}: {
  svg: any;
  data: any;
  originalDataMap: any;
  heatmapData: any;
  width: number;
  height: number;
  setAreaSelection: (arg0: AreaSelection) => void;
  x: d3.ScaleLinear<number, number, never>;
  y: d3.ScaleLinear<number, number, never>;
}) => {
  const tooltip = createTooltip();
  let isMenuOpen = false;

  d3.select('body').on('click', () => {
    d3.select('#customMenu').style('visibility', 'hidden');
    isMenuOpen = false;
  });

  svg
    .selectAll('rect')
    .on('mouseover', (event, d) =>
      handleMouseOver({
        event,
        d,
        tooltip,
        originalDataMap,
        heatmapData,
      }),
    )
    .on('mousemove', (event) => handleMouseMove({ event, tooltip }))
    .on('mouseout', (event) => handleMouseOut({ tooltip, event }))
    .on('click', (event, d) => d.value > 0 && handleClick({ event, tooltip }));

  const handleBrush = (e) => {
    const { selection } = e;
    if (!selection) {
      return;
    }

    const [x0, y0] = selection[0];
    const [x1, y1] = selection[1];

    const x0Value = x.invert(x0);
    const x1Value = x.invert(x1);
    const y0Value = y.invert(y0);
    const y1Value = y.invert(y1);

    const xMin = Math.floor(Math.min(x0Value, x1Value));
    const xMax = Math.ceil(Math.max(x0Value, x1Value));
    const yMin = Math.floor(Math.min(y0Value, y1Value) / 1000000);
    const yMax = Math.ceil(Math.max(y0Value, y1Value) / 1000000);

    const areaSelection = {
      xMin,
      xMax,
      yMin,
      yMax,
    };

    setAreaSelection(areaSelection);
  };

  const brush = d3
    .brush()
    .extent([
      [0, 0],
      [width, height],
    ])
    .on('end', handleBrush);
  d3.select('svg g').call(brush);
};

type Args = {
  heatmapData: any;
  setAreaSelection: (arg0: AreaSelection) => void;
};

export const drawGraph = ({ heatmapData, setAreaSelection }) => {
  const {
    GRAPH_MARGIN,
    LEFT_MARGIN,
    GRAPH_WIDTH,
    GRAPH_HEIGHT,
    GRID_SIZE,
    X_TICKMARK_SCALE,
    Y_TICKMARK_SCALE,
  } = getGraphConstants();

  const { width, height, margin } = calculateGraphDimensions({
    GRAPH_MARGIN,
    LEFT_MARGIN,
    GRAPH_WIDTH,
    GRAPH_HEIGHT,
  });

  const svg = createSvg(width, height, margin);
  if (!heatmapData) return;

  const { x, y, colorScale } = createScales({
    heatmapData,
    width,
    height,
    GRID_SIZE,
  });
  const { normalizedData, originalDataMap } = normalizeData({
    heatmapData,
    x,
    y,
    width,
    height,
    GRID_SIZE,
  });
  const normalizedDataFilledWithZeroes = fillDataWithZeroes({
    normalizedData,
    GRID_SIZE,
  });

  drawHeatmapRects({
    svg,
    data: normalizedDataFilledWithZeroes,
    width,
    height,
    GRID_SIZE,
    colorScale,
  });
  drawAxes({
    svg,
    x,
    y,
    width,
    height,
    GRID_SIZE,
    X_TICKMARK_SCALE,
    Y_TICKMARK_SCALE,
  });
  setupTooltipAndInteractions({
    svg,
    data: normalizedDataFilledWithZeroes,
    originalDataMap,
    heatmapData,
    width,
    height,
    setAreaSelection,
    x,
    y,
  });
};

export const getAreaSelection = (areaSelection: AreaSelectionProps) => {
  const { startTime, endTime, startValue, endValue } = areaSelection;

  return {
    ...areaSelection,
    startTime: Math.min(startTime, endTime),
    endTime: Math.max(startTime, endTime),
    startValue: Math.min(startValue, endValue),
    endValue: Math.max(startValue, endValue),
  };
};
