import {
  Button,
  MultiselectV2,
  TooltipTrigger,
  useModalsContext,
} from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { MdGroupAdd } from 'react-icons/md';
import { getGrafanaAlertManager } from 'requests';
import { AlertsContactsCreate } from '../../AlertsContacts';

import { useAlertsCreate } from '../hooks';

const AlertsCreateContacts = ({
  alertsCreateState,
}: {
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
}): ReactElement => {
  const modal = useModalsContext();
  const grafanaAlertManagerRequest = useRequest(getGrafanaAlertManager);
  const { contactPoints, setContactPoints } = alertsCreateState;

  useEffect(() => {
    grafanaAlertManagerRequest.call('contact-point');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCreateContactModal = () => {
    modal.push(
      <div className="alerts__create__contacts__new-contact overflow-y-scroll">
        <AlertsContactsCreate
          onClose={(name) => {
            if (!name) {
              modal.pop();
              return;
            }
            setContactPoints((prev) => [...prev, name]);
            grafanaAlertManagerRequest.call('contact-point');
            modal.pop();
          }}
        />
      </div>,
      false,
    );
  };

  return (
    <div className="alerts__create__section">
      <div className="alerts__create__contacts-points">
        <div className="alerts__create__details__container__item">
          <div
            className="alerts__create__details__container__item__textbox"
            data-testid="contact-points-container"
          >
            <MultiselectV2
              onChange={(val) => setContactPoints(val)}
              options={grafanaAlertManagerRequest.result || []}
              placeholder="Choose contact points"
              value={contactPoints}
            />
          </div>
          <div className="alerts__create__details__container__item__action">
            <TooltipTrigger tooltip="Create new contact points">
              <Button
                variant="icon-primary"
                className="ml-2 rounded-full p-2"
                onClick={openCreateContactModal}
              >
                <MdGroupAdd />
              </Button>
            </TooltipTrigger>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertsCreateContacts;
