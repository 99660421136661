import { useCallback } from 'react';

type ValueOf<T> = T[keyof T];

function useActionCreatorFactory<PayloadByActionType>(
  dispatch: React.Dispatch<{
    type: keyof PayloadByActionType;
    payload: ValueOf<PayloadByActionType>;
  }>,
): <Type extends keyof PayloadByActionType>(
  type: Type,
) => (payload: PayloadByActionType[Type]) => void {
  return useCallback(
    function useActionCreator<Type extends keyof PayloadByActionType>(
      type: Type,
    ) {
      return useCallback(
        (payload: PayloadByActionType[Type]) => {
          dispatch({
            type,
            payload,
          });
        },
        [type],
      );
    },
    [dispatch],
  );
}

export default useActionCreatorFactory;
