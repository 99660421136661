import { isEqual } from 'lodash';
import useDebouncedEffect from 'use-debounced-effect';
import { useRef } from 'react';

const useDebouncedDeepCompareEffect = (
  callback: () => void,
  config:
    | number
    | {
        timeout?: number;
        ignoreInitialCall?: boolean;
      },
  dependencies: any[],
) => {
  const previousDependenciesRef = useRef(dependencies);
  useDebouncedEffect(
    () => {
      if (!isEqual(previousDependenciesRef.current, dependencies)) {
        previousDependenciesRef.current = dependencies;
        callback();
      }
    },
    config,
    dependencies,
  );
};

export default useDebouncedDeepCompareEffect;
