import React from 'react';

import { Table, TableRow } from 'components';
import KubernetesRightSideBar from '../KubernetesRightSideBar';
import { useKubernetesController } from '../KubernetesController';
import {
  KubeCellCluster,
  KubeCellNamespace,
  KubeCellTimestamp,
  KubeTableCell,
  KubeTableEntityTags,
} from './Components';
import { generatePath, useParams } from 'react-router-dom';
import { Box } from 'components/layouts/Box';
import KubernetesTableGroup from './KubernetesTableGroup';
import {
  useEntitiesData,
  useEntitiesGroupData,
  useMergedGroupByFilter,
} from './useEntitiesData';

const renderEmptyCell = () => '';

function KubernetesTableForRoleBindingRow({ row }) {
  const { date, entitiesType, navigate } = useKubernetesController();

  const facets = useMergedGroupByFilter(row.groupBys);
  const [rows] = useEntitiesData({
    entitiesType,
    kubesSort: null,
    facets,
    page: 0,
    limit: 50,
    date,
  });

  const setActiveKube = (row) => {
    navigate(({ location, params, pathTemplate }) => {
      return {
        ...location,

        pathname: generatePath(pathTemplate, {
          ...params,

          activeKube: row.roleBinding.metadata.uid,
          tab: 'yaml',
        }),
      };
    });
  };

  const activeKubeParam = useParams()['activeKube'];
  const activeKube = (
    typeof activeKubeParam === 'string' && rows ? rows : []
  ).find((row) => {
    return row.roleBinding.metadata.uid === activeKubeParam;
  });

  return (
    <Box width="full" height="full">
      {activeKube && <KubernetesRightSideBar activeKube={activeKube} />}
      <Table
        className="-my-px"
        renderHeader={false}
        rows={rows ?? []}
        renderRow={(props) => (
          <TableRow
            {...props}
            className="border-y"
            onRowClickHandler={(row) => () => setActiveKube(row)}
          />
        )}
        columns={[
          { key: 'header', label: '' },
          {
            key: 'kube_role_binding',
            label: 'Role Binding',
            renderCell(prop) {
              const roleBindingName = prop.row.roleBinding.metadata?.name;
              return (
                <KubeTableCell
                  {...prop}
                  className="kubernetes__table__cell__entity-name pt-1.5 pb-1.5"
                  tooltipText={roleBindingName}
                >
                  {roleBindingName}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'kube_cluster_name',
            label: 'Cluster',
            renderCell(prop) {
              return <KubeCellCluster {...prop} />;
            },
          },
          {
            key: 'metadata.namespace',
            label: 'Namespace',
            renderCell(prop) {
              return <KubeCellNamespace {...prop} />;
            },
          },
          {
            key: 'kube_role',
            label: 'Role',
            renderCell(prop) {
              const bindingRole = prop.row.roleBinding.tags
                .find((t: string) => t.includes('kube_role'))
                .split(':')[1];
              return (
                <KubeTableCell
                  {...prop}
                  align="left"
                  className="kubernetes__table__cell__entity-name"
                  tooltipText={bindingRole}
                >
                  {bindingRole}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'headerSubjects',
            label: 'Subjects',
            renderCell(prop) {
              return (
                <KubeTableCell {...prop} align="left">
                  {prop.row.roleBinding?.subjects[0]?.kind}
                </KubeTableCell>
              );
            },
          },
          {
            key: 'metadata.creationTimestamp',
            label: 'Age',
            renderCell(prop) {
              const timestamp =
                prop.row.roleBinding.metadata.creationTimestamp || '';
              return <KubeCellTimestamp {...prop} timestamp={timestamp} />;
            },
          },
        ]}
      />
    </Box>
  );
}

export default function KubernetesTableForRoleBindingGroup() {
  const { date, entitiesType, facets, groupBySearchTerms } =
    useKubernetesController();

  const [rows] = useEntitiesGroupData({
    entitiesType,
    date,
    facets,
    groupBySearchTerms,
  });

  return (
    <KubernetesTableGroup
      rows={rows ?? []}
      columns={[
        {
          key: 'kube_role_binding',
          label: 'Role Binding Group',
          renderCell(prop) {
            return <KubeTableEntityTags {...prop} />;
          },
        },
        {
          key: 'kube_cluster_name',
          label: 'Cluster',
          renderCell: renderEmptyCell,
        },
        {
          key: 'metadata.namespace',
          label: 'Namespace',
          renderCell: renderEmptyCell,
        },
        {
          key: 'kube_role',
          label: 'Role',
          renderCell: renderEmptyCell,
        },
        {
          key: 'subjects',
          label: 'Subjects',
          renderCell: renderEmptyCell,
        },
        {
          key: 'age',
          label: 'Age',
          renderCell: renderEmptyCell,
        },
      ]}
      renderExpandedRow={(props) => (
        <KubernetesTableForRoleBindingRow {...props} />
      )}
    />
  );
}
