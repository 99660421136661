import classNames from 'classnames';
import { AutocompleteV2, Button, TooltipTrigger, useToaster } from 'components';
import { useAuth, useRequest } from 'hooks';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { MdClose as CloseIcon } from 'react-icons/md';

import {
  addUserToGroup,
  removeUserFromGroup,
} from '../GroupManagement/requests';
import { PermissionOptionsGroup } from '../GroupManagement/utils';
import { getUserGroupsById } from './requests';
import useUserManagementState from './useUserManagementState';

const UserManagementEditGroup = ({
  auth,
  userManagementState,
}: {
  auth: ReturnType<typeof useAuth>;
  userManagementState: ReturnType<typeof useUserManagementState>;
}): ReactElement => {
  const { addToast } = useToaster();
  const { userByIdRequest } = userManagementState;
  const user = userByIdRequest.result;
  const userGroupsByIdRequest = useRequest(getUserGroupsById);
  const updateGroupMemberPermissionRequest = useRequest(addUserToGroup);
  const removeGroupMemberRequest = useRequest(removeUserFromGroup);

  const onPermissionChange = (val: string, groupId: string) => {
    updateGroupMemberPermissionRequest
      .call({
        groupId,
        userId: user.id.toString(),
        permission: val,
        method: 'PUT',
      })
      .then((res) => {
        if (res) {
          addToast({ text: 'Permission updated', status: 'success' });
          userGroupsByIdRequest.call({ userId: user.id });
        }
      });
  };

  const isLoggedInUser = useMemo(() => {
    if (!auth.user || !user) return false;
    return auth.user.id === user.id;
  }, [auth.user, user]);

  const onRemoveMember = (groupId: string) => {
    if (!groupId) return;
    removeGroupMemberRequest.call({ groupId, userId: user.id }).then((res) => {
      if (res?.message) {
        addToast({
          text: isLoggedInUser
            ? 'You have been removed from the group'
            : 'User removed from group',
          status: 'success',
        });
      }

      userGroupsByIdRequest.call({ userId: user.id });
    });
  };

  useEffect(() => {
    if (user) {
      userGroupsByIdRequest.call({ userId: user.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="pb-4">
      <div className="alerts__contacts__list__header">
        <h2 className="text-lg font-semibold">Groups</h2>
      </div>
      <div>
        <table className="w-full table-auto">
          {userGroupsByIdRequest.isLoading ||
          removeGroupMemberRequest.isLoading ? (
            <tbody>
              <tr className="admin__row-muted">
                <td className="flex h-8 items-center justify-center">
                  <div className="spinner"></div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {userGroupsByIdRequest.result?.map((group, idx) => (
                <tr
                  key={group.id}
                  className={classNames({
                    'admin__row-muted': idx % 2 === 0,
                  })}
                >
                  <td className="h-10 pl-4 text-left font-medium">
                    {group.name}
                  </td>
                  <td className="w-[200px]">
                    <AutocompleteV2
                      className="max-h-[32x] w-full"
                      options={PermissionOptionsGroup}
                      value={group.permission}
                      onChange={(val: string) =>
                        onPermissionChange(val, group.id)
                      }
                    />
                  </td>
                  <td className="pr-2 pt-1 text-right">
                    <Button
                      className="w-8"
                      onClick={() => onRemoveMember(group.id)}
                      variant="ghost-destructive"
                      size="sm"
                    >
                      <TooltipTrigger
                        tooltip={isLoggedInUser ? 'Remove yourself' : 'Remove'}
                      >
                        <CloseIcon size={18} />
                      </TooltipTrigger>
                    </Button>
                  </td>
                </tr>
              ))}
              {userGroupsByIdRequest.result?.length === 0 && (
                <tr className="admin__row-muted">
                  <td className="flex h-8 items-center justify-center">
                    You are not a associated with any groups
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
        <div className="flex gap-2 pt-4"></div>
      </div>
    </div>
  );
};

export default UserManagementEditGroup;
