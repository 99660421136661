import classnames from 'classnames';
import { keyCodes } from 'kfuse-constants';
import { useKeyboardShortcut } from 'hooks';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { clamp } from 'utils';
import { SelectOption } from './types';

const getRenderedOptions = ({
  getSearchedValue,
  isAutocompleteEnabled,
  options,
  search,
}) => {
  if (isAutocompleteEnabled && search) {
    const searchLoweredParts = search.toLowerCase().split(' ');
    return options.filter((option) => {
      const { label, value } = option;
      const searchedValue =
        typeof getSearchedValue === 'function'
          ? getSearchedValue(value)
          : label;

      return (typeof searchedValue === 'string' ? searchedValue : '')
        .split(' ')
        .some((labelPart) =>
          searchLoweredParts.some(
            (searchLoweredPart) =>
              labelPart.toLowerCase().indexOf(searchLoweredPart) > -1,
          ),
        );
    });
  }
  return options;
};

type Props = {
  close: VoidFunction;
  getSearchedValue?: (value: any) => string;
  isAutocompleteEnabled?: boolean;
  onChange: (value: any) => void;
  options: SelectOption[];
  search: string;
  setSearch: (nextSearch: string) => void;
};

const SelectPanel = ({
  close,
  getSearchedValue,
  isAutocompleteEnabled,
  onChange,
  options,
  search,
  setSearch,
}: Props): ReactNode => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const scrollIntoView = (index: number) => {
    const option = renderedOptions[index];
    if (option) {
      const element = document.getElementById(JSON.stringify(option.value));
      if (element) {
        element.scrollIntoView();
      }
    }
  };

  const shortcutMap = useMemo(
    () => ({
      [keyCodes.UP]: {
        callback: () => {
          setActiveIndex((prevActiveIndex) => {
            const nextActiveIndex = clamp(
              prevActiveIndex - 1,
              -1,
              renderedOptions.length - 1,
            );
            scrollIntoView(nextActiveIndex);
            return nextActiveIndex;
          });
        },
      },
      [keyCodes.DOWN]: {
        callback: () => {
          setActiveIndex((prevActiveIndex) => {
            const nextActiveIndex = clamp(
              prevActiveIndex + 1,
              -1,
              renderedOptions.length - 1,
            );
            scrollIntoView(nextActiveIndex);

            return nextActiveIndex;
          });
        },
      },
      [keyCodes.ENTER]: {
        callback: () => {
          const option = renderedOptions[activeIndex];
          if (option) {
            onChange(option.value);
            close();
          }
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeIndex, options],
  );
  useKeyboardShortcut(shortcutMap);

  const onClickHandler = (nextValue: any) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(nextValue);
    close();
  };

  const renderedOptions = useMemo(
    () =>
      getRenderedOptions({
        getSearchedValue,
        isAutocompleteEnabled,
        options,
        search,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAutocompleteEnabled, getSearchedValue, options, search],
  );

  useEffect(() => {
    setSearch('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return renderedOptions.length ? (
    renderedOptions.map((option, i) => (
      <button
        className={classnames({
          select__panel__option: true,
          'select__panel__option--active': activeIndex === i,
        })}
        id={JSON.stringify(option.value)}
        key={i}
        onMouseDown={onClickHandler(option.value)}
      >
        <div className="select__panel__option__label">{option.label}</div>
      </button>
    ))
  ) : (
    <div className="select__panel__option select__panel__option--placeholder">
      <div className="select__panel__option__label">No options available</div>
    </div>
  );
};

export default SelectPanel;
