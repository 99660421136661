import { getIsLogRangeFacet } from 'utils';

const formatValueCounts = (type) => (valueCounts) => {
  return valueCounts.map((valueCount) => ({
    count: valueCount.count,
    value: String(
      getIsLogRangeFacet(type.toLowerCase())
        ? valueCount.floatValue
        : valueCount.value,
    ),
  }));
};

export default formatValueCounts;
