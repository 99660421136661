import { SearchState, useRequest } from 'hooks';
import React, { useMemo } from 'react';
import { getServices } from 'requests';
import useDebouncedEffect from 'use-debounced-effect';
import { DateSelection, SelectedFacetValuesByName } from 'types';

import { RuleProps } from '../types';
import { getFiltersStateFromArrayStateItem } from 'hooks/useFiltersState';
import { AlertsTracesParsedProps } from '../AlertsCreateTraces/type';
import { getTracesTags } from 'screens/Traces';

const AlertsDetailsPropertiesTraces = ({
  date,
  parsedMetadata,
  rule,
}: {
  date: DateSelection;
  parsedMetadata: AlertsTracesParsedProps;
  rule: RuleProps;
}) => {
  const getServicesRequest = useRequest(getServices, true, true);

  const serviceByHash = useMemo(() => {
    return getServicesRequest.result?.reduce(
      (obj, service) => ({ ...obj, [service.hash]: service }),
      {},
    );
  }, [getServicesRequest.result]);
  const { queries } = parsedMetadata;

  useDebouncedEffect(
    () => {
      const selectedFacetValuesByName = { span_type: { db: 0 } };
      getServicesRequest.call({
        customerFilter: {},
        date,
        selectedFacetValuesByName:
          selectedFacetValuesByName as SelectedFacetValuesByName,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    { ignoreInitialCall: false, timeout: 50 },
    [date],
  );

  const queriesWithFiltersState = useMemo(
    () => {
      const baseQueries = queries.map((query: SearchState) => ({
        ...query,
        filtersState: query.searchBarState.filters,
      }));

      return queries.map((query: SearchState, i: number) => ({
        ...query,
        filtersState: getFiltersStateFromArrayStateItem({
          key: 'filtersState',
          index: i,
          setState: () => {},
          state: baseQueries,
        }),
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queries],
  );

  const tagsWithQueries = useMemo(
    () => {
      return queriesWithFiltersState.map((query) => {
        const filtersState = query.filtersState;
        return getTracesTags({
          colorsByServiceName: {},
          filtersState,
          serviceByHash: serviceByHash || {},
          setTraceIdSearch: () => {},
          traceIdSearch: '',
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queriesWithFiltersState, serviceByHash],
  );

  return (
    <div className="alerts__details__properties__summary__item">
      <span className="text-sm font-bold text-text-secondary">Filters:</span>{' '}
      {tagsWithQueries.map((tags, index) => (
        <div key={index} className="flex flex-row items-center">
          <div className="mr-2 text-text-secondary">
            Query {queriesWithFiltersState[index].queryKey}
          </div>
          <div>
            {tags.map((t) => (
              <div className="chip" key={index}>
                {t.label}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AlertsDetailsPropertiesTraces;
