import { Loader, TreemapGraph } from 'components';
import React, { ReactElement, useMemo } from 'react';

const WIDTH_LEFT_RIGHT_OFFSET = 32;

const DashboardEditGraphTreemap = ({
  baseWidth,
  dataFormatter,
}: {
  baseWidth: number;
  dataFormatter: () => any;
}): ReactElement => {
  const treemapQueryData = useMemo(() => dataFormatter(), [dataFormatter]);

  const treemapData = useMemo(() => {
    return {
      name: '',
      children: treemapQueryData.treeChartData || [],
      color: 'transparent',
    };
  }, [treemapQueryData]);
  return (
    <Loader isLoading={treemapQueryData?.isLoading}>
      <div className="dashboard-edit__metric__body__top-list">
        {treemapQueryData?.data ? (
          <TreemapGraph
            data={treemapData}
            height={260}
            width={baseWidth - WIDTH_LEFT_RIGHT_OFFSET - 8}
          />
        ) : null}
      </div>
    </Loader>
  );
};

export default DashboardEditGraphTreemap;
