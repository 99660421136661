import classnames from 'classnames';
import React, { SyntheticEvent } from 'react';
import { RadioOption } from './types';

type Props = {
  className?: string;
  onChange: (nextValue: any) => void;
  options: RadioOption[];
  value: any;
};

const RadioGroup = ({ className, onChange, options, value }: Props) => {
  const onChangeHandler = (e: SyntheticEvent<HTMLInputElement>) => {
    const nextValue = e.currentTarget.value;
    onChange(nextValue);
  };

  return (
    <div
      className={classnames({ 'radio-group': true, [className]: className })}
    >
      {options.map((option: RadioOption) => {
        const checked = option.value === value;
        return (
          <div
            className={classnames({
              'radio-group__option': true,
              [`radio-group__option--checked`]: checked,
            })}
            key={option.value}
          >
            <input
              className="radio-group__option__input"
              checked={checked}
              id={option.value}
              onClick={onChangeHandler}
              type="radio"
              value={option.value}
            />
            <label
              className="radio-group__option__label"
              htmlFor={option.value}
            >
              {option.label}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default RadioGroup;
