import { useToaster } from 'components';
import { useRequest } from 'hooks';
import { deleteDashboardByUid } from 'requests';

const useDashboardDelete = () => {
  const { addToast } = useToaster();
  const dashboardDeleteByUidRequest = useRequest(deleteDashboardByUid);

  const onDeleteDashboard = (uid: string, name: string) => {
    return new Promise((resolve) => {
      dashboardDeleteByUidRequest.call(uid).then(() => {
        addToast({
          status: 'success',
          text: `${name} dashboard deleted successfully`,
        });
        resolve(true);
      });
    });
  };

  const onDeleteDashboardBulk = async (
    dashboards: Record<string, string>[],
  ) => {
    return Promise.all(
      dashboards.map((dashboard) => {
        return onDeleteDashboard(dashboard.uid, dashboard.title);
      }),
    );
  };

  return {
    onDeleteDashboard,
    onDeleteDashboardBulk,
    dashboardDeleteByUidRequest,
  };
};

export default useDashboardDelete;
