import { useContext } from 'react';
import { FilterContext } from './filterContext';

import { useUrlQueryStringReducer } from 'hooks';

// Global Hook to use the filter context
const useGlobalFilters = ({
  urlKeysToSync = [],
}: {
  urlKeysToSync?: string[];
} = {}) => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useGlobalFilters must be used within a FilterProvider');
  }

  //Events URL SYNC, writes back to URL on change and on Mount loads from URL if available
  useUrlQueryStringReducer({
    key: 'date',
    state: context.globalFilters.events.date,
    dispatch: ({ payload }) => {
      try {
        const parsed = JSON.parse(payload);
        if (parsed && typeof parsed === 'object') {
          context.globalFilters.events.setDate(parsed);
        }
      } catch (e) {
        console.error('Error parsing date from URL', e);
      }
    },
    shouldWriteToUrl: urlKeysToSync.includes('DATE'),
  });

  useUrlQueryStringReducer({
    key: 'filter',
    state: context.globalFilters.events.filterState.filter,
    dispatch: context.globalFilters.events.filterState.filterDispatch,
    shouldWriteToUrl: urlKeysToSync.includes('EVENTS_FILTER'),
  });

  return context;
};

export default useGlobalFilters;
