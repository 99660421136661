import { ChartGridV2 } from 'components';
import React, { useMemo } from 'react';
import { DateSelection } from 'types';
import { multipleQueryRangeWithLabels } from 'utils/chartGrid';
import { getQueryForServerlessMetric } from '../hooks/utils';
import { queryRangeStep } from '../utils';

type Args = {
  colorsByFunctionName: { [key: string]: string };
  date: DateSelection;
  functionArn: string;
  serverlessMetrics: string[];
};

const getRows = ({
  colorsByFunctionName,
  date,
  functionArn,
  serverlessMetrics,
}: Args) => {
  const options = serverlessMetrics.map((metric) => ({
    label: metric.replace('aws_lambda_', '').replace(/_/g, ' '),
    value: metric,
  }));

  const colorMap = colorsByFunctionName;
  const step = `${queryRangeStep(date)}s`;
  return [
    [
      {
        initialKey: 'aws_lambda_concurrent_executions',
        charts: options.map((option) => ({
          key: option.value,
          chartType: 'line',
          colorMap,
          label: option.label,
          legendTableColumns: ['key', 'min', 'max', 'avg'],
          libraryType: 'uplot',
          onSelection: () => {},
          query: multipleQueryRangeWithLabels(
            [
              () =>
                getQueryForServerlessMetric({
                  functionArn,
                  param: option.value,
                  step,
                }),
            ],
            [[functionArn]],
          ),
          tooltipProps: { layoutType: 'drawer' },
        })),
      },
      {
        initialKey: 'aws_lambda_duration_maximum',
        charts: options.map((option) => ({
          key: option.value,
          chartType: 'line',
          colorMap,
          label: option.label,
          legendTableColumns: ['key', 'min', 'max', 'avg'],
          libraryType: 'uplot',
          onSelection: () => {},
          query: multipleQueryRangeWithLabels(
            [
              () =>
                getQueryForServerlessMetric({
                  functionArn,
                  param: option.value,
                  step,
                }),
            ],
            [[functionArn]],
          ),
          tooltipProps: { layoutType: 'drawer' },
        })),
      },
    ],
    [
      {
        initialKey: 'aws_lambda_throttles',
        charts: options.map((option) => ({
          key: option.value,
          chartType: 'line',
          colorMap,
          label: option.label,
          legendTableColumns: ['key', 'min', 'max', 'avg'],
          libraryType: 'uplot',
          onSelection: () => {},
          query: multipleQueryRangeWithLabels(
            [
              () =>
                getQueryForServerlessMetric({
                  functionArn,
                  param: option.value,
                  step,
                }),
            ],
            [[functionArn]],
          ),
          tooltipProps: { layoutType: 'drawer' },
        })),
      },
      {
        initialKey: 'aws_lambda_async_event_age',
        charts: options.map((option) => ({
          key: option.value,
          chartType: 'line',
          colorMap,
          label: option.label,
          legendTableColumns: ['key', 'min', 'max', 'avg'],
          libraryType: 'uplot',
          onSelection: () => {},
          query: multipleQueryRangeWithLabels(
            [
              () =>
                getQueryForServerlessMetric({
                  functionArn,
                  param: option.value,
                  step,
                }),
            ],
            [[functionArn]],
          ),
          tooltipProps: { layoutType: 'drawer' },
        })),
      },
    ],
  ];
};

type Props = {
  colorsByFunctionName: { [key: string]: string };
  date: DateSelection;
  functionArn: string;
  serverlessMetrics: string[];
};

const ServerlessRightSidebarMetrics = ({
  colorsByFunctionName,
  date,
  functionArn,
  serverlessMetrics,
}: Props) => {
  const rows = useMemo(
    () =>
      getRows({ colorsByFunctionName, date, functionArn, serverlessMetrics }),
    [colorsByFunctionName, date, functionArn, serverlessMetrics],
  );

  return (
    <div className="serverless__right-sidebar__metrics">
      <ChartGridV2.ChartGrid date={date} rows={rows} />
    </div>
  );
};

export default ServerlessRightSidebarMetrics;
