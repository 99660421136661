import { useEffect, useMemo, useState } from 'react';
import { validateTraceFormulaExpression } from 'utils';

import { FormulaState, SearchState } from './types';
import { getInitialFormulaState } from './utils';

const useSearchFormulas = (
  queries: SearchState[],
  initialFormulas?: Array<FormulaState>,
) => {
  const [formulas, setFormulas] = useState<Array<FormulaState>>(
    initialFormulas ? initialFormulas : [],
  );

  const addNewFormula = (isNewOnlyActive?: boolean) => {
    if (isNewOnlyActive) {
      setFormulas((prevFormulas) => [
        ...prevFormulas.map((formula) => ({
          ...formula,
          isActive: false,
        })),
        {
          ...getInitialFormulaState(
            prevFormulas[prevFormulas.length - 1]?.queryKey,
          ),
          isActive: true,
        },
      ]);
    } else {
      setFormulas((prevFormulas) => [
        ...prevFormulas,
        getInitialFormulaState(prevFormulas[prevFormulas.length - 1]?.queryKey),
      ]);
    }
  };

  const deactivateAll = () => {
    setFormulas((prevFormulas) =>
      prevFormulas.map((formula) => ({ ...formula, isActive: false })),
    );
  };

  const activateOnlySingleFormula = (index: number) => {
    setFormulas((prevFormulas) =>
      prevFormulas.map((formula, i) => ({
        ...formula,
        isActive: i === index,
      })),
    );
  };

  const setStateByIndex = (index, nextState) => {
    setFormulas((prevSearches) => {
      const nextSearches = [...prevSearches];
      const prevSearch = prevSearches[index];
      const nextSearch =
        typeof nextState === 'function' ? nextState(prevSearch) : nextState;
      nextSearches[index] = { ...prevSearch, ...nextSearch };
      return nextSearches;
    });
  };

  const changeHandlerByIndex =
    <T extends keyof FormulaState>(index: number, key: T) =>
    (value: FormulaState[T]) => {
      if (key === 'expression') {
        const isValid = validateTraceFormulaExpression(
          value as string,
          queries,
        );
        setStateByIndex(index, { [key]: value, isValid });
      } else {
        setStateByIndex(index, { [key]: value });
      }
    };

  useEffect(() => {
    setFormulas((prev) => {
      const next = [...prev];
      let isChanged = false;
      next.forEach((formula, i) => {
        const isValid = validateTraceFormulaExpression(
          formula.expression as string,
          queries,
        );
        next[i] = { ...formula, isValid };
        isChanged = isChanged || formula.isValid !== isValid;
      });

      if (isChanged) return next;
      return prev;
    });
  }, [queries]);

  const memoizedSearchesFormulas = useMemo(() => {
    return formulas.map((state, i) => ({
      ...state,
      addNewFormula,
      activateOnlySingleFormula: () => activateOnlySingleFormula(i),
      updateFormulaExprByIndex: changeHandlerByIndex(i, 'expression'),
      updateFormulaIsActiveByIndex: changeHandlerByIndex(i, 'isActive'),
      removeExistingSearch: () => {
        setFormulas((prevSearches) => {
          const nextSearches = [...prevSearches];
          nextSearches.splice(i, 1);
          return nextSearches;
        });
      },
      state,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formulas]);

  return {
    formulas: memoizedSearchesFormulas,
    formulasState: formulas,
    addNewFormula,
    deactivateAll,
  };
};

export default useSearchFormulas;
