import colorsByLogLevel from 'kfuse-constants/colorsByLogLevel';
import React, { ReactElement } from 'react';
import { Bar, BarChart, Tooltip, YAxis } from 'recharts';
import { formatNumber } from 'utils/formatNumber';

const CustomTooltip = ({
  active,
  payload,
  label,
  labels,
}: {
  active: boolean;
  payload?: Array<{
    dataKey: string;
    payload: { [key: string]: number };
    fill: string;
  }>;
  label?: string;
  labels: { [key: string]: string };
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="logs__timeline__chart__tooltip">
        <div className="logs__timeline__chart__tooltip__inner">
          <div className="logs__timeline__chart__tooltip__label">
            {labels[label]}
          </div>
          <div className="logs__timeline__chart__tooltip__payload">
            {payload.map((row, idx: number) => (
              <div
                key={idx}
                className="logs__timeline__chart__tooltip__payload__row"
              >
                <div
                  className="logs__timeline__chart__tooltip__payload__row__fill"
                  style={{ backgroundColor: row.fill }}
                />
                <div className="logs__timeline__chart__tooltip__payload__row__label">
                  {row.dataKey}
                </div>
                <div className="logs__timeline__chart__tooltip__payload__row__count">
                  {formatNumber(row.payload[row.dataKey])}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const MetricsCardinalityBarChart = ({
  data,
}: {
  data: { data: any; max: number };
}): ReactElement => {
  if (!data.data || data.data.length === 0)
    return <div style={{ height: 40 }}></div>;
  return (
    <BarChart
      data={data.data}
      height={40}
      margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      width={120}
    >
      <Tooltip content={<CustomTooltip labels={{ count: 'Count' }} />} />
      <YAxis domain={[0, data.max]} hide type="number" />
      <Bar
        dataKey={'count'}
        stackId="a"
        fill={colorsByLogLevel['info'] || '#000000'}
      />
    </BarChart>
  );
};

export default MetricsCardinalityBarChart;
