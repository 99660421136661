import { SelectV2 } from 'components';
import React, { ReactElement } from 'react';

import {
  AlertsCreateConditionInfo,
  useAlertsCreateCondition,
} from '../AlertsCreateCondition';
import { forecastAlertPopupMessage } from '../constants';
import { forecastLinearDurationOptions } from './utils';

const AlertsCreateForecastConditionLinear = ({
  conditionState,
  handleForecastDurationChange,
}: {
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
  handleForecastDurationChange: (val: string) => void;
}): ReactElement => {
  const { forecastCondition } = conditionState;

  return (
    <div>
      <div className="alerts__create__section__item">
        <div className="alerts__create__conditions__item__text">
          and Forecast Duration:
        </div>
        <div className="alert__create__anomaly__detaction_desciption">
          <AlertsCreateConditionInfo
            description={forecastAlertPopupMessage.LINEAR_FORECAST_DURATION}
          />
        </div>
        <div className="alerts__create__conditions__item__input alerts__create__conditions__item__input--select">
          <SelectV2.Select
            onChange={(val: string) => handleForecastDurationChange(val)}
            onEnter={(val: string) => handleForecastDurationChange(val)}
            isAutocompleteEnabled
            options={forecastLinearDurationOptions}
            value={forecastCondition.forecastDuration}
          />
        </div>
      </div>
    </div>
  );
};

export default AlertsCreateForecastConditionLinear;
