import { ThemeContextProvider } from 'components';
import React from 'react';
import Root from './Root';
import { ConfigProvider } from './context';

const RootWithContexts = () => {
  return (
    <ConfigProvider>
      <ThemeContextProvider>
        <Root />
      </ThemeContextProvider>
    </ConfigProvider>
  );
};

export default RootWithContexts;
