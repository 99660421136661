import React, { ComponentPropsWithoutRef } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { createPrimitiveComponent, createArrayVariants } from './shared';

const sharedVariants = {
  align: {
    start: 'items-start',
    center: 'items-center',
    end: 'items-end',
    baseline: 'items-baseline',
    stretch: 'items-stretch',
  },
  justify: {
    start: 'justify-start',
    center: 'justify-center',
    end: 'justify-end',
    between: 'justify-between',
  },
  wrap: {
    true: 'flex-wrap',
    nowrap: 'flex-nowrap',
    wrap: 'flex-wrap',
    'wrap-reverse': 'flex-wrap-reverse',
  },
  gap: createArrayVariants(['gap-0', 'gap-1', 'gap-2', 'gap-3']),
};

export const Column = createPrimitiveComponent(
  'layout::FlexColumn',
  'div',
  cva('flex flex-col gap-1', {
    variants: sharedVariants,
    defaultVariants: {
      gap: '1',
    },
  }),
);

export type ColumnProps = ComponentPropsWithoutRef<typeof Column>;

export const Row = createPrimitiveComponent(
  'layout::FlexRow',
  'div',
  cva('flex items-baseline gap-1', {
    variants: sharedVariants,
    defaultVariants: {
      gap: '1',
    },
  }),
);

export type RowProps = ComponentPropsWithoutRef<typeof Row>;

export const Item = createPrimitiveComponent(
  'layout::FlexItem',
  'div',
  cva('', {
    variants: {
      // default is flex-grow: 1, flex-shrink: 1, flex-basis: 0%
      grow: {
        true: 'grow',
        undefined: '',
      },
      shrink: {
        true: 'min-w-0',
        undefined: '',
      },
      'no-grow': {
        true: 'grow-0',
      },
      'no-shrink': {
        true: 'shrink-0',
      },
      basis: {},
    },
  }),
);

export type ItemProps = ComponentPropsWithoutRef<typeof Item>;
