import dayjs from 'dayjs';
import {
  AreaSelectionProps,
  DateSelection,
  FacetRegexTerm,
  KeyExists,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  SpanFilter,
} from 'types';
import { onPromiseError } from 'utils';
import { CompareAttributeCount } from '../types';
import { buildTracesFilter, queryTraceService } from 'requests';

type Args = {
  areaSelection: AreaSelectionProps;
  customerFilter?: { key: string; value: string };
  date: DateSelection;
  facetRegex: FacetRegexTerm[];
  keyExists: KeyExists;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  spanFilter?: SpanFilter;
  ParentSpanIdFilter?: string;
  traceIdSearch: string;
};

const getCompareAttributeCounts = async ({
  areaSelection,
  customerFilter,
  date,
  facetRegex,
  keyExists,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  spanFilter,
  traceIdSearch,
}: Args): Promise<CompareAttributeCount[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTimeDayJs = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  const { startTime, startValue, endTime, endValue } = areaSelection;

  const filter = buildTracesFilter({
    customerFilter,
    facetRegex,
    keyExists,
    selectedFacetRangeByName,
    selectedFacetValuesByName,
    spanFilter,
    traceIdSearch,
  });

  return queryTraceService<CompareAttributeCount[], 'compareAttributeCounts'>(`
    {
      compareAttributeCounts(
        timestamp: "${endTimeDayJs.format()}",
        durationSecs: ${durationSecs},
        filter: ${filter}
        attributeComparisonFilter: {
          attrBucketStart: ${startValue},
          attrBucketEnd: ${endValue},
          attrBucketUnit: "ms"
          timeBucketStartSecs: ${startTime},
          timeBucketEndSecs: ${endTime},
        }
      ){
        name
        counts{
          value
          baselineCount
          selectionCount
          selectionIncidence
          baselineIncidence
        }
        selectionTotalCount
        baselineTotalCount
        maxRelativeIncidence
      }
    }
  `).then((data) => data.compareAttributeCounts || [], onPromiseError);
};

export default getCompareAttributeCounts;
