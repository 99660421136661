import { useSelectedFacetValuesByNameState } from 'hooks';
import React, { ReactElement } from 'react';

import AlertsSidebarFacetGroup from './AlertsSidebarFacetGroup';
import { AlertsFacet } from '../utils';
import { useAlertsState } from '../hooks';

const AlertsSidebar = ({
  alertsState,
  selectedFacetValuesByNameState,
}: {
  alertsState: ReturnType<typeof useAlertsState>;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
}): ReactElement => {
  const { alertsProperties, getPredefinedFacetValues } = alertsState;

  return (
    <div className="events__sidebar">
      <AlertsSidebarFacetGroup
        alertsProperties={alertsProperties}
        forceExpanded
        facetNames={AlertsFacet}
        selectedFacetValuesByNameState={selectedFacetValuesByNameState}
        request={getPredefinedFacetValues}
      />
    </div>
  );
};

export default AlertsSidebar;
