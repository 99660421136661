import { BitmapFilter, Filter, FilterType, Operator } from './types';
import useFiltersState from './useFiltersState';
import useKeyExistsState from '../useKeyExistsState';

type KeyExistsFilterType =
  | FilterType.keyExists
  | FilterType.filterOrExcludeByFingerprint;

type FindKeyExistsFilterByKeyArgs = {
  key: string;
  filterType: KeyExistsFilterType;
};

const findKeyExistsFilterByKey =
  ({ key, filterType }: FindKeyExistsFilterByKeyArgs) =>
  (filter: Filter) =>
    filter.type === filterType && filter.value.facet === key;

type GetKeyExistsFilterArgs = {
  filterType: KeyExistsFilterType;
  key: string;
  operator: Operator;
};

const getKeyExistsFilter = ({
  filterType,
  key,
  operator,
}: GetKeyExistsFilterArgs) => ({
  disabled: false,
  type: filterType,
  value: {
    operator,
    facet: key,
  },
});

const getState = (
  state: ReturnType<typeof useFiltersState>['state'],
  filterType: KeyExistsFilterType,
) => {
  if (!state) return {};

  const keyExistsFilters = state.filter(
    (filter) => filter.type === filterType,
  ) as BitmapFilter[];

  return keyExistsFilters.reduce(
    (obj, filter) => ({
      ...obj,
      [filter.value.facet]: filter.value.operator === Operator.equal ? 1 : 0,
    }),
    {},
  );
};

export const getLegacyKeyExistsState = (
  state: Filter[],
): ReturnType<typeof useKeyExistsState>['state'] => {
  return state
    .filter((filter) => filter.type === FilterType.keyExists)
    .reduce(
      (obj, filter: BitmapFilter) => ({
        ...obj,
        [filter.value.facet]: filter.value.operator === Operator.equal ? 1 : 0,
      }),
      {},
    );
};

const getSetState = (setState, filterType) => (nextStateOrCallback) => {
  setState((prevState) => {
    const nextKeyExistsState =
      typeof nextStateOrCallback === 'function'
        ? nextStateOrCallback(getState(prevState, filterType))
        : nextStateOrCallback;

    const nextState = [...prevState];
    Object.keys(nextKeyExistsState).forEach((facet) => {
      const operator = nextKeyExistsState[facet]
        ? Operator.notEqual
        : Operator.equal;

      const index = nextState.findIndex(
        findKeyExistsFilterByKey({ key: facet, filterType }),
      );
      const nextIndex = index > -1 ? index : nextState.length;

      nextState[nextIndex] = getKeyExistsFilter({
        filterType,
        key: facet,
        operator,
      });
    });

    return nextState;
  });
};

type Args = {
  filtersState: ReturnType<typeof useFiltersState>;
  filterType: KeyExistsFilterType;
};

const getKeyExistsState = ({
  filtersState,
  filterType,
}: Args): ReturnType<typeof useKeyExistsState> => {
  const { setState, state } = filtersState;

  const toggleKeyExists = (key: string) => {
    setState((prevState) => {
      const nextState = [...prevState];
      const filterIndex = prevState.findIndex(
        findKeyExistsFilterByKey({ filterType, key }),
      );

      if (filterIndex > -1) {
        nextState.splice(filterIndex, 1);
        return nextState;
      }

      const prevFilter =
        filterIndex > -1 ? (prevState[filterIndex] as BitmapFilter) : null;
      const nextOperator =
        prevFilter && prevFilter.value.operator === Operator.equal
          ? Operator.notEqual
          : Operator.equal;

      const nextIndex = filterIndex > -1 ? filterIndex : prevState.length;
      const keyExistsFilter = getKeyExistsFilter({
        key,
        filterType,
        operator: nextOperator,
      }) as Filter;

      nextState[nextIndex] = keyExistsFilter;
      return nextState;
    });
  };

  const setKeyExists = (key: string, value?: number) => {
    setState((prevState) => {
      const nextState = [...prevState];
      const filterIndex = prevState.findIndex(
        findKeyExistsFilterByKey({ key, filterType }),
      );
      const nextIndex = filterIndex > -1 ? filterIndex : prevState.length;
      nextState[nextIndex] = getKeyExistsFilter({
        key,
        filterType,
        operator: value === 0 ? Operator.notEqual : Operator.equal,
      }) as Filter;
      return nextState;
    });
  };

  const unsetKeyExists = (key: string) => {
    setState((prevState) => {
      const nextState = [...prevState];
      const filterIndex = prevState.findIndex(
        findKeyExistsFilterByKey({ filterType, key }),
      );
      if (filterIndex > -1) {
        nextState.splice(filterIndex, 1);
      }

      return nextState;
    });
  };

  return {
    setState: getSetState(setState, filterType),
    state: getState(state, filterType),
    setKeyExists,
    toggleKeyExists,
    unsetKeyExists,
  };
};

export default getKeyExistsState;
