import { useToggle } from 'hooks';
import React from 'react';
import AccordionContent from './AccordionContent';
import AccordionRoot from './AccordionRoot';
import AccordionTrigger from './AccordionTrigger';

type Props = {
  className?: string;
  defaultOpen?: boolean;
  renderContent: () => JSX.Element | JSX.Element[];
  renderTrigger: () => JSX.Element | JSX.Element[];
};

const Accordion = ({
  className,
  defaultOpen = false,
  renderContent,
  renderTrigger,
}: Props) => {
  const isOpenToggle = useToggle(defaultOpen);

  return (
    <AccordionRoot
      className={className}
      isOpenToggle={isOpenToggle}
      forceOpen={defaultOpen}
    >
      <AccordionTrigger renderTrigger={renderTrigger} />
      <AccordionContent
        isOpenToggle={isOpenToggle}
        renderContent={renderContent}
      />
    </AccordionRoot>
  );
};

export default Accordion;
