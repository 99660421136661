export enum Operator {
  NotEqual = '!=',
  GreaterThanEqual = '>=',
  GreaterThan = '>',
  LessThanEqual = '<=',
  LessThan = '<',
  Regex = '=~',
  NotRegex = '!~',
  Equal = '=',
}

export const operator: { [key: string]: string } = {
  '!=': 'neq',
  '>=': 'gte',
  '>': 'gt',
  '<=': 'lte',
  '<': 'lt',
  '=~': 'regex',
  '=': 'eq',
};

export const getOperatorInQuery = (query: string): string => {
  const result = Object.keys(operator).find((key) => query.includes(key));
  return result || '';
};

export const getOperatorSign = (op: string): string => {
  const invertedOperator: { [key: string]: string } = Object.keys(
    operator,
  ).reduce((acc, key) => {
    acc[operator[key]] = key;
    return acc;
  }, {});
  return invertedOperator[op] || '';
};

export const eventQueryOperator: { [key: string]: string } = {
  '!=': 'neq',
  '=~': 'regex',
  '=': 'eq',
};

export const silenceMatcherOperator: { [key: string]: string } = {
  '!=': 'neq',
  '=~': 'regex',
  '!~': 'notregex',
  '=': 'eq',
};

export const policyScopeFilterOperator: { [key: string]: string } = {
  '=~': 'regex',
  '=': 'eq',
};

export const getEventOperatorInQuery = (query: string): string => {
  const result = Object.keys(eventQueryOperator).find((key) =>
    query.includes(key),
  );
  return result || '';
};

export const logsQueryOperator: { [key: string]: string } = {
  '!==': 'notFacetTermsExist',
  '!=': 'neq',
  '>=': 'gte',
  '>': 'gt',
  '<=': 'lte',
  '<': 'lt',
  '!~': 'notregex',
  '=~': 'regex',
  '==': 'facetTermsExist',
  '=': 'eq',
  '*~': 'startsWith',
  '**': 'contains',
  '~*': 'endsWith',
};

export const getLogsOperatorInQuery = (query: string): string => {
  const result = Object.keys(logsQueryOperator).find((key) =>
    query.includes(key),
  );
  return result || '';
};

export const getLogsOperatorSign = (op: string): string => {
  const invertedOperator: { [key: string]: string } = Object.keys(
    logsQueryOperator,
  ).reduce((acc, key) => {
    acc[logsQueryOperator[key]] = key;
    return acc;
  }, {});
  return invertedOperator[op] || '';
};

export const prometheusArithmeticOperator: string[] = [
  '+',
  '-',
  '*',
  '/',
  '%',
  '^',
];

export const prometheusComparisonOperator: string[] = [
  '==',
  '!=',
  '>',
  '<',
  '>=',
  '<=',
];

export const prometheusLogicalOperator: string[] = ['and', 'or', 'unless'];

export const prometheusQueryOperator: string[] = [
  ...prometheusArithmeticOperator,
  ...prometheusComparisonOperator,
];

export const prometheusQueryOperatorRegex =
  '\\+|\\-|\\*|\\/|\\%|\\^|==|!=|>|<|>=|<=';

export const rumQueryOperators: { [key: string]: string } = {
  '!=': 'neq',
  '=': 'eq',
  '!~': 'notregex',
  '=~': 'regex',
  '>': 'gt',
  '>=': 'gte',
  '<': 'lt',
  '<=': 'lte',
};
