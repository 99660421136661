import fetchGrafanaApi from './fetchGrafanaApi';

const getGrafanaAlertsRulesByFolder = async ({
  folderName,
  folderUid,
}: {
  folderName: string;
  folderUid: string;
}) => {
  const requestListURI = [
    `grafana/api/ruler/grafana/api/v1/rules/${folderUid}?subtype=cortex`,
    `grafana/api/prometheus/grafana/api/v1/rules`,
  ];
  const datasets: Array<any> = await Promise.all(
    requestListURI.map((uri) =>
      fetchGrafanaApi(uri, {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      }),
    ),
  );

  const [rulerDataWithFolder, prometheusData] = datasets;
  const rulerDataList = rulerDataWithFolder[folderName];
  if (!rulerDataList) return {};

  const prometheusDataGroup = prometheusData.data.groups.find(
    (group: any) => group.file === folderName,
  );
  const newRulerDataList = rulerDataList.map((rulerData: any) => {
    return {
      ...rulerData,
      rules: rulerData.rules.map((rule: any) => {
        const prometheusDataRule = prometheusDataGroup.rules.find(
          (prometheusRule: any) =>
            prometheusRule.name === rule.grafana_alert.title,
        );

        return {
          ...rule,
          grafana_alert: {
            ...rule.grafana_alert,
            state: prometheusDataRule?.state,
          },
        };
      }),
    };
  });

  return { [folderName]: newRulerDataList };
};

export default getGrafanaAlertsRulesByFolder;
