import { tooltipFormatter } from 'components/Timeseries/utils';
import Uplot from 'uplot';
import {
  canvasPadding,
  getOperatorSign,
  drawLineOnTimeseries,
  drawRectangeOnTimeseries,
  ctaColor,
  chartThresholdBandColor,
} from 'utils';

const drawChartThresold = ({
  u,
  conditionValue,
  conditionOperator,
  darkModeEnabled,
  unit,
}: {
  u: Uplot;
  conditionValue: number;
  conditionOperator: string;
  darkModeEnabled: boolean;
  unit: string;
}): void => {
  if (isNaN(conditionValue)) return;

  const { ctx, height, width } = u;
  const padding = canvasPadding(u);
  const devicePixelRatio = window.devicePixelRatio || 1;
  const TOTAL_HEIGHT = height * devicePixelRatio;
  const TOTAL_WIDTH = width * devicePixelRatio;
  const CANVAS_WIDTH = TOTAL_WIDTH - padding.left - padding.right;
  const valuePosition = u.valToPos(conditionValue, 'y', true);
  const yExceedsTop = valuePosition < 0;
  const yExceedsBottom = valuePosition > TOTAL_HEIGHT - padding.bottom;
  let y = valuePosition;
  if (yExceedsTop) {
    y = padding.top;
  }
  if (yExceedsBottom) {
    y = TOTAL_HEIGHT - padding.bottom;
  }

  drawLineOnTimeseries({
    ctx,
    start: { x: padding.left, y },
    end: { x: TOTAL_WIDTH - padding.right, y },
    options: { color: ctaColor.red, dash: [10, 10], lineWidth: 2 },
  });

  let fillHeight = 0;
  const isAbove = conditionOperator === 'gt' || conditionOperator === 'gte';
  const isBelow = conditionOperator === 'lt' || conditionOperator === 'lte';
  if (isAbove) {
    fillHeight = y - padding.top;
  }
  if (isBelow) {
    fillHeight = TOTAL_HEIGHT - y - padding.bottom;
  }
  // Draw the rectangle
  drawRectangeOnTimeseries({
    ctx,
    x: padding.left,
    y: isAbove ? padding.top : y,
    width: CANVAS_WIDTH,
    height: fillHeight,
    options: {
      color: darkModeEnabled
        ? chartThresholdBandColor.dark
        : chartThresholdBandColor.light,
    },
  });

  // Draw the text
  const text = `y ${getOperatorSign(conditionOperator)} ${tooltipFormatter(
    conditionValue || 0,
    unit,
  )}`;
  ctx.save();
  const fontSize = 10 * devicePixelRatio;
  ctx.font = `600 ${fontSize}pt sans-serif`;
  ctx.fillStyle = ctaColor.red;
  const textWidth = ctx.measureText(text).width;
  const yOffset = isAbove ? -16 : 20;
  const xOffset = 12;

  ctx.fillText(text, padding.left + textWidth + xOffset, y + yOffset);
  ctx.restore();
};

export default drawChartThresold;
