import { ChipWithLabel, FacetPicker, FacetAccordion } from 'components';
import { useFilterState } from 'hooks';
import { colorsByLogLevel } from 'kfuse-constants';
import React, { ReactElement, useEffect, useState } from 'react';
import { DateSelection, FilterMethodByType } from 'types';

const ServerlessSidebarFacetGroup = ({
  component,
  date,
  facetNames,
  filterState,
  forceExpanded,
  request,
  sidebarFilterState,
}: {
  component: string;
  date: DateSelection;
  facetNames: any;
  filterState: ReturnType<typeof useFilterState>;
  forceExpanded?: boolean;
  request: any;
  sidebarFilterState: FilterMethodByType<'map'>;
}): ReactElement => {
  const [lastRefreshedAt, setLastRefreshedAt] = useState(null);
  const [expanded, setExpanded] = useState(forceExpanded);

  const {
    addMappedFilter,
    excludeFromMappedFilter,
    removeMappedFilterByKey,
    state,
    toggleMappedFilterByKey,
  } = sidebarFilterState;

  useEffect(() => {
    setLastRefreshedAt(new Date());
  }, [date, filterState?.filter]);

  const handlersByName = (name: string) => ({
    excludeFacetValue: (value: string) => {
      excludeFromMappedFilter('sidebarFilters', name, value);
    },
    selectOnlyFacetValue: (value: string) => {
      addMappedFilter('sidebarFilters', name, value);
    },
    toggleFacetValue: (value: string, allValues: Array<string>) => {
      toggleMappedFilterByKey(
        'sidebarFilters',
        name,
        value,
        allValues,
        state[name] || {},
      );
    },
  });

  const clearFacetHandler = (name: string) => () => {
    removeMappedFilterByKey('sidebarFilters', name);
  };

  return (
    <FacetAccordion
      renderContent={() => {
        return expanded ? (
          <div>
            {facetNames.map((facet: any) => {
              const name = facet.name;
              return (
                <FacetPicker
                  forceExpanded={false}
                  clearFacet={clearFacetHandler(name)}
                  key={name}
                  lastRefreshedAt={lastRefreshedAt}
                  name={name}
                  renderName={(s) => {
                    if (s === 'source_type_name') {
                      return 'source';
                    }
                    if (s === 'alert_type') {
                      return 'status';
                    }
                    if (s === 'text') {
                      return 'message';
                    }
                    return s.replace(/_+/g, ' ');
                  }}
                  request={request(name)}
                  selectedFacetValues={state[name] || {}}
                  renderValue={(label) => {
                    if (name === 'alert_type') {
                      return (
                        <ChipWithLabel
                          color={colorsByLogLevel[label]}
                          label={label}
                        />
                      );
                    }
                    return label;
                  }}
                  {...handlersByName(name)}
                />
              );
            })}
          </div>
        ) : null;
      }}
      renderTrigger={() => component}
      expanded={expanded}
      setExpanded={setExpanded}
      isVisuallyHidden={false}
      triggerClassName="leading-[28px] pl-2 mb-1 w-full active:bg-interaction-nested-contrast hover:bg-interaction-nested data-[state=open]:bg-interaction-nested border-t-0 border-transparent cursor-pointer group"
    />
  );
};

export default ServerlessSidebarFacetGroup;
