import eventsTimeSeriesDataTransformer from './events/eventsTimeSeriesDataTransformer';
import labelTransformer from './labelTransformer/labelTransformer';
import {
  eventqlDataTransformer,
  eventqlDataTransformerInstant,
} from './rangeTransformer';

const eventsDataTransformer = (isInstant?: boolean) => {
  if (isInstant) {
    return [
      {
        id: 'prometheusToDataFrame',
        func: eventqlDataTransformerInstant,
      },
    ];
  }

  return [
    {
      id: 'prometheusToDataFrame',
      func: eventqlDataTransformer,
    },
    {
      id: 'transformToDisplayLabel',
      func: labelTransformer,
    },
    {
      id: 'transformToTimeseries',
      func: eventsTimeSeriesDataTransformer,
    },
  ];
};

export default eventsDataTransformer;
