import React from 'react';
import { FiInfo as InfoIconCircle } from 'react-icons/fi';
import { HOURS, DAYS, WEEKS } from 'kfuse-constants/timeInNumber';
import { DateSelection, LogsMetricQueryProps } from 'types';
import { KfuseQlFunction } from 'utils/KfuseqlBuilder/types';
import { getRollupByVisualization } from 'utils/rollup';
import { seasonalityNumToText } from 'utils/KfuseqlBuilder/kfuseBuilderFunction';

enum Seasonality {
  HOURLY = '1',
  DAILY = '2',
  WEEKLY = '3',
}

const MIN_TIME_FOR_HOURLY = HOURS * 2;
const MIN_TIME_FOR_DAILY = DAYS * 2;
const MIN_TIME_FOR_WEEKLY = WEEKS * 2;

const SEASONALITY_CONFIG = {
  [Seasonality.HOURLY]: MIN_TIME_FOR_HOURLY,
  [Seasonality.DAILY]: MIN_TIME_FOR_DAILY,
  [Seasonality.WEEKLY]: MIN_TIME_FOR_WEEKLY,
} as const;

export const calcAgileRobustAnomalyTimeRange = (
  anomalySeasonality: string,
  date: DateSelection,
): { agileRobustAnomalyDate: DateSelection; anomalyStep: number } => {
  const { startTimeUnix, endTimeUnix } = date;
  const diff = endTimeUnix - startTimeUnix;

  const agileRobustAnomalyDate = { ...date };

  const minTime = SEASONALITY_CONFIG[anomalySeasonality as Seasonality];
  if (minTime && diff < minTime) {
    agileRobustAnomalyDate.startTimeUnix = endTimeUnix - minTime;
  }

  const step = getRollupByVisualization(agileRobustAnomalyDate, 'bar');
  return { agileRobustAnomalyDate, anomalyStep: step };
};

export const getAgileRobustAnomalyInfoMessage = ({
  queries,
}: {
  queries: LogsMetricQueryProps[];
}): React.ReactNode | null => {
  const activeQueries = queries.filter((query) => query.isActive);
  const queriesWithAnomaly = activeQueries.filter((query) =>
    query.functions?.some((func) => func.name === KfuseQlFunction.ANOMALIES),
  );
  if (queriesWithAnomaly.length === 0) return null;

  let seasonality = undefined;
  queriesWithAnomaly.forEach((query) => {
    const anomalyFunc = query.functions?.find(
      (func) => func.name === KfuseQlFunction.ANOMALIES,
    );
    if (!anomalyFunc) return;

    const algorithm = anomalyFunc.params[0].value;
    if (algorithm !== 'agile-robust') return;
    seasonality = anomalyFunc.params[1].value;
  });

  if (!seasonality) return null;
  const seasonalityText = seasonalityNumToText[seasonality];
  return (
    <div className="flex items-center italic text-indigo-300">
      <InfoIconCircle className="mr-1" />
      Agile Robust anomaly requires at least 2 times of seasonality&nbsp;
      <span className="font-bold">({seasonalityText})</span>&nbsp;for time range
    </div>
  );
};
