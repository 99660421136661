import { Button, InputWithValidatorV3, TooltipTrigger } from 'components';
import React, { Dispatch, SetStateAction, ReactElement } from 'react';
import { MdDelete as DeleteIcon } from 'react-icons/md';
import { Plus } from 'react-feather';

import { AlertMuteTimingProps } from './types';

const AlertsMuteTimingEditTimeRange = ({
  clearErrorByIndex,
  deleteTimeInterval,
  errorByIndex,
  intervalIdx,
  muteInterval,
  setMuteTiming,
}: {
  clearErrorByIndex: (idx: number, key: string) => void;
  deleteTimeInterval: (idx: number) => void;
  errorByIndex: { [key: string]: string };
  intervalIdx: number;
  muteInterval: AlertMuteTimingProps['time_intervals'][0];
  setMuteTiming: Dispatch<SetStateAction<AlertMuteTimingProps>>;
}): ReactElement => {
  const handleDeleteTimeRange = (timeIdx: number) => {
    setMuteTiming((prev) => {
      const newState = { ...prev };
      const newIntervals = { ...newState.time_intervals[intervalIdx] };
      const newTimes = newIntervals.times.filter((_, idx) => idx !== timeIdx);
      newIntervals.times = newTimes;
      newState.time_intervals[intervalIdx] = newIntervals;
      return newState;
    });
  };

  const handleAddTimeRange = () => {
    setMuteTiming((prev) => {
      const newState = { ...prev };
      const newIntervals = { ...newState.time_intervals[intervalIdx] };
      newIntervals.times.push({ start_time: '', end_time: '' });
      newState.time_intervals[intervalIdx] = newIntervals;
      return newState;
    });
  };

  const handleTimeChange = (
    timeIdx: number,
    timeType: 'start_time' | 'end_time',
    val: string,
  ) => {
    setMuteTiming((prev) => {
      const newState = { ...prev };
      const newIntervals = { ...newState.time_intervals[intervalIdx] };
      newIntervals.times[timeIdx][timeType] = val;
      newState.time_intervals[intervalIdx] = newIntervals;
      return newState;
    });
    const errorKey = `times.${timeIdx}.${timeType}`;
    clearErrorByIndex(intervalIdx, errorKey);
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <div>
          <div className="text-sm font-semibold">Time Range</div>
          <div className="pb-1 text-xs text-text-secondary">
            The time inclusive of the start and exclusive of the end time (in
            UTC if no location has been selected, otherwise local time)
          </div>
        </div>
        <div>
          <Button
            variant="ghost-destructive"
            size="sm"
            className="border border-red-600 text-red-600 hover:bg-red-600 hover:text-white"
            onClick={() => deleteTimeInterval(intervalIdx)}
          >
            Delete Time interval
          </Button>
        </div>
      </div>
      {muteInterval.times.map((time, idx) => {
        const errorTextStart = errorByIndex?.[`times.${idx}.start_time`];
        const errorTextEnd = errorByIndex?.[`times.${idx}.end_time`];
        return (
          <div key={idx} className="flex flex-row items-center gap-x-2 pt-1">
            <label className="text-xs font-semibold text-text-secondary">
              Start Time
            </label>
            <InputWithValidatorV3
              className="w-20 rounded-sm"
              placeholder="HH:mm"
              size="4"
              type="text"
              variant="default"
              value={time.start_time}
              onChange={(val) => handleTimeChange(idx, 'start_time', val)}
              error={errorTextStart}
              errorMessageShownType="tooltip"
            />
            <label className="text-xs font-semibold text-text-secondary">
              End Time
            </label>
            <InputWithValidatorV3
              className="w-20 rounded-sm"
              placeholder="HH:mm"
              size="4"
              type="text"
              variant="default"
              value={time.end_time}
              onChange={(val) => handleTimeChange(idx, 'end_time', val)}
              error={errorTextEnd}
              errorMessageShownType="tooltip"
            />
            <Button
              variant="ghost-destructive"
              className="border px-2 text-red-600 hover:text-white"
              onClick={() => handleDeleteTimeRange(idx)}
            >
              <TooltipTrigger tooltip="Delete">
                <DeleteIcon size={16} />
              </TooltipTrigger>
            </Button>
          </div>
        );
      })}
      <Button
        variant="default"
        size="xs"
        className="mt-2"
        onClick={() => handleAddTimeRange()}
      >
        <Plus size={14} className="mr-1" /> Add Time Range
      </Button>
    </div>
  );
};

export default AlertsMuteTimingEditTimeRange;
