import { useRumPageStateContext } from 'context';
import React from 'react';
import RumPerformance from './RumPerformance';
import { RumTab } from './types';
import { useParams } from 'react-router-dom';

const RumPerformanceContainer = () => {
  const { tab } = useParams();
  const rumPageState = useRumPageStateContext();

  return (
    <RumPerformance
      rumPageState={rumPageState}
      rumTab={(tab as RumTab) || RumTab.performanceMonitoring}
    />
  );
};

export default RumPerformanceContainer;
