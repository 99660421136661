import uPlot, { AlignedData } from 'uplot';
import { getActivePointPosition } from 'utils/Timeseries';

import calculateIntersectionOfTwoLine from './calculateIntersectionOfTwoLine';

type Point = { x: number; y: number };

const getPositionByIndex = ({
  u,
  upperBandIndex,
  lowerBandIndex,
  seriesIndex,
  i,
}: {
  u: uPlot;
  upperBandIndex: number;
  lowerBandIndex: number;
  seriesIndex: number;
  i: number;
}) => {
  if (i < 0) return null;
  const data = u.data[0];
  if (i > data.length - 1) return null;
  const upperBandPosition = getActivePointPosition(u, upperBandIndex, i);
  const lowerBandPosition = getActivePointPosition(u, lowerBandIndex, i);
  const seriesPosition = getActivePointPosition(u, seriesIndex, i);
  return {
    upper: upperBandPosition,
    lower: lowerBandPosition,
    inner: seriesPosition,
  };
};

const getAnomalyOutofBoundPoints = ({
  anomalyBandIndexes,
  data,
  seriesIndex,
  u,
}: {
  anomalyBandIndexes: { lowerBandIndex: number; upperBandIndex: number };
  data: AlignedData;
  seriesIndex: number;
  u: uPlot;
}): Point[][] => {
  const { upperBandIndex, lowerBandIndex } = anomalyBandIndexes;
  const upperBandData = data[upperBandIndex];
  const lowerBandData = data[lowerBandIndex];
  const innerBandData = data[seriesIndex];

  const outofBoundPoints = Array(innerBandData.length).fill(undefined);
  const outofBoundPositions = Array(innerBandData.length).fill({
    upper: undefined,
    lower: undefined,
    inner: undefined,
  });
  let streakStart: number | null = null;
  let streakStartType: 'upper' | 'lower' | null = null;
  const intersectionPoints: Point[][] = [];

  const updateOutofBoundData = (index: number, type: 'upper' | 'lower') => {
    outofBoundPoints[index] = 1;
    if (streakStart === null) {
      streakStart = index;
      streakStartType = type;
    }
    const position = getPositionByIndex({
      u,
      upperBandIndex,
      lowerBandIndex,
      seriesIndex,
      i: index,
    });
    if (position) {
      outofBoundPositions[index] = position;
    }

    // if there is direct upper to lower or lower to upper, then end the streak and start a new one
    if (streakStartType !== type) {
      handleStreakEnd(index);
      streakStart = index;
      streakStartType = type;
    }
  };

  const getIntersectionStartPosition = (
    streakStartIndex: number,
    posType: 'upper' | 'lower',
  ) => {
    const payload = { u, upperBandIndex, lowerBandIndex, seriesIndex };
    const posPrev = getPositionByIndex({ ...payload, i: streakStartIndex - 1 });
    if (!posPrev) return null;
    outofBoundPositions[streakStartIndex - 1] = posPrev;

    const intersectionStart = calculateIntersectionOfTwoLine({
      p1: {
        start: outofBoundPositions[streakStartIndex - 1][posType],
        end: outofBoundPositions[streakStartIndex][posType],
      },
      p2: {
        start: outofBoundPositions[streakStartIndex - 1]['inner'],
        end: outofBoundPositions[streakStartIndex]['inner'],
      },
    });

    return intersectionStart;
  };

  const getIntersectionEndPosition = (
    index: number,
    posType: 'upper' | 'lower',
  ) => {
    const payload = { u, upperBandIndex, lowerBandIndex, seriesIndex };
    const nextPos = getPositionByIndex({ ...payload, i: index });
    if (!nextPos) return null;
    outofBoundPositions[index] = nextPos;
    const intersectionEnd = calculateIntersectionOfTwoLine({
      p1: {
        start: outofBoundPositions[index][posType],
        end: outofBoundPositions[index - 1][posType],
      },
      p2: {
        start: outofBoundPositions[index]['inner'],
        end: outofBoundPositions[index - 1]['inner'],
      },
    });
    return intersectionEnd;
  };

  const handleStreakEnd = (index: number, isLast?: boolean) => {
    const newIntersectionPoints = [];
    const intersectionStart = getIntersectionStartPosition(
      streakStart,
      streakStartType,
    );

    if (intersectionStart) {
      newIntersectionPoints.push(intersectionStart);
    }
    for (let i = streakStart; i < index; i++) {
      newIntersectionPoints.push(outofBoundPositions[i]?.inner);
    }

    // if the streak is not the last one, then add the last point
    if (isLast) {
      newIntersectionPoints.push(outofBoundPositions[index]?.inner);
    }

    const intersectionEnd = getIntersectionEndPosition(index, streakStartType);
    if (intersectionEnd) {
      newIntersectionPoints.push(intersectionEnd);
    }

    intersectionPoints.push(newIntersectionPoints);
    streakStart = null;
    streakStartType = null;
  };

  for (let i = 0; i < innerBandData.length; i++) {
    const innerDataNum = Number(innerBandData[i]);
    if (isNaN(innerDataNum)) continue;

    const upperDataNum = Number(upperBandData[i]);
    const lowerDataNum = Number(lowerBandData[i]);

    // Upper band data less than inner band data
    if (upperDataNum < innerDataNum) {
      updateOutofBoundData(i, 'upper');
    }

    //Lower band data greater than inner band data
    if (lowerDataNum > innerDataNum) {
      updateOutofBoundData(i, 'lower');
    }

    if (outofBoundPoints[i] === undefined && streakStart !== null) {
      handleStreakEnd(i);
    }

    // If the last point is out of bound
    if (i === innerBandData.length - 1 && streakStart !== null) {
      handleStreakEnd(i, true);
    }
  }

  return intersectionPoints;
};

export default getAnomalyOutofBoundPoints;
