import {
  QueryCombinedStatusProps,
  QueryDataProps,
  QueryDataPropsRangeCombined,
  TimeseriesRenderProps,
} from 'types';
import { Band, Series } from 'uplot';
import {
  INACTIVE_COLOR_DARK,
  INACTIVE_COLOR_LIGHT,
  getColorForOneSeries,
} from 'utils/colors';

import combineAnomalyQueryData from './combineAnomalyQueryData';
import combineForecastQueryData from './combineForecastQueryData';
import getCombinedQueryDataTimestamps from './getCombinedQueryDataTimestamps';

const getSeriesColor = (
  idx: number,
  queryIndex: number,
  stroke: string,
  darkModeEnabled: boolean,
) => {
  if (stroke === INACTIVE_COLOR_LIGHT && darkModeEnabled) {
    return INACTIVE_COLOR_DARK;
  }

  if (stroke === INACTIVE_COLOR_LIGHT && !darkModeEnabled) {
    return INACTIVE_COLOR_LIGHT;
  }

  return getColorForOneSeries({}, idx + queryIndex);
};

const getCombineLabel = ({
  queryKey,
  label,
  combineLabelWithQueryKey,
  isStrokeModifiable,
  type,
}: {
  queryKey: string;
  label: string;
  combineLabelWithQueryKey: boolean;
  isStrokeModifiable: boolean;
  type: string;
}) => {
  if (combineLabelWithQueryKey && isStrokeModifiable) {
    if (type === 'query') {
      return `${queryKey}__${label}`;
    }

    if (label.startsWith('formula_of_')) {
      return label;
    }
    return `formula_${queryKey}__${label}`;
  }
  return label;
};

const combineRangeQueryData = ({
  formulas = [],
  queries,
  queryData,
  darkModeEnabled,
  combineTimestamp,
  combineLabelWithQueryKey = false,
}: {
  formulas: QueryCombinedStatusProps[];
  queries: QueryCombinedStatusProps[];
  queryData: QueryDataProps;
  darkModeEnabled?: boolean;
  combineTimestamp?: boolean;
  combineLabelWithQueryKey?: boolean;
}): QueryDataPropsRangeCombined => {
  const chartKeys = Object.keys(queryData);
  const newData: Array<number[]> = [];
  let newMaxValue = 0;
  let newMinValue = Infinity;
  const newSeries: Series[] = [];
  let isLoading = false;
  const bands: Band[] = [];
  const hooks: TimeseriesRenderProps['hooks'] = [];

  const { timestamps, timestampsWithIndex } = getCombinedQueryDataTimestamps({
    combineTimestamp,
    formulas,
    queryData,
    queries,
  });
  newData.push(timestamps);

  const anomalyData = combineAnomalyQueryData({
    darkModeEnabled,
    formulas,
    queries,
    queryData,
    timestamps,
    timestampsWithIndex,
  });
  if (anomalyData) {
    newMaxValue = Math.max(newMaxValue, anomalyData.maxValue);
    newMinValue = Math.min(newMinValue, anomalyData.minValue);
    newData.push(...anomalyData.data);
    newSeries.push(...anomalyData.series);
    bands.push(...anomalyData.bands);
    hooks.push(...anomalyData.hooks);
    isLoading = anomalyData.isLoading;
  }

  const forecastData = combineForecastQueryData({
    darkModeEnabled,
    formulas,
    queries,
    queryData,
    timestamps,
    timestampsWithIndex,
  });
  if (forecastData) {
    newMaxValue = Math.max(newMaxValue, forecastData.maxValue);
    newMinValue = Math.min(newMinValue, forecastData.minValue);
    newData.push(...forecastData.data);
    newSeries.push(...forecastData.series);
    bands.push(...forecastData.bands);
    isLoading = forecastData.isLoading;
    hooks.push(...forecastData.hooks);
  }

  const activeQueries = queries.filter((query) => query.isActive);
  const activeFormulas = formulas.filter((formula) => formula.isActive);
  const isStrokeModifiable = activeQueries.length + activeFormulas.length > 1;

  chartKeys.forEach((key, queryIndex) => {
    if (key.includes('anomaly') || key.includes('forecast')) return;
    if (queryData[key].isLoading) {
      isLoading = true;
    }

    const [type, queryKey] = key.split('_');
    let query = null;
    if (type === 'query') {
      query = queries.find((q) => q.queryKey === queryKey);
    }
    if (type === 'formula') {
      query = formulas.find((q) => q.queryKey === queryKey);
    }
    if (!query || !query.isActive || !queryData[key].range?.data) return;

    const { data, maxValue, minValue, series } = queryData[key].range;
    if (!data || !series || series.length === 0) return;
    if (data.length === 0) return;

    if (combineTimestamp) {
      const currSeriesTimestamps = data[0];
      data.forEach((d: number[], index: number) => {
        if (index === 0) return; // skip first index as it is timestamp
        const preFillData = Array(timestamps.length).fill(undefined);
        d.forEach((value, valueIndex) => {
          const timestamp = currSeriesTimestamps[valueIndex];
          const timestampIndex = timestampsWithIndex[timestamp];
          preFillData[timestampIndex] = value;
        });
        newData.push(preFillData);
      });
    } else {
      data.forEach((d: number[], index: number) => {
        if (index === 0) return; // skip first index as it is timestamp
        newData.push(d);
      });
    }

    newMaxValue = maxValue > newMaxValue ? maxValue : newMaxValue;
    newMinValue = minValue < newMinValue ? minValue : newMinValue;
    series.forEach((s: Series, idx: number) => {
      const stroke = s.stroke;
      newSeries.push({
        ...s,
        label: getCombineLabel({
          queryKey,
          label: s.label,
          combineLabelWithQueryKey,
          isStrokeModifiable,
          type,
        }),
        stroke: isStrokeModifiable
          ? getSeriesColor(idx, queryIndex, stroke, darkModeEnabled)
          : stroke,
      });
    });
  });

  return {
    data: newData,
    isLoading,
    maxValue: newMaxValue === -Infinity ? 0 : newMaxValue,
    minValue: newMinValue === Infinity ? 0 : newMinValue,
    series: newSeries,
    bands,
    hooks,
  };
};

export default combineRangeQueryData;
