import { AutocompleteV2, useToaster } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { RequestResult } from 'types/Request';

import { addUserToGroup } from './requests';
import { GroupMemberProps } from './types';
import { PermissionOptionsGroup } from './utils';

const GroupManagementMembersPermissions = ({
  member,
  groupMembersRequest,
}: {
  member: GroupMemberProps;
  groupMembersRequest: RequestResult<GroupMemberProps[], any>;
}): ReactElement => {
  const { addToast } = useToaster();
  const { groupId } = useParams();
  const updateGroupMemberPermissionRequest = useRequest(addUserToGroup);
  const onPermissionChange = (val: string) => {
    updateGroupMemberPermissionRequest
      .call({
        groupId,
        userId: member.id.toString(),
        permission: val,
        method: 'PUT',
      })
      .then((res) => {
        if (res) {
          addToast({ text: 'Permission updated', status: 'success' });
          groupMembersRequest.call({ groupId });
        }
      });
  };

  return (
    <div className="flex items-center">
      <AutocompleteV2
        className="max-h-[32x] w-full"
        options={PermissionOptionsGroup}
        value={member.permission}
        onChange={onPermissionChange}
      />
    </div>
  );
};

export default GroupManagementMembersPermissions;
