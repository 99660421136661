import { useMemo } from 'react';

type Props = {
  data: any;
};

const useTracesOverlayMessage = ({ data }: Props) => {
  const overlayMessage = useMemo(() => {
    const dataIsEmpty = !data?.result;
    if (dataIsEmpty) {
      const errorMessage =
        'Failed to fetch traces attributes cardinality data for the table. ';
      return errorMessage;
    }
    return null;
  }, [data]);
  return overlayMessage;
};
export default useTracesOverlayMessage;
