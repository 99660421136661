export const GRID_CELL_VMARGIN = 12;
export const GRID_COLUMN_COUNT = 24;
export const GRID_HEADER_HEIGHT = 30;
export const PANEL_HEADER_HEIGHT = 28;
export const GRID_CELL_HEIGHT = 30;
export const GRID_CELL_HEIGHT_MD = 26;
export const GRID_CELL_HEIGHT_SM = 22;
export const GRID_CELL_HEIGHT_XS = 18;
export const GRID_CELL_HEIGHT_XXS = 14;

export const GRID_CELL_HEIGHT_BREAKPOINTS: {
  [key: string]: number;
} = {
  xxs: GRID_CELL_HEIGHT_XXS,
  xs: GRID_CELL_HEIGHT_XS,
  sm: GRID_CELL_HEIGHT_SM,
  md: GRID_CELL_HEIGHT_MD,
  lg: GRID_CELL_HEIGHT,
};

export const MAX_DYNAMIC_REPEATED_ROWS = 40;
