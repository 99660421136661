import React from 'react';
import { colorByRumEventType } from './constants';
import { RumEvent, Session, ShownRumEventTypes } from './types';

type Props = {
  events: RumEvent[];
  shownRumEventTypes: ShownRumEventTypes;
  session: Session;
};

const RumReplayBarHashes = ({ events, shownRumEventTypes, session }: Props) => {
  const { startTimeUnixMs, endTimeUnixMs } = session;
  const totalMs = endTimeUnixMs - startTimeUnixMs;
  return (
    <div className="rum-replay__bar__hashes">
      {events.map((event) => {
        const eventMs = event.time - startTimeUnixMs;
        return (
          <div
            className="rum-replay__bar__hashes__item"
            key={event.data['action.id']}
            style={{
              borderColor: colorByRumEventType[event.eventType],
              left: `${(eventMs / totalMs) * 100}%`,
            }}
          />
        );
      })}
    </div>
  );
};

export default RumReplayBarHashes;
