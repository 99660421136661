import dayjs from 'dayjs';
import { FacetValue, LogsState, TimeSeries } from 'types';
import { formatSeriesToLogCounts } from './utils';

import query from './query';
import { buildQuery } from './utils';

type Args = {
  bucketSecs: number;
  logsState: LogsState;
};

const getLogStackedBarCountsUsingMetrics = async ({
  bucketSecs,
  logsState,
}: Args): Promise<FacetValue[]> => {
  const { date } = logsState;
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const logQuery = buildQuery(logsState);

  const stepMs = bucketSecs * 1000;

  const logLevelLogCounts = await query<
    TimeSeries[],
    'getLogMetricsTimeSeries'
  >(`
    {
      getLogMetricsTimeSeries(
        durationMs: ${durationSecs * 1000}
        lookBackMs: ${stepMs}
        stepMs: ${stepMs}
        ${logQuery !== '{}' ? `logQuery: ${logQuery},` : ''}
        rangeAggregate: "count_over_time"
        vectorAggregate: "sum"
        vectorAggregateGrouping: {
          groups: ["level"]
        }
        timestamp: "${endTime.format()}",
      ) {
        points {
          ts
          value
        }
        tags
      }
    }
  `)
    .then((data) => data.getLogMetricsTimeSeries || [])
    .then(formatSeriesToLogCounts);

  return logLevelLogCounts;
};

export default getLogStackedBarCountsUsingMetrics;
