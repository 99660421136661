import { delimiter } from 'kfuse-constants';
import { FavoriteFacet } from 'types';

export const getFacetKey = (facet: FavoriteFacet): string => {
  const { component, name, type, displayName, group } = facet;
  const displayNameWithGroup = group
    ? `${group}:${displayName || ''}`
    : displayName || '';
  return `${component}${delimiter}${name}${delimiter}${type}${delimiter}${displayNameWithGroup}`;
};

export const parseFacetKey = (facetKey: string): FavoriteFacet => {
  const [component, name, type, displayName] = facetKey.split(delimiter);
  return {
    component,
    name,
    type,
    dataType: type,
    displayName,
    source: component,
  };
};
