export const buildKubeGroupByFilter = (groupBy: any): string => {
  {
    let result = '[';

    if (groupBy) {
      const values = Object.values(groupBy);
      Object.keys(groupBy).forEach((facet) => {
        const groupData = groupBy[facet];
        result += `{key: "${groupData.key}", type: TAG}`;
      });
    }

    result += ']';

    return result;
  }
};

export default buildKubeGroupByFilter;
