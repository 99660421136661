import { formatFileSize } from 'utils';
import {
  RumEvent,
  RumActionEventData,
  RumErrorEventData,
  RumEventType,
  RumLongTaskEventData,
  RumResourceEventData,
} from '../types';
import { convertMsToNs } from './utils';
import { FormattedRumEventForFlameGraph } from './types';

export const getEventDuration = (event: RumEvent) => {
  switch (event.eventType) {
    case RumEventType.RESOURCE:
      return (
        parseFloat((event.data as RumResourceEventData)['resource.duration']) ||
        0
      );
    case RumEventType.ACTION:
      return (
        parseFloat((event.data as RumActionEventData)['action.loading_time']) ||
        0
      );
    case RumEventType.LONGTASK:
      return (
        parseFloat(
          (event.data as RumLongTaskEventData)['long_task.duration'],
        ) || 0
      );
    case RumEventType.ERROR:
      return 0;
    default:
      return 0;
  }
};

const eventTypeDependentFields = (event: RumEvent) => {
  const duration = getEventDuration(event);
  switch (event.eventType) {
    case RumEventType.RESOURCE:
      return {
        id: (event.data as RumResourceEventData)['resource.id'],
        size: formatFileSize(
          (event.data as RumResourceEventData)['resource.size'],
        ),
        duration,
        name:
          (event.data as RumResourceEventData)['resource.url'] ||
          'Missing Resource',
      };
    case RumEventType.ACTION:
      const actionData = event.data as RumActionEventData;
      const target = actionData['action.target.name'] || 'Missing Target';
      const actionType = actionData['action.type'] || 'Missing Type';
      return {
        id: (event.data as RumActionEventData)['action.id'],
        size: '',
        duration,
        name: `${actionType} on ${target}`,
      };
    case RumEventType.LONGTASK:
      return {
        id: (event.data as RumLongTaskEventData)['long_task.id'],
        size: '',
        duration,
        name: 'Long Task',
      };
    case RumEventType.ERROR:
      return {
        id: (event.data as RumErrorEventData)['error.id'],
        size: '',
        duration,
        name: (event.data as RumErrorEventData)['error.message'] || 'Error',
      };
    default:
      return {
        id: null,
        size: '',
        duration: 0,
        name: '',
      };
  }
};
const getFormattedEvent = (event: RumEvent): FormattedRumEventForFlameGraph => {
  const { id: eventId, size, duration, name } = eventTypeDependentFields(event);

  const startTimeNs = convertMsToNs(event.time);
  const endTimeNs = startTimeNs + duration;

  return {
    duration,
    eventId,
    startTimeNs,
    endTimeNs,
    name,
    size,
    eventType: event.eventType,
    originalEvent: event,
  };
};

const formatAndGroupEvents = (events: RumEvent[]) => {
  const eventById: { [key: string]: FormattedRumEventForFlameGraph } = {};
  const eventIdsByEventType: { [key in RumEventType]?: string[] } = {};
  const formattedEvents: FormattedRumEventForFlameGraph[] = [];
  const flameGraphRows: string[][] = [];

  events.forEach((event) => {
    const formattedEvent = getFormattedEvent(event);
    formattedEvents.push(formattedEvent);
    eventById[formattedEvent.eventId] = formattedEvent;

    const { eventType, eventId } = formattedEvent;

    if (!eventIdsByEventType[eventType]) {
      eventIdsByEventType[eventType] = [];
    }
    flameGraphRows.push([eventId]);
    eventIdsByEventType[eventType].push(eventId);
  });

  return {
    formattedEvents,
    eventById,
    eventIdsByEventType,
    flameGraphRows,
  };
};

export default formatAndGroupEvents;
