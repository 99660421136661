import React, { useMemo } from 'react';
import RumReplayBarHashes from './RumReplayBarHashes';
import { useRumReplayStateContext } from './RumReplayStateContext';
import { Session, SessionMetadata } from './types';
import { getSessionInactivePeriods, getBarLeftAndWidth } from './utils';

type Args = {
  session: SessionMetadata;
  startReplayAtUnixMs?: number;
};

const RumReplayControlsBar = ({ session }: Args) => {
  const { startTimeUnixMs, endTimeUnixMs } = session;
  const totalMs = endTimeUnixMs - startTimeUnixMs;

  const {
    actions,
    currentTimeState,
    hoverPercentState,
    segmentFetcher,
    shownEvents,
    shownRumEventTypesState,
  } = useRumReplayStateContext();

  const { onBarClick, onBarHover } = actions;
  const [currentTime] = currentTimeState;
  const [hoverPercent, setHoverPercent] = hoverPercentState;
  const [shownRumEventTypes] = shownRumEventTypesState;

  const { eventsBySegmentKeyByTabId } = segmentFetcher;
  const inactivePeriods = useMemo(
    () => getSessionInactivePeriods({ eventsBySegmentKeyByTabId, session }),
    [eventsBySegmentKeyByTabId, session],
  );

  return (
    <div
      className="rum-replay__bar__container"
      onClick={onBarClick}
      onMouseMove={onBarHover}
      onMouseEnter={onBarHover}
      onMouseLeave={() => {
        setHoverPercent(0);
      }}
    >
      <div className="rum-replay__bar">
        <div className="rum-replay__bar__inactivity-periods">
          {inactivePeriods.map((inactivePeriod, i) => (
            <div
              className="rum-replay__bar__inactivity-periods__item"
              key={i}
              style={getBarLeftAndWidth({
                ...inactivePeriod,
                session,
              })}
            />
          ))}
        </div>
        <div
          className="rum-replay__bar__fill"
          style={{ width: `${(currentTime / totalMs) * 100}%` }}
        >
          <div className="rum-replay__bar__marker" />
        </div>
        {hoverPercent ? (
          <div
            className="rum-replay__bar__hover"
            style={{ width: `${hoverPercent * 100}%` }}
          >
            <div className="rum-replay__bar__marker" />
          </div>
        ) : null}
        <RumReplayBarHashes
          events={shownEvents}
          shownRumEventTypes={shownRumEventTypes}
          session={session}
        />
      </div>
    </div>
  );
};

export default RumReplayControlsBar;
