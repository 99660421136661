import classnames from 'classnames';
import { TooltipTrigger } from 'components';
import React, { ReactNode } from 'react';

type Props = {
  className?: string;
  icon: ReactNode;
  label: ReactNode;
  tooltip?: string;
};

const IconWithLabel = ({ className, icon, label, tooltip }: Props) => {
  const iconDiv = (
    <div className="icon-with-label__icon">{icon ? icon : null}</div>
  );
  return (
    <div
      className={classnames({
        'icon-with-label': true,
        'icon-with-label--missing-icon': !icon,
        [className]: className,
      })}
    >
      <TooltipTrigger tooltip={tooltip}>{iconDiv}</TooltipTrigger>

      {label ? <div className="icon-with-label__label">{label}</div> : null}
    </div>
  );
};

export default IconWithLabel;
