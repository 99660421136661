import { Badge, TooltipTrigger } from 'components';
import { Separator } from 'components/shadcn';
import dayjs from 'dayjs';
import { dateTimeFormat } from 'kfuse-constants/dateTimeFormat';
import React, { ReactElement } from 'react';
import { getOperatorSign } from 'utils';

import { ConditionProps } from '../AlertsCreateCondition';
import AlertsDetailsAPMParameters from './AlertsDetailsAPMParameters';
import AlertsDetailsChangeParameters from './AlertsDetailsChangeParameters';
import AlertsDetailsOutliersParameters from './AlertsDetailsOutliersParameters';
import AlertsDetailsAnomalyParameters from './AlertsDetailsAnomalyParameters';
import AlertsDetailsForecastParameters from './AlertsDetailsForecastParameters';
import { AlertsMetricsParsedProps, RuleProps, RuleType } from '../types';
import { alertTypeSearch, ruleAlertType } from '../utils';

const AlertsDetailsPropertiesInfo = ({
  condition,
  parsedMetadata,
  rule,
  hideAlertType = false,
}: {
  condition: ConditionProps;
  parsedMetadata: AlertsMetricsParsedProps;
  rule: RuleProps;
  hideAlertType?: boolean;
}): ReactElement => {
  const annotations = rule.annotations || {};
  const updatedDate = dayjs(rule.updated);
  const contactPoints = rule.contactpoints || [];

  return (
    <div className="min-w-[24vw] px-2">
      {!hideAlertType && (
        <>
          <div className="text-base font-bold text-text-secondary">
            <span data-testid="alert-detail-alert-type">
              {alertTypeSearch[annotations.alertType || 'threshold']}
            </span>
          </div>
          <div className="font-semibold text-text-secondary">
            TYPE:{' '}
            <span data-testid="alert-detail-rule-type">
              {ruleAlertType[annotations.ruleType]}
            </span>
          </div>
          <div className="font-semibold text-text-secondary">
            UPDATED AT:{' '}
            <TooltipTrigger tooltip={updatedDate.format(dateTimeFormat)}>
              {updatedDate.fromNow()}
            </TooltipTrigger>
          </div>
        </>
      )}
      <Separator orientation="horizontal" className="mt-2" />
      {annotations.ruleType === RuleType.APM && (
        <AlertsDetailsAPMParameters annotations={rule.annotations} />
      )}
      {annotations.alertType &&
        ['change', 'outliers', 'anomaly', 'forecast'].includes(
          annotations.alertType,
        ) && (
          <>
            {annotations.alertType === 'change' && (
              <AlertsDetailsChangeParameters parsed={parsedMetadata} />
            )}
            {annotations.alertType == 'outliers' && (
              <AlertsDetailsOutliersParameters parsed={parsedMetadata} />
            )}
            {annotations.alertType == 'anomaly' && (
              <AlertsDetailsAnomalyParameters parsed={parsedMetadata} />
            )}
            {annotations.alertType == 'forecast' && (
              <AlertsDetailsForecastParameters
                parsed={parsedMetadata}
                ruleType={annotations.ruleType}
              />
            )}
          </>
        )}
      {!['outliers'].includes(annotations?.alertType) && (
        <div className="alerts__details__properties__summary__item">
          <span className="text-sm font-bold text-text-secondary">
            Condition:{' '}
          </span>
          <span data-testid="alert-detail-condition">
            {getOperatorSign(condition.of)} {condition.value}
          </span>
        </div>
      )}
      {contactPoints.length > 0 && (
        <>
          <Separator orientation="horizontal" />
          <div className="alerts__details__properties__summary__item">
            <span className="text-sm font-bold text-text-secondary">
              Contact Points:{' '}
            </span>
            {contactPoints.map((label) => (
              <Badge
                className="mb-1 mr-1 rounded-sm px-2 text-xs font-medium text-text-secondary"
                key={label}
                variant="outline"
              >
                {label}
              </Badge>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default AlertsDetailsPropertiesInfo;
