import { AutocompleteOption, useToaster } from 'components';
import { useRequest } from 'hooks';
import { useEffect, useState } from 'react';
import { getGrafanaAlertsNotifiers, testGrafanaContactPoint } from 'requests';

import {
  buildOptionsDependencyRelation,
  getContactPointSettings,
  getNotifiersTypeOptions,
} from '../utils';
import {
  ContactNotifierType,
  ReceiverConfigOptionsProps,
  ReceiverProps,
} from '../types';

const useAlertsContactsState = () => {
  const { addToast } = useToaster();

  const grafanaAlertsNotifiesRequest = useRequest(
    getGrafanaAlertsNotifiers,
    true,
    true,
  );

  const testContactPointRequest = useRequest(testGrafanaContactPoint);

  const [editMode, setEditMode] = useState(false);
  const [contactPointName, setContactPointName] = useState('');
  const [notifiersTypes, setNotifiersTypes] = useState<AutocompleteOption[]>(
    [],
  );
  const [dependencyRelation, setDependencyRelation] = useState<
    Record<string, string[]>
  >({});
  const [secureFieldsArray, setSecureFieldsArray] = useState<
    Array<Record<string, boolean>>
  >([]);
  const [selectedNotifierTypes, setSelectedNotifierTypes] = useState<string[]>([
    'email',
  ]);
  const [selectedNotifierData, setSelectedNotifierData] = useState<
    Array<{ [key: string]: any }>
  >([{}]);
  const [receiversOptions, setReceiversOptions] = useState<
    ReceiverConfigOptionsProps[]
  >([{ disableResolveMessage: false, uid: '' }]);

  useEffect(() => {
    grafanaAlertsNotifiesRequest.call().then((result: any) => {
      setNotifiersTypes(getNotifiersTypeOptions(result));
      setDependencyRelation(buildOptionsDependencyRelation(result));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNotifierData = (notifierType: string) => {
    if (grafanaAlertsNotifiesRequest.result) {
      return grafanaAlertsNotifiesRequest.result.find(
        (notifier: ContactNotifierType) => notifier.type === notifierType,
      );
    }
    return null;
  };

  const updateSelectedNotifierTypes = (
    notifierIndex: number,
    notifierType: string,
  ) => {
    const newSelectedNotifierTypes = [...selectedNotifierTypes];
    newSelectedNotifierTypes[notifierIndex] = notifierType;

    const newSelectedNotifierData = [...selectedNotifierData];
    newSelectedNotifierData[notifierIndex] = {};

    setSelectedNotifierData(newSelectedNotifierData);
    setSelectedNotifierTypes(newSelectedNotifierTypes);
  };

  const addNotifierType = () => {
    const newSelectedNotifierTypes = [...selectedNotifierTypes, 'email'];
    const newSelectedNotifierData = [...selectedNotifierData, {}];

    setSelectedNotifierData(newSelectedNotifierData);
    setSelectedNotifierTypes(newSelectedNotifierTypes);
    setReceiversOptions([
      ...receiversOptions,
      { disableResolveMessage: false, uid: '' },
    ]);
  };

  const updateSelectedNotifierData = (
    notifierIndex: number,
    propertyKey: string,
    propertyValue: any,
  ) => {
    const newSelectedNotifierData = [...selectedNotifierData];
    if (!newSelectedNotifierData[notifierIndex]) {
      newSelectedNotifierData[notifierIndex] = {};
    }
    newSelectedNotifierData[notifierIndex] = {
      ...newSelectedNotifierData[notifierIndex],
      [propertyKey]: propertyValue,
    };
    setSelectedNotifierData(newSelectedNotifierData);
  };

  const removeSelectedNotifier = (notifierIndex: number) => {
    const newSelectedNotifierTypes = [...selectedNotifierTypes];
    newSelectedNotifierTypes.splice(notifierIndex, 1);

    const newSelectedNotifierData = [...selectedNotifierData];
    newSelectedNotifierData.splice(notifierIndex, 1);

    setSelectedNotifierData(newSelectedNotifierData);
    setSelectedNotifierTypes(newSelectedNotifierTypes);
  };

  const setUpdatedContactPointState = ({
    receiver,
  }: {
    receiver: ReceiverProps;
  }) => {
    if (!receiver || !receiver.grafana_managed_receiver_configs) return;

    const newSelectedNotifierTypes: string[] = [];
    const newSelectedNotifierData: Record<string, any>[] = [];
    const secureFieldsArray: Array<Record<string, boolean>> = [];
    const receiverOptions: ReceiverConfigOptionsProps[] = [];

    receiver.grafana_managed_receiver_configs.map((point) => {
      newSelectedNotifierTypes.push(point.type);
      newSelectedNotifierData.push({ ...point.settings });
      secureFieldsArray.push({ ...point.secureFields });
      receiverOptions.push({
        disableResolveMessage: point.disableResolveMessage,
        uid: point.uid,
      });
    });

    setEditMode(true);
    setContactPointName(receiver.name);
    setSelectedNotifierTypes(newSelectedNotifierTypes);
    setSelectedNotifierData([...newSelectedNotifierData]);
    setSecureFieldsArray(secureFieldsArray);
    setReceiversOptions(receiverOptions);
  };

  const getContactPointsCreatePayload = (): ReceiverProps => {
    const contactPoints: any = [];
    selectedNotifierTypes.forEach((notifierType, index) => {
      const notifierData = getNotifierData(notifierType);
      const selectedData = selectedNotifierData[index];
      const { settings, secureSettings } = getContactPointSettings(
        notifierData.options,
        selectedData,
      );
      const receiverOptions = receiversOptions[index];

      contactPoints.push({
        settings,
        secureSettings,
        type: notifierType,
        name: contactPointName,
        disableResolveMessage: receiverOptions.disableResolveMessage,
      });
    });

    const prepData = {
      name: contactPointName,
      grafana_managed_receiver_configs: contactPoints,
    };
    return prepData;
  };

  const testContactPoint = (notifierIndex: number) => {
    const createPayload = getContactPointsCreatePayload();

    const payload = {
      receivers: [
        {
          grafana_managed_receiver_configs: [
            createPayload.grafana_managed_receiver_configs[notifierIndex],
          ],
          name: 'test',
        },
      ],
    };

    testContactPointRequest
      .call(payload)
      .then((result: { receivers: ReceiverProps[]; notified_at: string }) => {
        const { receivers } = result;
        let error = '';
        receivers.forEach((receiver) => {
          const { grafana_managed_receiver_configs } = receiver;
          grafana_managed_receiver_configs.forEach((config) => {
            if (config.error) {
              error += `${config.error}\n`;
            }
          });
        });
        if (error) {
          addToast({ text: error, status: 'error', timeout: 10000 });
        } else {
          addToast({ text: 'Test was successful', status: 'success' });
        }
      })
      .catch((error: any) => {});
  };

  return {
    addNotifierType,
    contactPointName,
    dependencyRelation,
    editMode,
    getNotifierData,
    grafanaAlertsNotifiesRequest,
    receiversOptions,
    secureFieldsArray,
    notifiersTypes,
    removeSelectedNotifier,
    setContactPointName,
    selectedNotifierData,
    selectedNotifierTypes,
    setSecureFieldsArray,
    setUpdatedContactPointState,
    setReceiversOptions,
    testContactPoint,
    updateSelectedNotifierData,
    updateSelectedNotifierTypes,
    alertsError: grafanaAlertsNotifiesRequest?.error,
  };
};

export default useAlertsContactsState;
