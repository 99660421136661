import classNames from 'classnames';
import { usePopoverContext } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect, useMemo, useRef } from 'react';
import { kubeFacetCount } from 'requests';
import { kubeNamespaceCount } from 'requests';

import { useKubesState } from './hooks';
import { useKubernetesController } from './KubernetesController';
import KubernetesTagsPopover from './KubernetesTagsPopover';
import { EntityTypes } from './types';
import {
  calculateKubeCount,
  getEntityDetailsValue,
  getFacetValuesFromFilterKey,
  kubeDetailsRowMapping,
} from './utils';

const KubeDetailsTagText = ({
  clickable,
  heading,
  entityKey,
  onTagClick,
  value,
}: {
  clickable: boolean;
  entityKey: string;
  heading: string;
  onTagClick: (tag: string, tagRef: any) => void;
  value: string;
}) => {
  const tagRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classNames({
        'kubernetes__table__details__entity-data__item': true,
        'kubernetes__table__details__entity-data__item--clickable': clickable,
      })}
      onClick={(e) => {
        if (!clickable) return;
        e.stopPropagation();
        onTagClick(`${entityKey}:${value}`, tagRef);
      }}
      style={{ minWidth: tagRef.current?.offsetWidth }}
      ref={tagRef}
    >
      <div className="kubernetes__table__details__entity-data__item__header">
        {heading}
      </div>
      <div className="kubernetes__table__details__entity-data__item__value">
        {value}
      </div>
    </div>
  );
};

const KubernetesRightSideBarDetails = ({
  entity,
}: {
  entity: any;
}): ReactElement => {
  const { flux, facets, facetValueSet, facetValueExclude } =
    useKubernetesController();
  const popover = usePopoverContext();
  const podsCount = useRequest(kubeNamespaceCount);
  const deploymentsCountRequest = useRequest(kubeFacetCount);
  const namespacesCountRequest = useRequest(kubeFacetCount);

  useEffect(() => {
    if (flux.entitiesType !== EntityTypes.Cluster) {
      return;
    }
    namespacesCountRequest.call({
      entityType: EntityTypes.Namespace,
      selectedFacetValuesByName: {
        kube_cluster_name: {
          [entity.clusterName]: 1,
        },
      },
      tags: JSON.stringify(['kube_cluster_name']),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.clusterName, flux.entitiesType]);

  const onFilterByAttribute = (attribute: string) => {
    const queryStr = attribute.replace(':', '=');
    const { facetKey, facetValue } = getFacetValuesFromFilterKey(queryStr);
    facetValueSet(facetKey, {
      [facetValue]: 1,
    });
    popover.close();
  };

  const onExcludeByAttribute = (attribute: string) => {
    const queryStr = attribute.replace(':', '!=');
    const { facetKey, facetValue } = getFacetValuesFromFilterKey(
      queryStr,
      '!=',
    );
    facetValueExclude(facetKey, facetValue);
    popover.close();
  };

  const onCopyToClipboard = (attribute: string) => {
    navigator.clipboard.writeText(attribute);
    popover.close();
  };

  const onTagClick = (tag: string, tagRef: any) => {
    popover.open({
      component: KubernetesTagsPopover,
      element: tagRef.current,
      props: {
        onExclude: onExcludeByAttribute,
        onFilter: onFilterByAttribute,
        onCopyToClipboard,
        tag,
      },
      width: 400,
      popoverPanelClassName: 'popover__panel--overflow',
    });
  };

  const podCount =
    podsCount.result && calculateKubeCount(entity, podsCount.result);

  const entityDetailsData = useMemo(() => {
    const entityDetails = kubeDetailsRowMapping().find(
      (row) => row.name === flux.entitiesType,
    )?.formats;

    const rowLength = entityDetails.length;
    const gridColumnCount =
      rowLength <= 5 ? rowLength : Math.ceil(rowLength / 2);

    return { entityDetails, gridColumnCount };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, podCount]);

  useEffect(() => {
    if (flux.entitiesType === EntityTypes.Namespace) {
      podsCount.call({
        entityType: EntityTypes.Pod,
        groupKey: 'kube_namespace',
        selectedFacetValuesByName: facets,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flux.entitiesType]);

  useEffect(() => {
    if (flux.entitiesType === EntityTypes.Cluster) {
      deploymentsCountRequest.call({
        entityType: EntityTypes.Deployment,
        selectedFacetValuesByName: {
          kube_cluster_name: {
            [entity.clusterName]: 1,
          },
        },
        tags: JSON.stringify(['kube_cluster_name']),
      });
    }

    if (flux.entitiesType === EntityTypes.Namespace) {
      const parsedTags: Record<string, string> = Object.fromEntries(
        entity.tags.map((tag: string) => tag.split(':')),
      );

      const clusterName = parsedTags['kube_cluster_name'];
      const namespaceName = parsedTags['kube_namespace'];

      deploymentsCountRequest.call({
        entityType: EntityTypes.Deployment,
        selectedFacetValuesByName: {
          kube_cluster_name: {
            [clusterName]: 1,
          },
          kube_namespace: {
            [namespaceName]: 1,
          },
        },
        tags: JSON.stringify(['kube_namespace', 'kube_cluster_name']),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flux.entitiesType]);
  const deploymentCount = deploymentsCountRequest?.result?.[0]?.count;

  return (
    <div
      className="kubernetes__table__details__entity-data"
      style={{
        gridTemplateColumns: `repeat(${entityDetailsData.gridColumnCount}, 1fr)`,
      }}
    >
      {entityDetailsData.entityDetails.map((label: any, index: number) => {
        if (
          flux.entitiesType === EntityTypes.Cluster &&
          label.key === 'Deployment'
        ) {
          return (
            <KubeDetailsTagText
              key={index}
              clickable={label.clickable}
              entityKey={label.value}
              heading={label.key}
              onTagClick={onTagClick}
              value={deploymentCount}
            />
          );
        }

        if (
          [EntityTypes.Cluster, EntityTypes.Namespace].includes(
            flux.entitiesType,
          ) &&
          label.key === 'Namespaces'
        ) {
          const namespacesCount = namespacesCountRequest?.result?.[0]?.count;
          return (
            <KubeDetailsTagText
              key={index}
              clickable={label.clickable}
              entityKey={label.value}
              heading={label.key}
              onTagClick={onTagClick}
              value={namespacesCount}
            />
          );
        }
        const entityDetailsValue = getEntityDetailsValue(
          entity,
          label,
          podCount,
          deploymentCount,
        );

        const sanitizedValue =
          typeof entityDetailsValue === 'object'
            ? Object.keys(entityDetailsValue)[0]
            : entityDetailsValue === 'array' && entityDetailsValue[0]
              ? entityDetailsValue[0]
              : entityDetailsValue;
        return (
          <KubeDetailsTagText
            key={index}
            clickable={label.clickable}
            entityKey={label.value}
            heading={label.key}
            onTagClick={onTagClick}
            value={sanitizedValue}
          />
        );
      })}
    </div>
  );
};

export default KubernetesRightSideBarDetails;
