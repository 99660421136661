const extractPathNameFromURL = (): string | null => {
  const url = new URL(window.location.href);
  const firstQuestionMark = url.hash.indexOf('?');
  const path = url.hash.substring(
    1,
    firstQuestionMark !== -1 ? firstQuestionMark : url.hash.length,
  );
  return path;
};

export default extractPathNameFromURL;
