import React from 'react';
import { formatDateTime, exhaustiveSwitchError } from 'utils';

type Context = 'selectedLogTitle'; // | 'anotherContext';

const UserFacingDateTime = ({
  context,
  timestamp,
}: {
  context: Context;
  timestamp: string;
}) => {
  switch (context) {
    case 'selectedLogTitle':
      return (
        <div className="flex gap-x-1">
          <div className="font-semibold">
            {formatDateTime.dateAtTime(timestamp)}
          </div>
          <div className="text-[var(--text03)]">
            ({formatDateTime.timeAgo(timestamp)})
          </div>
        </div>
      );
    default:
      throw exhaustiveSwitchError(context);
  }
};

export default UserFacingDateTime;
