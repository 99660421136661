import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useAlertsState } from '../hooks';
import { RuleProps } from '../types';
import AlertsDetailsSLO from './AlertsDetailsSLO';

const AlertsDetailsSLOWrapper = (): ReactElement => {
  const location = useLocation();
  const { alertId } = useParams();
  const ruleFromUrl = location.state as RuleProps;

  const [rule, setRule] = useState<RuleProps>(ruleFromUrl);

  const alertsState = useAlertsState();

  useEffect(() => {
    if (!rule) {
      const filteredRules = alertsState.rules.filter(
        (rule) => rule.uid === alertId,
      );

      const ruleFromResponse = filteredRules?.[0];

      if (ruleFromResponse) {
        setRule(ruleFromResponse);
      }
    }
  }, [alertId, alertsState.rules, rule]);

  useEffect(() => {
    if (!rule) {
      alertsState.reloadAlerts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!rule) {
    return null;
  }

  return <AlertsDetailsSLO rule={rule} alertsState={alertsState} />;
};

export default AlertsDetailsSLOWrapper;
