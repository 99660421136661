import { useToaster } from 'components';
import { useDateState, useLabelValuePicker, useRequest } from 'hooks';
import { useEffect, useState } from 'react';
import {
  aggregateTimeSeries,
  traceAttributeCardinality,
  traceLabelValues,
} from 'requests';
import { DateSelection, TimeSeries } from 'types';

import { TraceCardinalityQueryProps } from './types';
import {
  convertLabelsToSelectedFacetValuesByName,
  sortTraceCardinalityRows,
  traceCardinalityMatcher,
} from './utils';

const useTracesCardinality = () => {
  const { addToast } = useToaster();
  const [tracesCardinalityQuery, setTracesCardinalityQuery] =
    useState<TraceCardinalityQueryProps>({
      lcLabels: ['=""'],
      hcLabels: ['=""'],
      limit: { direction: 'topk', count: '10' },
      type: 'Lc',
    });
  const [tracesCardinalityRows, setTracesCardinalityRows] = useState<any[]>([]);
  const [date, setDate] = useDateState();

  const [error, setError] = useState({
    getTracesCardinality: null,
  });

  const labelValuePicker = useLabelValuePicker();
  const { setLabelListMap, setLabelValueListMap } = labelValuePicker;

  const traceCardinalityRequest = useRequest(
    traceAttributeCardinality,
    true,
    true,
  );

  const traceCardinalityRequestOneHour = useRequest(traceAttributeCardinality);
  const traceCardinalityChartRequest = useRequest(traceAttributeCardinality);

  const traceCardinalityLabelRequest = useRequest(traceAttributeCardinality);
  const traceLabelValuesRequest = useRequest(traceLabelValues);
  const overallTraceCardinalityRequest = useRequest((arg) =>
    aggregateTimeSeries(arg).then((res: TimeSeries[]) =>
      res ? res[0]?.Value : null,
    ),
  );

  const onDateChange = (nextDate: DateSelection) => {
    const { startTimeUnix, endTimeUnix } = nextDate;
    if (endTimeUnix - startTimeUnix > 300) {
      addToast({
        text: 'Please select a time range less than 5 minutes',
        status: 'error',
      });
      return;
    }
    setDate(nextDate);
  };

  const getLcAndHcSelectedFacet = (
    newTracesCardinalityQuery: TraceCardinalityQueryProps,
  ) => {
    const { lcLabels, hcLabels } = newTracesCardinalityQuery;
    const selectedFacetLc = convertLabelsToSelectedFacetValuesByName(
      lcLabels,
      lcLabels.length - 1,
      'Lc',
    );
    const selectedFacetHc = convertLabelsToSelectedFacetValuesByName(
      hcLabels,
      hcLabels.length - 1,
      'Hc',
    );
    return { selectedFacetLc, selectedFacetHc };
  };

  const loadTraceCardinality = (
    newTracesCardinalityQuery: TraceCardinalityQueryProps,
  ) => {
    return new Promise((resolve, reject) => {
      const { type } = newTracesCardinalityQuery;
      const { selectedFacetLc, selectedFacetHc } = getLcAndHcSelectedFacet(
        newTracesCardinalityQuery,
      );
      traceCardinalityRequest
        .call({
          date,
          selectedFacetValuesByName: selectedFacetLc,
          selectedHcFacetValuesByName: type === 'Hc' ? selectedFacetHc : {},
          type,
        })
        .then((res) => {
          const { cardinalityRows, sortedKeys } = sortTraceCardinalityRows({
            rows: res,
            cardinalityQuery: newTracesCardinalityQuery,
          });
          setTracesCardinalityRows(cardinalityRows);
          loadTraceCardinalityChart(sortedKeys, newTracesCardinalityQuery);
          loadOverallTraceCardinality(newTracesCardinalityQuery);
          loadTraceCardinalityValueOneHour(
            sortedKeys,
            newTracesCardinalityQuery,
          );
          resolve(res);

          if (res) {
            setError((prevError) => ({
              ...prevError,
              getTracesCardinality: {
                message: null,
              },
            }));
          }
        })
        .catch((err) => {
          setError((prevError) => ({
            ...prevError,
            getTracesCardinality: {
              message: 'Failed to fetch trace attributes cardinality',
            },
          }));
          reject(err);
        });
    });
  };

  const loadTraceCardinalityValueOneHour = (
    sortedKeys: string[],
    newTracesCardinalityQuery: TraceCardinalityQueryProps,
  ) => {
    const { type } = newTracesCardinalityQuery;
    const { selectedFacetLc, selectedFacetHc } = getLcAndHcSelectedFacet(
      newTracesCardinalityQuery,
    );
    const hourAgoDate = { ...date };
    hourAgoDate.startTimeUnix = hourAgoDate.endTimeUnix - 3600;
    traceCardinalityRequestOneHour
      .call({
        date: hourAgoDate,
        selectedFacetValuesByName: selectedFacetLc,
        selectedHcFacetValuesByName: type === 'Hc' ? selectedFacetHc : {},
        type,
        keys: sortedKeys,
      })
      .then((res) => {
        setTracesCardinalityRows((prevState) => {
          return prevState.map((row) => {
            const rowLabel = row.GroupVal['key'];
            const valueOneHour = res.find(
              (r: TimeSeries) => r.GroupVal['key'] === rowLabel,
            )?.Value;
            return { ...row, valueOneHour };
          });
        });
      });
  };

  const loadTraceCardinalityChart = (
    sortedKeys: string[],
    newTracesCardinalityQuery: TraceCardinalityQueryProps,
  ) => {
    if (sortedKeys.length === 0) return;
    const { lcLabels, hcLabels, type } = newTracesCardinalityQuery;
    const selectedFacetLc = convertLabelsToSelectedFacetValuesByName(
      lcLabels,
      lcLabels.length - 1,
      'Lc',
    );
    const selectedFacetHc = convertLabelsToSelectedFacetValuesByName(
      hcLabels,
      hcLabels.length - 1,
      'Hc',
    );
    traceCardinalityChartRequest
      .call({
        date,
        format: 'timeseries',
        instant: false,
        keys: sortedKeys,
        rollUpSeconds: 15,
        selectedFacetValuesByName: selectedFacetLc,
        selectedHcFacetValuesByName: type === 'Hc' ? selectedFacetHc : {},
        type,
      })
      .then((res) => {
        if (!res) return;
        setTracesCardinalityRows((prevState) => {
          return prevState.map((row) => {
            const rowValue = row.GroupVal['key'];
            const valueSeries = res[rowValue];
            return { ...row, valueSeries };
          });
        });
      });
  };

  const loadTraceCardinalityLimit = (
    newTracesCardinalityQuery: TraceCardinalityQueryProps,
  ) => {
    const { cardinalityRows, sortedKeys } = sortTraceCardinalityRows({
      rows: traceCardinalityRequest.result || [],
      cardinalityQuery: newTracesCardinalityQuery,
    });
    setTracesCardinalityRows(cardinalityRows);
    loadTraceCardinalityChart(sortedKeys, newTracesCardinalityQuery);
    loadTraceCardinalityValueOneHour(sortedKeys, newTracesCardinalityQuery);
  };

  const updateTracesCardinalityQuery = (
    propertyKey: string,
    value: any,
    noLoad?: boolean,
  ) => {
    setTracesCardinalityQuery((prevState) => {
      const newState = { ...prevState, [propertyKey]: value };
      if (propertyKey === 'limit') {
        loadTraceCardinalityLimit(newState);
        return newState;
      }

      if (propertyKey === 'type') {
        newState.hcLabels = ['=""'];
        newState.lcLabels = ['=""'];
        setLabelValueListMap({});

        loadTraceCardinality(newState).then((resp: TimeSeries[]) => {
          if (!resp || !Array.isArray(resp)) return;
          const labels = resp.map((row) => ({
            label: row.GroupVal['key'],
            value: row.GroupVal['key'],
          }));
          const mapKey = newState.type === 'Lc' ? 'Lc{}' : 'Hc{}';
          setLabelListMap((prev) => ({
            ...prev,
            [mapKey]: { data: labels, isLoading: false },
          }));
        });
        return newState;
      }

      if (
        (propertyKey === 'lcLabels' || propertyKey === 'hcLabels') &&
        noLoad
      ) {
        return newState;
      }
      loadTraceCardinality(newState);

      return newState;
    });
  };

  const loadTraceLabelValues = (
    label: string,
    labelIndex: number,
    newTracesCardinalityQuery: TraceCardinalityQueryProps,
  ) => {
    const { hcLabels, lcLabels, type } = newTracesCardinalityQuery;
    const selectedFacetsLc = convertLabelsToSelectedFacetValuesByName(
      lcLabels,
      labelIndex,
      'Lc',
    );
    const selectedFacetsHc = convertLabelsToSelectedFacetValuesByName(
      hcLabels,
      labelIndex,
      'Hc',
    );

    setLabelValueListMap((prev) => ({
      ...prev,
      [label]: { data: [], isLoading: true },
    }));
    traceLabelValuesRequest
      .call({
        date,
        labelName: label,
        selectedHcFacetValuesByName: selectedFacetsHc,
        selectedFacetValuesByName: type === 'Lc' ? selectedFacetsLc : {},
      })
      .then((res: { value: string }[]) => {
        if (!res || !Array.isArray(res)) return;
        const labelValues = res
          .map((row) => ({ label: row.value, value: row.value }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setLabelValueListMap((prev) => ({
          ...prev,
          [label]: { data: labelValues, isLoading: false },
        }));
      })
      .catch((err) => {
        setLabelValueListMap((prev) => ({
          ...prev,
          [label]: { data: [], isLoading: false },
        }));
      });
  };

  const loadTraceLabels = (
    newTracesCardinalityQuery: TraceCardinalityQueryProps,
    labelIndex: number,
  ) => {
    const { lcLabels, hcLabels, type } = newTracesCardinalityQuery;
    const selectedFacetsLc = convertLabelsToSelectedFacetValuesByName(
      lcLabels,
      labelIndex,
      'Lc',
    );
    const selectedFacetsHc = convertLabelsToSelectedFacetValuesByName(
      hcLabels,
      labelIndex,
      'Hc',
    );

    traceCardinalityLabelRequest
      .call({
        date,
        type: newTracesCardinalityQuery.type,
        selectedHcFacetValuesByName: selectedFacetsHc,
        selectedFacetValuesByName: type === 'Lc' ? selectedFacetsLc : {},
      })
      .then((res: TimeSeries[]) => {
        const labels = type === 'Lc' ? lcLabels : hcLabels;
        const matcher = traceCardinalityMatcher(labels, labelIndex, type);
        if (!res || !Array.isArray(res)) return;
        const labelOptions = res
          .map((row) => ({
            label: row.GroupVal['key'],
            value: row.GroupVal['key'],
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setLabelListMap((prev) => ({
          ...prev,
          [matcher]: { data: labelOptions, isLoading: false },
        }));
      });
  };

  const loadOverallTraceCardinality = (
    newTracesCardinalityQuery: TraceCardinalityQueryProps,
  ) => {
    const { lcLabels, hcLabels, type } = newTracesCardinalityQuery;
    const selectedFacetsLc = convertLabelsToSelectedFacetValuesByName(
      lcLabels,
      lcLabels.length - 1,
      'Lc',
    );
    const selectedFacetsHc = convertLabelsToSelectedFacetValuesByName(
      hcLabels,
      hcLabels.length - 1,
      'Hc',
    );

    const { endTimeUnix, startTimeUnix } = date;
    overallTraceCardinalityRequest.call({
      aggregation: 'distinctcount',
      aggregationField: 'attributes',
      date,
      instant: true,
      groupBys: ['*'],
      rollUpSeconds: endTimeUnix - startTimeUnix,
      selectedFacetValuesByName: selectedFacetsLc,
      selectedHcFacetValuesByName: type === 'Hc' ? selectedFacetsHc : {},
    });
  };

  useEffect(() => {
    loadTraceCardinality(tracesCardinalityQuery).then((resp: TimeSeries[]) => {
      if (!resp || !Array.isArray(resp)) return;
      if (labelValuePicker.labelListMap['Lc{}']) return;
      const labels = resp
        .map((row) => ({
          label: row.GroupVal['key'],
          value: row.GroupVal['key'],
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      const mapKey = tracesCardinalityQuery.type === 'Lc' ? 'Lc{}' : 'Hc{}';
      setLabelListMap({ [mapKey]: { data: labels, isLoading: false } });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return {
    date,
    labelValuePicker,
    loadTraceLabels,
    loadTraceLabelValues,
    onDateChange,
    overallTraceCardinalityRequest,
    tracesCardinalityQuery,
    traceCardinalityRequest,
    traceCardinalityRequestOneHour,
    traceCardinalityChartRequest,
    tracesCardinalityRows,
    traceLabelValuesRequest,
    setTracesCardinalityQuery,
    updateTracesCardinalityQuery,
    error: traceCardinalityRequest?.error,
  };
};

export default useTracesCardinality;
