import classNames from 'classnames';
import {
  AutocompleteV2,
  AutocompleteOption,
  Input,
  InputWithValidatorV3,
  Textarea,
  TooltipTrigger,
  useModalsContext,
} from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect } from 'react';
import { MdCreateNewFolder } from 'react-icons/md';
import { getGrafanaAlertsStatus } from 'requests';

import AlertsKeyPairValue from './AlertsKeyPairValue';
import CreateNewFolderModal from './CreateNewFolderModal';
import { useAlertsCreate } from '../hooks';
import { getFolderOptions } from '../utils';

const AlertsCreateDetails = ({
  alertsCreateState,
}: {
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
}): ReactElement => {
  const modal = useModalsContext();
  const requestGrafanaAlertsRules = useRequest(getGrafanaAlertsStatus);
  const {
    alertsDetails,
    errorHandling,
    getGrafanaAlertsFoldersRequest,
    setAlertsDetails,
    setErrorHandling,
  } = alertsCreateState;

  const folderOptions: AutocompleteOption[] = getFolderOptions(
    getGrafanaAlertsFoldersRequest.result || [],
  );

  useEffect(() => {
    requestGrafanaAlertsRules.call('rules');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCreateNewFolderModal = () => {
    modal.push(
      <div className="alerts__create__details__new-folder-modal">
        <CreateNewFolderModal
          closeModal={modal.pop}
          updateFolder={(folderName: string, folderUid?: string) => {
            modal.pop();
            setAlertsDetails({ ...alertsDetails, folderName, folderUid });
            getGrafanaAlertsFoldersRequest.call();
          }}
        />
      </div>,
    );
  };

  return (
    <div className="alerts__create__section">
      <div className="alerts__create__details__container">
        <div className="alerts__create__details__container__item">
          <div
            className="alerts__create__details__container__item__textbox"
            data-testid="folder-name-container"
          >
            <div>
              Folder name <span className="text--red">*</span>
            </div>
            <AutocompleteV2
              placeholder={alertsDetails.folderName || 'Choose folder name'}
              className={classNames({
                'autocomplete--error': errorHandling['folderName'],
                'autocomplete__fixed-height-30': true,
                'metrics__query-builder__series__builder__placeholder--highlighted':
                  Boolean(alertsDetails.folderName),
              })}
              onChange={(val: string) => {
                const folder = folderOptions.find(
                  (option) => option.value === val,
                );
                setAlertsDetails({
                  ...alertsDetails,
                  folderUid: val,
                  folderName: folder?.label as string,
                });
                setErrorHandling((prev) => ({ ...prev, folderName: '' }));
              }}
              options={folderOptions}
              value={alertsDetails.folderName}
            />
            {errorHandling['folderName'] && (
              <p className="text--red">{errorHandling['folderName']}</p>
            )}
          </div>
          <div className="alerts__create__details__container__item__action">
            <TooltipTrigger tooltip="Create new folder">
              <button
                className="alerts__create__details__add-icon"
                onClick={openCreateNewFolderModal}
              >
                <MdCreateNewFolder />
              </button>
            </TooltipTrigger>
          </div>
        </div>
        <div className="alerts__create__details__container__item">
          <div className="alerts__create__details__container__item__textbox">
            <div>
              Rule name <span className="text--red">*</span>
            </div>
            <InputWithValidatorV3
              className="w-full py-0"
              error={errorHandling['ruleName']}
              errorMessageShownType="inline"
              exclamationMarkPosition="none"
              onChange={(val) => {
                setAlertsDetails((prev) => ({ ...prev, ruleName: val }));
                setErrorHandling((prev) => ({ ...prev, ruleName: '' }));
              }}
              placeholder="Enter rule name.."
              size="5"
              type="text"
              value={alertsDetails.ruleName}
              variant="default"
            />
          </div>
          <div className="alerts__create__details__container__item__action"></div>
        </div>
        <div className="alerts__create__details__container__item">
          <div className="alerts__create__details__container__item__textbox">
            <div>Title</div>
            <Input
              className="py-0"
              onChange={(val) =>
                setAlertsDetails((prev) => ({ ...prev, summary: val }))
              }
              placeholder="Enter title.."
              size="5"
              type="text"
              value={alertsDetails.summary}
              variant="default"
            />
          </div>
          <div className="alerts__create__details__container__item__action"></div>
        </div>
        <div className="alerts__create__details__container__item">
          <div className="alerts__create__details__container__item__textbox">
            <div>Runbook URL</div>
            <InputWithValidatorV3
              className="w-full py-0"
              error={errorHandling['runbookUrl']}
              errorMessageShownType="inline"
              exclamationMarkPosition="none"
              onChange={(val) => {
                setAlertsDetails((prev) => ({ ...prev, runbookUrl: val }));
                setErrorHandling((prev) => ({ ...prev, runbookUrl: '' }));
              }}
              placeholder="Enter runbook URL.."
              size="5"
              type="text"
              value={alertsDetails.runbookUrl}
              variant="default"
            />
          </div>
          <div className="alerts__create__details__container__item__action"></div>
        </div>
        <div className="alerts__create__details__container__item">
          <div className="alerts__create__details__container__item__textbox">
            <div>Description</div>
            <Textarea
              className="alerts__create__details__textarea"
              onChange={(val) =>
                setAlertsDetails((prevState) => ({
                  ...prevState,
                  description: val,
                }))
              }
              placeholder="Enter description.."
              type="text"
              value={alertsDetails.description}
            />
          </div>
          <div className="alerts__create__details__container__item__action"></div>
        </div>
        <div
          className="alerts__create__details__container__item"
          data-testid="labels-container"
        >
          <div className="alerts__create__details__container__item__textbox">
            <div>Custom Labels</div>
            <AlertsKeyPairValue
              onChange={(val) => {
                setAlertsDetails((prevState) => ({
                  ...prevState,
                  customLabels: val,
                }));
              }}
              values={alertsDetails.customLabels}
            />
          </div>
        </div>
        <div
          className="alerts__create__details__container__item"
          data-testid="annotations-container"
        >
          <div className="alerts__create__details__container__item__textbox">
            <div>Custom Annotations</div>
            <AlertsKeyPairValue
              onChange={(val) => {
                setAlertsDetails((prevState) => ({
                  ...prevState,
                  customAnnotations: val,
                }));
              }}
              values={alertsDetails.customAnnotations}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertsCreateDetails;
