import { delimiter } from 'kfuse-constants';
import { mapDataTypeToGraphQLType } from 'requests';
import { findFirstOperator } from 'utils';

const parseFilterByFacet = (filter: string) => {
  const splitForDataType = filter.split(delimiter);

  const [dataTypeRaw] = splitForDataType;
  const dataType = mapDataTypeToGraphQLType(dataTypeRaw?.toUpperCase());
  const facetQueryStr = splitForDataType.slice(1).join(delimiter);

  const { operatorIndex, operator } = findFirstOperator(facetQueryStr);
  if (operatorIndex > -1) {
    const rawFacetName = facetQueryStr.slice(0, operatorIndex);
    const rawFacetNameParts = rawFacetName.split(':');

    const component = rawFacetNameParts.length > 1 ? rawFacetNameParts[0] : '';

    const facetName = rawFacetNameParts
      .slice(rawFacetNameParts.length > 1 ? 1 : 0)
      .join(':');
    const rawValue = facetQueryStr.slice(operatorIndex + operator.length);

    // remove wrapping first / last quotes
    const value = rawValue.replace(/^"(.*)"$/, '$1');

    return {
      component,
      dataType,
      facetName,
      operator,
      value,
    };
  }

  return null;
};

export default parseFilterByFacet;
