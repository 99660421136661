import { DashboardPanelType } from 'types/Dashboard';
import { DataFrame } from '../types';

import { QueryCombinedStatusProps, QueryDataProps } from 'types/QueryData';
import { combineRangeQueryData } from '../combineQueryData';

const traceDashboardDataTransformer = (
  data: { data: QueryDataProps; meta: DataFrame['meta'] }[],
) => {
  const panelType = data[0].meta.type;

  if (panelType !== DashboardPanelType.TIMESERIES) {
    const activeQueriesData = data.filter((d) => d.meta.isActive);
    if (activeQueriesData.length === 0) return [];
    return activeQueriesData[0];
  }

  const formulasStatus: QueryCombinedStatusProps[] = [];
  const queriesStatus: QueryCombinedStatusProps[] = [];
  const queryData: QueryDataProps = {};
  data.forEach((d) => {
    const { refId, queryType, isActive } = d.meta;
    const queryId = `${queryType}_${refId}`;
    queryData[queryId] = { range: d.data, meta: d.meta, isLoading: false };
    if (queryType === 'query') {
      queriesStatus.push({ isActive, queryKey: refId });
    }
    if (queryType === 'formula') {
      formulasStatus.push({ isActive, queryKey: refId });
    }
  });

  const combinedData = combineRangeQueryData({
    formulas: formulasStatus,
    queries: queriesStatus,
    queryData,
  });

  return combinedData;
};

export default traceDashboardDataTransformer;
