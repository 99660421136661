import uPlot from 'uplot';

const orient = (u: uPlot, seriesIdx: number) => {
  const mode = u.mode;
  const series = u.series[seriesIdx];
  const data = mode == 2 ? u.data[seriesIdx] : u.data;
  const scales = u.scales;
  const bbox = u.bbox;

  const dx = data[0],
    dy = mode == 2 ? data[1] : data[seriesIdx],
    sx = mode == 2 ? scales[series.facets[0].scale] : scales[u.series[0].scale],
    sy = mode == 2 ? scales[series.facets[1].scale] : scales[series.scale];
  const H = u.valToPosH;
  const V = u.valToPosV;

  return {
    series,
    dataX: dx,
    dataY: dy,
    scaleX: sx,
    scaleY: sy,
    valToPosX: sx.ori === 0 ? H : V,
    valToPosY: sx.ori === 0 ? V : H,
    xOffset: sx.ori === 0 ? bbox.left : bbox.top,
    yOffset: sx.ori === 0 ? bbox.top : bbox.left,
    xDimens: sx.ori === 0 ? bbox.width : bbox.height,
    yDimens: sx.ori === 0 ? bbox.height : bbox.width,
  };
};

export const getActivePointPosition = (
  u: uPlot,
  seriesIdx: number,
  pointIdx: number,
): { x: number; y: number } => {
  const or = orient(u, seriesIdx);
  const {
    dataX,
    dataY,
    scaleX,
    scaleY,
    series,
    valToPosX,
    valToPosY,
    xDimens,
    yDimens,
    xOffset,
    yOffset,
  } = or;
  const { pxRound } = series;
  if (!pxRound) return { x: 0, y: 0 };

  const x = pxRound(valToPosX(dataX[pointIdx], scaleX, xDimens, xOffset));
  const y = pxRound(valToPosY(dataY[pointIdx], scaleY, yDimens, yOffset));

  if (scaleX.ori === 0) {
    return { x, y };
  } else {
    return { x: y, y: x };
  }
};

export const canvasPadding = (
  u: uPlot,
): {
  left: number;
  bottom: number;
  right: number;
  top: number;
} => {
  const { _padding } = u;
  const [bottom, left] = u.axes;
  const devicePixelRatio = window.devicePixelRatio || 1;

  const padding = {
    left: (left._size * devicePixelRatio) as number,
    bottom: (bottom._size * devicePixelRatio) as number,
    right: _padding[1] * devicePixelRatio,
    top: _padding[0] * devicePixelRatio,
  };

  return padding;
};

export const getTooltipCanvasX = (u: uPlot, pointIdx: number) => {
  // find the value in the data array where the value is not null expect array 0
  const seriesIdx = u.data
    .map((arr, idx) => (idx !== 0 ? arr[pointIdx] : undefined))
    .findIndex((arr) => arr !== null && arr !== undefined);

  if (seriesIdx === -1) return null;

  // x position of the tooltip
  const pos = getActivePointPosition(u, seriesIdx, pointIdx);
  return pos.x;
};
