import { DashboardPanelProps } from 'types/Dashboard';

export const mapPanelTableData = ({
  fieldConfig,
  data,
}: {
  fieldConfig: DashboardPanelProps['fieldConfig'];
  data: { [key: string]: any }[];
}) => {
  if (!fieldConfig) return data;
  if (
    !fieldConfig.defaults?.mappings ||
    fieldConfig.defaults?.mappings.length === 0
  )
    return data;

  const mappings = fieldConfig.defaults.mappings;
  let initMappingData = [...data];
  mappings.forEach((map) => {
    if (map.type === 'regex') {
      initMappingData = mapDataByRegex(data, map.options);
    }
  });

  return initMappingData;
};

const mapDataByRegex = (
  data: { [key: string]: any }[],
  options: {
    pattern: string;
    result: { index: number; text: string };
  },
) => {
  if (!data) return [];
  if (Array.isArray(data) && data.length === 0) {
    return data;
  }

  const regex = RegExp(options.pattern);
  return data.map((d) => {
    const matchedData: { [key: string]: string } = {};
    Object.keys(d).forEach((k) => {
      if (regex.test(d[k])) {
        matchedData[k] = d[k].replace(regex, options.result.text);
      } else {
        matchedData[k] = d[k];
      }
    });
    return matchedData;
  });
};
