import { SizeObserver } from 'components';
import React from 'react';
import RumFlameGraphMain from './RumFlameGraphMain';
import { RumEvent, RumEventType } from '../types';
import { getChartColor } from '../utils';

type Props = {
  activeRumEvent: RumEvent;
  applicationFilter?: string;
  eventType: RumEventType;
  setNestedDrawerEvent: (event: RumEvent | null) => void;
  startTimeMs: number;
};

export const FLAME_GRAPH_TABLE_WIDTH = 400;

const RumFlameGraph = ({
  activeRumEvent,
  applicationFilter,
  eventType,
  setNestedDrawerEvent,
  startTimeMs,
}: Props) => {
  return (
    <div className="mx-2 h-full">
      <SizeObserver className="rum-flamegraph">
        {({ width: baseWidth }) => (
          <RumFlameGraphMain
            activeRumEvent={activeRumEvent}
            applicationFilter={applicationFilter}
            flameGraphChartContainerWidth={baseWidth - FLAME_GRAPH_TABLE_WIDTH}
            getColor={(event) => {
              return getChartColor(event.eventType);
            }}
            startTimeMs={startTimeMs}
            eventType={eventType}
            setNestedDrawerEvent={setNestedDrawerEvent}
          />
        )}
      </SizeObserver>
    </div>
  );
};

export default RumFlameGraph;
