import { onPromiseError } from 'utils';
import fetchJson from './fetchJson';
import { DateSelection } from 'types/DateSelection';

const promqlLabels = ({
  date,
  matcher,
}: {
  date: DateSelection;
  matcher?: string;
}): Promise<string[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  let time = `start=${startTimeUnix}&end=${endTimeUnix}`;
  if (matcher) {
    time += `&match[]=${matcher}`;
  }
  return fetchJson(`/api/v1/labels?${time}`)
    .then((res: { data: string[] }) => {
      if (!res?.data) return [];
      const { data } = res;
      return data.filter((label) => label !== '__name__' && label !== '');
    })
    .catch(onPromiseError);
};

export default promqlLabels;
