import { Button, TooltipTrigger } from 'components';
import { SearchState, useSearches, useSearchFormulas } from 'hooks';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Plus, PlayCircle } from 'react-feather';
import { DashboardPanelType, TracesTab } from 'types';
import {
  ALLOW_ONLY_ONE_ACTIVE_SEARCH,
  ALLOW_ONLY_TWO_ACTIVE_SEARCHES,
} from '../utils';

type Props = {
  allowOnlyOneActiveSearchTypes?: DashboardPanelType[];
  searches: ReturnType<typeof useSearches>;
  searchesFormulas?: ReturnType<typeof useSearchFormulas>;
  tracesTab: TracesTab;
  runQueries?: () => void;
};

const TracesSearchQueryButtons = ({
  allowOnlyOneActiveSearchTypes = ALLOW_ONLY_ONE_ACTIVE_SEARCH,
  searches,
  searchesFormulas,
  tracesTab,
  runQueries,
}: Props) => {
  const addNewSearch = useCallback(() => {
    if (
      ALLOW_ONLY_TWO_ACTIVE_SEARCHES.includes(
        tracesTab as unknown as DashboardPanelType,
      )
    ) {
      const activeSearches = searches.filter((s) => s.isActive).length;

      if (activeSearches < 2) {
        searches[activeSearches - 1].addNewSearch(false, false);
      } else {
        searches[activeSearches - 1].addNewSearch(false, true);
      }

      return;
    }

    if (
      allowOnlyOneActiveSearchTypes.includes(
        tracesTab as unknown as DashboardPanelType,
      )
    ) {
      searchesFormulas.deactivateAll();
      searches[0].addNewSearch(true);
    } else {
      searches[0].addNewSearch(false);
    }
  }, [allowOnlyOneActiveSearchTypes, searches, searchesFormulas, tracesTab]);

  const addFormula = useCallback(() => {
    if (
      allowOnlyOneActiveSearchTypes.includes(
        tracesTab as unknown as DashboardPanelType,
      )
    ) {
      searches[0].deactivateAll();
      searchesFormulas.addNewFormula(true);
    } else {
      searchesFormulas.addNewFormula(false);
    }
  }, [allowOnlyOneActiveSearchTypes, searches, searchesFormulas, tracesTab]);

  useEffect(() => {
    if (
      allowOnlyOneActiveSearchTypes.includes(
        tracesTab as unknown as DashboardPanelType,
      )
    ) {
      const countOfFormulas = searchesFormulas?.formulas?.filter(
        (f) => f.state.isActive,
      )?.length;
      if (countOfFormulas > 0) {
        searches?.[0]?.deactivateAll();
        searchesFormulas?.formulas?.[0]?.activateOnlySingleFormula();
        return;
      }
      const countOfSearches = searches.filter((s) => s.state.isActive).length;
      if (countOfSearches > 1) {
        searches?.[0]?.selectOnlySingeQuery();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracesTab]);

  return (
    <div>
      <TooltipTrigger
        tooltip={
          searches?.length >= 2
            ? 'Only 2 queries can be active in a heatmap'
            : null
        }
        className="inline"
      >
        <Button
          variant="default"
          size="sm"
          className="mt-2"
          onClick={addNewSearch}
          data-testid="add-query"
          disabled={
            tracesTab === TracesTab.heatmap ? searches?.length >= 2 : false
          }
        >
          <Plus size={16} /> Add query
        </Button>
      </TooltipTrigger>

      {tracesTab !== TracesTab.heatmap && (
        <Button
          variant="default"
          size="sm"
          className="ml-1 mt-2"
          onClick={addFormula}
        >
          <Plus size={16} /> Add Formula
        </Button>
      )}

      {tracesTab === TracesTab.heatmap && (
        <Button
          variant="default"
          size="sm"
          className="ml-1 mt-2"
          onClick={runQueries}
          data-testid="run"
        >
          <PlayCircle size={16} className="mr-[2px]" /> Run
        </Button>
      )}
    </div>
  );
};

export default TracesSearchQueryButtons;
