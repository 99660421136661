import { fetchGrafanaApi } from 'requests';

import { SilencesProps } from '../types';

const createOrUpdateSilence = async ({
  silence,
}: {
  silence: SilencesProps;
}): Promise<Record<string, unknown>> => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ ...silence }),
  };
  const result = await fetchGrafanaApi(
    `grafana/api/alertmanager/grafana/api/v2/silences`,
    options,
  );

  return result;
};

export default createOrUpdateSilence;
