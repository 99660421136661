import { Button, RightSidebar, Table, useToaster } from 'components';
import { useRequest, useToggle } from 'hooks';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { MdShield as ShieldIcon } from 'react-icons/md';

import { AddPermission } from '../AddPermission';
import FolderManagementSettingsTableActions from './FolderManagementSettingsTableActions';
import FolderManagementSettingsPermission from './FolderManagementSettingsPermission';
import {
  getFolderPermissionsByUid,
  updateFolderPermissionById,
  updateFolderPermissionRole,
} from './requests';
import {
  FolderProps,
  FolderPermissionsProps,
  FolderPermissionCategory,
} from './types';
import { filterFolderPermissions } from './utils';

const folderPermissionColumns = ({
  folder,
  onRemoveMember,
  permissionCategory,
  onPermissionChange,
}: {
  folder: FolderProps;
  onRemoveMember: (
    member: FolderPermissionsProps,
    type: FolderPermissionCategory,
  ) => void;
  permissionCategory: FolderPermissionCategory;
  onPermissionChange?: () => void;
}) => [
  {
    key: 'folder-permission-avatar',
    label: '',
    renderCell: ({ row }: { row: FolderPermissionsProps }) => {
      if (permissionCategory === FolderPermissionCategory.USER) {
        return (
          <div className="flex items-center justify-center">
            <img
              className="size-7 w-7 rounded-full"
              referrerPolicy="no-referrer"
              src={row.userAvatarUrl}
            />
          </div>
        );
      }
      return (
        <div className="flex items-center justify-center">
          <ShieldIcon size={16} />
        </div>
      );
    },
  },
  {
    key: 'folder-permission-name',
    label:
      permissionCategory === FolderPermissionCategory.USER
        ? 'User'
        : permissionCategory === FolderPermissionCategory.ROLE
          ? 'Role'
          : 'Group',
    renderCell: ({ row }: { row: FolderPermissionsProps }) => {
      if (permissionCategory === FolderPermissionCategory.USER)
        return row.userLogin;
      if (permissionCategory === FolderPermissionCategory.ROLE)
        return row.builtInRole;
      if (permissionCategory === FolderPermissionCategory.TEAM) return row.team;
    },
  },
  {
    key: 'permission',
    label: 'Permission',
    renderCell: ({ row }: { row: FolderPermissionsProps }) => {
      return (
        <FolderManagementSettingsPermission
          folder={folder}
          permission={row}
          permissionCategory={permissionCategory}
          onPermissionChange={onPermissionChange}
        />
      );
    },
  },
  {
    label: '',
    key: 'actions',
    renderCell: ({ row }: { row: FolderPermissionsProps }) => (
      <FolderManagementSettingsTableActions
        permissionCategory={permissionCategory}
        row={row}
        onRemoveMember={onRemoveMember}
      />
    ),
  },
];

const PermissionTableTitle = ({
  title,
  className,
}: {
  title: string;
  className?: string;
}) => {
  return (
    <div className={`flex items-center justify-between pb-2 ${className}`}>
      <h2 className="text-lg font-semibold">{title}</h2>
    </div>
  );
};

const PermissionTableLoader = () => {
  return (
    <div className="admin__row-muted flex h-10 w-full items-center justify-center">
      <div className="spinner"></div>
    </div>
  );
};

const FolderManagementSettings = ({
  folder,
  close,
}: {
  folder: FolderProps;
  close: () => void;
}): ReactElement => {
  const { addToast } = useToaster();
  const getFolderPermissions = useRequest(getFolderPermissionsByUid);
  const updateFolderUserPermission = useRequest(updateFolderPermissionById);
  const updateFolderRolePermission = useRequest(updateFolderPermissionRole);
  const addMemberToggle = useToggle(false);

  const onRemoveMember = (
    permission: FolderPermissionsProps,
    type: FolderPermissionCategory,
  ) => {
    if (type === FolderPermissionCategory.ROLE) {
      updateFolderRolePermission
        .call({
          folderUid: folder.uid,
          role: permission.builtInRole,
          permission: '',
        })
        .then(() => {
          getFolderPermissions.call({ uid: folder.uid });
          addToast({ text: `Role removed from folder`, status: 'success' });
        });
    }
    if (
      type === FolderPermissionCategory.USER ||
      type === FolderPermissionCategory.TEAM
    ) {
      const isUser = type === FolderPermissionCategory.USER;
      updateFolderUserPermission
        .call({
          folderUid: folder.uid,
          id: isUser ? permission.userId : permission.teamId,
          permission: '',
          type: isUser ? 'users' : 'teams',
        })
        .then(() => {
          getFolderPermissions.call({ uid: folder.uid });
          addToast({
            text: `${isUser ? 'User' : 'Team'} removed from folder`,
            status: 'success',
          });
        });
    }
  };

  useEffect(() => {
    getFolderPermissions.call({ uid: folder.uid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder.uid]);

  const { roles, users, groups } = useMemo(() => {
    return filterFolderPermissions(getFolderPermissions.result);
  }, [getFolderPermissions.result]);

  return (
    <div>
      <RightSidebar
        className="w-[800px]"
        close={close}
        title={
          <span className="text-lg font-medium">
            Folder Settings of {folder.title}
          </span>
        }
        dataTestId="folder-settings"
      >
        <div className="p-4">
          <div className="flex items-center justify-end pb-2">
            <Button
              variant="default"
              size="sm"
              onClick={addMemberToggle.toggle}
            >
              Add Permission to Folder
            </Button>
          </div>
          {addMemberToggle.value && (
            <AddPermission
              type="folder"
              folderUid={folder.uid}
              onPermissionAdded={() => {
                getFolderPermissions.call({ uid: folder.uid });
              }}
              onClose={addMemberToggle.toggle}
            />
          )}
          <PermissionTableTitle title="Roles" />
          {getFolderPermissions.isLoading ? (
            <PermissionTableLoader />
          ) : (
            <Table
              className="table--bordered table--bordered-cells table__actions--hidden alerts__list__table"
              columns={folderPermissionColumns({
                folder,
                onRemoveMember,
                permissionCategory: FolderPermissionCategory.ROLE,
                onPermissionChange: () => {
                  getFolderPermissions.call({ uid: folder.uid });
                },
              })}
              dataTestId="folder-settings-roles-table"
              rows={roles}
            />
          )}
          {users.length > 0 ? (
            <>
              <PermissionTableTitle className="pt-4" title="Users" />
              {getFolderPermissions.isLoading ? (
                <PermissionTableLoader />
              ) : (
                <Table
                  className="table--bordered table--bordered-cells table__actions--hidden alerts__list__table"
                  columns={folderPermissionColumns({
                    folder,
                    onRemoveMember,
                    permissionCategory: FolderPermissionCategory.USER,
                    onPermissionChange: () => {
                      getFolderPermissions.call({ uid: folder.uid });
                    },
                  })}
                  dataTestId="folder-settings-users-table"
                  rows={users}
                />
              )}
            </>
          ) : null}
          {groups.length > 0 ? (
            <>
              <PermissionTableTitle className="pt-4" title="Groups" />
              {getFolderPermissions.isLoading ? (
                <PermissionTableLoader />
              ) : (
                <Table
                  className="table--bordered table--bordered-cells table__actions--hidden alerts__list__table"
                  columns={folderPermissionColumns({
                    folder,
                    onRemoveMember,
                    permissionCategory: FolderPermissionCategory.TEAM,
                    onPermissionChange: () => {
                      getFolderPermissions.call({ uid: folder.uid });
                    },
                  })}
                  dataTestId="folder-settings-groups-table"
                  rows={groups}
                />
              )}
            </>
          ) : null}
        </div>
      </RightSidebar>
    </div>
  );
};

export default FolderManagementSettings;
