import { Tab, Tabs, useTabs } from 'components';
import React from 'react';
import { Span, TraceMetrics } from 'types';
import TraceSidebarMainContent from './TraceSidebarMainContent';
import TraceSidebarMainSpanList from './TraceSidebarMainSpanList';
import TraceSidebarMainWaterfall from './TraceSidebarMainWaterfall';

type Props = {
  clickedSpanId?: string;
  colorsByServiceName: { [key: string]: string };
  hoveredSpanId?: string;
  setClickedSpanId: (spanId: string) => void;
  setHoveredSpanId: (spanId: string) => void;
  spans: Span[];
  traceMetrics: TraceMetrics;
};

const TraceSidebarMain = ({
  clickedSpanId,
  colorsByServiceName,
  hoveredSpanId,
  setClickedSpanId,
  setHoveredSpanId,
  spans,
  traceMetrics,
}: Props) => {
  const tabs = useTabs();
  return (
    <Tabs className="tabs--underline" tabs={tabs}>
      <Tab label="Flame graph">
        <TraceSidebarMainContent
          clickedSpanId={clickedSpanId}
          colorsByServiceName={colorsByServiceName}
          hoveredSpanId={hoveredSpanId}
          setClickedSpanId={setClickedSpanId}
          setHoveredSpanId={setHoveredSpanId}
          shouldShowWaterfall={false}
          spans={spans}
          traceMetrics={traceMetrics}
        />
      </Tab>
      <Tab label={`Span List (${spans.length})`}>
        <TraceSidebarMainSpanList
          clickedSpanId={clickedSpanId}
          colorsByServiceName={colorsByServiceName}
          setClickedSpanId={setClickedSpanId}
          spans={spans}
          traceMetrics={traceMetrics}
        />
      </Tab>
      <Tab label="Waterfall">
        <TraceSidebarMainContent
          clickedSpanId={clickedSpanId}
          colorsByServiceName={colorsByServiceName}
          hoveredSpanId={hoveredSpanId}
          setClickedSpanId={setClickedSpanId}
          setHoveredSpanId={setHoveredSpanId}
          shouldShowWaterfall
          spans={spans}
          traceMetrics={traceMetrics}
        />
      </Tab>
    </Tabs>
  );
};

export default TraceSidebarMain;
