import { Tabs, Tab, useTabs } from 'components';
import React, { ReactElement } from 'react';
import { DateSelection } from 'types';

import AlertsServiceCorrelationsMetrics from './AlertsServiceCorrelationsMetrics';
import AlertsServiceCorrelationsLogs from './AlertsServiceCorrelationsLogs';
import AlertsServiceCorrelationsTraces from './AlertsServiceCorrelationsTraces';
import useAlertsServiceCorrelationState from './useAlertsServiceCorrelationState';
import { CorrelationMetricsQueryProps } from './types';

const AlertsServiceCorrelationsTabs = ({
  alertsServiceCorrelationState,
  date,
  serviceHash,
}: {
  alertsServiceCorrelationState: ReturnType<
    typeof useAlertsServiceCorrelationState
  >;
  date: DateSelection;
  serviceHash: string;
}): ReactElement => {
  const { analysisServiceCorrelationRequest } = alertsServiceCorrelationState;
  const tabs = useTabs();

  return (
    <Tabs className="tabs--underline" tabs={tabs}>
      <Tab label="Metrics">
        <AlertsServiceCorrelationsMetrics
          correlationMetrics={
            analysisServiceCorrelationRequest.result as CorrelationMetricsQueryProps[]
          }
          error={analysisServiceCorrelationRequest.error}
          date={date}
        />
      </Tab>
      <Tab label="Logs">
        <AlertsServiceCorrelationsLogs date={date} />
      </Tab>
      <Tab label="Traces">
        <AlertsServiceCorrelationsTraces
          alertsServiceCorrelationState={alertsServiceCorrelationState}
          date={date}
          serviceHash={serviceHash}
        />
      </Tab>
    </Tabs>
  );
};

export default AlertsServiceCorrelationsTabs;
