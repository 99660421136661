import {
  useToaster,
  AutocompleteV2,
  MultiselectV2,
  Input,
  Button,
  TooltipTrigger,
} from 'components';
import { useLogsMetricsQueryState } from 'hooks';
import React, { ReactElement } from 'react';
import { IoMdClose } from 'react-icons/io';
import { FunctionProps } from 'types';
import { LogsMetricQueryProps } from 'types/LogsMetricsQueryBuilder';
import { AGGREGATE_FUNCTIONS, getRollupOptionsByTimeRange } from 'utils';
import { KfuseQlFunction } from 'utils/KfuseqlBuilder/types';

const LogsMetricsQueryBuilderFunctionsParam = ({
  queryIndex,
  query,
  logsMetricsQueryState,
  minStep,
}: {
  queryIndex: number;
  query: LogsMetricQueryProps;
  logsMetricsQueryState: ReturnType<typeof useLogsMetricsQueryState>;
  minStep: string;
}): ReactElement => {
  const { addToast } = useToaster();
  const { date, updateFunction, removeFunction } = logsMetricsQueryState;

  const getRollupOptionsForAnomalyWindow = ({
    param,
    fnName,
  }: {
    param: FunctionProps['params'][0];
    fnName: string;
  }) => {
    const { name } = param;
    if (fnName === KfuseQlFunction.ANOMALIES && name === 'window') {
      return getRollupOptionsByTimeRange({ date, minStep, step: query.step });
    }
    return param.options;
  };
  return (
    <>
      {query.functions?.map((fn, fnIndex) => {
        return (
          <React.Fragment key={fnIndex}>
            <div className="button-group" key={fnIndex}>
              <div className="button-group__item button-group__item--unpadded">
                <div className="metrics__function-builder__item__name">
                  {AGGREGATE_FUNCTIONS.includes(fn.name)
                    ? 'aggregation'
                    : fn.name}
                </div>
                <div
                  className="metrics__function-builder__item__params"
                  data-testid="function-builder-params"
                >
                  {fn.params &&
                    fn.params.map((param, paramIndex) => {
                      if (param.type === 'text' || param.type === 'number') {
                        return (
                          <Input
                            className="metrics__function-builder__item__params__input"
                            key={paramIndex}
                            max={param.max}
                            min={param.min}
                            onChange={(val) => {
                              if (param.validator) {
                                const isValid = param.validator(val);
                                if (Boolean(isValid)) {
                                  addToast({ text: isValid, status: 'error' });
                                  return;
                                }
                              }
                              updateFunction(
                                queryIndex,
                                fnIndex,
                                paramIndex,
                                val,
                              );
                            }}
                            placeholder={param.name}
                            type={param.type}
                            value={param.value}
                            size="2"
                            step={param.step}
                            required={param.required}
                          />
                        );
                      }
                      if (param.type === 'select') {
                        return (
                          <AutocompleteV2
                            className="autocomplete-container--no-border autocomplete__fixed-height-30"
                            key={paramIndex}
                            onChange={(val) =>
                              updateFunction(
                                queryIndex,
                                fnIndex,
                                paramIndex,
                                val,
                              )
                            }
                            options={getRollupOptionsForAnomalyWindow({
                              param,
                              fnName: fn.name,
                            })}
                            value={param.value}
                          />
                        );
                      }
                      if (param.type === 'multi-select') {
                        return (
                          <MultiselectV2
                            className="autocomplete-container--no-border autocomplete__fixed-height-30"
                            key={paramIndex}
                            onChange={(val) =>
                              updateFunction(
                                queryIndex,
                                fnIndex,
                                paramIndex,
                                val,
                              )
                            }
                            options={param.options}
                            value={param.value}
                          />
                        );
                      }
                    })}
                </div>
                <TooltipTrigger tooltip="Remove function">
                  <Button
                    className="metrics__function-builder__item__close h-full"
                    variant="ghost"
                    size="sm"
                  >
                    <IoMdClose
                      onClick={() => removeFunction(queryIndex, fnIndex)}
                      size={18}
                    />
                  </Button>
                </TooltipTrigger>
              </div>
            </div>
            <div className="search__button-group__divider">
              <div />
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default LogsMetricsQueryBuilderFunctionsParam;
