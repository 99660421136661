import { SunburstDataProps } from 'types/Sunburst';
import { getColorForOneSeries } from 'utils/colors';
import { TableTransformerData } from '../types';
import getEvaluatedValue from './utils/getEvaluatedValue';

const treeMapTransformer = ({
  data: acc,
  options,
}: {
  data: TableTransformerData;
  options?: { unit: string; decimals: number };
}): TableTransformerData & {
  treeChartData: SunburstDataProps[];
} => {
  const { data, columns } = acc;
  const treeChartData: SunburstDataProps[] = [];
  let total = 0;
  const labelCols = columns.filter((column) => !column.key.includes('Value #'));
  const valueCols = columns.find((column) => column.key.includes('Value #'));

  const labelKeys = labelCols.map((col) => col.key);
  const valueKeys = valueCols?.key;

  data.forEach((item, idx) => {
    const value = Number(item[valueKeys]);
    const evaluatedValue = options?.unit
      ? getEvaluatedValue(value, options.unit, options.decimals)
      : value;
    const color = getColorForOneSeries(item, idx);
    treeChartData.push({
      name: `${labelKeys.map((key) => item[key]).join(', ')} (${evaluatedValue || 0})`,
      size: value || 0,
      color,
      tooltipTitle: `${labelKeys.map((key) => item[key]).join(', ')}`,
      tooltipContent: `${evaluatedValue || 0}`,
    });
    total += value || 0;
    data[idx].color = color;
  });

  data.forEach((item) => {
    item.percentage = (Number(item[valueKeys]) / total) * 100;
    item.label = labelKeys.map((key) => item[key]).join(', ');
  });

  return { data, columns: columns, treeChartData };
};

export default treeMapTransformer;
