import dayjs from 'dayjs';
import { LogEvent, LogsState } from 'types';
import { onPromiseError } from 'utils';

import query from './query';
import { buildQuery } from './utils';

type Args = {
  fingerprint: string;
  logsState: LogsState;
};

const logSample = async ({ fingerprint, logsState }: Args): Promise<any> => {
  const { date } = logsState;
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const logQuery = buildQuery(logsState);

  return query<LogEvent[], 'logSample'>(`
    {
      logSample(
        durationSecs: ${durationSecs}
        fingerprints: ["${fingerprint}"]
        ${logQuery !== '{}' ? `query: ${logQuery},` : ''}
        timestamp: "${endTime.format()}",
      ) {
        fpPattern
        message
      }
    }
  `).then(
    (data) =>
      data.logSample && data.logSample.length ? data.logSample[0] : null,
    onPromiseError,
  );
};

export default logSample;
