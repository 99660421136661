import formatLogLineParts from './formatLogLineParts';
import parseLogFacetsFromLogEvent from './parseLogFacetsFromLogEvent';

const findValue = (key: string, obj: { [key: string]: any }): any => {
  const keys = key.split('.');
  if (keys.length === 1) {
    return obj[keys[0]];
  }

  if (keys[0] in obj) {
    return findValue(keys.slice(1).join('.'), obj[keys[0]]);
  }

  return '';
};

const getValue = ({ column, row }) => {
  const { key, value } = column;
  const v = typeof value === 'function' ? value({ row }) : findValue(key, row);
  const vType = typeof v;
  if (vType === 'string' || vType === 'number' || vType === 'boolean') {
    return String(v);
  }

  return '';
};

const formatAsTxt = ({ columns, rows }) => {
  let result = '';
  rows.forEach((row) => {
    result += `${
      row.logLineParts ? formatLogLineParts(row.logLineParts) : row.message
    }\n`;
  });

  return result;
};
const formatAsJson = ({ columns, rows }) => {
  // If the logLineParts are present, we will format the rows as a log event
  const applyLogFormatter = Boolean(rows?.[0]?.logLineParts);
  if (applyLogFormatter) {
    const content = rows.map((row = {}) => ({
      level: row.level,
      labels: row.labels,
      log: formatLogLineParts(row.logLineParts),
      facets: parseLogFacetsFromLogEvent(row),
    }));
    return JSON.stringify(content);
  } else {
    return JSON.stringify(rows);
  }
};
const formatAsCsv = ({ columns, rows }) => {
  let result = '';
  for (let x = 0; x < columns.length; x += 1) {
    result += `${columns[x].label}${x === columns.length - 1 ? '\n' : ','}`;
  }

  for (let y = 0; y < rows.length; y += 1) {
    for (let x = 0; x < columns.length; x += 1) {
      result += `"${getValue({ column: columns[x], row: rows[y] }).replace(
        /"/g,
        '""',
      )}"${x === columns.length - 1 ? '\n' : ','}`;
    }
  }

  return result;
};

export const getFileExtension = (downloadType) => {
  switch (downloadType) {
    case 'CSV':
      return 'csv';
    case 'JSON':
      return 'json';
    case 'TXT':
      return 'txt';
    default:
      return formatAsTxt;
  }
};

export const getFormatter = (downloadType) => {
  switch (downloadType) {
    case 'CSV':
      return formatAsCsv;
    case 'JSON':
      return formatAsJson;
    case 'TXT':
      return formatAsTxt;
    default:
      return formatAsTxt;
  }
};
