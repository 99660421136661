import { ErrorBoundary } from 'components';
import React, { ReactElement, useMemo } from 'react';
import { DateSelection } from 'types';

import { DashboardByName } from '../../Kfuse';

const ServiceTabAnomaly = ({
  asmMatcher,
  date,
  kfSource,
}: {
  asmMatcher: string;
  date: DateSelection;
  kfSource?: string;
}): ReactElement => {
  const kubeFilters = useMemo(() => {
    return { matchers: asmMatcher, kf_source: kfSource };
  }, [asmMatcher, kfSource]);

  return (
    <div className="service__tab">
      {kubeFilters && (
        <ErrorBoundary errorMessage={<h1>Failed to load anomaly charts</h1>}>
          <DashboardByName
            date={date}
            disableDeletePanel={true}
            folderName="kfuse-asm"
            hideHeader={true}
            name="ASM Anomaly Resources"
            templateValues={kubeFilters}
          />
        </ErrorBoundary>
      )}
    </div>
  );
};

export default ServiceTabAnomaly;
