import React, { createContext, useContext } from 'react';

export const RefContext = createContext<{
  appRef: React.MutableRefObject<HTMLDivElement>;
  leftSidebarRef: React.MutableRefObject<HTMLDivElement>;
  bodyDivRef: React.MutableRefObject<HTMLDivElement>;
}>({
  appRef: { current: null },
  leftSidebarRef: { current: null },
  bodyDivRef: { current: null },
});

export const useRefContext = () => useContext(RefContext);
