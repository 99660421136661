import {
  ConfirmationModal,
  Loader,
  Paginator,
  Table,
  TooltipTrigger,
  useModalsContext,
  usePaginator,
  useToaster,
  useTableSearch,
  useTableSort,
  TableSearch,
} from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect } from 'react';
import { deleteLogsMetric, getLogsSavedMetrics } from 'requests';
import { MdDelete } from 'react-icons/md';
import { BiLinkExternal } from 'react-icons/bi';
import { getMetricsExplorerDefaultQuery } from 'utils';

const columns = (
  onDelete: (name: string) => void,
  onOpen: (name: string) => void,
) => [
  { label: 'Name', key: 'name' },
  { label: 'LogQL', key: 'log_ql_expr' },
  {
    label: 'Actions',
    key: 'actions',
    renderCell: ({ row }: { row: { name: string; type: string } }) => {
      return (
        <div className="alerts__contacts__table__actions">
          <TooltipTrigger
            className="table__cell__actions__item--blue"
            tooltip="Open in metric explorer"
          >
            <BiLinkExternal
              className="alerts__contacts__table__actions__icon--edit"
              onClick={() => onOpen(row.name)}
              size={18}
            />
          </TooltipTrigger>
          <TooltipTrigger
            className="table__cell__actions__item--red"
            tooltip="Delete"
          >
            <MdDelete
              className="alerts__contacts__table__actions__icon--delete"
              onClick={() => onDelete(row.name)}
              size={18}
            />
          </TooltipTrigger>
        </div>
      );
    },
  },
];

const emptyArray = [];
const LogsDerivedMetrics = (): ReactElement => {
  const modal = useModalsContext();
  const { addToast } = useToaster();
  const savedMetricsRequest = useRequest(getLogsSavedMetrics);
  const deleteLogsMetricRequest = useRequest(deleteLogsMetric);

  const onDeleteMetric = (metricName: string) => {
    modal.push(
      <ConfirmationModal
        className="logs__analytics__saved-metrics__delete-modal"
        description={`Are you sure you want to delete saved metric?`}
        onCancel={() => modal.pop()}
        onConfirm={() => {
          deleteLogsMetricRequest
            .call(metricName)
            .then((deleteMetricResponse: any) => {
              if (deleteMetricResponse) {
                addToast({
                  status: 'success',
                  text: 'Saved logs metric deleted successfully.',
                });
                savedMetricsRequest.call();
              }
            });
          modal.pop();
        }}
        title="Delete Metric"
      />,
    );
  };

  const openInMetricsExplorer = (metricName: string) => {
    const defaultQuery = getMetricsExplorerDefaultQuery(metricName);
    const defaultQueryStr = encodeURIComponent(JSON.stringify([defaultQuery]));
    window.open(
      `${window.location.origin}/#/metrics?metricsQueries=${defaultQueryStr}`,
      '_blank',
    );
  };

  useEffect(() => {
    savedMetricsRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableSearch = useTableSearch({
    rows: savedMetricsRequest.result || emptyArray,
  });
  const tableSort = useTableSort({
    columns: columns(null, null),
    rows: tableSearch.searchedRows,
  });
  const paginator = usePaginator({ rows: tableSort.sortedRows });

  return (
    <div className="logs__analytics__saved-metrics">
      <div className="logs__analytics__saved-metrics__header">
        Logs Derived Metrics
      </div>
      <TableSearch
        className="mb-2 dashboard__list__search"
        placeholder="Search logs derived"
        tableSearch={tableSearch}
        dataTestId="derived_metrics_search"
      />
      <Loader
        isLoading={
          savedMetricsRequest.isLoading || deleteLogsMetricRequest.isLoading
        }
      >
        <Table
          className="table--bordered table--bordered-cells alerts__contacts__table"
          columns={columns(onDeleteMetric, openInMetricsExplorer)}
          rows={paginator.paginatedRows}
          externalTableSort={tableSort}
          isSortingEnabled
          dataTestId="derived_metrics_table"
        />
        <div className="table-footer">
          <Paginator paginator={paginator} />
        </div>
      </Loader>
    </div>
  );
};

export default LogsDerivedMetrics;
