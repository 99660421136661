import fetchGrafanaApi from './fetchGrafanaApi';

const getGrafanaAlertsRuleByGroup = (
  folderName?: string,
  groupName?: string,
) => {
  let url = `grafana/api/ruler/grafana/api/v1/rules`;
  if (folderName) {
    url += `/${folderName}`;
  }
  if (groupName) {
    url += `/${groupName}`;
  }
  url += `?subtype=cortex`;

  return fetchGrafanaApi(url, {
    headers: { 'Content-Type': 'application/json' },
    method: 'GET',
  }).then((result) => {
    return result;
  });
};

export default getGrafanaAlertsRuleByGroup;
