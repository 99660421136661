import { TableColumnType } from 'components';
import { useToggle } from 'hooks';
import { useState } from 'react';
import { queryRange } from 'requests';
import { DateSelection, SelectedFacetValuesByName } from 'types';
import { getInstantRateIntervalAndStep } from 'utils';
import { formatDataset, formatKpiAsEmpty } from './utils';
import ServerlessTableKpis, {
  ServerlessTableKpiKeys,
} from '../requests/serverlessTableKpis';

// This function creates a mapping of ServerlessTableKpisV2 array using the key as the property name
const serverlessTableKpiByKey = ServerlessTableKpis.reduce(
  (obj, kpi) => ({ ...obj, [kpi.key]: kpi }),
  {},
);

// This function generates an array of queries based on the columns, date, and selected facet values
const getQueries = (
  columns: TableColumnType[],
  date: DateSelection,
  selectedFacetValuesByName: SelectedFacetValuesByName,
) => {
  const { rateInterval, stepInMs } = getInstantRateIntervalAndStep({ date });
  return columns
    .map((column) => serverlessTableKpiByKey[column.key])
    .map((kpi) =>
      kpi.functionsQuery({ rateInterval, selectedFacetValuesByName, stepInMs }),
    );
};

// This is the main custom hook that handles the logic for fetching KPIs by service name
const useKpisByFunctionNameRequest = () => {
  const [lastRefreshedAt, setLastRefreshedAt] = useState<number>();
  const [state, setState] = useState({});
  const [isLoadingState, setIsLoadingState] = useState({});
  const isLoadingToggle = useToggle();

  // This function is called when the queries are done fetching data
  const onDone = () => {
    setLastRefreshedAt(new Date().valueOf());
    isLoadingToggle.off();
  };

  // This function runs a query and updates the state and loading state accordingly
  const runQuery = ({ date, key, query }) => {
    setIsLoadingState((prevLoadingState) => ({
      ...prevLoadingState,
      [key]: 1,
    }));

    return queryRange({ date, instant: true, query })
      .then(formatDataset(setState, key), formatKpiAsEmpty(setState, key))
      .finally(() => {
        setIsLoadingState((prevLoadingState) => ({
          ...prevLoadingState,
          [key]: 0,
        }));
      });
  };

  // This function is called to fetch KPIs by service name
  const call = async ({
    columns,
    date,
    selectedFacetValuesByName,
    selectedColumns,
  }) => {
    setState({});
    const columnsByKey = columns.reduce(
      (obj, column) => ({ ...obj, [column.key]: column }),
      {},
    );

    setState({});
    isLoadingToggle.on();

    // Filter the columns based on selectedColumns and serverlessTableKpiByKey
    const filteredColumns = [
      ServerlessTableKpiKeys.invocations,
      ServerlessTableKpiKeys.duration,
      ServerlessTableKpiKeys.errors,
    ].map((key) => columnsByKey[key]);

    const sequentialQueries = getQueries(
      filteredColumns,
      date,
      selectedFacetValuesByName,
    );

    // Set loading state for sequential columns
    setIsLoadingState((prevIsLoadingState) => ({
      ...prevIsLoadingState,
      ...filteredColumns.reduce(
        (obj, column) => ({
          ...obj,
          [column.key]: 1,
        }),
        {},
      ),
    }));

    // Run sequential queries one by one
    for (let i = 0; i < sequentialQueries.length; i += 1) {
      const query = sequentialQueries[i];
      await runQuery({ date, key: filteredColumns[i].key, query });
    }

    onDone();
  };

  // This function is called to fetch a single column by key
  const fetchSingleColumn = ({ date, key, selectedFacetValuesByName }) => {
    const kpi = ServerlessTableKpis.find((kpi) => kpi.key === key);
    if (kpi) {
      const { rateInterval, stepInMs } = getInstantRateIntervalAndStep({
        date,
      });
      const query = kpi.functionsQuery({
        rateInterval,
        selectedFacetValuesByName,
        stepInMs,
      });
      runQuery({ date, key: kpi.key, query });
    }
  };

  // Determine if the loading state is true for any column
  const isLoading = Boolean(
    Object.values(isLoadingState).filter((value) => value).length,
  );

  return {
    call,
    fetchSingleColumn,
    isLoading,
    isLoadingState,
    lastRefreshedAt,
    result: state,
  };
};

export default useKpisByFunctionNameRequest;
