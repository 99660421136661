import { QueryDataProps } from 'types/QueryData';
import { DataFrame } from '../types';

const eventsDataFrameToQuery = (dataFrames: DataFrame[]) => {
  const queryData: QueryDataProps = {};
  dataFrames.forEach((dataFrame) => {
    const { meta } = dataFrame;
    const refId = meta.refId;
    const isQuery = Number.isNaN(Number(refId));
    let queryId = '';
    if (isQuery) {
      queryId = `query_${refId}`;
    } else {
      queryId = `formula_${refId}`;
    }

    queryData[queryId] = dataFrame;
  });

  return queryData;
};

export default eventsDataFrameToQuery;
