import Icon from './base/Icon';
import React, { ReactNode } from 'react';
import { Alert as ShadcnAlert } from './shadcn';
import { AlertTitle as ShadcnAlertTitle } from './shadcn';
import { AlertDescription as ShadcnAlertDescription } from './shadcn';

type Props = {
  description: ReactNode;
  icon?: React.ComponentProps<typeof Icon>['icon'];
  title?: ReactNode;
};

const Alert = ({ description, icon, title }: Props) => {
  return (
    <ShadcnAlert>
      {icon ? <Icon icon={icon} /> : null}
      {title ? <ShadcnAlertTitle>{title}</ShadcnAlertTitle> : null}
      {description ? (
        <ShadcnAlertDescription>{description}</ShadcnAlertDescription>
      ) : null}
    </ShadcnAlert>
  );
};

export default Alert;
