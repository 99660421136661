import uPlot from 'uplot';

const findClosestSplit = (value: number, splits: number[]) => {
  for (let i = 0; i < splits.length; i++) {
    if (splits[i] >= value) {
      return splits[i];
    } else {
      continue;
    }
  }
  return splits[splits.length - 1];
};

const buildHeatmapPath = (opts: {
  disp: {
    fill: {
      lookup: string[];
      values: (u: uPlot, seriesIdx: number) => number[];
    };
  };
}) => {
  const { disp } = opts;

  return (u: uPlot, seriesIdx: number) => {
    uPlot.orient(
      u,
      seriesIdx,
      (
        series,
        dataX,
        dataY,
        scaleX,
        scaleY,
        valToPosX,
        valToPosY,
        xOff,
        yOff,
        xDim,
        yDim,
        moveTo,
        lineTo,
        rect,
        arc,
      ) => {
        const d = u.data[seriesIdx];
        const [xs, ys, counts] = d as unknown as number[][];
        const dlen = xs.length;

        const fills = disp.fill.values(u, seriesIdx);
        const fillPalette = disp.fill.lookup ?? [...new Set(fills)];
        const fillPaths = fillPalette.map(() => new Path2D());

        const { axes } = u;
        const xAxisSplits = axes[0]._splits;
        const yAxisSplits = axes[1]._splits;

        const { width, height } = u.bbox;
        const xSize = width / xAxisSplits.length;
        const ySize = height / yAxisSplits.length;

        for (let i = 0; i < dlen; i++) {
          // filter out 0 counts and out of view
          if (
            counts[i] > 0 &&
            xs[i] >= scaleX.min &&
            xs[i] <= scaleX.max &&
            ys[i] >= scaleY.min &&
            ys[i] <= scaleY.max
          ) {
            const closestXSplit = findClosestSplit(xs[i], xAxisSplits);
            const closestYSplit = findClosestSplit(ys[i], yAxisSplits);

            const xPos = valToPosX(closestXSplit, scaleX, xDim, xOff);
            const yPos = valToPosY(closestYSplit, scaleY, yDim, yOff);

            const fillPath = fillPaths[fills[i]];
            rect(fillPath, xPos, yPos, xSize, ySize);
          }
        }

        u.ctx.save();
        u.ctx.rect(u.bbox.left, u.bbox.top, u.bbox.width, u.bbox.height);
        u.ctx.clip();
        fillPaths.forEach((p: Path2D, i: number) => {
          u.ctx.fillStyle = fillPalette[i] as string;
          u.ctx.fill(p);
        });
        u.ctx.restore();
      },
    );
  };
};

export default buildHeatmapPath;
