import { Stepper } from 'components';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import AlertsCreateAPMCondition from './AlertsCreateAPMCondition';
import AlertsCreateAPMChart from './AlertsCreateAPMChart';
import AlertsCreateAPMService from './AlertsCreateAPMService';
import AlertsCreateAPMType from './AlertsCreateAPMType';
import {
  alertParseConditionValue,
  useAlertsCreateCondition,
} from '../AlertsCreateCondition';
import {
  AlertsCreateContacts,
  AlertsCreateDetails,
  CreateRuleButton,
} from '../components';
import { useAlertsCreate } from '../hooks';
import { useAlertsCreateAPM } from './hooks';
import { AlertType, RuleProps } from '../types';
import { getAnomalyAlertEditPromql } from '../AlertsCreateMetrics/utils';
import { apmChartUnit } from './utils';

const AlertsCreateAPM = ({
  baseWidth,
  alertsCreateState,
  conditionState,
}: {
  baseWidth: number;
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
}): ReactElement => {
  const location = useLocation();
  const rule = useMemo(() => location.state as RuleProps, [location.state]);

  const { alertType, date, evaluate, setUpdateAlertsRuleState, setAlertType } =
    alertsCreateState;
  const {
    anomalyCondition,
    condition,
    setUpdateConditionState,
    setAnomalyCondition,
    setCondition,
  } = conditionState;
  const alertsCreateAPMState = useAlertsCreateAPM({
    alertsCreateState,
    conditionState,
  });

  const { createAPMAlert, setApmAlertDetails, apmAlertDetails } =
    alertsCreateAPMState;

  useEffect(() => {
    if (rule) {
      const parsedExtraData = JSON.parse(rule.annotations.extraData || '{}');
      const newAlertType: AlertType =
        (rule.annotations?.alertType as AlertType) || AlertType.THRESHOLD;

      setApmAlertDetails((prev) => ({
        ...prev,
        triggerType: parsedExtraData?.apmTriggerType || prev.triggerType,
        serviceName: parsedExtraData?.serviceName || prev.serviceName,
        uniqueLabels: parsedExtraData?.uniqueLabels || prev.uniqueLabels,
        additionalLabels:
          parsedExtraData?.additionalLabels || prev.additionalLabels,
      }));
      if (newAlertType !== alertType) {
        setAlertType({ value: newAlertType });
      }

      const condition = alertParseConditionValue(rule.ruleData);
      if (rule.noData && condition) {
        condition.noData = rule.noData;
      }

      if (rule.executionError && condition) {
        condition.executionError = rule.executionError;
      }
      setUpdateAlertsRuleState(rule);
      setUpdateConditionState(condition);

      if (rule.annotations?.alertType === 'anomaly') {
        const { condition, anomalyCondition } = getAnomalyAlertEditPromql({
          data: rule.ruleData,
          annotations: rule.annotations,
          step: null,
        });
        if (anomalyCondition) {
          setAnomalyCondition(anomalyCondition);
        }
        setCondition((prev) => {
          const newCondition = { ...prev };
          newCondition.of = condition.of;
          return newCondition;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rule]);

  return (
    <div className="alerts__create__logs">
      <div className="alerts__create__logs__chart">
        <AlertsCreateAPMChart
          alertsCreateAPMState={alertsCreateAPMState}
          alertType={alertType}
          baseWidth={baseWidth}
          conditionState={conditionState}
          forWindow={evaluate.for}
          isEditing={alertsCreateState.isEditing}
          date={date}
        />
      </div>
      <Stepper
        steps={[
          {
            title: 'Choose the detection method',
            component: (
              <AlertsCreateAPMType
                alertType={alertType}
                setActiveDetection={(activeDetection: string) =>
                  setAlertType({ value: activeDetection })
                }
              />
            ),
          },
          {
            title: 'Pick a service and Trigger type',
            component: (
              <AlertsCreateAPMService
                alertsCreateAPMState={alertsCreateAPMState}
              />
            ),
          },
          {
            title: 'Set Condition',
            component: (
              <AlertsCreateAPMCondition
                alertsCreateState={alertsCreateState}
                unit={apmChartUnit[apmAlertDetails.triggerType] || 'number'}
                conditionState={conditionState}
              />
            ),
          },
          {
            title: 'Add Details',
            component: (
              <AlertsCreateDetails alertsCreateState={alertsCreateState} />
            ),
          },
          {
            title: 'Add Contacts',
            component: (
              <AlertsCreateContacts alertsCreateState={alertsCreateState} />
            ),
          },
        ]}
      />
      <CreateRuleButton
        isEditing={alertsCreateState.isEditing}
        onClick={() => createAPMAlert({ anomalyCondition, condition })}
      />
    </div>
  );
};

export default AlertsCreateAPM;
