import { EntityTypes } from '../types';

export const entityKeyMapping: { [key: string]: string } = {
  Pod: 'pod_name',
  Cluster: 'kube_cluster_name',
  Namespace: 'kube_namespace',
  Node: 'kube_node',
  Deployment: 'kube_deployment',
  ReplicaSet: 'kube_replica_set',
  Service: 'kube_service',
  PersistentVolumeClaim: 'persistentvolumeclaim',
  PersistentVolume: 'kube_persistent_volume',
  CronJob: 'kube_cron_job',
  DaemonSet: 'kube_daemon_set',
  StatefulSet: 'kube_stateful_set',
  Role: 'kube_role',
  RoleBinding: 'kube_role_binding',
  ClusterRole: 'kube_cluster_role',
  ClusterRoleBinding: 'kube_cluster_role_binding',
  ServiceAccount: 'kube_service_account',
  Ingress: 'kube_ingress',
  Job: 'kube_job',
};

export const entityMappedToGraphQL = (entityType: EntityTypes) => {
  // first letter to lower case
  const mappedEntity = EntityTypes[entityType];
  return mappedEntity.charAt(0).toLowerCase() + mappedEntity.slice(1);
};

export const entityTypesList: Array<{
  label: string;
  key?: EntityTypes;
  nestedLabels?: Array<{
    key: EntityTypes;
    label: string;
  }>;
}> = [
  { key: EntityTypes.Pod, label: 'Pods' },
  { key: EntityTypes.Cluster, label: 'Clusters' },
  { key: EntityTypes.Namespace, label: 'Namespaces' },
  { key: EntityTypes.Node, label: 'Nodes' },
  {
    label: 'Workloads',
    nestedLabels: [
      { key: EntityTypes.Deployment, label: 'Deployments' },
      { key: EntityTypes.ReplicaSet, label: 'Replica Sets' },
      { key: EntityTypes.Job, label: 'Jobs' },
      { key: EntityTypes.CronJob, label: 'Cron Jobs' },
      { key: EntityTypes.DaemonSet, label: 'Daemon Sets' },
      { key: EntityTypes.StatefulSet, label: 'Stateful Sets' },
    ],
  },
  {
    label: 'Network',
    nestedLabels: [
      { key: EntityTypes.Service, label: 'Services' },
      { key: EntityTypes.Ingress, label: 'Ingresses' },
    ],
  },
  {
    label: 'Storage',
    nestedLabels: [
      {
        key: EntityTypes.PersistentVolumeClaim,
        label: 'Persistent Volume Claims',
      },
      { key: EntityTypes.PersistentVolume, label: 'Persistent Volumes' },
    ],
  },
  {
    label: 'Access Control',
    nestedLabels: [
      { key: EntityTypes.Role, label: 'Roles' },
      { key: EntityTypes.RoleBinding, label: 'Role Bindings' },
      { key: EntityTypes.ClusterRole, label: 'Cluster Roles' },
      { key: EntityTypes.ClusterRoleBinding, label: 'Cluster Role Bindings' },
      { key: EntityTypes.ServiceAccount, label: 'Service Accounts' },
    ],
  },
];

export const entityMultipleKeyMapping: { [key: string]: any } = {
  Pod: ['kube_cluster_name'],
  Pod_Service: ['kube_cluster_name', 'kube_service', 'kube_namespace'],
  Cluster: ['kube_cluster_name'],
  Node: ['kube_cluster_name', 'kube_node'],
  Deployment: ['kube_cluster_name', 'kube_deployment', 'kube_namespace'],
  ReplicaSet: ['kube_cluster_name', 'kube_replica_set', 'kube_namespace'],
  CronJob: ['kube_cluster_name', 'kube_cron_job'],
  DaemonSet: ['kube_cluster_name', 'kube_daemon_set', 'kube_namespace'],
  StatefulSet: ['kube_cluster_name', 'kube_stateful_set', 'kube_namespace'],
  Service: ['kube_cluster_name', 'kube_service', 'kube_namespace'],
  Namespace: ['kube_cluster_name', 'kube_namespace'],
  Ingress: ['kube_cluster_name', 'kube_namespace', 'kube_service'],
  Job: ['kube_cluster_name', 'kube_job'],
  PersistentVolumeClaim: [
    'kube_cluster_name',
    'persistentvolumeclaim',
    'kube_namespace',
  ],
  PersistentVolume: [
    'kube_cluster_name',
    'kube_persistent_volume',
    'kube_namespace',
  ],
  Role: ['kube_cluster_name'],
  RoleBinding: ['kube_cluster_name'],
  ClusterRole: ['kube_cluster_name'],
  ClusterRoleBinding: ['kube_cluster_name'],
  ServiceAccount: ['kube_cluster_name'],
};
