import { uniqBy } from 'lodash';

const removeDuplicateByKeys = (
  items: Array<any>,
  keys: string[],
): Array<any> => {
  return uniqBy(items, (item) => keys.map((key) => item[key]).join('-'));
};

export default removeDuplicateByKeys;
