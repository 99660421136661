import { getLogsOperatorInQuery } from 'utils';
import { ConditionProps } from '../types';
import {
  AlertQuery,
  AlertQueryExpressionType,
  AlertQueryReducerType,
} from '../../types';

export const alertParseConditionValue = (
  data: AlertQuery[],
): ConditionProps => {
  const defaultCondition: ConditionProps = {
    of: 'gt',
    value: '',
    when: 'last',
  };

  if (data.length === 0) return defaultCondition;

  const reducerCondition = getConditionValueByType({
    conditionType: AlertQueryExpressionType.reduce,
    data,
  });
  const mathCondition = getConditionValueByType({
    conditionType: AlertQueryExpressionType.math,
    data,
  });
  const classicCondition = getConditionValueByType({
    conditionType: AlertQueryExpressionType.classic,
    data,
  });
  const thresholdCondition = getConditionValueByType({
    conditionType: AlertQueryExpressionType.threshold,
    data,
  });

  if (
    !reducerCondition &&
    !mathCondition &&
    !classicCondition &&
    !thresholdCondition
  ) {
    return defaultCondition;
  }

  const isClassicCondition = Boolean(classicCondition) && data.length === 2;
  if (isClassicCondition) {
    defaultCondition.of = classicCondition.of;
    defaultCondition.value = classicCondition.value;
    defaultCondition.when = classicCondition.when;
  }

  if (reducerCondition) {
    defaultCondition.when = reducerCondition.when;
    if (reducerCondition.of) {
      defaultCondition.of = reducerCondition.of;
    }
  }

  if (mathCondition) {
    if (mathCondition.of) {
      defaultCondition.of = mathCondition.of;
    }
    if (mathCondition.value) {
      defaultCondition.value = mathCondition.value;
    }
  }

  if (thresholdCondition) {
    if (thresholdCondition.of) {
      defaultCondition.of = thresholdCondition.of;
    }
    if (thresholdCondition.value) {
      defaultCondition.value = thresholdCondition.value;
    }
  }

  defaultCondition.rawValue = defaultCondition.value;

  return defaultCondition;
};

export const getConditionValueByType = ({
  conditionType,
  data,
}: {
  conditionType: AlertQueryExpressionType;
  data: AlertQuery[];
}): ConditionProps => {
  const condition = data.find((item) => item.model.type === conditionType);
  if (!condition) return undefined;

  const defaultCondition: ConditionProps = {
    of: 'gt',
    value: '',
    when: 'last',
  };

  const { model } = condition;
  const { conditions, expression, reducer } = model;
  const sanitizedReducer = reducer?.replace('()', '');
  if (sanitizedReducer) {
    defaultCondition.when = sanitizedReducer as AlertQueryReducerType;
  }

  conditions.forEach((condition) => {
    const { evaluator } = condition;
    const { params } = evaluator;
    const [value] = params;
    defaultCondition.of = evaluator.type;
    defaultCondition.value = value?.toString();
  });

  if (conditionType === AlertQueryExpressionType.math) {
    const expressionValue = parseMathExpressionConditionValue(expression);

    if (expressionValue && defaultCondition.value !== expressionValue) {
      defaultCondition.value = expressionValue;
    }
  }

  return defaultCondition;
};

/**
 * Parse the math expression condition value
 * @param expression string
 * @returns string
 * @example a > 1, b < 2, c = 3, d >= 4, e <= 5, f != 6, g == 7
 */
export const parseMathExpressionConditionValue = (
  expression: string,
): string => {
  const operator = getLogsOperatorInQuery(expression);

  if (!operator) return '';
  const splitExpression = expression.split(operator);
  const expressionValue = splitExpression[1] ? splitExpression[1].trim() : '';
  const isExpressionValueNaN = Number.isNaN(Number(expressionValue));

  if (expressionValue && !isExpressionValueNaN) {
    return expressionValue;
  }

  return '';
};
