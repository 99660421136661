import classnames from 'classnames';
import React, { ReactNode } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { AiOutlineLineChart } from 'react-icons/ai';
import { ImTable } from 'react-icons/im';
import { BsFilterLeft, BsPieChartFill } from 'react-icons/bs';
import { HiTableCells } from 'react-icons/hi2';
import { EventsTab } from 'screens/Events/types';

const iconByVisualizeAs: { [key: string]: ReactNode } = {
  [EventsTab.list]: <ImTable size={14} />,
  [EventsTab.timeseries]: <AiOutlineLineChart size={14} />,
  [EventsTab.toplist]: <BsFilterLeft size={14} />,
  [EventsTab.table]: <HiTableCells size={14} />,
  [EventsTab.piechart]: <BsPieChartFill size={14} />,
};

const labelByVisualizeAs: { [key: string]: string } = {
  [EventsTab.list]: 'List',
  [EventsTab.timeseries]: 'Time Series',
  [EventsTab.toplist]: 'Top List',
  [EventsTab.table]: 'Table',
  [EventsTab.piechart]: 'Pie Chart',
};

const SUPPORTED_VISUALIZE_AS = [
  EventsTab.list,
  EventsTab.timeseries,
  EventsTab.toplist,
  EventsTab.table,
  EventsTab.piechart,
];

const EventsTabs = () => {
  const [searchParams] = useSearchParams();
  const searchParamsString = searchParams.toString();

  return (
    <div className="button-group m-3">
      {SUPPORTED_VISUALIZE_AS.map((eventsTab) => (
        <NavLink
          className={({ isActive }) =>
            classnames({
              'button-group__item': true,
              'button-group__item--active': isActive,
            })
          }
          key={eventsTab}
          to={`/events/${eventsTab}${
            searchParamsString ? `?${searchParamsString}` : ''
          }`}
        >
          <div className="button-group__item__icon">
            {iconByVisualizeAs[eventsTab]}
          </div>
          {labelByVisualizeAs[eventsTab]}
        </NavLink>
      ))}
    </div>
  );
};

export default EventsTabs;
