import { DateFormatter } from 'components';
import { startCase } from 'lodash';
import React from 'react';
import { dateTimeFormatWithMilliseconds } from 'kfuse-constants';
import { formatDurationNs, formatFileSize, isNullOrUndefined } from 'utils';
import { RumResourceEvent } from '../types';
import isDurationFacet from '../utils/isDurationFacet';
import isSizeFacet from '../utils/isSizeFacet';
import sidebarFacets from '../requests/sidebarFacets';

const resourceColumns = sidebarFacets
  .filter(
    (facet) => facet.resource_facet && facet.field !== 'session.has_replay',
  )
  .map((facet) => {
    return {
      key: facet.field,
      label: facet.name,
    };
  })
  .sort((a, b) => a.label.localeCompare(b.label));

const RumResourceStringColumnKey = resourceColumns.reduce(
  (acc, column) => {
    acc[column.key] = column.label as string;
    return acc;
  },
  {} as Record<string, string>,
);

export const RumResourceColumnKey = {
  date: 'time',
  'application.id': 'Application ID',
  ...RumResourceStringColumnKey,
};

type RenderCellPropsOfResource = {
  row: RumResourceEvent;
  value: any;
};

export const rumDynamicResourceTableColumns = () => [
  {
    key: RumResourceColumnKey.date,
    label: 'Date',
    renderCell: ({ row, value }: RenderCellPropsOfResource) => {
      return (
        <DateFormatter
          formatString={dateTimeFormatWithMilliseconds}
          unixTimestamp={value}
        />
      );
    },
  },
  ...Object.keys(RumResourceStringColumnKey).map((key) => ({
    key,
    label: startCase(
      RumResourceStringColumnKey[
        key as keyof typeof RumResourceStringColumnKey
      ],
    ),
    value: ({ row }: RenderCellPropsOfResource) => {
      const isDurationColumn = isDurationFacet(key);
      const value = row.data[key as keyof typeof row.data];
      if (isDurationColumn) {
        const convertedValue = Number(value);
        return isNaN(convertedValue) || isNullOrUndefined(convertedValue)
          ? '-'
          : formatDurationNs(convertedValue, 1, 2);
      }
      const isSizeColumn = isSizeFacet(key);
      if (isSizeColumn) {
        return formatFileSize(value as string);
      }
      return value;
    },
  })),
];
