import { fetchGrafanaApi } from 'requests';

const updateFolderPermissionById = async ({
  folderUid,
  id,
  permission,
  type,
}: {
  folderUid: string;
  id: number | string;
  permission: string;
  type: 'users' | 'teams';
}): Promise<Record<string, unknown>> => {
  const options = { method: 'POST', body: JSON.stringify({ permission }) };
  const result = await fetchGrafanaApi(
    `grafana/api/access-control/folders/${folderUid}/${type}/${id}`,
    options,
  );

  return result;
};

export default updateFolderPermissionById;
