import { useToggle } from 'hooks';
import * as Shadcn from '../shadcn';
import React from 'react';

type Props = {
  isOpenToggle: ReturnType<typeof useToggle>;
  renderContent: () => JSX.Element | JSX.Element[];
};

const AccordionContent = ({ isOpenToggle, renderContent }: Props) => {
  return (
    <Shadcn.AccordionContent asChild className="accordion__content">
      {isOpenToggle.value ? renderContent() : null}
    </Shadcn.AccordionContent>
  );
};

export default AccordionContent;
