import { AutocompleteV2, Button } from 'components';
import { useRequest } from 'hooks';
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
} from 'react';
import { MdClose as CloseIcon } from 'react-icons/md';

import { getGroupList } from '../GroupManagement/requests';
import { getPolicyList } from '../PolicyManagement/requests';
import { PolicyConfigProps } from './types';

const PolicyConfigManagementAdd = ({
  createPolicyToGroupRequest,
  onSavePolicyConfig,
  policyConfig,
  setPolicyConfig,
}: {
  createPolicyToGroupRequest: ReturnType<typeof useRequest>;
  onSavePolicyConfig: () => void;
  policyConfig: PolicyConfigProps;
  setPolicyConfig: Dispatch<SetStateAction<PolicyConfigProps>>;
}): ReactElement => {
  const policyListRequest = useRequest(() =>
    getPolicyList().then((res) =>
      res.map((p) => ({ label: p.name, value: p.name })),
    ),
  );
  const groupListRequest = useRequest(() =>
    getGroupList().then((res) =>
      res.map((g) => ({ label: g.name, value: g.name })),
    ),
  );

  useEffect(() => {
    policyListRequest.call();
    groupListRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading =
    policyListRequest.isLoading ||
    groupListRequest.isLoading ||
    createPolicyToGroupRequest.isLoading;

  return (
    <div>
      <div className="admin__row-muted mb-4 flex items-center justify-between rounded-md px-2 py-4">
        {isLoading ? (
          <div className="flex w-full items-center justify-center">
            <div className="spinner"></div>
          </div>
        ) : (
          <>
            <div className="flex items-center gap-2">
              <label className="flex flex-col gap-1">
                <span>
                  Policy (<span className="text-red-500">*</span>)
                </span>
                <AutocompleteV2
                  className="max-h-[32px] w-[200px]"
                  options={policyListRequest.result}
                  onChange={(val: string) =>
                    setPolicyConfig((prev) => ({ ...prev, policy: val }))
                  }
                  value={policyConfig.policy}
                  placeholder="Select Policy"
                />
              </label>

              <label className="flex flex-col gap-1">
                <span>
                  Group (<span className="text-red-500">*</span>)
                </span>
                <AutocompleteV2
                  className="max-h-[32px] w-[200px]"
                  options={groupListRequest.result}
                  onChange={(val: string) =>
                    setPolicyConfig((prev) => ({ ...prev, group: val }))
                  }
                  value={policyConfig.group}
                  placeholder="Select Group"
                />
              </label>
              <div className="flex self-end">
                <Button
                  className="h-8"
                  variant="default"
                  size="sm"
                  onClick={onSavePolicyConfig}
                >
                  Save
                </Button>
              </div>
            </div>
            <div>
              <Button
                variant="ghost-destructive"
                size="icon"
                onClick={() => setPolicyConfig(null)}
              >
                <CloseIcon size={16} />
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PolicyConfigManagementAdd;
