import { useSearches } from 'hooks/useSearch';
import React, { ReactElement } from 'react';

import EventsSearchQueryButtons from './EventsSearchQueryButtons';
import EventsQuerySearch from './EventsQuerySearch';
import { useEventsState } from '../hooks';
import { EventsTab } from '../types';

const EventsQuerySearchAnalytics = ({
  searches,
  eventsState,
  tab,
}: {
  searches: ReturnType<typeof useSearches>;
  eventsState: ReturnType<typeof useEventsState>;
  tab: EventsTab;
}): ReactElement => {
  const { filterState } = eventsState;
  return (
    <>
      {searches.map((search, i) => (
        <>
          <EventsQuerySearch
            index={i}
            key={i}
            search={search}
            searches={searches}
            eventsTab={tab}
            eventsState={eventsState}
            filterState={filterState}
          />
        </>
      ))}
      <EventsSearchQueryButtons searches={searches} eventsTab={tab} />
    </>
  );
};

export default EventsQuerySearchAnalytics;
