import React, { useMemo } from 'react';
import { MAX_SPANS_TO_RENDER } from 'components/TraceSidebar/constants';
import { ListLazyLoader } from 'components';
import RumFlameGraphChartRow from './RumFlameGraphChartRow';
import { formatDurationNs } from 'utils/timeNs';
import { useDebounce } from 'use-debounce';
import { EventById, FormattedRumEventForFlameGraph } from './types';

type Props = {
  elementRef?: React.RefObject<HTMLDivElement>;
  getColor: (event: FormattedRumEventForFlameGraph) => string;
  hoveredEventId?: string;
  minPresentationalDuration: number;
  minStartTimeNs: number;
  maxEndTimeNs: number;
  scrollLeft?: number;
  setClickedEventId?: (eventId: string) => void;
  setHoveredEventId?: (eventId: string) => void;
  eventById: EventById;
  flameGraphRows: string[][];
  width: number;
  niceUpperBound: number;
};

const RumFlameGraphRows = ({
  elementRef,
  getColor,
  hoveredEventId,
  minPresentationalDuration,
  minStartTimeNs,
  maxEndTimeNs,
  scrollLeft,
  setClickedEventId,
  setHoveredEventId,
  eventById,
  flameGraphRows,
  width,
  niceUpperBound,
}: Props) => {
  const onClickHandler = (eventId: string) => () => {
    if (setClickedEventId) {
      setClickedEventId(eventId);
    }
  };

  const [debouncedHover] = useDebounce(setHoveredEventId, 300);

  const onMouseEnterHandler = (eventId: string) => {
    if (setHoveredEventId) {
      debouncedHover(eventId);
    }
  };

  const onMouseLeave = () => {
    setHoveredEventId(null);
  };

  const virtualizedContainerHeight = elementRef?.current?.clientHeight
    ? elementRef.current.clientHeight - 20
    : null;

  const sortedFlameGraphRows = useMemo(
    () =>
      flameGraphRows.sort((a, b) => {
        const aStartTime = eventById[a[0]].startTimeNs;
        const bStartTime = eventById[b[0]].startTimeNs;
        return aStartTime - bStartTime;
      }),
    [eventById, flameGraphRows],
  );

  return (
    <div className="flamegraph__rows">
      <ListLazyLoader
        containerHeight={virtualizedContainerHeight}
        virtualizationOffset={MAX_SPANS_TO_RENDER}
      >
        {sortedFlameGraphRows.map((flameGraphRow, i) => (
          <div
            className="flex cursor-pointer"
            key={i}
            onMouseEnter={() => onMouseEnterHandler(flameGraphRow[0])}
            onMouseLeave={onMouseLeave}
          >
            <div className="rum-flamegraph__table__item">
              <div className="rum-flamegraph__table__item__label">
                <div
                  style={{
                    borderLeft: `4px solid ${getColor(eventById[flameGraphRow[0]])}`,
                  }}
                  className="rum-flamegraph__table__item__label--with-border"
                >
                  {eventById[flameGraphRow[0]].name}
                </div>
              </div>
              <div className="rum-flamegraph__table__item__label rum-flamegraph__table__item__label--fixed">
                {eventById[flameGraphRow[0]].size
                  ? eventById[flameGraphRow[0]].size
                  : ''}
              </div>
              <div className="rum-flamegraph__table__item__label rum-flamegraph__table__item__label--fixed">
                {eventById[flameGraphRow[0]].duration
                  ? formatDurationNs(eventById[flameGraphRow[0]].duration, 1, 2)
                  : ''}
              </div>
            </div>

            <div className="flamegraph__row" key={i}>
              {flameGraphRow.map((eventId) => (
                <RumFlameGraphChartRow
                  getColor={getColor}
                  hoveredEventId={hoveredEventId}
                  key={eventId}
                  minPresentationalDuration={minPresentationalDuration}
                  minStartTimeNs={minStartTimeNs}
                  maxEndTimeNs={maxEndTimeNs}
                  onClick={onClickHandler(eventId)}
                  scrollLeft={scrollLeft}
                  event={eventById[eventId]}
                  eventId={eventId}
                  width={width}
                  niceUpperBound={niceUpperBound}
                />
              ))}
            </div>
          </div>
        ))}
      </ListLazyLoader>
    </div>
  );
};

export default RumFlameGraphRows;
