import {
  useTableSearch,
  useTableSort,
  usePaginator,
  TableSearch,
  Table,
  Paginator,
  TooltipTrigger,
  Loader,
  Button,
  ConfirmationModal,
  useModalsContext,
  Badge,
  ErrorMessage,
} from 'components';
import { useRequest } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdDelete as DeleteIcon, MdModeEdit as EditIcon } from 'react-icons/md';

import { deletePolicyByName, getPolicyList } from './requests';
import { PolicyProps } from './types';
import { getColorRandom } from 'utils/colors';
import { userManagementError } from 'utils/error/userManagementError';

const policyColumns = (onDeletePolicy: (policy: PolicyProps) => void) => [
  { label: 'Policy Name', key: 'name' },
  {
    label: 'Type',
    key: 'type',
    renderCell: ({ row }: { row: PolicyProps }) => row.scope?.type,
  },
  {
    label: 'Scope',
    key: 'scope',
    renderCell: ({ row }: { row: PolicyProps }) => {
      if (!row.scope?.filters) return;
      return (
        <div className="flex max-w-[600px] flex-wrap">
          {row.scope.filters.map((filter) => {
            const { key, op, value } = filter;
            return (
              <Badge
                key={`${key}-${op}-${value}`}
                className="py-0.25 mb-1 mr-1 truncate rounded-sm px-1 text-xs font-medium text-white"
                variant="outline"
                style={{ backgroundColor: getColorRandom() }}
              >
                <div className="flex items-center gap-1">
                  <span>{key}</span>
                  <span>{op}</span>
                  <span>{value}</span>
                </div>
              </Badge>
            );
          })}
        </div>
      );
    },
  },
  {
    label: '',
    key: 'actions',
    renderCell: ({ row }: { row: PolicyProps }) => {
      return (
        <div
          className="table__row__actions"
          style={{ '--table-actions-width': '74px' }}
        >
          <div className="table__row__actions--hidden">
            <div className="table__row__actions__slider">
              <div className="alerts__contacts__table__actions">
                <div className="table__cell__actions__item--blue">
                  <Link
                    className="link"
                    to={`/admin/policies/${row.policy_id}`}
                  >
                    <TooltipTrigger tooltip="Edit">
                      <EditIcon
                        className="alerts__contacts__table__actions__icon--edit"
                        size={18}
                      />
                    </TooltipTrigger>
                  </Link>
                </div>
                <div
                  className="table__cell__actions__item--red"
                  onClick={() => onDeletePolicy(row)}
                >
                  <TooltipTrigger tooltip="Delete">
                    <DeleteIcon
                      className="alerts__contacts__table__actions__icon--delete"
                      size={18}
                    />
                  </TooltipTrigger>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
];

const emptyArr: any[] = [];
const PolicyManagement = () => {
  const [error, setError] = useState({
    getPolicyList: null,
  });
  const modal = useModalsContext();
  const policyListRequest = useRequest(getPolicyList, true, true);
  const deletePolicyRequest = useRequest(deletePolicyByName);

  useEffect(() => {
    policyListRequest
      .call()
      .then((nextResult) => {
        if (nextResult) {
          setError((prevError) => ({ ...prevError, getPolicyList: null }));
        }
      })
      .catch(() => {
        setError((prevError) => ({
          ...prevError,
          getPolicyList: { message: userManagementError.getPolicyList },
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeletePolicy = (row: PolicyProps) => {
    modal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description={
          <span>
            Are you sure you want to delete{' '}
            <span className="font-bold">{row.name}</span> policy? This action
            cannot be undone.
          </span>
        }
        dataTestId="delete-group-confirmation-modal"
        onCancel={() => modal.pop()}
        onConfirm={() => {
          deletePolicyRequest.call(row.name).then(() => {
            modal.pop();
            policyListRequest.call();
          });
        }}
        title="Delete Policy"
      />,
    );
  };

  const columns = policyColumns(onDeletePolicy);
  const policies = policyListRequest.result || emptyArr;
  const tableSearch = useTableSearch({ rows: policies });
  const tableSort = useTableSort({ columns, rows: tableSearch.searchedRows });
  const paginator = usePaginator({
    rows: tableSort.sortedRows,
    initialNumberOfRowsPerPage: 25,
  });

  return (
    <div className="mx-auto max-w-[1200px] py-4">
      <div className="flex items-center justify-between pb-2">
        <h2 className="text-lg font-semibold">Policy Management</h2>

        <div className="flex items-center gap-[4px]">
          <ErrorMessage
            message={error?.getPolicyList?.message}
            className="justify-end pr-[14px]"
          />

          <Link to="/admin/policies/new?new=true">
            <Button variant="default" size="sm">
              Add New Policy
            </Button>
          </Link>
        </div>
      </div>
      <Loader isLoading={policyListRequest.isLoading}>
        <TableSearch
          className="dashboard__list__search"
          placeholder="Search Policy..."
          tableSearch={tableSearch}
          dataTestId="dashboard-list-search"
        />
        <Table
          className="table--bordered table--bordered-cells table__actions--hidden alerts__list__table"
          columns={columns}
          dataTestId="alerts-list-table"
          externalTableSort={tableSort}
          isSortingEnabled
          rows={paginator.paginatedRows}
        />
        <div className="table-footer">
          <Paginator paginator={paginator} />
        </div>
      </Loader>
    </div>
  );
};

export default PolicyManagement;
