import {
  delimiter,
  IGNORE_ANOMALY_UPPER_BAND_LABEL,
  IGNORE_ANOMALY_LOWER_BAND_LABEL,
} from 'kfuse-constants';

const getAnomalyBandIndexes = (
  label: string,
  series: uPlot.Series[],
):
  | {
      lowerBandIndex: number;
      upperBandIndex: number;
      totalAnomalySeries: number;
    }
  | undefined => {
  let lowerBandIndex: number | undefined = undefined;
  let upperBandIndex: number | undefined = undefined;
  let totalAnomalySeries = 0;

  const labelUpperBand = `${IGNORE_ANOMALY_UPPER_BAND_LABEL}${delimiter}${label}`;
  const labelLowerBand = `${IGNORE_ANOMALY_LOWER_BAND_LABEL}${delimiter}${label}`;
  series.forEach((s, idx) => {
    if (s.label === labelLowerBand) {
      lowerBandIndex = idx;
    }
    if (s.label === labelUpperBand) {
      upperBandIndex = idx;
    }
    if (s.label?.startsWith(IGNORE_ANOMALY_UPPER_BAND_LABEL)) {
      totalAnomalySeries += 1;
    }
  });

  if (lowerBandIndex === undefined || upperBandIndex === undefined) {
    return undefined;
  }

  return { lowerBandIndex, upperBandIndex, totalAnomalySeries };
};

export default getAnomalyBandIndexes;
