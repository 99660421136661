import { ServiceTableKpiKeys } from 'kfuse-constants/serviceTableKpis';
import { advancedServiceMonitoringFilter } from 'screens/Header/utils';
import { PrometheusDataset } from 'types';
import { getIsAsmChecked } from 'utils';

export const formatDataset =
  (setState, key) =>
  (result: PrometheusDataset[], ignoreResult: boolean): void => {
    if (ignoreResult) {
      return;
    }
    setState((prevState) => {
      const newState = { ...prevState };

      result.forEach((dataset) => {
        const { metric, value } = dataset;
        const { service_name, service_hash } = metric;

        const kpis = newState[service_hash]
          ? { ...newState[service_hash] }
          : {};
        kpis[key] =
          key === ServiceTableKpiKeys.apdex && isNaN(value[1] as number)
            ? 1
            : Number(value[1]);

        kpis.serviceName = service_name;
        newState[service_hash] = kpis;
      });
      return newState;
    });
  };

export const formatKpiAsEmpty = (setState, key) => (ignoreResult: boolean) => {
  if (ignoreResult) {
    return;
  }
  setState((prevState) => {
    return Object.keys(prevState).reduce((obj, serviceHash) => {
      const nextKpis = { ...prevState[serviceHash] };
      delete nextKpis[key];

      return {
        ...obj,
        [serviceHash]: nextKpis,
      };
    }, {});
  });
};

export enum ServicesTab {
  serviceMap = 'Service Map',
  table = 'Services',
  db = 'databases',
}

export const getActiveTab = (location: any) => {
  if (!location?.pathname) {
    return ServicesTab.table;
  }
  if (location.pathname.endsWith('/service-map')) {
    return ServicesTab.serviceMap;
  } else if (location.pathname.endsWith('/databases')) {
    return ServicesTab.db;
  }
  return ServicesTab.table;
};

export const getSpanTypeFilter = (
  serviceTab: ServicesTab,
): { filter: string; operator: string } | undefined => {
  return serviceTab
    ? {
        filter: 'db',
        operator: serviceTab === ServicesTab.db ? '=' : '!=',
      }
    : undefined;
};

export const getASMFilterFromURLString = (queryString: string): string => {
  if (!queryString) return '';
  try {
    const params = new URLSearchParams(queryString);
    const encodedJson = params.get('selectedFacetValuesByName');
    const decodedJson = decodeURIComponent(encodedJson);
    const selectedFacetValuesByName = JSON.parse(decodedJson);

    const isAsmChecked = getIsAsmChecked(selectedFacetValuesByName);
    if (!isAsmChecked) return '';

    return `&${advancedServiceMonitoringFilter}`;
  } catch (e) {
    return '';
  }
};

export const latencyPercentile = {
  [ServiceTableKpiKeys.p50latency]: 0.5,
  [ServiceTableKpiKeys.p75latency]: 0.75,
  [ServiceTableKpiKeys.p90latency]: 0.9,
  [ServiceTableKpiKeys.p95latency]: 0.95,
  [ServiceTableKpiKeys.p99latency]: 0.99,
};

export const getBoundedValueForLatency = ({
  kpiKey,
  maxValue,
  minValue,
  value,
}: {
  kpiKey: string;
  maxValue: number;
  minValue: number;
  value: number;
}): number => {
  if (kpiKey in latencyPercentile && maxValue < 5) {
    return (
      minValue +
      latencyPercentile[kpiKey as keyof typeof latencyPercentile] *
        (maxValue - minValue)
    );
  }

  return value;
};
