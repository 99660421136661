import {
  Button,
  PopoverPosition,
  PopoverTriggerV2,
  TooltipTrigger,
} from 'components';
import React, { Dispatch, ReactElement, SetStateAction, useMemo } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import { FiTarget } from 'react-icons/fi';
import { DateSelection, RequestResult } from 'types';

import { RuleProps } from '../../NewAlerts/types';
import useAdvanceFunctionAlertState from './useAdvanceFunctionAlertState';
import ServiceAlertsStatusList from './ServiceAlertsStatusList';

const ServiceAdvanceFunctions = ({
  asmMatcher,
  date,
  kfSource,
  serviceName,
  setActiveSmartAlert,
  grafanaFoldersRequest,
  dataTestId,
}: {
  asmMatcher: string;
  date: DateSelection;
  getGraphanaAlertsRules: () => void;
  kfSource?: string;
  serviceName: string;
  setActiveSmartAlert: Dispatch<SetStateAction<RuleProps>>;
  grafanaFoldersRequest: RequestResult<any, any>;
  dataTestId: string;
}): ReactElement => {
  const advanceFunctionAlertState = useAdvanceFunctionAlertState({
    asmMatcher,
    date,
    kfSource,
    serviceName,
    grafanaFoldersRequest,
  });
  const {
    asmServiceAlertsRules,
    enableDisableSmartAlert,
    isEnabled,
    isLoading,
    getAsmAutoAlertRequest,
  } = advanceFunctionAlertState;

  const isAlerting =
    asmServiceAlertsRules && asmServiceAlertsRules.alerting.length > 0;

  const asmStatusColor = useMemo(() => {
    if (!isEnabled) return '';
    if (isAlerting) return `var(--ui-status-danger)`;
    return `var(--ui-status-success-contrast)`;
  }, [isEnabled, isAlerting]);

  const onViewAllAlerts = () => {
    const filterURI = encodeURI(
      `selectedFacetValuesByName={"folder":{"${
        getAsmAutoAlertRequest.result?.folder || 'kfuse-asm'
      }":1}}`,
    );
    window.open(`#/alerts?${filterURI}&tableSearch="${serviceName}"`, '_blank');
  };

  return (
    <div
      className="service__header__left__advance-functions"
      data-testid={dataTestId}
    >
      <TooltipTrigger
        className="mr-1"
        tooltip={
          !isEnabled ? 'ASM not enabled' : isAlerting ? 'Alerting' : 'OK'
        }
      >
        <FiTarget
          color={asmStatusColor}
          size={14}
          title="Enable Advance Functions"
        />
      </TooltipTrigger>
      <div className="service__header__left__slo-info__title">
        <div>ASM</div>
      </div>
      {isLoading ? (
        <div className="spinner" />
      ) : (
        <>
          <PopoverTriggerV2
            popover={({ close }) => (
              <ServiceAlertsStatusList
                isASM={true}
                onRuleClick={(rule) => {
                  setActiveSmartAlert({ ...rule, isASMAlert: true });
                  close();
                }}
                rules={[
                  ...asmServiceAlertsRules.alerting,
                  ...asmServiceAlertsRules.ok,
                ]}
                onViewAllAlerts={onViewAllAlerts}
              />
            )}
            offsetY={2}
            offsetX={-70}
            position={PopoverPosition.BOTTOM_LEFT}
          >
            <div className="flex items-center">
              {asmServiceAlertsRules && asmServiceAlertsRules.ok.length > 0 && (
                <div
                  className="mr-2 cursor-pointer"
                  style={{ color: `var(--ui-status-success-contrast)` }}
                >
                  {asmServiceAlertsRules.ok.length} OK
                </div>
              )}
              {asmServiceAlertsRules &&
                asmServiceAlertsRules.alerting.length > 0 && (
                  <div
                    className="flex items-center"
                    style={{ color: `var(--ui-status-danger)` }}
                  >
                    {`${asmServiceAlertsRules.alerting.length} alerting`}
                  </div>
                )}
            </div>
          </PopoverTriggerV2>
          <PopoverTriggerV2
            popover={({ close }) => {
              return (
                <Button
                  onClick={() => {
                    enableDisableSmartAlert();
                    close();
                  }}
                  variant={isEnabled ? 'destructive' : 'default'}
                  size="sm"
                >
                  {isEnabled ? 'Disable' : 'Enable'} Advance Service Monitoring
                </Button>
              );
            }}
            position={PopoverPosition.BOTTOM_LEFT}
            offsetX={20}
          >
            <TooltipTrigger
              className="service__header__left__advance-functions__menu"
              tooltip={'ASM Menu'}
            >
              <HiDotsVertical />
            </TooltipTrigger>
          </PopoverTriggerV2>
        </>
      )}
    </div>
  );
};

export default ServiceAdvanceFunctions;
