import React from 'react';
import { cn } from 'utils/tailwind';
import { FocusedElementInfo } from '.';

const RelativeContainer = ({
  children,
  focusedElementInfo,
  initializerRef,
  className,
}: {
  children: React.ReactNode;
  focusedElementInfo: FocusedElementInfo;
  initializerRef: React.MutableRefObject<HTMLInputElement>;
  className?: string;
}): React.ReactElement => {
  return (
    <div
      className={cn('group relative flex min-h-[36px] w-full min-w-0', {
        'bg-searchbar-background-focus': focusedElementInfo !== null,
      })}
      onClick={() => {
        if (!focusedElementInfo) {
          initializerRef.current?.focus();
        }
      }}
    >
      <div
        className={cn(
          'flex w-full px-1 pb-[3px] pt-1',
          'border border-form-border',
          'focus-within:border-interaction-primary',
          'hover:border-form-border-hover',
          'hover:focus-within:border-interaction-primary',
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default RelativeContainer;
