import { useRumPageStateContext } from 'context';
import React from 'react';
import RumPerformance from './RumPerformance';
import { RumPerformanceSubtab } from './types';
import { useParams } from 'react-router-dom';

const RumErrorsGridContainer = () => {
  const { tab } = useParams();
  const rumPageState = useRumPageStateContext();

  return (
    <RumPerformance
      rumPageState={rumPageState}
      rumTab={(tab as RumPerformanceSubtab) || RumPerformanceSubtab.errors}
    />
  );
};

export default RumErrorsGridContainer;
