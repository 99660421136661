import dayjs from 'dayjs';
import {
  DateSelection,
  FacetRegexTerm,
  SelectedFacetValuesByName,
} from 'types';
import { onPromiseError } from 'utils';
import queryRumService from './queryRumService';
import { RumEventType } from '../types';
import { buildRumFilter } from './utils';

const valueRange = async ({
  applicationFilter,
  date,
  eventType,
  facetRegex,
  idSearch,
  labelName,
  selectedFacetValuesByName,
}: {
  applicationFilter: string;
  date: DateSelection;
  eventType: RumEventType;
  facetRegex?: FacetRegexTerm[];
  idSearch?: string;
  labelName: string;
  selectedFacetValuesByName: SelectedFacetValuesByName;
}): Promise<{
  min: number;
  max: number;
}> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  return queryRumService<
    {
      min: number;
      max: number;
    },
    'valueRange'
  >(`
  query {
    valueRange(
      eventType: ${eventType},
      timestamp: "${endTime.format()}",
      durationSecs: ${durationSecs},
      filter: ${buildRumFilter({ applicationFilter, facetRegex, idSearch, selectedFacetValuesByName })},
      field: "${labelName}"
    ) {
      min,
      max,
    }
  } 
  `).then((data) => data?.valueRange || null, onPromiseError);
};

export default valueRange;
