import { useUrlState } from 'hooks';
import { useState } from 'react';

type Options = {
  initalState?: State;
  shouldWriteToUrl?: boolean;
};

type State = {
  [key: string]: {
    isLowerInclusive?: boolean;
    isUpperInclusive?: boolean;
    lower: number;
    upper: number;
  };
};

const useSelectedFacetRangeByNameState = (options?: Options) => {
  const shouldWriteToUrl = options ? options.shouldWriteToUrl : true;
  const [regularState, setRegularState] = useState<State>(
    options?.initalState || {},
  );
  const [urlState, setUrlState] = useUrlState(
    'selectedFacetRangeByName',
    options?.initalState || {},
  );

  const state = shouldWriteToUrl ? urlState : regularState;
  const setState = shouldWriteToUrl ? setUrlState : setRegularState;

  const changeFacetRange =
    ({ name }: { name: string }) =>
    (nextRange) => {
      setState((prevState) => {
        const nextState = { ...prevState };
        nextState[name] = nextRange;
        return nextState;
      });
    };

  const clearFacet = (name: string) => {
    setState((prevState) => {
      const nextState = { ...prevState };
      delete nextState[name];
      return nextState;
    });
  };

  return {
    changeFacetRange,
    clearFacet,
    state,
  };
};

export default useSelectedFacetRangeByNameState;
