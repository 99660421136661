import { FunctionNamesProps } from 'types/MetricsQueryBuilder';

const logsFunctionNames: FunctionNamesProps[] = [
  {
    name: 'Anomalies',
    category: 'Algorithms',
    description:
      'Overlay a band on the metric showing the expected behavior of a series based on past.',
    expression: 'anomalies()',
    shortName: 'anomalies',
    vectorType: 'range',
    algorithm: 'Algorithm: method used to detect anomalies',
    tolerance: 'Bounds: width under which anomolies will be observed',
    imageUrl: '',
  },
  {
    name: 'Outliers',
    category: 'Algorithms',
    description: 'Highlight outliers series.',
    expression: 'outliers()',
    shortName: 'outliers',
    vectorType: 'range',
    algorithm: 'Algorithm: algorithm used to detect outliers',
    tolerance: 'Tolerance: tolerance of outliers algorithm',
    imageUrl: '',
  },
  {
    name: 'Forecast',
    category: 'Algorithms',
    description: 'Forecast future values based on past values.',
    expression: 'forecast()',
    shortName: 'forecast',
    vectorType: 'range',
    algorithm: 'Algorithm: methodology used to forecast the metric',
    imageUrl: '',
  },
  {
    name: 'Absolute Value',
    category: 'Arithmetic',
    description: 'Absolute value of the metric.',
    expression: 'abs()',
    shortName: 'abs',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Acos',
    category: 'Trigonometric',
    description: 'Calculates the arccosine of all elements in v.',
    expression: 'acos()',
    shortName: 'acos',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Exponential',
    category: 'Arithmetic',
    description: 'Calculates the exponential function for all elements in v.',
    expression: 'exp()',
    shortName: 'exp',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Tan',
    category: 'Trigonometric',
    description: 'Calculates the tangent of all elements in v.',
    expression: 'tan()',
    shortName: 'tan',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Tanh',
    category: 'Trigonometric',
    description: 'Calculates the hyperbolic tangent of all elements in v.',
    expression: 'tanh()',
    shortName: 'tanh',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Floor',
    category: 'Arithmetic',
    description:
      'Rounds the sample values of all elements in v down to the nearest integer.',
    expression: 'floor()',
    shortName: 'floor',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Cube Root',
    category: 'Arithmetic',
    description: 'Calculates the cube root of all elements in v.',
    expression: 'cbrt()',
    shortName: 'cbrt',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Expm1',
    category: 'Arithmetic',
    description:
      'Calculates exp(x) - 1, compensating for the roundoff in exp(x).',
    expression: 'expm1()',
    shortName: 'expm1',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Square Root',
    category: 'Arithmetic',
    description: 'Calculates the square root of all elements in v.',
    expression: 'sqrt()',
    shortName: 'sqrt',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Sin',
    category: 'Trigonometric',
    description: 'Calculates the sine of all elements in v.',
    expression: 'sin()',
    shortName: 'sin',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Sinh',
    category: 'Trigonometric',
    description: 'Calculates the hyperbolic sine of all elements in v.',
    expression: 'sinh()',
    shortName: 'sinh',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Round',
    category: 'Arithmetic',
    description:
      'Rounds the sample values of all elements in v to the nearest integer.',
    expression: 'round()',
    shortName: 'round',
    vectorType: 'range',
    imageUrl: '',
  },
  {
    name: 'Log',
    category: 'Arithmetic',
    description: 'Returns the natural logarithm of x.',
    expression: 'log()',
    shortName: 'log',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Asin',
    category: 'Trigonometric',
    description: 'Calculates the arcsine of all elements in v.',
    expression: 'asin()',
    shortName: 'asin',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Atan',
    category: 'Trigonometric',
    description: 'Calculates the arctangent of all elements in v.',
    expression: 'atan()',
    shortName: 'atan',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Ceil',
    category: 'Arithmetic',
    description:
      'Rounds the sample values of all elements in v up to the nearest integer.',
    expression: 'ceil()',
    shortName: 'ceil',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Cos',
    category: 'Trigonometric',
    description: 'Calculates the cosine of all elements in v.',
    expression: 'cos()',
    shortName: 'cos',
    vectorType: 'instant',
    imageUrl: '',
  },
  {
    name: 'Cosh',
    category: 'Trigonometric',
    description: 'Calculates the hyperbolic cosine of all elements in v.',
    expression: 'cosh()',
    shortName: 'cosh',
    vectorType: 'instant',
    imageUrl: '',
  },
];

export default logsFunctionNames;
