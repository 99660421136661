import { Button, LogsMetricsQueryBuilder, TooltipTrigger } from 'components';
import { Switch } from 'components/shadcn';
import { useLogsMetricsQueryState } from 'hooks';
import React, { ReactElement } from 'react';
import { Plus } from 'react-feather';
import { DateSelection, QueryLangType } from 'types';

import { useAlertsCreate } from '../hooks';
import { AlertType } from '../types';

const newArray = [];
const newObj = {};

const AlertsCreateLogsQueryBuilder = ({
  alertsCreateState,
  date,
  logsMetricsQueryState,
}: {
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  date: DateSelection;
  logsMetricsQueryState: ReturnType<typeof useLogsMetricsQueryState>;
}): ReactElement => {
  const { queryLangType, setQueryLangType } = logsMetricsQueryState;
  const { alertType } = alertsCreateState;
  return (
    <div>
      <LogsMetricsQueryBuilder
        allowMultipleQueries={true}
        date={date}
        logsMetricsQueryState={logsMetricsQueryState}
        blockedFunctionsCategories={['Algorithms']}
        minStep="15s"
        logsState={{
          date,
          filterByFacets: newArray,
          searchTerms: newArray,
          selectedFacetValues: newObj,
        }}
      />
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Button
            onClick={() => logsMetricsQueryState.addQuery(false)}
            variant="default"
            size="sm"
          >
            <Plus size={16} /> Add Query
          </Button>
          <Button
            onClick={() => logsMetricsQueryState.addFormula(false)}
            variant="default"
            size="sm"
          >
            <Plus size={16} /> Add Formula
          </Button>
        </div>
        <div className="flex items-center gap-2 pr-4">
          <TooltipTrigger
            tooltip={
              alertType === AlertType.ANOMALY ||
              alertType === AlertType.OUTLIERS ||
              alertType === AlertType.FORECAST
                ? 'Switching to LogQL is not supported for anomaly, outliers and forecast alerts'
                : 'FuseQL is developed by Kloudfuse to improve the query experience for logs'
            }
          >
            <label className="text-xs font-semibold text-text-secondary">
              Use FuseQL
            </label>
          </TooltipTrigger>
          <Switch
            size="3"
            checked={queryLangType === QueryLangType.KFUSEQL}
            onCheckedChange={() =>
              setQueryLangType((prev) =>
                prev === QueryLangType.LOGQL
                  ? QueryLangType.KFUSEQL
                  : QueryLangType.LOGQL,
              )
            }
            disabled={
              alertType === AlertType.ANOMALY ||
              alertType === AlertType.OUTLIERS ||
              alertType === AlertType.FORECAST
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AlertsCreateLogsQueryBuilder;
