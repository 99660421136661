import { DataFrame } from '../types';
import timeseriesDataTransformer from '../visualTransformer/timeseriesDataTransformer';
import { labelTransformer } from '../labelTransformer';

const ANOMALY_RESULT_TYPE = 'result_type';

const forecastDataTransformer = (dataFrame: DataFrame) => {
  const { data, meta, maxValue, minValue, timestamps } = dataFrame;

  const lower: DataFrame = { meta, data: [], maxValue, minValue, timestamps };
  const upper: DataFrame = { meta, data: [], maxValue, minValue, timestamps };
  const mean: DataFrame = { meta, data: [], maxValue, minValue, timestamps };

  data.forEach((item) => {
    const { label } = item;
    if (label[ANOMALY_RESULT_TYPE] === 'lower') {
      delete item.label[ANOMALY_RESULT_TYPE];
      lower.data.push(item);
    }
    if (label[ANOMALY_RESULT_TYPE] === 'upper') {
      delete item.label[ANOMALY_RESULT_TYPE];
      upper.data.push(item);
    }
    if (label[ANOMALY_RESULT_TYPE] === 'mean') {
      delete item.label[ANOMALY_RESULT_TYPE];
      mean.data.push(item);
    }
  });

  const lowerLabelTransformed = labelTransformer(lower);
  const upperLabelTransformed = labelTransformer(upper);
  const meanLabelTransformed = labelTransformer(mean);

  const lowerData = timeseriesDataTransformer(lowerLabelTransformed);
  const upperData = timeseriesDataTransformer(upperLabelTransformed);
  const meanData = timeseriesDataTransformer(meanLabelTransformed);

  return [upperData, lowerData, meanData];
};

export default forecastDataTransformer;
