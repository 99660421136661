import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
  useRef,
} from 'react';
import { isEqual } from 'lodash';
import { useActionCreatorFactory } from 'hooks';

import reducer from './reducer';
import useFocusElementToFocusEffect from './useFocusElementToFocusEffect';
import { isValid } from './utils';

import {
  ActionType,
  ElementToFocusInfo,
  Reducer,
  ChipRef,
  ElementType,
  FocusedElementInfo,
  PayloadByActionType,
  ExpressionBuilderState,
  Expression,
} from './types';

const useExpressionBuilderState = ({
  urlExpressions,
  initializerRef,
  chipRefs,
}: {
  urlExpressions: Array<Expression>;
  initializerRef: React.MutableRefObject<HTMLInputElement>;
  chipRefs: React.MutableRefObject<ChipRef[]>;
}): ExpressionBuilderState => {
  const [expressions, dispatch] = useReducer<Reducer>(reducer, []);
  const useActionCreator =
    useActionCreatorFactory<PayloadByActionType>(dispatch);

  const setExpressions = useActionCreator(ActionType.SET_EXPRESSIONS);
  const createExpression = useActionCreator(ActionType.CREATE_EXPRESSION);
  const removeExpression = useActionCreator(ActionType.REMOVE_EXPRESSION);
  const setProperty = useActionCreator(ActionType.SET_PROPERTY);
  const setOperator = useActionCreator(ActionType.SET_OPERATOR);
  const setValue = useActionCreator(ActionType.SET_VALUE);
  const removeValue = useActionCreator(ActionType.REMOVE_VALUE);

  const getExpressionIfExists = ({
    index,
  }: {
    index: number;
  }): Expression | null => {
    return expressions[index] || null;
  };

  const getValueIfExists = ({
    index,
    valueIndex,
  }: {
    index: number;
    valueIndex: number;
  }): string | null => expressions[index]?.values?.[valueIndex] || null;

  const getPropertyIfExists = ({ index }: { index: number }): string | null =>
    expressions[index]?.property || null;

  const [elementToFocusInfo, setElementToFocusInfo] =
    useState<ElementToFocusInfo | null>(null);

  useEffect(() => {
    if (!isEqual(expressions, urlExpressions)) {
      setExpressions({ expressions: urlExpressions });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlExpressions]);

  useEffect(() => {
    const invalidIndices = expressions
      .map(isValid)
      .map((valid, idx) => (valid ? null : idx))
      .filter((idx) => idx !== null);

    if (invalidIndices.length > 0) {
      invalidIndices.forEach((idx) => removeExpression({ index: idx }));
      setElementToFocusInfo({ type: ElementType.INITIALIZER });
    }
  }, [expressions, removeExpression]);

  const [focusedElementInfo, _setFocusedElementInfo] =
    useState<FocusedElementInfo | null>(null);

  const timerId = useRef<NodeJS.Timeout>(null);
  // focusedElementInfo determines if Suggestions popover is open
  // This handler adds a 200ms delay when nullifying focusedElementInfo
  // to allow 'click' event on the suggestions pane.
  const setFocusedElementInfo = useCallback(
    (info: FocusedElementInfo | null): void => {
      if (info === null) {
        timerId.current = setTimeout(() => {
          _setFocusedElementInfo(null);
        }, 200);
      } else {
        clearTimeout(timerId.current);
        _setFocusedElementInfo(info);
      }
    },
    [],
  );

  useFocusElementToFocusEffect({
    elementToFocusInfo,
    setElementToFocusInfo,
    initializerRef,
    chipRefs,
  });

  return {
    expressions,
    getExpressionIfExists,
    createExpression,
    removeExpression,
    getPropertyIfExists,
    setProperty,
    getValueIfExists,
    setValue,
    removeValue,
    setOperator,
    focusedElementInfo,
    setFocusedElementInfo,
    setElementToFocusInfo,
  };
};

export default useExpressionBuilderState;
