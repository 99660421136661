import { Hostmap, Loader, useThemeContext } from 'components';
import React, { ReactElement, useMemo } from 'react';
import { DashboardPanelComponentProps, DashboardPanelType } from 'types';
import { queryDataFormattingByGraphType } from 'utils';

import { useDashboardDataLoader } from '../hooks';
import {
  getPanelStreamType,
  getPanelWidthHeight,
  getPolyStatData,
} from '../utils';

const DashboardPanelPolyStat = ({
  baseWidth,
  dashboardState,
  dashboardTemplateState,
  isInView,
  nestedIndex,
  panel,
  panelIndex,
}: DashboardPanelComponentProps): ReactElement => {
  const { templateValues } = dashboardTemplateState;
  const { darkModeEnabled } = useThemeContext();
  const stream = getPanelStreamType(panel);

  const dashboardDataLoader = useDashboardDataLoader({
    baseWidth,
    dashboardState,
    dashboardTemplateState,
    isInView,
    nestedIndex,
    panelIndex,
    templateValues,
    type: DashboardPanelType.GRAFANA_POLYSTAT_PANEL,
  });

  const hostmapQueryData = useMemo(() => {
    if (!dashboardDataLoader.result) return null;

    const formattedHostmap = queryDataFormattingByGraphType({
      darkModeEnabled,
      graphType: DashboardPanelType.GRAFANA_POLYSTAT_PANEL,
      queries: [{ queryKey: 'a', isActive: true }],
      queryData: { query_a: dashboardDataLoader.result },
      formulas: [],
    });

    return {
      ...formattedHostmap,
      data: getPolyStatData(formattedHostmap.data, panel, stream),
    };
  }, [darkModeEnabled, dashboardDataLoader.result, panel, stream]);
  const panelSize = getPanelWidthHeight(panel.gridPos, baseWidth, panel.title);

  return (
    <Loader isLoading={dashboardDataLoader.isLoading}>
      {hostmapQueryData?.data ? (
        <Hostmap
          data={hostmapQueryData.data}
          layoutType="dashboard"
          width={panelSize.width}
          height={panelSize.heightContainer}
        />
      ) : null}
    </Loader>
  );
};

export default DashboardPanelPolyStat;
