import { SearchState } from 'hooks/index';

export const areQueriesGroupedByService = ({
  queries,
}: {
  queries: SearchState[];
}) => {
  const groupByInQueries = queries?.map((query) => query.groupBys) || [];
  return groupByInQueries.some((groupBy) => groupBy.includes('service_hash'));
};
