import {
  ConfirmationModal,
  Loader,
  Paginator,
  Table,
  TableSearch,
  TooltipTrigger,
  useModalsContext,
  usePaginator,
  useTableSearch,
  useTableSort,
  useToaster,
} from 'components';
import dayjs from 'dayjs';
import { useRequest } from 'hooks';
import { dateTimeFormat } from 'kfuse-constants';
import React, { ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  MdShield as ShieldIcon,
  MdDelete as DeleteIcon,
  MdModeEdit as EditIcon,
} from 'react-icons/md';
import { RequestResult } from 'types/Request';

import { UserProps } from './types';
import { deleteUserById } from './requests';

const columnsUserManagement = ({
  onDeleteUser,
}: {
  onDeleteUser: (row: UserProps) => void;
}) => [
  { key: 'name', label: 'Name' },
  { key: 'login', label: 'Login' },
  { key: 'email', label: 'Email' },
  { key: 'role', label: 'Role' },
  // {
  //   key: 'updated',
  //   label: 'Updated',
  //   renderCell: ({ row }: { row: UserProps }) => {
  //     const updatedDate = dayjs.unix(row.lastSeenAtUnix);
  //     return (
  //       <TooltipTrigger tooltip={updatedDate.format(dateTimeFormat)}>
  //         {updatedDate.fromNow()}
  //       </TooltipTrigger>
  //     );
  //   },
  // },
  {
    label: '',
    key: 'actions',
    renderCell: ({ row }: { row: UserProps }) => {
      return (
        <div
          className="table__row__actions"
          style={{ '--table-actions-width': '74px' }}
        >
          <div className="table__row__actions--hidden">
            <div className="table__row__actions__slider">
              <div className="alerts__contacts__table__actions">
                <div className="table__cell__actions__item--blue">
                  <Link className="link" to={`/admin/users/${row.id}`}>
                    <TooltipTrigger tooltip="Edit">
                      <EditIcon
                        className="alerts__contacts__table__actions__icon--edit"
                        size={18}
                      />
                    </TooltipTrigger>
                  </Link>
                </div>
                <div
                  className="table__cell__actions__item--red"
                  onClick={() => onDeleteUser(row)}
                >
                  <TooltipTrigger tooltip="Delete">
                    <DeleteIcon
                      className="alerts__contacts__table__actions__icon--delete"
                      size={18}
                    />
                  </TooltipTrigger>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
];

const emptyArr = [];
const UserManagementTable = ({
  userListRequest,
}: {
  userListRequest: RequestResult<UserProps[], any>;
}): ReactElement => {
  const { addToast } = useToaster();
  const deleteUserRequest = useRequest(deleteUserById);
  const users = userListRequest.result || emptyArr;
  const modal = useModalsContext();

  const onDeleteUser = (row: UserProps) => {
    modal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description={
          <span>
            Are you sure you want to delete{' '}
            <span className="font-bold">{row.email}</span> user? This action
            cannot be undone.
          </span>
        }
        onCancel={() => modal.pop()}
        onConfirm={() => {
          deleteUserRequest.call({ userId: row.id }).then((res) => {
            if (res.message) {
              addToast({
                text: 'User deleted successfully',
                status: 'success',
              });
            }
            userListRequest.call();
            modal.pop();
          });
        }}
        title="Delete User"
      />,
    );
  };

  const columns = useMemo(() => {
    return columnsUserManagement({ onDeleteUser });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableSearch = useTableSearch({ rows: users });
  const tableSort = useTableSort({ columns, rows: tableSearch.searchedRows });
  const paginator = usePaginator({
    rows: tableSort.sortedRows,
    initialNumberOfRowsPerPage: 25,
  });

  return (
    <div>
      <Loader isLoading={userListRequest.isLoading}>
        <TableSearch
          className="dashboard__list__search"
          placeholder="Search Users..."
          tableSearch={tableSearch}
          dataTestId="dashboard-list-search"
        />
        <Table
          className="table--bordered table--bordered-cells table__actions--hidden alerts__list__table"
          columns={columns}
          dataTestId="alerts-list-table"
          externalTableSort={tableSort}
          isSortingEnabled
          rows={paginator.paginatedRows}
        />
        <div className="table-footer">
          <Paginator paginator={paginator} />
        </div>
      </Loader>
    </div>
  );
};

export default UserManagementTable;
