import {
  RumActionColumnKey,
  RumErrorColumnKey,
  RumLongTaskColumnKey,
  RumResourceColumnKey,
  RumSessionColumnKey,
  RumViewColumnKey,
} from './RumTableConstants';

export enum RumCommonColumnKey {
  hasReplay = 'session.has_replay',
}

export enum RumActionWithErrorsColumnKey {
  actionId = 'action.id',
  actionType = 'action.type',
  actionTargetName = 'action.target.name',
  actionName = 'action.name',
  sessionId = 'session.id',
  actionErrorCount = 'action.error.count',
  viewName = 'view.name',
}

export const RumTableColumnKey = {
  ...RumCommonColumnKey,
  ...RumViewColumnKey,
  ...RumActionColumnKey,
  ...RumLongTaskColumnKey,
  ...RumResourceColumnKey,
  ...RumSessionColumnKey,
  ...RumErrorColumnKey,
  ...RumActionWithErrorsColumnKey,
};
