import { fetchJson } from 'requests';

const deletePolicyByName = async (
  name: string,
): Promise<Record<string, unknown>> => {
  const result = await fetchJson(`rbac/policies/${name}`, {
    method: 'DELETE',
  });

  return result as Record<string, unknown>;
};

export default deletePolicyByName;
