import React, { useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { DateSelection } from 'types';
import {
  DateControls,
  LeftSidebar,
  useLeftSidebarState,
  ProductTour,
  ShowSidebarTooltipButton,
  CursorStateProvider,
} from 'components';
import { Datepicker } from 'composite';
import useDebouncedEffect from 'use-debounced-effect';
import RumSidebar from './RumSidebar';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import rumLabels from './requests/rumLabels';
import useRequest from 'hooks/useRequest';
import RumPerformanceGrid from './RumPerformanceGrid';
import RumPerformanceOverview from './RumPerformanceOverview';
import { RumTab, RumPerformanceSubtab } from './types';
import RumApplicationPerformanceTab from './RumCoreWebVitals/RumApplicationPerformanceTab';
import { RumEventType } from 'screens/Rum';
import RumErrorsOverview from './RumErrorsOverview';
import RumApplicationFilter from './RumApplicationFilter';
import RumAnalyzeErrors from './RumAnalyzeErrors';

type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
  rumTab: RumTab | RumPerformanceSubtab;
};

const getEventTypeForPerformanceSidebar = (
  rumTab: RumTab | RumPerformanceSubtab,
) => {
  switch (rumTab) {
    case RumTab.performanceMonitoring:
    case RumPerformanceSubtab.performance:
      return RumEventType.VIEW;
    case RumPerformanceSubtab.errors:
      return RumEventType.ERROR;
    default:
      return RumEventType.VIEW;
  }
};

const RumPerformance = ({ rumPageState, rumTab }: Props) => {
  const { dependenciesForWriteStateToUrl, rumState, writeStateToUrl } =
    rumPageState || {};

  const leftSidebarState = useLeftSidebarState('rum');
  const { dateState } = rumState;
  const [date, setDate] = dateState;
  const rumLabelNamesRequest = useRequest(rumLabels);

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesForWriteStateToUrl);

  const sideBarEventType = useMemo(
    () => getEventTypeForPerformanceSidebar(rumTab),
    [rumTab],
  );

  useDebouncedEffect(
    () => {
      rumLabelNamesRequest.call({ eventType: sideBarEventType });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      timeout: 100,
      ignoreInitialCall: false,
    },
    [sideBarEventType],
  );

  return (
    <div
      className={classnames({
        rum: true,
        'relative flex w-full flex-row overflow-hidden': true,
      })}
    >
      <LeftSidebar leftSidebarState={leftSidebarState}>
        <RumApplicationFilter rumState={rumState} />
        <RumSidebar
          eventType={sideBarEventType}
          rumLabelNamesRequest={rumLabelNamesRequest}
          rumState={rumState}
        />
      </LeftSidebar>
      <div className="p-4">
        {leftSidebarState.width === 0 ? (
          <ShowSidebarTooltipButton onClick={leftSidebarState.show} />
        ) : null}
      </div>
      <div className="rum__main  relative flex flex-col overflow-auto px-4">
        <div className="flex w-full items-center justify-between">
          <h1 className="rum__performance-title">
            Analyze your application performance
          </h1>

          <div className="rum__header__right flex flex-row items-center space-x-4">
            <Datepicker onChange={setDate} value={date as DateSelection} />
            <DateControls date={date} setDate={setDate} />
          </div>
        </div>

        <div className="rum__subtab_div">
          <RumApplicationPerformanceTab />
        </div>

        <div>
          {rumTab === RumTab.performanceMonitoring && (
            <CursorStateProvider>
              <RumPerformanceOverview rumPageState={rumPageState} />
            </CursorStateProvider>
          )}
        </div>
        <div>
          {rumTab === RumPerformanceSubtab.performance ? (
            <CursorStateProvider>
              <RumPerformanceGrid rumPageState={rumPageState} />
            </CursorStateProvider>
          ) : null}
        </div>
        {rumTab === RumPerformanceSubtab.errors && (
          <CursorStateProvider>
            <RumAnalyzeErrors rumPageState={rumPageState} />
          </CursorStateProvider>
        )}
        <div></div>
      </div>
      <ProductTour />
    </div>
  );
};

export default RumPerformance;
