import { DataFrame } from '../types';

const adjustForecastTimeseriesTimestamp = ({
  dataFrame,
  startTimeUnix,
  step,
}: {
  dataFrame: DataFrame;
  startTimeUnix: number;
  step: number;
}): DataFrame => {
  const { timestamps } = dataFrame;
  const adjustedTimestamps = timestamps.map((_, idx) => {
    return startTimeUnix + step * idx;
  });

  return { ...dataFrame, timestamps: adjustedTimestamps };
};

export default adjustForecastTimeseriesTimestamp;
