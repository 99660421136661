import { IconWithLabel } from 'components';
import React, { ReactNode } from 'react';

type Props = {
  label?: ReactNode;
  language: string;
};

const iconByLanguage: { [key: string]: string } = {
  cpp: 'cplusplus',
  dotnet: 'dot-net',
};

const LanguageIconWithLabel = ({ label, language }: Props) => {
  return (
    <IconWithLabel
      icon={
        <i
          className={`devicon-${
            iconByLanguage[language] || language
          }-plain colored`}
        ></i>
      }
      label={label}
    />
  );
};

export default LanguageIconWithLabel;
