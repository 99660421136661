// Ivory to Deep Purple
// export const heatmapColorPalette = [
//   'rgb(131,58,180)',  // Deep Purple
//   'rgb(154,65,159)',  // Amethyst
//   'rgb(178,67,136)',  // Magenta
//   'rgb(202,63,111)',  // Deep Pink
//   'rgb(228,53,80)',   // Crimson
//   'rgb(253,29,29)',   // Bright Red
//   'rgb(255,76,37)',   // Vermilion
//   'rgb(256,106,45)',  // Burnt Orange
//   'rgb(256,131,53)',  // Tangerine
//   'rgb(255,154,61)',  // Light Orange
//   'rgb(252,176,69)',  // Marigold
//   'rgb(254,193,115)', // Pale Gold
//   'rgb(255,209,153)', // Peach
//   'rgb(256,224,188)', // Champagne
//   'rgb(256,240,222)', // Ivory
// ].reverse();

// Red hot to blue cold
// export const heatmapColorPalette = [
//   'rgb(0, 0, 255)', // Deep blue (cold)
//   'rgb(0, 128, 255)', // Light blue
//   'rgb(0, 255, 255)', // Cyan
//   'rgb(0, 255, 128)', // Teal
//   'rgb(0, 255, 0)', // Green
//   'rgb(128, 255, 0)', // Lime green
//   'rgb(255, 255, 0)', // Yellow
//   'rgb(255, 128, 0)', // Orange
//   'rgb(255, 0, 0)', // Red (hot)
// ].reverse();

// Lightest aqua to dark blue
export const heatmapColorPalette = [
  'rgb(9, 67, 153)', // Dark blue
  'rgb(16, 80, 161)', // Deep blue
  'rgb(23, 93, 169)', // Blue
  'rgb(31, 107, 177)', // Medium blue
  'rgb(38, 120, 186)', // Sky blue
  'rgb(46, 133, 194)', // Light blue
  'rgb(53, 147, 202)', // Pale blue
  'rgb(60, 160, 210)', // Light teal
  'rgb(68, 173, 219)', // Soft teal
  'rgb(75, 187, 227)', // Aqua
  'rgb(83, 200, 235)', // Light aqua
  'rgb(90, 213, 243)', // Pale aqua
  'rgb(98, 227, 252)', // Lightest aqua
].reverse();
