import { SizeObserver } from 'components';
import React, { useEffect, useRef } from 'react';
import RrwebPlayer from 'rrweb-player';
import { RumReplayStateProvider } from './RumReplayStateContext';
import RumReplayControls from './RumReplayControls';
import RumReplayEvents from './RumReplayEvents';
import RumReplayPlayer from './RumReplayPlayer';
import { RumEvent, SessionMetadata } from './types';

type Args = {
  events: RumEvent[];
  session: SessionMetadata;
  startReplayAtUnixMs?: number;
};

const RumReplayFetched = ({ events, session, startReplayAtUnixMs }: Args) => {
  const playersByIndexRef = useRef<Record<string, RrwebPlayer>>({});

  const { startTimeUnixMs, tabs } = session;

  useEffect(() => {
    return () => {
      // eslint-disable-next-line
      const playersByIndex = playersByIndexRef.current;
      Object.values(playersByIndex).forEach((player) => {
        if (player) {
          player.$destroy();
        }
      });
    };
  }, []);

  return (
    <RumReplayStateProvider
      events={events}
      playersByIndexRef={playersByIndexRef}
      session={session}
      startReplayAtUnixMs={startReplayAtUnixMs}
    >
      <div className="rum-replay__left">
        <div className="rum-replay__left__inner">
          <div className="rum-replay__players">
            <SizeObserver className="rum-replay__players__size-observer">
              {({ height, width }) =>
                width ? (
                  <div className="rum-replay__players__inner">
                    {tabs.map((tab, i) => (
                      <RumReplayPlayer
                        height={height}
                        index={i}
                        key={tab.tabId}
                        offset={tab.startTimeUnixMs - startTimeUnixMs}
                        playersByIndexRef={playersByIndexRef}
                        tab={tab}
                        width={width}
                      />
                    ))}
                  </div>
                ) : null
              }
            </SizeObserver>
          </div>
          <RumReplayControls events={events} session={session} />
        </div>
      </div>
      <RumReplayEvents events={events} session={session} />
    </RumReplayStateProvider>
  );
};

export default RumReplayFetched;
