import { useToaster } from 'components/Toasts';
import React, { ReactElement, useMemo } from 'react';
import { Clipboard } from 'react-feather';
import { getMetricsExplorerUrl } from 'utils';

import useMetricsSummary from './useMetricsSummary';

const MetricsSummaryMetadata = ({
  metricSummaryState,
}: {
  metricSummaryState: ReturnType<typeof useMetricsSummary>;
}): ReactElement => {
  const { addToast } = useToaster();
  const { metricLabels, promqlMetadataRequest, selectedMetric } =
    metricSummaryState;

  const onOpenInExplorer = () => {
    if (selectedMetric) {
      const queryUrl = getMetricsExplorerUrl(selectedMetric, '');
      window.open(queryUrl, '_blank');
    }
  };

  const cardinality = useMemo(() => {
    if (!selectedMetric || !metricLabels[selectedMetric]) return;
    const selectedMetricLabels = metricLabels[selectedMetric];

    const labels = selectedMetricLabels.find(({ label }) => label === 'labels');
    return labels?.count;
  }, [selectedMetric, metricLabels]);

  const metadata = promqlMetadataRequest?.result?.[selectedMetric] || [];
  const { type, unit, help } = metadata?.[0] || {};
  return (
    <div className="metrics-summary__body__details__metadata">
      {selectedMetric && (
        <>
          <div className="metrics-summary__body__details__metadata__explorer">
            <div className="metrics-summary__body__subtitle">
              {selectedMetric}{' '}
              <Clipboard
                className="metrics-summary__body__details__metadata__explorer__copy-icon "
                onClick={() => {
                  addToast({ status: 'success', text: 'Copied to clipboard' });
                  navigator.clipboard.writeText(selectedMetric);
                }}
                size={16}
              />
            </div>
            <button className="button button--blue" onClick={onOpenInExplorer}>
              Open in Metrics Explorer
            </button>
          </div>
          <div className="metrics-summary__body__details__metadata__tags"></div>
          {promqlMetadataRequest.result && !promqlMetadataRequest.isLoading && (
            <div className="metrics-summary__body__details__metadata__metadata">
              <div className="metrics-summary__body__subtitle">Metadata</div>
              {cardinality && (
                <div>
                  Cardinality: <span className="chip">{cardinality}</span>
                </div>
              )}
              {type && (
                <div>
                  Metric Type: <span className="chip">{type}</span>
                </div>
              )}
              {unit && (
                <div>
                  Unit: <span className="chip">{unit}</span>
                </div>
              )}
              {help && <div className="mt-1">Description: {help}</div>}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MetricsSummaryMetadata;
