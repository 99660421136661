import {
  useFacetRegexState,
  useRequest,
  useSelectedFacetRangeByNameState,
  useSelectedFacetValuesByNameState,
  useSortState,
} from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { DateSelection } from 'types';
import { RumEventType } from './types';
import getActionsWithErrors from './requests/getActionsWithErrors';

const limit = 15;

type Args = {
  applicationFilter: string;
  date: DateSelection;
  eventType: RumEventType;
  facetRegexState: ReturnType<typeof useFacetRegexState>;
  idSearch: string;
  rumTableSortState?: ReturnType<typeof useSortState>;
  selectedFacetRangeByNameState: ReturnType<
    typeof useSelectedFacetRangeByNameState
  >;
  selectedFacetValuesByNameState?: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
};
type RumActionsWithErrorsData = {
  call: () => void;
  isLoading: boolean;
  onScrollEnd: () => void;
  result: {
    data: any[];
    error: string;
  };
};
const useRumActionsWithErrorsRequest = ({
  applicationFilter,
  date,
  eventType,
  facetRegexState,
  idSearch,
  rumTableSortState,
  selectedFacetRangeByNameState,
  selectedFacetValuesByNameState,
}: Args): RumActionsWithErrorsData => {
  const pageCursorRef = useRef<string>(null);
  const request = useRequest(getActionsWithErrors, true, true);
  const [result, setResult] = useState({ data: [], error: null });

  const handleError = (error: string) => {
    setResult((prevResult) => ({
      ...prevResult,
      error,
    }));
  };

  const call = () => {
    const { startTimeUnix, endTimeUnix } = date;

    request
      .call({
        applicationFilter,
        facetRegex: facetRegexState?.state,
        startTimeUnix,
        idSearch,
        endTimeUnix,
        sortBy: rumTableSortState?.state.key,
        sortOrder: rumTableSortState?.state.isAsc ? 'asc' : 'desc',
        selectedFacetRangeByName: selectedFacetRangeByNameState.state,
        selectedFacetValuesByName: selectedFacetValuesByNameState.state,
        limit,
      })
      .then((nextResult) => {
        if (
          nextResult &&
          nextResult.data &&
          nextResult.data.actionsTriggeringErrors &&
          nextResult.data.actionsTriggeringErrors.length
        ) {
          setResult((prevResult) => ({
            data: [
              ...(prevResult?.data || []),
              ...nextResult.data.actionsTriggeringErrors,
            ],
            error: null,
          }));
        } else {
          setResult((prevResult) => ({
            ...prevResult,
            error: 'No data available',
          }));
        }
      })
      .catch((e) => {
        handleError(e.message || 'Error fetching data');
      });
  };

  const onScrollEnd = () => {
    if (pageCursorRef.current) {
      call();
    }
  };

  useEffect(() => {
    pageCursorRef.current = null;
    setResult({ data: [], error: null });
    call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applicationFilter,
    date,
    eventType,
    facetRegexState?.state,
    idSearch,
    selectedFacetRangeByNameState.state,
    selectedFacetValuesByNameState.state,
    rumTableSortState?.state,
  ]);

  return {
    call,
    isLoading: request.isLoading,
    onScrollEnd,
    result,
  };
};

export default useRumActionsWithErrorsRequest;
