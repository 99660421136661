import React, { ReactElement, useMemo } from 'react';
import { DateSelection, ExplorerQueryProps, FormulaProps } from 'types';

import {
  AnomalyConditionProps,
  ConditionProps,
} from '../AlertsCreateCondition';
import AlertsChartAnomalyPromql from './AlertsChartAnomalyPromql';
import { getPromQlQuery } from '../utils';

const AlertsChartAnomaly = ({
  anomalyCondition,
  baseWidth,
  condition,
  date,
  forWindow,
  isEditing = false,
  formulas,
  queries,
}: {
  anomalyCondition: AnomalyConditionProps;
  baseWidth: number;
  condition: ConditionProps;
  date: DateSelection;
  forWindow?: string;
  isEditing?: boolean;
  formulas: FormulaProps[];
  queries: ExplorerQueryProps[];
}): ReactElement => {
  const queryItemPromql = useMemo(() => {
    if (!anomalyCondition) return;
    const { queryKey } = condition;
    return getPromQlQuery({
      date,
      formulas,
      queryKey,
      queries,
    });
  }, [anomalyCondition, condition, date, formulas, queries]);

  return (
    <AlertsChartAnomalyPromql
      anomalyCondition={anomalyCondition}
      baseWidth={baseWidth}
      date={date}
      forWindow={forWindow}
      isEditing={isEditing}
      queryItem={{
        promql: queryItemPromql?.promql as string,
        queryType: queryItemPromql?.queryType,
        ...queryItemPromql?.meta,
      }}
    />
  );
};

export default AlertsChartAnomaly;
