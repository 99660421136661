import { BasicLogsSheet } from 'components';
import React, { useMemo } from 'react';
import { KfPlatform } from 'types';
import { RumEvent, RumEventType } from './types';
import { getFacetKey } from 'utils';
import { delimiter } from 'kfuse-constants';
import { extractStartAndTimeUnixFromEvent } from './RumFlameGraph/utils';

type Props = {
  kfPlatform?: KfPlatform;
  service?: string;
  activeRumEvent: RumEvent;
  applicationId?: string;
  sessionId?: string;
  viewId?: string;
};

type LogsState = {
  selectedFacetValues: Record<string, number>;
  selectedFacetValuesWithOr: Record<string, number>;
};

const RumActiveLogs = ({
  activeRumEvent,
  applicationId,
  sessionId,
  viewId,
}: Props): JSX.Element | null => {
  const mapIdFilterKeyToFacet = (key: string) => {
    switch (key) {
      case 'viewId':
        return 'view_id';
      case 'actionId':
        return 'action_id';
      case 'errorId':
        return 'error_id';
      default:
        return null;
    }
  };

  const generateFacetValue = (input: Record<string, string | undefined>) => {
    const [key, value] = Object.entries(input)[0];
    if (!value) return null;

    const mappedKey = mapIdFilterKeyToFacet(key) || key;
    const facetKey = getFacetKey({
      component: 'browser',
      name: mappedKey,
      type: 'string',
    });

    return { [`${facetKey}${delimiter}${value}`]: 1 };
  };

  const dateFilter = useMemo(() => {
    const { startTimeUnix, endTimeUnix } =
      extractStartAndTimeUnixFromEvent(activeRumEvent);

    return {
      startTimeUnix: startTimeUnix - 60,
      endTimeUnix: endTimeUnix + 60,
    };
  }, [activeRumEvent]);

  const generateLogsState: LogsState | null = useMemo(() => {
    if (!activeRumEvent?.eventType) return null;

    const baseSelectedFacetValues = {
      ...generateFacetValue({ applicationid: applicationId }),
      [`Additional${delimiter}__kf_ddrum${delimiter}string${delimiter}${delimiter}true`]: 1,
    };

    const eventSpecificFacetValues =
      activeRumEvent.eventType === RumEventType.VIEW
        ? {
            ...generateFacetValue({ view_id: viewId }),
            ...generateFacetValue({ sessionid: sessionId }),
          }
        : {};

    const eventSpecificFacetValuesWithOr =
      activeRumEvent.eventType === RumEventType.ERROR
        ? {
            ...generateFacetValue({ view_id: viewId }),
            ...generateFacetValue({ sessionid: sessionId }),
          }
        : {};

    return {
      date: dateFilter,
      selectedFacetValues: {
        ...baseSelectedFacetValues,
        ...eventSpecificFacetValues,
      },
      selectedFacetValuesWithOr: eventSpecificFacetValuesWithOr,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRumEvent, applicationId, viewId, sessionId, dateFilter]);

  if (!generateLogsState) return null;

  return (
    <div className="trace-sidebar__active-span__logs">
      <BasicLogsSheet
        logsState={generateLogsState}
        renderToolbarLeft={() => null}
        disableOnlySaveQueryInToolBar={true}
        disableOnlyAddToDashboardInToolBar={false}
      />
    </div>
  );
};

export default RumActiveLogs;
