import dayjs from 'dayjs';
import { getSpanTypeFilterForServiceAPI } from 'utils';
import { DateSelection, SelectedFacetValuesByName, Service } from 'types';
import { onPromiseError } from 'utils';
import queryTraceService from './queryTraceService';

type Args = {
  date: DateSelection;
  kfSource?: string;
  serviceHash: string;
  selectedFacetValuesByName?: SelectedFacetValuesByName;
};

const getService = async ({
  date,
  kfSource,
  serviceHash,
  selectedFacetValuesByName = {},
}: Args): Promise<Service> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  const spanTypeServiceFilter = getSpanTypeFilterForServiceAPI(
    selectedFacetValuesByName['span_type'] || {},
  );

  return queryTraceService<Service[], 'services'>(`
    {
      services (
        durationSecs: ${durationSecs},
        kfSource: "${kfSource || 'apm'}"
        service: {
          hash: "${serviceHash}"
          ${spanTypeServiceFilter ? `kfType: "${spanTypeServiceFilter}",` : ''}
        },
        timestamp: "${endTime.format()}",
      ) {
        name
        distinctLabels
        labels
        hash
        kfType
      }
    }
  `).then(
    (data) => (data.services?.length ? data.services[0] : null),
    onPromiseError,
  );
};

export default getService;
