import classnames from 'classnames';
import React, {
  forwardRef,
  KeyboardEventHandler,
  MutableRefObject,
  ReactElement,
  HTMLAttributes,
} from 'react';
import { InputProps } from './types';
import * as Shadcn from '../shadcn';

const BaseInput = (
  {
    className,
    onChange,
    onBackspace,
    onEnter,
    placeholder,
    name,
    type,
    value,
    ...rest
  }: InputProps & Omit<HTMLAttributes<HTMLInputElement>, 'onChange'>,
  ref: MutableRefObject<HTMLInputElement>,
): ReactElement => {
  const onKeyUp: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (onKeyUp) {
        if (onEnter) {
          onEnter();
        }
      }
    }

    if (e.key === 'Backspace') {
      if (onBackspace) {
        onBackspace();
      }
    }
  };

  return (
    <Shadcn.Input
      {...rest}
      className={classnames({ input: true, [className]: className })}
      onChange={(e) => {
        onChange(e.currentTarget.value);
      }}
      onKeyUp={onKeyUp}
      placeholder={placeholder}
      name={name}
      ref={ref}
      type={type}
      value={value}
    />
  );
};

const Input = forwardRef(BaseInput);

export default Input;
