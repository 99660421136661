const fetchJson = async <T>(
  url: string,
  options: RequestInit = {},
): Promise<T> => {
  return fetch(url, {
    credentials: 'same-origin',
    headers: { 'content-type': 'application/json' },
    ...options,
  })
    .then((response) => {
      if (!response.ok) {
        return handleError(response);
      }

      if (response.headers.get('content-length') === '0') {
        throw new Error('Empty response you may not have permission.');
      }

      if (response.headers.get('Content-Length') === '0') {
        throw new Error('Empty response you may not have permission.');
      }

      return response.text();
    })
    .then((text) => {
      if (!text) {
        throw new Error('Empty response you may not have permission.');
      }

      try {
        const json = JSON.parse(text);
        return json;
      } catch (e) {
        if (text.indexOf('/oauth2/start') > -1) {
          window.location?.reload();
        }

        throw new Error(text);
      }
    })
    .catch((error: Error) => {
      throw error;
    });
};

export default fetchJson;

const handleError = (response: Response) => {
  // If the response was not JSON
  if (response.headers.get('content-type') !== 'application/json') {
    return response.text().then((text) => {
      throw new Error(text);
    });
  }
  return response.json().then((body) => {
    // If the body was successfully parsed as JSON
    const bodyJsonKeys = Object.keys(body);
    if (body.message) {
      throw new Error(body.message);
    } else if (body.Message) {
      throw new Error(body.Message);
    } else if (body.reason) {
      throw new Error(body.reason);
    } else if (bodyJsonKeys.length === 1) {
      throw new Error(body[bodyJsonKeys[0]]);
    } else if (bodyJsonKeys.length > 1) {
      throw new Error(JSON.stringify(body));
    } else {
      throw new Error(response.statusText);
    }
  });
};
