import { labelTransformer } from './labelTransformer';
import {
  prometheusDataTransformer,
  prometheusDataTransformerInstant,
} from './rangeTransformer';
import { timeseriesDataTransformer } from './visualTransformer';

const metricsDataTransformer = (isInstant?: boolean) => {
  if (isInstant) {
    return [
      {
        id: 'prometheusToDataFrame',
        func: prometheusDataTransformerInstant,
      },
    ];
  }

  return [
    {
      id: 'prometheusToDataFrame',
      func: prometheusDataTransformer,
    },
    {
      id: 'transformToDisplayLabel',
      func: labelTransformer,
    },
    {
      id: 'transformToTimeseries',
      func: timeseriesDataTransformer,
    },
  ];
};

export default metricsDataTransformer;
