import { InputWithValidatorV3 } from 'components';
import React, { Dispatch, SetStateAction, ReactElement, useState } from 'react';

import { AlertMuteTimingProps } from './types';

const AlertsMuteTimingEditDaysOfMonth = ({
  clearErrorByIndex,
  errorText,
  intervalIdx,
  muteInterval,
  setMuteInterval,
}: {
  clearErrorByIndex: (idx: number, key: string) => void;
  errorText: string;
  intervalIdx: number;
  muteInterval: AlertMuteTimingProps['time_intervals'][0];
  setMuteInterval: Dispatch<SetStateAction<AlertMuteTimingProps>>;
}): ReactElement => {
  const { days_of_month } = muteInterval;
  const [daysOfMonth, setDaysOfMonth] = useState<string>(
    days_of_month.join(', '),
  );

  const handleDayOfMonthChange = (val: string) => {
    setMuteInterval((prev) => {
      const newState = { ...prev };
      const newIntervals = { ...newState.time_intervals[intervalIdx] };
      newIntervals.days_of_month = val
        .split(',')
        .map((d) => d.trim())
        .filter(Boolean);
      newState.time_intervals[intervalIdx] = newIntervals;
      setDaysOfMonth(newIntervals.days_of_month.join(', '));
      return newState;
    });
  };

  return (
    <div className="flex flex-col pt-2">
      <div className="text-sm font-semibold">Days of Month</div>
      <div className="pb-1 text-xs text-text-secondary">
        The days of the month, 1-31, of a month. Negative values can be used to
        represent days which begin at the end of the month
      </div>
      <div className="max-w-[380px]">
        <InputWithValidatorV3
          className="rounded-sm"
          placeholder="Example: 1, 14:16, -1"
          size="4"
          type="text"
          variant="default"
          onChange={(val) => {
            setDaysOfMonth(val);
            clearErrorByIndex(intervalIdx, 'days_of_month');
          }}
          value={daysOfMonth}
          onBlur={() => handleDayOfMonthChange(daysOfMonth)}
          error={errorText}
          errorMessageShownType="inline"
        />
      </div>
    </div>
  );
};

export default AlertsMuteTimingEditDaysOfMonth;
