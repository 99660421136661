import { drawLineOnTimeseries, themeColorDark, themeColorLight } from 'utils';
import { LayoutType, TooltipCoordsProps } from 'types';

import { calculateNextTooltipPosition } from '../../Timeseries/utils';
import { Hexagon, KfuseHostmapProps } from '../types';
import { drawHoveredHexagon } from './hostmapDrawUtils';

/**
 * This function handles the display of a tooltip for a hexagon on a hostmap.
 * @param {Object} params - An object containing the following properties:
 *    - darkModeEnabled: A boolean indicating whether dark mode is enabled.
 *    - h: An object representing the hostmap.
 *    - hexagon: An object representing the hexagon for which the tooltip should be displayed.
 *    - layoutType: A string indicating the layout type of the hostmap.
 *
 * @returns {TooltipCoordsProps}
 */
export const handleHostmapTooltip = ({
  darkModeEnabled,
  h,
  hexagon,
  layoutType,
}: {
  darkModeEnabled: boolean;
  h: KfuseHostmapProps;
  hexagon: Hexagon;
  layoutType: LayoutType;
}): TooltipCoordsProps => {
  h.clearTooltipCanavas();
  if (!hexagon) return;

  const bbox = h.root.getBoundingClientRect();
  const nextPos = calculateNextTooltipPosition({
    bbox,
    cursorLeft: hexagon.x,
    cursorTop: hexagon.y,
  });

  drawHoveredHexagon(h.tooltipCtx, hexagon);

  // calculate color
  const bgColor = darkModeEnabled
    ? themeColorLight.light03
    : themeColorDark.dark03;

  // draw lines till the tooltip data point
  if (nextPos) {
    const { x, y } = nextPos;
    drawLineOnTimeseries({
      ctx: h.tooltipCtx,
      start: { x: hexagon.x, y: hexagon.y },
      end: { x, y },
      options: { color: bgColor, lineWidth: 2 },
    });
  }

  const nextPosX = nextPos.x / devicePixelRatio;
  const nextPosY = nextPos.y / devicePixelRatio;

  if (layoutType === 'dashboard' && nextPos) {
    const TOP_OFFSET = 34;
    return {
      x: nextPosX,
      y: nextPosY + TOP_OFFSET,
      positionX: nextPos.positionX,
      positionY: nextPos.positionY,
      data: {
        label: hexagon.data.label,
        value: hexagon.data.value,
        color: null,
      },
    };
  }

  if (layoutType === 'modal') {
    // All modal that has a chart must maintain a margin offset of 24px
    const MODEL_MARGIN_OFFSET = 24;
    return {
      x: nextPosX + bbox.left - MODEL_MARGIN_OFFSET,
      y: nextPosY + bbox.top - MODEL_MARGIN_OFFSET,
      positionX: nextPos.positionX,
      positionY: nextPos.positionY,
      data: {
        label: hexagon.data.label,
        value: hexagon.data.value,
        color: null,
      },
    };
  }

  return {
    x: nextPosX + bbox.left,
    y: nextPosY + bbox.top,
    positionX: nextPos.positionX,
    positionY: nextPos.positionY,
    data: {
      label: hexagon.data.label,
      value: hexagon.data.value,
      color: null,
    },
  };
};
