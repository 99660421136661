import { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { RequestState, SubscriptionArgs } from 'types';
import { v4 as uuidV4 } from 'uuid';
import { getWebsocketBaseUrl } from 'utils';

enum Action {
  closeAttempt = 'closeAttempt',
  closeError = 'closeError',
  closeSkipped = 'closeSkipped',
  closeSuccess = 'closeSucces',
  errorEvent = 'errorEvent',
  handleDataType = 'handleDataType',
  isNotMountedEarlyReturn = 'isNotMountedEarlyReturn',
  openEvent = 'openEvent',
  messageEvent = 'messageEvent',
  sendingConnectionInitMessage = 'sendingConnectionInitMessage',
  sendingQuery = 'sendingQuery',
  serverCloseEvent = 'serverCloseEvent',
}

const log = ({
  action,
  data,
  message,
}: {
  action: string;
  data?: Record<string, unknown>;
  message?: string;
}) => {
  Sentry.addBreadcrumb({
    category: 'useSubscriptionRequest',
    message: `${action}${message ? `: ${message}` : ''}`,
    level: 'info',
    data: {
      ...(data ? data : {}),
      action,
    },
  });
};

const useSubscriptionRequest = ({
  key,
  merge,
  initialState,
  onReceivedMessagePayload,
  onDone,
  queryBuilder,
}: SubscriptionArgs) => {
  const websocketUrl = `${getWebsocketBaseUrl()}/query`;

  const connectionInitTimeoutRef = useRef<Timeout>(null);
  const completedTimeoutRef = useRef<Timeout>(null);
  const requestIdRef = useRef(null);
  const socketRef = useRef(null);
  const isMounted = useRef(true);
  const [state, setState] = useState<RequestState>({
    calledAtLeastOnce: false,
    error: null,
    isLoading: false,
    result: initialState,
  });

  const close = () => {
    const requestId = requestIdRef.current;
    const logWithRequestId = ({
      action,
      data,
    }: {
      action: Action;
      data?: Record<string, unknown>;
    }) => {
      log({ action, data: { ...(data ? data : {}), requestId } });
    };

    logWithRequestId({ action: Action.closeAttempt });
    const socket = socketRef.current;

    if (socket) {
      const { readyState } = socket;
      if (readyState === 1) {
        try {
          socket.close();
          logWithRequestId({ action: Action.closeSuccess });
        } catch (e) {
          logWithRequestId({
            action: Action.closeError,
            data: { error: e.toString() },
          });
          Sentry.captureException(e);
        }
      } else {
        logWithRequestId({
          action: Action.closeSkipped,
          data: {
            socketReadyState: readyState,
          },
        });
      }
    }
  };

  const call = (...args: any[]) =>
    new Promise((resolve, reject) => {
      const query = queryBuilder(...args);
      const requestId = uuidV4();
      requestIdRef.current = requestId;

      const logWithRequestIdAndQuery = ({
        action,
        data,
      }: {
        action: string;
        data?: Record<string, unknown>;
      }) => {
        log({
          action,
          data: {
            ...(data ? data : {}),
            requestId,
            query,
          },
        });
      };

      const runIfCurrentRequest =
        (handler: (arg?: any) => void) => (arg: any) => {
          if (requestId === requestIdRef.current) {
            handler(arg);
          }
        };

      setState((prevState) => ({
        ...prevState,
        error: null,
        calledAtLeastOnce: true,
        isLoading: true,
        result: initialState,
      }));

      close();

      const socket = new WebSocket(websocketUrl, 'graphql-ws');

      socket.addEventListener(
        'open',
        runIfCurrentRequest((event) => {
          logWithRequestIdAndQuery({ action: Action.openEvent });
          logWithRequestIdAndQuery({
            action: Action.sendingConnectionInitMessage,
          });
          connectionInitTimeoutRef.current = setTimeout(() => {
            Sentry.captureException(
              new Error(
                'connection_ack not received within 20 seconds of sending connection_init',
              ),
            );
          }, 20 * 1000);
          socket.send(JSON.stringify({ type: 'connection_init' }));
        }),
      );

      socket.addEventListener(
        'close',
        runIfCurrentRequest((event) => {
          logWithRequestIdAndQuery({ action: Action.serverCloseEvent });
          setState((prevState) => {
            if (onDone) {
              onDone();
            }

            close();
            resolve(prevState.result);

            return {
              ...prevState,
              isLoading: false,
              error: event.wasClean ? new Error('Websocket closed') : null,
            };
          });
        }),
      );

      socket.addEventListener(
        'error',
        runIfCurrentRequest((event) => {
          logWithRequestIdAndQuery({ action: Action.errorEvent });
          if (!isMounted.current) {
            logWithRequestIdAndQuery({
              action: Action.isNotMountedEarlyReturn,
            });
            return;
          }

          setState((prevState) => {
            if (onDone) {
              onDone();
            }
            close();
            resolve(prevState.result);

            return {
              ...prevState,
              isLoading: false,
              error: event,
            };
          });
        }),
      );

      socket.addEventListener(
        'message',
        runIfCurrentRequest((event) => {
          logWithRequestIdAndQuery({
            action: Action.messageEvent,
          });
          const data = JSON.parse(event.data);
          logWithRequestIdAndQuery({
            action: Action.handleDataType,
            data: { dataType: data.type },
          });
          if (data.type === 'connection_ack') {
            clearTimeout(connectionInitTimeoutRef.current);
            completedTimeoutRef.current = setTimeout(() => {
              Sentry.captureException(
                new Error(
                  'complete not received within 20 seconds after sending query',
                ),
              );
            }, 20 * 1000);
            logWithRequestIdAndQuery({ action: Action.sendingQuery });
            socket.send(
              JSON.stringify({
                id: '1',
                type: 'start',
                payload: {
                  variables: {},
                  extensions: {},
                  operationName: null,
                  query,
                },
              }),
            );
          }

          if (data.type === 'data' && data.payload?.data) {
            if (requestIdRef.current === requestId) {
              const messagePayload = data.payload.data[key];
              if (onReceivedMessagePayload) {
                onReceivedMessagePayload(messagePayload);
              }

              setState((prevState) => ({
                ...prevState,
                result: merge(prevState.result, messagePayload, args),
              }));
            }
          }

          if (data.type === 'complete') {
            setState((prevState) => {
              if (onDone) {
                onDone();
              }
              close();
              resolve(prevState.result);
              return {
                ...prevState,
                isLoading: false,
              };
            });
          }
        }),
      );

      socketRef.current = socket;
    });

  useEffect(() => {
    return () => {
      close();
      clearTimeout(connectionInitTimeoutRef.current);
      clearTimeout(completedTimeoutRef.current);
      isMounted.current = false;
    };
  }, []);

  return {
    ...state,
    call,
    close,
  };
};

export default useSubscriptionRequest;
