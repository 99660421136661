import { fetchJson } from 'requests';

const createPolicyToGroup = async (
  policyName: string,
  groupName: string,
): Promise<Record<string, string>> => {
  const result = await fetchJson(`rbac/rbacconfig/`, {
    method: 'POST',
    body: JSON.stringify({ policy: policyName, group: groupName }),
  });

  return result as Record<string, string>;
};

export default createPolicyToGroup;
