import { useUrlQueryStringReducer } from 'hooks';
import { useReducer } from 'react';
import {
  FilterActionType,
  FilterMappedMethods,
  FilterMethods,
  FilterMethodByType,
  FilterState,
  FilterStructure,
} from 'types';

import filterReducer from './filterReducer';

const initialState: FilterState = {
  searchTerms: [],
  filterByFacets: [],
  sidebarFilters: {},
  facetRangeFilters: {},
  keyExists: {},
};

const commonMethods = [
  'removeFilterByKey',
  'replaceFilterByKey',
  'clearFiltersByKey',
  'clearAllFilters',
];
const filterMappedMethos = {
  array: [
    'addFilter',
    'updateFilterByIndex',
    'removeFilterByIndex',
    ...commonMethods,
  ],
  map: [
    'addMappedFilter',
    'excludeFromMappedFilter',
    'removeMappedFilterByKey',
    'toggleMappedFilterByKey',
    'updateMappedFilterByKey',
    ...commonMethods,
  ],
  object: ['addFilter', 'removeFilterByKey', ...commonMethods],
};

const defaultFilterStructure = {
  searchTerms: { dataType: 'array', tagPrefix: 'search' },
  filterByFacets: { dataType: 'array', tagPrefix: '' },
  sidebarFilters: { dataType: 'map', tagPrefix: '' },
  facetRangeFilters: { dataType: 'map', tagPrefix: '' },
  keyExists: { dataType: 'map', tagPrefix: 'key exist' },
};

const useFilterState = ({
  shouldWriteToUrl = true,
  filterStructure = defaultFilterStructure,
}: {
  filterStructure?: FilterStructure;
  shouldWriteToUrl?: boolean;
}) => {
  const [state, dispatch] = useReducer(filterReducer, initialState);

  useUrlQueryStringReducer({
    key: 'filter',
    state,
    dispatch,
    shouldWriteToUrl,
  });
  const removeFilterByIndex = (index: number, filterKey: string) => {
    dispatch({
      type: FilterActionType.REMOVE_FILTER_BY_INDEX,
      index,
      filterKey,
    });
  };

  const removeFilterByKey = (filterKey: string, payload: any) => {
    dispatch({
      type: FilterActionType.REMOVE_FILTER_BY_KEY,
      filterKey,
      payload,
    });
  };

  const addFilter = (filterKey: string, payload: any) => {
    dispatch({
      type: FilterActionType.ADD_FILTER,
      filterKey,
      payload,
    });
  };

  const updateFilterByIndex = (
    index: number,
    filterKey: string,
    payload: any,
  ) => {
    dispatch({
      type: FilterActionType.UPDATE_FILTER_BY_INDEX,
      index,
      filterKey,
      payload,
    });
  };

  const addMappedFilter = (filterKey: string, mapKey: string, payload: any) => {
    dispatch({
      type: FilterActionType.ADD_MAPPED_FILTER,
      filterKey,
      mapKey,
      payload,
    });
  };

  const updateMappedFilterByKey = (
    filterKey: string,
    mapKey: string,
    payload: any,
  ) => {
    dispatch({
      type: FilterActionType.UPDATE_MAPPED_FILTER_BY_KEY,
      filterKey,
      mapKey,
      payload,
    });
  };

  const excludeFromMappedFilter = (
    filterKey: string,
    mapKey: string,
    payload: any,
  ) => {
    dispatch({
      type: FilterActionType.EXCLUDE_FROM_MAPPED_FILTER,
      filterKey,
      mapKey,
      payload,
    });
  };

  const removeMappedFilterByKey = (filterKey: string, mapKey: string) => {
    dispatch({
      type: FilterActionType.REMOVED_MAPPED_FILTER_BY_KEY,
      filterKey,
      mapKey,
    });
  };

  const toggleMappedFilterByKey = (
    filterKey: string,
    mapKey: string,
    payload: any,
    allFacetValues: Array<string>,
    selectedFacetValues: Record<string, number>,
  ) => {
    dispatch({
      type: FilterActionType.TOGGLE_MAPPER_FILTER_BY_KEY,
      filterKey,
      mapKey,
      payload,
      allFacetValues,
      selectedFacetValues,
    });
  };

  const replaceFilterByKey = (filterKey: string, payload: any) => {
    dispatch({
      type: FilterActionType.REPLACE_FILTER_BY_KEY,
      filterKey,
      payload,
    });
  };

  const clearAllFilters = () => {
    dispatch({
      type: FilterActionType.CLEAR_ALL_FILTERS,
      filterKey: '',
    });
  };

  const clearFiltersByKey = (filterKey: string) => {
    dispatch({
      type: FilterActionType.CLEAR_FILTERS_BY_KEY,
      filterKey,
    });
  };

  const methodDictionary: FilterMethods = {
    addFilter,
    addMappedFilter,
    clearAllFilters,
    clearFiltersByKey,
    excludeFromMappedFilter,
    removeFilterByIndex,
    removeFilterByKey,
    removeMappedFilterByKey,
    replaceFilterByKey,
    toggleMappedFilterByKey,
    updateFilterByIndex,
    updateMappedFilterByKey,
  };

  const getMethodAndStateByFilterKey = <T extends keyof FilterMappedMethods>(
    filterKey: string,
    dataType: T,
  ): FilterMethodByType<T> => {
    const result: FilterMethodByType<T> = {};
    const methods = filterMappedMethos[dataType];
    methods.forEach((method) => {
      result[method] = methodDictionary[method];
    });

    return {
      ...result,
      state: state[filterKey],
    };
  };

  return {
    addFilter,
    addMappedFilter,
    clearAllFilters,
    clearFiltersByKey,
    getMethodAndStateByFilterKey,
    filter: state,
    filterStructure,
    filterDispatch: dispatch,
    removeFilterByIndex,
    removeFilterByKey,
    replaceFilterByKey,
    excludeFromMappedFilter,
    removeMappedFilterByKey,
    toggleMappedFilterByKey,
    updateFilterByIndex,
    updateMappedFilterByKey,
  };
};

export default useFilterState;
