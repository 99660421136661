import { Loader } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useRef } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { getLogStackedBarCountsUsingMetrics, logSample } from 'requests';
import { Fingerprint, LogsState } from 'types';
import {
  convertNumberToReadableUnit,
  formatNumber,
  getRollupByVisualization,
} from 'utils';
import LogsFingerprintsListItemMain from './LogsFingerprintsListItemMain';
import LogsFingerprintsListItemChart from './LogsFingerprintsListItemChart';

const WIDTH = 100;

const getBarCount = (width: number) => Math.ceil(width / 4) + 1;

const getBucketSecs = (
  startTimeUnix: number,
  endTimeUnix: number,
  width: number,
) => {
  return getRollupByVisualization(
    { startTimeUnix, endTimeUnix },
    'Stacked Bar',
  );
};

type Props = {
  clearTabFetched: VoidFunction;
  fingerprint: Fingerprint & { groups: { [key: string]: string } };
  fingerprintGroupByKeys: string[];
  getFpListRequest?: ReturnType<typeof useRequest>;
  logsState: LogsState;
};

const LogsFingerprintsListItem = ({
  clearTabFetched,
  fingerprint,
  fingerprintGroupByKeys,
  getFpListRequest,
  logsState,
}: Props): ReactElement => {
  const fetchedRef = useRef(false);
  const { hash } = fingerprint;
  const { date } = logsState;
  const bucketSecs = getBucketSecs(date.startTimeUnix, date.endTimeUnix, WIDTH);
  const getLogCountsRequest = useRequest(getLogStackedBarCountsUsingMetrics);
  const logSampleRequest = useRequest(logSample);

  const onChange = (isVisible: boolean) => {
    if (isVisible && !fetchedRef.current) {
      fetchedRef.current = true;
      getLogCountsRequest.call({
        bucketSecs,
        logsState: {
          ...logsState,
          filterOrExcludeByFingerprint: { [hash]: true },
        },
      });

      logSampleRequest.call({ fingerprint: hash, logsState });
    }
  };

  const message = logSampleRequest.result?.message;
  const pattern = logSampleRequest.result?.fpPattern;

  return (
    <VisibilitySensor onChange={onChange}>
      <div className="logs__fingerprints-list__item">
        {fingerprintGroupByKeys.map((key: string, idx: number) => (
          <div
            key={`${key}:${fingerprint.groups[key]}:${idx}`}
            className="logs__fingerprints-list__item__source"
          >
            {fingerprint.groups[key]}
          </div>
        ))}
        <LogsFingerprintsListItemMain
          clearTabFetched={clearTabFetched}
          fingerprint={fingerprint}
          getFpListRequest={getFpListRequest}
          logsState={logsState}
          message={message || null}
          pattern={pattern || null}
        />
        <div
          className="logs__fingerprints-list__item__count"
          data-tooltip={formatNumber(fingerprint.count)}
        >
          {convertNumberToReadableUnit(fingerprint.count)}
        </div>
        <Loader
          className="logs__fingerprints-list__item__chart"
          isLoading={getLogCountsRequest.isLoading}
        >
          <LogsFingerprintsListItemChart
            bucketSecs={bucketSecs}
            date={date}
            logCounts={getLogCountsRequest.result || []}
            width={WIDTH}
          />
        </Loader>
      </div>
    </VisibilitySensor>
  );
};

export default LogsFingerprintsListItem;
