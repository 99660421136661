import { MutableRefObject, useEffect, useLayoutEffect, useRef } from 'react';
import {
  getSelectionRange,
  setCaretPosition,
  setCaretPositionExact,
  textFrom,
} from './dom';

export default function useBeforeInput(
  ref: MutableRefObject<HTMLElement>,
  onChange?: (next: string, current: string) => void,
) {
  const caretPosition = useRef<number>(-1);

  useLayoutEffect(() => {
    const observer = new MutationObserver(([entry]) => {
      // Case: update from user key input
      if (caretPosition.current >= 0) {
        setCaretPosition(ref.current, caretPosition.current);
      }
      // Case: update from program
      else {
        console.log('mutation observer by change');
        setCaretPositionExact(entry.target, entry.target.textContent.length);
      }

      caretPosition.current = -1;
    });

    observer.observe(ref.current, {
      subtree: true,
      childList: true,
      characterData: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  useEffect(() => {
    const { current } = ref;

    async function onBeforeInput(evt: InputEvent) {
      evt.preventDefault();

      switch (evt.inputType) {
        case 'insertText':
        case 'insertReplacementText':
        case 'insertFromPaste':
        case 'insertFromDrop':
        case 'insertFromPasteAsQuotation':
        case 'insertTranspose':
        case 'insertLink': {
          const [start, end] = getSelectionRange(
            ref.current,
            document.getSelection().getRangeAt(0),
            'insert',
          );

          const { textContent } = evt.target as HTMLElement;
          const text = await textFrom(evt);
          const next = `${textContent.slice(
            0,
            start,
          )}${text}${textContent.slice(end)}`;

          caretPosition.current = start + text.length;

          onChange(next, text);

          break;
        }
        case 'insertLineBreak':
        case 'insertParagraph': {
          //         return (0, F.i)(
          //           e,
          //           `
          // `,
          //           t.range || e.selection,
          //           `${t.type}-${r}`,
          //         );
        }
        case 'deleteContentBackward':
        case 'deleteContentForward':
        case 'deleteWordBackward':
        case 'deleteWordForward':
        case 'deleteHardLineBackward':
        case 'deleteHardLineForward':
        case 'deleteSoftLineBackward':
        case 'deleteSoftLineForward': {
          const [start, end] = getSelectionRange(
            ref.current,
            document.getSelection().getRangeAt(0),
            'delete',
          );
          const { textContent } = evt.target as HTMLElement;
          const next = `${textContent.slice(
            0,
            Math.max(0, start),
          )}${textContent.slice(end)}`;

          caretPosition.current = start; // TODO does this work with multi delete? aka cut?

          onChange(next, textContent);

          break;
        }
        case 'deleteEntireSoftLine':
        case 'deleteContent':
        case 'deleteByCut':
        case 'deleteByDrag': {
          // return (0, F.h)(e, t.range || e.selection, `${t.type}-${r}`);
        }
        case 'historyUndo':
        case 'historyRedo': {
        }
        default:
          return;
      }
    }

    current.addEventListener('beforeinput', onBeforeInput);

    return () => {
      current.removeEventListener('beforeinput', onBeforeInput);
    };
  }, [onChange, ref]);
}
