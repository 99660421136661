import { AutocompleteOption, AutocompleteV2, SelectV2 } from 'components';
import React, { ReactElement } from 'react';
import { OUTLIER_OPTIONS } from 'utils';

import {
  AlertsCreateConditionFor,
  AlertsCreateConditionInfo,
  AlertsCreateConditionQueryKey,
  useAlertsCreateCondition,
} from '../AlertsCreateCondition';
import { useAlertsCreate } from '../hooks';
import { toleranceTypeOptions } from './utils';
import { outliersAlertPopupMessage } from '../constants';

const AlertCreateOutlierCondition = ({
  alertsCreateState,
  conditionState,
  queryAndFormulaKeysLabel,
}: {
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
  queryAndFormulaKeysLabel: AutocompleteOption[];
}): ReactElement => {
  const { outlierCondition, setOutlierCondition } = conditionState;
  const { evaluate, setEvaluate } = alertsCreateState;
  return (
    <div>
      <div className="alerts__create__section__item">
        <div className="alerts__create__conditions__item__text">
          Trigger when outliers are detected on evaluated value of
        </div>
        <AlertsCreateConditionQueryKey
          conditionState={conditionState}
          queryAndFormulaKeysLabel={queryAndFormulaKeysLabel}
        />
        <div className="alerts__create__conditions__item__text">over</div>
        <AlertsCreateConditionFor
          evaluate={evaluate}
          ruleType={alertsCreateState.ruleType}
          setEvaluate={setEvaluate}
        />
      </div>
      <div className="alerts__create__section__header">
        Outlier algorithm options
      </div>
      <div className="alerts__create__section__item">
        <div className="alerts__create__conditions__item__text">
          Using Algorithm
        </div>
        <div className="alert__create__outliers__description__container">
          <AlertsCreateConditionInfo
            description={outliersAlertPopupMessage.DBSCAN_ALGORITHM_DESCRIPTION}
            algorithm="dbscan"
          />
        </div>
        <div className="alerts__create__conditions__item__input">
          <AutocompleteV2
            onChange={(val: string) =>
              setOutlierCondition({ ...outlierCondition, algorithm: val })
            }
            options={[
              ...[{ label: 'Please select', value: '' }],
              ...OUTLIER_OPTIONS,
            ]}
            value={outlierCondition.algorithm}
            placeholder={'Select'}
          />
        </div>
        {outlierCondition.algorithm && (
          <>
            <div className="alerts__create__conditions__item__text">
              tolerance:
            </div>
            <div className="alerts__create__conditions__item__input alerts__create__conditions__item__input--select">
              <SelectV2.Select
                onChange={(val: string) =>
                  setOutlierCondition({ ...outlierCondition, tolerance: val })
                }
                options={toleranceTypeOptions}
                value={outlierCondition.tolerance}
                onEnter={(val: string) =>
                  setOutlierCondition({ ...outlierCondition, tolerance: val })
                }
                isAutocompleteEnabled
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AlertCreateOutlierCondition;
