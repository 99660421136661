import { DateSelection } from 'types/DateSelection';
import { onPromiseError } from 'utils';

import fetchJson from './fetchJson';

const getMetricsList = (
  date: DateSelection,
  matcher?: string,
): Promise<string[]> => {
  const { endTimeUnix, startTimeUnix } = date;
  let params = `start=${startTimeUnix}&end=${endTimeUnix}`;
  if (matcher) {
    params += `&match[]=${matcher}`;
  }

  return fetchJson(`/api/v1/label/__name__/values?${params}`).then(
    (result) => result?.data || [],
    onPromiseError,
  );
};

export default getMetricsList;
