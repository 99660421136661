import { Stepper } from 'components';
import classNames from 'classnames';
import { useRequest, useSearches, useSearchFormulas } from 'hooks';
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import {
  DashboardPanelProps,
  DashboardPanelType,
  DashboardStreamType,
  QueryDataProps,
} from 'types';
import useDebouncedEffect from 'use-debounced-effect';
import {
  combineInstantQueryData,
  combineRangeQueryData,
  rumDataTransformer,
} from 'utils/DataTransformer';

import {
  DashboardPanelModalFooter,
  DashboardPanelModalHeader,
} from '../components';
import { useDashboardEditState } from './hooks';
import DashboardEditPanelName from './DashboardEditPanelName';
import DashboardEditStreamType from './DashboardEditStreamType';
import DashboardEditMetricsVisualType from './DashboardEditMetricsVisualType';
import {
  DashboardEditGraphGeomap,
  DashboardEditGraphSunburst,
  DashboardEditGraphTable,
  DashboardEditGraphTimeseries,
  DashboardEditGraphToplist,
  DashboardEditGraphTreemap,
} from './DashboardEditGraph';
import {
  EventTypeFilter,
  RumApplicationFilter,
  RumEventType,
  RumSearchAnalytics,
  rumAnalyticsFetchMulti,
  rumLabels,
  useRumState,
} from 'screens/Rum';
import {
  defaultTransformations,
  rumExtendSupportedEditGraphList,
  getRumPanelExprToQueryAndFormula,
  getRumPanelExpr,
} from './utils';

const DashboardEditRum = ({
  baseWidth,
  close,
  dashboardEditState,
  panel,
  panelType,
}: {
  baseWidth: number;
  close: () => void;
  dashboardEditState: ReturnType<typeof useDashboardEditState>;
  panel: DashboardPanelProps;
  panelType?: DashboardPanelType;
}): ReactElement => {
  const { date, editPanel, onSaveClickRum, setDate } = dashboardEditState;
  const { queries: parsedQueries } = getRumPanelExprToQueryAndFormula(panel);
  const { searches } = useSearches({
    shouldWriteToUrl: false,
    initialSearches: parsedQueries,
  });
  const queries = searches.map((s) => s.state);
  const searchesFormulas = useSearchFormulas(queries, []);
  const rumState = useRumState({
    dateState: [date, setDate],
    shouldWriteToUrl: false,
    initialEventType: RumEventType.VIEW,
  });
  const { applicationFilter, eventType, state } = rumState;
  const {
    selectedFacetValuesByName,
    facetRegex,
    idSearch,
    selectedFacetRangeByNameState,
  } = state;

  const rumLabelNamesRequest = useRequest(rumLabels);
  const rumAnalyticsFetchMultiRequest = useRequest(rumAnalyticsFetchMulti);

  const loadRumTimeseries = () => {
    const instant = panelType !== DashboardPanelType.TIMESERIES;
    if (!applicationFilter) return;
    const baseTransformers = rumDataTransformer(instant);

    const preparedQueries = searches.map((s) => s.state);
    const query = preparedQueries[0];

    rumAnalyticsFetchMultiRequest.call({
      applicationFilter,
      date,
      eventType,
      dataFormat: panelType,
      formulas: [],
      instant,
      query,
      transformer: baseTransformers,
      selectedFacetValuesByName,
      facetRegex,
      selectedFacetRangeByName: selectedFacetRangeByNameState,
      idSearch,
    });
  };

  useEffect(() => {
    if (panelType === DashboardPanelType.GEOMAP) {
      searches[0].setOnlyOneAdvancedGroupBy('geo.country');
    }

    if (
      (panelType as unknown as DashboardPanelType) !== DashboardPanelType.TABLE
    ) {
      const currentAggregations = searches[0].state.multiAggregations;
      if (currentAggregations?.length > 1) {
        searches[0].setOnlyOneMultiAggregationBy(
          currentAggregations[0].measure,
          currentAggregations[0].operation,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelType]);

  useDebouncedEffect(
    () => {
      loadRumTimeseries();
    },
    { timeout: 200, ignoreInitialCall: false },
    [
      rumState.applicationFilter,
      panelType,
      date,
      rumState.eventType,
      idSearch,
      searches,
      selectedFacetRangeByNameState,
      selectedFacetValuesByName,
    ],
  );

  useDebouncedEffect(
    () => {
      rumLabelNamesRequest.call({ eventType: rumState.eventType });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    { timeout: 200, ignoreInitialCall: false },
    [rumState.eventType],
  );

  const rumQueryData = useMemo(() => {
    const result: QueryDataProps = {};
    const queryId = 'query_a';
    const rangeKey =
      panelType === DashboardPanelType.TIMESERIES ? 'range' : 'instant';
    if (rumAnalyticsFetchMultiRequest.isLoading) {
      result[queryId] = { isLoading: true, range: undefined, meta: undefined };
    }

    if (!rumAnalyticsFetchMultiRequest.result) {
      result[queryId] = { isLoading: false, range: undefined, meta: undefined };
    }

    if (rumAnalyticsFetchMultiRequest.result) {
      result[queryId] = {
        isLoading: false,
        [rangeKey]: rumAnalyticsFetchMultiRequest.result,
        meta: rumAnalyticsFetchMultiRequest.result.meta,
      };
    }

    return result;
  }, [
    panelType,
    rumAnalyticsFetchMultiRequest.isLoading,
    rumAnalyticsFetchMultiRequest.result,
  ]);

  const dataFormatterRange = useCallback(() => {
    if (editPanel.type !== DashboardPanelType.TIMESERIES) return null;
    return combineRangeQueryData({
      formulas: [],
      queries: [{ isActive: true, queryKey: 'a' }],
      queryData: rumQueryData,
      combineTimestamp: true,
      combineLabelWithQueryKey: true,
    });
  }, [editPanel.type, rumQueryData]);

  const dataFormatterInstant = useCallback(() => {
    if (editPanel.type === DashboardPanelType.TIMESERIES) return null;
    return combineInstantQueryData({
      dataFormat: editPanel.type,
      queries: [{ isActive: true, queryKey: 'a' }],
      queryData: rumQueryData,
      formulas: [],
      transformations: defaultTransformations({
        dataFormat: editPanel.type,
        stream: DashboardStreamType.TRACE,
      }),
    });
  }, [editPanel.type, rumQueryData]);

  return (
    <>
      <DashboardPanelModalHeader
        close={close}
        modalDate={date}
        setModalDate={setDate}
        title={!panel ? 'Add Panel' : editPanel.title || 'Edit Panel'}
      />
      <div className={classNames({ 'dashboard-edit__metric__body': true })}>
        {editPanel.type === DashboardPanelType.TIMESERIES && (
          <DashboardEditGraphTimeseries
            baseWidth={baseWidth}
            onDateChange={setDate}
            panel={editPanel}
            dataFormatter={dataFormatterRange}
          />
        )}
        {editPanel.type === DashboardPanelType.TOP_LIST && (
          <DashboardEditGraphToplist
            baseWidth={baseWidth}
            dataFormatter={dataFormatterInstant}
          />
        )}
        {editPanel.type === DashboardPanelType.TABLE && (
          <DashboardEditGraphTable dataFormatter={dataFormatterInstant} />
        )}
        {editPanel.type === DashboardPanelType.PIECHART && (
          <DashboardEditGraphSunburst
            baseWidth={baseWidth}
            dataFormatter={dataFormatterInstant}
            panel={editPanel}
          />
        )}
        {editPanel.type === DashboardPanelType.TREEMAP && (
          <DashboardEditGraphTreemap
            baseWidth={baseWidth}
            dataFormatter={dataFormatterInstant}
          />
        )}
        {editPanel.type === DashboardPanelType.GEOMAP && (
          <DashboardEditGraphGeomap
            baseWidth={baseWidth}
            dataFormatter={dataFormatterInstant}
          />
        )}
        <Stepper
          steps={[
            {
              title: 'Choose Visualization',
              component: (
                <DashboardEditMetricsVisualType
                  dashboardEditState={dashboardEditState}
                  hideVisualizationTypes={[
                    DashboardPanelType.GRAFANA_POLYSTAT_PANEL,
                  ]}
                  extendVisualizationTypes={rumExtendSupportedEditGraphList}
                />
              ),
            },
            {
              title: 'Choose Application and Event Type',
              component: (
                <div className="flex flex-row items-center gap-6">
                  <div className="flex flex-row items-center gap-2">
                    <label className="text-sm font-medium">
                      Application Filter
                    </label>
                    <RumApplicationFilter hideHeader rumState={rumState} />
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <label className="text-sm font-medium">Event Type</label>
                    <EventTypeFilter rumState={rumState} />
                  </div>
                </div>
              ),
            },
            {
              title: 'Graph your data',
              component: (
                <>
                  {!panel && (
                    <div className="dashboard__widget__metrics-query-builder">
                      <DashboardEditStreamType
                        setStream={dashboardEditState.setStream}
                        stream={dashboardEditState.stream}
                      />
                    </div>
                  )}
                  <RumSearchAnalytics
                    placeholder="Search for a metric"
                    searches={searches}
                    rumTab={panelType}
                    rumLabelNamesRequest={rumLabelNamesRequest}
                    rumState={rumState}
                    searchesFormulas={searchesFormulas}
                  />
                </>
              ),
            },
            {
              title: 'Enter chart title',
              component: (
                <DashboardEditPanelName
                  dashboardEditState={dashboardEditState}
                />
              ),
            },
          ]}
        />
      </div>
      <DashboardPanelModalFooter
        close={close}
        onSave={() => {
          const newExpr = getRumPanelExpr({
            editedPanel: editPanel,
            applicationFilter,
            facetRegex,
            eventType,
            queries,
            selectedFacetRangeByName: selectedFacetRangeByNameState,
            selectedFacetValuesByName,
            idSearch,
          });
          onSaveClickRum({ newExpr });
        }}
      />
    </>
  );
};

export default DashboardEditRum;
