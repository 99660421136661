import { useUrlState } from 'hooks';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import { LogsMetricForumlaProps, LogsMetricQueryProps } from 'types';
import { validateLogqlFormulaExpression } from 'utils';

import useLogsMetricsQueryData from './useLogsMetricsQueryData';

const defaultFormula: LogsMetricForumlaProps = {
  expression: '',
  isValid: false,
  isActive: true,
};

const useLogsMetricsQueryFormula = ({
  chartWidth,
  isRange = true,
  queries,
  logsMetricsQueryData,
  urlCleanUp = false,
}: {
  chartWidth?: number;
  isRange?: boolean;
  logsMetricsQueryData: ReturnType<typeof useLogsMetricsQueryData>;
  queries: LogsMetricQueryProps[];
  urlCleanUp?: boolean;
}) => {
  const [formulas, setFormulas] = useUrlState<LogsMetricForumlaProps[]>(
    'LogsMetricsFormulas',
    [],
    urlCleanUp,
  );
  const {
    setLogsChartData,
    loadInstantMultipleLogsChartData,
    loadMultipleLogsChartData,
  } = logsMetricsQueryData;

  const addFormula = (deActivateOtherFormulas: boolean) => {
    const formulaLength = formulas.length;
    let newFormulasKey = formulaLength === 0 ? '1' : '';
    if (newFormulasKey === '') {
      const lastFormula = formulas[formulaLength - 1];
      const lastFormulaKey = lastFormula.queryKey;
      const lastFormulaKeyNumber = Number(lastFormulaKey);
      newFormulasKey = `${lastFormulaKeyNumber + 1}`;
    }

    const newFormula = { ...defaultFormula, queryKey: `${newFormulasKey}` };
    if (deActivateOtherFormulas) {
      setFormulas((prev) => [
        ...prev.map((formula) => ({ ...formula, isActive: false })),
        newFormula,
      ]);
    } else {
      setFormulas((prev) => [...prev, ...[newFormula]]);
    }
  };

  const deactivateAllFormulas = () => {
    setFormulas((prevFormulas) =>
      prevFormulas.map((formula) => ({ ...formula, isActive: false })),
    );
  };

  const clearFormulas = () => {
    setFormulas([]);
  };

  const activateOnlySingleFormula = (index: number) => {
    setFormulas((prevFormulas) =>
      prevFormulas.map((formula, i) => ({
        ...formula,
        isActive: i === index,
      })),
    );
  };

  const removeFormula = (queryKey: string) => {
    const index = formulas.findIndex(
      (formula: LogsMetricForumlaProps) => formula.queryKey === queryKey,
    );
    setFormulas((prevState: LogsMetricForumlaProps[]) =>
      prevState.filter((_, i) => i !== index),
    );

    setLogsChartData((prevState: any) => {
      const newState = { ...prevState };
      const queryId = `formula_${queryKey}`;
      delete newState[queryId];
      return newState;
    });
  };

  const callFormulaChangeQuery = useCallback(
    (
      formulas: LogsMetricForumlaProps[],
      latestQueries?: LogsMetricQueryProps[],
    ) => {
      const callArgs = {
        formulaOnly: true,
        queries: latestQueries || queries,
        chartWidth,
      };
      if (isRange) {
        loadMultipleLogsChartData({ ...callArgs, formulas });
      } else {
        loadInstantMultipleLogsChartData({ ...callArgs, formulas });
      }
    },
    [
      isRange,
      loadMultipleLogsChartData,
      loadInstantMultipleLogsChartData,
      queries,
      chartWidth,
    ],
  );

  const callFormulaQueryDebounced = useCallback(() => {
    const debouncedFunc = debounce(() => callFormulaChangeQuery(formulas), 300);
    debouncedFunc();
  }, [callFormulaChangeQuery, formulas]);

  const updateFormula = (index: number, propertyKey: string, value: any) => {
    setFormulas((prevState: LogsMetricForumlaProps[]) => {
      const newState = [...prevState];
      let formulaExpression = value;
      if (propertyKey === 'isActive') {
        formulaExpression = newState[index].expression;
      }

      const queryKeys = queries.map((query) => query.queryKey);
      const isValid =
        validateLogqlFormulaExpression(
          formulaExpression,
          queryKeys,
          queries,
        ) === true;
      newState[index][propertyKey] = value;
      newState[index].isValid = isValid;

      if (isValid && newState[index].isActive) {
        callFormulaQueryDebounced([newState[index]]);
      }
      return newState;
    });
  };

  const loadLogsAffectedFormula = (
    queryKey: string,
    latestQueries?: LogsMetricQueryProps[],
  ) => {
    const affectedFormulas = formulas.filter((formula) =>
      formula.expression.includes(queryKey),
    );
    if (affectedFormulas.length === 0) return;

    callFormulaChangeQuery(affectedFormulas, latestQueries);
  };

  return {
    addFormula,
    activateOnlySingleFormula,
    clearFormulas,
    deactivateAllFormulas,
    formulas,
    loadLogsAffectedFormula,
    setFormulas,
    removeFormula,
    updateFormula,
  };
};

export default useLogsMetricsQueryFormula;
