import { DataFrame } from '../types';

const legendFormatCustomTimeseries = (
  dataFrame: DataFrame,
  customFunc: (label: DataFrame['data'][0]['label']) => string,
): DataFrame => {
  const { data } = dataFrame;
  const transformedData = data.map((data) => ({
    ...data,
    displayLabel: customFunc(data.label),
  }));

  return {
    ...dataFrame,
    data: transformedData,
  };
};

export default legendFormatCustomTimeseries;
