import dayjs from 'dayjs';
import { LogsState } from 'types';
import { onPromiseError } from 'utils';

import query from './query';
import { buildQuery } from './utils';

type Args = {
  logsState: LogsState;
};

const getLabelNames = async ({ logsState }: Args): Promise<string[]> => {
  const { date } = logsState;
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const logQuery = buildQuery(logsState);

  return query<string[], 'getLabelNames'>(`
    query GetLabelNames {
      getLabelNames(
        durationSecs: ${durationSecs}
        ${logQuery !== '{}' ? `logQuery: ${logQuery},` : ''}
        timestamp: "${endTime.format()}",
      )
    }
  `).then((data) => data.getLabelNames || [], onPromiseError);
};

export default getLabelNames;
