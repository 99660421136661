import uPlot from 'uplot';
import { getNonRedColorForOneSeries } from 'utils/colors';

import { checkAnomalySeriesBandByLabel } from './checkAnomalySeriesBandByLabel';
import { drawLineWithMultiplePoints } from './drawUtils';
import getPositionsBySeriesIndex from './getPositionsBySeriesIndex';

const drawForecastSeriesByIndex = ({
  breakPoint,
  seriesIndex,
  u,
}: {
  breakPoint: number;
  seriesIndex: number;
  u: uPlot;
}): void => {
  const { ctx } = u;
  const series = u.series[seriesIndex];
  const isBand = checkAnomalySeriesBandByLabel(series.label);
  if (isBand) return;

  const positions = getPositionsBySeriesIndex(u, seriesIndex);

  const devicePixelRatio = window.devicePixelRatio || 1;
  const color = getNonRedColorForOneSeries({}, seriesIndex - 1);
  series._stroke = color;

  // draw solid line
  drawLineWithMultiplePoints({
    ctx,
    points: positions.slice(0, breakPoint),
    options: {
      color,
      lineWidth: series.width * devicePixelRatio,
    },
  });

  // draw dashed line
  drawLineWithMultiplePoints({
    ctx,
    points: positions.slice(breakPoint),
    options: {
      color,
      lineWidth: series.width * devicePixelRatio,
      dash: [6, 8],
    },
  });
};

export default drawForecastSeriesByIndex;
