import { fetchJson } from 'requests';

import { PolicyProps } from '../types';

const createPolicy = async (
  policy: PolicyProps,
): Promise<Record<string, string>> => {
  const result = await fetchJson(`rbac/policies/`, {
    method: 'POST',
    body: JSON.stringify(policy),
  });

  return result as Record<string, string>;
};

export default createPolicy;
