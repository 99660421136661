export const RangeFacetsMap = {
  'action.loading_time': 'DURATION',
  'action.long_task.count': 'NUMBER',
  'action.resource.count': 'NUMBER',
  'error.time_since_app_start': 'DURATION',
  'session.frustration.count': 'NUMBER',
  'view.frustration.count': 'NUMBER',
  'view.error.count': 'NUMBER',
  'view.action.count': 'NUMBER',
  'view.long_task.count': 'NUMBER',
  'view.crash.count': 'NUMBER',
  'session.action.count': 'NUMBER',
  'session.crash.count': 'NUMBER',
  'session.error.count': 'NUMBER',
  'session.view.count': 'NUMBER',
  'session.time_spent': 'DURATION',
  'display.viewport.height': 'NUMBER',
  'display.viewport.width': 'NUMBER',
  'view.loading_time': 'DURATION',
  'view.first_byte': 'DURATION',
  'view.time_spent': 'DURATION',
  'resource.encoded_body_size': 'BYTES',
  'resource.decoded_body_size': 'BYTES',
  'resource.size': 'BYTES',
  'resource.transfer_size': 'BYTES',
  'resource.connect.duration': 'DURATION',
  'resource.connect.start': 'DURATION',
  'resource.dns.duration': 'DURATION',
  'resource.dns.start': 'DURATION',
  'resource.download.duration': 'DURATION',
  'resource.download.start': 'DURATION',
  'resource.first_byte.duration': 'DURATION',
  'resource.first_byte.start': 'DURATION',
  'resource.redirect.duration': 'DURATION',
  'resource.redirect.start': 'DURATION',
  'resource.ssl.duration': 'DURATION',
  'resource.ssl.start': 'DURATION',
  'resource.duration': 'DURATION',
  'freeze.duration': 'DURATION',
  'long_task.duration': 'DURATION',
  'long_task.blocking_duration': 'DURATION',
  'long_task.scripts.duration': 'DURATION',
  'long_task.scripts.pause_duration': 'DURATION',
  'long_task.scripts.forced_style_and_layout_duration': 'DURATION',
  'display.scroll.max_depth': 'NUMBER',
  'display.scroll.max_depth_pct': 'NUMBER',
  'display.scroll.max_depth_scroll_top': 'NUMBER',
  'display.scroll.max_scroll_height': 'NUMBER',
  'display.scroll.max_scroll_height_time': 'DURATION',
  'issue.age': 'DURATION',
  'vital.duration': 'DURATION',
  'view.first_contentful_paint': 'DURATION',
  'view.largest_contentful_paint': 'DURATION',
  'view.first_input_delay': 'DURATION',
  'view.first_input_time': 'DURATION',
  'view.interaction_to_next_paint': 'DURATION',
  'view.interaction_to_next_paint_time': 'DURATION',
  'view.cumulative_layout_shift': 'NUMBER',
  'view.cumulative_layout_shift_time': 'DURATION',
  'view.dom_complete': 'DURATION',
  'view.dom_content_loaded': 'DURATION',
  'view.dom_interactive': 'DURATION',
  'view.in_foreground_periods.start': 'DURATION',
  'view.in_foreground_periods.duration': 'DURATION',
  'view.load_event': 'DURATION',
  'view.frozen_frame.count': 'NUMBER',
  'view.resource.count': 'NUMBER',
  'view.memory_average': 'BYTES',
  'view.memory_max': 'BYTES',
  'view.cpu_ticks_count': 'NUMBER',
  'view.cpu_ticks_per_second': 'NUMBER',
  'view.refresh_rate_average': 'NUMBER',
  'view.refresh_rate_min': 'NUMBER',
  'view.flutter_build_time': 'DURATION',
  'view.flutter_raster_time': 'DURATION',
  'view.js_refresh_rate': 'NUMBER',
  'action.error.count': 'NUMBER',
  'action.crash.count': 'NUMBER',
};

const isRangeFacet = (facet: string) =>
  Boolean(RangeFacetsMap[facet as keyof typeof RangeFacetsMap]);

export default isRangeFacet;
