import classNames from 'classnames';
import React, { ReactNode, ReactElement } from 'react';

const SearchHelp = ({
  children,
  operators,
  warning,
}: {
  children?: ReactNode;
  operators: { [key: string]: string };
  warning?: string;
}): ReactElement => {
  return (
    <div className="search__help">
      {warning ? (
        <div className="search__help__row">
          <div className="search__help__row__label">Warning:</div>
          <div className="search__help__operators">{warning}</div>
        </div>
      ) : null}
      {children ? children : null}
      <div className="search__help__row">
        <div className="search__help__row__label">Facet value operators:</div>
        <div className="search__help__operators">
          {Object.keys(operators).map((key) => {
            return (
              <div
                className={classNames({
                  search__help__operator__option: true,
                })}
                key={key}
              >
                <span className="font-bold">{key}</span>
                <span>{` ${operators[key]}`}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="search__help__row">
        <div className="search__help__row__label">Union (facet values):</div>
        <div className="search__help__operators">{'facetName="A OR B"'}</div>
      </div>
    </div>
  );
};

export default SearchHelp;
