import classNames from 'classnames';
import { useMetricsQueryStateV2 } from 'hooks';
import React, { ReactElement } from 'react';
import { Plus } from 'react-feather';
import { FunctionName } from 'types/MetricsQueryBuilder';

import MetricsQueryBuilder from './MetricsQueryBuilder';
import MetricsQueryFormula from './MetricsQueryFormula';
import { AutocompleteOption } from '..';

const MetricsQuery = ({
  onlyOneFunction,
  blockedFunctionsCategories = [],
  blockedFunctionsNames,
  customAggregateFunctions,
  editableMetrics = true,
  metricsQueryState,
  ComponentAfterFrom,
}: {
  onlyOneFunction?: boolean;
  blockedFunctionsCategories?: string[];
  blockedFunctionsNames?: Array<FunctionName>;
  customAggregateFunctions?: AutocompleteOption[];
  editableMetrics?: boolean;
  hideCustomStep?: boolean;
  metricsQueryState: ReturnType<typeof useMetricsQueryStateV2>;
  ComponentAfterFrom?: ReactElement;
}): ReactElement => {
  const {
    activeQueryType,
    addFormula,
    addQuery,
    formulas,
    queries,
    toggleAddFormula,
  } = metricsQueryState;

  return (
    <div>
      <div>
        <MetricsQueryBuilder
          onlyOneFunction={onlyOneFunction}
          blockedFunctionsCategories={blockedFunctionsCategories}
          blockedFunctionsNames={blockedFunctionsNames}
          customAggregateFunctions={customAggregateFunctions}
          ComponentAfterFrom={ComponentAfterFrom}
          editableMetrics={editableMetrics}
          queries={queries}
          metricsQueryState={metricsQueryState}
        />
        <MetricsQueryFormula
          formulas={formulas}
          queries={queries}
          metricsQueryState={metricsQueryState}
        />
        <div className="metrics-query-builder__actions mt-2">
          <button
            className="button button--blue"
            onClick={() => addQuery()}
            data-testid="add_query"
          >
            <Plus size={16} /> Add Query
          </button>
          {activeQueryType === 'multi' && (
            <button
              className={classNames({
                'button button--blue': true,
                'button--disabled': toggleAddFormula.value,
              })}
              onClick={() => addFormula()}
            >
              <Plus size={16} /> Add Formula
            </button>
          )}
          {formulas.length === 0 && activeQueryType === 'single' && (
            <button
              className={classNames({
                'button button--blue': true,
                'button--disabled': toggleAddFormula.value,
              })}
              onClick={() => addFormula()}
              data-testid="add_formula"
            >
              <Plus size={16} /> Add Formula
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MetricsQuery;
