import classNames from 'classnames';
import { TooltipTrigger } from 'components';
import React, { ReactElement } from 'react';
import {
  MdOutlineFavoriteBorder as FavoriteEmptyIcon,
  MdFavorite as FavoriteFilledIcon,
  MdModeEdit as EditIcon,
} from 'react-icons/md';
import { LuLineChart as LineChartIcon } from 'react-icons/lu';

import { FavoriteFacetExtend } from './types';

const LogsFacetExplorerSourceFacetsActions = ({
  facetWithFavorite,
  onMarkFavorite,
  onRemoveFavorite,
  onChartClick,
  onEditFavoriteFacet,
}: {
  facetWithFavorite: FavoriteFacetExtend;
  onEditFavoriteFacet: (facet: FavoriteFacetExtend) => void;
  onMarkFavorite: (facet: FavoriteFacetExtend) => void;
  onRemoveFavorite: (facet: FavoriteFacetExtend) => void;
  onChartClick: (facet: FavoriteFacetExtend) => void;
}): ReactElement => {
  const isFavorite = facetWithFavorite.isFavorite;

  return (
    <div>
      <div
        className={classNames({
          'table__row__actions--virtualized': true,
          'logs-facet-explorer__facets__row__actions--favourite': true,
          hidden: !isFavorite,
        })}
        style={{ '--table-actions-width': '34px' }}
      >
        <div className="table__row__actions--hidden">
          <div className="table__row__actions__open">
            <div className="flex h-full items-center justify-end">
              <TooltipTrigger
                className="table__cell__actions__item--blue"
                tooltip="Remove Favorite"
              >
                <FavoriteFilledIcon
                  className="alerts__contacts__table__actions__icon--edit"
                  onClick={() => onRemoveFavorite(facetWithFavorite)}
                  size={18}
                />
              </TooltipTrigger>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames({
          'table__row__actions--virtualized': true,
          'logs-facet-explorer__facets__row__actions': true,
        })}
        style={{ '--table-actions-width': isFavorite ? '109px' : '74px' }}
      >
        <div className="table__row__actions--hidden">
          <div className="table__row__actions__slider">
            <div className="alerts__contacts__table__actions">
              <div
                className="table__cell__actions__item--blue"
                onClick={() => onChartClick(facetWithFavorite)}
              >
                <TooltipTrigger tooltip="Show analytics">
                  <LineChartIcon
                    className="alerts__contacts__table__actions__icon--edit"
                    size={18}
                  />
                </TooltipTrigger>
              </div>
              {isFavorite && (
                <div
                  className="table__cell__actions__item--blue"
                  onClick={(e) => {
                    onEditFavoriteFacet(facetWithFavorite);
                    e.stopPropagation();
                  }}
                >
                  <TooltipTrigger tooltip="Edit Favorite Facet">
                    <EditIcon
                      className="alerts__contacts__table__actions__icon--edit"
                      size={18}
                    />
                  </TooltipTrigger>
                </div>
              )}
              <div
                className="table__cell__actions__item--blue"
                onClick={(e) => {
                  if (facetWithFavorite.isFavorite) {
                    onRemoveFavorite(facetWithFavorite);
                  } else {
                    onMarkFavorite(facetWithFavorite);
                  }
                  e.stopPropagation();
                }}
              >
                <TooltipTrigger
                  tooltip={
                    facetWithFavorite.isFavorite
                      ? 'Remove Favorite'
                      : 'Mark Favorite'
                  }
                >
                  {facetWithFavorite.isFavorite ? (
                    <FavoriteFilledIcon
                      className="alerts__contacts__table__actions__icon--edit"
                      size={18}
                    />
                  ) : (
                    <FavoriteEmptyIcon
                      className="alerts__contacts__table__actions__icon--edit"
                      size={18}
                    />
                  )}
                </TooltipTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogsFacetExplorerSourceFacetsActions;
