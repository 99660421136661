import { useSelectedFacetValuesByNameState } from 'hooks';
import React, { ReactElement } from 'react';
import { ValueCount } from 'types/generated';

import DashboardListSidebarFacetGroup from './DashboardListSidebarFacetGroup';
import { DashboardListSidebarFacet } from '../utils';

const DashboardListSidebar = ({
  dashboardFilterProperties,
  request,
  selectedFacetValuesByNameState,
}: {
  dashboardFilterProperties: { [key: string]: ValueCount[] };
  request: any;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
}): ReactElement => {
  return (
    <div className="events__sidebar">
      <DashboardListSidebarFacetGroup
        dashboardFilterProperties={dashboardFilterProperties}
        forceExpanded
        facetNames={DashboardListSidebarFacet}
        selectedFacetValuesByNameState={selectedFacetValuesByNameState}
        request={request}
      />
    </div>
  );
};

export default DashboardListSidebar;
