import { fetchJson } from 'requests';

const deletePolicyConfig = async ({
  groupId,
  policyId,
}: {
  groupId: string;
  policyId: string;
}): Promise<Record<string, string>> => {
  const result = await fetchJson(`rbac/rbacconfig/${groupId}/${policyId}`, {
    method: 'DELETE',
  });

  return result as Record<string, string>;
};

export default deletePolicyConfig;
