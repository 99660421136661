import { AutocompleteV2, Button, Input, TooltipTrigger } from 'components';
import classNames from 'classnames';
import React, { ReactElement, useMemo } from 'react';
import { MetricLabelOperatorOptions, parseMetricLabelQuery } from 'utils';
import { IoMdClose } from 'react-icons/io';

import useMetricsCardinality from './useMetricsCardinality';
import { cardinalityMatcher } from './utils';

const MetricsCardinalityLabelValue = ({
  labelIndex,
  series,
  metricsCardinality,
}: {
  labelIndex: number;
  series: string;
  metricsCardinality: ReturnType<typeof useMetricsCardinality>;
}): ReactElement => {
  const { date, cardinalityQuery, labelValuePicker, updateCardinalityQuery } =
    metricsCardinality;
  const { labelListMap, labelValueListMap, loadLabelValueList } =
    labelValuePicker;
  const { type } = cardinalityQuery;

  const { label, value, operator } = parseMetricLabelQuery(series);
  const { labelMatcher, metricMatcher } = cardinalityMatcher(
    cardinalityQuery,
    labelIndex - 1,
  );

  const setLabelAndLoadValueList = (lb: string) => {
    const newLabel = `${lb}${operator}""`;
    const newLabels = [...cardinalityQuery.labels];
    newLabels[labelIndex] = newLabel;
    updateCardinalityQuery('labels', newLabels, true);
    const newQuery = { ...cardinalityQuery, labels: newLabels };
    const { labelMatcher, metricMatcher } = cardinalityMatcher(
      newQuery,
      labelIndex,
    );
    const matcher = type === 'metric' ? metricMatcher : labelMatcher;
    loadLabelValueList({ date, label: lb, matcher });
  };

  const matcher = type === 'metric' ? metricMatcher : labelMatcher;

  const errors = useMemo(() => {
    const errors = { label: false, value: false };
    if (!label) errors.label = true;
    if (!value) errors.value = true;
    return errors;
  }, [label, value]);

  return (
    <div className="button-group">
      <div className="button-group__item button-group__item--value">
        <AutocompleteV2
          className={classNames({
            'autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value':
              true,
            'metrics__query-builder__series__builder--error': errors?.label,
            'metrics__query-builder__series__builder__placeholder--highlighted':
              !!label,
          })}
          isLoading={labelListMap[matcher]?.isLoading}
          onChange={setLabelAndLoadValueList}
          options={labelListMap[matcher]?.data || []}
          placeholder={(label as string) || 'Select a label'}
          value={label}
        />
      </div>
      <div className="button-group__item button-group__item--value">
        <AutocompleteV2
          className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value"
          isSearchable={false}
          onChange={(op) => {
            const newLabel = `${label}${op}"${value}"`;
            const newLabels = [...cardinalityQuery.labels];
            newLabels[labelIndex] = newLabel;
            updateCardinalityQuery('labels', newLabels);
          }}
          options={MetricLabelOperatorOptions}
          value={operator}
          placeholder={'Select a label'}
        />
      </div>
      <div className="button-group__item button-group__item--value">
        {(operator === '=' || operator === '!=') && (
          <AutocompleteV2
            className={classNames({
              'autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value':
                true,
              'metrics__query-builder__series__builder--error': errors?.value,
              'metrics__query-builder__series__builder__placeholder--highlighted':
                !!value,
            })}
            isLoading={labelValueListMap[label]?.isLoading}
            onChange={(val: string) => {
              const newLabel = `${label}${operator}"${val}"`;
              const newLabels = [...cardinalityQuery.labels];
              newLabels[labelIndex] = newLabel;
              updateCardinalityQuery('labels', newLabels);
            }}
            options={labelValueListMap[label]?.data || []}
            placeholder={(value as string) || 'Select a value'}
            value={value as string}
          />
        )}
        {(operator === '=~' || operator === '!~') && (
          <Input
            autoFocus
            className="input--no-border metrics__query-builder__series__builder--input"
            onChange={(val) => {
              const newLabel = `${label}${operator}"${val}"`;
              const newLabels = [...cardinalityQuery.labels];
              newLabels[labelIndex] = newLabel;
              updateCardinalityQuery('labels', newLabels);
            }}
            type="text"
            value={value as string}
            placeholder="regex"
          />
        )}
      </div>

      <TooltipTrigger
        className="button-group__item button-group__item__no-padding flex"
        tooltip="Delete"
      >
        <Button
          className="metrics__function-builder__item__close h-full"
          variant="ghost"
          size="sm"
          onClick={() => {
            if (cardinalityQuery.labels.length === 1) {
              updateCardinalityQuery('labels', ['=""']);
              return;
            }
            const newLabels = [...cardinalityQuery.labels];
            newLabels.splice(labelIndex, 1);
            updateCardinalityQuery('labels', newLabels);
          }}
        >
          <IoMdClose size={16} />
        </Button>
      </TooltipTrigger>
    </div>
  );
};

export default MetricsCardinalityLabelValue;
