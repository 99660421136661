import {
  AutocompleteOption,
  AutocompleteV2,
  Input,
  TooltipTrigger,
} from 'components';
import React, { ReactElement, useEffect, useState } from 'react';
import { MdDelete, MdAdd } from 'react-icons/md';

import { KeyPairProps } from '../types';

const AlertsKeyPairValue = ({
  onChange,
  values,
  isOperatorConfiguratble,
  operatorOptions,
}: {
  onChange: (val: KeyPairProps[]) => void;
  values: KeyPairProps[];
  isOperatorConfiguratble?: boolean;
  operatorOptions?: AutocompleteOption[];
}): ReactElement => {
  const [keyPair, setKeyPair] = useState<KeyPairProps[]>(values);

  const handleKeyPairChange = (
    index: number,
    field: 'label' | 'value' | 'operator',
    value: string,
  ) => {
    const newKeyPair = [...keyPair];
    newKeyPair[index][field] = value;
    setKeyPair(newKeyPair);
    onChange(newKeyPair);
  };

  const handleDeleteKeyPair = (index: number) => {
    const newKeyPair = [...keyPair];
    newKeyPair.splice(index, 1);
    setKeyPair(newKeyPair);
    onChange(newKeyPair);
  };

  const addNewLabel = () => {
    const newKeyPair = [...keyPair];
    if (newKeyPair.length > 0) {
      const lastItem = newKeyPair[newKeyPair.length - 1];
      if (lastItem.label === '' || lastItem.value === '') {
        return;
      }
    }
    newKeyPair.push({ label: '', value: '' });
    setKeyPair(newKeyPair);
    onChange(newKeyPair);
  };

  useEffect(() => {
    setKeyPair(values);
  }, [values]);

  return (
    <div className="alerts__create__details__label">
      {keyPair.map(({ label, value, operator }, idx) => {
        return (
          <div className="labels__create__details__label__item" key={idx}>
            <div className="labels__create__details__label__item__input">
              <Input
                placeholder="Label name"
                type="text"
                value={label}
                onChange={(e) => handleKeyPairChange(idx, 'label', e)}
                size="3"
                variant="default"
              />
              {isOperatorConfiguratble ? (
                <AutocompleteV2
                  className="autocomplete-container--no-border alerts__create__details__label__item__operator-config"
                  isClearable={false}
                  isSearchable={false}
                  onChange={(val: string) =>
                    handleKeyPairChange(idx, 'operator', val)
                  }
                  options={operatorOptions}
                  value={operator || '='}
                />
              ) : (
                <div className="labels__create__details__label__item__operator">
                  =
                </div>
              )}
              <Input
                placeholder="value"
                type="text"
                value={value}
                onChange={(e) => handleKeyPairChange(idx, 'value', e)}
                size="3"
                variant="default"
              />
              <div
                className="labels__create__details__label__item__delete"
                onClick={() => handleDeleteKeyPair(idx)}
              >
                <MdDelete />
              </div>
            </div>
          </div>
        );
      })}
      <TooltipTrigger tooltip="Add new label">
        <div
          className="labels__create__details__label__add"
          onClick={addNewLabel}
        >
          <MdAdd />
        </div>
      </TooltipTrigger>
    </div>
  );
};

export default AlertsKeyPairValue;
