import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from 'utils/tailwind';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-primary text-primary-foreground hover:border-form-border-hover',
        destructive:
          'bg-destructive text-destructive-foreground hover:border-form-border-hover',
        outline:
          'border border-primary bg-background hover:bg-accent hover:text-accent-foreground hover:border-form-border-hover',
        'outline-secondary':
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground hover:border-form-border-hover',
        secondary:
          'bg-secondary text-secondary-foreground hover:border-form-border-hover',
        accent:
          'bg-accent text-accent-foreground hover:border-form-border-hover',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        'ghost-destructive':
          'hover:bg-destructive hover:text-destructive-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        icon: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground hover:border-form-border-hover',
        'icon-outline':
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground hover:border-form-border-hover',
        'icon-primary':
          'bg-primary text-primary-foreground hover:border-form-border-hover',
      },
      size: {
        default: 'h-8 px-4 py-2 text-base',
        xss: 'h-5 rounded-sm px-2 text-xs',
        xs: 'h-6 rounded-sm px-2 text-xs',
        sm: 'h-7 rounded-sm px-3 text-xs',
        md: 'h-10 rounded-md px-4 text-sm',
        lg: 'h-12 rounded-md px-6 text-lg',
        icon: 'h-8 w-8',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  // eslint-disable-next-line react/prop-types
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
